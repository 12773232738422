import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { systemOptionsArmDisarmActivityDaysFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsArmDisarmActivityDaysField";
import { systemOptionsCelsiusFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCelsiusField";
import { systemOptionsCIDFormatFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCIDFormatField";
import { systemOptionsClosingCheckFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingCheckField";
import { systemOptionsClosingCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingCodeField";
import { systemOptionsCrossZoneTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import { systemOptionsDetectWirelessJammingFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDetectWirelessJammingField";
import { systemOptionsDisplayTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDisplayTimeField";
import { systemOptionsEcpPartitionFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEcpPartitionField";
import { systemOptionsEnableKeypadPanicKeysFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEnableKeypadPanicKeysField";
import { systemOptionsEntryDelay1FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import { systemOptionsEntryDelay2FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay2Field";
import { systemOptionsEolResistorValueFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEolResistorValueField";
import { systemOptionsExitDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsExitDelayField";
import { systemOptionsHoursFromGMTFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import { systemOptionsIso2CountryCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsIso2CountryCodeField";
import { systemOptionsKeypadInputFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsKeypadInputField";
import { systemOptionsObserveDstFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsObserveDstField";
import { systemOptionsOccupiedPremisesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsOccupiedPremisesField";
import { systemOptionsPowerFailDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import { systemOptionsPrimaryProgrammingLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPrimaryProgrammingLanguageField";
import { systemOptionsPrimaryUserLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPrimaryUserLanguageField";
import { systemOptionsResetSwingerBypassFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import { systemOptionsSecondaryProgrammingLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSecondaryProgrammingLanguageField";
import { systemOptionsSecondaryUserLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSecondaryUserLanguageField";
import { systemOptionsSwingerBypassTripsFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import { systemOptionsSystemTypeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSystemTypeField";
import { systemOptionsTimeChangeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsTimeChangeField";
import { systemOptionsUseBuiltIn1100WirelessFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsUseBuiltIn1100WirelessField";
import { systemOptionsUseFalseAlarmQuestionFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsUseFalseAlarmQuestionField";
import { systemOptionsWeatherPostalCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherPostalCodeField";
import { systemOptionsWeatherZipCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import { systemOptionsWirelessAudiblesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import { systemOptionsWirelessEncryption1100FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100Field";
import { systemOptionsWirelessEncryption1100PassphraseFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100PassphraseField";
import { systemOptionsWirelessHouseCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessHouseCodeField";
import { systemOptionsZoneActivityHoursFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsZoneActivityHoursField";
import { XTSystemOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XTFullProgramming/XTSystemOptionsProgrammingConceptForm/__generated__/XTSystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getSystemOptionsState } from "../../../XTFullProgramming/XTSystemOptionsProgrammingConceptForm";
import { XtProgrammingTemplateSystemOptionsInput } from "../Create/__generated__/XTTemplateSaveMutation.graphql";
import { XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        systemOptions {
          id
          included
          systemType {
            included
            data
          }
          closingCode {
            included
            data
          }
          closingCheck {
            included
            data
          }
          entryDelay1 {
            included
            data
          }
          entryDelay2 {
            included
            data
          }
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          resetSwingerBypass {
            included
            data
          }
          zoneActivityHours {
            included
            data
          }
          armDisarmActivityDays {
            included
            data
          }
          timeChange {
            included
            data
          }
          observeDst {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          displayTime {
            included
            data
          }
          houseCode {
            included
            data
          }
          detectJam {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          useBuiltIn1100Wireless {
            included
            data
          }
          enableKeypadPanicKeys {
            included
            data
          }
          occupiedPremises {
            included
            data
          }
          useFalseAlarmQuestion {
            included
            data
          }
          weatherZipCode {
            included
            data
          }
          iso2CountryCode {
            included
            data
          }
          weatherPostalCode {
            included
            data
          }
          primaryProgrammingLanguage {
            included
            data
          }
          secondaryProgrammingLanguage {
            included
            data
          }
          primaryUserLanguage {
            included
            data
          }
          secondaryUserLanguage {
            included
            data
          }
          wirelessEncryption1100 {
            included
            data
          }
          wirelessEncryption1100Passphrase {
            included
            data
          }
          eolResistorValue {
            included
            data
          }
          celsius {
            included
            data
          }
          exitDelay {
            included
            data
          }
          ecpPartition {
            included
            data
          }
          keypadInput {
            included
            data
          }
          captureFormat {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XTSystemOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XtProgrammingTemplateSystemOptionsInput => {
  const {
    panel: { systemOptions },
  } = getSystemOptionsState(controlSystem);

  const systemOptionsInputFields = {
    systemType: {
      included: includedFields.has(systemOptionsSystemTypeFieldId()),
      data: systemOptions.systemType,
    },
    closingCode: {
      included: includedFields.has(systemOptionsClosingCodeFieldId()),
      data: systemOptions.closingCode,
    },
    closingCheck: {
      included: includedFields.has(systemOptionsClosingCheckFieldId()),
      data: systemOptions.closingCheck,
    },
    entryDelay1: {
      included: includedFields.has(systemOptionsEntryDelay1FieldId()),
      data: systemOptions.entryDelay1,
    },
    entryDelay2: {
      included: includedFields.has(systemOptionsEntryDelay2FieldId()),
      data: systemOptions.entryDelay2,
    },
    crossZoneTime: {
      included: includedFields.has(systemOptionsCrossZoneTimeFieldId()),
      data: systemOptions.crossZoneTime,
    },
    powerFailDelay: {
      included: includedFields.has(systemOptionsPowerFailDelayFieldId()),
      data: systemOptions.powerFailDelay,
    },
    swingerBypassTrips: {
      included: includedFields.has(systemOptionsSwingerBypassTripsFieldId()),
      data: systemOptions.swingerBypassTrips,
    },
    resetSwingerBypass: {
      included: includedFields.has(systemOptionsResetSwingerBypassFieldId()),
      data: systemOptions.resetSwingerBypass,
    },
    zoneActivityHours: {
      included: includedFields.has(systemOptionsZoneActivityHoursFieldId()),
      data: systemOptions.zoneActivityHours,
    },
    armDisarmActivityDays: {
      included: includedFields.has(systemOptionsArmDisarmActivityDaysFieldId()),
      data: systemOptions.armDisarmActivityDays,
    },
    timeChange: {
      included: includedFields.has(systemOptionsTimeChangeFieldId()),
      data: systemOptions.timeChange,
    },
    observeDst: {
      included: includedFields.has(systemOptionsObserveDstFieldId()),
      data: systemOptions.observeDst,
    },
    hoursFromGMT: {
      included: includedFields.has(systemOptionsHoursFromGMTFieldId()),
      data: systemOptions.hoursFromGMT,
    },
    displayTime: {
      included: includedFields.has(systemOptionsDisplayTimeFieldId()),
      data: systemOptions.displayTime,
    },
    houseCode: {
      included: includedFields.has(systemOptionsWirelessHouseCodeFieldId()),
      data: systemOptions.houseCode,
    },
    detectJam: {
      included: includedFields.has(systemOptionsDetectWirelessJammingFieldId()),
      data: systemOptions.detectJam,
    },
    wirelessAudibles: {
      included: includedFields.has(systemOptionsWirelessAudiblesFieldId()),
      data: systemOptions.wirelessAudibles,
    },
    useBuiltIn1100Wireless: {
      included: includedFields.has(
        systemOptionsUseBuiltIn1100WirelessFieldId()
      ),
      data: systemOptions.useBuiltIn1100Wireless,
    },
    enableKeypadPanicKeys: {
      included: includedFields.has(systemOptionsEnableKeypadPanicKeysFieldId()),
      data: systemOptions.enableKeypadPanicKeys,
    },
    occupiedPremises: {
      included: includedFields.has(systemOptionsOccupiedPremisesFieldId()),
      data: systemOptions.occupiedPremises,
    },
    useFalseAlarmQuestion: {
      included: includedFields.has(systemOptionsUseFalseAlarmQuestionFieldId()),
      data: systemOptions.useFalseAlarmQuestion,
    },
    weatherZipCode: {
      included: includedFields.has(systemOptionsWeatherZipCodeFieldId()),
      data: systemOptions.weatherZipCode,
    },
    iso2CountryCode: {
      included: includedFields.has(systemOptionsIso2CountryCodeFieldId()),
      data: systemOptions.iso2CountryCode,
    },
    weatherPostalCode: {
      included: includedFields.has(systemOptionsWeatherPostalCodeFieldId()),
      data: systemOptions.weatherPostalCode,
    },
    primaryProgrammingLanguage: {
      included: includedFields.has(
        systemOptionsPrimaryProgrammingLanguageFieldId()
      ),
      data: systemOptions.primaryProgrammingLanguage,
    },
    secondaryProgrammingLanguage: {
      included: includedFields.has(
        systemOptionsSecondaryProgrammingLanguageFieldId()
      ),
      data: systemOptions.secondaryProgrammingLanguage,
    },
    primaryUserLanguage: {
      included: includedFields.has(systemOptionsPrimaryUserLanguageFieldId()),
      data: systemOptions.primaryUserLanguage,
    },
    secondaryUserLanguage: {
      included: includedFields.has(systemOptionsSecondaryUserLanguageFieldId()),
      data: systemOptions.secondaryUserLanguage,
    },
    wirelessEncryption1100: {
      included: includedFields.has(
        systemOptionsWirelessEncryption1100FieldId()
      ),
      data: systemOptions.wirelessEncryption1100,
    },
    wirelessEncryption1100Passphrase: {
      included: includedFields.has(
        systemOptionsWirelessEncryption1100PassphraseFieldId()
      ),
      data: systemOptions.wirelessEncryption1100Passphrase,
    },
    eolResistorValue: {
      included: includedFields.has(systemOptionsEolResistorValueFieldId()),
      data: systemOptions.eolResistorValue,
    },
    celsius: {
      included: includedFields.has(systemOptionsCelsiusFieldId()),
      data: systemOptions.celsius,
    },
    exitDelay: {
      included: includedFields.has(systemOptionsExitDelayFieldId()),
      data: systemOptions.exitDelay,
    },
    ecpPartition: {
      included: includedFields.has(systemOptionsEcpPartitionFieldId()),
      data: systemOptions.ecpPartition,
    },
    keypadInput: {
      included: includedFields.has(systemOptionsKeypadInputFieldId()),
      data: systemOptions.keypadInput,
    },
    captureFormat: {
      included: includedFields.has(systemOptionsCIDFormatFieldId()),
      data: systemOptions.captureFormat,
    },
  };

  const systemOptionsIncluded = Object.values(systemOptionsInputFields).some(
    ({ included }) => included
  );

  return {
    included: systemOptionsIncluded,
    ...systemOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { systemOptions } = getState(template);

  if (!systemOptions) return;

  if (systemOptions.systemType?.included) {
    setIncludedFields(setToggle(systemOptionsSystemTypeFieldId()));
  }
  if (systemOptions.closingCode?.included) {
    setIncludedFields(setToggle(systemOptionsClosingCodeFieldId()));
  }
  if (systemOptions.closingCheck?.included) {
    setIncludedFields(setToggle(systemOptionsClosingCheckFieldId()));
  }
  if (systemOptions.entryDelay1?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay1FieldId()));
  }
  if (systemOptions.entryDelay2?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay2FieldId()));
  }
  if (systemOptions.crossZoneTime?.included) {
    setIncludedFields(setToggle(systemOptionsCrossZoneTimeFieldId()));
  }
  if (systemOptions.powerFailDelay?.included) {
    setIncludedFields(setToggle(systemOptionsPowerFailDelayFieldId()));
  }
  if (systemOptions.swingerBypassTrips?.included) {
    setIncludedFields(setToggle(systemOptionsSwingerBypassTripsFieldId()));
  }
  if (systemOptions.resetSwingerBypass?.included) {
    setIncludedFields(setToggle(systemOptionsResetSwingerBypassFieldId()));
  }
  if (systemOptions.zoneActivityHours?.included) {
    setIncludedFields(setToggle(systemOptionsZoneActivityHoursFieldId()));
  }
  if (systemOptions.armDisarmActivityDays?.included) {
    setIncludedFields(setToggle(systemOptionsArmDisarmActivityDaysFieldId()));
  }
  if (systemOptions.timeChange?.included) {
    setIncludedFields(setToggle(systemOptionsTimeChangeFieldId()));
  }
  if (systemOptions.observeDst?.included) {
    setIncludedFields(setToggle(systemOptionsObserveDstFieldId()));
  }
  if (systemOptions.hoursFromGMT?.included) {
    setIncludedFields(setToggle(systemOptionsHoursFromGMTFieldId()));
  }
  if (systemOptions.displayTime?.included) {
    setIncludedFields(setToggle(systemOptionsDisplayTimeFieldId()));
  }
  if (systemOptions.houseCode?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessHouseCodeFieldId()));
  }
  if (systemOptions.detectJam?.included) {
    setIncludedFields(setToggle(systemOptionsDetectWirelessJammingFieldId()));
  }
  if (systemOptions.wirelessAudibles?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessAudiblesFieldId()));
  }
  if (systemOptions.useBuiltIn1100Wireless?.included) {
    setIncludedFields(setToggle(systemOptionsUseBuiltIn1100WirelessFieldId()));
  }
  if (systemOptions.enableKeypadPanicKeys?.included) {
    setIncludedFields(setToggle(systemOptionsEnableKeypadPanicKeysFieldId()));
  }
  if (systemOptions.occupiedPremises?.included) {
    setIncludedFields(setToggle(systemOptionsOccupiedPremisesFieldId()));
  }
  if (systemOptions.useFalseAlarmQuestion?.included) {
    setIncludedFields(setToggle(systemOptionsUseFalseAlarmQuestionFieldId()));
  }
  if (systemOptions.weatherZipCode?.included) {
    setIncludedFields(setToggle(systemOptionsWeatherZipCodeFieldId()));
  }
  if (systemOptions.iso2CountryCode?.included) {
    setIncludedFields(setToggle(systemOptionsIso2CountryCodeFieldId()));
  }
  if (systemOptions.weatherPostalCode?.included) {
    setIncludedFields(setToggle(systemOptionsWeatherPostalCodeFieldId()));
  }
  if (systemOptions.primaryProgrammingLanguage?.included) {
    setIncludedFields(
      setToggle(systemOptionsPrimaryProgrammingLanguageFieldId())
    );
  }
  if (systemOptions.secondaryProgrammingLanguage?.included) {
    setIncludedFields(
      setToggle(systemOptionsSecondaryProgrammingLanguageFieldId())
    );
  }
  if (systemOptions.primaryUserLanguage?.included) {
    setIncludedFields(setToggle(systemOptionsPrimaryUserLanguageFieldId()));
  }
  if (systemOptions.secondaryUserLanguage?.included) {
    setIncludedFields(setToggle(systemOptionsSecondaryUserLanguageFieldId()));
  }
  if (systemOptions.wirelessEncryption1100?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessEncryption1100FieldId()));
  }
  if (systemOptions.wirelessEncryption1100Passphrase?.included) {
    setIncludedFields(
      setToggle(systemOptionsWirelessEncryption1100PassphraseFieldId())
    );
  }
  if (systemOptions.eolResistorValue?.included) {
    setIncludedFields(setToggle(systemOptionsEolResistorValueFieldId()));
  }
  if (systemOptions.celsius?.included) {
    setIncludedFields(setToggle(systemOptionsCelsiusFieldId()));
  }
  if (systemOptions.exitDelay?.included) {
    setIncludedFields(setToggle(systemOptionsExitDelayFieldId()));
  }
  if (systemOptions.captureFormat?.included) {
    setIncludedFields(setToggle(systemOptionsCIDFormatFieldId()));
  }
};
