/**
 * @generated SignedSource<<5a4c35c1248763c2cb49c4f4eb42de65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DirectionOfTravel = "CROSS" | "IN" | "OFF" | "OUT";
import { FragmentRefs } from "relay-runtime";
export type RecorderCameraDetectionLineSettings_varHubCameraDetectionLine$data = {
  readonly detectAnimals: boolean;
  readonly detectPeople: boolean;
  readonly detectVehicles: boolean;
  readonly drawRegionOnAlerts: boolean;
  readonly id: string;
  readonly index: number | null;
  readonly isNew: boolean | null;
  readonly movementDirection: DirectionOfTravel | null;
  readonly name: string;
  readonly slotNumber: number | null;
  readonly zone: {
    readonly area: {
      readonly id: string;
    } | null;
    readonly id: string;
    readonly isNew: boolean;
    readonly name: string;
    readonly number: string;
  } | null;
  readonly " $fragmentType": "RecorderCameraDetectionLineSettings_varHubCameraDetectionLine";
};
export type RecorderCameraDetectionLineSettings_varHubCameraDetectionLine$key = {
  readonly " $data"?: RecorderCameraDetectionLineSettings_varHubCameraDetectionLine$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionLineSettings_varHubCameraDetectionLine">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNew",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecorderCameraDetectionLineSettings_varHubCameraDetectionLine",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detectPeople",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detectAnimals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detectVehicles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "drawRegionOnAlerts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "movementDirection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slotNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Zone",
      "kind": "LinkedField",
      "name": "zone",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "area",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "index",
          "storageKey": null
        },
        (v2/*: any*/)
      ]
    }
  ],
  "type": "VarHubCameraDetectionLine",
  "abstractKey": null
};
})();

(node as any).hash = "ddf1ac3376f95ffcfbabb0199f78449d";

export default node;
