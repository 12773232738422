import graphql from "babel-plugin-relay/macro";
import Space from "common/components/web/Space";
import AdvancedFields from "components/AdvancedFields";
import {
  useHardwareModel,
  useIsInternational,
  useSoftwareVersion,
  useSupports1100T,
  useSupportsVplex,
} from "components/FullProgramming/common/PanelContext";
import { useHouseCode } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsContext";
import ZoneInformationCompetitorWirelessField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationCompetitorWirelessField";
import ZoneInformationDisarmDisableField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationDisarmDisableField";
import ZoneInformationFireBellOutputNumberField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationFireBellOutputNumberField";
import ZoneInformationLocationField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationLocationField";
import ZoneInformationNumberOfEolsField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationNumberOfEolsField";
import ZoneInformationPresignalKeypadsField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationPresignalKeypadsField";
import ZoneInformationTamperField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationTamperField";
import ZoneInformationVplexSerialNumberField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationVplexSerialNumberField";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useFragment } from "react-relay";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import ZoneInformationAreaField from "../../common/ZoneInformationFields/ZoneInformationAreaField";
import ZoneInformationArmAreasField from "../../common/ZoneInformationFields/ZoneInformationArmAreasField";
import ZoneInformationArmedOpenMessageField from "../../common/ZoneInformationFields/ZoneInformationArmedOpenMessageField";
import ZoneInformationArmedOpenOutputActionField from "../../common/ZoneInformationFields/ZoneInformationArmedOpenOutputActionField";
import ZoneInformationArmedOpenOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationArmedOpenOutputNumberField";
import ZoneInformationArmedShortMessageField from "../../common/ZoneInformationFields/ZoneInformationArmedShortMessageField";
import ZoneInformationArmedShortOutputActionField from "../../common/ZoneInformationFields/ZoneInformationArmedShortOutputActionField";
import ZoneInformationArmedShortOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationArmedShortOutputNumberField";
import ZoneInformationArmingStyleField from "../../common/ZoneInformationFields/ZoneInformationArmingStyleField";
import ZoneInformationChimeSoundField from "../../common/ZoneInformationFields/ZoneInformationChimeSoundField";
import ZoneInformationContactField from "../../common/ZoneInformationFields/ZoneInformationContactField";
import ZoneInformationContactNormallyOpenField from "../../common/ZoneInformationFields/ZoneInformationContactNormallyOpenField";
import { ZoneInformationContextProvider } from "../../common/ZoneInformationFields/ZoneInformationContext";
import ZoneInformationCrossZoneField from "../../common/ZoneInformationFields/ZoneInformationCrossZoneField";
import ZoneInformationDisarmedOpenMessageField from "../../common/ZoneInformationFields/ZoneInformationDisarmedOpenMessageField";
import ZoneInformationDisarmedOpenOutputActionField from "../../common/ZoneInformationFields/ZoneInformationDisarmedOpenOutputActionField";
import ZoneInformationDisarmedOpenOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationDisarmedOpenOutputNumberField";
import ZoneInformationDisarmedShortMessageField from "../../common/ZoneInformationFields/ZoneInformationDisarmedShortMessageField";
import ZoneInformationDisarmedShortOutputActionField from "../../common/ZoneInformationFields/ZoneInformationDisarmedShortOutputActionField";
import ZoneInformationDisarmedShortOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationDisarmedShortOutputNumberField";
import ZoneInformationDMPWirelessField from "../../common/ZoneInformationFields/ZoneInformationDMPWirelessField";
import ZoneInformationEntryDelayField from "../../common/ZoneInformationFields/ZoneInformationEntryDelayField";
import ZoneInformationExpanderSerialNumberField from "../../common/ZoneInformationFields/ZoneInformationExpanderSerialNumberField";
import ZoneInformationFastResponseField from "../../common/ZoneInformationFields/ZoneInformationFastResponseField";
import ZoneInformationFirePanelSlaveInputField from "../../common/ZoneInformationFields/ZoneInformationFirePanelSlaveInputField";
import ZoneInformationFollowAreaField from "../../common/ZoneInformationFields/ZoneInformationFollowAreaField";
import ZoneInformationLEDOperationField from "../../common/ZoneInformationFields/ZoneInformationLEDOperationField";
import ZoneInformationLockdownField from "../../common/ZoneInformationFields/ZoneInformationLockdownField";
import ZoneInformationNameField from "../../common/ZoneInformationFields/ZoneInformationNameField";
import ZoneInformationNumberField from "../../common/ZoneInformationFields/ZoneInformationNumberField";
import ZoneInformationPIRPetImmunityField from "../../common/ZoneInformationFields/ZoneInformationPIRPetImmunityField";
import ZoneInformationPIRPulseCountField from "../../common/ZoneInformationFields/ZoneInformationPIRPulseCountField";
import ZoneInformationPIRSensitivityField from "../../common/ZoneInformationFields/ZoneInformationPIRSensitivityField";
import ZoneInformationPrewarnKeypadsField from "../../common/ZoneInformationFields/ZoneInformationPrewarnKeypadsField";
import ZoneInformationPriorityZoneField from "../../common/ZoneInformationFields/ZoneInformationPriorityZoneField";
import ZoneInformationReportWithAccountNumberField from "../../common/ZoneInformationFields/ZoneInformationReportWithAccountNumberField";
import ZoneInformationSensorTypeField from "../../common/ZoneInformationFields/ZoneInformationSensorTypeField";
import ZoneInformationSerialNumberField from "../../common/ZoneInformationFields/ZoneInformationSerialNumberField";
import ZoneInformationSupervisionTimeField from "../../common/ZoneInformationFields/ZoneInformationSupervisionTimeField";
import ZoneInformationSwingerBypassField from "../../common/ZoneInformationFields/ZoneInformationSwingerBypassField";
import ZoneInformationTrafficCountField from "../../common/ZoneInformationFields/ZoneInformationTrafficCountField";
import ZoneInformationTypeField from "../../common/ZoneInformationFields/ZoneInformationTypeField";
import ZoneInformationZoneAuditDaysField from "../../common/ZoneInformationFields/ZoneInformationZoneAuditDaysField";
import ZoneInformationZoneRealTimeStatusField from "../../common/ZoneInformationFields/ZoneInformationZoneRealTimeStatus";
import ZoneInformationZoneRetardDelayField from "../../common/ZoneInformationFields/ZoneInformationZoneRetardDelayField";
import { XRZoneInformationsFields_zone$key } from "./__generated__/XRZoneInformationsFields_zone.graphql";
function XRZoneInformationsFields(props: {
  zoneInformation: XRZoneInformationsFields_zone$key;
}) {
  const data = useFragment(
    graphql`
      fragment XRZoneInformationsFields_zone on Zone {
        id
        type
        ...ZoneInformationContext_zone
        ...ZoneInformationNameField_zone
        ...ZoneInformationNumberField_zone
        ...ZoneInformationLocationField_zone
        ...ZoneInformationTypeField_zone
        ...ZoneInformationAreaField_zone
        ...ZoneInformationChimeSoundField_zone
        ...ZoneInformationSensorTypeField_zone
        ...ZoneInformationExpanderSerialNumberField_zone
        ...ZoneInformationDMPWirelessField_zone
        ...ZoneInformationSerialNumberField_zone
        ...ZoneInformationContactField_zone
        ...ZoneInformationContactNormallyOpenField_zone
        ...ZoneInformationSupervisionTimeField_zone
        ...ZoneInformationLEDOperationField_zone
        ...ZoneInformationPIRPulseCountField_zone
        ...ZoneInformationPIRSensitivityField_zone
        ...ZoneInformationPIRPetImmunityField_zone
        ...ZoneInformationPrewarnKeypadsField_zone
        ...ZoneInformationPresignalKeypadsField_zone
        ...ZoneInformationSwingerBypassField_zone
        ...ZoneInformationCrossZoneField_zone
        ...ZoneInformationEntryDelayField_zone
        ...ZoneInformationPriorityZoneField_zone
        ...ZoneInformationTamperField_zone
        ...ZoneInformationZoneAuditDaysField_zone
        ...ZoneInformationTrafficCountField_zone
        ...ZoneInformationReceiverRoutingField_zone
        ...ZoneInformationArmAreasField_zone
        ...ZoneInformationDisarmedOpenMessageField_zone
        ...ZoneInformationDisarmedOpenOutputNumberField_zone
        ...ZoneInformationFireBellOutputNumberField_zone
        ...ZoneInformationDisarmedOpenOutputActionField_zone
        ...ZoneInformationDisarmedShortMessageField_zone
        ...ZoneInformationDisarmedShortOutputNumberField_zone
        ...ZoneInformationDisarmedShortOutputActionField_zone
        ...ZoneInformationArmedOpenMessageField_zone
        ...ZoneInformationArmedOpenOutputNumberField_zone
        ...ZoneInformationArmedOpenOutputActionField_zone
        ...ZoneInformationArmedShortMessageField_zone
        ...ZoneInformationArmedShortOutputNumberField_zone
        ...ZoneInformationArmedShortOutputActionField_zone
        ...ZoneInformationArmingStyleField_zone
        ...ZoneInformationZoneRetardDelayField_zone
        ...ZoneInformationFastResponseField_zone
        ...ZoneInformationFollowAreaField_zone
        ...ZoneInformationZoneRealTimeStatusField_zone
        ...ZoneInformationReportWithAccountNumberField_zone
        ...ZoneInformationFirePanelSlaveInputField_zone
        ...ZoneInformationDisarmDisableField_zone
        ...ZoneInformationCompetitorWirelessField_zone
        ...ZoneInformationVplexSerialNumberField_zone
        ...ZoneInformationLockdownField_zone
        ...ZoneInformationNumberOfEolsField_zone
      }
    `,
    props.zoneInformation
  );

  const softwareVersion = useSoftwareVersion();
  const houseCode = useHouseCode();
  const supports1100T = useSupports1100T();
  const supportsVplex = useSupportsVplex();
  const isInternational = useIsInternational();
  const hardware = useHardwareModel();
  const fireType = [
    ZoneType.FIRE,
    ZoneType.FIRE_VERIFY,
    ZoneType.SUPERVISORY,
    ZoneType.CARBON_MONOXIDE,
  ].includes(data.type as ZoneType);
  const { isXr } = resolvePanelType(hardware);
  const supportsZoneLocation = panelVersionGTOE(252, softwareVersion);

  return (
    <ZoneInformationContextProvider zoneInformation={data}>
      <ProgrammingConceptForm.Fields>
        <ZoneInformationNumberField />
        <ZoneInformationNameField />
        {supportsZoneLocation ? <ZoneInformationLocationField /> : null}
        <ZoneInformationTypeField />
        <ZoneInformationAreaField />
        {panelVersionGTOE(170, softwareVersion) ? (
          <ZoneInformationChimeSoundField />
        ) : null}
        {isInternational ? (
          softwareVersion >= 742 ? (
            <ZoneInformationNumberOfEolsField />
          ) : (
            <ZoneInformationTamperField />
          )
        ) : null}
        <ZoneInformationFollowAreaField />
        <ZoneInformationSensorTypeField />
        <ZoneInformationExpanderSerialNumberField />
        {supportsVplex && <ZoneInformationVplexSerialNumberField />}
      </ProgrammingConceptForm.Fields>

      {/* Wireless Fields */}
      <Space />
      <h3>Wireless</h3>
      <ProgrammingConceptForm.Fields>
        <ZoneInformationDMPWirelessField houseCode={houseCode} />
        {supports1100T ? (
          <ZoneInformationCompetitorWirelessField houseCode={houseCode} />
        ) : null}
        <ZoneInformationSerialNumberField />
        <ZoneInformationContactField />
        <ZoneInformationContactNormallyOpenField />
        <ZoneInformationSupervisionTimeField />
        <ZoneInformationLEDOperationField />
        <ZoneInformationDisarmDisableField />
        <ZoneInformationPIRPulseCountField />
        <ZoneInformationPIRSensitivityField />
        {panelVersionGTOE(170, softwareVersion) && (
          <ZoneInformationPIRPetImmunityField />
        )}
      </ProgrammingConceptForm.Fields>
      <Space />
      {/* Advanced Fields */}
      <AdvancedFields>
        <ProgrammingConceptForm.Fields>
          <ZoneInformationSwingerBypassField />
          <ZoneInformationZoneRetardDelayField />
          <ZoneInformationPresignalKeypadsField />
          <ZoneInformationPrewarnKeypadsField />
          {!isInternational ? <ZoneInformationFastResponseField /> : null}
          <ZoneInformationCrossZoneField />
          <ZoneInformationEntryDelayField />
          <ZoneInformationPriorityZoneField />
          <ZoneInformationTrafficCountField />
          <ZoneInformationZoneAuditDaysField />
          <ZoneInformationFirePanelSlaveInputField />
          <ZoneInformationArmAreasField />
          <ZoneInformationReportWithAccountNumberField />
          <ZoneInformationZoneRealTimeStatusField />
          <ZoneInformationLockdownField />
        </ProgrammingConceptForm.Fields>
      </AdvancedFields>
      <Space />
      {/* Actions Fields */}
      <AdvancedFields label="Actions">
        <ProgrammingConceptForm.Fields>
          <ZoneInformationDisarmedOpenMessageField />
          {isXr && fireType ? (
            <ZoneInformationFireBellOutputNumberField />
          ) : (
            <ZoneInformationDisarmedOpenOutputNumberField />
          )}
          <ZoneInformationDisarmedOpenOutputActionField />
          <ZoneInformationDisarmedShortMessageField />
          <ZoneInformationDisarmedShortOutputNumberField />
          <ZoneInformationDisarmedShortOutputActionField />
          <ZoneInformationArmedOpenMessageField />
          <ZoneInformationArmedOpenOutputNumberField />
          <ZoneInformationArmedOpenOutputActionField />
          <ZoneInformationArmedShortMessageField />
          <ZoneInformationArmedShortOutputNumberField />
          <ZoneInformationArmedShortOutputActionField />
          <ZoneInformationArmingStyleField />
        </ProgrammingConceptForm.Fields>
      </AdvancedFields>
    </ZoneInformationContextProvider>
  );
}

export default XRZoneInformationsFields;
