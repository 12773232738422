App.directive('statusPill', function () {
  return {
      restrict: 'E',
      scope: {
          state: '@',
          icon: '@',
          title: '@'
      },
      template: `
<div class="status-pill {{stateClass}}">
<i class="{{icon}}"></i>
<span>{{title}}</span>
</div>
      `,
      link: function (scope) {
          const stateClasses = {
              success: 'pill-success',
              fail: 'pill-fail',
              info: 'pill-info'
          };
          scope.stateClass = stateClasses[scope.state] || 'pill-default';
      }
  };
}); 