import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions.graphql";

export const remoteOptionsIntegratorArmDisarmReportsFieldId = () =>
  "remote-options-integrator-arm-disarm-reports";

function RemoteOptionsIntegratorArmDisarmReportsField() {
  const [
    { integratorArmDisarmReports, integratorConnection },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorArmDisarmReports
          integratorConnection
        }
        ... on Xt75RemoteOptions {
          integratorArmDisarmReports
          integratorConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorArmDisarmReportsFieldId();
  const label = "Integrator Arm / Disarm Reports";
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label={label}
    >
      <Switch
        label={label}
        disabled={disabled}
        id={fieldId}
        checked={!!integratorArmDisarmReports}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !integratorArmDisarmReports,
              "integratorArmDisarmReports"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorArmDisarmReportsField;
