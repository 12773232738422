import graphql from "babel-plugin-relay/macro";
import { addParamToUrl } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import AdvancedFields from "components/AdvancedFields";
import AddButton from "components/CameraEditCommon/AddButton";
import CameraEditOptions from "components/CameraEditCommon/CameraEditOptions";
import {
  AnalyticsHeader,
  CameraDetailsContainer,
  DetectionContainer,
  Grid,
  Header,
  InnerHeader,
  InputContainer,
  InputLabel,
  MainContainerHeading,
  MainEdit,
  MainSettings,
  MainSettingsContainer,
  PriceContainer,
  RecorderCameraEditVideoNotViewable,
  SettingsTitles,
  Snapshot,
  SnapshotContainer,
  SnapshotFrame,
  SnapshotLoadingScreen,
  TagContainer,
} from "components/CameraEditCommon/CameraEditStyledComponents";
import InlineTooltip from "components/InlineTooltip";
import { DeleteConfirmModal } from "components/Modal/ConfirmModal";
import Select from "components/Select";
import { Switch } from "components/SiteForm/FormFields";
import Tag from "components/Tag";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useState } from "react";
import {
  useFragment,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  AnalyticalDetectionLine,
  AnalyticalDetectionRegion,
  AnalyticalDetectionRegionCoordinates,
  asID,
  CameraEventsEnabled,
  fromVideoChannelId,
  MotionDetectionRegion,
  MotionDetectionRegionCoordinates,
  SecureComCamera,
  VideoDeviceStatus,
} from "securecom-graphql/client";
import { v4 as uuidv4 } from "uuid";
import CameraDetailsModal from "../CameraEditCommon/CameraDetailsModal";
import { OutOfSyncBanner } from "./OutOfSyncBanner";
import TestConnectionModal from "./TestConnectionModal";
import UniviewAnalyticalDetectionElements from "./UniviewAnalyticalDetectionElements";
import UniviewAnalyticalDetectionLineSettings from "./UniviewAnalyticalDetectionLineSettings";
import UniviewAnalyticalDetectionRegionSettings from "./UniviewAnalyticalDetectionRegionSettings";
import UniviewMotionDetectionRegions from "./UniviewMotionDetectionRegions";
import UniviewMotionDetectionRegionSettings from "./UniviewMotionDetectionRegionSettings";
import { UniviewCameraEditFormDealerChargesQuery } from "./__generated__/UniviewCameraEditFormDealerChargesQuery.graphql";
import { UniviewCameraEditFormFormatSdCardMutation } from "./__generated__/UniviewCameraEditFormFormatSdCardMutation.graphql";
import { UniviewCameraEditFormKeypadsQuery } from "./__generated__/UniviewCameraEditFormKeypadsQuery.graphql";
import { UniviewCameraEditFormRemoveCameraMutation } from "./__generated__/UniviewCameraEditFormRemoveCameraMutation.graphql";
import { UniviewCameraEditFormSaveMutation } from "./__generated__/UniviewCameraEditFormSaveMutation.graphql";
import { UniviewCameraEditForm_secureComCamera$key } from "./__generated__/UniviewCameraEditForm_secureComCamera.graphql";

interface UniviewCameraEditFormProps {
  camera: UniviewCameraEditForm_secureComCamera$key;
  canDelete: boolean;
  canViewPricing: boolean;
  controlSystemId: string;
  dealerId: number;
  isEditable: boolean;
  isSupervisor: boolean;
  isX1: boolean;
  onCancel: () => void;
  timeZones: string[];
  userService: any;
  rootScope: any;
  canFormatSdCard?: boolean;
}

const formatSdCardMutation = graphql`
  mutation UniviewCameraEditFormFormatSdCardMutation($deviceId: ID!) {
    formatSdCard(deviceId: $deviceId) {
      ... on FormatSdCardPayload {
        success
        message
      }
    }
  }
`;

//make a lazy load DealerCharges Query to bring back V6000Analytics

function UniviewCameraEditForm(props: UniviewCameraEditFormProps) {
  const {
    camera,
    canDelete,
    canViewPricing,
    controlSystemId,
    dealerId,
    isEditable,
    isSupervisor,
    isX1,
    onCancel,
    timeZones,
    userService,
    rootScope,
    canFormatSdCard,
  } = props;

  const data = useFragment(
    graphql`
      fragment UniviewCameraEditForm_secureComCamera on SecureComCamera {
        id
        isOnline
        name
        macAddress
        ipAddress
        serialNumber
        status
        timeZone
        lastCheckIn
        wirelessSignalStrength
        firmwareVersion
        adminUsername
        adminPassword
        daylightSaving
        ceilingMount
        recordOnAlarm
        includeInVerification
        manufacturer
        allowCustomerEdit
        settingConfirmation
        ...OutOfSyncBanner_secureComCamera
        keypadVisible
        videoQuality
        videoAnalyticsEnabled
        videoAnalyticsSupported
        snapshot
        generation
        controlSystem {
          id
          dealerAccess {
            accessType
          }
          panel {
            hardwareModel
          }
          servicesManager {
            videoVerificationEnabled
          }
          customer {
            dealer {
              vernaculars {
                scapiId
                dealerId
                original
                replacement
              }
              securityCommand
            }
          }
        }

        maxMotionDetectionRegions
        ...UniviewMotionDetectionRegions_secureComCamera
        motionDetectionRegions {
          regions {
            id

            coordinates {
              x
              y
            }
            daySensitivityLevel
            nightSensitivityLevel
            dayObjectSize
            nightObjectSize
          }
        }

        ...UniviewAnalyticalDetectionElements_secureComCamera
        maxAnalyticalDetectionLines
        analyticalDetectionLines {
          lines {
            id

            sensitivity
            direction
            startX
            startY
            endX
            endY
            detectPerson
            detectVehicle
          }
        }
        maxAnalyticalDetectionRegions
        analyticalDetectionRegions {
          regions {
            id

            coordinates {
              x
              y
            }
            sensitivity
            detectPerson
            detectVehicle
          }
        }
      }
    `,
    camera
  );
  // need to convert data.id base 64 to original value
  const cameraId = () =>
    parseInt(fromVideoChannelId(asID(data.id)).deviceId).toString();

  const [formatSdCard] =
    useMutation<UniviewCameraEditFormFormatSdCardMutation>(
      formatSdCardMutation
    );

  const handleFormatSdCard = () => {
    formatSdCard({
      variables: {
        deviceId: cameraId(),
      },
      onCompleted: (response, errors) => {
        if (errors) {
          // Handle GraphQL errors
          console.error(errors);
          showAlert({
            type: "error",
            text: response.formatSdCard.message ?? "Error formatting SD card.",
          });
        } else if (response.formatSdCard.success) {
          // Handle success
          showAlert({
            type: "success",
            text: "SD card formatted successfully.",
          });
        } else {
          // Handle API specific errors
          showAlert({
            type: "error",
            text: response.formatSdCard.message ?? "Error formatting SD card.",
          });
        }
      },
      onError: (error) => {
        // Handle network or other unexpected errors
        console.error(error);
        showAlert({
          type: "error",
          text: "Error formatting SD card.",
        });
      },
    });
  };

  const dealerChargesData =
    useLazyLoadQuery<UniviewCameraEditFormDealerChargesQuery>(
      graphql`
        query UniviewCameraEditFormDealerChargesQuery($dealerId: String!) {
          dealerChargesQuery(dealerId: $dealerId) {
            ... on DealerCharges {
              V6000Analytics
            }
            ... on UnknownError {
              type
            }
          }
        }
      `,
      { dealerId: dealerId.toString() }
    );

  const { controlSystemKeypadsAPI: keypadsData } =
    useLazyLoadQuery<UniviewCameraEditFormKeypadsQuery>(
      graphql`
        query UniviewCameraEditFormKeypadsQuery($controlSystemId: String!) {
          controlSystemKeypadsAPI(controlSystemId: $controlSystemId) {
            model
          }
        }
      `,
      {
        controlSystemId: controlSystemId,
      }
    );

  const relayEnv = useRelayEnvironment();
  const showAlert = useShowAlert();
  const [cameraDetailsModalOpen, setCameraDetailsModalOpen] = useState(false);
  const [testConnectionModalOpen, setTestConnectionModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [snapshotAspectRatio, setSnapshotAspectRatio] = useState(4 / 3);
  const [snapshotStatus, setSnapshotStatus] = useState({
    loading: true,
    failed: false,
  });
  const [imageHash, setImageHash] = useState(Date.now());
  const [forceDelete, setForceDelete] = useState(false);
  const [save, isSaving] =
    useMutation<UniviewCameraEditFormSaveMutation>(saveMutation);
  const [removeCamera, removingCamera] =
    useMutation<UniviewCameraEditFormRemoveCameraMutation>(
      removeCameraMutation
    );

  const isActive = data.status === VideoDeviceStatus.ACTIVE;
  const videoVerificationEnabled =
    data.controlSystem?.servicesManager.videoVerificationEnabled;
  const hardwareModel = data.controlSystem?.panel.hardwareModel;
  const supportsAnalyticalDetectionLines = data.generation === "1";
  const videoAnalyticsPrice =
    dealerChargesData?.dealerChargesQuery?.V6000Analytics;
  const availableMotionDetectionRegions =
    data.maxMotionDetectionRegions -
    (data.motionDetectionRegions?.regions.length || 0);
  const availableAnalyticalDetectionLines =
    data.maxAnalyticalDetectionLines -
    (data.analyticalDetectionLines?.lines.length || 0);
  const availableAnalyticalDetectionRegions =
    data.maxAnalyticalDetectionRegions -
    (data.analyticalDetectionRegions?.regions.length || 0);
  const has8860 =
    keypadsData?.some((keypad) => keypad?.model === "8860") ?? false;

  const hasRequiredFields = () => {
    let valid = true;

    if (data.videoAnalyticsEnabled) {
      const hasMissingDetectionType =
        data.analyticalDetectionLines.lines.some(
          (line) => !line.detectPerson && !line.detectVehicle
        ) ||
        data.analyticalDetectionRegions.regions.some(
          (region) => !region.detectPerson && !region.detectVehicle
        );
      valid = !hasMissingDetectionType;
    }

    return valid;
  };
  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (hasRequiredFields()) {
      let eventsEnabled;

      // eventsEnabled is 'ON' if analytics is enabled and analytical regions or lines exists or if analytics is disabled and non-analytical regions exists.
      if (data.videoAnalyticsEnabled) {
        eventsEnabled =
          data.analyticalDetectionLines.lines.length +
            data.analyticalDetectionRegions.regions.length >
          0
            ? CameraEventsEnabled.ON
            : CameraEventsEnabled.OFF;
      } else {
        eventsEnabled =
          data.motionDetectionRegions.regions.length > 0
            ? CameraEventsEnabled.ON
            : CameraEventsEnabled.OFF;
      }

      save({
        variables: {
          input: {
            id: data.id,
            name: data.name,
            eventsEnabled: eventsEnabled,
            daylightSaving: data.daylightSaving,
            ceilingMount: data.ceilingMount,
            recordOnAlarm: data.recordOnAlarm,
            includeInVerification: data.includeInVerification,
            allowCustomerEdit: data.allowCustomerEdit,
            keypadVisible: data.keypadVisible,
            videoQuality: data.videoQuality,
            videoAnalyticsEnabled: data.videoAnalyticsEnabled,
            timeZone: data.timeZone,
            motionDetectionRegions: data.motionDetectionRegions.regions,
            analyticalDetectionLines: data.analyticalDetectionLines.lines,
            analyticalDetectionRegions: data.analyticalDetectionRegions.regions,
          },
        },
        onCompleted: (result) => {
          const { secureComCamera, error, message } =
            result.updateSecureComCamera;
          if (secureComCamera) {
            onCancel();
            showAlert({
              type: "success",
              text: "Camera saved",
            });
          } else if (error && message) {
            showAlert({
              type: "error",
              text: message,
            });
          } else {
            showAlert({
              type: "error",
              text: "Failed to save",
            });
          }
        },
        onError: () => {
          showAlert({
            type: "error",
            text: "Failed to save",
          });
        },
      });
    }
  };
  const handleAddRegion = () => {
    relayEnv.commitUpdate((store) => {
      const camera = store.get(data.id) as RecordProxy<SecureComCamera>;
      const motionDetectionRegions = camera.getLinkedRecord(
        "motionDetectionRegions"
      );
      const newRegionId = uuidv4(); // NOTE: uuid being passed in is arbitrary. Backend will ultimately assign ID
      const newRegion = store.create(
        newRegionId,
        "MotionDetectionRegion"
      ) as RecordProxy<MotionDetectionRegion>;
      const coordinates = [
        {
          x: 250,
          y: 250,
        },
        {
          x: 750,
          y: 250,
        },
        {
          x: 750,
          y: 750,
        },
        {
          x: 250,
          y: 750,
        },
      ];
      const coordinatesRecordProxies = coordinates.map(({ x, y }) => {
        // NOTE: uuid being passed in is arbitrary. Backend will ultimately assign ID
        const newCoordinate = store.create(
          uuidv4(),
          "MotionDetectionRegionCoordinates"
        ) as RecordProxy<MotionDetectionRegionCoordinates>;

        newCoordinate.setValue(x, "x");
        newCoordinate.setValue(y, "y");

        return newCoordinate;
      });

      newRegion.setLinkedRecords(coordinatesRecordProxies, "coordinates");
      newRegion.setValue(newRegionId, "id");
      newRegion.setValue(50, "daySensitivityLevel");
      newRegion.setValue(50, "nightSensitivityLevel");
      newRegion.setValue(4, "dayObjectSize");
      newRegion.setValue(4, "nightObjectSize");
      newRegion.setValue(data.motionDetectionRegions.regions.length, "index");

      motionDetectionRegions.setLinkedRecords(
        [...motionDetectionRegions.getLinkedRecords("regions"), newRegion],
        "regions"
      );
      camera.setLinkedRecord(motionDetectionRegions, "motionDetectionRegions");
    });
  };
  const handleAddAnalyticalRegion = () => {
    relayEnv.commitUpdate((store) => {
      const camera = store.get(data.id) as RecordProxy<SecureComCamera>;
      const analyticalDetectionRegions = camera.getLinkedRecord(
        "analyticalDetectionRegions"
      );
      const newRegionId = uuidv4(); // NOTE: uuid being passed in is arbitrary. Backend will ultimately assign ID
      const newRegion = store.create(
        newRegionId,
        "AnalyticalDetectionRegion"
      ) as RecordProxy<AnalyticalDetectionRegion>;
      const coordinates = [
        {
          x: 2500,
          y: 2500,
        },
        {
          x: 7500,
          y: 2500,
        },
        {
          x: 7500,
          y: 7500,
        },
        {
          x: 2500,
          y: 7500,
        },
      ];
      const coordinatesRecordProxies = coordinates.map(({ x, y }) => {
        // NOTE: uuid being passed in is arbitrary. Backend will ultimately assign ID
        const newCoordinate = store.create(
          uuidv4(),
          "AnalyticalDetectionRegionCoordinates"
        ) as RecordProxy<AnalyticalDetectionRegionCoordinates>;

        newCoordinate.setValue(x, "x");
        newCoordinate.setValue(y, "y");

        return newCoordinate;
      });

      newRegion.setLinkedRecords(coordinatesRecordProxies, "coordinates");
      newRegion.setValue(newRegionId, "id");
      newRegion.setValue(50, "sensitivity");
      newRegion.setValue(true, "detectPerson");
      newRegion.setValue(true, "detectVehicle");
      newRegion.setValue(
        data.analyticalDetectionRegions.regions.length,
        "index"
      );

      analyticalDetectionRegions.setLinkedRecords(
        [...analyticalDetectionRegions.getLinkedRecords("regions"), newRegion],
        "regions"
      );
      camera.setLinkedRecord(
        analyticalDetectionRegions,
        "analyticalDetectionRegions"
      );
    });
  };
  const handleAddLine = () => {
    relayEnv.commitUpdate((store) => {
      const camera = store.get(data.id) as RecordProxy<SecureComCamera>;
      const analyticalDetectionLines = camera.getLinkedRecord(
        "analyticalDetectionLines"
      );
      const newLineId = uuidv4(); // NOTE: uuid being passed in is arbitrary. Backend will ultimately assign ID
      const newLine = store.create(
        newLineId,
        "AnalyticalDetectionLine"
      ) as RecordProxy<AnalyticalDetectionLine>;

      newLine.setValue(newLineId, "id");
      newLine.setValue(50, "sensitivity");
      newLine.setValue(0, "direction");
      newLine.setValue(2500, "startX");
      newLine.setValue(5000, "startY");
      newLine.setValue(7500, "endX");
      newLine.setValue(5000, "endY");
      newLine.setValue(true, "detectPerson");
      newLine.setValue(true, "detectVehicle");
      newLine.setValue(data.analyticalDetectionLines.lines.length, "index");

      analyticalDetectionLines.setLinkedRecords(
        [...analyticalDetectionLines.getLinkedRecords("lines"), newLine],
        "lines"
      );

      camera.setLinkedRecord(
        analyticalDetectionLines,
        "analyticalDetectionLines"
      );
    });
  };

  const token = rootScope.single_use_token
    ? rootScope.single_use_token
    : userService.auth_token;

  const [snapShotUrl, setSnapShotUrl] = React.useState<string>("");

  const vernaculars =
    data.controlSystem?.customer.dealer.vernaculars.map((v) => ({ ...v })) ??
    [];

  const appName = data.controlSystem?.customer.dealer.securityCommand
    ? "Security Command"
    : "Dealer Admin";

  React.useEffect(() => {
    if (data.snapshot) {
      const awaitSnapShot = async (url: string) => {
        try {
          const response = await fetch(addParamToUrl("ts", imageHash, url), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const blob = await response.blob();
          setSnapShotUrl(URL.createObjectURL(blob));
        } catch (e) {
          setSnapshotStatus({
            loading: false,
            failed: true,
          });
        }
      };

      awaitSnapShot(data.snapshot);
    }
  }, [data.snapshot, imageHash, token]);

  return (
    <form onSubmit={(event) => handleSave(event)}>
      <Header>
        <div className="row">
          <div className="page-header">
            <div className="page-header__left">
              <div className="page-header__title">
                {`Editing ${data.name || "Camera"}`}
              </div>
            </div>
            <div className="page-header__right">
              <button
                type="button"
                className="btn btn-dmp btn-sm"
                onClick={() => setTestConnectionModalOpen(true)}
              >
                Test Connection
              </button>
              <button
                type="button"
                className="btn btn-default btn-sm"
                onClick={onCancel}
              >
                Back
              </button>
              {canDelete ? (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => setDeleteModalOpen(true)}
                  disabled={isSaving}
                >
                  {forceDelete && "Force"} Delete
                </button>
              ) : null}
              {isEditable && isActive ? (
                <button
                  type="submit"
                  className="btn btn-dmp btn-sm"
                  disabled={isSaving}
                >
                  Save
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </Header>
      <OutOfSyncBanner secureComCameraFragment={data} />
      <Grid>
        <MainEdit>
          <InnerHeader>
            <InputContainer>
              <InputLabel>
                Camera Name<span className="text-danger">*</span>
              </InputLabel>
              <input
                type="text"
                name="name"
                className="form-control form-control-300"
                disabled={!isEditable || !isActive}
                required
                value={data.name}
                onChange={(event) => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<SecureComCamera>;
                    camera.setValue(event.target.value, "name");
                  });
                }}
                onBlur={(event) => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<SecureComCamera>;
                    camera.setValue(event.target.value.trim(), "name");
                  });
                }}
              />
            </InputContainer>
            <CameraDetailsContainer>
              <button
                type="button"
                className="btn btn-dmp btn-sm"
                onClick={() => setCameraDetailsModalOpen(true)}
              >
                Camera Details
              </button>
            </CameraDetailsContainer>
          </InnerHeader>
          <MainContainerHeading></MainContainerHeading>
          <MainSettingsContainer>
            <fieldset disabled={!isEditable || !isActive}>
              <MainSettings>
                <SettingsTitles>Options</SettingsTitles>
                {data.videoAnalyticsSupported && (
                  <CameraEditOptions
                    label="Video Analytics"
                    info="Create regions or lines to detect people or vehicles"
                  >
                    <PriceContainer>
                      {isNotNullOrUndefined(videoAnalyticsPrice) &&
                      canViewPricing ? (
                        <div className="feature__price">
                          {" "}
                          {`$${Number(videoAnalyticsPrice).toFixed(2)}`}
                        </div>
                      ) : null}
                      <Switch
                        label="Video Analytics"
                        checked={data.videoAnalyticsEnabled}
                        onChange={() => {
                          relayEnv.commitUpdate((store) => {
                            const camera = store.get(
                              data.id
                            ) as RecordProxy<SecureComCamera>;
                            camera.setValue(
                              !data.videoAnalyticsEnabled,
                              "videoAnalyticsEnabled"
                            );
                          });
                        }}
                      />
                    </PriceContainer>
                  </CameraEditOptions>
                )}
                {videoVerificationEnabled && (
                  <CameraEditOptions
                    label="Available for Monitoring Center Video Verification"
                    info="Enable this camera's stream for Monitoring Center Video Verification"
                  >
                    <Switch
                      label="Available for Monitoring Center Video Verification"
                      checked={data.includeInVerification}
                      onChange={() => {
                        relayEnv.commitUpdate((store) => {
                          const camera = store.get(
                            data.id
                          ) as RecordProxy<SecureComCamera>;
                          camera.setValue(
                            !data.includeInVerification,
                            "includeInVerification"
                          );
                        });
                      }}
                    />
                  </CameraEditOptions>
                )}
                {has8860 && (
                  <CameraEditOptions label="Visible on Keypad">
                    <Switch
                      label="Visible on Keypad"
                      checked={data.keypadVisible}
                      onChange={() => {
                        relayEnv.commitUpdate((store) => {
                          const camera = store.get(
                            data.id
                          ) as RecordProxy<SecureComCamera>;
                          camera.setValue(!data.keypadVisible, "keypadVisible");
                        });
                      }}
                    />
                  </CameraEditOptions>
                )}
                <AdvancedFields defaultOpen>
                  <CameraEditOptions label="Video Quality">
                    <Select
                      value={data.videoQuality}
                      onChange={(event) => {
                        relayEnv.commitUpdate((store) => {
                          const camera = store.get(
                            data.id
                          ) as RecordProxy<SecureComCamera>;
                          camera.setValue(event.target.value, "videoQuality");
                        });
                      }}
                      style={{ width: "auto" }}
                    >
                      <Select.Option key="low" value="low">
                        Low
                      </Select.Option>
                      <Select.Option key="medium" value="medium">
                        Medium
                      </Select.Option>
                      <Select.Option key="high" value="high">
                        High
                      </Select.Option>
                    </Select>
                  </CameraEditOptions>
                  <CameraEditOptions label="Flip Image">
                    <Switch
                      label="Flip Image"
                      checked={data.ceilingMount}
                      onChange={() => {
                        relayEnv.commitUpdate((store) => {
                          const camera = store.get(
                            data.id
                          ) as RecordProxy<SecureComCamera>;
                          camera.setValue(!data.ceilingMount, "ceilingMount");
                        });
                      }}
                    />
                  </CameraEditOptions>
                  {hardwareModel !== "VIDEO_ONLY" && (
                    <CameraEditOptions
                      label="Record on Alarm"
                      info={`Activates recording for${
                        isX1 ? "" : " burglary,"
                      } panic, and fire alarms.`}
                    >
                      <Switch
                        label="Record on Alarm"
                        checked={data.recordOnAlarm}
                        onChange={() => {
                          relayEnv.commitUpdate((store) => {
                            const camera = store.get(
                              data.id
                            ) as RecordProxy<SecureComCamera>;
                            camera.setValue(
                              !data.recordOnAlarm,
                              "recordOnAlarm"
                            );
                          });
                        }}
                      />
                    </CameraEditOptions>
                  )}
                  <CameraEditOptions
                    label="Allow End User Settings"
                    info="Select to allow end users to edit camera settings in Virtual Keypad."
                  >
                    <Switch
                      label="Allow End User Settings"
                      checked={data.allowCustomerEdit}
                      onChange={() => {
                        relayEnv.commitUpdate((store) => {
                          const camera = store.get(
                            data.id
                          ) as RecordProxy<SecureComCamera>;
                          camera.setValue(
                            !data.allowCustomerEdit,
                            "allowCustomerEdit"
                          );
                        });
                      }}
                    />
                  </CameraEditOptions>
                  <CameraEditOptions label="Time Zone">
                    <Select
                      value={data.timeZone}
                      onChange={(event) => {
                        relayEnv.commitUpdate((store) => {
                          const camera = store.get(
                            data.id
                          ) as RecordProxy<SecureComCamera>;
                          camera.setValue(event.target.value, "timeZone");
                        });
                      }}
                      style={{ width: "auto" }}
                    >
                      {timeZones.map((timeZone) => (
                        <Select.Option key={timeZone} value={timeZone}>
                          {timeZone}
                        </Select.Option>
                      ))}
                    </Select>
                  </CameraEditOptions>
                  <CameraEditOptions label="Observe DST">
                    <Switch
                      label="Observe DST"
                      checked={data.daylightSaving}
                      onChange={() => {
                        relayEnv.commitUpdate((store) => {
                          const camera = store.get(
                            data.id
                          ) as RecordProxy<SecureComCamera>;
                          camera.setValue(
                            !data.daylightSaving,
                            "daylightSaving"
                          );
                        });
                      }}
                    />
                  </CameraEditOptions>
                </AdvancedFields>
                <AnalyticsHeader>
                  <SettingsTitles>
                    Regions & Analytics
                    <TagContainer>
                      <Tag variant="success">
                        {`${
                          data.videoAnalyticsEnabled
                            ? availableAnalyticalDetectionRegions +
                              (supportsAnalyticalDetectionLines
                                ? availableAnalyticalDetectionLines
                                : 0)
                            : availableMotionDetectionRegions
                        } available`}
                      </Tag>
                      {data.videoAnalyticsEnabled &&
                        supportsAnalyticalDetectionLines && (
                          <InlineTooltip
                            message={`Line Cross: ${availableAnalyticalDetectionLines}\u000ARegion: ${availableAnalyticalDetectionRegions}`}
                          />
                        )}
                    </TagContainer>
                  </SettingsTitles>
                  <div>
                    {data.videoAnalyticsEnabled &&
                      supportsAnalyticalDetectionLines && (
                        <AddButton
                          type="button"
                          disabled={availableAnalyticalDetectionLines <= 0}
                          onClick={() => handleAddLine()}
                        >
                          <span>Line Cross</span>
                        </AddButton>
                      )}
                    <AddButton
                      type="button"
                      disabled={
                        (data.videoAnalyticsEnabled
                          ? availableAnalyticalDetectionRegions
                          : availableMotionDetectionRegions) <= 0
                      }
                      onClick={
                        data.videoAnalyticsEnabled
                          ? () => handleAddAnalyticalRegion()
                          : () => handleAddRegion()
                      }
                    >
                      <span>Region</span>
                    </AddButton>
                  </div>
                </AnalyticsHeader>
                <div>
                  {data.videoAnalyticsEnabled ? (
                    <>
                      {data.analyticalDetectionLines.lines.map(
                        (line, index) => (
                          <UniviewAnalyticalDetectionLineSettings
                            index={index}
                            data={data}
                            isEditable={isEditable && isActive}
                            analyticalDetectionLine={line}
                          />
                        )
                      )}
                      {data.analyticalDetectionRegions.regions.map(
                        (region, index) => (
                          <UniviewAnalyticalDetectionRegionSettings
                            index={index}
                            data={data}
                            isEditable={isEditable && isActive}
                            analyticalDetectionRegion={region}
                          />
                        )
                      )}
                    </>
                  ) : (
                    data.motionDetectionRegions.regions.map((region, index) => (
                      <UniviewMotionDetectionRegionSettings
                        index={index}
                        data={data}
                        isEditable={isEditable && isActive}
                        motionDetectionRegion={region}
                      />
                    ))
                  )}
                </div>
              </MainSettings>
            </fieldset>
            {!document.getElementById("expo") && (
              <DetectionContainer>
                <SnapshotFrame aspectRatio={4 / 3}>
                  <SnapshotContainer>
                    {data.snapshot === null ||
                    data.status !== VideoDeviceStatus.ACTIVE ? (
                      <RecorderCameraEditVideoNotViewable
                        cameraId={data.id}
                        isEditable={isEditable}
                        isSupervisor={isSupervisor}
                        status={VideoDeviceStatus[data.status]}
                        vernaculars={vernaculars}
                        appName={appName}
                      />
                    ) : (
                      <>
                        <div
                          style={{
                            display:
                              snapshotStatus.loading || snapshotStatus.failed
                                ? "block"
                                : "none",
                          }}
                        >
                          <SnapshotLoadingScreen
                            failed={snapshotStatus.failed}
                            handleRefresh={() => {
                              setSnapshotStatus({
                                loading: true,
                                failed: false,
                              });
                              setImageHash(Date.now());
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display:
                              snapshotStatus.loading || snapshotStatus.failed
                                ? "none"
                                : "block",
                          }}
                        >
                          <Snapshot
                            src={snapShotUrl}
                            onLoad={(event) => {
                              if (event.target instanceof HTMLImageElement) {
                                setSnapshotAspectRatio(
                                  event.target.width / event.target.height
                                );
                              }
                              setSnapshotStatus({
                                loading: false,
                                failed: false,
                              });
                            }}
                          />
                          {data.videoAnalyticsEnabled ? (
                            <UniviewAnalyticalDetectionElements
                              aspectRatio={snapshotAspectRatio}
                              camera={data}
                              isEditable={isEditable}
                            />
                          ) : (
                            <UniviewMotionDetectionRegions
                              aspectRatio={snapshotAspectRatio}
                              camera={data}
                              isEditable={isEditable}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </SnapshotContainer>
                </SnapshotFrame>
              </DetectionContainer>
            )}
          </MainSettingsContainer>
        </MainEdit>
      </Grid>

      {cameraDetailsModalOpen && (
        <CameraDetailsModal
          cameraId={data.id}
          status={data.isOnline}
          cameraName={data.name}
          macAddress={data.macAddress}
          ipAddress={data.ipAddress ?? ""}
          serialNumber={data.serialNumber !== null ? data.serialNumber : ""}
          activationStatus={data.status}
          timeZone={data.timeZone}
          daylightSavingsTime={data.daylightSaving}
          lastCheckIn={data.lastCheckIn}
          wirelessSignalStrength={data.wirelessSignalStrength}
          firmwareVersion={data.firmwareVersion}
          userName={data.adminUsername !== null ? data.adminUsername : ""}
          password={data.adminPassword !== null ? data.adminPassword : ""}
          onFormatSdCard={handleFormatSdCard}
          onCancel={() => setCameraDetailsModalOpen(false)}
          ableToFormatSdCard={canFormatSdCard}
          manufacturer={data.manufacturer}
        />
      )}

      {testConnectionModalOpen && (
        <TestConnectionModal
          cameraId={data.id}
          onCancel={() => setTestConnectionModalOpen(false)}
        />
      )}

      {deleteModalOpen && (
        <DeleteConfirmModal
          actionPending={removingCamera}
          header="Delete Camera Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          pendingText="Deleting..."
          onConfirm={() =>
            removeCamera({
              variables: {
                id: data.id,
                force: forceDelete,
              },
              onCompleted: (result) => {
                if (result.removeSecureComCamera.status === "SUCCESS") {
                  showAlert({
                    type: "success",
                    text: `Successfully removed camera.`,
                  });
                  onCancel();
                } else {
                  setForceDelete(true);
                  showAlert({
                    type: "error",
                    text: `Error removing camera.`,
                  });
                }
                setDeleteModalOpen(false);
              },
              onError: () => {
                setForceDelete(true);
                showAlert({
                  type: "error",
                  text: `Error removing camera.`,
                });
                setDeleteModalOpen(false);
              },
            })
          }
          onCancel={() => {
            setDeleteModalOpen(false);
          }}
        >
          Are you sure you want to delete this Camera?
        </DeleteConfirmModal>
      )}
    </form>
  );
}

export default UniviewCameraEditForm;

const saveMutation = graphql`
  mutation UniviewCameraEditFormSaveMutation($input: SecureComCameraInput!) {
    updateSecureComCamera(secureComCamera: $input) {
      ... on UpdateSecureComCameraSuccessPayload {
        secureComCamera {
          ...UniviewCameraEditForm_secureComCamera
        }
      }
      ... on UpdateSecureComCameraErrorPayload {
        error
        message
      }
    }
  }
`;

const removeCameraMutation = graphql`
  mutation UniviewCameraEditFormRemoveCameraMutation(
    $id: ID!
    $force: Boolean
  ) {
    removeSecureComCamera(id: $id, force: $force) {
      ... on RemoveSecureComCameraSuccessPayload {
        id
        status
      }
      ... on NotFoundError {
        type
      }
    }
  }
`;
