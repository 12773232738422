import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { systemOptionsClosingCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingCodeField";
import { systemOptionsCrossZoneTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import { systemOptionsEnableKeypadPanicKeysFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEnableKeypadPanicKeysField";
import { systemOptionsEntryDelay1FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import { systemOptionsExitDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsExitDelayField";
import { systemOptionsHoursFromGMTFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import { systemOptionsObserveDstFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsObserveDstField";
import { systemOptionsPowerFailDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import { systemOptionsResetSwingerBypassFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import { systemOptionsSwingerBypassTripsFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import { systemOptionsTimeChangeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsTimeChangeField";
import { systemOptionsUseFalseAlarmQuestionFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsUseFalseAlarmQuestionField";
import { systemOptionsWeatherZipCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import { systemOptionsWirelessAudiblesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import { TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/TMSentryFullProgramming/__generated__/TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getSystemOptionsState } from "../../../TMSentryFullProgramming/TMSentrySystemOptionsProgrammingConceptForm";
import { XtProgrammingTemplateSystemOptionsInput } from "../Create/__generated__/TMSentryTemplateSaveMutation.graphql";
import { TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key } from "./__generated__/TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts.graphql";
export const getState = (
  template: TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        systemOptions {
          id
          included
          closingCode {
            included
            data
          }
          entryDelay1 {
            included
            data
          }
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          resetSwingerBypass {
            included
            data
          }
          timeChange {
            included
            data
          }
          observeDst {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          displayTime {
            included
            data
          }
          houseCode {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          enableKeypadPanicKeys {
            included
            data
          }
          useFalseAlarmQuestion {
            included
            data
          }
          weatherZipCode {
            included
            data
          }
          exitDelay {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XtProgrammingTemplateSystemOptionsInput => {
  const {
    panel: { systemOptions },
  } = getSystemOptionsState(controlSystem);
  const systemOptionsInputFields = {
    closingCode: {
      included: includedFields.has(systemOptionsClosingCodeFieldId()),
      data: systemOptions.closingCode,
    },
    crossZoneTime: {
      included: includedFields.has(systemOptionsCrossZoneTimeFieldId()),
      data: systemOptions.crossZoneTime,
    },
    enableKeypadPanicKeys: {
      included: includedFields.has(systemOptionsEnableKeypadPanicKeysFieldId()),
      data: systemOptions.enableKeypadPanicKeys,
    },
    entryDelay1: {
      included: includedFields.has(systemOptionsEntryDelay1FieldId()),
      data: systemOptions.entryDelay1,
    },
    exitDelay: {
      included: includedFields.has(systemOptionsExitDelayFieldId()),
      data: systemOptions.exitDelay,
    },
    hoursFromGMT: {
      included: includedFields.has(systemOptionsHoursFromGMTFieldId()),
      data: systemOptions.hoursFromGMT,
    },
    powerFailDelay: {
      included: includedFields.has(systemOptionsPowerFailDelayFieldId()),
      data: systemOptions.powerFailDelay,
    },
    resetSwingerBypass: {
      included: includedFields.has(systemOptionsResetSwingerBypassFieldId()),
      data: systemOptions.resetSwingerBypass,
    },
    swingerBypassTrips: {
      included: includedFields.has(systemOptionsSwingerBypassTripsFieldId()),
      data: systemOptions.swingerBypassTrips,
    },
    timeChange: {
      included: includedFields.has(systemOptionsTimeChangeFieldId()),
      data: systemOptions.timeChange,
    },
    observeDst: {
      included: includedFields.has(systemOptionsObserveDstFieldId()),
      data: systemOptions.observeDst,
    },
    useFalseAlarmQuestion: {
      included: includedFields.has(systemOptionsUseFalseAlarmQuestionFieldId()),
      data: systemOptions.useFalseAlarmQuestion,
    },
    weatherZipCode: {
      included: includedFields.has(systemOptionsWeatherZipCodeFieldId()),
      data: systemOptions.weatherZipCode,
    },
    wirelessAudibles: {
      included: includedFields.has(systemOptionsWirelessAudiblesFieldId()),
      data: systemOptions.wirelessAudibles,
    },
  };

  const systemOptionsIncluded = Object.values(systemOptionsInputFields).some(
    ({ included }) => included
  );

  return {
    included: systemOptionsIncluded,
    ...systemOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { systemOptions } = getState(template);

  if (!systemOptions) return;

  if (systemOptions.closingCode?.included) {
    setIncludedFields(setToggle(systemOptionsClosingCodeFieldId()));
  }
  if (systemOptions.crossZoneTime?.included) {
    setIncludedFields(setToggle(systemOptionsCrossZoneTimeFieldId()));
  }
  if (systemOptions.enableKeypadPanicKeys?.included) {
    setIncludedFields(setToggle(systemOptionsEnableKeypadPanicKeysFieldId()));
  }
  if (systemOptions.entryDelay1?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay1FieldId()));
  }
  if (systemOptions.exitDelay?.included) {
    setIncludedFields(setToggle(systemOptionsExitDelayFieldId()));
  }
  if (systemOptions.hoursFromGMT?.included) {
    setIncludedFields(setToggle(systemOptionsHoursFromGMTFieldId()));
  }
  if (systemOptions.powerFailDelay?.included) {
    setIncludedFields(setToggle(systemOptionsPowerFailDelayFieldId()));
  }
  if (systemOptions.resetSwingerBypass?.included) {
    setIncludedFields(setToggle(systemOptionsResetSwingerBypassFieldId()));
  }
  if (systemOptions.swingerBypassTrips?.included) {
    setIncludedFields(setToggle(systemOptionsSwingerBypassTripsFieldId()));
  }
  if (systemOptions.timeChange?.included) {
    setIncludedFields(setToggle(systemOptionsTimeChangeFieldId()));
  }
  if (systemOptions.observeDst?.included) {
    setIncludedFields(setToggle(systemOptionsObserveDstFieldId()));
  }
  if (systemOptions.weatherZipCode?.included) {
    setIncludedFields(setToggle(systemOptionsWeatherZipCodeFieldId()));
  }
  if (systemOptions.wirelessAudibles?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessAudiblesFieldId()));
  }
  if (systemOptions.useFalseAlarmQuestion?.included) {
    setIncludedFields(setToggle(systemOptionsUseFalseAlarmQuestionFieldId()));
  }
};
