App.directive("daTempAppUser", [
  "TempAppUserService",
  "$rootScope",
  "$modal",
  "$q",
  function (TempAppUserService, $rootScope, $modal, $q) {
    return {
      restrict: "C",
      controller: function ($scope) {
        $scope.createTempUser = function (systemId, isUserManagement) {
          $scope.openStatusModal(systemId, isUserManagement).then(
            function () {},
            function () {},
            function () {}
          );
        };

        /**
         * Creates and opens a modal to display status messages during first connect
         **/
        $scope.openStatusModal = function (systemId, isUserManagement) {
          const deferred = $q.defer();
          const statusModal = {};
          switch ($rootScope.appProperties.type) {
            case "dealerAdmin":
              $scope.statusModal = $modal.open({
                templateUrl: "app/common/templates/temp-user-modal-tpl.html",
                controller: "DaUserManagementLoginCtrl",
                size: "md",
                backdrop: "static",
                resolve: {
                  systemId: function () {
                    return systemId;
                  },
                  isUserManagement: function () {
                    return isUserManagement ?? false;
                  },
                },
              });
              $scope.statusModal.result.then(
                function (result) {
                  if (result.createdTempUser) {
                    deferred.resolve();
                  } else {
                    deferred.reject();
                  }
                },
                function () {
                  deferred.reject();
                }
              );
              break;
            case "techApp":
              console.warn("daTempAppUser directive does not support TechApp");
              deferred.reject();
              break;
            default:
              console.warn(
                "daTempAppUser directive does not support $rootScope.appProperties.type: " +
                  $rootScope.appProperties.type
              );
              deferred.reject();
              break;
          }
          return deferred.promise;
        };
      },
    };
  },
]);

/**
 * A controller specifically for status while attempting to retrieve programming
 */
App.controller("TempUserModalCtrl", [
  "$interval",
  "$scope",
  "$modalInstance",
  "PROPS",
  "TempAppUserService",
  "UserService",
  "$rootScope",
  "systemId",
  "ControlSystemsService",
  "controlSystemModel",
  "$q",
  "LoginService",
  "AboutMeV2API",
  "GoogleAnalyticsService",
  "RefreshSessionAPI",
  "SplitSessionService",
  "isUserManagement",
  function (
    $interval,
    $scope,
    $modalInstance,
    PROPS,
    TempAppUserService,
    UserService,
    $rootScope,
    systemId,
    ControlSystemsService,
    controlSystemModel,
    $q,
    LoginService,
    AboutMeV2API,
    GoogleAnalyticsService,
    RefreshSessionAPI,
    SplitSessionService,
    isUserManagement
  ) {
    const _this = this;
    $scope.system = {};
    $scope.tempUser = null;
    $scope.UserService = UserService;
    const systemName = UserService.dealerInfo.vernaculars.systems.replacement
      ? ` ${UserService.dealerInfo.vernaculars.systems.replacement}`
      : " systems";
    $scope.props = {
      title: UserService.customerInfo.name + systemName,
      constants: PROPS,
    };
    $scope.group = {
      statuses: {},
      step: 0,
      createJobSuccess: false,
      error: false,
    };
    $scope.isUserManagement = isUserManagement;

    $scope.trackUser = () => {
      GoogleAnalyticsService.trackEvent(
        "control-system",
        "login-as-customer",
        "dealer-login"
      );
    };

    $scope.cancel = () => {
      $interval.cancel(_this.intervalWorker);
      $modalInstance.dismiss("cancel");
    };

    const openVkb = (token, isUserManagement) => {
      isUserManagement = isUserManagement ?? false;
      const baseUrl =
        "https://security-command-master-dev.staging.virtualkeypad.com";

      if (isUserManagement) {
        $scope.url = `${baseUrl}/admin/users?refreshToken=${token}${
          UserService.customer_id ? "&id=" + UserService.customer_id : ""
        }`;
      } else {
        $scope.url = `${baseUrl}/systems/?refreshToken=${token}`;
      }
      window.open($scope.url, "_blank");
    };

    // Function to refresh the token

    async function refreshLogin() {
      try {
        const deferred = $q.defer();
        const refreshAPI = RefreshSessionAPI;
        $rootScope.refreshPromise = refreshAPI.refresh(
          {},
          angular.toJson({
            refresh_token: UserService.refresh_token,
          }),
          function (data) {
            try {
              UserService.login(data.email, data.jwt, data.refresh_token);
            } catch (e) {
              deferred.reject(e);
            }
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.logout();
            $q.reject(error);
          }
        );
        return deferred.promise;
      } catch (e) {
        return $q.reject(e);
      }
    }

    $scope.close = () => {
      $interval.cancel(_this.intervalWorker);
      $modalInstance.close({
        createdTempUser: $scope.group.createJobSuccess,
      });
    };

    const successfulJobStatuses = ["completed", "success"];
    const inProgressJobStatuses = [
      "new",
      "created",
      "acquired",
      "started",
      "running",
    ];
    const jobCompleted = (status) => !inProgressJobStatuses.includes(status);

    const didJobSucceed = (status) => successfulJobStatuses.includes(status);

    function init() {
      $scope.group.step = 1;
      $scope.tempUserID = 0;
      getDesiredSystem().then(
        (system) => {
          $scope.system = system;
          TempAppUserService.create(system).then(
            (SchedulerJobGroup) => {
              const { Id } = SchedulerJobGroup;
              const salt = Date.now();
              startJobMonitor(Id, salt);
              $rootScope.$on(`TempAppUserCreated-${Id}-${salt}`, function () {
                refreshLogin().then(
                  /**
                   *  find the user in the  refreshData.sso_users array where id =
                   *  $scope.group.statuses.DestroyJobData.TempAppUserId and store object as tempUser
                   */
                  function (response) {
                    if (response) {
                      const refreshData = response;

                      const tempUser = refreshData.sso_users.find(
                        (user) =>
                          user.id ===
                          $scope.group.statuses.DestroyJobData.TempAppUserId
                      );
                      if (tempUser) {
                        // Call `getSplitSession` with the found user ID and token
                        SplitSessionService.getSplitSession(
                          tempUser.id,
                          tempUser.jwt
                        ).then((data) => {
                          const { refresh_token } = data;
                          openVkb(
                            refresh_token,
                            $scope.system.control_system_id,
                            $scope.isUserManagement
                          );
                          $scope.close();
                        });
                      }
                    }
                  }
                );
              });
            },
            () => {
              errorCloseModal();
            }
          );
        },
        () => {
          errorCloseModal();
        }
      );
    }

    const getDesiredSystem = () => {
      const deferred = $q.defer();
      if (+systemId > 0) {
        const system = new controlSystemModel({
          customer_id: UserService.customer_id,
          control_system_id: +systemId,
        });
        system.get().then(
          (data) => {
            deferred.resolve(data);
          },
          (error) => {
            console.error(
              "DaUserManagementLoginCtrl->getDesiredSystem() error: " +
                angular.toJson(error)
            );
            deferred.reject();
          }
        );
      } else {
        deferred.resolve(ControlSystemsService.currentControlSystem);
      }
      return deferred.promise;
    };

    function errorCloseModal() {
      $rootScope.alerts.push({
        type: "error",
        text: `authority to log in as customer has not been approved. please try again.`,
      });
      $scope.close();
    }

    /**
     * Starts interval function that checks Initial Connection job group status,
     * updating scoped data for display in the initial connection modal
     */
    var startJobMonitor = function (groupId, salt) {
      $scope.group.step = 2;
      $scope.group.refreshing = true;
      _this.intervalWorker = $interval(function () {
        TempAppUserService.getStatus(groupId).then(
          function (groupData) {
            $scope.group.statuses = groupData;
            if (jobCompleted(groupData.CreateJobStatus)) {
              $scope.group.step = 3;
              $interval.cancel(_this.intervalWorker);
              $scope.group.refreshing = false;
              $scope.group.createJobSuccess = didJobSucceed(
                $scope.group.statuses.CreateJobStatus
              );

              $rootScope.$broadcast(`TempAppUserCreated-${groupId}-${salt}`);
              $rootScope.$broadcast(`TempAppUserCreatedRefreshCustomerList`);
            }
          },
          function () {
            failTempUser();
          }
        );
      }, PROPS.initialConnectionWatcherInterval);
    };

    function failTempUser() {
      $scope.group.step = 3;
      $interval.cancel(_this.intervalWorker);
      $scope.group.refreshing = false;
      $scope.group.error = true;
    }

    init();
  },
]);
