App.controller("SystemsWithEarlyToClose", [
  "$scope",
  "$q",
  "$rootScope",
  "UserService",
  "DashboardDataService",
  "DTColumnBuilder",
  "DataTablesUtilsService",
  function (
    $scope,
    $q,
    $rootScope,
    UserService,
    DashboardDataService,
    DTColumnBuilder,
    DataTablesUtilsService
  ) {
    $scope.UserService = UserService;
    $scope.dealerID = UserService.dealer_id;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(
      systemsWithEarlyToCloseEvents()
    );
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    $scope.dtOptions.withOption("order", [0, "asc"], [1, "asc"], [2, "asc"]);

    function systemsWithEarlyToCloseEvents() {
      //the early to close event id is 37
      const eventId = "37";
      var deferred = $q.defer();
      DashboardDataService.getSystemsWithEventSubCategoryId(
        $scope.dealerID,
        eventId
      )
        .then(
          function (systems) {
            deferred.resolve(systems);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error getting systems",
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    const customerReplacement =
      UserService.dealerInfo.vernaculars.customers.replacement || "Customer";
    const systemReplacement =
      UserService.dealerInfo.vernaculars.systems.replacement || "System";

    $scope.dtColumns = [
      DTColumnBuilder.newColumn(null)
        .withTitle(`${customerReplacement} Name`)
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "customer_name");
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle(`${systemReplacement} Type`)
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(
            row,
            "panel_hardware_model"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle(`${systemReplacement} Name`)
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(
            row,
            "control_system_name"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Message")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(
            row,
            "event_history_message"
          );
        }),
      DTColumnBuilder.newColumn("event_event_at")
        .withTitle("Date of Event")
        .renderWith(DataTablesUtilsService.asDateTimeRenderFn()),
    ];
  },
]);
