import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions.graphql";

export const remoteOptionsIntegratorOutgoingTcpPortFieldId = () =>
  "remote-options-integrator-outgoing-tcp-port";

function RemoteOptionsIntegratorOutgoingTcpPortField() {
  const [
    { integratorOutgoingPort, integratorConnection },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorOutgoingPort
          integratorConnection
        }
        ... on Xt75RemoteOptions {
          integratorOutgoingPort
          integratorConnection
        }
        ... on XfRemoteOptions {
          integratorOutgoingPort
          integratorConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorOutgoingTcpPortFieldId();
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Integrator Outbound TCP Port" //Note that this is outbound in the label text.  This is not a typo, this matches the panel keypad verbiage
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        value={integratorOutgoingPort ?? ""}
        maxLength={16}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        inlineHelp="1-65535"
        min={1}
        max={65535}
        validationMessage="Valid values are 1-65535."
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "integratorOutgoingPort");
          });
        }}
        onBlur={({ target }) => {
          const value = clamp(1, 65535, Number(target.value));
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(
              String(value).padStart(5, `0`),
              "integratorOutgoingPort"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorOutgoingTcpPortField;
