/**
 * @generated SignedSource<<3f6cc57d972e38b4387a750273ee689b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VideoDeviceStatus = "ACTIVATING" | "ACTIVE" | "ERROR" | "INACCESSIBLE";
export type VideoDeviceType = "BRIDGE" | "CAMERA" | "DVR" | "NVR";
export type AddToVarHubEnabledCamerasListInput = {
  addCamectCameraId?: ReadonlyArray<string | null> | null;
};
export type CameraListItemAddToVarHubEnabledCameraListMutation$variables = {
  addition: AddToVarHubEnabledCamerasListInput;
  camectCameraId?: string | null;
  hubId: string;
  isScapiCamera: boolean;
  macAddress?: string | null;
  scapiCameraId?: string | null;
  systemId: string;
};
export type CameraListItemAddToVarHubEnabledCameraListMutation$data = {
  readonly addToVarHubEnabledCamerasList: {
    readonly __typename: "AddToVarHubEnabledCamerasListErrorPayload";
    readonly message: string | null;
    readonly type: string | null;
  } | {
    readonly __typename: "AddToVarHubEnabledCamerasListSuccessPayload";
    readonly system: {
      readonly secureComCameras: ReadonlyArray<{
        readonly deviceType: VideoDeviceType;
        readonly formFactor: string | null;
        readonly id: string;
        readonly imageSize: string | null;
        readonly isOnline: boolean;
        readonly lastCheckIn: string | null;
        readonly macAddress: string;
        readonly manufacturer: string;
        readonly model: string;
        readonly name: string;
        readonly networkReady: boolean | null;
        readonly status: VideoDeviceStatus;
      }>;
      readonly varHubs: ReadonlyArray<{
        readonly camectHubId: string;
        readonly cameras: ReadonlyArray<{
          readonly addedToDB: boolean;
          readonly camectCamId: string;
          readonly camectHubId: string;
          readonly cameraId: number | null;
          readonly cameraName: string;
          readonly framesPerSecond: number;
          readonly ipAddress: string;
          readonly isEnabled: boolean;
          readonly isHidden: boolean;
          readonly isScapiCamera: boolean;
          readonly isStreaming: boolean;
          readonly macAddress: string;
          readonly megapixels: number;
          readonly needsCredential: boolean;
          readonly playerAuthToken: string;
          readonly playerUrl: string;
          readonly rtspUrl: string | null;
          readonly scapiCameraId: number | null;
          readonly videoCodec: string;
        }>;
        readonly hubId: number;
      }>;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CameraListItemAddToVarHubEnabledCameraListMutation = {
  response: CameraListItemAddToVarHubEnabledCameraListMutation$data;
  variables: CameraListItemAddToVarHubEnabledCameraListMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "camectCameraId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hubId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isScapiCamera"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "macAddress"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scapiCameraId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "addition",
    "variableName": "addition"
  },
  {
    "kind": "Variable",
    "name": "camectCameraId",
    "variableName": "camectCameraId"
  },
  {
    "kind": "Variable",
    "name": "hubId",
    "variableName": "hubId"
  },
  {
    "kind": "Variable",
    "name": "isScapiCamera",
    "variableName": "isScapiCamera"
  },
  {
    "kind": "Variable",
    "name": "macAddress",
    "variableName": "macAddress"
  },
  {
    "kind": "Variable",
    "name": "scapiCameraId",
    "variableName": "scapiCameraId"
  },
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectHubId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addedToDB",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectCamId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cameraId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cameraName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ipAddress",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEnabled",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStreaming",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isScapiCamera",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "macAddress",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsCredential",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playerAuthToken",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playerUrl",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rtspUrl",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoCodec",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "megapixels",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "framesPerSecond",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHidden",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scapiCameraId",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "SecureComCamera",
  "kind": "LinkedField",
  "name": "secureComCameras",
  "plural": true,
  "selections": [
    (v28/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastCheckIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageSize",
      "storageKey": null
    },
    (v18/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "networkReady",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manufacturer",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CameraListItemAddToVarHubEnabledCameraListMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addToVarHubEnabledCamerasList",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHub",
                    "kind": "LinkedField",
                    "name": "varHubs",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VarHubCamera",
                        "kind": "LinkedField",
                        "name": "cameras",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v8/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          (v27/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v29/*: any*/)
                ],
                "storageKey": null
              },
              (v30/*: any*/)
            ],
            "type": "AddToVarHubEnabledCamerasListSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v31/*: any*/),
              (v32/*: any*/),
              (v30/*: any*/)
            ],
            "type": "AddToVarHubEnabledCamerasListErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CameraListItemAddToVarHubEnabledCameraListMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addToVarHubEnabledCamerasList",
        "plural": false,
        "selections": [
          (v30/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHub",
                    "kind": "LinkedField",
                    "name": "varHubs",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VarHubCamera",
                        "kind": "LinkedField",
                        "name": "cameras",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v8/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          (v27/*: any*/),
                          (v28/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v28/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v29/*: any*/),
                  (v28/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "AddToVarHubEnabledCamerasListSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v31/*: any*/),
              (v32/*: any*/)
            ],
            "type": "AddToVarHubEnabledCamerasListErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95518adc3e227407542cd46e98a1cc22",
    "id": null,
    "metadata": {},
    "name": "CameraListItemAddToVarHubEnabledCameraListMutation",
    "operationKind": "mutation",
    "text": "mutation CameraListItemAddToVarHubEnabledCameraListMutation(\n  $systemId: String!\n  $hubId: String!\n  $addition: AddToVarHubEnabledCamerasListInput!\n  $isScapiCamera: Boolean!\n  $scapiCameraId: String\n  $macAddress: String\n  $camectCameraId: String\n) {\n  addToVarHubEnabledCamerasList(hubId: $hubId, systemId: $systemId, addition: $addition, isScapiCamera: $isScapiCamera, scapiCameraId: $scapiCameraId, macAddress: $macAddress, camectCameraId: $camectCameraId) {\n    __typename\n    ... on AddToVarHubEnabledCamerasListSuccessPayload {\n      system {\n        varHubs {\n          camectHubId\n          hubId\n          cameras {\n            addedToDB\n            camectCamId\n            camectHubId\n            cameraId\n            cameraName\n            ipAddress\n            isEnabled\n            isStreaming\n            isScapiCamera\n            macAddress\n            needsCredential\n            playerAuthToken\n            playerUrl\n            rtspUrl\n            videoCodec\n            megapixels\n            framesPerSecond\n            isHidden\n            scapiCameraId\n            id\n          }\n          id\n        }\n        secureComCameras {\n          id\n          deviceType\n          name\n          lastCheckIn\n          isOnline\n          status\n          model\n          formFactor\n          imageSize\n          macAddress\n          networkReady\n          manufacturer\n        }\n        id\n      }\n      __typename\n    }\n    ... on AddToVarHubEnabledCamerasListErrorPayload {\n      type\n      message\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1152530e8fd2247f91114ef31b19f567";

export default node;
