/**
 * @generated SignedSource<<d551182b07c2db52f28adb0c3d2bd5bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveFromVarHubEnabledCamerasListInput = {
  removeCamectCameraId?: ReadonlyArray<string | null> | null;
};
export type CameraListItemRemoveFromVarHubEnabledCameraListMutation$variables = {
  hubId: string;
  removal: RemoveFromVarHubEnabledCamerasListInput;
  systemId: string;
};
export type CameraListItemRemoveFromVarHubEnabledCameraListMutation$data = {
  readonly removeFromVarHubEnabledCamerasList: {
    readonly __typename: "RemoveFromVarHubEnabledCamerasListErrorPayload";
    readonly message: string | null;
    readonly type: string | null;
  } | {
    readonly __typename: "RemoveFromVarHubEnabledCamerasListSuccessPayload";
    readonly hubs: ReadonlyArray<{
      readonly camectHubId: string;
      readonly cameras: ReadonlyArray<{
        readonly addedToDB: boolean;
        readonly camectCamId: string;
        readonly camectHubId: string;
        readonly cameraId: number | null;
        readonly cameraName: string;
        readonly framesPerSecond: number;
        readonly ipAddress: string;
        readonly isEnabled: boolean;
        readonly isHidden: boolean;
        readonly isScapiCamera: boolean;
        readonly isStreaming: boolean;
        readonly macAddress: string;
        readonly megapixels: number;
        readonly needsCredential: boolean;
        readonly playerAuthToken: string;
        readonly playerUrl: string;
        readonly rtspUrl: string | null;
        readonly scapiCameraId: number | null;
        readonly videoCodec: string;
      }>;
      readonly hubId: number;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CameraListItemRemoveFromVarHubEnabledCameraListMutation = {
  response: CameraListItemRemoveFromVarHubEnabledCameraListMutation$data;
  variables: CameraListItemRemoveFromVarHubEnabledCameraListMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removal"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "hubId",
    "variableName": "hubId"
  },
  {
    "kind": "Variable",
    "name": "removal",
    "variableName": "removal"
  },
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectHubId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addedToDB",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectCamId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cameraId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cameraName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ipAddress",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEnabled",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStreaming",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isScapiCamera",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "macAddress",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsCredential",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playerAuthToken",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playerUrl",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rtspUrl",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoCodec",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "megapixels",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "framesPerSecond",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHidden",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scapiCameraId",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CameraListItemRemoveFromVarHubEnabledCameraListMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "removeFromVarHubEnabledCamerasList",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "hubs",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHubCamera",
                    "kind": "LinkedField",
                    "name": "cameras",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v24/*: any*/)
            ],
            "type": "RemoveFromVarHubEnabledCamerasListSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v25/*: any*/),
              (v26/*: any*/),
              (v24/*: any*/)
            ],
            "type": "RemoveFromVarHubEnabledCamerasListErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CameraListItemRemoveFromVarHubEnabledCameraListMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "removeFromVarHubEnabledCamerasList",
        "plural": false,
        "selections": [
          (v24/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "hubs",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHubCamera",
                    "kind": "LinkedField",
                    "name": "cameras",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v27/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v27/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "RemoveFromVarHubEnabledCamerasListSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v25/*: any*/),
              (v26/*: any*/)
            ],
            "type": "RemoveFromVarHubEnabledCamerasListErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f111b8ee1b625c137d7676fbb63ac833",
    "id": null,
    "metadata": {},
    "name": "CameraListItemRemoveFromVarHubEnabledCameraListMutation",
    "operationKind": "mutation",
    "text": "mutation CameraListItemRemoveFromVarHubEnabledCameraListMutation(\n  $systemId: String!\n  $hubId: String!\n  $removal: RemoveFromVarHubEnabledCamerasListInput!\n) {\n  removeFromVarHubEnabledCamerasList(hubId: $hubId, systemId: $systemId, removal: $removal) {\n    __typename\n    ... on RemoveFromVarHubEnabledCamerasListSuccessPayload {\n      hubs {\n        camectHubId\n        hubId\n        cameras {\n          addedToDB\n          camectCamId\n          camectHubId\n          cameraId\n          cameraName\n          ipAddress\n          isEnabled\n          isStreaming\n          isScapiCamera\n          macAddress\n          needsCredential\n          playerAuthToken\n          playerUrl\n          rtspUrl\n          videoCodec\n          megapixels\n          framesPerSecond\n          isHidden\n          scapiCameraId\n          id\n        }\n        id\n      }\n      __typename\n    }\n    ... on RemoveFromVarHubEnabledCamerasListErrorPayload {\n      type\n      message\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b9f8272b167d42ae515d8d75a5b0242";

export default node;
