/**
 * @generated SignedSource<<e93b7917ec858862c5468423ed8f51ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ConnectionStatus = "OFFLINE" | "ONLINE";
export type ControlSystemCommType = "CELL" | "NETWORK" | "PERSISTENT" | "PERSISTENT_WITH_CELL_BACKUP" | "UNKNOWN";
export type InitialConnectionStatus = "COMPLETE" | "NONE" | "PENDING";
export type OsdpOrWiegand = "OSDP" | "WIEGAND";
export type SiteControlSystemType = "DOOR_ACCESS" | "ELEVATOR";
export type SiteOutputIntendedUsage = "ALARM" | "GENERIC";
export type SiteOutputModuleIntendedUsage = "FLOORS" | "OUTPUTS";
export type SiteOutputType = "FLOORS" | "OUTPUTS";
export type X1Type = "X1" | "X8";
import { FragmentRefs } from "relay-runtime";
export type ExistingSiteControlSystemForm_siteControlSystem$data = {
  readonly alarmPanelIntegrationOutput: {
    readonly id: string;
    readonly number: number;
  } | null;
  readonly availableOutputModulesCount: number;
  readonly cellStatus: {
    readonly status: ConnectionStatus;
  } | null;
  readonly commType: ControlSystemCommType;
  readonly doors: ReadonlyArray<{
    readonly active: boolean;
    readonly buzzer: boolean;
    readonly doorForce: boolean;
    readonly doorPropTime: number;
    readonly doorSensor: boolean;
    readonly fireExit: boolean;
    readonly fireZone: boolean;
    readonly id: string;
    readonly includeInLockdown: boolean;
    readonly led: boolean;
    readonly name: string;
    readonly number: number;
    readonly osdpOrWiegand: OsdpOrWiegand;
    readonly requestToExit: boolean;
    readonly strikeDelay: number;
    readonly strikeTime: number;
    readonly unlockOnRex: boolean;
  }>;
  readonly easyConnectStatus: {
    readonly status: ConnectionStatus;
  } | null;
  readonly firmwareUpdateDismissed: boolean | null;
  readonly hasCell: boolean;
  readonly hasEasyConnect: boolean;
  readonly hasFailedPreProgrammingJob: boolean;
  readonly hasPendingChanges: boolean;
  readonly hasReplaceX1Backup: boolean;
  readonly id: string;
  readonly initialConnectionStatus: InitialConnectionStatus;
  readonly intendedUsage: SiteControlSystemType;
  readonly isBillingControlSystem: boolean;
  readonly maxOutputModules: number;
  readonly name: string;
  readonly onboardOutput1: {
    readonly id: string;
    readonly isAvailableForAlarmIntegration: boolean;
    readonly name: string;
    readonly outputIntendedUsage: SiteOutputIntendedUsage;
  } | null;
  readonly onboardOutput2: {
    readonly id: string;
    readonly isAvailableForAlarmIntegration: boolean;
    readonly name: string;
    readonly outputIntendedUsage: SiteOutputIntendedUsage;
  } | null;
  readonly online: boolean;
  readonly outputModulesConnection: {
    readonly nodes: ReadonlyArray<{
      readonly address: number;
      readonly id: string;
      readonly intendedUsage: SiteOutputModuleIntendedUsage;
      readonly name: string;
      readonly outputsConnection: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly relayNumber: number | null;
          readonly type: SiteOutputType;
        }>;
        readonly totalCount: number;
      };
    }>;
    readonly totalCount: number;
  };
  readonly replacingPanelJobGroupId: number | null;
  readonly serialNumber: string;
  readonly site: {
    readonly alarmPanelX1Integration: boolean;
    readonly armablePanelSerialNumber: string | null;
    readonly customer: {
      readonly dealer: {
        readonly securityCommand: boolean | null;
        readonly vernaculars: ReadonlyArray<{
          readonly original: string;
          readonly replacement: string;
        } | null>;
      };
    };
    readonly firmwareUpdateDismissed: boolean | null;
    readonly hasFireZone: boolean;
    readonly id: string;
  } | null;
  readonly softwareVersion: string;
  readonly type: X1Type;
  readonly " $fragmentSpreads": FragmentRefs<"SiteControlSystemJobStatusBadge_siteControlSystem">;
  readonly " $fragmentType": "ExistingSiteControlSystemForm_siteControlSystem";
};
export type ExistingSiteControlSystemForm_siteControlSystem$key = {
  readonly " $data"?: ExistingSiteControlSystemForm_siteControlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExistingSiteControlSystemForm_siteControlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "intendedUsage",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v7 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firmwareUpdateDismissed",
      "storageKey": null
    }
  ]
},
v8 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "outputIntendedUsage",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isAvailableForAlarmIntegration",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ExistingSiteControlSystemFormRefetch_siteControlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ExistingSiteControlSystemForm_siteControlSystem",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "softwareVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initialConnectionStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBillingControlSystem",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxOutputModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableOutputModulesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEasyConnect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemCellStatus",
      "kind": "LinkedField",
      "name": "cellStatus",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemEasyConnectStatus",
      "kind": "LinkedField",
      "name": "easyConnectStatus",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteOutputModulesConnection",
      "kind": "LinkedField",
      "name": "outputModulesConnection",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteOutputModule",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteOutputModuleOutputsConnection",
              "kind": "LinkedField",
              "name": "outputsConnection",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SiteOutput",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "relayNumber",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeUnprogrammedDoors",
          "value": true
        }
      ],
      "concreteType": "SiteDoor",
      "kind": "LinkedField",
      "name": "doors",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "strikeTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "strikeDelay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fireZone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fireExit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeInLockdown",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorPropTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "osdpOrWiegand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSensor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorForce",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requestToExit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unlockOnRex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "buzzer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "led",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": "doors(includeUnprogrammedDoors:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasFireZone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "alarmPanelX1Integration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "armablePanelSerialNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Customer",
          "kind": "LinkedField",
          "name": "customer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Dealer",
              "kind": "LinkedField",
              "name": "dealer",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Vernacular",
                  "kind": "LinkedField",
                  "name": "vernaculars",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "original",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "replacement",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "securityCommand",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteOutput",
      "kind": "LinkedField",
      "name": "alarmPanelIntegrationOutput",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteOutput",
      "kind": "LinkedField",
      "name": "onboardOutput1",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteOutput",
      "kind": "LinkedField",
      "name": "onboardOutput2",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPendingChanges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasFailedPreProgrammingJob",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "replacingPanelJobGroupId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasReplaceX1Backup",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteControlSystemJobStatusBadge_siteControlSystem"
    },
    (v7/*: any*/)
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "9808c476e14cb0e90f35e5aa68bdcc15";

export default node;
