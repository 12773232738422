import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import AdvancedFields from "components/AdvancedFields";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, RemoteOptions } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import RemoteOptionsApnField from "../common/RemoteOptionsFields/RemoteOptionsApnField";
import RemoteOptionsAppKeyField from "../common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import { RemoteOptionsContextProvider } from "../common/RemoteOptionsFields/RemoteOptionsContext";
import RemoteOptionsIntegratorArmDisarmReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorArmDisarmReportsField";
import RemoteOptionsIntegratorBackupConnectionField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorBackupConnectionField";
import RemoteOptionsIntegratorBackupIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorBackupIpAddressField";
import RemoteOptionsIntegratorBackupTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorBackupTcpPortField";
import RemoteOptionsIntegratorConnectionField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorConnectionField";
import RemoteOptionsIntegratorDoorReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorDoorReportsField";
import RemoteOptionsIntegratorIncomingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorIncomingTcpPortField";
import RemoteOptionsIntegratorIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorIpAddressField";
import RemoteOptionsIntegratorOutgoingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorOutgoingTcpPortField";
import RemoteOptionsIntegratorPassphraseField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorPassphraseField";
import RemoteOptionsIntegratorSupervisoryReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorSupervisoryReportsField";
import RemoteOptionsIntegratorUserCommandReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorUserCommandReportsField";
import RemoteOptionsIntegratorVideoReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorVideoReportsField";
import RemoteOptionsIntegratorZoneReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorZoneReportsField";
import RemoteOptionsRemoteDisarmField from "../common/RemoteOptionsFields/RemoteOptionsRemoteDisarmField";
import { panelVersionGTOE } from "../utils/panel";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$data,
  XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XT75RemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XT75RemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation,
} from "./__generated__/XT75RemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation.graphql";
import {
  XT75RemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation,
  XT75RemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation$data,
} from "./__generated__/XT75RemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation.graphql";
import { XT75RemoteOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XT75RemoteOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Remote Options";
export const conceptId = "xt75-remote-options";

export const getState = (
  controlSystem: XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XT75RemoteOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          remoteOptions {
            ... on Xt75RemoteOptions {
              __typename
              id
              remoteDisarm
              appKey
              firstApn
              integratorConnection
              integratorIncomingPort
              integratorIpAddress
              integratorOutgoingPort
              integratorBackupConnection
              integratorBackupIpAddress
              integratorBackupIncomingPort
              integratorArmDisarmReports
              integratorZoneReports
              integratorUserCommandReports
              integratorDoorReports
              integratorSupervisoryReports
              integratorPassphrase
              integratorVideoReports
            }
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XT75RemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshRemoteOptions(id: $id) {
      ... on RefreshRemoteOptionsSuccessPayload {
        __typename
        controlSystem {
          ...XT75RemoteOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshRemoteOptions, isRefreshing] =
    useMutation<XT75RemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshRemoteOptions({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshRemoteOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Remote Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshRemoteOptions: {
                    __typename: response.refreshRemoteOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Remote Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Remote Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XT75RemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation(
    $systemId: ID!
    $remoteOptions: Xt75RemoteOptionsInput!
  ) {
    sendXt75RemoteOptionsProgramming(
      systemId: $systemId
      remoteOptions: $remoteOptions
    ) {
      ... on SendRemoteOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          __typename
          id
          ...XT75RemoteOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendRemoteOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XT75RemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation$data,
  originalControlSystemData: XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendXt75RemoteOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshRemoteOptions: {
          __typename: "RefreshRemoteOptionsSuccessPayload",
          controlSystem: getState(
            response.sendXt75RemoteOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendRemoteOptions, isSending] =
    useMutation<XT75RemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation>(
      sendMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { remoteOptions },
        } = getState(props.controlSystem);
        if (remoteOptions?.__typename === "Xt75RemoteOptions") {
          sendRemoteOptions({
            variables: {
              systemId,
              remoteOptions: {
                remoteDisarm: remoteOptions.remoteDisarm,
                appKey: remoteOptions.appKey,
                firstApn: remoteOptions.firstApn,
                integratorConnection: remoteOptions.integratorConnection,
                integratorIncomingPort: remoteOptions.integratorIncomingPort,
                integratorIpAddress: remoteOptions.integratorIpAddress,
                integratorOutgoingPort: remoteOptions.integratorOutgoingPort,
                integratorBackupConnection:
                  remoteOptions.integratorBackupConnection,
                integratorBackupIpAddress:
                  remoteOptions.integratorBackupIpAddress,
                integratorBackupIncomingPort:
                  remoteOptions.integratorBackupIncomingPort,
                integratorArmDisarmReports:
                  remoteOptions.integratorArmDisarmReports,
                integratorZoneReports: remoteOptions.integratorZoneReports,
                integratorUserCommandReports:
                  remoteOptions.integratorUserCommandReports,
                integratorDoorReports: remoteOptions.integratorDoorReports,
                integratorSupervisoryReports:
                  remoteOptions.integratorSupervisoryReports,
                integratorPassphrase: remoteOptions.integratorPassphrase,
                integratorVideoReports: remoteOptions.integratorVideoReports,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendXt75RemoteOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendXt75RemoteOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendXt75RemoteOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSending,
  ];
};

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

const readRemoteOptionsTemplateData = (
  programmingTemplateConcepts: XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          included
          remoteDisarm {
            included
            data
          }
          appKey {
            included
            data
          }
          firstApn {
            included
            data
          }
          integratorConnection {
            included
            data
          }
          integratorIncomingPort {
            included
            data
          }
          integratorIpAddress {
            included
            data
          }
          integratorOutgoingPort {
            included
            data
          }
          integratorBackupConnection {
            included
            data
          }
          integratorBackupIpAddress {
            included
            data
          }
          integratorBackupIncomingPort {
            included
            data
          }
          integratorArmDisarmReports {
            included
            data
          }
          integratorZoneReports {
            included
            data
          }
          integratorUserCommandReports {
            included
            data
          }
          integratorDoorReports {
            included
            data
          }
          integratorSupervisoryReports {
            included
            data
          }
          integratorPassphrase {
            included
            data
          }
          integratorVideoReports {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).remoteOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readRemoteOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const remoteOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "remoteOptions",
      "RemoteOptions"
    ) as unknown as RecordProxy<RemoteOptions>;

    applyTemplateScalarDataToRecordProxy(
      remoteOptionsRecordProxy,
      templateData
    );
  }
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XT75RemoteOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XT75RemoteOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            remoteOptions {
              __typename
              ...RemoteOptionsContext_remoteOptions
              ...RemoteOptionsRemoteDisarmField_remoteOptions
              ...RemoteOptionsAppKeyField_remoteOptions
              ...RemoteOptionsApnField_remoteOptions
              # Integrator Section
              ...RemoteOptionsIntegratorConnectionField_remoteOptions
              ...RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions
              ...RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions
              ...RemoteOptionsIntegratorIpAddressField_remoteOptions
              ...RemoteOptionsIntegratorBackupConnectionField_remoteOptions
              ...RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions
              ...RemoteOptionsIntegratorZoneReportsField_remoteOptions
              ...RemoteOptionsIntegratorUserCommandReportsField_remoteOptions
              ...RemoteOptionsIntegratorDoorReportsField_remoteOptions
              ...RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions
              ...RemoteOptionsIntegratorPassphraseField_remoteOptions
              ...RemoteOptionsIntegratorVideoReportsField_remoteOptions
              ...RemoteOptionsIntegratorBackupIpAddressField_remoteOptions
              ...RemoteOptionsIntegratorBackupTcpPortField_remoteOptions
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const {
    panel: {
      remoteOptions,
      softwareVersion,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <RemoteOptionsContextProvider
        remoteOptions={controlSystem.panel.remoteOptions}
      >
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Remote%20Options`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(remoteOptions)}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            {remoteOptions?.__typename === "Xt75RemoteOptions" ? (
              <>
                <ProgrammingConceptForm.Fields>
                  <RemoteOptionsRemoteDisarmField />
                  <RemoteOptionsApnField />
                  <RemoteOptionsAppKeyField />
                </ProgrammingConceptForm.Fields>
                {panelVersionGTOE(252, softwareVersion) && (
                  <AdvancedFields label="Integrator">
                    <RemoteOptionsIntegratorConnectionField />
                    <RemoteOptionsIntegratorIncomingTcpPortField />
                    <RemoteOptionsIntegratorIpAddressField />
                    <RemoteOptionsIntegratorOutgoingTcpPortField />
                    <RemoteOptionsIntegratorBackupConnectionField />
                    <RemoteOptionsIntegratorBackupIpAddressField />
                    <RemoteOptionsIntegratorBackupTcpPortField />
                    <RemoteOptionsIntegratorArmDisarmReportsField />
                    <RemoteOptionsIntegratorZoneReportsField />
                    <RemoteOptionsIntegratorUserCommandReportsField />
                    <RemoteOptionsIntegratorDoorReportsField />
                    <RemoteOptionsIntegratorSupervisoryReportsField />
                    <RemoteOptionsIntegratorPassphraseField />
                    <RemoteOptionsIntegratorVideoReportsField />
                  </AdvancedFields>
                )}
              </>
            ) : null}
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </RemoteOptionsContextProvider>
    </PanelContextProvider>
  );
}
