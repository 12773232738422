import Flex from "common/components/web/Flex";
import {
  FieldLabelCell,
  FieldLabelContainer,
  FieldLabelText,
  FieldValueCell,
  RegionSettingsDeleteButton,
  RegionSettingsHeaderText,
  RegionsSettingsRoot,
  RegionsSettingsRow,
  RegionsSettingsTable,
  SensitivityValue,
} from "components/CameraEditCommon/CameraEditStyledComponents";
import Switch from "components/FullProgramming/common/Switch";
import Icon from "components/Icon";
import Select from "components/Select";
import React from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  AnalyticalDetectionLine,
  SecureComCamera,
} from "securecom-graphql/client";
import { UniviewCameraEditForm_secureComCamera$data } from "./__generated__/UniviewCameraEditForm_secureComCamera.graphql";

interface UniviewAnalyticalDetectionLineSettingsProps {
  index: number;
  data: UniviewCameraEditForm_secureComCamera$data;
  isEditable: boolean;
  analyticalDetectionLine: AnalyticalDetectionLine;
}
function UniviewAnalyticalDetectionLineSettings(
  props: UniviewAnalyticalDetectionLineSettingsProps
) {
  const { index, data, isEditable, analyticalDetectionLine } = props;
  const { id, sensitivity, direction, detectPerson, detectVehicle } =
    analyticalDetectionLine;
  const relayEnv = useRelayEnvironment();

  const showSensitivity = data.manufacturer !== "Malmoset";

  const lineDetectionTypeNotSelected = !(detectPerson || detectVehicle);
  const noDetectionLineTypeCustomMessage = (input: HTMLInputElement) =>
    input.validity.valueMissing
      ? "At least one detection type must be selected"
      : "";

  return (
    <RegionsSettingsRoot>
      <RegionsSettingsTable>
        <thead>
          <RegionsSettingsRow>
            <th colSpan={2}>
              <Flex alignItems="center" justifyContent="space-between">
                <RegionSettingsHeaderText
                  index={index}
                  onMouseEnter={() => {
                    // brings the line to the top of the pile
                    relayEnv.commitUpdate((store) => {
                      const camera = store.get(
                        data.id
                      ) as RecordProxy<SecureComCamera>;
                      if (camera && isEditable) {
                        camera
                          .getLinkedRecord("analyticalDetectionLines")
                          .setValue(index, "activeDetectionLineIndex");
                      }
                    });
                  }}
                >
                  Line #{index + 1}
                </RegionSettingsHeaderText>
                {isEditable && (
                  <RegionSettingsDeleteButton
                    type="button"
                    onClick={() => {
                      relayEnv.commitUpdate((store) => {
                        const camera = store.get(
                          data.id
                        ) as RecordProxy<SecureComCamera>;
                        const analyticalDetectionLines = camera.getLinkedRecord(
                          "analyticalDetectionLines"
                        );
                        const lines = analyticalDetectionLines
                          .getLinkedRecords("lines")
                          .filter((line) => line.getValue("id") !== id);

                        lines.forEach((line, index) =>
                          line.setValue(index, "index")
                        ); // Resetting index for regions
                        analyticalDetectionLines.setLinkedRecords(
                          lines,
                          "lines"
                        );
                        analyticalDetectionLines.setValue(
                          null,
                          "activeDetectionLineIndex"
                        );
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </RegionSettingsDeleteButton>
                )}
              </Flex>
            </th>
          </RegionsSettingsRow>
        </thead>
        <tbody>
          {showSensitivity ? (
            <RegionsSettingsRow>
              <FieldLabelCell>
                <FieldLabelContainer>
                  <FieldLabelText
                    htmlFor={`detection-line-${index}-sensitivity`}
                  >
                    Sensitivity
                  </FieldLabelText>
                </FieldLabelContainer>
              </FieldLabelCell>
              <FieldValueCell>
                <input
                  id={`detection-line-${index}-sensitivity`}
                  name={`detection-line-${index}-sensitivity`}
                  type="range"
                  min="1"
                  max="100"
                  value={sensitivity}
                  onChange={(event) => {
                    relayEnv.commitUpdate((store) => {
                      const camera = store.get(
                        data.id
                      ) as RecordProxy<SecureComCamera>;
                      camera
                        .getLinkedRecord("analyticalDetectionLines")
                        .getLinkedRecords("lines")
                        [index].setValue(
                          Number(event.target.value),
                          "sensitivity"
                        );
                    });
                  }}
                />
                <SensitivityValue>{sensitivity}%</SensitivityValue>
              </FieldValueCell>
            </RegionsSettingsRow>
          ) : null}
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText htmlFor={`detection-line-${index}-direction`}>
                  Direction
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Select
                value={direction}
                onChange={(event) => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<SecureComCamera>;
                    camera
                      .getLinkedRecord("analyticalDetectionLines")
                      .getLinkedRecords("lines")
                      [index].setValue(Number(event.target.value), "direction");
                  });
                }}
              >
                <Select.Option key={0} value={0}>
                  Both
                </Select.Option>
                <Select.Option key={1} value={1}>
                  Enter
                </Select.Option>
                <Select.Option key={2} value={2}>
                  Exit
                </Select.Option>
              </Select>
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-line-${index}-detect-people`}
                >
                  Detect People
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-line-${index}-detect-people`}
                label="Detect People"
                required={lineDetectionTypeNotSelected}
                checked={detectPerson}
                onChange={() => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<SecureComCamera>;
                    camera
                      .getLinkedRecord("analyticalDetectionLines")
                      .getLinkedRecords("lines")
                      [index].setValue(!detectPerson, "detectPerson");
                  });
                }}
                getValidationMessage={noDetectionLineTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-line-${index}-detect-vehicles`}
                >
                  Detect Vehicles
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-line-${index}-detect-vehicles`}
                label="Detect Vehicles"
                required={lineDetectionTypeNotSelected}
                checked={detectVehicle}
                onChange={() => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<SecureComCamera>;
                    camera
                      .getLinkedRecord("analyticalDetectionLines")
                      .getLinkedRecords("lines")
                      [index].setValue(!detectVehicle, "detectVehicle");
                  });
                }}
                getValidationMessage={noDetectionLineTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
        </tbody>
      </RegionsSettingsTable>
    </RegionsSettingsRoot>
  );
}

export default UniviewAnalyticalDetectionLineSettings;
