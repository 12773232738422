App.controller("LoginFormCtrl", [
  "$rootScope",
  "$scope",
  "$state",
  "$modal",
  "PROPS",
  "LoginService",
  "UserService",
  "DA_LOGIN_BACKGROUNDS",
  "DA_LOGOS",
  "focus",
  "$timeout",
  "$stateParams",
  "$q",

  function (
    $rootScope,
    $scope,
    $state,
    $modal,
    PROPS,
    LoginService,
    UserService,
    DA_LOGIN_BACKGROUNDS,
    DA_LOGOS,
    focus,
    $timeout,
    $stateParams,
    $q
  ) {
    $scope.user = {};
    $scope.settings = {};
    $scope.DA_LOGOS = DA_LOGOS; // pass this to the react login form
    $scope.DA_LOGIN_BACKGROUNDS = DA_LOGIN_BACKGROUNDS;
    $scope.userWasAutoLoggedOut = $stateParams.autoLogout;

    init();
    function init() {
      if (
        ["scdev", "prod_security_cmd", "test_security_cmd"].includes(
          process.env.REACT_APP_SECURECOM_ENV ?? ""
        )
      ) {
        $scope.backgroundImage = DA_LOGIN_BACKGROUNDS.securityCommand;
      } else {
        $scope.backgroundImage = DA_LOGIN_BACKGROUNDS.default;
      }
      $scope.eulaBackground = $scope.backgroundImage;

      if ($scope.$storage.getItem("username")) {
        $scope.settings.rememberEmail = true;
        $scope.user.email = $scope.$storage.getItem("username");
      }
    }

    $timeout(function () {
      focus("LoginEmail");
    }, 300);

    $scope.setUserEmail = function (email) {
      $scope.user.email = email;
    };

    $scope.setUserPassword = function (password) {
      $scope.user.password = password;
    };

    //Takes a GrqphQL users list that is returned by the authenticate mutation, logs in
    // the highest authority user and propagates their auth token to angular services

    $scope.loginPersonDA = function (usersList) {
      var deferred = $q.defer();
      const SCAPIUsers = usersList.map((user) => {
        const formattedUser = {
          user: {
            ...user.user,
            authentication_token: user.bearerToken,
            refresh_token: user.refreshToken,
          },
        };
        formattedUser.user.role = (
          user.user.supervisorRole ??
          user.user.dealerRole ??
          user.user.customerRole ??
          ""
        ).toLowerCase();
        return formattedUser;
      });

      if (SCAPIUsers.length >= 2) {
        //initalize multiple account user
        localStorage.setItem("SCAPIUsers", JSON.stringify(SCAPIUsers));
        localStorage.setItem("dealerNames", []);
        localStorage.setItem("currentAccount", 0);
      } else {
        //remove unneeded variables from stoarge for single users
        localStorage.removeItem("SCAPIUsers");
        localStorage.removeItem("dealerNames");
        localStorage.removeItem("currentAccount");
      }

      const highestUser = LoginService.findHighestAuthorityUser(SCAPIUsers);
      UserService.login(
        highestUser.email,
        highestUser.authentication_token,
        highestUser.refresh_token
      );
      manageSessionStorage();
      LoginService.DALoginRouter(highestUser)
        .then(
          function (route) {
            $state.go(route.to, route.toParams);
            deferred.resolve("success");
          },
          function (error) {
            $scope.loginError = error;
            deferred.reject($scope.loginError);
          }
        )
        .catch(function (error) {
          console.error(error);
          deferred.reject(error);
        });
      return deferred.promise;
    };

    $scope.submitPersonLoginDA = function () {
      $scope.loginError = null;
      LoginService.getHighestAuthorityUser(
        $scope.user.email,
        $scope.user.password
      )
        .then(
          function (user) {
            UserService.login(user.email, user.authentication_token);
            manageSessionStorage();
            LoginService.DALoginRouter(user)
              .then(
                function (route) {
                  $state.go(route.to, route.toParams);
                },
                function (error) {
                  $scope.loginError = error;
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (signInError) {
            console.error(
              "submitPersonLoginDA: error " + angular.toJson(signInError)
            );
            $scope.loginError =
              LoginService.getSCAPISignInErrorDescriptor(signInError);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.openFPModal = function () {
      $scope.openForgotPasswordModal = $modal.open({
        templateUrl: "forgot-password-modal.html",
        controller: "ForgotPasswordModalCtrl",
        windowClass: "forgot-password-modal",
        size: "md",
        backdrop: "true",
        scope: $scope,
      });
    };
    function manageSessionStorage() {
      // If the user selected to, store their email, otherwise make sure it isn't stored
      $scope.settings.rememberEmail
        ? $scope.$storage.setItem("username", $scope.user.email)
        : $scope.$storage.removeItem("username");
    }
  },
]);

App.controller("ForgotPasswordModalCtrl", [
  "$scope",
  "$modalInstance",
  "$rootScope",
  "PROPS",
  "UsersV2API",
  function ($scope, $modalInstance, $rootScope, PROPS, UsersV2API) {
    $scope.resetPassword = function (email) {
      UsersV2API.resetAccessEmail(
        { email: email },
        function () {
          // Success
          $rootScope.alerts.push({
            type: "success",
            text:
              "Sent the password reset to " +
              email +
              ". \r Please check your email for the password reset . ",
          });
        },
        function (error) {
          // Error
          $scope.status = false;
          $scope.error = error;
        }
      );
    };

    $scope.close = function () {
      $modalInstance.dismiss("close");
    };
  },
]);
