/**
 * @generated SignedSource<<8e3d873db8ef18a1d247d311ab226345>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type TMSentryRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly alarmReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entreArmDisarmReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreCheckInMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entreConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreOutgoingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entrePassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreSupervisoryReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreUserCommandReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreZoneReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly remoteDisarm: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly serviceReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly validEntreCheckinMinutes: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentryRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TMSentryRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "b3a007316efffbcf7c77205bb77264bd";

export default node;
