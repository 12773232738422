/**
 * @generated SignedSource<<185d09fc2b7e9ca48c8367e54fd9d2cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RecorderAddCameraModalScanForNewCamerasMutation$variables = {
  hubId: string;
  systemId: string;
};
export type RecorderAddCameraModalScanForNewCamerasMutation$data = {
  readonly scanForNewCameras: {
    readonly __typename: "ScanForNewCamerasErrorPayload";
    readonly type: string | null;
  } | {
    readonly __typename: "ScanForNewCamerasSuccessPayload";
    readonly hubs: ReadonlyArray<{
      readonly cameras: ReadonlyArray<{
        readonly addedToDB: boolean;
        readonly camectCamId: string;
        readonly camectHubId: string;
        readonly cameraId: number | null;
        readonly cameraName: string;
        readonly ipAddress: string;
        readonly isEnabled: boolean;
        readonly isHidden: boolean;
        readonly isScapiCamera: boolean;
        readonly isStreaming: boolean;
        readonly macAddress: string;
        readonly needsCredential: boolean;
        readonly playerAuthToken: string;
        readonly playerUrl: string;
        readonly rtspUrl: string | null;
        readonly scapiCameraId: number | null;
      }>;
      readonly disableAutoAddCameras: boolean | null;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type RecorderAddCameraModalScanForNewCamerasMutation = {
  response: RecorderAddCameraModalScanForNewCamerasMutation$data;
  variables: RecorderAddCameraModalScanForNewCamerasMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "hubId",
    "variableName": "hubId"
  },
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disableAutoAddCameras",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addedToDB",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectCamId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectHubId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cameraId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cameraName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ipAddress",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEnabled",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isScapiCamera",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStreaming",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "macAddress",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsCredential",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playerAuthToken",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playerUrl",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rtspUrl",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHidden",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scapiCameraId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderAddCameraModalScanForNewCamerasMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "scanForNewCameras",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "hubs",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHubCamera",
                    "kind": "LinkedField",
                    "name": "cameras",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v20/*: any*/)
            ],
            "type": "ScanForNewCamerasSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v21/*: any*/),
              (v20/*: any*/)
            ],
            "type": "ScanForNewCamerasErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecorderAddCameraModalScanForNewCamerasMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "scanForNewCameras",
        "plural": false,
        "selections": [
          (v20/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "hubs",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHubCamera",
                    "kind": "LinkedField",
                    "name": "cameras",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ScanForNewCamerasSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v21/*: any*/)
            ],
            "type": "ScanForNewCamerasErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bebe873d7a3de167fcb8dce0b71b93d",
    "id": null,
    "metadata": {},
    "name": "RecorderAddCameraModalScanForNewCamerasMutation",
    "operationKind": "mutation",
    "text": "mutation RecorderAddCameraModalScanForNewCamerasMutation(\n  $systemId: String!\n  $hubId: String!\n) {\n  scanForNewCameras(systemId: $systemId, hubId: $hubId) {\n    __typename\n    ... on ScanForNewCamerasSuccessPayload {\n      hubs {\n        disableAutoAddCameras\n        cameras {\n          addedToDB\n          camectCamId\n          camectHubId\n          cameraId\n          cameraName\n          ipAddress\n          isEnabled\n          isScapiCamera\n          isStreaming\n          macAddress\n          needsCredential\n          playerAuthToken\n          playerUrl\n          rtspUrl\n          isHidden\n          scapiCameraId\n          id\n        }\n        id\n      }\n      __typename\n    }\n    ... on ScanForNewCamerasErrorPayload {\n      type\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ecb7e125494f733b392de50a14788988";

export default node;
