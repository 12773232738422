import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions.graphql";

export const remoteOptionsIntegratorIncomingTcpPortFieldId = () =>
  "remote-options-integrator-incoming-tcp-port";

function RemoteOptionsIntegratorIncomingTcpPortField() {
  const [
    { integratorIncomingPort, integratorConnection },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorIncomingPort
          integratorConnection
        }
        ... on Xt75RemoteOptions {
          integratorIncomingPort
          integratorConnection
        }
        ... on XfRemoteOptions {
          integratorIncomingPort
          integratorConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorIncomingTcpPortFieldId();
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Integrator Incoming TCP Port"
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        value={integratorIncomingPort ?? ""}
        maxLength={16}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        inlineHelp="1-65535"
        min={1}
        max={65535}
        validationMessage="Valid values are 1-65535."
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "integratorIncomingPort");
          });
        }}
        onBlur={({ target }) => {
          const value = clamp(1, 65535, Number(target.value));
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(
              String(value).padStart(5, `0`),
              "integratorIncomingPort"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorIncomingTcpPortField;
