/**=========================================================
 * Module: sidebar-menu.js
 * Provides a simple way to implement bootstrap collapse plugin using a target
 * next to the current element (sibling)
 * Targeted elements must have [data-toggle="collapse-next"]
 =========================================================*/
App.controller("SidebarController", [
  "$rootScope",
  "$scope",
  "$state",
  "$q",
  "$sessionStorage",
  "$location",
  "$modal",
  "$anchorScroll",
  "$http",
  "$timeout",
  "$filter",
  "APP_MEDIAQUERY",
  "DealerV2API",
  "UserService",
  "UserSettingsService",
  "RecentActivityService",
  "AboutMeV2API",
  "LoginService",

  function (
    $rootScope,
    $scope,
    $state,
    $q,
    $sessionStorage,
    $location,
    $modal,
    $anchorScroll,
    $http,
    $timeout,
    $filter,
    mq,
    DealerV2API,
    UserService,
    UserSettingsService,
    RecentActivityService,
    AboutMeV2API,
    LoginService
  ) {
    $scope.search = {};
    $scope.recentSystems = [];
    $scope.UserService = UserService;
    $scope.dealerNames = [];
    var currentState = $rootScope.$state.current.name;
    var $win = $(window);
    var $html = $("html");
    var $body = $("body");
    if ($rootScope.$storage.SCAPIUsers) {
      $scope.SCAPIUsers = JSON.parse($rootScope.$storage.SCAPIUsers);
    }

    $scope.dealer_id = UserService.dealer_id;

    UserSettingsService.useUserSettings($scope);

    $scope.getTryNewProgramming = () => true;

    $scope.getSystemName = function (systemName) {
      const systemReplacement =
        UserService.dealerInfo.vernaculars.systems.replacement;
      const name = systemName
        ? systemName
        : systemReplacement
        ? `New ${systemReplacement}`
        : "New System";

      return name.length > 18 ? name.slice(0, 18) + "..." : name;
    };

    /**
     * Creates and opens a modal to display the success or failure of allowing
     * access of a temporary user.
     **/
    $scope.openUserManagementModal = function (systemId, isUserManagement) {
      const statusModal = {};
      const deferred = $q.defer();
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          $scope.statusModal = $modal.open({
            templateUrl: "app/common/templates/temp-user-modal-tpl.html",
            controller: "DaUserManagementLoginCtrl",
            size: "md",
            backdrop: "static",
            resolve: {
              systemId: function () {
                return systemId;
              },
              isUserManagement: function () {
                return isUserManagement ?? false;
              },
            },
          });
          $scope.statusModal.result.then(
            function (result) {
              if (result.createdTempUser) {
                deferred.resolve();
              } else {
                deferred.reject();
              }
            },
            function () {
              deferred.reject();
            }
          );
          break;
        case "techApp":
          console.warn(
            "daTempAppUserPreAuthorized directive does not support TechApp"
          );
          deferred.reject();
          break;
        default:
          console.warn(
            "daTempAppUserPreAuthorized directive does not support $rootScope.appProperties.type: " +
              $rootScope.appProperties.type
          );
          deferred.reject();
          break;
      }
      return deferred.promise;
    };

    /**
     * Array of Objects :  $scope.nav_menu_items
     *
     * desc: used to manipulate accordion collapse state
     * accordion collapse control
     *
     */

    $scope.nav_menu_items = [
      { name: "search", isVisible: false },
      { name: "programming", isVisible: true },
      { name: "site", isVisible: false },
      { name: "tools", isVisible: false },
      { name: "resources", isVisible: false },
      { name: "settings", isVisible: false },
      { name: "personnel", isVisible: false },
      { name: "help", isVisible: false },
      { name: "dealerSwitch", isVisible: false },
    ];

    function refreshSystemList() {
      $scope.recentSystems = RecentActivityService.getList(
        RecentActivityService.props.storageKeys.system
      );
    }

    //dev function
    $rootScope.$watch("stateChange", function (newVal) {
      refreshSystemList();
    });

    $scope.assignNames = async function () {
      let x = 0;
      if ($scope.SCAPIUsers && $rootScope.$storage.dealerNames.length === 0) {
        //run this on log in if there are multiple accounts for the user and set up the storage needed to keep track of the accounts
        let temp = "";
        let tempUser = "";
        const currentAuth =
          $scope.SCAPIUsers[$rootScope.$storage.currentAccount].user
            .authentication_token;
        const currentId = UserService.user.accessible_id;
        const currentPerson = UserService.user.person_id;

        while ($scope.SCAPIUsers[x]) {
          tempUser = await getUserInfo(
            $scope.SCAPIUsers[x].user.authentication_token
          );
          //assign all values at once to not trigger watchers a bunch of times
          Object.assign(UserService, {
            auth_token: $scope.SCAPIUsers[x].user.authentication_token,
            user: Object.assign(UserService.user, {
              accessible_id: $scope.SCAPIUsers[x].user.accessible_id,
              person_id: tempUser.user.person_id,
            }),
          });
          $sessionStorage.auth_token =
            $scope.SCAPIUsers[x].user.authentication_token;
          temp = await getDealerFromDealerID(
            $scope.SCAPIUsers[x].user.accessible_id
          );
          $scope.dealerNames.push({
            name: temp.dealer.name,
            id: $scope.SCAPIUsers[x].user.accessible_id,
          });
          x++;
        }
        $rootScope.$storage.dealerNames = JSON.stringify($scope.dealerNames);
        UserService.user.accessible_id = currentId;
        UserService.user.person_id = currentPerson;
        UserService.auth_token = currentAuth;
        $sessionStorage.auth_token = currentAuth;
      } else if ($rootScope.$storage.SCAPIUsers) {
        //run this on log in if there are multiple accounts for the user and it is a page refresh and set up the storage needed to keep track of the accounts
        const temp = JSON.parse($rootScope.$storage.dealerNames);
        UserService.email = JSON.parse(
          $rootScope.$storage.SCAPIUsers
        )[0].user.email;
        UserService.dealer_id = temp[$rootScope.$storage.currentAccount].id;
        while (temp[x]) {
          refreshSystemList();
          $scope.dealerNames.push({
            name: temp[x].name,
            id: temp[x].id,
            user: temp[x].user,
          });
          x++;
        }
      }
    };

    const getDealerFromDealerID = function (dealerID) {
      var deferred = $q.defer();
      DealerV2API.show(
        { dealer_id: dealerID }, //params
        function (data) {
          //success
          deferred.notify({
            job_uuid: "n/a",
            status: "success",
            poll_count: 0,
          });
          deferred.resolve(data);
        },
        function (error) {
          //failure
          deferred.notify({
            job_uuid: "n/a",
            status: "error",
            poll_count: 0,
          });
          deferred.reject(error);
        },
        function (info) {
          //info
          deferred.notify(info);
        }
      );
      return deferred.promise;
    };
    function getUserInfo(authToken) {
      var deferred = $q.defer();
      AboutMeV2API.get(
        { auth_token: authToken },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }
    $scope.assignNames();

    $scope.changeAccount = function (account) {
      let currentAccount = 0;
      const SCAPIUsers = JSON.parse($rootScope.$storage.SCAPIUsers);
      const accountName = JSON.parse($rootScope.$storage.dealerNames).filter(
        function (n) {
          return n.name === account.name;
        }
      );
      SCAPIUsers.forEach(async (dealer) => {
        if (dealer.user.accessible_id == accountName[0].id) {
          $rootScope.$storage.currentAccount = currentAccount;
          UserService.login(
            dealer.user.email,
            dealer.user.authentication_token
          );
          await LoginService.DALoginRouter(dealer.user)
            .then(
              function (route) {
                UserService.email = SCAPIUsers[0].user.email;
                UserService.refresh_token = dealer.user.refresh_token;
                UserService.dealer_id = dealer.user.accessible_id;
                UserService.auth_token = dealer.user.authentication_token;
                $sessionStorage.auth_token = dealer.user.authentication_token;
                if ($rootScope.refreshPromise) {
                  $rootScope.refreshPromise.jwt =
                    dealer.user.authentication_token;
                  $rootScope.refreshPromise.refresh_token =
                    dealer.user.refresh_token;
                }
                refreshSystemList();
                $state.go(route.to, route.toParams);
              },
              function (error) {
                $scope.loginError = error;
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
        currentAccount++;
      });
    };
    /**
     * $scope.collapseOtherMenuItems
     * desc: Toggles passed menu item and closes all other menu items in  $scope.nav_menu_items
     * @param openItem: Menu item toggle that has been clicked by user
     *
     */
    $scope.collapseOtherMenuItems = function (openItem) {
      angular.forEach($scope.nav_menu_items, function (value) {
        if (value.name == openItem) {
          //toggles the state of the passed menu item.
          value.isVisible = !value.isVisible;
        } else {
          value.isVisible = false; // collapes all other menu items
        }
      });
    };

    /**
     * $rootScope.toggleMenuItem
     * desc: sets the accordion of the passed menu item (name) to visible
     * scope at
     * @param name
     */
    $rootScope.toggleMenuItem = function (name) {
      //set isVisible of passed menu item to true
      return $filter("filter")($scope.nav_menu_items, { name: name })[0]
        .isVisible;
    };
    /**
     * $scope.openAllSubmenus
     * desc: Opens all menu accordions
     *       Runs on broadcast event: collapseSidebarMenu
     *       expands all submenus
     */
    $scope.openAllSubmenus = function () {
      angular.forEach($scope.nav_menu_items, function (value) {
        // sets the value of every menu item - isVisible = true
        value.isVisible = true;
      });
    };

    /**
     *  $scope.closeSubmenus
     *  desc: keeps programming accordion open and collapses everything else
     *  runs on global broadcast event: expandSidebarMenu
     * @param name
     */
    $scope.closeSubmenus = function (name) {
      angular.forEach($scope.nav_menu_items, function (value) {
        if (value.name == "programming" || value.name == "site") {
          value.isVisible = true;
        } else {
          value.isVisible = false;
        }
      });
    };

    /* $scope.controlSubmenuVisibility = function (name) {

      if ($rootScope.app.layout.isToggled) {
        openAllSubmenus()
      }
      };*/

    /**
     *  $scope.collapseTheMenuOnRefresh
     *  desc: keeps programming accordion open and collapses everything else on refresh
     *  ONLY IF THE SIDEBAR MENU IS NOT TOGGLED TO ICON ONLY VIEW
     *  runs on global broadcast event: expandSidebarMenu
     */
    $scope.collapseTheMenuOnRefresh = function () {
      if (!$rootScope.app.layout.isToggled) {
        angular.forEach($scope.nav_menu_items, function (value) {
          if (value.name == "programming" || value.name == "site") {
            value.isVisible = true;
          } else {
            value.isVisible = false;
          }
        });
      } else {
        angular.forEach($scope.nav_menu_items, function (value) {
          value.isVisible = true;
        });
      }
    };

    /**
     *
     * global broadcast: expandSidebarMenu
     * desc:  if global broadcast event is caught then run closeSubmenus()
     */

    $scope.$on("expandSidebarMenu", function () {
      $scope.closeSubmenus();
    });

    /**
     *
     * global broadcast: collapseSidebarMenu
     * desc:  if global broadcast event is caught then run closeSubmenus()
     * $scope.openAllSubmenus()
     */

    $scope.$on("collapseSidebarMenu", function () {
      $scope.openAllSubmenus();
    });
    /**
     *
     * global broadcast: collapseSidebarMenu
     * desc:  if global broadcast event is caught then run closeSubmenus()
     * $scope.openAllSubmenus()
     */
    // Listen for the parent event on $scope
    $rootScope.$on("layoutLoaded", function (event, data) {
      $scope.collapseTheMenuOnRefresh();
    });

    $rootScope.toggleTheMenu = function () {
      $rootScope.app.layout.isToggled = !$rootScope.app.layout.isToggled;
    };

    $rootScope.scrollTo = function (selector) {
      $location.hash(selector);
      $anchorScroll();
    };

    // Adjustment on route changes
    $rootScope.$on(
      "$stateChangeStart",
      function (event, toState, toParams, fromState, fromParams) {
        currentState = toState.name;
        // Hide sidebar automatically on mobile
        $("body.aside-toggled").removeClass("aside-toggled");
        $rootScope.app.layout.isToggled = false;
        $rootScope.$broadcast("closeSidebarMenu");
      }
    );

    // Normalize state on resize to avoid multiple checks
    $win.on("resize", function () {
      if (isMobile()) $body.removeClass("aside-collapsed");
      else {
        $body.removeClass("aside-toggled");
        if (
          angular.isDefined($rootScope.app.layout.isToggled) &&
          $rootScope.app.layout.isToggled
        )
          $rootScope.$apply(function () {
            $rootScope.app.layout.isToggled = false;
          });
      }
    });

    // Check item and children active state
    var isActive = function (item) {
      if (!item) return;

      if (!item.sref || item.sref == "#") {
        var foundActive = false;
        angular.forEach(item.submenu, function (value, key) {
          if (isActive(value)) foundActive = true;
        });
        return foundActive;
      } else return $state.is(item.sref);
    };

    // Load menu from json file
    // -----------------------------------

    $scope.getMenuItemPropClasses = function (item) {
      return (
        (item.heading ? "nav-heading" : "") + (isActive(item) ? " active" : "")
      );
    };

    $scope.loadSidebarMenu = function () {
      var menuJson = "/app/common/sidebar-menu.json",
        menuURL = menuJson + "?v=" + new Date().getTime(); // jumps cache
      $http
        .get(menuURL)
        .then(function (items) {
          $scope.menuItems = items;
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.loadSidebarMenu();

    // Handle sidebar collapse items
    // -----------------------------------
    var collapseList = [];

    $scope.addCollapse = function ($index, item) {
      collapseList[$index] = !isActive(item);
    };

    $scope.isCollapse = function ($index) {
      return collapseList[$index];
    };

    $scope.toggleCollapse = function ($index) {
      // collapsed sidebar doesn't toggle drodopwn
      if (isSidebarCollapsed() && !isMobile()) return true;
      // make sure the item index exists
      if (typeof collapseList[$index] === undefined) return true;

      closeAllBut($index);
      collapseList[$index] = !collapseList[$index];

      return true;

      function closeAllBut($index) {
        angular.forEach(collapseList, function (v, i) {
          if ($index !== i) collapseList[i] = true;
        });
      }
    };

    // Helper checks
    // -----------------------------------

    function isMobile() {
      return $win.width() < mq.tablet;
    }

    function isTouch() {
      return $html.hasClass("touch");
    }

    function isSidebarCollapsed() {
      return $body.hasClass("aside-collapsed");
    }

    function isSidebarToggled() {
      return $body.hasClass("aside-toggled");
    }

    function isSidebarMenuCollapsed() {
      return $body.hasClass("aside-collapsed");
    }

    $scope.doSearch = function () {
      $state.go("app.dealer.search", {
        dealer_id: UserService.dealer_id,
        criteria: $scope.search.category,
        parameter: $scope.search.value,
      });
    };
  },
]);

App.controller("TopNavController", [
  "$scope",
  "UserService",
  "DealerService",
  "$state",
  function ($scope, UserService, DealerService, $state) {
    $scope.unsetSelectedDealer = function () {
      UserService.unsetSelectedDealer(
        new DealerService({ dealer_id: UserService.user.accessible_id })
      )
        .then(function () {
          $state.go(
            "app.dealer.search",
            {
              dealer_id: UserService.dealer_id,
              criteria: "Dealers",
              parameter: "",
            },
            { reload: true }
          );
        })
        .catch(function (error) {
          console.error(error);
        });
    };
  },
]);

App.controller("UserBlockController", [
  "$scope",
  "UserService",
  function ($scope, UserService) {
    $scope.UserService = UserService;

    $scope.userBlockVisible = true;

    $scope.$on("toggleUserBlock", function (event, args) {
      $scope.userBlockVisible = !$scope.userBlockVisible;
    });
  },
]);
