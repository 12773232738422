/**
 * @generated SignedSource<<307bfd029562f2fe052e022c35b05674>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AllowTimeChange = "NO" | "SERVER" | "YES";
export type CaptureFormat = "CID" | "DMP" | "FOUR2" | "SIA";
export type EolResistorValue = "FOUR_POINT_SEVEN_THOUSAND" | "ONE_THOUSAND" | "THREE_POINT_THREE_THOUSAND" | "TWO_POINT_TWO_THOUSAND";
export type KeypadInput = "DSC" | "ECP" | "NONE";
export type PanelProgrammingLanguage = "CZECH" | "DUTCH" | "ENGLISH" | "FRENCH" | "GREEK" | "NONE" | "SPANISH";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
export type WirelessEncryption1100 = "ALL" | "BOTH" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts$data = {
  readonly systemOptions: {
    readonly armDisarmActivityDays: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly captureFormat: {
      readonly data: CaptureFormat | null;
      readonly included: boolean | null;
    } | null;
    readonly celsius: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCheck: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly crossZoneTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly detectJam: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly displayTime: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly ecpPartition: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly enableKeypadPanicKeys: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay1: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay2: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly eolResistorValue: {
      readonly data: EolResistorValue | null;
      readonly included: boolean | null;
    } | null;
    readonly exitDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly hoursFromGMT: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly houseCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly iso2CountryCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly keypadInput: {
      readonly data: KeypadInput | null;
      readonly included: boolean | null;
    } | null;
    readonly observeDst: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly occupiedPremises: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly powerFailDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly primaryProgrammingLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly primaryUserLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly resetSwingerBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly secondaryProgrammingLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly secondaryUserLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly swingerBypassTrips: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly systemType: {
      readonly data: SystemType | null;
      readonly included: boolean | null;
    } | null;
    readonly timeChange: {
      readonly data: AllowTimeChange | null;
      readonly included: boolean | null;
    } | null;
    readonly useBuiltIn1100Wireless: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly useFalseAlarmQuestion: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly weatherPostalCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly weatherZipCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessAudibles: {
      readonly data: WirelessAudibles | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessEncryption1100: {
      readonly data: WirelessEncryption1100 | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessEncryption1100Passphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneActivityHours: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts";
};
export type XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "ef6cdceb00fa72b68fd33a41714a9094";

export default node;
