/**
 * @generated SignedSource<<8aef5bacff1a9c05460a4a034efd54b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly remoteOptions: {
      readonly __typename: "Xt75RemoteOptions";
      readonly appKey: string | null;
      readonly firstApn: string | null;
      readonly id: string;
      readonly integratorArmDisarmReports: boolean | null;
      readonly integratorBackupConnection: RemoteOptionsConnectionType | null;
      readonly integratorBackupIncomingPort: string | null;
      readonly integratorBackupIpAddress: string | null;
      readonly integratorConnection: RemoteOptionsConnectionType | null;
      readonly integratorDoorReports: boolean | null;
      readonly integratorIncomingPort: string | null;
      readonly integratorIpAddress: string | null;
      readonly integratorOutgoingPort: string | null;
      readonly integratorPassphrase: string | null;
      readonly integratorSupervisoryReports: boolean | null;
      readonly integratorUserCommandReports: boolean | null;
      readonly integratorVideoReports: boolean | null;
      readonly integratorZoneReports: boolean | null;
      readonly remoteDisarm: boolean;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  };
  readonly " $fragmentType": "XT75RemoteOptionsProgrammingConceptFormInline_controlSystem";
};
export type XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75RemoteOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75RemoteOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "ac53394a079f0531ea0e66009291538f";

export default node;
