/**
 * @generated SignedSource<<4cbd19b64d4c8d8244a8c8eacbbde503>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemStatus_dealer$data = {
  readonly centralStationAutomationIntegrations: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly id: string;
  readonly vernaculars: ReadonlyArray<{
    readonly original: string;
    readonly replacement: string;
  } | null>;
  readonly " $fragmentType": "SystemStatus_dealer";
};
export type SystemStatus_dealer$key = {
  readonly " $data"?: SystemStatus_dealer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemStatus_dealer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemStatus_dealer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CentralStationAutomationIntegration",
      "kind": "LinkedField",
      "name": "centralStationAutomationIntegrations",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Vernacular",
      "kind": "LinkedField",
      "name": "vernaculars",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "original",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "replacement",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};
})();

(node as any).hash = "5c7e0702baa2c3f7109ed8a54170adb2";

export default node;
