/**
 * @generated SignedSource<<c59fab94d225155f229ba84e6b450cae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MassProgrammingGlobalHolidayDatesViewQuery$variables = {
  dealerId: string;
  id: string;
};
export type MassProgrammingGlobalHolidayDatesViewQuery$data = {
  readonly dealer: {
    readonly vernaculars: ReadonlyArray<{
      readonly original: string;
      readonly replacement: string;
    } | null>;
  } | null;
  readonly holiday: {
    readonly completedAt: string;
    readonly dealerHolidayDate: {
      readonly holidayDate: string | null;
      readonly holidayName: string | null;
    } | null;
    readonly failedToUpdate: number;
    readonly id: string;
    readonly jobData: {
      readonly operation: string | null;
    } | null;
    readonly jobOutputDetails: ReadonlyArray<{
      readonly customerName: string | null;
      readonly status: string | null;
      readonly systemType: string | null;
    } | null> | null;
    readonly personWhoStartedJob: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
    readonly scapiId: number;
    readonly successfullyUpdated: number;
    readonly totalSystems: number;
    readonly updateInProgress: number;
    readonly waitingForUpdate: number;
  };
};
export type MassProgrammingGlobalHolidayDatesViewQuery = {
  response: MassProgrammingGlobalHolidayDatesViewQuery$data;
  variables: MassProgrammingGlobalHolidayDatesViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dealerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "holiday",
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "SchedulerJob",
  "kind": "LinkedField",
  "name": "holidayDateJobQuery",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scapiId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobDataType",
      "kind": "LinkedField",
      "name": "jobData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "operation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonWhoStartedJob",
      "kind": "LinkedField",
      "name": "personWhoStartedJob",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobOutputDetail",
      "kind": "LinkedField",
      "name": "jobOutputDetails",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DealerHolidayDate",
      "kind": "LinkedField",
      "name": "dealerHolidayDate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "holidayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "holidayDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSystems",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "successfullyUpdated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updateInProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitingForUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failedToUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dealerId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "replacement",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MassProgrammingGlobalHolidayDatesViewQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vernacular",
            "kind": "LinkedField",
            "name": "vernaculars",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MassProgrammingGlobalHolidayDatesViewQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vernacular",
            "kind": "LinkedField",
            "name": "vernaculars",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "52c908217ceca539dd56ad7fe72ccb63",
    "id": null,
    "metadata": {},
    "name": "MassProgrammingGlobalHolidayDatesViewQuery",
    "operationKind": "query",
    "text": "query MassProgrammingGlobalHolidayDatesViewQuery(\n  $id: ID!\n  $dealerId: ID!\n) {\n  holiday: holidayDateJobQuery(id: $id) {\n    id\n    scapiId\n    jobData {\n      operation\n    }\n    personWhoStartedJob {\n      firstName\n      lastName\n      email\n    }\n    jobOutputDetails {\n      customerName\n      systemType\n      status\n    }\n    dealerHolidayDate {\n      holidayName\n      holidayDate\n    }\n    completedAt\n    totalSystems\n    successfullyUpdated\n    updateInProgress\n    waitingForUpdate\n    failedToUpdate\n  }\n  dealer(id: $dealerId) {\n    vernaculars {\n      original\n      replacement\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8952e6370ee986e165e9b1ab42acd8bf";

export default node;
