import graphql from "babel-plugin-relay/macro";
import TextInput from "components/Inputs/TextInput";
import { DeleteConfirmModal } from "components/Modal/ConfirmModal";
import { useShowAlert } from "contexts/AlertsContext";
import { difference, union } from "ramda";
import React, { useMemo, useRef, useState } from "react";
import {
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay/hooks";
import Select from "react-select";
import { CellMeasurerCache } from "react-virtualized";
import { RecordProxy } from "relay-runtime";
import { Dealer, idAsString, Tag, toDealerId } from "securecom-graphql/client";
import styled from "styled-components";
import CustomerList from "./CustomerList";
import { TagEditAllTagsQuery } from "./__generated__/TagEditAllTagsQuery.graphql";
import { TagEditCustomerListQuery } from "./__generated__/TagEditCustomerListQuery.graphql";
import { TagEditDealerListQuery } from "./__generated__/TagEditDealerListQuery.graphql";
import { TagEditDeleteTagMutation } from "./__generated__/TagEditDeleteTagMutation.graphql";
import { TagEditQuery } from "./__generated__/TagEditQuery.graphql";
import { TagEditSaveTagMutation } from "./__generated__/TagEditSaveTagMutation.graphql";
import { TagEditTagRelationsQuery } from "./__generated__/TagEditTagRelationsQuery.graphql";

interface TagEditProps {
  dealerId: number;
  onCancel: () => void;
  tagId: string;
  securityCommandEnabled: boolean;
  isSuperUser: boolean;
}
function TagEdit(props: TagEditProps) {
  const { dealerId, onCancel, tagId, securityCommandEnabled, isSuperUser } =
    props;
  const isCreating = tagId === "0";
  const { getTagById: tag } = useLazyLoadQuery<TagEditQuery>(
    graphql`
      query TagEditQuery($tagId: ID!) {
        getTagById(id: $tagId) {
          id
          name
          description
          billingDealerId
        }
      }
    `,
    { tagId: tagId }
  );
  const { getDealerList: dealerList } =
    useLazyLoadQuery<TagEditDealerListQuery>(
      graphql`
        query TagEditDealerListQuery {
          getDealerList {
            databaseId
            name
            securityCommand
          }
        }
      `,
      {}
    );
  const { getCustomerList: customers } =
    useLazyLoadQuery<TagEditCustomerListQuery>(
      graphql`
        query TagEditCustomerListQuery($dealerId: ID!) {
          getCustomerList(id: $dealerId) {
            customerId
            customerName
            controlSystemId
            controlSystemName
            siteId
          }
        }
      `,
      { dealerId: idAsString(toDealerId(dealerId)) }
    );
  // NOTE: We need to include all tag relations arrays in this query even if they're not directly being used. They are needed
  // for the save mutation. If any are missing, those tag relations will be wiped upon saving.
  const { getTagRelationsById: tagRelations } =
    useLazyLoadQuery<TagEditTagRelationsQuery>(
      graphql`
        query TagEditTagRelationsQuery($tagId: ID!) {
          getTagRelationsById(id: $tagId) {
            tagCustomers
            tagCustomRoles
            tagPanels
            tagSites
            tagUsers
          }
        }
      `,
      { tagId: tagId },
      { fetchPolicy: "network-only" }
    );
  const { dealer } = useLazyLoadQuery<TagEditAllTagsQuery>(
    graphql`
      query TagEditAllTagsQuery($dealerId: ID!) {
        dealer: node(id: $dealerId) {
          ... on Dealer {
            tags {
              id
              value
            }
            vernaculars {
              original
              replacement
            }
          }
        }
      }
    `,
    { dealerId: idAsString(toDealerId(dealerId)) }
  );
  const customerReplacement = dealer?.vernaculars?.find(
    (v) => v?.original === "customers"
  )?.replacement;
  const systemReplacement = dealer?.vernaculars?.find(
    (v) => v?.original === "systems"
  )?.replacement;
  const relayEnv = useRelayEnvironment();
  const showAlert = useShowAlert();
  const [saveTag, savingTag] =
    useMutation<TagEditSaveTagMutation>(saveTagMutation);
  const [deleteTag, deletingTag] =
    useMutation<TagEditDeleteTagMutation>(deleteTagMutation);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  type SelectedDealer = {
    value: number;
    label: string;
  };

  const [selectedDealer, setSelectedDealer] = useState<number | null>(
    tag.billingDealerId
  );
  const handleDealerSelected = (
    selectedDealer: { value: number | undefined; label: string } | null
  ) => {
    setSelectedDealer(selectedDealer?.value ?? null);
  };

  // return a copy and sort it alphabetically
  const sortedDealerList = dealerList.slice().sort((a, b) => {
    const nameA = a?.name.trim() || "";
    const nameB = b?.name.trim() || "";
    return nameA.localeCompare(nameB);
  });
  // with our sorted list we need to format how we show the values in the drop down
  // and filter out Security Command dealers
  const formattedDealerList = sortedDealerList
    .filter((dealer) => !dealer?.securityCommand)
    .map((dealer) => ({
      value: dealer?.databaseId,
      label: `${dealer?.databaseId} - ${dealer?.name}`,
    }));

  const customersMap = useMemo(() => getCustomersMap(customers), [customers]);
  const customersData = useMemo(() => {
    // Clear all row height cache due to index reference being lost when search field is being used
    cache.current.clearAll();

    // Convert customersMap to an array. Map through array to create data needed for list of customers. Finally filter
    // the list base on searchValue.
    return Array.from(customersMap)
      .map(([customerId, customer]) => ({
        customerId: customerId,
        name: customer.name,
        sites: customer.sites,
        systems: customer.systems,
        isOpen: false,
      }))
      .filter((customer) =>
        customer.name.toLowerCase().includes(searchValue.toLowerCase())
      );
  }, [customersMap, searchValue]);
  const tagRelationsMap = useMemo(
    () => getTagRelationsMap(tagRelations),
    [tagRelations]
  );
  const tagsSet = useMemo(() => getTagsSet(dealer, tagId), [dealer]);
  const AllNoneButtons = () => {
    const handleClick = (selectAll: boolean) => {
      relayEnv.commitUpdate((store) => {
        const tagRelationsRecordProxy = store
          .getRoot()
          .getLinkedRecord("getTagRelationsById", { id: tagId });

        if (tagRelationsRecordProxy) {
          const currentlySelectedCustomers = tagRelationsRecordProxy.getValue(
            "tagCustomers"
          ) as number[];
          const customersSubset = Array.from(
            customersData,
            (customer) => customer.customerId
          );

          if (selectAll) {
            tagRelationsRecordProxy.setValue(
              union(currentlySelectedCustomers, customersSubset),
              "tagCustomers"
            );
          } else {
            tagRelationsRecordProxy.setValue(
              difference(currentlySelectedCustomers, customersSubset),
              "tagCustomers"
            );
          }
        }
      });
    };
    return (
      <ButtonGroup className="btn-group btn-group-xs" role="group">
        <button
          className="btn btn-default"
          type="button"
          onClick={() => handleClick(true)}
        >
          All
        </button>
        <button
          className="btn btn-default"
          type="button"
          onClick={() => handleClick(false)}
        >
          None
        </button>
      </ButtonGroup>
    );
  };
  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    saveTag({
      variables: {
        input: {
          id: tag.id,
          dealerId: dealerId,
          name: tag.name || "",
          description: tag.description,
          tagCustomers: tagRelations.tagCustomers || [],
          tagCustomRoles: tagRelations.tagCustomRoles || [],
          tagPanels: tagRelations.tagPanels || [],
          tagSites: tagRelations.tagSites || [],
          tagUsers: tagRelations.tagUsers || [],
          billingDealerId: selectedDealer || null,
        },
      },
      onCompleted: ({ saveTag }) => {
        if (saveTag.status === "SUCCESS") {
          if (isCreating) {
            relayEnv.commitUpdate((store) => {
              const tagRecordProxy = store.get(tag.id) as RecordProxy<Tag>;

              // Invalidate changes made when creating against tagId 0 so subsequent creates will not have previous create's inputs
              tagRecordProxy.invalidateRecord();

              if (saveTag.tag) {
                const dealerRecordProxy = store.get(
                  idAsString(toDealerId(dealerId))
                ) as RecordProxy<Dealer>;
                const dealerTags = dealerRecordProxy.getLinkedRecords("tags");
                const newTag = store.get(saveTag.tag.id) as RecordProxy<Tag>;

                // Update the store with new tag
                dealerRecordProxy.setLinkedRecords(
                  [...dealerTags, newTag],
                  "tags"
                );
              }
            });
          }
          onCancel();
          showAlert({
            type: "success",
            text: "Tag saved",
          });
        } else {
          showAlert({
            type: "error",
            text: "Failed to save",
          });
        }
      },
      onError: () => {
        showAlert({
          type: "error",
          text: "Failed to save",
        });
      },
    });
  };

  return tag ? (
    <form onSubmit={(event) => handleSave(event)}>
      <div className="row">
        <div className="page-header">
          <div className="page-header__left">
            <div className="page-header__title">
              <span>{`${isCreating ? "Creating" : "Editing"} ${
                tag.name
              }`}</span>
            </div>
          </div>
          <div className="page-header__right">
            <button
              type="button"
              className="btn btn-default btn-sm"
              onClick={() => {
                relayEnv.commitUpdate((store) => {
                  const tagRecordProxy = store.get(tag.id) as RecordProxy<Tag>;
                  const tagRelationsRecordProxy = store
                    .getRoot()
                    .getLinkedRecord("getTagRelationsById", { id: tagId });

                  // Invalidate changes made to store thus far
                  tagRecordProxy.invalidateRecord();
                  tagRelationsRecordProxy?.invalidateRecord();
                  onCancel();
                });
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() => setDeleteModalOpen(!deleteModalOpen)}
              disabled={savingTag || isCreating}
            >
              Delete
            </button>
            <button
              type="submit"
              className="btn btn-dmp btn-sm"
              disabled={savingTag}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <Container>
          <div className="form-group">
            <label htmlFor="name" className="control-label">
              Name
            </label>
            <TextInput
              id={"name"}
              maxLength={30}
              defaultValue={tag.name || ""}
              required
              onChange={({ target }) => {
                target.setCustomValidity("");
                relayEnv.commitUpdate((store) => {
                  const tagRecordProxy = store.get(tag.id) as RecordProxy<Tag>;
                  tagRecordProxy.setValue(target.value, "name");
                });
              }}
              onBlur={({ target }) => {
                if (tagsSet.has(target.value.toLowerCase())) {
                  target.setCustomValidity("Tag name is already in use.");
                }
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description" className="control-label">
              Description
            </label>
            <TextInput
              id={"description"}
              maxLength={255}
              defaultValue={tag.description || ""}
              onBlur={({ target }) => {
                relayEnv.commitUpdate((store) => {
                  const tagRecordProxy = store.get(tag.id) as RecordProxy<Tag>;
                  tagRecordProxy.setValue(target.value, "description");
                });
              }}
            />
          </div>
          {securityCommandEnabled && isSuperUser ? (
            <>
              <label htmlFor="billing-option" className="control-label">
                Billing Options
              </label>
              <div className="form-group">
                <span className="form-label">
                  Select a dealer by entering their name or Dealer ID.{" "}
                  {systemReplacement ?? "Systems"} associated to this tag will
                  be billed to this dealer.
                </span>
              </div>
              <div className="form-group">
                <Select
                  name="dealerSelect"
                  value={
                    formattedDealerList.find(
                      (dealer) => dealer.value === selectedDealer
                    ) ?? null
                  }
                  onChange={handleDealerSelected}
                  options={formattedDealerList}
                  isDisabled={!isCreating}
                  placeholder="Select a Dealer..."
                  required
                />
              </div>
            </>
          ) : null}
        </Container>
        {!isSuperUser ? (
          <Container>
            <BoldH5>
              Add to {systemReplacement ?? "Systems"} or{" "}
              {customerReplacement ? customerReplacement : "Customers"}
            </BoldH5>
            <div>
              Add this tag to{" "}
              {customerReplacement ? customerReplacement : "customers"} or{" "}
              {systemReplacement ?? "Systems"} below.
            </div>
            <FlexDiv>
              <AllNoneButtons />
              <input
                type="text"
                name="search"
                className="form-control form-control-300"
                onChange={({ target }) => setSearchValue(target.value)}
                placeholder="Search..."
              />
            </FlexDiv>
            <CustomerList
              cache={cache}
              customersData={customersData}
              tagId={tagId}
              tagRelationsMap={tagRelationsMap}
              systemReplacement={systemReplacement}
            />
          </Container>
        ) : null}
      </div>

      {deleteModalOpen ? (
        <DeleteConfirmModal
          actionPending={deletingTag}
          header="Delete Tag Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          pendingText="Deleting..."
          onConfirm={() =>
            deleteTag({
              variables: {
                encodedTagId: tagId,
              },
              onCompleted(results) {
                if (results.deleteTag.status === "SUCCESS") {
                  showAlert({
                    type: "success",
                    text: `Deleted tag: ${tag.name}`,
                  });
                  onCancel();
                } else {
                  showAlert({
                    type: "error",
                    text: `Failed to delete tag: ${tag.name}`,
                  });
                }
              },
            })
          }
          onCancel={() => setDeleteModalOpen(false)}
        >
          Are you sure you want to delete this Tag?
        </DeleteConfirmModal>
      ) : null}
    </form>
  ) : null;
}

const getCustomersMap = (
  customers: readonly ({
    readonly controlSystemId: number | null;
    readonly controlSystemName: string | null;
    readonly customerId: number;
    readonly customerName: string;
    readonly siteId: number | null;
  } | null)[]
) => {
  const customersMap = new Map();

  customers.forEach((customer) => {
    if (customersMap.has(customer?.customerId)) {
      const customersMapItem = customersMap.get(customer?.customerId);

      // add site or system to exiting sites or systems Map for customer
      if (!!customer?.siteId) {
        customersMapItem.sites.set(customer.siteId, {
          name: customer.controlSystemName,
        });
      } else {
        customersMapItem.systems.set(customer?.controlSystemId, {
          name: customer?.controlSystemName,
        });
      }
    } else {
      const systems = new Map();
      const sites = new Map();

      // if customer has site or system attached, add it to systems Map
      if (!!customer?.controlSystemId && !!customer.controlSystemName) {
        // If customer has site attached, add it to sites Map and not systems Map
        if (!!customer.siteId) {
          sites.set(customer.siteId, { name: customer.controlSystemName });
        } else {
          systems.set(customer.controlSystemId, {
            name: customer.controlSystemName,
          });
        }
      }

      // add customer to customersMap
      customersMap.set(customer?.customerId, {
        name: customer?.customerName,
        sites: sites,
        systems: systems,
      });
    }
  });

  return customersMap;
};
const getTagRelationsMap = (tagRelations: {
  readonly tagCustomers: readonly (number | null)[] | null;
  readonly tagPanels: readonly (number | null)[] | null;
}) => {
  const tagRelationsMap = new Map();

  for (const relationType in tagRelations) {
    const relatedIds = new Set(
      tagRelations[relationType as keyof typeof tagRelations]
    );
    tagRelationsMap.set(relationType, relatedIds);
  }

  return tagRelationsMap;
};
const getTagsSet = (
  dealer: {
    readonly tags?:
      | readonly ({
          readonly id: string;
          readonly value: string | null;
        } | null)[]
      | undefined;
  } | null,
  tagId: string
) => {
  const tagsSet = new Set();

  dealer?.tags?.forEach((tag) => {
    // Don't include current tag inside set as we don't want duplication to check against itself
    if (tag && tag.id !== tagId) {
      tagsSet.add(tag.value);
    }
  });

  return tagsSet;
};
const saveTagMutation = graphql`
  mutation TagEditSaveTagMutation($input: TagInput!) {
    saveTag(tag: $input) {
      ... on SaveTagSuccessPayload {
        tag {
          id
          name
          description
          label
          value
          billingDealerId
        }
        status
      }
      ... on UnknownError {
        type
      }
    }
  }
`;
const deleteTagMutation = graphql`
  mutation TagEditDeleteTagMutation($encodedTagId: ID!) {
    deleteTag(encodedTagId: $encodedTagId) {
      ... on DeleteTagSuccessPayload {
        dealer {
          tags {
            id
          }
        }
        deletedTagId
        status
      }
      ... on UnknownError {
        type
      }
    }
  }
`;
const Container = ({ children }: { children: React.ReactNode }) => (
  <div className="col-md-6">
    <div className="panel">
      <div className="panel-body"></div>
      {children}
    </div>
  </div>
);
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 10px 0px;
`;
const ButtonGroup = styled.div`
  min-width: 67px;
  margin-right: 20px;
`;
const BoldH5 = styled.h5`
  font-weight: bold;
`;

export default TagEdit;
