/**
 * @generated SignedSource<<0aaae7b19cb664fef59ba5a980edd3da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsEntreZoneReportsField_remoteOptions$data = {
  readonly entreConnection?: RemoteOptionsConnectionType | null;
  readonly entreZoneReports?: boolean | null;
  readonly " $fragmentType": "RemoteOptionsEntreZoneReportsField_remoteOptions";
};
export type RemoteOptionsEntreZoneReportsField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsEntreZoneReportsField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsEntreZoneReportsField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entreZoneReports",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entreConnection",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsEntreZoneReportsField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "TMSentryRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "58f38bf9c1cecf5dae4bdd0ca08f73c8";

export default node;
