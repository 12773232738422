import { compose, descend, multiply, prop, sortWith } from "ramda";
import { isNotNullOrUndefinedOrEmpty } from "./function";

export const secondsInMs = multiply(1000);
export const minutesInMs = compose(secondsInMs, multiply(60));
export const hoursInMs = compose(minutesInMs, multiply(60));
export const daysInMs = compose(hoursInMs, multiply(24));
export const weeksInMs = compose(daysInMs, multiply(7));

export function cleanUpDateString(strDate: string): string {
  // Reformat a - AM, am - AM, p - PM, pm - PM, etc.
  const formatTimePeriod = (str: string) =>
    str.toUpperCase().includes("P") ? "PM" : "AM";

  // 8 -> 8:00 OR 11 -> 11:00 OR 08 -> 08:00
  if (/^[0-2]?[0-9]$/.test(strDate)) {
    return `${strDate}:00`;
  }

  // 800 -> 8:00 or 0800 -> 08:00
  if (/^[0-2]?[0-9][0-9][0-9]$/.test(strDate)) {
    return `${strDate.slice(0, strDate.length - 2)}:${strDate.slice(-2)}`;
  }

  // 8.00 or 08.00 or 8;00 or 08;00
  if (/^[0-2]?[0-9](;|\.|,)[0-9][0-9]$/.test(strDate)) {
    return `${strDate.slice(0, strDate.length - 3)}:${strDate.slice(-2)}`;
  }

  // 8p or 8 p or 8pm or 8 pm
  if (/^[0-1]?[0-9][ ]?([APap][mM]?)$/.test(strDate)) {
    // Get the a, p, am, pm, A, P, AM, PM, etc
    const [suffix] = strDate.match(/([APap][mM]?)/) ?? ["a"];
    // Strip off the suffix, and the rest is the prefix
    const prefix = strDate.replace(suffix, "").trim();
    return `${prefix}:00 ${formatTimePeriod(suffix)}`;
  }

  // 8:00pm or 8:00 PM, etc.
  if (/^[0-1]?[0-9](:|;|\.|,)?[0-9][0-9][ ]?([APap][mM]?)$/.test(strDate)) {
    // Get the a, p, am, pm, A, P, AM, PM, etc
    const [suffix] = strDate.match(/([APap][mM]?)/) ?? ["a"];
    // Strip off the suffix, then trim it, and the rest is the prefix
    let prefix = strDate.replace(suffix, "").trim();
    if (prefix.match(/(:|;|\.|,)/)) {
      // 8;00 to 8:00, 12,00 to 12:00, etc.
      prefix = `${prefix.slice(0, prefix.length - 3)}:${prefix.slice(-2)}`;
    } else {
      prefix = `${prefix.slice(0, prefix.length - 2)}:${prefix.slice(-2)}`;
    }
    return `${prefix} ${formatTimePeriod(suffix)}`;
  }

  return strDate.trim();
}

export const getTimeFormat = (value: string): "12hr" | "24hr" | "invalid" => {
  if (
    /^((0?[0-9])|(1[0-2]))(:|.|,|;|s)?([0-5][0-9])[ ]?[apAP][mM]$/i.test(value)
  ) {
    return "12hr";
  }

  if (/^([01]?[0-9]|2[0-3])[:;][0-5][0-9]$/i.test(value)) {
    return "24hr";
  }

  return "invalid";
};

export function isValidDate(date: any): boolean {
  // @ts-ignore - https://stackoverflow.com/a/1353711
  return date instanceof Date && !isNaN(date);
}

/**
 * This exists to use point free
 */
export function newDate(v: string | number | Date) {
  return new Date(v);
}

export function toUTCDate(isoString: string) {
  const date = new Date();

  const [year, month, day, hours, minutes, seconds] = isoString
    .split(/[-T:\s.]/gim)
    .map(Number);

  date.setUTCFullYear(year, month - 1, day);
  date.setUTCHours(hours, minutes, seconds);

  return date;
}

export const getCountdown = (currentDate: Date, targetDate: Date) => {
  const valueOfTarget = targetDate.valueOf();
  const valueOfCurrent = currentDate.valueOf();

  const diff = valueOfTarget - valueOfCurrent;
  const days = Math.floor(diff / daysInMs(1));
  const hours = Math.floor((diff % daysInMs(1)) / hoursInMs(1));
  const minutes = Math.floor((diff % hoursInMs(1)) / minutesInMs(1));
  const seconds = Math.floor((diff % minutesInMs(1)) / secondsInMs(1));
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const formatCountdown = (expiresAt: number, seconds: boolean) => {
  const formatDays = (days: number) =>
    days === 0 ? `` : days === 1 ? `${days} Day` : `${days} Days`;
  const formatHours = (hours: number) =>
    hours === 0 ? `` : hours === 1 ? `${hours} Hour` : `${hours} Hours`;
  const formatMinutes = (minutes: number) =>
    minutes === 0
      ? ``
      : minutes === 1
      ? `${minutes} Minute`
      : `${minutes} Minutes`;
  const formatSeconds = (seconds: number) =>
    seconds === 1 ? `${seconds} Second` : `${seconds} Seconds`;

  const timeRemaining = getCountdown(new Date(), new Date(expiresAt));
  if (timeRemaining.days || timeRemaining.hours || timeRemaining.minutes) {
    return [
      formatDays(timeRemaining.days),
      formatHours(timeRemaining.hours),
      formatMinutes(timeRemaining.minutes),
    ]
      .filter(Boolean)
      .join(` `);
  }

  return seconds ? `${formatSeconds(timeRemaining.seconds)}` : "0 minutes";
};

const EMPTY_DEFAULT_DATE = "000000";

/**
 * @description Convert date string formatted as DDMMYY into Date
 * @param unformattedString date string in format DDMMYY
 * @returns Date
 */
export const createISODateStringFromDDMMYY = (unformattedString: string) => {
  const rawDateString =
    unformattedString !== EMPTY_DEFAULT_DATE &&
    isNotNullOrUndefinedOrEmpty(unformattedString)
      ? unformattedString
      : new Date().toISOString();
  const formattedString = rawDateString.match(/^\d{6}$/gim)
    ? `20${rawDateString.slice(-2)}-${rawDateString.slice(
        2,
        4
      )}-${rawDateString.slice(0, 2)}T00:00:00`
    : rawDateString;

  return new Date(formattedString);
};

/**
 * @description Sort an array of objects by a given date string.
 * @param arrayOfObjects Your array of objects.
 * @param keyToSortBy The key in your object for your date string. For example, `created_at`.
 * @returns A sorted array of objects.
 */
export const sortArrayOfObjectsByDate = <T>(
  arrayOfObjects: readonly T[],
  keyToSortBy: keyof T
) => {
  const sortByDate = sortWith([
    descend<any>(compose(Date.parse, prop(String(keyToSortBy)))),
  ]);
  return sortByDate(arrayOfObjects);
};
