/**
 * @generated SignedSource<<f716a8003d60ebcb529607f747610706>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecorderCameraDetectionLineSettings_varHubCamera$data = {
  readonly cameraName: string;
  readonly detectionLines: ReadonlyArray<{
    readonly id: string;
    readonly slotNumber: number | null;
    readonly zone: {
      readonly number: string;
    } | null;
  }>;
  readonly id: string;
  readonly " $fragmentType": "RecorderCameraDetectionLineSettings_varHubCamera";
};
export type RecorderCameraDetectionLineSettings_varHubCamera$key = {
  readonly " $data"?: RecorderCameraDetectionLineSettings_varHubCamera$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionLineSettings_varHubCamera">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecorderCameraDetectionLineSettings_varHubCamera",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cameraName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VarHubCameraDetectionLine",
      "kind": "LinkedField",
      "name": "detectionLines",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slotNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Zone",
          "kind": "LinkedField",
          "name": "zone",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "VarConnectedCamera",
  "abstractKey": null
};
})();

(node as any).hash = "692e8754261452d3698663947bc64c45";

export default node;
