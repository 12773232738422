/**
 *
 * Widget
 * @author Chad Watson
 *
 */

import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../../utils/styles";
import CaretMenu from "../CaretMenu";
import { BORDER_RADIUS } from "../constants/index";
import { themePanelBorder } from "../containers/Theme";
import EditableLabel from "../EditableLabel";
import { MenuItemProps } from "../PopUpMenu/MenuItem";
import Small from "../Small";
import StatusIndicators from "../StatusIndicators";
import { themeGray } from "../Theme";

export { default as LoadingStatusMessage } from "./LoadingStatusMessage";

const Widget = ({
  className,
  statusMessage,
  icon,
  editableTitle,
  title,
  onTitleChange,
  onTitleCommit,
  defaultFocusTitle,
  statusIndicators,
  loading,
  error,
  globalControls,
  menu,
  children,
  ...rest
}: {
  className?: string;
  statusMessage?: React.ReactNode;
  icon?: React.ReactNode;
  editableTitle?: boolean;
  title?: string | React.ReactNode;
  onTitleChange?: () => void;
  onTitleCommit?: () => void;
  defaultFocusTitle?: boolean;
  statusIndicators?: boolean;
  loading?: boolean;
  error?: boolean;
  globalControls?: React.ReactNode[];
  menu?: MenuItemProps[];
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Root className={className} hasFooter={!!statusMessage} {...rest}>
      <Header>
        <TitleWrapper>
          <IconWrap>{icon}</IconWrap>

          <TitleTextWrapper>
            {editableTitle && onTitleChange ? (
              <EditableTitle
                id={`widget-title-${title?.toLowerCase().replace(" ", "-")}`}
                value={title}
                onChange={onTitleChange}
                onCommit={onTitleCommit}
                defaultFocused={defaultFocusTitle}
              />
            ) : (
              <Title>{title}</Title>
            )}
          </TitleTextWrapper>
          {statusIndicators ?? (
            <StatusIndicators loading={loading} error={!loading && error} />
          )}
        </TitleWrapper>
        <HeaderRight>
          {!!globalControls && <Controls>{globalControls}</Controls>}
          {!!menu && <CaretMenu items={menu} />}
        </HeaderRight>
      </Header>

      {children}

      {!!statusMessage && (
        <Footer>
          <FooterText>{statusMessage}</FooterText>
        </Footer>
      )}
    </Root>
  );
};

export default Widget;

export const widgetPaddingX = css`
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.medium`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `};
`;

export const widgetPaddingY = css`
  padding-top: 0.5rem;
  padding-bottom: ${({ hasFooter }: { hasFooter: boolean }) =>
    hasFooter ? 0.5 : 1.25}rem;

  ${media.medium`
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
  `};
`;

const Root = styled.div`
  ${widgetPaddingY};
  position: relative;
  display: flex;
  flex-direction: column;
  border: ${themePanelBorder};
  border-radius: ${BORDER_RADIUS};
  background: white;
  text-align: center;
`;
const Header = styled.div`
  ${widgetPaddingX};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  margin-bottom: 10px;
  color: ${themeGray};
`;
const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 18px;
  line-height: 1.25;
`;
const TitleTextWrapper = styled.div`
  margin: 0 7px;
  overflow: hidden;

  ${({ hasOverflow }: { hasOverflow?: boolean }) =>
    hasOverflow &&
    css`
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
        );
      }
    `};
`;
const EditableTitle = styled(EditableLabel)`
  font-size: inherit;
  font-weight: bold;
  text-align: left;
  line-height: inherit;
`;
const Title = styled.h1`
  margin: 0;
  font-size: 1em;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const IconWrap = styled.div`
  display: inline-block;
  font-size: 1.2em;

  & svg {
    display: block;
  }
`;
const HeaderRight = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 5px;
`;
const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const footerHeight = 20; // px
const Footer = styled.footer`
  ${widgetPaddingX};
  height: ${footerHeight}px;
  margin-top: 10px;
  line-height: ${footerHeight}px;
`;
const FooterText = styled(Small)`
  vertical-align: top;
`;
