import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { remoteOptionsApnFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsApnField";
import { remoteOptionsAppKeyFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import { remoteOptionsRemoteDisarmFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemoteDisarmField";
import { XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XT75FullProgramming/__generated__/XT75RemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getRemoteOptionsState } from "../../../XT75FullProgramming/XT75RemoteOptionsProgrammingConceptForm";

import { Xt75ProgrammingTemplateRemoteOptionsInput } from "../Create/__generated__/XT75TemplateSaveMutation.graphql";
import { XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts.graphql";
import { remoteOptionsIntegratorArmDisarmReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorArmDisarmReportsField";
import { remoteOptionsIntegratorBackupConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorBackupConnectionField";
import { remoteOptionsIntegratorBackupIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorBackupIpAddressField";
import { remoteOptionsIntegratorBackupTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorBackupTcpPortField";
import { remoteOptionsIntegratorConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorConnectionField";
import { remoteOptionsIntegratorDoorReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorDoorReportsField";
import { remoteOptionsIntegratorIncomingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorIncomingTcpPortField";
import { remoteOptionsIntegratorIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorIpAddressField";
import { remoteOptionsIntegratorOutgoingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorOutgoingTcpPortField";
import { remoteOptionsIntegratorPassphraseFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorPassphraseField";
import { remoteOptionsIntegratorSupervisoryReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorSupervisoryReportsField";
import { remoteOptionsIntegratorUserCommandReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorUserCommandReportsField";
import { remoteOptionsIntegratorVideoReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorVideoReportsField";
import { remoteOptionsIntegratorZoneReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorZoneReportsField";

export const getState = (
  template: XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          id
          included
          remoteDisarm {
            data
            included
          }
          appKey {
            data
            included
          }
          firstApn {
            data
            included
          }
          integratorConnection {
            data
            included
          }
          integratorIncomingPort {
            data
            included
          }
          integratorIpAddress {
            data
            included
          }
          integratorOutgoingPort {
            data
            included
          }
          integratorBackupConnection {
            data
            included
          }
          integratorBackupIpAddress {
            data
            included
          }
          integratorBackupIncomingPort {
            data
            included
          }
          integratorArmDisarmReports {
            data
            included
          }
          integratorZoneReports {
            data
            included
          }
          integratorUserCommandReports {
            data
            included
          }
          integratorDoorReports {
            data
            included
          }
          integratorSupervisoryReports {
            data
            included
          }
          integratorPassphrase {
            data
            included
          }
          integratorVideoReports {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): Xt75ProgrammingTemplateRemoteOptionsInput => {
  const {
    panel: { remoteOptions },
  } = getRemoteOptionsState(controlSystem);

  if (!remoteOptions) {
    return { included: false };
  }

  const remoteOptionsInputFields =
    remoteOptions?.__typename === "Xt75RemoteOptions"
      ? {
        remoteDisarm: {
          data: remoteOptions.remoteDisarm,
          included: includedFields.has(remoteOptionsRemoteDisarmFieldId()),
        },
        appKey: {
          data: remoteOptions.appKey,
          included: includedFields.has(remoteOptionsAppKeyFieldId()),
        },
        firstApn: {
          data: remoteOptions.firstApn,
          included: includedFields.has(remoteOptionsApnFieldId()),
        },
        integratorConnection: {
          data: remoteOptions.integratorConnection,
          included: includedFields.has(
            remoteOptionsIntegratorConnectionFieldId()
          ),
        },
        integratorIncomingPort: {
          data: remoteOptions.integratorIncomingPort,
          included: includedFields.has(
            remoteOptionsIntegratorIncomingTcpPortFieldId()
          ),
        },
        integratorIpAddress: {
          data: remoteOptions.integratorIpAddress,
          included: includedFields.has(
            remoteOptionsIntegratorIpAddressFieldId()
          ),
        },
        integratorOutgoingPort: {
          data: remoteOptions.integratorOutgoingPort,
          included: includedFields.has(
            remoteOptionsIntegratorOutgoingTcpPortFieldId()
          ),
        },
        integratorBackupConnection: {
          data: remoteOptions.integratorBackupConnection,
          included: includedFields.has(
            remoteOptionsIntegratorBackupConnectionFieldId()
          ),
        },
        integratorBackupIpAddress: {
          data: remoteOptions.integratorBackupIpAddress,
          included: includedFields.has(
            remoteOptionsIntegratorBackupIpAddressFieldId()
          ),
        },
        integratorBackupIncomingPort: {
          data: remoteOptions.integratorBackupIncomingPort,
          included: includedFields.has(
            remoteOptionsIntegratorBackupTcpPortFieldId()
          ),
        },
        integratorArmDisarmReports: {
          data: remoteOptions.integratorArmDisarmReports,
          included: includedFields.has(
            remoteOptionsIntegratorArmDisarmReportsFieldId()
          ),
        },
        integratorZoneReports: {
          data: remoteOptions.integratorZoneReports,
          included: includedFields.has(
            remoteOptionsIntegratorZoneReportsFieldId()
          ),
        },
        integratorUserCommandReports: {
          data: remoteOptions.integratorUserCommandReports,
          included: includedFields.has(
            remoteOptionsIntegratorUserCommandReportsFieldId()
          ),
        },
        integratorDoorReports: {
          data: remoteOptions.integratorDoorReports,
          included: includedFields.has(
            remoteOptionsIntegratorDoorReportsFieldId()
          ),
        },
        integratorSupervisoryReports: {
          data: remoteOptions.integratorSupervisoryReports,
          included: includedFields.has(
            remoteOptionsIntegratorSupervisoryReportsFieldId()
          ),
        },
        integratorPassphrase: {
          data: remoteOptions.integratorPassphrase,
          included: includedFields.has(
            remoteOptionsIntegratorPassphraseFieldId()
          ),
        },
        integratorVideoReports: {
          data: remoteOptions.integratorVideoReports,
          included: includedFields.has(
            remoteOptionsIntegratorVideoReportsFieldId()
          ),
        },
      }
      : {};

  const remoteOptionsIncluded = Object.values(remoteOptionsInputFields).some(
    (field) => field?.included
  );

  return { included: remoteOptionsIncluded, ...remoteOptionsInputFields };
};

export const setIncludedFields = (
  template: XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { remoteOptions } = getState(template);

  if (!remoteOptions) return;

  if (remoteOptions.remoteDisarm?.included) {
    setIncludedFields(setToggle(remoteOptionsRemoteDisarmFieldId()));
  }
  if (remoteOptions.appKey?.included) {
    setIncludedFields(setToggle(remoteOptionsAppKeyFieldId()));
  }
  if (remoteOptions.firstApn?.included) {
    setIncludedFields(setToggle(remoteOptionsApnFieldId()));
  }
  if (remoteOptions.integratorConnection?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorConnectionFieldId()));
  }
  if (remoteOptions.integratorIncomingPort?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorIncomingTcpPortFieldId())
    );
  }
  if (remoteOptions.integratorIpAddress?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorIpAddressFieldId()));
  }
  if (remoteOptions.integratorOutgoingPort?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorOutgoingTcpPortFieldId())
    );
  }
  if (remoteOptions.integratorBackupConnection?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorBackupConnectionFieldId())
    );
  }
  if (remoteOptions.integratorBackupIpAddress?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorBackupIpAddressFieldId())
    );
  }
  if (remoteOptions.integratorBackupIncomingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorBackupTcpPortFieldId()));
  }
  if (remoteOptions.integratorArmDisarmReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorArmDisarmReportsFieldId())
    );
  }
  if (remoteOptions.integratorZoneReports?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorZoneReportsFieldId()));
  }
  if (remoteOptions.integratorUserCommandReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorUserCommandReportsFieldId())
    );
  }
  if (remoteOptions.integratorDoorReports?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorDoorReportsFieldId()));
  }
  if (remoteOptions.integratorSupervisoryReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorSupervisoryReportsFieldId())
    );
  }
  if (remoteOptions.integratorPassphrase?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorPassphraseFieldId()));
  }
  if (remoteOptions.integratorVideoReports?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorVideoReportsFieldId()));
  }
};
