/**
 * @generated SignedSource<<591dadd96534ec44388e11b2781d0703>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AllowTimeChange = "NO" | "SERVER" | "YES";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsObserveDstField_systemOptions$data = {
  readonly observeDst?: boolean | null;
  readonly timeChange: AllowTimeChange;
  readonly " $fragmentType": "SystemOptionsObserveDstField_systemOptions";
};
export type SystemOptionsObserveDstField_systemOptions$key = {
  readonly " $data"?: SystemOptionsObserveDstField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsObserveDstField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "observeDst",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsObserveDstField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "TMSentrySystemOptions",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeChange",
      "storageKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "0edb112fda15c92233f4de8b2d1a11c6";

export default node;
