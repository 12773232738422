import graphql from "babel-plugin-relay/macro";
import KeyfobButtonProgrammingFields from "components/KeyfobButtonProgrammingFields";
import * as React from "react";
import { useFragment } from "react-relay";
import { KeyfobNumberOfButtons } from "securecom-graphql/client";
import { KeyfobFields_keyfob$key } from "../../common/KeyfobFields/__generated__/KeyfobFields_keyfob.graphql";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import KeyfobButtonActionField from "./KeyfobButtonActionField";
import KeyfobButtonAreasField from "./KeyfobButtonAreasField";
import KeyfobButtonOutputActionField from "./KeyfobButtonOutputActionField";
import KeyfobButtonOutputField from "./KeyfobButtonOutputField";
import KeyfobButtonPressTimeField from "./KeyfobButtonPressTimeField";
import { KeyfobContextProvider } from "./KeyfobContext";
import KeyfobNameField from "./KeyfobNameField";
import KeyfobNumberField from "./KeyfobNumberField";
import KeyfobNumberOfButtonsField from "./KeyfobNumberOfButtonsField";
import KeyfobSerialNumberField from "./KeyfobSerialNumberField";
import KeyfobSupervisionTimeField from "./KeyfobSupervisionTimeField";
import KeyfobUsernameField from "./KeyfobUsernameField";
import KeyfobUserNumberField from "./KeyfobUserNumberField";

function KeyfobFields(props: { keyfob: KeyfobFields_keyfob$key }) {
  const data = useFragment(
    graphql`
      fragment KeyfobFields_keyfob on Keyfob {
        id
        number
        numberOfButtons
        keyfobName
        ...KeyfobContext_keyfob
        ...KeyfobNumberField_keyfob
        ...KeyfobNameField_keyfob
        ...KeyfobNumberOfButtonsField_keyfob
        ...KeyfobUserNumberField_keyfob
        ...KeyfobUsernameField_keyfob
        ...KeyfobSerialNumberField_keyfob
        ...KeyfobSupervisionTimeField_keyfob
        ...KeyfobButtonActionField_keyfob
        ...KeyfobButtonPressTimeField_keyfob
        ...KeyfobButtonOutputField_keyfob
        ...KeyfobButtonOutputActionField_keyfob
        ...KeyfobButtonAreasField_keyfob
      }
    `,
    props.keyfob
  );
  const { numberOfButtons } = data;

  return (
    <KeyfobContextProvider keyfob={data}>
      <ProgrammingConceptForm.Fields>
        <KeyfobNumberField />
        <KeyfobNumberOfButtonsField />
        <KeyfobNameField />
        <KeyfobUsernameField />
        <KeyfobUserNumberField />
        <KeyfobSerialNumberField />
        <KeyfobSupervisionTimeField />
      </ProgrammingConceptForm.Fields>
      {/* Button 1 */}
      <KeyfobButtonProgrammingFields
        buttonTitle={
          numberOfButtons === KeyfobNumberOfButtons.ONE ? "Button" : "Top"
        }
      >
        <ProgrammingConceptForm.Fields>
          <KeyfobButtonActionField buttonNumber="One" />
          <KeyfobButtonPressTimeField buttonNumber="One" />
          <KeyfobButtonAreasField buttonNumber="One" />
          <KeyfobButtonOutputField buttonNumber="One" />
          <KeyfobButtonOutputActionField buttonNumber="One" />
        </ProgrammingConceptForm.Fields>
      </KeyfobButtonProgrammingFields>
      {numberOfButtons !== KeyfobNumberOfButtons.ONE ? (
        <>
          {numberOfButtons === KeyfobNumberOfButtons.FOUR ? (
            <>
              {/* Button 2 */}
              <KeyfobButtonProgrammingFields buttonTitle="Left">
                <ProgrammingConceptForm.Fields>
                  <KeyfobButtonActionField buttonNumber="Two" />
                  <KeyfobButtonPressTimeField buttonNumber="Two" />
                  <KeyfobButtonAreasField buttonNumber="Two" />
                  <KeyfobButtonOutputField buttonNumber="Two" />
                  <KeyfobButtonOutputActionField buttonNumber="Two" />
                </ProgrammingConceptForm.Fields>
              </KeyfobButtonProgrammingFields>
              {/* Button 3 */}
              <KeyfobButtonProgrammingFields buttonTitle="Right">
                <ProgrammingConceptForm.Fields>
                  <KeyfobButtonActionField buttonNumber="Three" />
                  <KeyfobButtonPressTimeField buttonNumber="Three" />
                  <KeyfobButtonAreasField buttonNumber="Three" />
                  <KeyfobButtonOutputField buttonNumber="Three" />
                  <KeyfobButtonOutputActionField buttonNumber="Three" />
                </ProgrammingConceptForm.Fields>
              </KeyfobButtonProgrammingFields>
            </>
          ) : null}
          {/* Button 4 */}
          <KeyfobButtonProgrammingFields buttonTitle="Bottom">
            <ProgrammingConceptForm.Fields>
              <KeyfobButtonActionField buttonNumber="Four" />
              <KeyfobButtonPressTimeField buttonNumber="Four" />
              <KeyfobButtonAreasField buttonNumber="Four" />
              <KeyfobButtonOutputField buttonNumber="Four" />
              <KeyfobButtonOutputActionField buttonNumber="Four" />
            </ProgrammingConceptForm.Fields>
          </KeyfobButtonProgrammingFields>
        </>
      ) : null}
    </KeyfobContextProvider>
  );
}

export default KeyfobFields;
