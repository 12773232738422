import graphql from "babel-plugin-relay/macro";
import { prop } from "ramda";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useMutation } from "react-relay/hooks";
import { AreaArmedStatusEnum } from "securecom-graphql/client";
import styled, { css } from "styled-components";
import { capitalize } from "../../../utils";
import { Zone_zone$key } from "../ArmingWidget/__generated__/Zone_zone.graphql";
import { useTheme } from "../containers/Theme";
import { ArmedShieldSolidIcon } from "../Icons";
import { themeGrayMedium, themeTextColor } from "../Theme";
import Tooltip from "../Tooltip";
import ZoneBypassSwitch from "./ZoneBypassSwitch";
import ZoneStatusIndicator from "./ZoneStatusIndicator";
const BORDER_BOTTOM_WIDTH_IN_PX = 1;
const LIST_ITEM_HEIGHT_IN_PX = 40;
export const zoneListItemStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${LIST_ITEM_HEIGHT_IN_PX}px;
  padding: 10px;
  border-bottom: ${BORDER_BOTTOM_WIDTH_IN_PX}px solid rgba(0, 0, 0, 0.1);
  text-align: left;
  color: ${themeTextColor};

  &:first-child {
    padding-top: 10px;
  }
`;
const Li = styled.li`
  ${zoneListItemStyle};
  padding-left: 20px;
`;
export const TitleColumn = styled.div`
  flex: 11;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Columns = styled.div<{ hasBypass: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: ${({ hasBypass }) => (hasBypass ? 9 : 4)};
`;
export const Column = styled.div<{ minWidth: number }>`
  min-width: ${({ minWidth }) => minWidth}px;
  flex: 1;
  text-align: center;
`;

const StatusMessage = styled.span`
  color: ${themeGrayMedium};
  font-size: 13px;
`;
const TooltipMessage = styled.p`
  width: 200px;
  padding: 0.5em 1em;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
`;
const SwitchWrapper = styled.div`
  display: inline-block;
  position: relative;
`;
const ArmedIndicator = styled.span`
  display: inline-block;
  margin-right: 0.2em;
  color: ${prop("color")};
`;

const bypassMutation = graphql`
  mutation ZoneBypassMutation($id: ID!) {
    bypassZone(id: $id) {
      ... on BypassZoneSuccessPayload {
        zone {
          id
          status
        }
      }
    }
  }
`;

const resetBypassMutation = graphql`
  mutation ZoneBypassResetMutation($id: ID!) {
    resetBypassZone(id: $id) {
      ... on ResetBypassZoneSuccessPayload {
        zone {
          id
          status
        }
      }
    }
  }
`;

type Props = {
  zone: Zone_zone$key;
  canBypassZones: boolean;
};

const Zone = ({ zone, canBypassZones }: Props) => {
  const data = useFragment(
    graphql`
      fragment Zone_zone on Zone {
        id
        name
        type
        canBypass
        status
        is24HrZone
        number
        area {
          id
          armedStatus
          number
        }
        controlSystem {
          isECP
          isDSC
          userCanBypassZones
        }
        ...ZoneStatusIndicator_zone
        ...ZoneBypassSwitch_zone
      }
    `,
    zone
  );

  const theme = useTheme();

  const [bypassZone, isBypassingZone] = useMutation(bypassMutation);

  const [resetBypassZone, isResetBypassingZone] =
    useMutation(resetBypassMutation);

  const showStatusLabel =
    !data.controlSystem.isECP &&
    !data.controlSystem.isDSC &&
    !(isBypassingZone && isResetBypassingZone && canBypassZones);

  const systemHasBypass =
    data.controlSystem.userCanBypassZones &&
    !data.controlSystem.isECP &&
    !data.controlSystem.isDSC &&
    canBypassZones;

  return (
    <Li>
      <TitleColumn>
        <ArmedIndicator
          color={
            data.area?.armedStatus === AreaArmedStatusEnum.ARMED
              ? theme.primary500
              : theme.grayMediumLight
          }
        >
          <ArmedShieldSolidIcon />
        </ArmedIndicator>
        &nbsp;
        {data.name}
      </TitleColumn>
      <Columns hasBypass={systemHasBypass}>
        <Column minWidth={80}>{data.number}</Column>
        <Column minWidth={80}>
          {!!data.status && (
            <div>
              <React.Suspense fallback={"loading..."}>
                <ZoneStatusIndicator
                  isBypassingZone={isBypassingZone}
                  isResetBypassingZone={isResetBypassingZone}
                  zone={data}
                />
              </React.Suspense>

              {showStatusLabel && (
                <StatusMessage>
                  {
                    <FormattedMessage
                      description="Zone Status Message"
                      defaultMessage="{status}"
                      values={{ status: capitalize(data.status.toLowerCase()) }}
                    />
                  }
                </StatusMessage>
              )}
            </div>
          )}
        </Column>

        {systemHasBypass && (
          <Column minWidth={80}>
            {data.canBypass && (
              <Tooltip
                anchor={({ registerAnchor }) => (
                  <ZoneBypassSwitch
                    zone={data}
                    bypassZone={bypassZone}
                    resetBypassZone={resetBypassZone}
                  />
                )}
              >
                {data.is24HrZone && (
                  <TooltipMessage>
                    <FormattedMessage
                      description="Zone Cannot Bypass Message"
                      defaultMessage="Zone is currently armed. Cannot change bypass."
                    />
                  </TooltipMessage>
                )}
              </Tooltip>
            )}
          </Column>
        )}
      </Columns>
    </Li>
  );
};

export default Zone;
