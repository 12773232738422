import {
  Coordinates,
  CoordinatesTransformer,
} from "components/CameraEditCommon/types";
import { RecordProxy, RecordSourceProxy } from "relay-runtime";
import {
  VarHubCameraDetectionLineWithClientSchemaData,
  VarHubCameraDetectionRegionWithClientSchemaData,
  VarHubCameraWithClientSchemaData,
} from "./types";

export const transformForKeyPress =
  (transformer: CoordinatesTransformer) =>
  (region: RecordProxy<VarHubCameraDetectionRegionWithClientSchemaData>) => {
    const activeCoordinates = new Set(
      region.getValue("activeCoordinateIndexes") ?? []
    );
    const geometry = region.getLinkedRecord("geometry");
    const coordinates = [
      ...geometry.getValue("coordinates")[0],
    ] as Coordinates[];

    if (activeCoordinates.size) {
      activeCoordinates.forEach((coordinateIndex) => {
        if (coordinateIndex in coordinates) {
          coordinates[coordinateIndex] = transformer(
            coordinates[coordinateIndex]
          );
        }
      });
      geometry.setValue([syncEndPoints(coordinates)], "coordinates");
    } else {
      geometry.setValue(
        [syncEndPoints(coordinates.map(transformer))],
        "coordinates"
      );
    }
  };

export const transformLineForKeyPress =
  (transformer: CoordinatesTransformer) =>
  (line: RecordProxy<VarHubCameraDetectionLineWithClientSchemaData>) => {
    const activeCoordinates = new Set(
      line.getValue("activeCoordinateIndexes") ?? []
    );
    const geometry = line.getLinkedRecord("geometry");
    const coordinates = [...geometry.getValue("coordinates")] as Coordinates[];

    if (activeCoordinates.size) {
      activeCoordinates.forEach((coordinateIndex) => {
        if (coordinateIndex in coordinates) {
          coordinates[coordinateIndex] = transformer(
            coordinates[coordinateIndex]
          );
        }
      });
      geometry.setValue(coordinates, "coordinates");
    } else {
      geometry.setValue(
        coordinates.map(transformer),
        "coordinates"
      );
    }
  };

export const translate =
  (transformer: (coordinates: Coordinates[]) => Coordinates[]) =>
  (region: RecordProxy<VarHubCameraDetectionRegionWithClientSchemaData>) => {
    const activeCoordinates = new Set(
      region.getValue("activeCoordinateIndexes") ?? []
    );
    const geometry = region.getLinkedRecord("geometry");
    const coordinates = [
      ...geometry.getValue("coordinates")[0],
    ] as Coordinates[];

    if (activeCoordinates.size) {
      const activeCoordinatesArray = [...activeCoordinates];
      const translatedCoordinates = transformer(
        activeCoordinatesArray.map((index) => coordinates[index])
      );
      translatedCoordinates.forEach((xy, index) => {
        coordinates[activeCoordinatesArray[index]] = xy;
      });
      geometry.setValue([syncEndPoints(coordinates)], "coordinates");
    } else {
      geometry.setValue(
        [syncEndPoints(transformer(coordinates))],
        "coordinates"
      );
    }
  };

export const translateLine =
  (transformer: (coordinates: Coordinates[]) => Coordinates[]) =>
  (line: RecordProxy<VarHubCameraDetectionLineWithClientSchemaData>) => {
    const activeCoordinates = new Set(
      line.getValue("activeCoordinateIndexes") ?? []
    );
    const geometry = line.getLinkedRecord("geometry");
    const coordinates = [...geometry.getValue("coordinates")] as Coordinates[];
    if (activeCoordinates.size) {
      const activeCoordinatesArray = [...activeCoordinates];
      const translatedCoordinates = transformer(
        activeCoordinatesArray.map((index) => coordinates[index])
      );
      translatedCoordinates.forEach((xy, index) => {
        coordinates[activeCoordinatesArray[index]] = xy;
      });
      geometry.setValue(coordinates, "coordinates");
    } else {
      geometry.setValue(transformer(coordinates), "coordinates");
    }
  };

export const syncEndPoints = (coordinates: Coordinates[]): Coordinates[] => {
  if (!coordinates.length) {
    return coordinates;
  }

  return [...coordinates.slice(0, coordinates.length - 1), coordinates[0]];
};

export const removeRegionUpdater = (
  store: RecordSourceProxy,
  cameraId: string,
  regionId: string
) => {
  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;
  const regions = camera
    .getLinkedRecords("detectionRegions")
    .filter((region) => region.getValue("id") !== regionId)
    .map((region, index) => region.setValue(index, "index"));
  camera.setLinkedRecords(regions, "detectionRegions");
  camera.setValue(null, "activeDetectionRegionIndex");
};

export const removeLineUpdater = (
  store: RecordSourceProxy,
  cameraId: string,
  lineId: string
) => {
  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;
  const lines = camera
    .getLinkedRecords("detectionLines")
    .filter((line) => line.getValue("id") !== lineId)
    .map((line, index) => line.setValue(index, "index"));
  camera.setLinkedRecords(lines, "detectionLines");
  camera.setValue(null, "activeDetectionLineIndex");
};
