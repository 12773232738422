/**
 * @generated SignedSource<<efdf81e556607ffe9183df4843ceb1db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecorderEditQuery$variables = {
  varHubId: string;
};
export type RecorderEditQuery$data = {
  readonly getVarHubFromId: {
    readonly varHub?: {
      readonly __typename: "VarHub";
      readonly aiModelVersion: string;
      readonly camectHubId: string;
      readonly cameras: ReadonlyArray<{
        readonly addedToDB: boolean;
        readonly camectCamId: string;
        readonly cameraId: number | null;
        readonly cameraName: string;
        readonly detectionRegions: ReadonlyArray<{
          readonly id: string;
          readonly loiteringPersonSeconds: number | null;
          readonly loiteringVehicleSeconds: number | null;
        } | null>;
        readonly id: string;
        readonly ipAddress: string;
      }>;
      readonly hardwareModel: string;
      readonly hardwareModelDisplayName: string;
      readonly hubId: number;
      readonly hubName: string;
      readonly id: string;
      readonly immixIntegrationEnabled: boolean;
      readonly isOnline: boolean;
      readonly lastUpdatedAt: string | null;
      readonly macAddress: string;
      readonly megapixelThresholdCritical: boolean;
      readonly megapixelThresholdWarning: boolean;
      readonly osVersion: string;
      readonly ownerEmail: string;
      readonly panelConnectionEnabled: boolean | null;
      readonly panelIntegrationGatewayIpAddress: string;
      readonly panelIntegrationLastInbound: string;
      readonly panelIntegrationLastOutbound: string;
      readonly panelIntegrationPanelDiscoveryEnabled: boolean | null;
      readonly panelIntegrationPanelIpAddress: string;
      readonly panelIntegrationSystemUptime: string;
      readonly panelIntegrationUptime: string;
      readonly panelIntegrationVersion: string;
      readonly privateIp: string;
      readonly secondaryNetworkIP: string;
      readonly secondaryNetworkSubnetMask: string;
      readonly secondaryNetworkType: string;
      readonly softwareVersion: string;
      readonly supportedMegapixels: number;
      readonly systemId: number;
      readonly timeLocation: string;
      readonly " $fragmentSpreads": FragmentRefs<"PanelConnectionBadge_varHub" | "RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub" | "VarStatusBadge_varHub">;
    };
  } | null;
};
export type RecorderEditQuery = {
  response: RecorderEditQuery$data;
  variables: RecorderEditQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "varHubId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "varHubId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectHubId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "macAddress",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aiModelVersion",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "osVersion",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownerEmail",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareVersion",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLocation",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privateIp",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hardwareModel",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hardwareModelDisplayName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "megapixelThresholdWarning",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "megapixelThresholdCritical",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "supportedMegapixels",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelIntegrationVersion",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelIntegrationUptime",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelIntegrationLastInbound",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelIntegrationLastOutbound",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryNetworkType",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryNetworkIP",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryNetworkSubnetMask",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOnline",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelConnectionEnabled",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedAt",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "immixIntegrationEnabled",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelIntegrationPanelDiscoveryEnabled",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelIntegrationPanelIpAddress",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelIntegrationSystemUptime",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelIntegrationGatewayIpAddress",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "VarHubCamera",
  "kind": "LinkedField",
  "name": "cameras",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cameraName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "camectCamId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cameraId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addedToDB",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VarHubCameraDetectionRegion",
      "kind": "LinkedField",
      "name": "detectionRegions",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loiteringPersonSeconds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loiteringVehicleSeconds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getVarHubFromId",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "varHub",
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "hub",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  (v33/*: any*/),
                  (v34/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PanelConnectionBadge_varHub"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "VarStatusBadge_varHub"
                  },
                  (v35/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "GetVarHubSuccessPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecorderEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getVarHubFromId",
        "plural": false,
        "selections": [
          (v35/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "varHub",
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "hub",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  (v33/*: any*/),
                  (v34/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "panelConnectionStatus",
                    "storageKey": null
                  },
                  (v35/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "GetVarHubSuccessPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc7e84b72a9d9055bfeebeb83a44643d",
    "id": null,
    "metadata": {},
    "name": "RecorderEditQuery",
    "operationKind": "query",
    "text": "query RecorderEditQuery(\n  $varHubId: ID!\n) {\n  getVarHubFromId(id: $varHubId) {\n    __typename\n    ... on GetVarHubSuccessPayload {\n      varHub: hub {\n        id\n        camectHubId\n        hubId\n        systemId\n        hubName\n        macAddress\n        aiModelVersion\n        osVersion\n        ownerEmail\n        softwareVersion\n        timeLocation\n        privateIp\n        hardwareModel\n        hardwareModelDisplayName\n        megapixelThresholdWarning\n        megapixelThresholdCritical\n        supportedMegapixels\n        panelIntegrationVersion\n        panelIntegrationUptime\n        panelIntegrationLastInbound\n        panelIntegrationLastOutbound\n        secondaryNetworkType\n        secondaryNetworkIP\n        secondaryNetworkSubnetMask\n        isOnline\n        panelConnectionEnabled\n        lastUpdatedAt\n        immixIntegrationEnabled\n        panelIntegrationPanelDiscoveryEnabled\n        panelIntegrationPanelIpAddress\n        panelIntegrationSystemUptime\n        panelIntegrationGatewayIpAddress\n        cameras {\n          id\n          cameraName\n          ipAddress\n          camectCamId\n          cameraId\n          addedToDB\n          detectionRegions {\n            id\n            loiteringPersonSeconds\n            loiteringVehicleSeconds\n          }\n        }\n        ...RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub\n        ...PanelConnectionBadge_varHub\n        ...VarStatusBadge_varHub\n        __typename\n      }\n    }\n  }\n}\n\nfragment PanelConnectionBadge_varHub on VarHub {\n  panelConnectionStatus\n  isOnline\n  id\n}\n\nfragment RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub on VarHub {\n  panelConnectionStatus\n  isOnline\n}\n\nfragment VarStatusBadge_varHub on VarHub {\n  isOnline\n  id\n}\n"
  }
};
})();

(node as any).hash = "c035199d55d351279b33a35f809375d1";

export default node;
