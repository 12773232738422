/**
 * @generated SignedSource<<7b1bb0904b7e6e25c77b4d07c4a02fc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatTypeField_CardFormatList_panel$data = {
  readonly cardFormats: ReadonlyArray<{
    readonly id: string;
    readonly wiegandLength: number;
  }>;
  readonly " $fragmentType": "CardFormatTypeField_CardFormatList_panel";
};
export type CardFormatTypeField_CardFormatList_panel$key = {
  readonly " $data"?: CardFormatTypeField_CardFormatList_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatTypeField_CardFormatList_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatTypeField_CardFormatList_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CardFormat",
      "kind": "LinkedField",
      "name": "cardFormats",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wiegandLength",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "15fdfbc575e066a03907625874121278";

export default node;
