import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationNameField_zone$key } from "./__generated__/ZoneInformationNameField_zone.graphql";

export const zoneInformationNameFieldId = (number: string) =>
  `zone-information-name-${number}`;

function ZoneInformationNameField() {
  const [{ number, name, isECP }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationNameField_zone$key>(
      graphql`
        fragment ZoneInformationNameField_zone on Zone {
          number
          name
          isECP
        }
      `
    );

  const fieldId = zoneInformationNameFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const { NAME: RULES } = resolveZoneFieldRules(hardwareModel);

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Zone Name">
      <TextInput
        id={fieldId}
        name={fieldId}
        required
        maxLength={RULES.MAX_LENGTH}
        value={name}
        pattern={RULES.PATTERN}
        title={RULES.VALIDATION_MSG}
        disabled={isECP}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "name");
          });
        }}
        onBlur={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value.trim().toUpperCase(), "name");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationNameField;
