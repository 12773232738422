import DaColors from "app/common/DaColors";
import DaFontWeight from "app/common/DaFontWeight";
import graphql from "babel-plugin-relay/macro";
import Modal from "common/components/web/Modal";
import { DaButton, Flex } from "components/DaStyledElements";
import { DaButtonDefault } from "components/DaStyledElements/Button";
import Icon from "components/Icon";
import { useShowAlert } from "contexts/AlertsContext";
import React from "react";
import { useMutation } from "react-relay";
import styled from "styled-components/macro";
import { ClaimSystemModalMutation } from "./__generated__/ClaimSystemModalMutation.graphql";

interface ClaimSystemModalProps {
  handleModalClose: () => void;
  UserService: any;
  $scope: any;
  $state: any;
  controlSystemService: any;
  selectedSystem: {
    dealerId: string;
    customerId: string;
    panelId: string;
    systemName: string;
    panelAccount: string;
    panelAccountNumber: string;
    panelSerialNumber: string;
  };
  onSuccessCloseModal: () => void;
}

const claimSystemModalMutation = graphql`
  mutation ClaimSystemModalMutation(
    $dealerId: String!
    $customerId: String!
    $panelId: String!
  ) {
    claimSystem(
      dealer_id: $dealerId
      customer_id: $customerId
      panel_id: $panelId
    ) {
      status
      success
    }
  }
`;

const claimSystemLineItem = (
  title: string,
  lineItem: string,
  index: number
) => (
  <ZebraRow align="center" zebraStripe={index % 2 === 0} key={title}>
    <Flex.Col>
      <LineTitle>{title}</LineTitle>
    </Flex.Col>
    <Flex.Col>
      <SystemData>{lineItem}</SystemData>
    </Flex.Col>
  </ZebraRow>
);

const ClaimSystemModal: React.FC<ClaimSystemModalProps> = ({
  handleModalClose,
  UserService,
  $scope,
  selectedSystem,
  onSuccessCloseModal,
  $state,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { dealerId, customerId, panelId } = selectedSystem;
  const [isClaimed, setIsClaimed] = React.useState(false);
  const [claimSystem, isClaimingSystem] = useMutation<ClaimSystemModalMutation>(
    claimSystemModalMutation
  );
  const [systemClaimedByOtherTech, setSystemClaimedByOtherTech] =
    React.useState(false);
  const showAlert = useShowAlert();

  const handleNavigateToClaimedSystem = ({
    dealerId,
    customerId,
    panelId,
  }: {
    dealerId: string;
    customerId: string;
    panelId: string;
  }) => {
    $state.go("app.control_system", {
      customer_id: customerId,
      control_system_id: panelId,
    });
  };

  const handleClaimSystem = () => {
    // Set loading state to true
    setIsLoading(true);
    // Check if essential variables are available
    if (!dealerId || !customerId || !panelId) {
      showAlert({
        type: "error",
        text: "Missing required information for claiming the system.",
      });
      setIsLoading(false);
      setSystemClaimedByOtherTech(false);
      return;
    }
    claimSystem({
      variables: {
        dealerId: dealerId.toString(),
        customerId: customerId.toString(),
        panelId: panelId.toString(),
      },
      onCompleted: (response) => {
        setIsLoading(false);
        if (response.claimSystem.success) {
          // code 202
          setIsClaimed(true);
          showAlert({
            type: "success",
            text: `Claimed System: ${selectedSystem.systemName}`,
          });
          handleNavigateToClaimedSystem({
            dealerId,
            customerId,
            panelId,
          });
          onSuccessCloseModal();
        } else {
          // code 410 is returned
          showAlert({
            type: "error",
            text: `Error Claiming System \n\r Status Code: ${response.claimSystem.status}`,
          });
          setSystemClaimedByOtherTech(true);
        }
      },

      onError: (err) => {
        setIsLoading(false);
        const errorMessage = err.message || "An unknown error occurred.";
        console.error(err);
        showAlert({
          type: "error",
          text: `Error occurred: ${errorMessage}`,
        });
      },
    });
  };

  const systemDetails = [
    { title: "System Name:", data: selectedSystem.systemName || "missing" },
    {
      title: "Account Number:",
      data: selectedSystem.panelAccount
        ? `${selectedSystem.panelAccount}-${selectedSystem.panelAccountNumber}`
        : "missing",
    },
    { title: "Serial #:", data: selectedSystem.panelSerialNumber || "missing" },
  ];

  return (
    <Modal>
      <ModalWrapper>
        <ModalTitle>Claim System</ModalTitle>

        {systemDetails.map(({ title, data }, index) =>
          claimSystemLineItem(title, data.toString(), index)
        )}

        <PaddedTextRow align="center">
          <Flex.Col>
            <h3>
              {!isClaimed
                ? "Would you like to claim this system?"
                : systemClaimedByOtherTech
                ? "Claimed System"
                : null}
            </h3>
          </Flex.Col>
        </PaddedTextRow>

        <PaddedTextRow align="center">
          <Flex.Col>
            {systemClaimedByOtherTech ? (
              <TextBody>
                <Alert>
                  <Icon name="radial_alert" size="1.6rem" className="mar-r-8" />{" "}
                  This system has already been claimed by another technician.
                </Alert>
              </TextBody>
            ) : isClaimed ? (
              <>
                <TextBody>
                  By claiming this system, other technicians that require a
                  system test will not be able to access the system until
                  another local system test is run.
                </TextBody>
                <TextBody>
                  Once claimed, you will be able to access the system for 8
                  hours.
                </TextBody>
              </>
            ) : null}
          </Flex.Col>
        </PaddedTextRow>

        <ModalFooter>
          <Flex.Row align="center" justify="flex-end">
            <DaButtonDefault className="mar-r-8" onClick={handleModalClose}>
              Close
            </DaButtonDefault>

            {!isClaimed && (
              <DaButton onClick={handleClaimSystem} disabled={isLoading}>
                Claim
              </DaButton>
            )}
          </Flex.Row>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div`
  padding: 0;
`;

const ModalTitle = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2.4rem;
  color: ${DaColors.Neutral2000};
  padding: 1.6rem;
`;
const ModalFooter = styled.div`
  padding: 1.6rem;
`;

const SystemData = styled.span`
  font-weight: ${DaFontWeight.Bold};
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
`;

const LineTitle = styled.span`
  font-size: 1.6rem;
`;

const TextBody = styled.div`
  text-align: left;
  font-size: 1.6rem;
  color: ${DaColors.Neutral40};
  margin-bottom: 0.8rem;
`;

const ZebraRow = styled(Flex.Row)<{ zebraStripe: boolean }>`
  background-color: ${(props) =>
    props.zebraStripe ? DaColors.Neutral200 : DaColors.White};
  padding: 0.8rem 1.6rem;
`;

const PaddedTextRow = styled(Flex.Row)`
  padding: 0 1.6rem;
`;

const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${DaColors.Failure500};
`;

export default ClaimSystemModal;
