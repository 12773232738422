import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { BellActions } from "securecom-graphql/client";
import { useSupportsTemporal3 } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { parseTemporalvsTemporal3 } from "./utils";
import { BellOptionsPanicBellActionField_bellOptions$key } from "./__generated__/BellOptionsPanicBellActionField_bellOptions.graphql";

export const bellOptionsPanicBellActionFieldId = () =>
  "bell-options-panic-bell-action";

function BellOptionsPanicBellActionField() {
  const [{ panicBellAction }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsPanicBellActionField_bellOptions$key>(
      graphql`
        fragment BellOptionsPanicBellActionField_bellOptions on BellOptions {
          panicBellAction
        }
      `
    );

  const fieldId = bellOptionsPanicBellActionFieldId();
  const supportsTemporal3 = useSupportsTemporal3();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Panic Bell Action"
      tooltip="Set the cadence the Bell Output uses for alarms on Panic type Zones."
    >
      <Select
        id={fieldId}
        value={parseTemporalvsTemporal3(
          panicBellAction as BellActions,
          supportsTemporal3
        )}
        onChange={({ target }) => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(target.value, "panicBellAction");
          });
        }}
      >
        <Select.Option value={BellActions.STEADY}>Steady</Select.Option>
        <Select.Option value={BellActions.PULSE}>Pulse</Select.Option>
        {supportsTemporal3 ? (
          <>
            <Select.Option value={BellActions.TEMPORAL_3}>
              Temporal 3
            </Select.Option>
            <Select.Option value={BellActions.TEMPORAL_4}>
              Temporal 4
            </Select.Option>
          </>
        ) : (
          <Select.Option value={BellActions.TEMPORAL}>Temporal</Select.Option>
        )}
        <Select.Option value={BellActions.NONE}>None</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsPanicBellActionField;
