import { isNotNullOrUndefined } from "common/utils/universal/function";

/**
 * @ngdoc service
 * @name App.factory:PanelDataService
 *
 * @description
 * Service for retrieving statistical information pertaining to a specific panel
 * Information may come from DMP VK server or Cellular Carrier APIs
 *
 */
App.factory("PanelDataService", [
  "$rootScope",
  "$stateParams",
  "$q",
  "PROPS",
  "PanelStatisticsV2API",
  "SitesODataAPI",
  "SendSemproMessageV2API",
  "RefreshPanelStatisticsV2API",
  "VerizonV2API",
  "VerizonV2ConnectionsAPI",
  "$filter",
  "JobService",
  "PanelODataAPI",
  "UserService",
  function (
    $rootScope,
    $stateParams,
    $q,
    PROP,
    PanelStatisticsV2API,
    SitesODataAPI,
    SendSemproMessageV2API,
    RefreshPanelStatisticsV2API,
    VerizonV2API,
    VerizonV2ConnectionsAPI,
    $filter,
    JobService,
    PanelODataAPI,
    UserService
  ) {
    return {
      /**
       * @ngdoc object
       * @name property:panel_id
       * @type Number
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Holds the Panel ID Number.  (i.e. PanelProgrammingService.panel_id = xxxxxx)
       */
      panel_id: 0,

      /**
       * @ngdoc object
       * @name method:getPanelStatistics
       * @methodOf App.factory:PanelDataService
       *
       * @description
       * Sends command to panel get statistical data.
       *
       * @param {Number} panel_id of the panel
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      getPanelStatistics: function (panel_id, scope) {
        const deferred = $q.defer();
        const _this = this;
        _this.panel_id = panel_id;
        const systemName = UserService.dealerInfo.vernaculars.systems
          .replacement
          ? UserService.dealerInfo.vernaculars.systems.replacement
          : "System";
        const handleGetPanelStatisticsSuccess = (response) => {
          //success

          // Make data structure between the two different API calls match.
          const data = scope.isX1
            ? response.value.map((datum) => ({
                ...datum,
                reported_at: datum.created_at,
              }))
            : response.map((datum) => ({
                ...datum,
                reported_at: datum.daily_report_event_at,
              }));

          if (data.length == 0) {
            // empty array, this data will always be returned as an array see getPanelStatistics resource configuration
            scope.panelStatisticsData = false;
            scope.noStatisticsMessage = `${systemName} Analytics data for this panel is currently unavailable.`;

            scope.devicesIsBusy = false; // flag for displaying panel statistics api loading status
            deferred.reject(data);
            return deferred.promise;
          }

          /********************************************************************************************************
           * IMPORTANT NOTE relating to Panel Statistics data return values:                                      *
           * If a data item for a given date is NULL, this date will be omitted from the data. Put another way,   *
           * a NULL value indicates that the panel did not generate the data is question on the date in question. *
           ********************************************************************************************************/

          /*********************************************************************************************************
           * Loop through the panel statistics data that is returned and stored the data items we need into        *
           * their own arrays. The analytics chart needs the data in this format -> each data point consists of an *
           * array containing the date and a value                                                                 *
           *********************************************************************************************************/
          const current_cell_signal = [];
          const strongest_cell_signal = [];
          const weakest_cell_signal = [];
          const cell_retries = [];
          const network_retries = [];
          const acVoltage = [];
          const dcVoltage = [];

          const current_cell_signal_sim1 = [];
          const strongest_cell_signal_sim1 = [];
          const weakest_cell_signal_sim1 = [];
          const cell_retries_sim1 = [];
          const current_cell_signal_sim2 = [];
          const strongest_cell_signal_sim2 = [];
          const weakest_cell_signal_sim2 = [];
          const cell_retries_sim2 = [];

          // custom sort function to sort the panelstatistics
          function compareDates(a, b) {
            //if (a.reported_at < b.reported_at) return -1;   // string comparison actually works but is not best practice given the format
            //if (a.reported_at > b.reported_at) return 1;
            if (new Date(a.reported_at) < new Date(b.reported_at)) return -1;
            if (new Date(a.reported_at) > new Date(b.reported_at)) return 1;
            return 0;
          }

          data.sort(compareDates);
          if (UserService.controlSystem.sim_2_carrier) {
            // Set the logo Icons
            for (
              let simStatusNumber = 1;
              simStatusNumber <= 2;
              simStatusNumber++
            ) {
              let carrier =
                UserService.controlSystem[
                  `sim_${simStatusNumber}_carrier`
                ].toLowerCase();
              switch (carrier) {
                case "verizon":
                  scope[`carrierIconAltSim${simStatusNumber}`] = "verizon-logo";
                  scope[`carrierIconSim${simStatusNumber}`] =
                    "assets/img/third-party-logos/logo-verizon.svg";
                  break;
                case "at&t":
                  scope[`carrierIconAltSim${simStatusNumber}`] = "att-logo";
                  scope[`carrierIconSim${simStatusNumber}`] =
                    "assets/img/third-party-logos/logo-att.svg";
                  break;
                default:
                  scope[`carrierIconAltSim${simStatusNumber}`] =
                    "Unknown Carrier";
                  scope[`carrierIconSim${simStatusNumber}`] = "";
              }
            }
          }
          // Sort the data by date (ascending, so earliest date is first item in the array.)

          /************************************************************************************************
           * Loop through the statistics array in descending date order. If any data is found, set the    *
           * last data date to the found date. This prevents data events that contain nulls for all data  *
           * items from being considered the last update date. (Null data is omitted from the chart data. *
           ************************************************************************************************/
          for (let i = data.length - 1; i >= 0; i--) {
            if (
              data[i].current_cell_signal ||
              data[i].strongest_cell_signal ||
              data[i].weakest_cell_signal ||
              data[i].cell_retries ||
              data[i].network_retries ||
              isNotNullOrUndefined(data[i].ac_voltage) ||
              isNotNullOrUndefined(data[i].dc_voltage)
            ) {
              /***********************************************
               * Convert the date to UTC time without offset *
               ***********************************************/
              const rawUTCDate = new Date(data[i].reported_at); // set Last Updated Date to date value of last populated item in the array
              const utcZulu = dateTimeForceUTC(rawUTCDate); // Now convert that date to a UTC date object - (returns date without local offset!)
              scope.lastDataDate = $filter("date")(
                utcZulu,
                "MM-dd-yyyy h:mm a"
              );
              break;
            }
          }

          for (let i = 0; i < data.length; i++) {
            /***********************************************
             * Convert the date to UTC time without offset *
             ***********************************************/
            const rawUTCEventDate = new Date(data[i].reported_at); // set Last Updated Date to date value of last populated item in the array
            const utcZuluEventDate = dateTimeForceUTC(rawUTCEventDate); // Now convert that date to a UTC date object - (returns date without local offset!)
            const itemDate = utcZuluEventDate.toUTCString(); // This string is used later to create a Javascript time object

            /*****************************************************************************************************
             * Note: Cell Signal values received from the panel need to be negated, (e.g. 55 represents -55 dBm) *
             *****************************************************************************************************/
            if (data[i].current_cell_signal) {
              current_cell_signal.push([
                itemDate,
                data[i].current_cell_signal * -1,
                data[i].sim_meid,
              ]);
              if (UserService.controlSystem.sim_2_carrier) {
                if (
                  data[i].sim_meid ===
                  UserService.controlSystem.sim_1_identifier
                ) {
                  current_cell_signal_sim1.push([
                    itemDate,
                    data[i].current_cell_signal * -1,
                    data[i].sim_meid,
                  ]);
                } else {
                  current_cell_signal_sim2.push([
                    itemDate,
                    data[i].current_cell_signal * -1,
                    data[i].sim_meid,
                  ]);
                }
              }
            }
            if (data[i].strongest_cell_signal) {
              strongest_cell_signal.push([
                itemDate,
                data[i].strongest_cell_signal * -1,
                data[i].sim_meid,
              ]);
              if (UserService.controlSystem.sim_2_carrier) {
                if (
                  data[i].sim_meid ===
                  UserService.controlSystem.sim_1_identifier
                ) {
                  strongest_cell_signal_sim1.push([
                    itemDate,
                    data[i].strongest_cell_signal * -1,
                    data[i].sim_meid,
                  ]);
                } else {
                  strongest_cell_signal_sim2.push([
                    itemDate,
                    data[i].strongest_cell_signal * -1,
                    data[i].sim_meid,
                  ]);
                }
              }
            }
            if (data[i].weakest_cell_signal) {
              weakest_cell_signal.push([
                itemDate,
                data[i].weakest_cell_signal * -1,
                data[i].sim_meid,
              ]);
              if (UserService.controlSystem.sim_2_carrier) {
                if (
                  data[i].sim_meid ===
                  UserService.controlSystem.sim_1_identifier
                ) {
                  weakest_cell_signal_sim1.push([
                    itemDate,
                    data[i].weakest_cell_signal * -1,
                    data[i].sim_meid,
                  ]);
                } else {
                  weakest_cell_signal_sim2.push([
                    itemDate,
                    data[i].weakest_cell_signal * -1,
                    data[i].sim_meid,
                  ]);
                }
              }
            }

            /******** NETWORK AND CELLULAR RETRIES LOGIC **************************************************************************
             * There is some special logic to handle Network and Cellular retry values. As per discussion with Jeff B., Stephen U.*
             * Duane K. The panel will only send a value for an attribute if it has one, otherwise it sends a blank. There are    *
             * 2 types of sempro events, one that sends modem info and has nothing else that we need for statistics, and another  *
             * that sends the relevant panel statistics information. The modem info event is excluded by the code because all of  *
             * the needed data attributes are null so the event is essentially ignored. The panel statistics event is what will   *
             * be used. Panel Statistics events It will always have AC voltage information while the 'modem' event has modem      *
             * model and firmware.                                                                                                *
             * NETWORK RETRIES RULE:                                                                                              *
             * If there is AC Voltage data in the Sempro event AND Network Retries is Null, Set Network Retries = 0               *
             * CELLULAR RETRIES RULE:                                                                                             *
             * If there is AC Voltage data in the Sempro event AND Cellular Retries is Null, AND data exists for any of the       *
             * three Cell Signal attributes (Current Signal, Best Signal, or Worst Signal), Set Cellular Retries = 0              *
             **********************************************************************************************************************/
            if (
              isNotNullOrUndefined(data[i].ac_voltage) &&
              !data[i].network_retries
            ) {
              data[i].network_retries = 0;
            }
            if (
              isNotNullOrUndefined(data[i].ac_voltage) &&
              !data[i].cell_retries &&
              (data[i].current_cell_signal ||
                data[i].strongest_cell_signal ||
                data[i].weakest_cell_signal)
            ) {
              data[i].cell_retries = 0;
            }

            /*********************************************************************************************
             * Now that we have interpreted the nulls for Cell Retries and Network Retries, we have to   *
             * use these values. Since null and 0 are equivalent in a logical comparison, the code needs *
             * to also check explicitly for zero values for Network and cell Retries                     *
             *********************************************************************************************/
            if (data[i].cell_retries || data[i].cell_retries === 0) {
              cell_retries.push([itemDate, data[i].cell_retries]);
              if (UserService.controlSystem.sim_2_carrier) {
                if (
                  data[i].sim_meid ===
                  UserService.controlSystem.sim_1_identifier
                ) {
                  cell_retries_sim1.push([
                    itemDate,
                    data[i].cell_retries * -1,
                    data[i].sim_meid,
                  ]);
                } else {
                  cell_retries_sim2.push([
                    itemDate,
                    data[i].cell_retries * -1,
                    data[i].sim_meid,
                  ]);
                }
              }
            }
            if (data[i].network_retries || data[i].network_retries === 0) {
              network_retries.push([itemDate, data[i].network_retries]);
            }

            /***********************************************************************************************************
             * Note: Voltage values received from the panel need to be divided by 10, (e.g. 135 represents 13.5 volts) *
             ***********************************************************************************************************/
            if (isNotNullOrUndefined(data[i].ac_voltage)) {
              acVoltage.push([itemDate, data[i].ac_voltage / 10]);
            }
            if (isNotNullOrUndefined(data[i].dc_voltage)) {
              dcVoltage.push([itemDate, data[i].dc_voltage / 10]);
            }
          }

          // This creates the final data structure used by the system analytics main chart.
          // For each type of data, add the data to the chart's corresponding data object if data exists
          if (current_cell_signal.length > 0) {
            scope.jsonSignalData["CurrentSignal"] = current_cell_signal;
          }
          if (strongest_cell_signal.length > 0) {
            scope.jsonSignalData["BestSignal"] = strongest_cell_signal;
          }
          if (weakest_cell_signal.length > 0) {
            scope.jsonSignalData["WorstSignal"] = weakest_cell_signal;
          }
          if (cell_retries.length > 0) {
            scope.jsonRetryData["RetriesCellular"] = cell_retries;
          }
          if (network_retries.length > 0) {
            scope.jsonRetryData["RetriesNetwork"] = network_retries;
          }
          if (acVoltage.length > 0) {
            scope.jsonHardwareData["ACVoltage"] = acVoltage;
          }
          if (
            dcVoltage.length > 0 &&
            dcVoltage.some((voltage) => voltage[1] !== 0)
          ) {
            scope.jsonHardwareData["BatteryVoltage"] = dcVoltage;
          }

          if (current_cell_signal_sim1.length > 0) {
            scope.jsonSignalData["CurrentSignalSim1"] =
              current_cell_signal_sim1;
          }
          if (strongest_cell_signal_sim1.length > 0) {
            scope.jsonSignalData["BestSignalSim1"] = strongest_cell_signal_sim1;
          }
          if (weakest_cell_signal_sim1.length > 0) {
            scope.jsonSignalData["WorstSignalSim1"] = weakest_cell_signal_sim1;
          }
          if (cell_retries_sim1.length > 0) {
            scope.jsonRetryData["RetriesCellularSim1"] = cell_retries_sim1;
          }
          if (current_cell_signal_sim2.length > 0) {
            scope.jsonSignalData["CurrentSignalSim2"] =
              current_cell_signal_sim2;
          }
          if (strongest_cell_signal_sim2.length > 0) {
            scope.jsonSignalData["BestSignalSim2"] = strongest_cell_signal_sim2;
          }
          if (weakest_cell_signal_sim2.length > 0) {
            scope.jsonSignalData["WorstSignalSim2"] = weakest_cell_signal_sim2;
          }
          if (cell_retries_sim2.length > 0) {
            scope.jsonRetryData["RetriesCellularSim2"] = cell_retries_sim2;
          }
          // Display the main chart if there is data
          /*********************************************************************************************************
           * This block determines the default data display order for the large chart when the system analytics    *
           * page first loads. It Iterates through the Chart data to find data to display first in the large chart.*
           * If none can be found, then it exits and sets panelStatisticsData to false.                            *
           *********************************************************************************************************/
          if (scope.jsonSignalData.CurrentSignal) {
            scope.changeChart(
              "Current Signal (dBm)",
              scope.jsonSignalData.CurrentSignal,
              "widget1"
            );
          } else if (scope.jsonSignalData.WorstSignal) {
            scope.changeChart(
              "Worst Signal (dBm)",
              scope.jsonSignalData.WorstSignal,
              "widget2"
            );
          } else if (scope.jsonSignalData.BestSignal) {
            scope.changeChart(
              "Best Signal (dBm)",
              scope.jsonSignalData.BestSignal,
              "widget3"
            );
          } else if (scope.jsonRetryData.RetriesCellular) {
            scope.changeChart(
              "Retries-Cellular",
              scope.jsonRetryData.RetriesCellular,
              "widget4"
            );
          } else if (scope.jsonRetryData.RetriesNetwork) {
            scope.changeChart(
              "Retries-Network",
              scope.jsonRetryData.RetriesNetwork,
              "widget5"
            );
          } else if (scope.jsonHardwareData.ACVoltage) {
            scope.changeChart(
              "AC Voltage",
              scope.jsonHardwareData.ACVoltage,
              "widget6"
            );
          } else if (scope.jsonHardwareData.BatteryVoltage) {
            scope.changeChart(
              "Battery Voltage",
              scope.jsonHardwareData.BatteryVoltage,
              "widget7"
            );
          } else {
            scope.panelStatisticsData = false; // Don't Display the statistics chart area if no data exists
            scope.noStatisticsMessage = `${systemName} Analytics Data for this panel is currently unavailable.`;
            deferred.reject(data);
          }

          scope.devicesIsBusy = false; // flag for displaying panel statistics api loading status

          deferred.resolve(data);
        };
        const handleGetPanelStatisticsFailed = (error) => {
          //failure
          deferred.notify({
            job_uuid: "n/a",
            status: "error",
            poll_count: 0,
          });
          deferred.reject(error);

          scope.deviceIsBusy = false; // flag for displaying panel statistics api loading status
          scope.panelStatisticsDisplay = false;
          scope.panelInfoTableDisplay = true;

          $rootScope.alerts.push({
            type: "error",
            text: "Error Retrieving System Analytics Data",
            json: error,
          });
        };

        if (scope.isX1) {
          SitesODataAPI.getX1PanelStatistics(
            {
              siteId: $stateParams.site_id,
              $select:
                "ac_voltage, cell_retries, created_at, current_cell_signal, dc_voltage, network_retries, strongest_cell_signal, weakest_cell_signal",
              $filter: `panel_id eq ${_this.panel_id}`,
            }, //params
            handleGetPanelStatisticsSuccess,
            handleGetPanelStatisticsFailed,
            (info) => {
              //failure
              deferred.notify(info);
            }
          );
        } else {
          PanelStatisticsV2API.getPanelStatistics(
            { panel_id: _this.panel_id }, //params
            handleGetPanelStatisticsSuccess,
            handleGetPanelStatisticsFailed,
            (info) => {
              //failure
              deferred.notify(info);
            }
          );
        }
        return deferred.promise;
      },

      getHourlyData: function (panel_id, scope) {
        const deferred = $q.defer();
        const _this = this;
        const today = new Date();
        const lastMonth = new Date().setDate(today.getDate() - 30);
        const tomorrow = new Date().setDate(today.getDate() + 1);
        const dateFilter = new Date(lastMonth).toISOString();
        const tomorrowFilter = new Date(tomorrow).toISOString();
        const dateField = scope.isX1 ? "created_at" : "reported_at";

        _this.panel_id = panel_id;
        _this.select = `ac_voltage, cell_retries, cell_tower_id, created_at, current_cell_signal, dc_voltage, hour, network_retries, strongest_cell_signal, weakest_cell_signal, ${dateField}`;
        _this.filter = `cell_tower_id ne null and ${dateField} gt ${dateFilter} and ${dateField} lt ${tomorrowFilter}`;
        _this.orderBy = `${dateField} desc`;

        const handleGetPanelStatisticsSuccess = ({ value }) => {
          //success
          const data = value;
          const allTheDays = [];
          let i = 0; //counter for all data

          while (data[i]) {
            const eventDateTime = new Date(data[i][dateField]);
            const usersOffset = eventDateTime.getTimezoneOffset() * 60000;
            const offset = eventDateTime.getTimezoneOffset() / 60; //for comparison to take off the correct ammount of hours
            const hourlySignal = {
              signal: [],
              meta: {},
            }; //holder for this days hourly signal

            const dateObj = new Date(
              Date.parse(data[i][dateField]) + usersOffset
            );
            hourlySignal.currentDate = dateObj;
            hourlySignal.dayBefore = new Date(dateObj - 86400000); // One day in milliseconds

            const refDate = new Date(data[i][dateField]).getDay(); // use this so we can lump all of the data for one particular day all together
            while (
              data[i] &&
              refDate === new Date(data[i][dateField]).getDay()
            ) {
              // looping in batches of 24 is not reliable because a panel may not have data for all hours
              const singleHour = [];

              // Get the correct date based on the hour field from the
              const year = eventDateTime.getFullYear();
              const month = eventDateTime.getMonth();
              let day = 0;

              if (data[i].hour > eventDateTime.getUTCHours()) {
                day = eventDateTime.getDate() - 1;
              } else {
                day = eventDateTime.getDate();
              }

              const hour = data[i].hour;
              const minute = eventDateTime.getMinutes();
              const dataTime = new Date(year, month, day, hour, minute, 0, 0);

              singleHour.push(
                dataTime.getTime(),
                data[i].current_cell_signal * -1,
                "-150",
                data[i].cell_tower_id,
                data[i].hour
              );

              hourlySignal.meta.hour = data[i].hour;
              hourlySignal.signal.push(singleHour);
              i++;
            }

            hourlySignal.signal.sort(
              ((index) => {
                return (a, b) => {
                  return a[index] === b[index]
                    ? 0
                    : a[index] < b[index]
                    ? -1
                    : 1;
                };
              })(4)
            ); // 4 is the index we want to sort on
            // more information on sorting a multi-dimensional array here: https://stackoverflow.com/questions/2824145/sorting-a-multidimensional-array-in-javascript

            for (let k = 0; k < offset - 1; k++) {
              const val = hourlySignal.signal.shift();
              hourlySignal.signal.push(val);
            }

            allTheDays.push(hourlySignal);
          }

          deferred.resolve(allTheDays);
        };

        if (scope.isX1) {
          SitesODataAPI.getX1PanelStatistics(
            {
              siteId: $stateParams.site_id,
              $select: _this.select,
              $filter: `${_this.filter} and panel_id eq ${_this.panel_id}`,
              $orderby: _this.orderBy,
            }, //params
            handleGetPanelStatisticsSuccess,
            (error) => {}
          );
        }
        return deferred.promise;
      },

      getYesterdaysHourlyData: function (panel_id) {
        var deferred = $q.defer();
        var _this = this;

        let today = new Date();
        let tomorrow = new Date().setDate(today.getDate() + 1);
        let dateFilter = new Date(tomorrow);
        dateFilter = dateFilter.toISOString();

        _this.filter = "cell_tower_id ne null and reported_at lt " + dateFilter;
        _this.orderBy = "reported_at desc";
        _this.top = "24";

        PanelODataAPI.getPanelStatistics(
          {
            panel_id: _this.panel_id,
            $filter: _this.filter,
            $orderby: _this.orderBy,
            $top: _this.top,
          }, //params
          function (data) {
            //success
            if (data.value.length > 0) {
              data = data.value;

              let allTheDays = [];

              let i = 0; //counter for all data

              let hourlySignal = {}; //holder for this days hourly signal
              hourlySignal.signal = [];
              hourlySignal.meta = {};

              while (data[i]) {
                dateObj = new Date(data[i].reported_at);
                dateObj = dateTimeForceUTC(dateObj);
                hourlySignal.currentDate = dateObj;
                hourlySignal.dayBefore = new Date(dateObj - 86400000); // One day in milliseconds

                let latestEvent = dateObj; //for comparison to take off the correct ammount of hours
                let offset = latestEvent.getTimezoneOffset() / 60;

                let eventDateTime = dateObj;

                let refDate = dateObj.getDay(); // use this so we can lump all of the data for one particular day all together
                while (data[i] && refDate == dateObj.getDay()) {
                  // looping in batches of 24 is not reliable because a panel may not have data for all hours
                  let singleHour = [];

                  // Get the correct date based on the hour field from the
                  let year = eventDateTime.getFullYear();
                  let month = eventDateTime.getMonth();
                  let day = 0;

                  if (data[i].hour > eventDateTime.getHours()) {
                    day = eventDateTime.getDate() - 1;
                  } else {
                    day = eventDateTime.getDate();
                  }
                  let hour = data[i].hour;
                  let minute = eventDateTime.getMinutes();

                  let dataTime = new Date(year, month, day, hour, minute, 0, 0);

                  singleHour.push(dataTime.getTime());
                  singleHour.push(data[i].current_cell_signal * -1);
                  // singleHour.push(((data[i].current_cell_signal * -1) + (Math.random() * (20 + 20) + 20)));
                  singleHour.push("-150");

                  singleHour.push(data[i].cell_tower_id);
                  singleHour.push(data[i].hour);
                  singleHour.push(dataTime);

                  hourlySignal.meta.hour = data[i].hour;
                  hourlySignal.signal.push(singleHour);
                  i++;
                }

                hourlySignal.signal.sort(
                  (function (index) {
                    return function (a, b) {
                      return a[index] === b[index]
                        ? 0
                        : a[index] < b[index]
                        ? 1
                        : -1;
                    };
                  })(4)
                ); // 4 is the index we want to sort on
                // more information on sorting a multi-dimensional array here: https://stackoverflow.com/questions/2824145/sorting-a-multidimensional-array-in-javascript

                for (var k = 0; k < offset - 1; k++) {
                  let val = hourlySignal.signal.pop();
                  hourlySignal.signal.unshift(val);
                }

                allTheDays.push(hourlySignal);
              }

              deferred.resolve(hourlySignal.signal);
            }
          },
          function (error) {}
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:handleJob
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Handles the call to the Job Service, checks for proper Job UUID and returns data based on parameters.
       *
       * @param {Object} data The data JSON containing the Job UUID.
       * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
       * @param {Boolean} returnResults A boolean flag to indicate whether or not to return the cached data after the job completes.
       * @param {String} action The name of the method calling the handleJob function (i.e. 'update','delete', etc.).
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      handleJob: function (data) {
        var _this = this;
        var deferred = $q.defer();
        if (angular.isDefined(data.job)) {
          //sanity check for job object
          if (data.job.status == "error") {
            //check status and bail if error is returned in the initial call
            $rootScope.alerts.push({
              type: "error",
              text: "Error Retrieving Real-Time Analytics Data",
              json: error,
            });
            deferred.reject(data);
          }
          // If the caller didn't provide a job.uuid, or provided an empty value, there's no point in going further.
          if (
            angular.isUndefined(data.job.uuid) ||
            data.job.uuid.trim() == ""
          ) {
            deferred.reject("NO_JOB_ID_AVAILABLE"); //Returns i18n constant name for internationalization.
          }
          JobService.process(data.job.uuid)
            .then(
              function (jobData) {
                //once the JobService returns with a success, we either return the success status or the cache data
                deferred.resolve(jobData);
              },
              function (errorData) {
                // sendSemproMessage function will display this error. Visual alert not shown here to avoid duplicate alerts. (errorData here has same info)
                deferred.reject(errorData);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        } else {
          deferred.reject(data);
        }
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:refresh
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Sends command to panel to update SCAPI (cached) data from the panel.  If requested, the refreshed data is returned.
       *
       * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
       * @param {Boolean} returnResults (Optional:Default = true) A boolean flag to indicate whether or not to return the cached data after the job completes.
       * @param {Object} additionalParams Any additional parameters in a JSON object structure (i.e. {"include_24_hour_zones":true}).
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      sendSemproMessage: function (panel_id, scope, displayModal) {
        var deferred = $q.defer();
        var _this = this;
        _this.panel_id = panel_id;

        var params = { panel_id: _this.panel_id, daily_report: { to: "R" } }; //because additional params may be added, this is declared outside the API call

        SendSemproMessageV2API.sendSemproMessage(
          params, //params
          function (JobId) {
            //success
            _this
              .handleJob(JobId, panel_id, "sendSemproMessage")
              .then(
                //The 'handleJob' function handles the Job Service processing
                function (data) {
                  _this
                    .refreshPanelStatistics(
                      data.job.details.object_id,
                      scope,
                      displayModal
                    )
                    .then(function (data) {
                      deferred.resolve(data);
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                function (error) {
                  deferred.reject(error);
                  scope.realTimeStatsLoading = false; // disable loading notification to end user
                  var errorDetail = "";
                  if (error?.data?.job?.details) {
                    errorDetail =
                      " (Code " +
                      error.data.job.details.code +
                      " - " +
                      error.data.job.details.message +
                      ")";
                  }
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error Retrieving Real-Time Analytics Data",
                    json: error,
                  });
                  deferred.reject(error);

                  //$rootScope.alerts.push({"type": "error", "text": "Error Retrieving Real-Time Analytics Data. " + "Error: " +  error.status + " " + error.statusText});
                },
                function (info) {
                  deferred.notify(info);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            //failure
            /*
                         var errorDetail = "";
                         if ( error.data.job.details) {
                         errorDetail = " (Code " + error.data.job.details.code + " - " + error.data.job.details.message + ")";
                         }
                         $rootScope.alerts.push({"type": "error", "text": "Error Retrieving Real-Time Analytics Data. " + "Error: " +  error.status + " " + error.statusText + errorDetail});
                         */
            // error.data does not always exist the the code above has been changed to the code below.
            scope.realTimeStatsLoading = false; // disable loading notification to end user
            $rootScope.alerts.push({
              type: "error",
              text: "Error Retrieving Real-Time Analytics Data",
              json: error,
            });

            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:refreshPanelStatistics
       * @methodOf App.factory:PanelDataService
       *
       * @description
       * Sends command to panel refresh statistical data.
       *
       * @param {Number} panel_id of the panel
       * @param {Boolean} returnResults (Optional:Default = true) A boolean flag to indicate whether or not to return the cached data after the job completes.
       * @param {Object} additionalParams Any additional parameters in a JSON object structure
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */

      /*************************************************************************************************************
       * This API call is dependent on the sendSemproMessage API call. sendSemproMessage returns an ID that        *
       * must be supplied to refreshPanelStatistics in order for it to return the refreshed panel statistics data. *
       *************************************************************************************************************/
      refreshPanelStatistics: function (object_id, scope, displayModal) {
        var deferred = $q.defer();
        var _this = this;
        if (displayModal === undefined) {
          displayModal = true;
        }

        RefreshPanelStatisticsV2API.refreshPanelStatistics(
          { object_id: object_id }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);

            scope.realTimeStatsLoading = false; // disable loading notification to end user

            if (!data) {
              // only one panel statistic object will be returned, not an array
              $rootScope.alerts.push({
                type: PROP.alertInfo,
                text: "Real Time System Analytics Data is currently unavailable.",
              });
              return;
            } else {
              // if there is real time panel statistics data
              // IMPORTANT NOTE: refreshPanelStatistics API uses the created_at date attribute, not reported_at, which is what getPanelStatistics uses
              // Also, this date is left in local time (of the browser) to correctly convey the real time nature of the results
              scope.realTimeStats.created_at = $filter("date")(
                data.refreshed_panel_statistic.created_at,
                "MM-dd-yyyy h:mm a"
              );
              /*****************************************************************************************************
               * Note: Cell Signal values received from the panel need to be negated, (e.g. 55 represents -55 dBm) *
               *****************************************************************************************************/
              if (data.refreshed_panel_statistic.current_cell_signal) {
                scope.realTimeStats.current_cell_signal =
                  data.refreshed_panel_statistic.current_cell_signal * -1;
              }
              if (data.refreshed_panel_statistic.strongest_cell_signal) {
                scope.realTimeStats.strongest_cell_signal =
                  data.refreshed_panel_statistic.strongest_cell_signal * -1;
              }
              if (data.refreshed_panel_statistic.weakest_cell_signal) {
                scope.realTimeStats.weakest_cell_signal =
                  data.refreshed_panel_statistic.weakest_cell_signal * -1;
              }

              //if (data.refreshed_panel_statistic.cell_retries) { scope.realTimeStats.cell_retries = data.refreshed_panel_statistic.cell_retries; }
              //if (data.refreshed_panel_statistic.network_retries) { scope.realTimeStats.network_retries =  data.refreshed_panel_statistic.network_retries; }
              /******** NETWORK AND CELLULAR RETRIES LOGIC *********************************************************************
               * Same logic as the getPanelStatistics function's API call. See Above.                                          *
               * Note that here, a variable is being populated based on the existence of the data. This does not occur         *
               * if the data is falsy (e.g. 0). So we use a string to force recognition of 0 as valid data value, which it is. *
               *****************************************************************************************************************/
              if (
                (isNotNullOrUndefined(data.refreshed_panel_statistic)
                  .ac_voltage &&
                  !data.refreshed_panel_statistic.network_retries) ||
                data.refreshed_panel_statistic.network_retries === 0
              ) {
                data.refreshed_panel_statistic.network_retries = "0"; // need to use a string. 0 by itself will not trigger the if clause below
              }
              if (
                isNotNullOrUndefined(
                  data.refreshed_panel_statistic.ac_voltage
                ) &&
                (!data.refreshed_panel_statistic.cell_retries ||
                  data.refreshed_panel_statistic.cell_retries === 0) &&
                (data.refreshed_panel_statistic.current_cell_signal ||
                  data.refreshed_panel_statistic.strongest_cell_signal ||
                  data.refreshed_panel_statistic.weakest_cell_signal)
              ) {
                data.refreshed_panel_statistic.cell_retries = "0"; // need to use a string. 0 by itself will not trigger the if clause below
              }
              if (
                data.refreshed_panel_statistic.cell_retries ||
                data.refreshed_panel_statistic.cell_retries === "0"
              ) {
                scope.realTimeStats.cell_retries =
                  data.refreshed_panel_statistic.cell_retries;
              }
              if (
                data.refreshed_panel_statistic.network_retries ||
                data.refreshed_panel_statistic.network_retries === "0"
              ) {
                scope.realTimeStats.network_retries =
                  data.refreshed_panel_statistic.network_retries;
              }

              /***********************************************************************************************************
               * Note: Voltage values received from the panel need to be divided by 10, (e.g. 135 represents 13.5 volts) *
               ***********************************************************************************************************/
              if (
                isNotNullOrUndefined(data.refreshed_panel_statistic.ac_voltage)
              ) {
                scope.realTimeStats.ac_voltage =
                  data.refreshed_panel_statistic.ac_voltage / 10;
              }
              if (
                isNotNullOrUndefined(data.refreshed_panel_statistic.dc_voltage)
              ) {
                scope.realTimeStats.dc_voltage =
                  data.refreshed_panel_statistic.dc_voltage / 10;
              }

              /*** API Bug Fix made on 6/18/2015 **************************************************************************
               * API erroneously returns cell_roaming status of 'false' even when cellular communication does not exist.  *
               * So I explicitly  put roaming status logic into a block that checks for cell signal data. As per          *
               * conversation with spec team (John G and Steven U) Cell Signal will always have a value & has to exist to *
               * get a roaming status.                                                                                    *
               ***********************************************************************************************************/
              if (data.refreshed_panel_statistic.current_cell_signal) {
                // If there is a cell signal value returned in the data

                /*******************************************************************************************************
                 * Because roaming status is returned as a true boolean value, checking for existence does not work as *
                 * with other values. So first the value is casted into a string and the string is displayed. If the   *
                 * string variable roamingStatus is empty or undefined, nothing will be displayed for roaming, which   *
                 * is how the other data items work.                                                                   *
                 *******************************************************************************************************/
                var roamingStatus =
                  data.refreshed_panel_statistic.cell_roaming + ""; // essentially caste the value to a string
                //if ( roamingStatus ) { scope.realTimeStats.cell_roaming = roamingStatus; }
                // Change: Display Yes or No instead of returning the string of the returned boolean value  //
                if (/false/i.test(roamingStatus)) {
                  scope.realTimeStats.cell_roaming = "No";
                }
                if (/true/i.test(roamingStatus)) {
                  scope.realTimeStats.cell_roaming = "Yes";
                }
              }

              if (displayModal) {
                scope.openStatusModal(); // Display the modal popup window with real time system analytics information
              }
            }
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);

            //scope.panelStatisticsData = false;
            //scope.deviceIsBusy = false;  // flag for displaying panel statistics api loading status
            //scope.noStatisticsMessage = "Panel Statistics Data is Unavailable. " + "Error: " +  error.status + " " + error.statusText;
            $rootScope.alerts.push({
              type: "error",
              text: "Error While Retrieving Real-Time Analytics Data",
              json: error,
            });
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getPanelInfo
       * @methodOf App.factory:PanelDataService
       *
       * @description
       * Sends command to panel to retrieve SCAPI (cached) data from the panel.
       *
       * @param {Number} panel_id of the panel
       * @param {Boolean} returnResults (Optional:Default = true) A boolean flag to indicate whether or not to return the cached data after the job completes.
       * @param {Object} additionalParams Any additional parameters in a JSON object structure
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      getDeviceInformation: function (device_id, device_id_type, scope) {
        var deferred = $q.defer();
        var _this = this;

        VerizonV2API.get_device_information(
          {
            dealer_id: UserService.dealerInfo.id,
            device_identifier: device_id,
            device_identifier_kind: device_id_type,
          }, //params
          function (data) {
            //success
            // Note: If there is no data, the view will not display anything because
            // the code it checks to see if data exists before anything is displayed.

            if (data.device_information) {
              // Check if device information data exists to avoid undefined errors
              scope.carrierCellStatus = $filter("carrier_codes")(
                data.device_information.carrier_information.carrier_information
                  .state,
                "Verizon",
                "status"
              );
              scope.carrierLastConnection = $filter("date")(
                data.device_information.last_connection_date,
                "MM-dd-yyyy h:mm a"
              );
              scope.carrierConnectionStatus = $filter("carrier_codes")(
                data.device_information.connected,
                "Verizon",
                "connected"
              );
            } else {
              scope.carrierErrorMessage =
                "Sim is Inactive or Sim Identifier is Not Valid.";
            }

            //deferred.notify({job_uuid: 'n/a', status: 'success', poll_count: 0});
            deferred.resolve(data);
          },
          function (error) {
            //failure
            /****  All error.status values != 1 are caught by this block by default ****/

            /*******************************************************************************************************************
             * Here we display any error messages that may be received. First we capture any server level error messages. Then *
             * we append any api specific error messages to that and it is displayed on the system analytics page in the table *
             *******************************************************************************************************************/
            scope.carrierErrorMessage = ""; // clear any previous error messages
            scope.carrierErrorMessage =
              "Error: " + error.status + "  " + error.statusText; // this will be populated with server errors

            if (error.data.errors) {
              // this will be populated with Verizon API specific errors
              if (scope.carrierErrorMessage)
                scope.carrierErrorMessage = scope.carrierErrorMessage + "  "; // insert a space if there is other error data there
              var errorArray = [];
              angular.forEach(
                error.data.errors,
                function (value, key) {
                  // loop through all errors returned by Verizon and store them for display
                  this.push(key + ": " + value);
                },
                errorArray
              );
              scope.carrierErrorMessage =
                scope.carrierErrorMessage + errorArray.toString();
            }

            //deferred.notify({job_uuid: 'n/a', status: 'error', poll_count: 0});
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getDeviceConnections
       * @methodOf App.factory:PanelDataService
       *
       * @description
       * Retrieves Connection history information from Verizon API.
       *
       * @param {Number} panel_id of the panel
       * @param {Number} device_id
       * @param {Number} device_id_type
       * @param {Number} s_date
       * @param {Number} e_date
       *
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      getDeviceConnections: function (
        device_id,
        device_id_type,
        s_date,
        e_date,
        scope
      ) {
        var deferred = $q.defer();
        var _this = this;

        VerizonV2ConnectionsAPI.get_device_connections(
          {
            dealer_id: UserService.dealerInfo.id,
            device_identifier: device_id,
            device_identifier_kind: device_id_type,
            start_date: s_date,
            end_date: e_date,
          }, //params
          function (data) {
            //success

            /*******************************************************************************************************
             * As per Verizon documentation ConnectionHistory contains List of device connection events,           *
             * sorted by the OccurredAt timestamp, oldest first. So the last item is the most recent connection.   *
             * Here we retrieve the connection events (all of them) and get the BSID information                   *
             * We loop through the attributes array for each connection event until we find BSID information,      *
             * starting with the most recent connection and working backwards. This way we keep looking for BSID   *
             * information if a given connection does not have it for whatever reason.                             *
             *******************************************************************************************************/
            for (
              var n =
                data.device_connection_history.device_connection_event.length -
                1;
              n >= 0;
              n--
            ) {
              var currentConnection =
                data.device_connection_history.device_connection_event[n];
              var currentConnectionAttributes =
                currentConnection.connection_event_attributes
                  .connection_event_attribute;

              // Instead of referencing a fixed index value in the array, I'm looking for the specific attribute named "Account3Gpp2BSID".
              // This way if Verizon adds or removes fields from this api, we will still be referencing the same data element.
              for (var i = 0; i < currentConnectionAttributes.length; i++) {
                var obj = currentConnectionAttributes[i];
                // Note: Verizon documentation lists the attribute as Account3Gpp2BSID, actual api data returns Account3GppBsId (no 2)
                // Verizon documentation's capitalization of this attribute also differs from that in actual data so a regex is used here
                if (/Account3GppBSID/i.test(obj.key)) {
                  scope.baseStationBsid = obj.value;
                  break; // stop looping through this event's attributes
                }
              }
              if (scope.baseStationBsid) break; // stop looping through the connection events
            }

            /********************************************************************************************************************
             * From Verizon Docs: https://m2mdeveloper.verizon.com/mediawiki/index.php/WNS2:API_Reference (GetConnectionHistory)*
             * Base Station Identifier servicing point of attachment for device. Format is: Concatenation                       *
             * of SID (4 octets) + NID (4 octets) + Cell Identifier Type 2 (4 octets). In the Cell Identifier, 12 upper bits    *
             * are Cell ID; 4 lower bits are Sector. Each item is encoded using hexadecimal uppercase ASCII characters.         *
             ********************************************************************************************************************/
            if (scope.baseStationBsid) {
              function d2h(d) {
                return d.toString(16);
              } // decimal to hex conversion - not used but included for completeness and testing
              function h2d(h) {
                return parseInt(h, 16);
              } // hex to decimal conversion
              function b2d(b) {
                return parseInt(b, 2);
              } // binary to decimal conversion

              var hexValue = scope.baseStationBsid; // full base station id

              var sid = hexValue.slice(0, 4); // base station system id
              var nid = hexValue.slice(4, 8); // base station network id
              var cellID = hexValue.slice(8, 12); // full base station cell id (cell id and sector)

              scope.baseStationSid = h2d(sid);
              scope.baseStationNid = h2d(nid);

              var base2 = h2d(cellID).toString(2); // convert into bits

              // convert the binary string into an array of bits
              var numArr = [];
              for (let i = 0; i < base2.length; i++) {
                numArr.push(base2[i]);
              }

              scope.baseStationCid = b2d(numArr.slice(0, 12).join(""));
              scope.baseStationSectorId = b2d(numArr.slice(12, 16).join(""));
            }

            scope.carrierApiCallStatus = "";
            //deferred.notify({job_uuid: 'n/a', status: 'success', poll_count: 0});
            deferred.resolve(data);
          },
          function (error) {
            //failure

            /*******************************************************************************************************************
             * Here we display any error messages that may be received. First we capture any server level error messages. Then *
             * we append any api specific error messages to that and it is displayed on the system analytics page in the table *
             * NOTE: This is not the first carrier api call so we will append error messages here to any existing ones.        *
             *******************************************************************************************************************/
            if (scope.carrierErrorMessage)
              scope.carrierErrorMessage = scope.carrierErrorMessage + "  "; // insert a space if there is other error data there
            scope.carrierErrorMessage =
              scope.carrierErrorMessage +
              "Error: " +
              error.status +
              "  " +
              error.statusText; // this will be populated with ws server errors

            if (error.data.errors) {
              // this will be populated with Verizon API specific errors
              if (scope.carrierErrorMessage)
                scope.carrierErrorMessage = scope.carrierErrorMessage + "  "; // insert a space if there is other error data there
              var errorArray = [];
              angular.forEach(
                error.data.errors,
                function (value, key) {
                  // loop through all errors returned by Verizon and store them for display
                  this.push(key + ": " + value);
                },
                errorArray
              );
              scope.carrierErrorMessage =
                scope.carrierErrorMessage + errorArray.toString();
            }

            scope.carrierApiCallStatus = "";
            //deferred.notify({job_uuid: 'n/a', status: 'error', poll_count: 0});
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );

        return deferred.promise;
      },
    };
  },
]);
