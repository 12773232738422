/**
 * @generated SignedSource<<6790cb2c6bf11ca3a888e945f4731b46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DirectionOfTravel = "CROSS" | "IN" | "OFF" | "OUT";
export type GeoJSONType = "FEATURE" | "FEATURECOLLECTION" | "GEOMETRYCOLLECTION" | "LINESTRING" | "MULTILINESTRING" | "MULTIPOINT" | "MULTIPOLYGON" | "POINT" | "POLYGON";
import { FragmentRefs } from "relay-runtime";
export type RecorderCameraEdit_varHubCameraDetectionRegion$data = {
  readonly detectAnimals: boolean;
  readonly detectPeople: boolean;
  readonly detectVehicles: boolean;
  readonly drawRegionOnAlerts: boolean;
  readonly geometry: {
    readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
    readonly type: GeoJSONType;
  };
  readonly id: string;
  readonly isNew: boolean | null;
  readonly loiteringPersonSeconds: number | null;
  readonly loiteringVehicleSeconds: number | null;
  readonly movementDirection: DirectionOfTravel | null;
  readonly name: string;
  readonly slotNumber: number | null;
  readonly zone: {
    readonly area: {
      readonly id: string;
    } | null;
    readonly id: string;
    readonly name: string;
    readonly number: string;
  } | null;
  readonly " $fragmentType": "RecorderCameraEdit_varHubCameraDetectionRegion";
};
export type RecorderCameraEdit_varHubCameraDetectionRegion$key = {
  readonly " $data"?: RecorderCameraEdit_varHubCameraDetectionRegion$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraEdit_varHubCameraDetectionRegion">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "RecorderCameraEdit_varHubCameraDetectionRegion"
};

(node as any).hash = "8c0d107f8e4105125b0a1a4caa28ef25";

export default node;
