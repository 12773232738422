import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorVideoReportsField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorVideoReportsField_remoteOptions.graphql";

export const remoteOptionsIntegratorVideoReportsFieldId = () =>
  "remote-options-integrator-video-reports";

function RemoteOptionsIntegratorVideoReportsField() {
  const [
    { integratorVideoReports, integratorConnection },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorVideoReportsField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorVideoReportsField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorVideoReports
          integratorConnection
        }
        ... on Xt75RemoteOptions {
          integratorVideoReports
          integratorConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorVideoReportsFieldId();
  const label = "Integrator Video Reports";
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label={label}
    >
      <Switch
        disabled={disabled}
        label={label}
        id={fieldId}
        checked={!!integratorVideoReports}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !integratorVideoReports,
              "integratorVideoReports"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorVideoReportsField;
