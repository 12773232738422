/**
 * @generated SignedSource<<245ef59dc64e9401600e1a1e16671a24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type XFZoneInformationsFields_zone$data = {
  readonly id: string;
  readonly type: ZoneType;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationArmedOpenMessageField_zone" | "ZoneInformationArmedOpenOutputActionField_zone" | "ZoneInformationArmedOpenOutputNumberField_zone" | "ZoneInformationArmedShortMessageField_zone" | "ZoneInformationArmedShortOutputActionField_zone" | "ZoneInformationArmedShortOutputNumberField_zone" | "ZoneInformationContactField_zone" | "ZoneInformationContext_zone" | "ZoneInformationCrossZoneField_zone" | "ZoneInformationDMPWirelessField_zone" | "ZoneInformationExpanderSerialNumberField_zone" | "ZoneInformationFastResponseField_zone" | "ZoneInformationFireBellOutputNumberField_zone" | "ZoneInformationFirePanelSlaveInputField_zone" | "ZoneInformationLocationField_zone" | "ZoneInformationNameField_zone" | "ZoneInformationNumberField_zone" | "ZoneInformationPresignalKeypadsField_zone" | "ZoneInformationPrewarnKeypadsField_zone" | "ZoneInformationSerialNumberField_zone" | "ZoneInformationSupervisionTimeField_zone" | "ZoneInformationSwingerBypassField_zone" | "ZoneInformationTypeField_zone" | "ZoneInformationZoneRealTimeStatusField_zone" | "ZoneInformationZoneRetardDelayField_zone">;
  readonly " $fragmentType": "XFZoneInformationsFields_zone";
};
export type XFZoneInformationsFields_zone$key = {
  readonly " $data"?: XFZoneInformationsFields_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFZoneInformationsFields_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFZoneInformationsFields_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationContext_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationNameField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationLocationField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationTypeField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDMPWirelessField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationSerialNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationContactField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationSupervisionTimeField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationPrewarnKeypadsField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationPresignalKeypadsField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationSwingerBypassField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationCrossZoneField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationFireBellOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedOpenMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedOpenOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedOpenOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedShortMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedShortOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedShortOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationZoneRetardDelayField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationFastResponseField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationZoneRealTimeStatusField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationFirePanelSlaveInputField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationExpanderSerialNumberField_zone"
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "be059cebcc6e4375a0e5f5c15e67be7f";

export default node;
