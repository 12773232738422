import { MouseEvent } from "react";
import {
  Disposable,
  FetchPolicy,
  PreloadedQuery,
  UseMutationConfig,
  UseQueryLoaderLoadQueryOptions,
} from "react-relay";
import { MutationParameters, OperationType, VariablesOf } from "relay-runtime";

export type UnionToIntersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;

export type AnyFunction = (...args: any[]) => any;

export type StringMap<T> = { [key: string]: T };

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type RecursivelyRequired<T> = {
  [P in keyof T]-?: T[P] extends (infer U)[]
    ? RecursivelyRequired<U>[]
    : T[P] extends object
    ? RecursivelyRequired<T[P]>
    : T[P];
};

/**
 * Sets specific fields as optional on a type
 */
export type OptionalFields<Type, Keys extends keyof Type> = Partial<
  Pick<Type, Keys>
> &
  Omit<Type, Keys>;

/**
 * Ensures a type has a value and is not undefined or null
 */
export type EnsureValue<Type> = Type extends undefined
  ? never
  : Type extends null
  ? never
  : Type;

/**
 * Ensures all fields exist on the object, making all fields required
 */
export type EnsureAllFields<Type> = EnsureFields<Type, keyof Type>;

/**
 * Ensures certain fields of a type exist and are not null or undefined,
 * all other fields become optional.
 */
export type EnsureFields<Type, Keys extends keyof Type> = Required<
  Pick<{ [K in keyof Type]-?: EnsureValue<Required<Type[K]>> }, Keys>
> &
  Omit<Partial<Type>, Keys>;

/**
 * Sets specific fields of a type to be Required (not null or undefined).
 */
export type RequireFields<Type, Keys extends keyof Type> = Omit<Type, Keys> &
  Required<
    {
      [Key in Keys]: EnsureValue<Type[Key]>;
    }
  >;

export enum SecureComEnv {
  Dev1 = "dev1",
  Dev2 = "dev2",
  Stage2 = "stage2",
  ScDev = "scdev", //security command dev
  Prod_Eu = "prod_eu",
  Beta = "beta",
  Test = "test",
  Prod_Security_Cmd = "prod_security_cmd",
  Test_Security_Cmd = "test_security_cmd",
  Production = "production",
}

export type ReactMouseEventHandler = (
  event: MouseEvent<HTMLButtonElement>
) => void;

export type Mutable<A> = {
  -readonly [K in keyof A]: Mutable<A[K]>;
};

export type Readonly<A> = {
  readonly [K in keyof A]: Readonly<A[K]>;
};

export type MutableOrReadonly<A> = Mutable<A> | Readonly<A>;

export type Never<K extends keyof any> = {
  [P in K]?: never;
};

export type InferArrayType<Type extends any[]> = Type extends
  | ReadonlyArray<infer U>
  | (infer U)[]
  ? U
  : never;

export type ExtraKeys<A, B> = Exclude<keyof A, keyof B>;

export type NotNullOrUndefined<A> = A extends null | undefined ? never : A;

export type InferPromiseType<A extends Promise<any>> = A extends Promise<
  infer B
>
  ? B
  : never;

export function castAs<A>(arg: any): A {
  return arg as unknown as A;
}

export type ISODateString = string;

export type PrimitiveType = string | number | boolean | null | undefined | Date;

/** Shape of a message from react-intl */
export type IntlMessage = {
  id?: string | number;
  description?: string | object;
  defaultMessage?: string;
  values?: Record<string, PrimitiveType>;
};

/** Shape of use notification hooks, such as `useWarningNotification()`. */
export type UseNotificationType = ({
  id,
  ...rest
}: {
  [x: string]: any;
  id: any;
}) => void;

/**
 * @description Create a type from the values of a type. Useful for typing key value pairs.
 * @example fieldName: keyof FormStateProps, value: ValueOf<FormStateProps>
 */
export type ValueOf<T> = T[keyof T];

/** Standard type of value label pairs used in dropdowns and `react-select` components. */
export type ValueLabelPair = { value: string; label: string };

/** For manually typing query options for Relay */
export type QueryOptionsProps = {
  fetchKey: any;
  fetchPolicy: FetchPolicy;
};

/** 
 * @description Create a mutation function type from a given Relay mutation type. Useful for typing context and reducer values.
 * @example type FormContextTypes = {
      createVideoAction:MutationTypeOf<VideoActionFormCreateMutation>;
 * }
 */
export type MutationTypeOf<T extends MutationParameters> = (
  config: UseMutationConfig<T>
) => Disposable;

/** 
 * @description Create a type for a preloaded query reference from a given Relay query type.
 * @example type Props = {
      queryReference: PreloadedTypeOf<VideoSettingsQuery>;
 * }
 */
export type PreloadedTypeOf<T extends OperationType> = PreloadedQuery<
  T,
  Record<string, unknown>
>;

/** 
 * @description Create a type for a query loader from a given Relay query type.
 * @example type Props = {
        loadUsersPageQuery: QueryLoaderTypeOf<UserFormQuery>
 * }
 */
export type QueryLoaderTypeOf<T extends OperationType> = (
  variables: VariablesOf<T>,
  options?: UseQueryLoaderLoadQueryOptions | undefined
) => void;

/**
 * @description Type match props from react-router
 * @example type Props = {
        match: MatchProps<{databaseCameraId: string}>
 *   }
 * ...
 * const databaseCameraId = match?.params.databaseCameraId;
 */
export type MatchProps<T extends { [K in keyof T]?: string | undefined }> = {
  params: T;
  url: string;
  isExact: boolean;
  path: string;
};

/** Type for event.target.value */
export type GenericEventTargetValue = {
  target?: {
    value?: string;
  };
};

/**
 * @description Get the keys of a nested object as a string union
 * @example
 * someProfileFragment$data: {
 *  profile: {
 *    options: {
 *      arm
 *      disarm
 *    }
 *  }
 * }
 *
 * const myFunction = <T,>(key: NestedKeyOf<T[keyof T]>) => {}
 *
 * myFunction(key) //Key type will be "arm" | "disarm"
 * //Typing key as NestedKeyOf<T> in this case would return type of "profile"
 */
export type NestedKeyOf<T> = T extends object
  ? {
      [K in keyof T]: K extends string
        ? T[K] extends object
          ? `${K}.${NestedKeyOf<T[K]>}`
          : K
        : never;
    }[keyof T]
  : never;

/**
 * @description types aren't exported from `react-modal`, so we have a copy here to use in the apps.
 */
export type ReactModalProps = {
  isOpen?: boolean;
  style?: {
    content?: React.CSSProperties;
    overlay?: React.CSSProperties;
  };
  portalClassName?: string;
  bodyOpenClassName?: string;
  htmlOpenClassName?: string;
  className?:
    | string
    | {
        base: string;
        afterOpen: string;
        beforeClose: string;
      };
  overlayClassName?:
    | string
    | {
        base: string;
        afterOpen: string;
        beforeClose: string;
      };
  appElement: HTMLElement | null;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
  closeTimeoutMS?: number;
  ariaHideApp?: boolean;
  shouldFocusAfterRender?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldReturnFocusAfterClose?: boolean;
  parentSelector?: () => void;
  aria?: Record<string, string>;
  data?: Record<string, string>;
  role?: string;
  contentLabel?: string;
  shouldCloseOnEsc?: boolean;
  overlayRef?: () => void;
  contentRef?: () => void;
};
