/**
 * @generated SignedSource<<2dfbd70b63a861f66eda1e6df3bfdad7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75RemoteOptionsProgrammingConceptForm_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly remoteOptions: {
      readonly __typename: string;
      readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsApnField_remoteOptions" | "RemoteOptionsAppKeyField_remoteOptions" | "RemoteOptionsContext_remoteOptions" | "RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions" | "RemoteOptionsIntegratorBackupConnectionField_remoteOptions" | "RemoteOptionsIntegratorBackupIpAddressField_remoteOptions" | "RemoteOptionsIntegratorBackupTcpPortField_remoteOptions" | "RemoteOptionsIntegratorConnectionField_remoteOptions" | "RemoteOptionsIntegratorDoorReportsField_remoteOptions" | "RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions" | "RemoteOptionsIntegratorIpAddressField_remoteOptions" | "RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions" | "RemoteOptionsIntegratorPassphraseField_remoteOptions" | "RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions" | "RemoteOptionsIntegratorUserCommandReportsField_remoteOptions" | "RemoteOptionsIntegratorVideoReportsField_remoteOptions" | "RemoteOptionsIntegratorZoneReportsField_remoteOptions" | "RemoteOptionsRemoteDisarmField_remoteOptions">;
    } | null;
    readonly softwareVersion: string;
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XT75RemoteOptionsProgrammingConceptForm_controlSystem";
};
export type XT75RemoteOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XT75RemoteOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75RemoteOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75RemoteOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "remoteOptions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsContext_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsRemoteDisarmField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsAppKeyField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsApnField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorConnectionField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorIpAddressField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorBackupConnectionField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorZoneReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorUserCommandReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorDoorReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorPassphraseField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorVideoReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorBackupIpAddressField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorBackupTcpPortField_remoteOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "f9e4e5a87b98dc6c2d4352c1e76827a8";

export default node;
