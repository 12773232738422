import graphql from "babel-plugin-relay/macro";
import LoadingSpinner from "common/components/web/LoadingSpinner";
import Button from "components/Button";
import {
  CleanCheckbox,
  CleanLabeledInput,
  CleanPageHeader,
  CleanPanel,
  CleanSpaceBetween,
  Tooltip,
} from "components/DaStyledElements";
import CleanConfirmingDelete from "components/DaStyledElements/CleanConfirmingDelete";
import {
  useBringPanelOnlineAndSendChanges,
  useProgrammingActionsContext,
} from "components/FullProgramming/common/ProgrammingContext";
import { useShowAlert } from "contexts/AlertsContext";
import useToasts from "hooks/use-toasts";
import React, { useReducer } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
import { asString, toControlSystemId } from "securecom-graphql/client";
import styled from "styled-components/macro";
import reducer, { GroupFormState } from "./reducer";
import { createSequentialArray } from "./validator";
import { BaseGroupFormCreateGroupMutation } from "./__generated__/BaseGroupFormCreateGroupMutation.graphql";
import { BaseGroupFormDeleteGroupMutation } from "./__generated__/BaseGroupFormDeleteGroupMutation.graphql";
import { BaseGroupFormSystemQuery } from "./__generated__/BaseGroupFormSystemQuery.graphql";
import { BaseGroupFormUpdateGroupMutation } from "./__generated__/BaseGroupFormUpdateGroupMutation.graphql";

export const ALL_DOORS_OPTION = {
  id: "all-doors",
  name: "All Doors",
  number: 999999,
};
const ALL_DOORS_ARRAY = createSequentialArray(8);

export const ALL_AREAS_OPTION = {
  id: "all-areas",
  name: "All Areas",
  number: "999999",
};
const ALL_AREAS_ARRAY = createSequentialArray(6);
const ALL_AREAS_ARRAY_STRINGS = ALL_AREAS_ARRAY.map((number) => String(number));
interface BasicDoor {
  id: string;
  name: string;
  number: number;
}

interface BasicArea {
  id: string;
  name: string;
  number: string;
}

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
`;
const Wrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-left: 1.5rem;
  }
  & h2 {
    font-size: 2.2rem;
  }
`;
const SwitchWrapper = styled.div`
  width: 32rem;
  padding-bottom: 0.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`;
const SwitchWrapperWithText = styled.div`
  width: 20rem;
  padding-bottom: 0.8rem;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;
const AuditLabelWrapper = styled.div`
  width: 20rem;
`;

const TextWrapperAudit = styled.div`
  width: 6rem;
  padding-bottom: 0.8rem;
  display: inline-block;
  align-items: center;
`;
const PreProgrammingBanner = styled.div`
  background-color: var(--color-neutral-100);
  border: 2px solid var(--color-info-900);
  border-radius: 0.6rem;
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
`;

const CheckBoxWrapper = styled.div`
  width: 20rem;
  display: flex;
`;

type Props = {
  customerId: string;
  controlSystemId: string;
  groupNumber?: string;
  initialFormState: GroupFormState;
  isNew: boolean;
};

const BaseGroupForm = ({
  customerId,
  controlSystemId,
  groupNumber,
  initialFormState,
  isNew,
}: Props) => {
  const [createToast] = useToasts();

  const systemData = useLazyLoadQuery<BaseGroupFormSystemQuery>(
    graphql`
      query BaseGroupFormSystemQuery($systemId: ID!) {
        system: node(id: $systemId) {
          ... on ControlSystem {
            id
            name
            panel {
              id
              online
              hardwareModel
            }
            groupsConnection {
              edges {
                node {
                  id
                  number
                }
              }
            }
            doorsConnection(trackedOnly: false) {
              edges {
                node {
                  id
                  name
                  number
                }
              }
            }
            areas {
              edges {
                node {
                  id
                  name
                  number
                }
              }
            }
            schedules {
              id
              name
              number
            }
            customer {
              dealer {
                vernaculars {
                  original
                  replacement
                }
                securityCommand
              }
            }
          }
        }
      }
    `,
    {
      systemId: asString(toControlSystemId(controlSystemId)),
    },
    { fetchPolicy: "network-only" }
  );
  const systemReplacement =
    systemData.system?.customer?.dealer.vernaculars.find(
      (v) => v?.original === "systems"
    )?.replacement;
  const controlSystemName = systemData.system?.name;
  const hardwareModel = systemData.system?.panel?.hardwareModel || "";
  const existingGroupNumbers =
    systemData.system?.groupsConnection?.edges.map((edge) =>
      Number(edge.node.number)
    ) || [];

  const systemDoorNumbers: number[] = [];
  const systemDoors = (systemData.system?.doorsConnection?.edges.map((edge) => {
    const door = edge.node;
    if (door) {
      systemDoorNumbers.push(door.number);
    }
    return door;
  }) ?? []) as BasicDoor[];

  const systemAreaNumbers: number[] = [];
  const systemAreas = (systemData.system?.areas?.edges.map((edge) => {
    const area = edge.node;
    if (area) {
      systemAreaNumbers.push(Number(area.number));
    }
    return area;
  }) ?? []) as BasicArea[];

  const availableDoors = [
    ...[ALL_DOORS_OPTION],
    ...systemDoors.sort((a, b) => (a && b ? a.number - b.number : 0)),
  ] as BasicDoor[];
  const availableAreas = [
    ...[ALL_AREAS_OPTION],
    ...systemAreas.sort((a, b) =>
      a && b ? Number(a.number) - Number(b.number) : 0
    ),
  ] as BasicArea[];

  const availableSchedules = systemData.system?.schedules || [];
  const getNextGroupNumber = () => {
    const nextValidNumber = createSequentialArray(10).find(
      (n) => !existingGroupNumbers.includes(n)
    );
    return nextValidNumber?.toString() || "";
  };

  const getAllAvailableGroupNumbers = () =>
    createSequentialArray(10).filter((n) => !existingGroupNumbers.includes(n));

  const findClosestAvailableNumber = (target: number) =>
    getAllAvailableGroupNumbers().reduce((closest, current) =>
      Math.abs(current - target) < Math.abs(closest - target)
        ? current
        : closest
    );

  const auditDaysRegex =
    "^(0{0,2}[0-9]|0?[1-9][0-9]|[1-3][0-9][0-9]|4[0-1][0-9]|42[0-5])$";

  const [createGroup, isCreatingGroup] =
    useMutation<BaseGroupFormCreateGroupMutation>(
      graphql`
        mutation BaseGroupFormCreateGroupMutation(
          $systemId: ID!
          $group: GroupInput!
        ) {
          createGroup(systemId: $systemId, profile: $group) {
            __typename
            ... on CreateGroupSuccessResponse {
              system {
                id
                doorsConnection(trackedOnly: true) {
                  edges {
                    node {
                      id
                      name
                      number
                    }
                  }
                }
                schedules {
                  id
                  name
                  number
                }
                groupsConnection {
                  edges {
                    node {
                      id
                      number
                      name
                      accessibleAreas
                      armableAreas
                      schedules {
                        id
                        name
                        number
                      }
                      doors {
                        id
                        name
                        number
                      }
                      options {
                        armAuthority
                        disarmAuthority
                        swipeTwiceToArm
                        lockdown
                        schedules
                        userProgramming
                        groupsProgramming
                        auditDaysEnabled
                        auditDaysValue
                      }
                      areas {
                        id
                        name
                        number
                      }
                    }
                  }
                }
              }
            }
            ... on CreateGroupErrorResponse {
              message
            }
          }
        }
      `
    );

  const [updateGroup, isUpdatingGroup] =
    useMutation<BaseGroupFormUpdateGroupMutation>(
      graphql`
        mutation BaseGroupFormUpdateGroupMutation(
          $systemId: ID!
          $group: GroupInput!
        ) {
          updateGroup(systemId: $systemId, profile: $group) {
            __typename
            ... on UpdateGroupSuccessResponse {
              system {
                id
                doorsConnection(trackedOnly: true) {
                  edges {
                    node {
                      id
                      name
                      number
                    }
                  }
                }
                schedules {
                  id
                  name
                  number
                }
                groupsConnection {
                  edges {
                    node {
                      id
                      number
                      name
                      accessibleAreas
                      armableAreas
                      schedules {
                        id
                        name
                        number
                      }
                      doors {
                        id
                        name
                        number
                      }
                      options {
                        armAuthority
                        disarmAuthority
                        swipeTwiceToArm
                        lockdown
                        schedules
                        userProgramming
                        groupsProgramming
                        auditDaysEnabled
                        auditDaysValue
                      }
                      areas {
                        id
                        name
                        number
                      }
                    }
                  }
                }
              }
            }
            ... on UpdateGroupErrorResponse {
              message
            }
          }
        }
      `
    );

  const [deleteGroup, isDeletingGroup] =
    useMutation<BaseGroupFormDeleteGroupMutation>(
      graphql`
        mutation BaseGroupFormDeleteGroupMutation(
          $systemId: ID!
          $groupNumber: String!
        ) {
          deleteGroup(systemId: $systemId, profileNumber: $groupNumber) {
            __typename
            ... on DeleteGroupSuccessResponse {
              system {
                id
                groupsConnection {
                  edges {
                    node {
                      id
                      number
                      name
                    }
                  }
                }
              }
            }
            ... on DeleteGroupErrorResponse {
              message
            }
          }
        }
      `
    );

  if (isNew) {
    initialFormState.number = getNextGroupNumber();
  }

  const [formState, dispatch] = useReducer(reducer, initialFormState);

  let sendToSystem = false; // This is true when we are sending groups to the system

  const onDoorChange = (doorNumber: number, checked: boolean) => {
    if (doorNumber === ALL_DOORS_OPTION.number && checked) {
      dispatch({ type: "set-all-doors" });
    } else if (checked) {
      dispatch({ type: "add-door", value: doorNumber });
    } else {
      dispatch({ type: "remove-door", value: doorNumber });
    }
  };

  React.useEffect(() => {
    if (formState.doors.length === 8) {
      dispatch({ type: "set-all-doors" });
    }
  }, [formState.doors]);

  const onAreaChange = (areaNumber: string, checked: boolean) => {
    if (areaNumber === ALL_AREAS_OPTION.number && checked) {
      dispatch({ type: "set-all-areas" });
    } else if (checked) {
      dispatch({ type: "add-area", value: areaNumber });
    } else {
      dispatch({ type: "remove-area", value: areaNumber });
    }
  };
  React.useEffect(() => {
    if (formState.areas.length === 6) {
      dispatch({ type: "set-all-areas" });
    }
  }, [formState.areas]);

  const onScheduleChange = (scheduleNumber: string, checked: boolean) => {
    if (checked) {
      dispatch({ type: "add-schedule", value: scheduleNumber });
    } else {
      dispatch({ type: "remove-schedule", value: scheduleNumber });
    }
  };

  const navigateToGroupsList = () => {
    location.assign(
      `/#/app/customers/${customerId}/control_systems/${controlSystemId}/panels/${controlSystemId}/programming/groups`
    );
  };

  const appName = systemData.system?.customer?.dealer.securityCommand
    ? "Security Command"
    : "Dealer Admin";
  const successGroupsMessage = `Group changes successfully saved to ${appName}`;
  const errorGroupsMessage = `Error saving groups to ${appName}`;
  const successProgramMessage = `Programming changes successfully saved to ${
    systemReplacement ?? "system"
  }`;
  const errorProgramMessage = `Error sending programming changes to ${
    systemReplacement ?? "system"
  }`;

  const onCreate = () => {
    createGroup({
      variables: {
        systemId: asString(toControlSystemId(controlSystemId)),
        group: {
          number: formState.number!,
          name: formState.name,
          options: {
            lockdown: formState.lockdown,
            armAuthority: formState.armAuthority,
            disarmAuthority: formState.disarmAuthority,
            schedules: formState.schedulesEnabled,
            swipeTwiceToArm: formState.swipeTwiceToArm,
            userProgramming: formState.userProgramming,
            groupsProgramming: formState.groupsProgramming,
            auditDaysEnabled: formState.auditDaysEnabled,
            auditDaysValue: formState.auditDaysValue,
          },
          schedules: formState.schedules.map((s) => Number(s)),
          doors:
            formState.doors[0] === 999999 ? ALL_DOORS_ARRAY : formState.doors,
          areas:
            formState.areas[0] === "999999"
              ? ALL_AREAS_ARRAY
              : formState.areas.map((a) => Number(a)),
        },
      },
      onCompleted: (response) => {
        if (response.createGroup.__typename === "CreateGroupErrorResponse") {
          sendToSystem
            ? createToast(
                "error",
                response.createGroup.message ?? errorProgramMessage
              )
            : createToast(
                "error",
                response.createGroup.message ?? errorGroupsMessage
              );
        } else {
          sendToSystem
            ? createToast("success", successProgramMessage)
            : createToast("success", successGroupsMessage);
          navigateToGroupsList();
        }
      },
      onError: () => {
        sendToSystem
          ? createToast("error", errorProgramMessage)
          : createToast("error", errorGroupsMessage);
      },
    });
  };

  const onUpdate = () => {
    updateGroup({
      variables: {
        systemId: asString(toControlSystemId(controlSystemId)),
        group: {
          number: formState.number!,
          name: formState.name,
          options: {
            lockdown: formState.lockdown,
            armAuthority: formState.armAuthority,
            disarmAuthority: formState.disarmAuthority,
            schedules: formState.schedulesEnabled,
            swipeTwiceToArm: formState.swipeTwiceToArm,
            userProgramming: formState.userProgramming,
            groupsProgramming: formState.groupsProgramming,
            auditDaysEnabled: formState.auditDaysEnabled,
            auditDaysValue: formState.auditDaysValue,
          },
          schedules: formState.schedules.map((s) => Number(s)),
          doors:
            formState.doors[0] === 999999 ? ALL_DOORS_ARRAY : formState.doors,
          areas:
            formState.areas[0] === "999999"
              ? ALL_AREAS_ARRAY
              : formState.areas.map((a) => Number(a)),
        },
      },
      onCompleted: (response) => {
        if (response.updateGroup.__typename === "UpdateGroupErrorResponse") {
          sendToSystem
            ? createToast(
                "error",
                response.updateGroup.message ?? errorProgramMessage
              )
            : createToast(
                "error",
                response.updateGroup.message ?? errorGroupsMessage
              );
        } else {
          sendToSystem
            ? createToast("success", successProgramMessage)
            : createToast("success", successGroupsMessage);
          navigateToGroupsList();
        }
      },
      onError: () => {
        sendToSystem
          ? createToast("error", errorProgramMessage)
          : createToast("error", errorGroupsMessage);
      },
    });
  };

  const onSave = formState.persisted ? onUpdate : onCreate;

  const onDelete = () => {
    if (groupNumber) {
      deleteGroup({
        variables: {
          systemId: asString(toControlSystemId(controlSystemId)),
          groupNumber,
        },
        onCompleted: (response) => {
          if (response.deleteGroup.__typename === "DeleteGroupErrorResponse") {
            sendToSystem
              ? createToast(
                  "error",
                  response.deleteGroup.message ?? errorProgramMessage
                )
              : createToast(
                  "error",
                  response.deleteGroup.message ?? errorGroupsMessage
                );
          } else {
            sendToSystem
              ? createToast("success", successProgramMessage)
              : createToast("success", successGroupsMessage);
            navigateToGroupsList();
          }
        },
        onError: () => {
          sendToSystem
            ? createToast("error", errorProgramMessage)
            : createToast("error", errorGroupsMessage);
        },
      });
    }
  };

  const isBusy = isCreatingGroup || isUpdatingGroup || isDeletingGroup;
  const isValid = formState.validationErrors.length === 0;

  React.useEffect(() => {
    const allDoorsAlreadySelected = ALL_DOORS_ARRAY.every(
      (value, idx) => value === formState.rawDoors[idx]
    );

    const allAreasAlreadySelected = ALL_AREAS_ARRAY.every(
      (value, idx) => value === formState.rawAreas[idx]
    );

    if (allDoorsAlreadySelected) {
      dispatch({ type: "set-all-doors" });
    }
    if (allAreasAlreadySelected) {
      dispatch({ type: "set-all-areas" });
    }
  }, []);

  function PreProgrammingSaveButton() {
    return (
      <>
        <Button
          hidden={!systemData.system?.panel?.online}
          design="primary"
          disabled={isBusy || !isValid}
          onClick={() => {
            sendToSystem = false;
            onSave();
          }}
        >
          Save To {appName}
        </Button>
      </>
    );
  }

  function PreProgrammingSendButton({
    systemId,
    hardwareModel,
  }: {
    systemId: string;
    hardwareModel: string;
  }) {
    const bringPanelOnlineAndSendChanges = useBringPanelOnlineAndSendChanges(
      systemId,
      hardwareModel,
      appName
    );
    const programmingContext = useProgrammingActionsContext();
    const showAlert = useShowAlert();
    return (
      <>
        {programmingContext.isSendingPreProgramming ? (
          <PreProgrammingBanner>
            <LoadingSpinner /> Sending Programming to{" "}
            {systemReplacement ?? "System"}
          </PreProgrammingBanner>
        ) : (
          <>
            <Button
              hidden={!systemData.system?.panel?.online}
              design="primary"
              disabled={isBusy || !isValid}
              onClick={() => {
                onSave();
                sendToSystem = true;
                bringPanelOnlineAndSendChanges().catch(() => {
                  programmingContext.setIsSendingPreProgramming(false);
                  showAlert({
                    type: "error",
                    text: "The programming failed to save before attempting initial connection.",
                  });
                });
              }}
            >
              Send To {systemReplacement ?? "System"}
            </Button>
          </>
        )}
      </>
    );
  }
  return (
    <>
      <CleanPageHeader
        leftContent={<h1>{controlSystemName}</h1>}
        rightContent={
          <ButtonsWrapper>
            <Button disabled={isBusy} onClick={navigateToGroupsList}>
              Cancel
            </Button>
            {systemData.system?.panel?.online ? (
              <Button
                design="primary"
                hidden={systemData.system?.panel?.online}
                disabled={isBusy || !isValid}
                onClick={() => {
                  sendToSystem = false;
                  onSave();
                }}
              >
                Save
              </Button>
            ) : (
              <>
                <PreProgrammingSaveButton />
                <PreProgrammingSendButton
                  systemId={controlSystemId}
                  hardwareModel={hardwareModel}
                />
              </>
            )}

            {formState.persisted && (
              <CleanConfirmingDelete
                onConfirm={onDelete}
                message="Are you sure you want to delete this Group?"
                clickReceiver={(onClick) => (
                  <Button design="alert" disabled={isBusy} onClick={onClick}>
                    Delete
                  </Button>
                )}
              />
            )}
          </ButtonsWrapper>
        }
      />
      <Wrapper>
        <h2>Group - {isNew ? "New" : "Edit"}</h2>
        <CleanPanel>
          <CleanSpaceBetween vertical="2.4rem">
            <CleanLabeledInput
              id="group-number"
              name="Group Number"
              value={formState.number}
              errorMessage={formState.validationErrors
                .find((e) => e.fieldName === "number")
                ?.errorMessages.join(" ")
                .trim()}
              onChange={(e) =>
                dispatch({ type: "update-number", value: e.target.value })
              }
              onBlur={(e) => {
                if (isNew) {
                  const valueNumber = Number(e.target.value);
                  if (!getAllAvailableGroupNumbers().includes(valueNumber)) {
                    dispatch({
                      type: "update-number",
                      value: String(findClosestAvailableNumber(valueNumber)),
                    });
                  }
                }
              }}
              disabled={!isNew}
              autoFocus
              maxLength={2}
            />
            <CleanLabeledInput
              id="group-name"
              name="Group Name"
              value={formState.name}
              errorMessage={formState.validationErrors
                .find((e) => e.fieldName === "name")
                ?.errorMessages.join(" ")
                .trim()}
              onChange={(e) =>
                dispatch({ type: "update-name", value: e.target.value })
              }
              onBlur={(e) =>
                dispatch({ type: "update-name", value: e.target.value })
              }
              autoFocus
              maxLength={32}
            />
            <CleanPanel heading="Doors">
              {systemDoors.length ? (
                availableDoors.map((door) => {
                  const checked = formState.doors.includes(door.number);
                  const disabled =
                    door.number !== ALL_DOORS_OPTION.number &&
                    formState.doors.includes(ALL_DOORS_OPTION.number);

                  return (
                    <CheckBoxWrapper>
                      <CleanCheckbox
                        key={door.number}
                        labelText={door?.name || ""}
                        checked={checked}
                        disabled={disabled}
                        onChange={(e) =>
                          onDoorChange(door.number, e.target.checked)
                        }
                      />
                    </CheckBoxWrapper>
                  );
                })
              ) : (
                <p> This {systemReplacement ?? "system"} has no doors </p>
              )}
            </CleanPanel>
            <CleanPanel heading="Areas">
              {systemAreas.length ? (
                availableAreas.map((area) => {
                  const checked = formState.areas.includes(area.number);
                  const disabled =
                    area.number !== ALL_AREAS_OPTION.number &&
                    formState.areas.includes(ALL_AREAS_OPTION.number);

                  return (
                    <CheckBoxWrapper>
                      <CleanCheckbox
                        key={area.number}
                        labelText={area?.name || ""}
                        checked={checked}
                        disabled={disabled}
                        onChange={(e) =>
                          onAreaChange(area.number, e.target.checked)
                        }
                      />
                    </CheckBoxWrapper>
                  );
                })
              ) : (
                <p> This {systemReplacement ?? "system"} has no areas </p>
              )}
            </CleanPanel>
            <CleanPanel heading="Access Schedules">
              {availableSchedules.length !== 0 ? (
                availableSchedules.map((schedule) => {
                  const checked = formState.schedules.includes(schedule.number);
                  const disabled = !checked && formState.schedules.length !== 0;
                  return (
                    <CheckBoxWrapper>
                      <CleanCheckbox
                        key={schedule.number}
                        labelText={schedule?.name || ""}
                        checked={checked}
                        disabled={disabled}
                        onChange={(e) =>
                          onScheduleChange(schedule.number, e.target.checked)
                        }
                      />
                    </CheckBoxWrapper>
                  );
                })
              ) : (
                <p> This {systemReplacement ?? "system"} has no schedules </p>
              )}
            </CleanPanel>
          </CleanSpaceBetween>
        </CleanPanel>
      </Wrapper>
      <Wrapper>
        <h2>Options</h2>
        <CleanPanel>
          <CleanSpaceBetween>
            <CleanPanel>
              <SwitchWrapper>
                <CleanCheckbox
                  labelText=""
                  checked={formState.armAuthority}
                  onChange={() => {
                    dispatch({ type: "toggle-arm-authority" });
                  }}
                />
                <Tooltip content="Allows group members to arm configured areas.">
                  <Tooltip.LabelText>Arm Authority</Tooltip.LabelText>
                </Tooltip>
              </SwitchWrapper>
              <SwitchWrapper>
                <CleanCheckbox
                  labelText=""
                  checked={formState.disarmAuthority}
                  onChange={() => {
                    dispatch({ type: "toggle-disarm-authority" });
                  }}
                />
                <Tooltip content="Allows group members to disarm configured areas.">
                  <Tooltip.LabelText>Disarm Authority</Tooltip.LabelText>
                </Tooltip>
              </SwitchWrapper>
              <SwitchWrapper>
                <CleanCheckbox
                  labelText=""
                  checked={formState.swipeTwiceToArm}
                  disabled={formState.swipeTwiceDisabled}
                  onChange={(e) =>
                    dispatch({ type: "toggle-swipe-twice-to-arm" })
                  }
                />
                <Tooltip content="Allows group members to swipe twice at configured doors to arm configured areas. Arm Authority must be enabled. ">
                  <Tooltip.LabelText>Swipe Twice To Arm</Tooltip.LabelText>
                </Tooltip>
              </SwitchWrapper>
              <SwitchWrapper>
                <CleanCheckbox
                  labelText=""
                  checked={formState.lockdown}
                  onChange={() => dispatch({ type: "toggle-lockdown" })}
                />
                <Tooltip content="Grants group members the ability to initiate and end a lockdown.">
                  <Tooltip.LabelText>Lockdown</Tooltip.LabelText>
                </Tooltip>
              </SwitchWrapper>
              <SwitchWrapper>
                <CleanCheckbox
                  labelText=""
                  checked={formState.userProgramming}
                  onChange={() => dispatch({ type: "toggle-user-programming" })}
                />
                <Tooltip content="Allows group members to add and edit users.">
                  <Tooltip.LabelText>User Programming</Tooltip.LabelText>
                </Tooltip>
              </SwitchWrapper>
              <SwitchWrapperWithText>
                <AuditLabelWrapper>
                  <CleanCheckbox
                    labelText="Audit Days"
                    checked={formState.auditDaysEnabled}
                    onChange={() => dispatch({ type: "toggle-audit-days" })}
                  />
                </AuditLabelWrapper>
                <TextWrapperAudit>
                  <CleanLabeledInput
                    disabled={!formState.auditDaysEnabled}
                    value={formState.auditDaysValue}
                    maxLength={3}
                    pattern={auditDaysRegex}
                    errorMessage={formState.validationErrors
                      .find((e) => e.fieldName === "auditDay")
                      ?.errorMessages.join(" ")
                      .trim()}
                    onChange={(e) =>
                      dispatch({
                        type: "toggle-audit-days-value",
                        value: e.target.value,
                      })
                    }
                  ></CleanLabeledInput>
                </TextWrapperAudit>
              </SwitchWrapperWithText>
            </CleanPanel>
          </CleanSpaceBetween>
        </CleanPanel>
      </Wrapper>
    </>
  );
};

export default BaseGroupForm;
