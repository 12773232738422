import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { idAsString, toDealerId } from "securecom-graphql/client";
import styled from "styled-components";
import TagsSettingsTableItem from "./TagsSettingsTableItem";
import { TagsSettingsTableDealerQuery } from "./__generated__/TagsSettingsTableDealerQuery.graphql";
import { TagsSettingsTableQuery } from "./__generated__/TagsSettingsTableQuery.graphql";

interface TagsSettingsTableProps {
  dealerId: number;
  state: any;
  securityCommandEnabled: boolean;
  billingType: string;
  isSuperUser: boolean;
}
function TagsSettingsTable(props: TagsSettingsTableProps) {
  const { dealerId, state, billingType, isSuperUser } = props;
  const { getDealerTagsRelationsCounts: tagsData } =
    useLazyLoadQuery<TagsSettingsTableQuery>(
      graphql`
        query TagsSettingsTableQuery($dealerId: ID!) {
          getDealerTagsRelationsCounts(id: $dealerId) {
            dealerTag {
              id
              name
              description
              billingDealerId
            }
            tagCustomerCount
            tagPanelCount
          }
        }
      `,
      { dealerId: idAsString(toDealerId(dealerId)) },
      { fetchPolicy: "network-only" }
    );

  const { dealer } = useLazyLoadQuery<TagsSettingsTableDealerQuery>(
    graphql`
      query TagsSettingsTableDealerQuery($dealerId: ID!) {
        dealer: node(id: $dealerId) {
          ... on Dealer {
            vernaculars {
              original
              replacement
            }
          }
        }
      }
    `,
    { dealerId: idAsString(toDealerId(dealerId)) }
  );
  const customerReplacement = dealer?.vernaculars?.find(
    (v) => v?.original === "customers"
  )?.replacement;
  const systemReplacement = dealer?.vernaculars?.find(
    (v) => v?.original === "systems"
  )?.replacement;
  return (
    <PanelTable className="panel panel-table">
      <div className="panel-body">
        <div className="table-responsive">
          <TableBr className="table table-striped table-fixed--not-mobile">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>{systemReplacement ?? "Systems"} Added</th>
                {billingType === "non-billing" ? (
                  <th>
                    {" "}
                    {customerReplacement
                      ? customerReplacement
                      : "Customers"}{" "}
                    Added
                  </th>
                ) : (
                  <th></th>
                )}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {tagsData.length > 0 ? (
                billingType === "non-billing" ? (
                  tagsData.map((tag) => {
                    return tag?.dealerTag.billingDealerId === null ? (
                      <TagsSettingsTableItem
                        key={tag.dealerTag.id}
                        state={state}
                        tag={tag?.dealerTag}
                        customersCount={tag.tagCustomerCount}
                        systemsCount={tag.tagPanelCount}
                        hideIcons={isSuperUser}
                        billingType={billingType}
                      />
                    ) : null;
                  })
                ) : billingType === "billing" ? (
                  tagsData.map((tag) => {
                    if (tag && tag?.dealerTag.billingDealerId !== null) {
                      return (
                        <TagsSettingsTableItem
                          key={tag.dealerTag.id}
                          state={state}
                          tag={tag.dealerTag}
                          systemsCount={tag.tagPanelCount}
                          hideIcons={!isSuperUser}
                          billingType={billingType}
                        />
                      );
                    }
                  })
                ) : (
                  tagsData.map((tag) => {
                    return (
                      tag && (
                        <TagsSettingsTableItem
                          key={tag.dealerTag.id}
                          state={state}
                          tag={tag.dealerTag}
                          customersCount={tag.tagCustomerCount}
                          systemsCount={tag.tagPanelCount}
                          hideIcons={isSuperUser}
                          billingType={billingType}
                        />
                      )
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan={4}>No tags created...</td>
                </tr>
              )}
            </tbody>
            <tfoot>&nbsp;</tfoot>
          </TableBr>
        </div>
      </div>
    </PanelTable>
  );
}

const PanelTable = styled.div`
  border-radius: 0.4rem;
`;
const TableBr = styled.table`
  border-radius: 0.4rem;
`;

export default TagsSettingsTable;
