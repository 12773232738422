import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { KeyfobSupervisionTime } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useKeyfobFragment } from "./KeyfobContext";
import { KeyfobSupervisionTimeField_keyfob$key } from "./__generated__/KeyfobSupervisionTimeField_keyfob.graphql";

export const keyfobSupervisionTimeFieldId = (number: string) =>
  `keyfob-supervision-time-${number}`;

function KeyfobSupervisionTimeField() {
  const [{ number, keyfobSupervisionTime }, updateKeyfob] =
    useKeyfobFragment<KeyfobSupervisionTimeField_keyfob$key>(
      graphql`
        fragment KeyfobSupervisionTimeField_keyfob on Keyfob {
          number
          keyfobSupervisionTime
        }
      `
    );

  const fieldId = keyfobSupervisionTimeFieldId(String(number));
  const is1144Keyfob = number >= 500;

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Supervison Time">
      <Select
        id={fieldId}
        value={keyfobSupervisionTime}
        disabled={is1144Keyfob}
        required
        onChange={({ target }) => {
          updateKeyfob((recordProxy) => {
            recordProxy.setValue(target.value, "keyfobSupervisionTime");
          });
        }}
      >
        <Select.Option value={KeyfobSupervisionTime.NONE}>None</Select.Option>
        <Select.Option value={KeyfobSupervisionTime._60_MIN}>
          60 min
        </Select.Option>
        <Select.Option value={KeyfobSupervisionTime._240_MIN}>
          240 min
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobSupervisionTimeField;
