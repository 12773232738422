App.controller("PersonnelEditCtrl", [
  "$q",
  "$scope",
  "$rootScope",
  "$state",
  "UserService",
  "dealerUser",
  "user_id",
  "dealer_id",
  "CustomRolesService",
  "ImagesService",
  "BusinessAnalyticsService",
  "$modal",
  "TagsService",
  "PersonUsersService",
  function (
    $q,
    $scope,
    $rootScope,
    $state,
    UserService,
    dealerUser,
    user_id,
    dealer_id,
    CustomRolesService,
    ImagesService,
    BusinessAnalyticsService,
    $modal,
    TagsService,
    PersonUsersService
  ) {
    //Update/Add Personnel
    $scope.uploadImage = uploadImage;
    $scope.thisUser = new dealerUser();
    $scope.UserService = UserService;
    $scope.hasCustomRole;
    $scope.salesTerritories = [];
    $scope.salesTerritories.selected = [];
    $scope.tags = [];

    $scope.authorityLevels = UserService.enabledSecurityCommand()
      ? [{ name: "Administrator", code: "admin" }]
      : [
          { name: "Administrator", code: "admin" },
          { name: "Operator", code: "operator" },
          { name: "Sales Person", code: "sales_person" },
          { name: "Sales Manager", code: "sales_manager" },
          { name: "Video Verifier", code: "video_verifier" },
          { name: "Technician", code: "technician" },
          { name: "Accountant", code: "accountant" },
        ];

    $scope.addingNewPersonnel = $state.is("app.dealer.personnel_new");
    $scope.editingPersonnel = $state.is("app.dealer.personnel_edit");

    $scope["customRoleInfo"] = {};
    $scope.customRoleInfo["roles"] = [];
    $scope.customRoleInfo["selectedRole"] = null;
    $scope.customRoleInfo["newRoleAdded"] = false;
    $scope.customRoleService = CustomRolesService;

    $scope.canEditAllOrAddFirstPersonnel = function () {
      return (
        UserService.canEditPersonnel() ||
        ($scope.addingNewPersonnel && UserService.canResetPersonnelPassword())
      );
    };

    $scope.checkHasCustomRole = () =>
      ($scope.hasCustomRole =
        $scope.customRoleInfo.roles.filter(
          (role) => role.name === $scope.thisUser.role
        ).length > 0);

    $scope.mayHaveCustomRole = function () {
      return CustomRolesService.mayHaveCustomRole($scope.thisUser);
    };

    $scope.newRole = function () {
      if (!$scope.customRoleInfo.newRoleAdded) {
        CustomRolesService.getNewRoleTemplate(UserService.dealer_id, 3)
          .then(function (roleTemplate) {
            var newRole = {};
            angular.merge(newRole, roleTemplate);
            $scope.customRoleInfo.roles.push(newRole);
            $scope.customRoleInfo.selectedRole = newRole;
            $scope.customRoleInfo.newRoleAdded = true;
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    };

    $scope.editRole = function () {
      if (
        $scope.customRoleInfo.selectedRole &&
        !$scope.customRoleInfo.selectedRole.isNew
      ) {
        CustomRolesService["breadcrumb"] = {};
        CustomRolesService.breadcrumb["personnel"] = {};
        angular.merge(CustomRolesService.breadcrumb.personnel, $scope.thisUser);
        CustomRolesService.breadcrumb["selectedRoleId"] =
          $scope.customRoleInfo.selectedRole.id;
        $state.go("app.dealer.personnel_custom_roles_edit", {
          role_id: $scope.customRoleInfo.selectedRole.id,
        });
      }
    };

    $scope.authorityChange = function () {
      $scope.thisUser.role = $scope.thisUser.displayRole;
      //ensure we have the available custom roles
      if ($scope.customRoleInfo) {
        //if the authority a user picks is a custom role (by role name)
        let customRole = $scope.customRoleInfo.roles.find(
          (n) => n.name === $scope.thisUser.role
        );
        if (customRole) {
          //set the selectedRole and user_auth_id
          $scope.customRoleInfo.selectedRole = customRole;
          $scope.thisUser.user_auth_id = customRole.id;
          TagsService.getCustomRoleTags(customRole.id).then(
            (tags) => ($scope.tags = tags)
          );
        } else {
          // remove custom role from form
          $scope.customRoleInfo.selectedRole = null;
        }
      }
      $scope.checkHasCustomRole();
    };

    $scope.saveUser = function (returnToEdit) {
      if (
        $scope.customRoleInfo.newRoleAdded &&
        !$scope.customRoleInfo.selectedRole.isNew
      ) {
        removeNewRole();
      }
      if (
        propertyExistsAndEquals(
          $scope.customRoleInfo,
          "selectedRole.isNew",
          true
        )
      ) {
        CustomRolesService.createRole($scope.customRoleInfo.selectedRole)
          .then(
            function (createdRole) {
              // Remove the new role template from the list of roles and replace with the created role in case anything goes wrong in subsequent steps
              var newRoleIdx = indexOfByPropertyValue(
                $scope.customRoleInfo.roles,
                "id",
                $scope.customRoleInfo.selectedRole.id
              );
              $scope.customRoleInfo.roles.splice(newRoleIdx, 1);
              $scope.customRoleInfo.selectedRole = {};
              angular.merge($scope.customRoleInfo.selectedRole, createdRole);
              $scope.customRoleInfo.roles.push(
                $scope.customRoleInfo.selectedRole
              );
              $rootScope.alerts.push({
                type: "success",
                text:
                  "Created role: " + $scope.customRoleInfo.selectedRole.name,
              });
              processPersonnelSave(returnToEdit);
            },
            function () {
              $rootScope.alerts.push({
                type: "error",
                text:
                  "Unable to create role: " +
                  $scope.customRoleInfo.selectedRole.name,
              });
              processPersonnelSave(returnToEdit);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        processPersonnelSave(returnToEdit);
      }
    };

    function removeNewRole() {
      var newRoleIdx = indexOfByPropertyValue(
        $scope.customRoleInfo.roles,
        "isNew",
        true
      );
      $scope.customRoleInfo.roles.splice(newRoleIdx, 1);
    }

    function processPersonnelSave(returnToEdit) {
      //if the user is assigned a custom role, set their authority to admin and disable the authority select
      if ($scope.customRoleInfo && $scope.customRoleInfo.selectedRole) {
        if ($scope.customRoleInfo.selectedRole.parent_user_auth_id == 3) {
          $scope.thisUser.role = "admin";
        } else if (
          $scope.customRoleInfo.selectedRole.parent_user_auth_id == 1
        ) {
          $scope.thisUser.role = "technician";
        }
      }

      var createOrUpdatePersonnel =
        $scope.thisUser.id !== null
          ? $scope.thisUser.update()
          : $scope.thisUser.create();

      createOrUpdatePersonnel
        .then(
          function () {
            // var customRoleEligible = CustomRolesService.mayHaveCustomRole($scope.thisUser);
            var customRoleEligible = true;
            var selectedRole = $scope.customRoleInfo.selectedRole || null;
            var assignedRole = $scope.thisUser.customRole || null;
            // Assign the role if the user is eligible and the selected role is different from the one currently assigned

            if (
              customRoleEligible &&
              selectedRole !== null &&
              (assignedRole === null || +assignedRole.id !== +selectedRole.id)
            ) {
              CustomRolesService.assignRoleToUsers(
                $scope.customRoleInfo.selectedRole,
                $scope.thisUser
              )
                .then(
                  function () {
                    finalizeSuccessfulSave(returnToEdit);
                  },
                  function (error) {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Unable to assign role to user",
                      json: error,
                    });
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
              // Remove if a role is assigned and the user shouldn't have one or they should but none is selected
            } else if (
              assignedRole !== null &&
              (!customRoleEligible ||
                (customRoleEligible && selectedRole === null))
            ) {
              CustomRolesService.removeRoleFromUsers(
                $scope.thisUser.customRole,
                $scope.thisUser
              )
                .then(
                  function () {
                    finalizeSuccessfulSave(returnToEdit);
                  },
                  function (error) {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Unable to remove role from user",
                      json: error,
                    });
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
              // If not adding or removing a custom role
            } else {
              finalizeSuccessfulSave(returnToEdit);
            }
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error saving personnel",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    async function finalizeSuccessfulSave(returnToEdit) {
      if (returnToEdit) {
        $scope.nonSSO = false;
        $state.go(
          "app.dealer.personnel_edit",
          { dealer_id: dealer_id, user_id: $scope.thisUser.id },
          { reload: true }
        );
      } else if (
        !$scope.mainEmail &&
        $scope.thisUser.email.endsWith("@no-reply.dmp.com")
      ) {
        const lastPersonEmail = await PersonUsersService.getPersonUsersEmail([
          $scope.thisUser.person_id,
        ]);
        if (lastPersonEmail) {
          const visibleEmail = lastPersonEmail[0].person_email_address;
          $scope.mainEmail = visibleEmail;

          $rootScope.alerts.push({
            type: "success",
            text: "Saved personnel: " + visibleEmail,
          });
          $state.go(
            "app.dealer.personnel",
            { dealer_id: dealer_id },
            { reload: true }
          );
        }
      } else {
        $rootScope.alerts.push({
          type: "success",
          text: "Saved personnel: " + personnel_user.value,
        });

        $scope.nonSSO = false;
        $state.go(
          "app.dealer.personnel",
          { dealer_id: dealer_id },
          { reload: true }
        );
      }
    }

    $scope.cancelPersonnelEdit = function () {
      // Remove any new roles
      var existingRoles = [];
      angular.forEach($scope.customRoleInfo.roles, function (role) {
        if (!role.isNew) {
          existingRoles.push(role);
        }
      });
      $scope.customRoleInfo.roles = [];
      angular.merge($scope.customRoleInfo.roles, existingRoles);
      $state.go("app.dealer.personnel");
    };

    function uploadImage(file) {
      if (file === undefined) {
        return;
      } // No file was specified, exit out of the function

      $scope.thisUser
        .uploadImage(file)
        .then(
          function () {
            $rootScope.alerts.push({ type: "success", text: "Image uploaded" });
            ImagesService.GetAllSizeURLs($scope.thisUser.user_image_url);
            if ($scope.thisUser.id === UserService.user.id)
              UserService.user.user_image_url = $scope.thisUser.user_image_url;
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", text: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function setSelectedRole(role) {
      $scope.thisUser["customRole"] = {};
      angular.extend($scope.thisUser.customRole, role);
      var roleIdx = indexOfByPropertyValue(
        $scope.customRoleInfo.roles,
        "id",
        role.id
      );
      $scope.customRoleInfo.selectedRole = $scope.customRoleInfo.roles[roleIdx];
      $scope.thisUser.displayRole = $scope.customRoleInfo.roles[roleIdx].name;
      if ($scope.customRole && $scope.customRole.selectedRole) {
        $scope.thisUser.role = $scope.customRoleInfo.roles[roleIdx].name;
      }
    }

    function restorePersonnelAfterRoleEdit() {
      $scope.thisUser.first_name =
        CustomRolesService.breadcrumb.personnel.first_name;
      $scope.thisUser.last_name =
        CustomRolesService.breadcrumb.personnel.last_name;
      $scope.thisUser.email = CustomRolesService.breadcrumb.personnel.email;
      $scope.thisUser.role = CustomRolesService.breadcrumb.personnel.role;
      if (angular.isDefined(CustomRolesService.breadcrumb.selectedRoleId)) {
        CustomRolesService.getRole(
          UserService.dealer_id,
          CustomRolesService.breadcrumb.selectedRoleId
        )
          .then(function (role) {
            setSelectedRole(role);
          })
          .catch(function (error) {
            console.error(error);
          });
      }
      delete CustomRolesService.breadcrumb;
    }

    function init() {
      UserService.isBusy = true;
      CustomRolesService.getRoles(UserService.dealer_id)
        .then(
          function (roles) {
            $scope.customRoleInfo.roles = [];
            angular.merge($scope.customRoleInfo.roles, roles);
            for (let role of roles) {
              $scope.authorityLevels.push({ name: role.name, code: role.name });
            }
            if ($scope.addingNewPersonnel) {
              $scope.nonSSO = true;
              $scope.thisUser
                .new()
                .then(
                  function () {
                    if (CustomRolesService.hasOwnProperty("breadcrumb")) {
                      restorePersonnelAfterRoleEdit();
                    }
                  },
                  function () {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Unable to initialize user. Please reload the page to try again.",
                    });
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else if ($scope.editingPersonnel) {
              $scope.thisUser
                .get(user_id)
                .then(
                  async function () {
                    if (CustomRolesService.hasOwnProperty("breadcrumb")) {
                      restorePersonnelAfterRoleEdit();
                    } else {
                      if (
                        CustomRolesService.mayHaveCustomRole($scope.thisUser)
                      ) {
                        CustomRolesService.getUserRole(dealer_id, user_id)
                          .then(function (role) {
                            if (role !== null) {
                              setSelectedRole(role);
                              $scope.hasCustomRole = true;
                              $scope.thisUser.displayRole = role.name;
                            } else {
                              $scope.thisUser.displayRole =
                                $scope.thisUser.role;
                            }
                          })
                          .catch(function (error) {
                            console.error(error);
                          });
                      } else {
                        $scope.thisUser.displayRole = $scope.thisUser.role;
                      }
                    }
                    //check if there are any emails to replace the guids with, or if they are in local storage for page refresh
                    if (!$rootScope.personEmails) {
                      if ($rootScope.$storage.personEmails) {
                        $rootScope.personEmails = JSON.parse(
                          $rootScope.$storage.personEmails
                        );
                      } else {
                        $rootScope.personEmails = [];
                      }
                      for (let x = 0; x < $rootScope.personEmails.length; x++) {
                        if (
                          $rootScope.personEmails[x].person_id ===
                          $scope.thisUser.person_id
                        ) {
                          $scope.mainEmail =
                            $rootScope.personEmails[x].person_email_address;
                        }
                      }
                    }
                    if (
                      !$scope.mainEmail &&
                      $scope.thisUser.email.endsWith("@no-reply.dmp.com")
                    ) {
                      //One last check in case you you are going directly to the personnel-edit page without every coming from the personnel page
                      const lastPersonEmail =
                        await PersonUsersService.getPersonUsersEmail([
                          $scope.thisUser.person_id,
                        ]);
                      if (lastPersonEmail) {
                        $scope.mainEmail =
                          lastPersonEmail[0].person_email_address;
                      }
                    } else if (!$scope.mainEmail) {
                      $scope.nonSSO = true;
                      $scope.mainEmail = $scope.thisUser.email;
                    }
                  },
                  function () {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Unable to get data for this user.",
                    });
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            }
            UserService.isBusy = false;
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to get roles",
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      function getSalesTerritoryList() {
        BusinessAnalyticsService.getSalespersonsTerritories().then((data) => {
          $scope.salesTerritories = data;
        });
      }
      getSalesTerritoryList();
    }

    /**
     * Creates and opens the change password modal
     */
    $scope.openChangePasswordModal = function () {
      var changePasswordModal = $modal.open({
        templateUrl: `password-change.html`,
        controller: "PasswordChangeCtrl",
        windowClass: "",
        size: "md",
        backdrop: true,
        scope: $scope,
      });
    };

    init();
  },
]);
