/**
 * @ngdoc object
 * @name App.factory:Panel
 * @function
 * @requires
 *
 * @description
 * The panel model.
 *
 */

App.factory("Panel", [
  "UserService",
  "$rootScope",
  "PanelProgrammingService",
  "$filter",
  "$q",
  "PANEL_CONCEPTS",
  "PANEL_SHARED_CONCEPTS",
  "SENSOR_STATUS_READABLE",
  "PANEL_NULL_EXCLUSION_LIST",
  "AXBusService",
  "PanelDefRuleService",
  "QueueService",
  "ReplacePanelService",
  "OnlinePanelService",
  "ClientEventsService",
  "InitialConnectionService",
  "DAY_NOT_ENCODED_SCHEDULE_CONCEPTS",
  "scheduleUtilService",
  "PanelProgArchiveService",
  "PanelCapabilitiesService",
  function (
    UserService,
    $rootScope,
    PanelProg,
    $filter,
    $q,
    PANEL_CONCEPTS,
    PANEL_SHARED_CONCEPTS,
    SENSOR_STATUS_READABLE,
    PANEL_NULL_EXCLUSION_LIST,
    AXBus,
    PanelDefRules,
    QueueService,
    ReplacePanelService,
    OnlinePanelService,
    ClientEventsService,
    InitialConnectionService,
    DAY_NOT_ENCODED_SCHEDULE_CONCEPTS,
    scheduleUtilService,
    PanelProgArchiveService,
    PanelCapabilitiesService
  ) {
    $rootScope.$on("$stateChangeStart", function () {
      PanelProg.init();
    });

    var panel = function (
      panelId,
      panelModel,
      panelVersion,
      panelConcepts,
      panelDefinitionService
    ) {
      // Setup any init value and methods
      angular.extend(this, {
        panel_id: panelId,
        panel_model: panelModel,
        panel_version: panelVersion,
        concepts: panelConcepts,
        conceptsWithDisplay: [],
        fullProgrammingConceptsWithDisplay: [],
        orphans: {},
        statuses: [], // read-only panel state information
        PDS: panelDefinitionService || null,
        session: {},
        allConceptsBusy: false,
        busyBanner: "",
        device_informations_reloaded: false,

        /**
         * @ngdoc object
         * @name method:isBusy
         * @methodOf App.factory:Panel
         *
         * @description
         *  Is the Panel/concept currently busy sending or receiving?
         * @param {String} concept optional. The name of the concept to check isBusy.
         * @returns {boolean} is the panel busy?
         */
        isBusy: function (concept) {
          var _this = this;
          if (concept) {
            return angular.isUndefined(_this[concept].isBusy)
              ? false
              : _this[concept].isBusy;
          } else {
            var busyConcept = false;
            for (var cIdx = 0; cIdx < _this.concepts.length; cIdx++) {
              if (
                angular.isDefined(_this[_this.concepts[cIdx]]) &&
                angular.isDefined(_this[_this.concepts[cIdx]].isBusy) &&
                _this[_this.concepts[cIdx]].isBusy
              ) {
                busyConcept = true;
                break;
              }
            }
            return busyConcept;
          }
        },

        /**
         * @param model {String}
         * @returns {boolean}
         */
        isXR550Family: function (model) {
          return model === "XR550" || model === "XR350" || model === "XR150";
        },

        isXt75: function (model) {
          return model === "XT75";
        },

        isXf: function (model) {
          return model === "XF6_500" || model === "XF6_100";
        },

        isCellCom: function (model) {
          return (
            model === "CellComSL" ||
            model === "CellComEX" ||
            model === "DualCom" ||
            model === "iComSL"
          );
        },

        isNotifyi: function (model) {
          return model === "NFYI";
        },

        isWiredOutput: function (number) {
          var _this = this;
          return (
            (number <= 2 &&
              (_this.panel_model === "CellComSL" ||
                _this.panel_model === "CellComEX" ||
                _this.panel_model === "DualCom" ||
                _this.panel_model === "iComSL" ||
                _this.panel_model === "iComLNC" ||
                _this.panel_model === "TMS6")) ||
            (number <= 4 &&
              (_this.panel_model === "XT50" ||
                _this.panel_model === "XT50L" ||
                _this.panel_model === "XT30" ||
                _this.panel_model === "XT30L")) ||
            (number <= 6 && _this.isXR550Family(_this.panel_model))
          );
        },

        /**
         * Returns true if the model is considered to be fully defined in PanelDef
         * @param model
         * @returns {boolean}
         */
        isPanelFullyDefined: function (model) {
          var fullyDefinedModels = [
            "XR550",
            "XR350",
            "XR150",
            "XT50",
            "XT30",
            "XTLP",
            "CellComSL",
            "CellComEX",
            "DualCom",
            "iComSL",
            "XF6_500",
            "XF6_100",
            "TMS6",
            "XT75",
          ];
          return fullyDefinedModels.includes(model);
        },

        /**
         * @ngdoc object
         * @name method:setConcept
         * @methodOf App.factory:Panel
         *
         * @description
         * Formats the incoming value for a concept for use: sets the .isArray attribute on that concept,
         * and calls function to convert SCAPI object, if necessary; Sets a pristine copy for comparison when sending
         * data; Sets/updates a local concept (local cache) to be manipulated in the UI if told to.
         * ,
         * @param {String} concept - The concept name that is being set
         * @param {Object} value - The value that you are setting
         * @param {boolean} [pristineOnly=false] - Only update the pristine copy; leave the local copy alone
         */
        setConcept: function (concept, value, pristineOnly) {
          var _this = this;
          if (angular.isDefined(concept) && angular.isDefined(value)) {
            // This function will update the local concept unless explicitly told not to
            if (angular.isUndefined(pristineOnly)) {
              pristineOnly = false;
            }

            // Database default value workaround - we need to set all incoming device.wireless_translator_1100t to 'N' if not previously set
            // because the panel def rule will not hide the 1100t_wireless_translator_1100t_frequency field if not
            if (
              concept === "device_informations" &&
              !_this.isXR550Family(_this.panel_model) &&
              PanelCapabilitiesService.supports1100T(
                UserService.controlSystem.panels[0]
              )
            ) {
              for (let device of value) {
                if (device.wireless_translator_1100t === null) {
                  device.wireless_translator_1100t = "N";
                }
              }
            }
            if (
              concept === "device_informations" &&
              (_this.isXR550Family(_this.panel_model) ||
                _this.isXt75(_this.panel_model))
            ) {
              // Clean the strk_time attribute...it comes back from the API with leading spaces
              angular.forEach(value, function (device) {
                if (device.strk_time)
                  device.strk_time = device.strk_time.trim();
              });
              value = _this.format550DeviceInformationsFromSCAPI(value);
            }

            if (concept === "card_formats") {
              angular.forEach(value, function (cardFormat) {
                cardFormat.sitecode_1 = cardFormat.sitecode_1.trim();
                cardFormat.sitecode_2 = cardFormat.sitecode_2.trim();
                cardFormat.sitecode_3 = cardFormat.sitecode_3.trim();
                cardFormat.sitecode_4 = cardFormat.sitecode_4.trim();
                cardFormat.sitecode_5 = cardFormat.sitecode_5.trim();
                cardFormat.sitecode_6 = cardFormat.sitecode_6.trim();
                cardFormat.sitecode_7 = cardFormat.sitecode_7.trim();
                cardFormat.sitecode_8 = cardFormat.sitecode_8.trim();
              });
            }

            if (DAY_NOT_ENCODED_SCHEDULE_CONCEPTS.includes(concept)) {
              scheduleUtilService.manuallySetDays(concept, value);
            }
            var conceptIsArray =
              value.constructor.toString().indexOf("Array") > -1;
            if (conceptIsArray) {
              // Sort the items by number...they may come to us unsorted
              value.sort(function (a, b) {
                return a.number - b.number;
              });
            }
            // Ensure the pristine storage is initialized
            if (angular.isUndefined(_this.pristine)) {
              _this.pristine = {};
            }
            // The pristine storage represents the value stored at SCAPI. Update it.
            _this.pristine[concept] = value;
            _this.pristine[concept].isArray = conceptIsArray;
            _this.pristine[concept].isBusy = false;
            // Replace the local concept
            if (!pristineOnly) {
              _this[concept] = conceptIsArray ? [] : {};
              angular.merge(_this[concept], _this.pristine[concept]);
            }
          }
        },

        /**
         * @ngdoc object
         * @name method:getSCAPIReadyObject
         * @methodOf App.factory:Panel
         *
         * @description
         * Retrieves local concept, preparing it for sending to SCAPI, if necessary
         * @param {string} concept - A panel concept. ex: zone_informations
         * @param {string} itemIdentifierKey - The unique identifier field for a panel entity (number)
         * @param {*} itemIdentifierValue - The value of the itemIdentifierKey field
         */
        getSCAPIReadyObject: function (
          concept,
          itemIdentifierKey,
          itemIdentifierValue
        ) {
          var _this = this;
          var obj = _this.getItemFromArray(
            _this[concept],
            itemIdentifierKey,
            itemIdentifierValue
          );
          if (
            concept === "device_informations" &&
            (_this.isXR550Family(_this.panel_model) ||
              _this.isXt75(_this.panel_model))
          ) {
            obj = _this.format550SCAPIDevice(obj, false);
          }
          return obj;
        },

        /**
         * @ngdoc object
         * @name method:format550DeviceInformationsFromSCAPI
         * @methodOf App.factory:Panel
         *
         * @description
         * Prepares 550 Device Informations concept from SCAPI for use in DA forms
         * @param device_informations {Object} The Device Informations array from SCAPI get response
         * @returns {*}
         */
        format550DeviceInformationsFromSCAPI: function (device_informations) {
          var _this = this;
          var VPLEX_TYPE = "6";

          // If any of the devices do not contain the device734 subsection, queue up a refresh of the concept
          var device734s = [];
          angular.forEach(device_informations, function (device) {
            var requiredAndExists =
              device.tipe === VPLEX_TYPE ||
              (device.tipe !== VPLEX_TYPE &&
                angular.isDefined(device.device734));
            device734s.push(requiredAndExists);
          });
          if (
            device734s.some(function (has734) {
              return has734 === false;
            }) &&
            //checking if the panel is online and has been established tell us
            //whether the panel is pre programming or not
            //we do not want to refresh when we are pre programming
            //because that would bring up the initial connect modal
            //and brining up that modal during pre program could potentially
            //ruin pre programming for the user
            //but i'm not sure if this is the right place to check this
            OnlinePanelService.isOnline() &&
            ClientEventsService.initialConnection.hasBeenEstablished()
          ) {
            refresh(_this, "device_informations");
          }
          angular.forEach(device_informations, function (device) {
            _this.format550SCAPIDevice(device, true);
            if (device.tipe === VPLEX_TYPE) {
              _this.format550VplexDevice(device);
            }
          });
          return device_informations;
        },

        /**
         * This is a list of fields that are unique to 734 options for (door) devices
         * note: this is iterated for both sending to and receiving from SCAPI
         */
        unique734Fields: [
          "zn2_bypass",
          "zn2_relck",
          "zn2_bp_t",
          "zn3_rex",
          "zn3_rex_t",
          "onbrd_spkr",
          "card_opts",
          "wieg_len",
          "sitecode_p",
          "sitecode_l",
          "usercode_p",
          "usercode_l",
          "sitecode_r",
          "sitecode_1",
          "sitecode_2",
          "sitecode_3",
          "sitecode_4",
          "sitecode_5",
          "sitecode_6",
          "sitecode_7",
          "sitecode_8",
          "usercode_d",
          "nocomwpanl",
        ],

        /**
         * @ngdoc object
         * @name method:format550DeviceFromSCAPI
         * @methodOf App.factory:Panel
         *
         * @description
         * Prepares 550 Device from SCAPI for use in DA forms: Flattens incoming object and
         * removes sitecode delete characters
         * @param device {Object} The Device as retrieved from SCAPI get response
         * @param isIncoming {boolean} Indicates if the object is coming into our app
         * @returns {*}
         */
        format550SCAPIDevice: function (device, isIncoming) {
          var _this = this;
          if (isIncoming === true) {
            // Don't choke if device734 subsection hasn't been retrieved yet
            if (angular.isDefined(device.device734)) {
              angular.forEach(_this.unique734Fields, function (field) {
                // Fields were moved to card options in 183 but we may not know the panel version at this point
                if (device.device734.hasOwnProperty(field)) {
                  // note: sitecode may be all spaces, which is meant to indicate nothing is there, so strip them.
                  if (
                    [
                      "sitecode_1",
                      "sitecode_2",
                      "sitecode_3",
                      "sitecode_4",
                      "sitecode_5",
                      "sitecode_6",
                      "sitecode_7",
                      "sitecode_8",
                    ].includes(field)
                  ) {
                    var value = device.device734[field];
                    // This is necessary to prevent null values, returned from a 191-192 panel.
                    // Runs the refresh only once per initialized Panel object
                    if (value == null && !_this.device_informations_reloaded) {
                      refresh(_this, "device_informations");
                      _this.device_informations_reloaded = true;
                      return;
                    } else if (value != null) {
                      device[field] = value.replace(/^ *$/, "");
                    }
                  } else {
                    device[field] = device.device734[field];
                  }
                }
              });
              delete device.device734;
            }
          } else {
            var daDevice = angular.copy(device);
            daDevice.device734_attributes = {};
            daDevice.device734_attributes.number = daDevice.number;
            angular.forEach(_this.unique734Fields, function (field) {
              daDevice.device734_attributes[field] = daDevice[field];
              delete daDevice[field];
            });
            return daDevice;
          }
        },

        /**
         * This is a list of fields that are returned by the panel as dashes for Vplex devices
         * note: this is iterated for both sending to and receiving from SCAPI
         */

        vplexDashFields: [
          "auto_force",
          "comm_type",
          "door_force",
          "door_rts",
          "fire_exit",
          "output_grp",
          "override",
          "prg_734",
          "public_dr",
          "serial_num",
          "private_door",
        ],

        format550VplexDevice: function (device) {
          var _this = this;
          if (
            PanelCapabilitiesService.supportsPrivateDoors(
              UserService.controlSystem.panels[0]
            )
          ) {
            if (device["private_door"] === null) {
              device["private_door"] = "N";
            }
          } else {
            if (!device.hasOwnProperty("private_door")) {
              const index = _this.vplexDashFields.indexOf("private_door");
              _this.vplexDashFields.splice(index, 1);
            }
          }
          angular.forEach(_this.vplexDashFields, function (field) {
            if (device[field].match("-")) {
              if (field == "comm_type") {
                device[field] = "K";
              } else if (field == "serial_num") {
                device[field] = "";
              } else {
                device[field] = "N";
              }
            }
          });
          return device;
        },

        /**
         * @ngdoc object
         * @name method:setStatus
         * @methodOf App.factory:Panel
         *
         * @description
         * Sets the local status to the passed in value, and also sets the .isArray attribute on that status
         * @param {String} status The status name that is being set
         * @param {Object} value The value that you are setting
         */
        setStatus: function (status, value) {
          var _this = this;
          _this.statuses[status] = value;
          _this.statuses[status].isArray =
            value.constructor.toString().indexOf("Array") > -1;
          _this.statuses[status].isBusy = false;
          if (_this.statuses[status].isArray) {
            // Sort the items by number...they may come to us unsorted
            value.sort(function (a, b) {
              return a.number - b.number;
            });
          }
        },

        /**
         * Gets the zone status for the specified zone number
         * @param {string} zoneNumber the zero padded zone number to search for (ex. 001)
         * @returns {string} the human readable zone status
         */
        getZoneStatus: function (zoneNumber) {
          if (
            this.statuses.zone_statuses === undefined ||
            this.statuses.zone_statuses.isBusy === true ||
            !angular.isArray(this.statuses.zone_statuses)
          ) {
            return "Unknown";
          }

          var zoneStatus = $filter("filter")(
            this.statuses.zone_statuses,
            { number: zoneNumber },
            true
          )[0];
          return zoneStatus
            ? SENSOR_STATUS_READABLE[zoneStatus.status]
            : "Unknown";
        },

        /**
         * @ngdoc object
         * @name method:isDirty
         * @methodOf App.factory:Panel
         *
         * @description
         *  Determines whether the data has been modified since the last time it was fetched.
         *
         * @param {String} concept -The name of the concept. ex: zone_informations
         * @param {String} [itemIdentifierKey] - The key to use to search for a specific concept item in an array concept
         * @param {*} [itemIdentifierValue] - The value to use to search for a specific concept item in an array concept
         * @returns {boolean} - Is the specified local concept or item different from the same pristine object
         */
        isDirty: function (concept, itemIdentifierKey, itemIdentifierValue) {
          if (!this.hasOwnProperty(concept)) {
            console.warn(
              "Panel->isDirty() - called for " +
                concept +
                "; not listed on panel object."
            );
            // TODO: If Panel.isDirty() is called for a concept not listed in Panel.concepts, should we return true?
            return true;
          }
          if (!this[concept].hasOwnProperty("isArray")) {
            console.warn(
              "Panel->isDirty() - Panel." +
                concept +
                " does not have isArray property. Concept assumed to be singular concept."
            );
          }
          var localEntity = this[concept].isArray
            ? this.getItemFromArray(
                this[concept],
                itemIdentifierKey,
                itemIdentifierValue
              )
            : this[concept];
          // If the local entity can't be found by KVP, say it's dirty, maybe the send will work
          if (localEntity === false) {
            return true;
          }
          // Remove properties we may have added
          localEntity = removeLocallyManagedProperties(localEntity);
          var pristineEntity = this[concept].isArray
            ? this.getItemFromArray(
                this.pristine[concept],
                itemIdentifierKey,
                itemIdentifierValue
              )
            : this.pristine[concept];
          // If there isn't a pristine copy, they don't match
          if (pristineEntity === false) {
            return true;
          }
          pristineEntity = removeLocallyManagedProperties(pristineEntity);
          return !angular.equals(localEntity, pristineEntity);
        },

        /**
         * @ngdoc object
         * @name method:fetchAllConcepts
         * @methodOf App.factory:Panel
         *
         * @description
         *  Gets the concepts that the current panel family/model support from the DefinitionService from VK cache.
         */
        fetchAllConcepts: function () {
          var deferred = $q.defer();
          var _this = this;
          var fetchConcepts = [];
          angular.merge(fetchConcepts, _this.concepts);
          // If replacing the panel, remove the concepts that cannot be restored
          if (
            ReplacePanelService.replacingPanel() &&
            ReplacePanelService.replacementPanelData.action.toString() ===
              "push"
          ) {
            angular.forEach(["favorites", "user_codes"], function (concept) {
              var i = fetchConcepts.indexOf(concept);
              if (i > -1) {
                fetchConcepts.splice(i, 1);
              }
            });
          }
          var fetchPromises = [];
          angular.forEach(fetchConcepts, function (concept) {
            fetchPromises.push(_this.fetch(concept));
          });

          $q.all(fetchPromises)
            .then(
              function () {
                // $rootScope.alerts.push({"type": "success", "text": "programming retrieved from the server"});
                if (
                  ClientEventsService.initialConnection.hasBeenEstablished()
                ) {
                  deferred.resolve();
                } else {
                  validateSCAPISystemOptions(_this)
                    .then(
                      function () {
                        deferred.resolve();
                      },
                      function (error) {
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                }
              },
              function (error) {
                if (error && error !== "STALE_STATE_CALL") {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "error retrieving programming from the server",
                    json: error,
                  });
                }
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * Initialize local concept if not already done and mark concept as busy
         * @param concept
         */
        ensureInitAndMarkBusy: function (concept) {
          if (!this[concept]) {
            this[concept] =
              DoesNestedPropertyExist(
                this,
                "PDS.panel_def.CONCEPTS." + concept + ".DISPLAY.isArray"
              ) && this.PDS.panel_def.CONCEPTS[concept].DISPLAY.isArray
                ? []
                : {};
          }
          this[concept].isBusy = true;
        },

        /**
         * @ngdoc object
         * @name method:get
         * @methodOf App.factory:Panel
         * @private
         *
         * @description
         *  Gets the specified concept from the API cache, sets it to this.<concept>
         *
         * @param {String} concept the name of the concept
         * @param {object} [additionalParams] - URI parameters
         */
        fetch: function (concept, additionalParams) {
          var deferred = $q.defer();
          var _this = this;
          if (!PANEL_CONCEPTS.hasOwnProperty(concept)) {
            if (
              this.panel_model === "TMS6" &&
              concept === "output_informations"
            ) {
              return deferred.resolve([
                { number: 1, name: "Output 1" },
                { number: 2, name: "Output 2" },
              ]);
            }
            console.warn(
              "PANEL_CONCEPTS does not have " +
                concept +
                " defined. Can't fetch"
            );
            deferred.reject();
            return deferred.promise;
          }
          var apiConcept = PANEL_CONCEPTS[concept].api_name;
          _this.ensureInitAndMarkBusy(concept);
          PanelProg.get(concept, additionalParams, PanelProg.getSessionKey())
            .then(
              function (data) {
                // Set the response data to the matching local concept attribute
                // For example: _this.communication = data.communication
                _this.setConcept(concept, data[apiConcept], false);
                _this[concept].isBusy = false;
                deferred.resolve();
              },
              function (error) {
                if (mayHaveNewItem(_this, error, concept)) {
                  _this
                    .newItem(concept)
                    .then(
                      function (item) {
                        _this.setConcept(concept, item, false);
                        _this[concept].isBusy = false;
                        deferred.resolve();
                      },
                      function (error) {
                        console.error(
                          "Panel->fetch() - Error getting new item template: " +
                            angular.toJson(error)
                        );
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                } else {
                  console.error(
                    "Panel->fetch() - Error getting " +
                      concept +
                      ": " +
                      angular.toJson(error)
                  );
                  // If isBusy is the only property of the concept, delete the concept so it
                  // can be identified as a new concept downstream
                  if (
                    _this.hasOwnProperty(concept) &&
                    _this[concept].hasOwnProperty("isBusy") &&
                    Object.keys(_this[concept]).length === 1
                  ) {
                    delete _this[concept];
                  }
                  deferred.reject(error);
                }
              },
              function (info) {}
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        loadProgBackup: function () {
          var _this = this;
          var deferred = $q.defer();
          // TODO: Validate backup is for dealer/customer/panel in question?
          angular.forEach(this.concepts, function (concept) {
            _this.setConcept(
              concept,
              PanelProgArchiveService.cache.backup.data["Data"][concept],
              false
            );
          });
          deferred.resolve();
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:refresh
         * @methodOf App.factory:Panel
         *
         * @description
         *  Gets the concepts from the panel
         *
         * @param {string|array} [concepts] - a single concept or array of concepts to send. If omitted, all concepts are refreshed
         * @param {boolean} [toastResult|true] - a single concept or array of concepts to send. If omitted, all concepts are refreshed
         */
        refresh: function (concepts, toastResult) {
          var deferred = $q.defer();
          if (typeof toastResult === "undefined") toastResult = true;
          var _this = this;
          var conceptsParameterValid = true;
          if (
            angular.isUndefined(concepts) ||
            (angular.isArray(concepts) && concepts.length === 0)
          ) {
            concepts = _this.concepts;
          } else if (angular.isString(concepts)) {
            concepts = [concepts];
          } else if (!angular.isArray(concepts)) {
            conceptsParameterValid = false;
          }
          if (!conceptsParameterValid) {
            console.warn(
              "Panel->refresh() - concepts parameter is not valid: " +
                angular.toJson(concepts)
            );
            deferred.reject("Concepts parameter not valid");
          } else {
            // Remove invalid concepts
            angular.forEach(concepts, function (concept) {
              if (!PANEL_CONCEPTS.hasOwnProperty(concept)) {
                concepts.splice(concepts.indexOf(concept), 1);
              }
            });
            if (concepts.length === 0) {
              console.warn(
                "Panel->refresh() - no valid concepts: " +
                  angular.toJson(concepts)
              );
              deferred.reject("Concepts parameter not valid");
            } else {
              var promises = [];
              // If performing an operation on all concepts, mark them all busy so spinners show on all concepts till complete
              if (angular.equals(concepts, _this.concepts)) {
                _this.allConceptsBusy = true;
              }
              // Update the busy banner
              _this.busyBanner = "retrieving";
              if (concepts.length === 1) {
                _this.busyBanner += " " + PANEL_CONCEPTS[concepts[0]].data_name;
              } else if (_this.allConceptsBusy) {
                _this.busyBanner += " all";
              }
              _this.busyBanner += " programming from";
              _this.busyBanner += " the system";
              angular.forEach(concepts, function (concept) {
                promises.push(refresh(_this, concept));
              });
              $q.all(promises)
                .then(
                  function (values) {
                    _this.busyBanner = "";
                    _this.allConceptsBusy = false;
                    if (toastResult) {
                      var conceptName =
                        concepts.length === 1
                          ? PANEL_CONCEPTS[concepts[0]].data_name + " "
                          : "";
                      $rootScope.alerts.push({
                        type: "success",
                        text:
                          conceptName + "programming retrieved from the system",
                      });
                    }
                    deferred.resolve(values);
                  },
                  function (error) {
                    _this.busyBanner = "";
                    _this.allConceptsBusy = false;
                    if (error !== "STALE_STATE_CALL" && toastResult) {
                      $rootScope.alerts.push({
                        type: "error",
                        text: "error retrieving programming from the system",
                        json: error,
                      });
                    }
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            }
          }
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:get
         * @methodOf App.factory:Panel
         *
         * @description
         *   Gets or refreshes the specified concept
         * @param {String} concept the name of the concept
         * @param {Object} [additionalParameters]
         * @returns {Promise} promise a promise that will be resolved or rejected
         */
        get: function (concept, additionalParameters) {
          var deferred = $q.defer();
          var _this = this;

          // try to fetch from the cache
          this.fetch(concept, additionalParameters)
            .then(
              function (data) {
                // If we only fetched an empty Array, we may need to also run a refresh...we must at least try.
                if (
                  _this[concept].length < 1 ||
                  _this.conceptHasNullProperty(concept, _this[concept])
                ) {
                  refresh(_this, concept)
                    .then(
                      function (data) {
                        deferred.resolve();
                      },
                      function (error) {
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                } else {
                  // Fetch retrieved something...resolve it.
                  deferred.resolve(_this[concept]);
                }
              },
              function (error) {
                // If we get a 404, then the concept doesn't exist in VK yet, so let's call Refresh
                if (error && error.status == 404) {
                  refresh(_this, concept)
                    .then(
                      function (data) {
                        deferred.resolve();
                      },
                      function (error) {
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                } else {
                  deferred.reject(error);
                }
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * Checks for null properties returned from VK.
         * This is used to determine if we need to force a refresh on a concept
         * @param concept The name of the concept we're working with. Currently only used for logging
         * @param obj The panel concept object to search through for null properties
         * @returns {boolean} True if one of the concept properties is null, else false
         */
        conceptHasNullProperty: function (concept, obj) {
          for (var prop in obj) {
            if (!obj.hasOwnProperty(prop)) {
              continue;
            } // Only check for null on valid properties
            if (obj.isArray) {
              // If this property is an object, recursively loop through it only stopping if a null value is found
              if (this.conceptHasNullProperty(concept, obj[prop])) {
                return true;
              }
            } else {
              // This property isn't an array, check to see if it's null

              // Check to see if this property is in our exclusion list. If so, don't check for a null value
              if (PANEL_NULL_EXCLUSION_LIST[concept]) {
                var exclude = PANEL_NULL_EXCLUSION_LIST[concept].some(function (
                  exclusion
                ) {
                  var expression = new RegExp(exclusion);
                  return expression.test(prop);
                });
                if (exclude) {
                  continue;
                }
              }

              if (obj[prop] == null) {
                return true;
              }
            }
          }

          return false;
        },

        restoreUserCodes: async () => {
          try {
            await PanelProg.restore("user_codes");
          } catch (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to restore User Codes",
            });
          }
        },

        /**
         * @ngdoc object
         * @name method:sendProgramming
         * @methodOf App.factory:Panel
         *
         * @description
         *   Sends the programming that is currently on this Panel object to the panel for ALL concepts.
         *
         * @param {string|array} [concepts] - a single concept or array of concepts to send. If omitted, all concepts are sent
         * @param {boolean} [toastResult=false] - Pop-up toast with result when complete
         * @param {boolean} forceSend - If Truthy, the concept will be sent to the panel even if no programming changes have been made
         * @param {string|null} [itemIdentifierValue] - For array concepts, the number of the entity
         * @returns {Array} an Array of promises, to be resolved with $q.all()
         */
        sendProgramming: function (
          concepts,
          toastResult,
          forceSend,
          itemIdentifierValue,
          syncPanelUserCodesWithScapi = false
        ) {
          $rootScope.isRestoringPanel = PanelProgArchiveService.restoringPanel;
          var deferred = $q.defer();
          var _this = this;
          // Make concepts into an array
          var conceptsParameterValid = true;
          if (
            angular.isUndefined(concepts) ||
            (angular.isArray(concepts) && concepts.length === 0)
          ) {
            concepts = _this.concepts;
          } else if (angular.isString(concepts)) {
            concepts = [concepts];
          } else if (!angular.isArray(concepts)) {
            conceptsParameterValid = false;
          }
          // Feature_keys is mostly read-only. We want to remove them from the list of concepts sent before we send all concepts.
          if (concepts.length > 1 && concepts.includes("feature_keys")) {
            delete concepts[concepts.indexOf("feature_keys")];
          }
          if (concepts.length > 1 && concepts.includes("pc_log_reports")) {
            // This and menu display have been added to panel def for the retry concept but do not work for backups.
            delete concepts[concepts.indexOf("pc_log_reports")];
          }
          if (concepts.length > 1 && concepts.includes("menu_display")) {
            delete concepts[concepts.indexOf("menu_display")];
          }
          if (!conceptsParameterValid) {
            console.warn(
              "Panel->SendProgramming() - concepts parameter is not valid: " +
                angular.toJson(concepts)
            );
            deferred.reject("Concepts parameter not valid");
          } else {
            // Remove invalid concepts
            angular.forEach(concepts, function (concept) {
              if (_this.concepts.indexOf(concept) === -1) {
                concepts.splice(concepts.indexOf(concept), 1);
              }
            });
            if (
              (ReplacePanelService.replacingPanel() &&
                ReplacePanelService.replacementPanelData.action.toString() ===
                  "push") ||
              PanelProgArchiveService.restoringPanel ||
              forceSend
            ) {
              // remove concepts that cannot be restored
              let excludedConcepts = ["favorites"];

              if (syncPanelUserCodesWithScapi) {
                this.restoreUserCodes();
              }

              if (ReplacePanelService.replacingPanel() || forceSend) {
                // When the replacement is finalized, a command is sent to SCAPI to restore the codes. If you retrieve / delete
                // user codes, SCAPI will have nothing to restore
                excludedConcepts.push("user_codes");
              }
              if (forceSend) {
                excludedConcepts.push("holiday_dates");

                if (UserService.controlSystem.panels[0].online) {
                  //we only want to send these when the panel is in the pre-programmed state
                  excludedConcepts.push("output_schedules");
                  excludedConcepts.push("favorite_schedules");
                  excludedConcepts.push("schedules");
                  excludedConcepts.push("time_schedules");
                  excludedConcepts.push("profiles");
                }

                if (
                  UserService.controlSystem.panels[0].hardware_family ===
                  "XR550"
                ) {
                  excludedConcepts.push("output_schedules");
                  excludedConcepts.push("favorite_schedules");
                  excludedConcepts.push("schedules");
                } else {
                  excludedConcepts.push("time_schedules");
                  excludedConcepts.push("profiles");
                }
              }
              angular.forEach(excludedConcepts, function (concept) {
                let i = concepts.indexOf(concept);
                if (i > -1) {
                  concepts.splice(i, 1);
                }
              });
            }
            if (concepts.length === 0) {
              console.warn(
                "Panel->SendProgramming() - no valid concepts: " +
                  angular.toJson(concepts)
              );
              deferred.reject("Concepts parameter not valid");
            } else {
              modifiedConcepts = [];
              // If performing an operation on all concepts, mark them all busy so spinners show on all concepts till complete
              if (angular.equals(concepts, _this.concepts)) {
                _this.allConceptsBusy = true;
              }
              // Update the busy banner
              _this.busyBanner = PanelProg.savingProgramming
                ? "saving"
                : "sending";
              if (concepts.length === 1) {
                _this.busyBanner += " " + PANEL_CONCEPTS[concepts[0]].data_name;
              } else if (_this.allConceptsBusy) {
                _this.busyBanner += " all";
              }
              _this.busyBanner += " programming to";
              _this.busyBanner += PanelProg.savingProgramming
                ? " dealer admin"
                : " the system";
              if (shouldRefreshPristineBeforeSend(concepts)) {
                var refreshPromises = [];
                angular.forEach(concepts, function (concept) {
                  refreshPromises.push(refresh(_this, concept, true));
                });
                if (_this.isXR550Family(_this.panel_model)) {
                  // add feature keys back in once we refresh
                  refreshPromises.push(refresh(_this, "feature_keys", true));
                }
                $q.all(refreshPromises)
                  .then(
                    function () {
                      queueConceptsForSend(
                        _this,
                        concepts,
                        forceSend,
                        toastResult,
                        itemIdentifierValue
                      )
                        .then(
                          function () {
                            _this.busyBanner = "";
                            deferred.resolve();
                          },
                          function (error) {
                            _this.busyBanner = "";
                            deferred.reject(error);
                          }
                        )
                        .catch(function (error) {
                          console.error(error);
                        });
                    },
                    function (error) {
                      _this.busyBanner = "";
                      console.error(
                        "Panel->SendProgramming() - Error refreshing concepts: " +
                          angular.toJson(error)
                      );
                      _this.allConceptsBusy = false;
                      deferred.reject(error);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              } else {
                return queueConceptsForSend(
                  _this,
                  concepts,
                  forceSend,
                  toastResult,
                  itemIdentifierValue
                );
              }
            }
          }
          return deferred.promise;
        },

        /**
         * Send all updated programming to VK, not to be sent to the panel
         */
        saveProgramming: function () {
          var _this = this;
          var deferred = $q.defer();
          if (OnlinePanelService.isOffline()) {
            PanelProg.savingProgramming = true;
            // Set wait for all to true in case any refreshes, creates or updates fail. There will most likely be
            // others queued up. You wouldn't want to restore the panel online and then have any of the queued up
            // requests succeed

            _this
              .sendProgramming([], true, false)
              .then(
                function () {
                  PanelProg.savingProgramming = false;
                  deferred.resolve();
                },
                function (error) {
                  PanelProg.savingProgramming = false;
                  console.error(
                    "Panel->saveProgramming() Error: " + angular.toJson(error)
                  );
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to save programming - system is online",
            });
            deferred.reject("Panel is online.");
          }
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:cancelEdit
         * @methodOf App.factory:Panel
         *
         * @description
         *   Reverts changes that have been made to a concept by overwriting them with the local pristine copy
         *
         * @param {String} concept The name of the concept to be overwritten by the pristine copy
         */
        cancelEdit: function (concept) {
          if (this.pristine.hasOwnProperty(concept)) {
            this[concept] = angular.copy(this.pristine[concept]);
            // if isArray is defined, copy it back over
            if (this.pristine[concept].hasOwnProperty("isArray")) {
              this[concept].isArray = this.pristine[concept].isArray;
            }
            // If isBusy is defined, copy it back over
            if (this.pristine[concept].hasOwnProperty("isBusy")) {
              this[concept].isBusy = this.pristine[concept].isBusy;
            }
          }
        },

        /**
         * @ngdoc object
         * @name method:getJSON
         * @methodOf App.factory:Panel
         *
         * @description
         *   Formats the specified concept into the correct format to be used in the request body for an update, create, etc.
         *
         * @param {String} concept The name of the concept to be updated
         * @param {Object} theItem Optional.  A single item from a multi-item concept.  If supplied, this is the actual item to be formatted.
         * @returns {Object} the concept, formatted correctly for the request body
         */
        getJSON: function (concept, theItem) {
          var json = {};
          if (this[concept] && this[concept].isArray) {
            // Format what needs to be updated
            var singularConcept = $filter("singularize")(concept);
            json[singularConcept] = theItem;
          } else {
            json[concept] = this[concept];
          }
          return json;
        },

        /**
         * @ngdoc object
         * @name method:update
         * @methodOf App.factory:Panel
         *
         * @description
         *   Update a panel programming concept
         *
         * @param {String} concept - The name of the concept to be updated
         * @param {String} [itemId] - The number of the specific item to update
         * @param {Object} [theItem] - A single item from a multi-item concept.  If supplied, this is the actual item to be formatted.
         */
        update: function (concept, itemId, theItem) {
          var deferred = $q.defer();
          if (angular.isDefined(concept) && this.hasOwnProperty(concept)) {
            var _this = this;
            _this[concept].isBusy = true;
            PanelProg.update(
              concept,
              _this.getJSON(concept, theItem),
              itemId,
              false,
              PanelProg.getSessionKey()
            )
              .then(
                function (data) {
                  // Set the response data to the matching local concept attribute
                  // For example: this.communication = data.communication
                  _this[concept].isBusy = false;
                  deferred.resolve();
                },
                function (error) {
                  _this.handleError(error, concept);
                  _this[concept].isBusy = false;
                  deferred.reject(error);
                },
                function (info) {
                  _this[concept].isBusy = true;
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else {
            deferred.reject("system does not contain concept: " + concept);
          }
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:handleError
         * @methodOf App.factory:Panel
         *
         * @description
         *   SCAPI Error handler for this object
         *
         * @param {Object} errorData the error data object
         * @param {String} concept the name of the concept
         */
        handleError: function (errorData, concept) {
          var _this = this;
          if (angular.isUndefined(_this.errors)) {
            _this.errors = {};
          }
          if (angular.isUndefined(_this.errors[concept])) {
            _this.errors[concept] = {};
          }

          if (
            errorData &&
            errorData.data &&
            errorData.data.errors &&
            errorData.config &&
            errorData.config.data
          ) {
            if (_this.hasOwnProperty(concept) && _this[concept].isArray) {
              // Attach the error to the correct item in the concept
              var singularConcept = $filter("singularize")(concept);
              var itemNumber = errorData.config.data[singularConcept].number;
              // If no error object exists yet, create one
              if (angular.isUndefined(_this.errors[concept][itemNumber])) {
                _this.errors[concept][itemNumber] = {};
              }
              _this.setInvalidIndicator(true, concept, +itemNumber);
              // Add the new error onto the list
              angular.extend(
                _this.errors[concept][itemNumber],
                errorData.data.errors
              );
            } else {
              // Attach the error to the concept
              angular.extend(_this.errors[concept], errorData.data.errors);
              _this.setInvalidIndicator(true, concept);
            }
          } else {
          }
        },

        /**
         * Places or removes the given error message on panelerrors
         * @param form - the ng-form for the concept in question
         * @param conceptKey {string} name of the concept
         * @param recordNumber {int} the item number
         * @param field {string} a field within a concept
         * @param hasErrors {bool} true if is in error
         * @param errorMessage {string} the error message to add or remove from panelerrors
         */
        setPanelErrors: function (
          form,
          conceptKey,
          recordNumber,
          field,
          hasErrors,
          errorMessage
        ) {
          var _this = this;
          if (hasErrors === true) {
            // Attach a message to the panel item
            // First, make sure the right folder is there
            if (angular.isUndefined(_this.errors)) _this.errors = {};
            if (angular.isUndefined(_this.errors[conceptKey]))
              _this.errors[conceptKey] = {};
            if (angular.isDefined(recordNumber)) {
              if (angular.isUndefined(_this.errors[conceptKey][recordNumber]))
                _this.errors[conceptKey][recordNumber] = {};
              if (
                angular.isUndefined(
                  _this.errors[conceptKey][recordNumber][field]
                )
              )
                _this.errors[conceptKey][recordNumber][field] = [];
              // Second, add the error if it's not already there
              if (
                _this.errors[conceptKey][recordNumber][field].indexOf(
                  errorMessage
                ) === -1
              )
                _this.errors[conceptKey][recordNumber][field].push(
                  errorMessage
                );
            } else {
              if (angular.isUndefined(_this.errors[conceptKey][field]))
                _this.errors[conceptKey][field] = [];
              if (_this.errors[conceptKey][field].indexOf(errorMessage) === -1)
                _this.errors[conceptKey][field].push(errorMessage);
            }
            // Set panelerrors to true on the form
            form[field].$error["panelerrors"] = true;
          } else if (hasErrors === false) {
            if (angular.isDefined(recordNumber)) {
              if (
                _this.errors &&
                _this.errors[conceptKey] &&
                _this.errors[conceptKey][recordNumber] &&
                _this.errors[conceptKey][recordNumber][field]
              )
                delete _this.errors[conceptKey][recordNumber][field];
            } else {
              if (
                _this.errors &&
                _this.errors[conceptKey] &&
                _this.errors[conceptKey][field]
              )
                delete _this.errors[conceptKey][field];
            }
            if (
              form[field] &&
              form[field].$error &&
              form[field].$error.panelerrors
            )
              form[field].$setValidity("panelerrors", true);
          }
        },

        /**
         * Initialize objects to store session data
         * @param {string} [conceptKey] - The name of the concept. If omitted, this function just initializes the
         * session data
         * @param {string|number} [number] - The number of an array concept entity
         * @param {string} [field] - The name of a field
         */
        initializeSessionData: function (conceptKey, number, field) {
          var _this = this;

          if (!_this.session.hasOwnProperty("panelDefRules")) {
            this.session["panelDefRules"] = PanelDefRules.getRules(
              this,
              this.conceptsWithDisplay
            );
            _this.session["CONCEPTS"] = {};
          }

          if (angular.isDefined(conceptKey)) {
            // Initialize the session object for the concept if it hasn't already been
            if (!_this.session.CONCEPTS.hasOwnProperty(conceptKey)) {
              _this.session.CONCEPTS[conceptKey] = {};
            }

            // If there is an item number, initialize a numbered object for it if it's not there
            if (angular.isDefined(number)) {
              if (angular.isString(number)) {
                number = +number;
              }
              if (
                !_this.session.CONCEPTS[conceptKey].hasOwnProperty("NUMBERS")
              ) {
                _this.session.CONCEPTS[conceptKey]["NUMBERS"] = {};
              }
              if (
                !_this.session.CONCEPTS[conceptKey].NUMBERS.hasOwnProperty(
                  number
                )
              ) {
                _this.session.CONCEPTS[conceptKey].NUMBERS[number] = {};
              }
              if (
                !_this.session.CONCEPTS[conceptKey].NUMBERS[
                  number
                ].hasOwnProperty("FIELDS")
              ) {
                _this.session.CONCEPTS[conceptKey].NUMBERS[number]["FIELDS"] =
                  {};
              }
              if (angular.isDefined(field)) {
                if (
                  !_this.session.CONCEPTS[conceptKey].NUMBERS[
                    number
                  ].FIELDS.hasOwnProperty(field)
                ) {
                  _this.session.CONCEPTS[conceptKey].NUMBERS[number].FIELDS[
                    field
                  ] = {};
                }
              }
            }
            // If there is a field, initialize an object for the field if it's not there
            else if (angular.isDefined(field)) {
              if (
                !_this.session.CONCEPTS[conceptKey].hasOwnProperty("FIELDS")
              ) {
                _this.session.CONCEPTS[conceptKey]["FIELDS"] = {};
              }
              if (
                !_this.session.CONCEPTS[conceptKey].FIELDS.hasOwnProperty(field)
              ) {
                _this.session.CONCEPTS[conceptKey].FIELDS[field] = {};
              }
            }

            // For System Area Information (550 family), initialize area_informations as an indicator next to the Areas accordion
            if (conceptKey === "system_area_information") {
              if (angular.isUndefined(_this.session.CONCEPTS.area_informations))
                _this.session.CONCEPTS["area_informations"] = {};
            }

            if (conceptKey === "card_formats") {
              if (angular.isUndefined(_this.session.CONCEPTS.card_formats)) {
                _this.session.CONCEPTS["card_formats"] = {};
              }
            }
          }
        },

        /**
         * Set the invalid indicator for each section and/or panel item in a concept
         * @param val
         * @param conceptKey
         * @param number
         * @param field
         */
        setInvalidIndicator: function (val, conceptKey, number, field) {
          var _this = this;
          if (
            angular.isDefined(field) &&
            DoesNestedPropertyExist(
              _this.session.CONCEPTS,
              conceptKey + ".FIELDS." + field
            )
          ) {
            _this.session.CONCEPTS[conceptKey].FIELDS[field]["invalidForm"] =
              val;
            _this.session.CONCEPTS[conceptKey]["invalidForm"] =
              _this.session.CONCEPTS[conceptKey].FIELDS[field]["invalidForm"] ||
              _this.anyInvalidForm(_this.session.CONCEPTS[conceptKey]) ||
              val;
            // TODO: Add invalidForm indicator to tabs of concept. Either here...
            // if (_this.isDefined(_this.PDS.panel_def.CONCEPTS[conceptKey].FIELDS[field].DISPLAY.FORM_GROUP)) {
            // }
          } else {
            if (isUndefinedOrNull(number)) {
              // If this is system area information, you need to check if any of the areas have a bad form
              if (conceptKey === "system_area_information") {
                var anyInvalidChild = angular.isDefined(
                  _this.session.CONCEPTS.area_informations
                )
                  ? _this.anyInvalidForm(
                      _this.session.CONCEPTS.area_informations
                    )
                  : false;
                _this.session.CONCEPTS[conceptKey]["invalidForm"] =
                  anyInvalidChild || val;
              } else if (
                angular.isDefined(_this.session.CONCEPTS[conceptKey])
              ) {
                _this.session.CONCEPTS[conceptKey]["invalidForm"] = val;
              }
            } else if (
              DoesNestedPropertyExist(
                _this.session.CONCEPTS,
                conceptKey + ".NUMBERS." + number
              )
            ) {
              _this.session.CONCEPTS[conceptKey].NUMBERS[number][
                "invalidForm"
              ] = val;
              // TODO: or here
              // If this field is part of a tab
              // if (_this.isDefined(_this.PDS.panel_def.CONCEPTS[conceptKey].FIELDS[field].DISPLAY.FORM_GROUP)) {
              // }
              var conceptIndicator =
                _this.anyInvalidForm(_this.session.CONCEPTS[conceptKey]) || val;
              // If this is an XR550 Area, the bubble-up indicator is on System Area Information
              if (
                conceptKey === "area_informations" &&
                _this.isXR550Family(_this.panel_model)
              ) {
                // The Areas Tab
                _this.session.CONCEPTS.area_informations["invalidForm"] =
                  // The concept header
                  _this.session.CONCEPTS.system_area_information[
                    "invalidForm"
                  ] = conceptIndicator;
              } else {
                _this.session.CONCEPTS[conceptKey]["invalidForm"] =
                  conceptIndicator;
              }
            }
          }
        },

        isVplexZone: function (num) {
          /*
              If the item is a v-plex device, we need to allow the cooresponding 
              zones to show the serial number even if wireless is turned off.
              Ex: If device 501 is a v-plex, zones 500-595 need to show the serial number option.
            */
          num = parseInt(num);
          let zoneIsInRange = false;
          let devices = this.device_informations;
          if (!devices) {
            return false;
          }

          let vplexDevices = devices.filter((device) => device.tipe == "6");

          let ranges = [];
          for (let bus of vplexDevices) {
            let busNum = parseInt(bus.lx_number);
            let range = { min: busNum - 1, max: busNum + 94 }; //ex: device 801 min zone becomes 800 and max zone becomes 895 per the spec
            ranges.push(range);
          }

          const isInRange = (range, num) => {
            return num >= range.min && num <= range.max;
          };

          for (let range of ranges) {
            if (isInRange(range, num)) {
              zoneIsInRange = true;
            }
          }

          return zoneIsInRange;
        },

        isVplexNumber: function (number) {
          let vplexNums = [501, 601, 701, 801, 901];
          return vplexNums.includes(parseInt(number));
        },

        keypadOrAXBus: function (number) {
          //(0{0,2}[1-9]|0?1[0-6]|[5-9](01|05|09|13|17|21|25|29|33|37|41|45|49|53|57|61)) paneldef
          let parsed = parseInt(number, 10);
          //'\b(0?[1-9]|1[0-6])\b'
          let keypadBus = new RegExp("^[0-9]{1}$|^0[0-9]{1}$|^1[0-6]{1}$");
          let axBus = new RegExp(
            "[5-9][0,2,4][1,5,9]|[5-9][1,3,5][3,7]|[5-9][6][1]"
          );
          if (keypadBus.test(parsed)) {
            return "Keypad Bus";
          }
          if (axBus.test(parsed)) {
            return "AX Bus";
          }
        },

        /**
         * Does the concept contain an item that has errors
         * @param conceptSessionData
         * @returns {boolean}
         */
        anyInvalidForm: function (conceptSessionData) {
          if (conceptSessionData.NUMBERS) {
            var numbers = Object.keys(conceptSessionData.NUMBERS);
            for (var nIdx = 0; nIdx < numbers.length; nIdx++) {
              if (conceptSessionData.NUMBERS[numbers[nIdx]].invalidForm) {
                return true;
              }
            }
          }
          if (conceptSessionData.FIELDS) {
            var fields = Object.keys(conceptSessionData.FIELDS);
            for (var fIdx = 0; fIdx < fields.length; fIdx++) {
              if (conceptSessionData.FIELDS[fields[fIdx]].invalidForm) {
                return true;
              }
            }
          }
          return false;
        },

        /**
         * Remove an invalid indicator from an item in a concept
         * @param conceptKey
         * @param number
         */
        removeBubbleUpErrors: function (conceptKey, number) {
          var _this = this;
          _this.setInvalidIndicator(false, conceptKey, number);
          if (
            angular.isDefined(_this.session.CONCEPTS[conceptKey]) &&
            angular.isDefined(_this.session.CONCEPTS[conceptKey].NUMBERS) &&
            angular.isDefined(
              _this.session.CONCEPTS[conceptKey].NUMBERS[number]
            )
          )
            delete _this.session.CONCEPTS[conceptKey].NUMBERS[number];
        },

        /**
         * Function to update the number of items available for a given concept, stored on the panel_def session info
         * @param conceptKey
         */
        updateItemsAvailable: function (conceptKey) {
          var _this = this;
          var conceptKeys = _this.isSharedConcept(conceptKey)
            ? PANEL_SHARED_CONCEPTS
            : [conceptKey];
          angular.forEach(conceptKeys, function (updateConcept) {
            _this.initializeSessionData(updateConcept);
            _this.session.CONCEPTS[updateConcept]["itemsAvailable"] =
              _this.itemsAvailable(updateConcept);
          });
        },

        /**
         * @ngdoc object
         * @name method:deleteItem
         * @methodOf App.factory:Panel
         *
         * @description
         *   Delete a panel programming concept item.  Only the specified item will be deleted.
         *
         * @param {String} concept - The name of the concept to be deleted
         * @param {String} item - The specific item to delete
         * @param {bool} [toast|true] - Toast the result
         */
        deleteItem: function (concept, item, toast) {
          var deferred = $q.defer();
          var _this = this;
          if (angular.isUndefined(toast)) {
            toast = true;
          }
          // If the user wants to delete a new zone, we don't have to go to the panel for that...just splice it out.
          if (item && item.isNew) {
            _this.removeBubbleUpErrors(concept, +item.number);
            // Find the item index in the concept
            _this[concept].splice(_this[concept].indexOf(item), 1);
            deferred.resolve();
          } else {
            _this[concept].isBusy = true;
            PanelProg.delete(
              concept,
              item.number,
              undefined,
              PanelProg.getSessionKey()
            )
              .then(
                function (data) {
                  _this.removeBubbleUpErrors(concept, +item.number);
                  // Set the response data to the matching local concept attribute
                  // For example: this.communication = data.communication
                  _this.setConcept(concept, data[concept], false);
                  if (toast) {
                    $rootScope.alerts.push({
                      type: "success",
                      text:
                        PANEL_CONCEPTS[concept].single_name +
                        " " +
                        item.number +
                        " was deleted from the system",
                    });
                  }
                  _this[concept].isBusy = false;
                  deferred.resolve();
                },
                function (error) {
                  if (toast) {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Error Deleting Item for " + concept,
                      json: error,
                    });
                  }
                  _this[concept].isBusy = false;
                  deferred.reject(error);
                },
                function (info) {
                  _this[concept].isBusy = true;
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:create
         * @methodOf App.factory:Panel
         *
         * @description
         *   Create a panel programming single concept or concept item.
         *
         * @param {String} concept The name of the concept to be created
         * @param {Object} theItem A single item from a multi-item concept.  This is the actual item to be formatted.
         */
        create: function (concept, theItem) {
          var deferred = $q.defer();
          var _this = this;
          _this[concept].isBusy = true;
          var item = _this.getJSON(concept, theItem);
          PanelProg.create(concept, item, false, PanelProg.getSessionKey())
            .then(
              function (data) {
                // Set the response data to the matching local concept attribute
                // For example: this.communication = data.communication
                _this[concept].isBusy = false;
                deferred.resolve();
              },
              function (error) {
                _this.handleError(error, concept);
                _this[concept].isBusy = false;
                deferred.reject(error);
              },
              function (info) {
                _this[concept].isBusy = true;
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:deleteAllItemsFromConcept
         * @methodOf App.factory:Panel
         *
         * @description
         *   Deletes all items that exist in the specified concept.  For example, all zones in a zone_informations concept.
         *
         * @param {String} concept The name of the concept
         */
        deleteAllItemsFromConcept: function (concept) {
          var deferred = $q.defer();
          var _this = this;
          var deleteQueue = [];

          angular.forEach(_this[concept], function (item) {
            deleteQueue.push(_this.deleteItem(concept, item));
          });

          $q.all(deleteQueue)
            .then(
              function (data) {
                deferred.resolve();
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });

          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:newItem
         * @methodOf App.factory:Panel
         *
         * @description
         *   Hits the NEW Api for the given concept, then adds the new item to the concept's array
         *   Also sets the isNew flag, so we'll know it's new and hasn't been CREATED yet.  After CREATE, a new cache version
         *   of the concept will be pulled, so the isNew flag will be gone at that point.
         *
         * @param {String} concept The concept for which to create the new item
         * @param {Boolean} [isWireless] Is the new concept a wireless item
         */
        newItem: function (concept, isWireless) {
          var deferred = $q.defer();
          var _this = this;
          PanelProg.new(concept)
            .then(
              function (data) {
                var item = _this[concept].isArray
                  ? data[$filter("singularize")(concept)]
                  : data[concept];
                _this.applyDefaultValues(concept, item);
                // Set some default values for different concepts
                switch (concept) {
                  // TODO: Determine if this needs to be done or it can be done in PanelDef
                  case "area_informations":
                    if (
                      UserService.controlSystem.panels[0].hardware_family ===
                      "XR550"
                    ) {
                      item.acct_num =
                        UserService.controlSystem.panels[0].account_number;
                    }
                    break;
                  case "communication":
                    item.acct_num =
                      UserService.controlSystem.panels[0].account_number;
                    break;
                  case "communication_paths":
                    item.acct_num =
                      UserService.controlSystem.panels[0].account_number;
                    item.comm_type = "D";
                    item.path_type = "B";
                    break;
                  case "device_informations":
                    if (
                      _this.isXR550Family(_this.panel_model) ||
                      _this.isXt75(_this.panel_model)
                    ) {
                      _this.format550SCAPIDevice(item, true);
                    }
                    break;
                  case "remote_options":
                    item.crypt_key =
                      UserService.controlSystem.panels[0].remote_key;
                    if (
                      ["persistent", "persistent_w_cell_backup"].indexOf(
                        UserService.controlSystem.panels[
                          UserService.controlSystem.panel_index
                        ].comm_type
                      ) > -1
                    ) {
                      item.app_key = UserService.dealerInfo.app_key;
                    }
                    break;
                  case "system_area_information":
                    // TODO: This is a SCAPI problem that should be fixed in offline phase 2. Once it is, this can be removed.
                    if (item.morn_ambsh === "N") {
                      item.morn_ambsh = 0;
                    }
                    break;
                  case "system_options":
                    var code = _this.modelHasRandomHouseCode()
                      ? getRandomIntInclusive(1, 50)
                      : 0;
                    item.house_code = pad(code, 3);
                    break;
                  case "zone_informations":
                    item.wl_dis_dis = "N";
                    if (
                      DoesNestedPropertyExist(
                        _this,
                        "PDS.panel_def.CONCEPTS.area_informations.number.DISPLAY.ZEROPAD"
                      )
                    ) {
                      item.area_list = $filter("zpad")(
                        item.area_list,
                        _this.PDS.panel_def.CONCEPTS.area_informations.number
                          .DISPLAY.ZEROPAD
                      );
                    }
                    item.folow_area = "00";
                    item.rptareaacc = "00";
                    break;
                  default:
                    break;
                }

                // Set the isNew flag on the item
                item.isNew = true;
                item.isOpen = true;
                if (_this[concept].isArray) {
                  _this.addItemToConcept(concept, item, isWireless);
                }
                deferred.resolve(item);
              },
              function (error) {
                if (_this[concept].isArray) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error adding Item for " + concept,
                    json: error,
                  });
                }
                deferred.reject(error);
              },
              function (info) {}
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        modelHasRandomHouseCode: function () {
          switch (this.panel_model) {
            case "XT50":
              return +this.panel_version >= 119;
            case "XTLP":
              return true;
            default:
              return false;
          }
        },

        /**
         * Apply default values from panel_def file
         * @param {string} concept
         * @param {*} entity
         */
        applyDefaultValues: function (concept, entity) {
          var _this = this;
          if (_this.PDS.hasOwnProperty("panel_def")) {
            angular.forEach(
              _this.PDS.panel_def.CONCEPTS[concept],
              function (field, key) {
                if (
                  DoesNestedPropertyExist(field, "DISPLAY.Default_Value") &&
                  entity.hasOwnProperty(key) &&
                  (_this.panel_model !== "XTLP" ||
                    (_this.panel_model === "XTLP" &&
                      key.toString() !== "wireless"))
                ) {
                  if (entity[key] !== field.DISPLAY.Default_Value.toString()) {
                    entity[key] = field.DISPLAY.Default_Value;
                  }
                }
              }
            );
          }
        },

        /**
         * @ngdoc object
         * @name method:addItemToConcept
         * @methodOf App.factory:Panel
         *
         * @description
         *   Adds a single item to a multi-item concept.  Currently, if there are no items in the concept, we can trust
         *   the next-man-up number from the API.  If there is at least 1 item in the concept, we can't trust it, since
         *   the user may have hit "new" multiple times before actually submitting the concept to the API/panel.
         *
         * @param {String} concept the name of the concept
         * @param {Object} item the item
         * @param isWireless {Boolean} Is the new concept a wireless item
         */
        addItemToConcept: function (concept, item, isWireless) {
          var _this = this;
          // A list of concepts that share numbers
          // If the concept isn't an Array concept, we can't add it.
          if (!_this[concept].isArray) return false;

          var nextManUp = _this.getNextManUp(concept, isWireless);
          if (nextManUp !== null) item.number = nextManUp;

          if (
            angular.isDefined(item.number) &&
            DoesNestedPropertyExist(
              _this.PDS.panel_def.CONCEPTS,
              concept + ".number.DISPLAY.ZEROPAD"
            )
          ) {
            item.number = $filter("zpad")(
              item.number,
              _this.PDS.panel_def.CONCEPTS[concept].number.DISPLAY.ZEROPAD
            );
          }

          _this[concept].push(item);
        },

        /**
         * Get the next valid record number for the concept
         *
         * @param concept The current concept name that we're working with
         * @param isWireless Bool indicating if the item added is wireless
         * @returns {integer} The next available concept number
         */
        getNextManUp: function (concept, isWireless) {
          var _this = this;

          // An array of all the numbers currently used for the concept
          var usedNumbers = [];

          // Get all the currently used numbers in this concept
          // If this is zone_informations for an ECP (vista) OR DSC panel, we don't want to use anything below zone 201
          if (
            _this[concept] &&
            _this[concept].isArray &&
            concept === "zone_informations" &&
            (_this.system_options.kpad_input === "E" ||
              _this.system_options.kpad_input === "D")
          ) {
            for (var i = 0; i < 200; i++) {
              usedNumbers.push(i);
            }
            var dmpZones = _this[concept].filter(function (item) {
              return +item.number > 200;
            });
            dmpZones.forEach((zone) => {
              usedNumbers.push(zone.number);
            });
            // For all other array concepts, just get all the currently used numbers
          } else if (_this[concept] && _this[concept].isArray) {
            angular.forEach(_this[concept], function (item) {
              usedNumbers.push(item.number);
            });
          }

          // Convert our strings to numbers
          usedNumbers = usedNumbers.map(Number);

          var mask = isWireless
            ? _this.getWirelessConceptNumbers(concept)
            : _this.placeholderToNumberRange(concept);

          var nextNumber = nextManUpMask(usedNumbers, mask);

          // For XT30 family panels, if the concept is one that could overlap
          if (_this.isSharedConcept(concept)) {
            var overlapMask = _this.getOverlapMask();
            // If the number is within the overlap range
            if (overlapMask.indexOf(+nextNumber) > -1) {
              // See which of the overlapping numbers are used
              var usedSharedNumbers = _this.getUsedOverlapNumbers(
                overlapMask,
                PANEL_SHARED_CONCEPTS
              );

              // Get the next available of the overlapping numbers
              var nextNonOverlap = nextManUpMask(
                usedSharedNumbers,
                overlapMask
              );
              // If an overlapping number is available, that's it
              if (nextNonOverlap !== null) {
                nextNumber = nextNonOverlap;
              }
              // If an overlapping number is not available, look in the numbers higher than the potentially overlapping ones
              else {
                var topOverlap = Math.max.apply(Math, overlapMask);
                var aboveOverlapUsed = usedNumbers.filter(function (num) {
                  return num > topOverlap;
                });
                var aboveOverlapMask = mask.filter(function (num) {
                  return num > topOverlap;
                });
                nextNumber = nextManUpMask(aboveOverlapUsed, aboveOverlapMask);
              }
            }
          }

          return nextNumber;
        },

        /**
         * Returns true if the given concept is one that shares some numbers between concepts. To share across
         * concepts means that if a number is used in one concept it isn't available in another. For example, having
         * a key fob number 31 on XT30 eliminates the option to assign a zone or output 31.
         * @param conceptKey
         * @returns {boolean}
         */
        isSharedConcept: function (conceptKey) {
          var _this = this;
          return (
            !_this.isXR550Family(_this.panel_model) &&
            PANEL_SHARED_CONCEPTS.indexOf(conceptKey) > -1
          );
        },

        /**
         * Get an array of the numbers that can overlap for a given model
         * @param model
         */
        getOverlapMask: function () {
          var _this = this;
          var overlapRange =
            _this.panel_model === "XTLP" ? "51-54, 61-64" : "31-34, 41-44";
          return numberRangeToCSV(overlapRange);
        },

        /**
         * Returns an array of the currently used numbers that overlap concepts
         * @param overlapMask - the numbers that might overlap
         * @param concepts - an array of concepts to get the used numbers from
         * @returns {Array}
         */
        getUsedOverlapNumbers: function (overlapMask, concepts) {
          var _this = this;
          var usedOverlapNumbers = [];
          angular.forEach(concepts, function (sharedConcept) {
            if (_this[sharedConcept] && _this[sharedConcept].isArray) {
              angular.forEach(_this[sharedConcept], function (item) {
                if (
                  overlapMask.indexOf(+item.number) > -1 &&
                  usedOverlapNumbers.indexOf(+item.number) === -1
                ) {
                  usedOverlapNumbers.push(+item.number);
                }
              });
            }
          });
          return usedOverlapNumbers;
        },

        /**
         * Gets a list of all wireless numbers for the given concept
         * @param concept The panel concept we're working with
         * @returns Array an array of wireless concept numbers
         */
        getWirelessConceptNumbers: function (concept) {
          var _this = this;
          var startingConfigProperty =
            "STARTING_WIRELESS_" + concept.toUpperCase();
          var startingWirelessNumber =
            this.getConfigValues(startingConfigProperty)[0] || 1;

          // Filter out any numbers that are less than the starting wireless number
          var range = _this
            .placeholderToNumberRange(concept)
            .filter(function (value) {
              return value >= startingWirelessNumber;
            });
          // Filter out AX Bus numbers if concept is device_informations
          if (
            _this.isXR550Family(_this.panel_model) &&
            concept === "device_informations"
          ) {
            range = range.filter(function (val) {
              return AXBus.AXBusNumbers.indexOf(val) === -1;
            });
          }

          var endingWirelessProperty =
            "ENDING_WIRELESS_" + concept.toUpperCase();
          if (
            _this.PDS.hasOwnProperty("panel_def") &&
            _this.PDS.panel_def.CONFIG[endingWirelessProperty]
          ) {
            range = range.filter(function (val) {
              return val <= +_this.PDS.panel_def.CONFIG[endingWirelessProperty];
            });
          }

          return range;
        },

        /**
         * Used to determine if the given concept number is valid according to panel def
         * @param concept
         * @param number
         */
        isValidNumber: function (concept, number) {
          number = parseInt(number, 10);
          var numberRange = [];
          if (this.PDS.hasOwnProperty("panel_def")) {
            numberRange = numberRangeToCSV(
              this.PDS.panel_def.CONCEPTS[concept].number.DISPLAY.PLACEHOLDER
            );
          }
          return numberRange.includes(number);
        },

        /**
         * @ngdoc object
         * @name method:getItemFromArray
         * @methodOf App.factory:Panel
         *
         * @description
         *   Gets a single item, given an attribute key/value, from the given concept.
         *
         * @param {Array} conceptArray - Array which contains the items for a single concept
         * @param {String} itemIdentifierKey - Key that defines this item
         * @param {String} itemIdentifierValue - Value that defines this item
         * @returns {Object} item - The item or false if no matching item was found.
         */
        getItemFromArray: function (
          conceptArray,
          itemIdentifierKey,
          itemIdentifierValue
        ) {
          var theItem = false;
          angular.forEach(conceptArray, function (item) {
            if (item[itemIdentifierKey] == itemIdentifierValue) {
              theItem = item;
            }
          });
          return theItem;
        },

        /**
         * @ngdoc object
         * @name method:clearErrors
         * @methodOf App.factory:Panel
         *
         * @description
         *   Clears all errors for the given concept and itemName
         * @param {String} concept the name of the concept
         * @param {String} itemName the name of the item
         */
        clearErrors: function (concept, itemName) {
          if (this.errors) {
            if (this.errors[concept]) {
              if (this.errors[concept][itemName]) {
                delete this.errors[concept][itemName];
              }
            }
          }
        },

        /**
         * @ngdoc object
         * @name method:getValidValues
         * @methodOf App.factory:Panel
         *
         * @description
         *   Get the specific CONFIG values from the PanelDefinitionService
         *
         * @param {String} type the name of the CONFIG value, such as valid_zones or valid_user_codes
         * @returns {Array} the array of valid values
         */
        getConfigValues: function (type) {
          if (angular.isDefined(this.PDS.getConfigItemValues(type))) {
            return numberRangeToCSV(this.PDS.getConfigItemValues(type));
          } else {
            return [];
          }
        },

        getFieldName: function (concept, fieldName) {
          var meta = this.PDS.getConceptFieldMeta(concept, fieldName);
          if (meta && meta.DISPLAY && meta.DISPLAY.NAME) {
            return meta.DISPLAY.NAME;
          } else {
            return fieldName.toUpperCase();
          }
        },

        getFieldMeta: function (concept, fieldName) {
          return this.PDS.getConceptFieldMeta(concept, fieldName);
        },

        getFieldMetaProperty: function (concept, fieldName, property) {
          return this.PDS.getConceptFieldMeta(concept, fieldName).DISPLAY[
            property
          ];
        },

        /**
         * Return the specified field session property if it exists
         * @param concept The name of the panel concept we're working with
         * @param fieldName The concept field name
         * @param property The session property we want returned (ex. hiddenByRule)
         * @param itemIdentifierValue number of panel entity (panel array concept: zone, device, etc.)
         * @returns {*}
         */
        getFieldSessionProperty: function (
          concept,
          fieldName,
          property,
          itemIdentifierValue
        ) {
          var _this = this;
          if (
            angular.isDefined(itemIdentifierValue) &&
            angular.isString(itemIdentifierValue)
          ) {
            itemIdentifierValue = +itemIdentifierValue;
          }
          var numberedHideRule =
            property === "hiddenByRule" &&
            angular.isArray(_this[concept]) &&
            angular.isDefined(itemIdentifierValue);
          var propertyLocation = numberedHideRule
            ? "session.CONCEPTS." +
              concept +
              ".NUMBERS." +
              itemIdentifierValue +
              ".FIELDS." +
              fieldName +
              "." +
              property
            : "session.CONCEPTS." +
              concept +
              ".FIELDS." +
              fieldName +
              "." +
              property;
          if (DoesNestedPropertyExist(this, propertyLocation)) {
            return numberedHideRule
              ? _this.session.CONCEPTS[concept].NUMBERS[itemIdentifierValue]
                  .FIELDS[fieldName][property]
              : _this.session.CONCEPTS[concept].FIELDS[fieldName][property];
          }
          // Property doesn't exist in session, return false
          return false;
        },

        getFieldValues: function (concept, fieldName) {
          var meta = this.PDS.getConceptFieldMeta(concept, fieldName);
          if (
            meta &&
            meta.DISPLAY &&
            meta.DISPLAY.Data_Type &&
            meta.DISPLAY.Data_Type == "Boolean"
          ) {
            return [
              { val: "Y", display: "Yes" },
              { val: "N", display: "No" },
            ];
          }
          if (meta && meta.VALUES) {
            var valueList = [];
            angular.forEach(meta.VALUES, function (value, key) {
              var newValue = { val: value, display: key };
              valueList.push(newValue);
            });
            return valueList;
          } else {
            return [];
          }
        },

        /**
         * Determine the number of items, of the given concept type, that are available in this panel
         *
         * @param {string} concept - The name of a concept. ex: zone_informations
         * @returns {int}
         */
        itemsAvailable: function (concept) {
          var _this = this;
          if (
            !DoesNestedPropertyExist(
              _this.PDS.panel_def.CONCEPTS,
              concept + ".number.DISPLAY.PLACEHOLDER"
            )
          )
            return 0;

          if (
            this[concept] &&
            DoesNestedPropertyExist(
              _this.PDS.panel_def.CONCEPTS,
              concept + ".number.DISPLAY.PLACEHOLDER"
            )
          ) {
            // Introduced with AX bus doors, the range string can have an 'or ...' tacked on the end.
            var rangeStrings = String(
              _this.PDS.panel_def.CONCEPTS[concept].number.DISPLAY.PLACEHOLDER
            ).split(" or ");
            // Get the available numbers from the range string (ex: '1-6, 11-14' => {1,2,3,4,5,6,11,12,13,14})
            var availableNumbers = numberRangeToCSV(rangeStrings[0]);
            // XT30 family panels have concepts that can overlap. Adjust the availableNumbers based on the used overlapping numbers
            if (_this.isSharedConcept(concept)) {
              // Get a list of all of the numbers that can overlap
              var overlapMask = _this.getOverlapMask(_this.panel_model);
              // Filter out the current concept
              var otherConcepts = PANEL_SHARED_CONCEPTS.filter(function (
                otherConcept
              ) {
                return otherConcept !== concept;
              });
              // Get a list of the potentially overlapping numbers used by the other concepts
              var usedOverlapNumbers = _this.getUsedOverlapNumbers(
                overlapMask,
                otherConcepts
              );
              // Removed the used overlapping numbers from the available numbers
              angular.forEach(usedOverlapNumbers, function (overlapNum) {
                var oIdx = availableNumbers.indexOf(overlapNum);
                if (oIdx > -1) {
                  availableNumbers.splice(oIdx, 1);
                }
              });
              // If this is a CellCom ECP panel, we should only count zones 201-203
              if (
                (PanelCapabilitiesService.supportsECPZones(
                  _this.panel_model,
                  _this.panel_version
                ) &&
                  _this.system_options.kpad_input === "E") ||
                (PanelCapabilitiesService.supportsDSCZones(
                  _this.panel_model,
                  _this.panel_version
                ) &&
                  _this.system_options.kpad_input === "D" &&
                  concept === "zone_informations")
              ) {
                availableNumbers = availableNumbers.filter((number) => {
                  return +number > 200 && +number < 204;
                });
              }
            }
            // The max number of items for this concept is the number of unused, non-overlapping numbers available
            var itemsMax = availableNumbers.length;
            // If the AX Bus is involved, add the number of items available based on the Add-on feature keys
            if (
              rangeStrings[1] &&
              rangeStrings[1].indexOf("AX address") !== -1
            ) {
              //TODO: Once the feature_keys API is ready, replace hard-coded falses with Add-on A and B

              if (_this.feature_keys) {
                itemsMax += AXBus.getNumDevices(
                  _this.feature_keys.includes("door_add_on_a"),
                  _this.feature_keys.includes("door_add_on_b")
                );
              } else {
                itemsMax += AXBus.getNumDevices(false, false);
              }
            }
            var itemsUsed = _this[concept].length;
            // If this is zone_informations for iComLNC, we need to ignore the zwave zones 5-20
            if (
              _this.panel_model === "iComLNC" &&
              concept === "zone_informations"
            ) {
              itemsUsed = _this.zone_informations.filter((zone) => {
                return +zone.number <= 4 || +zone.number >= 21;
              }).length;
            }
            // If this is a CellCom ECP panel, we need to only look at zones above 200
            if (
              ((PanelCapabilitiesService.supportsECPZones(
                _this.panel_model,
                _this.panel_version
              ) &&
                _this.system_options.kpad_input === "E") ||
                (PanelCapabilitiesService.supportsDSCZones(
                  _this.panel_model,
                  _this.panel_version
                ) &&
                  _this.system_options.kpad_input === "D")) &&
              concept === "zone_informations"
            ) {
              itemsUsed = _this.zone_informations.filter((zone) => {
                return +zone.number > 200 && +zone.number < 204;
              }).length;
            }
            var resultNotANumber = isNaN(itemsMax - itemsUsed);
            if (resultNotANumber) {
              console.warn(
                "Result of Panel.itemsAvailable() is not a number. itemsMax: " +
                  Number(itemsMax) +
                  " | itemsUsed: " +
                  Number(itemsUsed)
              );
            } else {
            }
            return resultNotANumber ? 0 : itemsMax - itemsUsed;
          }
        },

        /**
         * Converts the placeholder from the panel def file to an array of item numbers available for the concept
         * @param concept
         */
        placeholderToNumberRange: function (concept) {
          var _this = this;
          if (
            DoesNestedPropertyExist(
              _this.PDS.panel_def.CONCEPTS,
              concept + ".number.DISPLAY.PLACEHOLDER"
            )
          ) {
            var rangeStrings = String(
              _this.PDS.panel_def.CONCEPTS[concept].number.DISPLAY.PLACEHOLDER
            ).split(" or ");
            var items = numberRangeToCSV(rangeStrings[0]);
            if (
              rangeStrings[1] &&
              rangeStrings[1].indexOf("AX address") !== -1
            ) {
              items = items.concat(AXBus.AXBusNumbers);
            }
            return items;
          } else return [];
        },

        /**
         * @ngdoc object
         * @name method:disconnect
         * @methodOf App.factory:Panel
         *
         * @description
         *   Hits the DISCONNECT Api for the panel
         *
         */
        disconnect: function () {
          var deferred = $q.defer();
          var _this = this;
          PanelProg.disconnect()
            .then(
              function (data) {
                deferred.resolve(data);
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Error disconnecting from the system.",
                  json: error,
                });
                deferred.reject(error);
              },
              function (info) {}
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * Determines if the provided list of PANEL_CONCEPTS are loaded
         * @param {Array} concepts An array of PANEL_CONCEPTS
         * @returns {boolean} True if all passed in concepts are loaded, else false
         */
        conceptsLoaded: function (concepts) {
          // Loop through all of the passed in PANEL_CONCEPTS checking to make sure that they're defined and done loading
          for (var i = 0; i < concepts.length; i++) {
            if (
              !this[concepts[i].api_name] ||
              this[concepts[i].api_name].isBusy
            ) {
              // A required concept was undefined or busy, return false
              return false;
            }
          }

          // All required concepts are loaded, continue
          return true;
        },

        get07ZoneContactNum: function (thisItem, placeholder) {
          let availableNums = numberRangeToCSV(placeholder);
          let newZoneNumIndex = availableNums.indexOf(
            parseInt(thisItem.number)
          );
          let zoneOneBackNum = availableNums[newZoneNumIndex - 1];
          let zoneTwoBackNum = availableNums[newZoneNumIndex - 2];
          let thisSerialNum = thisItem.serial_no;
          let zoneOneBack = null;
          let zoneTwoBack = null;

          for (let zone of this.zone_informations) {
            let zoneNum = parseInt(zone.number);

            if (zone.serial_no === thisSerialNum) {
              if (zoneNum === zoneOneBackNum) {
                zoneOneBack = zone;
              } else if (zoneNum === zoneTwoBackNum) {
                zoneTwoBack = zone;
              } else if (zoneOneBack && zoneTwoBack) {
                break;
              }
            }
          }

          if (zoneOneBack && zoneTwoBack) {
            return "2";
          } else if (zoneOneBack) {
            return "1";
          } else {
            return "0";
          }
        },

        /**
         * Sets default area and zone properties for a zone based on its serial number
         * @param serial_no The zone we're getting defaults for
         * @Returns {Object} An object containing the default zone description, area, and tipe
         */
        getZoneDefaults: function (curZone) {
          let serial_no = curZone.serial_no;
          var defaults = {};

          switch (serial_no.substr(0, 2)) {
            case "01":
              defaults.description = "Universal Transmitter";
              defaults.area = "01";
              defaults.tipe = "EX";
              defaults.contact_no = "0"; // Internal
              break;
            case "02":
              defaults.description = "PIR / Recessed Door";
              defaults.area = "02";
              defaults.tipe = "NT";
              defaults.contact_no = "0"; // Internal
              break;
            case "03":
              defaults.description = "Glassbreak";
              defaults.area = "02";
              defaults.tipe = "DY";
              defaults.contact_no = "1"; // External
              break;
            case "04":
              defaults.description = "Hold-Up";
              defaults.area = "--";
              defaults.tipe = "EM";
              defaults.contact_no = "0"; // Internal
              break;
            case "06":
              defaults.description = "Smoke";
              defaults.area = "--";
              defaults.tipe = "FI";
              defaults.contact_no = "0"; // Internal
              break;
            case "07":
              defaults.description = "Smoke";
              defaults.area = "--";
              defaults.tipe = "FI";
              defaults.contact_no = PanelCapabilitiesService.supports1168(
                UserService.controlSystem.panels[0]
              )
                ? this.get07ZoneContactNum(
                    curZone,
                    this.PDS.panel_def.CONCEPTS.zone_informations.number.DISPLAY
                      .PLACEHOLDER
                  )
                : "0"; // Internal
              break;
            case "08":
              defaults.description = "Wireless Four Zone Expander";
              defaults.area = "--";
              defaults.tipe = "NT";
              defaults.contact_no = "0"; // Internal
              break;
            case "09":
              defaults.description = "Wireless PIR";
              defaults.area = "02";
              defaults.tipe = "NT";
              defaults.contact_no = "0"; // Internal
              break;
            case "13":
              defaults.description = "1100R Repeater";
              defaults.area = "--";
              defaults.tipe = "A1";
              defaults.contact_no = "0"; // Internal
              break;
            default:
              defaults.description = "Unknown Device";
              defaults.area = "--";
              defaults.tipe = "--";
              defaults.contact_no = "0"; // Internal
              break;
          }
          return defaults;
        },

        /**
         * Returns true if the zone SN has two zones with External and Internal Contact
         * @param zoneSerialNumber The zone SN we are checking
         * @Returns boolean
         */

        zoneHasExternalInternalContacts: function (zoneSerialNumber) {
          return zoneSerialNumber > 999999 && zoneSerialNumber < 2000000;
        },

        /**
         * Returns true if the zone has 4 zones with 4 internal contacts
         * @param zoneSerialNumber The zone SN we are checking
         * @Returns boolean
         */

        zoneHasFourInternalContacts: function (zoneSerialNumber) {
          return zoneSerialNumber > 7999999 && zoneSerialNumber < 9000000;
        },

        /**
         * Returns true if the zone is an 1168
         * @param zoneSerialNumber The zone SN we are checking
         * @Returns boolean
         */

        zoneHasThreeContacts1168: function (zoneSerialNumber, panel) {
          return (
            zoneSerialNumber > 6999999 &&
            zoneSerialNumber < 8000000 &&
            PanelCapabilitiesService.supports1168(panel)
          );
        },

        /**
         * Returns true if the zone has one contact
         * @param zoneSerialNumber The zone SN we are checking
         * @Returns boolean
         */

        zoneHasOneContact: function (zoneSerialNumber) {
          return zoneSerialNumber > 2999999 && zoneSerialNumber < 4000000;
        },

        /**
         * Returns true if the zone has one contact
         * @param zoneSerialNumber The zone SN we are checking
         * @Returns boolean
         */

        zoneHasNoContacts: function (zoneSerialNumber, panel) {
          if (
            !(
              this.zoneHasExternalInternalContacts(zoneSerialNumber) ||
              this.zoneHasFourInternalContacts(zoneSerialNumber) ||
              this.zoneHasThreeContacts1168(zoneSerialNumber, panel) ||
              this.zoneHasOneContact(zoneSerialNumber)
            )
          ) {
            return true;
          } else {
            return false;
          }
        },

        /**
         * @ngdoc object
         * @name method:setDefaultAreas
         * @methodOf App.factory:Panel
         *
         * @description
         *   Sets the default areas on the panel, based on the system_options.arm_mode value
         *
         */
        setDefaultAreas: function () {
          var _this = this;
          var defaultAreas = {};
          if (
            [
              "XR150",
              "XR350",
              "XR550",
              "DualCom",
              "iComSL",
              "CellComSL",
              "CellComEX",
              "iComLNC",
              "XT75",
            ].indexOf(_this.panel_model) > -1
          )
            return false;
          // Default Areas for HSA
          defaultAreas.H = [
            {
              number: "01",
              name: "PERIMETER       ",
              auto_arm: "N",
              auto_disrm: "N",
              bad_zn: "R",
              isNew: true,
            },
            {
              number: "02",
              name: "INTERIOR        ",
              auto_arm: "N",
              auto_disrm: "N",
              bad_zn: "B",
              isNew: true,
            },
            {
              number: "03",
              name: "BEDROOMS        ",
              auto_arm: "N",
              auto_disrm: "N",
              bad_zn: "B",
              isNew: true,
            },
          ];
          // Default Areas for AP
          defaultAreas.A = [
            {
              number: "01",
              name: "PERIMETER       ",
              auto_arm: "N",
              auto_disrm: "N",
              bad_zn: "R",
              isNew: true,
            },
            {
              number: "02",
              name: "INTERIOR        ",
              auto_arm: "N",
              auto_disrm: "N",
              bad_zn: "B",
              isNew: true,
            },
          ];
          console.debug(
            "Panel->setDefaultAreas() - arm_mode: " +
              _this.system_options.arm_mode
          );
          console.debug(
            "Panel->setDefaultAreas() - default arm_mode: " +
              defaultAreas[_this.system_options.arm_mode]
          );
          console.debug(
            "Panel->setDefaultAreas() - area_informations: " +
              angular.toJson(_this.area_informations)
          );
          if (_this.system_options.arm_mode.toString() !== "N") {
            _this.area_informations =
              defaultAreas[_this.system_options.arm_mode];
            _this.area_informations.isArray = true;
            _this
              .sendProgramming("area_informations")
              .then(
                function () {},
                function (error) {
                  console.error(
                    "Panel->setDefaultAreas() - Error setting Area Informations: " +
                      angular.toJson(error)
                  );
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
        },

        permanentScheduleHasTimes: function () {
          let _this = this;
          if (_this.hasOwnProperty("schedules")) {
            let keys = Object.keys(_this.schedules[0]);
            for (let i = 0; i < keys.length; i++) {
              if (keys[i] !== "number" && _this.schedules[0][keys[i]] !== "") {
                return true;
              }
            }
          }
          return false;
        },

        sendFeatureKey: (value) => {
          this.feature_keys_busy = true;
          PanelProg.update(
            "feature_keys",
            JSON.stringify({ key_code: value }),
            null,
            false,
            PanelProg.getSessionKey()
          )
            .then(
              (success) => {
                $rootScope.alerts.push({
                  type: "success",
                  text: "successfully sent feature key",
                });
                this.feature_key_value = null;
                this.feature_keys_busy = false;
                this.get("feature_keys");
              },
              (fail) => {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Error sending feature key",
                });
                this.feature_key_value = null;
                this.feature_keys_busy = false;
              }
            )
            .catch((fail) =>
              $rootScope.alerts.push({
                type: "error",
                text: "Error sending feature key",
              })
            );
        },

        retrieveFeatureKeys: () => {
          this.feature_keys_busy = true;
          this.refresh("feature_keys").then(
            (success) => (this.feature_keys_busy = false)
          );
        },

        /**
         * @ngdoc object
         * @name method:init
         * @methodOf App.factory:Panel
         *
         * @description
         * Initialization method.
         *  Retrieves the panel_id from the PanelProg service
         *  Sets the concepts from the Definition service
         *  Instantiates the pristine object, which will later hold a copy of the concept data.
         */
        init: function () {
          var _this = this;
          // This check is used to ensure that we don't overwrite the PanelProg panel id when creating a new Panel for multi-panel pages
          if (UserService.control_system_id == _this.panel_id) {
            PanelProg.panel_id = _this.panel_id;
          }
          if (_this.PDS == null) {
            console.warn("Panel.init - no PDS");
            return;
          }
          if (!_this.concepts || _this.concepts.length == 0)
            _this.concepts = _this.PDS.getConcepts();
          _this.pristine = {};
          _this.conceptsWithDisplay = _this.PDS.getConceptsWithDisplay();
          _this.fullProgrammingConceptsWithDisplay =
            _this.conceptsWithDisplay.filter(function (concept) {
              return concept.hasOwnProperty("FULL") && concept.FULL === "Y";
            });
          _this.initializeSessionData();

          // Some concepts are not defined in panel def. Create warnings in the log for those that aren't defined
          // so we can identify them and add them to panel def. Some concepts are defined in panel def but are not
          // listed on this object because we're not using them.
          angular.forEach(_this.concepts, function (concept) {
            if (!_this.PDS.panel_def.CONCEPTS.hasOwnProperty(concept)) {
              console.warn(
                "Panel - " +
                  concept +
                  " is not defined in panel def for panel model: " +
                  _this.panel_model
              );
            }
          });

          // because feature_keys doesn't work like any other concept, we need to initialize it to an empty array
          if (_this.concepts && _this.concepts.includes("feature_keys")) {
            _this.feature_keys = [];
          }
        },
      });

      this.init();
    };

    // Private functions

    var locallyManagedProperties = ["isOpen", "isNew", "isArray", "isBusy"];
    /**
     * Remove properties that we may have added to a panel object (zone, system_options, etc.)
     * @param {{}} obj - A panel entity
     * @returns {{}} - The same object minus any properties we may have added
     */
    var removeLocallyManagedProperties = function (obj) {
      var res = {};
      angular.extend(res, obj);
      angular.forEach(locallyManagedProperties, function (prop) {
        if (res.hasOwnProperty(prop)) {
          delete res[prop];
        }
      });
      return res;
    };

    /**
     * This is a list of concepts for which SCAPI and the panel are assumed to be in sync
     *
     * note: With the ability to perform offline programming, the panel and SCAPI may get out of sync if information
     * is saved offline. At the time this was written, these concepts had no save button so they are assumed to
     * remain in sync.
     *
     * @type {string[]}
     */
    var trustedSCAPICacheConcepts = ["user_codes"];

    /**
     * Function to determine if a refresh of the pristine (panel) object should be performed before sending so it can
     * be compared to the local object down stream
     * @param {string[]} concepts - A string array of panel concepts that are to be sent to the panel
     * @returns {boolean} - True if a refresh should be performed prior to sending
     */
    var shouldRefreshPristineBeforeSend = function (concepts) {
      // Concepts should be refreshed if sending programming when replacing a panel
      if (
        ReplacePanelService.replacingPanel() ||
        PanelProgArchiveService.restoringPanel
      ) {
        return true;
      }
      // When saving, the panel is offline so refreshing will initiate the initial connection
      if (PanelProg.savingProgramming) {
        return false;
      }
      // If the panel is online, concepts are considered to be in sync
      if (
        OnlinePanelService.isOnline() &&
        ClientEventsService.initialConnection.hasBeenEstablished()
      ) {
        return false;
      }
      // If any of the concepts that may be out-of-sync are being sent, refresh
      var anyUntrustedConcept = false;
      if (angular.isArray(concepts)) {
        for (var i = 0; i < concepts.length; i++) {
          if (trustedSCAPICacheConcepts.indexOf(concepts[i]) === -1) {
            anyUntrustedConcept = true;
            break;
          }
        }
      }
      return anyUntrustedConcept;
    };

    /**
     * Determines if a new item may exist for the given concept if a 404 is received
     *
     * note: When fetching a non-array, you will get a 404 for a concept that has never been retrieved.
     * @param {*} panel - The panel object
     * @param {*} error - The error received from an API call
     * @param {string} concept - The panel concept
     * @returns {*|boolean}
     */
    var mayHaveNewItem = function (panel, error, concept) {
      // TODO: Define all concepts for all XTL and CellCom panels in panel def. At the least, we could use the isArray property.
      // note: When an array concept is fetched, the response is an empty array as long as the panel id
      // exists at SCAPI. Not so for a singular concept. For a singular concept, a 404 can indicate the panel
      // id was invalid or the record has never been retrieved from the panel. Since (currently) not all
      // concepts are defined in panel def, if a concept is not defined, we have to assume that new will
      // return what is needed. If, however, the concept is an array concept, this will return a template for
      // an item in the array. i.e. This will break something downstream if an array concept is not defined.
      var isNotFoundError = error && error.status && error.status === 404;
      var conceptIsValid = panel.hasOwnProperty(concept);
      var conceptIsDefined =
        panel.PDS != null &&
        panel.PDS.panel_def.CONCEPTS.hasOwnProperty(concept);
      var conceptIsSingular =
        conceptIsDefined &&
        panel.PDS.panel_def.CONCEPTS[concept].DISPLAY.isArray === false;
      return (
        isNotFoundError &&
        conceptIsValid &&
        (!conceptIsDefined || conceptIsSingular)
      );
    };

    /**
     * A list of concepts for which items are created or updated when sendProgramming is called
     * @type {Array}
     */
    var modifiedConcepts = [];
    /**
     * Add a concept to modifiedConcepts if it isn't already there
     * @param {string} concept
     */
    var addToModifiedConcepts = function (concept) {
      if (!modifiedConcepts.includes(concept)) {
        modifiedConcepts.push(concept);
      }
    };

    /**
     * Add each concept to a queue of promises. Called by Panel.sendProgramming()
     *
     * @param {*} panel - The panel object
     * @param {string[]} concepts - a single concept or array of concepts to send. If omitted, all concepts are sent
     * @param {boolean} forceSend - If Truthy the programming will be sent even if no changes have been made
     * @param {boolean} [toastResult] - Pop-up toast with result when complete
     * @param {string} [itemIdentifierValue] - For array concepts, the number of the entity
     */
    var queueConceptsForSend = function (
      panel,
      concepts,
      forceSend,
      toastResult,
      itemIdentifierValue
    ) {
      var deferred = $q.defer();
      prepForRestore(panel, concepts).finally(function () {
        var promises = [];
        angular.forEach(concepts, function (concept) {
          if (
            PanelProgArchiveService.restoringPanel &&
            concept === "user_codes"
          ) {
            // Codes are restored via (Quartz) scheduled job; can't be sent from DA.
            return;
          }
          // Only create promises for concepts that exist on the panel object
          if (panel.hasOwnProperty(concept)) {
            promises = promises.concat(
              sendConcept(panel, concept, forceSend, itemIdentifierValue)
            );
          }
        });
        var waitForAll = OnlinePanelService.isOffline();
        // If the panel is offline, wait for all concepts to complete before resolving
        var action = waitForAll
          ? QueueService.waitForAllPromisesToResolve(promises)
          : $q.all(promises);
        action
          .then(
            function () {
              // Any time we modify information, retrieve to update all properties. For panels that are offline, fetch to
              // update isNew, etc. properties. For panels that are online, refresh to update names and other properties
              // that the panel may modify to ensure that all information is synchronized (names appear correctly)
              var retrievePromises = [];
              angular.forEach(concepts, function (concept) {
                retrievePromises.push(panel.fetch(concept));
              });
              $q.all(retrievePromises).then(
                function () {
                  finalizeSend(panel, true, toastResult, forceSend).then(
                    function () {
                      deferred.resolve();
                    },
                    function (error) {
                      deferred.reject(error);
                    }
                  );
                },
                function (error) {
                  console.error(
                    "Panel->queueConceptsForSend - error refreshing concepts after send: " +
                      angular.toJson(error)
                  );
                  finalizeSend(
                    panel,
                    false,
                    toastResult,
                    forceSend,
                    error
                  ).then(
                    function () {
                      deferred.reject(error);
                    },
                    function (finalizeError) {
                      deferred.reject(finalizeError);
                    }
                  );
                }
              );
            },
            function (error) {
              console.error(
                "Panel->queueConceptsForSend - error sending concepts: " +
                  angular.toJson(error)
              );
              // The error from a $q.all is an array (of 1), a $q.waitFor... is a array of many. Get the error out so that
              // if the error is "USER_CANCELLED", the error can be propagated and won't toast
              let err = angular.isArray(error) ? error[0] : error;
              finalizeSend(
                panel,
                false,
                toastResult,
                forceSend,
                error,
                waitForAll
              )
                .then(
                  function () {},
                  function (error) {
                    console.error(`error finalizing: ${angular.toJson(error)}`);
                  }
                )
                .finally(function () {
                  deferred.reject(err);
                });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      });
      return deferred.promise;
    };

    /**
     * Clean-up function for when sending programming completes
     *
     * @param {*} panel - The panel object
     * @param {boolean} succeeded - Job was a success
     * @param {boolean} toastResults - Should a toast message with some information be popped-up
     * @param {boolean} forceSend - Was the programming force sent
     * @param {*} [error] - For an unsuccessful job, the http error or errors
     * @param {boolean} [waitForAll] - indicates all promises finished and there may be a lot of errors
     */
    var finalizeSend = function (
      panel,
      succeeded,
      toastResults,
      forceSend,
      error,
      waitForAll
    ) {
      console.info(
        `panel->finalizeSend() - succeeded: ${succeeded}, toastResults: ${toastResults}, error: ${angular.toJson(
          error
        )}, waitForAll: ${waitForAll}`
      );
      let deferred = $q.defer();
      panel.allConceptsBusy = false;
      if (PanelProgArchiveService.cache.backup.data) {
        PanelProgArchiveService.finalizeRestoral().then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "any user codes stored are being sent to the system",
            });
            deferred.resolve();
          },
          function (error) {
            console.error("error restoring user codes" + angular.toJson(error));
            $rootScope.alerts.push({
              type: "error",
              text: "error restoring user codes",
            });
            deferred.reject(error);
          }
        );
      } else if (
        ReplacePanelService.replacingPanel() &&
        ReplacePanelService.canRestoreUserCodes
      ) {
        // Once all other restore jobs have been taken care of, fire a restore user codes command. These can be
        // very long running so this is a fire-and-forget until further notice
        restore(panel, "user_codes").then(
          function () {
            deferred.resolve();
          },
          function (error) {
            console.error(
              `error restoring user codes: ${angular.toJson(error)}`
            );
            deferred.reject(error);
          }
        );
      } else {
        if (toastResults) {
          toast(succeeded, error, waitForAll, forceSend);
        }
        deferred.resolve();
      }
      return deferred.promise;
    };

    let toast = function (succeeded, error, waitForAll, forceSend) {
      if (succeeded) {
        var sendMessage = forceSend ? "programming" : "programming changes";
        var successMessage = PanelProg.savingProgramming
          ? "programming changes successfully saved to Dealer Admin"
          : `${sendMessage} successfully sent to the system`;
        $rootScope.alerts.push({ type: "success", text: successMessage });
      } else {
        var errorMessage = PanelProg.savingProgramming
          ? "error saving programming to dealer admin"
          : "error sending programming to system";
        if (waitForAll) {
          var errors = extractAndFormatSCAPIErrors(error);
          $rootScope.alerts.push({
            type: "error",
            text: errorMessage + errors,
          });
        } else {
          $rootScope.alerts.push({
            type: "error",
            text: errorMessage,
            json: error,
          });
        }
      }
    };

    /**
     * @ngdoc object
     * @name method:sendConcept
     * @methodOf App.factory:Panel
     *
     * @description
     *   Send any concept; a singular concept, a single item of a multi-concept, or an entire multi-concept.
     *
     *   When data is sent downstream, the local and pristine copies are compared to determine if the data needs to be
     *   created or has changed and needs to be updated.  Sets the new cached value of the full concept to the local
     *   object.  In other words, if you send only one zone to the panel, the entire zone_informations array will be
     *   updated from the API cache after a successful send.  The local programming values are the values sent to the panel.
     *
     *   note: A refresh of the pristine object is performed in case the panel and SCAPI data may be out of sync.
     *
     * @param {*} panel - The panel object
     * @param {String} concept - The name of the concept, such as zone_informations
     * @param {boolean} forceSend - If this is truthy the concept will be sent even if no programming changes have been made
     * @param {string} [itemIdentifierValue] - For array concepts, the number of the entity
     *
     * @returns {Array} - An array of promises to send entities
     */
    var sendConcept = function (
      panel,
      concept,
      forceSend,
      itemIdentifierValue
    ) {
      var promises = [];
      if (panel.hasOwnProperty(concept)) {
        var replacingPanel = ReplacePanelService.replacingPanel();
        if (
          !replacingPanel ||
          (replacingPanel && !["favorites", "user_codes"].includes(concept))
        ) {
          // If this is not an array concept, or there is an item value, send it
          if (
            !panel[concept].isArray ||
            angular.isDefined(itemIdentifierValue)
          ) {
            promises.push(
              sendEntity(panel, concept, forceSend, itemIdentifierValue)
            );
          } else {
            // Send all items in the array (concept)
            if (throttledConcepts.includes(concept)) {
              promises.push(sendThrottledConcept(panel, concept));
            } else {
              angular.forEach(panel[concept], function (entity) {
                if (
                  forceSend &&
                  concept === "zone_informations" &&
                  (panel.system_options.kpad_input === "E" ||
                    panel.system_options.kpad_input === "D")
                ) {
                  var zonesForECP = ["201", "202", "203", "204"];
                  if (zonesForECP.includes(entity.number)) {
                    promises.push(
                      sendEntity(panel, concept, forceSend, entity.number)
                    );
                  }
                } else {
                  promises.push(
                    sendEntity(panel, concept, forceSend, entity.number)
                  );
                }
              });
            }
          }
        } else {
          console.warn(
            "Panel->sendConcept() - Concept cannot currently be restored: " +
              concept
          );
        }
      } else {
        console.warn(
          "sendConcept() error - panel does not have concept: " + concept
        );
      }
      return promises;
    };

    /**
     * @ngdoc object
     * @name method:sendEntity
     * @methodOf App.factory:Panel
     *
     * @description
     *   Sends one entity, either a singular concept like system_options or one item of a multi-concept like
     *   zone_informations, to the panel.
     *   On the response, sets the new cached value of the full concept to the local object.  In other words, if
     *   you send only one zone to the panel, the entire zone_informations array will be updated from the API
     *   cache after a successful send.  The programming values that are set on the Panel model (this) are the
     *   values sent to the panel.
     *
     * @param {*} panel - The panel object
     * @param {String} concept - The name of the concept, such as zone_informations
     * @param {String} forceSend - If truthy, send programming even if it has not been changed/isDirty
     * @param {Object} [itemIdentifierValue] - The value to match, usually an integer. Only used on Array concepts.
     */
    var sendEntity = function (panel, concept, forceSend, itemIdentifierValue) {
      var deferred = $q.defer();
      // Clear the errors for this concept (if it exists)
      if (DoesNestedPropertyExist(panel, "errors." + concept)) {
        delete panel.errors[concept];
      }
      if (panel[concept].isArray) {
        // According to the API guys, we can count on 'number' being the key for any multi-concept items.
        var itemIdentifierKey = "number";
        if (angular.isDefined(itemIdentifierValue)) {
          // Get the right item to create or update
          var theItem = panel.getSCAPIReadyObject(
            concept,
            itemIdentifierKey,
            itemIdentifierValue
          );
          // If there is no item found, the key/value pair that was sent in didn't match anything...we don't know what to update
          if (theItem === false) {
            $rootScope.alerts.push({
              type: "error",
              text:
                "No concept found to update: itemIdentifierKey: " +
                itemIdentifierKey +
                "  itemIdentifierValue: " +
                angular.toJson(itemIdentifierValue),
            });
            deferred.reject();
          }
          // See if the panel has a matching item
          var panelEntity = undefined;
          if (DoesNestedPropertyExist(panel, "pristine." + concept)) {
            panelEntity = panel.pristine[concept].find(function (entity) {
              return (
                entity.hasOwnProperty(itemIdentifierKey) &&
                +entity[itemIdentifierKey] === +itemIdentifierValue
              );
            });
          }
          var entityIsNew = angular.isUndefined(panelEntity);
          if (entityIsNew) {
            panel
              .create(concept, theItem)
              .then(
                function () {
                  addToModifiedConcepts(concept);
                  deferred.resolve();
                },
                function (error) {
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else {
            // Only send the update if something has changed
            if (
              panel.isDirty(concept, itemIdentifierKey, itemIdentifierValue) ||
              forceSend
            ) {
              if (forceSend) {
              } else {
              }
              // Send to update, and provide the item ID
              panel
                .update(concept, theItem[itemIdentifierKey], theItem)
                .then(
                  function () {
                    addToModifiedConcepts(concept);
                    deferred.resolve();
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              // Nothing was sent, but we still need to resolve the promise.
              deferred.resolve();
            }
          }
        } else {
          console.warn("No item number provided for array concept.");
          deferred.reject("No item number provided for " + concept);
        }
      } else {
        // Single concept
        // The pristine concept for a single concept will be new when saving a panel. It indicates the new template
        // was retrieved when refreshing the pristine copy.
        if (panel.pristine[concept].isNew) {
          panel
            .create(concept, panel[concept])
            .then(
              function () {
                addToModifiedConcepts(concept);
                deferred.resolve();
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        } else if (panel.isDirty(concept) || forceSend) {
          // Only send the update if something has changed or forceSend is Truthy
          if (forceSend) {
          } else {
          }
          // Store the arm_mode to see if it changes
          var pristineArmMode = panel.pristine.system_options.arm_mode;
          // If CellCom family...
          var pristineKeypadInput = panel.pristine.system_options.kpad_input;
          let pristineToUse = pristineKeypadInput
            ? pristineKeypadInput
            : pristineArmMode;

          panel
            .update(concept)
            .then(
              function () {
                addToModifiedConcepts(concept);
                // The panel will automatically create / modify areas for certain arming modes
                // let kpadNew = pristineKeypadInput != panel.system_options.kpad_input;
                if (
                  concept === "system_options" &&
                  panel.system_options.arm_mode !== pristineToUse &&
                  OnlinePanelService.isOnline()
                ) {
                  refresh(panel, "area_informations")
                    .then(
                      function () {
                        panel.pristine.system_options.arm_mode =
                          panel.system_options.arm_mode;
                        panel.setDefaultAreas();
                        deferred.resolve();
                      },
                      function (error) {
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                }
                deferred.resolve();
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        } else {
          // Nothing was sent, but we still need to resolve the promise.
          deferred.resolve();
        }
      }
      return deferred.promise;
    };

    /**
     * Pull just the errors out of the errors returned from sending programming and format it for a more friendly
     * display
     *
     * @param {object} errors - errors returned from sending programming
     * @returns {string}
     */
    var extractAndFormatSCAPIErrors = function (errors) {
      var fieldErrors = "";
      var statusTexts = [];
      angular.forEach(errors, function (error) {
        // SCAPI provides errors in error.data.error such as which property caused an unprocessable entity
        if (DoesNestedPropertyExist(error, "data.errors")) {
          var fieldKeys = Object.keys(error.data.errors);
          angular.forEach(fieldKeys, function (fieldKey) {
            fieldErrors += fieldErrors === "" ? " - " : ", ";
            fieldErrors +=
              $filter("titlecase")(fieldKey) +
              " " +
              error.data.errors[fieldKey];
          });
        } else if (
          error.hasOwnProperty("statusText") &&
          error.statusText &&
          statusTexts.indexOf(error.statusText) === -1
        ) {
          statusTexts.push(error.statusText);
        }
      });
      if (fieldErrors === "") {
        angular.forEach(statusTexts, function (status) {
          fieldErrors += fieldErrors === "" ? " - " : ", ";
          fieldErrors += $filter("titlecase")(status);
        });
      }
      return fieldErrors;
    };

    /**
     * Prepare the panel for replacement or restoral from backup - delete all array concepts
     * @param {*} panel
     * @param {*} concepts
     */
    function prepForRestore(panel, concepts) {
      let deferred = $q.defer();
      if (
        OnlinePanelService.isOnline() &&
        (ReplacePanelService.replacingPanel() ||
          PanelProgArchiveService.restoringPanel)
      ) {
        let deletePromises = [];
        let sequentialDeleteEntities = [];
        let conceptsWithDeletes = [];
        if (OnlinePanelService.isOnline()) {
          angular.forEach(concepts, function (concept) {
            if (
              DoesNestedPropertyExist(
                panel,
                "PDS.panel_def.CONCEPTS." + concept + ".DISPLAY.isArray"
              ) &&
              panel.PDS.panel_def.CONCEPTS[concept].DISPLAY.isArray
            ) {
              angular.forEach(panel.pristine[concept], function (entity) {
                if (!conceptsWithDeletes.includes(concept)) {
                  conceptsWithDeletes.push(concept);
                  panel[concept].isBusy = true;
                }
                if (throttledConcepts.includes(concept)) {
                  sequentialDeleteEntities.push(
                    createActionItem(concept, entity, "DELETE")
                  );
                } else {
                  deletePromises.push(
                    PanelProg.delete(
                      concept,
                      entity.number,
                      false,
                      PanelProg.getSessionKey()
                    )
                  );
                }
              });
            }
          });
        }
        deletePromises.push(sendSequentially(panel, sequentialDeleteEntities));
        QueueService.waitForAllPromisesToResolve(deletePromises)
          .then(
            function () {},
            function (error) {}
          )
          .finally(function () {
            var refreshPromises = [];
            angular.forEach(conceptsWithDeletes, function (concept) {
              panel[concept].isBusy = false;
              refreshPromises.push(refresh(panel, concept, true));
            });
            $q.all(refreshPromises).then(
              function () {
                deferred.resolve();
              },
              function (error) {
                console.error(
                  `error refreshing concepts with deletes: ${angular.toJson(
                    error
                  )}`
                );
                deferred.reject();
              }
            );
          });
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }

    // In testing, we found that if multiple calls are made for XR schedules rapidly, the connection to the panel will be lost and all
    // subsequent requests will fail.
    let throttledConcepts = ["time_schedules"];

    /**
     * Send each entity of a concept sequentially, one-at-a-time, waiting for each one to finish before
     * starting the next
     * @param {*} panel
     * @param {string} concept
     * @returns {*} promise
     */
    function sendThrottledConcept(panel, concept) {
      let deferred = $q.defer();
      if (throttledConcepts.includes(concept)) {
        // Clear the errors for this concept (if it exists)
        if (DoesNestedPropertyExist(panel, "errors." + concept)) {
          delete panel.errors[concept];
        }
        if (panel[concept].isArray) {
          // According to the API guys, we can count on 'number' being the key for any multi-concept items.
          var itemIdentifierKey = "number";
          console.debug(
            `Panel->sendThrottledConcept() - ${concept}: ${angular.toJson(
              panel[concept]
            )}`
          );
          let sequentialActionItems = [];
          angular.forEach(panel[concept], function (entity) {
            let itemIdentifierValue = entity[itemIdentifierKey];
            // Get the right item to create or update and format it for SCAPI
            var theItem = panel.getSCAPIReadyObject(
              concept,
              itemIdentifierKey,
              itemIdentifierValue
            );
            // If there is no item found, carry on
            if (theItem === false) {
              console.error(
                `error getting scapi ready entity for throttled concept: ${concept}, entity ${itemIdentifierKey} = ${itemIdentifierValue}`
              );
            } else {
              // (Assuming a pristine-only refresh has been done) See if the panel has a matching item
              var panelEntity = undefined;
              if (DoesNestedPropertyExist(panel, "pristine." + concept)) {
                panelEntity = panel.pristine[concept].find(function (e) {
                  return (
                    e.hasOwnProperty(itemIdentifierKey) &&
                    +e[itemIdentifierKey] === +itemIdentifierValue
                  );
                });
              }
              var entityIsNew = angular.isUndefined(panelEntity);
              if (entityIsNew) {
                sequentialActionItems.push(
                  createActionItem(concept, theItem, "POST")
                );
              } else {
                // Only send the update if something has changed
                if (
                  panel.isDirty(concept, itemIdentifierKey, itemIdentifierValue)
                ) {
                  // Send to update, and provide the item ID
                  sequentialActionItems.push(
                    createActionItem(concept, theItem, "PUT")
                  );
                } else {
                  // Nothing was sent, but we still need to resolve the promise.
                }
              }
            }
          });
          return sendSequentially(panel, sequentialActionItems);
        } else {
          console.error(
            `non-array concepts are not yet supported for throttling`
          );
          deferred.reject(`throttling ${concept} is not supported`);
        }
      } else {
        console.error(`${concept} is not a valid throttled concept`);
        deferred.reject(`throttling ${concept} is not supported`);
      }
      return deferred.promise;
    }

    function createActionItem(concept, entity, operation) {
      if (
        !angular.isString(concept) ||
        !angular.isObject(entity) ||
        !angular.isString(operation)
      ) {
        console.warn(`Panel->createActionItem() - invalid arguement`);
        return null;
      } else if (!["DELETE", "PUT", "POST"].includes(operation)) {
        console.warn(
          `Panel->createActionItem() - invalid operation: ${operation}`
        );
        return null;
      }
      return {
        concept: concept,
        entity: entity,
        operation: operation,
      };
    }

    /**
     * This function sends a set of panel entities in order, allowing each command to complete before (recursively) calling the next
     * @param {*} panel
     * @param {actionItem} actionItems
     * @param {*} i
     * @param {*} deferred
     */
    function sendSequentially(panel, actionItems, i, deferred) {
      deferred = deferred || $q.defer();
      if (angular.isArray(actionItems) && actionItems.length > 0) {
        i = i || 0;
        let actionItem = actionItems[i];
        let promise = null;
        switch (actionItem.operation) {
          case "DELETE":
            promise = PanelProg.delete(
              actionItem.concept,
              actionItem.entity.number,
              false,
              PanelProg.getSessionKey()
            );
            break;
          case "PUT":
            promise = PanelProg.update(
              actionItem.concept,
              panel.getJSON(actionItem.concept, actionItem.entity),
              actionItem.entity.number,
              false,
              PanelProg.getSessionKey()
            );
            break;
          case "POST":
            promise = PanelProg.create(
              actionItem.concept,
              actionItem.entity,
              false,
              PanelProg.getSessionKey()
            );
            break;
          default:
            console.error(
              `unrecognized throttle operation: ${angular.toJson(actionItem)}`
            );
            deferred.reject();
        }
        promise
          .then(
            function () {
              addToModifiedConcepts(actionItem.concept);
            },
            function (error) {
              console.error(
                `error slowly deleting items: ${angular.toJson(error)}`
              );
            }
          )
          .finally(function () {
            if (i < actionItems.length - 1) {
              sendSequentially(panel, actionItems, ++i, deferred);
            } else {
              deferred.resolve();
            }
          })
          .catch(function (error) {
            console.error(
              `caught error slowly deleting actionItems: ${angular.toJson(
                error
              )}`
            );
            deferred.reject(error);
          });
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }

    /**
     * This function determines if SCAPI has connected to a system before initial connection is complete. If so,
     * System Options and Remote Options are refreshed.
     * note: If SCAPI is able to connect to a system when it is created, System Options will only contain an arm_mode.
     * The remaining properties will be null.
     * @param {*} panel
     */
    var validateSCAPISystemOptions = function (panel) {
      var deferred = $q.defer();
      if (ClientEventsService.initialConnection.hasBeenEstablished()) {
        deferred.resolve();
      } else {
        if (panel.hasOwnProperty("system_options")) {
          var systemOptionsProps = Object.keys(panel.system_options);
          var nonNullProps = [];
          angular.forEach(systemOptionsProps, function (prop) {
            // Ignore properties that we add (isBusy, isArray, etc).
            if (
              locallyManagedProperties.indexOf(prop) === -1 &&
              panel.system_options[prop] !== null
            ) {
              nonNullProps.push(prop);
            }
          });
          // If only arm_mode remains, refresh
          if (nonNullProps.length === 1 && nonNullProps[0] === "arm_mode") {
            InitialConnectionService.bypassForSCAPICorrection = true;
            var promises = [];
            promises.push(refresh(panel, "system_options"));
            promises.push(refresh(panel, "remote_options"));
            $q.all(promises)
              .then(
                function () {
                  InitialConnectionService.bypassForSCAPICorrection = false;
                  deferred.resolve();
                },
                function (error) {
                  InitialConnectionService.bypassForSCAPICorrection = false;
                  console.error(
                    "Panel->validateSCAPISystemOptions() - Error refreshing: " +
                      angular.toJson(error)
                  );
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else {
            deferred.resolve();
          }
        } else {
          deferred.resolve();
        }
      }
      return deferred.promise;
    };

    /**
     * @ngdoc object
     * @name method:refresh
     * @methodOf App.factory:Panel
     *
     * @description
     *   Refresh a single panel concept
     *
     * @param {*} panel
     * @param {String} concept the name of the concept
     * @param {boolean} [pristineOnly=false] - Add this concept to the list of locally cached concepts
     */
    var refresh = function (panel, concept, pristineOnly) {
      var isStatus = concept === "zone_statuses";
      var deferred = $q.defer();

      // If the concept is capabilities we can't call refresh so exit
      if (concept === "capabilities") {
        deferred.resolve();
        return deferred.promise;
      }

      // This function will update the local concept unless explicitly told not to
      if (angular.isUndefined(pristineOnly)) {
        pristineOnly = false;
      }
      var additionalParams = {};
      additionalParams.panel_id = panel.panel_id; // Make sure we're using the correct panel id for our call
      if (isStatus) {
        additionalParams.include_24_hour_zones = true; // Make sure that we refresh 24 hr zone statuses
        if (angular.isUndefined(panel.statuses[concept])) {
          panel.statuses[concept] = {};
        }
        // Initialize the status object
        panel.statuses[concept].isBusy = true;
      } else {
        // Initialize the concept object
        var apiConcept = PANEL_CONCEPTS[concept].api_name;
        panel.ensureInitAndMarkBusy(concept);
      }

      // Remove bubble-up errors on refresh
      if (
        angular.isDefined(panel.session.CONCEPTS[concept]) &&
        angular.isDefined(panel.session.CONCEPTS[concept].NUMBERS)
      ) {
        angular.forEach(
          panel.session.CONCEPTS[concept].NUMBERS,
          function (sessionData, num) {
            panel.setInvalidIndicator(false, concept, num);
          }
        );
      }

      PanelProg.refresh(
        concept,
        true,
        additionalParams,
        PanelProg.getSessionKey()
      )
        .then(
          function (data) {
            if (isStatus) {
              panel.setStatus(concept, data[concept]);
              panel.statuses[concept].isBusy = false;
            } else {
              // Update the pristine concept and update the locally cached concept if told to (by pristineOnly)
              panel.setConcept(concept, data[apiConcept], pristineOnly);
              panel[concept].isBusy = false;
            }
            deferred.resolve();
          },
          function (error) {
            if (mayHaveNewItem(panel, error, concept)) {
              panel
                .newItem(concept)
                .then(
                  function (item) {
                    panel.setConcept(concept, item, pristineOnly);
                    panel[concept].isBusy = false;
                    deferred.resolve();
                  },
                  function (error) {
                    console.error(
                      "Panel->fetch() - Error getting new item template: " +
                        angular.toJson(error)
                    );
                    panel[concept].isBusy = false;
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              isStatus
                ? (panel.statuses[concept].isBusy = false)
                : (panel[concept].isBusy = false);
              deferred.reject(error);
            }
          },
          function (info) {
            isStatus
              ? (panel.statuses[concept].isBusy = true)
              : (panel[concept].isBusy = true);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * An array of concepts that have the SCAPI /restore option
     * @type {string[]}
     */
    var validRestoreConcepts = ["user_codes"];
    /**
     * @ngdoc object
     * @name method:restore
     * @methodOf App.factory:Panel
     *
     * @description
     *   Send programming from VK cache to the panel
     *
     * @param {*} panel
     * @param {String} concept the name of the concept
     */
    var restore = function (panel, concept) {
      var deferred = $q.defer();

      if (validRestoreConcepts.indexOf(concept) === -1) {
        deferred.resolve();
        return deferred.promise;
      }

      PanelProg.restore(concept)
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "any user codes stored are being sent to the system",
            });
            deferred.resolve();
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    return panel;
  },
]);
