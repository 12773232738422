import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreOutgoingTcpPortField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreOutgoingTcpPortField_remoteOptions.graphql";

export const remoteOptionsEntreOutgoingTcpPortFieldId = () =>
  "remote-options-entre-outgoing-tcp-port";

function RemoteOptionsEntreOutgoingTcpPortField() {
  const [{ entreOutgoingPort, entreConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsEntreOutgoingTcpPortField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsEntreOutgoingTcpPortField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            entreOutgoingPort
            entreConnection
          }
          ... on XfRemoteOptions {
            entreOutgoingPort
            entreConnection
          }
          ... on TMSentryRemoteOptions {
            entreOutgoingPort
            entreConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsEntreOutgoingTcpPortFieldId();
  const disabled = entreConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entré Outbound TCP Port" //Note that this is outbound in the label text.  This is not a typo, this matches the panel keypad verbiage
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={entreOutgoingPort || ""}
        maxLength={16}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        inlineHelp="1-65535"
        disabled={disabled}
        min={1}
        max={65535}
        validationMessage="Valid values are 1-65535."
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "entreOutgoingPort");
          });
        }}
        onBlur={({ target }) => {
          const value = clamp(1, 65535, Number(target.value));
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(
              String(value).padStart(5, `0`),
              "entreOutgoingPort"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreOutgoingTcpPortField;
