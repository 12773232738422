/**
 * @generated SignedSource<<d615547519bcbf9b76237e4f7b3c390d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationVplexSerialNumberField_zone$data = {
  readonly competitorWireless: boolean | null;
  readonly expanderSerialNumber: string | null;
  readonly number: string;
  readonly serialNumber: string;
  readonly wireless: boolean;
  readonly " $fragmentType": "ZoneInformationVplexSerialNumberField_zone";
};
export type ZoneInformationVplexSerialNumberField_zone$key = {
  readonly " $data"?: ZoneInformationVplexSerialNumberField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationVplexSerialNumberField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationVplexSerialNumberField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expanderSerialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "competitorWireless",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "a082000f3d0e6c7830f08bb0990d4888";

export default node;
