/**
 * @generated SignedSource<<dcfadd9eb2c60ceb57857613e89b2f68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DirectionOfTravel = "CROSS" | "IN" | "OFF" | "OUT";
export type GeoJSONType = "FEATURE" | "FEATURECOLLECTION" | "GEOMETRYCOLLECTION" | "LINESTRING" | "MULTILINESTRING" | "MULTIPOINT" | "MULTIPOLYGON" | "POINT" | "POLYGON";
export type UpdateVarHubInput = {
  cameras?: ReadonlyArray<UpdateVarHubLoiteringCameraInput | null> | null;
  disableAutoAddCameras?: boolean | null;
  hubId: string;
  hubName: string;
  panelIntegrationGatewayIpAddress?: string | null;
  panelIntegrationPanelDiscoveryEnabled?: boolean | null;
  panelIntegrationPanelIpAddress?: string | null;
  proFeatures?: boolean | null;
  secondaryNetworkIP?: string | null;
  secondaryNetworkSubnetMask?: string | null;
  secondaryNetworkType?: string | null;
  systemId: string;
  timeLocation?: string | null;
};
export type UpdateVarHubLoiteringCameraInput = {
  addedToDB?: boolean | null;
  camectCamId?: string | null;
  cameraId?: number | null;
  cameraName?: string | null;
  detectionLines?: ReadonlyArray<VarHubCameraDetectionLineInput | null> | null;
  detectionRegions?: ReadonlyArray<VarHubCameraDetectionRegionInput | null> | null;
  id: string;
  ipAddress?: string | null;
};
export type VarHubCameraDetectionRegionInput = {
  detectAnimals?: boolean | null;
  detectPeople?: boolean | null;
  detectVehicles?: boolean | null;
  drawRegionOnAlerts?: boolean | null;
  geometry?: VarHubCameraRegionGeometryInput | null;
  id?: string | null;
  loiteringPersonSeconds?: number | null;
  loiteringVehicleSeconds?: number | null;
  movementDirection?: DirectionOfTravel | null;
  name?: string | null;
  slotNumber?: number | null;
  zone?: VarHubCameraZoneInput | null;
};
export type VarHubCameraZoneInput = {
  areaId?: string | null;
  id?: string | null;
  name?: string | null;
  number: number;
};
export type VarHubCameraRegionGeometryInput = {
  coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
  type: GeoJSONType;
};
export type VarHubCameraDetectionLineInput = {
  detectAnimals?: boolean | null;
  detectPeople?: boolean | null;
  detectVehicles?: boolean | null;
  drawRegionOnAlerts?: boolean | null;
  geometry?: VarHubCameraLineGeometryInput | null;
  id?: string | null;
  movementDirection?: DirectionOfTravel | null;
  name?: string | null;
  slotNumber?: number | null;
  zone?: VarHubCameraZoneInput | null;
};
export type VarHubCameraLineGeometryInput = {
  coordinates: ReadonlyArray<ReadonlyArray<number>>;
  type: GeoJSONType;
};
export type RecorderAddModalUpdateVarHubMutation$variables = {
  hub: UpdateVarHubInput;
};
export type RecorderAddModalUpdateVarHubMutation$data = {
  readonly updateVarHub: {
    readonly __typename: "UpdateVarHubErrorPayload";
    readonly message: string | null;
    readonly type: string | null;
  } | {
    readonly __typename: "UpdateVarHubSuccessPayload";
    readonly status: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type RecorderAddModalUpdateVarHubMutation = {
  response: RecorderAddModalUpdateVarHubMutation$data;
  variables: RecorderAddModalUpdateVarHubMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hub"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "hub",
        "variableName": "hub"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateVarHub",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "type": "UpdateVarHubSuccessPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UpdateVarHubErrorPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderAddModalUpdateVarHubMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecorderAddModalUpdateVarHubMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a245df3fb59035231f1cc3e964ab435d",
    "id": null,
    "metadata": {},
    "name": "RecorderAddModalUpdateVarHubMutation",
    "operationKind": "mutation",
    "text": "mutation RecorderAddModalUpdateVarHubMutation(\n  $hub: UpdateVarHubInput!\n) {\n  updateVarHub(hub: $hub) {\n    __typename\n    ... on UpdateVarHubSuccessPayload {\n      status\n    }\n    ... on UpdateVarHubErrorPayload {\n      type\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1b7638a4ab717e7bb72ad2fce914d39";

export default node;
