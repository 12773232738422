import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { asString, toGroupId } from "securecom-graphql/client";
import BaseGroupForm from "./BaseGroupForm";
import { GroupFormState } from "./reducer";
import { GroupEditGroupQuery } from "./__generated__/GroupEditGroupQuery.graphql";

type Props = {
  customerId: string;
  controlSystemId: string;
  groupNumber: string;
};

const GroupEdit = ({ customerId, controlSystemId, groupNumber }: Props) => {
  const groupData = useLazyLoadQuery<GroupEditGroupQuery>(
    graphql`
      query GroupEditGroupQuery($groupId: ID!) {
        group: node(id: $groupId) {
          ... on Group {
            id
            number
            name
            doors {
              id
              name
              number
            }
            accessibleAreas
            armableAreas
            schedules {
              id
              name
              number
            }
            areas {
              id
              name
              number
            }
            options {
              armAuthority
              disarmAuthority
              swipeTwiceToArm
              lockdown
              schedules
              userProgramming
              groupsProgramming
              auditDaysEnabled
              auditDaysValue
            }
          }
        }
      }
    `,
    {
      groupId: asString(toGroupId(groupNumber, controlSystemId)),
    }
  );

  const initialFormState: GroupFormState = {
    number: groupData.group?.number || undefined,
    name: groupData.group?.name || "",
    doors: groupData.group?.doors?.map((d) => d?.number || 0) || [],
    areas: groupData.group?.areas?.map((d) => d?.number || "") || [],
    schedules: groupData.group?.schedules?.map((s) => s?.number || "") || [],
    lockdown: groupData.group?.options?.lockdown || false,
    armAuthority: groupData.group?.options?.armAuthority || false,
    disarmAuthority: groupData.group?.options?.disarmAuthority || false,
    swipeTwiceToArm: groupData.group?.options?.swipeTwiceToArm || false,
    schedulesEnabled: groupData.group?.options?.schedules || false,
    userProgramming: groupData.group?.options?.userProgramming || false,
    groupsProgramming: groupData.group?.options?.groupsProgramming || false,
    auditDaysEnabled: groupData.group?.options?.auditDaysEnabled || false,
    auditDaysValue: groupData.group?.options?.auditDaysValue || "",
    swipeTwiceDisabled:
      !groupData.group?.options?.swipeTwiceToArm &&
      !groupData.group?.options?.armAuthority,
    persisted: true,
    validationErrors: [],
    rawDoors: groupData.group?.accessibleAreas?.map((number) => number!) || [],
    rawAreas: groupData.group?.armableAreas?.map((number) => number!) || [],
  };

  return (
    <BaseGroupForm
      customerId={customerId}
      controlSystemId={controlSystemId}
      groupNumber={groupNumber}
      initialFormState={initialFormState}
      isNew={false}
    />
  );
};

export default GroupEdit;
