import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { BellActions } from "securecom-graphql/client";
import { useHardwareModel, useSupportsTemporal3 } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { parseTemporalvsTemporal3 } from "./utils";
import { BellOptionsCarbonMonoxideBellActionField_bellOptions$key } from "./__generated__/BellOptionsCarbonMonoxideBellActionField_bellOptions.graphql";

export const bellOptionsCarbonMonoxideBellActionFieldId = () =>
  "bell-options-carbon-monoxide-bell-action";

function BellOptionsCarbonMonoxideBellActionField() {
  const [{ carbonMonoxideBellAction }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsCarbonMonoxideBellActionField_bellOptions$key>(
      graphql`
        fragment BellOptionsCarbonMonoxideBellActionField_bellOptions on BellOptions {
          carbonMonoxideBellAction
        }
      `
    );

  const fieldId = bellOptionsCarbonMonoxideBellActionFieldId();
  const supportsTemporal3 = useSupportsTemporal3();
  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Carbon Monoxide Bell Action"
      tooltip={`${
        isXf ? "Applies only to NAC cicuits using DMP protocol: " : ""
      } Set the cadence the Bell Output uses for alarms in Carbon Monoxide type Zones.`}
    >
      <Select
        id={fieldId}
        value={parseTemporalvsTemporal3(
          carbonMonoxideBellAction as BellActions,
          supportsTemporal3
        )}
        onChange={({ target }) => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(target.value, "carbonMonoxideBellAction");
          });
        }}
      >
        <Select.Option value={BellActions.STEADY}>Steady</Select.Option>
        <Select.Option value={BellActions.PULSE}>Pulse</Select.Option>
        {supportsTemporal3 ? (
          <>
            <Select.Option value={BellActions.TEMPORAL_3}>
              Temporal 3
            </Select.Option>
            <Select.Option value={BellActions.TEMPORAL_4}>
              Temporal 4
            </Select.Option>
          </>
        ) : (
          <Select.Option value={BellActions.TEMPORAL}>Temporal</Select.Option>
        )}
        <Select.Option value={BellActions.NONE}>None</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsCarbonMonoxideBellActionField;
