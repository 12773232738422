/**
 * @generated SignedSource<<4514830f554842bde40e371e2583b89d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagEditDealerListQuery$variables = {};
export type TagEditDealerListQuery$data = {
  readonly getDealerList: ReadonlyArray<{
    readonly databaseId: number;
    readonly name: string;
    readonly securityCommand: boolean | null;
  } | null>;
};
export type TagEditDealerListQuery = {
  response: TagEditDealerListQuery$data;
  variables: TagEditDealerListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DealerListEntry",
    "kind": "LinkedField",
    "name": "getDealerList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "databaseId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "securityCommand",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TagEditDealerListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TagEditDealerListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8902fdd93a108870ac7b1af6546dbbcf",
    "id": null,
    "metadata": {},
    "name": "TagEditDealerListQuery",
    "operationKind": "query",
    "text": "query TagEditDealerListQuery {\n  getDealerList {\n    databaseId\n    name\n    securityCommand\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b6f58914047b11d7dffdae2ea5c664b";

export default node;
