/**
 * @generated SignedSource<<c3b8c7bed1dddade014c3336773393ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SyncBillingResponseErrors = "SYNC_FAILED" | "SYSTEM_NOT_FOUND";
export type TagFieldSyncBillingMutation$variables = {
  systemId: string;
};
export type TagFieldSyncBillingMutation$data = {
  readonly syncBilling: {
    readonly controlSystem?: {
      readonly id: string;
    };
    readonly error?: SyncBillingResponseErrors;
  };
};
export type TagFieldSyncBillingMutation = {
  response: TagFieldSyncBillingMutation$data;
  variables: TagFieldSyncBillingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystem",
      "kind": "LinkedField",
      "name": "controlSystem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SyncBillingSuccessResponse",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    }
  ],
  "type": "SyncBillingErrorResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagFieldSyncBillingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "syncBilling",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagFieldSyncBillingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "syncBilling",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d8a3923db43d7c8cc8469b89919f18c",
    "id": null,
    "metadata": {},
    "name": "TagFieldSyncBillingMutation",
    "operationKind": "mutation",
    "text": "mutation TagFieldSyncBillingMutation(\n  $systemId: ID!\n) {\n  syncBilling(systemId: $systemId) {\n    __typename\n    ... on SyncBillingSuccessResponse {\n      controlSystem {\n        id\n      }\n    }\n    ... on SyncBillingErrorResponse {\n      error\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "684aa1e818c9d4e9a1d4adfaacca5f01";

export default node;
