import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefinedOrEmpty } from "common/utils/universal/function";
import TextInput from "components/FullProgramming/common/TextInput";
import BarCodeScanner from "components/MobileCamera/MobileCameraCheck";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { ZoneInformationExpanderSerialNumberField_zone$key } from "./__generated__/ZoneInformationExpanderSerialNumberField_zone.graphql";
import { getOnBoardZoneNumberRange, resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { DEFAULT_VPLEX_SERIAL_NUMBER } from "./ZoneInformationVplexSerialNumberField";

export const DEFAULT_EXPANDER_SERIAL_NUMBER = "0000000000";

export const ZONE_INFORMATION_EXPANDER_SERIAL_NUMBER =
  "zone-information-expander-serial-number-";

export const zoneInformationExpanderSerialNumberFieldId = (number: string) =>
  ZONE_INFORMATION_EXPANDER_SERIAL_NUMBER + number;

function ZoneInformationExpanderSerialNumberField() {
  const [
    {
      number,
      expanderSerialNumber,
      serialNumber,
      wireless,
      competitorWireless,
    },
    updateZoneInformation,
  ] =
    useZoneInformationFragment<ZoneInformationExpanderSerialNumberField_zone$key>(
      graphql`
        fragment ZoneInformationExpanderSerialNumberField_zone on Zone {
          number
          expanderSerialNumber
          serialNumber
          wireless
          competitorWireless
        }
      `
    );

  const numberTypeNumber = Number(number);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const fieldID = zoneInformationExpanderSerialNumberFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const onboardZones = getOnBoardZoneNumberRange(hardwareModel);
  const hasVplexSerialNumber =
    isNotNullOrUndefinedOrEmpty(serialNumber) &&
    serialNumber !== DEFAULT_VPLEX_SERIAL_NUMBER;
  const disabled =
    wireless ||
    competitorWireless ||
    !number ||
    onboardZones.includes(numberTypeNumber) ||
    hasVplexSerialNumber;
  const { EXPANDER_SERIAL_NUMBER: RULES } =
    resolveZoneFieldRules(hardwareModel);
  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldID}
      label="Expander Serial Number"
      disabled={disabled}
    >
      <div>
        <TextInput
          id={fieldID}
          name={fieldID}
          value={!disabled ? expanderSerialNumber ?? "" : ""}
          className={`expander-${number}-textbox`}
          disabled={disabled}
          maxLength={10}
          pattern={RULES.PATTERN}
          inlineHelp={RULES.INLINE_HELP}
          title={RULES.INLINE_HELP}
          validationMessage={RULES.VALIDATION_MSG}
          onChange={({ target }) => {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(target.value, "expanderSerialNumber");
            });
          }}
          onBlur={({ target }) => {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(
                target.value === ""
                  ? DEFAULT_EXPANDER_SERIAL_NUMBER
                  : target.value,
                "expanderSerialNumber"
              );
            });
          }}
        />
        {windowWidth <= 800 || window.navigator.userAgent.includes("iPad") ? (
          <BarCodeScanner updateZoneInformation={updateZoneInformation} />
        ) : null}
      </div>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationExpanderSerialNumberField;
