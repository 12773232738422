/**
 * @generated SignedSource<<4576ec80e1f8a1433d18c15ae3b041f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AllowTimeChange = "NO" | "SERVER" | "YES";
export type InspectionReminder = "NONE" | "ONE_YEAR" | "SIX_MONTHS" | "THREE_MONTHS";
export type PanelProgrammingLanguage = "CZECH" | "DUTCH" | "ENGLISH" | "FRENCH" | "GREEK" | "NONE" | "SPANISH";
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
export type WirelessEncryption1100 = "ALL" | "BOTH" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XFSystemOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly id: string;
    readonly systemOptions: {
      readonly __typename: string;
      readonly crossZoneTime?: number;
      readonly detectJam?: boolean;
      readonly hoursFromGMT?: number;
      readonly houseCode?: string;
      readonly id: string;
      readonly inspectionReminder?: InspectionReminder | null;
      readonly latchedSupervisoryZones?: boolean;
      readonly observeDst?: boolean | null;
      readonly powerFailDelay?: number;
      readonly primaryProgrammingLanguage?: PanelProgrammingLanguage | null;
      readonly primaryUserLanguage?: PanelProgrammingLanguage | null;
      readonly secondaryProgrammingLanguage?: PanelProgrammingLanguage | null;
      readonly secondaryUserLanguage?: PanelProgrammingLanguage | null;
      readonly swingerBypassTrips?: number;
      readonly timeChange?: AllowTimeChange;
      readonly wirelessAudibles?: WirelessAudibles | null;
      readonly wirelessEncryption1100?: WirelessEncryption1100;
      readonly wirelessEncryption1100Passphrase?: string;
      readonly zoneRetardDelay?: number;
    };
  };
  readonly " $fragmentType": "XFSystemOptionsProgrammingConceptFormInline_controlSystem";
};
export type XFSystemOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFSystemOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFSystemOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFSystemOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "da8e88a606759517c2fbf97ef7b6d902";

export default node;
