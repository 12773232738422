import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions.graphql";

export const remoteOptionsIntegratorSupervisoryReportsFieldId = () =>
  `remote-options-integrator-supervisory-reports`;

function RemoteOptionsIntegratorSupervisoryReportsField() {
  const [
    { integratorSupervisoryReports, integratorConnection },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorSupervisoryReports
          integratorConnection
        }
        ... on Xt75RemoteOptions {
          integratorSupervisoryReports
          integratorConnection
        }
        ... on XfRemoteOptions {
          integratorSupervisoryReports
          integratorConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorSupervisoryReportsFieldId();
  const label = "Integrator Supervisory Reports";
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label={label}
    >
      <Switch
        label={label}
        disabled={disabled}
        id={fieldId}
        checked={!!integratorSupervisoryReports}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !integratorSupervisoryReports,
              "integratorSupervisoryReports"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorSupervisoryReportsField;
