import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import {
  ChangedProgrammingConcepts,
  removeProgrammingConceptFromChangedProgrammingConcepts,
  useSetChangedProgrammingConcepts,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { SystemOptionsContextProvider } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsContext";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  PanelProgrammingLanguage,
  SystemOptions,
  SystemType,
} from "securecom-graphql/client";
import {
  ControlSystemContextProvider,
  useControlSystemFragment,
} from "../../common/ControlSystemContext";
import { PanelContextProvider } from "../../common/PanelContext";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import {
  conceptId as xtAreaConceptId,
  useSaveMutation as useSaveXTAreas,
} from "../XTAreaInformationProgrammingConceptForm/index";
import { XTAreaInformationProgrammingConceptFormInline_controlSystem$key } from "../XTAreaInformationProgrammingConceptForm/__generated__/XTAreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import XT30SystemOptions from "./XT30SystemOptions";
import XT50SystemOptions from "./XT50SystemOptions";
import XTLPSystemOptions from "./XTLPSystemOptions";
import XTLSystemOptions from "./XTLSystemOptions";
import {
  XTSystemOptionsProgrammingConceptFormInline_controlSystem$data,
  XTSystemOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XTSystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XTSystemOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTSystemOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XTSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation,
} from "./__generated__/XTSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation.graphql";
import {
  XTSystemOptionsProgrammingConceptFormSendSystemOptionsMutation,
  XTSystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
} from "./__generated__/XTSystemOptionsProgrammingConceptFormSendSystemOptionsMutation.graphql";
import { XTSystemOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XTSystemOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "System Options";
export const conceptId = "xt-system-options";

export const getState = (
  controlSystem: XTSystemOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XTSystemOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          systemOptions {
            id
            ... on XtSystemOptions {
              systemType
              __typename
              closingCode
              closingCheck
              entryDelay1
              entryDelay2
              crossZoneTime
              powerFailDelay
              swingerBypassTrips
              resetSwingerBypass
              zoneActivityHours
              armDisarmActivityDays
              timeChange
              observeDst
              hoursFromGMT
              displayTime
              houseCode
              detectJam
              wirelessAudibles
              useBuiltIn1100Wireless
              enableKeypadPanicKeys
              occupiedPremises
              useFalseAlarmQuestion
              weatherZipCode
              iso2CountryCode
              weatherPostalCode
              primaryProgrammingLanguage
              secondaryProgrammingLanguage
              primaryUserLanguage
              secondaryUserLanguage
              wirelessEncryption1100
              wirelessEncryption1100Passphrase
              eolResistorValue
              celsius
              exitDelay
              ecpPartition
              keypadInput
              captureFormat
            }
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation XTSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation(
    $systemId: ID!
  ) {
    refreshSystemOptions(systemId: $systemId) {
      ... on RefreshSystemOptionsSuccessResponse {
        __typename
        controlSystem {
          ...XTSystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XTSystemOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XTSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id: systemId } = getState(props.controlSystem);
        retrieve({
          variables: {
            systemId,
          },
          onCompleted: (response) => {
            const { controlSystem, type } = response.refreshSystemOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "System Options Programming Retrieved From The System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id: systemId,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshSystemOptions: {
                    __typename: response.refreshSystemOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (type) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve System Options: ${hyphenScoreToTitleCase(
                      type
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve System Options",
                  });
                }
              }

              reject(type);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XTSystemOptionsProgrammingConceptFormSendSystemOptionsMutation(
    $systemId: ID!
    $xtSystemOptionsInput: XtSystemOptionsInput!
  ) {
    sendXtSystemOptionsProgramming(
      systemId: $systemId
      xtSystemOptionsInput: $xtSystemOptionsInput
    ) {
      ... on SendSystemOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          id
          ...XTSystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendSystemOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const syncAreas = (
  oldControlSystem: XTSystemOptionsProgrammingConceptFormInline_controlSystem$data,
  newControlSystem: XTSystemOptionsProgrammingConceptFormInline_controlSystem$data,
  setChangedProgrammingConcept: React.Dispatch<
    React.SetStateAction<ChangedProgrammingConcepts>
  >,
  saveAreas: (
    showAlerts?: boolean,
    isSavingAllListItems?: boolean
  ) => Promise<SaveErrors>
) => {
  const {
    panel: {
      systemOptions: { systemType: oldSystemType },
    },
  } = oldControlSystem;
  const {
    panel: {
      systemOptions: { systemType: newSystemType },
    },
  } = newControlSystem;

  if (oldSystemType !== newSystemType && newSystemType !== SystemType.AREA) {
    saveAreas().finally(() => {
      setChangedProgrammingConcept(
        removeProgrammingConceptFromChangedProgrammingConcepts(xtAreaConceptId)
      );
    });
  }
};

const updateOriginalControlSystem = (
  response: XTSystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
  originalControlSystemData: XTSystemOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendXtSystemOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshSystemOptions: {
          __typename: "RefreshSystemOptionsSuccessPayload",
          controlSystem: getState(
            response.sendXtSystemOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XTSystemOptionsProgrammingConceptFormInline_controlSystem$key &
    XTAreaInformationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XTSystemOptionsProgrammingConceptFormSendSystemOptionsMutation>(
      saveMutation
    );
  const [saveAreas, isSavingAreas] = useSaveXTAreas({
    controlSystem: props.controlSystem,
  });

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const setChangedProgrammingConcept = useSetChangedProgrammingConcepts();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) => {
      return new Promise(async (resolve, reject) => {
        const {
          id: systemId,
          panel: { systemOptions },
        } = getState(props.controlSystem);

        save({
          variables: {
            systemId,
            xtSystemOptionsInput: {
              captureFormat: systemOptions.captureFormat,
              systemType: systemOptions.systemType,
              closingCode: systemOptions.closingCode,
              closingCheck: systemOptions.closingCheck,
              entryDelay1: systemOptions.entryDelay1,
              entryDelay2: systemOptions.entryDelay2,
              crossZoneTime: systemOptions.crossZoneTime,
              powerFailDelay: systemOptions.powerFailDelay,
              swingerBypassTrips: systemOptions.swingerBypassTrips,
              resetSwingerBypass: systemOptions.resetSwingerBypass,
              zoneActivityHours: systemOptions.zoneActivityHours,
              armDisarmActivityDays: systemOptions.armDisarmActivityDays,
              timeChange: systemOptions.timeChange,
              observeDst: systemOptions.observeDst,
              hoursFromGMT: systemOptions.hoursFromGMT,
              displayTime: systemOptions.displayTime,
              houseCode: systemOptions.houseCode,
              detectJam: systemOptions.detectJam,
              wirelessAudibles: systemOptions.wirelessAudibles,
              useBuiltIn1100Wireless: systemOptions.useBuiltIn1100Wireless,
              enableKeypadPanicKeys: systemOptions.enableKeypadPanicKeys,
              occupiedPremises: systemOptions.occupiedPremises,
              useFalseAlarmQuestion: systemOptions.useFalseAlarmQuestion,
              weatherZipCode: systemOptions.weatherZipCode,
              iso2CountryCode: systemOptions.iso2CountryCode,
              weatherPostalCode: systemOptions.weatherPostalCode,
              primaryProgrammingLanguage:
                systemOptions.primaryProgrammingLanguage ??
                PanelProgrammingLanguage.ENGLISH,
              secondaryProgrammingLanguage:
                systemOptions.secondaryProgrammingLanguage ??
                PanelProgrammingLanguage.ENGLISH,
              primaryUserLanguage:
                systemOptions.primaryUserLanguage ??
                PanelProgrammingLanguage.ENGLISH,
              secondaryUserLanguage:
                systemOptions.secondaryUserLanguage ??
                PanelProgrammingLanguage.ENGLISH,
              wirelessEncryption1100: systemOptions.wirelessEncryption1100,
              wirelessEncryption1100Passphrase:
                systemOptions.wirelessEncryption1100Passphrase,
              eolResistorValue: systemOptions.eolResistorValue,
              celsius: systemOptions.celsius,
              exitDelay: systemOptions.exitDelay,
              ecpPartition: systemOptions.ecpPartition,
              keypadInput: systemOptions.keypadInput,
            },
          },
          onCompleted: (response) => {
            const sendErrors: SaveErrors = [];
            if (response.sendXtSystemOptionsProgramming.controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: `${title} Programming Saved To the System`,
                });
              }
              resetLastUpdated(conceptId);

              syncAreas(
                getState(originalControlSystem),
                getState(props.controlSystem),
                setChangedProgrammingConcept,
                saveAreas
              );

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );
            } else if (response.sendXtSystemOptionsProgramming.errors) {
              sendErrors.push({
                programmingConcept: title,
                errors: response.sendXtSystemOptionsProgramming.errors,
              });
            }
            resolve(sendErrors);
          },
          onError: () => {
            reject();
          },
        });
      });
    },
    isSaving || isSavingAreas,
  ];
};

const readSystemOptionsTemplateData = (
  programmingTemplateConcepts: XTSystemOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTSystemOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        systemOptions {
          included
          systemType {
            included
            data
          }
          closingCode {
            included
            data
          }
          closingCheck {
            included
            data
          }
          entryDelay1 {
            included
            data
          }
          entryDelay2 {
            included
            data
          }
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          resetSwingerBypass {
            included
            data
          }
          zoneActivityHours {
            included
            data
          }
          armDisarmActivityDays {
            included
            data
          }
          timeChange {
            included
            data
          }
          observeDst {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          displayTime {
            included
            data
          }
          houseCode {
            included
            data
          }
          detectJam {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          useBuiltIn1100Wireless {
            included
            data
          }
          enableKeypadPanicKeys {
            included
            data
          }
          occupiedPremises {
            included
            data
          }
          useFalseAlarmQuestion {
            included
            data
          }
          weatherZipCode {
            included
            data
          }
          iso2CountryCode {
            included
            data
          }
          weatherPostalCode {
            included
            data
          }
          primaryProgrammingLanguage {
            included
            data
          }
          secondaryProgrammingLanguage {
            included
            data
          }
          primaryUserLanguage {
            included
            data
          }
          secondaryUserLanguage {
            included
            data
          }
          wirelessEncryption1100 {
            included
            data
          }
          wirelessEncryption1100Passphrase {
            included
            data
          }
          eolResistorValue {
            included
            data
          }
          celsius {
            included
            data
          }
          exitDelay {
            included
            data
          }
          ecpPartition {
            included
            data
          }
          keypadInput {
            included
            data
          }
          captureFormat {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).systemOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XTSystemOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readSystemOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const systemOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "systemOptions",
      "SystemOptions"
    ) as unknown as RecordProxy<SystemOptions>;

    applyTemplateScalarDataToRecordProxy(
      systemOptionsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XTSystemOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XTSystemOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          panel {
            hardwareModel
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...SystemOptionsSystemTypeField_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
            systemOptions {
              ...SystemOptionsCIDFormatField_systemOptions
              ...SystemOptionsContextSystemType_systemOptions
              ...SystemOptionsContextHouseCode_systemOptions
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsSystemTypeField_systemOptions
              ...SystemOptionsClosingCodeField_systemOptions
              ...SystemOptionsClosingCheckField_systemOptions
              ...SystemOptionsEntryDelay1Field_systemOptions
              ...SystemOptionsEntryDelay2Field_systemOptions
              ...SystemOptionsCrossZoneTimeField_systemOptions
              ...SystemOptionsPowerFailDelayField_systemOptions
              ...SystemOptionsSwingerBypassTripsField_systemOptions
              ...SystemOptionsZoneActivityHoursField_systemOptions
              ...SystemOptionsArmDisarmActivityDaysField_systemOptions
              ...SystemOptionsResetSwingerBypassField_systemOptions
              ...SystemOptionsTimeChangeField_systemOptions
              ...SystemOptionsObserveDstField_systemOptions
              ...SystemOptionsHoursFromGMTField_systemOptions
              ...SystemOptionsDisplayTimeField_systemOptions
              ...SystemOptionsWirelessHouseCodeField_systemOptions
              ...SystemOptionsDetectWirelessJammingField_systemOptions
              ...SystemOptionsWirelessAudiblesField_systemOptions
              ...SystemOptionsUseBuiltIn1100WirelessField_systemOptions
              ...SystemOptionsEnableKeypadPanicKeysField_systemOptions
              ...SystemOptionsOccupiedPremisesField_systemOptions
              ...SystemOptionsUseFalseAlarmQuestionField_systemOptions
              ...SystemOptionsWeatherZipCodeField_systemOptions
              ...SystemOptionsIso2CountryCodeField_systemOptions
              ...SystemOptionsWeatherPostalCodeField_systemOptions
              ...SystemOptionsPrimaryProgrammingLanguageField_systemOptions
              ...SystemOptionsSecondaryProgrammingLanguageField_systemOptions
              ...SystemOptionsPrimaryUserLanguageField_systemOptions
              ...SystemOptionsSecondaryUserLanguageField_systemOptions
              ...SystemOptionsWirelessEncryption1100Field_systemOptions
              ...SystemOptionsWirelessEncryption1100PassphraseField_systemOptions
              ...SystemOptionsEolResistorValueField_systemOptions
              ...SystemOptionsCelsiusField_systemOptions
              ...SystemOptionsExitDelayField_systemOptions
              ...XT30SystemOptions_systemOptions
              ...XT50SystemOptions_systemOptions
            }
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem
        }
      `
    );

  const {
    panel: {
      systemOptions,
      hardwareModel,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <ControlSystemContextProvider controlSystem={controlSystem}>
      <PanelContextProvider panel={controlSystem.panel}>
        <SystemOptionsContextProvider systemOptions={systemOptions}>
          <ProgrammingConceptForm
            conceptId={conceptId}
            title="System Options"
            helpLink={`${programmingGuideUrl}#System%20Options`}
            initialDataIsNotEmptyOrNull={isNotNullOrUndefined(systemOptions)}
          >
            <RemountOnUpdateContainer nodeId={conceptId}>
              <ProgrammingConceptForm.Fields>
                {hardwareModel === "XT50" || hardwareModel === "XT50L" ? (
                  <XT50SystemOptions />
                ) : hardwareModel === "XT30" || hardwareModel === "XT30L" ? (
                  <XT30SystemOptions />
                ) : hardwareModel === "XTL" ||
                  hardwareModel === "XTLN" ||
                  hardwareModel === "XTLW" ? (
                  <XTLSystemOptions />
                ) : hardwareModel === "XTLP" ? (
                  <XTLPSystemOptions />
                ) : null}
              </ProgrammingConceptForm.Fields>
            </RemountOnUpdateContainer>
          </ProgrammingConceptForm>
        </SystemOptionsContextProvider>
      </PanelContextProvider>
    </ControlSystemContextProvider>
  );
}
