import * as React from "react";

const Context = React.createContext<any>(null);

export function UserServiceDAContextProvider({
  userService,
  children,
}: {
  userService: any;
  children: React.ReactNode;
}) {
  return <Context.Provider value={userService}>{children}</Context.Provider>;
}

export function useAngularUserService() {
  return React.useContext(Context);
}

export function useSetHasSignedEula() {
  const userService = useAngularUserService();
  return () => {
    userService.setHasSignedEula();
  };
}
