App.controller("CustomRolesListCtrl", [
  "$scope",
  "$rootScope",
  "UserService",
  "CustomRolesService",
  function ($scope, $rootScope, UserService, CustomRolesService) {
    $scope["roles"] = [];

    $scope.refreshRoles = function () {
      $scope.roles = [];
      CustomRolesService.refreshRoles(UserService.dealer_id)
        .then(function (roles) {
          $scope.roles = roles;
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.deleteRole = function (role) {
      CustomRolesService.deleteRole(role)
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Deleted role: " + role.name,
            });
            $scope.refreshRoles();
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to delete role",
            });
            $scope.refreshRoles();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    const roleReplacement =
      UserService.dealerInfo.vernaculars.customRoles.replacement || "role";
    $scope.getDeleteRoleMessage = function (roleName) {
      return `Deleting ${roleReplacement} ${roleName}. Are you sure?`;
    };

    function init() {
      $scope.refreshRoles();
    }

    init();
  },
]);
