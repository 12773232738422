/**
 * @generated SignedSource<<ea1265a274fe98595fcfa41fa9713624>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AllowTimeChange = "NO" | "SERVER" | "YES";
export type KeypadArmedLed = "ALL" | "ANY";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
export type WirelessEncryption1100 = "ALL" | "BOTH" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data = {
  readonly systemOptions: {
    readonly bypassLimit: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly celsius: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingWait: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly crossZoneTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly detectJam: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly enableKeypadPanicKeys: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay1: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay2: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly hoursFromGMT: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly houseCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly instantArming: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly keypadArmedLed: {
      readonly data: KeypadArmedLed | null;
      readonly included: boolean | null;
    } | null;
    readonly latchedSupervisoryZones: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly observeDst: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly occupiedPremises: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly powerFailDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly resetSwingerBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly swingerBypassTrips: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly systemType: {
      readonly data: SystemType | null;
      readonly included: boolean | null;
    } | null;
    readonly timeChange: {
      readonly data: AllowTimeChange | null;
      readonly included: boolean | null;
    } | null;
    readonly useBuiltIn1100Wireless: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly useFalseAlarmQuestion: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly weatherZipCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessAudibles: {
      readonly data: WirelessAudibles | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessEncryption1100: {
      readonly data: WirelessEncryption1100 | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessEncryption1100Passphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneActivityHours: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts";
};
export type XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "cf9f370beeb18953ceea1bc5154d3153";

export default node;
