/**
 * @generated SignedSource<<f970ab329f3b43e5059d33b4890698d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteCardFormatsSection_site$data = {
  readonly cardFormats: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly wiegandLength: number;
    readonly " $fragmentSpreads": FragmentRefs<"CardFormatFormExistingModal_cardFormat">;
  }>;
  readonly customer: {
    readonly dealer: {
      readonly vernaculars: ReadonlyArray<{
        readonly original: string;
        readonly replacement: string;
      } | null>;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatFormAddModal_site" | "CardFormatFormExistingModal_site">;
  readonly " $fragmentType": "SiteCardFormatsSection_site";
};
export type SiteCardFormatsSection_site$key = {
  readonly " $data"?: SiteCardFormatsSection_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteCardFormatsSection_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteCardFormatsSection_site",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CardFormatFormAddModal_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CardFormatFormExistingModal_site"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CardFormat",
      "kind": "LinkedField",
      "name": "cardFormats",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CardFormatFormExistingModal_cardFormat"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wiegandLength",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Dealer",
          "kind": "LinkedField",
          "name": "dealer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vernacular",
              "kind": "LinkedField",
              "name": "vernaculars",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "original",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "replacement",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "64a3c80d61c0098a21fbb026991a9ff2";

export default node;
