import DaColors from "app/common/DaColors";
import { css } from "styled-components";

export const focusRing = css`
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    ${DaColors.PrimaryBase} inset 0px 0px 0px 2px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 0px !important;
`;

// Utility function to darken HSL color
export const darkenHSL = (hslColor: string, percent: number) => {
  const [h, s, l] = hslColor.match(/\d+/g)!.map(Number);
  const newL = Math.max(0, Math.min(100, l - percent));
  return `hsl(${h}, ${s}%, ${newL}%)`;
};

// Utility function to lighten HSL color
export const lightenHSL = (hslColor: string, percent: number) => {
  const [h, s, l] = hslColor.match(/\d+/g)!.map(Number);
  const newL = Math.max(0, Math.min(100, l + percent));
  return `hsl(${h}, ${s}%, ${newL}%)`;
};

// Utility function to create disabled HSL color
// saturation and lightness can be adjusted so colors are accessibility compliant
export const disabledHSL = (
  hslColor: string,
  satReduction: number = 30,
  lightIncrease: number = 20
) => {
  const [h, s, l] = hslColor.match(/\d+/g)!.map(Number);
  const newS = Math.max(0, Math.min(100, s - satReduction));
  const newL = Math.max(0, Math.min(100, l + lightIncrease)); 
  return `hsl(${h}, ${newS}%, ${newL}%)`;
};
