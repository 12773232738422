import * as React from "react";
import PutSystemOnTestModal from "./PutSystemOnTestModal";

const PutSystemOnTestButton: React.FC<
  React.PropsWithChildren<{
    systemId: string;
    systemReplacement: string | undefined;
  }>
> = ({ systemId, systemReplacement }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <button className="btn btn-sm btn-dmp" onClick={() => setModalOpen(true)}>
        Put {systemReplacement ?? "System"} On Test
      </button>
      {modalOpen ? (
        <PutSystemOnTestModal
          systemId={systemId}
          onClose={() => setModalOpen(false)}
          systemReplacement={systemReplacement}
        />
      ) : null}
    </>
  );
};

export default PutSystemOnTestButton;
