import Flex from "common/components/web/Flex";
import {
  FieldLabelCell,
  FieldLabelContainer,
  FieldLabelText,
  FieldValueCell,
  RegionSettingsDeleteButton,
  RegionSettingsHeaderText,
  RegionsSettingsRoot,
  RegionsSettingsRow,
  RegionsSettingsTable,
  SensitivityValue,
} from "components/CameraEditCommon/CameraEditStyledComponents";
import Switch from "components/FullProgramming/common/Switch";
import Icon from "components/Icon";
import React from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  AnalyticalDetectionRegion,
  SecureComCamera,
} from "securecom-graphql/client";
import { UniviewCameraEditForm_secureComCamera$data } from "./__generated__/UniviewCameraEditForm_secureComCamera.graphql";

interface UniviewAnalyticalDetectionRegionSettingsProps {
  index: number;
  data: UniviewCameraEditForm_secureComCamera$data;
  isEditable: boolean;
  analyticalDetectionRegion: AnalyticalDetectionRegion;
}
function UniviewAnalyticalDetectionRegionSettings(
  props: UniviewAnalyticalDetectionRegionSettingsProps
) {
  const { index, data, isEditable, analyticalDetectionRegion } = props;
  const { id, sensitivity, detectPerson, detectVehicle } =
    analyticalDetectionRegion;
  const relayEnv = useRelayEnvironment();

  const showSensitivity = data.manufacturer !== "Malmoset";

  const regionDetectionTypeNotSelected = !(detectPerson || detectVehicle);
  const noDetectionRegionTypeCustomMessage = (input: HTMLInputElement) =>
    input.validity.valueMissing
      ? "At least one detection type must be selected"
      : "";

  return (
    <RegionsSettingsRoot>
      <RegionsSettingsTable>
        <thead>
          <RegionsSettingsRow>
            <th colSpan={2}>
              <Flex alignItems="center" justifyContent="space-between">
                <RegionSettingsHeaderText
                  index={index}
                  onMouseEnter={() => {
                    // brings the region to the top of the pile
                    relayEnv.commitUpdate((store) => {
                      const camera = store.get(
                        data.id
                      ) as RecordProxy<SecureComCamera>;
                      if (camera && isEditable) {
                        camera
                          .getLinkedRecord("analyticalDetectionRegions")
                          .setValue(index, "activeDetectionRegionIndex");
                      }
                    });
                  }}
                >
                  Region #{index + 1}
                </RegionSettingsHeaderText>
                {isEditable && (
                  <RegionSettingsDeleteButton
                    type="button"
                    onClick={() => {
                      relayEnv.commitUpdate((store) => {
                        const camera = store.get(
                          data.id
                        ) as RecordProxy<SecureComCamera>;
                        const analyticalDetectionRegions =
                          camera.getLinkedRecord("analyticalDetectionRegions");
                        const regions = analyticalDetectionRegions
                          .getLinkedRecords("regions")
                          .filter((region) => region.getValue("id") !== id);

                        regions.forEach((region, index) =>
                          region.setValue(index, "index")
                        ); // Resetting index for regions
                        analyticalDetectionRegions.setLinkedRecords(
                          regions,
                          "regions"
                        );
                        analyticalDetectionRegions.setValue(
                          null,
                          "activeDetectionRegionIndex"
                        );
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </RegionSettingsDeleteButton>
                )}
              </Flex>
            </th>
          </RegionsSettingsRow>
        </thead>
        <tbody>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-region-${index}-sensitivity`}
                >
                  Sensitivity
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <input
                id={`detection-region-${index}-sensitivity`}
                name={`detection-region-${index}-sensitivity`}
                type="range"
                min="1"
                max="100"
                value={sensitivity}
                onChange={(event) => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<SecureComCamera>;
                    camera
                      .getLinkedRecord("analyticalDetectionRegions")
                      .getLinkedRecords("regions")
                      [index].setValue(
                        Number(event.target.value),
                        "sensitivity"
                      );
                  });
                }}
              />
              <SensitivityValue>{sensitivity}%</SensitivityValue>
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-region-${index}-detect-people`}
                >
                  Detect People
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-region-${index}-detect-people`}
                label="Detect People"
                required={regionDetectionTypeNotSelected}
                checked={detectPerson}
                onChange={() => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<SecureComCamera>;
                    camera
                      .getLinkedRecord("analyticalDetectionRegions")
                      .getLinkedRecords("regions")
                      [index].setValue(!detectPerson, "detectPerson");
                  });
                }}
                getValidationMessage={noDetectionRegionTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-region-${index}-detect-vehicles`}
                >
                  Detect Vehicles
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-region-${index}-detect-vehicles`}
                label="Detect Vehicles"
                required={regionDetectionTypeNotSelected}
                checked={detectVehicle}
                onChange={() => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<SecureComCamera>;
                    camera
                      .getLinkedRecord("analyticalDetectionRegions")
                      .getLinkedRecords("regions")
                      [index].setValue(!detectVehicle, "detectVehicle");
                  });
                }}
                getValidationMessage={noDetectionRegionTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
        </tbody>
      </RegionsSettingsTable>
    </RegionsSettingsRoot>
  );
}

export default UniviewAnalyticalDetectionRegionSettings;
