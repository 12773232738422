import graphql from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment, useMutation } from "react-relay/hooks";
import { AreaArmedStatusEnum, BadZonesOption } from "securecom-graphql/client";
import styled, { css } from "styled-components";
import { CUBIC_BEZIER } from "../../../constants";
import { fadeInOut, noSelection } from "../../../utils/styles";
import { ArmedShieldSolidIcon, ArmingShieldIcon } from "../Icons";
import LoadingSpinner from "../LoadingSpinner";
import VisuallyHidden from "../VisuallyHidden";
import messages from "./messages";
import { ArmingSliderArmMutation } from "./__generated__/ArmingSliderArmMutation.graphql";
import { ArmingSliderDisarmMutation } from "./__generated__/ArmingSliderDisarmMutation.graphql";
import { ArmingSlider_area$key } from "./__generated__/ArmingSlider_area.graphql";

const height = 2.5; // em
const handleSize = 3; // em
const animationDuration = 0.4; // s

type ArmingSliderProps = {
  className?: string;
  area: ArmingSlider_area$key;
  isPending: boolean;
  canArm?: boolean; // Allow Arming from custom role page
  canDisarm?: boolean; // Allow Disarming from custom role page
  setBadZonesMethod: Function;
};

const ArmingSlider = ({
  className,
  area,
  isPending,
  canArm,
  canDisarm,
  setBadZonesMethod,
}: ArmingSliderProps) => {
  const data = useFragment(
    graphql`
      fragment ArmingSlider_area on Area {
        id
        armedStatus
        isInAlarm
        isArmable
        isDisarmable
      }
    `,
    area
  );

  const [disarmArea, isDisarming] =
    useMutation<ArmingSliderDisarmMutation>(graphql`
      mutation ArmingSliderDisarmMutation($areaId: ID!) {
        disarmArea(areaId: $areaId) {
          ... on MultipleAreasDisarmedSuccessPayload {
            controlSystem {
              isInAlarm
              ...AreaArming_controlSystem
              areas(sort: { keys: ["number"], order: ASC }) {
                nodes {
                  armedStatus
                  ...Area_area
                }
              }
              armedStatus
            }
          }
          ... on Error {
            type
          }
        }
      }
    `);

  const [armArea, isArming] = useMutation<ArmingSliderArmMutation>(graphql`
    mutation ArmingSliderArmMutation(
      $areaId: ID!
      $badZonesOption: BadZonesOption
    ) {
      armArea(areaId: $areaId, badZonesOption: $badZonesOption) {
        ... on MultipleAreasArmedSuccessPayload {
          controlSystem {
            isInAlarm
            ...AreaArming_controlSystem
            areas(sort: { keys: ["number"], order: ASC }) {
              nodes {
                armedStatus
                ...Area_area
              }
            }
            armedStatus
          }
        }
        ... on BadZonesError {
          __typename
          badZones {
            name
            number
          }
        }
        ... on Error {
          __typename
          type
        }
      }
    }
  `);
  const intl = useIntl();
  const [hover, setHover] = useState(false);
  const pending = isArming || isDisarming || isPending;
  const isDisabled =
    (data.armedStatus === AreaArmedStatusEnum.ARMED && !data.isDisarmable) ||
    (!(data.armedStatus === AreaArmedStatusEnum.ARMED) && !data.isArmable) ||
    (data.armedStatus === AreaArmedStatusEnum.DISARMED && !canArm) ||
    (data.armedStatus === AreaArmedStatusEnum.ARMED && !canDisarm);
  const onLabel = () => {
    if (pending) {
      return data.armedStatus === AreaArmedStatusEnum.ARMED ? (
        <FormattedMessage {...messages.disarming} />
      ) : (
        <FormattedMessage {...messages.arming} />
      );
    }

    if (hover && !isDisabled) {
      return <FormattedMessage {...messages.disarm} />;
    }

    if (data.isInAlarm) {
      return <FormattedMessage {...messages.alarm} />;
    }

    return <FormattedMessage {...messages.armed} />;
  };

  const offLabel = () => {
    return hover && !isDisabled ? (
      <FormattedMessage {...messages.arm} />
    ) : (
      <FormattedMessage {...messages.disarmed} />
    );
  };

  const isSwitched = () => {
    return pending || data.armedStatus === AreaArmedStatusEnum.ARMED;
  };

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const handleClick = (event: { preventDefault: () => void }) => {
    // Chrome currently has a bug that scrolls the page when you click
    event.preventDefault();
    if (!isDisabled) {
      if (data.armedStatus === AreaArmedStatusEnum.ARMED) {
        disarmArea({ variables: { areaId: data.id } });
      } else {
        armArea({
          variables: { areaId: data.id },
          onCompleted: (response) => {
            if (response?.armArea?.badZones) {
              setBadZonesMethod({
                badZones: response.armArea.badZones,
                executeMutation: (badZonesOption: BadZonesOption) => {
                  armArea({
                    variables: {
                      areaId: data.id,
                      badZonesOption,
                    },
                  });
                },
              });
            }
          },
        });
      }
    }
  };

  return (
    <Wrapper className={className}>
      <VisuallyHidden>
        <input
          type="checkbox"
          id={data.id}
          checked={isSwitched()}
          disabled={isDisabled}
        />
      </VisuallyHidden>
      <ActionArea
        htmlFor={data.id}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        disabled={isDisabled}
        pending={false}
      >
        <Track
          active={pending || data.armedStatus === AreaArmedStatusEnum.ARMED}
          burgAlarm={data.isInAlarm}
        >
          <TrackInner>
            <OffLabel
              pending={pending}
              on={data.armedStatus === AreaArmedStatusEnum.ARMED}
            >
              {offLabel()}
            </OffLabel>
            <OnLabel on={isSwitched()}>
              <OnLabelText
                burgAlarm={data.isInAlarm}
                hover={hover}
                pending={pending}
              >
                {onLabel()}
              </OnLabelText>
            </OnLabel>
          </TrackInner>
        </Track>
        <Handle
          title={intl.formatMessage(
            data.armedStatus === AreaArmedStatusEnum.ARMED
              ? messages.disarmed
              : messages.armed
          )}
          on={isSwitched()}
        >
          <HandleIcon
            pending={pending}
            on={data.armedStatus === AreaArmedStatusEnum.ARMED}
            burgAlarm={data.isInAlarm}
          >
            {pending && <LoadingSpinner />}
            {!pending && data.armedStatus === AreaArmedStatusEnum.ARMED && (
              <ArmedShieldSolidIcon />
            )}
            {!pending && !(data.armedStatus === AreaArmedStatusEnum.ARMED) && (
              <ArmingShieldIcon />
            )}
          </HandleIcon>
        </Handle>
      </ActionArea>
    </Wrapper>
  );
};

export default ArmingSlider;

const Wrapper = styled.div`
  height: ${height}em;
  font-size: 13px;
`;
const ActionArea = styled.label<{ disabled: boolean; pending: boolean }>`
  display: block;
  position: relative;
  border-radius: ${height / 2}em;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `};
  ${({ pending, disabled }) =>
    !pending &&
    !disabled &&
    css`
      cursor: pointer;
    `};
`;
const Track = styled.div<{ active: boolean; burgAlarm: boolean }>`
  height: ${height}em;
  border-radius: ${height / 2}em;
  z-index: 2;
  box-shadow: 0 0 0 1px ${(props) => props.theme.grayAccent};
  background: ${({ active, burgAlarm, theme }) => {
    if (active && burgAlarm) {
      return theme.danger;
    }
    if (active) {
      return theme.primary;
    }
    return theme.sliderBackground;
  }};
  transition: 0.35s background;
`;
const TrackInner = styled.div`
  position: relative;
  height: ${height}em;
  border-radius: ${height / 2}em;
  overflow: hidden;
`;
const Label = styled.div`
  ${noSelection};
  position: absolute;
  display: flex;
  align-items: center;
  top: 0px;
  left: 0px;
  height: ${height}em;
  width: 100%;
  margin: 0;
  padding: 0 1.25em;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  white-space: pre;
`;
const OffLabel = styled(Label)<{ pending: boolean; on: boolean }>`
  padding-left: ${handleSize + 0.25}em;
  color: ${({ theme }) => theme.textColor};
  z-index: 0;
  opacity: ${({ pending, on }) => (pending || on ? 0 : 1)};
  transition: 0.35s opacity;
`;
const OnLabel = styled(Label)<{ on: boolean }>`
  position: absolute;
  left: 0px;
  padding-right: ${handleSize + 0.25}em;
  overflow: hidden;
  color: ${({ theme }) => theme.trueWhite};
  transform: translateX(calc(-100% + ${handleSize / 2}em));
  transition: transform ${animationDuration}s ${CUBIC_BEZIER};
  z-index: 1;
  ${({ on }) =>
    on &&
    css`
      transform: translateX(0);
    `};
`;

const OnLabelText = styled.span<{
  burgAlarm: boolean;
  hover: boolean;
  pending: boolean;
}>`
  display: block
    ${({ burgAlarm, hover, pending }) =>
      burgAlarm &&
      !hover &&
      !pending &&
      css`
        animation: ${fadeInOut} 2s linear infinite;
      `};
`;
const Handle = styled.i<{ on: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -${(handleSize - height) / 2}em;
  left: -${(handleSize - height) / 2}em;
  width: ${handleSize}em;
  height: ${handleSize}em;
  border-radius: 50%;
  border: 1px solid rgb(224, 224, 224);
  overflow: hidden;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  background: white;
  transition: left ${animationDuration}s ${CUBIC_BEZIER};
  z-index: 3;
  ${({ on }) =>
    on &&
    css`
      left: calc(100% - ${handleSize - (handleSize - height) / 2}em);
    `};
`;
const HandleIcon = styled.span<{
  pending: boolean;
  on: boolean;
  burgAlarm: boolean;
}>`
  color: ${({ theme }) => theme.grayMedium};
  font-size: 1.5em;
  line-height: 1;
  ${({ pending, on, burgAlarm, theme }) =>
    (pending || on) &&
    css`
      color: ${burgAlarm ? theme.danger : theme.primary};
    `};
`;
