import React, { forwardRef, InputHTMLAttributes, MouseEvent } from "react";
import styled, { css } from "styled-components/macro";
import { themeGrayAccent } from "../Theme";
import VisuallyHidden from "../VisuallyHidden";

export type SwitchProps = {
  id: string;
  checked?: boolean;
  small?: boolean;
  disabled?: boolean;
  color?: string;
  onMouseEnter?: (event: MouseEvent<HTMLLabelElement>) => void;
  onMouseLeave?: (event: MouseEvent<HTMLLabelElement>) => void;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onMouseEnter" | "onMouseLeave"
>;

const Switch = forwardRef(
  (
    {
      id,
      small = false,
      checked = false,
      disabled = false,
      color,
      onMouseEnter,
      onMouseLeave,
      ...rest
    }: SwitchProps,
    ref
  ) => {
    return (
      <Wrapper
        ref={ref as any}
        htmlFor={id}
        small={small}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <VisuallyHidden>
          <input
            {...rest}
            type="checkbox"
            id={id}
            checked={checked}
            disabled={disabled}
          />
        </VisuallyHidden>
        <Indicator checked={checked} color={color} disabled={disabled} />
      </Wrapper>
    );
  }
);

export default Switch;

const width = 3.125; // em
const height = 1.875; // em
const borderRadius = height / 2; // em
const borderWidth = 2;

const Wrapper = styled.label<{ disabled?: boolean; small?: boolean }>`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: ${({ small }) => (small ? 0.8 : 1)}em;
`;
const Indicator = styled.i<{
  disabled?: boolean;
  color?: string;
  checked: boolean;
}>`
  display: block;
  position: relative;
  width: ${width}em;
  height: ${height}em;
  border-radius: ${borderRadius}em;
  color: ${themeGrayAccent};
  border: ${borderWidth}px solid;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -${borderWidth}px;
    left: -${borderWidth}px;
    right: -${borderWidth}px;
    bottom: -${borderWidth}px;
    border-radius: inherit;
    transform: scale(0);
    background: ${({ color, theme }) => color || theme.primary500};
    opacity: 0;
    transition: all 300ms;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: ${height}em;
    height: ${height}em;
    transform: translate(-8%, -50%) scale(0.75);
    border-radius: 50%;
    background: currentColor;
    transition: all 300ms;
  }

  ${({ checked, color }) =>
    checked &&
    css`
      border-color: ${color};

      &::before {
        opacity: 0.9;
        transform: scale(1);
      }

      &::after {
        transform: translate(57%, -50%) scale(0.75);
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      }
    `};
`;
