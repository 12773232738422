import graphql from "babel-plugin-relay/macro";
import { MessagePosition } from "common/components/web/Tooltip";
import {
  isNotNullOrUndefined,
  isNullOrUndefined,
} from "common/utils/universal/function";
import noop from "common/utils/universal/noop";
import InlineTooltip from "components/InlineTooltip";
import RelayEnvironmentCloneProvider from "components/RelayEnvironmentCloneProvider";
import {
  useCanSaveBillingTags,
  useHasCustomRole,
  useTagsAreSaving,
} from "components/SiteForm/EntryPointContext";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useEffect, useRef, useState } from "react";
import {
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import Select, {
  ActionMeta,
  components,
  OptionsType,
  OptionTypeBase,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { commitLocalUpdate, RecordProxy } from "relay-runtime";
import {
  idAsString,
  Tag as TagProxy,
  toControlSystemId,
  toCustomerId,
  toDealerId,
  toGlobalId,
  toSiteId,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import { TagFieldAddTagMutation } from "./__generated__/TagFieldAddTagMutation.graphql";
import { TagFieldCreateTagMutation } from "./__generated__/TagFieldCreateTagMutation.graphql";
import { TagFieldQuery } from "./__generated__/TagFieldQuery.graphql";
import { TagFieldRemoveTagMutation } from "./__generated__/TagFieldRemoveTagMutation.graphql";
import { TagFieldSyncBillingMutation } from "./__generated__/TagFieldSyncBillingMutation.graphql";
import { TagFieldValueQuery } from "./__generated__/TagFieldValueQuery.graphql";
import { sleep } from "common/utils";
export let handleSavingTags: (arg0: boolean, arg1: number) => void;

interface Tag {
  readonly id: number;
  readonly label: string;
  readonly value: string;
  readonly billingDealerId: number;
}
interface TagFieldProps {
  customerId?: string;
  dealerId: number;
  isEditable: boolean;
  type: string;
  typeId: number;
  setTagsAreSaving: (arg0: boolean) => void;
  setCanSaveBillingTags: (arg0: boolean) => void;
  handleCanSaveBillingTags?: (arg0: boolean) => void;
  runCheckBillingTag?: (arg0: string | undefined) => void;
  isSecurityCommand?: boolean;
  labelColumns?: number;
  inputColumns?: number;
  isSitesModal?: boolean;
  isNewSite?: boolean;
  UserService?: any;
  ControlSystemsService?: any;
}

interface TagChangeInput {
  encodedTagId?: string;
  dealerId?: string;
  name?: string;
  tagType: string;
  typeId: number;
}
function TagField(props: TagFieldProps) {
  const {
    customerId,
    dealerId,
    isEditable,
    type,
    typeId,
    setTagsAreSaving,
    setCanSaveBillingTags,
    handleCanSaveBillingTags,
    runCheckBillingTag,
    isSecurityCommand,
    labelColumns,
    inputColumns,
    isSitesModal,
    isNewSite,
    UserService,
    ControlSystemsService,
  } = props;
  let encodedTypeId: string;
  const customRoleId = UserService.user?.customRole?.id
    ? toGlobalId("CustomRoles", UserService.user?.customRole.id)
    : "";
  const panelsCustomerId = // Need to get the customer tags that are non optional for new panels so that we can add them to the list of tags shown.
    !typeId && type === "Panels"
      ? idAsString(toCustomerId(UserService.customer_id))
      : !typeId && type === "Sites" && customerId
      ? idAsString(toCustomerId(customerId))
      : "";

  switch (type) {
    case "Panels":
      encodedTypeId =
        !typeId && customRoleId
          ? idAsString(customRoleId)
          : idAsString(toControlSystemId(typeId));
      break;
    case "Customers":
      encodedTypeId = idAsString(toCustomerId(typeId));
      break;
    case "CustomRoles":
      encodedTypeId = idAsString(toGlobalId(type, typeId));
      break;
    case "Sites":
      encodedTypeId =
        !typeId && customRoleId
          ? idAsString(customRoleId)
          : customerId
          ? idAsString(toSiteId(customerId, typeId))
          : "";
      break;
    default:
      encodedTypeId = "";
  }

  const typeSupportsBillingTags =
    isSecurityCommand && ["Panels", "CustomRoles", "Sites"].includes(type);

  const setSaveBillingTagsFromContext = useCanSaveBillingTags();
  const setTagsAreSavingFromContext = useTagsAreSaving();
  const userHasCustomRole =
    type === "Sites" ? useHasCustomRole() : UserService.userHasCustomRole();

  const data = useLazyLoadQuery<TagFieldQuery>(
    graphql`
      query TagFieldQuery($dealerId: ID!) {
        dealer: node(id: $dealerId) {
          ... on Dealer {
            tags {
              id
              label
              value
              billingDealerId
            }
          }
        }
      }
    `,
    { dealerId: idAsString(toDealerId(dealerId)) }
  );
  const tagFieldValue = useLazyLoadQuery<TagFieldValueQuery>(
    graphql`
      query TagFieldValueQuery(
        $typeId: ID!
        $getCustomerTags: Boolean!
        $customerId: ID!
      ) {
        getTagsByType(id: $typeId) {
          tags {
            id
            isFixed
            label
            value
            billingDealerId
          }
        }
        customerRequiredTags: getTagsByType(id: $customerId)
          @skip(if: $getCustomerTags) {
          tags {
            id
            isFixed
            label
            value
            billingDealerId
          }
        }
      }
    `,
    {
      typeId: encodedTypeId,
      getCustomerTags: !panelsCustomerId,
      customerId: panelsCustomerId,
    },
    { fetchPolicy: "network-only" }
  );

  const [syncBilling, isSyncingBilling] =
    useMutation<TagFieldSyncBillingMutation>(syncBillingMutation);

  const [addTag, isAddingTag] =
    useMutation<TagFieldAddTagMutation>(addTagMutation);
  const [removeTag, isRemovingTag] =
    useMutation<TagFieldRemoveTagMutation>(removeTagMutation);
  const [createTag, isCreatingTag] =
    useMutation<TagFieldCreateTagMutation>(createTagMutation);
  const isLoading = isAddingTag || isRemovingTag || isCreatingTag;
  const isSystemType = type === "Panels" || type === "Sites";
  const newItemTagsListRef = useRef(new Map<string, TagChangeInput>());
  const [newItemTagsListRefUpdated, setNewItemTagsListRefUpdated] = useState(0);
  const [billingTagRefUpdated, setBillingTagRefUpdated] = useState(0);
  const [, setNewItemTagsListRefCopy] = useState(new Map());
  const currentBillingId = useRef(
    tagFieldValue.getTagsByType?.tags.find(
      (tag) => tag?.billingDealerId !== null
    )?.id
  );

  const relayEnv = useRelayEnvironment();
  const showAlert = useShowAlert();

  if (isSecurityCommand && ["Sites"].includes(type)) {
    setSaveBillingTagsFromContext(
      isNotNullOrUndefined(currentBillingId.current)
    );
    handleCanSaveBillingTags
      ? handleCanSaveBillingTags(isNotNullOrUndefined(currentBillingId.current))
      : noop;
  } else if (isSecurityCommand && ["Panels"].includes(type)) {
    setCanSaveBillingTags(isNotNullOrUndefined(currentBillingId.current));
  }

  useEffect(() => {
    // Make a copy of newItemTagsListRef to make sure data is not stale
    setNewItemTagsListRefCopy(new Map(newItemTagsListRef.current));
  }, [newItemTagsListRefUpdated, billingTagRefUpdated]);

  useEffect(() => {
    // we need to notify the custom roles directive if a billing tag is selected
    runCheckBillingTag && runCheckBillingTag(currentBillingId.current);
  }, []);

  const handleOnBillingChange = (selectedTag: OptionTypeBase | null) => {
    const tagInput = {
      encodedTagId: selectedTag?.id,
      tagType: type,
      typeId: typeId ? typeId : 0,
    };
    setShowError(false);
    if (!typeId) {
      newItemTagsListRef.current.delete(currentBillingId.current ?? "");
      commitLocalUpdate(relayEnv, (store) => {
        const tagQueryResults = store
          .getRoot()
          .getLinkedRecord("getTagsByType", { id: encodedTypeId });
        let usedTags = tagQueryResults?.getLinkedRecords("tags") || [];

        usedTags = usedTags.filter(
          (tagProxy) => tagProxy.getValue("id") !== currentBillingId.current
        );
        tagQueryResults?.setLinkedRecords(usedTags, "tags");
      });
      if (selectedTag) {
        newItemTagsListRef.current.set(selectedTag?.id, tagInput);
        // Trigger the useEffect for tags list
        setNewItemTagsListRefUpdated((prev) => prev + 1);
      }
      currentBillingId.current = selectedTag?.id;
      // disable Reports and Analytics if billing tag is selected
      runCheckBillingTag && runCheckBillingTag(currentBillingId.current);
    } else {
      if (currentBillingId.current) {
        removeTag({
          variables: { ...tagInput, encodedTagId: currentBillingId.current },
          updater: (store, results) => {
            if (results.removeTag.status === "SUCCESS") {
              const tagQueryResults = store
                .getRoot()
                .getLinkedRecord("getTagsByType", { id: encodedTypeId });
              let usedTags = tagQueryResults?.getLinkedRecords("tags") || [];

              usedTags = usedTags.filter(
                (tagProxy) =>
                  tagProxy.getValue("id") !== results.removeTag.removedTagId
              );
              tagQueryResults?.setLinkedRecords(usedTags, "tags");
            }
          },
          onCompleted: () => {
            currentBillingId.current = selectedTag?.id;
            // disable Reports and Analytics if billing tag is selected
            runCheckBillingTag && runCheckBillingTag(currentBillingId.current);
            setBillingTagRefUpdated((prev) => prev + 1);
          },
        });
      }
      addTag({
        variables: tagInput,
        updater: (store, results) => {
          if (
            results.addTag.status === "SUCCESS" &&
            results.addTag.addedTagId
          ) {
            const tagQueryResults = store
              .getRoot()
              .getLinkedRecord("getTagsByType", { id: encodedTypeId });
            const usedTags = tagQueryResults?.getLinkedRecords("tags") || [];
            const addedTag = store.get(
              results.addTag.addedTagId
            ) as RecordProxy<TagProxy>;

            usedTags.push(addedTag);
            tagQueryResults?.setLinkedRecords(usedTags, "tags");
          }
        },
        onCompleted: () => {
          if (["Panels"].includes(type)) {
            // force updates the scapi billing on control systems so that it can be up to date with the latest billing tag
            syncBilling({
              variables: {
                systemId: idAsString(toControlSystemId(tagInput.typeId)),
              },
            });
            ControlSystemsService.modifySIM();
          }
          currentBillingId.current = selectedTag?.id;
          // disable Reports and Analytics if billing tag is selected
          runCheckBillingTag && runCheckBillingTag(currentBillingId.current);
          setBillingTagRefUpdated((prev) => prev + 1);
        },
      });
    }
  };

  const handleOnChange = (
    _value: OptionsType<OptionTypeBase>,
    actionMeta: ActionMeta<OptionTypeBase>
  ) => {
    switch (actionMeta.action) {
      case "select-option":
        if (actionMeta.option?.id) {
          const tagInput = {
            encodedTagId: actionMeta.option.id,
            tagType: type,
            typeId: typeId ? typeId : 0,
          };
          if (!typeId) {
            newItemTagsListRef.current.set(actionMeta.option.id, tagInput);
            // Trigger the useEffect for tags list
            setNewItemTagsListRefUpdated((prev) => prev + 1);
            commitLocalUpdate(relayEnv, (store) => {
              const tagQueryResults = store
                .getRoot()
                .getLinkedRecord("getTagsByType", { id: encodedTypeId });
              const usedTags = tagQueryResults?.getLinkedRecords("tags") || [];
              const addedTag = store.get(actionMeta.option?.id);

              if (addedTag) usedTags.push(addedTag);
              tagQueryResults?.setLinkedRecords(usedTags, "tags");
            });
          } else {
            addTag({
              variables: tagInput,
              updater: (store, results) => {
                if (
                  results.addTag.status === "SUCCESS" &&
                  results.addTag.addedTagId
                ) {
                  const tagQueryResults = store
                    .getRoot()
                    .getLinkedRecord("getTagsByType", { id: encodedTypeId });
                  const usedTags =
                    tagQueryResults?.getLinkedRecords("tags") || [];
                  const addedTag = store.get(
                    results.addTag.addedTagId
                  ) as RecordProxy<TagProxy>;

                  usedTags.push(addedTag);
                  tagQueryResults?.setLinkedRecords(usedTags, "tags");
                }
              },
            });
          }
        }
        break;
      case "remove-value":
        if (actionMeta.removedValue?.id) {
          const tagInput = {
            encodedTagId: actionMeta.removedValue.id,
            tagType: type,
            typeId: typeId,
          };
          if (!typeId) {
            newItemTagsListRef.current.delete(actionMeta.removedValue.id);
            commitLocalUpdate(relayEnv, (store) => {
              const tagQueryResults = store
                .getRoot()
                .getLinkedRecord("getTagsByType", { id: encodedTypeId });
              let usedTags = tagQueryResults?.getLinkedRecords("tags") || [];

              usedTags = usedTags.filter(
                (tagProxy) =>
                  tagProxy.getValue("id") !== actionMeta.removedValue.id
              );
              tagQueryResults?.setLinkedRecords(usedTags, "tags");
            });
          } else {
            removeTag({
              variables: tagInput,
              updater: (store, results) => {
                if (results.removeTag.status === "SUCCESS") {
                  const tagQueryResults = store
                    .getRoot()
                    .getLinkedRecord("getTagsByType", { id: encodedTypeId });
                  let usedTags =
                    tagQueryResults?.getLinkedRecords("tags") || [];

                  usedTags = usedTags.filter(
                    (tagProxy) =>
                      tagProxy.getValue("id") !== results.removeTag.removedTagId
                  );
                  tagQueryResults?.setLinkedRecords(usedTags, "tags");
                }
              },
            });
          }
        }
        break;
    }
  };
  const handleOnCreate = (inputValue: string) => {
    const tagInput = {
      dealerId: dealerId.toString(),
      name: inputValue,
      tagType: type,
      typeId: typeId ? typeId : 0,
    };
    createTag({
      variables: tagInput,
      updater: (store, results) => {
        if (
          results.createTag.status === "SUCCESS" &&
          results.createTag.newTagId
        ) {
          const tagQueryResults = store
            .getRoot()
            .getLinkedRecord("getTagsByType", { id: encodedTypeId });
          const usedTags = tagQueryResults?.getLinkedRecords("tags") || [];
          const createdTag = store.get(
            results.createTag.newTagId
          ) as RecordProxy<TagProxy>;

          usedTags.push(createdTag);
          tagQueryResults?.setLinkedRecords(usedTags, "tags");
          if (!typeId) {
            const newTagInput = {
              encodedTagId: results.createTag.newTagId,
              tagType: type,
              typeId: typeId,
            };
            newItemTagsListRef.current.set(
              results.createTag.newTagId,
              newTagInput
            );
            // Trigger the useEffect for tags list
            setNewItemTagsListRefUpdated((prev) => prev + 1);
          }
        }
      },
    });
  };

  const customTagStyles = {
    menu: (provided: any) => ({ ...provided, zIndex: 5 }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "black",
      padding: 5,
    }),
    multiValue: (baseStyles: any, state: any) => {
      return state.data.isFixed && isSystemType
        ? { ...baseStyles, backgroundColor: "gray" }
        : { ...baseStyles, display: "flex" };
    },
    multiValueLabel: (baseStyles: any, state: any) => {
      return state.data.isFixed && isSystemType
        ? {
            ...baseStyles,
            fontWeight: "bold",
            fontSize: "100%",
            color: "white",
            paddingRight: 6,
          }
        : { ...baseStyles, fontSize: "100%" };
    },
    multiValueRemove: (baseStyles: any, state: any) => {
      return state.data.isFixed && isSystemType
        ? { ...baseStyles, color: "white" }
        : baseStyles;
    },
  };

  const customBillingTagStyles = {
    menu: (provided: any) => ({ ...provided, zIndex: 5 }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "black",
      padding: 5,
    }),
    control: (base: { borderColor: string }) => ({
      ...base,
      borderColor: showError ? "var(--color-danger-500)" : base.borderColor,
      "&:hover": {
        borderColor: showError ? "var(--color-danger-500)" : base.borderColor,
      },
    }),
  };

  const Input = (props: any) => <components.Input {...props} maxLength={30} />;
  const MultiValueRemove = (props: any) => {
    return (
      <>
        {props.data.isFixed && isSystemType ? (
          <RemoveIconContainer>
            <InlineTooltip
              message="This tag is applied to the customer and cannot be removed."
              position={MessagePosition.Top}
              icon={RemoveIcon()}
            />
          </RemoveIconContainer>
        ) : (
          <components.MultiValueRemove {...props} />
        )}
      </>
    );
  };
  const NoOptionsMessage = (props: any) => (
    <components.NoOptionsMessage {...props}>
      No Tags
    </components.NoOptionsMessage>
  );

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <svg width="20" height="20" viewBox="0 0 24 24" fill="black">
        <path d="M7 10l5 5 5-5z" />
      </svg>
    </components.DropdownIndicator>
  );

  handleSavingTags = (savingTags: boolean, newTypeId: number) => {
    if (isNotNullOrUndefined(savingTags) && (!!typeId || !!newTypeId)) {
      if (newItemTagsListRef.current.size === 0) {
        const billingTagInput = {
          encodedTagId: currentBillingId.current || "",
          tagType: type,
          typeId: newTypeId ? newTypeId : typeId,
        };
        addTag({
          variables: billingTagInput,
          updater: (store, results) => {
            if (
              results.addTag.status === "SUCCESS" &&
              results.addTag.addedTagId
            ) {
              const tagQueryResults = store
                .getRoot()
                .getLinkedRecord("getTagsByType", { id: encodedTypeId });
              const usedTags = tagQueryResults?.getLinkedRecords("tags") || [];
              const addedTag = store.get(
                results.addTag.addedTagId
              ) as RecordProxy<TagProxy>;

              usedTags.push(addedTag);
              if (["Panels"].includes(type)) {
                // force updates the scapi billing on control systems so that it can be up to date with the latest billing tag
                syncBilling({
                  variables: {
                    systemId: idAsString(
                      toControlSystemId(newTypeId ? newTypeId : typeId)
                    ),
                  },
                });
                ControlSystemsService.modifySIM();
              }
              tagQueryResults?.setLinkedRecords(usedTags, "tags");
            }
          },
          onError: (error) => {
            showAlert({
              type: "error",
              text: `Error Applying Tag to Item.  Please edit Item and reapply Tag`,
            });
          },
        });
      } else {
        newItemTagsListRef.current.forEach(async (tag) => {
          // Using a sleep timer here to avoid a duplicate call that causes a 500 Error
          await sleep(250);
          const tagInput = {
            encodedTagId: tag.encodedTagId ? tag.encodedTagId : "",
            tagType: type,
            typeId: newTypeId ? newTypeId : typeId,
          };
          addTag({
            variables: tagInput,
            updater: (store, results) => {
              if (
                results.addTag.status === "SUCCESS" &&
                results.addTag.addedTagId
              ) {
                const tagQueryResults = store
                  .getRoot()
                  .getLinkedRecord("getTagsByType", { id: encodedTypeId });
                const usedTags =
                  tagQueryResults?.getLinkedRecords("tags") || [];
                const addedTag = store.get(
                  results.addTag.addedTagId
                ) as RecordProxy<TagProxy>;

                usedTags.push(addedTag);
                tagQueryResults?.setLinkedRecords(usedTags, "tags");
                if (
                  ["Panels"].includes(type) &&
                  tagInput.encodedTagId === currentBillingId.current
                ) {
                  // force updates the scapi billing on control systems so that it can be up to date with the latest billing tag
                  syncBilling({
                    variables: {
                      systemId: idAsString(
                        toControlSystemId(newTypeId ? newTypeId : typeId)
                      ),
                    },
                  });
                  ControlSystemsService.modifySIM();
                }
              }
            },
            onError: (error) => {
              showAlert({
                type: "error",
                text: `Error Applying Tag to Item.  Please edit Item and reapply Tag`,
              });
            },
          });
        });
      }
      // Odata is not saving the tag fast enough so we need a small wait
      setTimeout(() => {
        if (isSecurityCommand && ["Sites"].includes(type)) {
          setTagsAreSavingFromContext(false);
        } else {
          setTagsAreSaving(false);
        }
      }, 1000);
    }
  };

  const [showError, setShowError] = useState(false);
  const handleBlur = () => {
    if (isNullOrUndefined(currentBillingId.current)) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  return (
    <RelayEnvironmentCloneProvider>
      <div className="col-sm-12">
        {typeSupportsBillingTags ? (
          <div className="form-group">
            <label
              className={
                labelColumns
                  ? `col-sm-${labelColumns} control-label`
                  : `col-sm-3 control-label`
              }
              style={{
                marginLeft:
                  isSitesModal && !isNewSite
                    ? "-15px"
                    : !isSitesModal && isNewSite
                    ? "90px"
                    : "0px",
              }}
            >
              Bill To
              {type !== "CustomRoles" ? (
                <span style={{ color: "var(--color-danger-500)" }}>*</span>
              ) : (
                <></>
              )}
            </label>
            <div
              className={inputColumns ? `col-sm-${inputColumns}` : `col-sm-9`}
              style={{
                marginBottom: isNewSite ? "10px" : "0px",
                marginLeft:
                  isSitesModal && !isNewSite
                    ? "7px"
                    : !isSitesModal && isNewSite
                    ? "-97px"
                    : "0px",
              }}
            >
              <Select
                placeholder="Select a Dealer"
                components={{ Input, NoOptionsMessage, DropdownIndicator }}
                isClearable={type === "CustomRoles" ? true : false}
                isDisabled={!isEditable || isLoading || (userHasCustomRole && isNotNullOrUndefined(currentBillingId.current))}
                isLoading={isLoading}
                menuPlacement="auto"
                onBlur={type !== "CustomRoles" ? handleBlur : () => {}}
                onChange={handleOnBillingChange}
                options={
                  data.dealer?.tags?.filter((tag) =>
                    isNotNullOrUndefined(tag?.billingDealerId)
                  ) as unknown as Tag[]
                }
                styles={customBillingTagStyles}
                value={
                  tagFieldValue.getTagsByType?.tags.find((tag) =>
                    isNotNullOrUndefined(tag?.billingDealerId)
                  ) as unknown as Tag
                }
              />
            </div>
            <div>
              {showError && (
                <label
                  className={isNewSite ? `col-sm-12` : `col-sm-9`}
                  style={{
                    color: "var(--color-danger-500)",
                    marginTop: isNewSite ? "0px" : "10px",
                    marginBottom: isNewSite ? "10px" : "-5px",
                    marginLeft: isNewSite ? "170px" : "190px",
                  }}
                >
                  This field is required.
                </label>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="form-group">
          <label
            className={
              labelColumns
                ? `col-sm-${labelColumns} control-label`
                : `col-sm-3 control-label`
            }
            style={{
              marginLeft:
                isSitesModal && !isNewSite
                  ? "-15px"
                  : !isSitesModal && isNewSite
                  ? "90px"
                  : "0px",
            }}
          >
            Tags
          </label>
          <div
            className={inputColumns ? `col-sm-${inputColumns}` : `col-sm-9`}
            style={{
              marginBottom: isNewSite ? "10px" : "0px",
              marginLeft:
                isSitesModal && !isNewSite
                  ? "7px"
                  : !isSitesModal && isNewSite
                  ? "-97px"
                  : "0px",
            }}
          >
            {type === "CustomRoles" ? (
              <Select
                placeholder="+ Add a Tag"
                components={{ Input, NoOptionsMessage, DropdownIndicator }}
                isClearable={false}
                isDisabled={!isEditable || isLoading}
                isLoading={isLoading}
                isMulti
                menuPlacement="auto"
                onChange={handleOnChange}
                options={
                  data.dealer?.tags?.filter((tag) =>
                    isNullOrUndefined(tag?.billingDealerId)
                  ) as unknown as Tag[]
                }
                styles={customTagStyles}
                value={
                  !typeId &&
                  tagFieldValue.getTagsByType?.tags.some((tag) =>
                    isNotNullOrUndefined(tag?.billingDealerId)
                  )
                    ? []
                    : tagFieldValue.getTagsByType?.tags.filter((tag) =>
                        isNullOrUndefined(tag?.billingDealerId)
                      ) || []
                }
              />
            ) : (
              <CreatableSelect
                placeholder="Start Typing to Create New Tag or Add Existing Tag"
                components={{
                  Input,
                  MultiValueRemove,
                  NoOptionsMessage,
                  DropdownIndicator,
                }}
                isClearable={false}
                isDisabled={!isEditable || isLoading}
                isLoading={isLoading}
                isMulti
                menuPlacement="auto"
                onChange={handleOnChange}
                onCreateOption={handleOnCreate}
                options={
                  data.dealer?.tags?.filter((tag) =>
                    isNullOrUndefined(tag?.billingDealerId)
                  ) as unknown as Tag[]
                }
                styles={customTagStyles}
                value={
                  (tagFieldValue.getTagsByType?.tags
                    .filter((tag) => isNullOrUndefined(tag?.billingDealerId))
                    .map((tag) => {
                      return { ...tag };
                    })
                    .concat(
                      // Add in the customer tags for new panels and make them fixed.
                      tagFieldValue?.customerRequiredTags?.tags?.map((tag) => {
                        return { ...tag, isFixed: true };
                      }) || []
                    ) || []) as unknown as Tag[]
                }
              />
            )}
          </div>
        </div>
      </div>
    </RelayEnvironmentCloneProvider>
  );
}

const RemoveIcon = () => {
  return (
    <Svg
      height="14"
      width="14"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
    >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </Svg>
  );
};
const RemoveIconContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Svg = styled.svg`
  display: inline-block;
  fill: white;
  line-height: 1;
  stroke: white;
  stroke-width: 0;
  cursor: default;
`;

const addTagMutation = graphql`
  mutation TagFieldAddTagMutation(
    $encodedTagId: ID!
    $tagType: String!
    $typeId: Int!
  ) {
    addTag(encodedTagId: $encodedTagId, tagType: $tagType, typeId: $typeId) {
      ... on AddTagSuccessPayload {
        addedTagId
        status
      }
      ... on UnknownError {
        type
      }
    }
  }
`;
const removeTagMutation = graphql`
  mutation TagFieldRemoveTagMutation(
    $encodedTagId: ID!
    $tagType: String!
    $typeId: Int!
  ) {
    removeTag(encodedTagId: $encodedTagId, tagType: $tagType, typeId: $typeId) {
      ... on RemoveTagSuccessPayload {
        removedTagId
        status
      }
      ... on UnknownError {
        type
      }
    }
  }
`;
const createTagMutation = graphql`
  mutation TagFieldCreateTagMutation(
    $dealerId: String!
    $name: String!
    $tagType: String!
    $typeId: Int!
  ) {
    createTag(
      dealerId: $dealerId
      name: $name
      tagType: $tagType
      typeId: $typeId
    ) {
      ... on CreateTagSuccessPayload {
        dealer {
          tags {
            id
            label
            value
          }
        }
        newTagId
        status
      }
      ... on UnknownError {
        type
      }
    }
  }
`;

const syncBillingMutation = graphql`
  mutation TagFieldSyncBillingMutation($systemId: ID!) {
    syncBilling(systemId: $systemId) {
      ... on SyncBillingSuccessResponse {
        controlSystem {
          id
        }
      }
      ... on SyncBillingErrorResponse {
        error
      }
    }
  }
`;

export default TagField;
