/**
 * @generated SignedSource<<7692768824b1af2567f8a8d870e715a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FullProgrammingForm_dealer$data = {
  readonly id: string;
  readonly name: string;
  readonly securityCommand: boolean | null;
  readonly vernaculars: ReadonlyArray<{
    readonly original: string;
    readonly replacement: string;
  } | null>;
  readonly " $fragmentSpreads": FragmentRefs<"ProgrammingTemplateForm_dealer">;
  readonly " $fragmentType": "FullProgrammingForm_dealer";
};
export type FullProgrammingForm_dealer$key = {
  readonly " $data"?: FullProgrammingForm_dealer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FullProgrammingForm_dealer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FullProgrammingForm_dealer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgrammingTemplateForm_dealer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Vernacular",
      "kind": "LinkedField",
      "name": "vernaculars",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "original",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "replacement",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "securityCommand",
      "storageKey": null
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};

(node as any).hash = "780366cdc6b4b18d188acce599360451";

export default node;
