import DaColors, { defaultTheme } from "app/common/DaColors";
import graphql from "babel-plugin-relay/macro";
import { GenericPageFallback } from "components/GenericPageFallback";
import Icon from "components/Icon";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React, { Suspense, useEffect, useState } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { react2angular } from "react2angular";
import { idAsString, toDealerId } from "securecom-graphql/client";
import styled, { keyframes, ThemeProvider } from "styled-components";
import { AddToHomeBannerQuery } from "./__generated__/AddToHomeBannerQuery.graphql";

const fadeInFromBottom = keyframes`
  98% {
    transform: scaleY(1.2) translateY(0.4rem);
  }
  100% {
    transform: none;
  }
`;
const Banner = styled.div`
  font-size: 1.6rem;
  align-items: center;
  transform-origin: bottom;
  transform: translateY(10rem) scaleY(1);
  animation: ${fadeInFromBottom} 600ms 1s cubic-bezier(0.28, 0.14, 1, 1.28)
    forwards;
  background-color: ${DaColors.Primary500};
  border-top: 0.1rem solid ${DaColors.Primary500};
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2),
    0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  left: 0;
  padding: var(--measure-1x) var(--measure-2x);
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1100;
  @media screen and (min-width: 400px) {
    display: none !important;
  }
  &:hover {
    background-color: ${DaColors.Primary500};
  }
`;

const CloserWrapper = styled.div`
  align-self: flex-end;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  &:hover {
    color: ${DaColors.White};
  }
`;

const BannerTitle = styled.div`
  color: ${DaColors.Neutral30};
  text-align: center;
`;

const AddToHomeScreenBanner: React.FC<
  React.PropsWithChildren<{ UserService: any }>
> = ({ UserService }) => {
  const dealerId = UserService.dealer_id;

  const data = useLazyLoadQuery<AddToHomeBannerQuery>(
    graphql`
      query AddToHomeBannerQuery($dealerId: ID!) {
        dealer(id: $dealerId) {
          securityCommand
        }
      }
    `,
    { dealerId: idAsString(toDealerId(dealerId)) },
    { fetchPolicy: "network-only" }
  );

  const [instructionAndroid, setInstructionAndroid] = useState<string>("");
  const [instructionIos, setInstructionIos] = useState<string>("");
  const [iosIconText, setIosIconText] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isBannerClicked, setIsBannerClicked] = useState<boolean>(false);

  const isIos = window.navigator.userAgent.includes("Mac");

  const appName = data?.dealer?.securityCommand
    ? "Security Command"
    : "Dealer Admin";

  const handleClick = () => {
    //add on localstorage that it's dismissed
    localStorage.setItem("banner", "true");
    setIsOpen(false); // Close the window when clicked
  };

  useEffect(() => {
    function updateBanner() {
      if (window.innerWidth > 800) {
        return; // Don't show the banner for screens wider than 800 pixels
      }
      const textForAndroid = `${
        !isIos ? `Add ${appName} to your home screen for quick access.` : ""
      } `;
      const textToPrecedeIconIos = `${isIos ? " Tap the " : ""}`;
      const textForIos = ` icon below then "Add to Home Screen" for ${appName} quick access.`;
      setInstructionAndroid(textForAndroid);
      setIosIconText(textToPrecedeIconIos);
      setInstructionIos(textForIos);
    }
    setIsBannerClicked(localStorage.getItem("banner") ? true : false);

    updateBanner();
  }, []);

  if (!isOpen) {
    return null;
  }

  return !isBannerClicked ? (
    <Banner onClick={handleClick}>
      <CloserWrapper>
        <Icon name="close_cancel" color="white" className="mar-r-8" />
      </CloserWrapper>
      <BannerTitle>
        {isIos ? (
          <span>
            {iosIconText}
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ios15-share-icon.png`}
              alt="Share icon"
              style={{ width: "20px", height: "20px", margin: "5px" }}
            />
            {instructionIos}
          </span>
        ) : (
          <span>{instructionAndroid}</span>
        )}
      </BannerTitle>
    </Banner>
  ) : null;
};

export default function AddToHomeBannerRoot({
  RelayService,
  UserService,
  $rootScope,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <ThemeProvider theme={defaultTheme}>
        <Suspense fallback={<GenericPageFallback />}>
          <AlertsContextProvider $rootScope={$rootScope}>
            <AddToHomeScreenBanner UserService={UserService} />
          </AlertsContextProvider>
        </Suspense>
      </ThemeProvider>
    </RelayEnvironmentProvider>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "addToHomeScreenBanner",
    react2angular(
      AddToHomeBannerRoot,
      [],
      ["RelayService", "UserService", "$state", "$rootScope", "$scope"]
    )
  );
}
