import {
  systemIsScheduledForFutureInstall,
  systemWillBeAutoProgrammed,
} from "utils/control-systems";

/**
 * @ngdoc service
 * @name App.factory:SimManagementService
 *
 * @description
 * API Service for SIM management (find, activate, etc.)
 *
 */
App.factory("SimManagementService", [
  "$q",
  "PROPS",
  "SimManagementV1API",
  "SimManagementV2API",
  "PlanManagementV1API",
  "PlanManagementV2API",
  "LicenseManagementV2API",
  "PanelsDiscoveryV1API",
  "CurrentDealerV2API",
  "$filter",
  "VALIDATION_PATTERNS",
  "LTE_CARRIER_TYPE",
  "ScheduledServiceAPI",
  "SitesDapiAPI",
  function (
    $q,
    PROP,
    SimManagementV1API,
    SimManagementV2API,
    PlanManagementV1API,
    PlanManagementV2API,
    LicenseManagementV2API,
    PanelsDiscoveryV1API,
    CurrentDealerV2API,
    $filter,
    VALIDATION_PATTERNS,
    LTE_CARRIER_TYPE,
    ScheduledServiceAPI,
    SitesDapiAPI
  ) {
    return {
      /**
       * @ngdoc object
       * @name method:find
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Used to find existing SIMs.  Returns rate plan, dealer license, phone number, ip, etc. See API docs:
       * http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/SimsController.html#find_by_identifier-instance_method
       *
       * @param {String} sim_id The MEID, ICCID or phone number used to identify the requested SIM.
       * @return {promise} The promise of the requested SIM information.
       */
      find: function (
        sim_id,
        simStatusNumber,
        securecom_license,
        controlSystem,
        dealerCode,
        dealerID
      ) {
        const deferred = $q.defer();
        const _this = this;
        const isX1 = !!controlSystem.site_id;
        const handleGetSimDataSuccess = (data) => {
          //success
          deferred.notify({
            job_uuid: "n/a",
            status: "success",
            poll_count: 0,
          });

          const simData =
            simStatusNumber === 1
              ? {
                  sim1: _this.getCorrectSimFromList(
                    data.sims,
                    securecom_license
                  ),
                }
              : simStatusNumber === 2
              ? {
                  sim2: _this.getCorrectSimFromList(
                    data.sims,
                    securecom_license
                  ),
                }
              : {
                  sim: _this.getCorrectSimFromList(
                    data.sims,
                    securecom_license
                  ),
                };

          // setting currentSIM as a pass by ref so we do not have to re-write logic for all SIMs
          if (simData.sim1 !== undefined) {
            currentSIM = simData.sim1;
          } else if (simData.sim2 !== undefined) {
            currentSIM = simData.sim2;
          } else {
            currentSIM = simData.sim;
          }

          if (currentSIM !== null) {
            currentSIM.isLTE = false;
            currentSIM.LTEtype = LTE_CARRIER_TYPE.NONE;
            currentSIM.isVerizonV1 = false;
            // If no there is no modem property then set what type of legacy technology is being used by carrier
            /**TODO remove this if leaving it out does not cause side effects.
             * Right now in select circumstances it is causing LTE devices to show HSPA early in their activation.
             * if (!simData.sim.modem) {
             *   simData.sim.legacyCellTechType= (simData.sim.carrier === "Verizon") ? "CDMA" : "HSPA"
             * }
             */
            // If an imei property is attached to the sim see if it is an LTE and what type of LTE
            if (
              currentSIM.imei ||
              new RegExp(VALIDATION_PATTERNS.ATT_DIRECT_ICCID).test(
                currentSIM.iccid
              )
            ) {
              currentSIM.isLTE = true;
              currentSIM.LTEtype = _this.getLTEType(currentSIM);
              if (currentSIM.LTEtype === LTE_CARRIER_TYPE.VERIZON_V1) {
                currentSIM.isVerizonV1 = true;
              }
            }
          }
          deferred.resolve(simData);
        };
        const handleGetSimDataFailed = (error) => {
          //failure
          deferred.notify({
            job_uuid: "n/a",
            status: "error",
            poll_count: 0,
          });
          deferred.reject(error);
        };

        if (isX1) {
          SitesDapiAPI.getX1SimsInfo(
            {
              siteId: controlSystem.site_id,
              identifier: sim_id,
            },
            handleGetSimDataSuccess,
            handleGetSimDataFailed,
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
        } else {
          SimManagementV2API.search(
            { sim_id: sim_id, identifier: sim_id, dealer_id: dealerID },
            handleGetSimDataSuccess,
            handleGetSimDataFailed,
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
        }
        return deferred.promise;
      },
      findScheduledSim: async (dealerCode, panelId) => {
        const deferred = $q.defer();
        ScheduledServiceAPI.search(
          {
            panel_id: panelId,
            dealer_code: dealerCode,
          },
          (success) => {
            deferred.resolve(success);
          },
          (error) => {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getCorrectSimFromList
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Goes through an array of sims and determines the "best" one to return to the caller
       *
       * @param listOfSims the list of sims returned from the Search API.
       * @param securecom_license the license to use to search the list for matches, generally "my" dealer's license
       * @returns {*}
       */
      getCorrectSimFromList: function (listOfSims, securecom_license) {
        if (listOfSims.length === 0) return null;
        if (listOfSims.length === 1) return listOfSims[0];
        // Sort the list by created date
        listOfSims.sort(function (a, b) {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
        // If there are sims with my license, just use those...otherwise, use the original full list.
        var matchingLicenses = $filter("filter")(listOfSims, {
          license: { key: securecom_license },
        });
        if (matchingLicenses && matchingLicenses.length > 0)
          listOfSims = matchingLicenses;
        // Get active SIMs
        var activeSims = $filter("filter")(
          listOfSims,
          { status_type: "active" },
          true
        );
        // Sort the active list by created date
        activeSims.sort(function (a, b) {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
        if (activeSims.length === 0) return listOfSims[0];
        // There are multiples, but none are active, so we should return the latest
        else {
          return activeSims[0]; // There are multiple actives, return the latest one
        }
      },
      /**
       * @ngdoc object
       * @name method:activate
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Used to activate a SIM card. See api docs for body structure:
       * http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/SimsController.html#activate-instance_method
       *
       * @param {Object} activate_body The JSON structure required to activate a SIM.
       * @return {promise} The promise of the Activation Request. (includes status, and msisdn-phone number)
       */
      activate: function (
        activate_body,
        controlSystem,
        dealerCode,
        panelId,
        dealer_id
      ) {
        const deferred = $q.defer();

        /**
         * A sim can be activated before the user is billed for the service.
         * The activation is different depending on if the systems installation
         * is scheduled for a future date.
         */
        let activateSim;
        let params;

        if (
          systemWillBeAutoProgrammed(controlSystem) &&
          systemIsScheduledForFutureInstall(controlSystem)
        ) {
          activateSim = ScheduledServiceAPI.activate;
          activate_body.scheduled_date = controlSystem.installed_at;
          params = { panel_id: panelId, dealer_code: dealerCode };
        } else {
          activateSim = SimManagementV1API.activate;
          params = { panelId, dealerCode, dealer_id: dealer_id };
        }

        activateSim(
          params, //params
          activate_body,
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:modify
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Used to modify a SIM card.  See api docs for body structure:
       * http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/SimsController.html#modify-instance_method
       *
       * @param {Object} modify_body The JSON structure required to modify a SIM.
       * @return {promise} The promise of the Modify Request. (includes status, and msisdn-phone number)
       */
      modify: function (modify_body, dealer_id, panelId) {
        var deferred = $q.defer();
        SimManagementV1API.modify(
          { panelId, dealer_id: dealer_id }, //params
          modify_body,
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:deactivate
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Used to modify a SIM card.  See api docs for body structure:
       * http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/SimsController.html#deactivate-instance_method
       *
       * @param {Object} deactivate_body The JSON structure required to deactivate a SIM.
       * @return {promise} The promise of the Deactivate Request. (includes status, and msisdn-phone number)
       */
      deactivate: function (
        deactivate_body,
        controlSystem,
        dealer_code,
        panel_id,
        dealer_id
      ) {
        const deferred = $q.defer();

        /**
         * A sim can be activated before the user is billed for the service.
         * The deactivation path is is different depending on if the system's
         * installation is scheduled for a future date.
         *
         * Each api function has different param requirements. The differences
         * are currently auto bound to the first parameter.
         */
        let deactivateSim;
        let params = {};

        if (
          systemWillBeAutoProgrammed(controlSystem) &&
          systemIsScheduledForFutureInstall(controlSystem)
        ) {
          deactivateSim = ScheduledServiceAPI.deactivate;
          params = { panel_id: panel_id, dealer_code: dealer_code };
        } else {
          deactivateSim = SimManagementV1API.deactivate;
          params = { dealer_id: dealer_id };
        }

        deactivateSim(
          params,
          deactivate_body,
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getRatePlans
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Returns the available rate plans for the requested SIM id. See API docs:
       * http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/Api/V1/RatePlansController.html
       *
       * @param {String} sim_id The MEID or ICCID number used to identify the requested SIM.
       * @return {promise} The promise of the requested SIM Rate Plans.
       */
      getRatePlans: function (sim_id, hardware_model, dealer_id) {
        const deferred = $q.defer();

        PlanManagementV2API.rate_plans(
          {
            identifier: sim_id,
            hardware_model: hardware_model,
            dealer_id: dealer_id,
          }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getTextPlans
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Returns the available rate plans for the requested SIM id. See API docs:
       * <a href='http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/Api/V1/TextPlansController.html#index-instance_method'>
       *   http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/Api/V1/TextPlansController.html#index-instance_method
       * </a>
       * @param {String} sim_id The MEID or ICCID number used to identify the requested SIM.
       * @return {promise} The promise of the requested SIM Rate Plans.
       */
      getTextPlans: function (sim_id, hardware_model, dealer_id) {
        const deferred = $q.defer();

        PlanManagementV1API.text_plans(
          {
            identifier: sim_id,
            hardware_model: hardware_model,
            dealer_id: dealer_id,
          }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getDealerLicenses
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Returns the available list of license keys for the requested dealer. See API docs:
       * <a href='http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/LicensesController.html#find_matching-instance_method'>
       *   http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/LicensesController.html#find_matching-instance_method
       * </a>
       *
       * @todo Move to Dealer Service
       *
       * Note: Not currently used.
       *
       * @param {Number} dealerCode The ID of the requested dealer
       * @return {promise} The promise of the requested Dealer Licenses.
       */
      getDealerLicenses: function (dealerCode) {
        const deferred = $q.defer();

        LicenseManagementV2API.find_by_dealer_code(
          { dealer_code: dealerCode }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getDealerPanels
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Returns the available list of panels for the requested dealer. See API docs:
       * <a href='http://jenkins.dmp.com:8080/job/farad-programming_api/ws/doc/PanelsController.html#api_discover-instance_method'>
       *   http://jenkins.dmp.com:8080/job/farad-programming_api/ws/doc/PanelsController.html#api_discover-instance_method
       * </a>
       *
       * @todo Move to Dealer Service
       *
       * Note: Not currently used.
       *
       * @param {Number} dealerID The ID of the requested dealer
       * @return {promise} The promise of the requested Dealer Panels.
       */
      getDealerPanels: function (dealerID) {
        const deferred = $q.defer();

        PanelsDiscoveryV1API.get(
          //{dealer_id: dealerID}, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getCurrentDealer
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Returns the dealer information that corresponds to the supplied customer id. See API docs:
       * <a href='http://jenkins.dmp.com:8080/job/farad-programming_api/ws/doc/Api/V2/DealersController.html#show-instance_method'>
       *   http://jenkins.dmp.com:8080/job/farad-programming_api/ws/doc/Api/V2/DealersController.html#show-instance_method
       * </a>
       *
       * @todo Move to Dealer Service
       *
       * @param {Number} customerID The customer ID which corresponds to the requested dealer.
       * @return {promise} The promise of the requested Dealer information.
       */
      getCurrentDealer: function (customerID) {
        const deferred = $q.defer();

        CurrentDealerV2API.get(
          { customer_id: customerID }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getSIMHistory
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Returns the the last history record for the requested SIM ID. See API docs:
       * <a href='http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/SimsController.html#history-instance_method'>
       *   http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/SimsController.html#history-instance_method
       * </a>
       *
       *Note: Not currently used.
       *
       * @param {String} simID The MEID or ICCID number used to identify the requested SIM.
       * @param {Number} dealerKey License key of the dealer to whom this SIM is currently associated.
       * @return {promise} The promise of the requested SIM history.
       */
      getSIMHistory: function (simID, dealerKey, dealerID) {
        const deferred = $q.defer();

        SimManagementV1API.history(
          { identifier: simID, key: dealerKey, dealer_id: dealerID }, //params
          function (data) {
            //success
            // If the call failed, reject and exit
            if (!data.success) {
              deferred.reject();
              return false;
            }
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getSIMLogs
       * @methodOf App.factory:SimManagementService
       *
       * @description
       * Returns the the logs for the requested SIM ID. See API docs:
       * <a href='http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/SimsController.html#log-instance_method'>
       *   http://jenkins.dmp.com:8080/job/ohm-rate_plan_api/ws/doc/SimsController.html#log-instance_method
       * </a>
       *
       * @param {String} simID The MEID or ICCID number used to identify the requested SIM.
       * @param {Number} dealerKey License key of the dealer to whom this SIM is currently associated.
       * @return {promise} The promise of the requested SIM logs.
       */
      getSIMLogs: function (simID, dealerKey, dealerID) {
        const deferred = $q.defer();

        SimManagementV1API.log(
          { id: simID, key: dealerKey, dealer_id: dealerID }, //params
          function (data) {
            //success
            var r = /<(.*?)>/;
            angular.forEach(data, function (line) {
              var desc = line.result.description;
              line.account = desc.split(r)[1];
              line.customer = desc.split(r)[3];
            });
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * Returns true if the identifier matches a pattern of an LTE modem
       * @param simData  the data return object from SimManagmentService.find
       * @returns {getLTEType}
       */

      getLTEType: function (simData) {
        var LTEtype = LTE_CARRIER_TYPE.NONE;
        if (
          new RegExp(VALIDATION_PATTERNS.NPHASE_VERIZON_V1_LTE_IMEI).test(
            simData.imei
          )
        ) {
          LTEtype = LTE_CARRIER_TYPE.VERIZON_V1;
        } else if (
          new RegExp(VALIDATION_PATTERNS.NPHASE_QUECTEL_LTE_IMEI).test(
            simData.imei
          )
        ) {
          if (
            new RegExp(VALIDATION_PATTERNS.NPHASE_ATT_LTE_ICCID).test(
              simData.iccid
            )
          ) {
            LTEtype = LTE_CARRIER_TYPE.ATT_V1;
          }
          if (
            new RegExp(VALIDATION_PATTERNS.NPHASE_VERIZON_LTE_ICCID).test(
              simData.iccid
            )
          ) {
            LTEtype = LTE_CARRIER_TYPE.VERIZON_V2;
          }
        } else if (
          new RegExp(VALIDATION_PATTERNS.ATT_DIRECT_ICCID).test(simData.iccid)
        ) {
          LTEtype = LTE_CARRIER_TYPE.ATT_V2;
        }
        return LTEtype;
      },
    };
  },
]);
