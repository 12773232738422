import graphql from "babel-plugin-relay/macro";
import { always, cond, prop, T } from "ramda";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment, useMutation } from "react-relay/hooks";
import { AreaArmedStatusEnum, BadZonesOption } from "securecom-graphql/client";
import styled from "styled-components";
import NakedButton from "../NakedButton";
import NakedList from "../NakedList";
import { SearchField } from "../SearchField";
import { widgetPaddingX } from "../Widget";
import Area, { AREA_ROW_HEIGHT_IN_PX } from "./Area";
import messages from "./messages";
import { AreaArmingDisarmMultipleMutation } from "./__generated__/AreaArmingDisarmMultipleMutation.graphql";
import { AreaArmingMultipleMutation } from "./__generated__/AreaArmingMultipleMutation.graphql";
import { AreaArming_controlSystem$key } from "./__generated__/AreaArming_controlSystem.graphql";
const MAX_VISIBLE_AREAS = 10;

const AreaArming = (props: {
  controlSystem: AreaArming_controlSystem$key;
  setBadZonesMethod: Function;
  setStatusMessage: Function;
  armingIsEnabled?: boolean;
  disarmingIsEnabled?: boolean;
}) => {
  const system = useFragment(
    graphql`
      fragment AreaArming_controlSystem on ControlSystem {
        id
        name
        isInAlarm
        areas(sort: { keys: ["number"], order: ASC }) {
          nodes {
            id
            armedStatus
            name
            ...Area_area
          }
        }
      }
    `,
    props.controlSystem
  );

  const [
    armAllAreas,
    isArmingAllAreas,
  ] = useMutation<AreaArmingMultipleMutation>(
    graphql`
      mutation AreaArmingMultipleMutation(
        $systemId: ID!
        $badZonesOption: BadZonesOption
      ) {
        armAllAreas(systemId: $systemId, badZonesOption: $badZonesOption) {
          ... on MultipleAreasArmedSuccessPayload {
            controlSystem {
              isInAlarm
              ...AreaArming_controlSystem
              armedStatus
            }
          }
          ... on BadZonesError {
            badZones {
              name
              number
            }
          }
          ... on ArmingError {
            type
          }
        }
      }
    `
  );

  const [
    disarmAllAreas,
    isDisarmingAllAreas,
  ] = useMutation<AreaArmingDisarmMultipleMutation>(
    graphql`
      mutation AreaArmingDisarmMultipleMutation($systemId: ID!) {
        disarmAllAreas(systemId: $systemId) {
          ... on MultipleAreasDisarmedSuccessPayload {
            controlSystem {
              armedStatus
              isInAlarm
              ...AreaArming_controlSystem
            }
          }
          ... on ArmingError {
            type
          }
        }
      }
    `
  );

  const [searchQuery, setSearchQuery] = useState("");
  const intl = useIntl();

  return (
    <div>
      {system.areas.nodes.length > 1 && (
        <AllButtons>
          <AllButton
            onClick={() => {
              armAllAreas({
                variables: {
                  systemId: system.id,
                },
                onCompleted: (response) => {
                  if (response.armAllAreas?.badZones) {
                    props.setBadZonesMethod({
                      badZones: response.armAllAreas.badZones,
                      executeMutation: (badZonesOption: BadZonesOption) => {
                        armAllAreas({
                          variables: {
                            systemId: system.id,
                            badZonesOption,
                          },
                        });
                      },
                    });
                  }
                },
              });
            }}
            // disable if arm is not enabled
            disabled={!props.armingIsEnabled || system.areas.nodes.every(
              (area) => area.armedStatus === AreaArmedStatusEnum.ARMED
            )} // filter armable areas first
          >
            <FormattedMessage {...messages.armAll} />
          </AllButton>
          <AllButton
            onClick={() => {
              disarmAllAreas({
                variables: {
                  systemId: system.id,
                },
              });
            }}
            // disable if disarm is not enabled
            disabled={!props.disarmingIsEnabled || system.areas.nodes.every(
              (area) => !(area.armedStatus === AreaArmedStatusEnum.ARMED)
            )} // Filter disarmable areas first
          >
            <FormattedMessage {...messages.disarmAll} />
          </AllButton>
        </AllButtons>
      )}
      {system.areas.nodes.length > MAX_VISIBLE_AREAS && (
        <SearchContainer>
          <SearchField // TODO use dumb search field
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder={intl.formatMessage(messages.findArea)}
            size="small"
          />
        </SearchContainer>
      )}
      <AreasContainer>
        <NakedList>
          {system.areas.nodes
            .filter((area) =>
              area.name
                .trim()
                .toLocaleLowerCase()
                .includes(searchQuery.trim().toLowerCase())
            )
            .map((area) => (
              <Area
                key={area.id}
                area={area}
                canArm={props.armingIsEnabled}
                canDisarm={props.disarmingIsEnabled}
                isPending={
                  (isArmingAllAreas &&
                    !(area.armedStatus === AreaArmedStatusEnum.ARMED)) ||
                  (isDisarmingAllAreas &&
                    area.armedStatus === AreaArmedStatusEnum.ARMED)
                }
                setBadZonesMethod={props.setBadZonesMethod}
              />
            ))}
        </NakedList>
      </AreasContainer>
    </div>
  );
};

export default AreaArming;
const AllButtons = styled.div`
  ${widgetPaddingX};
  display: flex;
  align-items: center;
  margin: 10px 0 20px;
`;
const AllButton = styled(NakedButton)`
  flex: 1;
  padding: 0.9em 1em;
  margin: 0 5px;
  border-radius: 2px;
  background: ${(props) =>
    cond([
      [prop("disabled"), always(props.theme.disabledGray)],
      [prop("burgAlarm"), always(props.theme.danger)],
      [T, always(props.theme.primary)],
    ])(props)};
  color: ${({ disabled, theme }) =>
    disabled ? theme.grayAccent : theme.trueWhite};
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 1;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:not([disabled]) {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    &:hover {
      transform: scale(1.01);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    }
    &:active {
      transform: scale(1.02);
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.3);
    }
  }
`;
const SearchContainer = styled.div`
  ${widgetPaddingX};
`;
const AreasContainer = styled.div`
  max-height: ${(AREA_ROW_HEIGHT_IN_PX + 1) * MAX_VISIBLE_AREAS}px;
  min-height: 200px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;
