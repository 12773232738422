import graphql from "babel-plugin-relay/macro";
import FormGridItems from "components/FormGridItems";
import {
  useEditSecureComNvrUrl,
  useOpenSecurecomNvrModal,
  useUserCanEditVideoDevices,
  useViewSecureComNvrUrl,
} from "components/SiteForm/EntryPointContext";
import React from "react";
import { useFragment } from "react-relay/hooks";
import { asID, fromVideoChannelId } from "securecom-graphql/client";
import VideoDeviceStatus from "../VideoDeviceStatus";
import Icon from "./Components/Icon";
import { PillButton } from "./Components/PillButton";
import Grid from "./VideoSection";
import styles from "./VideoSection.module.css";
import { SecureComNvrs_secureComNvr$key } from "./__generated__/SecureComNvrs_secureComNvr.graphql";
import { SecureComNvrs_site$key } from "./__generated__/SecureComNvrs_site.graphql";

export function NvrHeaderLabel({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className={styles["video-device-title"]}>
      <span>{title}</span>
      <span className={styles["video-device-title-value"]}>{children}</span>
    </div>
  );
}

function SecureComNvr({
  secureComNvrRef,
  getViewUrl,
  getEditUrl,
}: {
  secureComNvrRef: SecureComNvrs_secureComNvr$key;
  getViewUrl: ({
    deviceId,
    channelId,
  }: {
    deviceId: string;
    channelId: string;
  }) => string;
  getEditUrl: (deviceId: string) => string;
}) {
  const nvr = useFragment(
    graphql`
      fragment SecureComNvrs_secureComNvr on SecureComNvr {
        id
        name
        status
        channels {
          id
          name
        }
      }
    `,
    secureComNvrRef
  );

  const { deviceId } = fromVideoChannelId(asID(nvr.id));

  const userCanEditVideoDevices = useUserCanEditVideoDevices();

  const titleContent = (
    <>
      <FormGridItems.ItemTitle>{nvr.name}</FormGridItems.ItemTitle>
      <VideoDeviceStatus status={nvr.status} />
    </>
  );

  return (
    <FormGridItems.Item>
      {userCanEditVideoDevices ? (
        <FormGridItems.ClickableContent as="a" href={getEditUrl(deviceId)}>
          {titleContent}
        </FormGridItems.ClickableContent>
      ) : (
        <FormGridItems.ItemContent>{titleContent}</FormGridItems.ItemContent>
      )}
      {nvr.channels.map((channel) => {
        const { videoChannelId } = fromVideoChannelId(asID(channel.id));
        return (
          <FormGridItems.ClickableContent
            as="a"
            href={getViewUrl({ deviceId, channelId: videoChannelId })}
            key={channel.id}
          >
            <FormGridItems.ItemTitle>{channel.name}</FormGridItems.ItemTitle>
          </FormGridItems.ClickableContent>
        );
      })}
    </FormGridItems.Item>
  );
}

export default function SecureComNvrs({
  siteRef,
}: {
  siteRef: SecureComNvrs_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment SecureComNvrs_site on Site {
        id
        billingControlSystemId
        customer {
          id
        }
        securecomNvrs {
          id
          ...SecureComNvrs_secureComNvr
        }
      }
    `,
    siteRef
  );
  const openSecureComNvrModal = useOpenSecurecomNvrModal();
  const getViewUrl = useViewSecureComNvrUrl(
    data.billingControlSystemId,
    data.id
  );
  const getEditUrl = useEditSecureComNvrUrl(
    data.billingControlSystemId,
    data.id
  );

  return (
    <Grid>
      <Grid.Header>
        <Icon className="icon-server_2" />
        NVRs
        <div className={styles["securecom-add-button"]}>
          <PillButton
            onClick={() => openSecureComNvrModal(data.billingControlSystemId)}
          />
        </div>
      </Grid.Header>
      {!!data.securecomNvrs.length && (
        <FormGridItems
          style={{
            gridTemplateColumns: "repeat(auto-fill, minmax(40rem, 1fr))",
          }}
        >
          {data.securecomNvrs.map((secureComNvr) => (
            <SecureComNvr
              key={secureComNvr.id}
              secureComNvrRef={secureComNvr}
              getViewUrl={getViewUrl}
              getEditUrl={getEditUrl}
            />
          ))}
        </FormGridItems>
      )}
    </Grid>
  );
}
