//Styled Components for CameraTable.tsx

import styled from "styled-components/macro";

export const CssGridTable = styled.table`
  min-width: 100%;
  width: auto;

  display: grid;
  border-collapse: collapse;
  /* These are just initial values which are overridden using JavaScript when a column is resized */
  grid-template-columns:
    minmax(12.5rem, 1.67fr)
    minmax(min-content, 1.07fr)
    minmax(min-content, 0.9fr)
    minmax(min-content, 1.27fr);
`;
export const TableHead = styled.thead`
  display: contents;
  width: 100%;
`;

export const HeaderRow = styled.tr`
  display: inherit;
  width: inherit;
  padding: 1.2rem 2.4rem;
`;

export const TableBody = styled.tbody`
  display: contents;
  border-top: 3px solid var(--color-neutral-400);
`;

export const TableRow = styled.tr`
  display: contents;
  &:nth-child(odd) td {
    background: hsl(210, 25%, 97%);
  }
`;

export const TableDataCell = styled.td`
  padding: 0.8rem 1.6rem !important;
  overflow: hidden;
  &:not(:last-child) {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const TableHeadCell = styled.th`
  background: white;
  text-align: left;
  font-weight: var(--font-weight-thin);
  font-size: 1.4rem;
  color: #5a677f;
  position: relative;
  padding: 1rem 2rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TableButtons = styled.button`
  background-color: transparent;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  border: none;
  color: black;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
  padding: 0;
  outline: none !important;
  text-decoration: none;
  transition: all 0.2s ease-out;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const VideoDeviceHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  padding: var(--measure-3x) var(--measure-3x) 0;
  margin-bottom: var(--measure-2x);
`;

export const VideoDeviceHeaderButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const TableResponsive = styled.div`
  overflow-x: auto;
  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    overflow-y: hidden;
  }
`;

//styled components for CameraMobileWidget.tsx
export const CameraMobileWidgetContainer = styled.div`
  display: grid;
  padding: 1.2rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, min-content);
  grid-template-rows: repeat(3, min-max(0, max-content));
  grid-template-areas:
    "header header"
    "model model"
    "resolution resolution";
  grid-gap: 1.2rem;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
  border: 1px solid --var(global-border-color);
  border-radius: 0.4rem;

  background-color: white;
  margin: 0.8rem;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const CameraMobileWidgetHeader = styled.div`
  grid-area: header;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
`;

export const CameraTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CameraModel = styled.div`
  grid-area: model;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: auto;
`;

export const CameraResolution = styled.div`
  grid-area: resolution;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: auto;
`;

export const CameraButtonArea = styled.div`
  grid-area: button;
  display: flex;
  flex-direction: column;
`;
