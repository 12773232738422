import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreArmDisarmReportsField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreArmDisarmReportsField_remoteOptions.graphql";

export const remoteOptionsEntreArmDisarmReportsFieldId = () =>
  "remote-options-entre-arm-disarm-reports";

function RemoteOptionsEntreArmDisarmReportsField() {
  const [{ entreArmDisarmReports, entreConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsEntreArmDisarmReportsField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsEntreArmDisarmReportsField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            entreArmDisarmReports
            entreConnection
          }
          ... on TMSentryRemoteOptions {
            entreArmDisarmReports
            entreConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsEntreArmDisarmReportsFieldId();
  const label = "Entré Arm / Disarm Reports";
  const disabled = entreConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
    >
      <Switch
        label={label}
        id={fieldId}
        disabled={disabled}
        checked={!!entreArmDisarmReports}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !entreArmDisarmReports,
              "entreArmDisarmReports"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreArmDisarmReportsField;
