/**
 * @generated SignedSource<<cf41cd478ce776ecdc46a5091a9653f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AllowTimeChange = "NO" | "SERVER" | "YES";
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
import { FragmentRefs } from "relay-runtime";
export type TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data = {
  readonly systemOptions: {
    readonly closingCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly crossZoneTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly displayTime: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly enableKeypadPanicKeys: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay1: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly exitDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly hoursFromGMT: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly houseCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly observeDst: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly powerFailDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly resetSwingerBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly swingerBypassTrips: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly timeChange: {
      readonly data: AllowTimeChange | null;
      readonly included: boolean | null;
    } | null;
    readonly useFalseAlarmQuestion: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly weatherZipCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessAudibles: {
      readonly data: WirelessAudibles | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts";
};
export type TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts"
};

(node as any).hash = "782e3accfaa30f5c5fe3e19b60487dad";

export default node;
