import graphql from "babel-plugin-relay/macro";
import HorizontalLabeledInput from "components/DaStyledElements/HorizontalLabeledInput";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useEffect, useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
import {
  asID,
  asString,
  CrudType,
  fromVernacularId,
  toDealerId,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import { SecCommandOptionsFormDealerQuery } from "./__generated__/SecCommandOptionsFormDealerQuery.graphql";
import { SecCommandOptionsFormSuperMutation } from "./__generated__/SecCommandOptionsFormSuperMutation.graphql";

type Props = { dealerId: string; isSaving: boolean; stateIsEdit: boolean };
type VernacularFields = {
  id: string;
  replacement: string;
};
type InitialValues = {
  customers: VernacularFields;
  systems: VernacularFields;
  appUsers: VernacularFields;
  personnel: VernacularFields;
  customRoles: VernacularFields;
};
const DapiVernMap: Record<keyof InitialValues, string> = {
  customers: "customers",
  systems: "systems",
  appUsers: "app users",
  personnel: "personnel",
  customRoles: "custom roles",
};

const SecCommandOptionsForm = ({ dealerId, isSaving, stateIsEdit }: Props) => {
  const dealerData = useLazyLoadQuery<SecCommandOptionsFormDealerQuery>(
    graphql`
      query SecCommandOptionsFormDealerQuery($dealerId: ID!) {
        dealer: node(id: $dealerId) {
          ... on Dealer {
            vernaculars {
              id
              dealerId
              original
              replacement
            }
          }
        }
      }
    `,
    { dealerId: asString(toDealerId(dealerId)) },
    { fetchPolicy: "network-only" }
  );
  const vernsArray = dealerData.dealer?.vernaculars ?? [];

  const vernacularMap = vernsArray.reduce<Record<string, VernacularFields>>(
    (acc, vern) => {
      if (vern?.original) {
        acc[vern.original] = {
          replacement: vern.replacement ?? "",
          id: vern.id ?? null,
        };
      }
      return acc;
    },
    {}
  );

  const defaultMap = { replacement: "", id: null };

  const initialValues: InitialValues = {
    customers: vernacularMap["customers"] ?? defaultMap,
    systems: vernacularMap["systems"] ?? defaultMap,
    appUsers: vernacularMap["app users"] ?? defaultMap,
    personnel: vernacularMap["personnel"] ?? defaultMap,
    customRoles: vernacularMap["custom roles"] ?? defaultMap,
  };

  const [currentValues, setCurrentValues] =
    useState<InitialValues>(initialValues);
  const [previousValues, setPreviousValues] =
    useState<InitialValues>(initialValues);

  const showAlert = useShowAlert();

  const [mutateVerns, isMutatingVerns] =
    useMutation<SecCommandOptionsFormSuperMutation>(
      graphql`
        mutation SecCommandOptionsFormSuperMutation(
          $dealerId: String!
          $inputArray: [VernacularSuperMutationInputs!]!
        ) {
          vernacularSuperMutation(
            dealerId: $dealerId
            inputArray: $inputArray
          ) {
            ... on VernacularSuperMutationSuccessResponse {
              apiResponses {
                errorMessage
                original
                result
              }
              dealer {
                vernaculars {
                  id
                  dealerId
                  original
                  replacement
                }
              }
            }
            ... on VernacularSuperMutationErrorResponse {
              errorMessage
              type
            }
          }
        }
      `
    );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentValues((prev) => ({
      ...prev,
      [name]: {
        ...prev[name as keyof InitialValues],
        replacement: value,
      },
    }));
  };
  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentValues((prev) => ({
      ...prev,
      [name]: {
        ...prev[name as keyof InitialValues],
        replacement: value.trim(),
      },
    }));
  };

  const handleSubmit = () => {
    const changedFields = Object.keys(currentValues).filter(
      (key) =>
        currentValues[key as keyof InitialValues].replacement !==
        initialValues[key as keyof InitialValues].replacement
    );

    if (changedFields.length) {
      const inputArray = changedFields.map((field) => {
        const currentField = currentValues[field as keyof InitialValues];
        const previousField = previousValues[field as keyof InitialValues];
        const isDeleting = currentField.replacement === "";
        const isCreating = previousField.replacement === "";

        const crudType = isCreating
          ? CrudType.CREATE
          : isDeleting
          ? CrudType.DELETE
          : CrudType.UPDATE;

        return {
          crudType: crudType,
          original: DapiVernMap[field as keyof InitialValues],
          replacement: isDeleting
            ? previousField.replacement
            : currentField.replacement,
          vernacularId: isCreating
            ? -1
            : Number(fromVernacularId(asID(currentField.id)).vernacularId),
        };
      });

      mutateVerns({
        variables: {
          dealerId: dealerId,
          inputArray,
        },
        onCompleted: (data) => {
          const responses = data.vernacularSuperMutation.apiResponses;
          if (responses?.length) {
            const toastString = responses.reduce((acc, str) => {
              if (str.result === "failure") {
                if (acc.length > 0) {
                  acc += `, ${str.original}`;
                } else {
                  acc += `${str.original}`;
                }
              }
              return acc;
            }, "");
            if (toastString.length) {
              showAlert({
                type: "error",
                text: `Error updating following terminologies: ${toastString}`,
              });
            }
          }
        },
        onError: (error) => {
          showAlert({
            type: "error",
            text: `Error updating terminologies.`,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (isSaving) {
      handleSubmit();
    }
  }, [isSaving]);

  return (
    <div>
      <HeaderContainer>
        <Header>
          <h3>Custom Terminology</h3>
        </Header>
        <SubHeader>
          Customize the users experience by adding terms that align with their
          organization.
        </SubHeader>
      </HeaderContainer>

      <FormContainer>
        <HorizontalLabeledInput
          id="customers"
          name="customers"
          label="Customer"
          value={currentValues.customers.replacement}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={isMutatingVerns || !stateIsEdit}
          maxLength={30}
        />
        <HorizontalLabeledInput
          id="systems"
          name="systems"
          label="System"
          value={currentValues.systems.replacement}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={isMutatingVerns || !stateIsEdit}
          maxLength={30}
        />
        <HorizontalLabeledInput
          id="appUsers"
          name="appUsers"
          label="App User"
          value={currentValues.appUsers.replacement}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={isMutatingVerns || !stateIsEdit}
          maxLength={30}
        />
        <HorizontalLabeledInput
          id="personnel"
          name="personnel"
          label="Personnel"
          value={currentValues.personnel.replacement}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={isMutatingVerns || !stateIsEdit}
          maxLength={30}
        />
        <HorizontalLabeledInput
          id="customRoles"
          name="customRoles"
          label="Custom Role"
          value={currentValues.customRoles.replacement}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={isMutatingVerns || !stateIsEdit}
          maxLength={30}
        />
      </FormContainer>
    </div>
  );
};

export default SecCommandOptionsForm;

const HeaderContainer = styled.div``;
const Header = styled.div``;
const SubHeader = styled.div``;
const FormContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 35%;
`;
