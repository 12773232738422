App.factory("baseUser", [
  "$q",
  "PROPS",
  "UsersV2API",
  "$filter",
  "$http",
  "ImagesService",
  function ($q, PROPS, UsersV2API, $filter, $http, ImagesService) {
    var baseUser = function (userObject) {
      angular.extend(this, {
        /**
         * Get the specified user from the server and populate this object
         */
        get: function (userId) {
          var deferred = $q.defer();
          var _this = this;
          UsersV2API.show(
            { user_id: userId }, //params
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              data.user.SizedImageURLs = ImagesService.GetAllSizeURLs(
                data.user.user_image_url
              );
              _this.extendMe(data.user);
              _this.init();
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },
        /**
         * Update the user on the VK server
         */
        update: function () {
          var deferred = $q.defer();
          var _this = this;
          UsersV2API.update(
            { user_id: _this.id }, //params
            this.toJsonForUpdate(),
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              _this.extendMe(data.user);
              _this.init();
              deferred.resolve(data);
            },
            function (error) {
              //failure
              _this.handleError(error);
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        /**
         * Upload a user image
         */
        uploadImage: function (file) {
          var deferred = $q.defer();
          var _this = this;

          var fd = new FormData();

          fd.append("user[user_image_upload]", file);
          $http
            .post(
              PROPS.apiUrl + "/v2/users/" + _this.id + "/upload_image",
              fd,
              {
                transformRequest: angular.identity,
                headers: { "Content-Type": undefined },
              }
            )
            .then(
              function (data) {
                _this.get(_this.id);
                deferred.resolve(data);
              },
              function (error) {
                _this.handleError(error);
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });

          return deferred.promise;
        },
        /**
         * Reset the user's password and possibly unlock
         */
        resetAccess: function () {
          var deferred = $q.defer();
          var _this = this;

          UsersV2API.resetAccessEmail(
            { email: _this.email },
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );

          return deferred.promise;
        },
        /**
         * Delete (destroy) the user on the VK server
         */
        delete: function () {
          var deferred = $q.defer();
          var _this = this;
          UsersV2API.destroy(
            { user_id: _this.id }, //params
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },
        /**
         * Convert the object attributes into a suitable json format for updating to VK
         */
        toJson: function () {
          var json = {};
          json.user = {};
          json.user.role = this.role;
          json.user.email = this.email;
          json.user.email_clips = this.email_clips;
          json.user.password = this.password; //This will be null unless called from setPassword
          json.user.first_name = this.first_name;
          json.user.last_name = this.last_name;
          json.user.person_id = this.person_id;
          json.user.hidden = Boolean(this.hidden);
          json.user.territories = this.territories;
          return json;
        },
        /**
         * Json format object for creating a new user
         * @returns {*}
         */
        toJsonForCreate: function () {
          var _this = this;
          var json = this.toJson();
          // Add the user_permissions_attributes if it's not empty
          var addedSystems = $filter("filter")(_this.user_permissions, {
            panel_access: true,
          });
          if (addedSystems.length > 0) {
            json.user.user_permissions_attributes = addedSystems;
            _this.cleanTempPermissions(json.user.user_permissions_attributes);
          }
          return json;
        },
        toJsonForUpdate: function () {
          var json = this.toJson();
          var newPanels = angular.copy(this.user_permissions);
          var origPanels = angular.copy(this.original_user_permissions);
          json.user.user_permissions_attributes = [];

          if (this.accessible_type == "Customer") {
            // First, let's get ADDED panels
            for (let i = 0; i < newPanels.length; i++) {
              // Does origPanels include this one?
              var found = $filter("filter")(origPanels, {
                panel_id: newPanels[i].panel_id,
              });
              // New panel was not found, and new panel was also set for panel_access
              if (found.length < 1 && newPanels[i].panel_access) {
                // NewPanel was not found in origPanel, Add the panel
                json.user.user_permissions_attributes.push(newPanels[i]);
              } else if (newPanels[i].panel_access) {
                // NewPanel was found, and it was set for panel_access, but we need to check if it was modified
                if (
                  found[0].reports_enabled !== newPanels[i].reports_enabled ||
                  found[0].multi_panel_user_codes_enabled !==
                    newPanels[i].multi_panel_user_codes_enabled ||
                  found[0].multi_panel_profiles_enabled !==
                    newPanels[i].multi_panel_profiles_enabled ||
                  found[0].multi_panel_schedules_enabled !==
                    newPanels[i].multi_panel_schedules_enabled ||
                  found[0].view_stored_user_codes !==
                    newPanels[i].view_stored_user_codes ||
                  found[0].arming_enabled !== newPanels[i].arming_enabled ||
                  found[0].remote_panic_police_enabled !==
                    newPanels[i].remote_panic_police_enabled ||
                  found[0].remote_panic_fire_enabled !==
                    newPanels[i].remote_panic_fire_enabled ||
                  found[0].remote_panic_emergency_enabled !==
                    newPanels[i].remote_panic_emergency_enabled
                ) {
                  json.user.user_permissions_attributes.push(newPanels[i]);
                }
              }
            }
            // Then, let's get DELETED panels
            for (let i = 0; i < origPanels.length; i++) {
              // Does newPanels include this one?
              var found = $filter("filter")(newPanels, {
                panel_id: origPanels[i].panel_id,
              });
              if (found.length < 1 || !found[0].panel_access) {
                // OrigPanel was not found in newPanels, Delete the panel
                json.user.user_permissions_attributes.push({
                  id: origPanels[i].id,
                  _destroy: true,
                });
              }
            }
          }

          // Remove the user_permissions_attributes array if it's a zero length
          if (json.user.user_permissions_attributes.length < 1)
            delete json.user.user_permissions_attributes;
          this.cleanTempPermissions(json.user.user_permissions_attributes);
          return json;
        },
        cleanTempPermissions: function (userPermissions) {
          angular.forEach(userPermissions, function (permission) {
            // Strip off temporary data
            delete permission.panel_access;
            delete permission.system_name;
          });
        },
        handleError: function (errorData) {
          if (angular.isUndefined(this.errors)) {
            this.errors = {};
          }
          angular.extend(this.errors, errorData.data.errors);
        },

        /**
         * Function to initialize necessary attributes
         */
        init: function () {
          var _this = this;
          _this.role = Boolean(_this.role) ? _this.role : null;
          _this.original_user_permissions = angular.copy(
            _this.user_permissions
          );
        },

        isSystemSelected: function (systemID) {
          var _this = this;
          var selectedSystems = $filter("filter")(
            _this.user_permissions_checklist,
            { panel_id: systemID }
          );
          return selectedSystems.length > 0;
        },

        extendMe: function (userData) {
          if (userData.user_image_url)
            userData.user_image_url =
              userData.user_image_url +
              "?" +
              Math.round(new Date().getTime() / 1000).toString();
          angular.extend(this, userData);
        },
      });
    };
    return baseUser;
  },
]);
