export const REGION_COLORS = ["#285FF6", "#D13A20", "#5CB85C", "#FFDA3E"];

export const viewBoxMinX = 0;
export const viewBoxMinY = 0;
export const viewBoxMaxX = 1000;
export const viewBoxMaxY = 1000;
export const viewBoxMaxWidth = viewBoxMaxX - viewBoxMinX;
export const viewBoxMaxHeight = viewBoxMaxY - viewBoxMinY;

export const motionDetectionRegionsSvgId = "motion-detection-regions";
export const motionDetectionElementsSvgId = "motion-detection-elements";
