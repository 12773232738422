/**
 * @generated SignedSource<<dedc378d2ea7f8ee9f734f8ce55fff87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddVarHubInput = {
  hubName?: string | null;
  macAddress: string;
  systemId: string;
};
export type RecorderAddModalAddVarHubMutation$variables = {
  addVarHubInput: AddVarHubInput;
};
export type RecorderAddModalAddVarHubMutation$data = {
  readonly addVarHub: {
    readonly __typename: "AddVarHubErrorPayload";
    readonly error: string | null;
    readonly message: string | null;
  } | {
    readonly __typename: "AddVarHubSuccessPayload";
    readonly system: {
      readonly id: string;
      readonly varHubs: ReadonlyArray<{
        readonly cameras: ReadonlyArray<{
          readonly addedToDB: boolean;
          readonly camectCamId: string;
          readonly camectHubId: string;
          readonly cameraId: number | null;
          readonly cameraName: string;
          readonly height: number;
          readonly hubId: number;
          readonly id: string;
          readonly ipAddress: string;
          readonly isEnabled: boolean;
          readonly isStreaming: boolean;
          readonly macAddress: string;
          readonly make: string | null;
          readonly needsCredential: boolean;
          readonly playerAuthToken: string;
          readonly playerUrl: string;
          readonly rtspUrl: string | null;
          readonly snapshot: string | null;
          readonly width: number;
        }>;
        readonly hardwareModel: string;
        readonly hardwareModelDisplayName: string;
        readonly hubId: number;
        readonly hubName: string;
        readonly isOnline: boolean;
        readonly macAddress: string;
        readonly megapixelThresholdCritical: boolean;
        readonly megapixelThresholdWarning: boolean;
        readonly panelConnectionEnabled: boolean | null;
        readonly secondaryNetworkIP: string;
        readonly secondaryNetworkSubnetMask: string;
        readonly secondaryNetworkType: string;
        readonly supportedMegapixels: number;
        readonly timeLocation: string;
      }>;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type RecorderAddModalAddVarHubMutation = {
  response: RecorderAddModalAddVarHubMutation$data;
  variables: RecorderAddModalAddVarHubMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "addVarHubInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hub",
    "variableName": "addVarHubInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "macAddress",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOnline",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelConnectionEnabled",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hardwareModel",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hardwareModelDisplayName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "megapixelThresholdWarning",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "megapixelThresholdCritical",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "supportedMegapixels",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLocation",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryNetworkType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryNetworkIP",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryNetworkSubnetMask",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "VarHubCamera",
  "kind": "LinkedField",
  "name": "cameras",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cameraId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addedToDB",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "camectCamId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "camectHubId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cameraName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isStreaming",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "make",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "needsCredential",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "playerAuthToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "playerUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rtspUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "snapshot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "error",
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "AddVarHubErrorPayload",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderAddModalAddVarHubMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addVarHub",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHub",
                    "kind": "LinkedField",
                    "name": "varHubs",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AddVarHubSuccessPayload",
            "abstractKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecorderAddModalAddVarHubMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addVarHub",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHub",
                    "kind": "LinkedField",
                    "name": "varHubs",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AddVarHubSuccessPayload",
            "abstractKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de8bd19f5f46a6f598683aa0b4d2c884",
    "id": null,
    "metadata": {},
    "name": "RecorderAddModalAddVarHubMutation",
    "operationKind": "mutation",
    "text": "mutation RecorderAddModalAddVarHubMutation(\n  $addVarHubInput: AddVarHubInput!\n) {\n  addVarHub(hub: $addVarHubInput) {\n    __typename\n    ... on AddVarHubSuccessPayload {\n      system {\n        id\n        varHubs {\n          hubId\n          macAddress\n          hubName\n          isOnline\n          panelConnectionEnabled\n          hardwareModel\n          hardwareModelDisplayName\n          megapixelThresholdWarning\n          megapixelThresholdCritical\n          supportedMegapixels\n          timeLocation\n          secondaryNetworkType\n          secondaryNetworkIP\n          secondaryNetworkSubnetMask\n          cameras {\n            id\n            cameraId\n            addedToDB\n            camectCamId\n            camectHubId\n            cameraName\n            height\n            hubId\n            ipAddress\n            isEnabled\n            isStreaming\n            macAddress\n            make\n            needsCredential\n            playerAuthToken\n            playerUrl\n            rtspUrl\n            snapshot\n            width\n          }\n          id\n        }\n      }\n    }\n    ... on AddVarHubErrorPayload {\n      error: type\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a378d1f23684e3535a9c5aef17f23a95";

export default node;
