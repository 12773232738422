App.controller("AutoProgrammingErrorsCtrl", [
  "$scope",
  "$q",
  "UserService",
  "DTColumnBuilder",
  "DataTablesUtilsService",
  "JobSchedulerService",
  "Customer",
  "ControlSystemsService",
  function (
    $scope,
    $q,
    UserService,
    DTColumnBuilder,
    DataTablesUtilsService,
    JobSchedulerService,
    Customer,
    ControlSystemsService
  ) {
    const getCustomer = (customerId) => {
      const customer = new Customer({}, $scope.dealerID);
      return customer
        .get(customerId)
        .then((data) => data.customer)
        .catch(() => null);
    };

    const getControlSystem = (panelId, customerId) => {
      return ControlSystemsService.showControlSystem(panelId, customerId)
        .then((data) => data)
        .catch(() => null);
    };

    const removeNullSystems = (jobs) => {
      return jobs.filter((job) => job.ControlSystem !== null);
    };

    const addCustomerAndSystem = (jobs) => {
      return $q.all(
        jobs.map((job) =>
          $q
            .all([
              getCustomer(job.CustomerId),
              getControlSystem(job.PanelId, job.CustomerId),
            ])
            .then(([customer, controlSystem]) => ({
              ...job,
              Customer: customer,
              ControlSystem: controlSystem,
            }))
        )
      );
    };

    const jobsWithAutoProgrammingErrors = () => {
      var deferred = $q.defer();

      const select = `DealerId,JobMessage,PanelId,CustomerId,JobStatus,CompletedAt`;
      const filter = `(DealerId eq ${UserService.dealer_id} and JobStatus ne 'success' and JobStatus ne 'unknown')`;
      const orderBy = `CompletedAt desc`;

      JobSchedulerService.getAutoProgrammingJobs(filter, orderBy, select)
        .then(addCustomerAndSystem)
        .then(removeNullSystems)
        .then(deferred.resolve)
        .catch((error) => console.log(error));

      return deferred.promise;
    };

    const customerReplacement =
      UserService.dealerInfo.vernaculars.customers.replacement || "Customer";
    const systemReplacement =
      UserService.dealerInfo.vernaculars.systems.replacement || "System";

    $scope.UserService = UserService;
    $scope.dealerID = UserService.dealer_id;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(
      jobsWithAutoProgrammingErrors()
    );
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    $scope.dtOptions.withOption(
      "order",
      [0, "asc"],
      [1, "asc"],
      [2, "asc"],
      [3, "asc"],
      [4, "asc"],
      [5, "asc"]
    );

    $scope.dtColumns = [
      DTColumnBuilder.newColumn(null)
        .withTitle(`${customerReplacement} Name`)
        .withOption("aDataSort", [0, 1])
        .withOption("width", "20%")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "Customer.name");
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle(`${systemReplacement} Name`)
        .withOption("width", "20%")
        .renderWith(function (data, type, row) {
          if (row.ControlSystem === null) {
            row.ControlSystem = { name: "-" };
          }
          return DataTablesUtilsService.dashProperty(row, "ControlSystem.name");
        }),
      DTColumnBuilder.newColumn("CompletedAt")
        .withTitle("Created At")
        .withOption("width", "10%")
        .renderWith(DataTablesUtilsService.asDateTimeRenderFn()),
      DTColumnBuilder.newColumn(null)
        .withTitle("Message")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "JobMessage");
        }),
    ];
  },
]);
