import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefinedOrEmpty } from "common/utils/universal/function";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { useHardwareModel, useVplexNumbers } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { ZoneInformationVplexSerialNumberField_zone$key } from "./__generated__/ZoneInformationVplexSerialNumberField_zone.graphql";
import { resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { DEFAULT_EXPANDER_SERIAL_NUMBER } from "./ZoneInformationExpanderSerialNumberField";

export const DEFAULT_VPLEX_SERIAL_NUMBER = "00000000";

export const ZONE_INFORMATION_VPLEX_SERIAL_NUMBER =
  "zone-information-vplex-serial-number-";

export const zoneInformationVplexSerialNumberFieldId = (number: string) =>
  ZONE_INFORMATION_VPLEX_SERIAL_NUMBER + number;

function ZoneInformationVplexSerialNumberField() {
  const [
    {
      serialNumber,
      expanderSerialNumber,
      number,
      wireless,
      competitorWireless,
    },
    updateZoneInformation,
  ] =
    useZoneInformationFragment<ZoneInformationVplexSerialNumberField_zone$key>(
      graphql`
        fragment ZoneInformationVplexSerialNumberField_zone on Zone {
          number
          serialNumber
          expanderSerialNumber
          wireless
          competitorWireless
        }
      `
    );

  const fieldId = zoneInformationVplexSerialNumberFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const hasExpanderSerialNumber =
    isNotNullOrUndefinedOrEmpty(expanderSerialNumber) &&
    expanderSerialNumber !== DEFAULT_EXPANDER_SERIAL_NUMBER;
  const vplexDevices = useVplexNumbers();

  const disabled =
    wireless ||
    competitorWireless ||
    !number ||
    !vplexDevices.includes(Number(number)) ||
    hasExpanderSerialNumber;

  const { VPLEX_SERIAL_NUMBER: RULES } = resolveZoneFieldRules(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="V-Plex Serial Number"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={!disabled ? serialNumber ?? "" : ""}
        disabled={disabled}
        required={!disabled}
        maxLength={8}
        pattern={RULES.PATTERN}
        inlineHelp={RULES.INLINE_HELP}
        title={RULES.INLINE_HELP}
        validationMessage={RULES.VALIDATION_MSG}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "serialNumber");
          });
        }}
        onBlur={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              target.value === "" ? DEFAULT_VPLEX_SERIAL_NUMBER : target.value,
              "serialNumber"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationVplexSerialNumberField;
