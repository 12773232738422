/**
 * @generated SignedSource<<b753b5cf0efecedb0f9e5fc952efa73e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagsSettingsTableDealerQuery$variables = {
  dealerId: string;
};
export type TagsSettingsTableDealerQuery$data = {
  readonly dealer: {
    readonly vernaculars?: ReadonlyArray<{
      readonly original: string;
      readonly replacement: string;
    } | null>;
  } | null;
};
export type TagsSettingsTableDealerQuery = {
  response: TagsSettingsTableDealerQuery$data;
  variables: TagsSettingsTableDealerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dealerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "replacement",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagsSettingsTableDealerQuery",
    "selections": [
      {
        "alias": "dealer",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vernacular",
                "kind": "LinkedField",
                "name": "vernaculars",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Dealer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagsSettingsTableDealerQuery",
    "selections": [
      {
        "alias": "dealer",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vernacular",
                "kind": "LinkedField",
                "name": "vernaculars",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Dealer",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "487fc32812962749bd23dae939102d63",
    "id": null,
    "metadata": {},
    "name": "TagsSettingsTableDealerQuery",
    "operationKind": "query",
    "text": "query TagsSettingsTableDealerQuery(\n  $dealerId: ID!\n) {\n  dealer: node(id: $dealerId) {\n    __typename\n    ... on Dealer {\n      vernaculars {\n        original\n        replacement\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1a3969b19331bb2a2c3cd6aba7bfa60";

export default node;
