App.controller("RemoteUpdateCtrl", [
  "$rootScope",
  "$scope",
  "$http",
  "$modal",
  "$interval",
  "$window",
  "UserService",
  "RemoteUpdateService",
  "ControlSystemsService",
  "control_system_id",
  "customer_id",
  "InitialConnectionService",
  "$q",
  "$state",
  "$filter",
  function (
    $rootScope,
    $scope,
    $http,
    $modal,
    $interval,
    $window,
    UserService,
    RemoteUpdateService,
    ControlSystemsService,
    control_system_id,
    customer_id,
    InitialConnectionService,
    $q,
    $state,
    $filter
  ) {
    $scope.panel_id = UserService.controlSystem.panels[0].id;
    RemoteUpdateService.initialize($scope.panel_id);
    $scope.RemoteUpdateService = RemoteUpdateService;
    $scope.updateStatuses = RemoteUpdateService.updateStatuses;
    $scope.UserService = UserService;
    $scope.validVernaculars = UserService.dealerInfo.vernaculars;

    $scope.currentDate = new Date(); //currentDate - used as a placeholder for latest firmware date

    $scope.system_name = UserService.controlSystem.name;
    $scope.currentSoftwareVersion =
      UserService.controlSystem.panels[0].software_version;
    $scope.currentSoftwareVersionModifier =
      UserService.controlSystem.panels[0].software_version_modifier == " " ||
      UserService.controlSystem.panels[0].software_version_modifier == null
        ? ""
        : UserService.controlSystem.panels[0].software_version_modifier;
    $scope.currentSoftwareDate =
      UserService.controlSystem.panels[0].software_date;
    $scope.commType = UserService.controlSystem.panels[0].comm_type;
    $scope.isInitialized =
      UserService.controlSystem.panels[0].hardware_family === "XF6" ||
      UserService.controlSystem.panels[0].hardware_family === "TMSentry"
        ? UserService.controlSystem.panels[0].last_command_at !== null
        : UserService.controlSystem.panels[0].arming_system !== null;
    $scope.isCellular = $scope.commType === "cell";
    $scope.hardware_model_name =
      UserService.controlSystem.panels[0].hardware_model_name;
    $scope.hardware_model = UserService.controlSystem.panels[0].hardware_model;
    $scope.runningUpdates = true;
    init();

    function init() {
      if (!$scope.isInitialized) {
        RemoteUpdateService.setStatus(
          $scope.updateStatuses.UPDATE_NOT_AVAILABLE
        );
      }
      initialConnectionCheck();
    }
    function initialConnectionCheck() {
      // Check for initial connect first
      InitialConnectionService.ensureConnectionReady()
        .then(
          function (connectionReady) {
            if (connectionReady) {
              /**
               * Check for any active updates in progress
               */
              RemoteUpdateService.getRunningUpdatesForPanel($scope.panel_id)
                .then(
                  function (data) {
                    if (data.length > 0) {
                      // Set the status immediately, since we know that there is an active update
                      RemoteUpdateService.setStatus(
                        $scope.updateStatuses.UPDATE_IN_PROGRESS
                      );
                      // Start the Update Watcher, to keep checking the status of the update
                      RemoteUpdateService.startUpdateWatcher(data[0].Id);
                    } else {
                      $scope.runningUpdates = false;
                    }

                    RemoteUpdateService.getAvailableUpdates()
                      .then(
                        function (data) {
                          if (data && data.length > 0) {
                            //Filters for international panel firmwares based on current software version
                            var possibleUpdates = data.filter(function (
                              firmware
                            ) {
                              if (
                                $scope.currentSoftwareVersionModifier ==
                                firmware.version_modifier
                              ) {
                                if ($scope.currentSoftwareVersion < 600) {
                                  return firmware.version < 600;
                                } else {
                                  return firmware.version >= 600;
                                }
                              } else {
                                return false;
                              }
                            });
                            if (!possibleUpdates[0]) {
                              RemoteUpdateService.setStatus(
                                $scope.updateStatuses.UPDATE_NOT_AVAILABLE
                              );
                            }
                            $scope.latestUpdate = possibleUpdates[0];
                            $scope.updateVersion();
                            UserService.canUpdatePanel();
                          }
                        },
                        function (error) {
                          $rootScope.alerts.push({
                            type: "error",
                            json: error,
                          });
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function (error) {
                    $rootScope.alerts.push({ type: "error", json: error });
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              switch ($rootScope.appProperties.type) {
                case "dealerAdmin":
                  $state.forceReload();
                  break;
                case "techApp":
                  $state.go("dealer.customer.customersystems", {
                    dealer_id: UserService.dealer_id,
                    customer_id: UserService.customer_id,
                    control_system_id:
                      ControlSystemsService.currentControlSystem.id,
                  });
                  break;
              }
            }
          },
          function (error) {
            if (error !== "USER_CANCELLED")
              $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    $scope.$on("$destroy", function () {
      RemoteUpdateService.stopUpdateWatcher();
    });

    /**
     * Stub to retrieve the release notes for the firmware update
     * ************************************************************/
    $scope.getReleaseNotes = function () {
      //Todo: Retrieve proper firmware documentation for display in pdf format
      //      Display in new window inside a PDF viewer
      $window.open($scope.latestUpdate.release_notes_url);
    };

    /**
     * Listen for the panelUpdateComplete event, then reload the control system so that the new panel software version
     * and date are loaded.
     */
    $rootScope.$on("event-panelUpdateComplete", function () {
      init();
      $scope.updateVersion();
    });

    /**
     * Listen for the scope being destroyed and stops the watcher so it does not keep running after you leave the page.
     */
    $scope.$on("$destroy", function () {
      RemoteUpdateService.stopUpdateWatcher();
    });

    $scope.updateVersion = function () {
      ControlSystemsService.showControlSystem(control_system_id, customer_id)
        .then(
          function (controlSystem) {
            UserService.setControlSystem(controlSystem);
            $scope.currentSoftwareVersion =
              UserService.controlSystem.panels[0].software_version;
            $scope.currentSoftwareVersionModifier =
              UserService.controlSystem.panels[0].software_version_modifier ==
              " "
                ? ""
                : UserService.controlSystem.panels[0].software_version_modifier;
            $scope.currentSoftwareDate =
              UserService.controlSystem.panels[0].software_date;
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Check to see if the panel is cellular
     * if true:
     * show cell charge warning of $10
     * options are continue or cancel
     * *************************************** * */
    $scope.cellCheck = function () {
      var formattedFromVersion = $scope.currentSoftwareVersion;
      if ($scope.currentSoftwareVersionModifier != null)
        formattedFromVersion += $scope.currentSoftwareVersionModifier;
      if ($scope.currentSoftwareDate)
        formattedFromVersion +=
          " (" +
          $filter("date")(
            dateTimeForceUTC(new Date($scope.currentSoftwareDate)),
            "MM/dd/yy"
          ) +
          ")";

      var formattedToVersion = $scope.latestUpdate.version;
      if ($scope.latestUpdate.version_modifier)
        formattedToVersion += $scope.latestUpdate.version_modifier;
      if ($scope.latestUpdate.code_date)
        formattedToVersion +=
          " (" +
          $filter("date")(
            dateTimeForceUTC(new Date($scope.latestUpdate.code_date)),
            "MM/dd/yy"
          ) +
          ")";
      let hardwareModel;
      if ($scope.hardware_model) {
        hardwareModel = $scope.hardware_model;
      } else {
        hardwareModel = "";
      }
      RemoteUpdateService.updatePanel(
        formattedFromVersion,
        formattedToVersion,
        hardwareModel,
        UserService.customerInfo.name,
        UserService.controlSystem.name,
        $scope.latestUpdate
      )
        .then(
          function (data) {},
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Creates and opens a modal to display the cellular charge window
     * and the update progress
     ********************************************************************/
    // $scope.openCellFeeModal = function () {
    //   var cellFeeModal = $modal.open({
    //     templateUrl: 'cellFeeModal.html',
    //     controller: 'CellFeeModalCtrl',
    //     windowClass: '',
    //     size: 'md',
    //     backdrop: true,
    //     scope: $scope
    //   });

    //   cellFeeModal.result.then(function (reason) {
    //     $state.forceReload();
    //   }, function () {
    //   }).catch(function(error){ console.error(error) });
    // };

    /**
     * A controller for the remote update modal
     **********************************************************************************************/
    // App.controller('CellFeeModalCtrl', function ($scope, $modalInstance, $state, UserService) {
    //   $scope.cancel = function () {
    //     $modalInstance.dismiss('cancel');
    //   };

    //   /**
    //    * Function Continue()
    //    * Fires firmware update and dismisses panel
    //    * ***********************************************/
    //   $scope.cancelCellFeeModal = function () {
    //     $modalInstance.dismiss('cancel');
    //   };

    // });
  },
]);
