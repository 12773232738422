/**
 * @generated SignedSource<<1e5f2857f59c21813994fb429cdfdecc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsIntegratorVideoReportsField_remoteOptions$data = {
  readonly integratorConnection?: RemoteOptionsConnectionType | null;
  readonly integratorVideoReports?: boolean | null;
  readonly " $fragmentType": "RemoteOptionsIntegratorVideoReportsField_remoteOptions";
};
export type RemoteOptionsIntegratorVideoReportsField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsIntegratorVideoReportsField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsIntegratorVideoReportsField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorVideoReports",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorConnection",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsIntegratorVideoReportsField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75RemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "4c8be4d65705e70bef632f74a81fac93";

export default node;
