// ArrowState.ts
export enum ArrowState {
  Inward = "RegionArrowInwards",
  Outward = "RegionArrowOutwards",
  None = "None",
  InwardLine = "InwardLine",
  OutwardLine = "OutwardLine",
  BiDirectionalLine = "BiDirectionalLine",
  BiDirectional = "BiDirectional",

}
