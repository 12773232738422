import graphql from "babel-plugin-relay/macro";
import { PATTERNS } from "common/utils/validation";
import { Flex, Form } from "components/DaStyledElements";
import { AlertsContextProvider, useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { RelayEnvironmentProvider, useMutation } from "react-relay";
import { react2angular } from "react2angular";
import Relay from "relay-runtime";
import PasswordConditionIndicator, {
  PasswordIndicatorCluster,
  PasswordIndicatorClusterRow,
} from "./PasswordIndicator";
import { ManagePasswordUpdatesChangePasswordMutation } from "./__generated__/ManagePasswordUpdatesChangePasswordMutation.graphql";

function ModifyPassword({
  formType,
  $state,
  token,
}: {
  formType: FormType;
  $state: any;

  token: string;
}) {
  const showAlert = useShowAlert();
  type ManagePasswordActionType =
    | {
        type: "NEW_PASSWORD_CHANGED";
        value: string;
      }
    | {
        type: "CONFIRM_PASSWORD_CHANGED";
        value: string;
      }
    | {
        type: "CONFIRM_PASSWORD_FIELD_TOUCHED";
        value: boolean;
      }
    | {
        type: "SHOW_NEW_PASSWORD_TOUCHED";
        value: boolean;
      }
    | {
        type: "SHOW_CONFIRM_PASSWORD_TOUCHED";
        value: boolean;
      };

  type ManagePasswordFormState = {
    newPassword: string;
    confirmPassword: string;
    confirmPasswordFieldTouched: boolean;
    showNewPassword: boolean;
    showConfirmPassword: boolean;
  };

  const [state, dispatch] = React.useReducer(
    (state: ManagePasswordFormState, action: ManagePasswordActionType) => {
      switch (action.type) {
        case "NEW_PASSWORD_CHANGED":
          return {
            ...state,
            newPassword: action.value,
          };
        case "CONFIRM_PASSWORD_CHANGED":
          return {
            ...state,
            confirmPassword: action.value,
          };
        case "CONFIRM_PASSWORD_FIELD_TOUCHED":
          return {
            ...state,
            confirmPasswordFieldTouched: action.value,
          };
        case "SHOW_NEW_PASSWORD_TOUCHED":
          return {
            ...state,
            showNewPassword: action.value,
          };
        case "SHOW_CONFIRM_PASSWORD_TOUCHED":
          return {
            ...state,
            showConfirmPassword: action.value,
          };
        default:
          return state;
      }
    },
    {
      newPassword: "",
      confirmPassword: "",
      confirmPasswordFieldTouched: false,
      showNewPassword: false,
      showConfirmPassword: false,
    }
  );

  const [makeChangePasswordMutation] =
    useMutation<ManagePasswordUpdatesChangePasswordMutation>(
      graphql`
        mutation ManagePasswordUpdatesChangePasswordMutation(
          $newPassword: String!
          $clientApplication: ClientApplication!
          $tempAuthToken: String
          $isV4Login: Boolean
        ) {
          changePassword(
            newPassword: $newPassword
            clientApplication: $clientApplication
            tempAuthToken: $tempAuthToken
            isV4Login: $isV4Login
          ) {
            __typename
            ... on ChangePasswordSuccessResponseWithBearerToken {
              users {
                bearerToken
                refreshToken
                user {
                  email
                  accessibleType
                  ... on DealerUser {
                    role
                  }
                }
              }
              __typename
            }
            ... on ChangePasswordSuccessResponse {
              users {
                authToken
                user {
                  email
                  accessibleType
                  ... on DealerUser {
                    role
                  }
                }
              }
              __typename
            }
            ... on ChangePasswordErrorResponse {
              error {
                __typename
              }
              __typename
            }
          }
        }
      `
    );

  const handleSubmit = (
    newPassword: string,
    tempAuthToken: string,
    $state: any
  ) => {
    makeChangePasswordMutation({
      variables: {
        newPassword: newPassword,
        clientApplication: "DEALER_ADMIN",
        tempAuthToken: tempAuthToken,
        isV4Login: true,
      },
      onCompleted: (data) => {
        if ("users" in data.changePassword) {
          showAlert({
            type: "success",
            text: "You have successfully updated your password. Please log in with your new password.",
          });
          $state.go("page.login");
        }
        if ("error" in data.changePassword) {
          showAlert({
            type: "error",
            text: "error changing password",
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "error changing password",
        });
      },
    });
  };

  const newPasswordContainsNumber = PATTERNS.CONTAINS_NUMBER.test(
    state.newPassword
  );
  const newPasswordContainsLetter = PATTERNS.CONTAINS_LETTER.test(
    state.newPassword
  );
  const newPasswordContainsSpecialCharacter =
    PATTERNS.CONTAINS_SPECIAL_CHARACTER.test(state.newPassword);
  const newPasswordContainsAtLeastTenCharacters = state.newPassword.length > 9;

  const allNewPasswordConditionsMet =
    newPasswordContainsNumber &&
    newPasswordContainsLetter &&
    newPasswordContainsSpecialCharacter &&
    newPasswordContainsAtLeastTenCharacters;

  const confirmPasswordMatchesNewPassword =
    state.confirmPassword === state.newPassword;

  return (
    <Flex.Row>
      <Flex.Col size={1}>
        <div
          style={{
            width: "full",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontSize: "3rem",
            }}
          >
            {formType} Password
          </p>
        </div>
        <p
          style={{
            fontSize: "1.4rem",
            marginBottom: "2rem",
            lineHeight: "1.2",
          }}
        >
          Enter a new password below. The password must be at least 10
          characters and include at least one letter, one number, and one
          special character.
        </p>
        <Form.Group className={`required`}>
          <Form.PasswordRevealGroup
            setInputStyle={() =>
              dispatch({
                type: "SHOW_NEW_PASSWORD_TOUCHED",
                value: !state.showNewPassword,
              })
            }
            iconStyle={{ transform: "translateY(25%)" }}
            eyeIsVisible={!!state.newPassword}
          >
            <input
              name="newPassword"
              id="newPassword"
              value={state.newPassword}
              type={state.showNewPassword ? "text" : "password"}
              placeholder="New Password"
              className="form-control"
              onChange={(event) => {
                dispatch({
                  type: "NEW_PASSWORD_CHANGED",
                  value: event.target.value,
                });
              }}
              required
            />
          </Form.PasswordRevealGroup>
          <PasswordIndicatorCluster>
            <PasswordIndicatorClusterRow>
              <PasswordConditionIndicator
                conditionText={"At least 1 number"}
                conditionMet={newPasswordContainsNumber}
              />
              <PasswordConditionIndicator
                conditionText={"At least 1 special character"}
                conditionMet={newPasswordContainsSpecialCharacter}
              />
            </PasswordIndicatorClusterRow>
            <PasswordIndicatorClusterRow>
              <PasswordConditionIndicator
                conditionText={"At least 1 letter"}
                conditionMet={newPasswordContainsLetter}
              />
              <PasswordConditionIndicator
                conditionText={"At least 10 characters"}
                conditionMet={newPasswordContainsAtLeastTenCharacters}
              />
            </PasswordIndicatorClusterRow>
          </PasswordIndicatorCluster>
        </Form.Group>

        <Form.Group className={`required`}>
          <Form.PasswordRevealGroup
            setInputStyle={() =>
              dispatch({
                type: "SHOW_CONFIRM_PASSWORD_TOUCHED",
                value: !state.showConfirmPassword,
              })
            }
            iconStyle={{ transform: "translateY(25%)" }}
            eyeIsVisible={!!state.confirmPassword}
          >
            <input
              name="confirmPassword"
              id="confirmPassword"
              value={state.confirmPassword}
              type={state.showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              className="form-control"
              onChange={(event) => {
                dispatch({
                  type: "CONFIRM_PASSWORD_CHANGED",
                  value: event.target.value,
                });
              }}
              onBlur={() => {
                dispatch({
                  type: "CONFIRM_PASSWORD_FIELD_TOUCHED",
                  value: true,
                });
              }}
              required
            />
          </Form.PasswordRevealGroup>
          {state.confirmPasswordFieldTouched &&
            !confirmPasswordMatchesNewPassword && (
              <div
                style={{
                  color: "var(--color-danger-500)",
                }}
              >
                Passwords do not match
              </div>
            )}

          <input
            type="submit"
            id="update-password-submit"
            disabled={
              !allNewPasswordConditionsMet || !confirmPasswordMatchesNewPassword
            }
            className="btn btn-block btn-dmp mt-lg"
            value={`${formType} Password`}
            onSubmit={() => {
              handleSubmit(state.newPassword, token, $state);
            }}
            onClick={() => {
              handleSubmit(state.newPassword, token, $state);
            }}
          />
        </Form.Group>
      </Flex.Col>
    </Flex.Row>
  );
}

type FormType = "Update" | "Create";

type ManagePasswordPropsType = {
  formType: FormType;
  token: string;
  RelayService: { getEnvironment: () => Relay.Environment };
  $state: any;
  $scope: any;
  $rootScope: {
    alerts: any[];
    $digest: () => void;
    $on: (event: string, callback: (arr: any[]) => void) => () => void;
  };
  DA_LOGOS: Record<string, string>;
  DA_LOGIN_BACKGROUNDS: Record<string, string>;
};

export default function CreateUpdatePasswordRoot({
  formType,
  token,
  RelayService,
  $scope,
  $rootScope,
  $state,
  DA_LOGOS,
  DA_LOGIN_BACKGROUNDS,
}: ManagePasswordPropsType) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  const isSecurityCmd = [
    "scdev",
    "prod_security_cmd",
    "test_security_cmd",
  ].includes(process.env.REACT_APP_SECURECOM_ENV ?? "");

  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <AlertsContextProvider $rootScope={$rootScope}>
        <div className="panel panel-login">
          <div className="panel-heading text-center">
            {isSecurityCmd ? (
              <img
                src={DA_LOGOS.securityCommand}
                width="90%"
                alt="Security Command Logo"
              />
            ) : (
              <img src={DA_LOGOS.default} width="90%" alt="Dealer Admin Logo" />
            )}
          </div>
          <div className="panel-body"></div>
          <ModifyPassword formType={formType} $state={$state} token={token} />
        </div>
      </AlertsContextProvider>
    </RelayEnvironmentProvider>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "createUpdatePassword",
    react2angular(
      CreateUpdatePasswordRoot,
      ["formType", "token"],
      [
        "RelayService",
        "$scope",
        "$rootScope",
        "$state",
        "DA_LOGOS",
        "DA_LOGIN_BACKGROUNDS",
      ]
    )
  );
}
