import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { systemOptionsAllowOwnUserCodeChangeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsAllowOwnUserCodeChangeField";
import { systemOptionsArmDisarmActivityDaysFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsArmDisarmActivityDaysField";
import { systemOptionsBypassLimitFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsBypassLimitField";
import { systemOptionsCelsiusFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCelsiusField";
import { systemOptionsClosingCheckFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingCheckField";
import { systemOptionsClosingCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingCodeField";
import { systemOptionsClosingWaitFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingWaitField";
import { systemOptionsCrossZoneTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import { systemOptionsDetectWirelessJammingFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDetectWirelessJammingField";
import { systemOptionsDisplayTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDisplayTimeField";
import { systemOptionsEnableKeypadPanicKeysFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEnableKeypadPanicKeysField";
import { systemOptionsEnhancedZoneTestFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEnhancedZoneTestField";
import { systemOptionsEntryDelay1FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import { systemOptionsEntryDelay2FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay2Field";
import { systemOptionsEntryDelay3FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay3Field";
import { systemOptionsEntryDelay4FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay4Field";
import { systemOptionsEolResistorValueFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEolResistorValueField";
import { systemOptionsExitDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsExitDelayField";
import { systemOptionsHoldupConfirmationTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoldupConfirmationTimeField";
import { systemOptionsHoursFromGMTFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import { systemOptionsInstantArmingFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsInstantArmingField";
import { systemOptionsIntruderConfirmationTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsIntruderConfirmationTimeField";
import { systemOptionsIso2CountryCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsIso2CountryCodeField";
import { systemOptionsKeypadArmedLedFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsKeypadArmedLedField";
import { systemOptionsLatchedSupervisoryZonesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsLatchedSupervisoryZonesField";
import { systemOptionsObserveDstFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsObserveDstField";
import { systemOptionsOccupiedPremisesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsOccupiedPremisesField";
import { systemOptionsPanicSupervisionFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPanicSupervisionField";
import { systemOptionsPowerFailDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import { systemOptionsPrimaryProgrammingLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPrimaryProgrammingLanguageField";
import { systemOptionsPrimaryUserLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPrimaryUserLanguageField";
import { systemOptionsResetSwingerBypassFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import { systemOptionsSecondaryProgrammingLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSecondaryProgrammingLanguageField";
import { systemOptionsSecondaryUserLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSecondaryUserLanguageField";
import { systemOptionsSend16CharacterNamesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSend16CharacterNamesField";
import { systemOptionsSwingerBypassTripsFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import { systemOptionsSystemTypeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSystemTypeField";
import { systemOptionsTimeChangeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsTimeChangeField";
import { systemOptionsUseBuiltIn1100WirelessFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsUseBuiltIn1100WirelessField";
import { systemOptionsUseFalseAlarmQuestionFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsUseFalseAlarmQuestionField";
import { systemOptionsWeatherPostalCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherPostalCodeField";
import { systemOptionsWeatherZipCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import { systemOptionsWirelessAudiblesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import { systemOptionsWirelessEncryption1100FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100Field";
import { systemOptionsWirelessEncryption1100PassphraseFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100PassphraseField";
import { systemOptionsWirelessHouseCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessHouseCodeField";
import { systemOptionsZoneActivityHoursFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsZoneActivityHoursField";
import { systemOptionsZoneRetardDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsZoneRetardDelayField";
import { XRSystemOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/__generated__/XRSystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getSystemOptionsState } from "../../../XRFullProgramming/XRSystemOptionsProgrammingConceptForm";
import { XrProgrammingTemplateSystemOptionsInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRSystemOptionsTemplateDataInline_XrProgrammingTemplateConcepts$key } from "./__generated__/XRSystemOptionsTemplateDataInline_XrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XRSystemOptionsTemplateDataInline_XrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRSystemOptionsTemplateDataInline_XrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        systemOptions {
          id
          included
          systemType {
            included
            data
          }
          closingCode {
            included
            data
          }
          closingCheck {
            included
            data
          }
          instantArming {
            included
            data
          }
          closingWait {
            included
            data
          }
          entryDelay1 {
            included
            data
          }
          entryDelay2 {
            included
            data
          }
          entryDelay3 {
            included
            data
          }
          entryDelay4 {
            included
            data
          }
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          resetSwingerBypass {
            included
            data
          }
          zoneActivityHours {
            included
            data
          }
          armDisarmActivityDays {
            included
            data
          }
          timeChange {
            included
            data
          }
          observeDst {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          displayTime {
            included
            data
          }
          houseCode {
            included
            data
          }
          detectJam {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          useBuiltIn1100Wireless {
            included
            data
          }
          enableKeypadPanicKeys {
            included
            data
          }
          occupiedPremises {
            included
            data
          }
          useFalseAlarmQuestion {
            included
            data
          }
          weatherZipCode {
            included
            data
          }
          iso2CountryCode {
            included
            data
          }
          weatherPostalCode {
            included
            data
          }
          primaryProgrammingLanguage {
            included
            data
          }
          secondaryProgrammingLanguage {
            included
            data
          }
          primaryUserLanguage {
            included
            data
          }
          secondaryUserLanguage {
            included
            data
          }
          wirelessEncryption1100 {
            included
            data
          }
          wirelessEncryption1100Passphrase {
            included
            data
          }
          eolResistorValue {
            included
            data
          }
          celsius {
            included
            data
          }
          exitDelay {
            included
            data
          }
          entryDelay3 {
            included
            data
          }
          entryDelay4 {
            included
            data
          }
          zoneRetardDelay {
            included
            data
          }
          latchedSupervisoryZones {
            included
            data
          }
          bypassLimit {
            included
            data
          }
          enhancedZoneTest {
            included
            data
          }
          send16CharacterNames {
            included
            data
          }
          keypadArmedLed {
            included
            data
          }
          allowOwnUserCodeChange {
            included
            data
          }
          panicSupervision {
            included
            data
          }
          intruderConfirmationTime {
            included
            data
          }
          holdupConfirmationTime {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XRSystemOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplateSystemOptionsInput => {
  const {
    panel: { systemOptions },
  } = getSystemOptionsState(controlSystem);

  const systemOptionsInputFields = {
    systemType: {
      included: includedFields.has(systemOptionsSystemTypeFieldId()),
      data: systemOptions.systemType,
    },
    instantArming: {
      included: includedFields.has(systemOptionsInstantArmingFieldId()),
      data: systemOptions.instantArming,
    },
    closingWait: {
      included: includedFields.has(systemOptionsClosingWaitFieldId()),
      data: systemOptions.closingWait,
    },
    closingCode: {
      included: includedFields.has(systemOptionsClosingCodeFieldId()),
      data: systemOptions.closingCode,
    },
    closingCheck: {
      included: includedFields.has(systemOptionsClosingCheckFieldId()),
      data: systemOptions.closingCheck,
    },
    entryDelay1: {
      included: includedFields.has(systemOptionsEntryDelay1FieldId()),
      data: systemOptions.entryDelay1,
    },
    entryDelay2: {
      included: includedFields.has(systemOptionsEntryDelay2FieldId()),
      data: systemOptions.entryDelay2,
    },
    entryDelay3: {
      included: includedFields.has(systemOptionsEntryDelay3FieldId()),
      data: systemOptions.entryDelay3,
    },
    entryDelay4: {
      included: includedFields.has(systemOptionsEntryDelay4FieldId()),
      data: systemOptions.entryDelay4,
    },
    zoneRetardDelay: {
      included: includedFields.has(systemOptionsZoneRetardDelayFieldId()),
      data: systemOptions.zoneRetardDelay,
    },
    latchedSupervisoryZones: {
      included: includedFields.has(
        systemOptionsLatchedSupervisoryZonesFieldId()
      ),
      data: systemOptions.latchedSupervisoryZones,
    },
    bypassLimit: {
      included: includedFields.has(systemOptionsBypassLimitFieldId()),
      data: systemOptions.bypassLimit,
    },
    enhancedZoneTest: {
      included: includedFields.has(systemOptionsEnhancedZoneTestFieldId()),
      data: systemOptions.enhancedZoneTest,
    },
    send16CharacterNames: {
      included: includedFields.has(systemOptionsSend16CharacterNamesFieldId()),
      data: systemOptions.send16CharacterNames,
    },
    keypadArmedLed: {
      included: includedFields.has(systemOptionsKeypadArmedLedFieldId()),
      data: systemOptions.keypadArmedLed,
    },
    allowOwnUserCodeChange: {
      included: includedFields.has(
        systemOptionsAllowOwnUserCodeChangeFieldId()
      ),
      data: systemOptions.allowOwnUserCodeChange,
    },
    panicSupervision: {
      included: includedFields.has(systemOptionsPanicSupervisionFieldId()),
      data: systemOptions.panicSupervision,
    },
    crossZoneTime: {
      included: includedFields.has(systemOptionsCrossZoneTimeFieldId()),
      data: systemOptions.crossZoneTime,
    },
    powerFailDelay: {
      included: includedFields.has(systemOptionsPowerFailDelayFieldId()),
      data: systemOptions.powerFailDelay,
    },
    swingerBypassTrips: {
      included: includedFields.has(systemOptionsSwingerBypassTripsFieldId()),
      data: systemOptions.swingerBypassTrips,
    },
    resetSwingerBypass: {
      included: includedFields.has(systemOptionsResetSwingerBypassFieldId()),
      data: systemOptions.resetSwingerBypass,
    },
    zoneActivityHours: {
      included: includedFields.has(systemOptionsZoneActivityHoursFieldId()),
      data: systemOptions.zoneActivityHours,
    },
    armDisarmActivityDays: {
      included: includedFields.has(systemOptionsArmDisarmActivityDaysFieldId()),
      data: systemOptions.armDisarmActivityDays,
    },
    timeChange: {
      included: includedFields.has(systemOptionsTimeChangeFieldId()),
      data: systemOptions.timeChange,
    },
    observeDst: {
      included: includedFields.has(systemOptionsObserveDstFieldId()),
      data: systemOptions.observeDst,
    },
    hoursFromGMT: {
      included: includedFields.has(systemOptionsHoursFromGMTFieldId()),
      data: systemOptions.hoursFromGMT,
    },
    displayTime: {
      included: includedFields.has(systemOptionsDisplayTimeFieldId()),
      data: systemOptions.displayTime,
    },
    houseCode: {
      included: includedFields.has(systemOptionsWirelessHouseCodeFieldId()),
      data: systemOptions.houseCode,
    },
    detectJam: {
      included: includedFields.has(systemOptionsDetectWirelessJammingFieldId()),
      data: systemOptions.detectJam,
    },
    wirelessAudibles: {
      included: includedFields.has(systemOptionsWirelessAudiblesFieldId()),
      data: systemOptions.wirelessAudibles,
    },
    useBuiltIn1100Wireless: {
      included: includedFields.has(
        systemOptionsUseBuiltIn1100WirelessFieldId()
      ),
      data: systemOptions.useBuiltIn1100Wireless,
    },
    enableKeypadPanicKeys: {
      included: includedFields.has(systemOptionsEnableKeypadPanicKeysFieldId()),
      data: systemOptions.enableKeypadPanicKeys,
    },
    occupiedPremises: {
      included: includedFields.has(systemOptionsOccupiedPremisesFieldId()),
      data: systemOptions.occupiedPremises,
    },
    useFalseAlarmQuestion: {
      included: includedFields.has(systemOptionsUseFalseAlarmQuestionFieldId()),
      data: systemOptions.useFalseAlarmQuestion,
    },
    weatherZipCode: {
      included: includedFields.has(systemOptionsWeatherZipCodeFieldId()),
      data: systemOptions.weatherZipCode,
    },
    iso2CountryCode: {
      included: includedFields.has(systemOptionsIso2CountryCodeFieldId()),
      data: systemOptions.iso2CountryCode,
    },
    weatherPostalCode: {
      included: includedFields.has(systemOptionsWeatherPostalCodeFieldId()),
      data: systemOptions.weatherPostalCode,
    },
    primaryProgrammingLanguage: {
      included: includedFields.has(
        systemOptionsPrimaryProgrammingLanguageFieldId()
      ),
      data: systemOptions.primaryProgrammingLanguage,
    },
    secondaryProgrammingLanguage: {
      included: includedFields.has(
        systemOptionsSecondaryProgrammingLanguageFieldId()
      ),
      data: systemOptions.secondaryProgrammingLanguage,
    },
    primaryUserLanguage: {
      included: includedFields.has(systemOptionsPrimaryUserLanguageFieldId()),
      data: systemOptions.primaryUserLanguage,
    },
    secondaryUserLanguage: {
      included: includedFields.has(systemOptionsSecondaryUserLanguageFieldId()),
      data: systemOptions.secondaryUserLanguage,
    },
    wirelessEncryption1100: {
      included: includedFields.has(
        systemOptionsWirelessEncryption1100FieldId()
      ),
      data: systemOptions.wirelessEncryption1100,
    },
    wirelessEncryption1100Passphrase: {
      included: includedFields.has(
        systemOptionsWirelessEncryption1100PassphraseFieldId()
      ),
      data: systemOptions.wirelessEncryption1100Passphrase,
    },
    eolResistorValue: {
      included: includedFields.has(systemOptionsEolResistorValueFieldId()),
      data: systemOptions.eolResistorValue,
    },
    celsius: {
      included: includedFields.has(systemOptionsCelsiusFieldId()),
      data: systemOptions.celsius,
    },
    exitDelay: {
      included: includedFields.has(systemOptionsExitDelayFieldId()),
      data: systemOptions.exitDelay,
    },
    intruderConfirmationTime: {
      included: includedFields.has(
        systemOptionsIntruderConfirmationTimeFieldId()
      ),
      data: systemOptions.intruderConfirmationTime,
    },
    holdupConfirmationTime: {
      included: includedFields.has(
        systemOptionsHoldupConfirmationTimeFieldId()
      ),
      data: systemOptions.holdupConfirmationTime,
    },
  };

  const systemOptionsIncluded = Object.values(systemOptionsInputFields).some(
    (field) => field.included
  );

  return {
    included: systemOptionsIncluded,
    ...systemOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: XRSystemOptionsTemplateDataInline_XrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { systemOptions } = getState(template);

  if (!systemOptions) return;

  if (systemOptions.systemType?.included) {
    setIncludedFields(setToggle(systemOptionsSystemTypeFieldId()));
  }
  if (systemOptions.closingCode?.included) {
    setIncludedFields(setToggle(systemOptionsClosingCodeFieldId()));
  }
  if (systemOptions.closingCheck?.included) {
    setIncludedFields(setToggle(systemOptionsClosingCheckFieldId()));
  }
  if (systemOptions.allowOwnUserCodeChange?.included) {
    setIncludedFields(setToggle(systemOptionsAllowOwnUserCodeChangeFieldId()));
  }
  if (systemOptions.closingWait?.included) {
    setIncludedFields(setToggle(systemOptionsClosingWaitFieldId()));
  }
  if (systemOptions.enhancedZoneTest?.included) {
    setIncludedFields(setToggle(systemOptionsEnhancedZoneTestFieldId()));
  }
  if (systemOptions.bypassLimit?.included) {
    setIncludedFields(setToggle(systemOptionsBypassLimitFieldId()));
  }
  if (systemOptions.instantArming?.included) {
    setIncludedFields(setToggle(systemOptionsInstantArmingFieldId()));
  }
  if (systemOptions.keypadArmedLed?.included) {
    setIncludedFields(setToggle(systemOptionsKeypadArmedLedFieldId()));
  }
  if (systemOptions.latchedSupervisoryZones?.included) {
    setIncludedFields(setToggle(systemOptionsLatchedSupervisoryZonesFieldId()));
  }
  if (systemOptions.send16CharacterNames?.included) {
    setIncludedFields(setToggle(systemOptionsSend16CharacterNamesFieldId()));
  }
  if (systemOptions.panicSupervision?.included) {
    setIncludedFields(setToggle(systemOptionsPanicSupervisionFieldId()));
  }
  if (systemOptions.zoneRetardDelay?.included) {
    setIncludedFields(setToggle(systemOptionsZoneRetardDelayFieldId()));
  }
  if (systemOptions.entryDelay1?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay1FieldId()));
  }
  if (systemOptions.entryDelay2?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay2FieldId()));
  }
  if (systemOptions.entryDelay3?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay3FieldId()));
  }
  if (systemOptions.entryDelay4?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay4FieldId()));
  }
  if (systemOptions.crossZoneTime?.included) {
    setIncludedFields(setToggle(systemOptionsCrossZoneTimeFieldId()));
  }
  if (systemOptions.powerFailDelay?.included) {
    setIncludedFields(setToggle(systemOptionsPowerFailDelayFieldId()));
  }
  if (systemOptions.swingerBypassTrips?.included) {
    setIncludedFields(setToggle(systemOptionsSwingerBypassTripsFieldId()));
  }
  if (systemOptions.resetSwingerBypass?.included) {
    setIncludedFields(setToggle(systemOptionsResetSwingerBypassFieldId()));
  }
  if (systemOptions.zoneActivityHours?.included) {
    setIncludedFields(setToggle(systemOptionsZoneActivityHoursFieldId()));
  }
  if (systemOptions.armDisarmActivityDays?.included) {
    setIncludedFields(setToggle(systemOptionsArmDisarmActivityDaysFieldId()));
  }
  if (systemOptions.timeChange?.included) {
    setIncludedFields(setToggle(systemOptionsTimeChangeFieldId()));
  }
  if (systemOptions.observeDst?.included) {
    setIncludedFields(setToggle(systemOptionsObserveDstFieldId()));
  }
  if (systemOptions.hoursFromGMT?.included) {
    setIncludedFields(setToggle(systemOptionsHoursFromGMTFieldId()));
  }
  if (systemOptions.displayTime?.included) {
    setIncludedFields(setToggle(systemOptionsDisplayTimeFieldId()));
  }
  if (systemOptions.houseCode?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessHouseCodeFieldId()));
  }
  if (systemOptions.detectJam?.included) {
    setIncludedFields(setToggle(systemOptionsDetectWirelessJammingFieldId()));
  }
  if (systemOptions.wirelessAudibles?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessAudiblesFieldId()));
  }
  if (systemOptions.useBuiltIn1100Wireless?.included) {
    setIncludedFields(setToggle(systemOptionsUseBuiltIn1100WirelessFieldId()));
  }
  if (systemOptions.enableKeypadPanicKeys?.included) {
    setIncludedFields(setToggle(systemOptionsEnableKeypadPanicKeysFieldId()));
  }
  if (systemOptions.occupiedPremises?.included) {
    setIncludedFields(setToggle(systemOptionsOccupiedPremisesFieldId()));
  }
  if (systemOptions.useFalseAlarmQuestion?.included) {
    setIncludedFields(setToggle(systemOptionsUseFalseAlarmQuestionFieldId()));
  }
  if (systemOptions.weatherZipCode?.included) {
    setIncludedFields(setToggle(systemOptionsWeatherZipCodeFieldId()));
  }
  if (systemOptions.iso2CountryCode?.included) {
    setIncludedFields(setToggle(systemOptionsIso2CountryCodeFieldId()));
  }
  if (systemOptions.weatherPostalCode?.included) {
    setIncludedFields(setToggle(systemOptionsWeatherPostalCodeFieldId()));
  }
  if (systemOptions.primaryProgrammingLanguage?.included) {
    setIncludedFields(
      setToggle(systemOptionsPrimaryProgrammingLanguageFieldId())
    );
  }
  if (systemOptions.secondaryProgrammingLanguage?.included) {
    setIncludedFields(
      setToggle(systemOptionsSecondaryProgrammingLanguageFieldId())
    );
  }
  if (systemOptions.primaryUserLanguage?.included) {
    setIncludedFields(setToggle(systemOptionsPrimaryUserLanguageFieldId()));
  }
  if (systemOptions.secondaryUserLanguage?.included) {
    setIncludedFields(setToggle(systemOptionsSecondaryUserLanguageFieldId()));
  }
  if (systemOptions.wirelessEncryption1100?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessEncryption1100FieldId()));
  }
  if (systemOptions.wirelessEncryption1100Passphrase?.included) {
    setIncludedFields(
      setToggle(systemOptionsWirelessEncryption1100PassphraseFieldId())
    );
  }
  if (systemOptions.eolResistorValue?.included) {
    setIncludedFields(setToggle(systemOptionsEolResistorValueFieldId()));
  }
  if (systemOptions.celsius?.included) {
    setIncludedFields(setToggle(systemOptionsCelsiusFieldId()));
  }
  if (systemOptions.exitDelay?.included) {
    setIncludedFields(setToggle(systemOptionsExitDelayFieldId()));
  }
  if (systemOptions.holdupConfirmationTime?.included) {
    setIncludedFields(setToggle(systemOptionsHoldupConfirmationTimeFieldId()));
  }
  if (systemOptions.intruderConfirmationTime?.included) {
    setIncludedFields(
      setToggle(systemOptionsIntruderConfirmationTimeFieldId())
    );
  }
};
