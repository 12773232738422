/**
 * @generated SignedSource<<fe08927148e93db44e1a318913f050cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeypadInput = "DSC" | "ECP" | "NONE";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelSystemOptionsProgrammingConceptForm_controlSystem$data = {
  readonly id: string;
  readonly isCellComEx: boolean;
  readonly panel: {
    readonly hardwareModel: PanelHardwareModel;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly softwareVersion: string;
    readonly systemOptions: {
      readonly keypadInput?: KeypadInput | null;
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsArmDisarmActivityDaysField_systemOptions" | "SystemOptionsCIDFormatField_systemOptions" | "SystemOptionsCelsiusField_systemOptions" | "SystemOptionsContextSystemType_systemOptions" | "SystemOptionsContext_systemOptions" | "SystemOptionsCrossZoneTimeField_systemOptions" | "SystemOptionsEcpPartitionField_systemOptions" | "SystemOptionsEntryDelay1Field_systemOptions" | "SystemOptionsExitDelayField_systemOptions" | "SystemOptionsHoursFromGMTField_systemOptions" | "SystemOptionsKeypadInputField_systemOptions" | "SystemOptionsObserveDstField_systemOptions" | "SystemOptionsPowerFailDelayField_systemOptions" | "SystemOptionsResetSwingerBypassField_systemOptions" | "SystemOptionsSwingerBypassTripsField_systemOptions" | "SystemOptionsTimeChangeField_systemOptions" | "SystemOptionsWeatherZipCodeField_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "TakeoverPanelSystemOptionsProgrammingConceptForm_controlSystem";
};
export type TakeoverPanelSystemOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: TakeoverPanelSystemOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelSystemOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TakeoverPanelSystemOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCellComEx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "keypadInput",
                  "storageKey": null
                }
              ],
              "type": "XtSystemOptions",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContext_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsEntryDelay1Field_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsCrossZoneTimeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsPowerFailDelayField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsSwingerBypassTripsField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsArmDisarmActivityDaysField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsResetSwingerBypassField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsTimeChangeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsObserveDstField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsHoursFromGMTField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWeatherZipCodeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsCelsiusField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsExitDelayField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsKeypadInputField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsCIDFormatField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsEcpPartitionField_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "89f20d335f041ef3d912ff34a0ca6bf9";

export default node;
