import { App } from "app-module";
import { EventEmitter } from "events";

App.controller("SiteCtrl", [
  "$state",
  "$scope",
  "$stateParams",
  "$modal",
  "$rootScope",
  "UserService",
  "VideoDeviceService",
  "VideoDevicesV2API",
  "ThirdPartyVideoService",
  "$q",
  function (
    $state,
    $scope,
    $stateParams,
    $modal,
    $rootScope,
    UserService,
    VideoDeviceService,
    VideoDevicesV2API,
    ThirdPartyVideoService,
    $q
  ) {
    $scope.customerId = $stateParams.customer_id;
    $scope.site_id = $stateParams.site_id;
    $scope.hikVision = {};
    $scope.hikVisionDevices = {};
    $scope.nvrConnectInfo = {};
    $scope.hikVision.connectNewDevice = false;
    $scope.hikVision.editHikVision = false;
    $scope.DWDelete = true;
    // We are faking the Services Manager, because the Hikvision modal requires this
    // for editing. However, our form no longer uses a DA Control System model.
    $scope.controlSystem = {
      services_manager: { standard_hikvision_nvr_enabled: true },
    };
    $scope.digitalWatchdog = {
      selected: "server",
    };

    $scope.digitalWatchdog.types = [
      { id: 1, name: "Blackjack", value: "server" },
      { id: 2, name: "DW Cloud", value: "cloud" },
    ];

    let dw_ip_add = { value: null };
    let dw_port = { value: null };

    $scope.eventEmitter = new EventEmitter();

    // ===========================
    // Navigation Actions
    // ===========================

    $scope.navBack = () => {
      $state.go("app.customers.customersummary", {
        customer_id: $stateParams.customer_id,
        dealer_id: UserService.dealerInfo.id,
      });
    };

    $scope.getAngularRouteLink = $state.href;

    // ===========================
    // Video Actions
    // ===========================

    // ---------------------------
    // Modals
    // ---------------------------

    $scope.openCameraModal = function openCameraModal(controlSystemId) {
      // Dangerously mutating the UserService to make try to ensure
      // that the VideoDeviceModalCtrl has the correct control_system_id set
      UserService.control_system_id = controlSystemId;

      $scope.cameraModal = $modal.open({
        templateUrl: "cameraModalContent.html",
        controller: "VideoDeviceModalCtrl",
        windowClass: "camera-modal-content",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });
    };

    $scope.openNvrModal = (controlSystemId) => {
      // Dangerously mutating the UserService to make try to ensure
      // that the VideoDeviceModalCtrl has the correct control_system_id set
      UserService.control_system_id = controlSystemId;

      $scope.nvrModal = $modal.open({
        templateUrl: "nvrModalContent.html",
        controller: "VideoDeviceModalCtrl",
        windowClass: "nvr-modal-content",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });

      $scope.nvrModal.result.catch(function (error) {
        console.error(error);
      });
    };

    /**
     * Creates and opens the Hikvision modal
     */
    $scope.openHikvisionModal = function (controlSystemId) {
      // Dangerously mutating the UserService to make try to ensure
      // that the VideoDeviceModalCtrl has the correct control_system_id set
      UserService.control_system_id = controlSystemId;
      getHikVisionDevices();
      var hikvisionModal = $modal.open({
        templateUrl: "app/control_system/templates/hikvision-modal.html",
        size: "md",
        controller: "closeModalControl",
        backdrop: "static",
        scope: $scope,
      });
    };

    $scope.openDwSpectrumModal = function (controlSystemId) {
      // Dangerously mutating the UserService to make try to ensure
      // that the VideoDeviceModalCtrl has the correct control_system_id set
      UserService.control_system_id = controlSystemId;
      getDWConnectionInfo();
      $scope.digitalWatchdog.selected = $scope.dwCloud ? "cloud" : "server";
      $scope.dwModal = $modal.open({
        templateUrl: "app/control_system/templates/dw-spectrum-modal.html",
        size: "md",
        controller: "closeModalControl",
        backdrop: "static",
        scope: $scope,
      });
    };

    /**
     * Creates and opens a modal to display status messages during first connect
     **/
    $scope.openLoginAsCustomerModal = function (systemId, isUserManagement) {
      var statusModal = {};
      var deferred = $q.defer();
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          statusModal = $modal.open({
            templateUrl: "app/common/templates/temp-user-modal-tpl.html",
            controller: "DaUserManagementLoginCtrl",
            size: "md",
            backdrop: "static",
            resolve: {
              systemId: function () {
                return systemId;
              },
              isUserManagement: function () {
                return isUserManagement;
              },
            },
          });
          statusModal.result.then(
            function (result) {
              if (result.createdTempUser) {
                deferred.resolve();
              } else {
                deferred.reject();
              }
            },
            function () {
              deferred.reject();
            }
          );
          break;
        case "techApp":
          console.warn("daTempAppUser directive does not support TechApp");
          deferred.reject();
          break;
        default:
          console.warn(
            "daTempAppUser directive does not support $rootScope.appProperties.type: " +
              $rootScope.appProperties.type
          );
          deferred.reject();
          break;
      }
      return deferred.promise;
    };

    /**
     * hikVision connect, find and delete devices
     */

    $scope.thirdPartyConnect = function () {
      $scope.thirdPartyBusy = true;
      $scope.nvrErrors = [];
      ThirdPartyVideoService.connectDevice(
        "hikvision",
        UserService.control_system_id,
        $scope.nvrConnectInfo
      ).then(
        function (data) {
          let device = data;
          $scope.refreshThirdPartyChannels(device.id);
          $scope.thirdPartyBusy = false;
          $scope.eventEmitter.emit("hik-changed");
        },
        function (error) {
          for (let val of error.data.errors) {
            $scope.nvrErrors.push(val);
          }

          $scope.thirdPartyBusy = false;
          $scope.eventEmitter.emit("hik-error", error);
        }
      );
    };

    function getHikVisionDevices() {
      // $scope.thirdPartyBusy = true;
      ThirdPartyVideoService.getDevicesByManufacturer(
        "hikvision",
        UserService.control_system_id,
        "NVR"
      )
        .then(
          function (data) {
            // $scope.hikVision.refresh = true;
            $scope.hikVisionDevices = data;
            // $scope.thirdPartyBusy = false;
          },
          function (error) {
            $scope.thirdPartyBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      $scope.hikVision.refresh = false;
    }

    $scope.selectHikVisionNVRField = function (device) {
      $scope.nvrConnectInfo.id = device.id;
      $scope.nvrConnectInfo.name = device.name;
      $scope.nvrConnectInfo.host = device.host;
      $scope.nvrConnectInfo.username = device.username;
      $scope.nvrConnectInfo.password = device.password;
      $scope.nvrConnectInfo.management_port = device.management_port;
      $scope.nvrConnectInfo.streaming_port = device.streaming_port;
      $scope.hikVision.editHikVision = true;
    };

    $scope.addNewHikVisionNVR = function () {
      $scope.hikVision.connectNewDevice = true;
      $scope.nvrConnectInfo = {};
    };

    $scope.refreshThirdPartyChannels = function (deviceId) {
      $scope.thirdPartyBusy = true;
      var deferred = $q.defer();
      ThirdPartyVideoService.refreshDevice(deviceId)
        .then(
          function (data) {
            getHikVisionDevices();
            $scope.thirdPartyBusy = false;
            deferred.resolve();
            $scope.eventEmitter.emit("hik-changed");
          },
          function (error) {
            console.error(
              "Error refreshing video device: " + JSON.stringify(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Error connecting to video device",
            });
            $scope.thirdPartyBusy = false;
            deferred.reject(error);
            $scope.eventEmitter.emit("hik-error", error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     Change either Device Info or User Credentials call for HikVision NVR
     */
    $scope.editHikVisionDevice = function (deviceId, connectForm) {
      $scope.thirdPartyBusy = true;
      var promises = [];
      if (
        connectForm.modifiedModels.includes("nvrConnectInfo.username") ||
        connectForm.modifiedModels.includes("nvrConnectInfo.password")
      ) {
        promises.push(
          ThirdPartyVideoService.updateCredentials(
            deviceId,
            $scope.nvrConnectInfo
          )
        );
      }
      if (
        connectForm.modifiedModels.includes("nvrConnectInfo.host") ||
        connectForm.modifiedModels.includes("nvrConnectInfo.management_port") ||
        connectForm.modifiedModels.includes("nvrConnectInfo.streaming_port")
      ) {
        promises.push(
          ThirdPartyVideoService.updateDevice(deviceId, $scope.nvrConnectInfo)
        );
      }

      $q.all(promises)
        .then(
          function () {
            $scope
              .refreshThirdPartyChannels($scope.nvrConnectInfo.id)
              .then(
                function () {
                  $scope.nvrConnectInfo = {};
                  $scope.thirdPartyBusy = false;
                  $scope.eventEmitter.emit("hik-changed");
                },
                function (error) {
                  getHikVisionDevices();
                  $scope.hikVision.editHikVision = true;
                  $scope.thirdPartyBusy = false;
                  $scope.eventEmitter.emit("hik-error", error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error editing HikVision device",
              json: error,
            });
            $scope.hikVision.editHikVision = true;
            $scope.thirdPartyBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.deleteHikVisionCamera = function (deviceId) {
      $scope.thirdPartyBusy = true;
      ThirdPartyVideoService.destroyDevice(deviceId)
        .then(
          function () {
            getHikVisionDevices();
            $scope.thirdPartyBusy = false;
            $scope.eventEmitter.emit("hik-changed");
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error removing video device",
              json: error,
            });
            $scope.thirdPartyBusy = false;
            $scope.eventEmitter.emit("hik-error", error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    function getDWConnectionInfo() {
      ThirdPartyVideoService.getVideoConnectionInformation(
        UserService.control_system_id,
        "DIGITAL_WATCHDOG"
      ).then(
        function (data) {
          $scope.dwCloud = false;
          $scope.dw_ip_add = data.host;
          $scope.dw_port = data.management_port;
        },
        function (error) {
          $scope.dwCloud = true;
          $scope.DWDelete = false;
        }
      );
    }

    $scope.saveDwInfo = function (form) {
      $scope.thirdPartyBusy = true;
      if ($scope.digitalWatchdog.selected === "cloud") {
        // Delete any existing info
        if ($scope.dw_ip_add) {
          $scope.deleteDwInfo(form);
        }
        $scope.dwCloud = true;
      } else {
        ThirdPartyVideoService.createOrUpdateVideoConnectionInformation(
          UserService.control_system_id,
          form.dw_ip_add.$modelValue,
          form.dw_port.$modelValue,
          "DIGITAL_WATCHDOG"
        )
          .then(
            function (data) {
              $rootScope.alerts.push({
                type: "success",
                text: "Saved DW Connection Info",
              });
              $scope.thirdPartyBusy = false;
              $scope.dwCloud = false;
              $scope.dw_ip_add = dw_ip_add.value;
              $scope.dw_port = dw_port.value;
              $scope.DWDelete = true;
              $scope.eventEmitter.emit("dw-changed");
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Failed to save DW Connection Info",
              });
              $scope.eventEmitter.emit("dw-error", error);
              $scope.thirdPartyBusy = false;
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      $scope.dwModal.close();
    };

    $scope.deleteDwInfo = function (form) {
      $scope.thirdPartyBusy = false;
      ThirdPartyVideoService.deleteVideoConnectionInformation(
        UserService.control_system_id,
        "DIGITAL_WATCHDOG"
      )
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Deleted DW Connection Info",
            });
            // Re-invalidate the form now that we have invalided it with JS

            $scope.dw_ip_add = null;
            $scope.dw_port = null;
            $scope.dwCloud = true;
            form.$setValidity("required", false, form);
            // Disable the delete button
            $scope.DWDelete = false;
            $scope.thirdPartyBusy = false;
            // These fields only exist if its direct.
            if ($scope.digitalWatchdog.selected === "server") {
              dw_ip_add.value = null;
              dw_port.value = null;
            }
            $scope.eventEmitter.emit("dw-changed");
            $scope.dwModal.close();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text:
                "Failed to delete DW Connection Info: " +
                error.data.errors[0].message,
            });
            $scope.eventEmitter.emit("dw-error", error);

            $scope.thirdPartyBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    // ---------------------------
    // List / Device Actions
    // ---------------------------

    $scope.testVideoDeviceConnection = function (deviceId) {
      return new Promise((resolve) => {
        VideoDevicesV2API.test_connection(
          { video_device_id: deviceId },
          function () {
            resolve("Online");
          },
          function (error) {
            resolve("Offline");
          }
        );
      });
    };

    $scope.reactivateVideoDevice = async function reactivateVideoDevice(
      serialNumber
    ) {
      const videoDevice = await new Promise((resolve, reject) => {
        VideoDeviceService.searchAvailable(["nvr"], serialNumber)
          .then(resolve)
          .catch(reject);
      });

      if (!videoDevice) {
        return;
      }

      await new Promise((resolve, reject) => {
        videoDevice
          .reactivate()
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: "Reactivating " + videoDevice.device_type,
              });
              resolve();
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                json: error,
              });
              reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      });
    };

    // ---------------------------
    // Required Helpers
    // ---------------------------

    $scope.getVideoDevices = function getVideoDevices() {
      VideoDeviceService.isBusy = true;
      VideoDeviceService.getVideoDevices(true)
        .then(
          function (videoDevices) {
            $scope.cameras = videoDevices.cameras; // Separate the cameras out from the returned video devices
            $scope.nvrList = videoDevices.nvrs; // Separate the NVRs from the returned video devices
            $scope.dvrList = videoDevices.dvrs; // Separate the DVRs from the returned video devices
            VideoDeviceService.isBusy = false;
          },
          function (error) {
            VideoDeviceService.isBusy = false;
            console.error(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    // Used to update site name in the menu when change is made from React component
    $scope.setSiteName = function setSiteName(name) {
      UserService.setSite({ id: UserService.site.id, name: name });
    };
  },
]);
