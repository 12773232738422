/**
 * @generated SignedSource<<cc97df9afdfe424e1a73c320f8031d8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFSystemOptionsProgrammingConceptForm_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly softwareVersion: string;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContext_systemOptions" | "SystemOptionsCrossZoneTimeField_systemOptions" | "SystemOptionsDetectWirelessJammingField_systemOptions" | "SystemOptionsHoursFromGMTField_systemOptions" | "SystemOptionsInspectionReminderField_systemOptions" | "SystemOptionsLatchedSupervisoryZonesField_systemOptions" | "SystemOptionsObserveDstField_systemOptions" | "SystemOptionsPowerFailDelayField_systemOptions" | "SystemOptionsPrimaryProgrammingLanguageField_systemOptions" | "SystemOptionsPrimaryUserLanguageField_systemOptions" | "SystemOptionsSecondaryProgrammingLanguageField_systemOptions" | "SystemOptionsSecondaryUserLanguageField_systemOptions" | "SystemOptionsSwingerBypassTripsField_systemOptions" | "SystemOptionsTimeChangeField_systemOptions" | "SystemOptionsWirelessAudiblesField_systemOptions" | "SystemOptionsWirelessEncryption1100Field_systemOptions" | "SystemOptionsWirelessEncryption1100PassphraseField_systemOptions" | "SystemOptionsWirelessHouseCodeField_systemOptions" | "SystemOptionsZoneRetardDelayField_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel" | "SystemOptionsSystemTypeField_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem" | "ControlSystemContext_controlSystem">;
  readonly " $fragmentType": "XFSystemOptionsProgrammingConceptForm_controlSystem";
};
export type XFSystemOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XFSystemOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFSystemOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFSystemOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SystemOptionsSystemTypeField_panel"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContext_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsPrimaryProgrammingLanguageField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsSecondaryProgrammingLanguageField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsPrimaryUserLanguageField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsSecondaryUserLanguageField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsCrossZoneTimeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsZoneRetardDelayField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsPowerFailDelayField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsSwingerBypassTripsField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsTimeChangeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsObserveDstField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsHoursFromGMTField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsLatchedSupervisoryZonesField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWirelessHouseCodeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsDetectWirelessJammingField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWirelessEncryption1100Field_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWirelessEncryption1100PassphraseField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWirelessAudiblesField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsInspectionReminderField_systemOptions"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "4342c01d1e1a375bfe217354ba3f48ff";

export default node;
