//RecorderDetectionCameraElements.tsx
import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { Svg } from "components/CameraEditCommon/CameraEditStyledComponents";
import {
  motionDetectionElementsSvgId,
  viewBoxMaxHeight,
  viewBoxMaxWidth,
} from "components/CameraEditCommon/constants";
import {
  Coordinates,
  RegionBounds,
  ViewBox,
} from "components/CameraEditCommon/types";
import {
  getKeyboardTransformer,
  getMouseTransformer,
  getRegionColor,
  removeIndexes,
  renderXCoordinate,
  renderYCoordinate,
} from "components/CameraEditCommon/utils";
import React, { useCallback, useEffect, useMemo } from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { RecordProxy, RecordSourceProxy } from "relay-runtime";
import { DirectionOfTravel as directionOfTravel } from "securecom-graphql/client";
import { isNullOrUndefined } from "util";
import DetectionLine from "./DetectionLine";
import DetectionRegion from "./DetectionRegion";
import { VarHubCameraWithClientSchemaData } from "./types";
import {
  removeLineUpdater,
  removeRegionUpdater,
  transformForKeyPress,
  transformLineForKeyPress,
  translate,
  translateLine,
} from "./utils";
import { RecorderCameraDetectionElements_varHubCamera$key } from "./__generated__/RecorderCameraDetectionElements_varHubCamera.graphql";

type Props = {
  camera: RecorderCameraDetectionElements_varHubCamera$key;
  aspectRatio: number;
  isEditable: boolean;
};

function documentKeyDownUpdater(props: {
  store: RecordSourceProxy;
  cameraId: string;
  event: KeyboardEvent;
  bounds: RegionBounds;
}) {
  const { store, cameraId, event, bounds } = props;

  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;

  const activeDetectionLineIndex = camera.getValue("activeDetectionLineIndex");
  const activeDetectionRegionIndex = camera.getValue(
    "activeDetectionRegionIndex"
  );

  const detectionLines = camera.getLinkedRecords("detectionLines");
  const detectionRegions = camera.getLinkedRecords("detectionRegions");

  if (
    isNullOrUndefined(activeDetectionLineIndex) &&
    isNullOrUndefined(activeDetectionRegionIndex)
  ) {
    return;
  }

  // Detection Line Events
  if (isNotNullOrUndefined(activeDetectionLineIndex)) {
    if (event.key === "Escape") {
      camera.setValue(null, "activeDetectionLineIndex");
    } else if (event.key === "Tab") {
      camera.setValue(
        (activeDetectionLineIndex + 1) % detectionLines.length,
        "activeDetectionLineIndex"
      );
    } else if (event.key === "Backspace") {
      const lineId = camera
        .getLinkedRecords("detectionLines")
        [activeDetectionLineIndex]?.getValue("id");
      if (lineId) {
        removeLineUpdater(store, cameraId, lineId);
      }
    } else if (
      isNotNullOrUndefined(activeDetectionLineIndex) &&
      detectionLines[activeDetectionLineIndex]
    ) {
      transformLineForKeyPress(getKeyboardTransformer(bounds)(event))(
        camera.getLinkedRecords("detectionLines")[activeDetectionLineIndex]
      );
    }
  }

  // Detection Regions Events
  if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
    if (event.key === "Escape") {
      camera.setValue(null, "activeDetectionRegionIndex");
    } else if (event.key === "Tab") {
      camera.setValue(
        (activeDetectionRegionIndex + 1) % detectionRegions.length,
        "activeDetectionRegionIndex"
      );
    } else if (event.key === "Backspace") {
      const activeRegion = detectionRegions[activeDetectionRegionIndex];
      const activeCoordinateIndexes = activeRegion.getValue(
        "activeCoordinateIndexes"
      );
      if (activeCoordinateIndexes?.length) {
        const geometry = activeRegion.getLinkedRecord("geometry");
        const coordinates = geometry.getValue("coordinates");
        geometry.setValue(
          [removeIndexes([...activeCoordinateIndexes])(coordinates[0])],
          "coordinates"
        );
        activeRegion.setValue([], "activeCoordinateIndexes");
      } else {
        const regionId = camera
          .getLinkedRecords("detectionRegions")
          [activeDetectionRegionIndex]?.getValue("id");
        if (regionId) {
          removeRegionUpdater(store, cameraId, regionId);
        }
      }
    } else if (
      isNotNullOrUndefined(activeDetectionRegionIndex) &&
      detectionRegions[activeDetectionRegionIndex]
    ) {
      transformForKeyPress(getKeyboardTransformer(bounds)(event))(
        camera.getLinkedRecords("detectionRegions")[activeDetectionRegionIndex]
      );
    }
  }
}

function documentMouseDownUpdater(
  store: RecordSourceProxy,
  cameraId: string,
  event: MouseEvent
) {
  const { target } = event;
  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;
  const activeDetectionLineIndex = camera.getValue("activeDetectionLineIndex");
  const activeDetectionRegionIndex = camera.getValue(
    "activeDetectionRegionIndex"
  );

  const detectionLines = camera.getLinkedRecords("detectionLines");
  const detectionRegions = camera.getLinkedRecords("detectionRegions");

  /* Line Element */
  if (
    target instanceof Element &&
    target.id.match(/motion-detection-line-[0-9]+/)
  ) {
    const idParts = target.id.split("-");
    const activeLineIndex = Number(idParts[3]);
    const detectionLine = detectionLines[activeLineIndex];
    const activeCoordinateIndexes = detectionLine.getValue(
      "activeCoordinateIndexes"
    );

    if (idParts[4] === "point") {
      const activeCoordinates = event.shiftKey
        ? new Set(activeCoordinateIndexes)
        : new Set<number>();
      activeCoordinates.add(Number(idParts[5]));
      detectionLine.setValue([...activeCoordinates], "activeCoordinateIndexes");
      camera.setValue(true, "mouseDown");
    } else if (activeCoordinateIndexes?.length) {
      detectionLine.setValue([], "activeCoordinateIndexes");
    } else {
      camera.setValue(activeLineIndex, "activeDetectionLineIndex");
      camera.setValue(true, "mouseDown");
    }
  } else if (isNotNullOrUndefined(activeDetectionLineIndex)) {
    const lines = camera.getLinkedRecords("detectionLines");
    const activeLine = lines[activeDetectionLineIndex];
    if (isNotNullOrUndefined(activeLine)) {
      activeLine.setValue([], "activeCoordinateIndexes");
      camera.setValue(null, "activeDetectionLineIndex");
    }
    camera.setValue(true, "mouseDown");
  } else {
    camera.setValue(true, "mouseDown");
  }

  /* Region Element */
  if (
    target instanceof Element &&
    target.id.match(/motion-detection-region-[0-9]+/)
  ) {
    const idParts = target.id.split("-");
    const activeRegionIndex = Number(idParts[3]);
    const activeRegion = detectionRegions[activeRegionIndex];
    const activeCoordinateIndexes = activeRegion.getValue(
      "activeCoordinateIndexes"
    );

    if (idParts[4] === "point") {
      const activeCoordinates = event.shiftKey
        ? new Set(activeCoordinateIndexes)
        : new Set<number>();
      activeCoordinates.add(Number(idParts[5]));
      activeRegion.setValue([...activeCoordinates], "activeCoordinateIndexes");
      camera.setValue(true, "mouseDown");
    } else if (activeCoordinateIndexes?.length) {
      activeRegion.setValue([], "activeCoordinateIndexes");
    } else {
      camera.setValue(activeRegionIndex, "activeDetectionRegionIndex");
      camera.setValue(true, "mouseDown");
    }
  } else if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
    const regions = camera.getLinkedRecords("detectionRegions");
    const activeRegion = regions[activeDetectionRegionIndex];
    if (isNotNullOrUndefined(activeRegion)) {
      activeRegion.setValue([], "activeCoordinateIndexes");
      camera.setValue(null, "activeDetectionRegionIndex");
    }
    camera.setValue(true, "mouseDown");
  } else {
    camera.setValue(true, "mouseDown");
  }
}

function documentMouseUpUpdater(store: RecordSourceProxy, cameraId: string) {
  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;
  camera.setValue(false, "mouseDown");
}

function documentMouseMoveUpdater(props: {
  store: RecordSourceProxy;
  cameraId: string;
  event: MouseEvent;
  bounds: RegionBounds;
}) {
  const { store, cameraId, event, bounds } = props;
  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;
  const mouseDown = camera.getValue("mouseDown");
  const activeDetectionLineIndex = camera.getValue("activeDetectionLineIndex");
  const activeDetectionRegionIndex = camera.getValue(
    "activeDetectionRegionIndex"
  );

  const detectionLines = camera.getLinkedRecords("detectionLines");
  const detectionRegions = camera.getLinkedRecords("detectionRegions");

  const svgElement = document.getElementById(motionDetectionElementsSvgId);

  if (
    svgElement &&
    mouseDown &&
    isNotNullOrUndefined(activeDetectionLineIndex) &&
    detectionLines[activeDetectionLineIndex]
  ) {
    translateLine(getMouseTransformer(bounds)(svgElement)(event))(
      detectionLines[activeDetectionLineIndex]
    );
  }

  if (
    svgElement &&
    mouseDown &&
    isNotNullOrUndefined(activeDetectionRegionIndex) &&
    detectionRegions[activeDetectionRegionIndex]
  ) {
    translate(getMouseTransformer(bounds)(svgElement)(event))(
      detectionRegions[activeDetectionRegionIndex]
    );
  }
}

function DetectionElements(props: Props) {
  const { aspectRatio, camera, isEditable } = props;
  const data = useFragment(
    graphql`
      fragment RecorderCameraDetectionElements_varHubCamera on VarConnectedCamera {
        id
        activeDetectionLineIndex
        activeDetectionRegionIndex
        mouseDown
        detectionLines {
          id
          index
          slotNumber
          activeCoordinateIndexes
          focus
          movementDirection
          geometry {
            coordinates
          }
        }
        detectionRegions {
          id
          index
          slotNumber
          activeCoordinateIndexes
          focus
          movementDirection
          geometry {
            coordinates
          }
        }
        minRegionX
        maxRegionX
        minRegionY
        maxRegionY
      }
    `,
    camera
  );

  const {
    id,
    activeDetectionLineIndex,
    activeDetectionRegionIndex,
    minRegionX,
    maxRegionX,
    minRegionY,
    maxRegionY,
  } = data;

  const relayEnv = useRelayEnvironment();

  const bounds = React.useMemo(
    () => ({
      minX: minRegionX,
      maxX: maxRegionX,
      minY: minRegionY,
      maxY: maxRegionY,
    }),
    [minRegionX, maxRegionX, minRegionY, maxRegionY]
  );

  const viewBox = useMemo(
    (): ViewBox =>
      aspectRatio <= viewBoxMaxWidth / viewBoxMaxHeight
        ? [viewBoxMaxWidth * aspectRatio, viewBoxMaxHeight]
        : [viewBoxMaxWidth, viewBoxMaxHeight / aspectRatio],
    [aspectRatio]
  );
  const xCoordinateRenderer = useCallback(renderXCoordinate(bounds)(viewBox), [
    bounds,
    viewBox,
  ]);
  const yCoordinateRenderer = useCallback(renderYCoordinate(bounds)(viewBox), [
    bounds,
    viewBox,
  ]);

  React.useLayoutEffect(() => {
    relayEnv.commitUpdate((store) => {
      const camera = store.get(
        id
      ) as RecordProxy<VarHubCameraWithClientSchemaData>;
      const lines = camera.getLinkedRecords("detectionLines");
      const regions = camera.getLinkedRecords("detectionRegions");
      lines.forEach((line, index) => {
        line.setValue(index, "index");
      });
      regions.forEach((region, index) => {
        region.setValue(index, "index");
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (
        isNotNullOrUndefined(activeDetectionLineIndex) ||
        isNotNullOrUndefined(activeDetectionRegionIndex)
      ) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentKeyDownUpdater({ store, cameraId: id, event, bounds });
      });
    }
    function handleMouseDown(event: MouseEvent) {
      if (
        isNotNullOrUndefined(activeDetectionLineIndex) ||
        isNotNullOrUndefined(activeDetectionRegionIndex)
      ) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseDownUpdater(store, id, event);
      });
    }
    function handleMouseUp(event: MouseEvent) {
      if (
        isNotNullOrUndefined(activeDetectionLineIndex) ||
        isNotNullOrUndefined(activeDetectionRegionIndex)
      ) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseUpUpdater(store, id);
      });
    }
    function handleMouseMove(event: MouseEvent) {
      if (
        isNotNullOrUndefined(activeDetectionLineIndex) ||
        isNotNullOrUndefined(activeDetectionRegionIndex)
      ) {
        event.preventDefault();
      }

      relayEnv.commitUpdate((store) => {
        documentMouseMoveUpdater({
          store,
          cameraId: id,
          event,
          bounds,
        });
      });
    }

    if (isEditable) {
      document.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (isEditable) {
        document.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [relayEnv, id, activeDetectionLineIndex, bounds, viewBox]);

  const lines = useMemo(() => {
    if (isNullOrUndefined(activeDetectionLineIndex)) {
      return data.detectionLines;
    }

    const linesWithActiveOnTop = [...data.detectionLines];
    return linesWithActiveOnTop.concat(
      linesWithActiveOnTop.splice(activeDetectionLineIndex, 1)
    );
  }, [activeDetectionLineIndex, data.detectionLines]);

  const regions = React.useMemo(() => {
    if (isNullOrUndefined(activeDetectionRegionIndex)) {
      return data.detectionRegions;
    }

    const regionsWithActiveOnTop = [...data.detectionRegions];
    return regionsWithActiveOnTop.concat(
      regionsWithActiveOnTop.splice(activeDetectionRegionIndex, 1)
    );
  }, [activeDetectionRegionIndex, data.detectionRegions]);

  return (
    <Svg
      id={motionDetectionElementsSvgId}
      viewBox={`0 0 ${viewBox[0]} ${viewBox[1]}`}
    >
      {regions.map((region) => (
        <DetectionRegion
          key={region.id}
          directionOfTravel={
            region.movementDirection
              ? (region.movementDirection as directionOfTravel)
              : directionOfTravel.OFF
          }
          aspectRatio={aspectRatio}
          mouseDown={!!data.mouseDown}
          color={getRegionColor(region.slotNumber ?? -1)}
          index={region.index ?? -1}
          renderX={xCoordinateRenderer}
          renderY={yCoordinateRenderer}
          bounds={bounds}
          coordinates={(region.geometry.coordinates[0] as Coordinates[]) ?? []}
          activeCoordinateIndexes={
            (region.activeCoordinateIndexes as number[]) ?? []
          }
          cameraId={data.id}
          isActive={activeDetectionRegionIndex === region.index}
        />
      ))}
      {lines.map((line) => (
        <DetectionLine
          key={line.id}
          directionOfTravel={
            line.movementDirection
              ? (line.movementDirection as directionOfTravel)
              : directionOfTravel.OFF
          }
          aspectRatio={aspectRatio}
          mouseDown={!!data.mouseDown}
          color={getRegionColor(line.slotNumber ?? -1)}
          index={line.index ?? -1}
          renderX={xCoordinateRenderer}
          renderY={yCoordinateRenderer}
          coordinates={(line.geometry.coordinates as Coordinates[]) ?? []}
          activeCoordinateIndexes={
            (line.activeCoordinateIndexes as number[]) ?? []
          }
          isActive={activeDetectionLineIndex === line.index}
        />
      ))}
    </Svg>
  );
}

export default DetectionElements;
