import {
  systemIsOnline,
  systemIsScheduledForFutureInstall,
  systemIsSite,
} from "../../utils/control-systems";

import { sleep } from "common/utils/universal/promise";
import { handleSavingTags } from "components/TagField/TagField.tsx";
import { omit } from "ramda";

/**
 * @ngdoc object
 * @name App.controller:ControlSystemCtrl
 *
 *
 * @description
 *  Controller for the Control System screen.
 */
App.controller("ControlSystemCtrl", [
  "$scope",
  "$q",
  "$rootScope",
  "$interval",
  "$stateParams",
  "$timeout",
  "UserService",
  "ControlSystemService",
  "ControlSystemsService",
  "PanelProgrammingService",
  "PROPS",
  "$modal",
  "$state",
  "COUNTRY_CODES",
  "$filter",
  "$location",
  "$anchorScroll",
  "PanelOD",
  "DealerCharges",
  "TIME_ZONES",
  "DealerService",
  "ProgramDefaultWorkflowService",
  "DealerDefaultsService",
  "ProgrammingTemplateService",
  "DEALER_API_DEFAULTS",
  "JobSchedulerService",
  "VALIDATION_PATTERNS",
  "ClientEventsService",
  "OnlinePanelService",
  "DealerReceiversService",
  "ThirdPartyVideoService",
  "CustomRolesService",
  "PanelCapabilitiesService",
  "ScheduledJobService",
  "SensorResetAPI",
  "ServiceRequestService",
  "DAYS_FOR_COMP_PROGRAMING",
  "DashboardDataService",
  "$compile",
  "PricingAPI",
  "GoogleAnalyticsService",
  "PanelDefinitionService",
  "$localStorage",
  "Panel",
  "$window",
  "STATE_CODES",
  "InitialConnectionService",
  "SimManagementService",
  "PanelEventsService",
  function (
    $scope,
    $q,
    $rootScope,
    $interval,
    $stateParams,
    $timeout,
    UserService,
    ControlSystemService,
    ControlSystemsService,
    PanelProgrammingService,
    PROPS,
    $modal,
    $state,
    COUNTRY_CODES,
    $filter,
    $location,
    $anchorScroll,
    PanelOD,
    DealerCharges,
    TIME_ZONES,
    DealerService,
    ProgramDefaultWorkflowService,
    DealerDefaultsService,
    ProgrammingTemplateService,
    DEALER_API_DEFAULTS,
    JobSchedulerService,
    VALIDATION_PATTERNS,
    ClientEventsService,
    OnlinePanelService,
    DealerReceiversService,
    ThirdPartyVideoService,
    CustomRolesService,
    PanelCapabilitiesService,
    ScheduledJobService,
    SensorResetAPI,
    ServiceRequestService,
    DAYS_FOR_COMP_PROGRAMING,
    DashboardDataService,
    $compile,
    PricingAPI,
    GoogleAnalyticsService,
    PanelDefinitionService,
    $localStorage,
    Panel,
    $window,
    STATE_CODES,
    InitialConnectionService,
    SimManagementService,
    PanelEventsService
  ) {
    //$scope variables
    $scope.PROPS = PROPS;
    $scope.defaultWorkflowKey = "";
    $scope.pricing_level = "";
    $scope.bool_type = "check";
    $scope.selectedADefault = selectedADefault;
    $scope.setForm = setForm;
    $scope.optionExists = optionExists;
    $scope.resetForm = resetForm;
    $scope.setControlSystem = setControlSystem;
    $scope.setDoorAccessLevelDefault = setDoorAccessLevelDefault;
    $scope.selectedType = selectedType;
    $scope.selectedCommType = selectedCommType;
    $scope.refreshZones = refreshZones;
    $scope.refreshSystemOptions = refreshSystemOptions;
    $scope.refreshDevices = refreshDevices;
    $scope.getSimStatus = getSimStatus;
    $scope.findSIM = findSIM;
    $scope.simChanged = simChanged;
    $scope.navDelete = navDelete;
    $scope.navCancel = navCancel;
    $scope.navBack = navBack;
    $scope.saveLocalValidate = saveLocalValidate;
    $scope.activateSIMwithValidations = activateSIMwithValidations;
    $scope.deactivateSIMwithValidations = deactivateSIMwithValidations;
    $scope.openDeactivateModal = openDeactivateModal;
    $scope.copyAddress = copyAddress;
    $scope.openErrorModal = openErrorModal;
    $scope.openConfirmAdvacneReportModal = openConfirmAdvanceReportModal;
    $scope.openUnauthorizedModal = openUnauthorizedModal;

    $scope.changeVideoLevel = changeVideoLevel;
    $scope.init = init;
    $scope.showArmingApp = showArmingApp;
    $scope.checkEasyConnect = checkEasyConnect;
    $scope.checkModemStatus = checkModemStatus;
    $scope.openReplacePanelModal = openReplacePanelModal;
    $scope.openChangeAccountNumberRemoteKeyModal =
      openChangeAccountNumberRemoteKeyModal;
    $scope.openDefaultProgrammingModal = openDefaultProgrammingModal;
    $scope.openCellActivationModal = openCellActivationModal;
    $scope.requireSerialNumber = requireSerialNumber;
    $scope.checkAutoProgStatus = checkAutoProgStatus;
    $scope.abortAutoProgramming = abortAutoProgramming;
    $scope.stopChecking = stopChecking;
    $scope.disableForCell = disableForCell;
    $scope.isAdvancedReportsSupported = isAdvancedReportsSupported;
    $scope.saveDwInfo = saveDwInfo;
    $scope.deleteDwInfo = deleteDwInfo;
    $scope.checkSettingsForSave = checkSettingsForSave;
    $scope.CSC = ControlSystemsService; //Used to access ControlSystemsService properties on $scope. Used interchangeably throughout the ctrl.
    $scope.UserService = UserService;
    $scope.PanelCapabilitiesService = PanelCapabilitiesService;
    $scope.$localStorage = $localStorage;
    $scope.viewVariables = {};
    $scope.viewVariables.MAX_NUMBER_OF_LINES_BEFORE_SCROLL = 7;
    $scope.serviceRequest = {};
    $scope.pricing = {};
    const MILLI_SECONDS_IN_ONE_DAY = 1000 * 60 * 60 * 24;
    $scope.thisCurrentSystem = {};
    $scope.rebatePanelOpen = false;
    $scope.dualSimPanelOpen = false;
    $scope.isFirstNetSim = isFirstNetSim;
    $scope.disableButtonFirstNet = false;
    $scope.isFirstNetDisabled = isFirstNetDisabled;
    $scope.DWDelete = true;
    $scope.digitalWatchdog = {};
    $scope.digitalWatchdog.selected = "server";
    $scope.digitalWatchdog.types = [
      { id: 1, name: "Blackjack", value: "server" },
      { id: 2, name: "DW Cloud", value: "cloud" },
    ];

    $scope.numberOfCredentialsOptions = [
      "50",
      "100",
      "250",
      "500",
      "750",
      "1000",
    ];

    if ($stateParams.customer_id)
      UserService.customer_id = $stateParams.customer_id;
    UserService.control_system_id = $stateParams.control_system_id;
    $scope.customerId = UserService.customer_id;
    $scope.controlSystemId = $stateParams.control_system_id;
    $scope.loader = {};
    $scope.loader.isBusy = true;
    $scope.controlSystems = {};
    $scope.sysForm = {};
    $scope.autoProgStatus = {};
    $scope.autoProgStatus.status = "unknown";
    $scope.panelOnDemand = {};
    $scope.COUNTRY_CODES = COUNTRY_CODES;
    $scope.useBilling = {
      show: true,
    };
    $scope.timeZones = TIME_ZONES;
    $scope.ProgramDefaultWorkflowService = ProgramDefaultWorkflowService;
    $scope.sendDefault = sendDefault;
    $scope.ClientEventsService = ClientEventsService;
    $scope.OnlinePanelService = OnlinePanelService;
    $scope.bringOnline = bringOnline;
    $scope.restoringOnline = false;
    $scope.listReceivers = false;
    $scope.dealerReceivers = [];
    //used for ng-placeholder
    $scope.serialNumberPlaceHolder = "Serial Number";
    $scope.nvrConnectInfo = {};
    $scope.hikVision = {};
    $scope.hikVision.connectNewDevice = false;
    $scope.hikVision.editHikVision = false;
    $scope.nvrErrors = [];
    $scope.hikVision.refresh = false;
    $scope.thirdPartyBusy = false;
    $scope.theDealer = UserService.dealerInfo;
    $scope.personnel = [];
    $scope.checkedByDefault = true;
    $scope.prepareToSave = prepareToSave;
    $scope.isSaveButtonClicked = false;
    $scope.sim1HasStatus = false;
    $scope.sim2HasStatus = false;
    $scope.CSC.sim1 = {};
    $scope.CSC.sim2 = {};
    $scope.carrierIconSim1 = "";
    $scope.carrierIconAltSim1 = "";
    $scope.carrierIconSim2 = "";
    $scope.carrierIconAltSim2 = "";
    $scope.isDualSimDeactivated = true;
    $rootScope.canSaveBillingTags = true;

    // This function is used to debounce the save button
    // so that the user cannot click the save button multiple times
    $scope.debounceSaveCall = function () {
      if (!$scope.isSaveButtonClicked) {
        $scope.isSaveButtonClicked = true;
        // Call  save function here
        $scope.checkSettingsForSave(true);

        // Reset after a delay
        setTimeout(function () {
          $scope.isSaveButtonClicked = false;
          // If the AngularJS digest cycle is not already in progress
          // then apply the changes to the scope by calling $apply
          if (!$scope.$$phase) $scope.$apply();
        }, 1000);
      }
    };

    $scope.numDoorbellOptions = [
      { value: 1, display: "1 Doorbell" },
      { value: 2, display: "2 Doorbells" },
      { value: 3, display: "3 Doorbells" },
      { value: 4, display: "4 Doorbells" },
      { value: 5, display: "5 Doorbells" },
      { value: 6, display: "6 Doorbells" },
      { value: 7, display: "7 Doorbells" },
      { value: 8, display: "8 Doorbells" },
    ];
    $scope.numVideoAnalyticsRecorderOptions = [
      { value: 1, display: "1  Recorder" },
      { value: 2, display: "2  Recorders" },
      { value: 3, display: "3  Recorders" },
      { value: 4, display: "4  Recorders" },
      { value: 5, display: "5  Recorders" },
    ];

    $scope.installMaxDate = null;
    let previousSimStatus;
    $scope.pollingFirstNetSimActive = false;
    $scope.preProgram = {
      models: [
        "new",
        "XT30",
        "XT50",
        "XR550",
        "XR150",
        "XTLP",
        "CellComSL",
        "CellComEX",
        "iComSL",
        "DualCom",
      ],
      versions: [],
      click: function () {
        updateVersions();
      },
    };
    $scope.autoActivation = { activate: false };
    var pristineControlSystem = {};
    var servicesCart = {};

    var originalRate = angular.copy($scope.CSC.sim.selected_rate_plan),
      originalText = angular.copy($scope.CSC.sim.selected_text_plan);
    var dealerService;
    var AUTO_PROG_CHECK_TIMES = 200;
    $scope.VALIDATION_PATTERNS = VALIDATION_PATTERNS;
    $scope.miniCellComPlanChanged = false;
    var originalSerialNumber = angular.copy(
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].serial_number
    );
    $scope.simStatusRequested = false;

    var originalAccountNumber = angular.copy(
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].account_number
    );
    var originalRemoteKey = angular.copy(
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].remote_key
    );
    var originalReceiverNumber = angular.copy(
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].account_prefix
    );
    $scope.validVernaculars = UserService.dealerInfo.vernaculars;
    $scope.pricingPlan = {};
    $scope.pricingPlan.selectedPlan = "";
    $scope.pricingPlan.automation;
    $scope.location = $location;
    $scope.autoProg = {};
    $scope.xrEnabled = {};
    $scope.autoProg.isChecked = false;
    $scope.panelSupportsTemplates = true;
    let isDSC;
    $scope.duplicateAccountNumberFound = false;
    $scope.STATE_CODES = STATE_CODES;
    const today = new Date().setHours(0, 0, 0, 0);

    function supportsZwave() {
      return !(
        ["CellComEX", "XR500"].includes(
          $scope.controlSystem.panels[0].hardware_model
        ) ||
        ["XF6", "TMS6"].includes(
          $scope.controlSystem.panels[0].programming_type
        )
      );
    }
    $scope.supportsZwave = supportsZwave;

    function supportsSchedules() {
      return (
        $scope.controlSystem.panels[0].hardware_model !== "CellComEX" &&
        $scope.controlSystem.panels[0].programming_type !== "XF6"
      );
    }
    $scope.supportsSchedules = supportsSchedules;

    $scope.selectFeaturePackage = function (packageName) {
      switch (packageName) {
        case "basic":
          $scope.pricingPlan.selectedPlan = "basic";
          turnOnFeatures("basic");
          break;
        case "standard":
          $scope.pricingPlan.selectedPlan = "standard";
          turnOnFeatures("standard");
          break;
        case "premium":
          $scope.pricingPlan.selectedPlan = "premium";
          turnOnFeatures("premium");
          break;
        case "none":
          $scope.pricingPlan.selectedPlan = "";
          turnOnFeatures("none");
          break;
        default:
          $scope.pricingPlan.selectedPlan = "";
          turnOnFeatures("none");
          break;
      }

      $scope.setNumDoorbells();
    };

    var getVkPackageAndServices = function () {
      const hardwareFamily = getHardwareFamilyFromHardwareModel(
        $scope.controlSystem.panels[0].hardware_model
      );

      if ($scope.controlSystem.services_manager.arming_app_enabled) {
        $scope.pricingPlan.selectedPlan = "basic";
      } else if ($scope.controlSystem.services_manager.full_app_enabled) {
        ($scope.controlSystem.services_manager.door_edit_enabled &&
          $scope.controlSystem.services_manager.real_time_events_enabled) ||
        ($scope.controlSystem.services_manager.hikvision_doorbell_enabled &&
          hardwareFamily !== "XR550" &&
          $scope.controlSystem.panels[0].programming_type !== "XF6")
          ? ($scope.pricingPlan.selectedPlan = "premium")
          : ($scope.pricingPlan.selectedPlan = "standard");
      } else {
        $scope.pricingPlan.selectedPlan = "none";
      }
    };

    function setAutomation() {
      if (
        supportsZwave() &&
        $scope.controlSystem.services_manager.thermostat_control_enabled &&
        $scope.controlSystem.services_manager.light_control_enabled &&
        $scope.controlSystem.services_manager.lock_control_enabled
      ) {
        $scope.pricingPlan.automation = true;
      } else {
        $scope.pricingPlan.automation = false;
      }
    }

    function setAutomationEditing() {
      if (
        $scope.controlSystem.services_manager.favorite_edit_enabled ||
        $scope.controlSystem.services_manager.zwave_node_edit_enabled ||
        $scope.controlSystem.services_manager.output_option_edit_enabled
      ) {
        $scope.pricingPlan.automation_edit = true;
        $scope.controlSystem.services_manager.favorite_edit_enabled = true;
        $scope.controlSystem.services_manager.zwave_node_edit_enabled = true;
        $scope.controlSystem.services_manager.output_option_edit_enabled = true;
      } else {
        $scope.pricingPlan.automation_edit = false;
      }
    }

    function setAdvancedReportsEditing() {
      $scope.controlSystem.services_manager.door_access_level = "all";
    }

    var turnOnFeatures = function (pkg) {
      let turnOnXTSpecificFeatures =
        $scope.controlSystem.installation_type == "residential" ||
        ($scope.controlSystem.installation_type == null &&
          $scope.isXTorCellCom(
            $scope.controlSystem.panels[0].programming_type
          )) ||
        $scope.isXTorCellCom($scope.controlSystem.panels[0].programming_type);

      function togglePremiumServices(val) {
        if (turnOnXTSpecificFeatures) {
          $scope.controlSystem.services_manager.hikvision_doorbell_enabled =
            val;
        } else {
          $scope.controlSystem.services_manager.door_edit_enabled = val;
          $scope.controlSystem.services_manager.real_time_events_enabled = val;
          setAdvancedReportsEditing();
        }
      }

      switch (pkg) {
        case "standard":
          $scope.controlSystem.services_manager.arming_app_enabled = false;
          $scope.controlSystem.services_manager.full_app_enabled = true;
          $scope.controlSystem.services_manager.schedule_edit_enabled =
            supportsSchedules();
          $scope.controlSystem.services_manager.geofencing_enabled = true;
          $scope.controlSystem.services_manager.user_code_edit_enabled = true;
          togglePremiumServices(false);
          break;

        case "premium":
          $scope.controlSystem.services_manager.arming_app_enabled = false;
          $scope.controlSystem.services_manager.full_app_enabled = true;
          $scope.controlSystem.services_manager.schedule_edit_enabled =
            supportsSchedules();
          $scope.controlSystem.services_manager.geofencing_enabled = true;
          $scope.controlSystem.services_manager.user_code_edit_enabled = true;
          togglePremiumServices(true);
          break;

        case "basic":
          $scope.controlSystem.services_manager.arming_app_enabled = true;
          $scope.controlSystem.services_manager.full_app_enabled = false;
          $scope.controlSystem.services_manager.schedule_edit_enabled = false;
          $scope.controlSystem.services_manager.geofencing_enabled = false;
          $scope.controlSystem.services_manager.thermostat_control_enabled = false;
          $scope.controlSystem.services_manager.light_control_enabled = false;
          $scope.controlSystem.services_manager.lock_control_enabled = false;
          $scope.controlSystem.services_manager.user_code_edit_enabled = false;
          togglePremiumServices(false);
          break;

        default:
          $scope.controlSystem.services_manager.arming_app_enabled = false;
          $scope.controlSystem.services_manager.full_app_enabled = false;
          $scope.controlSystem.services_manager.schedule_edit_enabled = false;
          $scope.controlSystem.services_manager.geofencing_enabled = false;
          $scope.controlSystem.services_manager.thermostat_control_enabled = false;
          $scope.controlSystem.services_manager.light_control_enabled = false;
          $scope.controlSystem.services_manager.lock_control_enabled = false;
          $scope.controlSystem.services_manager.user_code_edit_enabled = false;
          togglePremiumServices(false);
      }
    };

    // function showECPZones() {
    //   if (PanelCapabilitiesService.isCellComECP($scope.controlSystem.panels[0])) {
    //     // We need to filter out any Vista zones from the sensor activity list.
    //     let allZones = $scope.controlSystem.panels[0].sensor_activity_zones;
    //     let dmpZones = allZones.filter(zone => zone.number > 200);
    //     $scope.controlSystem.panels[0].sensor_activity_zones = dmpZones;
    //   }
    // }

    // $scope.getSensorActivityZones = function() {
    //   if (PanelCapabilitiesService.isCellComECP($scope.controlSystem.panels[0])) {
    //     if ($scope.controlSystem.panels[$scope.controlSystem.panel_index].sensor_activity_zones) {
    //       return $scope.controlSystem.panels[$scope.controlSystem.panel_index].sensor_activity_zones.filter((zone) => zone.number > 200);
    //     }
    //   } else {
    //     return $scope.controlSystem.panels[$scope.controlSystem.panel_index].sensor_activity_zones
    //   }
    // }

    $scope.isDmpZone = function (zone, index, list) {
      if (
        PanelCapabilitiesService.isCellComECP($scope.controlSystem.panels[0])
      ) {
        return zone.number > 200;
      } else {
        return true;
      }
    };

    $scope.isDSCZone = function (zone) {
      if (isDSC) return zone.number > 200;
      else return true;
    };

    function isDSCPanel() {
      if (!$scope.controlSystem.isNew) {
        PanelCapabilitiesService.isCellComDSC(
          $scope.controlSystem.panels[0]
        ).then(
          function (data) {
            data === true ? (isDSC = true) : (isDSC = false);
          },
          function (fail) {
            isDSC = false;
          }
        );
      }
    }

    $scope.supportsVideoAnalytics = () =>
      PanelCapabilitiesService.supportsVideoAnalytics(
        $scope.controlSystem.panels[0]
      );

    $scope.checkDuplicateAccountNumber = async () => {
      let duplicateAccountNumberFound = await isDuplicateAccountNumber(
        $scope.controlSystem.panels[0].account_prefix,
        $scope.controlSystem.panels[0].account_number
      );

      $scope.$apply(function () {
        $scope.duplicateAccountNumberFound = duplicateAccountNumberFound;
      });
    };

    $scope.checkDuplicateSerialNumber = async () => {
      const duplicateSerialNumberFound = await isDuplicateSerialNumber(
        $scope.controlSystem.panels[0].serial_number
      );

      $scope.$apply(function () {
        $scope.duplicateSerialNumberFound = duplicateSerialNumberFound;
      });
    };

    /**Checks submitted account number against the list of existing account numbers to see if it is not a match to the current and is a duplicate*/

    const isDuplicateAccountNumber = async (receiverPrefix, accountNumber) => {
      if (!receiverPrefix || !accountNumber) return false;

      const duplicates =
        await DashboardDataService.getPanelsByAccountNumberForDealer(
          UserService.dealer_id,
          receiverPrefix,
          accountNumber
        );

      if (duplicates.length === 0) return false;

      const foundMeInDuplicates = duplicates.filter(
        (duplicate) =>
          duplicate.id ===
          ControlSystemsService.currentControlSystem.panels[
            ControlSystemsService.currentControlSystem.panel_index
          ].id
      );
      return !!duplicates.length;
    };

    const isDuplicateSerialNumber = async (serialNumber) => {
      if (!serialNumber) {
        return false;
      }

      const duplicates =
        await DashboardDataService.getPanelsBySerialNumberForDealer(
          UserService.dealer_id,
          serialNumber
        );

      return !!duplicates.length;
    };

    /**
     * Creates and opens the Sensor Activity Edit modal
     */
    $scope.openSensorActivityEditModal = function (enabled) {
      if (enabled) {
        var sensorActivityEditModal = $modal.open({
          templateUrl:
            "app/control_system/templates/sensor-activity-modal-edit.html",
          size: "md",
          controller: "closeModalControl",
          backdrop: true,
          scope: $scope,
        });
      }
    };

    $scope.getChargeArmingApp = function () {
      let commType = $scope.controlSystem.panels[0].comm_type;
      if (commType === "cell" || commType === "persistent_w_cell_backup") {
        if ($scope.CSC.sim.selected_rate_plan) {
          return (
            $scope.pricing.ChargeArmingAppCell +
            $scope.getRatePlanPrice($scope.CSC.sim.selected_rate_plan)
          );
        } else {
          return $scope.pricing.ChargeArmingAppCell;
        }
      } else {
        return $scope.pricing.ChargeArmingAppNetwork;
      }
    };

    const dualSimPricing = (price) => price * 2 - 1;
    $scope.getRatePlanMap = () => {
      const areBothDualSimsActive =
        $scope.dualSimPanelOpen &&
        ($scope.CSC.sim1?.status_type === "active" ||
          $scope.CSC.sim1?.status_type === "active-pending") &&
        ($scope.CSC.sim2?.status_type === "active" ||
          $scope.CSC.sim2?.status_type === "active-pending");

      const ratePlanMap = {
        406: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlan406)
          : $scope.pricing.RatePlan406,
        408: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlan408)
          : $scope.pricing.RatePlan408,
        416: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlan416)
          : $scope.pricing.RatePlan416,
        425: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlan425)
          : $scope.pricing.RatePlan425,
        435: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlan435)
          : $scope.pricing.RatePlan435,
        445: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlan445)
          : $scope.pricing.RatePlan445,
        Backup: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlanBackup)
          : $scope.pricing.RatePlanBackup,
        CellComSL: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlanCellComSL)
          : $scope.pricing.RatePlanCellComSL,
        CellComEX: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlanCellComSL)
          : $scope.pricing.RatePlanCellComSL,
        410: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlanFire)
          : $scope.pricing.RatePlanFire,
        XR: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlanXR)
          : $scope.pricing.RatePlanXR,
        XT: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlanXT)
          : $scope.pricing.RatePlanXT,
        XTL: areBothDualSimsActive
          ? dualSimPricing($scope.pricing.RatePlanXTL)
          : $scope.pricing.RatePlanXTL,
      };
      ratePlanMap["FIRSTNET PRIMARY"] = areBothDualSimsActive
        ? dualSimPricing($scope.pricing.RatePlanFirstNet)
        : $scope.pricing.RatePlanFirstNet;
      ratePlanMap["FIRSTNET BACKUP"] = areBothDualSimsActive
        ? dualSimPricing($scope.pricing.RatePlanFirstNetBackup)
        : $scope.pricing.RatePlanFirstNetBackup;
      ratePlanMap["FIRSTNET FIRE"] = areBothDualSimsActive
        ? dualSimPricing($scope.pricing.RatePlanFirstNetFire)
        : $scope.pricing.RatePlanFirstNetFire;

      return ratePlanMap;
    };

    $scope.getRatePlanPrice = function (plan) {
      if ($scope.pricing) {
        const ratePlanMap = $scope.getRatePlanMap();

        return ratePlanMap[plan];
      }
    };

    $scope.getDualRatePlanPrices = function (plan) {
      const prices = $scope.pricing;
      const dualRatePlan = {
        406: {
          single: prices.RatePlan406,
          double: dualSimPricing($scope.pricing.RatePlan406),
        },
        408: {
          single: prices.RatePlan408,
          double: dualSimPricing($scope.pricing.RatePlan408),
        },
        416: {
          single: prices.RatePlan416,
          double: dualSimPricing($scope.pricing.RatePlan416),
        },
        425: {
          single: prices.RatePlan425,
          double: dualSimPricing($scope.pricing.RatePlan425),
        },
        435: {
          single: prices.RatePlan435,
          double: dualSimPricing($scope.pricing.RatePlan435),
        },
        445: {
          single: prices.RatePlan445,
          double: dualSimPricing($scope.pricing.RatePlan445),
        },
        Backup: {
          single: prices.RatePlanBackup,
          double: dualSimPricing($scope.pricing.RatePlanBackup),
        },
        CellComSL: {
          single: prices.RatePlanCellComSL,
          double: dualSimPricing($scope.pricing.RatePlanCellComSL),
        },
        CellComEX: {
          single: prices.RatePlanCellComEX,
          double: dualSimPricing($scope.pricing.RatePlanCellComEX),
        },
        410: {
          single: prices.RatePlanFire,
          double: dualSimPricing($scope.pricing.RatePlanFire),
        },
        XR: {
          single: prices.RatePlanXR,
          double: dualSimPricing($scope.pricing.RatePlanXR),
        },
        XT: {
          single: prices.RatePlanXT,
          double: dualSimPricing($scope.pricing.RatePlanXT),
        },
        XTL: {
          single: prices.RatePlanXTL,
          double: dualSimPricing($scope.pricing.RatePlanXTL),
        },
        "FIRSTNET PRIMARY": {
          single: prices.RatePlanFirstNet,
          double: dualSimPricing($scope.pricing.RatePlanFirstNet),
        },
        "FIRSTNET BACKUP": {
          single: prices.RatePlanFirstNetBackup,
          double: dualSimPricing($scope.pricing.RatePlanFirstNetBackup),
        },
        "FIRSTNET FIRE": {
          single: prices.RatePlanFirstNetFire,
          double: dualSimPricing($scope.pricing.RatePlanFirstNetFire),
        },
      };
      return dualRatePlan[plan];
    };

    function getAssociatedCosts(services_manager) {
      let commType = $scope.controlSystem.panels[0].comm_type;
      let total = 0;

      const servicesPriceMap = {
        traffic_count_enabled: $scope.pricing.ChargeTrafficCount,
        hikvision_doorbell_enabled:
          $scope.pricing.ChargeVideoDoorbell *
          $scope.controlSystem.services_manager.doorbell_total,
        video_verification_enabled: $scope.pricing.ChargeVideoVerification,
      };

      // add the prices for the rate plan
      angular.extend(servicesPriceMap, $scope.getRatePlanMap());

      servicesPriceMap["FIRSTNET PRIMARY"] = $scope.pricing.RatePlanFirstNet;
      servicesPriceMap["FIRSTNET BACKUP"] =
        $scope.pricing.RatePlanFirstNetBackup;
      servicesPriceMap["FIRSTNET FIRE"] = $scope.pricing.RatePlanFirstNetFire;

      // add price for doors with advanced reports
      if (services_manager.real_time_events_enabled) {
        angular.extend(servicesPriceMap, {
          real_time_events_enabled: getPriceAdvancedReports(),
        });
      }

      // add the price for the xV24
      if (services_manager.video_analytics_recorder_enabled) {
        angular.extend(servicesPriceMap, {
          video_analytics_recorder_enabled:
            $scope.pricing.AlarmVisionChannel *
            $scope.controlSystem.services_manager
              .video_analytics_recorder_cameras,
        });
      }

      // add the price for the XC cameras
      if (services_manager.ienso_cameras_enabled) {
        angular.extend(servicesPriceMap, {
          ienso_cameras_enabled:
            $scope.pricing.AlarmVisionChannel *
            $scope.controlSystem.services_manager.ienso_camera_count,
        });
      }

      // add the price for the sensor_activity
      if (services_manager.sensor_activity_enabled) {
        angular.extend(servicesPriceMap, {
          sensor_activity_enabled: $scope.pricing.ChargeSensorActivity,
        });
      }

      // add the price of the full app
      if (services_manager.full_app_enabled) {
        switch (commType) {
          case "network":
          case "persistent": {
            angular.extend(servicesPriceMap, {
              full_app_enabled: $scope.pricing.ChargeFullAppNetwork,
            });
            break;
          }
          case "persistent_w_cell_backup": {
            // The price is different for panels using cell or cell backup with non DMP sims (charge full app network cell other network)
            if (
              $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .comm_address &&
              !$scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .sim_identifier
            ) {
              angular.extend(servicesPriceMap, {
                full_app_enabled: $scope.pricing.ChargeFullAppNetworkCellOther,
              });
            } else {
              angular.extend(servicesPriceMap, {
                full_app_enabled: $scope.pricing.ChargeFullAppNetworkCellBackup,
              });
            }
            break;
          }
          case "cell": {
            // The price is different for panels using cell or cell backup with non DMP sims (charge full app network cell other network)
            if (
              $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .comm_address &&
              !$scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .sim_identifier
            ) {
              angular.extend(servicesPriceMap, {
                full_app_enabled: $scope.pricing.ChargeFullAppCellOther,
              });
            } else {
              angular.extend(servicesPriceMap, {
                full_app_enabled: $scope.pricing.ChargeFullAppCell,
              });
            }
            break;
          }
          default: {
            break;
          }
        }
      }

      // add the price of the arming app
      if (services_manager.arming_app_enabled) {
        if (commType === "cell") {
          angular.extend(servicesPriceMap, {
            arming_app_enabled: $scope.pricing.ChargeArmingAppCell,
          });
        } else if (!commType) {
        } else {
          angular.extend(servicesPriceMap, {
            arming_app_enabled: $scope.pricing.ChargeArmingAppNetwork,
          });
        }
      }

      // A discount is given if the customer has automation AND securecom video
      if (
        services_manager.automation_enabled &&
        !services_manager.video_service_level
      ) {
        angular.extend(servicesPriceMap, {
          automation_enabled: $scope.pricing.ChargeZWaveOnly,
        });
      }

      // add the price for securecom cameras and nvr
      if (services_manager.video_level) {
        const cameraLevels = [
          "standard",
          "standard_plus_four",
          "standard_plus_eight",
        ];

        let currentCameras = 4;
        for (let i = 0; i < cameraLevels.length; i++) {
          if (services_manager[cameraLevels[i]]) {
            if (services_manager.automation_enabled) {
              angular.extend(servicesPriceMap, {
                video_service_level:
                  $scope.pricing[`ChargeZWave${currentCameras + 4}Video`],
              });
            } else {
              angular.extend(servicesPriceMap, {
                video_service_level:
                  $scope.pricing[`Charge${currentCameras + 4}Video`],
              });
              $scope.pricing_level =
                $scope.pricing[`Charge${currentCameras + 4}Video`];
            }
          }
          currentCameras += 4;
        }
      }
      angular.forEach(services_manager, function (value, service) {
        if (servicesPriceMap[service]) {
          total = total + servicesPriceMap[service];
        }
      });

      return total;
    }

    $scope.getPremiumAddOnPrice = function () {
      if (
        ($scope.controlSystem.installation_type == "commercial" ||
          !$scope.controlSystem.installation_type) &&
        !isXTFamily(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .programming_type
        )
      ) {
        return getPriceAdvancedReports();
      } else {
        return $scope.pricing.ChargeVideoDoorbell;
      }
    };

    $scope.getFullAppPrice = function () {
      let commType = $scope.controlSystem.panels[0].comm_type;
      switch (commType) {
        case "network":
        case "persistent": {
          return $scope.pricing.ChargeFullAppNetwork;
        }
        case "persistent_w_cell_backup": {
          // The price is different for panels using cell or cell backup with non DMP sims (charge full app network cell other network)
          if (
            $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .comm_address &&
            !$scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .sim_identifier
          ) {
            return $scope.pricing.ChargeFullAppNetworkCellOther;
          } else {
            return $scope.pricing.ChargeFullAppNetworkCellBackup;
          }
        }
        case "cell": {
          // The price is different for panels using cell or cell backup with non DMP sims (charge full app network cell other network)
          if (
            $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .comm_address &&
            !$scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .sim_identifier
          ) {
            return $scope.pricing.ChargeFullAppCellOther;
          } else {
            return $scope.pricing.ChargeFullAppCell;
          }
        }

        default:
          return;
      }
    };

    function getPriceAdvancedReports() {
      let commType = $scope.controlSystem.panels[0].comm_type;
      let numDoors = $scope.controlSystem.panels[0].device_informations.filter(
        (door) => door.tracked
      ).length;

      if ($scope.controlSystem.services_manager.door_edit_enabled) {
        if (commType === "cell") {
          return $scope.pricing.ChargeAdvancedReportsCell * numDoors;
        } else {
          return $scope.pricing.ChargeAdvancedReportsNetwork * numDoors;
        }
      } else {
        return 0;
      }
    }

    $scope.$watch(
      "controlSystem.services_manager",
      function () {
        if ($scope.pricing) {
          $scope.getSelectedServices();
        }
      },
      true
    );

    $scope.$watchGroup(
      [
        function () {
          return $scope.CSC.sim1?.status_type;
        },
        function () {
          return $scope.CSC.sim2?.status_type;
        },
      ],
      function (newValues, oldValues) {
        const sim1New = newValues[0];
        const sim1Old = oldValues[0];
        const sim2New = newValues[1];
        const sim2Old = oldValues[1];

        if (
          (sim1New !== sim1Old && sim1New !== "Please Wait...") ||
          (sim2New !== sim2Old && sim2New !== "Please Wait...")
        ) {
          if ($scope.pricing) {
            $scope.getSelectedServices();
          }
        }
      }
    );

    $scope.pristinePanelIsVideoOnly = function () {
      return pristineControlSystem.panels[0].hardware_model === "Video Only";
    };

    $scope.clearZipAndState = function () {
      controlSystem.state_province = undefined;
      controlSystem.postal_code = undefined;
    };

    $scope.getSelectedServices = function () {
      let services_manager = $scope.controlSystem.services_manager;
      let services = angular.copy(services_manager);
      let servicesCart = {};

      // Add the rate plan
      if ($scope.CSC.sim.selected_rate_plan) {
        servicesCart[$scope.CSC.sim.selected_rate_plan] = true;
      }

      angular.forEach(services, function (value, key) {
        if (value === true) servicesCart[key] = value;
      });

      if (
        services.thermostat_control_enabled ||
        services.light_control_enabled ||
        services.lock_control_enabled
      ) {
        angular.extend(servicesCart, { automation_enabled: true });
        delete servicesCart.thermostat_control_enabled;
        delete servicesCart.light_control_enabled;
        delete servicesCart.lock_control_enabled;
        delete servicesCart.favorite_edit_enabled;
        delete servicesCart.zwave_node_edit_enabled;
        delete servicesCart.output_option_edit_enabled;
      }

      // Show the video service level inside the cart
      if (services.video_level === "premium") {
        let videoLevelObj = {};
        videoLevelObj[services.video_service_level] = true;

        angular.extend(servicesCart, videoLevelObj);
        angular.extend(servicesCart, { video_level: true });
        angular.extend(servicesCart, { video_service_level: true });
      }

      // We don't want to show any services if the arming app is chosen
      if (services.arming_app_enabled) {
        angular.forEach(servicesCart, function (val, key) {
          if (key !== "arming_app_enabled") {
            delete servicesCart[key];
          }
        });
      }

      // We don't want to show any services if neither app is chosen
      if (!services.arming_app_enabled && !services.full_app_enabled) {
        angular.forEach(servicesCart, function (val, key) {
          delete servicesCart[key];
        });
      }

      $scope.totalCost = getAssociatedCosts(servicesCart);
      $scope.pricing.totalCost = $scope.totalCost;
      $scope.pricing.totalCostArming = $scope.getChargeArmingApp();
    };

    /**
     * Creates and opens the Sensor Activity Edit modal
     */
    $scope.openTrackedOutputEditModal = function () {
      var trackedOutputEditModal = $modal.open({
        templateUrl:
          "app/control_system/templates/tracked-output-modal-edit.html",
        size: "md",
        controller: "closeModalControl",
        backdrop: true,
        scope: $scope,
      });
    };

    /**
     * Creates and opens the Door Control Edit modal
     */
    $scope.openDoorControlEditModal = function (enabled) {
      if (enabled) {
        var doorControlEditModal = $modal.open({
          templateUrl: "app/control_system/templates/door-control-modal.html",
          size: "md",
          controller: "closeModalControl",
          backdrop: true,
          scope: $scope,
        });
      }
    };

    /**
     * Creates and opens the Hikvision modal
     */
    $scope.openHikvisionModal = function (enabled) {
      if (enabled) {
        var hikvisionModal = $modal.open({
          templateUrl: "app/control_system/templates/hikvision-modal.html",
          size: "md",
          controller: "closeModalControl",
          backdrop: true,
          scope: $scope,
        });
      }
    };

    /**
     * Creates and opens the VAR enabled modal
     */
    $scope.openVideoAnalyticsRecorderEnabledModal = function () {
      var videoAnalyticsRecorderEnabledModal = $modal.open({
        templateUrl:
          "app/control_system/templates/video-analytics-recorder-enabled-modal.html",
        size: "md",
        controller: "videoAnalyticsRecorderEnabledModalControl",
        backdrop: "static",
        keyboard: false,
        scope: $scope,
      });
    };

    /**
     * Creates and opens a modal to display status messages during first connect
     **/
    $scope.openLoginAsCustomerModal = function (systemId) {
      var statusModal = {};
      var deferred = $q.defer();
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          statusModal = $modal.open({
            templateUrl: "app/common/templates/temp-user-modal-tpl.html",
            controller: "TempUserModalCtrl",
            size: "md",
            backdrop: "static",
            resolve: {
              systemId: function () {
                return systemId;
              },
            },
          });
          statusModal.result.then(
            function (result) {
              if (result.createdTempUser) {
                deferred.resolve();
              } else {
                deferred.reject();
              }
            },
            function () {
              deferred.reject();
            }
          );
          break;
        case "techApp":
          console.warn("daTempAppUser directive does not support TechApp");
          deferred.reject();
          break;
        default:
          console.warn(
            "daTempAppUser directive does not support $rootScope.appProperties.type: " +
              $rootScope.appProperties.type
          );
          deferred.reject();
          break;
      }
      return deferred.promise;
    };

    /**
     * Creates and opens the dw-spectrum modal
     */
    $scope.openDwSpectrumModal = function (enabled) {
      if (enabled) {
        $scope.digitalWatchdog.selected = $scope.dwCloud ? "cloud" : "server";

        $scope.dwModal = $modal.open({
          templateUrl: "app/control_system/templates/dw-spectrum-modal.html",
          size: "md",
          controller: "closeModalControl",
          backdrop: true,
          scope: $scope,
        });
      }
    };

    /**
     * Creates and opens the OpenEye modal
     */
    $scope.openOpeneyeModal = function (enabled) {
      if (enabled) {
        var openeyeModal = $modal.open({
          templateUrl: "app/control_system/templates/openeye-modal.html",
          size: "md",
          controller: "closeModalControl",
          backdrop: true,
          scope: $scope,
        });
      }
    };

    /**
     * Creates and opens the OpenEye modal
     */
    $scope.openEagleEyeModal = function (enabled) {
      if (enabled) {
        var eagleModal = $modal.open({
          templateUrl: "app/control_system/templates/eagleeye-modal.html",
          size: "md",
          controller: "closeModalControl",
          backdrop: true,
          scope: $scope,
        });
      }
    };

    /**
     * Creates and opens the openDoorbellReadOnlyModal modal
     */
    $scope.openDoorbellReadOnlyModal = function (device) {
      $scope.device = device;
      var doorbellReadOnlyModal = $modal.open({
        templateUrl:
          "app/control_system/templates/doorbell-read-only-modal.html",
        size: "md",
        controller: "closeModalControl",
        backdrop: true,
        scope: $scope,
      });
    };

    /**
     * Creates and opens the Hanwha Wave modal
     */
    $scope.hanwhaModal = function (device) {
      $scope.device = device;
      const hanwhaModal = $modal.open({
        templateUrl: "app/control_system/templates/hanwha-modal.html",
        size: "md",
        controller: "closeModalControl",
        backdrop: true,
        scope: $scope,
      });
    };

    //Destroy checking for auto programming status
    $scope.$on("$destroy", function () {
      $scope.stopChecking();
    });

    //Function to determine the state of the programming status alert
    $scope.programmingAlert = function (status) {
      var alert = {};

      switch (status) {
        case "new":
          alert.type = "info";
          alert.icon = "dmp-icon-radial_info";
          alert.status = "initiated";
          break;
        case "scheduled":
          alert.type = "info";
          alert.icon = "dmp-icon-time_clock";
          alert.status = "scheduled";
          break;
        case "running":
          alert.type = "info";
          alert.icon = "dmp-icon-radial_info";
          alert.status = "in progress";
          break;
        case "retry":
          alert.type = "warning";
          alert.icon = "dmp-icon-cycle icon-spin";
          alert.status = "retrying";
          break;
        case "complete":
          alert.type = "success";
          alert.icon = "dmp-icon-radial_check";
          alert.status = "complete";
          break;
        case "fail":
          alert.type = "danger";
          alert.icon = "dmp-icon-radial_alert";
          alert.status = "failed";
          break;
        default:
          alert.type = "danger";
          alert.icon = "dmp-icon-radial_alert";
          alert.status = "failed";
          break;
      }
      return alert;
    };
    $scope.simStatusAlert = function (status) {
      var alert = {};
      switch (status) {
        case "new":
          alert.icon = "icon-radial_check";
          alert.color_class = "text-dmp-green";
          break;
        case "active":
          alert.icon = "icon-radial_check";
          alert.color_class = "text-dmp-green";
          break;
        case "inactive":
          alert.icon = "icon-radial_question";
          alert.color_class = "text-dmp-danger";
          break;
        case "inactive-pending":
          alert.icon = "icon-radial_question";
          alert.color_class = "text-dmp-danger";
          break;
        case "unauthorized":
          alert.icon = "icon-radial_alert";
          alert.color_class = "text-dmp-danger";
          break;
        case "error":
          alert.icon = "icon-radial_question";
          alert.color_class = "text-dmp-danger";
          break;
        case "invalid / unknown":
          alert.icon = "icon-radial_question";
          alert.color_class = "text-dmp-danger";
          break;
        default:
          alert.icon = "icon-radial_question";
          alert.color_class = "text-dmp-danger";
          break;
      }
      return alert;
    };

    $scope.supportsAmbush = function () {
      return PanelCapabilitiesService.canSendAmbushNotifications(
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
      );
    };

    $scope.openPanel = function () {
      $scope.rebatePanelOpen = true;
    };

    $scope.onDualsim1Change = function () {
      $scope.controlSystem.sim_1_carrier = null;
      delete $scope.CSC.sim1["status_type"];
      $scope.controlSystem.dual_sim = null;
      $scope.sim1HasStatus = false;
    };

    $scope.onDualsim2Change = function () {
      $scope.controlSystem.sim_2_carrier = null;
      delete $scope.CSC.sim2["status_type"];
      $scope.controlSystem.dual_sim = null;
      $scope.sim2HasStatus = false;
    };

    $scope.isActiveOrActivePending = function (status) {
      return status === "active" || status === "active-pending";
    };

    $scope.areDualSimsOffset = function () {
      const sim1 = $scope.CSC.sim1;
      const sim2 = $scope.CSC.sim2;

      const isSim1ActiveOnly =
        $scope.isActiveOrActivePending(sim1?.status_type) &&
        !$scope.isActiveOrActivePending(sim2?.status_type);
      const isSim2ActiveOnly = !$scope.isActiveOrActivePending(
        sim1?.status_type
      );
      $scope.isActiveOrActivePending(sim2?.status_type);

      return isSim1ActiveOnly || isSim2ActiveOnly;
    };

    $scope.areBothDualSimsActive = function () {
      const sim1 = $scope.CSC.sim1;
      const sim2 = $scope.CSC.sim2;

      const isSim1Active =
        $scope.isActiveOrActivePending(sim1?.status_type) && sim1.isAvailable;
      const isSim2Active =
        $scope.isActiveOrActivePending(sim2?.status_type) && sim2.isAvailable;

      return isSim1Active && isSim2Active;
    };

    /**
     * Returns a number that is respective to dual sim number
     * Should only be used if it is known that dual sim are offset
     */
    $scope.activeDualSim = function () {
      const sim1 = $scope.CSC.sim1;
      const sim2 = $scope.CSC.sim2;
      if ($scope.isActiveOrActivePending(sim1?.status_type)) {
        return 1;
      } else if ($scope.isActiveOrActivePending(sim2?.status_type)) {
        return 2;
      } else {
        return undefined;
      }
    };

    /**
     *
     * This function copies data from sim 0 to the inputted sim.
     * Simply pass the sim number to be overwritten as the parameter.
     * There are currently only three possible sim numbers: 0, 1, and 2.
     * sim 0 refers to the sim when dual sim is NOT activated, while sim 1 and sim 2 are the official numbers for the two sims when dual sim is activated.
     * From the API, the data for sim 0 lives in panel object, while the data for the dual sim data lives on the control system itself.
     *
     * @function copyFromSim0
     * @param {Number} simNumber - The SIM number of the SIM to be copied over.
     * @returns {void} This function does not return a value.
     */
    $scope.copyFromSim0 = function (simNumber) {
      const cscSim0 = $scope.CSC.sim;

      $scope.CSC[`sim${simNumber}`] = cscSim0;

      const panel = $scope.controlSystem.panels[0];
      const controlSystem = $scope.controlSystem;

      controlSystem[`sim_${simNumber}_identifier`] = panel.sim_identifier;
      controlSystem[`sim_${simNumber}_comm_address`] = panel.comm_address;
      controlSystem[`sim_${simNumber}_carrier`] = panel.sim_carrier
        ? panel.sim_carrier.toLowerCase()
        : "";
      $scope[`sim${simNumber}HasStatus`] = $scope.CSC.sim.isAvailable
        ? !!cscSim0
        : $scope.CSC.sim.isAvailable;
      $scope.pollingFirstNetSimActive = false;
      setCarrier(simNumber);
    };

    /**
     *
     * This function copies data from the given sim to sim 0. Inverse of copyFromSim0.
     * Simply pass the sim number to be coped from as the parameter.
     * There are currently only three possible sim numbers: 0, 1, and 2.
     * sim 0 refers to the sim when dual sim is NOT activated, while sim 1 and sim 2 are the official numbers for the two sims when dual sim is activated.
     * From the API, the data for sim 0 lives in panel object, while the data for the dual sim data lives on the control system itself.
     *
     * @function copyToSim0
     * @param {Number} simNumber - The SIM number of the SIM to be copied from.
     * @returns {void} This function does not return a value.
     */
    $scope.copyToSim0 = function (simNumber) {
      if (simNumber) {
        const cscSimToCopyFrom = $scope.CSC[`sim${simNumber}`];
        if (cscSimToCopyFrom.status_type !== "Please Wait...") {
          const sim0SelectedRatePlan = $scope.CSC.sim.selected_rate_plan;

          const statusFilter = ["new", "inactive-pending"];
          if (
            !cscSimToCopyFrom.selected_rate_plan ||
            $scope.isInactive(cscSimToCopyFrom.status_type) ||
            statusFilter.includes(cscSimToCopyFrom.status_type)
          ) {
            cscSimToCopyFrom.selected_rate_plan = sim0SelectedRatePlan;
          }
          $scope.CSC.sim = cscSimToCopyFrom;

          const panel = $scope.controlSystem.panels[0];
          const controlSystem = $scope.controlSystem;

          panel.sim_identifier = controlSystem[`sim_${simNumber}_identifier`];
          panel.comm_address = controlSystem[`sim_${simNumber}_comm_address`];
          if (controlSystem[`sim_${simNumber}_carrier`] !== null) {
            panel.sim_carrier =
              controlSystem[`sim_${simNumber}_carrier`].toLowerCase();
          }
          $scope.pollingFirstNetSimActive = false;
        }
      }
    };

    $scope.clearDualSim = function () {
      $scope.controlSystem.sim_1_carrier = null;
      $scope.controlSystem.sim_1_comm_address = null;
      $scope.controlSystem.sim_1_identifier = null;
      $scope.controlSystem.sim_2_carrier = null;
      $scope.controlSystem.sim_2_comm_address = null;
      $scope.controlSystem.sim_2_identifier = null;
      $scope.controlSystem.dual_sim = null;
      $scope.sim1HasStatus = false;
      $scope.sim2HasStatus = false;
      $scope.CSC.sim1 = {};
      $scope.CSC.sim2 = {};
    };

    $scope.toggleDualSimPanel = function () {
      const previousToggle = $scope.dualSimPanelOpen;

      if (
        !previousToggle &&
        $scope.controlSystem.panels[0].sim_identifier &&
        !$scope.CSC.sim1.identifier &&
        !$scope.CSC.sim2.identifier
      ) {
        //if we're turning dualsim on and we have sim0 but don't have sim1 and sim2
        $scope.copyFromSim0(1);
      } else if (
        previousToggle &&
        $scope.isInactive($scope.CSC.sim1?.status_type) &&
        $scope.isInactive($scope.CSC.sim2?.status_type)
      ) {
        //if we're turning dualsim off and both sim1 and sim2 are inactive
        $scope.copyToSim0(1);
        $scope.clearDualSim();
      } else if (
        previousToggle &&
        $scope.CSC.sim1 &&
        $scope.CSC.sim2 &&
        $scope.areDualSimsOffset()
      ) {
        //if we're turning dualsim off and only 1 of the dualsims are active
        $scope.copyToSim0($scope.activeDualSim());
        $scope.clearDualSim();
      }

      $scope.dualSimPanelOpen = !previousToggle;
    };

    $scope.canSaveDualSim = function () {
      const system = $scope.controlSystem;

      return (
        $scope.dualSimPanelOpen &&
        system.sim_1_carrier !== null &&
        system.sim_1_comm_address !== null &&
        system.sim_1_identifier !== null &&
        system.sim_2_carrier !== null &&
        system.sim_2_comm_address !== null &&
        system.sim_2_identifier !== null &&
        system.dual_sim !== null
      );
    };

    $scope.getSpanClass = function (status) {
      switch (status) {
        case "active":
          return "text-success-ada";
        case "new":
        case "active-pending":
        case "Please Wait...":
        case "Activating":
          return "text-primary-ada";
        default:
          return "text-danger-ada";
      }
    };

    $scope.getIconClass = function (status) {
      switch (status) {
        case "active":
          return "icon-radial_check text-success-ada";
        case "new":
        case "active-pending":
        case "Please Wait...":
        case "Activating":
          return "icon-radial_subtract text-primary-ada";
        default:
          return "icon-radial_warning text-danger-ada";
      }
    };

    $scope.isPending = function (status) {
      if (
        status?.toLowerCase() === "activating" ||
        status?.toLowerCase() === "deactivating" ||
        status?.toLowerCase() === "inactive-pending" ||
        status?.toLowerCase() === "active-pending"
      ) {
        return true;
      }
      return false;
    };

    $scope.isInactive = function (status) {
      if (
        status?.toLowerCase() === "deactivating" ||
        status?.toLowerCase() === "inactive" ||
        status?.toLowerCase() === "inactive-pending"
      ) {
        return true;
      }
      return false;
    };

    $scope.saveCompetitorImage = function (file) {
      if (file) {
        $scope.competitorInfo.img = file;
        $scope.competitorInfo.imgName = file.name;
        CompetitorUpgradeService.getBase64Img(file)
          .then(
            function (base64Img) {
              $scope.competitorInfo.imgSrc = base64Img;
            },
            function (error) {}
          )
          .catch(function (error) {});
      } else {
        if ($scope.competitorUpgradeInfo.isNew) {
          var competitorImgInput = angular.element(
            document.querySelector("#competitor_image_url")
          );
          $compile(competitorImgInput)($scope);
        }
      }
    };

    $scope.getCompetitorImageSrc = () =>
      $scope.competitorInfo.imgSrc
        ? $scope.competitorInfo.imgSrc
        : `${PROPS.imageApiUrl}/Uploads/CompetitorUpgrades/${$scope.controlSystem.id}/upgrade-image/upgrade-image.jpg`;

    function getCompetitorUpgradeInfo() {
      CompetitorUpgradeService.getCompUpgradeInfo(
        UserService.dealerInfo.id,
        UserService.control_system_id
      )
        .then(
          function (upgradeInfo) {
            $scope.competitorUpgradeInfo = angular.copy(upgradeInfo);
            $scope.competitorUpgradeInfo.isNew = false;
            $scope.competitorInfo.imageToUpload = true;
          },
          function (error) {
            if (error.status === 404) {
              $scope.competitorUpgradeInfo.isNew = true;
            }
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    $scope.toggleZwaveEditAndFavorites = function () {
      if (
        $scope.controlSystem.services_manager.thermostat_control_enabled ||
        $scope.controlSystem.services_manager.light_control_enabled ||
        $scope.controlSystem.services_manager.lock_control_enabled
      ) {
        $scope.controlSystem.services_manager.favorite_edit_enabled = true;
        $scope.controlSystem.services_manager.zwave_node_edit_enabled = true;
        $scope.controlSystem.services_manager.output_option_edit_enabled = true;
      }
      if (
        !$scope.controlSystem.services_manager.thermostat_control_enabled &&
        !$scope.controlSystem.services_manager.light_control_enabled &&
        !$scope.controlSystem.services_manager.lock_control_enabled
      ) {
        $scope.controlSystem.services_manager.favorite_edit_enabled = false;
        $scope.controlSystem.services_manager.zwave_node_edit_enabled = false;
        $scope.controlSystem.services_manager.output_option_edit_enabled = false;
      }
    };

    $scope.toggleAutomation = function () {
      if ($scope.pricingPlan.automation) {
        $scope.controlSystem.services_manager.thermostat_control_enabled = true;
        $scope.controlSystem.services_manager.light_control_enabled = true;
        $scope.controlSystem.services_manager.lock_control_enabled = true;
      } else {
        $scope.controlSystem.services_manager.thermostat_control_enabled = false;
        $scope.controlSystem.services_manager.light_control_enabled = false;
        $scope.controlSystem.services_manager.lock_control_enabled = false;
      }

      $scope.toggleZwaveEditAndFavorites();
      setAutomationEditing();
    };

    $scope.toggleAutomationEdit = function () {
      if ($scope.pricingPlan.automation_edit) {
        $scope.controlSystem.services_manager.favorite_edit_enabled = true;
        $scope.controlSystem.services_manager.zwave_node_edit_enabled = true;
        $scope.controlSystem.services_manager.output_option_edit_enabled = true;
      } else {
        $scope.controlSystem.services_manager.favorite_edit_enabled = false;
        $scope.controlSystem.services_manager.zwave_node_edit_enabled = false;
        $scope.controlSystem.services_manager.output_option_edit_enabled = false;
      }
    };

    $scope.setNumDoorbells = function () {
      switch (
        $scope.controlSystem.services_manager.hikvision_doorbell_enabled
      ) {
        case true:
          if (!$scope.controlSystem.services_manager.doorbell_total)
            $scope.controlSystem.services_manager.doorbell_total = 1;
          break;
        case false:
          $scope.controlSystem.services_manager.doorbell_total = 0;
          break;
        default:
          $scope.controlSystem.services_manager.doorbell_total = 0;
          break;
      }
    };

    // enables the xv gateways and the xv(xc) cameras
    $scope.setNumVideoAnalyticsRecordersAndXcCameras = function () {
      switch (
        $scope.controlSystem.services_manager.video_analytics_recorder_enabled
      ) {
        case true:
          if (
            !$scope.controlSystem.services_manager
              .video_analytics_recorder_total
          ) {
            $scope.controlSystem.services_manager.video_analytics_recorder_total = 1;
            $scope.controlSystem.services_manager.ienso_cameras_enabled = true;
          }
          break;
        case false:
          $scope.controlSystem.services_manager.video_analytics_recorder_total = 0;
          $scope.controlSystem.services_manager.ienso_cameras_enabled = false;
          break;
        default:
          $scope.controlSystem.services_manager.video_analytics_recorder_total = 0;
          $scope.controlSystem.services_manager.ienso_cameras_enabled = false;
          break;
      }
    };

    const getRateplanForScheduledSimActivation = async () => {
      try {
        const sim = await SimManagementService.findScheduledSim(
          UserService.dealerInfo.dealer_code,
          $scope.controlSystem.panels[0].id
        );
        const rate_subplan = sim.rate_subplan;
        const iccid = sim.iccid;
        const imei = sim.imei;
        if (rate_subplan) {
          $scope.CSC.rate_subplan = rate_subplan ? rate_subplan : undefined;

          const result = rate_subplan.split("-");
          const ratePlan = result[0];
          const textPlan = result[1];
          $scope.CSC.sim.selected_rate_plan = ratePlan;

          $scope.CSC.sim.selected_text_plan = textPlan.replace("T", "");
        }

        if (iccid) {
          ControlSystemsService.getPlans(iccid);
          $scope.CSC.sim.isAvailable = true;
          $scope.CSC.sim.iccid = iccid;
          $scope.CSC.sim.identifier = iccid;
          $scope.CSC.sim.imei = imei;
        }
      } catch (e) {
        $rootScope.alerts.push({
          type: "error",
          text: "error retrieving scheduled activation information",
        });
      }
    };

    $scope.setInstallDateMax = () => {
      $scope.installMaxDate = $scope.controlSystem.panels[0].auto_program
        ? new Date(new Date().setDate(new Date().getDate() + 89)).setHours(
            0,
            0,
            0,
            0
          )
        : null;

      if (
        $scope.panelInDeferredBillingState() &&
        new Date($scope.controlSystem.installed_at).setHours(0, 0, 0, 0) >
          new Date($scope.installMaxDate).setHours(0, 0, 0, 0)
      ) {
        $scope.controlSystem.installed_at = null;
      }
    };

    //Initilizaiton
    function init() {
      dealerService = new DealerService({
        dealer_id: UserService.dealerInfo.id,
      });
      $scope.setControlSystem(); //Set control system
      pristineControlSystem = angular.copy($scope.controlSystem); // Create a pristine copy of the control system, so we can later compare for changes
      if ($stateParams.hardware_model) {
        $scope.controlSystem.panels[
          $scope.controlSystem.panel_index
        ].hardware_model = $stateParams.hardware_model;
        selectedType();
        $scope.getSelectedServices();
      }
      $scope.isInitialized =
        $scope.controlSystem.panels[0].programming_type === "XF6" ||
        $scope.controlSystem.panels[0].programming_type === "TMS6"
          ? $scope.controlSystem.panels[0].last_command_at !== null
          : $scope.controlSystem.panels[0].arming_system !== null;
      $scope.checkAutoProgStatus();
      getDefaults();
      if ($scope.controlSystem.isNew) {
        // Initialize sim1 and sim2 to empty
        if (UserService.dealerInfo.store_user_codes) {
          $scope.controlSystem.services_manager.store_user_codes = true;
        }
        if ($stateParams.simNumber) {
          $scope.controlSystem.panels[0].sim_identifier =
            $stateParams.simNumber;
          $scope.controlSystem.panels[0].comm_type = "cell";
        }
        //Set Dealer's Defaults
        DealerDefaultsService.getDealerDefaults(
          UserService.dealerInfo.id,
          "services_defaults",
          null
        )
          .then(function (data) {
            var services_defaults = data;
            delete services_defaults.id;
            delete services_defaults.dealer_id;
            delete services_defaults.$promise;
            delete services_defaults.$resolved;
            $scope.controlSystem.services_manager = angular.extend(
              $scope.controlSystem.services_manager,
              services_defaults
            );
            $scope.changeVideoLevel();
            getVkPackageAndServices();
            setAutomationEditing();
            setAutomation();
          })
          .catch(function (error) {
            console.error(error);
          });
      }

      if (
        !$scope.controlSystem.isNew &&
        $scope.panelInDeferredBillingState() &&
        ["cell", "persistent_w_cell_backup"].includes(
          $scope.controlSystem.panels[0].comm_type
        )
      ) {
        if (
          !(
            $rootScope.appProperties.type === "techApp" &&
            $state.is("dealer.customer.controlsystem.controlSystemLanding")
          )
        ) {
          getRateplanForScheduledSimActivation();
        }
      }

      PanelProgrammingService.panel_id = $scope.controlSystem.panels[0].id;

      DealerReceiversService.getAll()
        .then(
          function (data) {
            $scope.dealerReceivers = data;
            // For a new system that has receivers, set the default to the lowest numbered one. For the select control,
            // the value needs to be a string to automatically select it form options
            if (
              $scope.controlSystem.isNew &&
              $scope.dealerReceivers.length > 0
            ) {
              $scope.controlSystem.panels[0].account_prefix =
                data[0].receiver_number;
            }
          },
          function (error) {
            console.error(
              "ControlSystemCtrl->init() - Error getting receivers: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "error retrieving receivers",
              json: error,
            });
          }
        )
        .catch(function (error) {});
      // Only get third party devices on existing systems.
      if (!$scope.controlSystem.isNew) {
        getHikVisionDevices();
        getOpenEyeDevices();
        getHanwhaDevices();
        getEagleEyeDevices();
        getHikDoorbell();
        getDWConnectionInfo();
      }
      getVkPackageAndServices();
      setAutomationEditing();
      setAutomation();
      getTimeZones();
      getNumDoorbells();
      //get personnel for
      getPersonnelData();
      /**
       * DatePicker
       *
       */
      $scope.dateFilters = {};
      $scope.datePickerOpen = {};
      $scope.datePickerOpenModal = {};
      $scope.currentDate = new Date();
      $scope.todayMinus30Days = new Date(
        $scope.currentDate -
          DAYS_FOR_COMP_PROGRAMING.NUM * MILLI_SECONDS_IN_ONE_DAY
      );

      if ($scope.controlSystem.isNew) {
        $scope.controlSystem.installed_at = $scope.currentDate;
      } else {
        $scope.controlSystem.installed_at = new Date(
          $scope.controlSystem.installed_at
        );
      }

      $scope.clear = function () {
        $scope.dt = null;
      };

      $scope.dateOptions = {
        formatYear: "yy",
        startingDay: 1,
      };

      $scope.formats = [
        "dd-MMMM-yyyy",
        "yyyy/MM/dd",
        "dd.MM.yyyy",
        "MM/dd/yy",
        "shortDate",
      ];
      $scope.format = $scope.formats[4];
      getPricingInfo();
      updateVersions();
      isDSCPanel();

      if (
        $scope.controlSystem.sim_1_identifier ||
        $scope.controlSystem.sim_2_identifier ||
        $scope.controlSystem.sim_1_comm_address ||
        $scope.controlSystem.sim_2_comm_address
      ) {
        $scope.dualSimPanelOpen = true;
      }

      if ($scope.dualSimPanelOpen) {
        // if sim 1 exists on load check its status
        if (
          !$scope.controlSystem.isNew &&
          $scope.controlSystem.sim_1_identifier
        ) {
          $scope.checkActiveSystemThenGetSimStatus(
            $scope.controlSystem.sim_1_identifier,
            1
          );
        }
        // if sim 2 exists on load check its status
        if (
          !$scope.controlSystem.isNew &&
          $scope.controlSystem.sim_2_identifier
        ) {
          $scope.checkActiveSystemThenGetSimStatus(
            $scope.controlSystem.sim_2_identifier,
            2
          );
        }
      }
    }

    function getPricingInfo() {
      PricingAPI.getPricing(
        { dealer_id: UserService.dealer_id },
        function (pricingInfo) {
          $scope.pricing = angular.copy(pricingInfo);

          // logic here to show correct price for advanced reporting
          // The price for advanced reporting is based on the number of doors $1/door for network, $2/door for cell
          $scope.pricing.advancedReportingPersistent =
            1 * $scope.controlSystem.panels[0].device_informations.length;
          $scope.pricing.advancedReportingCell =
            2 * $scope.controlSystem.panels[0].device_informations.length;
          $scope.getSelectedServices();
        },
        function (error) {}
      );
    }

    $scope.install_date_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.datePickerOpen.installDateOpened = true;
    };
    $scope.install_date_open_modal = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.datePickerOpenModal.installDateOpened = true;
    };

    $scope.showMobileWalletControl = () => {
      const hardwareModel =
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .programming_type;
      return hardwareModel === "XR550" || hardwareModel === "XR150";
    };

    $scope.showDoorControl = function () {
      let hardwareModel =
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .programming_type;
      return (
        hardwareModel == "XR550" ||
        hardwareModel == "XT75" ||
        ((hardwareModel == "XT50" ||
          hardwareModel == "XT30" ||
          hardwareModel == "XTLP") &&
          (between(
            $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .software_version,
            202,
            599
          ) ||
            between(
              $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .software_version,
              702,
              Infinity
            )))
      );
    };

    $scope.$watch("controlSystem.panels[0].account_prefix", function (newVal) {
      if (typeof newVal !== "string" && +newVal > 0) {
        // UI selects match to string values. SCAPI could care less if its a number or string. Force the account_prefix
        // to string so that the receiver dropdown works.
        $scope.controlSystem.panels[0].account_prefix =
          $scope.controlSystem.panels[0].account_prefix.toString();
      }
    });

    /**
     * Return true if a receiver select dropdown should be shown
     * @returns {boolean}
     */
    $scope.showReceiverSelect = function () {
      if ($scope.controlSystem.isNew) {
        return $scope.dealerReceivers.length > 0;
      } else {
        return (
          $scope.dealerReceivers.length > 0 &&
          angular.isDefined(
            $scope.dealerReceivers.find(function (r) {
              return (
                r.receiver_number ===
                +$scope.controlSystem.panels[0].account_prefix
              );
            })
          )
        );
      }
    };

    /**
     * Attempts to kill the programming job
     */
    function abortAutoProgramming() {
      $scope.autoProgrammingCanceled = true;
      if (!$scope.autoProgStatus || !$scope.autoProgStatus.SchedulerJobGroupId)
        return;

      JobSchedulerService.abortJobGroup(
        $scope.autoProgStatus.SchedulerJobGroupId
      )
        .then(
          function () {
            $scope.checkAutoProgStatus();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error Canceling Auto Programming",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }
    var checker; //Variable to keep track of counting times

    /**
     * Function to check Auto Programming status.  Checks every 3 seconds until 100 times or you get a terminal
     * status (fail, retry, complete).
     * @returns {*}
     */
    function checkAutoProgStatus() {
      $scope.stopChecking();
      var deferred = $q.defer();
      // Don't start a new checker if we are already checking
      if (angular.isDefined(checker)) return;
      var timesChecked = 0;

      dealerService
        .getAutoProgStatus(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index].id
        )
        .then(
          function (data) {
            $scope.autoProgStatus = data;
            var nextAttemptDate = new Date($scope.autoProgStatus.NextAttempt);
            $scope.showStatus =
              new Date() <
              new Date(
                nextAttemptDate.getFullYear(),
                nextAttemptDate.getMonth(),
                nextAttemptDate.getDate() + 1,
                nextAttemptDate.getHours(),
                nextAttemptDate.getMinutes(),
                nextAttemptDate.getSeconds(),
                nextAttemptDate.getMilliseconds()
              );

            checker = $interval(function () {
              if (
                ($scope.autoProgStatus.Status === "scheduled" ||
                  $scope.autoProgStatus.Status === "running" ||
                  $scope.autoProgStatus.Status === "retry" ||
                  $scope.autoProgStatus.Status === "new") &&
                timesChecked < AUTO_PROG_CHECK_TIMES
              ) {
                timesChecked++;
                dealerService
                  .getAutoProgStatus(
                    $scope.controlSystem.panels[
                      $scope.controlSystem.panel_index
                    ].id
                  )
                  .then(
                    function (data) {
                      $scope.autoProgStatus = data;
                    },
                    function (error) {
                      $scope.stopChecking();
                      deferred.reject(error);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              } else if (timesChecked === AUTO_PROG_CHECK_TIMES) {
                $scope.stopChecking();
              } else {
                // Reset the canceled flag so that the real status appears in the app

                $scope.stopChecking();
                if ($scope.autoProgStatus.Status === "complete")
                  deferred.resolve();
                else deferred.reject();
              }
            }, 3000);
          },
          function (error) {
            $scope.autoProgStatus.Status = "unknown";
            $scope.stopChecking();
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     * Function to stop checking Auto Prog status
     */
    function stopChecking() {
      if (angular.isDefined(checker)) {
        $interval.cancel(checker);
        checker = undefined;
      }
    }

    $scope.closeAlert = function (index) {
      $scope.alerts.splice(index, 1);
    };

    /**
     * Grab Defaults based on Panel Type
     */
    function getDefaults() {
      $scope.loader.isBusy = true;
      ProgrammingTemplateService.getProgTpls(
        UserService.dealerInfo.id,
        DEALER_API_DEFAULTS.XR_DEFAULTS
      )
        .then(
          function (defaults) {
            $scope.available_defaults =
              filterAvalibleTemplatesToCustomer(defaults);
            $scope.loader.isBusy = false;
            getVkPackageAndServices();
            setAutomationEditing();
            setAutomation();
          },
          function () {
            $scope.loader.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function filterAvalibleTemplatesToCustomer(allTemplates) {
      var finalList = undefined;
      var customerList = allTemplates.filter(function (template) {
        return (
          template.customer_id == 0 ||
          template.customer_id == UserService.customer_id
        );
      });

      finalList = customerList.filter(function (customerTemplate) {
        return (
          customerTemplate.hardware_model ==
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .hardware_model
        );
      });

      return finalList;
    }

    function getInitialTemplate(availableTemplates) {
      var initialTemplate = undefined;
      var customerTemplates = availableTemplates.filter(function (template) {
        return template.customer_id == UserService.customer_id;
      });
      if (
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .hardware_model
      ) {
        if (customerTemplates.length > 0)
          initialTemplate = customerTemplates[0];
      }
      return initialTemplate;
    }

    /**
     * Function to determine if part of XT family
     * @param {String} hardware_type
     */
    function isXTFamily(hardware_type) {
      switch (hardware_type) {
        case "XTLP":
        case "XT30":
        case "XT50":
          return true;
        default:
          return false;
      }
    }
    function isCellComFamily(hardware_type) {
      switch (hardware_type) {
        case "CellComSL":
        case "CellComEX":
        case "iComSL":
        case "DualCom":
        case "MiniCellCom":
        case "TMS6":
          return true;
        default:
          return false;
      }
    }
    $scope.isXT75 = function (hardware_type) {
      return hardware_type === "XT75";
    };

    $scope.isXTorCellCom = function (hardware_type) {
      return isXTFamily(hardware_type) || isCellComFamily(hardware_type);
    };
    /**
     *Function to determine if serial number required
     */
    function requireSerialNumber() {
      var isRequired;
      switch (
        $scope.controlSystem.panels[$scope.controlSystem.panel_index].comm_type
      ) {
        case "persistent_w_cell_backup":
        case "persistent":
        case "network":
          isRequired = true;
          $scope.serialNumberPlaceHolder = "Serial Number*";
          break;
        case "cell":
          isRequired = false;
          $scope.serialNumberPlaceHolder = "Serial Number";
          break;
        default:
          isRequired = false;
          $scope.serialNumberPlaceHolder = "Serial Number";
          break;
      }
      return isRequired;
    }

    /*
     * Logic for Determining Workflow and Button Text
     */
    function defaultButtonText(key) {
      if (
        key === "Send Default" ||
        key === "Send Default:Activation" ||
        key === "Back"
      ) {
        return "Send Template";
      } else if (key === "Activation") {
        return "Activation";
      }
    }

    //Used when selected a default from drop-down or ion-list
    function selectedADefault(selectedDefault) {
      $scope.defaultWorkflowKey =
        $scope.ProgramDefaultWorkflowService.whereToNextDefault(
          selectedDefault,
          $scope.controlSystem.panels[$scope.controlSystem.panel_index],
          $scope.CSC.sim
        );
      $scope.buttonText = defaultButtonText($scope.defaultWorkflowKey);
    }

    /**
     * Sets the form
     * @param {form} form
     */
    function setForm(form) {
      $scope.sysForm = form;
    }

    /**
     * @desc
     * Checks whether or not newly added Service Manager Options exist and should be displayed.  Had problems running
     * 'angular.isDefined function in the DOM, created this helper function.  Can not test object, because it is a
     * boolean and the value would return if property existed.
     * @param ServiceManageOption The option to be checked.
     * @returns {boolean} result of isDefined check
     */
    function optionExists(ServiceManageOption) {
      return angular.isDefined(ServiceManageOption);
    }

    $scope.$watch("controlSystem.errors", function () {
      if ($scope.controlSystem && $scope.controlSystem.errors) {
        var newHash = Object.keys($scope.controlSystem.errors)[0];

        if ($location.hash() !== newHash) {
          // set the $location.hash to `newHash` and
          // $anchorScroll will automatically scroll to it
          $location.hash(newHash);
        } else {
          // call $anchorScroll() explicitly,
          // since $location.hash hasn't changed
          $anchorScroll();
        }

        var eCnt = 0;
        angular.forEach($scope.controlSystem.errors, function (val, key) {
          eCnt++;
          if (key === "panels_base") {
            $rootScope.alerts.push({
              type: "error",
              text: val[0],
            });
          }
        });
        if (
          eCnt === 1 &&
          angular.isDefined($scope.controlSystem.errors.panels)
        ) {
          if (
            $scope.controlSystem.errors.panels.length === 1 &&
            $scope.controlSystem.errors.panels[0] === "is invalid"
          ) {
            $rootScope.alerts.push({
              type: "error",
              text: "Unknown Error editing Control System.",
            });
          }
        }
      }
    });

    /**
     * Resets the form
     */
    function resetForm() {
      $timeout(function () {
        $scope.sysForm.$setPristine();
      });
    }

    /**
     * Check for dirty form
     */
    function checkDirty() {
      if (angular.isDefined($scope.sysForm)) {
        var form = angular.isDefined($scope.sysForm.SystemInfo)
          ? $scope.sysForm.SystemInfo
          : $scope.sysForm;

        if (
          ["cell", "persistent_w_cell_backup"].indexOf(
            $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .comm_type
          ) > -1 &&
          angular.isDefined(ControlSystemsService.sim) &&
          ControlSystemsService.sim.isAvailable &&
          ControlSystemsService.sim.status_type.toLowerCase() === "active" &&
          ControlSystemsService.cellular_available
        ) {
          if (
            form.name.modified ||
            form.panels_account_number.modified ||
            form.panels_account_number.$dirty ||
            form.rate_plan.$dirty ||
            (form.sms_pack && form.sms_pack.$dirty) ||
            $scope.miniCellComPlanChanged === true
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    /**
     * Checks if the rate plan has been updated on dual sim
     */
    function shouldModifyDualSim() {
      const sim1 = $scope.CSC.sim1;
      const sim2 = $scope.CSC.sim2;

      if (angular.isDefined($scope.sysForm) && $scope.dualSimPanelOpen) {
        const form = angular.isDefined($scope.sysForm.SystemInfo)
          ? $scope.sysForm.SystemInfo
          : $scope.sysForm;

        if (
          ["cell", "persistent_w_cell_backup"].indexOf(
            $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .comm_type
          ) > -1 &&
          form.rate_plan.$dirty
        ) {
          if (
            (sim1 &&
              sim1.isAvailable &&
              sim1.status_type.toLowerCase() === "active") ||
            (sim2 &&
              sim2.isAvailable &&
              sim2.status_type.toLowerCase() === "active")
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    /**
     * Calls ControlSystemsService to get the currentControlSystem
     */
    function setControlSystem() {
      $scope.controlSystem = ControlSystemsService.currentControlSystem;
      // Get the On Demand Monitoring data, if this dealer/panel supports it
      if (
        UserService.supportMonitorOnDemand() &&
        !$scope.controlSystem.isNew &&
        $state.is("app.control_system_edit")
      )
        getPanelOnDemand(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index].id
        );
    }

    if ($state.is("app.control_system")) {
      $scope.loader.isBusy = true;
      UserService.isBusy = true;
      $scope.loader.isBusy = false;
      UserService.isBusy = false;
    } else {
      $scope.loader.isBusy = false;
      UserService.isBusy = false;
    }

    function isAdvancedReportsSupported() {
      var system =
        $scope.controlSystem.panels[$scope.controlSystem.panel_index];
      const hasCorrectRatePlan = [
        "XR",
        "410",
        "Backup",
        "FIRSTNET PRIMARY",
        "FIRSTNET BACKUP",
        "FIRSTNET FIRE",
      ].includes($scope.CSC.sim.selected_rate_plan);

      return (
        (system.programming_type === "XR550" ||
          system.programming_type === "XT75") &&
        (system.comm_type === "persistent" ||
          system.comm_type === "network" ||
          (system.comm_type === "persistent_w_cell_backup" &&
            hasCorrectRatePlan) ||
          (system.comm_type === "cell" && hasCorrectRatePlan))
      );
    }

    function simChangedWithoutStatusCheck(simNumber) {
      var currentSIMNumber =
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .sim_identifier;

      return (
        currentSIMNumber !== pristineControlSystem.panels[0].sim_identifier &&
        !$scope.simStatusRequested
      );
    }

    function setDoorAccessLevelDefault() {
      if ($scope.controlSystem.services_manager.real_time_events_enabled) {
        if ($scope.controlSystem.services_manager.door_access_level === null)
          $scope.controlSystem.services_manager.door_access_level = "all";
        var showSpinner = false;
        if (
          $scope.controlSystem.panels[$scope.controlSystem.panel_index].online
        )
          $scope.refreshDevices(showSpinner);
      }
    }

    /**
     * hikVision connect, find and delete devices
     */
    $scope.thirdPartyConnect = function () {
      $scope.thirdPartyBusy = true;
      var control_system_id = UserService.control_system_id;
      $scope.nvrErrors = [];
      ThirdPartyVideoService.connectDevice(
        "hikvision",
        control_system_id,
        $scope.nvrConnectInfo
      ).then(
        function (data) {
          var device = data;
          $scope.refreshThirdPartyChannels(device.id);
          $scope.thirdPartyBusy = false;
        },
        function (error) {
          angular.forEach(error.data.errors, function (val) {
            $scope.nvrErrors.push(val);
          });
          $scope.thirdPartyBusy = false;
        }
      );
    };
    function getHikVisionDevices() {
      $scope.thirdPartyBusy = true;
      ThirdPartyVideoService.getDevicesByManufacturer(
        "hikvision",
        $scope.controlSystemId,
        "NVR"
      )
        .then(
          function (data) {
            $scope.hikVision.refresh = true;
            $scope.hikVisionDevices = data;
            $scope.thirdPartyBusy = false;
          },
          function (error) {
            $scope.thirdPartyBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      $scope.hikVision.refresh = false;
    }
    $scope.getSingleHikDevice = function (deviceId) {
      $scope.thirdPartyBusy = true;
      ThirdPartyVideoService.getSingleDevice(deviceId)
        .then(
          function (data) {
            $scope.thirdPartyBusy = false;
          },
          function (error) {
            console.error(
              "Error retrieving third party video devices: " +
                angular.toJson(error)
            );
            $scope.thirdPartyBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    $scope.refreshThirdPartyChannels = function (deviceId) {
      $scope.thirdPartyBusy = true;
      var deferred = $q.defer();
      ThirdPartyVideoService.refreshDevice(deviceId)
        .then(
          function (data) {
            getHikVisionDevices();
            $scope.thirdPartyBusy = false;
            deferred.resolve();
          },
          function (error) {
            console.error(
              "Error refreshing video device: " + angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Error connecting to video device",
            });
            $scope.thirdPartyBusy = false;
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };
    $scope.deleteHikVisionCamera = function (deviceId) {
      $scope.thirdPartyBusy = true;
      ThirdPartyVideoService.destroyDevice(deviceId)
        .then(
          function () {
            getHikVisionDevices();
            $scope.thirdPartyBusy = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error removing video device",
              json: error,
            });
            $scope.thirdPartyBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     Change either Device Info or User Credentials call for HikVision NVR
     */
    $scope.editHikVisionDevice = function (deviceId, connectForm) {
      $scope.thirdPartyBusy = true;
      var promises = [];
      if (
        connectForm.modifiedModels.includes("nvrConnectInfo.username") ||
        connectForm.modifiedModels.includes("nvrConnectInfo.password")
      ) {
        promises.push(
          ThirdPartyVideoService.updateCredentials(
            deviceId,
            $scope.nvrConnectInfo
          )
        );
      }
      if (
        connectForm.modifiedModels.includes("nvrConnectInfo.host") ||
        connectForm.modifiedModels.includes("nvrConnectInfo.management_port") ||
        connectForm.modifiedModels.includes("nvrConnectInfo.streaming_port")
      ) {
        promises.push(
          ThirdPartyVideoService.updateDevice(deviceId, $scope.nvrConnectInfo)
        );
      }

      $q.all(promises)
        .then(
          function () {
            $scope
              .refreshThirdPartyChannels($scope.nvrConnectInfo.id)
              .then(
                function () {
                  $scope.nvrConnectInfo = {};
                  $scope.thirdPartyBusy = false;
                },
                function (error) {
                  getHikVisionDevices();
                  $scope.hikVision.editHikVision = true;
                  $scope.thirdPartyBusy = false;
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error editing HikVision device",
              json: error,
            });
            $scope.hikVision.editHikVision = true;
            $scope.thirdPartyBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    /**
    GET call for OpenEye Devices
     */
    function getOpenEyeDevices() {
      ThirdPartyVideoService.getDevicesByManufacturer(
        "openeye",
        $scope.controlSystemId,
        "DVR"
      ).then(
        function (data) {
          $scope.openEyeDevices = data;
        },
        function (error) {}
      );
    }
    /**
    GET call for Hanwha Devices
     */
    function getHanwhaDevices() {
      ThirdPartyVideoService.getDevicesByManufacturer(
        "hanwha",
        $scope.controlSystemId,
        "server"
      ).then(
        function (data) {
          $scope.hanwhaDevices = data;
        },
        function (error) {}
      );
    }
    /**
    GET call for OpenEye Devices
     */
    function getEagleEyeDevices() {
      ThirdPartyVideoService.getDevicesByManufacturer(
        "eagle_eye",
        $scope.controlSystemId
      ).then(
        function (data) {
          $scope.eagleEyeDevices = data;
        },
        function (error) {}
      );
    }

    $scope.requirePanelOnline = () => {
      return (
        $scope.controlSystem.panels[0].auto_program &&
        !$scope.autoProg.template?.Id
      );
    };

    $scope.autoProgramButtonClicked = () => {
      if ($scope.controlSystem.panels[0].auto_program) {
        $scope.controlSystem.panels[0].online = false;
        $scope.preProgram.click();
        $scope.openInstallDateModal();
      } else {
        $scope.controlSystem.panels[0].online = true;
      }
    };

    function getDWConnectionInfo() {
      ThirdPartyVideoService.getVideoConnectionInformation(
        $scope.controlSystemId,
        "DIGITAL_WATCHDOG"
      ).then(
        function (data) {
          $scope.dwCloud = false;
          $scope.dw_ip_add = data.host;
          $scope.dw_port = data.management_port;
        },
        function (error) {
          $scope.dwCloud = true;
          $scope.DWDelete = false;
        }
      );
    }

    function getHikDoorbell() {
      ThirdPartyVideoService.getDevicesByManufacturer(
        "hikvision",
        $scope.controlSystemId,
        "HIKVISION-DOORBELL"
      )
        .then(
          function (data) {
            $scope.hikDoorbell = data;
          },
          function (error) {}
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    $scope.sensorResetButton = function () {
      SensorResetAPI.resetSensor(
        {
          panel_id: UserService.panel_id,
        },
        function () {
          $rootScope.alerts.push({
            type: "success",
            text: "Sensor Reset Sent",
          });
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error Sending Sensor Reset",
            json: error,
          });
        }
      );
    };

    $scope.addNewHikVisionNVR = function () {
      $scope.hikVision.connectNewDevice = true;
      $scope.nvrConnectInfo = {};
    };

    $scope.selectHikVisionNVRField = function (device) {
      $scope.nvrConnectInfo.id = device.id;
      $scope.nvrConnectInfo.name = device.name;
      $scope.nvrConnectInfo.host = device.host;
      $scope.nvrConnectInfo.username = device.username;
      $scope.nvrConnectInfo.password = device.password;
      $scope.nvrConnectInfo.management_port = device.management_port;
      $scope.nvrConnectInfo.streaming_port = device.streaming_port;
      $scope.hikVision.editHikVision = true;
    };

    function getTimeZones() {
      $scope["timeZones"] = [];
      CustomRolesService.getTimeZones(UserService.dealer_id)
        .then(
          function (data) {
            angular.forEach(data, function (zone) {
              $scope.timeZones.push(zone.name);
            });
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to get time zones",
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function getNumDoorbells() {
      if (
        $scope.controlSystem.services_manager.hikvision_doorbell_enabled &&
        $scope.controlSystem.services_manager.doorbell_total === 0
      ) {
        $scope.controlSystem.services_manager.doorbell_total = 1;
      }
    }

    /**
     * Get personnel data
     * used to get information for primary installer
     */
    function getPersonnelData() {
      $scope.theDealer
        .getPersonnelNames()
        .then(
          function (data) {
            for (let i = 0; i < data.length; i++) {
              $scope.personnel.push(data[i]);
            }
          },
          function (error) {
            console.error(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Grabs the control system from the ControlSystem Model
     */
    function getControlSystem() {
      var deferred = $q.defer();
      $scope.loader.isBusy = true;
      UserService.isBusy = true;
      ControlSystemsService.getControlSystem(
        $scope.controlSystem.control_system_id ||
          $stateParams.control_system_id,
        $stateParams.customer_id
      )
        .then(
          function () {
            $scope.controlSystem = ControlSystemsService.currentControlSystem;
            $scope.loader.isBusy = false;
            UserService.isBusy = false;
            deferred.resolve();
          },
          function (error) {
            $scope.errorMessage = $rootScope.processAlert({
              type: "critical",
              json: error,
            });
            $scope.openErrorModal();
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     * A list of the services_manager properties that are valid for XR500
     * @type {string[]}
     */
    //Do we remove 'four_additional_channels_enabled and replace it with video_service_level?
    var validXR500Services = [
      "full_app_enabled",
      "video_level",
      "video_service_level",
    ];

    function selectedType() {
      var panel = $scope.controlSystem.panels[$scope.controlSystem.panel_index];

      switch (panel.hardware_model) {
        case "XR150":
          panel.programming_type = "XR550";
          break;
        case "XF6_100":
        case "XF6_500":
          panel.programming_type = "XF6";
          break;
        case "TMS6":
          panel.programming_type = "TMS6";
          //cycle through the services_manager and set all to false
          angular.forEach(
            $scope.controlSystem.services_manager,
            function (service, key) {
              // Services are booleans. Properties like id are not and should not be set.
              if (typeof service === "boolean" && key !== "store_user_codes") {
                $scope.controlSystem.services_manager[key] = false;
              }
            }
          );
          break;
        case "XT75":
          panel.programming_type = "XT75";
          break;
        default:
          panel.programming_type = panel.hardware_model;
      }

      // Clear the hardware_model_name if it's not on XTLP or XTLT
      if (!["XTLP", "XTLT"].includes(panel.programming_type))
        panel.hardware_model_name = null;

      switch (panel.programming_type) {
        case "CellComEx":
          // The CellComEX does not support Z-Wave or Schedules
          $scope.controlSystem.services_manager.light_control_enabled = false;
          $scope.controlSystem.services_manager.lock_control_enabled = false;
          $scope.controlSystem.services_manager.schedule_edit_enabled = false;
          $scope.controlSystem.services_manager.thermostat_control_enabled = false;
          $scope.controlSystem.services_manager.zwave_node_edit_enabled = false;
          $scope.controlSystem.services_manager.zwave_sensor_enabled = false;
        // Fall through
        case "CellComSL":
          if (!$scope.controlSystem.isNew) {
            UserService.setProgramming(panel);
          }
          panel.comm_type = "cell";
          break;
        case "iComSL":
          if (!$scope.controlSystem.isNew) {
            UserService.setProgramming(panel);
          }
          panel.comm_type = "persistent";
          break;
        case "iComLNC":
        case "XTL":
        case "XTLP":
          if (!$scope.controlSystem.isNew) {
            UserService.setProgramming(panel);
          }
          if (panel.comm_type === "persistent_w_cell_backup") {
            panel.comm_type = "";
          }
          break;
        case "MiniCellCom":
          if (!$scope.controlSystem.isNew) {
            UserService.setProgramming(panel);
          }
          panel.comm_type = "cell";
          $scope.CSC.sim.selected_rate_plan = "CellComSL";
          $scope.CSC.sim.selected_text_plan = "0";
          $scope.miniCellComPlanChanged = true;
          $scope.controlSystem.services_manager.sensor_activity_enabled = false;
          $scope.controlSystem.services_manager.traffic_count_enabled = false;
          break;
        case "XR500":
          if (
            ["persistent", "persistent_w_cell_backup"].includes(panel.comm_type)
          ) {
            panel.comm_type = null;
          }
          angular.forEach(
            $scope.controlSystem.services_manager,
            function (service, key) {
              // Services are booleans. Properties like id are not and should not be set.
              if (
                typeof service === "boolean" &&
                validXR500Services.indexOf(key) === -1
              ) {
                $scope.controlSystem.services_manager[key] = false;
              }
            }
          );
          break;
        case "XTLPlus":
          // This means we are switching from XTLplus from XTLtouch
          panel.hardware_model_name = null;
          panel.programming_type = "XTLP";
          panel.hardware_model = "XTLP";
          if (!$scope.controlSystem.isNew) {
            UserService.setProgramming(panel);
          }
          if (panel.comm_type === "persistent_w_cell_backup") {
            panel.comm_type = "";
          }
          break;
        case "XTLT":
          // This means we are switching from something else to XTLtouch
          panel.hardware_model_name = "XTLtouch";
          panel.programming_type = "XTLP";
          panel.hardware_model = "XTLP";
          if (!$scope.controlSystem.isNew) {
            UserService.setProgramming(panel);
          }
          if (panel.comm_type === "persistent_w_cell_backup") {
            panel.comm_type = "";
          }
          break;
        case "DualCom":
          if (!$scope.controlSystem.isNew) {
            UserService.setProgramming(panel);
          }
          panel.hardware_model = "DualCom";
          break;
        case "Video Only":
          panel.hardware_model = "Video Only";
          $scope.controlSystem.services_manager.full_app_enabled = true;
          $scope.controlSystem.services_manager.arming_app_enabled = false;
          $scope.controlSystem.services_manager.video_verification_enabled = false;
          break;
        case "XF6":
          // Auto selecting Standard package as it will be the only one available
          $scope.selectFeaturePackage("standard");

          panel.remote_key = "";
          $scope.CSC.sim.selected_rate_plan = "410"; // Code for fireplan
          $scope.CSC.sim.selected_text_plan = "0";
          $scope.pricingPlan.automation = false;
          $scope.toggleAutomation();
          $scope.controlSystem.services_manager.schedule_edit_enabled = false;
          $scope.controlSystem.services_manager.geofencing_enabled = false;
          $scope.controlSystem.services_manager.traffic_count_enabled = false;
          $scope.controlSystem.services_manager.sensor_activity_enabled = false;
          break;
        default:
          UserService.setProgramming(panel);
          if (panel.comm_type === "persistent_w_cell_backup") {
            panel.comm_type = "";
          }
      }

      //Check if panel type support Auto Programming if not it disables auto programming
      if (
        ["XR500", "MiniCellCom", "iComLNC", "XTL"].includes(
          panel.programming_type
        )
      ) {
        $scope.panelSupportsTemplates = false;
        $scope.autoProg.isChecked = false;
      } else {
        $scope.panelSupportsTemplates = true;
      }

      if (
        OnlinePanelService.panelModelsDisallowedInUI.includes(
          panel.hardware_model
        )
      ) {
        panel.online = true;
      }
      getDefaults();

      if (
        panel.programming_type.startsWith("XT") &&
        panel.programming_type !== "XT75"
      ) {
        $scope.controlSystem.installation_type = "residential";
      } else if (
        panel.programming_type.startsWith("XR") ||
        panel.programming_type === "XT75"
      ) {
        $scope.controlSystem.installation_type = "commercial";
      } else if (panel.programming_type.startsWith("XF")) {
        $scope.controlSystem.installation_type = "fire";
      }

      updateVersions();
      $stateParams.hardware_model = panel.hardware_model;
      $stateParams.test = "This is from an Angular Controller";
    }

    function selectedCommType(newValue, oldValue) {
      var panel = $scope.controlSystem.panels[$scope.controlSystem.panel_index];
      // Reset the comm_address if the user changes the comm type
      panel.comm_address = "";
      if (
        !["persistent_w_cell_backup", "cell"].includes(newValue) &&
        ["persistent_w_cell_backup", "cell"].includes(oldValue) &&
        $scope.dualSimPanelOpen
      ) {
        $scope.toggleDualSimPanel();
      }
    }

    // TODO: Look into combining the refreshZones, devices, and system options functions to reduce duplicate code
    function refreshZones(blockEdit) {
      var deferred = $q.defer();
      blockEdit = typeof blockEdit === "undefined" ? true : blockEdit;
      $scope.zonesIsBusy = true;
      $scope.loader.isBusy = blockEdit;

      $scope.controlSystem
        .save()
        .then(
          function () {
            PanelProgrammingService.refresh(
              "zone_informations",
              true,
              {
                tracked: true,
              },
              PanelProgrammingService.getSessionKey()
            )
              .then(
                function () {
                  //success
                  if (blockEdit) {
                    getControlSystem()
                      .then(
                        function () {
                          deferred.resolve();
                        },
                        function (error) {
                          deferred.reject(error);
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  } else {
                    $rootScope.alerts.push({
                      type: "success",
                      text: "Zone Information Updated",
                    });
                    deferred.resolve();
                  }
                  $scope.zonesIsBusy = false;
                },
                function (error) {
                  if (error !== "STALE_STATE_CALL") {
                    getControlSystem();
                    $scope.zonesIsBusy = false;
                    $scope.loader.isBusy = false;
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Error connecting to system",
                      json: error,
                    });
                  }
                  deferred.reject(error);
                },
                function (info) {}
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            $scope.zonesIsBusy = false;
            $scope.loader.isBusy = false;
            if (angular.isUndefined($scope.controlSystem.errors)) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error saving System Information",
                json: error,
              });
            }
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function refreshSystemOptions(showSpinner) {
      var deferred = $q.defer();
      showSpinner = showSpinner === undefined ? true : showSpinner;
      if (showSpinner) {
        $scope.systemOptionsIsBusy = true;
      }
      $scope.controlSystem
        .save()
        .then(
          function () {
            PanelProgrammingService.panel_id =
              $scope.controlSystem.panels[0].id;
            PanelProgrammingService.refresh(
              "system_options",
              true,
              {
                tracked: true,
              },
              PanelProgrammingService.getSessionKey()
            )
              .then(
                function () {
                  //success
                  getControlSystem()
                    .then(
                      function () {
                        $scope.systemOptionsIsBusy = false;
                        deferred.resolve();
                      },
                      function (error) {
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                function (error) {
                  if (error !== "STALE_STATE_CALL") {
                    getControlSystem();
                    $scope.systemOptionsIsBusy = false;
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Error while Refreshing System Options",
                      json: error,
                    });
                  }
                  deferred.reject(error);
                },
                function (info) {}
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            $scope.systemOptionsIsBusy = false;
            if (angular.isUndefined($scope.controlSystem.errors)) {
              $rootScope.alerts.push({
                type: "error",
                json: error,
              });
            }
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function refreshDevices(showSpinner) {
      var deferred = $q.defer();
      showSpinner = showSpinner === undefined ? true : showSpinner;
      if (showSpinner) {
        $scope.devicesIsBusy = true;
        $scope.loader.isBusy = true;
      }
      if ($scope.controlSystem.isNew && $scope.useBilling.show) {
        $scope.copyAddress();
      }
      $scope.controlSystem
        .save()
        .then(
          function (data) {
            PanelProgrammingService.panel_id =
              $scope.controlSystem.panels[0].id;
            PanelProgrammingService.refresh(
              "device_informations",
              true,
              {
                tracked: true,
              },
              PanelProgrammingService.getSessionKey()
            )
              .then(
                function () {
                  //success
                  getControlSystem()
                    .then(
                      function () {
                        $scope.devicesIsBusy = false;
                        deferred.resolve();
                      },
                      function (error) {
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                function (error) {
                  if (error !== "STALE_STATE_CALL") {
                    getControlSystem();
                    $scope.devicesIsBusy = false;
                    $scope.loader.isBusy = false;
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Error while Refreshing Devices",
                      json: error,
                    });
                  }
                  deferred.reject(error);
                },
                function (info) {}
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            $scope.devicesIsBusy = false;
            $scope.loader.isBusy = false;
            if (angular.isUndefined($scope.controlSystem.errors)) {
              $rootScope.alerts.push({
                type: "error",
                json: error,
              });
            }
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    $scope.checkActiveSystemThenGetSimStatus = (
      currentSIM,
      simStatusNumber
    ) => {
      const simStatusObjectName = `sim${
        simStatusNumber ? simStatusNumber : ""
      }`;

      if ($scope.dualSimPanelOpen) {
        if (simStatusNumber === 1) {
          $scope.sim1HasStatus = true;
        } else {
          $scope.sim2HasStatus = true;
        }
      }

      if ($scope.panelInDeferredBillingState()) {
        $scope.simStatusRequested = true;
        $scope.CSC[simStatusObjectName].isAvailable = true;
        $scope.CSC[simStatusObjectName].status_type = "deferred";
        $scope.CSC[simStatusObjectName].selected_text_plan = "0";
        ControlSystemsService.getPlans(currentSIM, simStatusNumber);
        setCarrier(simStatusNumber);
      } else {
        getSimStatus(currentSIM, simStatusNumber).then(() => {
          if ($scope.controlSystem.panels[0].programming_type === "XF6") {
            // This is needed here for when SIMs were previously used on different panels.
            // Previous panel rate plan would be selected in that case, so forcing fire rate plan selection here.
            $scope.CSC[simStatusObjectName].selected_rate_plan = "410"; // Code for fireplan
          }

          const sim1Status = $scope.CSC.sim1?.status_type;
          const sim2Status = $scope.CSC.sim2?.status_type;

          const areBothDualSimsActive =
            (sim1Status === "active" || sim1Status === "active-pending") &&
            (sim2Status === "active" || sim2Status === "active-pending");

          const sim0id = $scope.CSC.sim.identifier;
          const sim1id = $scope.CSC.sim1.identifier;
          const sim2id = $scope.CSC.sim2.identifier;

          if (
            (areBothDualSimsActive && sim0id !== sim1id && sim0id !== sim2id) ||
            (!$scope.isActiveOrActivePending(sim1Status) &&
              !$scope.isActiveOrActivePending(sim2Status) &&
              $scope.dualSimPanelOpen)
          ) {
            $scope.copyToSim0(1);
          } else if (
            $scope.isActiveOrActivePending(sim1Status) &&
            !$scope.isActiveOrActivePending(sim2Status)
          ) {
            $scope.copyToSim0(1);
          } else if (
            !$scope.isActiveOrActivePending(sim1Status) &&
            $scope.isActiveOrActivePending(sim2Status)
          ) {
            $scope.copyToSim0(2);
          }
        });
      }
    };

    function getSimStatus(currentSIM, simStatusNumber) {
      const simStatusObjectName = `sim${
        simStatusNumber ? simStatusNumber : ""
      }`;
      var simID = currentSIM;
      $scope.simStatusRequested = true;
      let renameSystem, hideNotification; // Keep rename system undefined UNLESS a sim number has been passed
      if ($stateParams.simNumber) {
        renameSystem = true;
        hideNotification = true;
      }
      if (simID) {
        return $scope
          .findSIM(simID, renameSystem, hideNotification, simStatusNumber)
          .then(function () {
            if (
              ControlSystemsService[
                simStatusObjectName
              ].status_type.toLowerCase() === "new"
            ) {
              $scope
                .findSIM(simID, "", "", simStatusNumber)
                .then(function () {
                  if ($scope.dualSimPanelOpen) {
                    $scope.copyToSim0(simStatusNumber);
                  }
                })
                .catch(function (error) {
                  console.error(error);
                });
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        return $q.defer().promise;
      }
    }

    function setCarrier(simStatusNumber) {
      if (simStatusNumber) {
        const simStatusObjectName = `sim${
          simStatusNumber ? simStatusNumber : ""
        }`;
        const carrier = $scope.CSC[simStatusObjectName].carrier
          ? $scope.CSC[simStatusObjectName].carrier.toLowerCase()
          : "";

        if ($scope.dualSimPanelOpen) {
          // configure controlSystem.dual_sim based on status of sims
          setDual_SIM();
          // Set the scope sim carrier equal to the sim of the current control system
          if (carrier !== null) {
            $scope.controlSystem[`sim_${simStatusNumber}_carrier`] = carrier;
          } else {
            $scope.controlSystem[`sim_${simStatusNumber}_carrier`] = "unknown";
          }
        } else {
          $scope.controlSystem[`sim_${simStatusNumber}_carrier`] = carrier;
        }

        switch (carrier) {
          case "verizon":
            $scope[`carrierIconAltSim${simStatusNumber}`] = "verizon-logo";
            $scope[`carrierIconSim${simStatusNumber}`] =
              "assets/img/third-party-logos/logo-verizon.svg";
            break;
          case "at&t":
            $scope[`carrierIconAltSim${simStatusNumber}`] = "att-logo";
            $scope[`carrierIconSim${simStatusNumber}`] =
              "assets/img/third-party-logos/logo-att.svg";
            break;
          default:
            $scope[`carrierIconAltSim${simStatusNumber}`] = "Unknown Carrier";
            $scope[`carrierIconSim${simStatusNumber}`] = "";
        }
      }
    }

    function setCommAddress(simID, commAddress) {
      if (simID === $scope.controlSystem.sim_1_identifier) {
        $scope.controlSystem.sim_1_comm_address = commAddress;
      } else if (simID === $scope.controlSystem.sim_2_identifier) {
        $scope.controlSystem.sim_2_comm_address = commAddress;
      } else {
        $scope.controlSystem.panels[
          $scope.controlSystem.panel_index
        ].comm_address = commAddress;
      }
    }

    function findSIM(simID, renameSystem, hideNotification, simStatusNumber) {
      const simStatusObjectName = `sim${
        simStatusNumber ? simStatusNumber : ""
      }`;
      var deferred = $q.defer();
      $scope.loader.isBusy = true;
      renameSystem =
        $scope.controlSystem.panels[0].programming_type === "MiniCellCom"
          ? false
          : renameSystem;

      ControlSystemsService.findSim(
        simID,
        renameSystem,
        hideNotification,
        simStatusNumber
      )
        .then(
          function (data) {
            $scope.loader.isBusy = false;
            // If renameSystem is false, that means we aren't overwriting the account number and sys name, so we need the form to stay modified
            if (renameSystem) $scope.resetForm();
            if (
              ControlSystemsService[simStatusObjectName].msisdn &&
              ControlSystemsService[simStatusObjectName].isAvailable
            ) {
              if (
                $scope.controlSystem.panels[0].programming_type ===
                "MiniCellCom"
              ) {
                simChanged(
                  $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                    .sim_identifier
                );
              }
              if (
                ControlSystemsService[simStatusObjectName].status_type ===
                  "inactive" ||
                ControlSystemsService[simStatusObjectName].status_type === "new"
              ) {
                setCommAddress(
                  simID,
                  ControlSystemsService[simStatusObjectName].identifier
                );
                setCarrier(simStatusNumber);
              } else {
                setCommAddress(
                  simID,
                  ControlSystemsService[simStatusObjectName].msisdn
                );
                setCarrier(simStatusNumber);
              }
            } else {
              if ($scope.controlSystem.isNew) {
                setCommAddress(
                  simID,
                  ControlSystemsService[simStatusObjectName].identifier
                );
                setCarrier(simStatusNumber);
              }
            }
            if (
              ControlSystemsService[
                simStatusObjectName
              ].status_type.toLowerCase() === "unauthorized"
            ) {
              $scope.errorMessage = {};
              $scope.errorMessage.title =
                "MEID/SIM has been Activated on Another License Key";
              $scope.errorMessage.text =
                "The MEID/SIM has already been activated on a different license key. Please call SecureCom Wireless at 877-300-8030.";
              $scope.openUnauthorizedModal();
            }
            deferred.resolve();
          },
          function (error) {
            $scope.loader.isBusy = false;
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function simChanged(identifier) {
      var panel = $scope.controlSystem.panels[$scope.controlSystem.panel_index];
      // If this is a MiniCellCom, but not a "SuperCell" branded panel, auto-set the account number
      if (
        !UserService.dealerInfo.allow_supercell &&
        panel.programming_type === "MiniCellCom" &&
        identifier &&
        identifier.length >= 8
      ) {
        // Update the account and serial number based on the SIM or MEID
        var isMEID = identifier.toUpperCase().startsWith("A1");
        var accountNumber = isMEID
          ? hexToDecimal(identifier.slice(-4))
          : identifier.slice(-5);
        if (isUndefinedOrNull(panel.account_number))
          panel.account_number =
            accountNumber > 65535 ? accountNumber - 40000 : accountNumber;
        panel.serial_number = identifier.slice(-8);
      } else if (
        UserService.dealerInfo.allow_supercell &&
        panel.programming_type === "MiniCellCom" &&
        identifier &&
        identifier.length >= 8
      ) {
        panel.serial_number = identifier.slice(-8);
      }
    }

    function saveDwInfo(form) {
      $scope.thirdPartyBusy = true;

      var control_system_id = UserService.control_system_id;

      if ($scope.digitalWatchdog.selected === "cloud") {
        // Delete any existing info
        if ($scope.dw_ip_add) {
          deleteDwInfo(form);
        }
        $scope.dwCloud = true;
      } else {
        ThirdPartyVideoService.createOrUpdateVideoConnectionInformation(
          control_system_id,
          dw_ip_add.value,
          dw_port.value,
          "DIGITAL_WATCHDOG"
        )
          .then(
            function (data) {
              $rootScope.alerts.push({
                type: "success",
                text: "Saved DW Connection Info",
              });
              $scope.thirdPartyBusy = false;
              $scope.dwCloud = false;
              $scope.dw_ip_add = dw_ip_add.value;
              $scope.dw_port = dw_port.value;
              $scope.DWDelete = true;
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Failed to save DW Connection Info",
              });
              $scope.thirdPartyBusy = false;
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      $scope.dwModal.close();
    }

    function deleteDwInfo(form) {
      $scope.thirdPartyBusy = false;

      var control_system_id = UserService.control_system_id;

      ThirdPartyVideoService.deleteVideoConnectionInformation(
        control_system_id,
        "DIGITAL_WATCHDOG"
      )
        .then(
          function (data) {
            $rootScope.alerts.push({
              type: "success",
              text: "Deleted DW Connection Info",
            });
            // Re-invalidate the form now that we have invalided it with JS

            $scope.dw_ip_add = null;
            $scope.dw_port = null;
            $scope.dwCloud = true;
            form.$setValidity("required", false, form);
            // Disable the delete button
            $scope.DWDelete = false;
            $scope.thirdPartyBusy = false;
            // These fields only exist if its direct.
            if ($scope.digitalWatchdog.selected === "server") {
              dw_ip_add.value = null;
              dw_port.value = null;
            }
            $scope.dwModal.close();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text:
                "Failed to delete DW Connection Info: " +
                error.data.errors[0].message,
            });
            $scope.thirdPartyBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function checkSettingsForSave(shouldNavigate) {
      if (
        !isAdvancedReportsSupported() &&
        $scope.controlSystem.services_manager.real_time_events_enabled == true
      ) {
        openConfirmAdvanceReportModal(shouldNavigate);
      } else if (
        simChangedWithoutStatusCheck() &&
        ($scope.controlSystem.panels[0].comm_type == "cell" ||
          $scope.controlSystem.panels[0].comm_type ==
            "persistent_w_cell_backup")
      ) {
        openConfirmGetStatusModal(shouldNavigate);
      } else {
        $scope.saveControlSystem(shouldNavigate);
      }
    }

    function popSensorActivityErrorModal() {
      let maxNumOfZones = 50;
      let trackedZones = [];
      angular.forEach(
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .sensor_activity_zones,
        function (zone) {
          if (zone.tracked) trackedZones.push(zone);
        }
      );
      if (trackedZones.length > maxNumOfZones)
        $rootScope.alerts.push({
          type: "error",
          text: "Sensor Activity: Number of zones exceeds 50",
        });
    }

    $scope.saveControlSystem = function (navigate) {
      if (
        !$scope.dualSimPanelOpen ||
        !(
          $scope.controlSystem.panels[0].comm_type === "cell" ||
          $scope.controlSystem.panels[0].comm_type ===
            "persistent_w_cell_backup"
        )
      ) {
        $scope.clearDualSim();
      }

      if ($scope.dualSimPanelOpen) {
        if (
          $scope.CSC.sim2.status_type === "active" &&
          $scope.CSC.sim.identifier !== $scope.CSC.sim2.identifier &&
          $scope.CSC.sim.carrier.toLowerCase() !==
            $scope.CSC.sim2.carrier.toLowerCase() &&
          $scope.CSC.sim.msisdn !== $scope.CSC.sim2.msisdn
        ) {
          $scope.CSC.sim = $scope.CSC.sim1;
        }
      }

      var deferred = $q.defer();
      popSensorActivityErrorModal();
      //if new system, send ga event tracking if using old or new ui
      //send event to GA of a NEW system created
      if ($scope.controlSystem.isNew) {
        GoogleAnalyticsService.trackEvent(
          "control-system",
          "create",
          $rootScope.appProperties.type
        );
      }

      // //send ga events that this panel is using pre-programming
      // if(!$scope.controlSystem.panels[$scope.controlSystem.panel_index].online){
      //   GoogleAnalyticsService.trackEvent($scope.gaEventCat, 'pre-program', 'programming')
      // }else{
      //   GoogleAnalyticsService.trackEvent($scope.gaEventCat, 'default', 'programming')
      // }

      // //if a previous system newly adds a virtual keypad service
      // if($scope.controlSystem.services_manager.arming_app_enabled && !$scope.systemHasKeypadServices){
      //   GoogleAnalyticsService.trackEvent($scope.gaEventCat, 'enabled', 'virtual-keypad-services')
      // }

      // Make sure that our rate and text plan are set correctly for MiniCellCom
      if (
        $scope.controlSystem.panels[0].programming_type === "MiniCellCom" &&
        ($scope.CSC.sim.selected_rate_plan !== "CellComSL" ||
          $scope.CSC.sim.selected_text_plan !== "0")
      ) {
        $scope.miniCellComPlanChanged = true;
        $scope.CSC.sim.selected_rate_plan = "CellComSL";
        $scope.CSC.sim.selected_text_plan = "0";
      }

      prepareToSave()
        .then(
          function () {
            // Auto activate MiniCellComs during save
            if (
              $scope.controlSystem.panels[0].programming_type ===
                "MiniCellCom" &&
              $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .comm_address
            ) {
              activateAndSaveMiniCellCom(navigate)
                .then(
                  function (successfulSave) {
                    deferred.resolve(successfulSave);
                  },
                  function (badSave) {
                    deferred.reject(badSave);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              saveAndNavigate(navigate)
                .then(
                  function (successfulSave) {
                    deferred.resolve(successfulSave);
                  },
                  function (badSave) {
                    deferred.reject(badSave);
                  }
                )
                .catch(function (error) {});
            }
          },
          function (error) {
            deferred.resolve(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * Attempt to activate, if not already active, and save the MiniCellCom system
     */
    function activateAndSaveMiniCellCom(navigate) {
      var deferred = $q.defer();
      // Get the SIM status
      $scope
        .findSIM($scope.CSC.sim.identifier, false)
        .then(
          function () {
            // If the SIM isn't already activated, activate it
            var status = $scope.CSC.sim.status_type;
            if (status === "active" || status === "active-pending") {
              // SIM already activated, Save and continue
              saveAndNavigate(navigate)
                .then(
                  function (successfulSave) {
                    deferred.resolve(successfulSave);
                  },
                  function (badSave) {
                    deferred.reject(badSave);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              // Attempt to activate the SIM
              $scope
                .activateSIMwithValidations()
                .then(
                  function () {
                    // Successfully activated the SIM. Save the system
                    saveAndNavigate(navigate)
                      .then(
                        function (successfulSave) {
                          deferred.resolve(successfulSave);
                        },
                        function (badSave) {
                          deferred.reject(badSave);
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function (error) {
                    // Error activating the SIM.
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            }
          },
          function (error) {
            // Error getting the SIM status
            $rootScope.alerts.push({
              type: "error",
              text: "Error getting SIM status",
              json: error,
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    }

    const removeDoorEdit = (hardware_family, firmware_version) =>
      !(
        hardware_family === "XT30" &&
        (between(firmware_version, 202, 599) ||
          between(firmware_version, 702, Infinity))
      );

    function prepareToSave() {
      var deferred = $q.defer();
      $scope.loader.isBusy = true;

      // need to make sure if nickname is an empty string or undefined that it goes to null to prevent app push messages being blank
      if (!$scope.controlSystem.nickname?.trim())
        $scope.controlSystem.nickname = null;

      const hardware_family = getHardwareFamilyFromHardwareModel(
        $scope.controlSystem.panels[0].hardware_model
      );

      /**
       * Some customers are selecting their app type then selecting system type.
       * We need to remove the door_access_level, door_edit_enabled, and real_time_events_enabled
       * before they save if the panel is an XT version 202 or higher
       */

      if (!(hardware_family === "XR550" || hardware_family === "XT75")) {
        if (
          removeDoorEdit(
            hardware_family,
            $scope.controlSystem.panels[0].software_version
          )
        ) {
          $scope.controlSystem.services_manager.door_edit_enabled = false;
        }
        $scope.controlSystem.services_manager.door_access_level = null;
        $scope.controlSystem.services_manager.real_time_events_enabled = false;
      }

      setDoorRealTimeStatus();

      // If this is a cell and the comm_address is empty, set it to the sim.identifier
      if (
        ["cell", "persistent_w_cell_backup"].indexOf(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .comm_type
        ) > -1 &&
        ($scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .comm_address === null ||
          $scope.controlSystem.panels[
            $scope.controlSystem.panel_index
          ].comm_address.trim().length === 0) &&
        ControlSystemsService.sim.identifier
      ) {
        $scope.controlSystem.panels[
          $scope.controlSystem.panel_index
        ].comm_address = ControlSystemsService.sim.identifier;
      }

      if ($scope.controlSystem.isNew && $scope.useBilling.show) {
        $scope.copyAddress();
      }
      // If the full app is not enabled, turn video to none prior to saving
      if (!$scope.controlSystem.services_manager.full_app_enabled) {
        $scope.controlSystem.services_manager.video_level = "none";
        $scope.changeVideoLevel();
      }

      if (
        !$scope.panelInDeferredBillingState() &&
        shouldModifyDualSim() &&
        $scope.dualSimPanelOpen
      ) {
        const modifyPromises = [];
        if ($scope.areBothDualSimsActive()) {
          modifyPromises.push($scope.CSC.modifySIM(1));
          modifyPromises.push($scope.CSC.modifySIM(2));
        } else if ($scope.areDualSimsOffset()) {
          if ($scope.activeDualSim() === 1) {
            modifyPromises.push($scope.CSC.modifySIM(1));
          } else {
            modifyPromises.push($scope.CSC.modifySIM(2));
          }
        }

        $q.all(modifyPromises).finally(function () {
          deferred.resolve();
        });
      } else if (!$scope.panelInDeferredBillingState() && checkDirty()) {
        $scope.CSC.modifySIM().finally(function () {
          deferred.resolve();
        });
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }

    function setDoorRealTimeStatus() {
      let editedDoors = $scope.controlSystem.panels[0].device_informations;
      let pristineDoors = pristineControlSystem.panels[0].device_informations;
      let editedFlag =
        $scope.controlSystem.services_manager.real_time_events_enabled;
      let pristineFlag =
        pristineControlSystem.services_manager.real_time_events_enabled;
      // If the Advanced Reporting flag has been changed, or the tracked doors have been changed, we need to update the door devices
      if (
        !angular.equals(editedDoors, pristineDoors) ||
        !angular.equals(editedFlag, pristineFlag)
      ) {
        if (!angular.equals(editedFlag, pristineFlag) && editedFlag) {
          //AR flag is true, and it WAS NOT previously
          //Advanced Reports was just enabled
          //Loop through the doors and send an update for all of them.
          var doorsToEnable = editedDoors.filter((door) => door.tracked);
          doorsToEnable.map(function (editedDoor) {
            ScheduledJobService.enableDeviceRealtimeStatus(
              $scope.controlSystemId,
              editedDoor
            );
          });
        } else if (angular.equals(editedFlag, pristineFlag) && editedFlag) {
          //AR flag is true, and it WAS previously
          //Advanced Reports is enabled, but it was set previously
          //We only need to look for Doors that were just selected
          var doorsToEnable = editedDoors.filter((door) => door.tracked);
          doorsToEnable.map(function (editedDoor) {
            let pristineDoor = pristineDoors.find(
              (door) => door.number === editedDoor.number
            );
            if (!pristineDoor.tracked) {
              ScheduledJobService.enableDeviceRealtimeStatus(
                $scope.controlSystemId,
                editedDoor
              );
            }
          });
        }
      }
    }

    function navDelete() {
      $scope.loader.isBusy = true;
      $scope.controlSystem
        .destroy()
        .then(
          function () {
            $scope.navBack();
          },
          function (error) {
            $scope.loader.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error during Navigation Deletion",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    $scope.applyTemplateToPanel = (templateName) => {
      const deferred = $q.defer();

      const job = JobSchedulerService.getJobTemplate();
      const jobGroup = JobSchedulerService.getJobGroupTemplate();

      const jobGroupTypePromise = JobSchedulerService.getJobGroupTypeByName(
        "PRE_PROGRAM_TEMPLATE"
      );
      const jobTypePromise = JobSchedulerService.getJobTypeByName(
        "PRE_PROGRAM_TEMPLATE"
      );

      $q.all([jobGroupTypePromise, jobTypePromise])
        .then(
          function ([jobGroupType, jobType]) {
            jobGroup.SchedulerJobGroupTypeId = jobGroupType.Id;
            jobGroup.PanelId = $scope.controlSystem.panels[0].id;
            jobGroup.UserId = UserService.user.id;
            jobGroup.DealerId = UserService.dealer_id;
            job.JobData = `{\"TemplateName\": \"${templateName}\"}`;
            job.PanelId = $scope.controlSystem.panels[0].id;
            job.SchedulerJobTypeId = jobType.Id;
            job.UserId = UserService.user.id;
            job.DealerId = UserService.dealer_id;
            job.Email = UserService.user.email;

            jobGroup.SchedulerJobs.push(
              omit(
                [
                  "ReferenceId",
                  "ReferenceDesc",
                  "RemoteActions",
                  "ReferenceId",
                ],
                job
              )
            );

            JobSchedulerService.createJobGroup(
              omit(["GroupOutput", "ReferenceDesc", "ReferenceId"], jobGroup)
            )
              .then(
                function (data) {
                  UserService.preProgramTemplateJobId = data.Id;
                  deferred.resolve(data);
                },
                function (error) {
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            _this.setStatus(_this.updateStatuses.UPDATE_UNKNOWN);
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    };

    $scope.simNumberFieldBlurred = () => {
      if ($scope.panelInDeferredBillingState()) {
        ControlSystemsService.getPlans(
          $scope.controlSystem.panels[0].sim_identifier
        );
      }
      return;
    };

    $scope.panelInDeferredBillingState = () => {
      return (
        $scope.controlSystem?.panels[0]?.auto_program &&
        today <
          new Date($scope.controlSystem?.installed_at).setHours(0, 0, 0, 0)
      );
    };

    /**
     * Param Navigate: Whether to go to the control system view or not.
     */
    function saveAndNavigate(navigate) {
      var deferred = $q.defer();
      var imageUploadPromise;

      // We need to know whether or not to set the template up for auto programming BEFORE we do the preliminary save, because afterward the system isn't technically 'new' anymore
      const newSystemAndTemplateSelected =
        !!$scope.controlSystem.isNew &&
        !!$scope.autoProg.template &&
        !!$scope.autoProg.template.name;

      const newSystemTemplateName = newSystemAndTemplateSelected
        ? $scope.autoProg.template.name
        : undefined;

      async function watchAndGo(stateDotGoFunction) {
        (async () => {
          while ($rootScope.savingTags) {
            await sleep(500);
          }
        })().then(() => {
          stateDotGoFunction();
        });
      }

      $scope.controlSystem
        .save()
        .then(
          async function (savedPanelData) {
            $rootScope.savingTags = true;
            handleSavingTags($rootScope.savingTags, $scope.controlSystem.id);
            let dealer_id = UserService.dealer_id;
            // Adding this for deferred billing of (SIM) Auto Program/Pre Program systems only
            // We want to make sure $scope.controlSystem.online is set to false, not just undefined
            if ($scope.panelInDeferredBillingState()) {
              ControlSystemsService.activateSIM(
                UserService.dealerInfo.dealer_code
              );
            }

            if (newSystemAndTemplateSelected) {
              if (!$scope.controlSystem.panels[0].online) {
                $scope.applyTemplateToPanel(newSystemTemplateName).then(
                  (success) => {
                    $rootScope.alerts.push({
                      type: "success",
                      text: "Template applied to panel",
                    });
                  },
                  (error) => {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Unable to apply template to panel",
                    });
                  }
                );
              } else {
                $scope.sendAutoProgramTemplate(newSystemTemplateName, true);
              }
            }

            if (
              !angular.equals($scope.panelOnDemand, {}) &&
              $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .programming_type !== "XR550"
            ) {
              $scope.panelOnDemand.Settings = angular.toJson(
                $scope.communications_object
              );
              PanelOD.update(
                {
                  id: $scope.panelOnDemand.Id,
                },
                $scope.panelOnDemand,
                function () {},
                function (error) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error saving Panel On Demand data",
                    json: error,
                  });
                }
              );
            }

            if (navigate) {
              watchAndGo(() => {
                return $state.go("app.control_system", {
                  customer_id: UserService.customer_id,
                  control_system_id: $scope.controlSystem.id,
                });
              });
            } else {
              deferred.resolve(savedPanelData);
            }
          },
          function (error) {
            deferred.reject(error);
            $scope.loader.isBusy = false;
            if (angular.isUndefined($scope.controlSystem.errors)) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error saving Control System",
                json: error,
              });
            }
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function navCancel() {
      $state.go("app.control_system", {
        customer_id: UserService.customer_id,
        control_system_id: UserService.control_system_id,
      });
    }

    function navBack() {
      $state.go("app.customers.customersummary", {
        customer_id: UserService.customer_id,
        dealer_id: UserService.dealerInfo.id,
      });
    }

    function disableForCell() {
      var cancelDisabled =
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .comm_type === "cell" &&
        (!originalRate || !originalText) &&
        ControlSystemsService.sim.status_type === "active";
      return cancelDisabled;
    }

    function saveLocalValidate() {
      var valid = true;
      $scope.controlSystem.clearErrors();
      $scope.controlSystem.errors = {};
      if (
        Number(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .account_number
        ) > 65535 ||
        Number(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .account_number
        ) < 1 ||
        isNaN(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .account_number
        )
      ) {
        angular.extend($scope.controlSystem.errors, {
          panels_account_number: ["Must be between 1-65535"],
        }); //TODO: Lang these
        valid = false;
      }
      if (
        Number(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .account_prefix
        ) > 99 ||
        Number(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .account_prefix
        ) < 1 ||
        isNaN(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .account_prefix
        )
      ) {
        angular.extend($scope.controlSystem.errors, {
          panels_account_prefix: ["Must be between 1-99"],
        }); //TODO: Lang these
        valid = false;
      }
      return valid;
    }

    function isFirstNetSim() {
      return /^8901100/.test(
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .sim_identifier
      );
    }

    function pollFirstNetStatus() {
      $scope.pollingFirstNetSimActive = true;
      if ($scope.CSC.sim.status_type !== previousSimStatus) {
        $scope.disableButtonFirstNet = false;
        $scope.pollingFirstNetSimActive = false;
      } else {
        $timeout(() => {
          getSimStatus().then(pollFirstNetStatus);
        }, 180000);
      }
    }

    function isFirstNetDisabled() {
      $scope.disableButtonFirstNet = isFirstNetSim();
    }

    function setDual_SIM() {
      if (
        ($scope.isActiveOrActivePending($scope.CSC.sim1?.status_type) &&
          $scope.isActiveOrActivePending($scope.CSC.sim2?.status_type)) ||
        ($scope.isInactive($scope.CSC.sim1?.status_type) &&
          $scope.isInactive($scope.CSC.sim2?.status_type))
      ) {
        $scope.controlSystem.dual_sim = "both";
      } else if ($scope.isActiveOrActivePending($scope.CSC.sim1?.status_type)) {
        // if carrier is at&t we need to remove the & character for the API
        if ($scope.CSC.sim1.carrier.toLowerCase() === "at&t") {
          $scope.controlSystem.dual_sim = "att";
        } else {
          $scope.controlSystem.dual_sim = $scope.CSC.sim1.carrier.toLowerCase();
        }
      } else if ($scope.isActiveOrActivePending($scope.CSC.sim2?.status_type)) {
        if ($scope.CSC.sim2.carrier.toLowerCase() === "at&t") {
          $scope.controlSystem.dual_sim = "att";
        } else {
          $scope.controlSystem.dual_sim = $scope.CSC.sim2.carrier.toLowerCase();
        }
      } else {
        $scope.controlSystem.dual_sim = "both";
      }
    }

    // simStatusNumber = sim number = 1 or 2
    function activateSIMwithValidations(simStatusNumber) {
      var deferred = $q.defer();
      const simStatusObjectName = `sim${
        simStatusNumber ? simStatusNumber : ""
      }`;

      if ($scope.saveLocalValidate()) {
        if (isFirstNetSim()) {
          previousSimStatus = $scope.CSC.sim.status_type;
          pollFirstNetStatus();
        }

        const system = $scope.CSC.currentControlSystem;

        if (
          !systemIsSite(system) &&
          systemIsOnline(system) &&
          systemIsScheduledForFutureInstall(system) &&
          (system.sim || $scope.CSC[simStatusObjectName])
        ) {
          SimManagementService.deactivate(
            {
              iccid: $scope.dualSimPanelOpen
                ? $scope.CSC[simStatusObjectName].identifier
                : system.sim,
              key: system.dealer.securecom_license,
            },
            system,
            UserService.dealerInfo.dealer_code,
            system.panels[0].id,
            UserService.dealerInfo.id
          );
        }

        const areDualsimsOffset = $scope.areDualSimsOffset();
        const alreadyActiveSimNumber = $scope.activeDualSim();
        const shouldModify = shouldModifyDualSim();

        $scope.CSC.activateSIM("", simStatusNumber)
          .then(
            function (data) {
              if (areDualsimsOffset && shouldModify) {
                if (alreadyActiveSimNumber === 1) {
                  $scope.CSC.modifySIM(1).finally(function () {
                    deferred.resolve(data);
                  });
                } else {
                  $scope.CSC.modifySIM(2).finally(function () {
                    deferred.resolve(data);
                  });
                }
              } else {
                deferred.resolve(data);
              }
            },
            function () {
              deferred.resolve();
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }

      return deferred.promise;
    }

    function deactivateSIMwithValidations(simStatusNumber) {
      const simStatusObjectName = `sim${
        simStatusNumber ? simStatusNumber : ""
      }`;

      if (!$scope.CSC.sim1 && !$scope.CSC.sim2) {
        previousSimStatus = $scope.CSC[simStatusObjectName].status_type;
        pollFirstNetStatus();
      }

      if ($scope.saveLocalValidate()) {
        $scope.CSC.deactivateSIM(simStatusNumber)
          .then(
            function () {
              //return
              if ($scope.canSaveDualSim() && $scope.dualSimPanelOpen) {
                $scope.controlSystem.save().finally(function () {
                  $scope.loader.isBusy = false;
                });
              }
            },
            function () {
              //error
              $scope.loader.isBusy = false;
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      setDual_SIM();
      $scope.copyToSim0($scope.activeDualSim());
      if (!$scope.dualSimPanelOpen) {
        $scope.clearDualSim();
      }
    }

    function copyAddress() {
      $scope.controlSystem.address_1 = angular.copy(
        UserService.customerInfo.address1
      );
      $scope.controlSystem.address_2 = angular.copy(
        UserService.customerInfo.address2
      );
      $scope.controlSystem.city = angular.copy(UserService.customerInfo.city);
      $scope.controlSystem.state = angular.copy(
        UserService.customerInfo.state_province
      );
      $scope.controlSystem.postal_code = angular.copy(
        UserService.customerInfo.postal_code
      );
      $scope.controlSystem.country = angular.copy(
        UserService.customerInfo.country
      );
    }

    $scope.openInstallDateModal = () => {
      if (!$scope.controlSystem.panels[0].online)
        $scope.installDateModal = $modal.open({
          templateUrl: "installDateModal.html",
          windowClass: "dialog-header-error",
          size: "lg",
          backdrop: "static",
          scope: $scope,
        });
    };

    function openErrorModal() {
      $scope.errorModal = $modal.open({
        templateUrl: "errorModalContentSystemInfo.html",
        windowClass: "dialog-header-error",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });

      $scope.errorModal.result
        .then(
          function (reason) {
            $scope.navCancel();
          },
          function () {
            $scope.navCancel();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function openConfirmAdvanceReportModal(navigate) {
      var confirmModal = $modal.open({
        controller: "ConfirmAdvanceReportCtrl",
        animation: false,
        templateUrl: "confirmModalContent.html",
        windowClass: "dialog-header-error",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });

      confirmModal.result.then(
        function (reason) {
          $scope.controlSystem.services_manager.real_time_events_enabled = false;
          checkSettingsForSave(navigate);
        },
        function () {}
      );
    }

    function openConfirmGetStatusModal(navigate) {
      $scope.confirmStatusModal = $modal.open({
        controller: "ConfirmGetStatusCtrl",
        animation: false,
        templateUrl: "confirmGetStatusContent.html",
        windowClass: "dialog-header-error",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });

      $scope.confirmStatusModal.result.then(
        function (reason) {
          $scope.saveControlSystem(navigate);
        },
        function () {}
      );
    }

    function openUnauthorizedModal() {
      if ($rootScope.appProperties.type === "techApp") {
        $modal
          .fromTemplateUrl("sim-unauthorized-check-status.html", {
            scope: $scope,
            animation: "animated slideInRight",
          })
          .then(function (modal) {
            $scope.modal = modal;
            $scope.modal.show();
            $scope.hideUnauthorizedModal = function () {
              $scope.modal.hide();
              $scope.modal.remove();
            };
          });
      } else {
        $scope.authModal = $modal.open({
          templateUrl: "unauthorizedModalContent.html",
          windowClass: "dialog-header-error",
          size: "md",
          backdrop: "static",
          scope: $scope,
        });

        $scope.authModal.result
          .then(
            function (reason) {},
            function () {}
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    }

    function openDeactivateModal(simStatusNumber) {
      $scope.deactivateModal = $modal.open({
        templateUrl: "deactivateModalContent.html",
        windowClass: "dialog-header-info",
        size: "md",
        backdrop: "static",
        scope: $scope,
        controller: "ConfirmDeactivateCtrl",
        resolve: {
          simStatusNumber: function () {
            return simStatusNumber;
          },
        },
      });

      $scope.deactivateModal.result
        .then(
          function (reason) {},
          function () {}
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Deselects Video Verification option when Video option is unselected.
     */
    function changeVideoLevel() {
      if ($scope.controlSystem.services_manager.video_level === "none") {
        $scope.controlSystem.services_manager.video_verification_enabled = false;
        $scope.controlSystem.services_manager.video_service_level = "no_video";
      } else {
        $scope.controlSystem.services_manager.video_service_level = "standard";
      }
    }

    function showArmingApp() {
      if (
        $scope.controlSystem.panels[0].comm_type === null ||
        $scope.controlSystem.panels[0].comm_type === ""
      ) {
        return false;
      }
      if (
        $scope.controlSystem.panels[0].comm_type === "cell" ||
        $scope.controlSystem.panels[0].comm_type === "persistent_w_cell_backup"
      ) {
        if (
          !$scope.CSC.sim.id &&
          !$scope.CSC.sim.isAvailable &&
          !$scope.CSC.isBusy
        ) {
          $scope.controlSystem.services_manager.arming_app_enabled = false;

          // update the vk package when arming app disabled.
          getVkPackageAndServices();
          return false;
        }
      }
      if ($scope.controlSystem.services_manager.arming_app_enabled) {
        return true;
      }
      return true;
    }

    function bringOnline() {
      $scope.restoringOnline = true;
      OnlinePanelService.bringOnline()
        .then(
          function () {
            $scope.restoringOnline = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to bring system back online",
              json: error,
            });
            $scope.restoringOnline = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Check to see if the panel with the specified serial_number is connected over EASYconnect
     */
    function checkEasyConnect() {
      dealerService
        .checkEasyConnectStatus(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .serial_number
        )
        .then(
          function () {
            $scope.isEasyConnected = true;
            // If there is already a comm type, leave it.  otherwise, set it.
            if (
              !$scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .comm_type
            )
              $scope.controlSystem.panels[
                $scope.controlSystem.panel_index
              ].comm_type = "persistent";
          },
          function () {
            $scope.isEasyConnected = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Check to see if the panel with the specified panel_id has had a modem checkin
     */
    function checkModemStatus() {
      dealerService
        .checkModemStatus(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index].id
        )
        .then(
          function () {
            $scope.isModemCheckedIn = true;
            // If there is already a comm type, leave it.  otherwise, set it.
            if (
              !$scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .comm_type
            )
              $scope.controlSystem.panels[
                $scope.controlSystem.panel_index
              ].comm_type = "cell";
          },
          function () {
            $scope.isModemCheckedIn = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * Hideous functions to support On Demand Monitoring.
     * TODO: Turn these into a service or move to the correct place eventually.
     */

    /**
     * Get the On Demand data for this panel
     */
    function getPanelOnDemand(panelId) {
      PanelOD.get(
        {
          id: panelId,
        },
        function (data) {
          // Data was returned
          $scope.panelOnDemand = data;
          if ($scope.panelOnDemand.DealerCharges)
            delete $scope.panelOnDemand.DealerCharges;
          DealerCharges.query(
            {
              id: UserService.dealer_id,
            },
            function (data) {
              $scope.dealerCharges = $filter("filter")(data, {
                Enabled: true,
              });
              if (
                $filter("filter")($scope.dealerCharges, {
                  Id: $scope.panelOnDemand.DealerChargesId,
                }).length === 0 &&
                $scope.panelOnDemand.CanPOD
              ) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "The previous On Demand Charge option is no longer valid. Please select a valid option from the dropdown.",
                });
              }
            }
          );
          setOnDemandEmails($scope.panelOnDemand);
        },
        function (error) {
          // PanelOD GET failed, let's create a new one
          DealerCharges.query(
            {
              id: UserService.dealerInfo.id,
            },
            function (data) {
              $scope.dealerCharges = $filter("filter")(data, {
                Enabled: true,
              });
              if ($scope.dealerCharges.length > 0) {
                $scope.panelOnDemand = createNewPOD(
                  panelId,
                  $scope.dealerCharges[0].Id
                );
                setOnDemandEmails($scope.panelOnDemand);
                if (
                  $filter("filter")($scope.dealerCharges, {
                    Id: $scope.panelOnDemand.DealerChargesId,
                  }).length === 0 &&
                  $scope.panelOnDemand.CanPOD
                ) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "The previous On Demand Charge option is no longer valid. Please select a valid option from the dropdown.",
                  });
                }
              }
              if ($scope.panelOnDemand.DealerCharges)
                delete $scope.panelOnDemand.DealerCharges;
            }
          );
        }
      );
    }

    $scope.getPanelOnDemand = function (panelId) {
      return getPanelOnDemand(panelId);
    };

    function setOnDemandEmails(panelOnDemand) {
      if (UserService.supportMonitorOnDemand()) {
        if (angular.isUndefined(panelOnDemand.CustomerEmail))
          panelOnDemand.CustomerEmail = UserService.customerInfo.email;
      }
    }

    $scope.shouldVideoCheckboxesDisable = function (checkboxModel) {
      const videoAnalyticsRecorderEnabled =
        $scope.controlSystem.services_manager.video_analytics_recorder_enabled;
      const videoLevel = $scope.controlSystem.services_manager.video_level;
      const videoVerificationEnabled =
        $scope.controlSystem.services_manager.video_verification_enabled;

      switch (checkboxModel) {
        case "xv":
          return (
            videoVerificationEnabled &&
            videoLevel !== "none" &&
            !videoAnalyticsRecorderEnabled
          );
        case "cameras":
          return (
            videoVerificationEnabled &&
            videoAnalyticsRecorderEnabled &&
            videoLevel === "none"
          );
        case "verification":
          return (
            videoAnalyticsRecorderEnabled &&
            videoLevel !== "none" &&
            !videoVerificationEnabled
          );
        default:
          return false;
      }
    };

    function createNewPOD(panelId, dealerChargeId) {
      var newPod = new PanelOD();
      newPod.PanelId = panelId;
      newPod.DealerChargesId = dealerChargeId;
      newPod.DealerId = UserService.dealerInfo.id;
      newPod.CanPOD = false;
      newPod.CustomerEmail = UserService.customerInfo.email;
      newPod.$save();
      return newPod;
    }

    /**
     * Modal for panel replacement
     */
    function openReplacePanelModal() {
      $scope["replacePanelModal"] = $modal.open({
        templateUrl: "app/control_system/templates/replace-panel-modal.html",
        controller: "ReplacePanelCtrl",
        windowClass: "",
        size: "md",
        backdrop: "static",
        scope: $scope,
        resolve: {
          controlSystemForm: function () {
            return $scope.sysForm;
          },
          originalSerialNumber: function () {
            return originalSerialNumber;
          },
        },
      });
    }
    /**
     * Modal for panel replacement
     */
    function openChangeAccountNumberRemoteKeyModal(changeType) {
      $scope["changeAccountNumberRemoteKeyModal"] = $modal.open({
        templateUrl:
          "app/control_system/templates/change-account-number-remote-key-modal.html",
        controller: "ChangeAccountNumberRemoteKeyCtrl",
        windowClass: "",
        size: "md",
        backdrop: "static",
        scope: $scope,
        resolve: {
          controlSystemForm: function () {
            return $scope.sysForm;
          },
          originalSerialNumber: function () {
            return originalSerialNumber;
          },
          originalAccountNumber: function () {
            return originalAccountNumber;
          },
          originalReceiverNumber: function () {
            return originalReceiverNumber;
          },
          originalRemoteKey: function () {
            return originalRemoteKey;
          },
          changeType: function () {
            return changeType;
          },
        },
      });
    }

    // - - Creates and Opens the Default Programming Modal Box - - //
    function openDefaultProgrammingModal() {
      $scope.defaultProgrammingmodal = $modal.open({
        templateUrl: "defaultProgrammingModal.html",
        controller: "DefaultProgrammingCtrl",
        windowClass: "",
        size: "lg",
        backdrop: "static",
        scope: $scope,
      });
    }

    // - - Creates and Opens the Cellular Activation Modal Box - - //
    function openCellActivationModal(defaultSent, rate_defaults) {
      $scope.cellActivationmodalmodal = $modal.open({
        templateUrl: "cellActivationModal.html",
        controller: "CellularActivationCtrl",
        windowClass: "",
        size: "lg",
        backdrop: "static",
        scope: $scope,
        resolve: {
          defaultSent: defaultSent,
          selectedDefault: rate_defaults,
        },
      });
    }

    function sendAutoProgram(panel, selectedDefault, useAutoProgramTiming) {
      return dealerService.submitAutoProgramming(
        panel,
        selectedDefault,
        useAutoProgramTiming
      );
    }

    function sendDefault(panel, selectedDefault) {
      return dealerService.submitAutoProgramming(panel, selectedDefault);
    }

    $scope.sendAutoProgramTemplate = function (
      templateName,
      useAutoProgramTiming
    ) {
      var currentPanel =
        $scope.controlSystem.panels[$scope.controlSystem.panel_index];
      if (useAutoProgramTiming) {
        sendAutoProgram(currentPanel, templateName, useAutoProgramTiming)
          .then(
            function (success) {},
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Could not send AutoProgramming: " + error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        $scope
          .sendDefault(currentPanel, templateName)
          .then(
            function (success) {},
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Could not send Template: " + error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    };

    function updateVersions() {
      let panel = $scope.controlSystem.panels[$scope.controlSystem.panel_index];
      if (
        panel.online === false &&
        panel.hardware_model &&
        $scope.preProgram.models.includes(panel.hardware_model)
      ) {
        PanelDefinitionService.getVersions(
          panel.hardware_model,
          UserService.enabledDistributionSubscriber()
        )
          .then(
            function (data) {
              $scope.preProgram.versions = data
                .sort((a, b) => b - a)
                .slice(0, 5);
            },
            function (error) {
              console.error(
                `error updating available firmware versions: ${angular.toJson(
                  error
                )}`
              );
              $rootScope.alerts.push({
                type: "error",
                text: "error getting available firmware versions",
              });
            }
          )
          .catch(function (error) {
            console.error(`caught error: ${angular.toJson(error)}`);
          });
      }
    }

    // ---------------------------
    // Sites Variables
    // ---------------------------

    $scope.getAngularRouteLink = $state.href;

    $scope.mergeFromReactState = function (state) {
      $scope.controlSystem.panels[
        $scope.controlSystem.panel_index
      ].hardware_model = state.type;
      $scope.controlSystem.name = state.name;
      $scope.controlSystem.nickname = state.nickname;
      $scope.useBilling = {
        show: state.useBillingAddress,
      };
      $scope.$evalAsync();
      $scope.selectedType();
      $scope.getSelectedServices();
    };

    init();
  },
]);

/**
 * A controller for the confirmAdvanceReport modal
 */
App.controller("ConfirmAdvanceReportCtrl", function ($scope, $modalInstance) {
  $scope.save = function () {
    $modalInstance.close("save");
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel");
  };
});

/**
 * A controller for the confirmGetStatusModal
 */
App.controller("ConfirmGetStatusCtrl", function ($scope, $modalInstance) {
  $scope.save = function () {
    $modalInstance.close("save");
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel");
  };
});

App.controller(
  "ConfirmDeactivateCtrl",
  function ($scope, $modalInstance, simStatusNumber) {
    $scope.simStatusNumber = simStatusNumber;
    const simStatusObjectName = `sim${simStatusNumber ? simStatusNumber : ""}`;
  }
);

/**
 * Service to return what the 'key' should be based on the information known.
 * This is used to determine the flow and where to next in the workflow once a default is selected or the SIM Status.
 */
App.factory("ProgramDefaultWorkflowService", [
  function () {
    return {
      whereToNextDefault: function (selectedDefault, panel, sim) {
        var connection_type = panel.comm_type;
        var defaultType = selectedDefault.receiver_communication;

        function text_plan() {
          return selectedDefault.text_plan;
        }

        function rate_plan() {
          return selectedDefault.rate_plan;
        }

        function activated_sim() {
          return (
            sim.status_type === "active" || sim.status_type === "active-pending"
          );
        }

        var key = "";
        switch (connection_type) {
          case "persistent":
            switch (defaultType) {
              case "CELL":
                if (activated_sim() && rate_plan && text_plan) {
                  key = "Send Default";
                } else {
                  key = "Send Default:Activation";
                }
                break;
              case "NET":
              case "WIFI":
              case "CID":
              case "DD":
              default:
                if (selectedDefault.rate_plan) {
                  key = "Send Default:Activation";
                } else {
                  key = "Send Default";
                }
                break;
            }
            break;
          case "persistent_w_cell_backup":
            switch (defaultType) {
              case "CELL":
                if (activated_sim()) {
                  key = "Send Default";
                } else {
                  key = "Activation";
                }
                break;
              case "NET":
              case "WIFI":
              case "CID":
              case "DD":
              default:
                if (activated_sim()) {
                  key = "Send Default";
                } else if (selectedDefault.rate_plan) {
                  key = "Activation";
                } else {
                  key = "Send Default:Activation";
                }
                break;
            }
            break;
          case "cell":
            switch (defaultType) {
              case "CELL":
                if (activated_sim()) {
                  key = "Send Default";
                } else {
                  key = "Activation";
                }
                break;
              case "NET":
              case "WIFI":
              case "CID":
              case "DD":
              default:
                if (activated_sim()) {
                  key = "Send Default";
                } else if (selectedDefault.rate_plan) {
                  key = "Activation";
                } else {
                  key = "Back";
                }
                break;
            }
            break;
          case "network":
          default:
            switch (defaultType) {
              case "CELL":
                if (activated_sim()) {
                  key = "Send Default";
                } else {
                  key = "Activation";
                }
                break;
              case "NET":
              case "WIFI":
              case "CID":
              case "DD":
              default:
                if (selectedDefault.rate_plan) {
                  key = "Send Default:Activation";
                } else {
                  key = "Send Default";
                }
                break;
            }
            break;
        }
        return key;
      },

      whereToNextActivation: function (sim, defaultSent) {
        function isActivated() {
          if (
            sim.status_type === "active" ||
            sim.status_type === "active-pending"
          ) {
            return true;
          } else if (
            sim.status_type === "new" ||
            sim.status_type === "inactive-pending" ||
            sim.status_type === "inactive"
          ) {
            return false;
          }
          return undefined;
        }
        var activated = isActivated();
        switch (activated) {
          case undefined:
            key = "Get Status";
            break;
          case false:
            key = "Activate";
            break;
          case true:
            if (defaultSent) {
              key = "Ok";
            } else {
              key = "Send Default";
            }
            break;
          default:
            key = "Get Status";
            break;
        }
        return key;
      },
    };
  },
]);

/**
 * @ngdoc object
 * @name Default Programming Modal Box.
 *
 * @description
 *  Modal box that is used when adding default programming to new systems or existing systems.
 */

App.controller("DefaultProgrammingCtrl", [
  "$scope",
  "$rootScope",
  "$modal",
  "$state",
  "$modalInstance",
  "UserService",
  function ($scope, $rootScope, $modal, $state, $modalInstance, UserService) {
    //Function to determine where to route to based on autoProgStatus
    function goToState() {
      if ($scope.autoProgStatus.Status !== "unknown") {
        $state.go(
          "app.control_system_edit",
          {
            customer_id: UserService.customer_id,
            control_system_id: $scope.controlSystem.id,
          },
          {
            reload: true,
          }
        );
      } else {
        $state
          .go(
            "app.control_system_edit",
            {
              customer_id: UserService.customer_id,
              control_system_id: $scope.controlSystem.id,
            },
            {
              location: "replace",
            }
          )
          .then(function () {
            $scope.checkAutoProgStatus();
          });
      }
    }

    /**
     * Click event for clicking the default button
     */
    $scope.defaultScreenButtonClick = function () {
      var func;
      key = $scope.defaultWorkflowKey;
      if (
        DoesNestedPropertyExist($scope.selectedDefault, "remote_options") &&
        $scope.selectedDefault.remote_options.crypt_key
      ) {
        $scope.controlSystem.panels[
          $scope.controlSystem.panel_index
        ].remote_key = $scope.selectedDefault.remote_options.crypt_key;
      }
      if (DoesNestedPropertyExist($scope.selectedDefault, "template_data")) {
        var template_data = angular.fromJson(
          $scope.selectedDefault.template_data
        );
        if (
          DoesNestedPropertyExist(template_data, "crypt_key") &&
          template_data.RemoteOptions.remote_options.crypt_key
        ) {
          $scope.controlSystem.panels[
            $scope.controlSystem.panel_index
          ].remote_key = template_data.RemoteOptions.remote_options.crypt_key;
        }
      }
      if (key === "Send Default") {
        $scope
          .saveControlSystem(false)
          .then(
            function (success) {
              if (
                $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                  .comm_type === "persistent_w_cell_backup" ||
                $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                  .comm_type === "cell"
              ) {
                if (
                  $scope.selectedDefault.rate_plan &&
                  $scope.selectedDefault.text_plan
                ) {
                  $scope.CSC.sim.selected_rate_plan =
                    $scope.selectedDefault.rate_plan;
                  $scope.CSC.sim.selected_text_plan =
                    $scope.selectedDefault.text_plan;
                  $scope.CSC.modifySIM();
                }
              }
              $scope
                .sendDefault(
                  $scope.controlSystem.panels[$scope.controlSystem.panel_index],
                  $scope.selectedDefault.name
                )
                .then(
                  function (success) {
                    $scope.loader.isBusy = false;
                    goToState();
                    $scope.close();
                  },
                  function (error) {
                    $scope.loader.isBusy = false;
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Please fix errors before selecting a default.",
                    });
                    $scope.close();
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              $scope.loader.isBusy = false;
              $rootScope.alerts.push({
                type: "error",
                text: "Please fix errors before selecting a default.",
              });
              $scope.close();
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else if (key === "Activation") {
        $scope.close();
        $scope.openCellActivationModal(false, $scope.selectedDefault);
      } else if (key === "Send Default:Activation") {
        $scope
          .saveControlSystem(false)
          .then(
            function (success) {
              $scope.close();
              $scope
                .sendDefault(
                  $scope.controlSystem.panels[$scope.controlSystem.panel_index],
                  $scope.selectedDefault.name
                )
                .then(
                  function () {
                    $scope.loader.isBusy = false;
                    if (
                      $scope.selectedDefault.rate_plan &&
                      $scope.selectedDefault.text_plan
                    ) {
                      $scope.CSC.sim.selected_rate_plan =
                        $scope.selectedDefault.rate_plan;
                      $scope.CSC.sim.selected_text_plan =
                        $scope.selectedDefault.text_plan;
                    }
                    $scope.openCellActivationModal(
                      true,
                      $scope.selectedDefault
                    );
                  },
                  function (error) {}
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              $scope.loader.isBusy = false;
              $rootScope.alerts.push({
                type: "error",
                text: "Please fix errors before selecting a default.",
              });
              $scope.close();
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else if (key === "Back") {
        $rootScope.alerts.push({
          type: "error",
          text: "Please select a rate & text plan before continuing.",
        });
        $scope.close();
      }
    };
    $scope.close = function () {
      $modalInstance.dismiss("close");
    };
    $scope.cancel = function () {
      $scope.close();
      if (!$scope.controlSystem.isNew) goToState();
    };
  },
]);

/**
 * @ngdoc object
 * @name Cellular Activation Modal Box.
 *
 * @description
 *  Modal box that is used when activating cellular numbers from default programming modal box.
 */

App.controller("CellularActivationCtrl", [
  "$scope",
  "$modal",
  "$modalInstance",
  "$rootScope",
  "$state",
  "defaultSent",
  "selectedDefault",
  "UserService",
  function (
    $scope,
    $modal,
    $modalInstance,
    $rootScope,
    $state,
    defaultSent,
    selectedDefault,
    UserService
  ) {
    cellKey = $scope.ProgramDefaultWorkflowService.whereToNextActivation(
      $scope.CSC.sim,
      defaultSent
    );
    $scope.cellButtonText = cellKey;
    function goToState() {
      $state
        .go(
          "app.control_system_edit",
          {
            customer_id: UserService.customer_id,
            control_system_id: $scope.controlSystem.id,
          },
          {
            location: "replace",
          }
        )
        .then(function () {
          $scope.checkAutoProgStatus();
        });
    }

    /**
     * This is a self invoking function to fire off the workflow required on button click. This is to keep the code concise and allow for ease of future implementations.
     */
    $scope.cellButtonFunction = function () {
      $scope.loader.isBusy = true;
      if (cellKey === "Ok") {
        return (function () {
          $scope.CSC.sim.selected_rate_plan = selectedDefault.rate_plan;
          $scope.CSC.sim.selected_text_plan = selectedDefault.text_plan;
          $scope.CSC.modifySIM().finally(function () {
            $scope.loader.isBusy = false;
            goToState();

            $scope.close();
          });
        })();
      } else if (cellKey === "Send Default") {
        return (function () {
          if (
            $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .comm_type === "cell" ||
            $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .comm_type === "persistent_w_cell_backup"
          ) {
            $scope.controlSystem.panels[
              $scope.controlSystem.panel_index
            ].comm_address = $scope.CSC.sim.identifier;
          }
          $scope
            .saveControlSystem(false)
            .then(
              function (success) {
                $scope
                  .sendDefault(
                    $scope.controlSystem.panels[
                      $scope.controlSystem.panel_index
                    ],
                    selectedDefault.name
                  )
                  .then(
                    function (success) {
                      $scope.loader.isBusy = false;
                      goToState();

                      $scope.close();
                    },
                    function (error) {
                      $scope.loader.isBusy = false;
                      $rootScope.alerts.push({
                        type: "error",
                        text: "Please fix errors before selecting a default.",
                      });
                      $scope.close();
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function (error) {
                $scope.loader.isBusy = false;
                $rootScope.alerts.push({
                  type: "error",
                  text: "Please fix errors before selecting a default.",
                });
                $scope.close();
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        })();
      } else if (cellKey === "Activate") {
        return (function () {
          if (selectedDefault.rate_plan && selectedDefault.text_plan) {
            $scope.CSC.sim.selected_rate_plan = selectedDefault.rate_plan;
            $scope.CSC.sim.selected_text_plan = selectedDefault.text_plan;
          }
          $scope
            .activateSIMwithValidations()
            .then(
              function (data) {
                cellKey =
                  $scope.ProgramDefaultWorkflowService.whereToNextActivation(
                    $scope.CSC.sim,
                    defaultSent
                  );
                $scope.cellButtonText = cellKey;
                $scope.loader.isBusy = false;
              },
              function () {}
            )
            .catch(function (error) {
              console.error(error);
            });
        })();
      } else if (cellKey === "Get Status") {
        return (function () {
          var renameSystem = false;
          $scope
            .findSIM($scope.CSC.sim.identifier, renameSystem)
            .then(function () {
              cellKey =
                $scope.ProgramDefaultWorkflowService.whereToNextActivation(
                  $scope.CSC.sim,
                  defaultSent
                );
              $scope.cellButtonText = cellKey;
              $scope.loader.isBusy = false;
            })
            .catch(function (error) {
              console.error(error);
            });
        })();
      }
    };
    $scope.close = function () {
      $modalInstance.dismiss("close");
    };
    $scope.cancel = function () {
      $scope.close();
      if (!$scope.controlSystem.isNew) goToState();
    };
  },
]);

App.controller("videoAnalyticsRecorderEnabledModalControl", [
  "$scope",
  "$modalInstance",
  function ($scope, $modalInstance) {
    $scope.continue = function () {
      $scope.controlSystem.services_manager.video_service_level = "no_video";
      $scope.setNumVideoAnalyticsRecordersAndXcCameras();
      $modalInstance.dismiss("continue");
    };
    $scope.cancel = function () {
      $scope.controlSystem.services_manager.video_analytics_recorder_enabled = false;
      $scope.setNumVideoAnalyticsRecordersAndXcCameras();
      $modalInstance.dismiss("cancel");
    };
  },
]);

App.controller("closeModalControl", [
  "$scope",
  "$modalInstance",
  function ($scope, $modalInstance) {
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };
  },
]);
