App.controller("PersonnelListCtrl", [
  "$q",
  "$scope",
  "$rootScope",
  "$state",
  "UserService",
  "dealerUser",
  "CustomRolesService",
  "PersonUsersService",
  function (
    $q,
    $scope,
    $rootScope,
    $state,
    UserService,
    dealerUser,
    CustomRolesService,
    PersonUsersService
  ) {
    //TODO: Change to use DataTables when time allows

    $scope.theDealer = UserService.dealerInfo;
    $scope["personnelList"] = [];
    $scope["searchText"] = "";
    $scope["filteredList"] = [];
    $scope["refreshingList"] = [];
    $scope["showAddButton"] = false;
    $scope.refreshing = false;

    $scope.refreshPersonnel = function () {
      $scope.refreshing = true;
      $scope.personnelList = [];
      $scope.refreshingList = [];
      $rootScope.personEmails = [];
      let guidEmails = [];
      let ids = [];
      $scope.theDealer.getPersonnel().then(
        async function (data) {
          const dealerUsers = [];
          for (var i = 0; i < data.length; i++) {
            //weed out the guid emails

            if (!data[i].user.email.endsWith("@no-reply.dmp.com"))
              dealerUsers.push(new dealerUser(data[i].user));
            else {
              ids.push(data[i].user.person_id);
              dealerUsers.push(new dealerUser(data[i].user));
            }
          }
          if (ids.length > 0) {
            localStorage.removeItem("personEmails"); // ensure the this is clear to assign new items
            //if there are any personnel with a guid email, swap it to the real one
            $rootScope.personEmails =
              await PersonUsersService.getPersonUsersEmail(ids);
            for (let x = 0; x < $rootScope.personEmails.length; x++) {
              for (let y = 0; y < dealerUsers.length; y++) {
                if (
                  dealerUsers[y].person_id ===
                  $rootScope.personEmails[x].person_id
                ) {
                  dealerUsers[y].email =
                    $rootScope.personEmails[x].person_email_address;
                  dealerUsers[y].locked_at =
                    $rootScope.personEmails[x].person_primary_login_locked ===
                    false
                      ? null
                      : "locked";
                }
              }
            }
            localStorage.setItem(
              "personEmails",
              JSON.stringify($rootScope.personEmails)
            );
          }

          var promises = [];
          angular.forEach(dealerUsers, function (user) {
            if (CustomRolesService.mayHaveCustomRole(user)) {
              promises.push(
                CustomRolesService.getUserRole(UserService.dealer_id, user.id)
                  .then(
                    function (role) {
                      if (role !== null && role.hasOwnProperty("name")) {
                        user["customRole"] = role;
                        user.role = role.name;
                      }
                      $scope.refreshingList.push(user);
                    },
                    function (error) {
                      $rootScope.alerts.push({
                        type: "error",
                        text: "Unable to get role for " + user.name,
                        json: error,
                      });
                      $scope.refreshingList.push(user);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  })
              );
            } else {
              $scope.refreshingList.push(user);
            }
          });
          $q.all(promises)
            .then(
              function (values) {
                finalizeRefresh();
              },
              function (values) {
                console.error(
                  "Personnel list refresh failed to attach all roles. Promise values: " +
                    angular.toJson(values)
                );
                finalizeRefresh();
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        },
        function (error) {
          console.error(error);
        }
      );
    };

    $scope.deleteUser = function (dealerUserObj) {
      var deferred = $q.defer();
      dealerUserObj
        .delete()
        .then(
          function () {
            $scope.refreshPersonnel();
            $rootScope.alerts.push({
              type: "success",
              text: dealerUserObj.email + " deleted successfully.",
            });
          },
          function (error) {
            console.error("User not deleted.");
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.resetUserAccess = function (dealerUserObj) {
      var deferred = $q.defer();
      dealerUserObj
        .resetAccess()
        .then(
          function () {
            console.error(dealerUserObj.email + "password reset successfully!");
            $rootScope.alerts.push({
              type: "success",
              text:
                "Password For " +
                dealerUserObj.email +
                " reset - email instructions sent",
            });
          },
          function (error) {
            console.error("User Access not reset.");
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    const personnelReplacement =
      UserService.dealerInfo.vernaculars.personnel.replacement || "personnel";

    $scope.getDeleteMessage = (personnel) =>
      `Deleting ${personnelReplacement} ${personnel.email}. Are you sure?`;

    // TODO: Can we use an angular $filter to replace this?
    var personnelSearchParameters = [
      "email",
      "first_name",
      "last_name",
      "role",
    ];
    $scope.filterPersonnel = function () {
      if ($scope.searchText === "") {
        $scope.filteredList = $scope.personnelList;
      } else {
        $scope.filteredList = [];
        angular.extend(
          $scope.filteredList,
          $scope.personnelList.filter(function (user) {
            return (
              user[personnelSearchParameters[0]]
                .toLowerCase()
                .indexOf($scope.searchText.toLowerCase()) !== -1
            );
          })
        );
        angular.forEach($scope.personnelList, function (user) {
          if (
            angular.isUndefined(
              $scope.filteredList.find(function (u) {
                return user.id === u.id;
              })
            )
          ) {
            for (var i = 1; i < personnelSearchParameters.length; i++) {
              if (
                user[personnelSearchParameters[i]] !== null &&
                user[personnelSearchParameters[i]]
                  .toLowerCase()
                  .indexOf($scope.searchText.toLowerCase()) !== -1
              ) {
                $scope.filteredList.push(user);
                break;
              }
            }
          }
        });
      }
    };

    function finalizeRefresh() {
      $scope.personnelList = $scope.refreshingList;
      $scope.showAddButton =
        UserService.canCreatePersonnel() &&
        !(
          (UserService.isTechSupport() || UserService.isCustomerSupport()) &&
          $scope.personnelList.length > 1
        );
      $scope.filterPersonnel();
      $scope.refreshing = false;
    }

    function init() {
      UserService.dealerInfo
        .get()
        .then(
          function (success) {
            $scope.refreshPersonnel();
            $scope.filterPersonnel();
          },
          function (error) {
            console.error(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    init();
  },
]);
