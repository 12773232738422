import { Flex, Form } from "components/DaStyledElements";
import React from "react";

const DummyLoginModal = () => {
  return (
    <>
      <div className="panel panel-login">
        <div className="panel-heading text-center">
          <img
            src="/assets/img/DealerAdmin_Color.svg"
            width="90%"
            alt="Dealer-Admin Logo"
          />
        </div>
        <div className="panel-body">
          <form>
            {/* USER NAME */}
            <Flex.Row>
              <Flex.Col size={1}>
                <Form.Group className={`required`}>
                  <input
                    name="userEmail"
                    id="userEmail"
                    type={"text"}
                    placeholder="Enter Email"
                    className="form-control"
                  />
                </Form.Group>
              </Flex.Col>
            </Flex.Row>
            {/* PASSWORD */}
            <Flex.Row>
              <Flex.Col size={1}>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  placeholder="Password"
                />
              </Flex.Col>
            </Flex.Row>
            <Flex.Row>
              <Flex.Col>
                <div className="text-center"></div>
                <div className="text-center mar-t-8">
                  <a onClick={() => null} className="text-muted text-underline">
                    Forgot your password?
                  </a>
                </div>
                <div className="text-center mar-t-8">
                  <a className="text-muted" onClick={() => null}>
                    Generate QR Code
                  </a>
                </div>
              </Flex.Col>
            </Flex.Row>
            <></>
            <input
              type="submit"
              id="sign-in-submit"
              disabled
              className="btn btn-block btn-dmp mt-lg"
              value="Sign In"
              onSubmit={() => {
                return null;
              }}
              onClick={() => null}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default DummyLoginModal;
