import graphql from "babel-plugin-relay/macro";
import { BaseButton } from "common/components/web/Button";
import { getDealerAdminUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import AddCameraModal from "components/AddCameraModal/AddCameraModal";
import { Flex, Form, Notification } from "components/DaStyledElements";
import GenericSuspenseFallback from "components/GenericSuspenseFallback";
import Modal from "components/Modal";
import { AlertsContextProvider, useShowAlert } from "contexts/AlertsContext";
import React from "react";
import {
  RelayEnvironmentProvider,
  useLazyLoadQuery,
  useMutation,
} from "react-relay";
import { react2angular } from "react2angular";
import { ClientApplication } from "securecom-graphql/client";
import styled from "styled-components/macro";
import {
  LoginFormReducer,
  TwoFactorCodeDeliveryMethods,
} from "./LoginFormReducer";
import { LoginForm_AuthenticateMutation } from "./__generated__/LoginForm_AuthenticateMutation.graphql";
import { LoginForm_TwoFALoginAuthorizationMutation } from "./__generated__/LoginForm_TwoFALoginAuthorizationMutation.graphql";
import { LoginForm_TwoFALoginSetupMutation } from "./__generated__/LoginForm_TwoFALoginSetupMutation.graphql";
import { LoginForm__EulaStatusQuery } from "./__generated__/LoginForm__EulaStatusQuery.graphql";

export enum SubmitButtonType {
  LOGIN,
  SECURITY_CODE,
  PHONE_NUMBER,
  EMAIL_CODE,
  SMS_CODE,
}

const MessageParagraph = styled.div`
  font-size: 1.4rem;
  line-height: 1.5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--measure-2x);
`;

const BaseButtonPrimary = styled(BaseButton)`
  color: var(--color-primary-600);
  padding: 1.2rem 2.4rem;
  background-color: #def1f7;
  border-color: var(--color-primary-500);
  transition: all 0.2s ease-out;
  &:hover {
    color: var(--color-primary-600);
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const InlineInputLg = styled.input`
  padding: 0.8rem 1.6rem;
  height: 4.4rem;
`;
const SecurityCodeInput = styled.input`
  padding: 0.4rem 0.8rem;
  text-align: center;
  height: 4.6rem;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.4em;
  width: 8.5em;
  border: 0.1rem solid var(--color-neutral-600);
`;

const StyledAnchor = styled.a`
  text-decoration: underline;
  color: black;
  margin-bottom: 0.8rem;
`;

const TextButton = styled.button`
  border: none;
  background-color: transparent;
  color: var(--color-primary-500);
  font-weight: var(--font-weight-regular);
  transition: all 0.2s ease-in;
  &:hover {
    color: var(--color-primary-700);
    font-weight: var(--font-weight-medium);
  }
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  color: var(--color-neutral-700);
  font-weight: var(--font-weight-regular);
  transition: all 0.2s ease-in;
  &:hover {
    color: var(--color-neutral-900);
    font-weight: var(--font-weight-medium);
  }
`;

export default function LoginFormDisplayRoot(props: any) {
  const environment = props.RelayService.getEnvironment();

  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <React.Suspense fallback={<GenericSuspenseFallback />}>
        <AlertsContextProvider $rootScope={props.$rootScope}>
          <LoginFormDisplay {...props}></LoginFormDisplay>
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}

export const LoginFormDisplay: React.FC<
  React.PropsWithChildren<{
    onCancel: () => void;
    login: (usersList: any) => PromiseLike<string | Error>;
    emailChanged: (email: string) => void;
    passwordChanged: (password: string) => void;
    forgotPassword: () => void;
    UserService: any;
    RelayService: any;
    VideoDeviceService: any;
    $scope: any;
    ControlSystemService: any;
    DA_LOGOS: any;
  }>
> = ({
  login,
  emailChanged,
  passwordChanged,
  forgotPassword,
  RelayService,
  UserService,
  VideoDeviceService,
  $scope,
  ControlSystemService,
  DA_LOGOS,
}) => {
  const [state, dispatch] = React.useReducer(LoginFormReducer, {
    showLogin: true,
    showTwoFAAuthSMS: false,
    showTwoFAAuthEmail: false,
    showTwoFASetup: false,
    showTwoFASetupSMS: false,
    authCode: "",
    userEmail: "",
    userEmailBlurred: false,
    password: "",
    passwordBlurred: false,
    passwordVisibility: false,
    uuid: "",
    sanitizedContactInfo: "",
    token: "",
    method: TwoFactorCodeDeliveryMethods.NONE,
    phoneNumber: "",
    phoneNumberBlurred: false,
    errorMessageLogin: "",
    errorMessageTwoFASetup: "",
    errorMessageTwoFAAuth: "",
    showQrCameraModal: false,
    showEulaModal: false,
    eulaAgreementSigned: false,
    showSecurityCommandEulaModal: false,
  });

  const isEU = process.env.REACT_APP_SECURECOM_ENV === SecureComEnv.Prod_Eu;
  const isSecurityCmd = [
    "scdev",
    "prod_security_cmd",
    "test_security_cmd",
  ].includes(process.env.REACT_APP_SECURECOM_ENV ?? "");
  const isV4Login = true;

  const { getEulaStatus: eulaStatus } =
    useLazyLoadQuery<LoginForm__EulaStatusQuery>(
      graphql`
        query LoginForm__EulaStatusQuery {
          getEulaStatus {
            hasAgreed
          }
        }
      `,
      {},
      { fetchPolicy: "network-only" }
    );

  const [makeLoginMutation, isMakingLoginMutation] =
    useMutation<LoginForm_AuthenticateMutation>(graphql`
      mutation LoginForm_AuthenticateMutation(
        $email: String!
        $PW: String!
        $clientApplication: ClientApplication!
        $forceSendToEmail: Boolean
        $isV4Login: Boolean
      ) {
        authenticate(
          email: $email
          password: $PW
          clientApplication: $clientApplication
          forceSendToEmail: $forceSendToEmail
          isV4Login: $isV4Login
        ) {
          ... on AuthenticateV4SuccessResponse {
            users(accessibleType: [DEALER, SUPERVISOR]) {
              bearerToken
              refreshToken
              user {
                id
                accessible_type: accessibleType
                accessible_id: accessibleId
                email
                __typename
                ... on CustomerUser {
                  customerRole: role
                }
                ... on DealerUser {
                  dealerRole: role
                }
                ... on SupervisorUser {
                  supervisorRole: role
                }
              }
            }
          }
          ... on AuthenticateSuccessResponse {
            __typename
            users(accessibleType: [DEALER, SUPERVISOR]) {
              authToken
              user {
                id
                accessible_type: accessibleType
                accessible_id: accessibleId
                email
                __typename
                ... on CustomerUser {
                  customerRole: role
                }
                ... on DealerUser {
                  dealerRole: role
                }
                ... on SupervisorUser {
                  supervisorRole: role
                }
              }
            }
          }
          ... on AuthenticateTwoFactorSetupRequiredResponse {
            token
            __typename
          }
          ... on AuthenticateWithTwoFactorCodeRequiredResponse {
            uuid
            sanitizedContactInfo
            validationMethod
            __typename
          }
          ... on AuthenticateErrorResponse {
            __typename
            error {
              ... on InvalidLoginCredentialsError {
                type
              }
            }
          }
        }
      }
    `);

  const [
    makeTwoFALoginAuthorizationMutation,
    isMakingTwoFALoginAuthorizationMutation,
  ] = useMutation<LoginForm_TwoFALoginAuthorizationMutation>(graphql`
    mutation LoginForm_TwoFALoginAuthorizationMutation(
      $uuid: String!
      $code: String!
      $clientApplication: ClientApplication!
      $isV4Login: Boolean
    ) {
      validateTwoFactorCode(
        uuid: $uuid
        code: $code
        clientApplication: $clientApplication
        isV4Login: $isV4Login
      ) {
        __typename
        ... on ValidateTwoFactorCodeSuccessResponseWithBearerToken {
          __typename
          users(accessibleType: [DEALER, SUPERVISOR]) {
            bearerToken
            refreshToken
            user {
              id
              accessible_type: accessibleType
              accessible_id: accessibleId
              email
              __typename
              ... on CustomerUser {
                customerRole: role
              }
              ... on DealerUser {
                dealerRole: role
              }
              ... on SupervisorUser {
                supervisorRole: role
              }
            }
          }
        }
        ... on ValidateTwoFactorCodeSuccessResponse {
          __typename
          users {
            authToken
            user {
              id
              twoFactorSettings {
                enabled
                requiredByDealer
                validationMethod
                phoneNumber
                requiredByCustomer
                __typename
              }
            }
          }
        }
        ... on ValidateTwoFactorCodeErrorResponse {
          error {
            __typename
          }
          __typename
        }
      }
    }
  `);

  const [makeTwoFALoginSetupMutation, isMakingTwoFALoginSetupMutation] =
    useMutation<LoginForm_TwoFALoginSetupMutation>(graphql`
      mutation LoginForm_TwoFALoginSetupMutation(
        $method: ValidationMethod!
        $phoneNumber: String
        $tempAuthToken: String
        $clientApplication: ClientApplication!
      ) {
        configureTwoFactorSettings(
          validationMethod: $method
          phoneNumber: $phoneNumber
          tempAuthToken: $tempAuthToken
          clientApplication: $clientApplication
        ) {
          ... on ConfigureTwoFactorSettingsTwoFactorCodeRequiredResponse {
            uuid
            validationMethod
            sanitizedContactInfo
            __typename
          }
          ... on ConfigureTwoFactorSettingsErrorResponse {
            __typename
            error {
              __typename
            }
          }
        }
      }
    `);

  const submitTwoFAPersonLogin = function (forceSendToEmail?: boolean) {
    makeLoginMutation({
      variables: {
        email: state.userEmail,
        PW: state.password.trim(),
        clientApplication: ClientApplication.DEALER_ADMIN,
        forceSendToEmail: forceSendToEmail,
        isV4Login: isV4Login,
      },
      onCompleted: (data) => {
        if ("users" in data.authenticate) {
          login(data.authenticate.users).then(
            (_data: any) => {
              dispatch({
                type: "SHOW_EULA_MODAL",
                value: true,
              });
            },
            (error: string) => {
              dispatch({
                type: "LOG_IN_ERRORED",
                value: error ?? "Login Error",
              });
            }
          );
        }

        if ("token" in data.authenticate) {
          dispatch({
            type: "TWO_F_A_SETUP_NEEDED",
            token: data.authenticate.token ?? "",
          });
        }
        if ("uuid" in data.authenticate) {
          dispatch({
            type: "TWO_F_A_AUTH_CODE_NEEDED",
            sanitizedContactInfo: data.authenticate.sanitizedContactInfo ?? "",
            uuid: data.authenticate.uuid ?? "",
            method: data.authenticate
              .validationMethod as TwoFactorCodeDeliveryMethods,
          });
        }

        if ("error" in data.authenticate) {
          dispatch({
            type: "LOG_IN_ERRORED",
            value: data?.authenticate?.error?.type ?? "Login Error",
          });
        }
      },
    });
  };

  const showAlert = useShowAlert();

  const submitTwoFAAuthentication = function () {
    makeTwoFALoginAuthorizationMutation({
      variables: {
        uuid: state.uuid,
        code: state.authCode,
        clientApplication: ClientApplication.DEALER_ADMIN,
        isV4Login: isV4Login,
      },
      onCompleted: (data) => {
        if ("users" in data.validateTwoFactorCode) {
          login(data.validateTwoFactorCode.users).then(
            (_data: any) => {},
            (error: string) => {
              dispatch({
                type: "TWO_F_A_AUTH_CODE_ERRORED",
                value: error ?? "Login Error",
              });
            }
          );
        }

        if ("error" in data.validateTwoFactorCode) {
          dispatch({
            type: "TWO_F_A_AUTH_CODE_ERRORED",
            value:
              data.validateTwoFactorCode.error.__typename ??
              "Authentication Error",
          });
          if (
            data.validateTwoFactorCode.error.__typename ===
            "TwoFactorMaxAttemptsReachedError"
          ) {
            RelayService.logout();
            showAlert({
              type: "error",
              text: "Too Many Attempts, Logged Out",
              json: "",
            });
            dispatch({ type: "LOGGED_OUT" });
          }
        }
      },
    });
  };

  const submitTwoFASetup = function (
    overRideStateMethod?: TwoFactorCodeDeliveryMethods
  ) {
    makeTwoFALoginSetupMutation({
      variables: {
        tempAuthToken: state.token,
        method: overRideStateMethod ?? state.method,
        phoneNumber: state.phoneNumber ?? state.phoneNumber,
        clientApplication: ClientApplication.DEALER_ADMIN,
      },
      onCompleted: (data) => {
        if ("uuid" in data.configureTwoFactorSettings) {
          dispatch({
            type: "TWO_F_A_AUTH_CODE_NEEDED",
            sanitizedContactInfo:
              data.configureTwoFactorSettings.sanitizedContactInfo,
            uuid: data.configureTwoFactorSettings.uuid,
            method: data.configureTwoFactorSettings
              .validationMethod as TwoFactorCodeDeliveryMethods,
          });
        }
        if ("error" in data.configureTwoFactorSettings) {
          dispatch({
            type: "TWO_F_A_SETUP_ERRORED",
            value:
              data.configureTwoFactorSettings.error.__typename ?? "Setup Error",
          });
        }
      },
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitTwoFAPersonLogin();
  };

  const handleKeypress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    button: SubmitButtonType
  ) => {
    if (event.key === "Enter") {
      button === SubmitButtonType.PHONE_NUMBER
        ? submitTwoFASetup()
        : submitTwoFAAuthentication();
    }
  };

  const handleModalClose = () => {
    dispatch({
      type: "HIDE_QR_CAMERA_MODAL",
    });
  };

  return (
    <>
      <div className="panel panel-login">
        <div className="panel-heading text-center">
          {isSecurityCmd ? (
            <img
              src={DA_LOGOS.securityCommand}
              width="90%"
              alt="Security Command Logo"
            />
          ) : (
            <img src={DA_LOGOS.default} width="90%" alt="Dealer Admin Logo" />
          )}
        </div>
        <div className="panel-body">
          {/* INITIAL LOGIN FORM */}

          {state.showLogin && (
            <>
              <form
                onSubmit={(event) => {
                  handleSubmit(event);
                }}
              >
                {/* USER NAME */}
                <Flex.Row>
                  <Flex.Col size={1}>
                    <Form.Group className={`required, mar-b-8`}>
                      <input
                        name="userEmail"
                        id="userEmail"
                        value={state.userEmail}
                        type={"text"}
                        placeholder="Enter Email"
                        className="form-control"
                        onBlur={() =>
                          dispatch({
                            type: "USER_EMAIL_BLURRED",
                            value: true,
                          })
                        }
                        onFocus={() =>
                          dispatch({
                            type: "USER_EMAIL_BLURRED",
                            value: false,
                          })
                        }
                        onChange={(event) => {
                          emailChanged(event.target.value);
                          dispatch({
                            type: "USER_EMAIL_CHANGED",
                            value: event.target.value,
                          });
                        }}
                        required
                      />
                    </Form.Group>
                  </Flex.Col>
                </Flex.Row>
                {/* PASSWORD */}
                <Flex.Row>
                  <Flex.Col size={1}>
                    <Form.PasswordRevealGroup
                      className={`required`}
                      showPassword={state.passwordVisibility}
                      iconStyle={{ transform: "translateY(25%)" }}
                      setInputStyle={() =>
                        dispatch({
                          type: "TOGGLE_PASSWORD_VISIBILITY",
                        })
                      }
                      eyeIsVisible={!!state.password}
                    >
                      <input
                        type={state.passwordVisibility ? "text" : "password"}
                        className="form-control"
                        name="password"
                        id="password"
                        placeholder="Password"
                        value={state.password}
                        onBlur={() =>
                          dispatch({
                            type: "PASSWORD_BLURRED",
                            value: true,
                          })
                        }
                        onFocus={() =>
                          dispatch({
                            type: "PASSWORD_BLURRED",
                            value: false,
                          })
                        }
                        onChange={(event) => {
                          passwordChanged(event.target.value);
                          dispatch({
                            type: "PASSWORD_CHANGED",
                            value: event.target.value,
                          });
                        }}
                        required
                      />
                    </Form.PasswordRevealGroup>
                  </Flex.Col>
                </Flex.Row>
                <Flex.Row>
                  <Flex.Col>
                    {isSecurityCmd ? null : (
                      <div className="text-center text-black mar-b-8">
                        <div className="mar-b-8">
                          {isEU
                            ? "Logging in within the Americas? "
                            : "Logging in outside of the Americas? "}
                        </div>
                        <StyledAnchor
                          href={
                            isEU
                              ? getDealerAdminUrl(SecureComEnv.Production)
                              : getDealerAdminUrl(SecureComEnv.Prod_Eu)
                          }
                        >
                          Click Here
                        </StyledAnchor>
                      </div>
                    )}
                    <div className="text-center mar-b-8">
                      <a
                        onClick={forgotPassword}
                        className="text-underline text-black"
                      >
                        Forgot your password?
                      </a>
                    </div>
                    <div className="text-center mar-b-8">
                      <a
                        className="text-black"
                        onClick={() =>
                          dispatch({
                            type: "SHOW_QR_CAMERA_MODAL",
                          })
                        }
                      >
                        Generate QR Code
                      </a>
                    </div>
                  </Flex.Col>
                </Flex.Row>
                <>
                  {state.errorMessageLogin && (
                    <Notification.Alert
                      type="error"
                      message={
                        state.errorMessageLogin ===
                          "INVALID_LOGIN_CREDENTIALS" ||
                        state.errorMessageLogin === "not authorized"
                          ? "Not Authorized"
                          : "Login Error"
                      }
                    />
                  )}
                </>

                <button
                  type="submit"
                  id="sign-in-submit"
                  disabled={isMakingLoginMutation}
                  className="btn btn-block btn-dmp"
                >
                  {" "}
                  Sign In{" "}
                </button>
              </form>
            </>
          )}

          {/* TWO FA SETUP FORM */}
          {state.showTwoFASetup && (
            <>
              <Flex.Row align={"center"} direction={"column"}>
                <Flex.Row justify={"center"}>
                  <h3 className="mar-t-8 mar-b-16">
                    Two Factor Authentication Setup
                  </h3>
                </Flex.Row>
                <MessageParagraph>
                  Two-Factor Authentication adds an extra layer of security for
                  your account. When you log in, you are required to enter a
                  security code that is sent in a text message or email
                </MessageParagraph>
                <MessageParagraph>
                  How would you like to receive the security code?
                </MessageParagraph>
                <Flex.Row direction={"row"}>
                  <BaseButtonPrimary
                    className="mar-r-8"
                    onClick={() => {
                      dispatch({
                        type: "TWO_F_A_SETUP_SMS",
                      });
                    }}
                  >
                    Text
                  </BaseButtonPrimary>
                  <BaseButtonPrimary
                    onClick={() => {
                      dispatch({
                        type: "TWO_F_A_SETUP_EMAIL",
                      });
                      submitTwoFASetup(TwoFactorCodeDeliveryMethods.EMAIL);
                    }}
                    disabled={isMakingTwoFALoginSetupMutation}
                  >
                    Email
                  </BaseButtonPrimary>
                </Flex.Row>
              </Flex.Row>
            </>
          )}
          {/* SMS SETUP */}
          {state.method === TwoFactorCodeDeliveryMethods.SMS &&
            state.showTwoFASetupSMS && (
              <>
                {/* Show this message if Two Factor Method is SMS*/}
                {state.method === TwoFactorCodeDeliveryMethods.SMS && (
                  <>
                    <Flex.Row justify={"center"}>
                      <h3 className="mar-t-8 mar-b-0">Enter Phone Number</h3>
                    </Flex.Row>
                    <Flex.Row
                      className="mar-t-16"
                      justify={"center"}
                      align={"flex-start"}
                    >
                      <MessageParagraph>
                        Enter the phone number you'd like the security code sent
                        to when you log in.
                      </MessageParagraph>
                    </Flex.Row>
                  </>
                )}
                <Flex.Row justify="center" align="center" direction="column">
                  <Form.Label>Phone Number</Form.Label>
                  <div className="form-inline">
                    <Form.Group className={`required`}>
                      <InlineInputLg
                        name="phone-number"
                        id="phone-number"
                        value={state.phoneNumber}
                        maxLength={15}
                        className="form-control"
                        onBlur={() => {
                          dispatch({
                            type: "PHONE_NUMBER_BLURRED",
                            value: true,
                          });
                        }}
                        onChange={(event) => {
                          dispatch({
                            type: "PHONE_NUMBER_CHANGED",
                            value: event.target.value,
                          });
                        }}
                        onKeyDown={(event) => {
                          handleKeypress(
                            event as React.KeyboardEvent<HTMLInputElement>,
                            SubmitButtonType.PHONE_NUMBER
                          );
                        }}
                        required
                      />
                    </Form.Group>
                    {state.errorMessageTwoFASetup && (
                      <Notification.Alert
                        type="error"
                        message={
                          state.errorMessageTwoFASetup ===
                          "InvalidPhoneNumberError"
                            ? "Invalid Phone Number"
                            : ""
                        }
                      />
                    )}
                  </div>
                  <BaseButtonPrimary
                    className="mar-t-4"
                    disabled={
                      state.phoneNumber.length < 10 ||
                      isMakingTwoFALoginSetupMutation
                    }
                    onClick={() => {
                      submitTwoFASetup();
                    }}
                  >
                    Send Security Code
                  </BaseButtonPrimary>
                </Flex.Row>
              </>
            )}

          {/* SMS AND EMAIL SECURITY CODE VERIFICATION */}
          {(state.showTwoFAAuthSMS || state.showTwoFAAuthEmail) && (
            <Flex.Row align={"center"} direction={"column"}>
              {state.showTwoFAAuthSMS && (
                <>
                  <h3 className="mar-t-8 mar-b-16">Enter Security Code</h3>
                  <MessageParagraph style={{ textAlign: "center" }}>
                    Enter the security code that was sent to your phone number
                    ending in "{state.sanitizedContactInfo}".
                  </MessageParagraph>
                </>
              )}
              {state.showTwoFAAuthEmail && (
                <>
                  <h3 className="mar-t-8 mar-b-16">Enter Security Code</h3>
                  <MessageParagraph style={{ textAlign: "center" }}>
                    Enter the security code that was sent to your email.
                  </MessageParagraph>
                </>
              )}

              <Form.Group className={`required`}>
                <SecurityCodeInput
                  name="auth-code"
                  id="auth-code"
                  maxLength={6}
                  value={state.authCode}
                  className="form-control"
                  onChange={(event) =>
                    dispatch({
                      type: "TWO_F_A_AUTH_CODE_CHANGED",
                      value: event.target.value,
                    })
                  }
                  onFocus={() => {
                    dispatch({
                      type: "TWO_F_A_AUTH_CODE_ERRORED",
                      value: "",
                    });
                  }}
                  onKeyDown={(event) => {
                    handleKeypress(
                      event as React.KeyboardEvent<HTMLInputElement>,
                      SubmitButtonType.SECURITY_CODE
                    );
                  }}
                  required
                />
              </Form.Group>
              {state.errorMessageTwoFAAuth && (
                <Notification.Alert
                  type="error"
                  message={
                    state.errorMessageTwoFAAuth === "not authorized"
                      ? "Not Authorized"
                      : state.errorMessageTwoFAAuth ===
                        "TwoFactorInvalidCodeError"
                      ? `The security code is expired or invalid.`
                      : state.authCode.length !== 6
                      ? `You must enter your six digit security code.`
                      : `Login Error`
                  }
                />
              )}
              <BaseButtonPrimary
                type="submit"
                className="mar-b-16"
                disabled={
                  !state.authCode || isMakingTwoFALoginAuthorizationMutation
                }
                onClick={() => {
                  if (state.authCode.length === 6) {
                    submitTwoFAAuthentication();
                  } else {
                    dispatch({
                      type: "TWO_F_A_AUTH_CODE_ERRORED",
                      value: "Field required",
                    });
                  }
                }}
              >
                Submit
              </BaseButtonPrimary>
            </Flex.Row>
          )}
          {
            <Flex.Row justify="center" direction={"row"}>
              {(state.showTwoFAAuthSMS || state.showTwoFAAuthEmail) && (
                <>
                  <TextButton
                    onClick={() => {
                      if (state.token) {
                        state.method === TwoFactorCodeDeliveryMethods.SMS
                          ? submitTwoFASetup()
                          : submitTwoFASetup(
                              TwoFactorCodeDeliveryMethods.EMAIL
                            );
                      } else {
                        submitTwoFAPersonLogin();
                      }
                    }}
                    disabled={isMakingTwoFALoginSetupMutation}
                  >
                    Send a New Code
                  </TextButton>
                  {state.showTwoFAAuthSMS && !state.token && (
                    <>
                      |
                      <TextButton
                        onClick={() => submitTwoFAPersonLogin(true)}
                        disabled={isMakingLoginMutation}
                      >
                        Try Another Way
                      </TextButton>
                    </>
                  )}
                  |
                </>
              )}
              {!state.showLogin && (
                <TextButton
                  onClick={() => {
                    RelayService.logout();
                    dispatch({
                      type: "LOGGED_OUT",
                    });
                  }}
                >
                  Log Out
                </TextButton>
              )}
            </Flex.Row>
          }
        </div>
      </div>
      {state.showQrCameraModal ? (
        <Modal size="medium">
          <AddCameraModal
            handleModalClose={handleModalClose}
            userService={UserService}
            state={state}
            scope={$scope}
            videoDeviceService={VideoDeviceService}
            controlSystemService={ControlSystemService}
          />
        </Modal>
      ) : null}
    </>
  );
};

export function dangerouslyAddToApp() {
  App.component(
    "loginFormDisplayRoot",
    react2angular(
      LoginFormDisplayRoot,
      [
        "onCancel",
        "login",
        "emailChanged",
        "passwordChanged",
        "forgotPassword",
      ],
      [
        "$rootScope",
        "$scope",
        "UserService",
        "RelayService",
        "$state",
        "VideoDeviceService",
        "ControlSystemService",
        "DA_LOGOS",
      ]
    )
  );
}
