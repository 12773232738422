import NakedButton from "common/components/web/Button/NakedButton";
import PopUpMenu from "components/PopUpMenu";
import * as React from "react";
import styled from "styled-components";
import { focusRing } from "utils/styles";

const Button = styled(NakedButton)`
  color: var(--color-neutral);
  font-size: var(--measure-font-20);

  &:hover {
    color: var(--color-text-default);
  }

  &:focus {
    ${focusRing};
  }
`;
const Icon = styled.svg`
  width: 1em;
  height: 1em;
`;

function MoreButton(
  props: Omit<
    React.ComponentProps<typeof PopUpMenu>,
    "anchor" | "open" | "onDismiss"
  >
) {
  const [open, setOpen] = React.useState(false);

  return (
    <PopUpMenu
      {...props}
      anchor={
        <Button
          onClick={() => {
            setOpen(!open);
          }}
          type="button"
          title="More options"
        >
          <Icon viewBox="0 0 64 64">
            <circle r="8" cx="8" cy="32" fill="currentColor" />
            <circle r="8" cx="32" cy="32" fill="currentColor" />
            <circle r="8" cx="56" cy="32" fill="currentColor" />
          </Icon>
        </Button>
      }
      open={open}
      onDismiss={() => {
        setOpen(false);
      }}
    />
  );
}

export default MoreButton;
