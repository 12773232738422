import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { BellOptions, ControlSystem } from "securecom-graphql/client";
import BellOptionsAutomaticBellTestField from "../common/BellOptionsFields/BellOptionsAutomaticBellTestField";
import BellOptionsAuxiliary1BellActionField from "../common/BellOptionsFields/BellOptionsAuxiliary1BellActionField";
import BellOptionsAuxiliary2BellActionField from "../common/BellOptionsFields/BellOptionsAuxiliary2BellActionField";
import BellOptionsBellCutoffTimeField from "../common/BellOptionsFields/BellOptionsBellCutoffTimeField";
import BellOptionsBellOutputField from "../common/BellOptionsFields/BellOptionsBellOutputField";
import BellOptionsBurglaryBellActionField from "../common/BellOptionsFields/BellOptionsBurglaryBellActionField";
import BellOptionsCarbonMonoxideBellActionField from "../common/BellOptionsFields/BellOptionsCarbonMonoxideBellActionField";
import { BellOptionsContextProvider } from "../common/BellOptionsFields/BellOptionsContext";
import BellOptionsEmergencyBellActionField from "../common/BellOptionsFields/BellOptionsEmergencyBellActionField";
import BellOptionsFireBellActionField from "../common/BellOptionsFields/BellOptionsFireBellAction";
import BellOptionsPanicBellActionField from "../common/BellOptionsFields/BellOptionsPanicBellActionField";
import BellOptionsSupervisoryBellActionField from "../common/BellOptionsFields/BellOptionsSupervisoryBellActionField";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { panelVersionGTOE } from "../utils/panel";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import refreshMutationConcreteRequest, {
  XTBellOptionsProgrammingConceptFormBellOptionsRefreshMutation,
} from "./__generated__/XTBellOptionsProgrammingConceptFormBellOptionsRefreshMutation.graphql";
import {
  XTBellOptionsProgrammingConceptFormBellOptionsSendMutation,
  XTBellOptionsProgrammingConceptFormBellOptionsSendMutation$data,
} from "./__generated__/XTBellOptionsProgrammingConceptFormBellOptionsSendMutation.graphql";
import {
  XTBellOptionsProgrammingConceptFormInline_controlSystem$data,
  XTBellOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XTBellOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import { XTBellOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XTBellOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Bell Options";
export const conceptId = "xt-bell-options";

export const getState = (
  controlSystem: XTBellOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XTBellOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          bellOptions {
            id
            emergencyBellAction
            auxiliary1BellAction
            auxiliary2BellAction
            carbonMonoxideBellAction
            bellCutoffTime
            bellCutoffTimeMin
            bellCutoffTimeMax
            automaticBellTest
            bellOutput
            fireBellAction
            burglaryBellAction
            supervisoryBellAction
            panicBellAction
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation XTBellOptionsProgrammingConceptFormBellOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshBellOptions(id: $id) {
      ... on RefreshBellOptionsSuccessPayload {
        __typename
        controlSystem {
          ...XTBellOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XTBellOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XTBellOptionsProgrammingConceptFormBellOptionsRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshBellOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Bell Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshBellOptions: {
                    __typename: response.refreshBellOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Bell Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Bell Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XTBellOptionsProgrammingConceptFormBellOptionsSendMutation(
    $systemId: ID!
    $bellOptions: BellOptionsInput!
  ) {
    sendBellOptionsProgramming(systemId: $systemId, bellOptions: $bellOptions) {
      ... on SendBellOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          ...XTBellOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendBellOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XTBellOptionsProgrammingConceptFormBellOptionsSendMutation$data,
  originalControlSystemData: XTBellOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendBellOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshBellOptions: {
          __typename: "RefreshBellOptionsSuccessPayload",
          controlSystem: getState(
            response.sendBellOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XTBellOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XTBellOptionsProgrammingConceptFormBellOptionsSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { bellOptions },
        } = getState(props.controlSystem);
        if (bellOptions) {
          save({
            variables: {
              systemId,
              bellOptions: {
                bellCutoffTime: bellOptions.bellCutoffTime,
                automaticBellTest: bellOptions.automaticBellTest,
                bellOutput: bellOptions.bellOutput,
                fireBellAction: bellOptions.fireBellAction,
                burglaryBellAction: bellOptions.burglaryBellAction,
                supervisoryBellAction: bellOptions.supervisoryBellAction,
                panicBellAction: bellOptions.panicBellAction,
                emergencyBellAction: bellOptions.emergencyBellAction,
                auxiliary1BellAction: bellOptions.auxiliary1BellAction,
                auxiliary2BellAction: bellOptions.auxiliary2BellAction,
                carbonMonoxideBellAction: bellOptions.carbonMonoxideBellAction,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendBellOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Bell Options Programming Saved To the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendBellOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendBellOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readBellOptionsTemplateData = (
  programmingTemplateConcepts: XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        bellOptions {
          included
          bellCutoffTime {
            included
            data
          }
          automaticBellTest {
            included
            data
          }
          bellOutput {
            included
            data
          }
          fireBellAction {
            included
            data
          }
          burglaryBellAction {
            included
            data
          }
          supervisoryBellAction {
            included
            data
          }
          panicBellAction {
            included
            data
          }
          emergencyBellAction {
            included
            data
          }
          auxiliary1BellAction {
            included
            data
          }
          auxiliary2BellAction {
            included
            data
          }
          carbonMonoxideBellAction {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).bellOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readBellOptionsTemplateData(programmingTemplateConcepts);

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const bellOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "bellOptions",
      "BellOptions"
    ) as unknown as RecordProxy<BellOptions>;

    applyTemplateScalarDataToRecordProxy(bellOptionsRecordProxy, templateData);
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [{ panel }] =
    useControlSystemFragment<XTBellOptionsProgrammingConceptForm_controlSystem$key>(graphql`
      fragment XTBellOptionsProgrammingConceptForm_controlSystem on ControlSystem {
        panel {
          softwareVersion
          helpFiles {
            programmingGuideUrl
            installGuideUrl
          }
          ...PanelContext_panel
          ...PanelContextUseSoftwareVersion_panel
          ...PanelContextUseSupportsTemporal3_panel
          ...PanelContextUseHardwareModel_panel
          bellOptions {
            ...BellOptionsContext_bellOptions
            ...BellOptionsBellCutoffTimeField_bellOptions
            ...BellOptionsAutomaticBellTestField_bellOptions
            ...BellOptionsBellOutputField_bellOptions
            ...BellOptionsFireBellActionField_bellOptions
            ...BellOptionsBurglaryBellActionField_bellOptions
            ...BellOptionsSupervisoryBellActionField_bellOptions
            ...BellOptionsPanicBellActionField_bellOptions
            ...BellOptionsEmergencyBellActionField_bellOptions
            ...BellOptionsAuxiliary1BellActionField_bellOptions
            ...BellOptionsAuxiliary2BellActionField_bellOptions
            ...BellOptionsCarbonMonoxideBellActionField_bellOptions
          }
          systemOptions {
            ...SystemOptionsContextSystemType_systemOptions
          }
        }
      }
    `);

  const {
    bellOptions,
    softwareVersion,
    helpFiles: { programmingGuideUrl },
  } = panel;

  return (
    <PanelContextProvider panel={panel}>
      <BellOptionsContextProvider bellOptions={bellOptions}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          title={title}
          helpLink={`${programmingGuideUrl}#Bell%20Options`}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(bellOptions)}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <BellOptionsBellCutoffTimeField />
              <BellOptionsAutomaticBellTestField />
              <BellOptionsBellOutputField />
              <BellOptionsFireBellActionField />
              <BellOptionsBurglaryBellActionField />
              <BellOptionsSupervisoryBellActionField />
              <BellOptionsPanicBellActionField />
              <BellOptionsEmergencyBellActionField />
              <BellOptionsAuxiliary1BellActionField />
              <BellOptionsAuxiliary2BellActionField />
              {panelVersionGTOE(183, softwareVersion) && (
                <BellOptionsCarbonMonoxideBellActionField />
              )}
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </BellOptionsContextProvider>
    </PanelContextProvider>
  );
}
