import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { CardType } from "components/SiteForm/ExistingSite/CardFormatForm";
import * as React from "react";
import { usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCardFormatFragment } from "./CardFormatContext";
import {
  CardFormatTypeField_cardFormat$data,
  CardFormatTypeField_cardFormat$key,
} from "./__generated__/CardFormatTypeField_cardFormat.graphql";
import { CardFormatTypeField_CardFormatList_panel$key } from "./__generated__/CardFormatTypeField_CardFormatList_panel.graphql";

export const cardFormatTypeFieldId = (number: string) =>
  `card-format-type-${number}`;

function CardFormatTypeField() {
  const [cardFormatData, updateCardFormat] =
    useCardFormatFragment<CardFormatTypeField_cardFormat$key>(
      graphql`
        fragment CardFormatTypeField_cardFormat on CardFormat {
          id
          type
          number
          wiegandLength
          siteCodePosition
          siteCodeLength
          userCodePosition
          userCodeLength
          userCodeDigits
        }
      `
    );

  const [panel] =
    usePanelFragment<CardFormatTypeField_CardFormatList_panel$key>(
      graphql`
        fragment CardFormatTypeField_CardFormatList_panel on Panel {
          cardFormats {
            id
            wiegandLength
          }
        }
      `
    );

  const { id, type, number, wiegandLength } = cardFormatData;
  const fieldId = cardFormatTypeFieldId(number);

  const otherWiegandLengthList = panel.cardFormats
    .filter((format) => format.id !== id && format.wiegandLength)
    .map((format) => format.wiegandLength);

  const duplicateWiegandLengthPattern = otherWiegandLengthList
    .map((length) => `^${length}$`)
    .join(`|`);

  const pattern = duplicateWiegandLengthPattern
    ? `(?!${duplicateWiegandLengthPattern}|^000$|^00$|^0$)^[0-1]?[0-9]{1,2}|2[0-4][0-9]|25[0-5]$`
    : `(?!^000$|^00$|^0$)^[0-1]?[0-9]{1,2}|2[0-4][0-9]|25[0-5]$`;

  type cardFormatType = Omit<
    CardFormatTypeField_cardFormat$data,
    "number" | "id" | " $fragmentType"
  >;

  const updateCardType = (cardFormat: cardFormatType) => {
    updateCardFormat((recordProxy) => {
      recordProxy.setValue(cardFormat.type, "type");
      recordProxy.setValue(cardFormat.wiegandLength, "wiegandLength");
      recordProxy.setValue(cardFormat.siteCodePosition, "siteCodePosition");
      recordProxy.setValue(cardFormat.siteCodeLength, "siteCodeLength");
      recordProxy.setValue(cardFormat.userCodePosition, "userCodePosition");
      recordProxy.setValue(cardFormat.userCodeLength, "userCodeLength");
      recordProxy.setValue(cardFormat.userCodeDigits, "userCodeDigits");
    });
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);

    switch (value) {
      case CardType.DMP:
        updateCardType({
          type: value,
          wiegandLength: 26,
          siteCodePosition: 1,
          siteCodeLength: 7,
          userCodePosition: 8,
          userCodeLength: 17,
          userCodeDigits: 5,
        });
        break;
      case CardType.DMP_BLUETOOTH:
        updateCardType({
          type: value,
          wiegandLength: 56,
          siteCodePosition: 1,
          siteCodeLength: 16,
          userCodePosition: 17,
          userCodeLength: 34,
          userCodeDigits: 10,
        });
        break;
      case CardType.STANDARD_26:
        updateCardType({
          type: value,
          wiegandLength: 26,
          siteCodePosition: 1,
          siteCodeLength: 8,
          userCodePosition: 9,
          userCodeLength: 16,
          userCodeDigits: 5,
        });
        break;
      case CardType.STANDARD_37:
        updateCardType({
          type: value,
          wiegandLength: 37,
          siteCodePosition: 1,
          siteCodeLength: 16,
          userCodePosition: 17,
          userCodeLength: 19,
          userCodeDigits: 6,
        });
        break;
      case CardType.STANDARD_37_NO_CODE:
        updateCardType({
          type: value,
          wiegandLength: 37,
          siteCodePosition: 0,
          siteCodeLength: 1,
          userCodePosition: 1,
          userCodeLength: 35,
          userCodeDigits: 11,
        });
        break;
      case CardType.CORP_35:
        updateCardType({
          type: value,
          wiegandLength: 35,
          siteCodePosition: 2,
          siteCodeLength: 12,
          userCodePosition: 14,
          userCodeLength: 20,
          userCodeDigits: 6,
        });
        break;
      case CardType.CORP_48:
        updateCardType({
          type: value,
          wiegandLength: 48,
          siteCodePosition: 2,
          siteCodeLength: 22,
          userCodePosition: 24,
          userCodeLength: 23,
          userCodeDigits: 7,
        });
        break;
      case CardType.DMP_WAVELYNX:
        updateCardType({
          type: value,
          wiegandLength: 40,
          siteCodePosition: 0,
          siteCodeLength: 1,
          userCodePosition: 0,
          userCodeLength: 40,
          userCodeDigits: 10,
        });
        break;
      default:
        updateCardType({ ...cardFormatData, type: value });
    }
  };

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Card Format Type"
      // disabled={disabled}
    >
      <Select
        id={fieldId}
        value={type}
        onChange={handleTypeChange}
        getValidationMessage={() => {
          if (
            !`${wiegandLength}`.match(pattern) &&
            ![CardType.CUSTOM].includes(type)
          )
            return "Wiegand length already in use";

          return "";
        }}
      >
        <Select.Option key={CardType.CUSTOM} value={CardType.CUSTOM}>
          Custom
        </Select.Option>
        <Select.Option key={CardType.DMP} value={CardType.DMP}>
          DMP
        </Select.Option>
        <Select.Option
          key={CardType.DMP_BLUETOOTH}
          value={CardType.DMP_BLUETOOTH}
        >
          DMP Bluetooth 56-Bit
        </Select.Option>
        <Select.Option
          key={CardType.DMP_WAVELYNX}
          value={CardType.DMP_WAVELYNX}
        >
          DMP Wavelynx 40 Bit
        </Select.Option>
        <Select.Option key={CardType.STANDARD_26} value={CardType.STANDARD_26}>
          Standard 26 Bit
        </Select.Option>
        <Select.Option key={CardType.STANDARD_37} value={CardType.STANDARD_37}>
          Standard 37 Bit (with Facility Code)
        </Select.Option>
        <Select.Option
          key={CardType.STANDARD_37_NO_CODE}
          value={CardType.STANDARD_37_NO_CODE}
        >
          Standard 37 Bit (without Facility Code)
        </Select.Option>
        <Select.Option key={CardType.CORP_35} value={CardType.CORP_35}>
          Corporate 1000 (35 Bit)
        </Select.Option>
        <Select.Option key={CardType.CORP_48} value={CardType.CORP_48}>
          Corporate 1000 (48 Bit)
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatTypeField;
