import { GenericPageFallback } from "components/GenericPageFallback";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import SecCommandOptionsForm from "./SecCommandOptionsForm";

export default function SecurityCommandOptions({
  RelayService,
  $stateParams,
  UserService,
  $rootScope,
  $state,
  PROPS,
  isSaving,
  stateIsEdit,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <SecCommandOptionsForm
            dealerId={$stateParams.dealer_id}
            isSaving={isSaving}
            stateIsEdit={stateIsEdit}
          />
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "securityCommandOptions",
    react2angular(
      SecurityCommandOptions,
      ["isSaving", "stateIsEdit"],
      [
        "$rootScope",
        "$state",
        "$scope",
        "UserService",
        "RelayService",
        "$stateParams",
        "PROPS",
      ]
    )
  );
}
