import { BellActions } from "securecom-graphql/client";

export const parseTemporalvsTemporal3 = (
  value: BellActions,
  temporal3Supported: boolean
) => {
  if ([BellActions.TEMPORAL, BellActions.TEMPORAL_3].includes(value)) {
    return temporal3Supported ? BellActions.TEMPORAL_3 : BellActions.TEMPORAL;
  } else {
    return value;
  }
};
