import { sequenceReducers } from "common/utils/universal/reducers";
import {
  Actions as AddressFormActions,
  reducer as billingAddressReducer,
} from "../CommonForms/BillingAddressForm";

import {
  ControlSystemCommType,
  SiteControlSystemIntendedUsage,
} from "securecom-graphql/client";
import { DMPTimezoneOffsetOptions } from "utils/dates";
import { BillingAddress, SavingState } from "../types";

export type Actions =
  | {
      type: "WALKTHROUGH_SUBMITTED" | "WALKTHROUGH_SAVED" | "WALKTHROUGH_IDLED";
    }
  | {
      type: "WALKTHROUGH_ERRORED";
      error: {
        type: string;
        payload?: any;
      };
    }
  | {
      type: "SYSTEM_NAME_CHANGED" | "SYSTEM_TYPE_CHANGED";

      value: string;
    }
  | {
      type: "SYSTEM_NICKNAME_CHANGED";
      value: string | null;
    }
  | {
      type: "SITE_TIMEZONE_CHANGED";
      value: DMPTimezoneOffsetOptions;
    }
  | {
      type:
        | "USE_DAYLIGHT_SAVINGS_TIME_TOGGLED"
        | "PRE_PROGRAM_TOGGLED"
        | "SITE_SAVED";
    }
  | {
      type: "USE_BILLING_ADDRESS_TOGGLED";
      billingAddress: BillingAddress;
    }
  | {
      type: "SERIAL_NUMBER_UPDATED";
      value: string;
    }
  | {
      type: "INTENDED_USE_CHANGED";
      value: SiteControlSystemIntendedUsage;
    }
  | {
      type: "CONNECTION_TYPE_CHANGED";
      value: ControlSystemCommType;
    }
  | AddressFormActions;

export type State = {
  name: string;
  nickname: string | null;
  type: string;
  useBillingAddress: boolean;
  timezoneOffset: number;
  observesDaylightSavingsTime: boolean;
  firstDoorSerialNumber: string;
  preProgram: boolean;
  savingState: SavingState;
  lastError?: {
    type: string;
    payload?: any;
  };
  intendedUsage: SiteControlSystemIntendedUsage;
  connectionType: ControlSystemCommType;
  vernaculars?: { original: string; replacement: string }[];
} & BillingAddress;

function walkthroughReducer(state: State, action: Actions): State {
  switch (action.type) {
    case "WALKTHROUGH_SUBMITTED":
      return {
        ...state,
        savingState: SavingState.Saving,
        lastError: undefined,
      };
    case "WALKTHROUGH_SAVED":
      return { ...state, savingState: SavingState.Success };
    case "WALKTHROUGH_IDLED":
      return { ...state, savingState: SavingState.Idle };
    case "WALKTHROUGH_ERRORED":
      return {
        ...state,
        savingState: SavingState.Errored,
        lastError: action.error,
      };
    case "SYSTEM_TYPE_CHANGED":
      return {
        ...state,
        type: action.value,
      };
    case "SYSTEM_NAME_CHANGED":
      return { ...state, name: action.value };
    case "SYSTEM_NICKNAME_CHANGED":
      return { ...state, nickname: action.value };
    case "SITE_TIMEZONE_CHANGED":
      return { ...state, timezoneOffset: action.value };
    case "USE_BILLING_ADDRESS_TOGGLED":
      return {
        ...state,
        useBillingAddress: !state.useBillingAddress,
      };
    case "USE_DAYLIGHT_SAVINGS_TIME_TOGGLED":
      return {
        ...state,
        observesDaylightSavingsTime: !state.observesDaylightSavingsTime,
      };
    case "SERIAL_NUMBER_UPDATED":
      return { ...state, firstDoorSerialNumber: action.value };
    case "PRE_PROGRAM_TOGGLED":
      return {
        ...state,
        preProgram: !state.preProgram,
      };
    case "INTENDED_USE_CHANGED":
      return { ...state, intendedUsage: action.value };
    case "CONNECTION_TYPE_CHANGED":
      return { ...state, connectionType: action.value };
    default:
      return state;
  }
}

export const reducer = sequenceReducers([
  billingAddressReducer,
  walkthroughReducer,
]);
