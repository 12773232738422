import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  PanelProgrammingLanguage,
  SystemOptions,
  SystemType,
} from "securecom-graphql/client";
import {
  ChangedProgrammingConcepts,
  removeProgrammingConceptFromChangedProgrammingConcepts,
  useSetChangedProgrammingConcepts,
} from "../common/ChangedProgrammingConceptsContext";
import {
  ControlSystemContextProvider,
  useControlSystemFragment,
} from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { PanelContextProvider } from "../common/PanelContext";
import ProgrammingConceptForm from "../common/ProgrammingConceptForm";
import SystemOptionsAllowOwnUserCodeChangeField from "../common/SystemOptionsFields/SystemOptionsAllowOwnUserCodeChangeField";
import SystemOptionsBypassLimitField from "../common/SystemOptionsFields/SystemOptionsBypassLimitField";
import SystemOptionsCelsiusField from "../common/SystemOptionsFields/SystemOptionsCelsiusField";
import SystemOptionsClosingWaitField from "../common/SystemOptionsFields/SystemOptionsClosingWaitField";
import { SystemOptionsContextProvider } from "../common/SystemOptionsFields/SystemOptionsContext";
import SystemOptionsCrossZoneTimeField from "../common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import SystemOptionsDetectWirelessJammingField from "../common/SystemOptionsFields/SystemOptionsDetectWirelessJammingField";
import SystemOptionsEnableKeypadPanicKeysField from "../common/SystemOptionsFields/SystemOptionsEnableKeypadPanicKeysField";
import SystemOptionsEnhancedZoneTestField from "../common/SystemOptionsFields/SystemOptionsEnhancedZoneTestField";
import SystemOptionsEntryDelay1Field from "../common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import SystemOptionsEntryDelay2Field from "../common/SystemOptionsFields/SystemOptionsEntryDelay2Field";
import SystemOptionsEntryDelay3Field from "../common/SystemOptionsFields/SystemOptionsEntryDelay3Field";
import SystemOptionsEntryDelay4Field from "../common/SystemOptionsFields/SystemOptionsEntryDelay4Field";
import SystemOptionsEolResistorValueField from "../common/SystemOptionsFields/SystemOptionsEolResistorValueField";
import SystemOptionsHoldupConfirmationTimeField from "../common/SystemOptionsFields/SystemOptionsHoldupConfirmationTimeField";
import SystemOptionsHoursFromGMTField from "../common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import SystemOptionsInactiveUserCodeAuditField from "../common/SystemOptionsFields/SystemOptionsInactiveUserCodeAuditField";
import SystemOptionsInstantArmingField from "../common/SystemOptionsFields/SystemOptionsInstantArmingField";
import SystemOptionsIntruderConfirmationTimeField from "../common/SystemOptionsFields/SystemOptionsIntruderConfirmationTimeField";
import SystemOptionsIso2CountryCodeField from "../common/SystemOptionsFields/SystemOptionsIso2CountryCodeField";
import SystemOptionsKeypadArmedLedField from "../common/SystemOptionsFields/SystemOptionsKeypadArmedLedField";
import SystemOptionsLatchedSupervisoryZonesField from "../common/SystemOptionsFields/SystemOptionsLatchedSupervisoryZonesField";
import SystemOptionsObserveDstField from "../common/SystemOptionsFields/SystemOptionsObserveDstField";
import SystemOptionsOccupiedPremisesField from "../common/SystemOptionsFields/SystemOptionsOccupiedPremisesField";
import SystemOptionsPanicSupervisionField from "../common/SystemOptionsFields/SystemOptionsPanicSupervisionField";
import SystemOptionsPowerFailDelayField from "../common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import SystemOptionsPrimaryProgrammingLanguageField from "../common/SystemOptionsFields/SystemOptionsPrimaryProgrammingLanguageField";
import SystemOptionsPrimaryUserLanguageField from "../common/SystemOptionsFields/SystemOptionsPrimaryUserLanguageField";
import SystemOptionsResetSwingerBypassField from "../common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import SystemOptionsSecondaryProgrammingLanguageField from "../common/SystemOptionsFields/SystemOptionsSecondaryProgrammingLanguageField";
import SystemOptionsSecondaryUserLanguageField from "../common/SystemOptionsFields/SystemOptionsSecondaryUserLanguageField";
import SystemOptionsSend16CharacterNamesField from "../common/SystemOptionsFields/SystemOptionsSend16CharacterNamesField";
import SystemOptionsSwingerBypassTripsField from "../common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import SystemOptionsSystemTypeField from "../common/SystemOptionsFields/SystemOptionsSystemTypeField";
import SystemOptionsTimeChangeField from "../common/SystemOptionsFields/SystemOptionsTimeChangeField";
import SystemOptionsUseFalseAlarmQuestionField from "../common/SystemOptionsFields/SystemOptionsUseFalseAlarmQuestionField";
import SystemOptionsWeatherPostalCodeField from "../common/SystemOptionsFields/SystemOptionsWeatherPostalCodeField";
import SystemOptionsWeatherZipCodeField from "../common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import SystemOptionsWirelessAudiblesField from "../common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import SystemOptionsWirelessEncryption1100Field from "../common/SystemOptionsFields/SystemOptionsWirelessEncryption1100Field";
import SystemOptionsWirelessEncryption1100PassphraseField from "../common/SystemOptionsFields/SystemOptionsWirelessEncryption1100PassphraseField";
import SystemOptionsWirelessHouseCodeField from "../common/SystemOptionsFields/SystemOptionsWirelessHouseCodeField";
import SystemOptionsZoneActivityHoursField from "../common/SystemOptionsFields/SystemOptionsZoneActivityHoursField";
import SystemOptionsZoneRetardDelayField from "../common/SystemOptionsFields/SystemOptionsZoneRetardDelayField";
import { panelVersionGTOE } from "../utils/panel";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  conceptId as xrAreaConceptId,
  useSaveMutation as useSaveXRAreas,
} from "./XRAreaInformationProgrammingConceptForm";
import { XRAreaInformationProgrammingConceptFormInline_controlSystem$key } from "./XRAreaInformationProgrammingConceptForm/__generated__/XRAreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import {
  XRSystemOptionsProgrammingConceptFormInline_controlSystem$data,
  XRSystemOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XRSystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XRSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation,
} from "./__generated__/XRSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation.graphql";
import {
  XRSystemOptionsProgrammingConceptFormSendSystemOptionsMutation,
  XRSystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
} from "./__generated__/XRSystemOptionsProgrammingConceptFormSendSystemOptionsMutation.graphql";
import { XRSystemOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XRSystemOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "System Options";
export const conceptId = "xr-system-options";

export const getState = (
  controlSystem: XRSystemOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRSystemOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          systemOptions {
            __typename
            id
            ... on XrSystemOptions {
              systemType
              displayTime
              iso2CountryCode
              instantArming
              closingWait
              closingCode
              resetSwingerBypass
              primaryProgrammingLanguage
              secondaryProgrammingLanguage
              primaryUserLanguage
              secondaryUserLanguage
              entryDelay1
              entryDelay2
              entryDelay3
              entryDelay4
              crossZoneTime
              armDisarmActivityDays
              zoneRetardDelay
              powerFailDelay
              swingerBypassTrips
              zoneActivityHours
              timeChange
              observeDst
              hoursFromGMT
              latchedSupervisoryZones
              bypassLimit
              houseCode
              detectJam
              wirelessAudibles
              closingCheck
              enhancedZoneTest
              occupiedPremises
              enableKeypadPanicKeys
              eolResistorValue
              send16CharacterNames
              celsius
              keypadArmedLed
              useFalseAlarmQuestion
              allowOwnUserCodeChange
              panicSupervision
              weatherZipCode
              weatherPostalCode
              wirelessEncryption1100
              wirelessEncryption1100Passphrase
              inactiveUserCodeAudit
              supportsZoneActivityHours
              supportsCelsius
              supportsEolResistorField
              supportsWirelessEncryption
              supportsInactiveUserCodeAudit
              useBuiltIn1100Wireless
              exitDelay
              intruderConfirmationTime
              holdupConfirmationTime
            }
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation XRSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation(
    $systemId: ID!
  ) {
    refreshSystemOptions(systemId: $systemId) {
      ... on RefreshSystemOptionsSuccessResponse {
        __typename
        controlSystem {
          ...XRSystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XRSystemOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XRSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            systemId: id,
          },
          onCompleted: (response) => {
            const { controlSystem, type } = response.refreshSystemOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "System Options Programming Retrieved From The System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshSystemOptions: {
                    __typename: response.refreshSystemOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (type) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve System Options: ${hyphenScoreToTitleCase(
                      type
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve System Options",
                  });
                }
              }
              reject(type);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XRSystemOptionsProgrammingConceptFormSendSystemOptionsMutation(
    $systemId: ID!
    $xrSystemOptionsInput: XrSystemOptionsInput!
  ) {
    sendXrSystemOptionsProgramming(
      systemId: $systemId
      xrSystemOptionsInput: $xrSystemOptionsInput
    ) {
      ... on SendSystemOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          id
          ...XRSystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendSystemOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const syncAreas = (
  oldControlSystem: XRSystemOptionsProgrammingConceptFormInline_controlSystem$data,
  newControlSystem: XRSystemOptionsProgrammingConceptFormInline_controlSystem$data,
  setChangedProgrammingConcept: React.Dispatch<
    React.SetStateAction<ChangedProgrammingConcepts>
  >,
  saveAreas: (
    showAlerts?: boolean,
    isSavingAllListItems?: boolean
  ) => Promise<SaveErrors>
) => {
  const {
    panel: {
      systemOptions: { systemType: oldSystemType },
    },
  } = oldControlSystem;
  const {
    panel: {
      systemOptions: { systemType: newSystemType },
    },
  } = newControlSystem;

  if (oldSystemType !== newSystemType && newSystemType !== SystemType.AREA) {
    saveAreas().finally(() => {
      setChangedProgrammingConcept(
        removeProgrammingConceptFromChangedProgrammingConcepts(xrAreaConceptId)
      );
    });
  }
};

const updateOriginalControlSystem = (
  response: XRSystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
  originalControlSystemData: XRSystemOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendXrSystemOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshSystemOptions: {
          __typename: "RefreshSystemOptionsSuccessPayload",
          controlSystem: getState(
            response.sendXrSystemOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XRSystemOptionsProgrammingConceptFormInline_controlSystem$key &
    XRAreaInformationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XRSystemOptionsProgrammingConceptFormSendSystemOptionsMutation>(
      saveMutation
    );
  const [saveAreas] = useSaveXRAreas({
    controlSystem: props.controlSystem,
  });

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const originalControlSystem = useOriginalControlSystem();
  const setChangedProgrammingConcept = useSetChangedProgrammingConcepts();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise(async (resolve, reject) => {
        const {
          id: systemId,
          panel: { systemOptions },
        } = getState(props.controlSystem);

        if (systemOptions) {
          save({
            variables: {
              systemId,
              xrSystemOptionsInput: {
                systemType:
                  systemOptions.systemType ?? SystemType.ALL_PERIMETER,
                closingCode: systemOptions.closingCode,
                closingCheck: systemOptions.closingCheck,
                entryDelay1: systemOptions.entryDelay1,
                entryDelay2: systemOptions.entryDelay2,
                crossZoneTime: systemOptions.crossZoneTime,
                powerFailDelay: systemOptions.powerFailDelay,
                swingerBypassTrips: systemOptions.swingerBypassTrips,
                resetSwingerBypass: systemOptions.resetSwingerBypass,
                zoneActivityHours: systemOptions.zoneActivityHours,
                armDisarmActivityDays: systemOptions.armDisarmActivityDays,
                timeChange: systemOptions.timeChange,
                observeDst: systemOptions.observeDst,
                hoursFromGMT: systemOptions.hoursFromGMT,
                displayTime: systemOptions.displayTime,
                houseCode: systemOptions.houseCode,
                detectJam: systemOptions.detectJam,
                wirelessAudibles: systemOptions.wirelessAudibles,
                useBuiltIn1100Wireless: systemOptions.useBuiltIn1100Wireless,
                enableKeypadPanicKeys: systemOptions.enableKeypadPanicKeys,
                occupiedPremises: systemOptions.occupiedPremises,
                useFalseAlarmQuestion: systemOptions.useFalseAlarmQuestion,
                weatherZipCode: systemOptions.weatherZipCode,
                iso2CountryCode: systemOptions.iso2CountryCode,
                weatherPostalCode: systemOptions.weatherPostalCode,
                primaryProgrammingLanguage:
                  systemOptions.primaryProgrammingLanguage ??
                  PanelProgrammingLanguage.ENGLISH,
                secondaryProgrammingLanguage:
                  systemOptions.secondaryProgrammingLanguage ??
                  PanelProgrammingLanguage.ENGLISH,
                primaryUserLanguage:
                  systemOptions.primaryUserLanguage ??
                  PanelProgrammingLanguage.ENGLISH,
                secondaryUserLanguage:
                  systemOptions.secondaryUserLanguage ??
                  PanelProgrammingLanguage.ENGLISH,
                wirelessEncryption1100: systemOptions.wirelessEncryption1100,
                wirelessEncryption1100Passphrase:
                  systemOptions.wirelessEncryption1100Passphrase,
                eolResistorValue: systemOptions.eolResistorValue,
                celsius: systemOptions.celsius,
                exitDelay: systemOptions.exitDelay,
                zoneRetardDelay: systemOptions.zoneRetardDelay,
                bypassLimit: systemOptions.bypassLimit,
                closingWait: systemOptions.closingWait,
                allowOwnUserCodeChange: systemOptions.allowOwnUserCodeChange,
                enhancedZoneTest: systemOptions.enhancedZoneTest,
                entryDelay3: systemOptions.entryDelay3,
                entryDelay4: systemOptions.entryDelay4,
                inactiveUserCodeAudit: systemOptions.inactiveUserCodeAudit,
                instantArming: systemOptions.instantArming,
                keypadArmedLed: systemOptions.keypadArmedLed,
                latchedSupervisoryZones: systemOptions.latchedSupervisoryZones,
                panicSupervision: systemOptions.panicSupervision,
                send16CharacterNames: systemOptions.send16CharacterNames,
                supportsCelsius: systemOptions.supportsCelsius,
                supportsEolResistorField:
                  systemOptions.supportsEolResistorField,
                supportsInactiveUserCodeAudit:
                  systemOptions.supportsInactiveUserCodeAudit,
                supportsWirelessEncryption:
                  systemOptions.supportsWirelessEncryption,
                supportsZoneActivityHours:
                  systemOptions.supportsZoneActivityHours,
                intruderConfirmationTime:
                  systemOptions.intruderConfirmationTime,
                holdupConfirmationTime: systemOptions.holdupConfirmationTime,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendXrSystemOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);

                syncAreas(
                  getState(originalControlSystem),
                  getState(props.controlSystem),
                  setChangedProgrammingConcept,
                  saveAreas
                );

                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendXrSystemOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendXrSystemOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readSystemOptionsTemplateData = (
  programmingTemplateConcepts: XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        systemOptions {
          id
          included
          systemType {
            included
            data
          }
          closingCode {
            included
            data
          }
          closingCheck {
            included
            data
          }
          instantArming {
            included
            data
          }
          closingWait {
            included
            data
          }
          entryDelay1 {
            included
            data
          }
          entryDelay2 {
            included
            data
          }
          entryDelay3 {
            included
            data
          }
          entryDelay4 {
            included
            data
          }
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          resetSwingerBypass {
            included
            data
          }
          zoneActivityHours {
            included
            data
          }
          armDisarmActivityDays {
            included
            data
          }
          timeChange {
            included
            data
          }
          observeDst {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          displayTime {
            included
            data
          }
          houseCode {
            included
            data
          }
          detectJam {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          useBuiltIn1100Wireless {
            included
            data
          }
          enableKeypadPanicKeys {
            included
            data
          }
          occupiedPremises {
            included
            data
          }
          useFalseAlarmQuestion {
            included
            data
          }
          weatherZipCode {
            included
            data
          }
          iso2CountryCode {
            included
            data
          }
          weatherPostalCode {
            included
            data
          }
          primaryProgrammingLanguage {
            included
            data
          }
          secondaryProgrammingLanguage {
            included
            data
          }
          primaryUserLanguage {
            included
            data
          }
          secondaryUserLanguage {
            included
            data
          }
          wirelessEncryption1100 {
            included
            data
          }
          wirelessEncryption1100Passphrase {
            included
            data
          }
          eolResistorValue {
            included
            data
          }
          celsius {
            included
            data
          }
          exitDelay {
            included
            data
          }
          entryDelay3 {
            included
            data
          }
          entryDelay4 {
            included
            data
          }
          zoneRetardDelay {
            included
            data
          }
          latchedSupervisoryZones {
            included
            data
          }
          bypassLimit {
            included
            data
          }
          enhancedZoneTest {
            included
            data
          }
          send16CharacterNames {
            included
            data
          }
          keypadArmedLed {
            included
            data
          }
          allowOwnUserCodeChange {
            included
            data
          }
          panicSupervision {
            included
            data
          }
          intruderConfirmationTime {
            included
            data
          }
          holdupConfirmationTime {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).systemOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readSystemOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const systemOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "systemOptions",
      "SystemOptions"
    ) as unknown as RecordProxy<SystemOptions>;

    applyTemplateScalarDataToRecordProxy(
      systemOptionsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRSystemOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRSystemOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          __typename
          id
          panel {
            ...SystemOptionsSystemTypeField_panel
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseSoftwareVersion_panel
            systemOptions {
              ... on XrSystemOptions {
                supportsZoneActivityHours
                supportsEolResistorField
                supportsCelsius
                supportsInactiveUserCodeAudit
                supportsWirelessEncryption
                houseCode
                wirelessEncryption1100
              }
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsSystemTypeField_systemOptions
              ...SystemOptionsInstantArmingField_systemOptions
              ...SystemOptionsClosingWaitField_systemOptions
              ...SystemOptionsResetSwingerBypassField_systemOptions
              ...SystemOptionsPrimaryProgrammingLanguageField_systemOptions
              ...SystemOptionsSecondaryProgrammingLanguageField_systemOptions
              ...SystemOptionsPrimaryUserLanguageField_systemOptions
              ...SystemOptionsSecondaryUserLanguageField_systemOptions
              ...SystemOptionsEntryDelay1Field_systemOptions
              ...SystemOptionsEntryDelay2Field_systemOptions
              ...SystemOptionsEntryDelay3Field_systemOptions
              ...SystemOptionsEntryDelay4Field_systemOptions
              ...SystemOptionsCrossZoneTimeField_systemOptions
              ...SystemOptionsZoneRetardDelayField_systemOptions
              ...SystemOptionsPowerFailDelayField_systemOptions
              ...SystemOptionsSwingerBypassTripsField_systemOptions
              ...SystemOptionsZoneActivityHoursField_systemOptions
              ...SystemOptionsTimeChangeField_systemOptions
              ...SystemOptionsObserveDstField_systemOptions
              ...SystemOptionsHoursFromGMTField_systemOptions
              ...SystemOptionsLatchedSupervisoryZonesField_systemOptions
              ...SystemOptionsBypassLimitField_systemOptions
              ...SystemOptionsWirelessHouseCodeField_systemOptions
              ...SystemOptionsDetectWirelessJammingField_systemOptions
              ...SystemOptionsWirelessAudiblesField_systemOptions
              ...SystemOptionsClosingCheckField_systemOptions
              ...SystemOptionsEnhancedZoneTestField_systemOptions
              ...SystemOptionsOccupiedPremisesField_systemOptions
              ...SystemOptionsEnableKeypadPanicKeysField_systemOptions
              ...SystemOptionsEolResistorValueField_systemOptions
              ...SystemOptionsSend16CharacterNamesField_systemOptions
              ...SystemOptionsCelsiusField_systemOptions
              ...SystemOptionsIntruderConfirmationTimeField_systemOptions
              ...SystemOptionsHoldupConfirmationTimeField_systemOptions
              ...SystemOptionsKeypadArmedLedField_systemOptions
              ...SystemOptionsUseFalseAlarmQuestionField_systemOptions
              ...SystemOptionsAllowOwnUserCodeChangeField_systemOptions
              ...SystemOptionsPanicSupervisionField_systemOptions
              ...SystemOptionsWeatherZipCodeField_systemOptions
              ...SystemOptionsWirelessEncryption1100Field_systemOptions
              ...SystemOptionsWirelessEncryption1100PassphraseField_systemOptions
              ...SystemOptionsInactiveUserCodeAuditField_systemOptions
              ...SystemOptionsIso2CountryCodeField_systemOptions
              ...SystemOptionsWeatherPostalCodeField_systemOptions
            }
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem
        }
      `
    );

  const softwareVersion = Number(controlSystem.panel.softwareVersion);

  const {
    panel: {
      systemOptions,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <ControlSystemContextProvider controlSystem={controlSystem}>
      <PanelContextProvider panel={controlSystem.panel}>
        <SystemOptionsContextProvider systemOptions={systemOptions}>
          <ProgrammingConceptForm
            conceptId={conceptId}
            title="System Options"
            helpLink={`${programmingGuideUrl}#System%20Options`}
            initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
              controlSystem.panel.systemOptions
            )}
          >
            <RemountOnUpdateContainer nodeId={conceptId}>
              <ProgrammingConceptForm.Fields>
                <SystemOptionsSystemTypeField />
                <SystemOptionsInstantArmingField />
                <SystemOptionsClosingWaitField />
                <SystemOptionsEntryDelay1Field />
                <SystemOptionsEntryDelay2Field />
                <SystemOptionsEntryDelay3Field />
                <SystemOptionsEntryDelay4Field />
                <SystemOptionsCrossZoneTimeField />
                <SystemOptionsZoneRetardDelayField />
                <SystemOptionsPowerFailDelayField />
                <SystemOptionsSwingerBypassTripsField />
                <SystemOptionsResetSwingerBypassField />
                {systemOptions?.supportsZoneActivityHours && (
                  <SystemOptionsZoneActivityHoursField />
                )}
                <SystemOptionsTimeChangeField />
                {panelVersionGTOE(252, softwareVersion) ? (
                  <SystemOptionsObserveDstField />
                ) : null}
                <SystemOptionsHoursFromGMTField />
                <SystemOptionsLatchedSupervisoryZonesField />
                <SystemOptionsPrimaryProgrammingLanguageField />
                <SystemOptionsSecondaryProgrammingLanguageField />
                <SystemOptionsPrimaryUserLanguageField />
                <SystemOptionsSecondaryUserLanguageField />
                <SystemOptionsBypassLimitField />
                <SystemOptionsWirelessHouseCodeField />
                <>
                  <SystemOptionsDetectWirelessJammingField />
                  <SystemOptionsWirelessAudiblesField />
                </>

                <SystemOptionsEnableKeypadPanicKeysField />
                <SystemOptionsOccupiedPremisesField />
                <SystemOptionsEnhancedZoneTestField />
                {systemOptions?.supportsEolResistorField && (
                  <SystemOptionsEolResistorValueField />
                )}
                <SystemOptionsSend16CharacterNamesField />
                {systemOptions?.supportsCelsius && (
                  <SystemOptionsCelsiusField />
                )}
                {softwareVersion >= 742 && (
                  <>
                    <SystemOptionsIntruderConfirmationTimeField />
                    <SystemOptionsHoldupConfirmationTimeField />
                  </>
                )}
                <SystemOptionsKeypadArmedLedField />
                <SystemOptionsUseFalseAlarmQuestionField />
                <SystemOptionsAllowOwnUserCodeChangeField />
                <SystemOptionsPanicSupervisionField />
                {systemOptions?.supportsInactiveUserCodeAudit && (
                  <SystemOptionsInactiveUserCodeAuditField />
                )}
                {softwareVersion <= 599 && <SystemOptionsWeatherZipCodeField />}
                {softwareVersion > 599 && (
                  <>
                    <SystemOptionsIso2CountryCodeField />
                    <SystemOptionsWeatherPostalCodeField />
                  </>
                )}
                {systemOptions?.supportsWirelessEncryption && (
                  <>
                    <SystemOptionsWirelessEncryption1100Field />
                    <SystemOptionsWirelessEncryption1100PassphraseField />
                  </>
                )}
              </ProgrammingConceptForm.Fields>
            </RemountOnUpdateContainer>
          </ProgrammingConceptForm>
        </SystemOptionsContextProvider>
      </PanelContextProvider>
    </ControlSystemContextProvider>
  );
}
