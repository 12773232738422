import graphql from "babel-plugin-relay/macro";
import { path } from "ramda";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment } from "react-relay/hooks";
import styled from "styled-components";
import { ArmingShieldIcon, CheckmarkIcon } from "../Icons";
import Space from "../Space";
import Widget from "../Widget";
import ZonesList from "./ZonesList";
import { ZoneStatuses_controlSystem$key } from "./__generated__/ZoneStatuses_controlSystem.graphql";

const NoFaultedZonesIndicator = styled.p`
  margin: 0 auto;
  color: ${path(["theme", "success"])};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;
const NoFaultedZonesIndicatorIcon = styled(CheckmarkIcon)`
  margin-right: 0.35em;
`;
const ScrollableWrapper = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
`;

type Props = {
  controlSystem: ZoneStatuses_controlSystem$key;
  globalControls: React.ReactNode[] | undefined;
  refreshing?: boolean;
  refreshZoneStatuses?: () => void;
  height?: number;
  style?: React.CSSProperties | undefined;
};

const ZoneStatuses = ({
  controlSystem,
  globalControls,
  refreshing,
  refreshZoneStatuses,
  height,
  style,
}: Props) => {
  const intl = useIntl();

  const data = useFragment(
    graphql`
      fragment ZoneStatuses_controlSystem on ControlSystem {
        zones {
          id
          isFaulted
          canBypass
          lockdownEnabled
          status
          number
          area {
            id
          }
          ...Zone_zone
        }
        customer {
          dealer {
            vernaculars {
              original
              replacement
            }
          }
        }
      }
    `,
    controlSystem
  );

  const faultedZones = data.zones.filter((zone) => zone.isFaulted);

  const goodZones = data.zones.filter((zone) => !zone.isFaulted);

  const canBypassZones = data.zones.some((zone) => zone.canBypass);

  const systemReplace = data.customer.dealer.vernaculars.find(
    (v) => v?.original === "systems"
  )?.replacement;
  const defaultNoZoneMessage = `This ${
    systemReplace ?? "system"
  } doesn't have any zones programmed`;

  return (
    <Widget
      title={intl.formatMessage({
        description: "ZoneStatuses panel title",
        defaultMessage: "Zone Status",
      })}
      globalControls={globalControls || undefined}
      loading={refreshing}
      icon={<ArmingShieldIcon />}
      statusMessage={""}
      style={{ height: height }}
    >
      <ScrollableWrapper>
        {!!faultedZones.length && (
          <React.Fragment>
            <ZonesList
              canBypassZones={canBypassZones}
              title={
                <FormattedMessage
                  description="ZoneStatuses faulted zones title"
                  defaultMessage="Faulted Zones"
                />
              }
              zones={faultedZones}
            />
            <Space small />
          </React.Fragment>
        )}
        {faultedZones.length === 0 && data.zones.length > 0 && (
          <React.Fragment>
            <Space small />
            <NoFaultedZonesIndicator>
              <NoFaultedZonesIndicatorIcon />
              <FormattedMessage
                description="ZoneStatuses no faulted zones message"
                defaultMessage="No faulted zones"
              />
            </NoFaultedZonesIndicator>
          </React.Fragment>
        )}
        {data.zones.length === 0 && (
          <React.Fragment>
            <Space small />
            <FormattedMessage
              description="ZoneStatuses no zones programmed message"
              defaultMessage="This {system} doesn't have any zones programmed"
              values={{
                system: systemReplace ?? "system",
              }}
            />
          </React.Fragment>
        )}
        {goodZones.length > 0 && (
          <ZonesList
            canBypassZones={canBypassZones}
            title={
              <FormattedMessage
                description="ZoneStatuses ok zones title"
                defaultMessage="OK Zones"
              />
            }
            zones={goodZones}
          />
        )}
      </ScrollableWrapper>
    </Widget>
  );
};

export default ZoneStatuses;
