import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { AllowTimeChange } from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsTimeChangeField_systemOptions$key } from "./__generated__/SystemOptionsTimeChangeField_systemOptions.graphql";

export const systemOptionsTimeChangeFieldId = () =>
  "system-options-time-change";

function TimeChangeField() {
  const [{ timeChange }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsTimeChangeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsTimeChangeField_systemOptions on SystemOptions {
          timeChange
          ... on XrSystemOptions {
            observeDst
          }
          ... on XfSystemOptions {
            observeDst
          }
          ... on XtSystemOptions {
            observeDst
          }
          ... on Xt75SystemOptions {
            observeDst
          }
          ... on Xt75SystemOptions {
            observeDst
          }
          ... on TMSentrySystemOptions {
            observeDst
          }
        }
      `
    );

  const fieldId = systemOptionsTimeChangeFieldId();
  const softwareVersion = useSoftwareVersion();
  const hasTimeChangeServerOption = panelVersionGTOE(252, softwareVersion);
  const label = hasTimeChangeServerOption ? "Update Time With" : "Time Change";
  const tooltip = hasTimeChangeServerOption
    ? "Configure if this panel receives time updates from the monitoring center receiver, or SecureCom Wireless servers.” "
    : "If enabled, requests time changes from DMP monitoring center receivers.";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip={tooltip}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={timeChange}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "timeChange");
          });
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              [AllowTimeChange.SERVER].includes(
                target.value as AllowTimeChange
              ),
              "observeDst"
            );
          });
        }}
        getValidationMessage={() => {
          if (
            !hasTimeChangeServerOption &&
            timeChange === AllowTimeChange.SERVER
          ) {
            return "Securecom is not supported on this panel version, update your panel or change this value.";
          } else {
            return "";
          }
        }}
      >
        <>
          <Select.Option value={AllowTimeChange.NO}>
            No Time Change
          </Select.Option>
          <Select.Option value={AllowTimeChange.YES}>Receiver</Select.Option>
          {hasTimeChangeServerOption ||
          (!hasTimeChangeServerOption &&
            timeChange === AllowTimeChange.SERVER) ? (
            <Select.Option
              disabled={!hasTimeChangeServerOption} //prevents templates from setting a version that is not allowed to use server as an option
              value={AllowTimeChange.SERVER}
            >
              SecureCom
            </Select.Option>
          ) : null}
        </>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default TimeChangeField;
