import graphql from "babel-plugin-relay/macro";
import PageStackView from "common/components/web/PageStackView";
import { sleep } from "common/utils/universal/promise";
import GenericErrorBoundary from "components/GenericErrorBoundary";
import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import styled from "styled-components";
import { AngularStateProvider } from "./AngularStateProvider";
import DiagnosticSearch from "./DiagnosticSearch";
import SelectedResult from "./SelectedResult";
import SystemDiagnosticRoot from "./SystemDiagnosticRoot";
import TableContainer from "./TableContainer";
import { Pages } from "./utils";
import { SystemDiagnosticsDirectLinkQuery } from "./__generated__/SystemDiagnosticsDirectLinkQuery.graphql";

function suspendUntilAngularDataIsPresent(data: any) {
  const dataIsStillMissing = data === null || data === undefined;

  if (dataIsStillMissing) {
    throw sleep(50);
  }
}

const PageStackViewContainer = styled.div`
  min-height: 50em;
`;

const directLinkQuery = graphql`
  query SystemDiagnosticsDirectLinkQuery($panelId: String!) {
    systemDiagnosticsGetPanel(panelId: $panelId) {
      panelSerialNumber
      controlSystemName
      accountNumber
      controlSystemId
      customerId
      simIdentifier
      panelCommAddress
      hardwareModel
      panelSoftwareVersion
      panelSoftwareDate
      panelArmingSystem
      commType
    }
  }
`;

// This function will only render for a second between the suspense and redirect
// It's purpose is to fetch some panel data if the URL parameter for the panelId is present
function UrlPanelLoader({
  panelId,
  setSelectedResult,
  setSearchStatus,
}: {
  panelId: string;
  setSelectedResult: (result: any) => void;
  setSearchStatus: (status: string) => void;
}) {
  const directData = useLazyLoadQuery<SystemDiagnosticsDirectLinkQuery>(
    directLinkQuery,
    {
      panelId: panelId,
    }
  );

  setSelectedResult(directData.systemDiagnosticsGetPanel);
  setSearchStatus("selected");

  return (
    <div className="panel pad-0 pad-v-24" style={{ height: "110px" }}>
      <p>Searching...</p>
    </div>
  );
}

function SystemDiagnosticsRoot({
  RelayService,
  UserService,
  DealerService,
  $state,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <AngularStateProvider state={$state}>
        <SystemDiagnostics
          authToken={UserService.auth_token}
          urlPanelId={$state.params.panelId}
          setSelectedDealer={(databaseDealerId: number) =>
            UserService.setSelectedDealer(
              new DealerService({ dealer_id: databaseDealerId })
            )
          }
          dealerId={UserService.dealer_id}
        />
      </AngularStateProvider>
    </RelayEnvironmentProvider>
  );
}

export default SystemDiagnosticsRoot;

// For now, we have 2 page stack views for displaying everthing in this page
// eventually, we'll likely move panel resets into SelectedResult with the other data
// and merge into just 1 panel stack view (or some other way of organizing)
function SystemDiagnostics({
  authToken,
  urlPanelId,
  setSelectedDealer,
  dealerId,
}: {
  authToken: string;
  urlPanelId: string;
  setSelectedDealer: (databaseDealerId: number) => void;
  dealerId: number;
}) {
  suspendUntilAngularDataIsPresent(authToken);
  const [activePage, setActivePage] = React.useState(Pages.Root);
  const [searchString, setSearchString] = React.useState("");
  const [selectedResult, setSelectedResult] = React.useState<any>({} as any);

  // if we get a panelId in the URL, we'll skip the searching phase
  const initialState = urlPanelId ? "urlSearching" : "searching";
  const [searchStatus, setSearchStatus] = React.useState(initialState);

  return (
    <>
      <PageStackViewContainer>
        <div className="row">
          <div className="page-header">
            <div className="page-header__left">
              <div className="page-header__title">Panel / System Search</div>
            </div>

            {searchStatus == "selected" && (
              <div className="page-header__right">
                {urlPanelId && (
                  <a
                    className="btn btn-primary btn-sm"
                    style={{ background: "white" }}
                    href="/#/app/dealers/1/system-diagnostics/"
                  >
                    Go Back
                  </a>
                )}
                {!urlPanelId && (
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ background: "white" }}
                    onClick={() => setSearchStatus("searching")}
                  >
                    Go Back
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div style={{ position: "relative" }}>
            <PageStackView
              activeId={searchStatus}
              pages={[
                {
                  id: "searching",
                  render: () => (
                    <>
                      <GenericErrorBoundary fallback={<GenericPageFallback />}>
                        <React.Suspense fallback={<GenericPageFallback />}>
                          <DiagnosticSearch
                            searchString={searchString}
                            setSelectedResult={setSelectedResult}
                            setSearchString={setSearchString}
                            setSearchStatus={setSearchStatus}
                            dealerId={dealerId}
                          />
                        </React.Suspense>
                      </GenericErrorBoundary>
                    </>
                  ),
                },
                {
                  id: "selected",
                  render: () => (
                    <GenericErrorBoundary fallback={<GenericPageFallback />}>
                      <React.Suspense fallback={<GenericPageFallback />}>
                        <SelectedResult
                          selectedResult={selectedResult}
                          authToken={authToken}
                        />
                      </React.Suspense>
                    </GenericErrorBoundary>
                  ),
                },
                {
                  id: "urlSearching",
                  render: () => (
                    <GenericErrorBoundary
                      fallback={
                        <div
                          className="panel pad-24"
                          style={{ height: "110px" }}
                        >
                          <p>Could not find the specified control system. </p>
                          <a
                            href="/#/app/dealers/1/system-diagnostics/"
                            className="btn btn-primary btn-sm"
                            style={{ textDecoration: "none" }}
                          >
                            <strong>Go Back</strong>
                          </a>
                        </div>
                      }
                    >
                      <React.Suspense fallback={<GenericPageFallback />}>
                        <UrlPanelLoader
                          panelId={urlPanelId}
                          setSelectedResult={setSelectedResult}
                          setSearchStatus={setSearchStatus}
                        />
                      </React.Suspense>
                    </GenericErrorBoundary>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </PageStackViewContainer>

      <PageStackViewContainer>
        <div className="row">
          <div className="page-header">
            <div className="page-header__left">
              <div className="page-header__title">
                Panel Resets{" "}
                {(() => {
                  switch (activePage) {
                    case Pages.J16Reset:
                      return " / J16 Reset";
                    case Pages.PowerReset:
                      return " / Power Reset";
                    case Pages.BrownOutReset:
                      return " / Brown Out";
                    case Pages.WatchdogReset:
                      return " / Watchdog";
                    case Pages.LockupReset:
                      return " / Lock Up";
                    case Pages.SystemReset:
                      return " / System";
                    default:
                      return "";
                  }
                })()}
              </div>
            </div>
            {activePage !== Pages.Root && (
              <div className="page-header__right">
                <button
                  className="btn btn-primary btn-sm"
                  // TODO: replace me with the appropriate class
                  style={{ background: "white" }}
                  onClick={() => setActivePage(Pages.Root)}
                >
                  Go Back
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div style={{ position: "relative" }}>
            <PageStackView
              activeId={activePage}
              pages={[
                {
                  id: Pages.Root,
                  render: () => (
                    <React.Suspense fallback={<GenericPageFallback />}>
                      <SystemDiagnosticRoot setActivePage={setActivePage} />
                    </React.Suspense>
                  ),
                },
                {
                  id: Pages.J16Reset,
                  render: () => (
                    <React.Suspense fallback={<GenericPageFallback />}>
                      <TableContainer
                        activePage={activePage}
                        setSelectedDealer={setSelectedDealer}
                      />
                    </React.Suspense>
                  ),
                },
                {
                  id: Pages.PowerReset,
                  render: () => (
                    <React.Suspense fallback={<GenericPageFallback />}>
                      <TableContainer
                        activePage={activePage}
                        setSelectedDealer={setSelectedDealer}
                      />
                    </React.Suspense>
                  ),
                },
                {
                  id: Pages.BrownOutReset,
                  render: () => (
                    <React.Suspense fallback={<GenericPageFallback />}>
                      <TableContainer
                        activePage={activePage}
                        setSelectedDealer={setSelectedDealer}
                      />
                    </React.Suspense>
                  ),
                },
                {
                  id: Pages.WatchdogReset,
                  render: () => (
                    <React.Suspense fallback={<GenericPageFallback />}>
                      <TableContainer
                        activePage={activePage}
                        setSelectedDealer={setSelectedDealer}
                      />
                    </React.Suspense>
                  ),
                },
                {
                  id: Pages.LockupReset,
                  render: () => (
                    <React.Suspense fallback={<GenericPageFallback />}>
                      <TableContainer
                        activePage={activePage}
                        setSelectedDealer={setSelectedDealer}
                      />
                    </React.Suspense>
                  ),
                },
                {
                  id: Pages.SystemReset,
                  render: () => (
                    <React.Suspense fallback={<GenericPageFallback />}>
                      <TableContainer
                        activePage={activePage}
                        setSelectedDealer={setSelectedDealer}
                      />
                    </React.Suspense>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </PageStackViewContainer>
    </>
  );
}
