import graphql from "babel-plugin-relay/macro";
import Portal from "common/components/web/Portal";
import useEffectOnce from "common/hooks/useEffectOnce";
import Button from "components/Button";
import React, { useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import {
  asID,
  asString,
  fromCustomerId,
  fromSiteId,
  toSiteId,
} from "securecom-graphql/client";
import {
  useCustomerId,
  useOpenLoginAsCustomerModal,
  useRecentActivityService,
  useUserCanLogInAsCustomer,
} from "../EntryPointContext";
import Sections from "../Layout/Layout";
import RedirectTo404 from "../RedirectTo404";
import SiteCardFormatsSection from "./SiteCardFormatsSection";
import SiteControlSystemsSection from "./SiteControlSystemsSection";
import SiteEditModal from "./SiteEditModal";
import SiteElevatorSystemsSection from "./SiteElevatorSystemsSection";
import SiteFirmwareUpdateDialogue from "./SiteFirmwareUpdateDialogue";
import SiteOutputModulesSection from "./SiteOutputModulesSection";
import SiteVideoIntegrationsSection from "./SiteVideoIntegrationsSection";
import { ExistingSiteFormFragment_site$key } from "./__generated__/ExistingSiteFormFragment_site.graphql";
import { ExistingSiteFormQuery } from "./__generated__/ExistingSiteFormQuery.graphql";

export default function ExistingSiteFormLoader({
  id,
  isNewlyCreated,
  setSiteName,
}: {
  id: string;
  isNewlyCreated: boolean;
  setSiteName: () => void;
}) {
  const [, customerId] = useCustomerId();
  const siteId = asString(toSiteId(customerId, id));

  const data = useLazyLoadQuery<ExistingSiteFormQuery>(
    graphql`
      query ExistingSiteFormQuery($siteId: ID!) {
        node(id: $siteId) {
          ... on Site {
            id
            ...ExistingSiteFormFragment_site
          }
        }
      }
    `,
    {
      siteId,
    },
    {
      fetchPolicy: "store-and-network",
    }
  );

  return data.node?.id ? (
    <ExistingSiteForm
      isNewlyCreated={isNewlyCreated}
      siteRef={data.node}
      setSiteName={setSiteName}
    />
  ) : (
    <RedirectTo404 />
  );
}

function ExistingSiteForm({
  siteRef,
  isNewlyCreated,
  setSiteName,
}: {
  siteRef: ExistingSiteFormFragment_site$key;
  isNewlyCreated: boolean;
  setSiteName: () => void;
}) {
  const openLoginAsCustomerModal = useOpenLoginAsCustomerModal();
  const userCanLoginAsCustomer = useUserCanLogInAsCustomer();
  const [siteEditModalOpen, setSiteEditModalOpen] = useState(false);
  const {
    props: {
      storageKeys: { system: systemStorageKey },
    },
    updateList,
  } = useRecentActivityService();
  const data = useFragment(
    graphql`
      fragment ExistingSiteFormFragment_site on Site {
        id
        name
        billingControlSystemId
        showDoors
        showElevators
        showOutputs
        showVideoServices
        customer {
          id
          name
          dealer {
            securityCommand
            id
          }
        }
        ...SiteEditModal_site
        ...SiteControlSystemsSection_site
        ...SiteElevatorSystemsSection_site
        ...SiteCardFormatsSection_site
        ...SiteVideoIntegrationsSection_site
        ...SiteOutputModulesSection_site
        ...SiteFirmwareUpdateDialogue_site
        ...SiteFirmwareUpdateDialogue_UpdateProgress_site
      }
    `,
    siteRef
  );

  useEffectOnce(() => {
    if (!data) return;
    updateList(systemStorageKey, {
      isSite: true,
      id: fromSiteId(asID(data.id)).siteId,
      customer_id: fromCustomerId(asID(data?.customer.id)).customerId,
      customer_name: data?.customer?.name,
      name: data.name,
    });
  });

  return (
    <>
      <Portal element={document.querySelector("#site-name")}>
        {data.name}
      </Portal>
      <Portal
        element={document.querySelector("#site-login-as-customer-button")}
      >
        {userCanLoginAsCustomer && (
          <Button
            onClick={() =>
              openLoginAsCustomerModal(
                data.billingControlSystemId,
                data.customer.dealer.securityCommand as boolean
              )
            }
          >
            {data.customer.dealer.securityCommand
              ? "User Management"
              : "Log In as Customer"}
          </Button>
        )}
      </Portal>
      <Portal element={document.querySelector("#site-edit-button")}>
        <Button design="primary" onClick={() => setSiteEditModalOpen(true)}>
          Edit
        </Button>
      </Portal>
      <Sections>
        <React.Suspense fallback={null}>
          <SiteFirmwareUpdateDialogue site={data} />
        </React.Suspense>
        {data.showDoors ? (
          <SiteControlSystemsSection
            siteRef={data}
            initiallyOpenFirstDoor={isNewlyCreated}
          />
        ) : null}
        {data.showElevators ? (
          <SiteElevatorSystemsSection
            siteRef={data}
            initiallyOpenFirstElevator={isNewlyCreated}
          />
        ) : null}
        <SiteCardFormatsSection siteRef={data} />
        {data.showOutputs ? <SiteOutputModulesSection siteRef={data} /> : null}
        {data.showVideoServices ? (
          <SiteVideoIntegrationsSection siteRef={data} />
        ) : null}
      </Sections>

      {siteEditModalOpen ? (
        <SiteEditModal
          siteRef={data}
          setSiteName={setSiteName}
          onCancel={() => setSiteEditModalOpen(false)}
        />
      ) : null}
    </>
  );
}
