import { defaultTheme } from "app/common/DaColors";
import graphql from "babel-plugin-relay/macro";
import LoadingSpinner from "common/components/web/LoadingSpinner";
import Tooltip from "common/components/web/Tooltip";
import useTicker from "common/react-hooks/use-ticker";
import { getCountdown } from "common/utils/universal/date";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { useMutation, useRelayEnvironment } from "react-relay/hooks";
import { commitLocalUpdate } from "relay-runtime";
import styled, { ThemeProvider } from "styled-components";
import { TakeSystemOffTestButtonMutation } from "./__generated__/TakeSystemOffTestButtonMutation.graphql";

const mutation = graphql`
  mutation TakeSystemOffTestButtonMutation($systemId: ID!) {
    takeSystemOffTest(systemId: $systemId) {
      ... on TakeSystemOffTestSuccessResponse {
        integration {
          id
          systemTestExpiresAt
        }
      }
      ... on TakeSystemOffTestErrorResponse {
        error {
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const ButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TooltipText = styled.p`
  padding: 0;
  margin: 1rem;
  font-size: 1.2rem;
`;

function dateIsInPast(currentDate: Date, testDate: Date) {
  return currentDate.valueOf() > testDate.valueOf();
}

const TakeSystemOffTestButton: React.FC<
  React.PropsWithChildren<{
    systemId: string;
    integrationId: string;
    systemTestExpiresAt: string;
    systemReplacement: string | undefined;
  }>
> = ({ systemId, integrationId, systemTestExpiresAt, systemReplacement }) => {
  // this is get the live countdown on hover
  const value = useTicker(500);
  const [buttonIsHovered, setButtonIsHovered] = React.useState(false);
  const [takeSystemOffTest, isPending] =
    useMutation<TakeSystemOffTestButtonMutation>(mutation);

  const env: any = useRelayEnvironment();

  React.useEffect(() => {
    if (dateIsInPast(new Date(), new Date(systemTestExpiresAt))) {
      commitLocalUpdate(env, (store) => {
        const integration = store.get(integrationId);
        if (integration) {
          integration.setValue(null, "systemTestExpiresAt");
        }
      });
    }
  }, [env, integrationId, systemTestExpiresAt, value]);

  const showAlert = useShowAlert();

  function showFailureAlert() {
    showAlert({
      type: "error",
      text: `An error occurred when attempting to take ${
        systemReplacement ?? "system"
      } off test. Please check your credentials are valid and try again.`,
    });
  }

  return (
    <Tooltip
      open={buttonIsHovered}
      // this is to get over DA's sidebar
      zIndex={1000}
      anchor={({ registerAnchor }) => (
        <button
          ref={registerAnchor as any}
          className="btn btn-sm btn-dmp"
          onClick={() => {
            takeSystemOffTest({
              variables: { systemId },
              onCompleted: (response) =>
                void (
                  "error" in response.takeSystemOffTest && showFailureAlert()
                ),
              onError: showFailureAlert,
            });
          }}
          onMouseEnter={() => void setButtonIsHovered(true)}
          onMouseLeave={() => void setButtonIsHovered(false)}
        >
          <ButtonTextWrapper>
            Take {systemReplacement ?? "System"} Off Test
            {isPending ? (
              <div style={{ display: "inline-block", padding: "0 0.5em" }}>
                <LoadingSpinner />
              </div>
            ) : null}
          </ButtonTextWrapper>
        </button>
      )}
    >
      {" "}
      <ThemeProvider theme={defaultTheme}>
        <TooltipText>
          {formatCountdown(systemTestExpiresAt)} Remaining
        </TooltipText>
      </ThemeProvider>
    </Tooltip>
  );
};

export default TakeSystemOffTestButton;

const formatDays = (days: number) =>
  days === 0 ? `` : days === 1 ? `${days} Day` : `${days} Days`;
const formatHours = (hours: number) =>
  hours === 0 ? `` : hours === 1 ? `${hours} Hour` : `${hours} Hours`;
const formatMinutes = (minutes: number) =>
  minutes === 0
    ? ``
    : minutes === 1
    ? `${minutes} Minute`
    : `${minutes} Minutes`;
const formatSeconds = (seconds: number) =>
  seconds === 1 ? `${seconds} Second` : `${seconds} Seconds`;

const formatCountdown = (expiresAt: string) => {
  const timeRemaining = getCountdown(new Date(), new Date(expiresAt));

  if (timeRemaining.days || timeRemaining.hours || timeRemaining.minutes) {
    return [
      formatDays(timeRemaining.days),
      formatHours(timeRemaining.hours),
      formatMinutes(timeRemaining.minutes),
    ]
      .filter(Boolean)
      .join(` `);
  }

  return `${formatSeconds(timeRemaining.seconds)}`;
};
