import graphql from "babel-plugin-relay/macro";
import { LegaleseModal } from "components/LegaleseModal";
import { useSetHasSignedEula } from "contexts/UserServiceDAContext";
import React, { useCallback } from "react";
import { useMutation } from "react-relay";
import { SecurityCommandEulaModalAgreeToEulaMutation } from "./__generated__/SecurityCommandEulaModalAgreeToEulaMutation.graphql";

const agreeToEulaMutation = graphql`
  mutation SecurityCommandEulaModalAgreeToEulaMutation {
    agreeToEula {
      hasAgreed
    }
  }
`;

const SecurityCommandEulaModal = ({
  $state,
  dealerId,
  RelayService,
}: {
  $state: any;
  dealerId: string;
  RelayService: any;
}) => {
  const [agreeToEula, _] =
    useMutation<SecurityCommandEulaModalAgreeToEulaMutation>(
      agreeToEulaMutation
    );

  const handleLogOut = useCallback(() => {
    RelayService.logout();
    reLogin();
  }, []);

  const redirect = () =>
    $state.go("app.dealer.dashboard", {
      dealer_id: dealerId,
    });

  const reLogin = () => $state.go("page.login");

  const setHasSignedEula = useSetHasSignedEula();

  const handleLogIn = useCallback(() => {
    agreeToEula({
      variables: {},
      onCompleted: ({ agreeToEula }) => {
        if (agreeToEula.hasAgreed) {
          setHasSignedEula();
          redirect();
        } else {
          handleLogOut();
        }
      },
      onError: () => {
        handleLogOut();
      },
    });
  }, []);

  return (
    <LegaleseModal
      modalTitle=" Scroll to the bottom for acknowledgement"
      titleColor={"DaColors.Neutral200"}
      srcFile="assets/img/legal-docs/LT-2910_SecurityCommand_DealerAdmin-ServicesAgreement_24362.html"
      handleCloseModal={handleLogOut}
      hasAgreementCheckbox
      handleSubmit={handleLogIn}
      loginText="Sign In"
    />
  );
};

export default SecurityCommandEulaModal;
