/**
 * @generated SignedSource<<2a8c6cd8c4aeda323f57e091708a61fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewSiteControlSystemForm_site$data = {
  readonly controlSystems: ReadonlyArray<{
    readonly id: string;
    readonly serialNumber: string;
  }>;
  readonly customer: {
    readonly dealer: {
      readonly vernaculars: ReadonlyArray<{
        readonly original: string;
        readonly replacement: string;
      } | null>;
    };
    readonly id: string;
  };
  readonly id: string;
  readonly " $fragmentType": "NewSiteControlSystemForm_site";
};
export type NewSiteControlSystemForm_site$key = {
  readonly " $data"?: NewSiteControlSystemForm_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewSiteControlSystemForm_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewSiteControlSystemForm_site",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteControlSystem",
      "kind": "LinkedField",
      "name": "controlSystems",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Dealer",
          "kind": "LinkedField",
          "name": "dealer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vernacular",
              "kind": "LinkedField",
              "name": "vernaculars",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "original",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "replacement",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "07ad332926e96e96e360d7e88af6ed57";

export default node;
