/**
 * @ngdoc service
 * @name App.factory:SplitSessionService
 *
 * @description
 * Service for getting a new temp user vkb session
 */

App.factory("SplitSessionService", [
  "SplitSessionAPI",
  "$q",
  function (SplitSessionAPI, $q) {
    return {
      /**
       * function: create a session and go to vkb admin
       *
       * @param {string} token
       *
       * @returns {object} promise
       */
      getSplitSession: function (userId, token) {
        const deferred = $q.defer();
        SplitSessionAPI.refresh(
          { user_id: userId, BearerToken: token }, // params
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error("SplitSessionService error:", error);
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
