/**
 * @generated SignedSource<<9ad777034e4aa97dfa3b804376abc685>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryRemoteOptionsProgrammingConceptForm_controlSystem$data = {
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly remoteOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsAppKeyField_remoteOptions" | "RemoteOptionsContext_remoteOptions" | "RemoteOptionsEntreArmDisarmReportsField_remoteOptions" | "RemoteOptionsEntreBackupConnectionField_remoteOptions" | "RemoteOptionsEntreBackupIpAddressField_remoteOptions" | "RemoteOptionsEntreBackupTcpPortField_remoteOptions" | "RemoteOptionsEntreCheckInMinutesField_remoteOptions" | "RemoteOptionsEntreConnectionField_remoteOptions" | "RemoteOptionsEntreIncomingTcpPortField_remoteOptions" | "RemoteOptionsEntreIpAddressField_remoteOptions" | "RemoteOptionsEntreOutgoingTcpPortField_remoteOptions" | "RemoteOptionsEntrePassphraseField_remoteOptions" | "RemoteOptionsEntreSupervisoryReportsField_remoteOptions" | "RemoteOptionsEntreUserCommandReportsField_remoteOptions" | "RemoteOptionsEntreZoneReportsField_remoteOptions" | "RemoteOptionsRemoteDisarmField_remoteOptions">;
    } | null;
    readonly softwareVersion: string;
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "TMSentryRemoteOptionsProgrammingConceptForm_controlSystem";
};
export type TMSentryRemoteOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: TMSentryRemoteOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryRemoteOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TMSentryRemoteOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "remoteOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsContext_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsRemoteDisarmField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsAppKeyField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreConnectionField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreIncomingTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreOutgoingTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreIpAddressField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreBackupConnectionField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreBackupIpAddressField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreArmDisarmReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreCheckInMinutesField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreZoneReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreUserCommandReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreSupervisoryReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntrePassphraseField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreBackupTcpPortField_remoteOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "b0b4ee4d090e4726bdda7dc67d542be7";

export default node;
