/**
 * @generated SignedSource<<b03521b7ca809dbcb2ad3687e53e44e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AllowTimeChange = "NO" | "SERVER" | "YES";
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
import { FragmentRefs } from "relay-runtime";
export type TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly systemOptions: {
      readonly __typename: "TMSentrySystemOptions";
      readonly closingCode?: boolean;
      readonly crossZoneTime: number;
      readonly enableKeypadPanicKeys?: boolean;
      readonly entryDelay1?: number;
      readonly exitDelay?: number;
      readonly hoursFromGMT: number;
      readonly id: string;
      readonly observeDst?: boolean | null;
      readonly powerFailDelay: number;
      readonly resetSwingerBypass?: boolean;
      readonly swingerBypassTrips: number;
      readonly timeChange: AllowTimeChange;
      readonly useFalseAlarmQuestion?: boolean;
      readonly weatherZipCode?: string;
      readonly wirelessAudibles?: WirelessAudibles | null;
    };
  };
  readonly " $fragmentType": "TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem";
};
export type TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "eb4a2141d78f6c85782c7ed66636b033";

export default node;
