/**
 * @ngdoc service
 * @name App.factory:VideoDevice
 *
 * @description
 * API factory for Video Device
 *
 */
App.factory("VideoDevice", [
  "$q",
  "$rootScope",
  "PROPS",
  "ControlSystemService",
  "VideoDevicesV2API",
  "VideoChannel",
  "$filter",
  function (
    $q,
    $rootScope,
    PROPS,
    ControlSystemService,
    VideoDevicesV2API,
    VideoChannel,
    $filter
  ) {
    var VideoDevice = function (deviceData) {
      angular.extend(this, {
        test_status: "Test<br> Connection",
        testing: false,
        deleting: false, // This is used to toggle the force delete option (deleting = true & status = Inaccessible)
        isGen2NVR: false,

        /**
         * @ngdoc object
         * @name method:get
         * @methodOf App.factory:VideoDevice
         *
         * @description
         * Returns the specified video device from the server
         *
         * @return {promise} The promise from the Video Devices API 'get'.
         */
        get: function (video_device_id) {
          var deferred = $q.defer();
          var _this = this;
          VideoDevicesV2API.get(
            {
              //params
              video_device_id: video_device_id,
            },
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              angular.extend(_this, _this.parseVideoDevice(data.video_device));
              _this.isGen2NVR =
                _this.device_type === "nvr" && +_this.generation >= 2;
              deferred.resolve();
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        getConnectionStatus: function () {
          var result = {};
          var networkReady = this.network_ready;
          var lastCheckIn = dateTimeForceUTC(new Date(this.checked_in_at));
          var curTime = dateTimeForceUTC(new Date());
          var timeSinceCheckIn = (curTime - lastCheckIn) / 1000 / 60; // Get the minutes since the last check-in

          if (timeSinceCheckIn > 5 && !networkReady) {
            // Check In time > 5 minutes and network is not ready
            result.connected = false;
            result.message =
              "The device cannot check-in, please verify the device has internet connectivity.";
          } else if (timeSinceCheckIn > 3 && !networkReady) {
            // Check In time > 3 minutes and network is not ready
            result.connected = false;
            result.message =
              "The device cannot create a Video VPN connection.  Please verify port UDP 1194 is open and the internet connection is stable.";
          } else if (timeSinceCheckIn <= 3 && !networkReady) {
            // Check In time <= 3 minutes and network is not ready
            result.connected = false;
            result.message =
              "The device is still attempting to connect.  Please try again in 2 minutes.";
          } else if (timeSinceCheckIn <= 3 && networkReady) {
            // Device is checking in and is network ready
            result.connected = true;
            result.message = "Device Online";
          } else {
            // Catch all
            result.connected = networkReady;
            networkReady
              ? (result.message = "Device Online")
              : (result.message = "Device Offline");
          }

          return result;
        },

        /**
         * @ngdoc object
         * @name method:get
         * @methodOf App.factory:VideoDevice
         *
         * @description
         * Returns a new video device from the server
         *
         * @return {promise} The promise from the Video Devices API 'new'.
         */
        new: function (control_system_id) {
          var deferred = $q.defer();
          var _this = this;
          ControlSystemService.newVideoDevice(control_system_id)
            .then(
              function (data) {
                angular.extend(_this, data.video_device);
                deferred.resolve();
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:refreshChannels
         * @methodOf App.factory:VideoDevice
         *
         * @description
         * Refresh the channels of an NVR
         *
         * @return {promise} The promise from the Video Devices API 'refreshChannels'.
         */
        refreshChannels: function () {
          var deferred = $q.defer();
          var _this = this;
          var changes = false; // Used to notify if there were any changes made to the channels list
          VideoDevicesV2API.refresh_channels(
            {
              //params
              video_device_id: _this.id,
            },
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });

              // Check to see if we received any changes
              if (
                data.amendments.additions.length > 0 ||
                data.amendments.removals.length > 0
              ) {
                changes = true;
              }
              deferred.resolve(changes);
            },
            function (error) {
              //failure
              if (error.data == null) {
                error.data = "Refreshing channels timed out.";
              }
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:addRemoteChannel
         * @methodOf App.factory:VideoDevice
         *
         * @description
         * Adds an ONVIF channel to the video device
         *
         * @return {promise} The promise from the Video Devices API 'addRemoteChannel'.
         */
        addRemoteChannel: function (onvifCamera) {
          var deferred = $q.defer();
          var _this = this;
          // Check to see if this is a DMP camera, if so, null out the username and password
          if (onvifCamera.camera_type === "securecom_hikvision") {
            onvifCamera.username = null;
            onvifCamera.password = null;
          }
          // Set the protocol based on which camera type... this is currently only used for NVR2.0, but is passive for older NVR
          onvifCamera.protocol =
            onvifCamera.camera_type === "other" ? "ONVIF" : "HIKVISION";
          var remoteParams = {
            //params
            video_device_id: _this.id,
            name: onvifCamera.name,
            username: onvifCamera.username,
            password: onvifCamera.password,
            ip_address: onvifCamera.ip_address,
            port: onvifCamera.port,
          };
          if (_this.isGen2NVR) {
            remoteParams.number = onvifCamera.number;
            remoteParams.protocol = onvifCamera.protocol;
          }
          VideoDevicesV2API.add_remote_channel(
            remoteParams,
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:save
         * @methodOf App.factory:VideoDevice
         *
         * @description
         * Updates the video device object using the VideoDevicesV2API.
         *
         * @return {promise} The promise from the Video Devices API 'save' function.
         */
        save: function (sendTimeZone) {
          if (angular.isUndefined(sendTimeZone)) {
            sendTimeZone = true;
          }
          var deferred = $q.defer();
          var _this = this;
          VideoDevicesV2API.save(
            { video_device_id: _this.id },
            angular.extend(_this.toJson(), { tz_sync: sendTimeZone }), // Body (If we're sending tz_sync we have to send it as part of the body
            function (success) {
              // Success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              deferred.resolve(success);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        refresh: function () {
          var deferred = $q.defer();
          var _this = this;
          VideoDevicesV2API.refresh(
            { video_device_id: _this.id },
            function (success) {
              deferred.resolve(success);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:reactivate
         * @methodOf App.factory:VideoDevice
         *
         * @description
         * Attempts to reactivate a video device
         *
         * @return {promise} The promise from the Video Devices API 'reactivate' function.
         */
        reactivate: function () {
          var deferred = $q.defer();
          var _this = this;
          VideoDevicesV2API.reactivate(
            { video_device_id: _this.id },
            function (success) {
              // Success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              deferred.resolve(success);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:save
         * @methodOf App.factory:VideoDevice
         *
         * @description
         * Tests the connection to the video device
         *
         * @return {promise} The promise from the Video Devices API 'test_connection' function.
         */
        testConnection: function () {
          this.testing = true;
          var deferred = $q.defer();
          var _this = this;
          VideoDevicesV2API.test_connection(
            { video_device_id: _this.id },
            function (success) {
              // Success
              _this.testing = false;
              _this.test_status = "Online";
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              deferred.resolve(success);
            },
            function (error) {
              //failure
              _this.testing = false;
              _this.test_status = "Offline";
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            }
          );

          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:parseVideoDevice
         * @methodOf App.factory:VideoDevice
         *
         * @description
         * Formats the retrieved video device to make it easier to work with
         *
         * @return {Object} A well formed video device response from the API
         */
        parseVideoDevice: function (videoDeviceData) {
          if (videoDeviceData.device_type == "dvr") {
            this.parseDVRChannels(videoDeviceData, 4);
          } else if (
            videoDeviceData.device_type === "nvr" &&
            Number(videoDeviceData.generation) >= 2
          ) {
            angular.forEach(videoDeviceData.channels, function (channel, key) {
              // create video channels from incoming data
              videoDeviceData.channels[key] = new VideoChannel(channel);
              videoDeviceData.channels[key].parseSchedules();
              if (!videoDeviceData.channels[key].online)
                videoDeviceData.channels[key].placeholder = true;
            });
          } else {
            angular.forEach(videoDeviceData.channels, function (channel, key) {
              // create video channels from incoming data
              videoDeviceData.channels[key] = new VideoChannel(channel);
              videoDeviceData.channels[key].parseSchedules();
            });
          }

          return videoDeviceData;
        },

        parseDVRChannels: function (videoDevice, dvrChannelCount) {
          // Loop from 1 to the DVR channel count. If a channel exists with the given number, fetch it, else create a blank channel
          var videoChannels = [];
          for (var i = 1; i <= dvrChannelCount; i++) {
            channel = $filter("filter")(videoDevice.channels, { number: i })[0];

            if (!channel) {
              channel = {
                name: "Channel " + i + " (Placeholder)",
                number: i,
                placeholder: true,
              };
            }

            var ch = new VideoChannel(channel);
            ch.parseSchedules();
            videoChannels.push(ch);
          }

          videoDevice.channels = videoChannels;
        },

        toJson: function () {
          var json = {};
          json.video_device = angular.copy(this);
          json.video_device.channels_attributes = $filter("filter")(
            json.video_device.channels,
            { placeholder: false }
          );
          delete json.video_device.channels;
          if (json.video_device.hasOwnProperty("local_password_confirm")) {
            delete json.video_device.local_password_confirm;
          }

          // If Channels_Attribute is blank, remove it from the request
          if (
            DoesNestedPropertyExist(json, "channels_attributes") &&
            json.video_device.channels_attributes.length == 0
          ) {
            delete json.video_device.channels_attributes;
          } else {
            // Loop through any video channel and remove empty schedule and motion detection region arrays
            angular.forEach(
              json.video_device.channels_attributes,
              function (channel, key) {
                // Check to see if we have any schedules attached to the video channel
                if (channel.schedules.length > 0) {
                  // Houston, We have schedules. Rename, then loop through them and clear out any fields the API doesn't want/need
                  channel.schedules_attributes = channel.schedules;
                  angular.forEach(
                    channel.schedules_attributes,
                    function (schedule, key) {
                      if (schedule.hasOwnProperty("isNew")) {
                        delete schedule.isNew;
                      }
                      if (schedule.hasOwnProperty("time_start")) {
                        delete schedule.time_start;
                      }
                      if (schedule.hasOwnProperty("time_end")) {
                        delete schedule.time_end;
                      }
                    }
                  );
                }
                // Make sure that we have a motion detection region for the channel
                channel.initializeMotionDetectionRegions(
                  json.video_device.model
                );
                channel.motion_detection_regions_attributes =
                  channel.motion_detection_regions;
                channel.analytical_detection_regions_attributes =
                  channel.analytical_detection_regions;
                channel.analytical_detection_lines_attributes =
                  channel.analytical_detection_lines;
                delete channel.analytical_detection_lines;
                delete channel.analytical_detection_regions;
                if (
                  deviceData?.device_type === "camera"
                ) {
                  channel.events_enabled = "on"; // If this is a camera then we want the events to be on.
                }
                angular.forEach(
                  channel.motion_detection_regions_attributes,
                  function (region, key) {
                    // Rename the coordinates array
                    if (region.hasOwnProperty("coordinates")) {
                      region.coordinates_attributes = angular.copy(
                        region.coordinates
                      );
                      delete region.coordinates;
                    }
                  }
                );
                angular.forEach(
                  channel.analytical_detection_regions_attributes,
                  function (region, key) {
                    // Rename the coordinates array
                    if (region.hasOwnProperty("coordinates")) {
                      region.coordinates_attributes = angular.copy(
                        region.coordinates
                      );
                      delete region.coordinates;
                    }
                  }
                );

                // If this is a camera, make sure that the channel name matches the device name
                if (json.video_device.device_type == "camera") {
                  json.video_device.channels_attributes[0].name =
                    json.video_device.name;
                }

                // Delete the fields the API doesn't need
                if (channel.hasOwnProperty("tryStreaming")) {
                  delete channel.tryStreaming;
                }
                if (channel.hasOwnProperty("pan_range")) {
                  delete channel.pan_range;
                }
                if (channel.hasOwnProperty("tilt_range")) {
                  delete channel.tilt_range;
                }
                if (channel.hasOwnProperty("old_camera_id")) {
                  delete channel.old_camera_id;
                }
                if (channel.hasOwnProperty("stream_uuid")) {
                  delete channel.stream_uuid;
                }
                if (channel.hasOwnProperty("stream_expiration")) {
                  delete channel.stream_expiration;
                }
                if (channel.hasOwnProperty("snapshot_url")) {
                  delete channel.snapshot_url;
                }
                if (channel.hasOwnProperty("light_url")) {
                  delete channel.light_url;
                }
                if (channel.hasOwnProperty("movement_url")) {
                  delete channel.movement_url;
                }
                if (channel.hasOwnProperty("peripheral_status_url")) {
                  delete channel.peripheral_status_url;
                }
                if (channel.hasOwnProperty("mjpeg_url")) {
                  delete channel.mjpeg_url;
                }
                if (channel.hasOwnProperty("rtsp_url")) {
                  delete channel.rtsp_url;
                }
                if (channel.hasOwnProperty("playback_url")) {
                  delete channel.playback_url;
                }
                if (channel.hasOwnProperty("network_status")) {
                  delete channel.network_status;
                }
                if (channel.hasOwnProperty("schedules_pristine")) {
                  delete channel.schedules_pristine;
                }
                if (channel.hasOwnProperty("camera")) {
                  delete channel.camera;
                }
                if (channel.hasOwnProperty("device_type")) {
                  delete channel.device_type;
                }
                if (channel.hasOwnProperty("available")) {
                  delete channel.available;
                }
                if (channel.hasOwnProperty("showing_jpeg")) {
                  delete channel.showing_jpeg;
                }
                if (channel.hasOwnProperty("fps")) {
                  delete channel.fps;
                }
                if (channel.hasOwnProperty("admin_username"))
                  delete channel.admin_username;
                if (channel.hasOwnProperty("admin_password"))
                  delete channel.admin_password;
                if (channel.hasOwnProperty("proxy_url"))
                  delete channel.proxy_url;
                if (channel.hasOwnProperty("nvr_low_resolution_url"))
                  delete channel.nvr_low_resolution_url;
                delete channel.placeholder;
                delete channel.stream_available;
                delete channel.schedules;
                delete channel.motion_detection_regions;
              }
            );
          }
          return json;
        },

        /**
         * @ngdoc object
         * @name method:extendMe
         * @methodOf App.factory:VideoDevice
         *
         * @description
         * Extend the video device object with the sent data
         */
        extendMe: function (deviceData) {
          angular.extend(this, this.parseVideoDevice(deviceData));
        },
      });

      // Initialize the video device with existing data
      if (deviceData !== null && angular.isDefined(deviceData)) {
        this.extendMe(deviceData);
      }
    };

    return VideoDevice;
  },
]);
