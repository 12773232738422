/**
 * @generated SignedSource<<1535c7887850b4072abfd39555d4263a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExistingSiteFormFragment_site$data = {
  readonly billingControlSystemId: string;
  readonly customer: {
    readonly dealer: {
      readonly id: string;
      readonly securityCommand: boolean | null;
    };
    readonly id: string;
    readonly name: string;
  };
  readonly id: string;
  readonly name: string;
  readonly showDoors: boolean;
  readonly showElevators: boolean;
  readonly showOutputs: boolean;
  readonly showVideoServices: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"SiteCardFormatsSection_site" | "SiteControlSystemsSection_site" | "SiteEditModal_site" | "SiteElevatorSystemsSection_site" | "SiteFirmwareUpdateDialogue_UpdateProgress_site" | "SiteFirmwareUpdateDialogue_site" | "SiteOutputModulesSection_site" | "SiteVideoIntegrationsSection_site">;
  readonly " $fragmentType": "ExistingSiteFormFragment_site";
};
export type ExistingSiteFormFragment_site$key = {
  readonly " $data"?: ExistingSiteFormFragment_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExistingSiteFormFragment_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExistingSiteFormFragment_site",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingControlSystemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showDoors",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showElevators",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showOutputs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showVideoServices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Dealer",
          "kind": "LinkedField",
          "name": "dealer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "securityCommand",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteEditModal_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteControlSystemsSection_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteElevatorSystemsSection_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteCardFormatsSection_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteVideoIntegrationsSection_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteOutputModulesSection_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteFirmwareUpdateDialogue_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteFirmwareUpdateDialogue_UpdateProgress_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "d2ec6b8c5aacf57316bfa30b5d964193";

export default node;
