/**
 * @generated SignedSource<<b7962a74cbf86419f2c6a1363a47697e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions$data = {
  readonly integratorArmDisarmReports?: boolean | null;
  readonly integratorConnection?: RemoteOptionsConnectionType | null;
  readonly " $fragmentType": "RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions";
};
export type RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorArmDisarmReports",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorConnection",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75RemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "88840ec9b6e81281832cac02b11bcb04";

export default node;
