App.controller("CustomReportCtrl", [
  "$scope",
  "$rootScope",
  "$window",
  "$state",
  "$http",
  "$timeout",
  "$compile",
  "$filter",
  "$q",
  "focus",
  "DashboardDataService",
  "UserService",
  "customReport",
  "CustomReportsService",
  "DTOptionsBuilder",
  "DTColumnDefBuilder",
  "DTColumnBuilder",
  "$localStorage",
  "DataTablesUtilsService",
  "JobSchedulerService",
  "Customer",
  "ControlSystemsService",
  function (
    $scope,
    $rootScope,
    $window,
    $state,
    $http,
    $timeout,
    $compile,
    $filter,
    $q,
    focus,
    DashboardDataService,
    UserService,
    customReport,
    CustomReportsService,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    DTColumnBuilder,
    $localStorage,
    DataTablesUtilsService,
    JobSchedulerService,
    Customer,
    ControlSystemsService
  ) {
    $scope.UserService = UserService;
    $scope.dealer_id = UserService.dealer_id;
    $scope.dtColumns = [];
    if ($localStorage.thisCustomReport) {
      $scope.customReport = new customReport($localStorage.thisCustomReport);
    } else {
      $rootScope.alerts.push({
        type: "error",
        text: "Something went wrong.  Please try to run the report again.",
      });
    }

    Promise.all([
      UserService.waitForUser(),
      UserService.waitForDealerInfo(),
    ]).then(([user, dealer]) => {
      const vernaculars = UserService.dealerInfo.vernaculars;
    
      const getReplacement = (item, defaultValue) => {
        const replacement = item?.replacement;
        return replacement && replacement.length > 0 ? replacement : defaultValue;
      };
    
      const customerReplacement = getReplacement(vernaculars.customers, "Customers");
      const systemReplacement = getReplacement(vernaculars.systems, "System");
      const vernacularMap = {
        Customer: customerReplacement,
        "System Name": `${systemReplacement} Name`,
        "System Type": `${systemReplacement} Type`,
      };
    
      $scope.dtOptions = DataTablesUtilsService.getDTOptions(getReportData(), "");
    
      function getReportData() {
        const deferred = $q.defer();
        $scope.customReport
          .getData()
          .then((data) => deferred.resolve(data))
          .catch((error) => console.error(error));
        getColumns(); // This must be outside of the promise, since only the DATA is promise based...we need column info immediately.
        return deferred.promise;
      }
    
      function getColumns() {
        angular.forEach($scope.customReport.HeaderMap, (column) => {
          const title = vernacularMap[column.name] || column.name;
          let dtColumn = DTColumnBuilder.newColumn(column.field).withTitle(title);
    
          if (column.visible === false) dtColumn.notVisible();
          dtColumn = formatColumn(dtColumn, column);
          $scope.dtColumns.push(dtColumn);
        });
      }
    
      function formatColumn(dtColumn, column) {
        if (column.type && column.type.includes("date")) {
          if (column.type.includes("datetime_raw")) {
            dtColumn = dtColumn.renderWith(
              DataTablesUtilsService.asLiteralDateTimeRenderFn(column.field)
            );
          } else {
            dtColumn = dtColumn.renderWith(
              DataTablesUtilsService.asDateTimeRenderFn()
            );
          }
        } else if (column.type === "boolean") {
          dtColumn = dtColumn.renderWith((data, type, row) =>
            DataTablesUtilsService.asBoolean(row, column.field)
          );
        } else if (column.type === "hardware_model") {
          dtColumn = dtColumn.renderWith((data, type, row) =>
            DataTablesUtilsService.asHardwareModel(row, column.field)
          );
        } else if (column.type === "string_titlecase") {
          dtColumn = dtColumn.renderWith((data, type, row) =>
            DataTablesUtilsService.stringTitlecase(row, column.field)
          );
        } else if (column.type === "comm_type") {
          dtColumn = dtColumn.renderWith((data, type, row) =>
            DataTablesUtilsService.commType(row, column.field)
          );
        } else {
          dtColumn = dtColumn.renderWith((data, type, row) =>
            DataTablesUtilsService.dashProperty(row, column.field)
          );
        }
        return dtColumn;
      }
    
      if ($scope.customReport.ConfigData.report_type === "new_systems") {
        const systemReplace = UserService.dealerInfo.vernaculars.systems.replacement;
        if (systemReplace) {
          $scope.newSystemTitle = `New ${systemReplace}`;
        }
        $scope.dtOptions.withOption("order", [6, "desc"]);
      }
    });
  },
]);
