/**
 * @generated SignedSource<<251f127f978af18da61360831a21a541>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeypadInput = "DSC" | "ECP" | "NONE";
export type ZoneChimeSound = "ASCEND" | "DESCEND" | "DOORBELL" | "NONE" | "OFF";
export type ZoneEntryDelayNumber = "FOUR" | "ONE" | "THREE" | "TWO";
export type ZoneMessage = "ALARM" | "CANCEL_AMBUSH" | "DOOR_PROPPED_OPEN" | "LOCAL" | "NONE" | "SENSOR_RESET" | "TROUBLE";
export type ZoneOutputAction = "FOLLOW" | "MOMENTARY" | "NONE" | "PULSE" | "STEADY";
export type ZonePetImmunity = "NONE" | "OFF" | "ON";
export type ZonePirPulseCount = "FOUR" | "NONE" | "TWO";
export type ZonePirSensitivity = "HIGH" | "LOW" | "NONE";
export type ZoneSupervisionTime = "NONE" | "_1440_MIN" | "_240_MIN" | "_3_MIN" | "_60_MIN" | "_720_MIN";
export type ZoneTransmitterContact = "CONTACT_3" | "CONTACT_4" | "EXTERNAL" | "INTERNAL";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type TMSentryZoneInformationsProgrammingConceptForm_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly copiedZoneInformation: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly isCellComEx: boolean;
  readonly panel: {
    readonly ecpDscZoneNumberRange: ReadonlyArray<number>;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly keyfobs: ReadonlyArray<{
      readonly id: string;
      readonly number: number;
    }>;
    readonly newZone: {
      readonly area: {
        readonly __typename: string;
        readonly id: string;
        readonly number: string;
      } | null;
      readonly armedAreasForArmingZone: string;
      readonly armedOpenActionMessage: ZoneMessage | null;
      readonly armedOpenOutputAction: ZoneOutputAction | null;
      readonly armedOpenOutputNumber: string;
      readonly armedShortActionMessage: ZoneMessage | null;
      readonly armedShortOutputAction: ZoneOutputAction | null;
      readonly armedShortOutputNumber: string;
      readonly chimeSound: ZoneChimeSound | null;
      readonly contactNumber: ZoneTransmitterContact | null;
      readonly crossZoneEnabled: boolean;
      readonly disarmedOpenActionMessage: ZoneMessage | null;
      readonly disarmedOpenOutputAction: ZoneOutputAction | null;
      readonly disarmedOpenOutputNumber: string | null;
      readonly disarmedShortActionMessage: ZoneMessage | null;
      readonly disarmedShortOutputAction: ZoneOutputAction | null;
      readonly disarmedShortOutputNumber: string;
      readonly entryDelayNumber: ZoneEntryDelayNumber | null;
      readonly id: string;
      readonly isNew: boolean;
      readonly location: string | null;
      readonly name: string;
      readonly number: string;
      readonly prewarnKeypads: string;
      readonly priorityZone: boolean;
      readonly serialNumber: string;
      readonly supervisionTime: ZoneSupervisionTime | null;
      readonly swingerBypassEnabled: boolean;
      readonly trafficCountEnabled: boolean;
      readonly type: ZoneType;
      readonly wireless: boolean;
      readonly wirelessContactNormallyOpen: boolean;
      readonly wirelessDisarmDisableEnabled: boolean;
      readonly wirelessLedEnabled: boolean;
      readonly wirelessPetImmunity: ZonePetImmunity;
      readonly wirelessPirPulseCount: ZonePirPulseCount | null;
      readonly wirelessPirSensitivity: ZonePirSensitivity | null;
      readonly zoneAuditDays: string;
      readonly " $fragmentSpreads": FragmentRefs<"TMSentryZoneInformationsFields_zone">;
    } | null;
    readonly outputInformations: ReadonlyArray<{
      readonly id: string;
      readonly number: number;
    }>;
    readonly supportsDscZones: boolean;
    readonly supportsEcpZones: boolean;
    readonly systemOptions: {
      readonly keypadInput?: KeypadInput | null;
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly totalWirelessZoneMax: number;
    readonly totalZonesMax: number;
    readonly zoneInformations: ReadonlyArray<{
      readonly id: string;
      readonly isECP: boolean;
      readonly isNew: boolean;
      readonly name: string;
      readonly number: string;
      readonly " $fragmentSpreads": FragmentRefs<"TMSentryZoneInformationsFields_zone">;
    }>;
    readonly zoneNumberRange: ReadonlyArray<number>;
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseAreaList_panel" | "PanelContextUseBuiltIn1100WirelessEnabled_panel" | "PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContextUseWirelessZoneNumberRange_panel" | "PanelContextUseZoneNumberRange_panel" | "PanelContext_panel" | "ZoneInformationNumberField_panel" | "ZoneInformationSerialNumberField_ZoneList_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem">;
  readonly " $fragmentType": "TMSentryZoneInformationsProgrammingConceptForm_controlSystem";
};
export type TMSentryZoneInformationsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: TMSentryZoneInformationsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryZoneInformationsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNew",
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "TMSentryZoneInformationsFields_zone"
},
v6 = [
  (v1/*: any*/),
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TMSentryZoneInformationsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCellComEx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zoneNumberRange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ecpDscZoneNumberRange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalWirelessZoneMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalZonesMax",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ZoneInformationNumberField_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportsEcpZones",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportsDscZones",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "keypadInput",
                  "storageKey": null
                }
              ],
              "type": "XtSystemOptions",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Zone",
          "kind": "LinkedField",
          "name": "zoneInformations",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isECP",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Zone",
          "kind": "LinkedField",
          "name": "newZone",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "location",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "area",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chimeSound",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "crossZoneEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entryDelayNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prewarnKeypads",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priorityZone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serialNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "supervisionTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "swingerBypassEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trafficCountEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wireless",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wirelessDisarmDisableEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wirelessLedEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wirelessPetImmunity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wirelessPirPulseCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wirelessPirSensitivity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "zoneAuditDays",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wirelessContactNormallyOpen",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "armedAreasForArmingZone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disarmedOpenActionMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disarmedOpenOutputNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disarmedOpenOutputAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disarmedShortActionMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disarmedShortOutputNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disarmedShortOutputAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "armedOpenActionMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "armedOpenOutputNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "armedOpenOutputAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "armedShortActionMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "armedShortOutputNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "armedShortOutputAction",
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Keyfob",
          "kind": "LinkedField",
          "name": "keyfobs",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Output",
          "kind": "LinkedField",
          "name": "outputInformations",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseZoneNumberRange_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseWirelessZoneNumberRange_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseBuiltIn1100WirelessEnabled_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseAreaList_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ZoneInformationSerialNumberField_ZoneList_panel"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Zone",
          "kind": "LinkedField",
          "name": "copiedZoneInformation",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "b0f1639ce223f05d862f7e51be0f6333";

export default node;
