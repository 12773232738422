/**
 * @ngdoc service
 * @name App.factory:controlSystemModel
 *
 * @description
 * Holds the model for the control system
 *
 * @param {Object} controlSystem A object containing a Control System ID and Customer Number
 * <pre>
 *   {control_system_id:12345,customer_id:12345}
 * </pre>
 */
App.factory("controlSystemModel", [
  "UserService",
  "PROPS",
  "$timeout",
  "ControlSystemService",
  "$filter",
  "$rootScope",
  "$q",
  "$location",
  function (
    UserService,
    PROPS,
    $timeout,
    ControlSystemService,
    $filter,
    $rootScope,
    $q,
    $location
  ) {
    var controlSystemModel = function (controlSystem) {
      angular.extend(this, {
        /**
         * SCAPI / tunnel require serial numbers for persistent connection types to be online to prevent
         * duplicate connections for panels prior to a certain version.
         * @type {boolean} - true if the current panel of this control system requires a serial number
         */
        requiresSerialNumber: function () {
          if (
            this.hasOwnProperty("panels") &&
            this.hasOwnProperty("panel_index")
          ) {
            var panel = this.panels[this.panel_index];
            return (
              (panel.programming_type === "XT75" &&
                panel.comm_type === "cell") ||
              (panel.auto_program && panel.comm_type === "cell") ||
              panel.programming_type === "XF6" ||
              panel.programming_type === "TMS6" || // Serial Number is always required for XF6
              ((panel.online || (!panel.online && panel.auto_program)) &&
                ["persistent", "persistent_w_cell_backup"].includes(
                  panel.comm_type
                ))
            );
          }
          return false;
        },
        /**
         * SCAPI / tunnel requires a cellular device identifier for certain connection types to be online to ensure
         * connection can be made.
         * @type {boolean} - true if the current panel of this control system requires a cellular device id
         */
        requiresDeviceId: function () {
          if (
            this.hasOwnProperty("panels") &&
            this.hasOwnProperty("panel_index")
          ) {
            var panel = this.panels[this.panel_index];
            return (
              panel.online &&
              ["cell", "persistent_w_cell_backup"].indexOf(
                this.panels[this.panel_index].comm_type
              ) > -1
            );
          }
          return false;
        },
        /**
         * @ngdoc object
         * @name property:control_system_id
         * @type Number
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * Holds the seed control system id.
         */
        control_system_id: controlSystem.control_system_id
          ? controlSystem.control_system_id
          : controlSystem.id,
        /**
         * @ngdoc object
         * @name property:customer_id
         * @type Number
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * Holds the seed customer id
         */
        customer_id: controlSystem.customer_id,
        /**
         * @ngdoc object
         * @name property:panel_index
         * @type Number
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * Currently only one panel is supported inside a Control System object, but if that ever changes, the
         * index property would be used to access additional panel objects inside the control system.  The default
         * is set to '0'.
         */
        panel_index: 0,
        /**
         * @ngdoc object
         * @name property:isNew
         * @type Boolean
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * Initializes the 'isNew' property to 'false'.  A call to the 'get' method with a control system id of 'new'
         * toggles this property.
         */
        isNew: false,

        /**
         * @ngdoc object
         * @name method:vvURL
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function returns the Video Verification URL based on current dealer settings
         *
         * @return {string} The URL for Video Verification
         */
        vvURL: function () {
          if (this.isNew)
            return "Not available until after panel has been saved.";
          if (UserService.dealerInfo.video_verification) {
            var vv_id = encrypt(UserService.dealerInfo.id.toString(), "vv_id");
            if (
              UserService.dealerInfo.video_verification_strategy ==
              "master_identifier"
            ) {
              var primary = $filter("filter")(
                UserService.dealerInfo.master_identifiers,
                { primary: true }
              );
              if (primary?.length == 0) {
                return "Error determining master identifier.  Contact Customer Support.";
              }
              var vv_email = encrypt(this.id.toString(), "vv_email");
              return (
                "https://" +
                $location.host() +
                ":" +
                $location.port() +
                "/#/page/video/" +
                this.id +
                "?vv=" +
                vv_id +
                "&email=" +
                vv_email +
                "&auth_token=" +
                primary?.[0].authentication_token
              );
            } else {
              return (
                "https://" +
                $location.host() +
                ":" +
                $location.port() +
                "/#/page/video/" +
                this.id
              );
            }
          } else {
            return "Video Verification not active.";
          }
        },

        /**
         * @ngdoc object
         * @name method:vvURL
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function returns the Video Devices for the Control System
         *
         * @return {promise} The Promise from the Control System Service Video Device API
         */
        getVideoDevices: function () {
          var deferred = $q.defer();
          var _this = this;
          ControlSystemService.getVideoDevices(_this.control_system_id)
            .then(
              function (data) {
                //success
                deferred.resolve(data);
              },
              function (error) {
                //failure
                deferred.reject(error);
              },
              function (info) {
                //failure
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:vvURL
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function returns a template video device object
         *
         * @return {promise} The Promise from the Control System Service Video Device API
         */
        newVideoDevice: function () {
          var deferred = $q.defer();
          var _this = this;
          ControlSystemService.newVideoDevice(_this.control_system_id)
            .then(
              function (data) {
                //success
                deferred.resolve(data);
              },
              function (error) {
                //failure
                deferred.reject(error);
              },
              function (info) {
                //failure
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:get
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function returns a copy of the current object after it has been populated with a new or existing control system.
         *
         * @return {promise} The promise from internal functions that return a new or existing control system.
         */
        get: function () {
          if (this.control_system_id === "new") {
            this.isNew = true;
            return this.getNewControlSystem();
          } else {
            return this.getExistingControlSystem();
          }
        },
        /**
         * @ngdoc object
         * @name method:getNewControlSystem
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function calls the 'new' method of the Control System Service/API and returns an empty copy of a
         * control system
         *
         * @return {promise} The promise from the Control Systems API for a new control system.
         */
        getNewControlSystem: function () {
          var deferred = $q.defer();
          var _this = this;
          ControlSystemService.new(this.customer_id)
            .then(
              function (success) {
                angular.extend(_this, success.control_system);
                _this.panels[_this.panel_index].programming_type = "";
                _this.panels[_this.panel_index].comm_type = "";
                deferred.resolve(_this);
              },
              function (error) {
                deferred.reject(error);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:getExistingControlSystem
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function calls the 'edit' method of the Control System Service/API and returns an existing control system
         *
         * @return {promise} The promise from the Control Systems API for an existing control system.
         */
        getExistingControlSystem: function () {
          var deferred = $q.defer();
          var _this = this;
          ControlSystemService.edit(this.customer_id, this.control_system_id)
            .then(
              function (success) {
                angular.extend(_this, success.control_system);
                //for each sensor zone, a 'tracked' flag is added if an 'id' exists for the given zone.
                angular.forEach(
                  _this.panels[_this.panel_index].sensor_activity_zones,
                  function (zone) {
                    zone.tracked = zone.id != null;
                  }
                );
                //Adds a 'destroy' flag to each output so it can be visually removed and still tracked for the save process.
                angular.forEach(
                  _this.panels[_this.panel_index].tracked_outputs,
                  function (output) {
                    output._destroy = false;
                  }
                );
                //Removes non-doors from the devices array.  TODO:This may eventually be filtered by the API so this could be removed.
                _this.panels[_this.panel_index].device_informations =
                  _this.panels[_this.panel_index].device_informations.filter(
                    function (device) {
                      if (device.tipe == "1" || device.door === "Y")
                        return device;
                    }
                  );
                //TODO: This should be a temporary patch to remove this international code from in front of the 'comm_address' field.
                //The API team confirmed that this was not desired behavior and will be removed.
                const pattern = /\+[0-9]+ /;
                const str = _this.panels[_this.panel_index].comm_address;
                if (str != null) {
                  _this.panels[_this.panel_index].comm_address = str.replace(
                    pattern,
                    ""
                  );
                }
                const isXf = /XF6_(1|5)00/.test(
                  _this.panels[_this.panel_index].hardware_model
                );
                const isTMSentry = /TMS/.test(
                  _this.panels[_this.panel_index].hardware_model
                );
                const isX1 =
                  _this.panels[_this.panel_index].hardware_model === "X001";
                //create 'system type' i.e._this.programming_type
                const hardwareType =
                  _this.panels[_this.panel_index].hardware_model !== null &&
                  (_this.panels[_this.panel_index].arming_system !== null ||
                    isXf ||
                    isX1)
                    ? _this.panels[_this.panel_index].hardware_model
                        .toUpperCase()
                        .substring(0, 8)
                    : _this.panels[_this.panel_index].name
                        .toUpperCase()
                        .substring(0, 8);
                switch (true) {
                  case /XTLP.*/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XTLP";
                    break;
                  case /XTL.*/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XTL";
                    break;
                  case /XT30/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XT30";
                    break;
                  case /XT50/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XT50";
                    break;
                  case /XT75/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XT75";
                    break;
                  case /XR(1|3|5)50/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XR550";
                    break;
                  case /XR(1|3|5)00/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XR500";
                    break;
                  case isTMSentry:
                    _this.panels[_this.panel_index].programming_type = "TMS6";
                    break;
                  case isXf:
                    _this.panels[_this.panel_index].programming_type = "XF6";
                    break;
                  case isX1:
                    _this.panels[_this.panel_index].programming_type = "X001";
                    break;
                  case /DUALCOM/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "DualCom";
                    break;
                  case /MINICELL/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "MiniCellCom";
                    break;
                  case /CELLCOMS/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "CellComSL";
                    break;
                  case /CELLCOME/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "CellComEX";
                    break;
                  case /ICOMS/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "iComSL";
                    break;
                  case /LNC/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "iComLNC";
                    break;
                  default:
                    _this.panels[_this.panel_index].programming_type = "XT50";
                }
                //TODO:Get SIM info and attach to Model.
                UserService.setProgramming(_this.panels[_this.panel_index]);
                deferred.resolve(_this);
              },
              function (error) {
                deferred.reject(error);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },
        /**
         * @ngdoc object
         * @name method:showControlSystem
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function calls the 'show' method of the Control System Service/API and returns an existing control system
         *
         * @return {promise} The promise from the Control Systems API for an existing control system.
         */
        showControlSystem: function () {
          var deferred = $q.defer();
          var _this = this;
          ControlSystemService.show(this.customer_id, this.control_system_id)
            .then(
              function (success) {
                angular.extend(_this, success.control_system);
                //TODO: This should be a temporary patch to remove this international code from in front of the 'comm_address' field.
                //The API team confirmed that this was not desired behavior and will be removed.
                const pattern = /\+[0-9]+ /;
                const str = _this.panels[_this.panel_index].comm_address;
                if (str != null) {
                  _this.panels[_this.panel_index].comm_address = str.replace(
                    pattern,
                    ""
                  );
                }
                const isXf = /XF6_(1|5)00/.test(
                  _this.panels[_this.panel_index].hardware_model
                );
                const isX1 =
                  _this.panels[_this.panel_index].hardware_model === "X001";
                //create 'system type' i.e._this.programming_type
                const hardwareType =
                  _this.panels[_this.panel_index].hardware_model !== null &&
                  (_this.panels[_this.panel_index].arming_system !== null ||
                    isXf ||
                    isX1)
                    ? _this.panels[_this.panel_index].hardware_model
                        .toUpperCase()
                        .substring(0, 8)
                    : _this.panels[_this.panel_index].name
                        .toUpperCase()
                        .substring(0, 8);
                switch (true) {
                  case /XTLP.*/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XTLP";
                    break;
                  case /XTL.*/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XTL";
                    break;
                  case /XT30/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XT30";
                    break;
                  case /XT50/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XT50";
                    break;
                  case /XT75/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XT75";
                    break;
                  case /XR(1|3|5)50/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XR550";
                    break;
                  case /XR(1|3|5)00/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "XR500";
                    break;
                  case isXf:
                    _this.panels[_this.panel_index].programming_type = "XF6";
                    break;
                  case isX1:
                    _this.panels[_this.panel_index].programming_type = "X001";
                    break;
                  case /DUALCOM/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "DualCom";
                    break;
                  case /MINICELL/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "MiniCellCom";
                    break;
                  case /CELLCOMS/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "CellComSL";
                    break;
                  case /CELLCOME/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "CellComEX";
                    break;
                  case /ICOMS/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type = "iComSL";
                    break;
                  case /LNC/.test(hardwareType):
                    _this.panels[_this.panel_index].programming_type =
                      "iComLNC";
                    break;
                  default:
                    _this.panels[_this.panel_index].programming_type = "XT50";
                }
                //TODO:Get SIM info and attach to Model.
                UserService.setProgramming(_this.panels[_this.panel_index]);
                angular.forEach(_this.panels, function (panel) {
                  if (angular.isDefined(panel.sim) && panel.sim != null) {
                    if (angular.isDefined(panel.sim.iccid)) {
                      panel.sim.identifier = panel.sim.iccid;
                      panel.sim.type = "ICCID";
                    } else if (angular.isDefined(panel.sim.meid)) {
                      panel.sim.identifier = panel.sim.meid;
                      panel.sim.type = "MEID";
                    } else if (
                      angular.isUndefined(panel.sim.iccid) &&
                      angular.isUndefined(panel.sim.meid) &&
                      angular.isDefined(panel.sim.msisdn)
                    ) {
                      panel.sim.identifier = panel.sim.msisdn;
                      panel.sim.type = "MSISDN";
                    } else if (
                      angular.isUndefined(panel.sim.iccid) &&
                      angular.isUndefined(panel.sim.meid) &&
                      angular.isUndefined(panel.sim.msisdn) &&
                      angular.isDefined(panel.sim.imsi)
                    ) {
                      panel.sim.identifier = panel.sim.imsi;
                      panel.sim.type = "IMSI";
                    } else {
                      panel.sim = null;
                    }
                  }
                });
                deferred.resolve(_this);
              },
              function (error) {
                deferred.reject(error);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },
        /**
         * @ngdoc object
         * @name method:save
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function creates a new or updates an existing control system.
         *
         * @return {promise} The promise from internal functions that return the created or updated control system.
         */
        save: function () {
          this.clearErrors();
          this.validate();
          if (Object.keys(this.errors).length) {
            return $q.reject(this.errors);
          } else {
            this.clearErrors();
          }
          if (this.control_system_id === "new") {
            delete this.id; //don't need it
            delete this.panels[this.panel_index].id; //don't need it
            delete this.services_manager.id; //don't need it
            return this.create();
          } else {
            return this.update();
          }
        },
        /**
         * @ngdoc object
         * @name method:save
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function updates an existing control system for replace panel and prevents the initial connect that scapi usually performs when the SN is changed.
         *
         * @return {promise} The promise from internal functions that return the created or updated control system.
         */
        replacePanelSave: function () {
          this.clearErrors();
          this.validate();
          if (Object.keys(this.errors).length) {
            return $q.reject(this.errors);
          } else {
            this.clearErrors();
          }
          const panel_is_being_replaced = true;
          return this.update(panel_is_being_replaced);
        },
        /**
         * @ngdoc object
         * @name method:validate
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function pre-validates selected fields and sets the model errors array.
         *
         */
        validate: function () {
          this.errors = {};
          //Control System Name
          if (isUndefinedOrNull(this.name)) {
            angular.extend(this.errors, { name: ["This field is required"] }); //TODO: Lang these
          }
          //Programming Type (i.e. XT, XR, CellCom)
          if (
            isUndefinedOrNull(this.panels[this.panel_index].programming_type)
          ) {
            angular.extend(this.errors, {
              programming_type: ["This field is required"],
            }); //TODO: Lang these
          }

          if (
            Number(this.panels[this.panel_index].account_number) > 65535 ||
            Number(this.panels[this.panel_index].account_number) < 1 ||
            isNaN(this.panels[this.panel_index].account_number)
          ) {
            angular.extend(this.errors, {
              panels_account_number: ["Must be between 1-65535"],
            }); //TODO: Lang these
          }

          if (
            Number(this.panels[this.panel_index].account_prefix) > 99 ||
            Number(this.panels[this.panel_index].account_prefix) < 1 ||
            isNaN(this.panels[this.panel_index].account_prefix)
          ) {
            angular.extend(this.errors, {
              panels_account_prefix: ["Must be between 1-99"],
            }); //TODO: Lang these
          }

          if (this.panels[this.panel_index].hardware_model !== "Video Only") {
            //Communications Type (i.e. EASY, Network, Cell)
            if (isUndefinedOrNull(this.panels[this.panel_index].comm_type)) {
              angular.extend(this.errors, {
                panels_comm_type: ["This field is required"],
              }); //TODO: Lang these
            }
            if (
              isUndefinedOrNull(this.panels[this.panel_index].account_prefix)
            ) {
              angular.extend(this.errors, {
                panels_account_prefix: ["This field is required"],
              }); //TODO: Lang these
            }
            if (
              isUndefinedOrNull(this.panels[this.panel_index].account_number)
            ) {
              angular.extend(this.errors, {
                panels_account_number: ["This field is required"],
              }); //TODO: Lang these
            }
            if (
              String(this.panels[this.panel_index].remote_key).length > 8 &&
              String(this.panels[this.panel_index].programming_type).substr(
                0,
                2
              ) != "XR"
            ) {
              angular.extend(this.errors, {
                panels_remote_key: ["This field is a max of 8 characters"],
              }); //TODO: Lang these
            }
            if (
              String(this.panels[this.panel_index].remote_key).length > 16 &&
              String(this.panels[this.panel_index].programming_type).substr(
                0,
                2
              ) == "XR"
            ) {
              angular.extend(this.errors, {
                panels_remote_key: ["This field is a max of 16 characters"],
              }); //TODO: Lang these
            }
            if (this.requiresSerialNumber()) {
              // Require Serial number on persistent
              if (
                isUndefinedOrNull(this.panels[this.panel_index].serial_number)
              ) {
                angular.extend(this.errors, {
                  panels_serial_number: ["This field is required"],
                }); //TODO: Lang these
              }
            }
            if (this.panels[this.panel_index].comm_type == "network") {
              //var ip = //;
              if (
                isUndefinedOrNull(this.panels[this.panel_index].comm_address)
              ) {
                angular.extend(this.errors, {
                  panels_comm_address: ["This field is required"],
                }); //TODO: Lang these
              }
            }
            if (this.requiresDeviceId()) {
              if (
                isUndefinedOrNull(this.panels[this.panel_index].comm_address) &&
                isUndefinedOrNull(this.panels[this.panel_index].sim_identifier)
              ) {
                angular.extend(this.errors, {
                  panels_sim_identifier: [
                    "Phone number or MEID/ICCID number is required",
                  ],
                });
              }
            }
          }
        },
        /**
         * @ngdoc object
         * @name method:update
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function calls the 'update' method of the Control System Service/API and returns the updated control system
         * @param {panel_is_being_replaced} Boolean that will appent the panel_is_being_repaced parameter to the body and tell scapi not to connect to the panel automatically ssp-3262
         *
         * @return {promise} The promise from the Control Systems API for the updated control system.
         */
        update: function (panel_is_being_replaced) {
          var deferred = $q.defer();
          var _this = this;
          ControlSystemService.update(
            this.customer_id,
            this.control_system_id,
            {
              ...this.toJson(),
              panel_is_being_replaced: panel_is_being_replaced,
            }
          )
            .then(
              function (data) {
                _this
                  .getExistingControlSystem()
                  .then(
                    function (success) {
                      deferred.resolve();
                    },
                    function (error) {
                      deferred.reject(error);
                    },
                    function (info) {
                      deferred.notify(info);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function (error) {
                _this.handleError(error);
                deferred.reject(error);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },
        /**
         * @ngdoc object
         * @name method:create
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function calls the 'create' method of the Control System Service/API and returns newly created control system
         *
         * @return {promise} The promise from the Control Systems API for the new control system.
         */
        create: function () {
          var deferred = $q.defer();
          var _this = this;

          ControlSystemService.create(this.customer_id, this.toJson())
            .then(
              function (success) {
                angular.extend(_this, success.control_system);
                _this.control_system_id = success.control_system.id;
                _this.isNew = false;
                // After the save, we need to get OUR custom formatted version of the control_system object.
                _this
                  .getExistingControlSystem()
                  .then(
                    function () {
                      deferred.resolve(_this);
                    },
                    function (error) {
                      deferred.resolve(_this);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function (error) {
                _this.handleError(error);
                deferred.reject(error);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },
        /**
         * @ngdoc object
         * @name method:destroy
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function calls the 'destroy' method of the Control System Service/API
         *
         * @return {promise} The promise from the Control Systems API to delete a control system.
         */
        destroy: function () {
          var deferred = $q.defer();
          var _this = this;
          ControlSystemService.destroy(this.id)
            .then(
              function (success) {
                _this.isNew = false;
                deferred.resolve(_this);
              },
              function (error) {
                _this.handleError(error);
                deferred.reject(error);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },
        /**
         * @ngdoc object
         * @name method:toJson
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * Copies and updates the JSON structure of the control system in preparation for the update or create API.
         *
         * @return {Object} A JSON object ready for the create or update body of the API calls.
         */
        toJson: function () {
          const json = {};

          // Checking arming_system property or last_command_at property for XF6 which will be null if panel has never connected to
          // SCAPI server to report proper hardware model.
          const initialized =
            this.panels[0].hardware_family === "XF6"
              ? this.panels[0].last_command_at !== null
              : this.panels[0].arming_system !== null;
          json.control_system = angular.copy(this); //creates outer container and copies the current control system to JSON object
          //The API does not accept empty arrays, so we test before creating the new structure

          delete json.control_system.services_manager.basic_video_enabled;
          delete json.control_system.services_manager.premium_video_enabled;
          delete json.control_system.services_manager
            .four_additional_video_channels_enabled;

          if (
            json.control_system.panels[json.control_system.panel_index]
              .tracked_outputs.length
          ) {
            //'tracked_outputs' must be changed to 'tracked_outputs_attributes'
            json.control_system.panels[
              json.control_system.panel_index
            ].tracked_outputs_attributes =
              json.control_system.panels[
                json.control_system.panel_index
              ].tracked_outputs;
          }
          if (
            json.control_system.panels[json.control_system.panel_index]
              .device_informations.length
          ) {
            //'doors' must be changed to 'device_informations_attributes'
            json.control_system.panels[
              json.control_system.panel_index
            ].device_informations_attributes =
              json.control_system.panels[
                json.control_system.panel_index
              ].device_informations;
            // Get rid of device734 if it exists, since we don't need to send this back to the update
            angular.forEach(
              json.control_system.panels[json.control_system.panel_index]
                .device_informations_attributes,
              function (device) {
                if (device.device734) delete device.device734;
              }
            );
          }
          //sensor_activity_zones_attributes can only contain 'tracked' zones.  Looks thru 'sensor_activity_zones' for
          //  zones that have been unselected or newly selected and add them to the array.
          if (
            json.control_system.panels[json.control_system.panel_index]
              .sensor_activity_zones.length
          ) {
            json.control_system.panels[
              json.control_system.panel_index
            ].sensor_activity_zones_attributes = [];
            angular.forEach(
              json.control_system.panels[json.control_system.panel_index]
                .sensor_activity_zones,
              function (zone) {
                if (!zone.tracked && zone.id != null) {
                  //remove previously selected zone
                  zone._destroy = true;
                  delete zone.tracked;
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].sensor_activity_zones_attributes.push(zone);
                }
                if (zone.tracked && zone.id == null) {
                  //add newly selected zones
                  delete zone.tracked;
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].sensor_activity_zones_attributes.push(zone);
                }
              }
            );
            //if sensor_activity_zones_attributes turns out to be empty, then just remove it.
            if (
              !json.control_system.panels[json.control_system.panel_index]
                .sensor_activity_zones_attributes.length
            ) {
              delete json.control_system.panels[json.control_system.panel_index]
                .sensor_activity_zones_attributes;
            }
          }
          //Updating the panel 'Name' field so we can pull the programming type back out if we need it.
          json.control_system.panels[json.control_system.panel_index].name =
            json.control_system.panels[json.control_system.panel_index]
              .programming_type +
            " " +
            json.control_system.panels[json.control_system.panel_index]
              .account_prefix +
            "-" +
            json.control_system.panels[json.control_system.panel_index]
              .account_number;
          if (
            json.control_system.panels[json.control_system.panel_index]
              .comm_address
          ) {
            json.control_system.panels[
              json.control_system.panel_index
            ].comm_address = json.control_system.panels[
              json.control_system.panel_index
            ].comm_address
              .toString()
              .toUpperCase();
          }
          if (
            json.control_system.panels[json.control_system.panel_index]
              .remote_key
          ) {
            json.control_system.panels[
              json.control_system.panel_index
            ].remote_key = json.control_system.panels[
              json.control_system.panel_index
            ].remote_key
              .toString()
              .toUpperCase();
          }
          delete json.control_system.panels[json.control_system.panel_index]
            .device_informations; //remove originals
          delete json.control_system.panels[json.control_system.panel_index]
            .sensor_activity_zones; //remove originals
          delete json.control_system.panels[json.control_system.panel_index]
            .tracked_outputs; //remove originals
          delete json.control_system.panels[json.control_system.panel_index]
            .connection_status;
          delete json.control_system.panels[json.control_system.panel_index]
            .description;
          delete json.control_system.panels[json.control_system.panel_index]
            .features;
          delete json.control_system.panels[json.control_system.panel_index]
            .last_command;
          delete json.control_system.panels[json.control_system.panel_index]
            .last_command_at;
          delete json.control_system.panels[json.control_system.panel_index]
            .last_command_status;
          delete json.control_system.panels[json.control_system.panel_index]
            .last_connected_at;
          delete json.control_system.panels[json.control_system.panel_index]
            .last_disconnected_at;
          delete json.control_system.panels[json.control_system.panel_index]
            .last_hold_at;
          delete json.control_system.panels[json.control_system.panel_index]
            .software_date;
          delete json.control_system.panels[json.control_system.panel_index]
            .cell_modem_firmware;
          delete json.control_system.panels[json.control_system.panel_index]
            .cell_modem_model;
          delete json.control_system.panels[json.control_system.panel_index]
            .last_alarm_at;
          // Will insert approximate model based on 'programming_type' instead of the default 'XT50' if panel has not made initial connection.
          // Otherwise, remove hardware_model to prevent updating it.
          if (!initialized) {
            const isInternationalDealer =
              UserService.enabledDistributionSubscriber();
            switch (
              json.control_system.panels[json.control_system.panel_index]
                .programming_type
            ) {
              case "XT75":
                if (
                  +json.control_system.panels[json.control_system.panel_index]
                    .software_version < 241
                ) {
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].software_version = isInternationalDealer ? "741" : "241";
                }
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "XT75";
                break;
              case "DualCom":
                if (
                  +json.control_system.panels[json.control_system.panel_index]
                    .software_version < 183
                ) {
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].software_version = isInternationalDealer ? "741" : "183";
                }
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "DualCom";
                break;
              case "MiniCellCom":
                if (
                  +json.control_system.panels[json.control_system.panel_index]
                    .software_version < 172
                ) {
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].software_version = isInternationalDealer ? "741" : "172";
                }
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "MiniCellCom";
                break;
              case "CellComSL":
                if (
                  +json.control_system.panels[json.control_system.panel_index]
                    .software_version < 116
                ) {
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].software_version = isInternationalDealer ? "741" : "116"; //min software version required for proper cell connection.
                }
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "CellComSL";
                break;
              case "CellComEX":
                if (
                  +json.control_system.panels[json.control_system.panel_index]
                    .software_version < 221
                ) {
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].software_version = isInternationalDealer ? "741" : "221"; //min software version required for proper cell connection.
                }
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "CellComEX";
                break;
              case "iComSL":
                if (
                  +json.control_system.panels[json.control_system.panel_index]
                    .software_version < 123
                ) {
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].software_version = isInternationalDealer ? "741" : "123";
                }
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "iComSL";
                break;
              case "iComLNC":
                if (
                  +json.control_system.panels[json.control_system.panel_index]
                    .software_version < 172
                ) {
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].software_version = isInternationalDealer ? "741" : "172";
                }
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "iComLNC";
                break;
              case "XTLP":
                if (
                  +json.control_system.panels[json.control_system.panel_index]
                    .software_version < 124
                ) {
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].software_version = isInternationalDealer ? "741" : "124"; //min software version required for proper initial connection.
                }
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "XTLP";
                break;
              case "XTL":
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "XTL";
                break;
              case "XR150":
              case "XR550":
              case "XT30":
              case "XT50":
                // Do nothing. Hardware model was set in ControlSystemCtrl.selectedType();
                if (
                  +json.control_system.panels[json.control_system.panel_index]
                    .software_version < 113
                ) {
                  json.control_system.panels[
                    json.control_system.panel_index
                  ].software_version = isInternationalDealer ? "741" : "113";
                }
                break;
              case "TMS6":
                json.control_system.panels[
                  json.control_system.panel_index
                ].software_version = isInternationalDealer ? "741" : "241";

                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "TMS6";
                break;
              case "XR500":
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "XR500";
                break;
              case "Video Only":
                json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model = "Video Only";
                json.control_system.panels[
                  json.control_system.panel_index
                ].name = null;
                json.control_system.panels[
                  json.control_system.panel_index
                ].comm_type = null;
                json.control_system.panels[
                  json.control_system.panel_index
                ].comm_address = null;
                // json.control_system.panels[json.control_system.panel_index].account_prefix = null;
                // json.control_system.panels[json.control_system.panel_index].account_number = null;
                delete json.control_system.panels[
                  json.control_system.panel_index
                ].special_panel;
                break;
              case "XF6":
                // Do nothing
                break;
              default:
                delete json.control_system.panels[
                  json.control_system.panel_index
                ].hardware_model;
                break;
            }
          } else {
            delete json.control_system.panels[json.control_system.panel_index]
              .hardware_model;
          }
          delete json.control_system.panels[json.control_system.panel_index]
            .arming_system;
          delete json.control_system.panels[json.control_system.panel_index]
            .hardware_family;
          delete json.control_system.panels[json.control_system.panel_index]
            .programming_type;
          json.control_system.panels_attributes = json.control_system.panels; //rename
          json.control_system.services_manager_attributes =
            json.control_system.services_manager; //rename
          delete json.control_system.services_manager_attributes
            .full_app_enabled_at;
          if (
            !json.control_system.services_manager_attributes.full_app_enabled
          ) {
            //if 'full_app_enabled' is disabled, shut off everything else
            angular.forEach(
              json.control_system.services_manager_attributes,
              function (value, key, obj) {
                // Ignore a couple service manager attributes. Regardless of full_app_enabled, leave store_user_codes alone.
                // It's technically a dealer feature, not an app feature.
                if (
                  typeof value === "boolean" &&
                  [
                    "arming_app_enabled",
                    "store_user_codes",
                    "competitor_upgrade_program",
                  ].indexOf(key) === -1
                ) {
                  if (
                    json.control_system.panels_attributes[0].hardware_model ===
                      "Video Only" &&
                    [
                      "hikvision_doorbell_enabled",
                      "open_eye_enabled",
                      "standard_hikvision_nvr_enabled",
                      "eagle_eye_enabled",
                      "ring_enabled",
                    ].indexOf(key) > -1
                  ) {
                  } else {
                    obj[key] = false;
                  }
                }
              }
            );
          }
          //country_code and remote_key can not be 'null'
          if (
            json.control_system.panels[json.control_system.panel_index]
              .country_code == null
          )
            json.control_system.panels[
              json.control_system.panel_index
            ].country_code = "";
          if (
            json.control_system.panels[json.control_system.panel_index]
              .remote_key == null
          )
            json.control_system.panels[
              json.control_system.panel_index
            ].remote_key = "";
          delete json.control_system.panels; //remove originals
          delete json.control_system.services_manager; //remove originals
          delete json.control_system.qualifiesForProgArchive;
          return json;
        },
        /**
         * @ngdoc object
         * @name method:addOutput
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * Adds an empty 'tracked output' to the model
         */
        addOutput: function () {
          this.panels[this.panel_index].tracked_outputs.push({
            name: "",
            number: "",
            _destroy: false,
          });
        },
        /**
         * @ngdoc object
         * @name method:deleteOutput
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * Marks an output to be destroyed.
         */
        deleteOutput: function (outputNumber) {
          angular.forEach(
            this.panels[this.panel_index].tracked_outputs,
            function (output) {
              if (output.number == outputNumber && !output._destroy) {
                output._destroy = true;
              }
            }
          );
        },
        /**
         * @ngdoc object
         * @name method:handleError
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function creates an array of validation errors and appends an 'errors' property to the base object.
         *
         * @param {Object} errorData The error object returned from a create or update function call
         */
        handleError: function (errorData) {
          if (angular.isUndefined(this.errors)) {
            this.errors = {};
          }
          if (
            errorData &&
            errorData.data &&
            errorData.data.errors &&
            errorData.config &&
            errorData.config.data
          ) {
            var errorText = angular.toJson(errorData.data.errors);
            //The validation process can not understand the 'panel.field_name' syntax, so this converts the '.' to '_'.
            errorText = errorText.replace(/panels\./gi, "panels_");
            errorText = errorText.replace(
              /panels_tracked_outputs\./gi,
              "panels_tracked_outputs_"
            );
            angular.extend(this.errors, angular.fromJson(errorText));
          } else {
            this.clearErrors();
          }
        },
        /**
         * @ngdoc object
         * @name method:clearErrors
         * @methodOf App.factory:controlSystemModel
         *
         * @description
         * This function removes the 'errors' collection from the base object.
         */
        clearErrors: function () {
          if (this.errors) {
            delete this.errors;
          }
        },
        /**
         * Function to extend _this_ with additional/new attributes
         */
        extendMe: function (userObject) {
          angular.extend(this, userObject);
        },
      });

      var _this = this;
      // This 'constructor' allows you to create a new controlSystemModel either by passing in a json object of
      // control system data, or a key/pair of customerID and controlSystemID
      if (
        controlSystem &&
        typeof controlSystem == "object" &&
        controlSystem.id
      ) {
        this.extendMe(controlSystem);
      }
    };
    return controlSystemModel;
  },
]);
