import { IconButtonThatLooksLikeLink } from "components/DaStyledElements/ClickableElements";
import React from "react";
import TagsSettingsTable from "./TagsSettingsTable";

interface TagsSettingsProps {
  dealerId: number;
  state: any;
  securityCommandEnabled: boolean;
  isSuperUser: boolean;
}
function TagsSettings(props: TagsSettingsProps) {
  const { state } = props;
  return props.securityCommandEnabled ? (
    <>
      <div className="row">
        <div className="sub-header">
          <div className="sub-header__left">
            <div className="sub-header__title">Tags</div>
            {!props.isSuperUser ? (
              <IconButtonThatLooksLikeLink
                className="btn btn-sm btn-add ml"
                onClick={() =>
                  state.go("app.dealer.tag-create", {
                    tag_id: "0",
                  })
                }
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <TagsSettingsTable
          {...props}
          billingType={"non-billing"}
          isSuperUser={props.isSuperUser}
        />
      </div>
      <div className="row">
        <div className="sub-header">
          <div className="sub-header__left">
            <div className="sub-header__title">Billing Tags</div>
            {props.isSuperUser && (
              <IconButtonThatLooksLikeLink
                className="btn btn-sm btn-add ml"
                onClick={() =>
                  state.go("app.dealer.tag-create", {
                    tag_id: "0",
                  })
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <TagsSettingsTable
          {...props}
          billingType={"billing"}
          isSuperUser={props.isSuperUser}
        />
      </div>
    </>
  ) : (
    <>
      <div className="row">
        <div className="sub-header">
          <div className="sub-header__left">
            <div className="sub-header__title">Tags</div>
            {!props.isSuperUser ? (
              <IconButtonThatLooksLikeLink
                className="btn btn-sm btn-add ml"
                onClick={() =>
                  state.go("app.dealer.tag-create", {
                    tag_id: "0",
                  })
                }
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <TagsSettingsTable {...props} billingType={""} />
      </div>
    </>
  );
}

export default TagsSettings;
