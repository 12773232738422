import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useIntl } from "react-intl";
import { useFragment, useMutation } from "react-relay/hooks";
import { ArmedStatusEnum, BadZonesOption } from "securecom-graphql/client";
import { seconds } from "../../../utils/dates";
import {
  AllLargeIcon,
  ArmedSolidShieldLargeIcon,
  BurglaryIcon,
  PerimeterLargeIcon,
} from "../Icons";
import { AreaButtonIcon } from "./AreaButtonIcon";
import Area from "./BasicArmingArea";
import AreaButton from "./BasicArmingAreaButton";
import AreaButtonContainer from "./BasicArmingAreaButtonContainer";
import AreaButtonLabel from "./BasicArmingAreaButtonLabel";
import Areas from "./BasicArmingAreas";
import ShieldWrap from "./BasicArmingShieldWrap";
import Wrapper from "./BasicArmingWrapper";
import messages from "./messages";
import { AllPerimeterArmingArmAllMutation } from "./__generated__/AllPerimeterArmingArmAllMutation.graphql";
import { AllPerimeterArmingArmPerimeterMutation } from "./__generated__/AllPerimeterArmingArmPerimeterMutation.graphql";
import { AllPerimeterArmingDisarmMutation } from "./__generated__/AllPerimeterArmingDisarmMutation.graphql";
import { AllPerimeterArming_controlSystem$key } from "./__generated__/AllPerimeterArming_controlSystem.graphql";

enum ArmingType {
  ALL,
  PERIMETER,
  NONE,
}

const AllPerimeterArming = (props: {
  armingIsEnabled?: boolean;
  disarmingIsEnabled?: boolean;
  controlSystem: AllPerimeterArming_controlSystem$key;
  setStatusMessage: Function;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setBadZonesMethod: Function;
}) => {
  const system = useFragment(
    graphql`
      fragment AllPerimeterArming_controlSystem on ControlSystem {
        id
        name
        isArmed
        isInAlarm
        armedStatus
        areas(sort: { keys: ["number"], order: ASC }) {
          nodes {
            id
            name
            armedStatus
            isInAlarm
            number
          }
        }
      }
    `,
    props.controlSystem
  );

  const [disarmAllAreas, isDisarmingAllAreas] =
    useMutation<AllPerimeterArmingDisarmMutation>(graphql`
      mutation AllPerimeterArmingDisarmMutation($systemId: ID!) {
        disarmAllAreas(systemId: $systemId) {
          ... on MultipleAreasDisarmedSuccessPayload {
            controlSystem {
              armedStatus
              ...AllPerimeterArming_controlSystem
            }
          }
          ... on Error {
            type
          }
        }
      }
    `);
  const [armPerimeter, isArmingPerimeter] =
    useMutation<AllPerimeterArmingArmPerimeterMutation>(graphql`
      mutation AllPerimeterArmingArmPerimeterMutation(
        $systemId: ID!
        $badZonesOption: BadZonesOption
        $armInstant: Boolean
      ) {
        armPerimeter(
          systemId: $systemId
          badZonesOption: $badZonesOption
          armInstant: $armInstant
        ) {
          ... on MultipleAreasArmedSuccessPayload {
            controlSystem {
              armedStatus
              ...AllPerimeterArming_controlSystem
            }
          }
          ... on BadZonesError {
            badZones {
              name
              number
            }
          }
          ... on Error {
            type
          }
        }
      }
    `);
  const [armAll, isArmingAll] =
    useMutation<AllPerimeterArmingArmAllMutation>(graphql`
      mutation AllPerimeterArmingArmAllMutation(
        $systemId: ID!
        $badZonesOption: BadZonesOption
      ) {
        armAll(systemId: $systemId, badZonesOption: $badZonesOption) {
          ... on MultipleAreasArmedSuccessPayload {
            controlSystem {
              armedStatus
              ...AllPerimeterArming_controlSystem
            }
          }
          ... on BadZonesError {
            badZones {
              name
              number
            }
          }
          ... on Error {
            type
          }
        }
      }
    `);
  const intl = useIntl();
  const armedStatusMessage = {
    ...messages.statusMessage,
    values: {
      status: intl.formatMessage(messages.armed).toLowerCase(),
    },
  };
  const disarmedStatusMessage = {
    ...messages.statusMessage,
    values: {
      status: intl.formatMessage(messages.disarmed).toLowerCase(),
    },
  };
  const armingPerimeterMessage = {
    ...messages.armingArea,
    values: {
      area: intl.formatMessage(messages.perimeter).toLowerCase(),
    },
  };
  const disarmActionMessage = {
    ...messages.clickInstructions,
    values: {
      instruction: intl.formatMessage(messages.disarm).toLowerCase(),
    },
  };
  const armingPerimeterInstantMessage = {
    ...messages.armingAreaInstant,
    values: {
      area: intl.formatMessage(messages.perimeter).toLowerCase(),
    },
  };
  const armingAllStatusMessage = {
    ...messages.armingArea,
    values: {
      area: intl.formatMessage(messages.all).toLowerCase(),
    },
  };
  let armPerimeterInstantlyTimeout: undefined | number = undefined;

  const armPerimeterInstantly = (badZonesOption = BadZonesOption.REFUSE) => {
    props.setIsLoading(true);
    props.setStatusMessage(armingPerimeterInstantMessage);
    armPerimeter({
      variables: {
        systemId: system.id,
        armInstant: true,
        badZonesOption: badZonesOption,
      },
      optimisticUpdater: (store) => {
        const systemRecord = store.get(system.id);
        systemRecord.setValue(ArmedStatusEnum.ARMING_PERIMETER, "armedStatus");
      },
      onCompleted: (response) => {
        if (
          response.armPerimeter?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_ALL ||
          response.armPerimeter?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_PERIMETER
        ) {
          props.setStatusMessage(armedStatusMessage);
        } else if (response.armPerimeter?.badZones) {
          props.setBadZonesMethod({
            badZones: response.armPerimeter.badZones,
            executeMutation: (badZonesOptionRetry: BadZonesOption) => {
              armPerimeterInstantly(badZonesOptionRetry);
            },
          });
        } else {
          props.setStatusMessage(disarmedStatusMessage);
        }
        props.setIsLoading(false);
      },
      onError: () => {
        props.setStatusMessage(disarmedStatusMessage);
        props.setIsLoading(false);
      },
    });
  };

  const handleAreaClick = (
    armingType: ArmingType,
    badZonesOption = BadZonesOption.REFUSE
  ) => {
    clearTimeout(armPerimeterInstantlyTimeout);
    if (!system.isArmed && armingType === ArmingType.ALL) {
      props.setStatusMessage(armingAllStatusMessage);
      props.setIsLoading(true);
      armAll({
        variables: {
          systemId: system.id,
          badZonesOption: badZonesOption,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          systemRecord.setValue(ArmedStatusEnum.ARMING_ALL, "armedStatus");
        },
        onCompleted: (response) => {
          if (
            response.armAll?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_ALL ||
            response.armAll?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_PERIMETER
          ) {
            props.setStatusMessage(armedStatusMessage);
          } else if (response.armAll?.badZones) {
            props.setBadZonesMethod({
              badZones: response.armAll.badZones,
              executeMutation: (badZonesOption: BadZonesOption) => {
                handleAreaClick(ArmingType.ALL, badZonesOption);
              },
            });
          } else {
            props.setStatusMessage(disarmedStatusMessage);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(disarmedStatusMessage);
          props.setIsLoading(false);
        },
      });
    } else if (!system.isArmed && armingType === ArmingType.PERIMETER) {
      props.setIsLoading(true);
      props.setStatusMessage(armingPerimeterMessage);
      armPerimeter({
        variables: {
          systemId: system.id,
          badZonesOption: badZonesOption,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          systemRecord.setValue(
            ArmedStatusEnum.ARMING_PERIMETER,
            "armedStatus"
          );
        },
        onCompleted: (response) => {
          if (
            response.armPerimeter?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_ALL ||
            response.armPerimeter?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_PERIMETER
          ) {
            props.setStatusMessage(armedStatusMessage);
          } else if (response.armPerimeter?.badZones) {
            props.setBadZonesMethod({
              badZones: response.armPerimeter.badZones,
              executeMutation: (badZonesOptionRetry: BadZonesOption) => {
                handleAreaClick(ArmingType.PERIMETER, badZonesOptionRetry);
              },
            });
          } else {
            props.setStatusMessage(disarmedStatusMessage);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(disarmedStatusMessage);
          props.setIsLoading(false);
        },
      });
    } else {
      props.setIsLoading(true);
      props.setStatusMessage(messages.disarming);
      disarmAllAreas({
        variables: {
          systemId: system.id,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          const currentStatus = systemRecord.getValue("armedStatus");
          if (currentStatus === ArmedStatusEnum.ARMED_PERIMETER) {
            systemRecord.setValue(
              ArmedStatusEnum.DISARMING_PERIMETER,
              "armedStatus"
            );
          } else if (currentStatus === ArmedStatusEnum.ARMED_ALL) {
            systemRecord.setValue(ArmedStatusEnum.DISARMING_ALL, "armedStatus");
          } else {
            systemRecord.setValue(ArmedStatusEnum.DISARMING, "armedStatus");
          }
        },
        onCompleted: (response) => {
          if (
            response.disarmAllAreas?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_ALL ||
            response.disarmAllAreas?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_PERIMETER
          ) {
            props.setStatusMessage(armedStatusMessage);
          } else {
            props.setStatusMessage(disarmedStatusMessage);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(armedStatusMessage);
          props.setIsLoading(false);
        },
      });
    }
  };

  const handleArmAllMouseEnter = () => {
    if (system.armedStatus === ArmedStatusEnum.ARMED_ALL) {
      props.setStatusMessage(disarmActionMessage);
    } else {
      props.setStatusMessage({
        ...messages.clickInstructions,
        values: {
          instruction: intl.formatMessage(messages.arm).toLowerCase(),
        },
      });
    }
  };

  const handleArmPerimeterMouseEnter = () => {
    if (system.armedStatus === ArmedStatusEnum.ARMED_PERIMETER) {
      props.setStatusMessage(disarmActionMessage);
    } else {
      props.setStatusMessage({
        ...messages.clickInstructions,
        values: {
          instruction: `arm. ${intl.formatMessage(messages.holdForInstant)}`,
        },
      });
    }
  };
  const handleAreaMouseLeave = () => {
    if (isDisarmingAllAreas || isArmingAll || isArmingPerimeter) return;
    if (system.isArmed) {
      props.setStatusMessage(armedStatusMessage);
    } else if (!system.isArmed) {
      props.setStatusMessage(disarmedStatusMessage);
    }
  };

  const armAllIsActive =
    system.armedStatus === ArmedStatusEnum.ARMED_ALL ||
    system.armedStatus === ArmedStatusEnum.ARMING_ALL ||
    system.armedStatus === ArmedStatusEnum.DISARMING_ALL ||
    isArmingAll;
  const armPerimeterIsActive =
    system.armedStatus === ArmedStatusEnum.ARMED_PERIMETER ||
    system.armedStatus === ArmedStatusEnum.ARMING_PERIMETER ||
    system.armedStatus === ArmedStatusEnum.DISARMING_PERIMETER ||
    isArmingPerimeter;

  return (
    <Wrapper>
      <Areas>
        <Area
          key={1}
          index={0}
          count={2}
          active={armAllIsActive}
          inactive={armPerimeterIsActive}
        >
          <AreaButton
            arming={isArmingAll}
            disarming={isDisarmingAllAreas}
            armed={system.armedStatus === ArmedStatusEnum.ARMED_ALL}
            onClick={() => handleAreaClick(ArmingType.ALL)}
            disabled={
              isArmingAll ||
              isArmingPerimeter ||
              isDisarmingAllAreas ||
              (system.armedStatus === ArmedStatusEnum.DISARMED &&
                !props.armingIsEnabled) ||
              ((system.armedStatus === ArmedStatusEnum.ARMED_ALL ||
                system.armedStatus === ArmedStatusEnum.ARMED_PERIMETER) &&
                !props.disarmingIsEnabled)
            }
            onMouseEnter={handleArmAllMouseEnter}
            onMouseLeave={handleAreaMouseLeave}
            burgAlarm={system.isInAlarm}
          >
            <AreaButtonContainer>
              <AreaButtonIcon
                armed={system.armedStatus === ArmedStatusEnum.ARMED_ALL}
                burgAlarm={system.isInAlarm}
              >
                {system.isInAlarm ? <BurglaryIcon /> : <AllLargeIcon />}
              </AreaButtonIcon>
              {(system.armedStatus === ArmedStatusEnum.DISARMED ||
                system.armedStatus === ArmedStatusEnum.ARMING_ALL) && (
                <AreaButtonLabel armed={false}>All</AreaButtonLabel>
              )}
              <ShieldWrap>
                {(system.armedStatus === ArmedStatusEnum.DISARMING_ALL ||
                  system.armedStatus === ArmedStatusEnum.ARMED_ALL) && (
                  <ArmedSolidShieldLargeIcon valign="top" />
                )}
              </ShieldWrap>
            </AreaButtonContainer>
          </AreaButton>
        </Area>
        <Area
          key={2}
          index={1}
          count={2}
          active={armPerimeterIsActive}
          inactive={armAllIsActive}
        >
          <AreaButton
            arming={isArmingPerimeter}
            disarming={isDisarmingAllAreas}
            armed={system.armedStatus === ArmedStatusEnum.ARMED_PERIMETER}
            onClick={() => handleAreaClick(ArmingType.PERIMETER)}
            disabled={
              isArmingPerimeter ||
              isArmingAll ||
              isDisarmingAllAreas ||
              (system.armedStatus === ArmedStatusEnum.DISARMED &&
                !props.armingIsEnabled) ||
              ((system.armedStatus === ArmedStatusEnum.ARMED_ALL ||
                system.armedStatus === ArmedStatusEnum.ARMED_PERIMETER) &&
                !props.disarmingIsEnabled)
            }
            onMouseDown={() => {
              if (system.isArmed) return;

              if (armPerimeterInstantlyTimeout !== undefined) {
                clearTimeout(armPerimeterInstantlyTimeout);
              }

              armPerimeterInstantlyTimeout = setTimeout(
                armPerimeterInstantly,
                seconds(1)
              );
            }}
            onMouseEnter={handleArmPerimeterMouseEnter}
            onMouseLeave={handleAreaMouseLeave}
            burgAlarm={system.isInAlarm}
          >
            <AreaButtonContainer>
              <AreaButtonIcon
                armed={system.armedStatus === ArmedStatusEnum.ARMED_PERIMETER}
                burgAlarm={system.isInAlarm}
              >
                {system.isInAlarm ? <BurglaryIcon /> : <PerimeterLargeIcon />}
              </AreaButtonIcon>
              {(system.armedStatus === ArmedStatusEnum.DISARMED ||
                system.armedStatus === ArmedStatusEnum.ARMING_PERIMETER) && (
                <AreaButtonLabel armed={false}>Perimeter</AreaButtonLabel>
              )}
              <ShieldWrap>
                {(system.armedStatus === ArmedStatusEnum.ARMED_PERIMETER ||
                  system.armedStatus ===
                    ArmedStatusEnum.DISARMING_PERIMETER) && (
                  <ArmedSolidShieldLargeIcon valign="top" />
                )}
              </ShieldWrap>
            </AreaButtonContainer>
          </AreaButton>
        </Area>
      </Areas>
    </Wrapper>
  );
};

export default AllPerimeterArming;
