/**
 * @generated SignedSource<<00c72818a6b263a06777a76fb2177204>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationLocationField_zone$data = {
  readonly isECP: boolean;
  readonly location: string | null;
  readonly number: string;
  readonly " $fragmentType": "ZoneInformationLocationField_zone";
};
export type ZoneInformationLocationField_zone$key = {
  readonly " $data"?: ZoneInformationLocationField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationLocationField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationLocationField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isECP",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "f3520aeeb9b827ebcba062a11c59cef6";

export default node;
