import graphql from "babel-plugin-relay/macro";
import { useSoftwareVersion } from "components/FullProgramming/common/PanelContext";
import SystemOptionsArmDisarmActivityDaysField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsArmDisarmActivityDaysField";
import SystemOptionsCelsiusField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCelsiusField";
import SystemOptionsClosingCheckField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingCheckField";
import SystemOptionsClosingCodeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingCodeField";
import { useSystemOptionsFragment } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsContext";
import SystemOptionsCrossZoneTimeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import SystemOptionsDetectWirelessJammingField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDetectWirelessJammingField";
import SystemOptionsDisplayTimeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDisplayTimeField";
import SystemOptionsEnableKeypadPanicKeysField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEnableKeypadPanicKeysField";
import SystemOptionsEntryDelay1Field from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import SystemOptionsEntryDelay2Field from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay2Field";
import SystemOptionsEolResistorValueField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEolResistorValueField";
import SystemOptionsExitDelayField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsExitDelayField";
import SystemOptionsHoursFromGMTField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import SystemOptionsIso2CountryCodeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsIso2CountryCodeField";
import SystemOptionsObserveDstField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsObserveDstField";
import SystemOptionsOccupiedPremisesField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsOccupiedPremisesField";
import SystemOptionsPowerFailDelayField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import SystemOptionsPrimaryProgrammingLanguageField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPrimaryProgrammingLanguageField";
import SystemOptionsPrimaryUserLanguageField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPrimaryUserLanguageField";
import SystemOptionsResetSwingerBypassField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import SystemOptionsSecondaryProgrammingLanguageField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSecondaryProgrammingLanguageField";
import SystemOptionsSecondaryUserLanguageField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSecondaryUserLanguageField";
import SystemOptionsSwingerBypassTripsField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import SystemOptionsSystemTypeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSystemTypeField";
import SystemOptionsTimeChangeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsTimeChangeField";
import SystemOptionsUseFalseAlarmQuestionField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsUseFalseAlarmQuestionField";
import SystemOptionsWeatherPostalCodeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherPostalCodeField";
import SystemOptionsWeatherZipCodeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import SystemOptionsWirelessAudiblesField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import SystemOptionsWirelessEncryption1100Field from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100Field";
import SystemOptionsWirelessEncryption1100PassphraseField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100PassphraseField";
import SystemOptionsWirelessHouseCodeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessHouseCodeField";
import SystemOptionsZoneActivityHoursField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsZoneActivityHoursField";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import React from "react";
import { XT30SystemOptions_systemOptions$key } from "./__generated__/XT30SystemOptions_systemOptions.graphql";

const XT30SystemOptions = () => {
  const [systemOptions] =
    useSystemOptionsFragment<XT30SystemOptions_systemOptions$key>(
      graphql`
        fragment XT30SystemOptions_systemOptions on SystemOptions {
          ... on XtSystemOptions {
            supportsEolResistorField
          }
        }
      `
    );
  const softwareVersion = useSoftwareVersion();
  return (
    <>
      <SystemOptionsSystemTypeField />
      <SystemOptionsClosingCodeField />
      <SystemOptionsClosingCheckField />
      <SystemOptionsEntryDelay1Field />
      <SystemOptionsEntryDelay2Field />
      <SystemOptionsExitDelayField />
      <SystemOptionsCrossZoneTimeField />
      <SystemOptionsPowerFailDelayField />
      <SystemOptionsSwingerBypassTripsField />
      <SystemOptionsResetSwingerBypassField />
      <SystemOptionsZoneActivityHoursField />
      <SystemOptionsArmDisarmActivityDaysField />
      <SystemOptionsTimeChangeField />
      {panelVersionGTOE(252, softwareVersion) ? (
        <SystemOptionsObserveDstField />
      ) : null}
      <SystemOptionsHoursFromGMTField />
      <SystemOptionsDisplayTimeField />
      <SystemOptionsWirelessHouseCodeField />
      <SystemOptionsDetectWirelessJammingField />
      <SystemOptionsWirelessAudiblesField />
      <SystemOptionsEnableKeypadPanicKeysField />
      <SystemOptionsOccupiedPremisesField />
      <SystemOptionsUseFalseAlarmQuestionField />
      {softwareVersion <= 599 && <SystemOptionsWeatherZipCodeField />}
      {softwareVersion >= 673 && (
        <>
          <SystemOptionsIso2CountryCodeField />
          <SystemOptionsWeatherPostalCodeField />
        </>
      )}
      {softwareVersion >= 683 && (
        <>
          <SystemOptionsPrimaryProgrammingLanguageField />
          <SystemOptionsSecondaryProgrammingLanguageField />
          {softwareVersion >= 693 && <SystemOptionsCelsiusField />}
          <SystemOptionsPrimaryUserLanguageField />
          <SystemOptionsSecondaryUserLanguageField />
        </>
      )}
      {((softwareVersion >= 183 && softwareVersion <= 599) ||
        softwareVersion >= 683) && (
        <>
          <SystemOptionsWirelessEncryption1100Field />
          <SystemOptionsWirelessEncryption1100PassphraseField />
        </>
      )}
      {systemOptions.supportsEolResistorField && softwareVersion >= 193 && (
        <SystemOptionsEolResistorValueField />
      )}
      {softwareVersion >= 193 && <SystemOptionsCelsiusField />}
    </>
  );
};

export default XT30SystemOptions;
