import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { systemOptionsBypassLimitFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsBypassLimitField";
import { systemOptionsCelsiusFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCelsiusField";
import { systemOptionsClosingWaitFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingWaitField";
import { systemOptionsCrossZoneTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import { systemOptionsDetectWirelessJammingFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDetectWirelessJammingField";
import { systemOptionsEnableKeypadPanicKeysFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEnableKeypadPanicKeysField";
import { systemOptionsEntryDelay1FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import { systemOptionsEntryDelay2FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay2Field";
import { systemOptionsHoursFromGMTFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import { systemOptionsInstantArmingFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsInstantArmingField";
import { systemOptionsKeypadArmedLedFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsKeypadArmedLedField";
import { systemOptionsLatchedSupervisoryZonesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsLatchedSupervisoryZonesField";
import { systemOptionsObserveDstFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsObserveDstField";
import { systemOptionsOccupiedPremisesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsOccupiedPremisesField";
import { systemOptionsPowerFailDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import { systemOptionsResetSwingerBypassFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import { systemOptionsSwingerBypassTripsFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import { systemOptionsSystemTypeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSystemTypeField";
import { systemOptionsTimeChangeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsTimeChangeField";
import { systemOptionsUseBuiltIn1100WirelessFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsUseBuiltIn1100WirelessField";
import { systemOptionsUseFalseAlarmQuestionFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsUseFalseAlarmQuestionField";
import { systemOptionsWeatherZipCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import { systemOptionsWirelessAudiblesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import { systemOptionsWirelessEncryption1100FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100Field";
import { systemOptionsWirelessEncryption1100PassphraseFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100PassphraseField";
import { systemOptionsWirelessHouseCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessHouseCodeField";
import { systemOptionsZoneActivityHoursFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsZoneActivityHoursField";
import { XT75SystemOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XT75FullProgramming/__generated__/XT75SystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getSystemOptionsState } from "../../../XT75FullProgramming/XT75SystemOptionsProgrammingConceptForm";
import { Xt75ProgrammingTemplateSystemOptionsInput } from "../Create/__generated__/XT75TemplateSaveMutation.graphql";
import { XT75SystemOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75SystemOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XT75SystemOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75SystemOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        systemOptions {
          id
          included
          systemType {
            included
            data
          }
          instantArming {
            included
            data
          }
          closingWait {
            included
            data
          }
          entryDelay1 {
            included
            data
          }
          entryDelay2 {
            included
            data
          }
          closingWait {
            included
            data
          }
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          resetSwingerBypass {
            included
            data
          }
          zoneActivityHours {
            included
            data
          }
          timeChange {
            included
            data
          }
          observeDst {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          houseCode {
            included
            data
          }
          detectJam {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          useBuiltIn1100Wireless {
            included
            data
          }
          enableKeypadPanicKeys {
            included
            data
          }
          occupiedPremises {
            included
            data
          }
          useFalseAlarmQuestion {
            included
            data
          }
          weatherZipCode {
            included
            data
          }
          wirelessEncryption1100 {
            included
            data
          }
          wirelessEncryption1100Passphrase {
            included
            data
          }
          celsius {
            included
            data
          }
          latchedSupervisoryZones {
            included
            data
          }
          bypassLimit {
            included
            data
          }
          keypadArmedLed {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XT75SystemOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): Xt75ProgrammingTemplateSystemOptionsInput => {
  const {
    panel: { systemOptions },
  } = getSystemOptionsState(controlSystem);

  const systemOptionsInputFields = {
    systemType: {
      included: includedFields.has(systemOptionsSystemTypeFieldId()),
      data: systemOptions.systemType,
    },
    instantArming: {
      included: includedFields.has(systemOptionsInstantArmingFieldId()),
      data: systemOptions.instantArming,
    },
    closingWait: {
      included: includedFields.has(systemOptionsClosingWaitFieldId()),
      data: systemOptions.closingWait,
    },
    entryDelay1: {
      included: includedFields.has(systemOptionsEntryDelay1FieldId()),
      data: systemOptions.entryDelay1,
    },
    entryDelay2: {
      included: includedFields.has(systemOptionsEntryDelay2FieldId()),
      data: systemOptions.entryDelay2,
    },
    latchedSupervisoryZones: {
      included: includedFields.has(
        systemOptionsLatchedSupervisoryZonesFieldId()
      ),
      data: systemOptions.latchedSupervisoryZones,
    },
    bypassLimit: {
      included: includedFields.has(systemOptionsBypassLimitFieldId()),
      data: systemOptions.bypassLimit,
    },
    keypadArmedLed: {
      included: includedFields.has(systemOptionsKeypadArmedLedFieldId()),
      data: systemOptions.keypadArmedLed,
    },
    crossZoneTime: {
      included: includedFields.has(systemOptionsCrossZoneTimeFieldId()),
      data: systemOptions.crossZoneTime,
    },
    powerFailDelay: {
      included: includedFields.has(systemOptionsPowerFailDelayFieldId()),
      data: systemOptions.powerFailDelay,
    },
    swingerBypassTrips: {
      included: includedFields.has(systemOptionsSwingerBypassTripsFieldId()),
      data: systemOptions.swingerBypassTrips,
    },
    resetSwingerBypass: {
      included: includedFields.has(systemOptionsResetSwingerBypassFieldId()),
      data: systemOptions.resetSwingerBypass,
    },
    zoneActivityHours: {
      included: includedFields.has(systemOptionsZoneActivityHoursFieldId()),
      data: systemOptions.zoneActivityHours,
    },
    timeChange: {
      included: includedFields.has(systemOptionsTimeChangeFieldId()),
      data: systemOptions.timeChange,
    },
    observeDst: {
      included: includedFields.has(systemOptionsObserveDstFieldId()),
      data: systemOptions.observeDst,
    },
    hoursFromGMT: {
      included: includedFields.has(systemOptionsHoursFromGMTFieldId()),
      data: systemOptions.hoursFromGMT,
    },
    houseCode: {
      included: includedFields.has(systemOptionsWirelessHouseCodeFieldId()),
      data: systemOptions.houseCode,
    },
    detectJam: {
      included: includedFields.has(systemOptionsDetectWirelessJammingFieldId()),
      data: systemOptions.detectJam,
    },
    wirelessAudibles: {
      included: includedFields.has(systemOptionsWirelessAudiblesFieldId()),
      data: systemOptions.wirelessAudibles,
    },
    useBuiltIn1100Wireless: {
      included: includedFields.has(
        systemOptionsUseBuiltIn1100WirelessFieldId()
      ),
      data: systemOptions.useBuiltIn1100Wireless,
    },
    enableKeypadPanicKeys: {
      included: includedFields.has(systemOptionsEnableKeypadPanicKeysFieldId()),
      data: systemOptions.enableKeypadPanicKeys,
    },
    occupiedPremises: {
      included: includedFields.has(systemOptionsOccupiedPremisesFieldId()),
      data: systemOptions.occupiedPremises,
    },
    useFalseAlarmQuestion: {
      included: includedFields.has(systemOptionsUseFalseAlarmQuestionFieldId()),
      data: systemOptions.useFalseAlarmQuestion,
    },
    weatherZipCode: {
      included: includedFields.has(systemOptionsWeatherZipCodeFieldId()),
      data: systemOptions.weatherZipCode,
    },
    wirelessEncryption1100: {
      included: includedFields.has(
        systemOptionsWirelessEncryption1100FieldId()
      ),
      data: systemOptions.wirelessEncryption1100,
    },
    wirelessEncryption1100Passphrase: {
      included: includedFields.has(
        systemOptionsWirelessEncryption1100PassphraseFieldId()
      ),
      data: systemOptions.wirelessEncryption1100Passphrase,
    },
    celsius: {
      included: includedFields.has(systemOptionsCelsiusFieldId()),
      data: systemOptions.celsius,
    },
  };

  const systemOptionsIncluded = Object.values(systemOptionsInputFields).some(
    (field) => field.included
  );

  return {
    included: systemOptionsIncluded,
    ...systemOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: XT75SystemOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { systemOptions } = getState(template);

  if (!systemOptions) return;

  if (systemOptions.systemType?.included) {
    setIncludedFields(setToggle(systemOptionsSystemTypeFieldId()));
  }
  if (systemOptions.closingWait?.included) {
    setIncludedFields(setToggle(systemOptionsClosingWaitFieldId()));
  }
  if (systemOptions.bypassLimit?.included) {
    setIncludedFields(setToggle(systemOptionsBypassLimitFieldId()));
  }
  if (systemOptions.instantArming?.included) {
    setIncludedFields(setToggle(systemOptionsInstantArmingFieldId()));
  }
  if (systemOptions.keypadArmedLed?.included) {
    setIncludedFields(setToggle(systemOptionsKeypadArmedLedFieldId()));
  }
  if (systemOptions.latchedSupervisoryZones?.included) {
    setIncludedFields(setToggle(systemOptionsLatchedSupervisoryZonesFieldId()));
  }
  if (systemOptions.entryDelay1?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay1FieldId()));
  }
  if (systemOptions.entryDelay2?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay2FieldId()));
  }
  if (systemOptions.crossZoneTime?.included) {
    setIncludedFields(setToggle(systemOptionsCrossZoneTimeFieldId()));
  }
  if (systemOptions.powerFailDelay?.included) {
    setIncludedFields(setToggle(systemOptionsPowerFailDelayFieldId()));
  }
  if (systemOptions.swingerBypassTrips?.included) {
    setIncludedFields(setToggle(systemOptionsSwingerBypassTripsFieldId()));
  }
  if (systemOptions.resetSwingerBypass?.included) {
    setIncludedFields(setToggle(systemOptionsResetSwingerBypassFieldId()));
  }
  if (systemOptions.zoneActivityHours?.included) {
    setIncludedFields(setToggle(systemOptionsZoneActivityHoursFieldId()));
  }
  if (systemOptions.timeChange?.included) {
    setIncludedFields(setToggle(systemOptionsTimeChangeFieldId()));
  }
  if (systemOptions.observeDst?.included) {
    setIncludedFields(setToggle(systemOptionsObserveDstFieldId()));
  }
  if (systemOptions.hoursFromGMT?.included) {
    setIncludedFields(setToggle(systemOptionsHoursFromGMTFieldId()));
  }
  if (systemOptions.houseCode?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessHouseCodeFieldId()));
  }
  if (systemOptions.detectJam?.included) {
    setIncludedFields(setToggle(systemOptionsDetectWirelessJammingFieldId()));
  }
  if (systemOptions.wirelessAudibles?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessAudiblesFieldId()));
  }
  if (systemOptions.useBuiltIn1100Wireless?.included) {
    setIncludedFields(setToggle(systemOptionsUseBuiltIn1100WirelessFieldId()));
  }
  if (systemOptions.enableKeypadPanicKeys?.included) {
    setIncludedFields(setToggle(systemOptionsEnableKeypadPanicKeysFieldId()));
  }
  if (systemOptions.occupiedPremises?.included) {
    setIncludedFields(setToggle(systemOptionsOccupiedPremisesFieldId()));
  }
  if (systemOptions.useFalseAlarmQuestion?.included) {
    setIncludedFields(setToggle(systemOptionsUseFalseAlarmQuestionFieldId()));
  }
  if (systemOptions.weatherZipCode?.included) {
    setIncludedFields(setToggle(systemOptionsWeatherZipCodeFieldId()));
  }
  if (systemOptions.wirelessEncryption1100?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessEncryption1100FieldId()));
  }
  if (systemOptions.wirelessEncryption1100Passphrase?.included) {
    setIncludedFields(
      setToggle(systemOptionsWirelessEncryption1100PassphraseFieldId())
    );
  }
  if (systemOptions.celsius?.included) {
    setIncludedFields(setToggle(systemOptionsCelsiusFieldId()));
  }
};
