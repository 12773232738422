import graphql from "babel-plugin-relay/macro";
import { sleep } from "common/utils/universal/promise";
import BigBlueAddButton from "components/BigBlueAddButton";
import { GenericPageFallback } from "components/GenericPageFallback";
import { AlertsContextProvider } from "contexts/AlertsContext";
import { pipe } from "fp-ts/function";
import * as React from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { asString, toDealerId } from "securecom-graphql/client";
import styled from "styled-components/macro";
import GlobalHolidayDatesModalForm from "./GlobalHolidayDatesModalForm";
import GlobalHolidayDatesTable from "./GlobalHolidayDatesTable";
import { GlobalHolidayDatesDealerQuery } from "./__generated__/GlobalHolidayDatesDealerQuery.graphql";

const suspendUntilAngularDataIsPresent = (data: any) => {
  const dataIsStillMissing = data === null || data === undefined;

  if (dataIsStillMissing) {
    throw sleep(50);
  }
};

export default function GlobalHolidayDatesRoot({
  RelayService,
  $stateParams,
  UserService,
  $rootScope,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <GlobalHolidayDates
            dealerId={$stateParams.dealer_id}
            userService={UserService}
          />
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}
function GlobalHolidayDates({
  dealerId,
  userService,
}: {
  dealerId: string;
  userService: any;
}) {
  suspendUntilAngularDataIsPresent(userService.user);

  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => setIsAddModalOpen(false);

  const data = useLazyLoadQuery<GlobalHolidayDatesDealerQuery>(
    graphql`
      query GlobalHolidayDatesDealerQuery($dealerId: ID!) {
        dealer(id: $dealerId) {
          vernaculars {
            original
            replacement
          }
          ...GlobalHolidayDatesTable_dealer
          ...GlobalHolidayDatesModalForm_dealer
        }
      }
    `,
    { dealerId: pipe(dealerId, toDealerId, asString) }
  );

  if (!data.dealer) {
    return null;
  }
  const customerVerns = data.dealer.vernaculars.find(
    (v) => v?.original === "customers"
  );
  const systemVerns = data.dealer.vernaculars.find(
    (v) => v?.original === "systems"
  );

  const helpFileLink = userService.enabledSecurityCommand()
    ? "https://securitycommand.dmp.support/sc/"
    : "https://dealeradmin.dmp.support/";

  return (
    <>
      <Row>
        <PageHeader>
          <PageHeaderLeft>
            <PageHeaderTitle>Create Global Holiday Dates</PageHeaderTitle>
            <BigBlueAddButton onClick={openAddModal} />
          </PageHeaderLeft>
          <SubHeader>
            Global holiday dates allow you to manage all your{" "}
            {customerVerns ? customerVerns.replacement : "customer's"} holiday
            dates from a single place. Anytime a change is made to a global
            holiday date, it will automatically be updated for all{" "}
            {systemVerns ? systemVerns.replacement : "systems"} subscribed to
            that date.{" "}
            <a href={helpFileLink} target="_blank" rel="noreferrer">
              Learn more
            </a>
          </SubHeader>
        </PageHeader>
      </Row>
      <Row>
        <GlobalHolidayDatesTable dealerId={dealerId} dealer={data.dealer} />
      </Row>
      {isAddModalOpen ? (
        <GlobalHolidayDatesModalForm
          closeModal={closeAddModal}
          isNew={true}
          dealerId={dealerId}
          fragmentRef={data.dealer}
        />
      ) : null}
    </>
  );
}
const SubHeader = styled.span`
  margin-top: 1rem;
`;
const Row = styled.div`
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  box-sizing: border-box;
  display: block;
  unicode-bidi: isolate;
`;
const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 0;
  padding: 1.6rem 0;
  border-bottom: 1px solid #cccccc;
  margin-bottom: var(--measure-3x);
  margin: 0 1.5rem;
`;
const PageHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const PageHeaderTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--measure-3x);
  line-height: 1;
  margin-right: 0.8rem;
`;
