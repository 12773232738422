import graphql from "babel-plugin-relay/macro";
import AdvancedFields from "components/AdvancedFields";
import { useSoftwareVersion } from "components/FullProgramming/common/PanelContext";
import ZoneInformationLocationField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationLocationField";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useFragment } from "react-relay";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import ZoneInformationAreaField from "../../common/ZoneInformationFields/ZoneInformationAreaField";
import ZoneInformationArmAreasField from "../../common/ZoneInformationFields/ZoneInformationArmAreasField";
import ZoneInformationArmedOpenMessageField from "../../common/ZoneInformationFields/ZoneInformationArmedOpenMessageField";
import ZoneInformationArmedOpenOutputActionField from "../../common/ZoneInformationFields/ZoneInformationArmedOpenOutputActionField";
import ZoneInformationArmedOpenOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationArmedOpenOutputNumberField";
import ZoneInformationArmedShortMessageField from "../../common/ZoneInformationFields/ZoneInformationArmedShortMessageField";
import ZoneInformationArmedShortOutputActionField from "../../common/ZoneInformationFields/ZoneInformationArmedShortOutputActionField";
import ZoneInformationArmedShortOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationArmedShortOutputNumberField";
import ZoneInformationArmingStyleField from "../../common/ZoneInformationFields/ZoneInformationArmingStyleField";
import { ZoneInformationContextProvider } from "../../common/ZoneInformationFields/ZoneInformationContext";
import ZoneInformationCrossZoneField from "../../common/ZoneInformationFields/ZoneInformationCrossZoneField";
import ZoneInformationDisarmedOpenMessageField from "../../common/ZoneInformationFields/ZoneInformationDisarmedOpenMessageField";
import ZoneInformationDisarmedOpenOutputActionField from "../../common/ZoneInformationFields/ZoneInformationDisarmedOpenOutputActionField";
import ZoneInformationDisarmedOpenOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationDisarmedOpenOutputNumberField";
import ZoneInformationDisarmedShortMessageField from "../../common/ZoneInformationFields/ZoneInformationDisarmedShortMessageField";
import ZoneInformationDisarmedShortOutputActionField from "../../common/ZoneInformationFields/ZoneInformationDisarmedShortOutputActionField";
import ZoneInformationDisarmedShortOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationDisarmedShortOutputNumberField";
import ZoneInformationNameField from "../../common/ZoneInformationFields/ZoneInformationNameField";
import ZoneInformationNumberField from "../../common/ZoneInformationFields/ZoneInformationNumberField";
import ZoneInformationReceiverRoutingField from "../../common/ZoneInformationFields/ZoneInformationReceiverRoutingField";
import ZoneInformationSwingerBypassField from "../../common/ZoneInformationFields/ZoneInformationSwingerBypassField";
import ZoneInformationTypeField from "../../common/ZoneInformationFields/ZoneInformationTypeField";
import { TakeoverPanelZoneInformationsFields_zone$key } from "./__generated__/TakeoverPanelZoneInformationsFields_zone.graphql";

function TakeoverPanelZoneInformationsFields(props: {
  zoneInformation: TakeoverPanelZoneInformationsFields_zone$key;
  disabled?: boolean;
}) {
  const data = useFragment(
    graphql`
      fragment TakeoverPanelZoneInformationsFields_zone on Zone {
        id
        number
        isECP
        ...ZoneInformationContext_zone
        ...ZoneInformationNameField_zone
        ...ZoneInformationLocationField_zone
        ...ZoneInformationNumberField_zone
        ...ZoneInformationTypeField_zone
        ...ZoneInformationAreaField_zone
        ...ZoneInformationSwingerBypassField_zone
        ...ZoneInformationCrossZoneField_zone
        ...ZoneInformationPriorityZoneField_zone
        ...ZoneInformationReceiverRoutingField_zone
        ...ZoneInformationArmAreasField_zone
        ...ZoneInformationDisarmedOpenMessageField_zone
        ...ZoneInformationDisarmedOpenOutputNumberField_zone
        ...ZoneInformationDisarmedOpenOutputActionField_zone
        ...ZoneInformationDisarmedShortMessageField_zone
        ...ZoneInformationDisarmedShortOutputNumberField_zone
        ...ZoneInformationDisarmedShortOutputActionField_zone
        ...ZoneInformationArmedOpenMessageField_zone
        ...ZoneInformationArmedOpenOutputNumberField_zone
        ...ZoneInformationArmedOpenOutputActionField_zone
        ...ZoneInformationArmedShortMessageField_zone
        ...ZoneInformationArmedShortOutputNumberField_zone
        ...ZoneInformationArmedShortOutputActionField_zone
        ...ZoneInformationArmingStyleField_zone
      }
    `,
    props.zoneInformation
  );

  const softwareVersion = useSoftwareVersion();
  const supportsZoneLocation =
    panelVersionGTOE(252, softwareVersion) && !data.isECP;

  return (
    <ZoneInformationContextProvider zoneInformation={data}>
      <ProgrammingConceptForm.Fields>
        <ZoneInformationNumberField />
        <ZoneInformationNameField />
        {supportsZoneLocation ? <ZoneInformationLocationField /> : null}
        <ZoneInformationTypeField />
        <ZoneInformationAreaField />
      </ProgrammingConceptForm.Fields>
      {/* Advanced Fields */}
      {!data.isECP && (
        <AdvancedFields>
          <ProgrammingConceptForm.Fields>
            <ZoneInformationSwingerBypassField />
            <ZoneInformationCrossZoneField />
            <ZoneInformationReceiverRoutingField />
            <ZoneInformationArmAreasField />
          </ProgrammingConceptForm.Fields>
        </AdvancedFields>
      )}
      {/* Actions Fields */}
      {!data.isECP && (
        <AdvancedFields label="Actions">
          <ProgrammingConceptForm.Fields>
            <ZoneInformationDisarmedOpenMessageField />
            <ZoneInformationDisarmedOpenOutputNumberField />
            <ZoneInformationDisarmedOpenOutputActionField />
            <ZoneInformationDisarmedShortMessageField />
            <ZoneInformationDisarmedShortOutputNumberField />
            <ZoneInformationDisarmedShortOutputActionField />
            <ZoneInformationArmedOpenMessageField />
            <ZoneInformationArmedOpenOutputNumberField />
            <ZoneInformationArmedOpenOutputActionField />
            <ZoneInformationArmedShortMessageField />
            <ZoneInformationArmedShortOutputNumberField />
            <ZoneInformationArmedShortOutputActionField />
            <ZoneInformationArmingStyleField />
          </ProgrammingConceptForm.Fields>
        </AdvancedFields>
      )}
    </ZoneInformationContextProvider>
  );
}

export default TakeoverPanelZoneInformationsFields;
