/**
 * @generated SignedSource<<9d31cd798aea8587fd2f5d36737517c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SecurityCommandEulaModalAgreeToEulaMutation$variables = {};
export type SecurityCommandEulaModalAgreeToEulaMutation$data = {
  readonly agreeToEula: {
    readonly hasAgreed: boolean;
  };
};
export type SecurityCommandEulaModalAgreeToEulaMutation = {
  response: SecurityCommandEulaModalAgreeToEulaMutation$data;
  variables: SecurityCommandEulaModalAgreeToEulaMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EulaStatus",
    "kind": "LinkedField",
    "name": "agreeToEula",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasAgreed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SecurityCommandEulaModalAgreeToEulaMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SecurityCommandEulaModalAgreeToEulaMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "38443fa48592aa3dc2de08380640661d",
    "id": null,
    "metadata": {},
    "name": "SecurityCommandEulaModalAgreeToEulaMutation",
    "operationKind": "mutation",
    "text": "mutation SecurityCommandEulaModalAgreeToEulaMutation {\n  agreeToEula {\n    hasAgreed\n  }\n}\n"
  }
};
})();

(node as any).hash = "10cab7391ffd7de065f8c7bb34b2cf39";

export default node;
