/**
 * @generated SignedSource<<3d76fff08f372d59a5fd3a7e201933e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DirectionOfTravel = "CROSS" | "IN" | "OFF" | "OUT";
export type GeoJSONType = "FEATURE" | "FEATURECOLLECTION" | "GEOMETRYCOLLECTION" | "LINESTRING" | "MULTILINESTRING" | "MULTIPOINT" | "MULTIPOLYGON" | "POINT" | "POLYGON";
import { FragmentRefs } from "relay-runtime";
export type RecorderCameraEdit_varHubCameraDetectionLine$data = {
  readonly detectAnimals: boolean;
  readonly detectPeople: boolean;
  readonly detectVehicles: boolean;
  readonly drawRegionOnAlerts: boolean;
  readonly geometry: {
    readonly coordinates: ReadonlyArray<ReadonlyArray<number>>;
    readonly type: GeoJSONType;
  };
  readonly id: string;
  readonly isNew: boolean | null;
  readonly movementDirection: DirectionOfTravel | null;
  readonly name: string;
  readonly slotNumber: number | null;
  readonly zone: {
    readonly area: {
      readonly id: string;
    } | null;
    readonly id: string;
    readonly name: string;
    readonly number: string;
  } | null;
  readonly " $fragmentType": "RecorderCameraEdit_varHubCameraDetectionLine";
};
export type RecorderCameraEdit_varHubCameraDetectionLine$key = {
  readonly " $data"?: RecorderCameraEdit_varHubCameraDetectionLine$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraEdit_varHubCameraDetectionLine">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "RecorderCameraEdit_varHubCameraDetectionLine"
};

(node as any).hash = "e32caaac95072a277c827f3167063f87";

export default node;
