App.controller("DaUserManagementLoginCtrl", [
  "$interval",
  "$scope",
  "$modalInstance",
  "PROPS",
  "TempAppUserService",
  "UserService",
  "$rootScope",
  "systemId",
  "ControlSystemsService",
  "controlSystemModel",
  "$q",
  "LoginService",
  "AboutMeV2API",
  "GoogleAnalyticsService",
  "RefreshSessionAPI",
  "SplitSessionService",
  "isUserManagement",
  "$sessionStorage",

  function (
    $interval,
    $scope,
    $modalInstance,
    PROPS,
    TempAppUserService,
    UserService,
    $rootScope,
    systemId,
    ControlSystemsService,
    controlSystemModel,
    $q,
    LoginService,
    AboutMeV2API,
    GoogleAnalyticsService,
    RefreshSessionAPI,
    SplitSessionService,
    isUserManagement,
    $sessionStorage
  ) {
    const _this = this;

    $scope.system = {};
    $scope.tempUser = null;
    $scope.UserService = UserService;
    const systemName = UserService.dealerInfo.vernaculars.systems.replacement
      ? ` ${UserService.dealerInfo.vernaculars.systems.replacement}`
      : " systems";
    $scope.props = {
      title: UserService.customerInfo.name + systemName,
      constants: PROPS,
    };
    $scope.group = {
      statuses: {},
      step: 0,
      createJobSuccess: false,
      error: false,
    };
    $scope.isUserManagement = isUserManagement;
    $scope.trackUser = () => {
      GoogleAnalyticsService.trackEvent(
        "control-system",
        "login-as-customer",
        "dealer-login"
      );
    };

    $scope.navigateToVkbUrl = function () {
      window.open($scope.vkbUrl, "_blank");
    };

    $scope.cancel = () => {
      $interval.cancel(_this.intervalWorker);
      $modalInstance.dismiss("cancel");
    };
    $scope.popupIsBlocked = false;
    $scope.vkbUrl = "";
    const setPopupIsBlocked = (url) => {
      const popup = window.open(url, "_blank");
      if (!popup || popup.closed || typeof popup.closed === "undefined") {
        $scope.popupIsBlocked = true;
      } else {
        $scope.popupIsBlocked = false;
      }
    };

    const openVkb = (token, systemId, isUserManagement = false) => {
      const baseUrl = $scope.props.constants.virtualKeypadUrl;
      const customerIdParam = UserService.customer_id
        ? `&id=${UserService.customer_id}`
        : "";

      $scope.vkbUrl = isUserManagement
        ? `${baseUrl}/admin/users?refreshToken=${token}${customerIdParam}`
        : `${baseUrl}/systems/${systemId}?refreshToken=${token}`;

      setPopupIsBlocked($scope.vkbUrl);
    };
    // Function to refresh the token

    async function refreshLogin() {
      try {
        const deferred = $q.defer();
        const refreshAPI = RefreshSessionAPI;
        $rootScope.refreshPromise = refreshAPI.refresh(
          {},
          angular.toJson({
            refresh_token: UserService.refresh_token,
          }),
          function (data) {
            try {
              if (data.sso_users && $rootScope.$storage.SCAPIUsers) {
                const SCAPIUsers = JSON.parse($rootScope.$storage.SCAPIUsers);

                for (let x = 0; x < SCAPIUsers.length; x++) {
                  const scapiUser = SCAPIUsers[x];
                  scapiUser.user.refresh_token = data.refresh_token;
                  if (scapiUser.user.accessible_id === data.accessible_id) {
                    scapiUser.user.authentication_token = data.jwt;
                  } else {
                    for (let y = 0; y < data.sso_users.length; y++) {
                      const ssoUser = data.sso_users[y];
                      if (
                        scapiUser.user.accessible_id === ssoUser.accessible_id
                      ) {
                        scapiUser.user.authentication_token = ssoUser.jwt;
                      }
                    }
                  }
                }
                localStorage.setItem("SCAPIUsers", JSON.stringify(SCAPIUsers));
                const currentAccount =
                  SCAPIUsers[$rootScope.$storage.currentAccount];
                UserService.login(
                  data.email,
                  currentAccount.user.authentication_token,
                  data.refresh_token
                );
                UserService.email = data.email;
                UserService.refresh_token = currentAccount.user.refresh_token;
                UserService.dealer_id = currentAccount.user.accessible_id;
                UserService.auth_token =
                  currentAccount.user.authentication_token;
                $sessionStorage.refresh_token =
                  currentAccount.user.refresh_token;
                $sessionStorage.auth_token =
                  currentAccount.user.authentication_token;

                //the relay environment is falling back to using the rootscope.Refreshsession
                //and the refreshSession is basically the data we get back from this call
                //however the jwt on the data is for the main user and not the sso
                //for the time being we'll try manually replacing the jwt with the sso here
                data.jwt = currentAccount.user.authentication_token;
              } else {
                UserService.login(data.email, data.jwt, data.refresh_token);
              }
            } catch (e) {
              deferred.reject(e);
            }
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.logout();
            $q.reject(error);
          }
        );

        return deferred.promise;
      } catch (e) {
        return $q.reject(e);
      }
    }

    $scope.close = () => {
      $interval.cancel(_this.intervalWorker);
      $modalInstance.close({
        createdTempUser: $scope.group.createJobSuccess,
      });
    };

    const successfulJobStatuses = ["completed", "success"];
    const inProgressJobStatuses = [
      "new",
      "created",
      "acquired",
      "started",
      "running",
    ];
    const jobCompleted = (status) => !inProgressJobStatuses.includes(status);

    const didJobSucceed = (status) => successfulJobStatuses.includes(status);

    function init() {
      $scope.group.step = 1;
      $scope.tempUserID = 0;
      getDesiredSystem().then(
        (system) => {
          $scope.system = system;
          TempAppUserService.create(system).then(
            (SchedulerJobGroup) => {
              console.debug(
                "TempUserModalCtrl->init() created temp user. Response data: " +
                  angular.toJson(SchedulerJobGroup)
              );
              const { Id } = SchedulerJobGroup;
              const salt = Date.now();
              startJobMonitor(Id, salt);
              $rootScope.$on(`TempAppUserCreated-${Id}-${salt}`, function () {
                refreshLogin().then(
                  /**
                   *  find the user in the  refreshData.sso_users array where id =
                   *  $scope.group.statuses.DestroyJobData.TempAppUserId and store object as tempUser
                   */
                  function (response) {
                    if (response) {
                      const refreshData = response;

                      const tempUser = refreshData.sso_users.find(
                        (user) =>
                          user.id ===
                          $scope.group.statuses.DestroyJobData.TempAppUserId
                      );
                      if (tempUser) {
                        // Call `getSplitSession` with the found user ID and token
                        SplitSessionService.getSplitSession(
                          tempUser.id,
                          tempUser.jwt
                        ).then((data) => {
                          const { refresh_token } = data;
                          openVkb(
                            refresh_token,
                            $scope.system.control_system_id,
                            $scope.isUserManagement
                          );
                          !$scope.popupIsBlocked && $scope.close();
                        });
                      }
                    }
                  }
                );
              });
            },
            () => {
              errorCloseModal();
            }
          );
        },
        () => {
          errorCloseModal();
        }
      );
    }

    const getDesiredSystem = () => {
      const deferred = $q.defer();
      if (+systemId > 0) {
        const system = new controlSystemModel({
          customer_id: UserService.customer_id,
          control_system_id: +systemId,
        });
        system.get().then(
          (data) => {
            deferred.resolve(data);
          },
          (error) => {
            console.error(
              "TempUserModalCtrl->getDesiredSystem() error: " +
                angular.toJson(error)
            );
            deferred.reject();
          }
        );
      } else {
        deferred.resolve(ControlSystemsService.currentControlSystem);
      }
      return deferred.promise;
    };

    function errorCloseModal() {
      $rootScope.alerts.push({
        type: "error",
        text: `authority to log in as customer has not been approved. please try again.`,
      });
      $scope.close();
    }

    /**
     * Starts interval function that checks Initial Connection job group status,
     * updating scoped data for display in the initial connection modal
     */
    var startJobMonitor = function (groupId, salt) {
      $scope.group.step = 2;
      $scope.group.refreshing = true;
      _this.intervalWorker = $interval(function () {
        TempAppUserService.getStatus(groupId).then(
          function (groupData) {
            $scope.group.statuses = groupData;
            if (jobCompleted(groupData.CreateJobStatus)) {
              $scope.group.step = 3;
              $interval.cancel(_this.intervalWorker);
              $scope.group.refreshing = false;
              $scope.group.createJobSuccess = didJobSucceed(
                $scope.group.statuses.CreateJobStatus
              );

              $rootScope.$broadcast(`TempAppUserCreated-${groupId}-${salt}`);
              $rootScope.$broadcast(`TempAppUserCreatedRefreshCustomerList`);
            }
          },
          function () {
            failTempUser();
          }
        );
      }, PROPS.initialConnectionWatcherInterval);
    };

    function failTempUser() {
      $scope.group.step = 3;
      $interval.cancel(_this.intervalWorker);
      $scope.group.refreshing = false;
      $scope.group.error = true;
    }

    init();
  },
]);
