App.controller("AllAppUsersCtrl", [
  "$scope",
  "$q",
  "$filter",
  "$compile",
  "$rootScope",
  "MIN_VIEW_SEARCH",
  "UserService",
  "DashboardDataService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "PanelDataService",
  "DataTablesUtilsService",
  function (
    $scope,
    $q,
    $filter,
    $compile,
    $rootScope,
    MIN_VIEW_SEARCH,
    UserService,
    DashboardDataService,
    DTOptionsBuilder,
    DTColumnBuilder,
    PanelDataService,
    DataTablesUtilsService
  ) {
    $scope.UserService = UserService;

    Promise.all([
      UserService.waitForUser(),
      UserService.waitForDealerInfo(),
    ]).then(
      ([user, dealer]) =>
        new Promise((resolve) => {
          $scope.validVernaculars = UserService.dealerInfo.vernaculars;

               const customerReplacement =
            $scope.validVernaculars.customers.replacement;
          const customerColumnTitle = customerReplacement
            ? `${customerReplacement} Name`
            : "Customer Name";


          function getList(refresh) {
            refresh = typeof refresh == "undefined" ? false : refresh;
            var deferred = $q.defer();
            var _this = this;
            UserService.dealerInfo
              .getDealersUsersList(refresh)
              .then(
                function (data) {
                  $scope.userList = data;

                  deferred.resolve(data);
                },
                function (error) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error retrieving customer list",
                    json: error,
                  });
                },
                function (info) {}
              )
              .catch(function (error) {
                console.error(error);
              });
            return deferred.promise;
          }

          $scope.dtOptions = DataTablesUtilsService.getDTOptions(
            getList(),
            "App Users"
          );
          $scope.dtOptions.withOption("order", [0, "asc"]);

          $scope.dtColumns = DataTablesUtilsService.dtColumns;

          // List of columns, column properties, and custom methods used to display the data in the Data Table.
          $scope.dtColumns = [
            DTColumnBuilder.newColumn("email").withTitle("Email"),
            DTColumnBuilder.newColumn("customer.name").withTitle(
              customerColumnTitle
            ),
            DTColumnBuilder.newColumn("last_sign_in_at")
              .withTitle("Last Login")
              .renderWith(DataTablesUtilsService.asDateTimeRenderFn()),
            DTColumnBuilder.newColumn("sign_in_count").withTitle(
              "Times Logged In"
            ),
            DTColumnBuilder.newColumn("customer.postal_code")
              .withTitle("Postal Code")
              .notVisible(),
            DTColumnBuilder.newColumn("customer.address1")
              .withTitle("Address 1")
              .notVisible(),
            DTColumnBuilder.newColumn("customer.address2")
              .withTitle("Address 2")
              .notVisible(),
            DTColumnBuilder.newColumn("customer.city")
              .withTitle("City")
              .notVisible(),
            DTColumnBuilder.newColumn("customer.state_province")
              .withTitle("State")
              .notVisible(),
            DTColumnBuilder.newColumn("customer.country")
              .withTitle("Country")
              .notVisible(),
            DTColumnBuilder.newColumn("customer.phone1")
              .withTitle("Phone 1")
              .notVisible(),
            DTColumnBuilder.newColumn("customer.phone2")
              .withTitle("Phone 2")
              .notVisible(),
          ];
        })
    );
  },
]);
