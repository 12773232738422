/**
 * @generated SignedSource<<a2641263f0f21b10c6b0bd22f340416d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly firstApn: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly integratorArmDisarmReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorBackupConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorBackupIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorBackupIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorDoorReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorOutgoingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorPassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorSupervisoryReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorUserCommandReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorVideoReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorZoneReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteDisarm: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts";
};
export type XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "d8c092f842c5179d23b1437f6735a40f";

export default node;
