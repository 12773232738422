// This is a component used to create a new mass programming global holiday dates job.
import graphql from "babel-plugin-relay/macro";
import { TableDataCell } from "components/CameraTable/styles";
import {
  DaButton,
  PageHeader,
  PageHeaderLeft,
  PageHeaderRight,
  PageHeaderTitle,
  PanelList,
} from "components/DaStyledElements";
import { DaButtonDefault } from "components/DaStyledElements/Button";
import LoadingSpinner from "components/DaStyledElements/LoadingSpinner";
import { Subtitle } from "components/DaStyledElements/Subtitle";
import { GenericPageFallback } from "components/GenericPageFallback";
import Panel from "components/Panel";
import { AlertsContextProvider, useShowAlert } from "contexts/AlertsContext";
import moment from "moment";
import React, { useMemo, useState } from "react";
import {
  RelayEnvironmentProvider,
  useLazyLoadQuery,
  useMutation,
} from "react-relay/hooks";
import {
  HolidayDateClass,
  idAsString,
  toDealerId,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import { GlobalHolidaySelection } from "./GlobalHolidaySelection";
import HolidaySystemSelectorModal from "./HolidaySystemSelectorModal";
import SystemsPanel from "./SystemsPanel";
import { MassProgrammingGlobalHolidayDatesJobNewDealerQuery } from "./__generated__/MassProgrammingGlobalHolidayDatesJobNewDealerQuery.graphql";
import { MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery } from "./__generated__/MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery.graphql";
import { MassProgrammingGlobalHolidayDatesJobNewRootSendMutation } from "./__generated__/MassProgrammingGlobalHolidayDatesJobNewRootSendMutation.graphql";

interface GlobalHolidayDate {
  scapiId: number;
  id: string;
  name: string;
  date: string;
  description: string;
  clazz: HolidayDateClass;
  created_at: string;
  updated_at: string;
}

interface Props {
  RelayService: any;
  $stateParams: any;
  UserService: any;
  $rootScope: any;
  $state: any;
}

export interface ControlSystem {
  controlSystemId: string;
  controlSystemName: string;
  panelHardwareModel: string;
  customerName: string;
  customerId: string;
}

export interface Customer {
  customerId: string;
  customerName: string;
  controlSystems: ControlSystem[];
  controlSystemsSet: Set<ControlSystem>;
}

const sendMassHolidayProgrammingJobMutation = graphql`
  mutation MassProgrammingGlobalHolidayDatesJobNewRootSendMutation(
    $input: UpdateGlobalHolidayDateForMassPanelsInput!
  ) {
    updateGlobalHolidayDateForMassPanels(input: $input) {
      ... on UpdateGlobalHolidayDateForMassPanelsSuccessResponse {
        __typename
        dealer {
          globalHolidayDateJobs {
            personWhoStartedJob {
              userId
              firstName
              lastName
              email
            }
            scapiId
            createdAt
            completedAt
            groupStatus
            jobStatus
            totalSystems
            successfullyUpdated
            updateInProgress
            waitingForUpdate
            failedToUpdate
            schedulerJobTypeId
          }
        }
      }
      ... on UpdateGlobalHolidayDateForMassPanelsErrorResponse {
        __typename
        type
        errorMessage
      }
    }
  }
`;

export const MassProgrammingGlobalHolidayDatesJobNewRoot: React.FC<Props> = ({
  RelayService,
  $stateParams,
  $rootScope,
  $state,
}) => {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <MassProgrammingGlobalHolidayDatesJobNew
            dealerId={$stateParams.dealer_id}
            state={$state}
          />
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
};

interface JobProps {
  dealerId: string;
  state: any;
}

const MassProgrammingGlobalHolidayDatesJobNew: React.FC<JobProps> = ({
  dealerId,
  state,
}) => {
  const showAlert = useShowAlert();
  const systemData =
    useLazyLoadQuery<MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery>(
      graphql`
        query MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery(
          $dealerId: ID!
        ) {
          massProgrammingQuickDetailedCustomersList(id: $dealerId) {
            __typename
            ...HolidaySystemSelectorModalFragment_systemData
          }
        }
      `,
      { dealerId: idAsString(toDealerId(dealerId)) }
    );

  const dealerGlobalHolidayDates =
    useLazyLoadQuery<MassProgrammingGlobalHolidayDatesJobNewDealerQuery>(
      graphql`
        query MassProgrammingGlobalHolidayDatesJobNewDealerQuery(
          $dealerId: ID!
        ) {
          dealer(id: $dealerId) {
            globalHolidayDates {
              scapiId
              id
              name
              date
              description
              clazz
            }
            vernaculars {
              original
              replacement
            }
          }
        }
      `,
      { dealerId: idAsString(toDealerId(dealerId)) }
    );

  const [selectedGlobalHolidayDate, setSelectedGlobalHolidayDate] =
    useState<GlobalHolidayDate | null>(null);
  const [sendMassHolidayProgrammingJob, isSendMassHolidayProgrammingJob] =
    useMutation<MassProgrammingGlobalHolidayDatesJobNewRootSendMutation>(
      sendMassHolidayProgrammingJobMutation
    );
  const handleSelectGlobalHolidayDate = (holidayDate: GlobalHolidayDate) => {
    if (
      selectedGlobalHolidayDate &&
      selectedGlobalHolidayDate.id === holidayDate.id
    ) {
      setSelectedGlobalHolidayDate(null); // Deselect if the same holiday date is clicked again
      setSelectedSystems([]); // Clear the selected systems
      setHolidaySystemSelectorModalIsOpen(false); // Close the modal
    } else {
      setSelectedGlobalHolidayDate(holidayDate);
      setHolidaySystemSelectorModalIsOpen(true);
    }
  };

  const [
    holidaySystemSelectorModalIsOpen,
    setHolidaySystemSelectorModalIsOpen,
  ] = useState(false);

  // this is the parent list of selected systems
  const [selectedSystemsList, setSelectedSystems] = useState<ControlSystem[]>(
    []
  );

  const [
    holidaySystemSelectorModalIsVisible,
    setHolidaySystemSelectorModalIsVisible,
  ] = useState(false);

  // this will handle toggling the system selection
  const handleSelectSystem = (
    system: ControlSystem,
    customer: Customer,
    isChecked: boolean
  ) => {
    // Add the customer name to the system object
    system.customerName = customer.customerName;

    if (isChecked) {
      setSelectedSystems((prev) => {
        const isSystemAlreadyChecked = prev.find(
          (s) => s.controlSystemId === system.controlSystemId
        );
        if (!isSystemAlreadyChecked) {
          return [...prev, system]; // Add system only if it's not already added
        }
        return prev; // Return previous state if system is already in the list
      });
    } else {
      setSelectedSystems((prev) =>
        prev.filter((s) => s.controlSystemId !== system.controlSystemId)
      );
    }
  };

  const areAnySystemsSelected = selectedSystemsList.length > 0;

  // a callback function to close the modal
  const handleCloseModal = () => {
    setHolidaySystemSelectorModalIsOpen(false);
    setHolidaySystemSelectorModalIsVisible(false);
  };

  //a callback function to cancel the modal the clear the selected systems
  const handleCancelModal = () => {
    setHolidaySystemSelectorModalIsOpen(false);
    setHolidaySystemSelectorModalIsVisible(false);
  };

  const systemReplacement = dealerGlobalHolidayDates.dealer?.vernaculars.find(
    (v) => v?.original === "systems"
  )?.replacement;

  // get the system data from the query response
  // and isolate the massProgrammingQuickDetailedCustomersList
  const massProgrammingQuickDetailedCustomersList =
    systemData.massProgrammingQuickDetailedCustomersList;

  // get the global holiday dates from the query response
  // and isolate the globalHolidayDates from the dealer
  const sortedGlobalHolidayDates = useMemo(() => {
    return Array.from(
      dealerGlobalHolidayDates?.dealer?.globalHolidayDates || []
    ).sort((a, b) => {
      const dateA = new Date(a.date).getTime(); // Convert date to number
      const dateB = new Date(b.date).getTime(); // Convert date to number
      return dateA - dateB; // For ascending order
    });
  }, [dealerGlobalHolidayDates?.dealer?.globalHolidayDates]); // Dependencies array
  const handleSaveMutation = (
    selectedSystemsList: ControlSystem[],
    selectedGlobalHolidayDate: GlobalHolidayDate | null
  ) => {
    if (!selectedGlobalHolidayDate) {
      return;
    }

    // Create an array of controlSystemIds from the selected systems
    const panelAssociation = selectedSystemsList.map((system) =>
      Number(system.controlSystemId)
    );

    const formattedDate = (date: string) => {
      return moment(date).format("DDMM");
    };

    // Create the input object for the mutation
    const inputData = {
      scapiId: Number(selectedGlobalHolidayDate.scapiId),
      name: selectedGlobalHolidayDate.name,
      date: formattedDate(selectedGlobalHolidayDate.date),
      description: selectedGlobalHolidayDate.description,
      clazz: selectedGlobalHolidayDate.clazz,
      dealerId: Number(dealerId),
      panelAssociation: panelAssociation,
    };
    sendMassHolidayProgrammingJob({
      variables: {
        input: inputData,
      },
      onCompleted(data) {
        if (
          data.updateGlobalHolidayDateForMassPanels.__typename ===
          "UpdateGlobalHolidayDateForMassPanelsSuccessResponse"
        ) {
          showAlert({
            type: "success",
            text: `Successfully added job for ${selectedSystemsList.length} ${
              systemReplacement ? systemReplacement : "system(s)"
            }`,
          });
          state.go("app.dealer.globalHolidayDates", {
            dealer_id: dealerId,
            active_tab: "sendGlobalHoliday",
          });
        } else if (
          data.updateGlobalHolidayDateForMassPanels.__typename ===
          "UpdateGlobalHolidayDateForMassPanelsErrorResponse"
        ) {
          showAlert({
            type: "error",
            text:
              data.updateGlobalHolidayDateForMassPanels.errorMessage ??
              data.updateGlobalHolidayDateForMassPanels.errorMessage,
          });
        } else {
          showAlert({
            type: "error",
            text: "Unknown Error Occurred",
          });
        }
      },
    });
  };

  return (
    <>
      <LayoutGrid>
        <TitleRow>
          <PageHeader>
            <PageHeaderLeft>
              <PageHeaderTitle>
                Global Holiday Date Mass Programming - New{" "}
                {isSendMassHolidayProgrammingJob ? (
                  <div style={{ position: "relative", marginLeft: ".8rem" }}>
                    <LoadingSpinner />
                  </div>
                ) : null}
              </PageHeaderTitle>
            </PageHeaderLeft>
            <PageHeaderRight>
              <DaButtonDefault
                disabled={isSendMassHolidayProgrammingJob}
                onClick={() =>
                  state.go("app.dealer.globalHolidayDates", {
                    dealer_id: dealerId,
                    active_tab: "sendGlobalHoliday",
                  })
                }
              >
                Back
              </DaButtonDefault>
              <DaButton
                onClick={() =>
                  handleSaveMutation(
                    selectedSystemsList,
                    selectedGlobalHolidayDate
                  )
                }
                disabled={
                  !selectedGlobalHolidayDate ||
                  isSendMassHolidayProgrammingJob ||
                  !selectedSystemsList.length
                }
              >
                Send
              </DaButton>
            </PageHeaderRight>
          </PageHeader>
        </TitleRow>
        <LeftColumn>
          <Subtitle>Holiday Dates</Subtitle>
          <Panel>
            <PanelList>
              {sortedGlobalHolidayDates ? (
                sortedGlobalHolidayDates.map((holiday) => (
                  <GlobalHolidaySelection
                    key={holiday.id}
                    globalHoliday={holiday}
                    isChecked={selectedGlobalHolidayDate?.id === holiday.id}
                    onCheckboxChange={() =>
                      handleSelectGlobalHolidayDate(
                        holiday as GlobalHolidayDate
                      )
                    }
                    isDisabled={isSendMassHolidayProgrammingJob}
                  />
                ))
              ) : (
                <TableDataCell>
                  No global holiday Dates have been created
                </TableDataCell>
              )}
            </PanelList>
          </Panel>
        </LeftColumn>
        {selectedGlobalHolidayDate ? (
          <RightColumn>
            <Subtitle>
              <span>{systemReplacement ?? "Systems"}</span>
              <button
                className="btn btn-sm btn-add--no-icon btn-add--no-icon--with-pulse"
                onClick={() => {
                  setHolidaySystemSelectorModalIsOpen(true);
                  setHolidaySystemSelectorModalIsVisible(true);
                }}
                disabled={isSendMassHolidayProgrammingJob}
              >
                Add {systemReplacement ?? "Systems"}
              </button>
            </Subtitle>
            {areAnySystemsSelected ? (
              <SystemsPanel
                selectedSystemsList={selectedSystemsList}
                areAnySystemsSelected={areAnySystemsSelected}
                vernaculars={dealerGlobalHolidayDates.dealer?.vernaculars}
              />
            ) : null}
            <React.Suspense fallback={<></>}>
              {holidaySystemSelectorModalIsOpen ? (
                <HolidaySystemSelectorModal
                  systemListRef={massProgrammingQuickDetailedCustomersList}
                  onUpdateSystemSelection={handleSelectSystem}
                  selectedSystemsList={selectedSystemsList}
                  onCloseModal={handleCloseModal}
                  onCancelModal={handleCancelModal}
                  holidayId={selectedGlobalHolidayDate?.id!}
                  setSelectedSystems={setSelectedSystems}
                  revealModal={holidaySystemSelectorModalIsVisible}
                  vernaculars={dealerGlobalHolidayDates.dealer?.vernaculars}
                />
              ) : null}
            </React.Suspense>
          </RightColumn>
        ) : null}
      </LayoutGrid>
    </>
  );
};

const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content max-content;
  grid-template-areas: "title-row" "left-column" "right-column";

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content max-content;
    grid-gap: 2rem;
    grid-template-areas: "title-row title-row" "left-column right-column";
  }
`;
const TitleRow = styled.div`
  grid-area: title-row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
`;

const LeftColumn = styled.div`
  grid-area: left-column;
  background-color: transparent;
  padding: 1rem;
`;
const RightColumn = styled.div`
  grid-area: right-column;
  background-color: transparent;
  padding: 1rem;
`;

export default MassProgrammingGlobalHolidayDatesJobNewRoot;
