import * as React from "react";
import { joinSpaced } from "utils/string";

/**
 *
 * @param param0
 */
export const Alert = ({
  type = "info",
  icon = (() => {
    switch (type) {
      case "warning":
      case "error":
        return <i className="icon-radial_alert mar-r-8"></i>;
      case "success":
        return <i className="icon-radial_check mar-r-8"></i>;
      case "info":
      default:
        return <i className="icon-radial_info mar-r-8"></i>;
    }
  })(),
  onClose,
  message,
}: {
  type: "info" | "warning" | "error" | "success";
  icon?: React.ReactNode;
  message: string;
  onClose?: () => void;
}) => {
  const getAlertClass = (type: string) => {
    switch (type) {
      case "error":
        return "danger";
      case "warning":
        return "warning";
      case "success":
        return "success";
      default:
        return type;
    }
  };
  return (
    <div
      className={joinSpaced(
        "alert mar-b-8",
        `alert-${getAlertClass(type)}`,
        !!onClose ? "alert-dismissible" : ""
      )}
      role="alert"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {icon}

        {message}
      </div>
      {!!onClose && (
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      )}
    </div>
  );
};

export const Popover = () => {
  return null;
};

export const Tooltip = () => {
  return null;
};

export const ProgressHorizontalBar = () => {
  return null;
};

export const ProgressRadialBar = () => {
  return null;
};

export const Toaster = () => {
  return null;
};

export default {
  Alert,
  Popover,
  Tooltip,
  ProgressHorizontalBar,
  ProgressRadialBar,
  Toaster,
};

/*
Array.from(document.querySelectorAll("[tip]")).forEach((el) => {
  let tip = document.createElement("div");
  tip.classList.add("tooltip");
  tip.innerText = el.getAttribute("tip");
  let delay = el.getAttribute("tip-delay");
  if (delay) {
    tip.style.transitionDelay = delay + "s";
  }
  tip.style.transform =
    "translate(" +
    (el.hasAttribute("tip-left") ? "calc(-100% - 5px)" : "15px") +
    ", " +
    (el.hasAttribute("tip-top") ? "-100%" : "0") +
    ")";
  el.appendChild(tip);
  el.onmousemove = (e) => {
    tip.style.left = e.clientX + "px";
    tip.style.top = e.clientY + "px";
  };
});*/
