import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { curry } from "ramda";
import * as React from "react";
import { KeyfobButtonType, KeyfobOutputAction } from "securecom-graphql/client";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useKeyfobFragment } from "./KeyfobContext";
import { KeyfobButtonNumberType } from "./utils";
import { KeyfobButtonOutputActionField_keyfob$key } from "./__generated__/KeyfobButtonOutputActionField_keyfob.graphql";

export const keyfobButtonOutputActionFieldId = curry(
  (buttonNumber: KeyfobButtonNumberType, number: string) =>
    `keyfob-button-output-action-button-number-${buttonNumber.toLowerCase()}-${number}`
);

function KeyfobButtonOutputActionField(props: {
  buttonNumber: KeyfobButtonNumberType;
}) {
  const [
    {
      number,
      buttonOneAction,
      buttonTwoAction,
      buttonThreeAction,
      buttonFourAction,
      buttonOneOutput,
      buttonTwoOutput,
      buttonThreeOutput,
      buttonFourOutput,
      buttonOneOutputAction,
      buttonTwoOutputAction,
      buttonThreeOutputAction,
      buttonFourOutputAction,
    },
    updateKeyfob,
  ] = useKeyfobFragment<KeyfobButtonOutputActionField_keyfob$key>(
    graphql`
      fragment KeyfobButtonOutputActionField_keyfob on Keyfob {
        number
        buttonOneAction
        buttonTwoAction
        buttonThreeAction
        buttonFourAction
        buttonOneOutput
        buttonTwoOutput
        buttonThreeOutput
        buttonFourOutput
        buttonOneOutputAction
        buttonTwoOutputAction
        buttonThreeOutputAction
        buttonFourOutputAction
      }
    `
  );
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);
  const softwareVersion = useSoftwareVersion();

  const is243 = isXr && softwareVersion >= 243;

  const { buttonNumber } = props;
  const fieldId = keyfobButtonOutputActionFieldId(buttonNumber, String(number));
  const isOutputGroupRegex = new RegExp(/[G](0[1-9]|1[0-9]|20)/);
  const isFavoriteGroupRegex = new RegExp(/[F](0[1-9]|1[0-9]|20)/);
  const disableButtonTypes = [
    KeyfobButtonType.UNUSED,
    KeyfobButtonType.ARMING,
    KeyfobButtonType.TOGGLE_ARM_DISARM,
    KeyfobButtonType.DISARMING,
    KeyfobButtonType.SENSOR_RESET,
    KeyfobButtonType.STATUS,
  ];
  let disabled;
  let value;
  let isOutputGroup;
  let isFavoriteGroup;

  if (is243 && number >= 500) {
    disabled = true;
    value = KeyfobOutputAction.OFF;
    isOutputGroup = false;
    isFavoriteGroup = false;
  } else {
    switch (buttonNumber) {
      case "One":
        disabled = disableButtonTypes.includes(
          buttonOneAction as KeyfobButtonType
        );
        value = buttonOneOutputAction;
        isOutputGroup = isOutputGroupRegex.test(buttonOneOutput);
        isFavoriteGroup = isFavoriteGroupRegex.test(buttonOneOutput);
        break;
      case "Two":
        disabled = disableButtonTypes.includes(
          buttonTwoAction as KeyfobButtonType
        );
        value = buttonTwoOutputAction;
        isOutputGroup = isOutputGroupRegex.test(buttonTwoOutput);
        isFavoriteGroup = isFavoriteGroupRegex.test(buttonTwoOutput);
        break;
      case "Three":
        disabled = disableButtonTypes.includes(
          buttonThreeAction as KeyfobButtonType
        );
        value = buttonThreeOutputAction;
        isOutputGroup = isOutputGroupRegex.test(buttonThreeOutput);
        isFavoriteGroup = isFavoriteGroupRegex.test(buttonThreeOutput);
        break;
      case "Four":
        disabled = disableButtonTypes.includes(
          buttonFourAction as KeyfobButtonType
        );
        value = buttonFourOutputAction;
        isOutputGroup = isOutputGroupRegex.test(buttonFourOutput);
        isFavoriteGroup = isFavoriteGroupRegex.test(buttonFourOutput);
        break;
      default:
        disabled = false;
        value = KeyfobOutputAction.STEADY;
        isOutputGroup = false;
        isFavoriteGroup = false;
    }
  }

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Output Action"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        disabled={disabled || isFavoriteGroup}
        value={value}
        required
        onChange={({ target }) => {
          updateKeyfob((recordProxy) => {
            recordProxy.setValue(
              target.value,
              `button${buttonNumber}OutputAction`
            );
          });
        }}
      >
        <Select.Option value={KeyfobOutputAction.OFF}>Off</Select.Option>
        {!isOutputGroup && (
          <Select.Option value={KeyfobOutputAction.PULSE}>Pulse</Select.Option>
        )}
        <Select.Option value={KeyfobOutputAction.STEADY}>Steady</Select.Option>
        <Select.Option value={KeyfobOutputAction.MOMENTARY}>
          Momentary
        </Select.Option>
        {!isOutputGroup && (
          <Select.Option value={KeyfobOutputAction.TOGGLE}>
            Toggle
          </Select.Option>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobButtonOutputActionField;
