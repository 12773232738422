/**
 * @generated SignedSource<<0726313ba0ea3544356f250ddfc429e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$data = {
  readonly areaInformations: ReadonlyArray<{
    readonly armedOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly autoArmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly autoDisarmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly badZonesOption: {
      readonly data: BadZonesOption | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryBellOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly lateArmDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly lateOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly openClosingReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts";
};
export type XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "58e4e2338f05f5b710b68019750d61cc";

export default node;
