import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorBackupConnectionField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorBackupConnectionField_remoteOptions.graphql";

export const remoteOptionsIntegratorBackupConnectionFieldId = () =>
  "remote-options-integrator-backup-connection";

function RemoteOptionsIntegratorBackupConnectionField() {
  const [
    { integratorBackupConnection, integratorConnection },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorBackupConnectionField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorBackupConnectionField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorBackupConnection
          integratorConnection
        }
        ... on Xt75RemoteOptions {
          integratorBackupConnection
          integratorConnection
        }
        ... on XfRemoteOptions {
          integratorBackupConnection
          integratorConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorBackupConnectionFieldId();
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Integrator Backup Connection"
    >
      <Select
        id={fieldId}
        disabled={disabled}
        name={fieldId}
        value={integratorBackupConnection ?? undefined}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "integratorBackupConnection");
            if (target.value === RemoteOptionsConnectionType.NONE) {
              recordProxy.setValue("0.0.0.0", "integratorBackupIpAddress");
            }
          });
        }}
      >
        <Select.Option value={RemoteOptionsConnectionType.NONE}>
          None
        </Select.Option>
        <Select.Option value={RemoteOptionsConnectionType.NETWORK}>
          Network
        </Select.Option>
        {isXr ? (
          <Select.Option value={RemoteOptionsConnectionType.CELL}>
            Cell
          </Select.Option>
        ) : null}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorBackupConnectionField;
