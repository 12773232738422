import * as React from "react";
import { joinSpaced } from "utils/string";
import Icon from "../Icon/";

export const Group: React.FC<
  React.PropsWithChildren<{
    className?: string;
    error?: boolean;
    style?: React.CSSProperties;
  }>
> = ({ className = "", error, style, ...rest }) => {
  return (
    <div
      {...rest}
      style={style}
      className={joinSpaced(
        "form-group",
        className,
        !!error ? "has-error" : ""
      )}
    />
  );
};

export const PasswordRevealGroup: React.FC<
  React.PropsWithChildren<{
    className?: string;
    children?: React.ReactNode;
    setInputStyle: () => void;
    iconStyle?: React.CSSProperties;
    showPassword?: boolean;
    eyeIsVisible?: boolean;
  }>
> = ({
  className,
  setInputStyle,
  iconStyle,
  children,
  eyeIsVisible,
  showPassword = false,
}) => {
  const [passwordRevealed, setPasswordRevealed] = React.useState(showPassword);

  return (
    <Group
      style={{ position: "relative", marginBottom: ".8rem" }}
      className={className}
    >
      {eyeIsVisible && (
        <Icon
          name={
            showPassword || passwordRevealed ? `visibility_off` : `visibility`
          }
          size="2.4rem"
          color="var(--color-primary)"
          style={{
            position: "absolute",
            right: "1.1rem",
            transformOrigin: "0 0",
            transform: "translateY(113%)",
            ...iconStyle,
          }}
          onClick={() => {
            setInputStyle();
            passwordRevealed
              ? setPasswordRevealed(false)
              : setPasswordRevealed(true);
          }}
        />
      )}
      {children}
    </Group>
  );
};

export const Label: React.FC<React.PropsWithChildren<{ htmlFor?: string }>> = ({
  children,
  htmlFor,
}) => {
  return (
    <label htmlFor={htmlFor} className="control-label">
      {children}
    </label>
  );
};

/**
 * Wraps
 * @param children
 */
export const Horizontal: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return <div className="form-horizontal">{children}</div>;
};
export function ErrorMsg({ ...props }) {
  return (
    <div
      {...props}
      style={{ marginTop: "5px", color: "var(--color-danger-500" }}
    ></div>
  );
}

export const Toggle: React.FC<
  React.PropsWithChildren<{
    label: string;
    isChecked: boolean;
    onChange: React.InputHTMLAttributes<HTMLInputElement>["onChange"];
  }>
> = ({ label, isChecked }) => {
  return (
    <>
      <label className="col-sm-2 control-label">{label}</label>
      <div className="col-sm-10">
        <label className="switch">
          <input type="checkbox" checked={isChecked} />
          <span></span>
        </label>
      </div>
    </>
  );
};

export const Checkbox: React.FC<
  React.PropsWithChildren<
    React.InputHTMLAttributes<HTMLInputElement> & {
      label: string;
    }
  >
> = ({ label, ...rest }) => {
  return (
    <>
      <div className="checkbox c-checkbox checkbox-inLine needsclick">
        <label className="animated fadeInDown needsclick">
          <input type="checkbox" {...rest} className="needsclick" />
          <span className="icon-checkmark"></span>
          {label}

          <span className="icon-checkmark"></span>
        </label>
      </div>
    </>
  );
};

export default {
  Group,
  PasswordRevealGroup,
  Label,
  Horizontal,
  Toggle,
  ErrorMsg,
};
