import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { zoneInformationAreaFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationAreaField";
import { zoneInformationArmAreasFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationArmAreasField";
import { zoneInformationArmedOpenMessageFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationArmedOpenMessageField";
import { zoneInformationArmedOpenOutputActionFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationArmedOpenOutputActionField";
import { zoneInformationArmedOpenOutputNumberFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationArmedOpenOutputNumberField";
import { zoneInformationArmedShortMessageFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationArmedShortMessageField";
import { zoneInformationArmedShortOutputActionFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationArmedShortOutputActionField";
import { zoneInformationArmedShortOutputNumberFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationArmedShortOutputNumberField";
import { zoneInformationArmingStyleFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationArmingStyleField";
import { zoneInformationChimeSoundFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationChimeSoundField";
import { zoneInformationCompetitorWirelessFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationCompetitorWirelessField";
import { zoneInformationContactFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationContactField";
import { zoneInformationContactNormallyOpenFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationContactNormallyOpenField";
import { zoneInformationCrossZoneFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationCrossZoneField";
import { zoneInformationDisarmDisableFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationDisarmDisableField";
import { zoneInformationDisarmedOpenMessageFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationDisarmedOpenMessageField";
import { zoneInformationDisarmedOpenOutputActionFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationDisarmedOpenOutputActionField";
import { zoneInformationDisarmedOpenOutputNumberFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationDisarmedOpenOutputNumberField";
import { zoneInformationDisarmedShortMessageFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationDisarmedShortMessageField";
import { zoneInformationDisarmedShortOutputActionFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationDisarmedShortOutputActionField";
import { zoneInformationDisarmedShortOutputNumberFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationDisarmedShortOutputNumberField";
import { zoneInformationDMPWirelessFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationDMPWirelessField";
import { zoneInformationEntryDelayFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationEntryDelayField";
import { zoneInformationFastResponseFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationFastResponseField";
import { zoneInformationFireBellOutputNumberFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationFireBellOutputNumberField";
import { zoneInformationFirePanelSlaveInputFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationFirePanelSlaveInputField";
import { zoneInformationFollowAreaFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationFollowAreaField";
import { zoneInformationLEDOperationFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationLEDOperationField";
import { zoneInformationLocationFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationLocationField";
import { zoneInformationLockdownFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationLockdownField";
import { zoneInformationNameFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationNameField";
import {
  zoneInformationNumberFieldId,
  zoneListItemTemplateId,
} from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationNumberField";
import { zoneInformationPIRPetImmunityFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationPIRPetImmunityField";
import { zoneInformationPIRPulseCountFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationPIRPulseCountField";
import { zoneInformationPIRSensitivityFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationPIRSensitivityField";
import { zoneInformationPresignalKeypadsFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationPresignalKeypadsField";
import { zoneInformationPrewarnKeypadsFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationPrewarnKeypadsField";
import { zoneInformationPriorityZoneFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationPriorityZoneField";
import { zoneInformationReceiverRoutingFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationReceiverRoutingField";
import { zoneInformationReportWithAccountNumberFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationReportWithAccountNumberField";
import { zoneInformationSensorTypeFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationSensorTypeField";
import { zoneInformationSupervisionTimeFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationSupervisionTimeField";
import { zoneInformationSwingerBypassFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationSwingerBypassField";
import { zoneInformationTrafficCountFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationTrafficCountField";
import { zoneInformationTypeFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationTypeField";
import { zoneInformationZoneAuditDaysFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationZoneAuditDaysField";
import { zoneInformationZoneRealTimeStatusFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationZoneRealTimeStatus";
import { zoneInformationZoneRetardDelayFieldId } from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationZoneRetardDelayField";
import { XRZoneInformationProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/XRZoneInformationProgrammingConceptForm/__generated__/XRZoneInformationProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import {
  getState as getZonesState,
  getZoneState,
} from "../../../XRFullProgramming/XRZoneInformationProgrammingConceptForm";
import { ProgrammingTemplateZoneInformationInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRZoneInformationTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRZoneInformationTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  zone: XRZoneInformationTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRZoneInformationTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        zoneInformations {
          id
          included
          number
          name {
            included
            data
          }
          location {
            included
            data
          }
          type {
            included
            data
          }
          area {
            included
            data
          }
          followArea {
            included
            data
          }
          reportWithAccountNumberForArea {
            included
            data
          }
          armedAreasForArmingZone {
            included
            data
          }
          disarmedOpenActionMessage {
            included
            data
          }
          disarmedOpenOutputNumber {
            included
            data
          }
          fireBellOutputNumber {
            included
            data
          }
          disarmedOpenOutputAction {
            included
            data
          }
          disarmedShortActionMessage {
            included
            data
          }
          disarmedShortOutputNumber {
            included
            data
          }
          disarmedShortOutputAction {
            included
            data
          }
          armedOpenActionMessage {
            included
            data
          }
          armedOpenOutputNumber {
            included
            data
          }
          armedOpenOutputAction {
            included
            data
          }
          armedShortActionMessage {
            included
            data
          }
          armingStyle {
            included
            data
          }
          armedShortOutputNumber {
            included
            data
          }
          armedShortOutputAction {
            included
            data
          }
          swingerBypassEnabled {
            included
            data
          }
          retardDelayEnabled {
            included
            data
          }
          fastResponseEnabled {
            included
            data
          }
          prewarnKeypads {
            included
            data
          }
          presignalKeypads {
            included
            data
          }
          crossZoneEnabled {
            included
            data
          }
          entryDelayNumber {
            included
            data
          }
          priorityZone {
            included
            data
          }
          firePanelSlaveInput {
            included
            data
          }
          realTimeStatusEnabled {
            included
            data
          }
          trafficCountEnabled {
            included
            data
          }
          zoneAuditDays {
            included
            data
          }
          wireless {
            included
            data
          }
          contactNumber {
            included
            data
          }
          supervisionTime {
            included
            data
          }
          wirelessLedEnabled {
            included
            data
          }
          wirelessDisarmDisableEnabled {
            included
            data
          }
          wirelessPirPulseCount {
            included
            data
          }
          wirelessPirSensitivity {
            included
            data
          }
          wirelessPetImmunity {
            included
            data
          }
          chimeSound {
            included
            data
          }
          lockdownEnabled {
            included
            data
          }
          normallyClosed {
            included
            data
          }
          wirelessContactNormallyOpen {
            included
            data
          }
          competitorWireless {
            included
            data
          }
          sensorType {
            included
            data
          }
          receiverRouting {
            included
            data
          }
        }
      }
    `,
    zone
  );

export const getInput = (
  controlSystem: XRZoneInformationProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateZoneInformationInput[] => {
  const {
    panel: { zoneInformations },
  } = getZonesState(controlSystem);

  return zoneInformations
    ? zoneInformations
        .filter(isNotNullOrUndefined)
        .map(getZoneState)
        .map((zone) => ({
          included: includedFields.has(
            zoneListItemTemplateId(String(zone.number))
          ),
          number: Number(zone.number),
          name: {
            included: includedFields.has(
              zoneInformationNameFieldId(zone.number)
            ),
            data: zone.name,
          },
          location: {
            included: includedFields.has(
              zoneInformationLocationFieldId(zone.number)
            ),
            data: zone.location,
          },
          type: {
            included: includedFields.has(
              zoneInformationTypeFieldId(zone.number)
            ),
            data: zone.type,
          },
          area: {
            included: includedFields.has(
              zoneInformationAreaFieldId(zone.number)
            ),
            data: zone.area?.number ? `${zone.area?.number}` : null,
          },
          followArea: {
            included: includedFields.has(
              zoneInformationFollowAreaFieldId(zone.number)
            ),
            data: zone.followArea?.number ? `${zone.followArea?.number}` : null,
          },
          reportWithAccountNumberForArea: {
            included: includedFields.has(
              zoneInformationReportWithAccountNumberFieldId(zone.number)
            ),
            data: zone.reportWithAccountNumberForArea?.number,
          },
          armedAreasForArmingZone: {
            included: includedFields.has(
              zoneInformationArmAreasFieldId(zone.number)
            ),
            data: zone.armedAreasForArmingZone,
          },
          disarmedOpenActionMessage: {
            included: includedFields.has(
              zoneInformationDisarmedOpenMessageFieldId(zone.number)
            ),
            data: zone.disarmedOpenActionMessage,
          },
          disarmedOpenOutputNumber: {
            included: includedFields.has(
              zoneInformationDisarmedOpenOutputNumberFieldId(zone.number)
            ),
            data: zone.disarmedOpenOutputNumber,
          },
          fireBellOutputNumber: {
            included: includedFields.has(
              zoneInformationFireBellOutputNumberFieldId(zone.number)
            ),
            data: zone.fireBellOutputNumber,
          },
          disarmedOpenOutputAction: {
            included: includedFields.has(
              zoneInformationDisarmedOpenOutputActionFieldId(zone.number)
            ),
            data: zone.disarmedOpenOutputAction,
          },
          disarmedShortActionMessage: {
            included: includedFields.has(
              zoneInformationDisarmedShortMessageFieldId(zone.number)
            ),
            data: zone.disarmedShortActionMessage,
          },
          disarmedShortOutputNumber: {
            included: includedFields.has(
              zoneInformationDisarmedShortOutputNumberFieldId(zone.number)
            ),
            data: zone.disarmedShortOutputNumber,
          },
          disarmedShortOutputAction: {
            included: includedFields.has(
              zoneInformationDisarmedShortOutputActionFieldId(zone.number)
            ),
            data: zone.disarmedShortOutputAction,
          },
          armedOpenActionMessage: {
            included: includedFields.has(
              zoneInformationArmedOpenMessageFieldId(zone.number)
            ),
            data: zone.armedOpenActionMessage,
          },
          armedOpenOutputNumber: {
            included: includedFields.has(
              zoneInformationArmedOpenOutputNumberFieldId(zone.number)
            ),
            data: zone.armedOpenOutputNumber,
          },
          armedOpenOutputAction: {
            included: includedFields.has(
              zoneInformationArmedOpenOutputActionFieldId(zone.number)
            ),
            data: zone.armedOpenOutputAction,
          },
          armedShortActionMessage: {
            included: includedFields.has(
              zoneInformationArmedShortMessageFieldId(zone.number)
            ),
            data: zone.armedShortActionMessage,
          },
          armingStyle: {
            included: includedFields.has(
              zoneInformationArmingStyleFieldId(zone.number)
            ),
            data: zone.armingStyle,
          },
          armedShortOutputNumber: {
            included: includedFields.has(
              zoneInformationArmedShortOutputNumberFieldId(zone.number)
            ),
            data: zone.armedShortOutputNumber,
          },
          armedShortOutputAction: {
            included: includedFields.has(
              zoneInformationArmedShortOutputActionFieldId(zone.number)
            ),
            data: zone.armedShortOutputAction,
          },
          swingerBypassEnabled: {
            included: includedFields.has(
              zoneInformationSwingerBypassFieldId(zone.number)
            ),
            data: zone.swingerBypassEnabled,
          },
          retardDelayEnabled: {
            included: includedFields.has(
              zoneInformationZoneRetardDelayFieldId(zone.number)
            ),
            data: zone.retardDelayEnabled,
          },
          fastResponseEnabled: {
            included: includedFields.has(
              zoneInformationFastResponseFieldId(zone.number)
            ),
            data: zone.fastResponseEnabled,
          },
          prewarnKeypads: {
            included: includedFields.has(
              zoneInformationPrewarnKeypadsFieldId(zone.number)
            ),
            data: zone.prewarnKeypads,
          },
          presignalKeypads: {
            included: includedFields.has(
              zoneInformationPresignalKeypadsFieldId(zone.number)
            ),
            data: zone.presignalKeypads,
          },
          crossZoneEnabled: {
            included: includedFields.has(
              zoneInformationCrossZoneFieldId(zone.number)
            ),
            data: zone.crossZoneEnabled,
          },
          entryDelayNumber: {
            included: includedFields.has(
              zoneInformationEntryDelayFieldId(zone.number)
            ),
            data: zone.entryDelayNumber,
          },
          priorityZone: {
            included: includedFields.has(
              zoneInformationPriorityZoneFieldId(zone.number)
            ),
            data: zone.priorityZone,
          },
          firePanelSlaveInput: {
            included: includedFields.has(
              zoneInformationFirePanelSlaveInputFieldId(zone.number)
            ),
            data: zone.firePanelSlaveInput,
          },
          realTimeStatusEnabled: {
            included: includedFields.has(
              zoneInformationZoneRealTimeStatusFieldId(zone.number)
            ),
            data: zone.realTimeStatusEnabled,
          },
          trafficCountEnabled: {
            included: includedFields.has(
              zoneInformationTrafficCountFieldId(zone.number)
            ),
            data: zone.trafficCountEnabled,
          },
          zoneAuditDays: {
            included: includedFields.has(
              zoneInformationZoneAuditDaysFieldId(zone.number)
            ),
            data: zone.zoneAuditDays,
          },
          wireless: {
            included: includedFields.has(
              zoneInformationDMPWirelessFieldId(zone.number)
            ),
            data: zone.wireless,
          },
          contactNumber: {
            included: includedFields.has(
              zoneInformationContactFieldId(zone.number)
            ),
            data: zone.contactNumber,
          },
          supervisionTime: {
            included: includedFields.has(
              zoneInformationSupervisionTimeFieldId(zone.number)
            ),
            data: zone.supervisionTime,
          },
          wirelessLedEnabled: {
            included: includedFields.has(
              zoneInformationLEDOperationFieldId(zone.number)
            ),
            data: zone.wirelessLedEnabled,
          },
          wirelessDisarmDisableEnabled: {
            included: includedFields.has(
              zoneInformationDisarmDisableFieldId(zone.number)
            ),
            data: zone.wirelessDisarmDisableEnabled,
          },
          wirelessPirPulseCount: {
            included: includedFields.has(
              zoneInformationPIRPulseCountFieldId(zone.number)
            ),
            data: zone.wirelessPirPulseCount,
          },
          wirelessPirSensitivity: {
            included: includedFields.has(
              zoneInformationPIRSensitivityFieldId(zone.number)
            ),
            data: zone.wirelessPirSensitivity,
          },
          wirelessPetImmunity: {
            included: includedFields.has(
              zoneInformationPIRPetImmunityFieldId(zone.number)
            ),
            data: zone.wirelessPetImmunity,
          },
          chimeSound: {
            included: includedFields.has(
              zoneInformationChimeSoundFieldId(zone.number)
            ),
            data: zone.chimeSound,
          },
          lockdownEnabled: {
            included: includedFields.has(
              zoneInformationLockdownFieldId(zone.number)
            ),
            data: zone.lockdownEnabled,
          },
          // normallyClosed: {
          //   included: false, // TODO: Consider removing from schema includedFields.has(zoneInformationNor(zone.number)),
          //   data: zone.normallyClosed,
          // },
          wirelessContactNormallyOpen: {
            included: includedFields.has(
              zoneInformationContactNormallyOpenFieldId(zone.number)
            ),
            data: zone.wirelessContactNormallyOpen,
          },
          competitorWireless: {
            included: includedFields.has(
              zoneInformationCompetitorWirelessFieldId(zone.number)
            ),
            data: zone.competitorWireless,
          },
          sensorType: {
            included: includedFields.has(
              zoneInformationSensorTypeFieldId(zone.number)
            ),
            data: zone.sensorType,
          },
          receiverRouting: {
            included: includedFields.has(
              zoneInformationReceiverRoutingFieldId(zone.number)
            ),
            data: zone.receiverRouting,
          },
          keyfobZone: {
            included: true,
            data: false,
          },
        }))
    : [];
};

export const setIncludedFields = (
  templateKey: XRZoneInformationTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { zoneInformations } = getState(templateKey);

  if (!zoneInformations) {
    return;
  }

  zoneInformations.filter(isNotNullOrUndefined).forEach((zone) => {
    const number = String(zone.number);

    if (zone.included) {
      setIncludedFields(setToggle(zoneListItemTemplateId(number)));
      setIncludedFields(setToggle(zoneInformationNumberFieldId(number)));
    }
    if (zone.name?.included) {
      setIncludedFields(setToggle(zoneInformationNameFieldId(number)));
    }
    if (zone.location?.included) {
      setIncludedFields(setToggle(zoneInformationLocationFieldId(number)));
    }
    if (zone.type?.included) {
      setIncludedFields(setToggle(zoneInformationTypeFieldId(number)));
    }
    if (zone.area?.included) {
      setIncludedFields(setToggle(zoneInformationAreaFieldId(number)));
    }
    if (zone.followArea?.included) {
      setIncludedFields(setToggle(zoneInformationFollowAreaFieldId(number)));
    }
    if (zone.reportWithAccountNumberForArea?.included) {
      setIncludedFields(
        setToggle(zoneInformationReportWithAccountNumberFieldId(number))
      );
    }
    if (zone.armedAreasForArmingZone?.included) {
      setIncludedFields(setToggle(zoneInformationArmAreasFieldId(number)));
    }
    if (zone.disarmedOpenActionMessage?.included) {
      setIncludedFields(
        setToggle(zoneInformationDisarmedOpenMessageFieldId(number))
      );
    }
    if (zone.disarmedOpenOutputNumber?.included) {
      setIncludedFields(
        setToggle(zoneInformationDisarmedOpenOutputNumberFieldId(number))
      );
    }
    if (zone.fireBellOutputNumber?.included) {
      setIncludedFields(
        setToggle(zoneInformationFireBellOutputNumberFieldId(number))
      );
    }
    if (zone.disarmedOpenOutputAction?.included) {
      setIncludedFields(
        setToggle(zoneInformationDisarmedOpenOutputActionFieldId(number))
      );
    }
    if (zone.disarmedShortActionMessage?.included) {
      setIncludedFields(
        setToggle(zoneInformationDisarmedShortMessageFieldId(number))
      );
    }
    if (zone.disarmedShortOutputNumber?.included) {
      setIncludedFields(
        setToggle(zoneInformationDisarmedShortOutputNumberFieldId(number))
      );
    }
    if (zone.disarmedShortOutputAction?.included) {
      setIncludedFields(
        setToggle(zoneInformationDisarmedShortOutputActionFieldId(number))
      );
    }
    if (zone.armedOpenActionMessage?.included) {
      setIncludedFields(
        setToggle(zoneInformationArmedOpenMessageFieldId(number))
      );
    }
    if (zone.armedOpenOutputNumber?.included) {
      setIncludedFields(
        setToggle(zoneInformationArmedOpenOutputNumberFieldId(number))
      );
    }
    if (zone.armedOpenOutputAction?.included) {
      setIncludedFields(
        setToggle(zoneInformationArmedOpenOutputActionFieldId(number))
      );
    }
    if (zone.armedShortActionMessage?.included) {
      setIncludedFields(
        setToggle(zoneInformationArmedShortMessageFieldId(number))
      );
    }
    if (zone.armingStyle?.included) {
      setIncludedFields(setToggle(zoneInformationArmingStyleFieldId(number)));
    }
    if (zone.armedShortOutputNumber?.included) {
      setIncludedFields(
        setToggle(zoneInformationArmedShortOutputNumberFieldId(number))
      );
    }
    if (zone.armedShortOutputAction?.included) {
      setIncludedFields(
        setToggle(zoneInformationArmedShortOutputActionFieldId(number))
      );
    }
    if (zone.swingerBypassEnabled?.included) {
      setIncludedFields(setToggle(zoneInformationSwingerBypassFieldId(number)));
    }
    if (zone.retardDelayEnabled?.included) {
      setIncludedFields(
        setToggle(zoneInformationZoneRetardDelayFieldId(number))
      );
    }
    if (zone.fastResponseEnabled?.included) {
      setIncludedFields(setToggle(zoneInformationFastResponseFieldId(number)));
    }
    if (zone.prewarnKeypads?.included) {
      setIncludedFields(
        setToggle(zoneInformationPrewarnKeypadsFieldId(number))
      );
    }
    if (zone.presignalKeypads?.included) {
      setIncludedFields(
        setToggle(zoneInformationPresignalKeypadsFieldId(number))
      );
    }
    if (zone.crossZoneEnabled?.included) {
      setIncludedFields(setToggle(zoneInformationCrossZoneFieldId(number)));
    }
    if (zone.entryDelayNumber?.included) {
      setIncludedFields(setToggle(zoneInformationEntryDelayFieldId(number)));
    }
    if (zone.priorityZone?.included) {
      setIncludedFields(setToggle(zoneInformationPriorityZoneFieldId(number)));
    }
    if (zone.firePanelSlaveInput?.included) {
      setIncludedFields(
        setToggle(zoneInformationFirePanelSlaveInputFieldId(number))
      );
    }
    if (zone.realTimeStatusEnabled?.included) {
      setIncludedFields(
        setToggle(zoneInformationZoneRealTimeStatusFieldId(number))
      );
    }
    if (zone.trafficCountEnabled?.included) {
      setIncludedFields(setToggle(zoneInformationTrafficCountFieldId(number)));
    }
    if (zone.zoneAuditDays?.included) {
      setIncludedFields(setToggle(zoneInformationZoneAuditDaysFieldId(number)));
    }
    if (zone.wireless?.included) {
      setIncludedFields(setToggle(zoneInformationDMPWirelessFieldId(number)));
    }
    if (zone.contactNumber?.included) {
      setIncludedFields(setToggle(zoneInformationContactFieldId(number)));
    }
    if (zone.supervisionTime?.included) {
      setIncludedFields(
        setToggle(zoneInformationSupervisionTimeFieldId(number))
      );
    }
    if (zone.wirelessLedEnabled?.included) {
      setIncludedFields(setToggle(zoneInformationLEDOperationFieldId(number)));
    }
    if (zone.wirelessDisarmDisableEnabled?.included) {
      setIncludedFields(setToggle(zoneInformationDisarmDisableFieldId(number)));
    }
    if (zone.wirelessPirPulseCount?.included) {
      setIncludedFields(setToggle(zoneInformationPIRPulseCountFieldId(number)));
    }
    if (zone.wirelessPirSensitivity?.included) {
      setIncludedFields(
        setToggle(zoneInformationPIRSensitivityFieldId(number))
      );
    }
    if (zone.wirelessPetImmunity?.included) {
      setIncludedFields(
        setToggle(zoneInformationPIRPetImmunityFieldId(number))
      );
    }
    if (zone.chimeSound?.included) {
      setIncludedFields(setToggle(zoneInformationChimeSoundFieldId(number)));
    }
    if (zone.lockdownEnabled?.included) {
      setIncludedFields(setToggle(zoneInformationLockdownFieldId(number)));
    }
    //if( zone.normallyClosed?.included) {
    // TODO: Consider removing from schema includedFields.has(zoneInformationNor(number)))}
    if (zone.wirelessContactNormallyOpen?.included) {
      setIncludedFields(
        setToggle(zoneInformationContactNormallyOpenFieldId(number))
      );
    }
    if (zone.competitorWireless?.included) {
      setIncludedFields(
        setToggle(zoneInformationCompetitorWirelessFieldId(number))
      );
    }
    if (zone.sensorType?.included) {
      setIncludedFields(setToggle(zoneInformationSensorTypeFieldId(number)));
    }
    if (zone.receiverRouting?.included) {
      setIncludedFields(
        setToggle(zoneInformationReceiverRoutingFieldId(number))
      );
    }
  });
};
