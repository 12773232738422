import { RelayServiceType } from "app/common/services/relay-service";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import Select from "react-select";
import { react2angular } from "react2angular";
import TagField from "./TagField";

interface TagFieldRootProps {
  type: string;
  typeId?: number;
  $rootScope: any;
  UserService: any;
  ControlSystemsService: any;
  RelayService: RelayServiceType;
  labelColumns: number;
  inputColumns: number;
}
function TagFieldRoot(props: TagFieldRootProps) {
  const {
    type,
    $rootScope,
    UserService,
    ControlSystemsService,
    RelayService,
    labelColumns,
    inputColumns,
  } = props;
  let typeId: number;

  switch (type) {
    case "Panels":
      typeId = props.typeId ?? UserService.panel_id;
      break;
    case "Customers":
      typeId = props.typeId ?? parseInt(UserService.customer_id);
      break;
    case "CustomRoles":
      typeId = props.typeId ?? 0;
      break;
    default:
      typeId = 0;
  }

  return (
    <React.Suspense fallback={<Select isDisabled={true} isLoading={true} />}>
      <RelayEnvironmentProvider environment={RelayService.getEnvironment()}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <TagField
            dealerId={UserService.dealer_id}
            isEditable={UserService.canEditTags()}
            type={type}
            typeId={typeId}
            setTagsAreSaving={$rootScope.setTagsAreSaving}
            setCanSaveBillingTags={$rootScope.setCanSaveBillingTags}
            runCheckBillingTag={$rootScope.runCheckBillingTag}
            isSecurityCommand={UserService.enabledSecurityCommand()}
            UserService={UserService}
            ControlSystemsService={ControlSystemsService}
            labelColumns={labelColumns}
            inputColumns={inputColumns}
          />
        </AlertsContextProvider>
      </RelayEnvironmentProvider>
    </React.Suspense>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "tagField",
    react2angular(
      TagFieldRoot,
      ["type", "typeId", "labelColumns", "inputColumns"],
      ["$rootScope", "UserService", "RelayService", "ControlSystemsService"]
    )
  );
}
