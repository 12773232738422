/**
 * @generated SignedSource<<8cfcb921a39be8ec5ee9682919ea96c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CrudType = "CREATE" | "DELETE" | "UPDATE";
export type VernacularsErrors = "ALL_FAILED" | "BAD_INPUT" | "BAD_PARAMS" | "BAD_REQUEST" | "CONFLICT" | "NO_CONTROL_SYSTEM" | "NO_DEALER" | "UNAUTHORIZED" | "UNKNOWN";
export type VernacularSuperMutationInputs = {
  crudType: CrudType;
  original: string;
  replacement: string;
  vernacularId: number;
};
export type SecCommandOptionsFormSuperMutation$variables = {
  dealerId: string;
  inputArray: ReadonlyArray<VernacularSuperMutationInputs>;
};
export type SecCommandOptionsFormSuperMutation$data = {
  readonly vernacularSuperMutation: {
    readonly apiResponses?: ReadonlyArray<{
      readonly errorMessage: string | null;
      readonly original: string;
      readonly result: string;
    }>;
    readonly dealer?: {
      readonly vernaculars: ReadonlyArray<{
        readonly dealerId: number;
        readonly id: string;
        readonly original: string;
        readonly replacement: string;
      } | null>;
    };
    readonly errorMessage?: string;
    readonly type?: VernacularsErrors;
  };
};
export type SecCommandOptionsFormSuperMutation = {
  response: SecCommandOptionsFormSuperMutation$data;
  variables: SecCommandOptionsFormSuperMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputArray"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "dealerId",
    "variableName": "dealerId"
  },
  {
    "kind": "Variable",
    "name": "inputArray",
    "variableName": "inputArray"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errorMessage",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "VernacularApiResponses",
  "kind": "LinkedField",
  "name": "apiResponses",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "result",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Vernacular",
  "kind": "LinkedField",
  "name": "vernaculars",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dealerId",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "replacement",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "VernacularSuperMutationErrorResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SecCommandOptionsFormSuperMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "vernacularSuperMutation",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Dealer",
                "kind": "LinkedField",
                "name": "dealer",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "VernacularSuperMutationSuccessResponse",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SecCommandOptionsFormSuperMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "vernacularSuperMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Dealer",
                "kind": "LinkedField",
                "name": "dealer",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "VernacularSuperMutationSuccessResponse",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19d1fa366f93a107977e1ae5d591cf5c",
    "id": null,
    "metadata": {},
    "name": "SecCommandOptionsFormSuperMutation",
    "operationKind": "mutation",
    "text": "mutation SecCommandOptionsFormSuperMutation(\n  $dealerId: String!\n  $inputArray: [VernacularSuperMutationInputs!]!\n) {\n  vernacularSuperMutation(dealerId: $dealerId, inputArray: $inputArray) {\n    __typename\n    ... on VernacularSuperMutationSuccessResponse {\n      apiResponses {\n        errorMessage\n        original\n        result\n      }\n      dealer {\n        vernaculars {\n          id\n          dealerId\n          original\n          replacement\n        }\n        id\n      }\n    }\n    ... on VernacularSuperMutationErrorResponse {\n      errorMessage\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "30a7035fc04fac9b31ae99cd92b8426e";

export default node;
