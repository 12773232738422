App.directive("daAuditTable", [
  "AuditService",
  "$rootScope",
  "UserService",
  "$sanitize",
  "DAYS_OF_WEEK",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$q",
  "ODataService",
  "$compile",
  "$filter",
  "DMP_EMPLOYEE_PROPERTIES",
  "DataTablesUtilsService",
  "$stateParams",
  "$state",
  function (
    AuditService,
    $rootScope,
    UserService,
    $sanitize,
    DAYS_OF_WEEK,
    DTOptionsBuilder,
    DTColumnBuilder,
    $q,
    ODataService,
    $compile,
    $filter,
    DMP_EMPLOYEE_PROPERTIES,
    DataTablesUtilsService,
    $stateParams,
    $state
  ) {
    const customerReplacement =
      UserService.dealerInfo.vernaculars.customers.replacement || "Customer";

    const systemReplacement =
      UserService.dealerInfo.vernaculars.systems.replacement || "System";
    return {
      restrict: "E",

      scope: {
        type: "@",
        auditItemId: "=",
        showFullAudit: "@",
        daysOfActivity: "=?",
        showFullReportOption: "=",
      },

      templateUrl: "/app/common/templates/audit-table-tpl.html",

      link: function (scope) {
        // Local variables
        scope.dataCallComplete = false;
        scope.initSystemTable = initSystemTable;
        scope.updateDaysOfActivity = updateDaysOfActivity;

        // On page initial load daysOfActivity is undefined - set it to 30.
        if (scope.daysOfActivity === undefined) scope.daysOfActivity = 30;

        var activePersonnelIds = [];
        var activeCustomers = [];

        // Watcher for determining how much activity to show
        var daysOfActivityChangeWatcher = scope.$watch(
          "daysOfActivity",
          function (newVal, oldVal) {
            if (newVal !== oldVal) {
              updateDaysOfActivity();

              // Assigning the watcher to a variable and calling that variable as a function will unbind the watcher
              // A detailed explanation here: https://www.bennadel.com/blog/2480-unbinding-watch-listeners-in-angularjs.htm
              daysOfActivityChangeWatcher();
            }
          }
        );

        // Define the table options for the audit table
        scope.dtOptions = DataTablesUtilsService.getDTOptions(
          getList(),
          "Rows per page"
        )
          .withOption("stateSave", true)
          .withOption("deferRender", true)
          .withOption("order", [0, "desc"])
          // sets pagination to allow only a previous and next button
          .withPaginationType("simple_numbers")
          // sets initial display length of of 10 rows
          .withDisplayLength(10)
          //calls the 'createRow' function to bind the sref directive to the hyperlinks
          .withOption("createdRow", function (row) {
            DataTablesUtilsService.createdRow(row, scope);
          })
          //sets location of tools in the DOM
          .withDOM(
            '<"data-table__top" <"data-table__top--left"fr> <"data-table__top--right"B>><t><"data-table__bottom"<"data-table__bottom--left" i><"data-table__bottom--center"p><"data-table__bottom--right"l>>'
          )
          .withBootstrap()
          .withBootstrapOptions({
            sFilter: {
              classes: {
                input: "input-xs",
              },
            },
            pagination: {
              classes: {
                ul: "pagination pagination-sm",
              },
            },
          });

        if (scope.showFullReportOption) {
          scope.dtOptions = scope.dtOptions.withButtons([
            {
              className: "btn-sm full-report-btn",
              text: "See Full Report",

              action: function (e, dt, node, config) {
                switch (scope.type) {
                  case "system": {
                    $state.go("app.dealer.single_system_audit", {
                      dealer_id: UserService.dealerInfo.id,
                      control_system_id: $stateParams.control_system_id,
                    });
                    break;
                  }
                  case "site": {
                    $state.go("app.dealer.site_audit", {
                      dealer_id: UserService.dealerInfo.id,
                      site_id: $stateParams.site_id,
                    });
                    break;
                  }
                  case "personnel": {
                    $state.go("app.dealer.single_person_audit", {
                      dealer_id: UserService.dealerInfo.id,
                      user_id: $stateParams.user_id,
                    });
                    break;
                  }
                }
              },
            },
            {
              extend: "collection",
              className: "btn-sm",
              text: "Export",
              buttons: [
                "csv",
                {
                  extend: "pdf",
                  orientation: "landscape",
                  exportOptions: {
                    columns: ":visible",
                  },
                },
              ],
            },
          ]);
        } else {
          scope.dtOptions = scope.dtOptions.withButtons([
            {
              extend: "collection",
              className: "btn-sm",
              text: "Export",
              buttons: [
                "csv",
                {
                  extend: "pdf",
                  orientation: "landscape",
                  exportOptions: {
                    columns: ":visible",
                  },
                },
              ],
            },
          ]);
        }

        // Initialize columns based on table type
        switch (scope.type) {
          case "system":
            scope.dtColumns = [
              DTColumnBuilder.newColumn(null)
                .withTitle("Date/Time")
                .renderWith(function (data, type, row) {
                  return DataTablesUtilsService.asDateTimeFormatted(
                    row,
                    "event_at"
                  );
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle("Name/Email")
                .renderWith(function (data, type, row) {
                  return formatNameEmailCombo(row);
                }),

              DTColumnBuilder.newColumn(null)
                .withTitle("Action")
                .renderWith(function (data, type, row) {
                  return `<span title="${row.descriptor}">${row.descriptor}</span>`;
                }),
            ];
            break;
          case "site":
            scope.dtColumns = [
              DTColumnBuilder.newColumn(null)
                .withTitle("Date/Time")
                .renderWith(function (data, type, row) {
                  return DataTablesUtilsService.asDateTimeFormatted(
                    row,
                    "event_at"
                  );
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle("Name/Email")
                .renderWith(function (data, type, row) {
                  return formatNameEmailComboSite(row);
                }),

              DTColumnBuilder.newColumn(null)
                .withTitle("Action")
                .renderWith(function (data, type, row) {
                  return `<span title="${row.batch_message}">${row.batch_message}</span>`;
                }),
            ];
            break;
          case "personnel":
            scope.dtColumns = [
              DTColumnBuilder.newColumn(null)
                .withTitle("Date/Time")
                .renderWith(function (data, type, row) {
                  return DataTablesUtilsService.asDateTimeFormatted(
                    row,
                    "event_at"
                  );
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle(customerReplacement)
                .renderWith(function (data, type, row) {
                  return formatCustomerCell(row);
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle(systemReplacement)
                .renderWith(function (data, type, row) {
                  return formatSystemCell(row);
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle("Action")
                .renderWith(function (data, type, row) {
                  return `<span title="${row.descriptor}">${row.descriptor}</span>`;
                }),
            ];
            break;
          case "dealer":
            scope.dtColumns = [
              DTColumnBuilder.newColumn(null)
                .withTitle("Date/Time")
                .renderWith(function (data, type, row) {
                  return DataTablesUtilsService.asDateTimeFormatted(
                    row,
                    "event_at"
                  );
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle("Role")
                .renderWith(function (data, type, row) {
                  return formatRoleCell(row);
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle("Name")
                .renderWith(function (data, type, row) {
                  return formatNameCell(row);
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle("Email")
                .renderWith(function (data, type, row) {
                  return formatEmailCell(row);
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle(customerReplacement)
                .renderWith(function (data, type, row) {
                  return formatCustomerCell(row);
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle(systemReplacement)
                .renderWith(function (data, type, row) {
                  return formatSystemCell(row);
                }),
              DTColumnBuilder.newColumn(null)
                .withTitle("Action")
                .renderWith(function (data, type, row) {
                  return `<span title="${row.descriptor}">${row.descriptor}</span>`;
                }),
            ];
            break;
          default:
            scope.dtColumns = [];
            break;
        }

        function getList() {
          switch (scope.type) {
            case "system":
              return initSystemTable();
            case "personnel":
              return initPersonnelData();
            case "dealer":
              return initDealerData();
            case "site":
              return initSiteTable();
            default:
              break;
          }
        }

        function initSiteTable() {
          var deferred = $q.defer();
          // Get active personnel to determine if anchors can be built for email attached to event
          ODataService.getPersonnelIds()
            .then(
              function (personnel) {
                var ids = [];
                angular.forEach(personnel, function (person) {
                  ids.push(person.id);
                });
                activePersonnelIds = ids;

                AuditService.getSiteLogs(scope.auditItemId, scope.showFullAudit)
                  .then(
                    function (data) {
                      scope.dataCallComplete = true;
                      deferred.resolve(data);
                    },
                    function () {
                      scope.dataCallComplete = true;
                      $rootScope.alerts.push({
                        type: "error",
                        text: "error getting event logs",
                      });
                      // resolve an empty array so datatable doesn't show "Loading..."
                      deferred.resolve([]);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function () {
                scope.dataCallComplete = true;
                $rootScope.alerts.push({
                  type: "error",
                  text: "error getting active personnel",
                });
                // resolve an empty array so datatable doesn't show "Loading..."
                deferred.resolve([]);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        }

        function initSystemTable() {
          var deferred = $q.defer();
          // Get active personnel to determine if anchors can be built for email attached to event
          ODataService.getPersonnelIds()
            .then(
              function (personnel) {
                var ids = [];
                angular.forEach(personnel, function (person) {
                  ids.push(person.id);
                });
                activePersonnelIds = ids;
                AuditService.getSystemLogs(
                  scope.auditItemId,
                  scope.showFullAudit
                )
                  .then(
                    function (data) {
                      scope.dataCallComplete = true;
                      deferred.resolve(data);
                    },
                    function () {
                      scope.dataCallComplete = true;
                      $rootScope.alerts.push({
                        type: "error",
                        text: "error getting event logs",
                      });
                      // resolve an empty array so datatable doesn't show "Loading..."
                      deferred.resolve([]);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function () {
                scope.dataCallComplete = true;
                $rootScope.alerts.push({
                  type: "error",
                  text: "error getting active personnel",
                });
                // resolve an empty array so datatable doesn't show "Loading..."
                deferred.resolve([]);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        }

        function initPersonnelData() {
          var deferred = $q.defer();
          ODataService.getCustomerIdsAndControlSystemIds()
            .then(
              function (customers) {
                activeCustomers = customers;
                AuditService.getPersonnelLogs(
                  scope.auditItemId,
                  scope.showFullAudit
                )
                  .then(
                    function (data) {
                      scope.dataCallComplete = true;
                      deferred.resolve(data);
                    },
                    function () {
                      scope.dataCallComplete = true;
                      $rootScope.alerts.push({
                        type: "error",
                        text: "error getting event logs",
                      });
                      // resolve an empty array so datatable doesn't show "Loading..."
                      deferred.resolve([]);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function () {
                scope.dataCallComplete = true;
                $rootScope.alerts.push({
                  type: "error",
                  text: "error getting customers",
                });
                // resolve an empty array so datatable doesn't show "Loading..."
                deferred.resolve([]);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        }

        function initDealerData() {
          var deferred = $q.defer();
          var promise = null;
          switch ($rootScope.stateChange.next.name) {
            case "app.dealer.app_user_audit":
              promise = AuditService.getDealerAppUserLogs(scope.daysOfActivity);
              break;
            case "app.dealer.personnel_audit":
              promise = AuditService.getDealerPersonnelLogs(
                scope.daysOfActivity
              );
              break;
            case "app.dealer.systems_audit":
              promise = AuditService.getDealerSystemsLogs(scope.daysOfActivity);
              break;
            case "app.dealer.activity_audit":
              promise = AuditService.getAllDealerLogs(scope.daysOfActivity);
              break;
            default:
              deferred.resolve([]);
              break;
          }
          promise
            .then(
              function (data) {
                scope.dataCallComplete = true;
                deferred.resolve(data);
              },
              function () {
                scope.dataCallComplete = true;
                $rootScope.alerts.push({
                  type: "error",
                  text: "error getting event logs",
                });
                // resolve an empty array so datatable doesn't show "Loading..."
                deferred.resolve([]);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        }

        function updateDaysOfActivity() {
          var auditTable = angular.element(
            document.querySelector("#auditTable")
          );
          $compile(auditTable)(scope);
        }

        //function to recompile the directive code in the table cell via the '.withOption('createdRow', createdRow)' Data Table option
        function createdRow(row, data, dataIndex) {
          // Recompiling so we can bind Angular directive to the DT
          $compile(angular.element(row).contents())(scope);
        }

        function formatDateTimeCell(log) {
          // note: Javascript date constructor will use time zone offset of local computer.
          var eventAt = new Date(log.event_at);
          var days = Object.keys(DAYS_OF_WEEK);
          var day = "";
          for (var i = 0; i < days.length; i++) {
            if (DAYS_OF_WEEK[days[i]].number === eventAt.getDay() + 1) {
              day = DAYS_OF_WEEK[days[i]].abbr;
              break;
            }
          }
          return `<span title="${day} ${eventAt.toLocaleDateString()} ${eventAt.toLocaleTimeString()}">${day} ${eventAt.toLocaleDateString()}<span class="app-user-cell__sub-row"> ${eventAt.toLocaleTimeString()}</span></span>`;
        }

        function formatNameCell(log) {
          let name = "";
          switch (log.event_type) {
            case "CACHE_SYNC":
              name = !isMobileCredentialDelete(log)
                ? DMP_EMPLOYEE_PROPERTIES.CACHE_SYNC_DISPLAY_NAME // if it's not a credential delete it a backup
                : log.accessible_type === "Supervisor" &&
                  !UserService.isSupervisorAccessible() // If the event was caused by a supervisor type login but the logged in user is not a supervisor type login, don't show the name
                ? ""
                : formatName(log);
              break;
            case "PROG_BACKUP":
              //name = angular.fromJson(log.event_data).BackupType === 'Manual' ? formatName(log) : DMP_EMPLOYEE_PROPERTIES.PROG_ARCHIVE_DISP_NAME;
              name =
                log.action === "restoralResult" ||
                isManualBackupData(log.event_data)
                  ? DMP_EMPLOYEE_PROPERTIES.PROG_ARCHIVE_DISP_NAME
                  : formatName(log);
              break;
            default:
              // If the event was caused by a supervisor type login but the logged in user is not a supervisor type login, don't show the name
              name =
                log.accessible_type === "Supervisor" &&
                !UserService.isSupervisorAccessible()
                  ? ""
                  : formatName(log);
              break;
          }
          // If there's a name, user id, the logged in user is authorized to view users and the audited user exists, make an anchor
          var anchor =
            name !== "" &&
            +log.user_id &&
            UserService.canViewUsers() &&
            UserService.canViewPersonnel() &&
            activePersonnelIds.indexOf(log.user_id) > -1 > 0
              ? `<a class="link link-primary" title="${name}"  ui-sref="app.dealer.personnel_edit({dealer_id: ${UserService.dealer_id}, user_id: ${log.user_id}})">${name}</a>`
              : null;
          return anchor || name;
        }

        function formatSiteNameCell(log) {
          let name = formatName(log);
          // If there's a name, user id, the logged in user is authorized to view users and the audited user exists, make an anchor
          var anchor =
            name !== "" &&
            +log.person_id &&
            UserService.canViewUsers() &&
            UserService.canViewPersonnel() &&
            activePersonnelIds.indexOf(log.person_id) > -1 > 0
              ? `<a class="link link-primary" title="${name}"  ui-sref="app.dealer.personnel_edit({dealer_id: ${UserService.dealer_id}, user_id: ${log.user_id}})">${name}</a>`
              : null;
          return anchor || name;
        }

        function isManualBackupData(eventData) {
          let backupData = angular.fromJson(eventData);
          return (
            backupData &&
            backupData.BackupType &&
            backupData.BackupType !== "Manual"
          );
        }

        function isMobileCredentialDelete(eventData) {
          return (
            eventData.event_type === "CACHE_SYNC" &&
            eventData.concept === "credential" &&
            eventData.action === "delete"
          );
        }

        function formatName(log) {
          return (
            ($sanitize(log.first_name) || "") +
            " " +
            ($sanitize(log.last_name) || "")
          ).trim();
        }

        function formatEmailCell(log) {
          let email = "";
          switch (log.event_type) {
            case "CACHE_SYNC":
              if (isMobileCredentialDelete(log)) {
                if (
                  log.accessible_type === "Supervisor" &&
                  !UserService.isSupervisorAccessible()
                ) {
                  email =
                    log.email === "serviceuser@dmp.com"
                      ? DMP_EMPLOYEE_PROPERTIES.AUTOMATIC_PROGRAMMING
                      : DMP_EMPLOYEE_PROPERTIES.TECH_SUPPORT_EMAIL;
                } else {
                  email = formatEmail(log);
                }
              }
              break;
            case "PROG_BACKUP":
              //  if (angular.fromJson(log.event_data).BackupType === 'Manual') {
              //    email = formatEmail(log);
              //  }
              if (isManualBackupData(log.event_data)) {
                email = formatEmail(log);
              }
              break;
            default:
              if (
                log.accessible_type === "Supervisor" &&
                !UserService.isSupervisorAccessible()
              ) {
                email =
                  log.email === "serviceuser@dmp.com"
                    ? DMP_EMPLOYEE_PROPERTIES.AUTOMATIC_PROGRAMMING
                    : DMP_EMPLOYEE_PROPERTIES.TECH_SUPPORT_EMAIL;
              } else {
                email = formatEmail(log);
              }
              break;
          }
          // If there's an email, user id, the logged in user is authorized to view users and the audited user exists, make an anchor
          var anchor =
            email !== "" &&
            email !== DMP_EMPLOYEE_PROPERTIES.TECH_SUPPORT_EMAIL &&
            +log.user_id > 0 &&
            UserService.canViewUsers() &&
            UserService.canViewPersonnel() &&
            activePersonnelIds.indexOf(log.user_id) > -1
              ? `<a class="link link-primary" title="${email}"  tooltip-popup-delay="800" ui-sref="app.dealer.personnel_edit({dealer_id: ${UserService.dealer_id}, user_id: ${log.user_id}})">${email}</a>`
              : null;
          return anchor || email;
        }

        function formatSiteEmailCell(log) {
          let email = "";
          if (
            log.accessible_type === "Supervisor" &&
            !UserService.isSupervisorAccessible()
          ) {
            email =
              log.email === "serviceuser@dmp.com"
                ? DMP_EMPLOYEE_PROPERTIES.AUTOMATIC_PROGRAMMING
                : DMP_EMPLOYEE_PROPERTIES.TECH_SUPPORT_EMAIL;
          } else {
            email = formatSiteEmail(log);
          }

          // If there's an email, user id, the logged in user is authorized to view users and the audited user exists, make an anchor
          var anchor =
            email !== "" &&
            email !== DMP_EMPLOYEE_PROPERTIES.TECH_SUPPORT_EMAIL &&
            +log.user_id > 0 &&
            UserService.canViewUsers() &&
            UserService.canViewPersonnel() &&
            activePersonnelIds.indexOf(log.person_id) > -1
              ? `<a class="link link-primary" title="${email}"  tooltip-popup-delay="800" ui-sref="app.dealer.personnel_edit({dealer_id: ${UserService.dealer_id}, user_id: ${log.user_id}})">${email}</a>`
              : null;
          return anchor || email;
        }

        function formatNameEmailCombo(log) {
          var name = formatNameCell(log);
          var email = formatEmailCell(log);
          var tableString =
            '<div class="cell-ellipsis">' +
            '<div class="table-emphasis">' +
            name +
            "</div><div>" +
            email +
            "</div></div>";
          return tableString;
        }

        function formatNameEmailComboSite(log) {
          var name = formatSiteNameCell(log);
          var email = formatSiteEmailCell(log);
          var tableString =
            '<div class="cell-ellipsis">' +
            '<div class="table-emphasis">' +
            name +
            "</div><div>" +
            email +
            "</div></div>";
          return tableString;
        }
        function formatSiteEmail(log) {
          return $sanitize(log.email_address).trim();
        }
        function formatEmail(log) {
          return $sanitize(log.email).trim();
        }

        function formatCustomerCell(log) {
          var name =
            `<span tooltip="${$sanitize(
              log.customer_name
            ).trim()}" tooltip-popup-delay="800">${$sanitize(
              log.customer_name
            ).trim()}</span>` || "";
          var customer = activeCustomers.find(function (c) {
            return c.id === log.customer_id;
          });
          var anchor =
            +log.customer_id > 0 &&
            name !== "" &&
            UserService.canViewCustomers() &&
            angular.isDefined(customer)
              ? `<span tooltip="${$sanitize(log.customer_name).trim()} - ${
                  log.customer_id
                }})" tooltip-popup-delay="800"><a class="link link-primary table-emphasis" ui-sref="app.customers.customersummary({dealer_id: ${
                  UserService.dealer_id
                }, customer_id: ${log.customer_id}})">${$sanitize(
                  log.customer_name
                ).trim()}</a></span>`
              : null;
          return anchor || name;
        }

        function formatSystemCell(log) {
          var systemName =
            `<span tooltip="${$sanitize(
              log.control_system_name
            ).trim()}" tooltip-popup-delay="800">${$sanitize(
              log.control_system_name
            ).trim()}</span>` || "";
          var customer = activeCustomers.find(function (_customer) {
            return _customer.id === log.customer_id;
          });
          var controlSystem = angular.isUndefined(customer)
            ? undefined
            : customer.control_systems.find(function (_system) {
                return _system.id === log.control_system;
              });
          var anchor =
            +log.control_system > 0 &&
            systemName !== "" &&
            +log.customer_id > 0 &&
            UserService.canViewSystems() &&
            customer &&
            controlSystem
              ? `<a class="link link-primary" tooltip="${$sanitize(
                  log.control_system_name
                ).trim()}" tooltip-popup-delay="800" ui-sref="app.control_system({customer_id: ${
                  log.customer_id
                }, control_system_id: ${log.control_system}})">${$sanitize(
                  log.control_system_name
                ).trim()}</a>`
              : null;
          return anchor || systemName;
        }

        function formatRoleCell(log) {
          var accessibleType = log.accessible_type || "";
          var role = log.role || "";
          var displayRole = accessibleType;
          switch (accessibleType.toLowerCase()) {
            case "supervisor":
              displayRole = DMP_EMPLOYEE_PROPERTIES.AUDIT_DISPLAY_ROLE;
              break;
            case "dealer":
              displayRole = $sanitize(
                $filter("authority_levels")(role.toLowerCase())
              );
              break;
            default:
              break;
          }
          return `<span tooltip=" ${displayRole} " tooltip-popup-delay="800">${displayRole}</span>`;
        }
      },
      controller: function ($scope, $stateParams) {
        $scope.control_system_id = $stateParams.control_system_id;
      },
    };
  },
]);
