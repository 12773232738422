import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { remoteOptionsAlarmReceiverAuthorizationFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAlarmReceiverAuthorizationField";
import { remoteOptionsAppKeyFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import { remoteOptionsArmedAnswerRingsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsArmedAnswerRingsField";
import { remoteOptionsDisarmedAnswerRingsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsDisarmedAnswerRingsField";
import { remoteOptionsRemoteDisarmFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemoteDisarmField";
import { remoteOptionsServiceReceiverAuthorizationFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsServiceReceiverAuthorizationField";
import { TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/TMSentryFullProgramming/__generated__/TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getRemoteOptionsState } from "../../../TMSentryFullProgramming/TMSentryRemoteOptionsProgrammingConceptForm";
import { XtProgrammingTemplateRemoteOptionsInput } from "../Create/__generated__/TMSentryTemplateSaveMutation.graphql";
import { TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key } from "./__generated__/TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts.graphql";
import { remoteOptionsEntreArmDisarmReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreArmDisarmReportsField";
import { remoteOptionsEntreBackupConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupConnectionField";
import { remoteOptionsEntreBackupIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupIpAddress";
import { remoteOptionsEntreBackupTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupTcpPortField";
import { remoteOptionsEntreCheckInMinutesFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreCheckInMinutesField";
import { remoteOptionsEntreConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreConnectionField";
import { remoteOptionsEntreIncomingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreIncomingTcpPortField";
import { remoteOptionsEntreIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreIpAddressField";
import { remoteOptionsEntreOutgoingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreOutgoingTcpPortField";
import { remoteOptionsEntrePassphraseFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntrePassphraseField";
import { remoteOptionsEntreSupervisoryReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreSupervisoryReportsField";
import { remoteOptionsEntreUserCommandReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreUserCommandReportsField";
import { remoteOptionsEntreZoneReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreZoneReportsField";

export const getState = (
  template: TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          id
          included
          remoteDisarm {
            included
            data
          }
          appKey {
            included
            data
          }
          armedAnswerRings {
            included
            data
          }
          disarmedAnswerRings {
            included
            data
          }
          alarmReceiverAuthorization {
            included
            data
          }
          serviceReceiverAuthorization {
            included
            data
          }
          entreConnection {
            included
            data
          }
          entreIncomingPort {
            included
            data
          }
          entreIpAddress {
            included
            data
          }
          entreOutgoingPort {
            included
            data
          }
          entreBackupConnection {
            included
            data
          }
          entreBackupIpAddress {
            included
            data
          }
          entreBackupIncomingPort {
            included
            data
          }
          entreCheckInMinutes {
            included
            data
          }
          entreArmDisarmReports {
            included
            data
          }
          entreZoneReports {
            included
            data
          }
          entreUserCommandReports {
            included
            data
          }
          entreSupervisoryReports {
            included
            data
          }
          validEntreCheckinMinutes {
            included
            data
          }
          entrePassphrase {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XtProgrammingTemplateRemoteOptionsInput => {
  const {
    panel: { remoteOptions },
  } = getRemoteOptionsState(controlSystem);

  if (!remoteOptions) {
    return { included: false };
  }

  const remoteOptionsInputFields = {
    remoteDisarm: {
      included: includedFields.has(remoteOptionsRemoteDisarmFieldId()),
      data: remoteOptions.remoteDisarm,
    },
    appKey: {
      included: includedFields.has(remoteOptionsAppKeyFieldId()),
      data: remoteOptions.appKey,
    },
    armedAnswerRings: {
      included: includedFields.has(remoteOptionsArmedAnswerRingsFieldId()),
      data: Number(remoteOptions.armedAnswerRings),
    },
    disarmedAnswerRings: {
      included: includedFields.has(remoteOptionsDisarmedAnswerRingsFieldId()),
      data: Number(remoteOptions.disarmedAnswerRings),
    },
    alarmReceiverAuthorization: {
      included: includedFields.has(
        remoteOptionsAlarmReceiverAuthorizationFieldId()
      ),
      data: remoteOptions.alarmReceiverAuthorization,
    },
    serviceReceiverAuthorization: {
      included: includedFields.has(
        remoteOptionsServiceReceiverAuthorizationFieldId()
      ),
      data: remoteOptions.serviceReceiverAuthorization,
    },
    entreConnection: {
      data: remoteOptions.entreConnection,
      included: includedFields.has(remoteOptionsEntreConnectionFieldId()),
    },
    entreIncomingPort: {
      data: remoteOptions.entreIncomingPort,
      included: includedFields.has(
        remoteOptionsEntreIncomingTcpPortFieldId()
      ),
    },
    entreIpAddress: {
      data: remoteOptions.entreIpAddress,
      included: includedFields.has(remoteOptionsEntreIpAddressFieldId()),
    },
    entreOutgoingPort: {
      data: remoteOptions.entreOutgoingPort,
      included: includedFields.has(
        remoteOptionsEntreOutgoingTcpPortFieldId()
      ),
    },
    entreBackupConnection: {
      data: remoteOptions.entreBackupConnection,
      included: includedFields.has(
        remoteOptionsEntreBackupConnectionFieldId()
      ),
    },
    entreBackupIpAddress: {
      data: remoteOptions.entreBackupIpAddress,
      included: includedFields.has(
        remoteOptionsEntreBackupIpAddressFieldId()
      ),
    },
    entreBackupIncomingPort: {
      data: remoteOptions.entreBackupIncomingPort,
      included: includedFields.has(
        remoteOptionsEntreBackupTcpPortFieldId()
      ),
    },
    entreCheckInMinutes: {
      data: remoteOptions.entreCheckInMinutes,
      included: includedFields.has(
        remoteOptionsEntreCheckInMinutesFieldId()
      ),
    },
    entreArmDisarmReports: {
      data: remoteOptions.entreArmDisarmReports,
      included: includedFields.has(
        remoteOptionsEntreArmDisarmReportsFieldId()
      ),
    },
    entreZoneReports: {
      data: remoteOptions.entreZoneReports,
      included: includedFields.has(
        remoteOptionsEntreZoneReportsFieldId()
      ),
    },
    entreUserCommandReports: {
      data: remoteOptions.entreUserCommandReports,
      included: includedFields.has(
        remoteOptionsEntreUserCommandReportsFieldId()
      ),
    },
    entreSupervisoryReports: {
      data: remoteOptions.entreSupervisoryReports,
      included: includedFields.has(
        remoteOptionsEntreSupervisoryReportsFieldId()
      ),
    },
    entrePassphrase: {
      data: remoteOptions.entrePassphrase,
      included: includedFields.has(remoteOptionsEntrePassphraseFieldId()),
    },
  };

  const remoteOptionsIncluded = Object.values(remoteOptionsInputFields).some(
    ({ included }) => included
  );

  return {
    included: remoteOptionsIncluded,
    ...remoteOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { remoteOptions } = getState(template);

  if (!remoteOptions) return;

  if (remoteOptions.remoteDisarm?.included) {
    setIncludedFields(setToggle(remoteOptionsRemoteDisarmFieldId()));
  }
  if (remoteOptions.appKey?.included) {
    setIncludedFields(setToggle(remoteOptionsAppKeyFieldId()));
  }
  if (remoteOptions.armedAnswerRings?.included) {
    setIncludedFields(setToggle(remoteOptionsArmedAnswerRingsFieldId()));
  }
  if (remoteOptions.disarmedAnswerRings?.included) {
    setIncludedFields(setToggle(remoteOptionsDisarmedAnswerRingsFieldId()));
  }
  if (remoteOptions.alarmReceiverAuthorization?.included) {
    setIncludedFields(
      setToggle(remoteOptionsAlarmReceiverAuthorizationFieldId())
    );
  }
  if (remoteOptions.serviceReceiverAuthorization?.included) {
    setIncludedFields(
      setToggle(remoteOptionsServiceReceiverAuthorizationFieldId())
    );
  }
  //Entre Options
  if (remoteOptions.entreConnection?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreConnectionFieldId()));
  }
  if (remoteOptions.entreIncomingPort?.included) {
    setIncludedFields(
      setToggle(remoteOptionsEntreIncomingTcpPortFieldId())
    );
  }
  if (remoteOptions.entreIpAddress?.included) {
    setIncludedFields(
      setToggle(remoteOptionsEntreIpAddressFieldId())
    );
  }
  if (remoteOptions.entreOutgoingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreOutgoingTcpPortFieldId()));
  }
  if (remoteOptions.entreBackupConnection?.included) {
    setIncludedFields(
      setToggle(remoteOptionsEntreBackupConnectionFieldId())
    );
  }
  if (remoteOptions.entreBackupIpAddress?.included) {
    setIncludedFields(
      setToggle(remoteOptionsEntreBackupIpAddressFieldId())
    );
  }
  if (remoteOptions.entreBackupIncomingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreBackupTcpPortFieldId()));
  }
  if (remoteOptions.entreCheckInMinutes?.included) {
    setIncludedFields(
      setToggle(remoteOptionsEntreCheckInMinutesFieldId())
    );
  }
  if (remoteOptions.entreArmDisarmReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsEntreArmDisarmReportsFieldId())
    );
  }
  if (remoteOptions.entreZoneReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsEntreZoneReportsFieldId())
    );
  }
  if (remoteOptions.entreUserCommandReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreUserCommandReportsFieldId()));
  }
  if (remoteOptions.entreSupervisoryReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsEntreSupervisoryReportsFieldId())
    );
  }
  if (remoteOptions.entrePassphrase?.included) {
    setIncludedFields(
      setToggle(remoteOptionsEntrePassphraseFieldId())
    );
  }
};
