App.controller("LowCellSignalUsersCtrl", [
  "$scope",
  "$q",
  "$filter",
  "$compile",
  "$rootScope",
  "UserService",
  "DashboardDataService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "DealerODataAPI",
  "DataTablesUtilsService",
  function (
    $scope,
    $q,
    $filter,
    $compile,
    $rootScope,
    UserService,
    DashboardDataService,
    DTOptionsBuilder,
    DTColumnBuilder,
    DealerODataAPI,
    DataTablesUtilsService
  ) {
    $scope.UserService = UserService;
    $scope.dealerID = UserService.dealer_id;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(panelsWithLowSig());
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    $scope.dtOptions.withOption("order", [0, "asc"], [1, "asc"], [3, "asc"]);

    const customerReplacement =
      UserService.dealerInfo.vernaculars.customers.replacement || "Customer";
    const systemReplacement =
      UserService.dealerInfo.vernaculars.systems.replacement || "System";

    function panelsWithLowSig() {
      var deferred = $q.defer();
      DashboardDataService.getPanelsWithLowCellSignal($scope.dealerID)
        .then(
          function (panels) {
            deferred.resolve(panels);
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("control_system.customer.name").withTitle(
        `${customerReplacement} Name`
      ),
      DTColumnBuilder.newColumn("control_system.name").withTitle(
        `${systemReplacement} Name`
      ),
      DTColumnBuilder.newColumn("control_system.customer.email").withTitle(
        `${customerReplacement} Email`
      ),
      DTColumnBuilder.newColumn(
        "panel_statistics[0].weakest_cell_signal"
      ).withTitle("Signal"),
      DTColumnBuilder.newColumn("control_system.postal_code")
        .withTitle("Postal Code")
        .notVisible(),
      DTColumnBuilder.newColumn("control_system.address_1")
        .withTitle("Address")
        .notVisible(),
      DTColumnBuilder.newColumn("control_system.city")
        .withTitle("City")
        .notVisible(),
      DTColumnBuilder.newColumn("control_system.state")
        .withTitle("State")
        .notVisible(),
      DTColumnBuilder.newColumn("control_system.country")
        .withTitle("Country")
        .notVisible(),
    ];
  },
]);
