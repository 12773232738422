/**
 * Component: SystemEditDropdown
 *
 * Description:
 * The `SystemEditDropdown` component is a React functional component used to render a dropdown menu
 * with various system-related actions. It provides a dynamic and interactive interface for users to
 * perform actions like editing system details, viewing guides, creating service requests, moving systems,
 * or deleting systems, based on their permissions.
 *
 * Props:
 * - Various props including service URLs (`INSTALL_GUIDE`, `PROGRAMMING_GUIDE`), system details (`system`),
 *   user service permissions (`userService`), and functions for handling different actions like
 *   `moveSystem`, `serviceRequestModal`, `tempUserModal`, `authorizeTempAppUser`, `openDeleteSystemModal`.
 *
 * State:
 * - `isOpen`: A boolean state that manages the visibility of the dropdown menu.
 *
 * Behavior:
 * - The component uses a `useState` hook to manage the dropdown's open/close state.
 * - It utilizes a `useRef` hook to reference the dropdown DOM element for detecting clicks outside to
 *   close the dropdown.
 * - The `useEffect` hook is used to add and clean up the click event listener for closing the dropdown.
 * - The dropdown contains multiple conditional renderings based on user permissions, provided through
 *   the `userService` prop.
 * - Actions such as editing, moving, and deleting systems are triggered by callback functions passed
 *   as props, and these actions are only available based on the user's permissions.
 * - External links for installation and programming guides are dynamically created based on the system's
 *   hardware model.
 *
 * Styling:
 * - The component uses styled-components for specific elements like `DropDownText`, `IconDropDown`,
 *   `DropDownParentSpan`, and `DropDownParentLink` for consistent and isolated styling.
 *
 * Usage:
 * This component is intended for use in interfaces where systems are managed, providing a consistent
 * and permission-based set of actions for each system in a list or table.
 */

import DaColors from "app/common/DaColors";
import React, { useEffect, useRef, useState } from "react";
import {
  asID,
  fromControlSystemId,
  PanelHardwareModel,
} from "securecom-graphql/client";
import styled, { css } from "styled-components/macro";
import Icon from "../Icon";
import { System } from "./CustomerSummarySystemTableComponent";

interface UserService {
  canCreateTempDealerCustomerUser: () => boolean;
  canAuthorizeLoginAsCustomer: () => boolean;
  canEditSystems: () => boolean;
  canDeleteSystems: () => boolean;
  canViewServiceRequests: () => boolean;
  canMoveSystems: () => boolean;
  enabledAllowTechSupportLogInAsCustomer: () => boolean;
  enabledSecurityCommand: () => boolean;
}

interface SystemEditDropdownProps {
  INSTALL_GUIDE: any;
  navToSystemUrl: any;
  PROGRAMMING_GUIDE: any;
  TEMP_DEALER_APP_USER: any;
  state: any;
  system: System;
  userService: UserService;
  moveSystem: any;
  serviceRequestModal: any;
  tempUserModal: any;
  authorizeTempAppUser: any;
  openDeleteSystemModal: any;
  openMoveSystemModal: any;
  openHandleBillableSystemModal: any;
  customerId: any;
  UserService: any;
  stateParams: any;
  systemReplacement?: string;
  billingControlSystemId: number;
  pickNewBillableSystemList?: {
    id: string | undefined;
    name: string | undefined;
    siteBillingControlSystemId: string | undefined;
    isX1: boolean | undefined;
  }[];
  mobileWalletCredsEnabled: boolean;
}

const SystemEditDropdown: React.FC<SystemEditDropdownProps> = ({
  INSTALL_GUIDE,
  navToSystemUrl,
  PROGRAMMING_GUIDE,
  TEMP_DEALER_APP_USER,
  state,
  system,
  userService,
  moveSystem,
  serviceRequestModal,
  tempUserModal,
  authorizeTempAppUser,
  openDeleteSystemModal,
  openMoveSystemModal,
  openHandleBillableSystemModal,
  customerId,
  UserService,
  stateParams,
  systemReplacement,
  billingControlSystemId,
  pickNewBillableSystemList,
  mobileWalletCredsEnabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };
  const [hasMobileWalletCredsEnabled] = React.useState(
    mobileWalletCredsEnabled
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const { systemId } = fromControlSystemId(asID(system.id));
  return (
    <div className="dropdown-select pull-right" ref={dropdownRef}>
      <div className={`dropdown-select ${isOpen ? "open" : ""}`}>
        <span
          className="dropdown-select__link dropdown-toggle"
          onClick={toggleDropdown}
        >
          <Icon
            name="more"
            style={{ margin: "-.5rem" }}
            size="2rem"
            color="var(--color-primary)"
          />
        </span>
      </div>
      {isOpen && (
        <div>
          <DropdownMenu className="dropdown-select__container">
            <li className="dropdown-select__item">
              {/**Dealer Login As Customer (visible on mobile) */}
              {userService.canCreateTempDealerCustomerUser() &&
              (system.panel.softwareDate ||
                [PanelHardwareModel.X001].includes(
                  system.panel.hardwareModel as PanelHardwareModel
                )) ? (
                <DropDownParentSpan
                  className="hidden-md hidden-lg da-temp-app-user"
                  onClick={() => tempUserModal(Number(systemId))}
                  hoverColor="white"
                >
                  <div className="flex--start--center">
                    <IconDropDown
                      name="armed_shield_solid"
                      className="icon-20 mar-r-8"
                      hoverColor="white"
                    />
                    <DropDownText>
                      {TEMP_DEALER_APP_USER.BUTTON_LABEL}
                    </DropDownText>
                  </div>
                </DropDownParentSpan>
              ) : null}
              {/** Authorize Support */}
              {userService.canAuthorizeLoginAsCustomer() &&
              userService.enabledAllowTechSupportLogInAsCustomer() &&
              (system.panel.softwareDate ||
                [PanelHardwareModel.X001].includes(
                  system.panel.hardwareModel as PanelHardwareModel
                )) ? (
                <DropDownParentSpan
                  onClick={() => {
                    authorizeTempAppUser(Number(systemId));
                  }}
                  hoverColor="white"
                >
                  <div className="flex--start--center">
                    <IconDropDown
                      name="armed_shield_solid"
                      className="icon-20 mar-r-8"
                      hoverColor="white"
                    />
                    <DropDownText>
                      {TEMP_DEALER_APP_USER.AUTHORIZE_BUTTON_LABEL}
                    </DropDownText>
                  </div>
                </DropDownParentSpan>
              ) : null}
              {/** Edit */}
              {userService.canEditSystems() ? (
                <DropDownParentSpan
                  hoverColor="white"
                  onClick={(e) => {
                    e.preventDefault();
                    navToSystemUrl(
                      system,
                      state,
                      UserService,
                      customerId,
                      stateParams
                    );
                  }}
                >
                  <div className="flex--start--center">
                    <IconDropDown
                      name="settings"
                      hoverColor="white"
                      className="icon-20 mar-r-8"
                    />
                    <DropDownText>Edit</DropDownText>
                  </div>
                </DropDownParentSpan>
              ) : null}
              {/** Install Guide */}
              <DropDownParentLink
                href={`${INSTALL_GUIDE.uri}/${
                  INSTALL_GUIDE[system.panel.hardwareModel]
                }`}
                hoverColor="white"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex--start--center">
                  <IconDropDown
                    name="install_guide"
                    className="icon-20 mar-r-8"
                    hoverColor="white"
                  />
                  <DropDownText>Installation Guide</DropDownText>
                </div>
              </DropDownParentLink>
              {/** Programming Guide */}
              <DropDownParentLink
                href={`${PROGRAMMING_GUIDE.uri}/${
                  PROGRAMMING_GUIDE[system.panel.hardwareModel]
                }`}
                hoverColor="white"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex--start--center">
                  <IconDropDown
                    name="install_guide"
                    className="icon-20 mar-r-8"
                    hoverColor="white"
                  />
                  <DropDownText>Programming Guide</DropDownText>
                </div>
              </DropDownParentLink>
              {/** Create Service Request */}
              {userService.canViewServiceRequests() &&
              !system.isX1 &&
              !userService.enabledSecurityCommand() ? (
                <DropDownParentSpan
                  onClick={() => {
                    serviceRequestModal(Number(systemId));
                  }}
                >
                  <div className="flex--start--center">
                    <IconDropDown
                      name="troubleshooting"
                      className="icon-20 mar-r-8"
                      hoverColor="white"
                    />
                    <DropDownText>Create Service Request</DropDownText>
                  </div>
                </DropDownParentSpan>
              ) : null}
              {/** Move System */}
              {userService.canMoveSystems() && !system.isX1 ? (
                <DropDownParentSpan
                  onClick={() => {
                    // if the customer has a billing_control_system_id
                    //and systemId for the system to be moved is the same,
                    //open the billable system modal to pick a new billable system
                    //else open moveSystem
                    if (Number(billingControlSystemId) === Number(systemId)) {
                      openHandleBillableSystemModal(
                        Number(systemId),
                        system,
                        pickNewBillableSystemList,
                        undefined,
                        "move"
                      );
                    } else {
                      moveSystem(Number(systemId));
                    }
                  }}
                >
                  <div className="flex--start--center">
                    <IconDropDown
                      name="move_system"
                      className="icon-20 mar-r-8"
                      hoverColor="white"
                    />
                    <DropDownText>Move {systemReplacement}</DropDownText>
                  </div>
                </DropDownParentSpan>
              ) : null}
              {/** Delete */}
              {userService.canDeleteSystems() ? (
                <DeleteDropDownParentSpan
                  onClick={() => {
                    // if the customer has a billing_control_system_id
                    //and systemId for the system to be deleted is the same,
                    //open the billable system modal to pick a new billable system
                    //else open openDeleteSystemModal

                    if (Number(billingControlSystemId) === Number(systemId)) {
                      openHandleBillableSystemModal(
                        Number(systemId),
                        system,
                        pickNewBillableSystemList,
                        undefined,
                        "delete"
                      );
                    } else {
                      openDeleteSystemModal(Number(systemId), system);
                    }
                  }}
                >
                  <div className="flex--start--center">
                    <Icon name="trash" className="icon-20 mar-r-8" />
                    <span>Delete</span>
                  </div>
                </DeleteDropDownParentSpan>
              ) : null}
            </li>
          </DropdownMenu>
        </div>
      )}
    </div>
  );
};

export default SystemEditDropdown;

export const DropDownText = styled.span`
  
  font-weight: bold
  &:hover {
    color: white!important; 
  }
`;

const commonDropDownStyles = css`
  display: flex;
  margin-bottom: 0;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.188rem 0.625rem;
  font-size: 14px;
  line-height: 1.52857;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const DropDownParentSpan = styled.span<{ hoverColor?: string }>`
  ${commonDropDownStyles}
  color: black; /* Default text color */
  &:hover {
    background-color: ${DaColors.PrimaryBase}!important;
    color: white !important;

    svg {
      color: ${({ hoverColor }) => hoverColor || "currentColor"} !important;
    }
  }
`;

export const IconDropDown = styled(Icon)<{ hoverColor?: string }>`
  color: #5f5f5f;
  &:hover {
    color: ${({ hoverColor }) => hoverColor || "currentColor"};
  }
`;

export const DropDownParentLink = styled.a<{ hoverColor?: string }>`
  ${commonDropDownStyles}
  &:hover {
    text-decoration: none;
    background-color: ${DaColors.PrimaryBase};
    color: white !important;
    transition: all 0.2s ease-out;

    svg {
      color: ${({ hoverColor }) => hoverColor || "currentColor"} !important;
    }
  }
`;

const DropdownMenu = styled.ul`
  transform: translateX(-20rem);
  position: absolute;
  z-index: 1000;
  min-width: 10rem;
  padding: 0.3125rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid #e1e1e1;
  border-radius: 0.25rem;
  box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.175);
  display: block;
`;

export const DeleteDropDownParentSpan = styled.span<{ hoverColor?: string }>`
  ${commonDropDownStyles}
  color: black; /* Default text color */
  &:hover {
    background-color: red !important;
    color: white !important;

    svg {
      color: white !important;
    }

    span {
      color: white !important;
    }
  }
`;
