import graphql from "babel-plugin-relay/macro";
import FadeInOut from "common/components/web/FadeInOut";
import Flex from "common/components/web/Flex";
import Select from "components/Select";
import { useStateDotGo } from "components/SystemDiagnostics/AngularStateProvider";
import { FULL_PROGRAMMING_TEMPLATE_ID } from "components/Templates/Templates";
import * as React from "react";
import { useFragment } from "react-relay";
import {
  asID,
  fromDealerId,
  PanelHardwareModel,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import { useTakeoverTemplateSaveMutation } from "../Templates/TakeoverTemplate/Create/TakeoverTemplateSaveHook";
import { useTMSentryTemplateSaveMutation } from "../Templates/TMSentryTemplate/Create/TMSentryTemplateSaveHook";
import { useXFTemplateSaveMutation } from "../Templates/XFTemplate/Create/XFTemplateSaveHook";
import { useXRTemplateSaveMutation } from "../Templates/XRTemplate/Create/XRTemplateSaveHook";
import { useXT75TemplateSaveMutation } from "../Templates/XT75Template/Create/XT75TemplateSaveHook";
import { useXTTemplateSaveMutation } from "../Templates/XTTemplate/Create/XTTemplateSaveHook";
import { resolvePanelType } from "../utils/panel";
import { useControlSystemFragment } from "./ControlSystemContext";
import { useIncludedTemplateFields } from "./IncludedTemplateFieldsContext";
import { useResetLastUpdated } from "./LastUpdatedContext";
import PrimaryButton from "./PrimaryButton";
import StandardButton from "./StandardButton";
import { EditingFrom, useTemplateContext } from "./TemplateContext";
import { ProgrammingTemplateForm_controlSystem$key } from "./__generated__/ProgrammingTemplateForm_controlSystem.graphql";
import { ProgrammingTemplateForm_dealer$key } from "./__generated__/ProgrammingTemplateForm_dealer.graphql";

const Form = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: var(--measure-2x);
`;
const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-right: var(--measure-2x);
    position: relative;
  }
`;
const Label = styled.label`
  margin: 0 var(--measure-1x) 0 0;
  white-space: pre;
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-left: var(--measure-1x);

  & ${PrimaryButton}, & ${StandardButton} {
    margin-left: var(--measure-1x);
  }
`;

export default function ProgrammingTemplateForm(props: {
  dealer: ProgrammingTemplateForm_dealer$key;
}) {
  const data = useFragment(
    graphql`
      fragment ProgrammingTemplateForm_dealer on Dealer {
        id
        vernaculars {
          id
          original
          replacement
        }
        customersConnection {
          edges {
            node {
              id
              name
            }
          }
        }
        programmingTemplates {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    props.dealer
  );

  const [controlSystem] =
    useControlSystemFragment<ProgrammingTemplateForm_controlSystem$key>(graphql`
      fragment ProgrammingTemplateForm_controlSystem on ControlSystem {
        ...XTTemplateSaveHook_controlSystem
        ...XRTemplateSaveHook_controlSystem
        ...XT75TemplateSaveHook_controlSystem
        ...XFTemplateSaveHook_controlSystem
        ...TakeoverTemplateSaveHook_controlSystem
        ...TMSentryTemplateSaveHook_controlSystem
      }
    `);

  const [includedFields] = useIncludedTemplateFields();

  const {
    editingFrom,
    hardwareModel,
    setHardwareModel,
    templateName: name,
    templateId: id,
    customerId: customer,
  } = useTemplateContext();
  const { isXr, isTakeoverPanel, isXt75, isXf, isTMS6 } =
    resolvePanelType(hardwareModel);
  const [templateName, setTemplateName] = React.useState(name);
  const [customerId, setCustomerId] = React.useState(customer);
  const [saveTakeoverTemplate, isSavingTakeoverTemplate] =
    useTakeoverTemplateSaveMutation(controlSystem);

  const [saveXtTemplate, isSavingXtTemplate] =
    useXTTemplateSaveMutation(controlSystem);

  const [saveXrTemplate, isSavingXrTemplate] =
    useXRTemplateSaveMutation(controlSystem);

  const [saveTMSentryTemplate, isSavingTMSentryTemplate] =
    useTMSentryTemplateSaveMutation(controlSystem);
  const [saveXfTemplate, isSavingXfTemplate] =
    useXFTemplateSaveMutation(controlSystem);

  const [saveXt75Template, isSavingXt75Template] =
    useXT75TemplateSaveMutation(controlSystem);

  const otherTemplateNames = React.useMemo(() => {
    return new Set(
      data.programmingTemplates.edges
        .filter(({ node }) => node?.id !== id)
        .map(({ node }) => node?.name.toUpperCase().trim())
    );
  }, [data.programmingTemplates.edges, id]);

  const isDuplicated = React.useMemo(() => {
    return templateName
      ? otherTemplateNames.has(templateName.toUpperCase().trim())
      : false;
  }, [otherTemplateNames, templateName]);

  const nav = useStateDotGo();
  const resetLastUpdated = useResetLastUpdated();
  const headless = window.location.search.includes("headless=true");

  const customerVerns = data.vernaculars.find(
    (v) => v?.original === "customers"
  );
  const systemVerns = data.vernaculars.find((v) => v?.original === "systems");
  const systemReplacement = systemVerns?.replacement ?? "System";

  return (
    <FadeInOut visible>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <Flex alignItems="flex-end">
          <FieldWrapper>
            <Label className="control-label" htmlFor="template-name">
              Template Name<span className="text-danger">*</span>
            </Label>

            <input
              type="text"
              id="template-name"
              className="form-control"
              value={templateName ?? ""}
              required
              onChange={({ target }) => {
                setTemplateName(String(target.value ?? ""));
              }}
            />

            <span
              style={{ position: "absolute" }}
              hidden={!isDuplicated}
              className="text-danger"
            >
              Name Must Be Unique
            </span>
          </FieldWrapper>
          {(editingFrom === EditingFrom.NEW_TEMPLATE_PAGE ||
            editingFrom === EditingFrom.EDIT_TEMPLATE_PAGE) && (
            <FieldWrapper>
              <Label className="control-label" htmlFor="template-system-type">
                {systemReplacement} Type
              </Label>
              <Select
                id="template-system-type"
                disabled={editingFrom === EditingFrom.EDIT_TEMPLATE_PAGE}
                value={hardwareModel}
                onChange={({ target }) => {
                  setHardwareModel(
                    target.value as PanelHardwareModel,
                    templateName ?? ""
                  );
                  resetLastUpdated(FULL_PROGRAMMING_TEMPLATE_ID);
                  includedFields.clear();
                }}
              >
                <Select.Option value={PanelHardwareModel.XTLP}>
                  XTLplus/XTLtouch
                </Select.Option>
                <Select.Option value={PanelHardwareModel.XT30}>
                  XT30
                </Select.Option>
                <Select.Option value={PanelHardwareModel.XT50}>
                  XT50
                </Select.Option>
                <Select.Option value={PanelHardwareModel.XT75}>
                  XT75
                </Select.Option>
                <Select.Option value={PanelHardwareModel.XR150}>
                  XR150
                </Select.Option>
                <Select.Option value={PanelHardwareModel.XR550}>
                  XR550
                </Select.Option>
                <Select.Option value={PanelHardwareModel.CELLCOM_EX}>
                  CellComEX
                </Select.Option>
                <Select.Option value={PanelHardwareModel.CELLCOM_SL}>
                  CellComSL
                </Select.Option>
                <Select.Option value={PanelHardwareModel.DUALCOM}>
                  DualCom
                </Select.Option>
                <Select.Option value={PanelHardwareModel.TMS6}>
                  TMSentry
                </Select.Option>
                <Select.Option value={PanelHardwareModel.XF6_100}>
                  XF6-100
                </Select.Option>
                <Select.Option value={PanelHardwareModel.XF6_500}>
                  XF6-500
                </Select.Option>
              </Select>
            </FieldWrapper>
          )}
          <FieldWrapper>
            <Label className="control-label" htmlFor="template-customer">
              {customerVerns ? customerVerns.replacement : "Customer"}
            </Label>
            <Select
              id="template-customer"
              value={customerId}
              onChange={({ target }) => {
                setCustomerId(target.value);
              }}
            >
              <Select.Option value="">
                All {customerVerns ? customerVerns.replacement : "Customers"}
              </Select.Option>
              {data.customersConnection.edges.map(
                ({ node }) =>
                  node && (
                    <Select.Option key={node.id} value={node.id}>
                      {node.name}
                    </Select.Option>
                  )
              )}
            </Select>
          </FieldWrapper>
        </Flex>
        <Buttons>
          <PrimaryButton
            size="small"
            disabled={
              !templateName ||
              isSavingXtTemplate ||
              isSavingXrTemplate ||
              isSavingTakeoverTemplate ||
              isSavingXfTemplate ||
              isSavingXt75Template ||
              isDuplicated ||
              isSavingTMSentryTemplate
            }
            type="submit"
            onClick={() => {
              if (isXr) {
                saveXrTemplate(
                  includedFields,
                  templateName?.trim() ?? "",
                  customerId,
                  data.id
                );
              } else if (isTakeoverPanel) {
                saveTakeoverTemplate(
                  includedFields,
                  templateName?.trim() ?? "",
                  customerId,
                  data.id
                );
              } else if (isTMS6) {
                saveTMSentryTemplate(
                  includedFields,
                  templateName?.trim() ?? "",
                  customerId,
                  data.id
                );
              } else if (isXf) {
                saveXfTemplate(
                  includedFields,
                  templateName?.trim() ?? "",
                  customerId,
                  data.id
                );
              } else if (isXt75) {
                saveXt75Template(
                  includedFields,
                  templateName?.trim() ?? "",
                  customerId,
                  data.id
                );
              } else {
                saveXtTemplate(
                  includedFields,
                  templateName?.trim() ?? "",
                  customerId,
                  data.id
                );
              }
            }}
          >
            Save Template
          </PrimaryButton>
          {editingFrom === EditingFrom.PROGRAMMING_PAGE ? (
            <StandardButton
              size="small"
              type="button"
              onClick={() => {
                nav(
                  "app.panel_programming.programming",
                  headless ? { headless: "true" } : {},
                  { reload: true }
                );
              }}
              disabled={false}
            >
              Cancel
            </StandardButton>
          ) : (
            <StandardButton
              size="small"
              type="button"
              onClick={() => {
                nav(
                  "app.dealer.default_programming",
                  {
                    dealer_id: fromDealerId(asID(data.id)).dealerId,
                    ...(headless && { headless: "true" }),
                  },
                  { reload: true }
                );
              }}
              disabled={false}
            >
              Cancel
            </StandardButton>
          )}
        </Buttons>
      </Form>
    </FadeInOut>
  );
}
