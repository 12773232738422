import { fetchImageSrcFromUrlWithBearerAuth } from "common/utils/web/image";
import { union } from "ramda";

/**
 * @ngdoc object
 * @name App.controller:DashboardCtrl
 *
 *
 * @description
 *  Controller for the Dashboard view
 */
App.controller("DashboardCtrl", [
  "$scope",
  "$rootScope",
  "$window",
  "$state",
  "$http",
  "$timeout",
  "$filter",
  "$q",
  "focus",
  "DashboardDataService",
  "CustomRolesService",
  "UserService",
  "dealer_id",
  "NewsItemsService",
  "NgMap",
  "control_system_comm_typeFilter",
  "control_system_hardware_modelFilter",
  "$sessionStorage",
  "$sanitize",
  "MAP_SETTINGS",
  "UserSettingsService",
  "PROPS",
  "DealerModemService",
  "PanelEventsService",
  "TagsService",
  "$modal",
  "Customer",
  function (
    $scope,
    $rootScope,
    $window,
    $state,
    $http,
    $timeout,
    $filter,
    $q,
    focus,
    DashboardDataService,
    CustomRolesService,
    UserService,
    dealer_id,
    NewsItemsService,
    NgMap,
    control_system_comm_typeFilter,
    control_system_hardware_modelFilter,
    $sessionStorage,
    $sanitize,
    MAP_SETTINGS,
    UserSettingsService,
    PROPS,
    DealerModemService,
    PanelEventsService,
    TagsService,
    $modal,
    Customer
  ) {
    $scope.getDashboardData = getDashboardData;
    $scope.getDashboardMapData = getDashboardMapData;
    $scope.filterByProperties = filterByProperties;
    $scope.getValuesFor = getValuesFor;
    $scope.allowedFilters = allowedFilters;
    $scope.filterMarkers = filterMarkers;
    $scope.getFilterKeys = getFilterKeys;
    $scope.clearFilters = clearFilters;
    $scope.clearBooleanFilters = clearBooleanFilters;
    $scope.selectAllOther = selectAllOther;
    $scope.selectPanelsWithNoAddress = selectPanelsWithNoAddress;
    $scope.exportData = exportData;
    $scope.toggleLayer = toggleLayer;
    $scope.weatherLayer = null;
    $scope.dataIsLinkedToMap = true;
    $scope.showMap = false;
    $scope.filteredPanelCount = 0;
    $scope.enableReset = false;
    $scope.tabledata = {};
    $scope.tabledata.IsLinkedToMap = false;
    $scope.filterPills = {};
    $scope.selectTimeTitle = "Last 12 Months";
    $scope.selectGraphTitle = "Customers Added";
    $scope.open = false;
    $scope.chartType = "LineChart";
    $scope.showForX1 = (customerId, controlSystemId, hardwareModel) => {
      return customerId && controlSystemId && hardwareModel === "X001";
    };
    $scope.showForNonX1 = (customerId, controlSystemId, hardwareModel) => {
      return (
        Boolean(customerId) &&
        Boolean(controlSystemId) &&
        Boolean(hardwareModel !== "X001")
      );
    };
    var dynMarkers = [];
    var booleanFilters = [
      "arm_inactivity",
      "cell_signal",
      "trouble",
      "alarm",
      "low_battery",
      "ac_trouble",
    ];
    $scope.booleanFilters = booleanFilters;
    $scope.customerListView = []; // contains enough data to go to a new system edit page for that customer
    var dealer_id = UserService.dealer_id;
    $scope.dealerId = dealer_id;
    $scope.sortReverse = false;
    $scope.no_address_data = {};
    $scope.no_address_data.value = false;
    $scope.markerCount = 0;
    $scope.$state = $state;
    $scope.colSort = {};
    $scope.colSort.predicate = "customer_name";
    $scope.colSort.sortReverse = false;
    $scope.chartTitle = "";
    $scope.pageLoaded = false;
    $scope.todaysNews = [];
    $scope.verizonCoverageDataAvailable = false;
    $scope.attCoverageDataAvailable = false;
    $scope.cellCoverageLayer = null;
    $scope.modemCount = 0;
    $scope.totalAffectedCameras = 0;
    $scope.tagsMap = new Map();
    $scope.roleHasCheckInRestriction = () =>
      UserService.userHasSystemCheckInRestriction();
    $scope.restrictedRoleCanViewAllCustomers =
      $scope.roleHasCheckInRestriction &&
      UserService.user?.customRole?.view_all_customers;
    $scope.claimedSystems = [];
    const MILLISECONDS_IN_ONE_DAY = 86400000;
    $scope.newPanelCustomer;
    var mapId = "map-" + dealer_id;
    $scope.validVernaculars = UserService.dealerInfo.vernaculars;

    $scope.toggleShowMap = () => ($scope.showMap = !$scope.showMap);
    // Focus on search bar
    $timeout(function () {
      focus("dashboardSearch");
    }, 300);

    $scope.$watch(
      "dashboardSettings",
      function (newValue, oldValue) {
        // We don't want to update the user settings on the initial declaration of the dashboardSettings
        if (
          !Object.is(newValue, UserSettingsService.getUserSettingsDefaults())
        ) {
          if (
            !UserService.isParentDealerLogin() &&
            !UserService.isSupervisorAccessible()
          ) {
            // Don't set user settings if the user is a supervisor type or a parent dealer
            UserSettingsService.updateUserSettings(
              "DealerAdmin",
              $scope.dashboardSettings
            );
          }
          filterMarkers();
          filterTable();
        }
      },
      true
    );

    //watch if the map is opened and re query as the map needs all data
    $scope.$watch(
      "showMap",
      function () {
        if ($scope.showMap) {
          getDashboardMapData(true);
        }
      },
      true
    );

    //watch if the map has filters applied to apply them to the table data
    $scope.$watch(
      "dashboardSettings.mapFilters",
      function () {
        $scope.dashboardSettings.tableFilters = {};

        if ($scope.dashboardSettings.mapFilters) {
          if ($scope.dashboardSettings.mapFilters.hardware_model) {
            $scope.dashboardSettings.tableFilters.k = Object.assign(
              $scope.dashboardSettings.mapFilters.hardware_model
            );
          }

          if ($scope.dashboardSettings.mapFilters.comm_type) {
            $scope.dashboardSettings.tableFilters.l = Object.assign(
              $scope.dashboardSettings.mapFilters.comm_type
            );
          }

          if ($scope.dashboardSettings.mapFilters.carrier) {
            $scope.dashboardSettings.tableFilters.t = Object.assign(
              $scope.dashboardSettings.mapFilters.carrier
            );
          }
        }
      },
      true
    );

    function setMapInfo(data) {
      if (data === null || data.hasUserSettings === undefined) {
        UserSettingsService.setDefaults("DealerAdmin");
        $scope.defaultDashboardSettings();
      } else {
        if (
          !UserService.isParentDealerLogin() &&
          !UserService.isSupervisorAccessible()
        ) {
          // Don't get user settings if the user is a supervisor type or a parent dealer
          $scope.dashboardSettings = data;
        } else {
          const userDefaults = UserSettingsService.getUserSettingsDefaults();
          $scope.dashboardSettings = Object.assign({}, userDefaults);
          $scope.dashboardSettings.mapFilters = Object.assign(
            {},
            userDefaults.mapFilters
          );
          $scope.dashboardSettings.pagination = Object.assign(
            {},
            userDefaults.pagination
          );
          $scope.dashboardSettings.tableColumns = Object.assign(
            {},
            userDefaults.tableColumns
          );
        }
      }
      setMapTheme();
    }
    $scope.setNewPanelCustomer = function (customer) {
      $scope.newPanelCustomer = customer;
    };
    $scope.navigateToNewSystemView = function (customer) {
      $state.go("app.control_system_new", {
        customer_id: customer.id,
        control_system_id: "new",
      });
    };
    $scope.defaultDashboardSettings = function () {
      $scope.dashboardSettings = UserSettingsService.getUserSettingsDefaults();
    };

    //CAMERA BANNER LOGIC START
    $scope.navigateToCameraOfflineReport = () => {
      $state.go("app.dealer.camera-status-report", { report_type: "offline" });
    };

    const getTotalAffectedCameras = async () =>
      ($scope.totalAffectedCameras =
        await DashboardDataService.getVideoDeviceFirmwareUpdateCountForDealer(
          dealer_id
        ).then((count) => count));

    //download firmware button click
    $scope.downloadGen1FirmwareFile = async () => {
      $scope.isDownloadingGen1Firmware = true;
      const suggestedFileName = "Firmware_gen_1_211027.dav";
      const downloadUrl =
        "https://logos.securecomwireless.com/Uploads/Firmware/Gen1/digicap.dav";

      const blob = await fetch(downloadUrl).then((res) => {
        return res.blob();
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("type", "hidden");
      link.setAttribute("target", "_self");
      link.setAttribute("download", suggestedFileName);
      link.setAttribute("href", url);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
      $scope.isDownloadingGen1Firmware = false;
      $scope.$apply();
    };

    $scope.downloadGen2FirmwareFile = async () => {
      $scope.isDownloadingGen2Firmware = true;
      const suggestedFileName = "Firmware_gen_2_211027.dav";
      const downloadUrl =
        "https://logos.securecomwireless.com/Uploads/Firmware/digicap.dav";
      const blob = await fetch(downloadUrl).then((res) => {
        return res.blob();
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("type", "hidden");
      link.setAttribute("target", "_self");
      link.setAttribute("download", suggestedFileName);
      link.setAttribute("href", url);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
      $scope.isDownloadingGen2Firmware = false;
      $scope.$apply();
    };

    $scope.downloadNvrFirmwareFile = async () => {
      $scope.isDownloadingNvrFirmware = true;
      const suggestedFileName = "Firmware_nvr_250327.dav";
      const downloadUrl =
        "https://logos.securecomwireless.com/Uploads/Firmware/NVR/nvr.dav";
      const blob = await fetch(downloadUrl).then((res) => {
        return res.blob();
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("type", "hidden");
      link.setAttribute("target", "_self");
      link.setAttribute("download", suggestedFileName);
      link.setAttribute("href", url);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
      $scope.isDownloadingNvrFirmware = false;
      $scope.$apply();
    };

    $scope.downloadDvrFirmwareFile = async () => {
      $scope.isDownloadingDvrFirmware = true;
      const suggestedFileName = "Firmware_dvr_v3.3.4.dav";
      const downloadUrl =
        "https://logos.securecomwireless.com/Uploads/Firmware/DVR/dvr.dav";
      const blob = await fetch(downloadUrl).then((res) => {
        return res.blob();
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("type", "hidden");
      link.setAttribute("target", "_self");
      link.setAttribute("download", suggestedFileName);
      link.setAttribute("href", url);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
      $scope.isDownloadingDvrFirmware = false;
      $scope.$apply();
    };

    //CAMERA BANNER LOGIC END

    $scope.$watch(
      "UserService",
      function (newValue) {
        //ensure this is only updating with the proper account information otherwise sso users generate 402 errors
        if (
          UserService.user &&
          (!$rootScope.$storage.SCAPIUsers ||
            UserService.user.accessible_id ===
              JSON.parse($rootScope.$storage.SCAPIUsers)[
                $rootScope.$storage.currentAccount
              ].user.accessible_id)
        ) {
          UserSettingsService.getUserSettings("DealerAdmin")
            .then(
              function (data) {
                setMapInfo(data);
              },
              function (error) {
                console.error(error);
                setMapInfo(null);
              }
            )
            .catch(function (error) {
              setMapInfo(null);
            });
        }
      },
      true
    );

    const getSunsetModemCount = () => {
      if (new Date().getFullYear() > 2022) {
        // We don't want to show this banner after 2022, return 0
        $scope.modemCount = 0;
        return;
      }

      DealerModemService.getSunsetModems(UserService.dealer_id).then(
        (modemCount) => {
          $scope.modemCount = parseInt(modemCount);
          const today = new Date().getTime();

          const lastDayOfYear = new Date("2022-12-31T00:00:00");

          // Days between now and the end of the year multiplied by 5/7 to get
          // roughly how many days are left (this doesn't need to be super accurate)
          const workingDaysLeft =
            ((lastDayOfYear - today) / MILLISECONDS_IN_ONE_DAY) * (5 / 7);
          $scope.modemsPerDay = parseInt(
            Math.ceil(modemCount / workingDaysLeft)
          );
        },
        (error) => {}
      );
    };

    const getTagsDictionary = async () => {
      const tagsRelations = await TagsService.getPanelRelations(dealer_id);

      // Creating a Map where the key is the tag name and the value are Sets of customer/system/site ids associated with that tag
      tagsRelations.forEach((relation) => {
        const relationsSets = {
          tagCustomerIds: new Set(relation.tagCustomerIds),
          tagPanelIds: new Set(relation.tagPanelIds),
          tagSiteIds: new Set(relation.tagSiteIds),
        };

        $scope.tagsMap.set(
          relation.dealerTag.name.toLowerCase(),
          relationsSets
        );
      });
    };

    $scope.openAddSystemModal = function () {
      $scope.addSystemModal = $modal.open({
        templateUrl: "app/common/templates/role-limited-add-system-modal.html",
        windowClass: "",
        size: "md",
        backdrop: true,
        scope: $scope,
      });
    };

    $scope.isDisabledForRoleRestrictions = function (systemID) {
      if (!$scope.roleHasCheckInRestriction()) {
        return false;
      }
      return !$scope.thisSystemClaimedByCurrentUser(systemID);
    };

    $scope.isThisTestSystemClaimed = (id) =>
      $scope.claimedSystems.includes(Number(id));

    /**
     * Initialize the DashboardCtrl
     */
    function init() {
      $scope.defaultDashboardSettings();
      checkForCellCoverageData();
      setMapTheme();
      getSunsetModemCount();
      getTagsDictionary();
      getTotalAffectedCameras();

      $scope.thisSystemClaimedByCurrentUser = function (systemId) {
        return (
          $scope.roleHasCheckInRestriction() &&
          $scope.isThisTestSystemClaimed(systemId)
        );
      };
      Promise.all([UserService.waitForUser()]).then(
        ([user]) =>
          new Promise((resolve) => {
            getDashboardData();
          })
      );

      // Grab the customers for the dealer
      DashboardDataService.getAbbreviatedCustomerList(dealer_id)
        .then(
          function (customers) {
            $scope.customerList = customers.map((customer) => ({
              id: customer.id,
              name: customer.name,
            }));
          },
          function (error) {
            console.error("customerList Error", error);
          }
        )
        .catch(function (error) {
          console.error("catch Error", error);
        });
    }

    Promise.all([UserService.waitForUser()]).then(
      ([user]) =>
        new Promise((resolve) => {
          CustomRolesService.claimedSystems(
            dealer_id,
            UserService.user.id
          ).then(
            function (systems) {
              $scope.claimedSystems = systems.map((system) => system.panel_id);
              return $scope.claimedSystems;
            },
            function (error) {
              console.error("Error getting claimed systems: ", error);
            }
          );
        })
    );

    $scope.load = function () {
      $scope.pageLoaded = true;
    };

    /**
     *gathering data from dashboard and map,
     *then setting it to deferred.promise
     */
    function getDashboardMapData(forceReload) {
      var deferred = $q.defer();
      DashboardDataService.getDealerMapData(dealer_id, forceReload)
        .then(
          function (data) {
            $scope.panelMapData = data.map((data) => {
              const { panel_software_date } = data;
              return {
                ...data,
                //panel_software_date converted to a date so column sort works correctly
                panel_software_date: panel_software_date
                  ? new Date(panel_software_date)
                  : null,
              };
            });

            $scope.panelCount = $scope.panelMapData.length;

            filterMarkers();
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject();
          },
          function (partialData) {
            $scope.panelMapData = partialData;
            $scope.panelCount = $scope.panelMapData.length;
            filterTable();
            //deferred.resolve(partialData);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     *gathering data from dashboard table,
     *then setting it to deferred.promise
     */
    function getDashboardData(forceReload) {
      var deferred = $q.defer();

      DashboardDataService.getDealerTableData(dealer_id, forceReload)
        .then(
          function (data) {
            $scope.panelTableData = data.map((data) => {
              const { n } = data;
              const combine = data.p + "-" + data.q;
              const addressExists = data.h ? false : true;
              return {
                ...data,
                //panel_software_date converted to a date so column sort works correctly
                n: n ? new Date(n) : null,
                // address converted to boolean so column sort works correctly, this is revered to allow the sort to display has address in a proper alphabetical order
                hasAddress: addressExists,
                // created a combined account number for search purposes
                accountNumber: combine,
              };
            });

            $scope.panelCount = $scope.panelTableData.length;
            filterTable();
            filterMarkers();
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject();
          },
          function (partialTableData) {
            $scope.panelTableData = partialTableData;
            $scope.panelCount = $scope.panelTableData.length;
            filterTable();
            //deferred.resolve(partialData);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     *Clear filters
     */
    function clearFilters() {
      $scope.dashboardSettings.searchFilter = "";
      $scope.dashboardSettings.mapFilters = {};
      $scope.dashboardSettings.tableFilters = null;
      $scope.dashboardSettings.allOthers = false;
    }

    /**
     * Remove all boolean filters from the list of selected filters
     */
    function clearBooleanFilters() {
      angular.forEach(booleanFilters, function (key) {
        delete $scope.dashboardSettings.mapFilters[key];
      });
    }

    /**
     * Set all boolean filters to the provided value
     * @param value
     */
    function setBooleanFilters(value) {
      angular.forEach(booleanFilters, function (key) {
        $scope.dashboardSettings.mapFilters[key] = value;
      });
    }

    /**
     * Select panels that have no trouble statuses
     */
    function selectAllOther() {
      if ($scope.dashboardSettings.allOthers) {
        setBooleanFilters("0");
      } else {
        clearBooleanFilters();
      }
    }

    /**
     * Find any boolean filters that are set to 0 (zero) and remove them from the filters
     */
    function cleanZeroBooleanFilters() {
      var keys = getFilterKeys();
      angular.forEach(keys, function (key) {
        if (
          booleanFilters.includes(key) &&
          $scope.dashboardSettings.mapFilters[key] === "0"
        ) {
          delete $scope.dashboardSettings.mapFilters[key];
        }
      });
    }

    /**
     * Selects all panels with no latitude or longitude values
     */
    function selectPanelsWithNoAddress() {
      if ($scope.no_address_data.value === true) {
        $scope.dashboardSettings.searchFilter = "";
        $scope.filteredPanelMapData = $filter("filter")(
          $scope.panelMapData,
          function (panel) {
            return panel.longitude === null || panel.latitude === null;
          }
        );
        $scope.filteredPanelCount = $scope.filteredPanelMapData?.length;
      } else {
        filterMarkers();
      }
    }

    function filterByTagsTable(panel, searchText) {
      if (typeof searchText !== "string") {
        return false; // Return false to avoid e error when calling toLowerCase() on undefined
      }

      const lowerSearchText = searchText.toLowerCase();
      return (
        !!lowerSearchText &&
        (!!$scope.tagsMap?.get(lowerSearchText)?.tagCustomerIds.has(panel.a) ||
          !!$scope.tagsMap?.get(lowerSearchText)?.tagPanelIds.has(panel.e) ||
          !!$scope.tagsMap?.get(lowerSearchText)?.tagSiteIds.has(panel.j))
      );
    }

    function filterTable() {
      // Filter by the filters in the tab
      if (!$scope.dashboardSettings.tableFilters) {
        $scope.dashboardSettings.tableFilters = {};
      }

      var panelTableData = $filter("filter")(
        $scope.panelTableData,
        function (panel) {
          return filterByPropertiesTable(panel);
        }
      );

      // Filter by the text search input in the table
      panelTableData = $filter("filter")(
        panelTableData,
        $scope.dashboardSettings.searchFilter
      );

      // Filter by tags
      const tagsTableData = $filter("filter")($scope.panelTableData, (panel) =>
        filterByTagsTable(panel, $scope.dashboardSettings.searchFilter)
      );

      // Merge panelTableData with tagsTableData as panelTableData could have filtered out results
      // that satisfy the tags filter.
      if (tagsTableData?.length > 0) {
        panelTableData = union(panelTableData, tagsTableData);
      }

      $scope.filteredPanelTableData = panelTableData ?? 0;
      $scope.filteredPanelTableCount = panelTableData?.length ?? 0;
    }

    /**
     *validating if there is data in panelMapData
     * as well as dashboardSettings.searchFilter
     */
    function filterMarkers() {
      // Get out if there is no panelMapData
      if (
        angular.isUndefined($scope.panelMapData) ||
        $scope.panelMapData.length === 0
      )
        return;
      // Get out if there is a dashboardSettings.searchFilter, but it's less than 3 characters.
      if (
        angular.isDefined($scope.dashboardSettings.searchFilter) &&
        $scope.dashboardSettings.searchFilter !== "" &&
        $scope.dashboardSettings.searchFilter.length < 3
      )
        return;

      // Set the filterPills object based on the currently selected filters
      setFilterPills();

      // Clear the boolean filters if they weren't set on purpose by the allOthers flag
      if (!$scope.dashboardSettings.allOthers) cleanZeroBooleanFilters();

      // Filter by the filters in the tab
      var panelMapData = $filter("filter")(
        $scope.panelMapData,
        function (panel) {
          return filterByProperties(panel);
        }
      );

      // Filter by the text search input in the table
      panelMapData = $filter("filter")(
        panelMapData,
        $scope.dashboardSettings.searchFilter
      );

      // Filter by tags
      const tagsMapData = $filter("filter")($scope.panelMapData, (panel) =>
        filterByTags(panel, $scope.dashboardSettings.searchFilter)
      );

      // Merge panelMapData with tagsMapData as panelMapData could have filtered out results
      // that satisfy the tags filter.
      if (tagsMapData.length > 0) {
        panelMapData = union(panelMapData, tagsMapData);
      }

      $scope.filteredPanelMapData = panelMapData;
      $scope.filteredPanelCount = panelMapData.length;

      NgMap.getMap(mapId)
        .then(function (map) {
          if ($scope.markerClusterer) $scope.markerClusterer.clearMarkers();
          dynMarkers = [];
          $rootScope.infoWindow = new google.maps.InfoWindow();
          var oms = new OverlappingMarkerSpiderfier(map, {
            markersWontMove: MAP_SETTINGS.SPIDERFIER.MARKERS_WONT_MOVE,
            markersWontHide: MAP_SETTINGS.SPIDERFIER.MARKERS_WONT_HIDE,
            basicFormatEvents: MAP_SETTINGS.SPIDERFIER.BASIC_FORMAT_EVENTS,
            circleSpiralSwitchover:
              MAP_SETTINGS.SPIDERFIER.CIRCLE_SPIRAL_SWITCHOVER,
            circleFootSeparation:
              MAP_SETTINGS.SPIDERFIER.CIRCLE_FOOT_SEPARATION,
            spiralLengthFactor: MAP_SETTINGS.SPIDERFIER.SPIRAL_LENGTH_FACTOR,
          });
          oms.legColors.highlighted.hybrid = MAP_SETTINGS.SPIDERFIER.LEG_COLOR;
          oms.legColors.highlighted.terrain = MAP_SETTINGS.SPIDERFIER.LEG_COLOR;
          oms.legColors.highlighted.satellite =
            MAP_SETTINGS.SPIDERFIER.LEG_COLOR;
          oms.legColors.highlighted.roadmap = MAP_SETTINGS.SPIDERFIER.LEG_COLOR;
          for (var j = 0; j < panelMapData.length; j++) {
            if (!panelMapData[j].longitude || !panelMapData[j].latitude)
              continue;
            // TODO: change this back when we have REAL data...
            var latLng = new google.maps.LatLng(
              panelMapData[j].latitude,
              panelMapData[j].longitude
            );
            var marker = new google.maps.Marker({
              position: latLng,
              title: panelMapData[j].control_system_name,
              icon: "/assets/img/googlemaps-markers/dmp-map-marker.png",
            });

            var content = formatMarkerInfoWindow(panelMapData[j]);

            google.maps.event.addListener(
              marker,
              "spider_click",
              (function (marker, content, infowindow) {
                return function () {
                  infowindow.setContent(content);
                  infowindow.open(map, marker);
                };
              })(marker, content, $rootScope.infoWindow)
            );
            oms.addMarker(marker); // adds the marker to the spiderfier _and_ the map
            dynMarkers.push(marker);
          }

          var mcOptions = {
            styles: MAP_SETTINGS.CLUSTER.STYLES,
            maxZoom: MAP_SETTINGS.CLUSTER.MAX_ZOOM,
            zoomOnClick: true,
          };
          $scope.markerClusterer = new MarkerClusterer(
            map,
            dynMarkers,
            mcOptions
          );

          var bounds = new google.maps.LatLngBounds();
          for (var i = 0; i < dynMarkers.length; i++) {
            bounds.extend(dynMarkers[i].position);
          }

          $scope.markerCount = dynMarkers.length;
          // If there are no markers, don't change the bounds
          if (dynMarkers.length > 0) map.fitBounds(bounds);

          $scope.load();
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function toggleLayer(layerType) {
      var opacity = layerType === "radar" ? "50" : "100";
      NgMap.getMap(mapId)
        .then(function (map) {
          map.overlayMapTypes.clear();

          if (layerType != "clear" && layerType) {
            var url = new google.maps.ImageMapType({
              getTileUrl: function (coord, zoom) {
                if (layerType == "verizon" || layerType == "att")
                  return fetchImageSrcFromUrlWithBearerAuth(
                    [
                      PROPS.coverageMapApiUrl,
                      "/api/v1/tiles/",
                      layerType,
                      "/",
                      zoom,
                      "/",
                      coord.x,
                      "/",
                      coord.y,
                    ].join(""),
                    UserService.auth_token
                  );
                else
                  return [
                    "https://maps.aerisapi.com/4yEmQCJNh7eij7qndeGmn_YCiCSSt3xWHwYosdcCgfT0k1LaWDo66WihJJrhqy/" +
                      layerType +
                      ":" +
                      opacity,
                    "/",
                    zoom,
                    "/",
                    coord.x,
                    "/",
                    coord.y,
                    "/current.png",
                  ].join("");
              },
              tileSize: new google.maps.Size(256, 256),
            });

            map.overlayMapTypes.push(url);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function checkForCellCoverageData() {
      let verizonTestData = new Image();
      let attTestData = new Image();
      verizonTestData.onload = function () {
        // image exists and is loaded
        $scope.verizonCoverageDataAvailable = true;
      };
      verizonTestData.onerror = function () {
        // image did not load
        $scope.verizonCoverageDataAvailable = false;
      };

      attTestData.onload = function () {
        // image exists and is loaded
        $scope.attCoverageDataAvailable = true;
      };
      attTestData.onerror = function () {
        // image did not load
        $scope.attCoverageDataAvailable = false;
      };
      verizonTestData.src = fetchImageSrcFromUrlWithBearerAuth(
        `${PROPS.coverageMapApiUrl}/api/v1/tiles/verizon/3/1/2/`,
        UserService.auth_token
      );
      attTestData.src = fetchImageSrcFromUrlWithBearerAuth(
        `${PROPS.coverageMapApiUrl}/api/v1/tiles/att/3/1/2/`,
        UserService.auth_token
      );
    }

    $scope.$on("$destroy", function () {
      if ($scope.markerClusterer != null) {
        $scope.markerClusterer.clearMarkers();
      }
    });

    /**
     *
     * @param panel
     * @returns {string}
     */
    function formatMarkerInfoWindow(panel) {
      var postalAddress = formatPostalAddress(
        $sanitize(panel.control_system_address_1),
        $sanitize(panel.control_system_address_2),
        $sanitize(panel.control_system_city),
        $sanitize(panel.control_system_state),
        $sanitize(panel.postal_code)
      );
      var customerName = panel.customer_name;
      var hardware_model = control_system_hardware_modelFilter(
        panel.hardware_model,
        panel.hardware_model_name
      );
      var comm_type = control_system_comm_typeFilter(panel.comm_type);
      var info =
        customerName +
        "<br>" +
        "<a href='/#/app/customers/" +
        panel.customer_id +
        "/control_systems/" +
        panel.control_system_id +
        "'>" +
        panel.control_system_name +
        "</a><br>" +
        hardware_model +
        "<br>" +
        comm_type;
      info += postalAddress.topLine || postalAddress.bottomLine ? "<br>" : "";
      info += postalAddress.topLine ? postalAddress.topLine + "<br>" : "";
      info += postalAddress.bottomLine ? postalAddress.bottomLine : "";

      return info;
    }

    /**
     *
     * @param panel
     */
    function filterByProperties(panel) {
      var activeFilterProps = getFilterKeys();

      // Use this snippet for matching with AND
      return activeFilterProps.every(function (prop) {
        if (booleanFilters.includes(prop)) {
          return $scope.dashboardSettings.mapFilters[prop] == panel[prop];
        } else {
          return $scope.dashboardSettings.mapFilters[prop][panel[prop]];
        }
      });
    }

    function filterByPropertiesTable(panel) {
      var activeTableFilterProps = getTableFilterKeys();

      // Use this snippet for matching with AND
      return activeTableFilterProps.every(function (prop) {
        if (booleanFilters.includes(prop)) {
          return $scope.dashboardSettings.tableFilters[prop] == panel[prop];
        } else {
          return $scope.dashboardSettings.tableFilters[prop][panel[prop]];
        }
      });
    }
    /**
     * Looks at tagsMap for matching searchText with tag name and if panel customer/control system/site id is associated to the tag
     *
     * @param {object} panel
     * @param {string} searchText
     */
    function filterByTags(panel, searchText) {
      const lowerSearchText = searchText.toLowerCase();
      return (
        !!lowerSearchText &&
        (!!$scope.tagsMap
          .get(lowerSearchText)
          ?.tagCustomerIds.has(panel.customer_id) ||
          !!$scope.tagsMap
            .get(lowerSearchText)
            ?.tagPanelIds.has(panel.control_system_id) ||
          !!$scope.tagsMap.get(lowerSearchText)?.tagSiteIds.has(panel.site_id))
      );
    }

    /**
     *Figures and Displays selected filters for Map on Dealer Dashboard
     */
    function setFilterPills() {
      var filterValues = [];
      $scope.enableReset = false;
      var pill = {};
      getFilterKeys().forEach(function (key) {
        Object.keys($scope.dashboardSettings.mapFilters[key]).forEach(function (
          valueKey
        ) {
          if ($scope.dashboardSettings.mapFilters[key][valueKey]) {
            if (booleanFilters.includes(key)) {
              if ($scope.dashboardSettings.mapFilters[key][valueKey] === "1") {
                pill = {};
                pill[key] = valueKey;
                filterValues.push(pill);
                $scope.enableReset = true;
              }
            } else {
              pill = {};
              pill[key] = valueKey;
              filterValues.push(pill);
              $scope.enableReset = true;
            }
          }
        });
      });
      if ($scope.dashboardSettings.allOthers) {
        pill = {};
        pill["allOthers"] = "all other systems";
        filterValues.push(pill);
        $scope.enableReset = true;
      }
      $scope.filterPills = filterValues;
    }

    /**
     *
     * @param key
     * @param value
     */
    $scope.deselectFilter = function (key, value) {
      if (booleanFilters.includes(key)) {
        delete $scope.dashboardSettings.mapFilters[key];
      } else if (key === "allOthers") {
        $scope.dashboardSettings.allOthers = false;
      } else {
        delete $scope.dashboardSettings.mapFilters[key][value];
      }
    };

    /**
     *
     * @returns {Array.<*>}
     */
    function getFilterKeys() {
      return Object.keys($scope.dashboardSettings.mapFilters).filter(function (
        prop
      ) {
        return !noFilter($scope.dashboardSettings.mapFilters[prop]);
      });
    }

    function getTableFilterKeys() {
      return Object.keys($scope.dashboardSettings.tableFilters).filter(
        function (prop) {
          return !noFilter($scope.dashboardSettings.tableFilters[prop]);
        }
      );
    }

    /**
     *
     * @param prop
     * @returns {Array.<*>}
     */
    function getValuesFor(prop) {
      return ($scope.panelMapData || [])
        .map(function (panel) {
          return panel[prop];
        })
        .filter(function (value, idx, arr) {
          return arr.indexOf(value) === idx && value != null;
        });
    }

    /**
     *
     * @param filterObj
     * @returns {boolean}
     */
    function noFilter(filterObj) {
      return Object.keys(filterObj).every(function (key) {
        return !filterObj[key];
      });
    }

    /**
     *
     * @param field
     * @returns {boolean}
     */
    function allowedFilters(field) {
      var allowedValues = [
        "account_number",
        "panel_id",
        "created_at",
        "latitude",
        "longitude",
        "control_system_id",
        "postal_code",
        "control_system_name",
        "dealer_name",
        "dealer_id",
        "customer_name",
        "rows_per_page",
      ];
      return allowedValues.indexOf(field) < 0;
    }

    function setMapTheme() {
      $scope.mapTheme = $scope.dashboardSettings.lightStyle
        ? MAP_SETTINGS.MAP_STYLES.LIGHT
        : MAP_SETTINGS.MAP_STYLES.DARK;
    }

    // function to toggle the style
    $scope.changeStyle = function () {
      NgMap.getMap(mapId)
        .then(function (map) {
          $scope.dashboardSettings.lightStyle =
            !$scope.dashboardSettings.lightStyle;
          setMapTheme();
          map.setOptions({ styles: $scope.mapTheme });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.tableCols = {
      no_addrs_data: false,
      cust_name: true,
      created_at: true,
      acct_num: true,
      sys_name: true,
      sys_nickname: false,
      sys_type: true,
      sys_ver: true,
      sof_date: false,
      con_type: true,
      carrier: false,
      app: false,
      net_add: false,
      last_con: false,
      arm_type: false,
      ser_num: false,
      cell_num: false,
      cust_acc: false,
      billing_information: false,
    };

    /**
     * Function to export selected data to CSV file
     */
    function exportData(inputData) {
      var outputData = [];
      angular.forEach(inputData, function (row) {
        var newRow = {};
        newRow.panel_account_number =
          (row.p == null ? "" : row.p) + "-" + (row.q == null ? "" : row.q);
        newRow.customer_name = row.b;
        newRow.control_system_name = row.f;
        newRow.control_system_nickname = row.z;
        newRow.hardware_model = control_system_hardware_modelFilter(
          row.k,
          row.hardware_model_name
        );
        newRow.panel_software_version = row.m;
        newRow.panel_software_date = row.n;
        newRow.app = appStanOrArm(row);
        newRow.panel_comm_address = row.o;
        newRow.panel_last_connected_at = $filter("date")(row.y, "short");
        newRow.panel_arming_system = row.r;
        newRow.panel_serial_number = row.s;
        newRow.comm_type = row.l;
        newRow.carrier = row.u;
        newRow.control_system_billing_information = row.i;
        outputData.push(newRow);
        // $scope.markerClusterer.clearMarkers();
        // $scope.setMarkers();
      });
      return outputData;
    }

    /**
         Function to decide which arm type to display
         */
    function appStanOrArm(row) {
      if (row.v && !row.w) {
        return "Standard";
      } else if (row.w && !row.v) {
        return "Arm";
      } else {
        return "-";
      }
    }

    init();
  },
]);

/**
 * Determine if element is in range
 */
App.filter("range", function () {
  return function (input, total) {
    total = parseInt(total);
    for (var i = 0; i < total; i++) input.push(i);
    return input;
  };
});
