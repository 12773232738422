App.directive("daCustomRoles", [
  "CustomRolesService",
  "UserService",
  "TagsService",
  "$rootScope",
  function (CustomRolesService, UserService, TagsService, $rootScope) {
    return {
      restrict: "E",
      require: "^form",
      scope: {
        role: "=",
        type: "=",
        disableFields: "=",
        showLabel: "=",
        tags: "=",
      },
      templateUrl: function (element, scope) {
        switch (scope.type) {
          case "technician":
            return "/app/personnel/custom-roles/da-technician-custom-roles-tpl.html";
          case "custom":
            return "/app/personnel/custom-roles/da-custom-roles-tpl.html";
          default:
            return "";
        }
      },
      link: function (scope, element, attrs, formCtrl) {
        scope.UserService = UserService;
        scope.validVernaculars = UserService.dealerInfo.vernaculars;
        scope.ipWhitelistEnabled = UserService.enabledIpWhitelist();
        let pristineRole = {};

        scope.$watch("role.id", function (newValue) {
          if (angular.isDefined(newValue)) {
            pristineRole = angular.copy(scope.role);
            scope.validateDaySelection();
            if (scope.disableFields) {
              scope.displayStartTime = undoTimeZoneOffset(
                scope.role.start_time
              );
              scope.displayEndTime = undoTimeZoneOffset(scope.role.end_time);
            }
          }
        });

        let limitEntryPrevValue = undefined; //need to prevent the watcher from running if the value is undefined and not true.  So that this will only run when the box is checked for the first time.

        scope.$watch("role.limit_entity", function (newValue) {
          if (newValue || limitEntryPrevValue !== undefined) {
            scope.processLimitEntity(newValue);
            limitEntryPrevValue = newValue;
          }
        });

        scope.$watch("role", function (newValue, oldValue) {
          if (
            newValue.user_auth_permissions ||
            oldValue.user_auth_permissions
          ) {
            checkBillingTag();
          }
        });

        scope.processLimitEntity = function (limitEntity) {
          const disableValues = limitEntity;
          scope.role.user_auth_permissions.move_systems = !disableValues;
          scope.role.user_auth_permissions.bulk_update_view = !disableValues;
          scope.role.user_auth_permissions.remote_update_dashboard_view =
            !disableValues;
          scope.role.user_auth_permissions.reporting_analytics_view =
            !disableValues;
          scope.role.user_auth_permissions.mass_programming_view =
            !disableValues;
          scope.role.manage_tags = !disableValues;
          scope.role.GUIPermissions.personnel = disableValues
            ? "Hidden"
            : "View, Edit & Delete";
          scope.disableFields = disableValues;
          scope.personnelHidden = disableValues;
          scope.fieldsDisabled = disableValues;
        };

        scope.updateCachedTime = function () {
          pristineRole.start_time = scope.role.start_time;
          pristineRole.end_time = scope.role.end_time;
        };

        scope.processAllDayCheck = function () {
          updateTime("start_time", "DAY_START_TIME");
          updateTime("end_time", "DAY_END_TIME");
        };

        scope.processEveryDayCheck = function () {
          [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
          ].forEach((day) => {
            scope.role[day] = scope.role.every_day;
          });
          scope.validateDaySelection();
        };

        function setEveryDayFlag() {
          scope.role.every_day = [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
          ].every((day) => scope.role[day]);
        }

        scope.validateDaySelection = function () {
          if (
            DoesNestedPropertyExist(formCtrl, "customRoleForm.dayBlockForm")
          ) {
            if (daySelectionValid(scope.role)) {
              formCtrl.customRoleForm.dayBlockForm.$setValidity(
                "noSelection",
                true
              );
            } else {
              formCtrl.customRoleForm.dayBlockForm.$setValidity(
                "noSelection",
                false
              );
            }
          }
        };

        scope.updateArmAndDisarm = function () {
          //disable arm/disarm if system_status_view is false
          if (scope.role.user_auth_permissions.system_status_view === false) {
            scope.role.user_auth_permissions.arming = false;
            scope.role.user_auth_permissions.disarming = false;
          }
          //enable arm/disarm if system_status_view is true
          else {
            scope.role.user_auth_permissions.arming = true;
            scope.role.user_auth_permissions.disarming = true;
          }
        };

        scope.dropDownOptionsTechApp =
          CustomRolesService.dropDownOptionsTechApp;
        scope.dropDownOptionsCustom = CustomRolesService.dropDownOptionsCustom;

        function updateTime(timeSlot, limit) {
          const guiLimit = CustomRolesService.getGUIDateString(
            CustomRolesService[limit]
          );
          scope.role[timeSlot] = scope.role.all_day
            ? guiLimit
            : pristineRole.all_day && pristineRole[timeSlot] === guiLimit
            ? ""
            : pristineRole[timeSlot];
        }

        function undoTimeZoneOffset(dateString) {
          const date = new Date(dateString);
          const timeZoneOffset = date.getTimezoneOffset() * 60000;
          return new Date(date.getTime() + timeZoneOffset);
        }

        function daySelectionValid(role) {
          return [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
          ].some((day) => role[day]);
        }

        function checkBillingTag(currentBillingTag) {
          const customRoleBillingTag = TagsService.getCustomRoleTags(
            scope.role.id
          ).then((customRoleTags) =>
            customRoleTags.find(
              (tag) =>
                tag.billing_dealer_id !== null ||
                tag.billing_dealer_id !== undefined
            )
          );

          if (scope.role.isNew) {
            if (currentBillingTag) {
              scope.customRoleHasBillingTag = true;
            } else if (!customRoleBillingTag || !currentBillingTag) {
              scope.customRoleHasBillingTag = false;
            }
          } else {
            scope.customRoleHasBillingTag = Boolean(currentBillingTag);
          }

          if (scope.customRoleHasBillingTag) {
            scope.role.user_auth_permissions.reporting_analytics_view = false;
            scope.disableReportsAndAnalytics = true;
          } else {
            scope.disableReportsAndAnalytics = false;
          }
        }

        function init() {
          setEveryDayFlag();
          $rootScope.runCheckBillingTag = checkBillingTag;
          scope.timeZones = [];
          CustomRolesService.getTimeZones(UserService.dealer_id)
            .then((data) => {
              scope.timeZones = data.map((zone) => zone.name);
            })
            .catch(() => {
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to get time zones",
              });
            });
        }

        init();
      },
    };
  },
]);
