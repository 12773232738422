/**
 * @generated SignedSource<<f3d6e74c15cf73bb272050793e1fd0a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalHolidayDatesDealerQuery$variables = {
  dealerId: string;
};
export type GlobalHolidayDatesDealerQuery$data = {
  readonly dealer: {
    readonly vernaculars: ReadonlyArray<{
      readonly original: string;
      readonly replacement: string;
    } | null>;
    readonly " $fragmentSpreads": FragmentRefs<"GlobalHolidayDatesModalForm_dealer" | "GlobalHolidayDatesTable_dealer">;
  } | null;
};
export type GlobalHolidayDatesDealerQuery = {
  response: GlobalHolidayDatesDealerQuery$data;
  variables: GlobalHolidayDatesDealerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dealerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "replacement",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalHolidayDatesDealerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vernacular",
            "kind": "LinkedField",
            "name": "vernaculars",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GlobalHolidayDatesTable_dealer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GlobalHolidayDatesModalForm_dealer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GlobalHolidayDatesDealerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vernacular",
            "kind": "LinkedField",
            "name": "vernaculars",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalHolidayDate",
            "kind": "LinkedField",
            "name": "globalHolidayDates",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scapiId",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clazz",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9972c1fc184d0f451c4057a4b6903ea7",
    "id": null,
    "metadata": {},
    "name": "GlobalHolidayDatesDealerQuery",
    "operationKind": "query",
    "text": "query GlobalHolidayDatesDealerQuery(\n  $dealerId: ID!\n) {\n  dealer(id: $dealerId) {\n    vernaculars {\n      original\n      replacement\n      id\n    }\n    ...GlobalHolidayDatesTable_dealer\n    ...GlobalHolidayDatesModalForm_dealer\n    id\n  }\n}\n\nfragment GlobalHolidayDatesModalForm_dealer on Dealer {\n  vernaculars {\n    original\n    replacement\n    id\n  }\n}\n\nfragment GlobalHolidayDatesTable_dealer on Dealer {\n  globalHolidayDates {\n    scapiId\n    id\n    name\n    description\n    date\n    clazz\n  }\n  ...GlobalHolidayDatesModalForm_dealer\n}\n"
  }
};
})();

(node as any).hash = "c05cff7002d99b9f45f09749ceb7a7a4";

export default node;
