import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorBackupTcpPortField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorBackupTcpPortField_remoteOptions.graphql";

export const remoteOptionsIntegratorBackupTcpPortFieldId = () =>
  "remote-options-integrator-backup-tcp-port";

function RemoteOptionsIntegratorBackupTcpPortField() {
  const [
    {
      integratorBackupIncomingPort,
      integratorConnection,
      integratorBackupConnection,
    },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorBackupTcpPortField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorBackupTcpPortField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorConnection
          integratorBackupConnection
          integratorBackupIncomingPort
        }
        ... on Xt75RemoteOptions {
          integratorConnection
          integratorBackupConnection
          integratorBackupIncomingPort
        }
        ... on XfRemoteOptions {
          integratorConnection
          integratorBackupConnection
          integratorBackupIncomingPort
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorBackupTcpPortFieldId();
  const disabled =
    integratorConnection === RemoteOptionsConnectionType.NONE ||
    integratorBackupConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Integrator Backup TCP Port"
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={integratorBackupIncomingPort || ""}
        maxLength={16}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        inlineHelp="1-65535"
        disabled={disabled}
        min={1}
        max={65535}
        validationMessage="Valid values are 1-65535."
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "integratorBackupIncomingPort");
          });
        }}
        onBlur={({ target }) => {
          const value = clamp(1, 65535, Number(target.value));
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(
              String(value).padStart(5, `0`),
              "integratorBackupIncomingPort"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorBackupTcpPortField;
