/**
 * @generated SignedSource<<1dfa3242806eefcaf97e6083032530f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly remoteOptions: {
      readonly __typename: string;
      readonly alarmReceiverAuthorization?: boolean | null;
      readonly appKey?: string | null;
      readonly armedAnswerRings?: number | null;
      readonly disarmedAnswerRings?: number | null;
      readonly entreArmDisarmReports?: boolean | null;
      readonly entreBackupConnection?: RemoteOptionsConnectionType | null;
      readonly entreBackupIncomingPort?: string | null;
      readonly entreBackupIpAddress?: string | null;
      readonly entreCheckInMinutes?: number | null;
      readonly entreConnection?: RemoteOptionsConnectionType | null;
      readonly entreIncomingPort?: string | null;
      readonly entreIpAddress?: string | null;
      readonly entreOutgoingPort?: string | null;
      readonly entrePassphrase?: string | null;
      readonly entreSupervisoryReports?: boolean | null;
      readonly entreUserCommandReports?: boolean | null;
      readonly entreZoneReports?: boolean | null;
      readonly id: string;
      readonly remoteDisarm?: boolean;
      readonly remoteKey?: string;
      readonly serviceReceiverAuthorization?: boolean | null;
    } | null;
  };
  readonly " $fragmentType": "TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem";
};
export type TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "332d6f664c637fdb5d6c83a174a8acc7";

export default node;
