App.controller("CustomerEngagementCtrl", [
  "$scope",
  "$q",
  "$rootScope",
  "CustomerEngagementService",
  "CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE",
  "CustomerEngagementAPI",
  "$stateParams",
  "UserService",
  "$modal",
  "$filter",
  "$state",
  function (
    $scope,
    $q,
    $rootScope,
    CustomerEngagementService,
    CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE,
    CustomerEngagementAPI,
    $stateParams,
    UserService,
    $modal,
    $filter,
    $state
  ) {
    $scope.coolDown = false;
    $scope.coolDownType = null;
    $scope.campaigns = [
      {
        name: "App Inactivity",
        desc: "It’s been at least 30 days since these customers logged into their Virtual Keypad app. This email offers your help in case they’re having trouble logging in and provides a chance to make sure they’re aware of the benefits the app can give them.",
        campaignType: CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE.AppUserActivity,
      },
      {
        name: "Automation",
        desc: "These customers haven’t taken advantage of lights, locks or thermostats in their systems. This email lets them know about the home automation features people love having, especially with custom actions.",
        campaignType: CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE.Automation,
      },
      {
        name: "Sensor Activity",
        desc: "For the customers who haven’t yet enabled Sensor Activity, this email highlights how they can put this powerful feature to work for them.",
        campaignType: CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE.SensorActivity,
      },
      {
        name: "User Code Management",
        desc: "Keys can be lost or duplicated, but User codes are a smarter way to give access at the office and home. Make sure your customers know about the benefits of user code management.",
        campaignType: CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE.UserCodeManagment,
      },
      {
        name: "Video Doorbell",
        desc: "For customers who don’t have a Video Doorbell in their systems, this is an easy way to remind them it’s a simple add-on with great security benefits.",
        campaignType: CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE.VideoDoorbell,
      },
      {
        name: "Video Cameras",
        desc: "For customers who don’t have video cameras connected to their systems, this email is an easy way to remind them that cameras are a simple add-on with great security benefits.",
        campaignType: CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE.VideoCameras,
      },
      {
        name: "Monitoring Center Video Verification",
        desc: "This email is an easy way to remind customers who don’t have Video Verification that it’s an important part of your professional monitoring services.",
        campaignType: CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE.VideoVerification,
      },
    ];

    $scope.executeCampaign = function (campaignType, campaignName) {
      var deferred = $q.defer();
      CustomerEngagementService.generateEmailCampaign(
        campaignType,
        campaignName
      ).then(
        function (data) {
          $rootScope.alerts.push({
            type: "success",
            text: "Email Campaign Sent",
          });
          reloadPage();
          deferred.resolve(data);
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error Generating Campaign Emails",
          });
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    $scope.customerEngagementEmailCount = function (campaignType) {
      var deferred = $q.defer();
      CustomerEngagementService.getEmailCount(
        $stateParams.dealer_id,
        campaignType
      ).then(
        function (data) {
          for (let campaign of $scope.campaigns) {
            if (campaign.campaignType == data.EmailCampaignType)
              campaign.count = data.Count;
            UserService;
          }
          deferred.resolve(data.Count);
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "error getting campaign email count",
          });
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    getLastRunEmailCampaigns = function () {
      var deferred = $q.defer();
      CustomerEngagementService.getLastSentDates($stateParams.dealer_id)
        .then(
          function (data) {
            if (data.length === 0) {
              deferred.resolve(data);
              return;
            }
            var campaignDates = $filter("orderBy")(data, [
              "-LastCampaignDateTime",
            ]);
            $scope.mostRecentDate = campaignDates[0].LastCampaignDateTime;
            $scope.coolDownStartDate = new Date($scope.mostRecentDate);
            $scope.coolDownStartDate.setHours(0, 0, 0, 0);
            $scope.coolDownEndDate = angular.copy($scope.coolDownStartDate);
            $scope.coolDownEndDate.setDate(
              $scope.coolDownStartDate.getDate() + 7
            );
            if (new Date() < $scope.coolDownEndDate) $scope.coolDown = true;

            for (let campaign of $scope.campaigns) {
              for (let date of data) {
                if (campaign.campaignType == date.EmailCampaignType)
                  campaign.send_date = date.LastCampaignDateTime;
              }
              if ($scope.mostRecentDate == campaign.send_date)
                $scope.coolDownType = campaign.name;
            }
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    function reloadPage() {
      $state.forceReload();
    }

    init();

    function init() {
      getLastRunEmailCampaigns();
    }
    /**
     ** CampaignExecutionModal
     *******************************************************************/
    $scope.openCampaignExecutionModal = function (campaign) {
      // enable spinner to prevent multiple queries running at a time
      $scope.loading = true;
      $scope
        .customerEngagementEmailCount(campaign.campaignType)
        .then((data) => {
          // Set the data into the campaign structure
          $scope.campaign = campaign;
          $scope.campaign.count = data;

          var CampaignExecutionModal = $modal.open({
            templateUrl:
              "app/dealer/customer-engagement/modals/campaign-execution-modal.html",
            controller: "CampaignExecutionInstanceCtrl",
            windowClass: "",
            size: "md",
            backdrop: true,
            scope: $scope,
          });

          // disable the loading spinner so user can interact
          $scope.loading = false;
        });
    };

    /**
     ** EmailPreview
     *******************************************************************/
    $scope.openEmailPreviewModal = function (campaign) {
      let EmailPreviewModal = $modal.open({
        templateUrl:
          "app/dealer/customer-engagement/modals/email-preview-modal.html",
        controller: "EmailPreviewInstanceCtrl",
        windowClass: "",
        size: "lg",
        backdrop: true,
        scope: $scope,
      });
      $scope.campaignType = campaign.campaignType;
      $scope.campaignTitle = campaign.name;
    };
  },
]);

/** A controller for the CampaignExecutionModal *********************************************************************************************/
App.controller(
  "CampaignExecutionInstanceCtrl",
  function ($scope, $modalInstance, $state, UserService) {
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    $scope.closeCampaignExecutionModal = function () {
      $modalInstance.dismiss("cancel");
    };
  }
);

/** A controller for the EmailPreviewModal *********************************************************************************************/
App.controller(
  "EmailPreviewInstanceCtrl",
  function ($scope, $modalInstance, $state, UserService) {
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    $scope.closeEmailPreviewModal = function () {
      $modalInstance.dismiss("cancel");
    };
  }
);
