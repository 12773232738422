import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useKeyfobFragment } from "./KeyfobContext";
import { KeyfobUserNumberField_keyfob$key } from "./__generated__/KeyfobUserNumberField_keyfob.graphql";

export const KEYFOB_USER_NUMBER = `keyfob-user-number-`;

export const keyfobUserNumberFieldId = (number: string) =>
  KEYFOB_USER_NUMBER + number;

function KeyfobUserNumberField() {
  const [keyfob] = useKeyfobFragment<KeyfobUserNumberField_keyfob$key>(
    graphql`
      fragment KeyfobUserNumberField_keyfob on Keyfob {
        id
        number
        user {
          id
          name
          number
        }
      }
    `
  );

  const fieldId = keyfobUserNumberFieldId(String(keyfob.number));
  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="User Number"
      disabled={!keyfob.user?.number}
    >
      {keyfob.user?.number}
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobUserNumberField;
