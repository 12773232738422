import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { BellOptions, ControlSystem } from "securecom-graphql/client";
import BellOptionsAutomaticBellTestField from "../common/BellOptionsFields/BellOptionsAutomaticBellTestField";
import BellOptionsAuxiliary1BellActionField from "../common/BellOptionsFields/BellOptionsAuxiliary1BellActionField";
import BellOptionsAuxiliary2BellActionField from "../common/BellOptionsFields/BellOptionsAuxiliary2BellActionField";
import BellOptionsBellCutoffTimeField from "../common/BellOptionsFields/BellOptionsBellCutoffTimeField";
import BellOptionsBellOutputField from "../common/BellOptionsFields/BellOptionsBellOutputField";
import BellOptionsBurglaryBellActionField from "../common/BellOptionsFields/BellOptionsBurglaryBellActionField";
import BellOptionsCarbonMonoxideBellActionField from "../common/BellOptionsFields/BellOptionsCarbonMonoxideBellActionField";
import { BellOptionsContextProvider } from "../common/BellOptionsFields/BellOptionsContext";
import BellOptionsEmergencyBellActionField from "../common/BellOptionsFields/BellOptionsEmergencyBellActionField";
import BellOptionsFireBellActionField from "../common/BellOptionsFields/BellOptionsFireBellAction";
import BellOptionsPanicBellActionField from "../common/BellOptionsFields/BellOptionsPanicBellActionField";
import BellOptionsSupervisoryBellActionField from "../common/BellOptionsFields/BellOptionsSupervisoryBellActionField";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import refreshMutationConcreteRequest, {
  XT75BellOptionsProgrammingConceptFormBellOptionsRefreshMutation,
} from "./__generated__/XT75BellOptionsProgrammingConceptFormBellOptionsRefreshMutation.graphql";
import {
  XT75BellOptionsProgrammingConceptFormBellOptionsSendMutation,
  XT75BellOptionsProgrammingConceptFormBellOptionsSendMutation$data,
} from "./__generated__/XT75BellOptionsProgrammingConceptFormBellOptionsSendMutation.graphql";
import {
  XT75BellOptionsProgrammingConceptFormInline_controlSystem$data,
  XT75BellOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XT75BellOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts.graphql";
import { XT75BellOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XT75BellOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Bell Options";
export const conceptId = "xt75-bell-options";

export const getState = (
  controlSystem: XT75BellOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XT75BellOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          bellOptions {
            id
            emergencyBellAction
            auxiliary1BellAction
            auxiliary2BellAction
            carbonMonoxideBellAction
            bellCutoffTime
            bellCutoffTimeMin
            bellCutoffTimeMax
            automaticBellTest
            bellOutput
            fireBellAction
            burglaryBellAction
            supervisoryBellAction
            panicBellAction
            strobe
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XT75BellOptionsProgrammingConceptFormBellOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshBellOptions(id: $id) {
      ... on RefreshBellOptionsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XT75BellOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XT75BellOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshBellOptions, isRefreshingBellOptions] =
    useMutation<XT75BellOptionsProgrammingConceptFormBellOptionsRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id: systemId } = getState(props.controlSystem);
        refreshBellOptions({
          variables: {
            id: systemId,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshBellOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Bell Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id: systemId,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshBellOptions: {
                    __typename: response.refreshBellOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Bell Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Bell Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshingBellOptions,
  ];
};
const saveMutation = graphql`
  mutation XT75BellOptionsProgrammingConceptFormBellOptionsSendMutation(
    $systemId: ID!
    $bellOptions: BellOptionsInput!
  ) {
    sendBellOptionsProgramming(systemId: $systemId, bellOptions: $bellOptions) {
      ... on SendBellOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XT75BellOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendBellOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XT75BellOptionsProgrammingConceptFormBellOptionsSendMutation$data,
  originalControlSystemData: XT75BellOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendBellOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshBellOptions: {
          __typename: "RefreshBellOptionsSuccessPayload",
          controlSystem: getState(
            response.sendBellOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XT75BellOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendBellOptions, isSendingBellOptions] =
    useMutation<XT75BellOptionsProgrammingConceptFormBellOptionsSendMutation>(
      saveMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { bellOptions },
        } = getState(props.controlSystem);
        if (bellOptions) {
          sendBellOptions({
            variables: {
              systemId: systemId,
              bellOptions: {
                bellCutoffTime: bellOptions.bellCutoffTime,
                automaticBellTest: bellOptions.automaticBellTest,
                bellOutput: bellOptions.bellOutput,
                fireBellAction: bellOptions.fireBellAction,
                burglaryBellAction: bellOptions.burglaryBellAction,
                supervisoryBellAction: bellOptions.supervisoryBellAction,
                panicBellAction: bellOptions.panicBellAction,
                emergencyBellAction: bellOptions.emergencyBellAction,
                auxiliary1BellAction: bellOptions.auxiliary1BellAction,
                auxiliary2BellAction: bellOptions.auxiliary2BellAction,
                carbonMonoxideBellAction: bellOptions.carbonMonoxideBellAction,
                strobe: bellOptions.strobe,
              },
            },

            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendBellOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Bell Options Programming Saved To the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendBellOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendBellOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSendingBellOptions,
  ];
};

const readBellOptionsTemplateData = (
  programmingTemplateConcepts: XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        bellOptions {
          included
          bellCutoffTime {
            included
            data
          }
          automaticBellTest {
            included
            data
          }
          bellOutput {
            included
            data
          }
          fireBellAction {
            included
            data
          }
          burglaryBellAction {
            included
            data
          }
          supervisoryBellAction {
            included
            data
          }
          panicBellAction {
            included
            data
          }
          emergencyBellAction {
            included
            data
          }
          auxiliary1BellAction {
            included
            data
          }
          auxiliary2BellAction {
            included
            data
          }
          carbonMonoxideBellAction {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).bellOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readBellOptionsTemplateData(programmingTemplateConcepts);

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const bellOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "bellOptions",
      "BellOptions"
    ) as unknown as RecordProxy<BellOptions>;

    applyTemplateScalarDataToRecordProxy(bellOptionsRecordProxy, templateData);
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XT75BellOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XT75BellOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseSupportsTemporal3_panel
            ...PanelContextUseHardwareModel_panel
            bellOptions {
              id
              ...BellOptionsContext_bellOptions
              ...BellOptionsBellCutoffTimeField_bellOptions
              ...BellOptionsAutomaticBellTestField_bellOptions
              ...BellOptionsBellOutputField_bellOptions
              ...BellOptionsFireBellActionField_bellOptions
              ...BellOptionsBurglaryBellActionField_bellOptions
              ...BellOptionsSupervisoryBellActionField_bellOptions
              ...BellOptionsPanicBellActionField_bellOptions
              ...BellOptionsEmergencyBellActionField_bellOptions
              ...BellOptionsAuxiliary1BellActionField_bellOptions
              ...BellOptionsAuxiliary2BellActionField_bellOptions
              ...BellOptionsCarbonMonoxideBellActionField_bellOptions
              ...BellOptionsStrobeField_bellOptions
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
          }
        }
      `
    );

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <BellOptionsContextProvider bellOptions={controlSystem.panel.bellOptions}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          title={title}
          helpLink={`${programmingGuideUrl}#Bell%20Options`}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.bellOptions
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <BellOptionsBellCutoffTimeField />
              <BellOptionsAutomaticBellTestField />
              <BellOptionsBellOutputField />
              <BellOptionsFireBellActionField />
              <BellOptionsBurglaryBellActionField />
              <BellOptionsSupervisoryBellActionField />
              <BellOptionsPanicBellActionField />
              <BellOptionsEmergencyBellActionField />
              <BellOptionsAuxiliary1BellActionField />
              <BellOptionsAuxiliary2BellActionField />
              <BellOptionsCarbonMonoxideBellActionField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </BellOptionsContextProvider>
    </PanelContextProvider>
  );
}
