import { formatISO, subMinutes } from "date-fns";
import { getAuthFromJWT } from "utils/string";

App.controller("VideoVerificationCtrl", [
  "$http",
  "UserService",
  "PROPS",
  "VideoService",
  "Camera",
  "$timeout",
  "ControlSystemService",
  "$sessionStorage",
  "control_system_id",
  "$filter",
  "$location",
  "$window",
  "$scope",
  "browser",
  "$interval",
  "$q",
  "DateUtilsService",
  "$rootScope",
  "$state",
  "CamerasInAlarmService",
  "PanelEventsService",
  "GoogleAnalyticsService",

  function (
    $http,
    UserService,
    PROPS,
    VideoService,
    Camera,
    $timeout,
    ControlSystemService,
    $sessionStorage,
    control_system_id,
    $filter,
    $location,
    $window,
    $scope,
    browser,
    $interval,
    $q,
    DateUtilsService,
    $rootScope,
    $state,
    CamerasInAlarmService,
    PanelEventsService,
    GoogleAnalyticsService
  ) {
    var video = this; // alias for this controller
    video.predicate = "created_at";
    video.reverse = true;
    video.clipsBusy = false;
    video.clipsMap = new Map();
    video.cameras = [];
    video.control_system_id = control_system_id; // set the control system id from the route
    var dealerInfo = undefined;
    video.onAttach = onAttach;
    video.goToLiveStream = goToLiveStream;
    video.viewing_live_stream = false;
    video.goToAlarmStream = goToAlarmStream;
    video.goBackInStream = goBackInStream;
    video.goForwardInStream = goForwardInStream;
    video.filterCamerasAndConverters = filterCamerasAndConverters;
    video.filterNVRs = filterNVRs;
    video.filterVideoVerification = filterVideoVerification;
    video.getHlsStreamUrl = getHlsStreamUrl;
    video.getTheTimeForV6000Events = getTheTimeForV6000Events;
    video.isHovered = true;
    video.viewing_alarm_stream = false;
    video.videoPlayerType = "";
    video.activeStreamType = "";
    video.clipsCombined = []; // array of clips including the v6000 clips
    video.currentCameraType = ""; // the current camera type - used to filter the video controls
    video.lastAlarmAtIso = video.last_alarm_at
      ? formatISO(video.last_alarm_at) // the last alarm is used for clip retrieval and is set in the getVVInfo function below
      : "";
    video.view2col = true;
    var USE_VARIABLE_POLLING = true;
    var REFRESH_VV_POLLING_ITERATION = 0;
    var REFRESH_VV_INFO_TIME = 30000;
    var REFRESH_CLIP_TIME = 5000; // milliseconds
    video.toggleCameras = false;
    video.clipsToView = "ALARM"; // ALARM or LIVE
    video.token = UserService.auth_token;

    const trackGoogleAnalyticsEvent = (action, event_label, value, args) => {
      return GoogleAnalyticsService.trackEvent(
        "Video_Verification",
        action,
        event_label,
        value,
        { ...args, dealerId: UserService.dealer_id, email: UserService.email }
      );
    };

    var getVVInfo = function () {
      // get the video verification info
      trackGoogleAnalyticsEvent(
        "Video_Verification",
        "Get_VV_Info",
        "Get_VV_Info",
        {
          control_system_id: video.control_system_id,
          lastAlarmAtMinusTenMin: video.lastAlarmAtMinusTenMin,
        }
      );

      getUpdatedVVInfo().then(function () {
        $timeout(function () {
          if (!video.clipsBusy) {
            trackGoogleAnalyticsEvent(
              "Video_Verification",
              "Get_Combined_Clips",
              "Get_Combined_Clips",
              {
                control_system_id: video.control_system_id,
                lastAlarmAtMinusTenMin: video.lastAlarmAtMinusTenMin,
              }
            );
            video.getCombinedClips(
              video.control_system_id,
              video.lastAlarmAtMinusTenMin
            );
          }
          autoRefreshClips();
        }, 200);
      });
    };

    const getVariableVVInfoTime = (useVariablePolling) => {
      const variablePollingTime = () =>
        (1 + Math.floor(REFRESH_VV_POLLING_ITERATION / 20)) * 1000; // sets up a stepped polling time so that each time the polling time is called 20 times it will increase by 1 second up to a max interval of REFRESH_VV_INFO_TIME
      //for about 590 iterations the max polling time will be 30 seconds
      REFRESH_VV_POLLING_ITERATION++;
      return !useVariablePolling || variablePollingTime() > REFRESH_VV_INFO_TIME
        ? REFRESH_VV_INFO_TIME
        : variablePollingTime();
    };

    /**
     * getisOnlineCameraStatus will compare the current time
     * to the last time the camera checked in
     * returns true if it's been less than 3 minutes
     * if it's been more than 3 minutes, return false
     **/
    const isOnline = (camera) => {
      const currentTime = new Date().getTime();
      const lastCheckedInTime = new Date(camera.checked_in_at).getTime();
      const timeDifference = currentTime - lastCheckedInTime;
      return timeDifference < 180000;
    };

    function getUpdatedVVInfo() {
      var deferred = $q.defer();
      if ($scope.useVAR) {
        deferred.resolve();
        return deferred.promise;
      }

      var search = $location.search();
      if (search.fps) {
        video.fps = true; // frame per second flag set. (add fps=x to add the frames per second to the title bar (i.e. &fps=1))
      }
      if ($sessionStorage.view_view) {
        // if the view is set in the session storage
        video.view_view = $sessionStorage.view_view; //remembers whether you picked two or three columns to maintain on refresh
      }
      $sessionStorage.auth_token = UserService.auth_token; // save the auth token in the session storage

      ControlSystemService.video_verification_info(video.control_system_id)
        .then(
          function (data) {
            if (
              data.control_system.video_analytics_recorder_enabled ||
              data.control_system.ienso_cameras_enabled
            ) {
              video.customer = data.customer;
              video.authToken = UserService.auth_token;
              video.dealerId = data.dealer.master_identifiers[0].dealer_id;
              video.controlSystemId = control_system_id;
              $scope.useVAR = true;
            } else {
              $scope.useVAR = false;
              video.customer = data.customer; // set the customer
              USE_VARIABLE_POLLING = false;
              video.control_system = data.control_system; // set the control system
              video.panel = data.panels[0]; // set the panel
              video.last_alarm_at = new Date(video.panel.last_alarm_at); // set the last alarm at
              video.lastAlarmAtIso = video.last_alarm_at.toISOString();
              video.lastAlarmAtMinusTenMin = video.getTheTimeForV6000Events(
                video.lastAlarmAtIso
              );
              video.dealer_id = data.dealer.master_identifiers[0].dealer_id; // set the dealer id

              let secondsAgo = 30; // seconds ago to get the clips
              let dateNow = new Date(); // get the current date
              video.alarm_stream_date = new Date(
                video.last_alarm_at.getTime() - secondsAgo * 1000 // set the alarm stream date to be 30 seconds ago
              );

              dealerInfo = data.dealer; // set the dealer info
              if (
                dealerInfo.video_verification_strategy && // if the dealer has a video verification strategy and...
                // if the dealer has a video verification strategy of master_identifier
                dealerInfo.video_verification_strategy == "master_identifier"
              ) {
                var primary = $filter("filter")(
                  data.dealer.master_identifiers,
                  {
                    primary: true,
                  }
                );
                UserService.auth_token = primary[0].authentication_token;
                UserService.dealer_id = primary[0].dealer_id;
                $sessionStorage.auth_token = UserService.auth_token;
                if (!search.vv) {
                  $location.search(
                    "vv",
                    encrypt(video.control_system_id.toString(), "vv")
                  );
                }
              }

              getCamerasInAlarm()
                .then(
                  function () {
                    // Get or update the cameras
                    angular.forEach(data.video_devices, function (deviceList) {
                      var newCameras = [];
                      // It's OK to run this for non-NVR devices...it handles that.
                      getOffsetForNVRTimezone(deviceList)
                        .then(
                          function (offset) {
                            deviceList.time_zone_offset = offset;
                            // Loop through the channels and create Camera objects
                            angular.forEach(
                              deviceList.channels,
                              function (cameraList) {
                                const thisCamera = new Camera( // create a new camera object
                                  cameraList.channel_video_verification_path,
                                  cameraList.clips_video_verification_path,
                                  cameraList.trigger_video_verification_path,
                                  cameraList.id,
                                  cameraList.name,
                                  cameraList.manufacturer,
                                  cameraList.network_ready,
                                  cameraList.checked_in_at,
                                  cameraList.is_online
                                );
                                thisCamera.device_type = deviceList.device_type;
                                thisCamera.time_zone_offset =
                                  deviceList.time_zone_offset;
                                thisCamera.in_alarm = false;
                                thisCamera.manufacturer =
                                  deviceList.manufacturer;
                                thisCamera.device_id = deviceList.id;
                                thisCamera.network_ready =
                                  deviceList.network_ready;
                                thisCamera.checked_in_at =
                                  deviceList.checked_in_at;
                                thisCamera.is_online = isOnline(thisCamera);
                                // set up a $watch to keep checked_in_at for each camera up to date when it changes

                                // if the camera is in the alarm list, set the in_alarm flag to true
                                for (let camInAlarm of video.camerasInAlarm) {
                                  if (camInAlarm.channel_id === thisCamera.id) {
                                    thisCamera.in_alarm = true;
                                  }
                                }
                                // If we've already retrieved the video devices, we just need to do some updates, then return the original camera object
                                var foundCameraIndex = video.cameras.findIndex(
                                  (c) => c.id === thisCamera.id
                                );
                                if (foundCameraIndex > -1) {
                                  // Update the existing one with the new one
                                  video.cameras[foundCameraIndex].in_alarm =
                                    thisCamera.in_alarm;
                                  //
                                  // Update checked_in_at and isOnline for the network notification area
                                  video.cameras[
                                    foundCameraIndex
                                  ].checked_in_at = thisCamera.checked_in_at;
                                  video.cameras[foundCameraIndex].is_online =
                                    isOnline(thisCamera);

                                  video.cameras[foundCameraIndex].get();
                                } else {
                                  video.cameras.push(thisCamera);
                                }
                              }
                            );

                            deferred.resolve();
                          },
                          function (error) {
                            // error
                            $rootScope.alerts.push({
                              type: "error",
                              text: "Unable to retrieve Time Zone for NVR  ",
                              json: error,
                            });
                          }
                        )
                        .catch(function (error) {
                          // catch any errors
                          console.error(error);
                        });
                    });
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  deferred.reject();
                });
            }
          },
          function (error) {
            // Setting customer undefined will cause the page to load the "not in alarm" message
            USE_VARIABLE_POLLING = true;
            video.customer = undefined;
            deferred.reject();
          }
        )
        .catch(function (error) {
          deferred.reject();
        });
      return deferred.promise;
    }

    function getHlsStreamUrl(proxyUrl, authToken) {
      const deferred = $q.defer();
      const headers =
        $sessionStorage.auth_token.length > 40
          ? { headers: { Authorization: "Bearer " + authToken } }
          : {};
      $http
        .get(proxyUrl, headers)
        .then(
          ({ data }) => deferred.resolve(data.hls_url),
          (error) => deferred.reject(error)
        )
        .catch((error) => {
          console.error(error);
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getTheTimeForV6000Events(videoLastAlarmAt) {
      // convert the date from zulu time to local time
      const localDateTimeAlarmAt = new Date(videoLastAlarmAt);
      // subtract 10 minutes from the local time date
      const localDateTimeMinus10Min = subMinutes(localDateTimeAlarmAt, 10);
      // convert the local time  minus 10min  to ISO 8601 format
      const alarm10Before = formatISO(localDateTimeMinus10Min);
      return alarm10Before;
    }

    //there is a separate call to get the clips for the v6000
    video.getV6000Clips = (control_system_id, lastAlarm) => {
      return VideoService.getV6000EventsParsed(control_system_id, lastAlarm)
        .then((clips) => {
          clips.forEach((clip) => {
            video.clipsMap.set(clip.id, clip);
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    getVVInfo();

    function getCamerasInAlarm() {
      var deferred = $q.defer();
      CamerasInAlarmService.getCamsInAlarm(
        video.dealer_id,
        video.control_system_id
      )
        .then(
          function (data) {
            video.camerasInAlarm = data.cameras;
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    /**
     * Gets the offset of the NVR time zone
     * @param {*} videoDevice
     */
    function getOffsetForNVRTimezone(videoDevice) {
      var deferred = $q.defer();
      // For NVR devices, we need the timezone offset for streaming computations
      if (videoDevice.device_type !== "nvr") deferred.resolve();
      else {
        DateUtilsService.getOffsetForNVRTimezone(videoDevice.time_zone)
          .then(
            function (offset) {
              deferred.resolve(offset);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      return deferred.promise;
    }

    /**
     * Get the Video Verification Info on an interval, to display new alarms, and it keeps the snapshots from failing
     * is using the variable polling to poll aggressively for new alarms on initial load if no video is in alarm but max out at 30 seconds gradually increasing the interval
     * Will poll every 30 seconds once the video loads
     */
    var getVVInfoRetry = $timeout(function retry() {
      getUpdatedVVInfo().then(function () {
        $timeout(function () {
          if (!video.clipsBusy) {
            video.getCombinedClips(
              video.control_system_id,
              video.lastAlarmAtMinusTenMin
            );
          }
        }, 200);
      });
      getVVInfoRetry = $timeout(
        retry,
        getVariableVVInfoTime(USE_VARIABLE_POLLING)
      );
    }, getVariableVVInfoTime(USE_VARIABLE_POLLING));

    var clipTimer;
    var autoRefreshClips = function () {
      clipTimer = $timeout(function () {
        if (!video.clipsBusy) {
          video.getCombinedClips(
            video.control_system_id,
            video.lastAlarmAtMinusTenMin
          );
        }
        autoRefreshClips();
      }, REFRESH_CLIP_TIME);
    };

    $scope.$on("$destroy", function () {
      if (angular.isDefined(clipTimer)) {
        $timeout.cancel(clipTimer);
        clipTimer = undefined;
      }
      current_hls && current_hls.destroy();
      $timeout.cancel(getVVInfoRetry);
      getVVInfoRetry = undefined;
    });

    video.setView = function (view) {
      video.view_view = view;
      $sessionStorage.view_view = view;
    };

    video.getClips = function () {
      // get the clips for the current camera
      const promises = [];

      angular.forEach(video.cameras, (camera) => {
        // loop through the cameras
        if (
          (camera.in_alarm || video.toggleCameras) &&
          !["Uniview", "Malmoset"].includes(camera.manufacturer)
        ) {
          promises.push(
            camera
              .getVVClips()
              .then(
                function (data) {
                  angular.forEach(data, function (the) {
                    the.clip.time = $filter("date")(
                      the.clip.created_at, // convert the created_at to a date
                      "hh:mm a" // format the date (Removed the seconds to match v-6000 events)
                    );
                    video.clipsMap.set(the.clip.id, the.clip);
                  });
                },
                function (error) {}
              )
              .catch(function (error) {
                console.error(error);
              })
          );
        }
      });

      return Promise.all(promises);
    };

    /**
     * @function getCombinedClips
     * @description Gets the clips from the v-6000 and the clips
     * from the cameras and combines them into one sorted array.
     * @param {} control_system_id // the control system id`
     * @param {*} alarmAt // the time of the last alarm
     */
    video.getCombinedClips = (control_system_id, alarmAt) => {
      video.clipsBusy = true;
      trackGoogleAnalyticsEvent(
        "Video_Verification",
        "Get_Clips",
        "Get_Clips",
        {
          clipsBusy: video.clipsBusy,
          nonInteraction: true,
        }
      );
      try {
        video
          .getV6000Clips(control_system_id, alarmAt)
          .then(video.getClips)
          .then(() => {
            video.clipsCombined = Array.from(video.clipsMap.values()).sort(
              (a, b) => {
                const timeA = new Date(a.created_at);
                const timeB = new Date(b.created_at);
                // sort the clips by time (most recent to oldest)
                return timeB - timeA;
              }
            );
            video.clipsBusy = false;
            trackGoogleAnalyticsEvent(
              "Video_Verification",
              "Got_Clips",
              "Got_Clips",
              {
                clipsBusy: video.clipsBusy,
                nonInteraction: true,
              }
            );
          });
      } catch (error) {
        console.error(error);
        video.clipsBusy = false;
        trackGoogleAnalyticsEvent(
          "Video_Verification",
          "Get_Clips_Error",
          "Get_Clips_Error",
          {
            clipsBusy: video.clipsBusy,
            nonInteraction: true,
          }
        );
      }
    };

    video.filterClips = function (clip) {
      if (video.clipsToView === "ALARM") {
        return clip.origin_event_type === "MANUAL";
      } else {
        return true;
      }
    };

    async function setClipDownloadUrl(url, authToken) {
      const searchParams = new URLSearchParams(url.split("?")[1]); // extract the search params from the URL
      const path = url.split("?")[0]; // extract the path from the URL
      const newPath = path.replace("recorded", "clip"); // replace "recorded" with "clip"
      searchParams.set("auth_token", authToken); // add the auth token to the search params
      const newUrl = `${newPath}?${searchParams.toString()}`; // create the new URL with the modified path and search params
      return newUrl;
    }

    // get the hsl url for the current v6000 camera
    video.setV6000ClipUrl = async function (clip) {
      // Some bugs happen if we allow the user to select a camera they're already viewing
      // We need to just return if they do that
      if (video.clip_url && video.clip_url === clip.event_stream_url) {
        return;
      }
      video.chanelId = clip.camera_number;
      video.currentCamera = video.getCurrentV6000ClipCamera(clip);
      video.activeStreamingCamera = video.currentCamera;
      video.currentCameraType = "V6000";
      // used to hide the unused controls for the v6000 cameras
      video.clip_url = ""; //clearing the URL causes the directive to clear and reload the same video if needed.
      video.current_clip_name = undefined;
      video.current_camera_name = clip.nameSubstring;
      video.videoPlayerType = "HLS_STREAM";
      current_hls && current_hls.destroy();
      $timeout(async function () {
        video.current_clip_name = `${video.customer.name} : ${
          video.panel.account_prefix
        } - ${video.panel.account_number} - ${video.control_system.name}, ${
          clip.camera_name ? clip.camera_name : clip.nameSubstring
        } - ${clip.time}`;

        video.clip_url = await setClipDownloadUrl(
          clip.event_stream_url,
          $sessionStorage.auth_token.length > 40
            ? getAuthFromJWT($sessionStorage.auth_token)
            : $sessionStorage.auth_token
        );
        video.email_clip =
          "mailto:" +
          video.customer.email +
          "?subject=" +
          encodeURI("Clip for " + video.current_clip_name) +
          "&body=" +
          encodeURI(video.clip_url);
        resizeView({ type: "manual" });
      }, 100);

      await video
        .getHlsStreamUrl(clip.event_stream_url, $sessionStorage.auth_token)
        .then(
          (hlsUrl) => {
            if (video.currentHlsUrl) {
              VideoService.destroyVidproxHlsStream(video.currentHlsUrl);
            }
            video.activeStreamingCamera.hls_url = video.currentHlsUrl = hlsUrl;
          },
          (error) => console.error(error)
        );
    };

    video.getV6000HlsUrlFromProxy = function (proxyUrl) {
      var deferred = $q.defer();
      var _this = this;

      let link = proxyUrl;

      const headers =
        $sessionStorage.auth_token.length > 40
          ? {
              headers: {
                Authorization: "Bearer " + $sessionStorage.auth_token,
              },
            }
          : {};

      $http
        .get(link, headers)
        .then(
          function (data) {
            const hlsData = data.data;
            deferred.resolve(hlsData);
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          deferred.reject(error);
        });
      return deferred.promise;
    };

    video.setClipURL = function (clip) {
      // Some bugs happen if we allow the user to select a camera they're already viewing
      // We need to just return if they do that
      if (video.clip_url && video.clip_url === clip.url) {
        return;
      }

      video.clip_url = ""; //clearing the URL causes the directive to clear and reload the same video if needed.
      video.current_clip_name = undefined;
      video.current_camera_name = clip.camera_name
        ? clip.camera_name
        : clip.nameSubstring;
      video.videoPlayerType = "CLIP_STREAM";
      current_hls && current_hls.destroy();
      $timeout(function () {
        video.current_clip_name = `${video.customer.name} : ${
          video.panel.account_prefix
        } - ${video.panel.account_number} - ${video.control_system.name}, ${
          clip.camera_name ? clip.camera_name : clip.nameSubstring
        } - ${clip.time}`;

        video.clip_url = clip.url;
        video.email_clip =
          "mailto:" +
          video.customer.email +
          "?subject=" +
          encodeURI("Clip for " + video.current_clip_name) +
          "&body=" +
          encodeURI(clip.url);
        resizeView({ type: "manual" });
      }, 100);
    };

    // This function is used to determine which function to use to set the clip url
    video.determineClipUrlFunction = function (clip) {
      if (clip.event_stream_url) {
        video.setV6000ClipUrl(clip);
      } else {
        video.setClipURL(clip);
      }
    };
    // This function returns the current camera object for the given V6000 clip
    // because the V6000 clips don't have the camera object attached to them.
    video.getCurrentV6000ClipCamera = function (clip) {
      video.currentV6000Camera = video.cameras.find(
        (camera) => camera.id === clip.camera_number
      );
      return video.currentV6000Camera;
    };
    video.getPanClass = function () {
      if (browser.msie) {
        return "camera-control-pan--ie";
      } else {
        return "camera-control-pan";
      }
    };
    video.getTiltClass = function () {
      if (browser.msie) {
        return "camera-control-tilt--ie";
      } else {
        return "camera-control-tilt";
      }
    };

    /**
     * Function that sets a specific camera as active, then gets a playback camera stream for the given date/time
     * Used for "RECORDED" streams only
     * @param {*} camera
     */
    function goToAlarmStream(camera) {
      cleanUpStreams();
      video.viewing_alarm_stream = true;
      video.viewing_live_stream = false;
      video.activeStreamingCamera = camera;
      video.activeStreamType = "PLAYBACK";
      camera.stream_user_start_time = new Date();
      let goToStreamTime = adjustStreamForTimeZone(
        camera,
        video.alarm_stream_date
      );
      camera.getNVRCameraStream(goToStreamTime).then(
        function (hlsUrl) {},
        function (error) {
          //alert(error);
          $rootScope.alerts.push({
            type: "error",
            text: "Error playing video for camera. ",
            json: error,
          });
        }
      );
    }

    function goToV6000AlarmStream(clip) {
      cleanUpStreams();
      let camera = video.cameras.find(
        (camera) => camera.id === clip.camera_name
      );
      video.viewing_alarm_stream = true;
      video.viewing_live_stream = false;
      video.activeStreamingCamera = video.cameras.find(
        (camera) => camera.id === clips[0].camera_name
      );
      video.activeStreamType = "PLAYBACK";
      camera.stream_user_start_time = new Date();
      let goToStreamTime = adjustStreamForTimeZone(
        camera,
        video.alarm_stream_date
      );
      camera.getv6000CameraStream(goToStreamTime).then(
        function (hlsUrl) {},
        function (error) {
          //alert(error);
          $rootScope.alerts.push({
            type: "error",
            text: "Error playing video for camera. ",
            json: error,
          });
        }
      );
    }

    /**
     * Function that sets a specific camera as active, then gets the camera stream for it.
     * Used for "LIVE" streams, not recorded playback
     * @param {*} camera
     */
    function goToLiveStream(camera) {
      cleanUpStreams();
      video.viewing_live_stream = true;
      video.viewing_alarm_stream = false;
      video.activeStreamingCamera = camera;
      video.videoPlayerType = "HLS_STREAM";
      video.activeStreamType = "LIVE";
      camera.stream_user_start_time = new Date();
      camera.getNVRCameraStream().then(
        function (hlsUrl) {},
        function (error) {
          //alert(error);
          $rootScope.alerts.push({
            type: "error",
            text: "Error playing video for camera. ",
            json: error,
          });
        }
      );
    }

    /**
     * Creates a playback stream 1 minute back from where you currently are
     * @param {*} camera
     */
    function goBackInStream(camera) {
      video.viewing_alarm_stream = false;
      video.viewing_live_stream = false;
      //elapsed_time = the difference between the current time and the start time of the stream
      let elapsedTime = Math.abs(new Date() - camera.stream_user_start_time);
      // Add the elapsed time to the stream_user_start_time to get where the stream is NOW
      let goToStreamTime = new Date();
      let startingPoint = camera.stream_start_time
        ? //If there is a start time, we can use that
          camera.stream_start_time.getTime()
        : // else we need to use the current time
          new Date().getTime();

      // subtract 1 minute from the starting point
      let numberOfMillis = startingPoint + elapsedTime - 60000;
      //set the time to the new time
      goToStreamTime.setTime(numberOfMillis);
      // adjust for timezone
      if (!camera.stream_start_time) {
        goToStreamTime = adjustStreamForTimeZone(camera, goToStreamTime);
      }
      //clean up any existing streams
      cleanUpStreams();
      video.activeStreamingCamera = camera; //
      video.activeStreamType = "PLAYBACK"; // set the stream type
      camera.stream_user_start_time = new Date(); // set the start time
      camera.getNVRCameraStream(goToStreamTime).then(
        function (hlsUrl) {},
        function (error) {
          $rootScope.alerts.push({
            type: "warning",
            text: "Could not move back in stream. Please try again.",
            json: error,
          });
          goToLiveStream(camera);
        }
      );
    }

    /**
     * Creates a playback stream 1 minute forward from where you currently are
     * @param {*} camera
     */
    function goForwardInStream(camera) {
      // If the camera is not playing recorded video, then it's live... you can't go forward in time.
      video.viewing_alarm_stream = false;
      video.viewing_live_stream = false;
      if (!camera.stream_playback) return false;

      let elapsedTime = Math.abs(new Date() - camera.stream_user_start_time);
      // Add the elapsed time to the stream_user_start_time to get where the stream is NOW
      let goToStreamTime = new Date();
      let numberOfMillis =
        camera.stream_start_time.getTime() + elapsedTime + 60000;
      goToStreamTime.setTime(numberOfMillis);

      if (!camera.stream_start_time) {
        goToStreamTime = adjustStreamForTimeZone(camera, goToStreamTime);
      }
      cleanUpStreams();
      video.activeStreamingCamera = camera;
      video.nvrStreamType = "PLAYBACK";
      camera.stream_user_start_time = new Date();
      camera.getNVRCameraStream(goToStreamTime).then(
        function (hlsUrl) {},
        function (error) {
          //sometimes the stream fails to play, so we push a warning toast.
          //and allow the user to try again.
          $rootScope.alerts.push({
            type: "warning",
            text: "Could not move forward in time. Please try again.",
            json: error,
          });
          goToLiveStream(camera);
        }
      );
    }

    /**
     * Adjusts the streaming time by comparing the user's timezone and the NVR timezone.
     * @param {*} camera
     * @param {*} originalStreamTime
     */
    function adjustStreamForTimeZone(camera, originalStreamTime) {
      // if the NVR is in a different time zone than the user (browser), adjust
      let browserOffset = new Date().getTimezoneOffset() * -1;
      let nvrOffset = camera.time_zone_offset * 60;
      let offsetDifference = undefined;
      let newStreamTime = originalStreamTime;
      if (browserOffset != nvrOffset) {
        offsetDifference = nvrOffset - browserOffset;
        newStreamTime = new Date(
          originalStreamTime.getTime() + offsetDifference * 60000
        );
      }
      return newStreamTime;
    }

    var current_hls;
    var current_video;

    /**
     * Used to start playing the hls/video player
     * @param {*} hls
     * @param {*} video
     */
    function onAttach(hls, video) {
      current_hls = hls;
      current_video = video;
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        current_video.play();
      });
    }

    video.$onDestroy = function () {
      current_hls && current_hls.destroy();
      cleanUpStreams();
    };

    /**
     * Clean up any streams that were previously used
     */
    function cleanUpStreams() {
      current_hls && current_hls.destroy();
      // Loop through all NVR cameras and delete any existing streams (there can only be 1)
      angular.forEach(video.cameras, function (camera) {
        camera.deleteExistingStream();
      });
    }

    function filterVideoVerification(camera) {
      return (
        camera.device_type === "camera" ||
        camera.device_type === "dvr" ||
        camera.device_type === "nvr"
      );
    }

    function filterCamerasAndConverters(camera) {
      return camera.device_type === "camera" || camera.device_type === "dvr";
    }

    function filterNVRs(camera) {
      return camera.device_type === "nvr";
    }

    function filterV6000s(camera) {
      return (
        camera.device_type === "camera" &&
        !["Uniview", "Malmoset"].includes(camera.manufacturer)
      );
    }

    var timeoutPromise = undefined;
    var window = angular.element($window);
    var viewerPadding = -87;
    var playerPadding = -117;
    var playerPadding2 = 0;
    video.viewerHeight = window.height() + viewerPadding;
    video.playerHeight =
      window.height() -
      (window.width() / 3) * 0.75 +
      playerPadding +
      playerPadding2;

    window.on("resize orientationchange", function (e) {
      resizeView(e);
    });

    var resizeView = function (e) {
      if (timeoutPromise) {
        $timeout.cancel(timeoutPromise);
      }
      timeoutPromise = $timeout(function () {
        video.viewerHeight = window.height() + viewerPadding;
        if (video.current_clip_name) {
          playerPadding2 = -48;
        }
        video.playerHeight =
          window.height() -
          (window.width() / 3) * 0.75 +
          playerPadding +
          playerPadding2;
      }, 250);
    };
  },
]);

/**
 *  This directive binds the hls.js library with the video tag
 */
App.directive("hlsSource", function () {
  return {
    link: postLink,
  };
  function postLink(scope, elem, attrs) {
    const video = elem[0];
    const hlsConfig = {
      manifestLoadingTimeOut: 20000,
    };
    let hls;

    // Used to loop back to the beginning at the end of the clip
    video.onwaiting = () => (video.currentTime = 0);

    scope.$watch(attrs.hlsSource, (value) => {
      if (!value) return;
      //else
      hls && hls.destroy();
      hls = new Hls(hlsConfig);
      hls.attachMedia(video);
      hls.loadSource(value);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(value);
        hls.on(Hls.Events.MANIFEST_PARSED, (_event, _data) => {
          // Delay playing video slightly to allow buffering
          setTimeout(() => video.play(), 500);
        });
      });
    });
  }
});
