import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { applyTakeoverZoneInformationProgrammingToZoneInformation } from "components/FullProgramming/common/ZoneInformationFields/utils";
import {
  zoneListItemTemplateId,
  ZONE_IDS,
} from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationNumberField";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { omit } from "ramda";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  Area,
  asID,
  ControlSystem,
  fromAreaId,
  fromControlSystemId,
  fromZoneId,
  idAsString,
  KeypadInput,
  Panel,
  toAreaId,
  toGlobalId,
  toZoneId,
  Zone,
  ZoneEntryDelayNumber,
  ZoneMessage,
  ZoneOutputAction,
  ZoneSupervisionTime,
} from "securecom-graphql/client";
import { useControlSystemFragment } from "../../common/ControlSystemContext";
import TakeoverPanelZoneInformationsFields from "./TakeoverPanelZoneInformationsFields";
import {
  TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem$data,
  TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem.graphql";
import { TakeoverPanelZoneInformationsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TakeoverPanelZoneInformationsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts.graphql";
import {
  TakeoverPanelZoneInformationsProgrammingConceptFormInline_zone$data,
  TakeoverPanelZoneInformationsProgrammingConceptFormInline_zone$key,
} from "./__generated__/TakeoverPanelZoneInformationsProgrammingConceptFormInline_zone.graphql";
import { TakeoverPanelZoneInformationsProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/TakeoverPanelZoneInformationsProgrammingConceptFormNavButton_controlSystem.graphql";
import { TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationDeleteMutation } from "./__generated__/TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationRefreshMutation,
} from "./__generated__/TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationRefreshMutation.graphql";
import {
  TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationSendMutation,
  TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationSendMutation$data,
} from "./__generated__/TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationSendMutation.graphql";
import { TakeoverPanelZoneInformationsProgrammingConceptForm_controlSystem$key } from "./__generated__/TakeoverPanelZoneInformationsProgrammingConceptForm_controlSystem.graphql";

export const title = "Zone Information";
export const conceptId = "takeover-panel-zone-informations";

export const getState = (
  controlSystem: TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          zoneNumberRange
          totalWirelessZoneMax
          totalZonesMax
          zoneInformations {
            __typename
            id
            isNew
            ...TakeoverPanelZoneInformationsProgrammingConceptFormInline_zone
          }
        }
      }
    `,
    controlSystem
  );

export const getZoneState = (
  zone: TakeoverPanelZoneInformationsProgrammingConceptFormInline_zone$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverPanelZoneInformationsProgrammingConceptFormInline_zone on Zone
      @inline {
        __typename
        id
        name
        location
        number
        type
        area {
          __typename
          id
          number
        }
        followArea {
          __typename
          number
        }
        reportWithAccountNumberForArea {
          __typename
          number
        }
        chimeSound
        contactNumber
        crossZoneEnabled
        entryDelayNumber
        prewarnKeypads
        priorityZone
        is24HrZone
        serialNumber
        supervisionTime
        swingerBypassEnabled
        trafficCountEnabled
        wireless
        wirelessDisarmDisableEnabled
        wirelessLedEnabled
        wirelessPetImmunity
        sensorType
        wirelessPirPulseCount
        wirelessPirSensitivity
        zoneAuditDays
        wirelessContactNormallyOpen
        armedAreasForArmingZone
        disarmedOpenActionMessage
        disarmedOpenOutputNumber
        disarmedOpenOutputAction
        disarmedShortActionMessage
        disarmedShortOutputNumber
        disarmedShortOutputAction
        armedOpenActionMessage
        armedOpenOutputNumber
        armedOpenOutputAction
        armedShortActionMessage
        armedShortOutputNumber
        armedShortOutputAction
        armingStyle
        retardDelayEnabled
        fastResponseEnabled
        firePanelSlaveInput
        realTimeStatusEnabled
        lockdownEnabled
        normallyClosed
        competitorWireless
        receiverRouting
        expanderSerialNumber
        isECP
        isNew
      }
    `,
    zone
  );

const deleteMutation = graphql`
  mutation TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationDeleteMutation(
    $id: ID!
  ) {
    deleteZoneInformation(id: $id) {
      ... on DeleteZoneInformationSuccessPayload {
        __typename
        deletedZoneId
      }
      ... on FailedToRemoveZoneErrorPayload {
        error: type
      }
    }
  }
`;

const retrieveMutation = graphql`
  mutation TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationRefreshMutation(
    $id: ID!
  ) {
    refreshZoneInformation(id: $id) {
      ... on RefreshZoneInformationSuccessPayload {
        __typename
        controlSystem {
          ...TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshZoneInformation;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Zone Information Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshZoneInformation: {
                    __typename: response.refreshZoneInformation.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Zone Information: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Zone Information",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationSendMutation(
    $systemId: ID!
    $zoneInformations: [ZoneProgrammingInput!]!
  ) {
    sendZoneProgramming(systemId: $systemId, zones: $zoneInformations) {
      ... on SendZoneProgrammingSuccessPayload {
        results {
          ... on SendZoneProgrammingZoneSuccessPayload {
            __typename
            zone {
              __typename
              id
              ...TakeoverPanelZoneInformationsProgrammingConceptFormInline_zone
            }
          }
          ... on SendListItemsErrorPayload {
            __typename
            number
            errors {
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
      ... on Error {
        type
      }
    }
  }
`;

const mergeOldAndNewZones = (
  response: TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationSendMutation$data,
  originalControlSystemData: TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendZoneProgramming.results) {
    const successfulZones = response.sendZoneProgramming.results
      .map((zone) => {
        if (zone.__typename === "SendZoneProgrammingZoneSuccessPayload") {
          return zone;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.zone)
      .map(getZoneState);

    const mergedZonesMap = new Map<
      string,
      TakeoverPanelZoneInformationsProgrammingConceptFormInline_zone$data
    >();

    originalControlSystemData.panel.zoneInformations
      .map(getZoneState)
      .forEach((item) => mergedZonesMap.set(item.id, item));

    successfulZones.forEach((item) =>
      mergedZonesMap.set(item.id, {
        ...mergedZonesMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedZonesMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationSendMutation$data,
  originalControlSystemData: TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedZones = mergeOldAndNewZones(response, originalControlSystemData);

  // Update original data store
  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshZoneInformation: {
        __typename: "RefreshZoneInformationSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            zoneInformations: mergedZones,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const changedZones = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { zoneInformations },
        } = getState(props.controlSystem);
        save({
          variables: {
            systemId,
            zoneInformations: zoneInformations
              .filter(
                (zone) =>
                  (!!changedZones &&
                    listItemHasChanged(zone.id, changedZones)) ||
                  zone.isNew ||
                  isSavingAllListItems
              )
              .map(getZoneState)
              .map((zone) => ({
                id: zone.id,
                number: zone.number.toString(),
                name: zone.name,
                location: zone.location,
                type: zone.type,
                area: zone.area?.number ?? "",
                followArea: zone.followArea?.number ?? "",
                reportWithAccountNumberForArea:
                  zone?.reportWithAccountNumberForArea?.number ?? "",
                armedAreasForArmingZone:
                  zone.armedAreasForArmingZone.toString(),
                disarmedOpenActionMessage:
                  zone.disarmedOpenActionMessage ?? ZoneMessage.NONE,
                disarmedOpenOutputNumber: zone.disarmedOpenOutputNumber,
                disarmedOpenOutputAction:
                  zone.disarmedOpenOutputAction ?? ZoneOutputAction.NONE,
                disarmedShortActionMessage:
                  zone.disarmedShortActionMessage ?? ZoneMessage.NONE,
                disarmedShortOutputNumber: zone.disarmedShortOutputNumber,
                disarmedShortOutputAction:
                  zone.disarmedShortOutputAction ?? ZoneOutputAction.NONE,
                armedOpenActionMessage:
                  zone.armedOpenActionMessage ?? ZoneMessage.NONE,
                armedOpenOutputNumber: zone.armedOpenOutputNumber,
                armedOpenOutputAction:
                  zone.armedOpenOutputAction ?? ZoneOutputAction.NONE,
                armedShortActionMessage:
                  zone.armedShortActionMessage ?? ZoneMessage.NONE,
                armingStyle: zone.armingStyle,
                armedShortOutputNumber: zone.armedShortOutputNumber,
                armedShortOutputAction:
                  zone.armedShortOutputAction ?? ZoneOutputAction.NONE,
                swingerBypassEnabled: zone.swingerBypassEnabled,
                retardDelayEnabled: zone.retardDelayEnabled,
                fastResponseEnabled: zone.fastResponseEnabled,
                prewarnKeypads: zone.prewarnKeypads,
                crossZoneEnabled: zone.crossZoneEnabled,
                entryDelayNumber:
                  zone.entryDelayNumber ?? ZoneEntryDelayNumber.FOUR,
                priorityZone: zone.priorityZone,
                firePanelSlaveInput: zone.firePanelSlaveInput,
                realTimeStatusEnabled: zone.realTimeStatusEnabled,
                trafficCountEnabled: zone.trafficCountEnabled,
                zoneAuditDays: zone.zoneAuditDays,
                wireless: zone.wireless,
                serialNumber: zone.serialNumber,
                contactNumber: zone.contactNumber,
                supervisionTime:
                  zone.supervisionTime ?? ZoneSupervisionTime.NONE,
                wirelessLedEnabled: zone.wirelessLedEnabled,
                wirelessDisarmDisableEnabled: zone.wirelessDisarmDisableEnabled,
                wirelessPirPulseCount: zone.wirelessPirPulseCount,
                wirelessPirSensitivity: zone.wirelessPirSensitivity,
                wirelessPetImmunity: zone.wirelessPetImmunity,
                chimeSound: zone.chimeSound,
                lockdownEnabled: zone.lockdownEnabled,
                normallyClosed: zone.normallyClosed,
                wirelessContactNormallyOpen: zone.wirelessContactNormallyOpen,
                competitorWireless: zone.competitorWireless,
                sensorType: zone.sensorType,
                receiverRouting: zone.receiverRouting,
                expanderSerialNumber: zone.expanderSerialNumber,
                isNew: zone.isNew,
              })),
          },
          onCompleted: (response) => {
            const sendErrors: SaveErrors = [];
            if (response.sendZoneProgramming.type && showAlert) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendZoneProgramming.results) {
              response.sendZoneProgramming.results.forEach((response) => {
                if (
                  response.__typename ===
                  "SendZoneProgrammingZoneSuccessPayload"
                ) {
                  resetLastUpdated(response.zone.id);
                } else if (
                  response.__typename === "SendListItemsErrorPayload"
                ) {
                  sendErrors.push({
                    programmingConcept: title,
                    errors: response.errors,
                    listItemNumber: response.number,
                  });
                }
              });

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (!sendErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: "Successfully Updated Zone Informations",
                });
              }
            }
            resolve(sendErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSaving,
  ];
};

const readZoneInformationsTemplateData = (
  programmingTemplateConcepts: TakeoverPanelZoneInformationsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverPanelZoneInformationsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        zoneInformations {
          included
          number
          name {
            included
            data
          }
          location {
            included
            data
          }
          type {
            included
            data
          }
          area {
            included
            data
          }
          followArea {
            included
            data
          }
          reportWithAccountNumberForArea {
            included
            data
          }
          armedAreasForArmingZone {
            included
            data
          }
          disarmedOpenActionMessage {
            included
            data
          }
          disarmedOpenOutputNumber {
            included
            data
          }
          disarmedOpenOutputAction {
            included
            data
          }
          disarmedShortActionMessage {
            included
            data
          }
          disarmedShortOutputNumber {
            included
            data
          }
          disarmedShortOutputAction {
            included
            data
          }
          armedOpenActionMessage {
            included
            data
          }
          armedOpenOutputNumber {
            included
            data
          }
          armedOpenOutputAction {
            included
            data
          }
          armedShortActionMessage {
            included
            data
          }
          armingStyle {
            included
            data
          }
          armedShortOutputNumber {
            included
            data
          }
          armedShortOutputAction {
            included
            data
          }
          swingerBypassEnabled {
            included
            data
          }
          retardDelayEnabled {
            included
            data
          }
          fastResponseEnabled {
            included
            data
          }
          prewarnKeypads {
            included
            data
          }
          crossZoneEnabled {
            included
            data
          }
          entryDelayNumber {
            included
            data
          }
          priorityZone {
            included
            data
          }
          firePanelSlaveInput {
            included
            data
          }
          realTimeStatusEnabled {
            included
            data
          }
          trafficCountEnabled {
            included
            data
          }
          zoneAuditDays {
            included
            data
          }
          wireless {
            included
            data
          }
          contactNumber {
            included
            data
          }
          supervisionTime {
            included
            data
          }
          wirelessLedEnabled {
            included
            data
          }
          wirelessDisarmDisableEnabled {
            included
            data
          }
          wirelessPirPulseCount {
            included
            data
          }
          wirelessPirSensitivity {
            included
            data
          }
          wirelessPetImmunity {
            included
            data
          }
          chimeSound {
            included
            data
          }
          lockdownEnabled {
            included
            data
          }
          normallyClosed {
            included
            data
          }
          wirelessContactNormallyOpen {
            included
            data
          }
          competitorWireless {
            included
            data
          }
          sensorType {
            included
            data
          }
          receiverRouting {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).zoneInformations;

export function applyTemplateData(
  programmingTemplateConcepts: TakeoverPanelZoneInformationsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const systemId = controlSystemRecordProxy.getValue("id");
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const zoneRecordProxies =
    panelRecordProxy.getLinkedRecords("zoneInformations") ?? [];
  const areaRecordProxies = panelRecordProxy.getLinkedRecords("areas") ?? [];
  const zonesByNumber = indexRecordProxiesByNumber(zoneRecordProxies);

  const zonesTemplateData =
    readZoneInformationsTemplateData(programmingTemplateConcepts) ?? [];
  zonesTemplateData.forEach((zoneTemplateData) => {
    if (zoneTemplateData?.included) {
      let zoneRecordProxy = zonesByNumber.get(zoneTemplateData.number);
      if (!zoneRecordProxy) {
        const newZoneId = applyNewDeviceToZoneInformationsList(
          {
            id: panelRecordProxy.getValue("id"),
            systemOptions: {
              keypadInput: panelRecordProxy
                .getLinkedRecord("systemOptions")
                ?.getValue("keypadInput") as KeypadInput,
            },
            supportsDscZones:
              panelRecordProxy.getValue("supportsDscZones") ?? false,
            supportsEcpZones:
              panelRecordProxy.getValue("supportsEcpZones") ?? false,
            ecpDscZoneNumberRange:
              panelRecordProxy.getValue("ecpDscZoneNumberRange") ?? [],
            zoneNumberRange: panelRecordProxy.getValue("zoneNumberRange") ?? [],
            zoneInformations: zoneRecordProxies.map(
              (zoneRecordProxy) =>
                ({ number: zoneRecordProxy.getValue("number") } as const)
            ),
            outputInformations:
              panelRecordProxy
                .getLinkedRecords("outputInformations")
                ?.map((recordProxy) => ({
                  number: recordProxy.getValue("number"),
                })) ?? [],
            keyfobs:
              panelRecordProxy
                .getLinkedRecords("keyfobs")
                ?.map((recordProxy) => ({
                  number: recordProxy.getValue("number"),
                })) ?? [],
          },
          store
        );
        if (newZoneId) {
          zoneRecordProxy = store.get(newZoneId) as RecordProxy<Zone>;
          if (zoneRecordProxy) {
            zoneRecordProxy.setValue(zoneTemplateData.number, "number");
          }
        }
      }

      if (!zoneRecordProxy) {
        return;
      }

      applyTemplateScalarDataToRecordProxy(
        zoneRecordProxy,
        omit(["area"], zoneTemplateData)
      );

      if (zoneTemplateData.area?.included && zoneTemplateData.area.data) {
        let areaRecordProxy = store.get(
          idAsString(
            toAreaId(
              fromControlSystemId(asID(systemId)).systemId,
              zoneTemplateData.area.data
            )
          )
        ) as RecordProxy<Area>;

        if (!areaRecordProxy) {
          areaRecordProxy = store.get(
            idAsString(
              asID(
                areaRecordProxies
                  .find(
                    (area) =>
                      fromAreaId(asID(area.getDataID().split(":")[7] ?? ""))
                        .number === zoneTemplateData?.area?.data
                  )
                  ?.getDataID()
                  .split(":")[7] ?? ""
              )
            )
          ) as RecordProxy<Area>;
        }

        if (areaRecordProxy) {
          zoneRecordProxy.setLinkedRecord(areaRecordProxy, "area");
        }
      }

      if (!zonesByNumber.has(zoneTemplateData.number)) {
        zonesByNumber.set(zoneTemplateData.number, zoneRecordProxy);
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(zonesByNumber),
    "zoneInformations"
  );
}

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<TakeoverPanelZoneInformationsProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment TakeoverPanelZoneInformationsProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            zoneInformations {
              isNew
            }
          }
        }
      `
    );
  const { zoneInformations } = controlSystem.panel;
  const itemsCount = zoneInformations.length;
  const hasNewItems =
    itemsCount > 0 && zoneInformations.some(({ isNew }) => isNew);

  const { isEditing: isEditingTemplate } = useTemplateContext();

  return !isEditingTemplate ? ( // removes this concept from the nav when editing a template
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  ) : (
    <></>
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<TakeoverPanelZoneInformationsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment TakeoverPanelZoneInformationsProgrammingConceptForm_controlSystem on ControlSystem {
          __typename
          id
          copiedZoneInformation {
            id
          }
          isCellComEx
          panel {
            id
            zoneNumberRange
            ecpDscZoneNumberRange
            totalWirelessZoneMax
            totalZonesMax
            ...ZoneInformationNumberField_panel
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            supportsEcpZones
            supportsDscZones
            systemOptions {
              ... on XtSystemOptions {
                keypadInput
              }
              ...SystemOptionsContextSystemType_systemOptions
            }
            zoneInformations {
              id
              name
              number
              isNew
              isECP
              ...TakeoverPanelZoneInformationsFields_zone
            }
            newZone {
              id
              number
              name
              location
              type
              area {
                __typename
                id
                number
              }
              chimeSound
              contactNumber
              crossZoneEnabled
              entryDelayNumber
              prewarnKeypads
              priorityZone
              serialNumber
              supervisionTime
              swingerBypassEnabled
              trafficCountEnabled
              wireless
              wirelessDisarmDisableEnabled
              wirelessLedEnabled
              wirelessPetImmunity
              wirelessPirPulseCount
              wirelessPirSensitivity
              zoneAuditDays
              wirelessContactNormallyOpen
              armedAreasForArmingZone
              disarmedOpenActionMessage
              disarmedOpenOutputNumber
              disarmedOpenOutputAction
              disarmedShortActionMessage
              disarmedShortOutputNumber
              disarmedShortOutputAction
              armedOpenActionMessage
              armedOpenOutputNumber
              armedOpenOutputAction
              armedShortActionMessage
              armedShortOutputNumber
              armedShortOutputAction
              isNew
              ...TakeoverPanelZoneInformationsFields_zone
            }
            keyfobs {
              id
              number
            }
            outputInformations {
              id
              number
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseZoneNumberRange_panel
            ...PanelContextUseWirelessZoneNumberRange_panel
            ...PanelContextUseBuiltIn1100WirelessEnabled_panel
            ...PanelContextUseAreaList_panel
            ...ZoneInformationSerialNumberField_ZoneList_panel
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
        }
      `
    );
  const relayEnv = useRelayEnvironment();
  const uncheckListItem = useUncheckListItem()(ZONE_IDS);
  const { zoneInformations, newZone } = controlSystem.panel;

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    controlSystem.panel.zoneInformations[0]?.id ?? null
  );

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const [deleteZoneInformation, isDeleting] =
    useMutation<TakeoverPanelZoneInformationsProgrammingConceptFormZoneInformationDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const removeSelectedZone = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.zoneInformations.findIndex(
          (zone) => zone.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.zoneInformations.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return (
          controlSystem.panel.zoneInformations[newSelectedIndex]?.id ?? null
        );
      });
      const zone = zoneInformations.find(
        (zone) => zone.id === selectedListItemId
      );
      uncheckListItem(String(zone?.number));
      if (zone?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "zoneInformations",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "Zone",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            zone?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteZoneInformation({
          variables: {
            id: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "zoneInformations",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedZoneId } = response.deleteZoneInformation;
            if (deletedZoneId) {
              showAlert({
                type: "success",
                text: "Zone Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "zoneInformations",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedZoneId, error } = response.deleteZoneInformation;
            if (deletedZoneId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  removeListItemFromStore(
                    selectedListItemId,
                    "zoneInformations",
                    controlSystem.panel.id,
                    parentStore
                  );
                });
              }
            } else {
              if (
                response.deleteZoneInformation.__typename !==
                "DeleteZoneInformationSuccessPayload"
              ) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Delete Zone: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Delete Zone",
                  });
                }
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Zone",
            });
          },
        });
      }
    }
  };

  const availableNumbers = getAvailableNumbers(controlSystem.panel);
  const canAdd = availableNumbers.size > 0 && !!newZone;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#Zone%20Information`}
        title={title}
        deleting={isDeleting}
        isArrayConcept
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.zoneInformations
        )}
        amountAvailable={availableNumbers.size}
        addButton={
          <ProgrammingConceptForm.AddButton
            onClick={() => {
              relayEnv.commitUpdate((store) => {
                const newZoneId = applyNewDeviceToZoneInformationsList(
                  controlSystem.panel,
                  store
                );
                if (newZoneId) {
                  setSelectedListItemId(newZoneId);
                }
              });
            }}
          >
            Add Zone
          </ProgrammingConceptForm.AddButton>
        }
      >
        {(conceptId === activeConcept || isApplying || isSavingAll) && (
          <ProgrammingConceptForm.ListItemsContainer>
            <ProgrammingConceptForm.ListItemPicker
              selectedId={selectedListItemId}
              onChange={(id) => {
                setSelectedListItemId(id);
              }}
              newItemId={newZone?.id}
              items={controlSystem.panel.zoneInformations.map((zone) => ({
                id: zone.id,
                templateListItemId: zoneListItemTemplateId(zone.number),
                isnew: zone.isNew,
                label: `#${zone.number} ${zone.name}`,
              }))}
            />
            <ProgrammingConceptForm.SelectedItemsContainer
              selectedListItemId={selectedListItemId}
              setSelectedListItemId={setSelectedListItemId}
            >
              {zoneInformations.map(
                (zoneInformation) =>
                  (zoneInformation.id === selectedListItemId || //Rendering at the last second before saving to check if there are errors or changes
                    programmingConcepts[conceptId].isSaving ||
                    isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                    isSavingAll) && (
                    <RemountOnUpdateContainer nodeId={zoneInformation.id}>
                      <ProgrammingConceptForm.SelectedItem
                        conceptId={conceptId}
                        isnew={zoneInformation.isNew}
                        visible={zoneInformation.id === selectedListItemId}
                        key={zoneInformation.id}
                        listItemId={zoneInformation.id}
                        templateListItemId={zoneListItemTemplateId(
                          zoneInformation.number
                        )}
                        title={`# ${zoneInformation.number} ${zoneInformation.name}`}
                        onDuplicate={
                          !zoneInformation?.isECP &&
                          canAdd &&
                          (() => {
                            relayEnv.commitUpdate((store) => {
                              const duplicateId =
                                applyDuplicatedZoneInformationToZoneInformationsList(
                                  selectedListItemId,
                                  controlSystem.panel,
                                  store
                                );
                              if (duplicateId) {
                                setSelectedListItemId(duplicateId);
                              }
                            });
                          })
                        }
                        onCopy={
                          !zoneInformation?.isECP &&
                          (() => {
                            relayEnv.commitUpdate((store) => {
                              const controlSystemRecord = store.get(
                                controlSystem.id
                              );
                              const zoneInformationRecord =
                                store.get<Zone>(selectedListItemId);
                              if (
                                controlSystemRecord &&
                                zoneInformationRecord
                              ) {
                                const tempRecord =
                                  store.get("copiedZoneInformation") ??
                                  store.create(
                                    "copiedZoneInformation",
                                    "ZoneInformation"
                                  );
                                tempRecord.copyFieldsFrom(
                                  zoneInformationRecord
                                );
                                controlSystemRecord.setLinkedRecord(
                                  tempRecord,
                                  "copiedZoneInformation"
                                );
                              }
                            });
                          })
                        }
                        onPaste={
                          !zoneInformation?.isECP &&
                          !!controlSystem.copiedZoneInformation &&
                          (() => {
                            relayEnv.commitUpdate((store) => {
                              const zoneInformationRecord =
                                store.get<Zone>(selectedListItemId);
                              const copiedZoneInformationRecord =
                                store.get<Zone>("copiedZoneInformation");
                              if (
                                zoneInformationRecord &&
                                copiedZoneInformationRecord
                              ) {
                                applyTakeoverZoneInformationProgrammingToZoneInformation(
                                  copiedZoneInformationRecord,
                                  zoneInformationRecord,
                                  false
                                );
                              }
                            });
                          })
                        }
                        onRemove={removeSelectedZone}
                      >
                        <TakeoverPanelZoneInformationsFields
                          zoneInformation={zoneInformation}
                        />
                      </ProgrammingConceptForm.SelectedItem>
                    </RemountOnUpdateContainer>
                  )
              )}
            </ProgrammingConceptForm.SelectedItemsContainer>
          </ProgrammingConceptForm.ListItemsContainer>
        )}
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}

const applyNewDeviceToZoneInformationsList = (
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly supportsEcpZones: boolean;
    readonly supportsDscZones: boolean;
    readonly systemOptions: {
      readonly keypadInput?: KeypadInput | "NONE" | "ECP" | "DSC" | null;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, supportsEcpZones, supportsDscZones, systemOptions } = panel;
  const isEcpTakeover = systemOptions?.keypadInput === KeypadInput.ECP;
  const isDscTakeover = systemOptions?.keypadInput === KeypadInput.DSC;

  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newZoneRecord = panelRecord.getLinkedRecord("newZone");
    if (newZoneRecord) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromZoneId(asID(newZoneRecord.getDataID()));
        const nextNewZoneId = idAsString(toZoneId(systemId, nextNumber));
        const nextNewZone = store.create(
          nextNewZoneId,
          "ZoneInformation"
        ) as RecordProxy<Zone>;

        nextNewZone.copyFieldsFrom(newZoneRecord);
        nextNewZone.setValue(nextNewZoneId, "id");
        nextNewZone.setValue(nextNumber, "number");
        nextNewZone.setValue(true, "isNew");
        nextNewZone.setValue(
          (((isEcpTakeover && supportsEcpZones) ||
            (isDscTakeover && supportsDscZones)) &&
            Number(nextNumber) < 200) ||
            Number(nextNumber) > 203,
          "isECP"
        );

        const zoneInformationRecords =
          panelRecord.getLinkedRecords("zoneInformations") ?? [];

        panelRecord.setLinkedRecords(
          [...zoneInformationRecords, nextNewZone],
          "zoneInformations"
        );
        return nextNewZoneId;
      }
    }
  }

  return null;
};

const applyDuplicatedZoneInformationToZoneInformationsList = (
  zoneId: string,
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly supportsEcpZones: boolean;
    readonly supportsDscZones: boolean;
    readonly systemOptions: {
      readonly keypadInput?: KeypadInput | "NONE" | "ECP" | "DSC" | null;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, supportsEcpZones, supportsDscZones, systemOptions } = panel;
  const isECP = systemOptions?.keypadInput === KeypadInput.ECP;
  const isDSC = systemOptions?.keypadInput === KeypadInput.DSC;

  const panelRecord = store.get<Panel>(id);
  const zoneInformationRecord = store.get<Zone>(zoneId);
  if (panelRecord && zoneInformationRecord) {
    const newZoneRecord = panelRecord.getLinkedRecord("newZone");
    if (newZoneRecord) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromZoneId(asID(newZoneRecord.getDataID()));
        const nextNewZoneId = idAsString(toZoneId(systemId, nextNumber));
        const nextNewZone = store.create(
          nextNewZoneId,
          "Zone"
        ) as RecordProxy<Zone>;

        nextNewZone.copyFieldsFrom(newZoneRecord);
        nextNewZone.setValue(nextNewZoneId, "id");
        nextNewZone.setValue(nextNumber, "number");
        nextNewZone.setValue(true, "isNew");
        nextNewZone.setValue(
          (((isECP && supportsEcpZones) || (isDSC && supportsDscZones)) &&
            Number(nextNumber) < 200) ||
            Number(nextNumber) > 203,
          "isECP"
        );
        const duplicatedZoneInformationRecord =
          applyTakeoverZoneInformationProgrammingToZoneInformation(
            zoneInformationRecord,
            nextNewZone,
            false
          );
        const zoneInformationRecords =
          panelRecord.getLinkedRecords("zoneInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...zoneInformationRecords, duplicatedZoneInformationRecord],
          "zoneInformations"
        );

        return duplicatedZoneInformationRecord.getValue("id");
      }
    }
  }
};

const getAvailableNumbers = (panel: {
  readonly zoneNumberRange: ReadonlyArray<number>;
  readonly ecpDscZoneNumberRange: ReadonlyArray<number>;
  readonly outputInformations: ReadonlyArray<{ readonly number: number }>;
  readonly keyfobs: ReadonlyArray<{ readonly number: number }>;
  readonly zoneInformations: ReadonlyArray<{ readonly number: string }>;
  readonly systemOptions: {
    readonly keypadInput?: KeypadInput | "NONE" | "ECP" | "DSC" | null;
  } | null;
  readonly supportsEcpZones: boolean;
  readonly supportsDscZones: boolean;
}) => {
  const {
    zoneNumberRange,
    ecpDscZoneNumberRange,
    outputInformations,
    keyfobs,
    systemOptions,
    supportsEcpZones,
    supportsDscZones,
  } = panel;
  const isECP = systemOptions?.keypadInput === KeypadInput.ECP;
  const isDSC = systemOptions?.keypadInput === KeypadInput.DSC;
  const allPossibleNumbers = new Set(
    (isECP && supportsEcpZones) || (isDSC && supportsDscZones)
      ? ecpDscZoneNumberRange
      : zoneNumberRange
  );

  const conflictingWirelessOutputs = outputInformations.map(({ number }) =>
    [31, 32, 33, 34, 41, 42, 43, 44, 51, 52, 53, 54, 61, 62, 63, 64].includes(
      Number(number)
    )
      ? Number(number)
      : 0
  );

  const takenNumbers = new Set(
    panel.zoneInformations
      .map(({ number }) => Number(number))
      .concat(keyfobs.map(({ number }) => Number(number)))
      .concat(conflictingWirelessOutputs)
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(panel));
