import React, { useMemo } from "react";
import {
  BiDirectionalArrows,
  BiDirectionalLineArrows,
  InwardLineArrows,
  OutwardLineArrows,
  RegionArrowInwards,
  RegionArrowOutwards
} from "./Arrow";
import { ArrowState } from "./ArrowState";

interface DirectionOfTravelArrowsProps {
  arrowState: ArrowState; // Current state of the arrows
  regionWidth: number; // Width of the region
  regionHeight: number; // Height of the region
  angleOfRotation?: number; // Use the slope of the line to angle the arrow
}

const DirectionOfTravelArrows: React.FC<DirectionOfTravelArrowsProps> = ({
  arrowState,
  regionWidth,
  regionHeight,
  angleOfRotation,
}) => {
  const { scaleX, scaleY } = useMemo(() => {
    const scaleX = Math.max(regionWidth / 200, 0.5); // Minimum scale 0.5
    const scaleY = Math.max(regionHeight / 200, 0.5);
    return { scaleX, scaleY };
  }, [regionWidth, regionHeight]);

  const rotateArrow = angleOfRotation ? `rotate(${angleOfRotation}deg)` : '';

  return (
    <svg width="200" height="200" viewBox="0 0 200 200">
      <g
        style={{
          transform: `scale(${scaleX}, ${scaleY}) ${rotateArrow}`,
          transformOrigin: "center center",
        }}
      >
        {arrowState === ArrowState.Outward && <RegionArrowOutwards />}
        {arrowState === ArrowState.Inward && <RegionArrowInwards />}
        {arrowState === ArrowState.BiDirectional && <BiDirectionalArrows />}
        {arrowState === ArrowState.InwardLine && <InwardLineArrows />}
        {arrowState === ArrowState.OutwardLine && <OutwardLineArrows />}
        {arrowState === ArrowState.BiDirectionalLine && (
          <BiDirectionalLineArrows />
        )}
      </g>
    </svg>
  );
};

export default DirectionOfTravelArrows;
