import VisuallyHidden from "common/components/web/VisuallyHidden";
import * as React from "react";
import Select from "react-select";
import { useFieldChangesAndValidity } from "../hooks/use-field-changes-and-validity";

type SelectProps = React.ComponentProps<typeof Select>;

const SelectSearchable = (props: Omit<SelectProps, "id"> & { id: string }) => {
  const {
    id,
    name,
    key,
    value,
    onFocus,
    onBlur,
    onChange,
    required,
    onInvalid,
    backspaceRemovesValue,
    ...rest
  } = props;
  const selectRef = React.useRef<HTMLSelectElement>(null);
  const enhancedProps = useFieldChangesAndValidity({
    id,
    value: value?.value,
    onFocus: (event) => {
      if (selectRef.current) {
        selectRef.current.focus();
      }
      if (onFocus) {
        onFocus(event);
      }
    },
    onBlur,
    onInvalid,
  });

  const isRequired = required ?? true;

  return (
    <>
      <Select
        {...props}
        key={key}
        value={value}
        onChange={onChange}
        ref={props.selectRef}
        id=""
      />
      <VisuallyHidden>
        <input {...enhancedProps} id={id} value={value} required={isRequired} />
      </VisuallyHidden>
    </>
  );
};

export default SelectSearchable;
