/**
 * @generated SignedSource<<cb090eedc680b632df65d56ebf2829fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DirectionOfTravel = "CROSS" | "IN" | "OFF" | "OUT";
import { FragmentRefs } from "relay-runtime";
export type RecorderCameraDetectionElements_varHubCamera$data = {
  readonly activeDetectionLineIndex: number | null;
  readonly activeDetectionRegionIndex: number | null;
  readonly detectionLines: ReadonlyArray<{
    readonly activeCoordinateIndexes: ReadonlyArray<number> | null;
    readonly focus: boolean | null;
    readonly geometry: {
      readonly coordinates: ReadonlyArray<ReadonlyArray<number>>;
    };
    readonly id: string;
    readonly index: number | null;
    readonly movementDirection: DirectionOfTravel | null;
    readonly slotNumber: number | null;
  }>;
  readonly detectionRegions: ReadonlyArray<{
    readonly activeCoordinateIndexes: ReadonlyArray<number> | null;
    readonly focus: boolean | null;
    readonly geometry: {
      readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
    };
    readonly id: string;
    readonly index: number | null;
    readonly movementDirection: DirectionOfTravel | null;
    readonly slotNumber: number | null;
  }>;
  readonly id: string;
  readonly maxRegionX: number;
  readonly maxRegionY: number;
  readonly minRegionX: number;
  readonly minRegionY: number;
  readonly mouseDown: boolean | null;
  readonly " $fragmentType": "RecorderCameraDetectionElements_varHubCamera";
};
export type RecorderCameraDetectionElements_varHubCamera$key = {
  readonly " $data"?: RecorderCameraDetectionElements_varHubCamera$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionElements_varHubCamera">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotNumber",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "movementDirection",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "coordinates",
    "storageKey": null
  }
],
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "index",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeCoordinateIndexes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "focus",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecorderCameraDetectionElements_varHubCamera",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "VarHubCameraDetectionLine",
      "kind": "LinkedField",
      "name": "detectionLines",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "VarHubCameraLineGeometry",
          "kind": "LinkedField",
          "name": "geometry",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VarHubCameraDetectionRegion",
      "kind": "LinkedField",
      "name": "detectionRegions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "VarHubCameraRegionGeometry",
          "kind": "LinkedField",
          "name": "geometry",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minRegionX",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxRegionX",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minRegionY",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxRegionY",
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activeDetectionLineIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activeDetectionRegionIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mouseDown",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "VarConnectedCamera",
  "abstractKey": null
};
})();

(node as any).hash = "dca71eccd8d6bfd3d6733914c18a68c4";

export default node;
