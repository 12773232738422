/**
 *Directives for displaying icons. 
 Uses svgs instead of an icon font
 */
App.directive("daIcon", () => {
  return {
    restrict: "E",
    replace: true,
    scope: {
      icon: "@", //id of icon to display
      iconColor: "@?", //fill color or state
      class: "@?", //class to add to svg
    },
    templateUrl: "/app/common/templates/menu-icon-tpl.html",
    link: (scope) => {
      scope.svgLink = `assets/img/sprite.svg#${scope.icon}`;
      const colors = {
        primary: "	hsl(198, 47%, 38%)",
        info: "	hsl(198, 47%, 38%)",
        warning: "hsl(20, 100%, 60%)",
        success: "hsl(167, 98%, 32%)",
        failure: "hsl(350, 87%, 62%)",
        menu: "hsl(210, 1%, 32%)",
        neutral: "black",
        input_icon: "hsl(201, 12%, 61%)",
        vk: "hsl(219.4, 75%, 74.9%)",
      };

      if (scope.iconColor)
        switch (scope.iconColor) {
          case "primary":
            scope.styleString = `fill: ${colors.primary}`;
            break;

          case "info":
            scope.styleString = `fill: ${colors.info}`;
            break;
          case "success":
            scope.styleString = `fill: ${colors.primary}`;
            break;

          case "warning":
            scope.styleString = `fill: ${colors.warning}`;
            break;

          case "failure":
            scope.styleString = `fill: ${colors.failure}`;
            break;
          case "menu":
            scope.styleString = `fill: ${colors.menu}`;
            break;
          case "neutral":
            scope.styleString = `fill: ${colors.neutral}`;

            break;
          case "input-icon":
            scope.styleString = `fill: ${colors.input_icon}`;
            break;
          case "vk":
            scope.styleString = `fill: ${colors.vk}`;
            break;
          default:
            scope.styleString = `fill: ${scope.iconColor}`;
        }
    },
  };
});

App.directive("iconButton", () => {
  return {
    restrict: "E",
    require: ["^daIcon", "ngCick" | "uiSref"],
    scope: {
      icon: "@", //id of svg to display
      iconColor: "@?", //fill color or state
      class: "@?",
    },
    templateUrl: "app/common/templates/icon-button-tpl.html",
  };
});

App.directive("iconButtonBorderless", () => {
  return {
    restrict: "E",
    replace: true,
    require: ["daIcon", "ngCick" | "uiSref"],
    scope: {
      icon: "@", //id of icon to display
      iconColor: "@?", //fill color or state
      class: "@?",
    },
    templateUrl: "app/common/templates/icon-button-borderless.html",
  };
});
