/**
 * @generated SignedSource<<d04bc5b390fad6e204be082a92b99d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiagnosticSearchQuery$variables = {
  dealerId: string;
  queryString: string;
  useWildCard: string;
};
export type DiagnosticSearchQuery$data = {
  readonly dealer: {
    readonly " $fragmentSpreads": FragmentRefs<"DiagnosticSearch_dealer">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DiagnosticSearch_query">;
};
export type DiagnosticSearchQuery = {
  response: DiagnosticSearchQuery$data;
  variables: DiagnosticSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dealerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryString"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useWildCard"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dealerId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DiagnosticSearchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DiagnosticSearch_query"
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DiagnosticSearch_dealer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DiagnosticSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "queryString",
            "variableName": "queryString"
          },
          {
            "kind": "Variable",
            "name": "useWildCard",
            "variableName": "useWildCard"
          }
        ],
        "concreteType": "SystemDiagnosticsPanel",
        "kind": "LinkedField",
        "name": "systemDiagnosticsPanelSearch",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelSerialNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "controlSystemName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "controlSystemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "simIdentifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelCommAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hardwareModel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelSoftwareVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelSoftwareDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelArmingSystem",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vernacular",
            "kind": "LinkedField",
            "name": "vernaculars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "original",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "replacement",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "43d6b9d12b9408753c9c527cacecb5d6",
    "id": null,
    "metadata": {},
    "name": "DiagnosticSearchQuery",
    "operationKind": "query",
    "text": "query DiagnosticSearchQuery(\n  $queryString: String!\n  $useWildCard: String!\n  $dealerId: ID!\n) {\n  ...DiagnosticSearch_query\n  dealer(id: $dealerId) {\n    ...DiagnosticSearch_dealer\n    id\n  }\n}\n\nfragment DiagnosticSearch_dealer on Dealer {\n  vernaculars {\n    original\n    replacement\n    id\n  }\n}\n\nfragment DiagnosticSearch_query on Query {\n  systemDiagnosticsPanelSearch(queryString: $queryString, useWildCard: $useWildCard) {\n    panelSerialNumber\n    controlSystemName\n    accountNumber\n    controlSystemId\n    customerId\n    simIdentifier\n    panelCommAddress\n    hardwareModel\n    panelSoftwareVersion\n    panelSoftwareDate\n    panelArmingSystem\n    commType\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c8cc21b2b4328cbe547e4c1627bc1f2";

export default node;
