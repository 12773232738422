/**
 * @generated SignedSource<<ff7acd633990ebb4591dbe4753be7825>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WirelessEncryption1100 = "ALL" | "BOTH" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XT75SystemOptionsProgrammingConceptForm_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly softwareVersion: string;
    readonly systemOptions: {
      readonly houseCode?: string;
      readonly wirelessEncryption1100?: WirelessEncryption1100;
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsBypassLimitField_systemOptions" | "SystemOptionsCelsiusField_systemOptions" | "SystemOptionsClosingWaitField_systemOptions" | "SystemOptionsContext_systemOptions" | "SystemOptionsCrossZoneTimeField_systemOptions" | "SystemOptionsDetectWirelessJammingField_systemOptions" | "SystemOptionsEnableKeypadPanicKeysField_systemOptions" | "SystemOptionsEntryDelay1Field_systemOptions" | "SystemOptionsEntryDelay2Field_systemOptions" | "SystemOptionsHoursFromGMTField_systemOptions" | "SystemOptionsInstantArmingField_systemOptions" | "SystemOptionsKeypadArmedLedField_systemOptions" | "SystemOptionsLatchedSupervisoryZonesField_systemOptions" | "SystemOptionsObserveDstField_systemOptions" | "SystemOptionsOccupiedPremisesField_systemOptions" | "SystemOptionsPowerFailDelayField_systemOptions" | "SystemOptionsResetSwingerBypassField_systemOptions" | "SystemOptionsSwingerBypassTripsField_systemOptions" | "SystemOptionsSystemTypeField_systemOptions" | "SystemOptionsTimeChangeField_systemOptions" | "SystemOptionsUseBuiltIn1100WirelessField_systemOptions" | "SystemOptionsUseFalseAlarmQuestionField_systemOptions" | "SystemOptionsWeatherZipCodeField_systemOptions" | "SystemOptionsWirelessAudiblesField_systemOptions" | "SystemOptionsWirelessEncryption1100Field_systemOptions" | "SystemOptionsWirelessEncryption1100PassphraseField_systemOptions" | "SystemOptionsWirelessHouseCodeField_systemOptions" | "SystemOptionsZoneActivityHoursField_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel" | "SystemOptionsSystemTypeField_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem" | "ControlSystemContext_controlSystem">;
  readonly " $fragmentType": "XT75SystemOptionsProgrammingConceptForm_controlSystem";
};
export type XT75SystemOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XT75SystemOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75SystemOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75SystemOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SystemOptionsSystemTypeField_panel"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "houseCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "wirelessEncryption1100",
                  "storageKey": null
                }
              ],
              "type": "Xt75SystemOptions",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContext_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsSystemTypeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsInstantArmingField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsClosingWaitField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsResetSwingerBypassField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsEntryDelay1Field_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsEntryDelay2Field_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsCrossZoneTimeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsPowerFailDelayField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsSwingerBypassTripsField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsZoneActivityHoursField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsTimeChangeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsObserveDstField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsHoursFromGMTField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsLatchedSupervisoryZonesField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsBypassLimitField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWirelessHouseCodeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsDetectWirelessJammingField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWirelessAudiblesField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsOccupiedPremisesField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsUseBuiltIn1100WirelessField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsEnableKeypadPanicKeysField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsCelsiusField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsKeypadArmedLedField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsUseFalseAlarmQuestionField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWeatherZipCodeField_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWirelessEncryption1100Field_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsWirelessEncryption1100PassphraseField_systemOptions"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "f1cc3baec128cd8e38b83efed7802a7e";

export default node;
