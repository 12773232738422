App.controller("AutomationCtrl", [
  "$rootScope",
  "$scope",
  "FavoritesService",
  "ZWaveService",
  "UserService",
  function ($rootScope, $scope, FavoritesService, ZWaveService, UserService) {
    $scope.UserService = UserService;
    $scope.deviceList = {
      S: {
        name: "Switch (light)",
        B: "Binary",
        M: "Multilevel",
        N: "Binary metered (smart)",
        L: "Multilevel metered (smart)",
      },
      L: {
        name: "Lock",
        S: "Standard",
      },
      T: {
        name: "Thermostat",
        S: "Standard (no auto mode)",
        A: "Supports auto mode",
      },
      U: {
        name: "Undefined",
      },
      B: {
        name: "Barrier Operator",
      },
      C: {
        name: "Controller",
      },
      A: {
        name: "Appliance",
      },
    };

    $scope.isBusy = false;
    $scope.deviceList = {
      S: {
        name: "Switch (light)",
        B: "Binary",
        M: "Multilevel",
        N: "Binary metered (smart)",
        L: "Multilevel metered (smart)",
      },
      L: {
        name: "Lock",
        S: "Standard",
      },
      T: {
        name: "Thermostat",
        S: "Standard (no auto mode)",
        A: "Supports auto mode",
      },
      U: {
        name: "Undefined",
      },
      B: {
        name: "Barrier Operator",
      },
      C: {
        name: "Controller",
      },
    };
    $scope.validVernaculars = UserService.dealerInfo.vernaculars;

    $scope.getFavorites = function () {
      FavoritesService.getFavorites(UserService.panel_id)
        .then(
          function (data) {
            $scope.favorites = data;
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.getZwaveDevices = function () {
      ZWaveService.getDevices(UserService.panel_id)
        .then(
          function (data) {
            $scope.zWaveDevices = data;
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.refreshAll = function () {
      $scope.isBusy = true;

      var devices = ZWaveService.refreshDevices(UserService.panel_id);
      var favorites = FavoritesService.refresh(UserService.panel_id);

      Promise.all([devices, favorites])
        .then(function (values) {
          $scope.getZwaveDevices();
          $scope.getFavorites();
          $rootScope.alerts.push({
            type: "success",
            text: "successfully retrieved automation",
          });
        })
        .catch(function (error) {
          $scope.isBusy = false;
          $rootScope.alerts.push({ type: "error", json: error });
          console.error(error);
        })
        .finally(function () {
          $scope.isBusy = false;
        });
    };

    $scope.getAllData = function () {
      $scope.getFavorites();
      $scope.getZwaveDevices();
    };
    $scope.getAllData();
  },
]);
