/**
 * @name App.service:PanelDefinitionService
 *
 * @description
 * Manage a panel definition object which has item valid values, default values and value format for panel
 * concept and configuration information for a given panel hardware model and firmware version.
 */

import { hyphenCaseToSnakeCase } from "common/utils";

App.service("PanelDefinitionService", [
  "PROPS",
  "$q",
  "PanelDefsAPI",
  function (PROPS, $q, PanelDefsAPI) {
    // Public accessors
    this.generate = generate;
    this.generateHighestVersion = generateHighestVersion;
    this.getVersions = getVersions;

    // Local variables and functions
    let options = {
      initialized: false,
      promise: null,
      data: null,
    };

    // TODO: Figure out what needs to be done here for the CellComEX
    /**
     * Retrieve and set the compiled panel definition for the given panel model and firmware version
     * @param {String} [panelModel=CellComSL] the panel hardware model
     * @param {int} [firmwareVersion=119]
     */
    function generate(panelModel, firmwareVersion) {
      panelModel = panelModel || "CellComSL";
      firmwareVersion = parseInt(firmwareVersion || 119, 10);
      let deferred = $q.defer();
      PanelDefsAPI.get(
        {
          hardware_model: hyphenCaseToSnakeCase(panelModel),
          firmware_version: firmwareVersion,
        },
        function (data) {
          let PDS = prepPanelDefObject(data);
          deferred.resolve(PDS);
        },
        function (error) {
          console.error("error getting PanelDef: " + angular.toJson(error));
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function generateHighestVersion(panelModel, international) {
      let deferred = $q.defer();
      PanelDefsAPI.highestVersion(
        { hardware_model: panelModel, international: international },
        function (data) {
          let PDS = prepPanelDefObject(data);
          deferred.resolve(PDS);
        },
        function (error) {
          console.error("error getting PanelDef: " + angular.toJson(error));
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getVersions(panelModel, international) {
      panelModel = panelModel.toUpperCase();
      international = international || false;
      let deferred = $q.defer();
      ensureOptionsInitialized().then(
        function () {
          let modelOptions = null;
          let versionRanges = null;
          let panelDefFileNames = Object.keys(options.data);
          for (let i = 0; i < panelDefFileNames.length; i++) {
            let fileOptions = options.data[panelDefFileNames[i]];
            if (fileOptions !== null) {
              let models = Object.keys(fileOptions.MODELS);
              for (let j = 0; j < models.length; j++) {
                if (models[j] === panelModel) {
                  modelOptions = fileOptions.MODELS[panelModel];
                  versionRanges =
                    fileOptions.VERSION_RANGES[
                      international ? "INTERNATIONAL" : "DOMESTIC"
                    ];
                  break;
                }
              }
            }
          }
          let versions = [];
          let upperVersionLimit = parseInt(versionRanges.split("-")[1], 10);
          for (let i = 0; i < modelOptions.VERSIONS.length; i++) {
            let ver = parseInt(modelOptions.VERSIONS[i], 10);
            if (ver > upperVersionLimit) {
              break;
            }
            versions.push(ver);
          }
          deferred.resolve(versions);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function prepPanelDefObject(data) {
      let PDS = data;
      delete PDS.$promise;
      delete PDS.$resolved;
      PDS.getConcepts = getConcepts;
      PDS.getConceptsWithDisplay = getConceptsWithDisplay;
      PDS.getConceptFieldMeta = getConceptFieldMeta;
      PDS.getConfigItemValues = getConfigItemValues;
      return PDS;
    }

    function ensureOptionsInitialized() {
      let deferred = $q.defer();
      if (!options.initialized) {
        if (options.promise === null) {
          options.promise = initOptions();
        }
        return options.promise;
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }

    function initOptions() {
      let deferred = $q.defer();
      PanelDefsAPI.options(
        {},
        function (data) {
          delete data.$promise;
          delete data.$resolved;
          options.data = data;
          options.initialized = true;
          deferred.resolve();
        },
        function (error) {
          console.error(
            `error retrieving panel definition information: ${angular.toJson(
              error
            )}`
          );
          deferred.reject();
        }
      );
      return deferred.promise;
    }

    /**
     * Get the concepts that the requested panel supports from the JSON data and return them
     * @param panel the data for the panel from the ControlSystem.
     * @returns {*[]} an Array of concept names
     */
    function getConcepts() {
      var _this = this;
      var concepts = [];
      var conceptData = _this.getConceptsWithDisplay();
      for (let i = 0; i < conceptData.length; i++) {
        concepts.push(conceptData[i].key);
      }
      if (_this.hardwareProperties.Family === "XR550")
        concepts.push("feature_keys");
      return concepts;
    }

    /**
     * Get the concepts that should be displayed on the FULL programming page.  Some concepts may appear in the panel_def file
     * for use in programming, such as schedules, user_codes, but aren't used on the FULL programming page.
     *
     * @returns {Array}
     */
    function getConceptsWithDisplay() {
      var _this = this;
      var concepts = [];
      angular.forEach(_this.panel_def.CONCEPTS, function (value, key) {
        // Don't include messaging_setup in the concepts for 101, since it was removed for later versions and causes issues.
        if (
          key !== "messaging_setup" ||
          (key === "messaging_setup" && _this.panel_version !== "101")
        ) {
          var theConcept = _this.panel_def.CONCEPTS[key].DISPLAY;
          theConcept.key = key;
          concepts.push(theConcept);
        }
      });
      return concepts;
    }

    /**
     *
     * @param concept the programming concept
     * @param fieldName the name of the field in the data model
     * @returns {object} An field object containing display and valid values information
     */
    function getConceptFieldMeta(concept, fieldName) {
      var _this = this;
      if (typeof concept == "string") concept = concept.toLowerCase(); // concept names are lower case in the JSON data by default.
      if (typeof fieldName == "string") fieldName = fieldName.toLowerCase(); // field names are upper case in the JSON data by default.

      if (
        _this.panel_def.CONCEPTS[concept] !== undefined &&
        _this.panel_def.CONCEPTS[concept].hasOwnProperty(fieldName)
      ) {
        return _this.panel_def.CONCEPTS[concept][fieldName];
      } else {
        // How do we want to handle these types of errors?
        if (PROPS.debug == 1) {
          //$rootScope.alerts.push({"type": PROPS.alertError, "text": "Metadata for " + concept + " " + fieldName +" not found."});
        }
        return;
      }
    }

    /**
     *
     * @param panel the data for the panel from the ControlSystem.  This must include panel_family, panel_model, panel_version
     * @param type : the specific Configuration item for which valid values are being requested
     * @returns {String} Probably an Array, containing the valid values for the specified configuration item
     */
    function getConfigItemValues(item) {
      var _this = this;
      item = item.toUpperCase(); // field names are upper case so change item name to upper case by default
      if (
        _this.panel_def.CONFIG !== undefined &&
        _this.panel_def.CONFIG.hasOwnProperty(item)
      ) {
        return _this.panel_def.CONFIG[item];
      } else {
        // How do we want to handle these types of errors?
        if (PROPS.debug == 1) {
          //$rootScope.alerts.push({"type": PROPS.alertError, "text": "Panel Config value for " + item + " not found."});
        }
        return;
      }
    }
  },
]);
