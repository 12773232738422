import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreIpAddressField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreIpAddressField_remoteOptions.graphql";

export const remoteOptionsEntreIpAddressFieldId = () =>
  "remote-options-entre-ip-address";

function RemoteOptionsEntreIpAddressField() {
  const [{ entreIpv6, entreIpAddress, entreConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsEntreIpAddressField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsEntreIpAddressField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            entreIpAddress
            entreConnection
            entreIpv6
          }
          ... on XfRemoteOptions {
            entreIpAddress
            entreConnection
          }
          ... on TMSentryRemoteOptions {
            entreIpAddress
            entreConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsEntreIpAddressFieldId();
  const disabled =
    entreConnection === RemoteOptionsConnectionType.NONE || !!entreIpv6;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entré IP Address"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={entreIpAddress || ""}
        disabled={disabled}
        maxLength={16}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        validationMessage="Please enter a valid IP address"
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "entreIpAddress")
          );
        }}
        onBlur={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "entreIpAddress"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreIpAddressField;
