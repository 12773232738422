import graphql from "babel-plugin-relay/macro";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useKeyfobFragment } from "./KeyfobContext";
import { KeyfobNameField_keyfob$key } from "./__generated__/KeyfobNameField_keyfob.graphql";

export const KEY_FOB_NAME = "keyfob-name-";
export const keyfobNameFieldId = (number: string) => KEY_FOB_NAME + number;

function KeyfobNameField() {
  const [{ keyfobName, id, number }, updateKeyfob] =
    useKeyfobFragment<KeyfobNameField_keyfob$key>(
      graphql`
        fragment KeyfobNameField_keyfob on Keyfob {
          id
          keyfobName
          number
        }
      `
    );

  const fieldId = keyfobNameFieldId(String(number));

  const { isXr } = resolvePanelType(useHardwareModel());
  const softwareVersion = useSoftwareVersion();
  const is243 = isXr && softwareVersion >= 243;
  const disabled = !is243 || (is243 && number < 500);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Name"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={keyfobName ?? ""}
        required={is243}
        maxLength={32}
        disabled={disabled}
        onChange={({ target }) => {
          updateKeyfob((recordProxy) => {
            recordProxy.setValue(target.value, "keyfobName");
          });
        }}
        onBlur={({ target }) => {
          updateKeyfob((recordProxy) => {
            recordProxy.setValue(
              target.value.trim().toUpperCase(),
              "keyfobName"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobNameField;
