import {
  getSecureComUrls,
  SecureComUrlCreatorInput,
} from "common/utils/universal/securecom-urls";

export const getDAUrls = (input: SecureComUrlCreatorInput) => {
  const {
    billing,
    companyStore,
    coverageMap,
    dealer,
    dealerAdmin,
    dealerInvoice,
    features,
    hls,
    images,
    integration,
    ipResolver,
    jobs,
    jobStatus,
    odata,
    odataReports,
    odataHistory,
    odataConnectionHistory,
    orbiPayHostedForms,
    payments,
    paymentService,
    pulse,
    sims,
    scheduledServiceUrl,
    thirdPartyVideo,
    virtualKeypad,
    vk,
    camect,
    vidprox,
    ...rest
  } = getSecureComUrls(input);

  return {
    ...rest,
    apiUrl: vk,
    billingUrl: billing,
    camectUrl: camect,
    companyStoreApiUrl: companyStore,
    coverageMapApiUrl: coverageMap,
    dealerAdminUrl: dealerAdmin,
    dealerApiUrl: dealer,
    dealerInvUrl: dealerInvoice,
    featuresUrl: features,
    hlsUrl: hls,
    imageApiUrl: images,
    integrationUrl: integration,
    ipResolverUrl: ipResolver,
    jobsApiUrl: jobs,
    jobStatusUrl: jobStatus,
    oDataConnectionHistoriesUrl: odataConnectionHistory,
    oDataEventHistoriesUrl: odataHistory,
    oDataEventReportsUrl: odataReports,
    orbiPayHostedForms,
    paymentServiceApiUrl: paymentService,
    paymentsUrl: payments,
    pulseApiUrl: pulse,
    reportsApiUrl: odata,
    scheduledServiceUrl,
    simsUrl: sims,
    thirdPartyVideo,
    vidprox,
    virtualKeypadUrl: virtualKeypad,
    vkLoginUrl: vk,
  };
};
