import noop, { asyncNoop } from "common/utils/universal/noop";
import { EventEmitter } from "events";
import React, { createContext, useContext } from "react";
import {
  asID,
  asString,
  fromSiteId,
  toCustomerId,
} from "securecom-graphql/client";
import {
  AngularCountryCodes,
  AngularStateCodes,
  GenericControlSystemState,
  Notification,
  RecentActivityServiceType,
  UserServiceType,
} from "./types";

type EntryPointContextValue = {
  UserService: UserServiceType;
  countryCodes: AngularCountryCodes;
  createNotification: (notification: Notification) => void;
  customerId: string;
  redirectTo404: () => void;
  redirectToSite: (siteId: string) => void;
  genericControlSystemStateFromAngular: GenericControlSystemState;
  usStateCodes: AngularStateCodes;
  syncAngularState: (state: GenericControlSystemState) => void;
  openSecurecomNvrModal: (controlSystemId: string) => void;
  openSecurecomCameraModal: (controlSystemId: string) => void;
  openHikvisionNvrModal: (controlSystemId: string) => void;
  openDWSpectrumModal: (controlSystemId: string) => void;
  openLoginAsCustomerModal: (controlSystemId: string, isUserManagement: boolean) => void;
  eventEmitter: EventEmitter;
  reactivateVideoDevice: (serialNumber: string) => Promise<void>;
  getAngularRouteLink: (route: string, params: any) => string;
  testVideoDeviceConnection: (deviceId: string) => Promise<string>;
  levelUpDealers: number[];
  RecentActivityService: RecentActivityServiceType;
  setUserCanSaveBillingTags?: (value: boolean) => void;
  userCanSaveBillingTags?: boolean;
};

const EntryPointContext = createContext<EntryPointContextValue>({
  setUserCanSaveBillingTags: () => {},
  userCanSaveBillingTags: false,
  countryCodes: [],
  levelUpDealers: [],
  createNotification: noop,
  customerId: "",
  redirectTo404: noop,
  redirectToSite: noop,
  usStateCodes: {},
  genericControlSystemStateFromAngular: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    name: "",
    nickname: null,
    type: "",
    useBillingAddress: true,
  },
  syncAngularState: noop,
  openSecurecomNvrModal: noop,
  openSecurecomCameraModal: noop,
  openHikvisionNvrModal: noop,
  openDWSpectrumModal: noop,
  openLoginAsCustomerModal: noop,
  eventEmitter: new EventEmitter(),
  getAngularRouteLink: () => "",
  reactivateVideoDevice: asyncNoop,
  UserService: {
    customerInfo: {},
    customer_id: "",
    canEditVideoDevice: () => false,
    canEditTags: () => false,
    canSaveBillingTags: () => false,
    canSaveBillingTagsFunctionInUserService: noop,
    tagsAreSavingFunctionInUserService: noop,
    canTestCameras: () => false,
    canUpdateHikDoorbellFirmware: () => false,
    canCreateTempDealerCustomerUser: () => false,
    dealerInfo: {
      id: -1,
      allow_mini_cellcom: false,
      allow_supercell: false,
    },
    isAdmin: () => false,
    isDealerAdmin: () => false,
    isDealerTechnician: () => false,
    tagsEnabled: () => false,
    enabledSecurityCommand: () => false,
    userHasCustomRole: () => false,
  },
  testVideoDeviceConnection: () => Promise.resolve("Offline"),
  RecentActivityService: {
    props: {
      storageKeys: {
        system: "",
      },
    },
    updateList(key, recentSystem) {},
  },
});

export const useEntryPointContext = () => useContext(EntryPointContext);

export const useCountryCodes = () => {
  const { countryCodes } = useEntryPointContext();
  return countryCodes;
};

export const useUSStateCodes = () => {
  const { usStateCodes } = useEntryPointContext();
  return usStateCodes;
};

export const useCreateNotification = () => {
  const { createNotification } = useEntryPointContext();
  return createNotification;
};

export const useCustomerId = () => {
  const { customerId } = useEntryPointContext();
  return [asString(toCustomerId(customerId)), customerId] as [string, string];
};

export const useRedirectTo404 = () => {
  const { redirectTo404 } = useEntryPointContext();
  return redirectTo404;
};

export const useSyncAngularState = () => {
  const { syncAngularState } = useEntryPointContext();
  return syncAngularState;
};

export const useGenericControlSystemStateFromAngular = () => {
  const { genericControlSystemStateFromAngular } = useEntryPointContext();
  return genericControlSystemStateFromAngular;
};

export const useSilentlyRedirectToSite = () => {
  const { redirectToSite } = useEntryPointContext();
  return redirectToSite;
};

export const useOpenSecurecomNvrModal = () => {
  const { openSecurecomNvrModal } = useEntryPointContext();
  return openSecurecomNvrModal;
};

export const useOpenSecurecomCameraModal = () => {
  const { openSecurecomCameraModal } = useEntryPointContext();
  return openSecurecomCameraModal;
};

export const useOpenHIkvisionNvrModal = () => {
  const { openHikvisionNvrModal } = useEntryPointContext();
  return openHikvisionNvrModal;
};

export const useOpenDWSpectrumModal = () => {
  const { openDWSpectrumModal } = useEntryPointContext();
  return openDWSpectrumModal;
};
export const useOpenLoginAsCustomerModal = () => {
  const { openLoginAsCustomerModal } = useEntryPointContext();
  return openLoginAsCustomerModal;
};

export const useEventEmitter = () => {
  const { eventEmitter } = useEntryPointContext();
  return eventEmitter;
};

export const useReactivateVideoDevice = () => {
  const { reactivateVideoDevice } = useEntryPointContext();
  return reactivateVideoDevice;
};

export const useGetRouteLink = () => {
  const { getAngularRouteLink } = useEntryPointContext();
  return getAngularRouteLink;
};

export const useGetCameraUrl = (controlSystemId: string, siteId: string) => {
  const [, customerId] = useCustomerId();
  const getRouteLink = useGetRouteLink();

  return (cameraId: string, manufacturer: string, channelId: number) => {
    const app = ["Uniview", "Malmoset"].includes(manufacturer)
      ? "app.uniview-camera-edit-sites"
      : "app.view-camera-sites";
    return getRouteLink(app, {
      customer_id: customerId,
      control_system_id: controlSystemId,
      device_id: cameraId,
      channel_id: channelId,
      site_id: fromSiteId(asID(siteId)).siteId,
    });
  };
};

export const useViewSecureComNvrUrl = (
  controlSystemId: string,
  siteId: string
) => {
  const [, customerId] = useCustomerId();
  const getRouteLink = useGetRouteLink();

  return ({ deviceId, channelId }: { deviceId: string; channelId: string }) => {
    return getRouteLink("app.edit-nvr-sites", {
      customer_id: customerId,
      control_system_id: controlSystemId,
      device_id: deviceId,
      channel_id: channelId,
      site_id: fromSiteId(asID(siteId)).siteId,
    });
  };
};

export const useEditSecureComNvrUrl = (
  controlSystemId: string,
  siteId: string
) => {
  const [, customerId] = useCustomerId();
  const getRouteLink = useGetRouteLink();

  return (deviceId: string) => {
    return getRouteLink("app.edit-nvr-sites", {
      customer_id: customerId,
      control_system_id: controlSystemId,
      device_id: deviceId,
      site_id: fromSiteId(asID(siteId)).siteId,
    });
  };
};

export const useUserCanEditVideoDevices = () => {
  const { UserService } = useEntryPointContext();
  return UserService.canEditVideoDevice();
};

export const useUserCanUpdateHikvisionDoorbellFirmware = () => {
  const { UserService } = useEntryPointContext();
  return UserService.canUpdateHikDoorbellFirmware();
};

export const useUserCanLogInAsCustomer = () => {
  const { UserService } = useEntryPointContext();
  return UserService.canCreateTempDealerCustomerUser();
};

export const UserService = () => {
  const { UserService } = useEntryPointContext();
  return UserService;
};

export const useRecentActivityService = () => {
  const { RecentActivityService } = useEntryPointContext();
  return RecentActivityService;
};

export const useUserCanTestCameras = () => {
  const { UserService } = useEntryPointContext();
  return UserService.canTestCameras();
};

export const useUserCanSelectMiniCellCom = () => {
  const { UserService } = useEntryPointContext();
  return (
    UserService.dealerInfo.allow_mini_cellcom ||
    UserService.dealerInfo.allow_supercell
  );
};
export const useUserCanSelectIComLnc = () => {
  const { UserService, levelUpDealers } = useEntryPointContext();
  return levelUpDealers.includes(UserService.dealerInfo.id);
};

export const useTestCameraConnection = () => {
  const { testVideoDeviceConnection } = useEntryPointContext();
  return testVideoDeviceConnection;
};

export const useGetDealerId = () => {
  const { UserService } = useEntryPointContext();
  return UserService.dealerInfo.id;
};

export const useUserCanEditTags = () => {
  const { UserService } = useEntryPointContext();
  return UserService.canEditTags();
};

export const useTagsEnabled = () => {
  const { UserService } = useEntryPointContext();
  return UserService.tagsEnabled();
};

export const useEnabledSecurityCommand = () => {
  const { UserService } = useEntryPointContext();
  return UserService.enabledSecurityCommand();
};

export const useHasCustomRole = () => {
  const { UserService } = useEntryPointContext();
  return UserService.userHasCustomRole();
};

export const useCanSaveBillingTag = () => {
  const { UserService } = useEntryPointContext();
  return UserService.canSaveBillingTags();
};

export const useCanSaveBillingTags = () => {
  const { UserService } = useEntryPointContext();
  return UserService.canSaveBillingTagsFunctionInUserService;
};

export const useTagsAreSaving = () => {
  const { UserService } = useEntryPointContext();
  return UserService.tagsAreSavingFunctionInUserService;
};

export default function EntryPointContextProvider({
  value,
  children,
}: {
  value: EntryPointContextValue;
  children: React.ReactNode;
}) {
  const [userCanSaveBillingTags, setUserCanSaveBillingTags] = React.useState(
    value.UserService.canSaveBillingTags()
  );
  return (
    <EntryPointContext.Provider
      value={{
        ...value,
        userCanSaveBillingTags,
        setUserCanSaveBillingTags,
      }}
    >
      {children}
    </EntryPointContext.Provider>
  );
}
