/**
 * @generated SignedSource<<c1d820caee414b4265fa1771062e3910>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsEntreBackupIpAddressField_remoteOptions$data = {
  readonly entreBackupConnection?: RemoteOptionsConnectionType | null;
  readonly entreBackupIpAddress?: string | null;
  readonly entreConnection?: RemoteOptionsConnectionType | null;
  readonly entreIpv6?: boolean | null;
  readonly " $fragmentType": "RemoteOptionsEntreBackupIpAddressField_remoteOptions";
};
export type RemoteOptionsEntreBackupIpAddressField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsEntreBackupIpAddressField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsEntreBackupIpAddressField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entreBackupIpAddress",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entreConnection",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entreBackupConnection",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsEntreBackupIpAddressField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreIpv6",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v3/*: any*/),
      "type": "XfRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v3/*: any*/),
      "type": "TMSentryRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "b2b47bc908faed226dad215dd1e8c8c7";

export default node;
