/**
 * @ngdoc service
 * @name App.factory:VernacularsAPI
 * @path projects/dealer-admin/src/app/common/api/vernaculars-api.js
 * @description
 * API Interface service for Vernaculars.
 * @requires $resource
 * @requires PROPS
 * */

App.factory("VernacularsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.featuresUrl + "/api/v1/vernacular",
      {},
      {
        getVernacular: {
          method: "GET",
          url: PROPS.featuresUrl + "/api/v1/vernacular/:vernacularId",
          params: {
            vernacularId: "@vernacularId",
          },
        },
        getVernaculars: {
          method: "GET",
          url: PROPS.featuresUrl + "/api/v1/vernacular/dealer/:dealerId",
          params: {
            dealerId: "@dealerId",
          },
          isArray: true,
        },
        /**
         * @ngdoc method
         * @name VernacularsAPI#getAllowedVernaculars
         * @methodOf App.factory:VernacularsAPI
         *
         * @description
         * Sends a GET request to fetch possible terms to be changed. Currently there are only 6 allowed:
         * account, customers, systems, app users, personnel, custom roles
         */
        getAllowedVernaculars: {
          method: "GET",
          url: PROPS.featuresUrl + "/api/v1/vernacular/allowed",
          isArray: true,
        },
        /**
         * @ngdoc method
         * @name VernacularsAPI#createVernacular
         * @methodOf App.factory:VernacularsAPI
         *
         * @description
         * Sends a POST request to create a new vernacular record for a dealer.
         *
         * @param {Object} body - The body of the POST request.
         * The following fields are required:
         *   - `dealerId` (number): The ID of the dealer for whom the vernacular is being set.
         *   - `original` (string): The original term, such as 'account'.
         *   - `replacement` (string): The replacement term to be displayed, such as 'user account'.
         *
         * @example
         * VernacularsAPI.createVernacular({}, {
         *   original: 'account',
         *   replacement: 'user account',
         *   dealerId: 1234
         * }, function(success) {
         *   //success logic goes here
         * }, function(error) {
         *   //error logic goes here
         * });
         */
        createVernacular: {
          method: "POST",
          params: {},
        },
        /**
         * @ngdoc method
         * @name VernacularsAPI#updateVernacular
         * @methodOf App.factory:VernacularsAPI
         *
         * @description
         * Sends a PATCH request to update an existing vernacular record for a dealer.
         *
         * @param {Object} body - The body of the PATCH request.
         * The following fields are required:
         *   - `id` (number): The ID of the vernacular record being updated.
         *   - `dealerId` (number): The ID of the dealer for whom the vernacular is being updated.
         *   - `original` (string): The original term, such as 'account'.
         *   - `replacement` (string): The replacement term to be displayed, such as 'user account'.
         *
         * @example
         * VernacularsAPI.updateVernacular({}, {
         *   id: 123,
         *   original: 'account',
         *   replacement: 'user account',
         *   dealerId: 1234
         * }, function(success) {
         *   //success logic goes here
         * }, function(error) {
         *   //error logic goes here
         * });
         */
        updateVernacular: {
          method: "PATCH",
          params: {},
        },
        /**
         * @ngdoc method
         * @name VernacularsAPI#deleteVernacular
         * @methodOf App.factory:VernacularsAPI
         *
         * @description
         * Sends a DELETE request to delete an existing vernacular record for a dealer.
         *
         * @param {Object} body - The body of the PATCH request.
         * The following fields are required:
         *   - `id` (number): The ID of the vernacular record being deleted.
         *   - `dealerId` (number): The ID of the dealer for whom the vernacular is being deleted.
         *   - `original` (string): The original term, such as 'account'.
         *   - `replacement` (string): The replacement term to be displayed, such as 'user account'.
         *
         * @example
         * VernacularsAPI.deleteVernacular({}, {
         *   id: 123,
         *   original: 'account',
         *   replacement: 'user account',
         *   dealerId: 1234
         * }, function(success) {
         *   //success logic goes here
         * }, function(error) {
         *   //error logic goes here
         * });
         */
        deleteVernacular: {
          method: "DELETE",
          params: {},
        },
      }
    );
  },
]);
