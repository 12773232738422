import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { SystemOptionsContextProvider } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import {
  applyCopiedZoneInformationProgrammingToZoneInformation,
  applyDupedZoneInformationProgrammingToZoneInformation,
  getWirelessOnlyZoneNumberRange,
  getWirelessZoneNumberRange,
} from "components/FullProgramming/common/ZoneInformationFields/utils";
import {
  zoneListItemTemplateId,
  ZONE_IDS,
} from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationNumberField";
import { PanelHardwareModel } from "components/FullProgramming/common/__generated__/PanelContextUseHardwareModel_panel.graphql";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { omit } from "ramda";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  Area,
  asID,
  ControlSystem,
  fromAreaId,
  fromControlSystemId,
  fromZoneId,
  idAsString,
  Panel,
  PanelHardwareModel as PanelHardwareModelEnum,
  toAreaId,
  toGlobalId,
  toZoneId,
  Zone,
  ZoneEntryDelayNumber,
  ZoneMessage,
  ZoneOutputAction,
  ZoneSupervisionTime,
} from "securecom-graphql/client";
import XTZoneInformationsFields from "./XTZoneInformationsFields";
import {
  XTZoneInformationsProgrammingConceptFormInline_controlSystem$data,
  XTZoneInformationsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XTZoneInformationsProgrammingConceptFormInline_controlSystem.graphql";
import { XTZoneInformationsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTZoneInformationsProgrammingConceptFormInline_xtProgrammingTemplateConcepts.graphql";
import {
  XTZoneInformationsProgrammingConceptFormInline_zone$data,
  XTZoneInformationsProgrammingConceptFormInline_zone$key,
} from "./__generated__/XTZoneInformationsProgrammingConceptFormInline_zone.graphql";
import { XTZoneInformationsProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XTZoneInformationsProgrammingConceptFormNavButton_controlSystem.graphql";
import { XTZoneInformationsProgrammingConceptFormZoneInformationDeleteMutation } from "./__generated__/XTZoneInformationsProgrammingConceptFormZoneInformationDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XTZoneInformationsProgrammingConceptFormZoneInformationRefreshMutation,
} from "./__generated__/XTZoneInformationsProgrammingConceptFormZoneInformationRefreshMutation.graphql";
import {
  XTZoneInformationsProgrammingConceptFormZoneInformationSendMutation,
  XTZoneInformationsProgrammingConceptFormZoneInformationSendMutation$data,
} from "./__generated__/XTZoneInformationsProgrammingConceptFormZoneInformationSendMutation.graphql";
import { XTZoneInformationsProgrammingConceptForm_controlSystem$key } from "./__generated__/XTZoneInformationsProgrammingConceptForm_controlSystem.graphql";

export const title = "Zone Information";
export const conceptId = "xt-zone-information";

export const getState = (
  controlSystem: XTZoneInformationsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XTZoneInformationsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          id
          zoneNumberRange
          totalWirelessZoneMax
          totalZonesMax
          zoneInformations {
            id
            number
            isNew
            ...XTZoneInformationsProgrammingConceptFormInline_zone
          }
        }
      }
    `,
    controlSystem
  );

export const getZoneState = (
  zone: XTZoneInformationsProgrammingConceptFormInline_zone$key
) =>
  readInlineData(
    graphql`
      fragment XTZoneInformationsProgrammingConceptFormInline_zone on Zone
      @inline {
        __typename
        id
        name
        location
        number
        type
        area {
          __typename
          id
          number
        }
        followArea {
          __typename
          number
        }
        reportWithAccountNumberForArea {
          __typename
          number
        }
        chimeSound
        contactNumber
        crossZoneEnabled
        entryDelayNumber
        prewarnKeypads
        priorityZone
        is24HrZone
        serialNumber
        supervisionTime
        swingerBypassEnabled
        trafficCountEnabled
        wireless
        wirelessDisarmDisableEnabled
        wirelessLedEnabled
        wirelessPetImmunity
        sensorType
        wirelessPirPulseCount
        wirelessPirSensitivity
        zoneAuditDays
        wirelessContactNormallyOpen
        armedAreasForArmingZone
        disarmedOpenActionMessage
        disarmedOpenOutputNumber
        disarmedOpenOutputAction
        disarmedShortActionMessage
        disarmedShortOutputNumber
        disarmedShortOutputAction
        armedOpenActionMessage
        armedOpenOutputNumber
        armedOpenOutputAction
        armedShortActionMessage
        armedShortOutputNumber
        armedShortOutputAction
        armingStyle
        retardDelayEnabled
        fastResponseEnabled
        firePanelSlaveInput
        realTimeStatusEnabled
        lockdownEnabled
        normallyClosed
        competitorWireless
        receiverRouting
        expanderSerialNumber
        isNew
        tamperEnabled
      }
    `,
    zone
  );

const deleteMutation = graphql`
  mutation XTZoneInformationsProgrammingConceptFormZoneInformationDeleteMutation(
    $id: ID!
  ) {
    deleteZoneInformation(id: $id) {
      ... on DeleteZoneInformationSuccessPayload {
        __typename
        deletedZoneId
      }
      ... on FailedToRemoveZoneErrorPayload {
        error: type
      }
    }
  }
`;

const retrieveMutation = graphql`
  mutation XTZoneInformationsProgrammingConceptFormZoneInformationRefreshMutation(
    $id: ID!
  ) {
    refreshZoneInformation(id: $id) {
      ... on RefreshZoneInformationSuccessPayload {
        __typename
        controlSystem {
          ...XTZoneInformationsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XTZoneInformationsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XTZoneInformationsProgrammingConceptFormZoneInformationRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id: systemId } = getState(props.controlSystem);
        retrieve({
          variables: {
            id: systemId,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshZoneInformation;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Zone Information Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id: systemId,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshZoneInformation: {
                    __typename: response.refreshZoneInformation.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Zone Information: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Zone Information",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XTZoneInformationsProgrammingConceptFormZoneInformationSendMutation(
    $systemId: ID!
    $zoneInformations: [ZoneProgrammingInput!]!
  ) {
    sendZoneProgramming(systemId: $systemId, zones: $zoneInformations) {
      ... on SendZoneProgrammingSuccessPayload {
        results {
          ... on SendZoneProgrammingZoneSuccessPayload {
            __typename
            zone {
              __typename
              id
              ...XTZoneInformationsProgrammingConceptFormInline_zone
            }
          }
          ... on SendListItemsErrorPayload {
            __typename
            number
            errors {
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
      ... on Error {
        type
      }
    }
  }
`;

const mergeOldAndNewZones = (
  response: XTZoneInformationsProgrammingConceptFormZoneInformationSendMutation$data,
  originalControlSystemData: XTZoneInformationsProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendZoneProgramming.results) {
    const successfulZones = response.sendZoneProgramming.results
      .map((zone) => {
        if (zone.__typename === "SendZoneProgrammingZoneSuccessPayload") {
          return zone;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.zone)
      .map(getZoneState);

    const mergedZonesMap = new Map<
      string,
      XTZoneInformationsProgrammingConceptFormInline_zone$data
    >();

    originalControlSystemData.panel.zoneInformations
      .map(getZoneState)
      .forEach((item) => mergedZonesMap.set(item.id, item));

    successfulZones.forEach((item) =>
      mergedZonesMap.set(item.id, {
        ...mergedZonesMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedZonesMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XTZoneInformationsProgrammingConceptFormZoneInformationSendMutation$data,
  originalControlSystemData: XTZoneInformationsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedZones = mergeOldAndNewZones(response, originalControlSystemData);

  // Update original data store
  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshZoneInformation: {
        __typename: "RefreshZoneInformationSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            zoneInformations: mergedZones,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XTZoneInformationsProgrammingConceptFormInline_controlSystem$key;
}): [
  (showAlerts: boolean, isSavingAllListItems?: boolean) => Promise<SaveErrors>,
  boolean
] => {
  const [save, isSaving] =
    useMutation<XTZoneInformationsProgrammingConceptFormZoneInformationSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const changedZones = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { zoneInformations },
        } = getState(props.controlSystem);
        save({
          variables: {
            systemId,
            zoneInformations: zoneInformations
              .filter(
                (zone) =>
                  zone.isNew ||
                  (!!changedZones &&
                    listItemHasChanged(zone.id, changedZones)) ||
                  isSavingAllListItems
              )
              .map(getZoneState)
              .map((zone) => ({
                id: zone.id,
                number: zone.number.toString(),
                name: zone.name,
                location: zone.location,
                type: zone.type,
                area: zone.area?.number ?? "",
                followArea: zone.followArea?.number ?? "",
                reportWithAccountNumberForArea:
                  zone?.reportWithAccountNumberForArea?.number ?? "",
                armedAreasForArmingZone:
                  zone.armedAreasForArmingZone.toString(),
                disarmedOpenActionMessage:
                  zone.disarmedOpenActionMessage ?? ZoneMessage.NONE,
                disarmedOpenOutputNumber: zone.disarmedOpenOutputNumber,
                disarmedOpenOutputAction:
                  zone.disarmedOpenOutputAction ?? ZoneOutputAction.NONE,
                disarmedShortActionMessage:
                  zone.disarmedShortActionMessage ?? ZoneMessage.NONE,
                disarmedShortOutputNumber: zone.disarmedShortOutputNumber,
                disarmedShortOutputAction:
                  zone.disarmedShortOutputAction ?? ZoneOutputAction.NONE,
                armedOpenActionMessage:
                  zone.armedOpenActionMessage ?? ZoneMessage.NONE,
                armedOpenOutputNumber: zone.armedOpenOutputNumber,
                armedOpenOutputAction:
                  zone.armedOpenOutputAction ?? ZoneOutputAction.NONE,
                armedShortActionMessage:
                  zone.armedShortActionMessage ?? ZoneMessage.NONE,
                armingStyle: zone.armingStyle,
                armedShortOutputNumber: zone.armedShortOutputNumber,
                armedShortOutputAction:
                  zone.armedShortOutputAction ?? ZoneOutputAction.NONE,
                swingerBypassEnabled: zone.swingerBypassEnabled,
                retardDelayEnabled: zone.retardDelayEnabled,
                fastResponseEnabled: zone.fastResponseEnabled,
                prewarnKeypads: zone.prewarnKeypads,
                crossZoneEnabled: zone.crossZoneEnabled,
                entryDelayNumber:
                  zone.entryDelayNumber ?? ZoneEntryDelayNumber.FOUR,
                priorityZone: zone.priorityZone,
                firePanelSlaveInput: zone.firePanelSlaveInput,
                realTimeStatusEnabled: zone.realTimeStatusEnabled,
                trafficCountEnabled: zone.trafficCountEnabled,
                zoneAuditDays: zone.zoneAuditDays,
                wireless: zone.wireless,
                serialNumber: zone.serialNumber,
                contactNumber: zone.contactNumber,
                supervisionTime:
                  zone.supervisionTime ?? ZoneSupervisionTime.NONE,
                wirelessLedEnabled: zone.wirelessLedEnabled,
                wirelessDisarmDisableEnabled: zone.wirelessDisarmDisableEnabled,
                wirelessPirPulseCount: zone.wirelessPirPulseCount,
                wirelessPirSensitivity: zone.wirelessPirSensitivity,
                wirelessPetImmunity: zone.wirelessPetImmunity,
                chimeSound: zone.chimeSound,
                lockdownEnabled: zone.lockdownEnabled,
                normallyClosed: zone.normallyClosed,
                wirelessContactNormallyOpen: zone.wirelessContactNormallyOpen,
                competitorWireless: zone.competitorWireless,
                sensorType: zone.sensorType,
                receiverRouting: zone.receiverRouting,
                expanderSerialNumber: zone.expanderSerialNumber,
                isNew: zone.isNew,
                tamperEnabled: zone.tamperEnabled,
              })),
          },
          onCompleted: (response) => {
            const sendErrors: SaveErrors = [];
            if (response.sendZoneProgramming.type && showAlert) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendZoneProgramming.results) {
              response.sendZoneProgramming.results.forEach((response) => {
                if (
                  response.__typename ===
                  "SendZoneProgrammingZoneSuccessPayload"
                ) {
                  resetLastUpdated(response.zone.id);
                } else if (
                  response.__typename === "SendListItemsErrorPayload"
                ) {
                  sendErrors.push({
                    programmingConcept: title,
                    errors: response.errors,
                    listItemNumber: response.number,
                  });
                }
              });

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (!sendErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: "Successfully Updated Zone Informations",
                });
              }
            }
            resolve(sendErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSaving,
  ];
};

const readZoneInformationsTemplateData = (
  programmingTemplateConcepts: XTZoneInformationsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTZoneInformationsProgrammingConceptFormInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        zoneInformations {
          included
          number
          name {
            included
            data
          }
          location {
            included
            data
          }
          type {
            included
            data
          }
          area {
            included
            data
          }
          followArea {
            included
            data
          }
          reportWithAccountNumberForArea {
            included
            data
          }
          armedAreasForArmingZone {
            included
            data
          }
          disarmedOpenActionMessage {
            included
            data
          }
          disarmedOpenOutputNumber {
            included
            data
          }
          disarmedOpenOutputAction {
            included
            data
          }
          disarmedShortActionMessage {
            included
            data
          }
          disarmedShortOutputNumber {
            included
            data
          }
          disarmedShortOutputAction {
            included
            data
          }
          armedOpenActionMessage {
            included
            data
          }
          armedOpenOutputNumber {
            included
            data
          }
          armedOpenOutputAction {
            included
            data
          }
          armedShortActionMessage {
            included
            data
          }
          armingStyle {
            included
            data
          }
          armedShortOutputNumber {
            included
            data
          }
          armedShortOutputAction {
            included
            data
          }
          swingerBypassEnabled {
            included
            data
          }
          retardDelayEnabled {
            included
            data
          }
          fastResponseEnabled {
            included
            data
          }
          prewarnKeypads {
            included
            data
          }
          crossZoneEnabled {
            included
            data
          }
          entryDelayNumber {
            included
            data
          }
          priorityZone {
            included
            data
          }
          firePanelSlaveInput {
            included
            data
          }
          realTimeStatusEnabled {
            included
            data
          }
          trafficCountEnabled {
            included
            data
          }
          zoneAuditDays {
            included
            data
          }
          wireless {
            included
            data
          }
          contactNumber {
            included
            data
          }
          supervisionTime {
            included
            data
          }
          wirelessLedEnabled {
            included
            data
          }
          wirelessDisarmDisableEnabled {
            included
            data
          }
          wirelessPirPulseCount {
            included
            data
          }
          wirelessPirSensitivity {
            included
            data
          }
          wirelessPetImmunity {
            included
            data
          }
          chimeSound {
            included
            data
          }
          lockdownEnabled {
            included
            data
          }
          normallyClosed {
            included
            data
          }
          wirelessContactNormallyOpen {
            included
            data
          }
          competitorWireless {
            included
            data
          }
          sensorType {
            included
            data
          }
          receiverRouting {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).zoneInformations;

export function applyTemplateData(
  programmingTemplateConcepts: XTZoneInformationsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const systemId = controlSystemRecordProxy.getValue("id");
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const zoneRecordProxies =
    panelRecordProxy.getLinkedRecords("zoneInformations") ?? [];
  const areaRecordProxies = panelRecordProxy.getLinkedRecords("areas") ?? [];
  const zonesByNumber = indexRecordProxiesByNumber(zoneRecordProxies);

  const zonesTemplateData =
    readZoneInformationsTemplateData(programmingTemplateConcepts) ?? [];
  zonesTemplateData.forEach((zoneTemplateData) => {
    if (zoneTemplateData?.included) {
      let zoneRecordProxy = zonesByNumber.get(zoneTemplateData.number);
      if (!zoneRecordProxy) {
        const newZoneId = applyNewDeviceToZoneInformationsList(
          {
            id: panelRecordProxy.getValue("id"),
            softwareVersion: panelRecordProxy.getValue("softwareVersion"),
            hardwareModel: panelRecordProxy.getValue("hardwareModel"),
            systemOptions: panelRecordProxy.getLinkedRecord(
              "systemOptions"
            ) && {
              useBuiltIn1100Wireless: !!panelRecordProxy
                .getLinkedRecord("systemOptions")
                ?.getValue("useBuiltIn1100Wireless"),
            },
            zoneNumberRange: panelRecordProxy.getValue("zoneNumberRange") ?? [],
            zoneInformations: zoneRecordProxies.map(
              (zoneRecordProxy) =>
                ({ number: zoneRecordProxy.getValue("number") } as const)
            ),
            outputInformations:
              panelRecordProxy
                .getLinkedRecords("outputInformations")
                ?.map((recordProxy) => ({
                  number: recordProxy.getValue("number"),
                })) ?? [],
            keyfobs:
              panelRecordProxy
                .getLinkedRecords("keyfobs")
                ?.map((recordProxy) => ({
                  number: recordProxy.getValue("number"),
                })) ?? [],
          },
          store
        );
        if (newZoneId) {
          zoneRecordProxy = store.get(newZoneId) as RecordProxy<Zone>;
          if (zoneRecordProxy) {
            zoneRecordProxy.setValue(zoneTemplateData.number, "number");
          }
        }
      }
      if (!zoneRecordProxy) {
        return;
      }

      applyTemplateScalarDataToRecordProxy(
        zoneRecordProxy,
        omit(["area"], zoneTemplateData)
      );

      if (zoneTemplateData.area?.included && zoneTemplateData.area.data) {
        let areaRecordProxy = store.get(
          idAsString(
            toAreaId(
              fromControlSystemId(asID(systemId)).systemId,
              zoneTemplateData.area.data
            )
          )
        ) as RecordProxy<Area>;

        if (!areaRecordProxy) {
          areaRecordProxy = store.get(
            idAsString(
              asID(
                areaRecordProxies
                  .find((area) =>
                    area
                      ? fromAreaId(
                          asID(area.getDataID().split(":")[7] ?? "") ?? ""
                        ).number === zoneTemplateData?.area?.data
                      : false
                  )
                  ?.getDataID()
                  .split(":")[7] ?? ""
              ) ?? ""
            )
          ) as RecordProxy<Area>;
        }

        if (areaRecordProxy) {
          zoneRecordProxy.setLinkedRecord(areaRecordProxy, "area");
        }
      }
      if (!zonesByNumber.has(zoneTemplateData.number)) {
        zonesByNumber.set(zoneTemplateData.number, zoneRecordProxy);
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(zonesByNumber),
    "zoneInformations"
  );
}

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XTZoneInformationsProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XTZoneInformationsProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            zoneInformations {
              isNew
            }
          }
        }
      `
    );
  const { zoneInformations } = controlSystem.panel;
  const itemsCount = zoneInformations.length;
  const hasNewItems =
    itemsCount > 0 && zoneInformations.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XTZoneInformationsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XTZoneInformationsProgrammingConceptForm_controlSystem on ControlSystem {
          __typename
          id
          copiedZoneInformation {
            id
          }
          panel {
            id
            zoneNumberRange
            totalWirelessZoneMax
            totalZonesMax
            hardwareModel
            softwareVersion
            ...ZoneInformationNumberField_panel
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            zoneInformations {
              id
              name
              number
              isNew
              ...XTZoneInformationsFields_zone
            }
            keyfobs {
              id
              number
            }
            outputInformations {
              id
              number
            }
            newZone {
              id
              number
              name
              location
              type
              area {
                __typename
                id
                number
              }
              chimeSound
              contactNumber
              crossZoneEnabled
              entryDelayNumber
              prewarnKeypads
              priorityZone
              serialNumber
              supervisionTime
              swingerBypassEnabled
              trafficCountEnabled
              wireless
              wirelessDisarmDisableEnabled
              wirelessLedEnabled
              wirelessPetImmunity
              wirelessPirPulseCount
              wirelessPirSensitivity
              zoneAuditDays
              wirelessContactNormallyOpen
              armedAreasForArmingZone
              disarmedOpenActionMessage
              disarmedOpenOutputNumber
              disarmedOpenOutputAction
              disarmedShortActionMessage
              disarmedShortOutputNumber
              disarmedShortOutputAction
              armedOpenActionMessage
              armedOpenOutputNumber
              armedOpenOutputAction
              armedShortActionMessage
              armedShortOutputNumber
              armedShortOutputAction
              isNew
              ...XTZoneInformationsFields_zone
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseZoneNumberRange_panel
            ...PanelContextUseWirelessZoneNumberRange_panel
            ...PanelContextUseBuiltIn1100WirelessEnabled_panel
            ...PanelContextUseVplexNumbers_panel
            ...PanelContextUseSupports1100T_panel
            ...PanelContextUseSupportsXR550_panel
            ...PanelContextUseAreaList_panel
            ...PanelContextHelpFiles_panel
            ...PanelContextUseVplexNumbers_panel
            ...PanelContextUseZoneIsWirelessOnly_panel
            ...PanelContextUseHas1100T_panel
            ...PanelContextUseSupports1100T_panel
            ...PanelContextUseSupportsZoneExpanderSerialNumber_panel
            ...ZoneInformationSerialNumberField_ZoneList_panel
            systemOptions {
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsContextHouseCode_systemOptions
              ...SystemOptionsContextSystemType_systemOptions
              ... on XtSystemOptions {
                useBuiltIn1100Wireless
              }
            }
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
        }
      `
    );

  const relayEnv = useRelayEnvironment();
  const uncheckListItem = useUncheckListItem()(ZONE_IDS);
  const [selectedListItemId, setSelectedListItemId] = React.useState(
    controlSystem.panel.zoneInformations[0]?.id ?? null
  );

  const {
    panel: {
      newZone,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const [deleteZoneInformation, isDeleting] =
    useMutation<XTZoneInformationsProgrammingConceptFormZoneInformationDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();

  const parentRelayEnv = useParentRelayEnvironment();
  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
    isValidatingProgramming,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const removeSelectedZone = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.zoneInformations.findIndex(
          (zone) => zone.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.zoneInformations.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return (
          controlSystem.panel.zoneInformations[newSelectedIndex]?.id ?? null
        );
      });
      const zone = controlSystem.panel.zoneInformations.find(
        (zone) => zone.id === selectedListItemId
      );
      uncheckListItem(String(zone?.number));
      if (zone?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "zoneInformations",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "Zone",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            zone?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteZoneInformation({
          variables: {
            id: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "zoneInformations",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedZoneId } = response.deleteZoneInformation;
            if (deletedZoneId) {
              showAlert({
                type: "success",
                text: "Zone Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "zoneInformations",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedZoneId, error } = response.deleteZoneInformation;
            if (deletedZoneId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  removeListItemFromStore(
                    selectedListItemId,
                    "zoneInformations",
                    controlSystem.panel.id,
                    parentStore
                  );
                });
              }
            } else {
              if (
                response.deleteZoneInformation.__typename !==
                "DeleteZoneInformationSuccessPayload"
              ) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Delete Zone: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Delete Zone",
                  });
                }
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Zone on Error",
            });
          },
        });
      }
    }
  };

  const availableNumbers = getAvailableNumbers(controlSystem.panel);
  const canAdd = availableNumbers.size > 0 && !!newZone;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <SystemOptionsContextProvider
        systemOptions={controlSystem.panel.systemOptions}
      >
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Zone%20Information`}
          title={title}
          deleting={isDeleting}
          isArrayConcept
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.zoneInformations
          )}
          amountAvailable={availableNumbers.size}
          addButton={
            <ProgrammingConceptForm.AddButton
              onClick={() => {
                relayEnv.commitUpdate((store) => {
                  const newZoneId = applyNewDeviceToZoneInformationsList(
                    controlSystem.panel,
                    store
                  );
                  if (newZoneId) {
                    setSelectedListItemId(newZoneId);
                  }
                });
              }}
            >
              Add Zone
            </ProgrammingConceptForm.AddButton>
          }
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            {(conceptId === activeConcept || isApplying || isSavingAll) && (
              <ProgrammingConceptForm.ListItemsContainer>
                <ProgrammingConceptForm.ListItemPicker
                  selectedId={selectedListItemId}
                  onChange={(id) => {
                    setSelectedListItemId(id);
                  }}
                  newItemId={newZone?.id}
                  items={controlSystem.panel.zoneInformations.map((zone) => ({
                    id: zone.id,
                    templateListItemId: zoneListItemTemplateId(
                      String(zone.number)
                    ),
                    isnew: zone.isNew,
                    label: `#${zone.number} ${zone.name}`,
                  }))}
                />
                <ProgrammingConceptForm.SelectedItemsContainer
                  selectedListItemId={selectedListItemId}
                  setSelectedListItemId={setSelectedListItemId}
                >
                  {controlSystem.panel.zoneInformations.map(
                    (zoneInformation) =>
                      (zoneInformation.id === selectedListItemId ||
                        isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                        ((isSavingAll || //Rendering at the last second before saving to check if there are errors or changes
                          programmingConcepts[conceptId].isSaving) &&
                          isValidatingProgramming)) && (
                        <RemountOnUpdateContainer nodeId={zoneInformation.id}>
                          <ProgrammingConceptForm.SelectedItem
                            conceptId={conceptId}
                            isnew={zoneInformation.isNew}
                            visible={zoneInformation.id === selectedListItemId}
                            key={zoneInformation.id}
                            listItemId={zoneInformation.id}
                            templateListItemId={zoneListItemTemplateId(
                              String(zoneInformation.number)
                            )}
                            title={`# ${zoneInformation.number} ${zoneInformation.name}`}
                            onDuplicate={
                              canAdd &&
                              (() => {
                                relayEnv.commitUpdate((store) => {
                                  const duplicateId =
                                    applyDuplicatedZoneInformationToZoneInformationsList(
                                      selectedListItemId,
                                      controlSystem.panel,
                                      store
                                    );
                                  if (duplicateId) {
                                    setSelectedListItemId(duplicateId);
                                  }
                                });
                              })
                            }
                            onCopy={() => {
                              relayEnv.commitUpdate((store) => {
                                const controlSystemRecord = store.get(
                                  controlSystem.id
                                );
                                const zoneInformationRecord =
                                  store.get<Zone>(selectedListItemId);
                                if (
                                  controlSystemRecord &&
                                  zoneInformationRecord
                                ) {
                                  const tempRecord =
                                    store.get("copiedZoneInformation") ??
                                    store.create(
                                      "copiedZoneInformation",
                                      "ZoneInformation"
                                    );
                                  tempRecord.copyFieldsFrom(
                                    zoneInformationRecord
                                  );
                                  controlSystemRecord.setLinkedRecord(
                                    tempRecord,
                                    "copiedZoneInformation"
                                  );
                                }
                              });
                            }}
                            onPaste={
                              !!controlSystem.copiedZoneInformation &&
                              (() => {
                                relayEnv.commitUpdate((store) => {
                                  const zoneInformationRecord =
                                    store.get<Zone>(selectedListItemId);
                                  const copiedZoneInformationRecord =
                                    store.get<Zone>("copiedZoneInformation");
                                  if (
                                    zoneInformationRecord &&
                                    copiedZoneInformationRecord
                                  ) {
                                    const allPossibleWirelessNumbers = new Set(
                                      getWirelessZoneNumberRange(
                                        controlSystem.panel.hardwareModel,
                                        Number(
                                          controlSystem.panel.softwareVersion
                                        ),
                                        true
                                      )
                                    );
                                    applyCopiedZoneInformationProgrammingToZoneInformation(
                                      copiedZoneInformationRecord,
                                      zoneInformationRecord,
                                      allPossibleWirelessNumbers
                                    );
                                  }
                                });
                              })
                            }
                            onRemove={removeSelectedZone}
                          >
                            <XTZoneInformationsFields
                              zoneInformation={zoneInformation}
                            />
                          </ProgrammingConceptForm.SelectedItem>
                        </RemountOnUpdateContainer>
                      )
                  )}
                </ProgrammingConceptForm.SelectedItemsContainer>
              </ProgrammingConceptForm.ListItemsContainer>
            )}
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </SystemOptionsContextProvider>
    </PanelContextProvider>
  );
}

const applyNewDeviceToZoneInformationsList = (
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly softwareVersion: string;
    readonly hardwareModel: PanelHardwareModel;
    readonly systemOptions: {
      readonly useBuiltIn1100Wireless?: boolean;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, softwareVersion, hardwareModel, systemOptions } = panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newZoneRecord = panelRecord.getLinkedRecord("newZone");
    if (newZoneRecord) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const isWireless = getWirelessOnlyZoneNumberRange(
          hardwareModel,
          Number(softwareVersion),
          systemOptions?.useBuiltIn1100Wireless ?? false
        ).includes(nextNumber);
        const { systemId } = fromZoneId(asID(newZoneRecord.getDataID()));
        const nextNewZoneId = idAsString(toZoneId(systemId, nextNumber));
        const nextNewZone = store.create(
          nextNewZoneId,
          "ZoneInformation"
        ) as RecordProxy<Zone>;
        nextNewZone.copyFieldsFrom(newZoneRecord);
        nextNewZone.setValue(nextNewZoneId, "id");
        nextNewZone.setValue(nextNumber, "number");
        nextNewZone.setValue(isWireless, "wireless");
        nextNewZone.setValue(true, "isNew");
        parseInt(softwareVersion) >= 693 &&
          nextNewZone.setValue(true, "tamperEnabled");
        nextNewZone.setValue(false, "wirelessDisarmDisableEnabled");

        const zoneInformationRecords =
          panelRecord.getLinkedRecords("zoneInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...zoneInformationRecords, nextNewZone],
          "zoneInformations"
        );
        return nextNewZone.getValue("id");
      }
    }
  }
};

const applyDuplicatedZoneInformationToZoneInformationsList = (
  zoneId: string,
  panel: Parameters<typeof getNextAvailableWirelessNumber>[0] &
    Parameters<typeof getNextAvailableNumber>[0] & {
      readonly id: string;
      readonly softwareVersion: string;
      readonly hardwareModel: PanelHardwareModel;
    },
  store: RecordSourceProxy
) => {
  const { id } = panel;
  const panelRecord = store.get<Panel>(id);
  const zoneInformationRecord = store.get<Zone>(zoneId);
  let allPossibleWirelessNumbers: Set<number> = new Set();

  if (panel) {
    const wirelessNumbers = getWirelessZoneNumberRange(
      panel.hardwareModel,
      Number(panel.softwareVersion),
      true
    ) as number[]; // Ensure this is an array of numbers

    allPossibleWirelessNumbers = new Set<number>(wirelessNumbers);
  }

  if (panelRecord && zoneInformationRecord) {
    const newZoneRecord = panelRecord.getLinkedRecord("newZone");
    newZoneRecord.setValue(false, "wirelessDisarmDisableEnabled");
    if (newZoneRecord) {
      const isWireless =
        zoneInformationRecord.getValue("wireless") ||
        zoneInformationRecord.getValue("competitorWireless");
      if (isWireless) {
        const nextWirelessNumber = getNextAvailableWirelessNumber(panel);
        if (isNotNullOrUndefined(nextWirelessNumber)) {
          return setZoneRecordForDuplicating(
            newZoneRecord,
            nextWirelessNumber,
            store,
            zoneInformationRecord,
            panelRecord,
            allPossibleWirelessNumbers
          );
        } else {
          const nextNumber = getNextAvailableNumber(panel);
          if (isNotNullOrUndefined(nextNumber)) {
            return setZoneRecordForDuplicating(
              newZoneRecord,
              nextNumber,
              store,
              zoneInformationRecord,
              panelRecord,
              allPossibleWirelessNumbers
            );
          }
        }
      } else {
        const nextNumber = getNextAvailableNumber(panel);
        if (isNotNullOrUndefined(nextNumber)) {
          return setZoneRecordForDuplicating(
            newZoneRecord,
            nextNumber,
            store,
            zoneInformationRecord,
            panelRecord,
            allPossibleWirelessNumbers
          );
        }
      }
    }
  }
};

const setZoneRecordForDuplicating = (
  newZoneRecord: RecordProxy<Zone>,
  nextNumber: number,
  store: RecordSourceProxy,
  zoneInformationRecord: RecordProxy<Zone>,
  panelRecord: RecordProxy<Panel>,
  allPossibleWirelessNumbers: Set<number>
) => {
  const { systemId } = fromZoneId(asID(newZoneRecord.getDataID()));
  const nextNewZoneId = idAsString(toZoneId(systemId, nextNumber));
  const nextNewZone = store.create(nextNewZoneId, "Zone") as RecordProxy<Zone>;
  nextNewZone.copyFieldsFrom(newZoneRecord);
  nextNewZone.setValue(nextNewZoneId, "id");
  nextNewZone.setValue(nextNumber, "number");
  nextNewZone.setValue(true, "isNew");

  const duplicatedZoneInformationRecord =
    applyDupedZoneInformationProgrammingToZoneInformation(
      zoneInformationRecord,
      nextNewZone,
      allPossibleWirelessNumbers
    );
  const zoneInformationRecords =
    panelRecord.getLinkedRecords("zoneInformations") ?? [];
  panelRecord.setLinkedRecords(
    [...zoneInformationRecords, duplicatedZoneInformationRecord],
    "zoneInformations"
  );
  return duplicatedZoneInformationRecord.getValue("id");
};

const getAvailableNumbers = (panel: {
  readonly hardwareModel: PanelHardwareModel;
  readonly softwareVersion: string;
  readonly outputInformations: ReadonlyArray<{ readonly number: number }>;
  readonly keyfobs: ReadonlyArray<{ readonly number: number }>;
  readonly zoneInformations: ReadonlyArray<{ readonly number: string }>;
  readonly zoneNumberRange: ReadonlyArray<number>;
  readonly systemOptions: {
    readonly useBuiltIn1100Wireless?: boolean;
  } | null;
}) => {
  const {
    zoneNumberRange,
    outputInformations,
    keyfobs,
    systemOptions,
    hardwareModel,
  } = panel;

  let allPossibleNumbers;

  // If panel is XT50, do additional checks for applicable wireless zone range
  if (hardwareModel === PanelHardwareModelEnum.XT50) {
    const xt50InternalWirelessZones = [
      80, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ];
    // zoneNumberRange already contains these zones, they need to be filtered out
    // if the panel is not using the built-in wireless receiver
    allPossibleNumbers = new Set(
      !systemOptions?.useBuiltIn1100Wireless
        ? zoneNumberRange.filter((v) => !xt50InternalWirelessZones.includes(v))
        : zoneNumberRange
    );
  } else {
    allPossibleNumbers = new Set(zoneNumberRange);
  }

  const conflictingWirelessOutputs = outputInformations.map(({ number }) =>
    [31, 32, 33, 34, 41, 42, 43, 44, 51, 52, 53, 54, 61, 62, 63, 64].includes(
      Number(number)
    )
      ? Number(number)
      : 0
  );

  const takenNumbers = new Set(
    panel.zoneInformations
      .map(({ number }) => Number(number))
      .concat(keyfobs.map(({ number }) => Number(number)))
      .concat(conflictingWirelessOutputs)
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(panel));

const getAvailableWirelessNumbers = (panel: {
  readonly hardwareModel: PanelHardwareModel;
  readonly softwareVersion: string;
  readonly outputInformations: ReadonlyArray<{ readonly number: number }>;
  readonly keyfobs: ReadonlyArray<{ readonly number: number }>;
  readonly zoneInformations: ReadonlyArray<{ readonly number: string }>;
}) => {
  const { outputInformations, keyfobs, hardwareModel, softwareVersion } = panel;

  const allPossibleNumbers = new Set(
    getWirelessZoneNumberRange(hardwareModel, Number(softwareVersion), true)
  );

  const conflictingWirelessOutputs = outputInformations.map(({ number }) =>
    [31, 32, 33, 34, 41, 42, 43, 44, 51, 52, 53, 54, 61, 62, 63, 64].includes(
      Number(number)
    )
      ? Number(number)
      : 0
  );

  const takenNumbers = new Set(
    panel.zoneInformations
      .map(({ number }) => Number(number))
      .concat(keyfobs.map(({ number }) => Number(number)))
      .concat(conflictingWirelessOutputs)
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableWirelessNumber = (
  panel: Parameters<typeof getAvailableWirelessNumbers>[0]
) => {
  return setFirst(getAvailableWirelessNumbers(panel));
};
