import { SecureComEnv } from "common/utils/universal/types";
import React from "react";
import { react2angular } from "react2angular";
import { DaSolidButton } from "../DaStyledElements";
import { LegaleseModal } from "../LegaleseModal";

const TermsAndConditions = () => {
  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] =
    React.useState(false);

  const isEU = process.env.REACT_APP_SECURECOM_ENV === SecureComEnv.Prod_Eu;

  const handleCloseModal = () => setIsTermsAndConditionsModalOpen(false);
  return isEU ? (
    <>
      <DaSolidButton onClick={() => setIsTermsAndConditionsModalOpen(true)}>
        Terms & Conditions
      </DaSolidButton>
      {isTermsAndConditionsModalOpen ? (
        <LegaleseModal
          modalTitle="Terms & Conditions"
          hasCloserIcon
          srcFile={
            "assets/img/legal-docs/LT-2911_GlobalDealer-ServicesAgreement_24362.html"
          }
          handleCloseModal={handleCloseModal}
          handleSubmit={handleCloseModal}
        />
      ) : null}
    </>
  ) : null;
};

export const dangerouslyAddToApp = () => {
  App.component(
    "termsAndConditions",
    react2angular(TermsAndConditions, [], ["$state", "$rootScope", "$scope"])
  );
};
