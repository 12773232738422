import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useIntl } from "react-intl";
import { useFragment, useMutation } from "react-relay/hooks";
import { ArmedStatusEnum, BadZonesOption } from "securecom-graphql/client";
import { seconds } from "../../../utils/dates";
import {
  ArmedSolidShieldLargeIcon,
  AwayLargeIcon,
  BurglaryIcon,
  HomeLargeIcon,
  SleepLargeIcon,
} from "../Icons";
import { AreaButtonIcon } from "./AreaButtonIcon";
import Area from "./BasicArmingArea";
import AreaButton from "./BasicArmingAreaButton";
import AreaButtonContainer from "./BasicArmingAreaButtonContainer";
import AreaButtonLabel from "./BasicArmingAreaButtonLabel";
import Areas from "./BasicArmingAreas";
import ShieldWrap from "./BasicArmingShieldWrap";
import Wrapper from "./BasicArmingWrapper";
import messages from "./messages";
import { HomeSleepAwayArmingArmAwayMutation } from "./__generated__/HomeSleepAwayArmingArmAwayMutation.graphql";
import { HomeSleepAwayArmingArmHomeMutation } from "./__generated__/HomeSleepAwayArmingArmHomeMutation.graphql";
import { HomeSleepAwayArmingArmSleepMutation } from "./__generated__/HomeSleepAwayArmingArmSleepMutation.graphql";
import { HomeSleepAwayArmingDisarmMutation } from "./__generated__/HomeSleepAwayArmingDisarmMutation.graphql";
import { HomeSleepAwayArming_controlSystem$key } from "./__generated__/HomeSleepAwayArming_controlSystem.graphql";

enum ArmingType {
  HOME,
  SLEEP,
  AWAY,
  NONE,
}

const HomeSleepAwayArming = (props: {
  armingIsEnabled?: boolean;
  disarmingIsEnabled?: boolean;
  controlSystem: HomeSleepAwayArming_controlSystem$key;
  setStatusMessage: Function;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setBadZonesMethod: Function;
}) => {
  const system = useFragment(
    graphql`
      fragment HomeSleepAwayArming_controlSystem on ControlSystem {
        id
        name
        isArmed
        isInAlarm
        isECP
        isDSC
        isSingleAreaSystem
        isHSASystem
        isHASystem
        isAreaSystem
        armedStatus
        areas(sort: { keys: ["number"], order: ASC }) {
          nodes {
            id
            name
            armedStatus
            isInAlarm
            number
          }
        }
      }
    `,
    props.controlSystem
  );

  const [disarmAllAreas, isDisarmingAllAreas] =
    useMutation<HomeSleepAwayArmingDisarmMutation>(graphql`
      mutation HomeSleepAwayArmingDisarmMutation($systemId: ID!) {
        disarmAllAreas(systemId: $systemId) {
          ... on MultipleAreasDisarmedSuccessPayload {
            controlSystem {
              armedStatus
              ...HomeSleepAwayArming_controlSystem
            }
          }
          ... on Error {
            type
          }
        }
      }
    `);

  const [armHome, isArmingHome] =
    useMutation<HomeSleepAwayArmingArmHomeMutation>(graphql`
      mutation HomeSleepAwayArmingArmHomeMutation(
        $systemId: ID!
        $badZonesOption: BadZonesOption
        $armInstant: Boolean
      ) {
        armHome(
          systemId: $systemId
          badZonesOption: $badZonesOption
          armInstant: $armInstant
        ) {
          ... on MultipleAreasArmedSuccessPayload {
            controlSystem {
              armedStatus
              ...HomeSleepAwayArming_controlSystem
            }
          }
          ... on BadZonesError {
            badZones {
              name
              number
            }
          }
          ... on Error {
            type
          }
        }
      }
    `);

  const [armSleep, isArmingSleep] =
    useMutation<HomeSleepAwayArmingArmSleepMutation>(graphql`
      mutation HomeSleepAwayArmingArmSleepMutation(
        $systemId: ID!
        $badZonesOption: BadZonesOption
        $armInstant: Boolean
      ) {
        armSleep(
          systemId: $systemId
          badZonesOption: $badZonesOption
          armInstant: $armInstant
        ) {
          ... on MultipleAreasArmedSuccessPayload {
            controlSystem {
              armedStatus
              ...HomeSleepAwayArming_controlSystem
            }
          }
          ... on BadZonesError {
            badZones {
              name
              number
            }
          }
          ... on Error {
            type
          }
        }
      }
    `);
  const intl = useIntl();
  const [armAway, isArmingAway] =
    useMutation<HomeSleepAwayArmingArmAwayMutation>(graphql`
      mutation HomeSleepAwayArmingArmAwayMutation(
        $systemId: ID!
        $badZonesOption: BadZonesOption
      ) {
        armAway(systemId: $systemId, badZonesOption: $badZonesOption) {
          ... on MultipleAreasArmedSuccessPayload {
            controlSystem {
              armedStatus
              ...HomeSleepAwayArming_controlSystem
            }
          }
          ... on BadZonesError {
            badZones {
              name
              number
            }
          }
          ... on Error {
            type
          }
        }
      }
    `);

  let armHomeInstantlyTimeout: undefined | number = undefined;
  let armSleepInstantlyTimeout: undefined | number = undefined;
  const disarmed = {
    ...messages.statusMessage,
    values: {
      status: intl.formatMessage(messages.disarmed).toLowerCase(),
    },
  };
  const armed = {
    ...messages.statusMessage,
    values: {
      status: intl.formatMessage(messages.armed).toLowerCase(),
    },
  };
  const armingHomeInstantMessage = {
    ...messages.armingAreaInstant,
    values: {
      area: intl.formatMessage(messages.home).toLowerCase(),
    },
  };
  const armingSleepInstantMessage = {
    ...messages.armingAreaInstant,
    values: {
      area: intl.formatMessage(messages.sleep).toLowerCase(),
    },
  };
  const armingHomeMessage = {
    ...messages.armingArea,
    values: {
      area: intl.formatMessage(messages.home).toLowerCase(),
    },
  };
  const armingAwayMessage = {
    ...messages.armingArea,
    values: {
      area: intl.formatMessage(messages.away).toLowerCase(),
    },
  };
  const armingSleepMessage = {
    ...messages.armingArea,
    values: {
      area: intl.formatMessage(messages.sleep).toLowerCase(),
    },
  };
  const disarmInstructionsMessage = {
    ...messages.clickInstructions,
    values: {
      instruction: intl.formatMessage(messages.disarm).toLowerCase(),
    },
  };
  const armInstantInstructionsMessage = {
    ...messages.clickInstructions,
    values: {
      instruction: `${intl
        .formatMessage(messages.arm)
        .toLowerCase()}. ${intl.formatMessage(messages.holdForInstant)}`,
    },
  };
  const armInstructionsMessage = {
    ...messages.clickInstructions,
    values: {
      instruction: intl.formatMessage(messages.arm).toLowerCase(),
    },
  };

  const armHomeInstantly = (badZonesOption = BadZonesOption.REFUSE) => {
    props.setIsLoading(true);
    props.setStatusMessage(armingHomeInstantMessage);
    armHome({
      variables: {
        systemId: system.id,
        armInstant: true,
        badZonesOption,
      },
      optimisticUpdater: (store) => {
        const systemRecord = store.get(system.id);
        systemRecord.setValue(ArmedStatusEnum.ARMING_HOME, "armedStatus");
      },
      onCompleted: (response) => {
        if (
          response.armHome?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_AWAY ||
          response.armHome?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_HOME ||
          response.armHome?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_SLEEP
        ) {
          props.setStatusMessage(armed);
        } else if (response.armHome?.badZones) {
          props.setBadZonesMethod({
            badZones: response.armHome.badZones,
            executeMutation: (badZonesOptionRetry: BadZonesOption) => {
              armHomeInstantly(badZonesOptionRetry);
            },
          });
        } else {
          props.setStatusMessage(disarmed);
        }
        props.setIsLoading(false);
      },
      onError: () => {
        props.setStatusMessage(disarmed);
        props.setIsLoading(false);
      },
    });
  };

  const armSleepInstantly = (badZonesOption = BadZonesOption.REFUSE) => {
    props.setIsLoading(true);
    props.setStatusMessage(armingSleepInstantMessage);
    armSleep({
      variables: {
        systemId: system.id,
        armInstant: true,
        badZonesOption,
      },
      optimisticUpdater: (store) => {
        const systemRecord = store.get(system.id);
        systemRecord.setValue(ArmedStatusEnum.ARMING_SLEEP, "armedStatus");
      },
      onCompleted: (response) => {
        if (
          response.armSleep?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_AWAY ||
          response.armSleep?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_HOME ||
          response.armSleep?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_SLEEP
        ) {
          props.setStatusMessage(armed);
        } else if (response.armSleep?.badZones) {
          props.setBadZonesMethod({
            badZones: response.armSleep.badZones,
            executeMutation: (badZonesOptionRetry: BadZonesOption) => {
              armSleepInstantly(badZonesOptionRetry);
            },
          });
        } else {
          props.setStatusMessage(disarmed);
        }
        props.setIsLoading(false);
      },
      onError: () => {
        props.setStatusMessage(disarmed);
        props.setIsLoading(false);
      },
    });
  };

  const handleAreaClick = (
    armingType: ArmingType,
    badZonesOption = BadZonesOption.REFUSE
  ) => {
    clearTimeout(armHomeInstantlyTimeout);
    clearTimeout(armSleepInstantlyTimeout);
    if (!system.isArmed && armingType === ArmingType.HOME) {
      props.setStatusMessage(armingHomeMessage);
      props.setIsLoading(true);
      armHome({
        variables: {
          systemId: system.id,
          badZonesOption,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          systemRecord.setValue(ArmedStatusEnum.ARMING_HOME, "armedStatus");
        },
        onCompleted: (response) => {
          if (
            response.armHome?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_AWAY ||
            response.armHome?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_HOME ||
            response.armHome?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_SLEEP
          ) {
            props.setStatusMessage(armed);
          } else if (response.armHome?.badZones) {
            props.setBadZonesMethod({
              badZones: response.armHome.badZones,
              executeMutation: (badZonesOptionRetry: BadZonesOption) => {
                handleAreaClick(ArmingType.HOME, badZonesOptionRetry);
              },
            });
          } else {
            props.setStatusMessage(disarmed);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(disarmed);
          props.setIsLoading(false);
        },
      });
    } else if (!system.isArmed && armingType === ArmingType.AWAY) {
      props.setIsLoading(true);
      props.setStatusMessage(armingAwayMessage);
      armAway({
        variables: {
          systemId: system.id,
          badZonesOption,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          systemRecord.setValue(ArmedStatusEnum.ARMING_AWAY, "armedStatus");
        },
        onCompleted: (response) => {
          if (
            response.armAway?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_AWAY ||
            response.armAway?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_HOME ||
            response.armAway?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_SLEEP
          ) {
            props.setStatusMessage(armed);
          } else if (response.armAway?.badZones) {
            props.setBadZonesMethod({
              badZones: response.armAway.badZones,
              executeMutation: (badZonesOptionRetry: BadZonesOption) => {
                handleAreaClick(ArmingType.AWAY, badZonesOptionRetry);
              },
            });
          } else {
            props.setStatusMessage(disarmed);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(disarmed);
          props.setIsLoading(false);
        },
      });
    } else if (!system.isArmed && armingType === ArmingType.SLEEP) {
      props.setIsLoading(true);
      props.setStatusMessage(armingSleepMessage);
      armSleep({
        variables: {
          systemId: system.id,
          badZonesOption,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          systemRecord.setValue(ArmedStatusEnum.ARMING_SLEEP, "armedStatus");
        },
        onCompleted: (response) => {
          if (
            response.armSleep?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_AWAY ||
            response.armSleep?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_HOME ||
            response.armSleep?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_SLEEP
          ) {
            props.setStatusMessage(armed);
          } else if (response.armSleep?.badZones) {
            props.setBadZonesMethod({
              badZones: response.armSleep.badZones,
              executeMutation: (badZonesOptionRetry: BadZonesOption) => {
                handleAreaClick(ArmingType.SLEEP, badZonesOptionRetry);
              },
            });
          } else {
            props.setStatusMessage(disarmed);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(disarmed);
          props.setIsLoading(false);
        },
      });
    } else {
      props.setIsLoading(true);
      props.setStatusMessage(messages.disarming);
      disarmAllAreas({
        variables: {
          systemId: system.id,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          const currentStatus = systemRecord.getValue("armedStatus");
          if (currentStatus === ArmedStatusEnum.ARMED_HOME) {
            systemRecord.setValue(
              ArmedStatusEnum.DISARMING_HOME,
              "armedStatus"
            );
          } else if (currentStatus === ArmedStatusEnum.ARMED_SLEEP) {
            systemRecord.setValue(
              ArmedStatusEnum.DISARMING_SLEEP,
              "armedStatus"
            );
          } else if (currentStatus === ArmedStatusEnum.ARMED_AWAY) {
            systemRecord.setValue(
              ArmedStatusEnum.DISARMING_AWAY,
              "armedStatus"
            );
          } else {
            systemRecord.setValue(ArmedStatusEnum.DISARMING, "armedStatus");
          }
        },
        onCompleted: (response) => {
          if (
            response.disarmAllAreas?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_AWAY ||
            response.disarmAllAreas?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_HOME ||
            response.disarmAllAreas?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_SLEEP
          ) {
            props.setStatusMessage(armed);
          } else {
            props.setStatusMessage(disarmed);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(armed);
          props.setIsLoading(false);
        },
      });
    }
  };

  const handleArmHomeMouseEnter = () => {
    if (system.isArmed) {
      props.setStatusMessage(disarmInstructionsMessage);
    } else {
      props.setStatusMessage(armInstantInstructionsMessage);
    }
  };

  const handleArmAwayMouseEnter = () => {
    if (system.isArmed) {
      props.setStatusMessage(disarmInstructionsMessage);
    } else {
      props.setStatusMessage(armInstructionsMessage);
    }
  };
  const handleArmSleepMouseEnter = () => {
    if (system.isArmed) {
      props.setStatusMessage(disarmInstructionsMessage);
    } else {
      props.setStatusMessage(armInstantInstructionsMessage);
    }
  };

  const handleAreaMouseLeave = () => {
    if (isDisarmingAllAreas || isArmingHome || isArmingAway || isArmingSleep)
      return;
    if (system.isArmed) {
      props.setStatusMessage(armed);
    } else if (!system.isArmed) {
      props.setStatusMessage(disarmed);
    }
  };

  const armHomeIsActive =
    system.armedStatus === ArmedStatusEnum.ARMED_HOME ||
    system.armedStatus === ArmedStatusEnum.ARMING_HOME ||
    system.armedStatus === ArmedStatusEnum.DISARMING_HOME ||
    isArmingHome;
  const armAwayIsActive =
    system.armedStatus === ArmedStatusEnum.ARMED_AWAY ||
    system.armedStatus === ArmedStatusEnum.ARMING_AWAY ||
    system.armedStatus === ArmedStatusEnum.DISARMING_AWAY ||
    isArmingAway;
  const armSleepIsActive =
    system.armedStatus === ArmedStatusEnum.ARMED_SLEEP ||
    system.armedStatus === ArmedStatusEnum.ARMING_SLEEP ||
    system.armedStatus === ArmedStatusEnum.DISARMING_SLEEP ||
    isArmingSleep;

  return (
    <Wrapper>
      <Areas>
        <Area
          key={1}
          index={0}
          count={3}
          active={armHomeIsActive}
          inactive={armAwayIsActive || armSleepIsActive}
        >
          <AreaButton
            arming={isArmingHome}
            disarming={isDisarmingAllAreas}
            armed={system.armedStatus === ArmedStatusEnum.ARMED_HOME}
            onClick={() => handleAreaClick(ArmingType.HOME)}
            disabled={
              isArmingHome ||
              isArmingSleep ||
              isArmingAway ||
              isDisarmingAllAreas ||
              (system.armedStatus === ArmedStatusEnum.DISARMED &&
                !props.armingIsEnabled) ||
              ((system.armedStatus === ArmedStatusEnum.ARMED_HOME ||
                system.armedStatus === ArmedStatusEnum.ARMED_AWAY) &&
                !props.disarmingIsEnabled)
            }
            onMouseDown={() => {
              if (system.isArmed) return;

              if (armHomeInstantlyTimeout !== undefined) {
                clearTimeout(armHomeInstantlyTimeout);
              }

              armHomeInstantlyTimeout = setTimeout(
                armHomeInstantly,
                seconds(1)
              );
            }}
            onMouseEnter={handleArmHomeMouseEnter}
            onMouseLeave={handleAreaMouseLeave}
            burgAlarm={system.isInAlarm}
          >
            <AreaButtonContainer>
              <AreaButtonIcon
                armed={system.armedStatus === ArmedStatusEnum.ARMED_HOME}
                burgAlarm={system.isInAlarm}
              >
                {system.isInAlarm ? <BurglaryIcon /> : <HomeLargeIcon />}
              </AreaButtonIcon>
              {(system.armedStatus === ArmedStatusEnum.DISARMED ||
                system.armedStatus === ArmedStatusEnum.ARMING_HOME) && (
                <AreaButtonLabel armed={false}>Home</AreaButtonLabel>
              )}
              <ShieldWrap>
                {(system.armedStatus === ArmedStatusEnum.ARMED_HOME ||
                  system.armedStatus === ArmedStatusEnum.DISARMING_HOME) && (
                  <ArmedSolidShieldLargeIcon valign="top" />
                )}
              </ShieldWrap>
            </AreaButtonContainer>
          </AreaButton>
        </Area>
        <Area
          key={2}
          index={1}
          count={3}
          active={armSleepIsActive}
          inactive={armHomeIsActive || armAwayIsActive}
        >
          <AreaButton
            arming={isArmingSleep}
            disarming={isDisarmingAllAreas}
            armed={system.armedStatus === ArmedStatusEnum.ARMED_SLEEP}
            onClick={() => handleAreaClick(ArmingType.SLEEP)}
            disabled={
              isArmingAway ||
              isArmingHome ||
              isArmingSleep ||
              isDisarmingAllAreas ||
              (system.armedStatus === ArmedStatusEnum.DISARMED &&
                !props.armingIsEnabled) ||
              ((system.armedStatus === ArmedStatusEnum.ARMED_HOME ||
                system.armedStatus === ArmedStatusEnum.ARMED_AWAY ||
                system.armedStatus === ArmedStatusEnum.ARMED_SLEEP) &&
                !props.disarmingIsEnabled)
            }
            onMouseDown={() => {
              if (system.isArmed) return;

              if (armSleepInstantlyTimeout !== undefined) {
                clearTimeout(armSleepInstantlyTimeout);
              }

              armSleepInstantlyTimeout = setTimeout(
                armSleepInstantly,
                seconds(1)
              );
            }}
            onMouseEnter={handleArmSleepMouseEnter}
            onMouseLeave={handleAreaMouseLeave}
            burgAlarm={system.isInAlarm}
          >
            <AreaButtonContainer>
              <AreaButtonIcon
                armed={system.armedStatus === ArmedStatusEnum.ARMED_SLEEP}
                burgAlarm={system.isInAlarm}
              >
                {system.isInAlarm ? <BurglaryIcon /> : <SleepLargeIcon />}
              </AreaButtonIcon>
              {(system.armedStatus === ArmedStatusEnum.DISARMED ||
                system.armedStatus === ArmedStatusEnum.ARMING_SLEEP) && (
                <AreaButtonLabel armed={false}>Sleep</AreaButtonLabel>
              )}
              <ShieldWrap>
                {(system.armedStatus === ArmedStatusEnum.ARMED_SLEEP ||
                  system.armedStatus === ArmedStatusEnum.DISARMING_SLEEP) && (
                  <ArmedSolidShieldLargeIcon valign="top" />
                )}
              </ShieldWrap>
            </AreaButtonContainer>
          </AreaButton>
        </Area>
        <Area
          key={3}
          index={2}
          count={3}
          active={armAwayIsActive}
          inactive={armHomeIsActive || armSleepIsActive}
        >
          <AreaButton
            arming={isArmingAway}
            disarming={isDisarmingAllAreas}
            armed={system.armedStatus === ArmedStatusEnum.ARMED_AWAY}
            onClick={() => handleAreaClick(ArmingType.AWAY)}
            disabled={
              isArmingAway ||
              isArmingHome ||
              isArmingSleep ||
              isDisarmingAllAreas ||
              (system.armedStatus === ArmedStatusEnum.DISARMED &&
                !props.armingIsEnabled) ||
              ((system.armedStatus === ArmedStatusEnum.ARMED_HOME ||
                system.armedStatus === ArmedStatusEnum.ARMED_AWAY) &&
                !props.disarmingIsEnabled)
            }
            onMouseEnter={handleArmAwayMouseEnter}
            onMouseLeave={handleAreaMouseLeave}
            burgAlarm={system.isInAlarm}
          >
            <AreaButtonContainer>
              <AreaButtonIcon
                armed={system.armedStatus === ArmedStatusEnum.ARMED_AWAY}
                burgAlarm={system.isInAlarm}
              >
                {system.isInAlarm ? <BurglaryIcon /> : <AwayLargeIcon />}
              </AreaButtonIcon>
              {(system.armedStatus === ArmedStatusEnum.DISARMED ||
                system.armedStatus === ArmedStatusEnum.ARMING_AWAY) && (
                <AreaButtonLabel armed={false}>Away</AreaButtonLabel>
              )}
              <ShieldWrap>
                {(system.armedStatus === ArmedStatusEnum.ARMED_AWAY ||
                  system.armedStatus === ArmedStatusEnum.DISARMING_AWAY) && (
                  <ArmedSolidShieldLargeIcon valign="top" />
                )}
              </ShieldWrap>
            </AreaButtonContainer>
          </AreaButton>
        </Area>
      </Areas>
    </Wrapper>
  );
};

export default HomeSleepAwayArming;
