import graphql from "babel-plugin-relay/macro";
import TextInput from "components/Inputs/TextInput";
import LoadingSpinner from "components/LoadingSpinner";
import Modal from "components/Modal";
import Select from "components/Select";
import { useShowAlert } from "contexts/AlertsContext";
import { format, parseISO } from "date-fns";
import React, { SetStateAction, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { HolidayDateClass } from "securecom-graphql/client";
import styled from "styled-components/macro";
import { DateInputPopover } from "./DateInputPopover";
import { GlobalHolidayDatesModalFormCreateMutation } from "./__generated__/GlobalHolidayDatesModalFormCreateMutation.graphql";
import { GlobalHolidayDatesModalFormUpdateMutation } from "./__generated__/GlobalHolidayDatesModalFormUpdateMutation.graphql";
import { GlobalHolidayDatesModalForm_dealer$key } from "./__generated__/GlobalHolidayDatesModalForm_dealer.graphql";

const classSelectionList: HolidayDateClass[] = [
  HolidayDateClass.A,
  HolidayDateClass.B,
  HolidayDateClass.C,
];

const GlobalHolidayDatesModalForm = ({
  closeModal,
  isNew,
  data,
  dealerId,
  panelsAssociated,
  fragmentRef,
}: {
  closeModal: () => void;
  isNew: boolean;
  //Data is only being brought in when EDITING an existing holiday
  data?: {
    readonly clazz: string;
    readonly date: string;
    readonly description: string;
    readonly id: string;
    readonly name: string;
    readonly panelAssociation: readonly (number | null)[];
    readonly scapiId: number;
  };
  dealerId: string;
  panelsAssociated?: number;
  fragmentRef: GlobalHolidayDatesModalForm_dealer$key;
}) => {
  const dealerData = useFragment(
    graphql`
      fragment GlobalHolidayDatesModalForm_dealer on Dealer {
        vernaculars {
          original
          replacement
        }
      }
    `,
    fragmentRef
  );

  const systemReplacement = dealerData.vernaculars.find(
    (v) => v?.original === "systems"
  );

  const showAlert = useShowAlert();
  const [createGlobalHolidayMutation, creatingHoliday] =
    useMutation<GlobalHolidayDatesModalFormCreateMutation>(graphql`
      mutation GlobalHolidayDatesModalFormCreateMutation(
        $input: CreateGlobalHolidayDateInput!
      ) {
        createGlobalHolidayDate(input: $input) {
          ... on CreateGlobalHolidayDateSuccessResponse {
            dealer {
              globalHolidayDates {
                scapiId
                id
                name
                description
                date
                clazz
              }
            }
          }
          ... on CreateGlobalHolidayDateErrorResponse {
            errorMessage
          }
        }
      }
    `);

  const [updateGlobalHolidayDate, updatingGlobalHolidayDate] =
    useMutation<GlobalHolidayDatesModalFormUpdateMutation>(graphql`
      mutation GlobalHolidayDatesModalFormUpdateMutation(
        $input: UpdateGlobalHolidayDateForMassPanelsInput!
      ) {
        updateGlobalHolidayDateForMassPanels(input: $input) {
          ... on UpdateGlobalHolidayDateForMassPanelsSuccessResponse {
            dealer {
              globalHolidayDates {
                name
                date
                description
                id
                scapiId
                clazz
              }
            }
          }
          ... on UpdateGlobalHolidayDateForMassPanelsErrorResponse {
            errorMessage
          }
        }
      }
    `);

  //HOLIDAY STATES
  const [holidayName, setHolidayName] = useState<string>(data ? data.name : "");
  // MM/DD Format
  const [holidayDate, setHolidayDate] = useState<string>(
    data ? format(parseISO(data.date), "MM/dd") : ""
  );
  const [holidayClass, setHolidayClass] = useState<HolidayDateClass>(
    data?.clazz ? (data?.clazz as HolidayDateClass) : HolidayDateClass.A
  );
  const [holidayDescription, setHolidayDescription] = useState<string>(
    data ? data.description : ""
  );

  //CHANGE HANDLERS
  const handleHolidayNameChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setHolidayName(event.target.value);
  };
  const handleHolidayClassChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setHolidayClass(event.target.value as HolidayDateClass);
  };
  const handleDescriptionChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setHolidayDescription(event.target.value);
  };

  const updating = creatingHoliday || updatingGlobalHolidayDate;

  return (
    <Modal size="medium" onClickOutside={closeModal}>
      <Modal.Body>
        <Modal.Header>
          <h1>{isNew ? "New" : "Edit"} Global Holiday Date</h1>
          {isNew ? null : (
            <NoteBanner>
              <b>Note</b>
              {`: Changes to this holiday will be automatically sent to ${panelsAssociated} ${
                systemReplacement ? systemReplacement.replacement : "system(s)"
              }.`}
            </NoteBanner>
          )}
        </Modal.Header>
        <form
          className="form-horizontal"
          onSubmit={(event) => {
            event.preventDefault();
            if (isNew) {
              createGlobalHolidayMutation({
                variables: {
                  input: {
                    name: holidayName,
                    date: format(
                      new Date(`${holidayDate}/${new Date().getUTCFullYear()}`),
                      "ddMM"
                    ),
                    description: holidayDescription,
                    clazz: holidayClass,
                    dealerId: Number(dealerId),
                    panelAssociation: [],
                  },
                },
                onCompleted: (res) => {
                  if (res.createGlobalHolidayDate.errorMessage) {
                    showAlert({
                      type: "error",
                      text: res.createGlobalHolidayDate.errorMessage,
                    });
                  } else {
                    showAlert({
                      type: "success",
                      text: "Successfully created holiday.",
                    });
                  }
                  closeModal();
                },
                onError: (error) => {
                  showAlert({
                    type: "error",
                    text: "An unknown error occurred while creating holiday.",
                  });
                },
              });
            } else {
              updateGlobalHolidayDate({
                variables: {
                  input: {
                    scapiId: data?.scapiId!,
                    name: holidayName,
                    date: format(
                      new Date(`${holidayDate}/${new Date().getUTCFullYear()}`),
                      "ddMM"
                    ),
                    description: holidayDescription,
                    clazz: holidayClass,
                    dealerId: Number(dealerId),
                    panelAssociation: data?.panelAssociation!,
                  },
                },
                onCompleted: (res) => {
                  if (res.updateGlobalHolidayDateForMassPanels.errorMessage) {
                    showAlert({
                      type: "error",
                      text: res.updateGlobalHolidayDateForMassPanels
                        .errorMessage,
                    });
                  } else {
                    showAlert({
                      type: "success",
                      text: "Successfully updated holiday.",
                    });
                  }
                  closeModal();
                },
                onError: (error) => {
                  showAlert({
                    type: "error",
                    text: "An unknown error occurred while updating holiday.",
                  });
                },
              });
            }
          }}
        >
          <div className="modal-body">
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="holidayName">
                Name <span className="text-danger">*</span>
              </label>
              <FieldContainer>
                <TextInput
                  type="text"
                  className="form-control"
                  required
                  name="holidayName"
                  id="holidayName"
                  value={holidayName}
                  onChange={handleHolidayNameChange}
                  disabled={updating}
                  maxLength={50}
                />
              </FieldContainer>
              <label className="col-sm-3 control-label" htmlFor="datePicker">
                Date <span className="text-danger">*</span>
              </label>
              <FieldContainer>
                <DateInputPopover
                  selectedDate={holidayDate}
                  setSelectedDate={setHolidayDate}
                  disabled={updating}
                />
              </FieldContainer>
              <label className="col-sm-3 control-label" htmlFor="holiday-class">
                Class <span className="text-danger">*</span>
              </label>
              <FieldContainer>
                <Select
                  id="holiday-class"
                  disabled={updating}
                  onChange={handleHolidayClassChange}
                  value={holidayClass}
                  required
                >
                  {classSelectionList.map((classSelection) => (
                    <Select.Option key={classSelection} value={classSelection}>
                      {classSelection}
                    </Select.Option>
                  ))}
                </Select>
              </FieldContainer>
              <label className="col-sm-3 control-label" htmlFor="description">
                Description
              </label>
              <DescriptionContainer>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  value={holidayDescription}
                  onChange={handleDescriptionChange}
                  disabled={updating}
                />
              </DescriptionContainer>
            </div>
          </div>
          <div className="modal-footer bt0">
            <div className="connection-status pull-left"></div>
            <div className="pull-right">
              <button
                className="btn btn-default"
                onClick={closeModal}
                disabled={updating}
              >
                Cancel
              </button>
              <button className="btn btn-dmp" type="submit" disabled={updating}>
                {updating ? <LoadingSpinner /> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const FieldContainer = styled.div`
  display: flex;
  align-items: left;
  margin: 0 2rem 2rem 0;
`;
const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 2rem 0 0;
`;
const NoteBanner = styled.div`
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  width: 96.4%;
  height: 3rem;
  background-color: var(--color-info-200);
  border: 1px solid var(--color-info-700);
`;

export default GlobalHolidayDatesModalForm;
