App.factory("SearchDataService", [
  "$q",
  "PROPS",
  "DealerODataAPI",
  "UserODataAPI",
  "OdataPageService",
  "ControlSystemsODataAPI",
  "VideoDevicesODataAPI",
  "ThirdPartyVideoDevicesODataAPI",
  "$filter",
  "CustomersODataAPI",
  "AlarmVisionXVMACAddressAPI",
  function (
    $q,
    PROPS,
    DealerODataAPI,
    UserODataAPI,
    OdataPageService,
    ControlSystemsODataAPI,
    VideoDevicesODataAPI,
    ThirdPartyVideoDevicesODataAPI,
    $filter,
    CustomersODataAPI,
    AlarmVisionXVMACAddressAPI
  ) {
    return {
      videoDeviceSearch: function (dealerId, searchParams) {
        var deferred = $q.defer();
        // if (angular.isUndefined(dealerId)) deferred.reject();
        var containsArray = [
          "name",
          "mac_address",
          "serial_number",
          "control_system/name",
        ];
        var filter = this.queryStringGenerator(searchParams, containsArray);
        var select =
          "name,serial_number,mac_address,status,checked_in_at,wifi_rssi,network_ready";
        var expand =
          "control_system($select=id,customer_id,name;$expand=customer($select=dealer_id))";
        var promise =
          dealerId !== null
            ? DealerODataAPI.getVideoDevices({
                dealer_id: dealerId,
                $filter: filter,
                $select: select,
                $expand: expand,
              }).$promise
            : VideoDevicesODataAPI.getVideoDevices({
                $filter: filter,
                $select: select,
                $expand: expand,
              }).$promise;
        var OOPS = new OdataPageService();
        OOPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (notification) {
              deferred.notify(notification);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      thirdPartyVideoDeviceSearch: function (dealerId, searchParams) {
        var deferred = $q.defer();
        // if (angular.isUndefined(dealerId)) deferred.reject();
        var containsArray = ["serial_number"];
        var filter = this.queryStringGenerator(searchParams, containsArray);
        var select = "name,serial_number,mac_address,status,wifi_rssi";
        var expand =
          "control_system($select=id,customer_id,name;$expand=customer($select=dealer_id))";
        var promise =
          dealerId !== null
            ? DealerODataAPI.getThirdPartyVideoDevices({
                dealer_id: dealerId,
                $filter: filter,
                $select: select,
                $expand: expand,
              }).$promise
            : ThirdPartyVideoDevicesODataAPI.getThirdPartyVideoDevices({
                $filter: filter,
                $select: select,
                $expand: expand,
              }).$promise;
        var OOPS = new OdataPageService();
        OOPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (notification) {
              deferred.notify(notification);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      usersSearch: function (dealerId, searchParams) {
        var deferred = $q.defer();
        var containsArray = ["email", "first_name", "last_name"];
        var filter = this.queryStringGenerator(searchParams, containsArray);
        var userSelect = [
          "accessible_type,accessible_id,id,email,role,locked_at,hidden",
        ];
        var customerSelect = ["id,customer_id,email,role,locked_at,hidden"];
        var promise =
          dealerId !== null
            ? DealerODataAPI.getCustomerUsers({
                dealer_id: dealerId,
                $filter: filter,
                $select: customerSelect,
              }).$promise
            : UserODataAPI.getUsers({ $filter: filter, $select: userSelect })
                .$promise;
        var OOPS = new OdataPageService();
        OOPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (notification) {
              deferred.notify(notification);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      dealersSearch: function (searchParams) {
        var deferred = $q.defer();
        var containsArray = [
          "name",
          "company_name",
          "dealer_code",
          "cms_account_number",
        ];
        var filter = this.queryStringGenerator(searchParams, containsArray);
        var select =
          "id,name,company_name,dealer_code,cms_account_number,can_have_children,parent_id, ";
        var promise = DealerODataAPI.getDealer({
          dealer_id: null,
          $filter: filter,
          $select: select,
        }).$promise;
        var OOPS = new OdataPageService();
        OOPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (notification) {
              deferred.notify(notification);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      // Gather prospect parent dealers
      parentDealersSearch: function () {
        var deferred = $q.defer();
        var filter = "can_have_children";
        var select = "id,name";
        var promise = DealerODataAPI.getDealer({
          dealer_id: null,
          $filter: filter,
          $select: select,
        }).$promise;
        var OOPS = new OdataPageService();
        OOPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (notification) {
              deferred.notify(notification);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      systemsSearch: function (dealer_id, searchParams) {
        var deferred = $q.defer();
        var containsArray = [
          "name",
          "panels/serial_number",
          "panels/comm_address",
        ];
        var filter = this.queryStringGenerator(
          searchParams,
          containsArray,
          "systems"
        );
        var select = "id,name,customer_id, site_id";
        var expand =
          "panels($select=account_prefix,account_number,serial_number,comm_address,hardware_model)";
        var promise =
          dealer_id !== null
            ? DealerODataAPI.getControlSystems({
                dealer_id: dealer_id,
                $filter: filter,
                $select: select,
                $expand: expand,
              }).$promise
            : ControlSystemsODataAPI.getSystems({
                $filter: filter,
                $select: select,
                $expand: expand,
              }).$promise;
        var OOPS = new OdataPageService();
        OOPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (notification) {
              deferred.notify(notification);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      customerSearch: function (dealer_id, searchParams) {
        var deferred = $q.defer();
        var containsArray = [
          "name",
          "email",
          "city",
          "state_province",
          "dealer_account",
        ];
        var filter = this.queryStringGenerator(searchParams, containsArray);
        var select = "dealer_account,dealer_id,id,name,city,state_province";
        var promise =
          dealer_id !== null
            ? DealerODataAPI.getCustomers({
                dealer_id: dealer_id,
                $filter: filter,
                $select: select,
              }).$promise
            : CustomersODataAPI.get({ $filter: filter, $select: select })
                .$promise;
        var OOPS = new OdataPageService();
        OOPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (notification) {
              deferred.notify(notification);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      xvSearch: function (searchParams) {
        var deferred = $q.defer();
        AlarmVisionXVMACAddressAPI.getXV(searchParams)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (notification) {
              deferred.notify(notification);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Function used to generate an ODdata filter string
       * @param {string} params - search parameters, separated by spaces
       * @param {[string]} containsArray - an array of fields to search
       * @param {string} [type] - the type of data being searched (ex: "control_systems")
       * @returns {string} - the OData query
       */
      queryStringGenerator: function (params, containsArray, type) {
        var paramArray = params.split(" ");
        var alphabet = ["a", "b", "c", "d", "e", "f"];

        var queryString = "";
        for (var param = 0; param < paramArray.length; param++) {
          var encodedParam = paramArray[param].replace("'", "''");
          if (param !== 0) {
            queryString += " and ";
          }
          for (var contain = 0; contain < containsArray.length; contain++) {
            if (contain === 0) {
              queryString += "(";
            } else {
              queryString += " or ";
            }
            if (type === "systems" && containsArray[contain].includes("/")) {
              var containParts = containsArray[contain].split("/");
              var concept = containParts[0];
              var field = containParts[1];
              queryString +=
                concept +
                "/any(" +
                alphabet[contain] +
                ":contains(" +
                alphabet[contain] +
                "/" +
                field +
                ",'" +
                encodedParam +
                "' ))";
            } else {
              queryString +=
                "contains(" +
                containsArray[contain] +
                ",'" +
                encodedParam +
                "')";
            }
            if (contain === containsArray.length - 1) {
              queryString += ")";
            }
          }
        }
        return queryString;
      },
    };
  },
]);
