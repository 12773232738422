import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreSupervisoryReportsField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreSupervisoryReportsField_remoteOptions.graphql";

export const remoteOptionsEntreSupervisoryReportsFieldId = () =>
  "remote-options-entre-supervisory-reports";

function RemoteOptionsEntreSupervisoryReportsField() {
  const [{ entreSupervisoryReports, entreConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsEntreSupervisoryReportsField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsEntreSupervisoryReportsField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            entreSupervisoryReports
            entreConnection
          }
          ... on XfRemoteOptions {
            entreSupervisoryReports
            entreConnection
          }
          ... on TMSentryRemoteOptions {
            entreSupervisoryReports
            entreConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsEntreSupervisoryReportsFieldId();
  const label = "Entré Supervisory Reports";
  const disabled = entreConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label={label}
    >
      <Switch
        id={fieldId}
        disabled={disabled}
        label={label}
        checked={!!entreSupervisoryReports}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !entreSupervisoryReports,
              "entreSupervisoryReports"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreSupervisoryReportsField;
