/**
 * @generated SignedSource<<4b58d313bb650ba55c11a9516c92a4bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecorderAddCameraModal_varHub$data = {
  readonly camectHubId: string;
  readonly cameras: ReadonlyArray<{
    readonly addedToDB: boolean;
    readonly camectCamId: string;
    readonly camectHubId: string;
    readonly cameraId: number | null;
    readonly cameraName: string;
    readonly framesPerSecond: number;
    readonly ipAddress: string;
    readonly isEnabled: boolean;
    readonly isHidden: boolean;
    readonly isScapiCamera: boolean;
    readonly isStreaming: boolean;
    readonly macAddress: string;
    readonly manuallyAdded: boolean;
    readonly megapixels: number;
    readonly needsCredential: boolean;
    readonly playerAuthToken: string;
    readonly playerUrl: string;
    readonly rtspUrl: string | null;
    readonly scapiCameraId: number | null;
    readonly videoCodec: string;
  }>;
  readonly disableAutoAddCameras: boolean | null;
  readonly hubId: number;
  readonly hubName: string;
  readonly megapixelThresholdCritical: boolean;
  readonly megapixelThresholdWarning: boolean;
  readonly supportedMegapixels: number;
  readonly unsupportedCodec: boolean;
  readonly unsupportedFrameRate: boolean;
  readonly " $fragmentType": "RecorderAddCameraModal_varHub";
};
export type RecorderAddCameraModal_varHub$key = {
  readonly " $data"?: RecorderAddCameraModal_varHub$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderAddCameraModal_varHub">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectHubId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecorderAddCameraModal_varHub",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hubId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hubName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableAutoAddCameras",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "megapixelThresholdWarning",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "megapixelThresholdCritical",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportedMegapixels",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unsupportedFrameRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unsupportedCodec",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VarHubCamera",
      "kind": "LinkedField",
      "name": "cameras",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addedToDB",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "camectCamId",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cameraId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cameraName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ipAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isScapiCamera",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isStreaming",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "macAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "needsCredential",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "playerAuthToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "playerUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rtspUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "megapixels",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoCodec",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "framesPerSecond",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manuallyAdded",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isHidden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scapiCameraId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "VarHub",
  "abstractKey": null
};
})();

(node as any).hash = "c8b4d0cadc31fe64980795af1ffe9ec6";

export default node;
