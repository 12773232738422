import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreConnectionField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreConnectionField_remoteOptions.graphql";

export const remoteOptionsEntreConnectionFieldId = () =>
  "remote-options-entre-connection";

function RemoteOptionsEntreConnectionField() {
  const [{ entreConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsEntreConnectionField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsEntreConnectionField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            entreConnection
            entreIpv6
            entreIpv6Address
            entreBackupIpv6Address
            entreIpAddress
            entreBackupIpAddress
          }
          ... on XfRemoteOptions {
            entreConnection
            entreIpAddress
            entreBackupIpAddress
          }
          ... on TMSentryRemoteOptions {
            entreConnection
            entreIpAddress
            entreBackupIpAddress
          }
        }
      `
    );

  const fieldId = remoteOptionsEntreConnectionFieldId();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Entré Connection">
      <Select
        id={fieldId}
        name={fieldId}
        value={entreConnection ?? undefined}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "entreConnection");
            if (target.value === RemoteOptionsConnectionType.NONE) {
              recordProxy.setValue(target.value, "entreBackupConnection");
              recordProxy.setValue("0.0.0.0", "entreIpAddress");
              recordProxy.setValue("0.0.0.0", "entreBackupIpAddress");
              recordProxy.setValue(0, "entreCheckInMinutes");
            }
            if (target.value !== RemoteOptionsConnectionType.NETWORK) {
              recordProxy.setValue(false, "entreIpv6");
              recordProxy.setValue(
                "0000:0000:0000:0000:0000:0000:0000:0000",
                "entreIpv6Address"
              );
              recordProxy.setValue(
                "0000:0000:0000:0000:0000:0000:0000:0000",
                "entreBackupIpv6Address"
              );
            }
          });
        }}
      >
        <Select.Option value={RemoteOptionsConnectionType.NONE}>
          None
        </Select.Option>
        <Select.Option value={RemoteOptionsConnectionType.NETWORK}>
          Network
        </Select.Option>
        {isXr ? (
          <Select.Option value={RemoteOptionsConnectionType.CELL}>
            Cell
          </Select.Option>
        ) : null}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreConnectionField;
