import React from "react";
import {
  useUserCanSelectIComLnc,
  useUserCanSelectMiniCellCom,
} from "../EntryPointContext";

interface SystemTypeSelectorProps extends React.HTMLProps<HTMLSelectElement> {
  systemReplacement?: string;
}

export default function SystemTypeSelector({
  systemReplacement,
  ...props
}: SystemTypeSelectorProps) {
  const canSelectIComLnc = useUserCanSelectIComLnc();
  const canSelectMiniCellCom = useUserCanSelectMiniCellCom();
  return (
    <select {...props}>
      <option value="" disabled>
        Select a {systemReplacement ?? "System"} Type
      </option>
      <option value="X001">X1</option>
      <option value="XF6_100">XF6-100</option>
      <option value="XF6_500">XF6-500</option>
      <option value="XTL">XTL Series</option>
      <option value="XTLP">XTLplus</option>
      <option value="XTLT">XTLtouch</option>
      <option value="XT30">XT30</option>
      <option value="XT50">XT50</option>
      <option value="XT75">XT75</option>
      <option value="XR500">XR Series (100/500)</option>
      <option value="XR150">XR150</option>
      <option value="XR550">XR550</option>
      <option value="TMS6">TMSentry</option>
      <option value="CellComSL">CellComSL</option>
      <option value="CellComEX">CellComEX</option>
      <option value="iComSL">iComSL</option>
      <option value="DualCom">DualCom</option>
      {canSelectIComLnc && <option value="iComLNC">iComLNC</option>}
      {canSelectMiniCellCom && <option value="MiniCellCom">MiniCellCom</option>}
      <option value="Video Only">Video Only</option>
    </select>
  );
}
