/**
 * @generated SignedSource<<26ca2c3193890cfda5c37c157dc86d38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsEntreArmDisarmReportsField_remoteOptions$data = {
  readonly entreArmDisarmReports?: boolean | null;
  readonly entreConnection?: RemoteOptionsConnectionType | null;
  readonly " $fragmentType": "RemoteOptionsEntreArmDisarmReportsField_remoteOptions";
};
export type RemoteOptionsEntreArmDisarmReportsField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsEntreArmDisarmReportsField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsEntreArmDisarmReportsField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entreArmDisarmReports",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entreConnection",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsEntreArmDisarmReportsField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "TMSentryRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "335aff7f10ca9d97f0e1e3b215fad3eb";

export default node;
