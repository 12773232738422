import { sleep } from "common/utils/universal/promise";
import { resetControlSystemIntegrations } from "components/StatusBadge";
import { handleSavingTags } from "components/TagField/TagField.tsx";

App.controller("CustomerEditCtrl", [
  "$rootScope",
  "$scope",
  "UserService",
  "Customer",
  "$state",
  "$filter",
  "customer_id",
  "dealer_id",
  "COUNTRY_CODES",
  "STATE_CODES",
  "RelayService",
  "LogoService",
  "CustomerKeypadCustomGraphicApi",
  "DealerKeypadCustomGraphicApi",
  "PricingAPI",
  "ODataService",
  "$q",
  "PROPS",
  "ControlSystemODataAPI",
  "$modal",
  function (
    $rootScope,
    $scope,
    UserService,
    Customer,
    $state,
    $filter,
    customer_id,
    dealer_id,
    COUNTRY_CODES,
    STATE_CODES,
    /** @type {import("../common/services/relay-service").RelayServiceType} */
    RelayService,
    LogoService,
    CustomerKeypadCustomGraphicApi,
    DealerKeypadCustomGraphicApi,
    PricingAPI,
    ODataService,
    $q,
    PROPS,
    ControlSystemODataAPI,
    $modal
  ) {
    // We're using this name because we're sharing a view (user-new.html) between new and edit, so the control alias
    // must be the same for both.
    var custedit = this; // ControllerAs

    $scope.UserService = UserService;

    custedit.COUNTRY_CODES = COUNTRY_CODES;
    custedit.STATE_CODES = STATE_CODES;

    if ($state.is("app.customers.customeredit")) {
      // Get the customer that matches the state variable
      custedit.customerId = customer_id;
      custedit.customer = new Customer(customer_id);
    } else {
      custedit.customer = new Customer(false, dealer_id);
    }

    //Begin number spinner logics
    $scope.isEditing = false;

    $scope.parsedCredentialLevel =
      parseInt(custedit.customer.mobile_wallet_credential_pool_level, 10) || 0;

    $scope.onBlur = function () {
      if ($scope.parsedCredentialLevel >= 5) {
        $scope.parsedCredentialLevel =
          Math.ceil($scope.parsedCredentialLevel / 5) * 5;
      } else {
        $scope.parsedCredentialLevel = 5;
      }

      custedit.customer.mobile_wallet_credential_pool_level =
        $scope.parsedCredentialLevel.toString();

      $scope.isEditing = false;
    };

    $scope.roundUpToNearestFive = function () {
      if ($scope.parsedCredentialLevel > 0) {
        $scope.parsedCredentialLevel =
          Math.ceil($scope.parsedCredentialLevel / 5) * 5;
      }

      $scope.parsedCredentialLevel = Math.min(
        1000,
        Math.max(0, $scope.parsedCredentialLevel)
      );

      custedit.customer.mobile_wallet_credential_pool_level =
        $scope.parsedCredentialLevel.toString();
    };

    $scope.increment = function () {
      $scope.parsedCredentialLevel += 5;
      $scope.roundUpToNearestFive();
    };

    $scope.decrement = function () {
      if ($scope.parsedCredentialLevel > 5) {
        $scope.parsedCredentialLevel = Math.max(
          5,
          $scope.parsedCredentialLevel - 5
        );
        custedit.customer.mobile_wallet_credential_pool_level =
          $scope.parsedCredentialLevel.toString();
      }
    };

    $scope.$watch(
      function () {
        return custedit.customer.mobile_wallet_credential_pool_level;
      },
      function (newValue, oldValue) {
        if (newValue !== oldValue) {
          $scope.parsedCredentialLevel = parseInt(newValue, 10) || 0;

          if ($scope.parsedCredentialLevel > 0) {
            $scope.roundUpToNearestFive();
          }
        }
      }
    );
    //End number spinner logics

    custedit.dealer_id = dealer_id;
    custedit.UserService = UserService;

    $scope.editCustomGraphic = editCustomGraphic;
    $scope.customGraphicEdited = false;
    $scope.allDbCustomGraphics = [{ Name: "None", FileName: "None" }];
    $scope.chosenGraphic;
    $scope.dealerGraphic;
    $scope.allSystemsHaveStoreUserCodes = false;
    $scope.noSystems = true;

    const systemReplacement =
      UserService.dealerInfo.vernaculars.systems.replacement || "system";

    $scope.billingSystemPlaceHolder = `Select which ${systemReplacement} to bill`;

    ControlSystemODataAPI.getPanelsForMultiPanelUser(
      {
        customer_id: customer_id,
        $select: "id, name",
        $expand: "services_managers($select=store_user_codes)",
      },
      function (data) {
        $scope.customerSystemsArray = data.value.flatMap((system) => system);
        $scope.allSystemsHaveStoreUserCodes = $scope.customerSystemsArray.every(
          ({ services_managers }) => services_managers[0].store_user_codes
        );
        $scope.noSystems = $scope.customerSystemsArray.length === 0;
      },
      function (error) {}
    );

    PricingAPI.getPricing(
      { dealer_id: UserService.dealer_id },
      function (pricingInfo) {
        $scope.pricing = angular.copy(pricingInfo);
        $scope.getSelectedServices();
      },
      function (error) {}
    );

    $scope.walletCredentialPricing = function (pricing) {
      return Number(pricing?.Wavelynx) * Number($scope.parsedCredentialLevel);
    };

    //Gets all the custom graphics in the database
    LogoService.getAllDbCustomGraphics()
      .then((data) =>
        data?.forEach((graphic) =>
          $scope.allDbCustomGraphics.push(graphic.CustomKeypadGraphics)
        )
      )
      .catch((error) => console.error(error));

    //Gets the custom graphic associated with the customer, if there is one
    if (
      UserService.canViewCustomBranding() ||
      UserService.isTechSupport() ||
      UserService.isAdmin()
    ) {
      CustomerKeypadCustomGraphicApi.getAssociatedCustomGraphic(
        {
          customer_id: customer_id,
        },
        function (data) {
          LogoService.getCustomGraphicById(data.CustomKeypadGraphicsId)
            .then((data) => {
              $scope.chosenGraphic = data.CustomKeypadGraphics.Name;
            })
            .catch((error) => {
              console.error(error);
            });
        },
        function (error) {
          if (error.status === 404) {
            $scope.chosenGraphic = $scope.allDbCustomGraphics[0].Name;
          } else {
            console.error(error);
          }
        }
      );

      //Gets the custom graphic associated with the dealer, if there is one
      DealerKeypadCustomGraphicApi.getAssociatedCustomGraphic(
        {
          dealer_id: dealer_id,
        },
        function (data) {
          LogoService.getCustomGraphicById(data.CustomKeypadGraphicsId)
            .then((data) => {
              $scope.dealerGraphic = data.CustomKeypadGraphics.Name;
            })
            .catch((error) => {
              console.error(error);
            });
        },
        function (error) {
          if (error.status === 404) {
            $scope.dealerGraphic = $scope.allDbCustomGraphics[0].Name;
          } else {
            console.error(error);
          }
        }
      );
    }

    function editCustomGraphic(model) {
      $scope.chosenGraphic = model;
      $scope.customGraphicEdited = true;
    }

    $scope.clearZipAndState = function () {
      custedit.customer.state_province = null;
      custedit.customer.postal_code = null;
    };

    $scope.setWavelynx = function (enabled) {
      const customer = custedit.customer;
      if (enabled === false) {
        $scope.parsedCredentialLevel = 0;
      } else {
        $scope.parsedCredentialLevel =
          customer.mobile_wallet_credential_pool_level = 5; //Defaulting both to 5
      }
      customer.mobile_wallet_credential_pool_enabled = enabled;
      if (!customer.mobile_wallet_credential_pool_enabled) {
        customer.billing_control_system_id = null;
      }
    };

    /**
     * Creates and opens the mobileCredentialEnableModal
     */
    $scope.openMobileCredentialEnableModal = function () {
      var openMobileCredentialsEnableModal = $modal.open({
        templateUrl: "app/common/templates/mobile-credential-enable-modal.html",
        size: "md",
        controller: "MobileCredentialEnableModalCtrl",
        backdrop: "static",
        keyboard: false,
        scope: $scope,
        resolve: {
          title: function () {
            return String(custedit.customer.name);
          },
          setWavelynx: function () {
            return $scope.setWavelynx;
          },
        },
      });
    };

    //This function will take the customer keypad custom graphic api resource and turn it into a promise
    //Depending on what you pass in to the isCreating parameter(which is a boolean) it will
    //either hit the create or delete endpoint
    function createGraphicAssociation(
      customer_id,
      matchingGraphicId,
      isCreating
    ) {
      var deferred = $q.defer();
      if (isCreating) {
        CustomerKeypadCustomGraphicApi.createCustomGraphicAssociation(
          {
            customer_id: customer_id,
          },
          {
            CustomKeypadGraphicsId: matchingGraphicId,
            AssociationId: customer_id,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: $filter("translate")("api.CUSTOMER_SAVE_FAILED"),
              json: "Error updating custom graphic.",
            });

            deferred.reject(error);
          }
        );
      } else {
        CustomerKeypadCustomGraphicApi.deleteCustomGraphicAssociation(
          {
            customer_id: customer_id,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: $filter("translate")("api.CUSTOMER_SAVE_FAILED"),
              json: "Error updating custom graphic.",
            });
            deferred.reject(error);
          }
        );
      }
      return deferred.promise;
    }

    async function watchAndGo(stateDotGoFunction) {
      (async () => {
        while ($rootScope.savingTags) {
          await sleep(500);
        }
      })().then(() => {
        stateDotGoFunction();
      });
    }

    custedit.saveCustomer = function () {
      var action;
      if ($state.is("app.customers.customeredit")) {
        //if the custom graphic has been edited
        if (UserService.isAdmin() || UserService.canViewCustomBranding()) {
          if ($scope.customGraphicEdited) {
            const name = $scope.chosenGraphic;
            const matchingGraphic = $scope.allDbCustomGraphics.find(
              (graphic) => graphic.Name === name
            );

            const promiseArray = [
              [
                createGraphicAssociation(
                  customer_id,
                  matchingGraphic.Id,
                  name !== "None"
                ),
              ],
            ];

            if (UserService.isAdmin()) {
              //Only Supervisor admins can edit the customer AND the custom branding
              promiseArray.push(custedit.customer.update());
            }
            //This is basically a Promise.all()
            //It will hit each call in the given array and return a promise for each one
            $q.all(promiseArray)
              .then(function (response) {
                const graphicResponse = response[0][0];
                const customerUpdateResponse = response[1];

                graphicResponse.then((graphicResponseData) => {
                  if (graphicResponseData.$resolved) {
                    if (
                      promiseArray.length > 1 &&
                      !customerUpdateResponse.$resolved
                    ) {
                      $rootScope.alerts.push({
                        type: "error",
                        text: $filter("translate")("api.CUSTOMER_SAVE_FAILED"),
                        json: "Custom Branding edited, however editing customer errored.",
                      });
                    } else {
                      $rootScope.alerts.push({
                        type: "success",
                        text: $filter("translate")(
                          "api.CUSTOMER_SUCCESSFULLY_SAVED"
                        ),
                      });
                      resetControlSystemIntegrations(
                        RelayService.getEnvironment()
                      );
                      watchAndGo(() => {
                        return $state.go("app.customers.customersummary", {
                          dealer_id: dealer_id,
                          customer_id: customer_id,
                        });
                      });
                    }
                  } else {
                    $rootScope.alerts.push({
                      type: "error",
                      text: $filter("translate")("api.CUSTOMER_SAVE_FAILED"),
                      json: "Error updating custom graphic.",
                    });
                  }
                });
              })
              .catch(function (error) {
                console.error(error);
              });
            $scope.customGraphicEdited = false;
          } else {
            custedit.customer
              .update()
              .then(
                function (data) {
                  $rootScope.alerts.push({
                    type: "success",
                    text: $filter("translate")(
                      "api.CUSTOMER_SUCCESSFULLY_SAVED"
                    ),
                  });
                  resetControlSystemIntegrations(RelayService.getEnvironment());
                  watchAndGo(() => {
                    return $state.go("app.customers.customersummary", {
                      dealer_id: dealer_id,
                      customer_id: data.customer.id,
                    });
                  });
                },
                function (error) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: $filter("translate")("api.CUSTOMER_SAVE_FAILED"),
                    json: error,
                  });
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
          return;
        } else {
          action = custedit.customer.update();
        }
      } else {
        action = custedit.customer.create();
      }
      action
        .then(
          function (data) {
            $rootScope.savingTags = true;
            handleSavingTags($rootScope.savingTags, data.customer.id);
            $rootScope.alerts.push({
              type: "success",
              text: $filter("translate")("api.CUSTOMER_SUCCESSFULLY_SAVED"),
            });
            resetControlSystemIntegrations(RelayService.getEnvironment());
            watchAndGo(() => {
              return $state.go("app.customers.customersummary", {
                dealer_id: dealer_id,
                customer_id: data.customer.id,
              });
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: $filter("translate")("api.CUSTOMER_SAVE_FAILED"),
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
  },
]);

App.controller("MobileCredentialEnableModalCtrl", [
  "$scope",
  "title",
  "$modalInstance",
  "setWavelynx",

  function ($scope, title, $modalInstance, setWavelynx) {
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    $scope.title = title;
    $scope.setWavelynx = setWavelynx;
  },
]);
