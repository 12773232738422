import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationLocationField_zone$key } from "./__generated__/ZoneInformationLocationField_zone.graphql";

export const zoneInformationLocationFieldId = (number: string) =>
  `zone-information-location-${number}`;

function ZoneInformationLocationField() {
  const [{ number, location, isECP }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationLocationField_zone$key>(
      graphql`
        fragment ZoneInformationLocationField_zone on Zone {
          number
          location
          isECP
        }
      `
    );

  const fieldId = zoneInformationLocationFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const { LOCATION: RULES } = resolveZoneFieldRules(hardwareModel);
  const tooltip =
    "Enter in the Zone Location name that will be sent to the monitoring center in addition to the zone name. Zone Location should be descriptive for the monitoring center to relay to responding authorities.";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Zone Location"
      tooltip={tooltip}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        maxLength={RULES.MAX_LENGTH}
        value={location ?? ""}
        pattern={RULES.PATTERN}
        title={RULES.VALIDATION_MSG}
        disabled={isECP}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "location");
          });
        }}
        onBlur={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value.trim().toUpperCase(), "location");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationLocationField;
