import graphql from "babel-plugin-relay/macro";
import FormGridItems from "components/FormGridItems";
import * as React from "react";
import { useFragment } from "react-relay/hooks";
import Sections, { SectionHeader } from "../Layout/Layout";
import { ExistingCardFormatModal, NewCardFormatModal } from "./CardFormatForm";
import { CardFormatFormExistingModal_cardFormat$key } from "./__generated__/CardFormatFormExistingModal_cardFormat.graphql";
import { SiteCardFormatsSection_site$key } from "./__generated__/SiteCardFormatsSection_site.graphql";

type Props = {
  siteRef: SiteCardFormatsSection_site$key;
};

function SiteCardFormatsSection({ siteRef }: Props) {
  const data = useFragment(
    graphql`
      fragment SiteCardFormatsSection_site on Site {
        ...CardFormatFormAddModal_site
        ...CardFormatFormExistingModal_site
        cardFormats {
          ...CardFormatFormExistingModal_cardFormat
          id
          name
          wiegandLength
        }
        customer {
          dealer {
            vernaculars {
              original
              replacement
            }
          }
        }
      }
    `,
    siteRef
  );
  const systemReplacement = data.customer?.dealer?.vernaculars?.find(
    (v) => v?.original === "systems"
  )?.replacement;

  const [adding, setAdding] = React.useState(false);
  const [selectedCardFormatRef, setSelectedCardFormatRef] =
    React.useState<CardFormatFormExistingModal_cardFormat$key | null>(null);
  return (
    <>
      <Sections.Section>
        <SectionHeader>
          <SectionHeader.Title>Card Formats</SectionHeader.Title>
          {data.cardFormats?.length < 8 && (
            <SectionHeader.Add
              onClick={() => {
                setAdding(true);
              }}
            />
          )}
        </SectionHeader>

        <FormGridItems>
          {data.cardFormats?.map((cardFormat) => (
            <FormGridItems.Item key={cardFormat.id}>
              <FormGridItems.ClickableContent
                onClick={() => {
                  setSelectedCardFormatRef(cardFormat);
                }}
              >
                <FormGridItems.ItemTitle>
                  {cardFormat.name}
                </FormGridItems.ItemTitle>
                <FormGridItems.ItemSubtitle>
                  Wiegand Length: {cardFormat.wiegandLength}
                </FormGridItems.ItemSubtitle>
              </FormGridItems.ClickableContent>
            </FormGridItems.Item>
          ))}
        </FormGridItems>
      </Sections.Section>
      {selectedCardFormatRef && (
        <ExistingCardFormatModal
          siteRef={data}
          cardFormatRef={selectedCardFormatRef}
          onCancel={() => {
            setSelectedCardFormatRef(null);
          }}
          onSaved={() => {
            setSelectedCardFormatRef(null);
          }}
          onDeleted={() => {
            setSelectedCardFormatRef(null);
          }}
          systemReplacement={systemReplacement}
        />
      )}
      {adding && (
        <NewCardFormatModal
          siteRef={data}
          onCancel={() => {
            setAdding(false);
          }}
          onSaved={() => {
            setAdding(false);
          }}
          systemReplacement={systemReplacement}
        />
      )}
    </>
  );
}

export default SiteCardFormatsSection;
