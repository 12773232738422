/**
 * @generated SignedSource<<0a4572db10b0161f874fa1043a65a1c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsEntreIncomingTcpPortField_remoteOptions$data = {
  readonly entreConnection?: RemoteOptionsConnectionType | null;
  readonly entreIncomingPort?: string | null;
  readonly " $fragmentType": "RemoteOptionsEntreIncomingTcpPortField_remoteOptions";
};
export type RemoteOptionsEntreIncomingTcpPortField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsEntreIncomingTcpPortField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsEntreIncomingTcpPortField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entreIncomingPort",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entreConnection",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsEntreIncomingTcpPortField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "TMSentryRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "dd293616fda390e2847f2764cb87d92f";

export default node;
