import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorConnectionField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorConnectionField_remoteOptions.graphql";

export const remoteOptionsIntegratorConnectionFieldId = () =>
  "remote-options-integrator-connection";

function RemoteOptionsIntegratorConnectionField() {
  const [{ integratorConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsIntegratorConnectionField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsIntegratorConnectionField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            integratorConnection
            integratorBackupConnection
            integratorIpAddress
            integratorBackupIpAddress
          }
          ... on Xt75RemoteOptions {
            integratorConnection
            integratorBackupConnection
            integratorIpAddress
            integratorBackupIpAddress
          }
          ... on XfRemoteOptions {
            integratorConnection
            integratorBackupConnection
            integratorIpAddress
            integratorBackupIpAddress
          }
        }
      `
    );

  const fieldId = remoteOptionsIntegratorConnectionFieldId();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Integrator Connection"
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={integratorConnection ?? undefined}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "integratorConnection");
            if (target.value === RemoteOptionsConnectionType.NONE) {
              recordProxy.setValue(target.value, "integratorBackupConnection");
              recordProxy.setValue("0.0.0.0", "integratorIpAddress");
              recordProxy.setValue("0.0.0.0", "integratorBackupIpAddress");
            }
          });
        }}
      >
        <Select.Option value={RemoteOptionsConnectionType.NONE}>
          None
        </Select.Option>
        <Select.Option value={RemoteOptionsConnectionType.NETWORK}>
          Network
        </Select.Option>
        {isXr ? (
          <Select.Option value={RemoteOptionsConnectionType.CELL}>
            Cell
          </Select.Option>
        ) : null}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorConnectionField;
