import { BORDER_RADIUS } from "common/constants";
import { media } from "common/utils/styles";
import styled, { css } from "styled-components";
import {
  defaultThemeBoxShadow,
  themeGrayXlight,
  themePanelBorder,
  themeTextColor,
} from ".././Theme";
import NakedButton from "../NakedButton";

const BasicArmingAreaButton = styled(NakedButton)<{
  burgAlarm?: boolean;
  armed?: boolean;
  arming?: boolean;
  disarming?: boolean;
}>`
  position: relative;
  height: 100%;
  width: 100%;
  border: ${themePanelBorder};
  background: ${({ burgAlarm, theme }) =>
    burgAlarm ? theme.trueWhite : theme.grayXlight};
  border-color: ${({ burgAlarm, theme }) =>
    burgAlarm ? theme.danger : theme.primary500};
  border-radius: ${BORDER_RADIUS};
  color: ${themeTextColor};
  font-size: 18px;
  text-align: center;
  transition: 0.2s transform, 0.25s box-shadow, 0.25s border-color;

  &[disabled] {
    opacity: 0.3;
  }

  &:not([disabled]) {
    box-shadow: ${({ theme }) => theme.grayAccent};

    ${media.medium`
      &:hover {
        box-shadow: ${defaultThemeBoxShadow};
        transition: 0.05s transform, 0.25s box-shadow, 0.25s border-color;
        transform: scale(1.05);
        z-index: 1;
      }

      &:active {
        transform: scale(1.075);
      }
    `};
  }

  ${({ armed, disarming, burgAlarm, theme }) =>
    (armed || disarming) &&
    css`
      color: ${() => (burgAlarm ? theme.danger : theme.primary)};

      ${media.medium`
      &:not([disabled]) {
        &:hover {
          transform: scale(1.025);
          transition: 0.05s transform, 0.25s box-shadow, 0.25s border-color;
        }

        &:active {
          transform: scale(1.04);
        }
      }
    `};
    `}
  ${({ arming, disarming, burgAlarm }) =>
    (arming || disarming) &&
    css`
      background: ${themeGrayXlight};
      border-color: ${({ theme }) =>
        burgAlarm ? theme.danger : theme.buttonPrimaryColor};
      z-index: 1;
    `};
`;

export default BasicArmingAreaButton;
