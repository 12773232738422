/**
 * @generated SignedSource<<fa3a7aa06fcc32732ff2bc2967686da9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsIntegratorPassphraseField_remoteOptions$data = {
  readonly integratorConnection?: RemoteOptionsConnectionType | null;
  readonly integratorPassphrase?: string | null;
  readonly " $fragmentType": "RemoteOptionsIntegratorPassphraseField_remoteOptions";
};
export type RemoteOptionsIntegratorPassphraseField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsIntegratorPassphraseField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsIntegratorPassphraseField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorPassphrase",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorConnection",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsIntegratorPassphraseField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75RemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "8d05f0f9c8d06421969b41c45fcaab51";

export default node;
