import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorPassphraseField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorPassphraseField_remoteOptions.graphql";

export const remoteOptionsIntegratorPassphraseFieldId = () =>
  "remote-options-integrator-passphrase";

function RemoteOptionsIntegratorPassphraseField() {
  const [{ integratorPassphrase, integratorConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsIntegratorPassphraseField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsIntegratorPassphraseField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            integratorPassphrase
            integratorConnection
          }
          ... on Xt75RemoteOptions {
            integratorPassphrase
            integratorConnection
          }
          ... on XfRemoteOptions {
            integratorPassphrase
            integratorConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsIntegratorPassphraseFieldId();
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Integrator Passphrase"
      tooltip="Enter a passphrase to encrypt communications with the Integrator."
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={integratorPassphrase ?? ""}
        maxLength={16}
        pattern={"([A-Z0-9 .*#\\-]{8,16}|)"}
        inlineHelp="Accepts 8 to 16 characters: A-Z 0-9 .*#-"
        validationMessage="Accepts 8 to 16 characters: A-Z 0-9 .*#-"
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "integratorPassphrase")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorPassphraseField;
