import graphql from "babel-plugin-relay/macro";
import NoWrap from "common/components/web/NoWrap";
import Alert from "components/Alert";
import Modal from "components/Modal";
import * as React from "react";
import { useFragment, useMutation } from "react-relay/hooks";
import { ControlSystemCommType, ErrorType } from "securecom-graphql/client";
import { validSerialRegex } from "utils/control-systems";
import { useCreateNotification } from "../EntryPointContext";
import {
  Checkbox,
  FieldRow,
  InlineFieldInputContainer,
  InlineFieldLabel,
  OffsetFieldRow,
} from "../FormFields";
import { AddButton } from "../FormFields/SaveButton";
import Spacer from "../Layout/Spacer";
import { SavingState } from "../types";
import styles from "./SiteControlSystemsForm.module.css";
import { NewSiteControlSystemFormSaveMutation } from "./__generated__/NewSiteControlSystemFormSaveMutation.graphql";
import { NewSiteControlSystemForm_site$key } from "./__generated__/NewSiteControlSystemForm_site.graphql";

const saveControlSystemMutation = graphql`
  mutation NewSiteControlSystemFormSaveMutation(
    $siteId: ID!
    $serialNumber: String!
    $controlSystemType: SiteControlSystemType!
    $addAsPreProgramming: Boolean
    $commType: ControlSystemCommType
  ) {
    addSiteControlSystem(
      siteId: $siteId
      serialNumber: $serialNumber
      controlSystemType: $controlSystemType
      addAsPreProgramming: $addAsPreProgramming
      commType: $commType
    ) {
      ... on NotFoundError {
        errorType: type
      }
      ... on FailedToAddControlSystemError {
        errorType: type
      }
      ... on ControlSystemHasTooManyDoorsError {
        errorType: type
      }
      ... on DuplicateSerialNumberError {
        errorType: type
      }
      ... on SerialNumberNotFoundError {
        errorType: type
      }
      ... on PanelNotCheckedInError {
        errorType: type
      }
      ... on InitialConnectionFailure {
        errorType: type
        site {
          id
          ...SiteControlSystemsSection_site
          ...SiteElevatorSystemsSection_site
          ...SiteOutputModulesSection_site
        }
        controlSystem {
          id
          doors(includeUnprogrammedDoors: true) {
            id
          }
          ...ExistingSiteControlSystemForm_siteControlSystem
        }
      }
      ... on AddSiteControlSystemSuccessResponse {
        site {
          id
          ...SiteControlSystemsSection_site
          ...SiteElevatorSystemsSection_site
          ...SiteOutputModulesSection_site
        }
        successControlSystem: controlSystem {
          id
          doors(includeUnprogrammedDoors: true) {
            id
          }
          serialNumber
          ...ExistingSiteControlSystemForm_siteControlSystem
        }
      }
    }
  }
`;

export default function NewSiteControlSystemForm({
  siteRef,
  onSaved,
  onCancel,
}: {
  siteRef: NewSiteControlSystemForm_site$key;
  onSaved: (systemId: string, firstDoorId: string) => void;
  onCancel: () => void;
}) {
  const data = useFragment(
    graphql`
      fragment NewSiteControlSystemForm_site on Site {
        id
        controlSystems {
          id
          serialNumber
        }
        customer {
          id
          dealer {
            vernaculars {
              original
              replacement
            }
          }
        }
      }
    `,
    siteRef
  );

  const systemReplacement = data.customer.dealer?.vernaculars?.find(
    (v) => v?.original === "systems"
  )?.replacement;

  // If an X1 is added in the middle of a firmware update for the site
  // there is a possibility of receiving data from the subscription that
  // includes the new X1 being added before DA is able to transition
  // the modal away. This causes the duplicate serial # warning to show up.
  // Capturing the data in a ref allows us to check for a duplicate serial #
  // using only the original control systems we received.
  const originalData = React.useRef(data).current;

  const [addControlSystem, addingControlSystem] =
    useMutation<NewSiteControlSystemFormSaveMutation>(
      saveControlSystemMutation
    );

  const serialNumberRef = React.useRef<HTMLInputElement | null>(null);
  const [serialNumber, setSerialNumber] = React.useState("");
  const [preProgram, setPreProgram] = React.useState(false);
  const [connectionType, setConnectionType] = React.useState(
    ControlSystemCommType.PERSISTENT
  );
  const [saveError, setSaveError] = React.useState<React.ReactNode | null>(
    null
  );
  const [fieldErrors, setFieldErrors] = React.useState({
    serialNumber: "",
  });

  const serialNumberIsUnique = (value: string) =>
    originalData.controlSystems.every(
      (system) => system.serialNumber.toUpperCase() !== value
    );

  const validate = (field: string, value: string) => {
    switch (field) {
      case "serialNumber":
        setFieldErrors({
          serialNumber: !value
            ? "This field is required."
            : value.length === 8 && !value.match(validSerialRegex)
            ? "Enter a valid 8-digit serial number (0-9, A-F)"
            : !serialNumberIsUnique(value)
            ? `Serial number "${value}" is already in use by another door on this ${
                systemReplacement ?? "system"
              }.`
            : "",
        });
        break;
    }
  };

  const canAdd = !addingControlSystem && !fieldErrors.serialNumber;

  const createNotification = useCreateNotification();

  React.useEffect(() => {
    if (serialNumberRef.current) {
      serialNumberRef.current.focus();
    }
  }, []);

  return (
    <Modal>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (canAdd) {
            setSaveError(null);
            addControlSystem({
              variables: {
                siteId: data.id,
                serialNumber,
                controlSystemType: "DOOR_ACCESS",
                addAsPreProgramming: preProgram,
                commType: connectionType,
              },
              onCompleted: ({ addSiteControlSystem }) => {
                const controlSystem =
                  addSiteControlSystem.controlSystem ??
                  addSiteControlSystem.successControlSystem;
                if (controlSystem) {
                  const { id, doors } = controlSystem;

                  onSaved(id, doors[0].id);
                  createNotification({
                    type: "success",
                    text: `${systemReplacement ?? "System"} added.`,
                  });
                } else {
                  setSaveError(() => {
                    switch (addSiteControlSystem.errorType) {
                      case ErrorType.NOT_FOUND:
                        return `Unable to find ${
                          systemReplacement ?? "system"
                        }.`;
                      case ErrorType.SERIAL_NUMBER_NOT_FOUND:
                        return "Serial number not found.";
                      case ErrorType.PANEL_NOT_CHECKED_IN:
                        return "The device appears to be offline.";
                      case ErrorType.CONTROL_SYSTEM_HAS_TOO_MANY_DOORS:
                        return `Unable to add door, maximum doors reached for this ${
                          systemReplacement ?? "system"
                        }.`;
                      case ErrorType.DUPLICATE_SERIAL_NUMBER:
                        return "Unable to add door, duplicate serial number.";
                      default:
                        return `Unable to add door to ${
                          systemReplacement ?? "system"
                        }.`;
                    }
                  });
                }
              },
              onError: () => {
                setSaveError(
                  `Unable to add door to ${systemReplacement ?? "system"}.`
                );
              },
            });
          }
        }}
      >
        <Modal.Header>
          <h3 className="mar-0">Add Door</h3>
        </Modal.Header>
        <Modal.Body>
          <FieldRow
            rowStyle="full-width"
            className="required"
            error={!!fieldErrors.serialNumber}
          >
            <InlineFieldLabel htmlFor="serialNumber">
              <NoWrap>Serial Number</NoWrap>
            </InlineFieldLabel>
            <InlineFieldInputContainer>
              <input
                ref={serialNumberRef}
                type="text"
                className="form-control"
                id="serialNumber"
                name="serialNumber"
                placeholder="E.G.: 00000000"
                pattern="[0-9A-F]{8}"
                maxLength={8}
                value={serialNumber}
                required
                disabled={addingControlSystem}
                onChange={({ target }) => {
                  const value = target.value.toUpperCase();
                  setSerialNumber(value);
                  validate(target.id, value);
                }}
              />
              {!!fieldErrors.serialNumber ? (
                <span>{fieldErrors.serialNumber}</span>
              ) : null}
            </InlineFieldInputContainer>
          </FieldRow>
          <OffsetFieldRow>
            <Checkbox
              checked={preProgram}
              disabled={addingControlSystem}
              onChange={() => {
                setPreProgram(!preProgram);
              }}
              label="Pre-Program X1"
              infoText="Allows this X1 to be configured prior to installation and automatically programmed when it comes online."
            />
          </OffsetFieldRow>
          {preProgram ? (
            <FieldRow rowStyle="full-width" className="required">
              <InlineFieldLabel htmlFor="connectionType">
                <NoWrap>Connection Type</NoWrap>
              </InlineFieldLabel>
              <InlineFieldInputContainer>
                <select
                  name="connectionType"
                  id="connectionType"
                  className="form-control"
                  value={connectionType}
                  disabled={addingControlSystem}
                  onChange={({ target }) => {
                    setConnectionType(target.value as ControlSystemCommType);
                  }}
                >
                  <option value={ControlSystemCommType.PERSISTENT}>
                    EASYconnect
                  </option>
                  <option value={ControlSystemCommType.CELL}>Cell</option>
                  <option
                    value={ControlSystemCommType.PERSISTENT_WITH_CELL_BACKUP}
                  >
                    EASYconnect + Cell Backup
                  </option>
                </select>
              </InlineFieldInputContainer>
            </FieldRow>
          ) : null}
          {saveError ? (
            <div className={styles["form-error"]}>
              <Alert type="error">{saveError}</Alert>
            </div>
          ) : null}
        </Modal.Body>
        <Spacer />
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-default"
            disabled={addingControlSystem}
            onClick={onCancel}
          >
            Cancel
          </button>
          <AddButton
            type="submit"
            savingState={
              addingControlSystem ? SavingState.Saving : SavingState.Idle
            }
            disabled={!canAdd || serialNumber.length !== 8}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
}
