App.factory("SplitSessionAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v4/users/:user_id/session",
      { user_id: "@user_id" }, // Define `user_id` here instead of in params
      {
        refresh: {
          method: "POST",
          isArray: false,
          headers: {
            "Accessible-Type": PROPS.authAccessTypeKey,
          },
         
          // Use a request function to dynamically add the Authorization header
          transformRequest: function (data, headersGetter) {
            const headers = headersGetter();
            // Add the dynamic token here, passing it into the `refresh` method
            headers["Authorization"] = "Bearer " + data.BearerToken;
            return JSON.stringify(data);
          },
        },
      }
    );
  },
 ]);