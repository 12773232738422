//RecorderCameraDetectionRegionSettings
import { between } from "app/common/services/util";
import graphql from "babel-plugin-relay/macro";
import Flex from "common/components/web/Flex";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setAdd, setDifference, setFirst } from "common/utils/universal/set";
import {
  FieldLabelCell,
  FieldLabelContainer,
  FieldLabelText,
  FieldValueCell,
  RegionSettingsDeleteButton,
  RegionSettingsHeaderText,
  RegionsSettingsRoot,
  RegionsSettingsRow,
  RegionsSettingsTable,
} from "components/CameraEditCommon/CameraEditStyledComponents";
import { Tooltip } from "components/DaStyledElements";
import Switch from "components/FullProgramming/common/Switch";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import Icon from "components/Icon";
import TextInput from "components/Inputs/TextInput";
import Select from "components/Select";

import { MessagePosition } from "common/components/web/Tooltip";
import useEffectOnce from "common/hooks/useEffectOnce";
import InlineTooltip from "components/InlineTooltip";
import { range } from "ramda";
import React, { FC, useEffect } from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { fetchQuery, RecordProxy } from "relay-runtime";
import {
  Area,
  DirectionOfTravel as directionOfTravel,
  idAsString,
  toZoneId,
  VarHubCameraDetectionRegion,
  Zone,
} from "securecom-graphql/client";
import AddButton from "../CameraEditCommon/AddButton";
import {} from "./arrow/DirectionOfTravelArrows";
import { removeRegionUpdater } from "./utils";
import { RecorderCameraDetectionRegionSettingsZonesQuery } from "./__generated__/RecorderCameraDetectionRegionSettingsZonesQuery.graphql";
import { RecorderCameraDetectionRegionSettings_panel$key } from "./__generated__/RecorderCameraDetectionRegionSettings_panel.graphql";
import { RecorderCameraDetectionRegionSettings_varHub$key } from "./__generated__/RecorderCameraDetectionRegionSettings_varHub.graphql";
import { RecorderCameraDetectionRegionSettings_varHubCamera$key } from "./__generated__/RecorderCameraDetectionRegionSettings_varHubCamera.graphql";
import { RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion$key } from "./__generated__/RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion.graphql";

const CAMERA_FRAGMENT = graphql`
  fragment RecorderCameraDetectionRegionSettings_varHubCamera on VarConnectedCamera {
    id
    cameraName
    detectionRegions {
      id
      slotNumber
      zone {
        number
      }
    }
  }
`;

const REGION_FRAGMENT = graphql`
  fragment RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion on VarHubCameraDetectionRegion {
    id
    name
    detectPeople
    detectAnimals
    detectVehicles
    drawRegionOnAlerts
    index
    isNew
    loiteringPersonSeconds
    loiteringVehicleSeconds
    movementDirection
    slotNumber
    zone {
      id
      name
      number
      isNew
      area {
        id
      }
    }
  }
`;

const PANEL_FRAGMENT = graphql`
  fragment RecorderCameraDetectionRegionSettings_panel on Panel {
    hardwareModel
    softwareVersion
    areas(first: 32, sort: { keys: ["number"], order: ASC }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const VARHUB_FRAGMENT = graphql`
  fragment RecorderCameraDetectionRegionSettings_varHub on VarHub {
    id
    panelConnectionEnabled
  }
`;

interface RecorderCameraDetectionRegionSettingsProps {
  detectionRegion: RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion$key;
  panel: RecorderCameraDetectionRegionSettings_panel$key;
  camera: RecorderCameraDetectionRegionSettings_varHubCamera$key;
  varHub: RecorderCameraDetectionRegionSettings_varHub$key | null;
  index: number;
  isEditable: boolean;
  globalSystemId: string;
  systemId: string;
  setRemovedDetectionRegionIds: React.Dispatch<
    React.SetStateAction<Set<string>>
  >;
  regionZoneNumbers: number[];
}

const RecorderCameraDetectionRegionSettings: FC<RecorderCameraDetectionRegionSettingsProps> =
  ({
    detectionRegion,
    panel,
    camera,
    varHub,
    index,
    isEditable,
    globalSystemId,
    systemId,
    setRemovedDetectionRegionIds,
    regionZoneNumbers,
  }) => {
    const cameraData = useFragment(CAMERA_FRAGMENT, camera);
    const regionData = useFragment(REGION_FRAGMENT, detectionRegion);
    const panelData = useFragment(PANEL_FRAGMENT, panel);
    const varHubData = useFragment(VARHUB_FRAGMENT, varHub);
    const relayEnv = useRelayEnvironment();
    //State Initialization
    const [fetchingZoneNumber, setFetchingZoneNumber] = React.useState(false);
    const [zoneAvailable, setZoneAvailable] = React.useState(true);
    const [takenZoneNumbersList, setTakenZoneNumbersList] = React.useState<
      Set<number>
    >(new Set() as Set<number>);
    const [possibleZoneNumbersList, setPossibleZoneNumbersList] =
      React.useState<Set<number>>(new Set() as Set<number>);
    const [axZoneNumbersList, setAxZoneNumbersList] = React.useState<
      Set<number>
    >(new Set() as Set<number>);
    const [zoneIsDuplicated, setZoneIsDuplicated] = React.useState(false);
    const [zoneIsOutOfRange, setZoneIsOutOfRange] = React.useState(false);
    const [zoneIsInAxRange, setZoneIsInAxRange] = React.useState(false);

    // Helper to initialize loitering-related states
    const initializeLoiteringStates = (loiteringSeconds: number | null) => {
      const minutes = loiteringSeconds
        ? Math.floor(loiteringSeconds / 60)
        : null;
      const seconds = loiteringSeconds ? loiteringSeconds % 60 : null;
      return { minutes, seconds };
    };

    // Use extracted helper for initialization
    const initialLoiteringStates = initializeLoiteringStates(
      regionData.loiteringPersonSeconds
    );
    const initialVehicleLoiteringStates = initializeLoiteringStates(
      regionData.loiteringVehicleSeconds
    );
    const [detectPersonLoitering, setDetectPersonLoitering] =
      React.useState(false);
    const [loiteringMinutes, setLoiteringMinutes] = React.useState<
      number | null
    >(initialLoiteringStates.minutes);
    const [loiteringSeconds, setLoiteringSeconds] = React.useState<
      number | null
    >(initialLoiteringStates.seconds);
    const [detectVehicleLoitering, setDetectVehicleLoitering] =
      React.useState(false);
    const [loiteringVehicleMinutes, setLoiteringVehicleMinutes] =
      React.useState<number | null>(initialVehicleLoiteringStates.minutes);
    const [loiteringVehicleSeconds, setLoiteringVehicleSeconds] =
      React.useState<number | null>(initialVehicleLoiteringStates.seconds);

    const [movementDirection, setMovementDirection] =
      React.useState<directionOfTravel>(directionOfTravel.OFF);

    const handleMinutesChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = parseInt(event.target.value);
      if (!isNaN(value)) {
        setLoiteringMinutes(value);
      } else {
        setLoiteringMinutes(null);
      }
    };

    const handleSecondsChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = parseInt(event.target.value);
      if (!isNaN(value)) {
        setLoiteringSeconds(value);
      } else {
        setLoiteringSeconds(null);
      }
    };

    const handleVehicleMinutesChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = parseInt(event.target.value);
      if (!isNaN(value)) {
        setLoiteringVehicleMinutes(value);
      } else {
        setLoiteringVehicleMinutes(null);
      }
    };

    const handleVehicleSecondsChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = parseInt(event.target.value);
      if (!isNaN(value)) {
        setLoiteringVehicleSeconds(value);
      } else {
        setLoiteringVehicleSeconds(null);
      }
    };

    useEffectOnce(() => {
      if (regionData.loiteringVehicleSeconds !== 0) {
        setDetectVehicleLoitering(true);
      }
      if (regionData.loiteringPersonSeconds !== 0) {
        setDetectPersonLoitering(true);
      }
      if (!regionData.detectPeople) {
        setDetectPersonLoitering(false);
      }
    });

    useEffect(() => {
      const totalLoiteringSeconds =
        (loiteringMinutes ?? 0) * 60 + (loiteringSeconds ?? 0);
      relayEnv.commitUpdate((store) => {
        const region = store.get(
          regionData.id
        ) as RecordProxy<VarHubCameraDetectionRegion>;
        if (region) {
          region.setValue(totalLoiteringSeconds, "loiteringPersonSeconds");
        }
      });
    }, [loiteringMinutes, loiteringSeconds]);

    useEffect(() => {
      const totalVehicleLoiteringSeconds =
        (loiteringVehicleMinutes ?? 0) * 60 + (loiteringVehicleSeconds ?? 0);
      relayEnv.commitUpdate((store) => {
        const region = store.get(
          regionData.id
        ) as RecordProxy<VarHubCameraDetectionRegion>;
        if (region) {
          region.setValue(
            totalVehicleLoiteringSeconds,
            "loiteringVehicleSeconds"
          );
        }
      });
    }, [loiteringVehicleMinutes, loiteringVehicleSeconds]);

    React.useEffect(() => {
      if (!regionData.detectVehicles) {
        setDetectVehicleLoitering(false);
      }
    }, [regionData.detectVehicles]);

    const panelSupportsVarIntegration =
      (resolvePanelType(panelData.hardwareModel).isXr ||
        resolvePanelType(panelData.hardwareModel).isXt75) &&
      panelVersionGTOE(221, panelData.softwareVersion);

    const regionDetectionTypeNotSelected = !(
      regionData.detectPeople ||
      regionData.detectAnimals ||
      regionData.detectVehicles
    );

    const noDetectionRegionTypeCustomMessage = (input: HTMLInputElement) => {
      if (input.validity.valueMissing) {
        return "At least one detection type must be selected";
      }
      return "";
    };

    const handleLoiteringToggle = (
      isChecked: boolean,
      setMinutes: React.Dispatch<React.SetStateAction<number | null>>,
      setSeconds: React.Dispatch<React.SetStateAction<number | null>>,
      initialStates: { minutes: number | null; seconds: number | null }
    ) => {
      if (!isChecked) {
        setMinutes(null);
        setSeconds(null);
      } else {
        setMinutes(initialStates.minutes);
        setSeconds(initialStates.seconds);
      }
    };

    const handleVehicleLoiteringChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const isChecked = event.target.checked;
      setDetectVehicleLoitering(isChecked);
      if (isChecked) {
        relayEnv.commitUpdate((store) => {
          const region = store.get(
            regionData.id
          ) as RecordProxy<VarHubCameraDetectionRegion>;
          if (region) {
            region.setValue(true, "loiteringVehicleSeconds");
          }
        });
      } else {
        relayEnv.commitUpdate((store) => {
          const region = store.get(
            regionData.id
          ) as RecordProxy<VarHubCameraDetectionRegion>;
          if (region) {
            region.setValue(null, "loiteringVehicleSeconds");
          }
        });
        setLoiteringVehicleMinutes(null);
        setLoiteringVehicleSeconds(null);
      }
    };

    const handleLoiteringChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const isChecked = event.target.checked;
      setDetectPersonLoitering(isChecked);
      if (isChecked) {
        relayEnv.commitUpdate((store) => {
          const region = store.get(
            regionData.id
          ) as RecordProxy<VarHubCameraDetectionRegion>;
          if (region) {
            region.setValue(true, "loiteringPersonSeconds");
          }
        });
      } else {
        relayEnv.commitUpdate((store) => {
          const region = store.get(
            regionData.id
          ) as RecordProxy<VarHubCameraDetectionRegion>;
          if (region) {
            region.setValue(null, "loiteringPersonSeconds");
          }
        });
        setLoiteringMinutes(null);
        setLoiteringSeconds(null);
      }
    };
    const onHandleDirectionSelect = (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      setMovementDirection(event.target.value as directionOfTravel);
    };

    return (
      <RegionsSettingsRoot>
        <RegionsSettingsTable>
          <thead>
            <RegionsSettingsRow>
              <th colSpan={2}>
                <Flex alignItems="center" justifyContent="space-between">
                  <RegionSettingsHeaderText
                    index={regionData.slotNumber ?? index}
                    onMouseEnter={() => {
                      // brings the region to the top of the pile
                      relayEnv.commitUpdate((store) => {
                        const cameraRecord = store.get(
                          idAsString(cameraData.id)
                        );
                        if (cameraRecord && isEditable) {
                          cameraRecord.setValue(
                            regionData.index,
                            "activeDetectionRegionIndex"
                          );
                        }
                      });
                    }}
                  >
                    Region #{(regionData.slotNumber ?? index) + 1}{" "}
                    {regionData.name}
                  </RegionSettingsHeaderText>
                  {isEditable ? (
                    <RegionSettingsDeleteButton
                      type="button"
                      onClick={() => {
                        if (!regionData.isNew) {
                          setRemovedDetectionRegionIds(setAdd(regionData.id));
                        }
                        relayEnv.commitUpdate((store) => {
                          removeRegionUpdater(
                            store,
                            cameraData.id,
                            regionData.id
                          );
                        });
                      }}
                    >
                      <Icon name="trash" />
                    </RegionSettingsDeleteButton>
                  ) : null}
                </Flex>
              </th>
            </RegionsSettingsRow>
          </thead>
          <tbody>
            <RegionsSettingsRow>
              <FieldLabelCell>
                <FieldLabelContainer>
                  <FieldLabelText
                    htmlFor={`detection-region-${regionData.id}-name`}
                  >
                    Name
                  </FieldLabelText>
                </FieldLabelContainer>
              </FieldLabelCell>
              <FieldValueCell>
                <TextInput
                  id={`detection-region-${regionData.id}-name`}
                  value={regionData.name}
                  required
                  autoFocus={!!regionData.isNew}
                  onChange={(event) => {
                    relayEnv.commitUpdate((store) => {
                      const region = store.get(
                        regionData.id
                      ) as RecordProxy<VarHubCameraDetectionRegion>;
                      if (region) {
                        region.setValue(event.target.value, "name");
                      }
                    });
                  }}
                />
              </FieldValueCell>
            </RegionsSettingsRow>
            <RegionsSettingsRow>
              <FieldLabelCell>
                <FieldLabelContainer>
                  <FieldLabelText
                    htmlFor={`detection-region-${regionData.id}-direction`}
                  >
                    Direction
                  </FieldLabelText>
                </FieldLabelContainer>
              </FieldLabelCell>
              <FieldValueCell>
                <Select
                  id={`detection-region-${regionData.id}-direction`}
                  value={
                    regionData.movementDirection
                      ? regionData.movementDirection
                      : movementDirection
                  }
                  onChange={(event) => {
                    onHandleDirectionSelect(event);
                    relayEnv.commitUpdate((store) => {
                      const newDirection = event.target
                        .value as directionOfTravel;
                      setMovementDirection(newDirection);
                      //update relay
                      const region = store.get(
                        regionData.id
                      ) as RecordProxy<VarHubCameraDetectionRegion>;
                      if (region) {
                        region.setValue(
                          event.target.value,
                          "movementDirection"
                        );
                      }
                    });
                  }}
                >
                  <option key={"OFF"} value={"OFF"}>
                    OFF
                  </option>
                  <option key={"CROSS"} value={"CROSS"}>
                    Bi-directional
                  </option>
                  <option key={"IN"} value={"IN"}>
                    Enter
                  </option>
                  <option key={"OUT"} value={"OUT"}>
                    Exit
                  </option>
                </Select>
              </FieldValueCell>
            </RegionsSettingsRow>
            <RegionsSettingsRow>
              <FieldLabelCell>
                <FieldLabelContainer>
                  <FieldLabelText
                    htmlFor={`detection-region-${regionData.id}-detect-people`}
                  >
                    Detect People
                  </FieldLabelText>
                </FieldLabelContainer>
              </FieldLabelCell>
              <FieldValueCell>
                {/* Detect People Toggle */}
                <Switch
                  id={`detection-region-${regionData.id}-detect-people`}
                  label="Detect People"
                  required={regionDetectionTypeNotSelected}
                  checked={regionData.detectPeople}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    relayEnv.commitUpdate((store) => {
                      const region = store.get(
                        regionData.id
                      ) as RecordProxy<VarHubCameraDetectionRegion>;
                      if (region) {
                        region.setValue(event.target.checked, "detectPeople");
                      }
                    });
                  }}
                  getValidationMessage={noDetectionRegionTypeCustomMessage}
                />
              </FieldValueCell>
            </RegionsSettingsRow>
            {/* Detect Loitering Toggle - Visible only when Detect People is enabled */}
            {regionData.detectPeople ? (
              <>
                <RegionsSettingsRow>
                  <FieldLabelCell>
                    <FieldLabelContainer>
                      <FieldLabelText
                        htmlFor={`detection-region-${regionData.id}-detect-people-loitering`}
                      >
                        Detect Loitering
                      </FieldLabelText>
                      <InlineTooltip
                        message="Enable to only alert when a person is within this region for the programmed period of time."
                        position={MessagePosition.Right}
                      />
                    </FieldLabelContainer>
                  </FieldLabelCell>
                  <FieldValueCell>
                    <Switch
                      id={`detection-region-${regionData.id}-detect-people-loitering`}
                      label="Detect Person Loitering"
                      checked={detectPersonLoitering}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleLoiteringChange(event);
                      }}
                      getValidationMessage={noDetectionRegionTypeCustomMessage}
                    />
                  </FieldValueCell>
                </RegionsSettingsRow>
                {detectPersonLoitering && (
                  <>
                    <RegionsSettingsRow>
                      <FieldLabelCell>
                        <FieldLabelContainer>
                          <FieldLabelText
                            htmlFor={`detection-region-${regionData.id}-loitering-min`}
                          >
                            Minutes
                          </FieldLabelText>
                        </FieldLabelContainer>
                      </FieldLabelCell>
                      <FieldValueCell>
                        <TextInput
                          id={`detection-region-${regionData.id}-loitering-min`}
                          maxLength={32}
                          value={
                            loiteringMinutes !== null
                              ? loiteringMinutes.toString()
                              : ""
                          }
                          onChange={handleMinutesChange}
                        />
                      </FieldValueCell>
                    </RegionsSettingsRow>
                    <RegionsSettingsRow>
                      <FieldLabelCell>
                        <FieldLabelContainer>
                          <FieldLabelText
                            htmlFor={`detection-region-${regionData.id}-loitering-sec`}
                          >
                            Seconds
                          </FieldLabelText>
                        </FieldLabelContainer>
                      </FieldLabelCell>
                      <FieldValueCell>
                        <TextInput
                          id={`detection-region-${regionData.id}-loitering-sec`}
                          maxLength={32}
                          value={
                            loiteringSeconds !== null
                              ? loiteringSeconds.toString()
                              : ""
                          }
                          onChange={handleSecondsChange}
                        />
                      </FieldValueCell>
                    </RegionsSettingsRow>
                  </>
                )}
              </>
            ) : null}
            <RegionsSettingsRow>
              <FieldLabelCell>
                <FieldLabelContainer>
                  <FieldLabelText
                    htmlFor={`detection-region-${regionData.id}-detect-animals`}
                  >
                    Detect Animals
                  </FieldLabelText>
                </FieldLabelContainer>
              </FieldLabelCell>
              <FieldValueCell>
                <Switch
                  id={`detection-region-${regionData.id}-detect-animals`}
                  label="Detect Animals"
                  required={regionDetectionTypeNotSelected}
                  checked={regionData.detectAnimals}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    relayEnv.commitUpdate((store) => {
                      const region = store.get(
                        regionData.id
                      ) as RecordProxy<VarHubCameraDetectionRegion>;
                      if (region) {
                        region.setValue(event.target.checked, "detectAnimals");
                      }
                    });
                  }}
                  getValidationMessage={noDetectionRegionTypeCustomMessage}
                />
              </FieldValueCell>
            </RegionsSettingsRow>
            <RegionsSettingsRow>
              <FieldLabelCell>
                <FieldLabelContainer>
                  <FieldLabelText
                    htmlFor={`detection-region-${regionData.id}-detect-vehicles`}
                  >
                    Detect Vehicles
                  </FieldLabelText>
                </FieldLabelContainer>
              </FieldLabelCell>
              <FieldValueCell>
                {/* Detect Vehicles Toggle */}
                <Switch
                  id={`detection-region-${regionData.id}-detect-vehicles`}
                  label="Detect Vehicles"
                  required={regionDetectionTypeNotSelected}
                  checked={regionData.detectVehicles}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    relayEnv.commitUpdate((store) => {
                      const region = store.get(
                        regionData.id
                      ) as RecordProxy<VarHubCameraDetectionRegion>;
                      if (region) {
                        region.setValue(event.target.checked, "detectVehicles");
                      }
                    });
                  }}
                  getValidationMessage={noDetectionRegionTypeCustomMessage}
                />
              </FieldValueCell>
            </RegionsSettingsRow>
            {/* Detect Vehicle Loitering Toggle - Visible only when Detect Vehicles is enabled */}
            {regionData.detectVehicles ? (
              <>
                <RegionsSettingsRow>
                  <FieldLabelCell>
                    <FieldLabelContainer>
                      <FieldLabelText
                        htmlFor={`detection-region-${regionData.id}-detect-vehicle-loitering`}
                      >
                        Detect Loitering
                      </FieldLabelText>
                      <InlineTooltip
                        message="Enable to only alert when a vehicle is within this region for the programmed period of time."
                        position={MessagePosition.Right}
                      />
                    </FieldLabelContainer>
                  </FieldLabelCell>
                  <FieldValueCell>
                    <Switch
                      id={`detection-region-${regionData.id}-detect-vehicle-loitering`}
                      label="Detect Loitering"
                      checked={detectVehicleLoitering}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleVehicleLoiteringChange(event);
                      }}
                      getValidationMessage={noDetectionRegionTypeCustomMessage}
                    />
                  </FieldValueCell>
                </RegionsSettingsRow>
                {detectVehicleLoitering && (
                  <>
                    <RegionsSettingsRow>
                      <FieldLabelCell>
                        <FieldLabelContainer>
                          <FieldLabelText
                            htmlFor={`detection-region-${regionData.id}-vehicle-loitering-min`}
                          >
                            Minutes
                          </FieldLabelText>
                        </FieldLabelContainer>
                      </FieldLabelCell>
                      <FieldValueCell>
                        <TextInput
                          id={`detection-region-${regionData.id}-vehicle-loitering-min`}
                          maxLength={32}
                          disabled={!detectVehicleLoitering}
                          value={
                            loiteringVehicleMinutes !== null
                              ? loiteringVehicleMinutes.toString()
                              : ""
                          }
                          onChange={handleVehicleMinutesChange}
                        />
                      </FieldValueCell>
                    </RegionsSettingsRow>
                    <RegionsSettingsRow>
                      <FieldLabelCell>
                        <FieldLabelContainer>
                          <FieldLabelText
                            htmlFor={`detection-region-${regionData.id}-loitering-sec`}
                          >
                            Seconds
                          </FieldLabelText>
                        </FieldLabelContainer>
                      </FieldLabelCell>
                      <FieldValueCell>
                        <TextInput
                          id={`detection-region-${regionData.id}-loitering-sec`}
                          maxLength={32}
                          disabled={!detectVehicleLoitering}
                          value={
                            loiteringVehicleSeconds !== null
                              ? loiteringVehicleSeconds.toString()
                              : ""
                          }
                          onChange={handleVehicleSecondsChange}
                        />
                      </FieldValueCell>
                    </RegionsSettingsRow>
                  </>
                )}
              </>
            ) : null}
            <RegionsSettingsRow>
              <FieldLabelCell>
                <FieldLabelContainer>
                  <Tooltip content="Display the configured region on event previews.">
                    <Tooltip.LabelText
                      htmlFor={`detection-region-${regionData.id}-bounding-boxes`}
                    >
                      Display Regions
                    </Tooltip.LabelText>
                  </Tooltip>
                </FieldLabelContainer>
              </FieldLabelCell>
              <FieldValueCell>
                <Switch
                  id={`detection-region-${regionData.id}-bounding-boxes`}
                  label="Display Regions"
                  checked={regionData.drawRegionOnAlerts}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    relayEnv.commitUpdate((store) => {
                      const region = store.get(
                        regionData.id
                      ) as RecordProxy<VarHubCameraDetectionRegion>;
                      if (region) {
                        region.setValue(
                          event.target.checked,
                          "drawRegionOnAlerts"
                        );
                      }
                    });
                  }}
                />
              </FieldValueCell>
            </RegionsSettingsRow>
            {regionData.zone ? (
              <>
                <RegionsSettingsRow>
                  <FieldLabelCell>
                    <FieldLabelContainer>
                      <FieldLabelText
                        htmlFor={`detection-region-${regionData.id}-zone-name`}
                      >
                        Zone Name
                      </FieldLabelText>
                    </FieldLabelContainer>
                  </FieldLabelCell>
                  <FieldValueCell>
                    <TextInput
                      id={`detection-region-${regionData.id}-zone-name`}
                      value={regionData.zone.name}
                      required
                      maxLength={32}
                      onChange={(event) => {
                        relayEnv.commitUpdate((store) => {
                          const region = store.get(
                            regionData.id
                          ) as RecordProxy<VarHubCameraDetectionRegion>;
                          if (region) {
                            const zone = region.getLinkedRecord("zone");
                            if (zone) {
                              zone.setValue(event.target.value, "name");
                            }
                          }
                        });
                      }}
                    />
                  </FieldValueCell>
                </RegionsSettingsRow>
                <RegionsSettingsRow>
                  <FieldLabelCell>
                    <FieldLabelContainer>
                      <FieldLabelText
                        htmlFor={`detection-region-${regionData.id}-zone-number`}
                      >
                        Zone Number
                      </FieldLabelText>
                    </FieldLabelContainer>
                  </FieldLabelCell>
                  <FieldValueCell>
                    <TextInput
                      id={`detection-region-${regionData.id}-zone-number`}
                      value={regionData.zone.number}
                      disabled={!regionData.zone.isNew}
                      required
                      onChange={({ target }) => {
                        relayEnv.commitUpdate((store) => {
                          const region = store.get(
                            regionData.id
                          ) as RecordProxy<VarHubCameraDetectionRegion>;
                          if (region) {
                            const zone = region.getLinkedRecord("zone");
                            if (zone) {
                              zone.setValue(target.value, "number");
                            }
                          }
                        });
                      }}
                      onBlur={({ target }) => {
                        setZoneIsDuplicated(
                          () =>
                            takenZoneNumbersList.has(Number(target.value)) ||
                            cameraData.detectionRegions
                              .map(
                                (region) =>
                                  region.id !== regionData.id &&
                                  Number(region.zone?.number) ===
                                    Number(target.value)
                              )
                              .includes(true)
                        );
                        setZoneIsOutOfRange(
                          () =>
                            target.value !== "" &&
                            !possibleZoneNumbersList.has(Number(target.value))
                        );
                        setZoneIsInAxRange(() =>
                          axZoneNumbersList.has(Number(target.value))
                        );
                      }}
                    />
                  </FieldValueCell>
                </RegionsSettingsRow>

                {regionData.zone.isNew ? (
                  zoneIsDuplicated || zoneIsOutOfRange || zoneIsInAxRange ? (
                    <RegionsSettingsRow>
                      <FieldLabelCell />
                      <FieldValueCell>
                        <span className="text-danger">
                          {zoneIsOutOfRange
                            ? "Zone Number Is Out Of Range"
                            : zoneIsInAxRange
                            ? "Zone Number Is In AX Bus Range"
                            : "Zone Number Is Already In Use"}
                        </span>
                      </FieldValueCell>
                    </RegionsSettingsRow>
                  ) : null
                ) : null}
                <RegionsSettingsRow>
                  <FieldLabelCell>
                    <FieldLabelContainer>
                      <FieldLabelText
                        htmlFor={`detection-region-${regionData.id}-area`}
                      >
                        Area
                      </FieldLabelText>
                    </FieldLabelContainer>
                  </FieldLabelCell>
                  <FieldValueCell>
                    <Select
                      id={`detection-region-${regionData.id}-area`}
                      value={regionData.zone.area?.id ?? ""}
                      required
                      onChange={(event) => {
                        relayEnv.commitUpdate((store) => {
                          const region = store.get(
                            regionData.id
                          ) as RecordProxy<VarHubCameraDetectionRegion>;
                          if (region) {
                            const zone = region.getLinkedRecord("zone");
                            if (zone) {
                              const area = store.get(
                                event.target.value
                              ) as RecordProxy<Area>;
                              if (event.target.value && area) {
                                zone.setLinkedRecord(area, "area");
                              } else {
                                zone.setValue(null, "area");
                              }
                            }
                          }
                        });
                      }}
                    >
                      <Select.Option value="">Select an area</Select.Option>
                      {panelData.areas.edges.map(({ node }) =>
                        node ? (
                          <Select.Option key={node.id} value={node.id}>
                            {node.name}
                          </Select.Option>
                        ) : null
                      )}
                    </Select>
                  </FieldValueCell>
                </RegionsSettingsRow>
              </>
            ) : panelSupportsVarIntegration &&
              varHubData?.panelConnectionEnabled ? (
              zoneAvailable ? (
                <RegionsSettingsRow>
                  <FieldLabelCell />
                  <FieldValueCell>
                    <AddButton
                      loading={fetchingZoneNumber}
                      onClick={async () => {
                        setFetchingZoneNumber(true);
                        const data =
                          await fetchQuery<RecorderCameraDetectionRegionSettingsZonesQuery>(
                            relayEnv,
                            graphql`
                              query RecorderCameraDetectionRegionSettingsZonesQuery(
                                $systemId: ID!
                              ) {
                                controlSystem: node(id: $systemId) {
                                  ... on ControlSystem {
                                    varHubDetectionRegionZoneNumberMin
                                    varHubDetectionRegionZoneNumberMax
                                    panel {
                                      deviceInformations {
                                        ... on XrDeviceInformation {
                                          axNumber
                                          deviceType
                                          deviceCommunicationMethod
                                        }
                                        ... on Xt75DeviceInformation {
                                          deviceType
                                          deviceCommunicationMethod
                                        }
                                      }
                                    }
                                    zones {
                                      number
                                    }
                                  }
                                }
                              }
                            `,
                            { systemId: globalSystemId },
                            { fetchPolicy: "network-only" }
                          ).toPromise();

                        if (
                          data?.controlSystem?.panel?.deviceInformations &&
                          data?.controlSystem?.zones &&
                          isNotNullOrUndefined(
                            data.controlSystem
                              .varHubDetectionRegionZoneNumberMin
                          ) &&
                          isNotNullOrUndefined(
                            data.controlSystem
                              .varHubDetectionRegionZoneNumberMax
                          )
                        ) {
                          const {
                            zones,
                            varHubDetectionRegionZoneNumberMin,
                            varHubDetectionRegionZoneNumberMax,
                            panel: { deviceInformations },
                          } = data.controlSystem;
                          const axTakenZoneNumbers = new Set(
                            deviceInformations.flatMap((device) => {
                              const number = Number(device.axNumber);
                              switch (true) {
                                case device.deviceType === "CAMERA" ||
                                  device.deviceType === "VPLEX":
                                  return [];
                                case between(number, 17, 32):
                                  return range(500, 600);
                                case between(number, 33, 48):
                                  return range(600, 700);
                                case between(number, 49, 64):
                                  return range(700, 800);
                                case between(number, 65, 80):
                                  return range(800, 900);
                                case between(number, 81, 96):
                                  return range(900, 1000);
                                default:
                                  return [];
                              }
                            })
                          );
                          const possibleZoneNumbers = new Set(
                            range(
                              varHubDetectionRegionZoneNumberMin,
                              varHubDetectionRegionZoneNumberMax + 1
                            )
                          );

                          const takenZoneNumbers = new Set(
                            zones
                              .map(({ number }) => parseInt(number, 10))
                              .concat(regionZoneNumbers)
                              .concat(Array.from(axTakenZoneNumbers))
                          );
                          const availableNumbers = setDifference(
                            takenZoneNumbers,
                            possibleZoneNumbers
                          ) as Set<number>;
                          setTakenZoneNumbersList(() => takenZoneNumbers);
                          setAxZoneNumbersList(() => axTakenZoneNumbers);
                          setPossibleZoneNumbersList(() => possibleZoneNumbers);
                          const nextAvailableNumber =
                            setFirst(availableNumbers);

                          if (isNotNullOrUndefined(nextAvailableNumber)) {
                            relayEnv.commitUpdate((store) => {
                              const region = store.get(
                                regionData.id
                              ) as RecordProxy<VarHubCameraDetectionRegion>;
                              if (region) {
                                const zone = store.create(
                                  idAsString(
                                    toZoneId(systemId, nextAvailableNumber)
                                  ),
                                  "Zone"
                                ) as RecordProxy<Zone>;
                                zone.setValue(``, "name");
                                zone.setValue(nextAvailableNumber, "number");
                                zone.setValue(true, "isNew");
                                zone.setValue(null, "area");
                                region.setLinkedRecord(zone, "zone");
                              }
                            });
                          } else {
                            setZoneAvailable(false);
                          }

                          setFetchingZoneNumber(true);
                        }
                      }}
                    >
                      Add Alarm Zone
                    </AddButton>
                  </FieldValueCell>
                </RegionsSettingsRow>
              ) : (
                <span>No zones available</span>
              )
            ) : null}
          </tbody>
        </RegionsSettingsTable>
      </RegionsSettingsRoot>
    );
  };

export default RecorderCameraDetectionRegionSettings;
