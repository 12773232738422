App.controller("CreateUpdatePasswordLinkCtrl", [
  "$scope",
  "$rootScope",
  "$state",
  "UserService",
  "LoginService",
  "DA_LOGOS",
  "DA_LOGIN_BACKGROUNDS",
  "$q",
  function (
    $scope,
    $rootScope,
    $state,
    UserService,
    LoginService,
    DA_LOGOS,
    DA_LOGIN_BACKGROUNDS,
    $q
  ) {
    $scope.DA_LOGOS = DA_LOGOS;
    $scope.DA_LOGIN_BACKGROUNDS = DA_LOGIN_BACKGROUNDS;

    $scope.getFormType = () => {
      switch ($state.current.url) {
        case "/create-password?token":
          return "Create";
        default:
          return "Update";
      }
    };

    const init = () => {
      $scope.token = $state.params?.token ?? "";
      $scope.formType = $scope.getFormType();

      if (
        ["scdev", "prod_security_cmd", "test_security_cmd"].includes(
          process.env.REACT_APP_SECURECOM_ENV ?? ""
        )
      ) {
        $scope.backgroundImage = DA_LOGIN_BACKGROUNDS.securityCommand;
      } else {
        $scope.backgroundImage = DA_LOGIN_BACKGROUNDS.default;
      }
    };

    init();
  },
]);
