import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { SystemOptionsContextProvider } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import {
  applyCopiedZoneInformationProgrammingToZoneInformation,
  applyDupedZoneInformationProgrammingToZoneInformation,
  getWirelessZoneNumberRange,
} from "components/FullProgramming/common/ZoneInformationFields/utils";
import {
  zoneListItemTemplateId,
  ZONE_IDS,
} from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationNumberField";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { omit } from "ramda";
import * as React from "react";
import {
  readInlineData,
  useFragment,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  ControlSystem,
  fromControlSystemId,
  fromZoneId,
  idAsString,
  Panel,
  toGlobalId,
  toZoneId,
  Zone,
  ZoneEntryDelayNumber,
  ZoneMessage,
  ZoneOutputAction,
  ZoneRemoteZoneType,
  ZoneSupervisionTime,
  ZoneType,
} from "securecom-graphql/client";
import XFZoneInformationsFields from "./XFZoneInformationsFields";
import {
  XFZoneInformationProgrammingConceptFormInline_controlSystem$data,
  XFZoneInformationProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XFZoneInformationProgrammingConceptFormInline_controlSystem.graphql";
import { XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import {
  XFZoneInformationProgrammingConceptFormInline_zone$data,
  XFZoneInformationProgrammingConceptFormInline_zone$key,
} from "./__generated__/XFZoneInformationProgrammingConceptFormInline_zone.graphql";
import { XFZoneInformationProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XFZoneInformationProgrammingConceptFormNavButton_controlSystem.graphql";
import { XFZoneInformationProgrammingConceptFormZoneInformationDeleteMutation } from "./__generated__/XFZoneInformationProgrammingConceptFormZoneInformationDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XFZoneInformationProgrammingConceptFormZoneInformationRefreshMutation,
} from "./__generated__/XFZoneInformationProgrammingConceptFormZoneInformationRefreshMutation.graphql";
import {
  XFZoneInformationProgrammingConceptFormZoneInformationSendMutation,
  XFZoneInformationProgrammingConceptFormZoneInformationSendMutation$data,
} from "./__generated__/XFZoneInformationProgrammingConceptFormZoneInformationSendMutation.graphql";
import {
  XFZoneInformationProgrammingConceptForm_controlSystem$data,
  XFZoneInformationProgrammingConceptForm_controlSystem$key,
} from "./__generated__/XFZoneInformationProgrammingConceptForm_controlSystem.graphql";
import { XFZoneInformationProgrammingConceptForm_ZoneListItemLabel_zone$key } from "./__generated__/XFZoneInformationProgrammingConceptForm_ZoneListItemLabel_zone.graphql";

export const title = "Zone Information";
export const conceptId = "xf-zone-information";

export const getState = (
  controlSystem: XFZoneInformationProgrammingConceptFormInline_controlSystem$key
) => {
  return readInlineData(
    graphql`
      fragment XFZoneInformationProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          zoneNumberRange
          totalWirelessZoneMax
          totalZonesMax
          zoneInformations {
            __typename
            id
            isNew
            ...XFZoneInformationProgrammingConceptFormInline_zone
          }
        }
      }
    `,
    controlSystem
  );
};

export const getZoneState = (
  zone: XFZoneInformationProgrammingConceptFormInline_zone$key
) =>
  readInlineData(
    graphql`
      fragment XFZoneInformationProgrammingConceptFormInline_zone on Zone
      @inline {
        __typename
        id
        name
        location
        number
        type
        retardDelayEnabled
        firePanelSlaveInput
        expanderSerialNumber
        contactNumber
        fastResponseEnabled
        realTimeStatusEnabled
        crossZoneEnabled
        prewarnKeypads
        presignalKeypads
        serialNumber
        competitorWireless
        sensorType
        supervisionTime
        swingerBypassEnabled
        wireless
        fireBellOutputNumber
        armedOpenActionMessage
        armedOpenOutputNumber
        armedOpenOutputAction
        armedShortActionMessage
        armedShortOutputNumber
        armedShortOutputAction
        isNew
      }
    `,
    zone
  );

const deleteMutation = graphql`
  mutation XFZoneInformationProgrammingConceptFormZoneInformationDeleteMutation(
    $id: ID!
  ) {
    deleteZoneInformation(id: $id) {
      ... on DeleteZoneInformationSuccessPayload {
        __typename
        deletedZoneId
      }
      ... on FailedToRemoveZoneErrorPayload {
        error: type
      }
    }
  }
`;

const sendMutation = graphql`
  mutation XFZoneInformationProgrammingConceptFormZoneInformationSendMutation(
    $systemId: ID!
    $zoneInformations: [ZoneProgrammingInput!]!
  ) {
    sendZoneProgramming(systemId: $systemId, zones: $zoneInformations) {
      ... on SendZoneProgrammingSuccessPayload {
        results {
          ... on SendZoneProgrammingZoneSuccessPayload {
            __typename
            zone {
              __typename
              id
              ...XFZoneInformationProgrammingConceptFormInline_zone
            }
          }
          ... on SendListItemsErrorPayload {
            __typename
            number
            errors {
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
      ... on Error {
        type
      }
    }
  }
`;

const refreshMutation = graphql`
  mutation XFZoneInformationProgrammingConceptFormZoneInformationRefreshMutation(
    $id: ID!
  ) {
    refreshZoneInformation(id: $id) {
      ... on RefreshZoneInformationSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XFZoneInformationProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

const mergeOldAndNewZones = (
  response: XFZoneInformationProgrammingConceptFormZoneInformationSendMutation$data,
  originalControlSystemData: XFZoneInformationProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendZoneProgramming.results) {
    const successfulZones = response.sendZoneProgramming.results
      .map((zone) => {
        if (zone.__typename === "SendZoneProgrammingZoneSuccessPayload") {
          return zone;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.zone)
      .map(getZoneState);

    const mergedZonesMap = new Map<
      string,
      XFZoneInformationProgrammingConceptFormInline_zone$data
    >();

    originalControlSystemData.panel.zoneInformations
      .map(getZoneState)
      .forEach((item) => mergedZonesMap.set(item.id, item));

    successfulZones.forEach((item) =>
      mergedZonesMap.set(item.id, {
        ...mergedZonesMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedZonesMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XFZoneInformationProgrammingConceptFormZoneInformationSendMutation$data,
  originalControlSystemData: XFZoneInformationProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedZones = mergeOldAndNewZones(response, originalControlSystemData);

  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshZoneInformation: {
        __typename: "RefreshZoneInformationSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            zoneInformations: mergedZones,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFZoneInformationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendZoneInformations, isSaving] =
    useMutation<XFZoneInformationProgrammingConceptFormZoneInformationSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const changedZones = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { zoneInformations },
        } = getState(props.controlSystem);
        sendZoneInformations({
          variables: {
            systemId,
            zoneInformations: zoneInformations
              .filter(
                (zone) =>
                  zone.isNew ||
                  (!!changedZones &&
                    listItemHasChanged(zone.id, changedZones)) ||
                  isSavingAllListItems
              )
              .map(getZoneState)
              .map((zone) => ({
                id: zone.id,
                number: zone.number.toString(),
                name: zone.name,
                location: zone.location,
                type: zone.type,
                area: "",
                followArea: "",
                reportWithAccountNumberForArea: "",
                armedAreasForArmingZone: "",
                disarmedOpenActionMessage: ZoneMessage.NONE,
                disarmedOpenOutputNumber: null,
                fireBellOutputNumber: zone.fireBellOutputNumber,
                disarmedOpenOutputAction: ZoneOutputAction.NONE,
                disarmedShortActionMessage: ZoneMessage.NONE,
                disarmedShortOutputNumber: "",
                disarmedShortOutputAction: ZoneOutputAction.NONE,
                armedOpenActionMessage:
                  zone.armedOpenActionMessage ?? ZoneMessage.NONE,
                armedOpenOutputNumber: zone.armedOpenOutputNumber,
                armedOpenOutputAction:
                  zone.armedOpenOutputAction ?? ZoneOutputAction.NONE,
                armedShortActionMessage:
                  zone.armedShortActionMessage ?? ZoneMessage.NONE,
                armingStyle: null,
                armedShortOutputNumber: zone.armedShortOutputNumber,
                armedShortOutputAction:
                  zone.armedShortOutputAction ?? ZoneOutputAction.NONE,
                swingerBypassEnabled: zone.swingerBypassEnabled,
                retardDelayEnabled: zone.retardDelayEnabled,
                fastResponseEnabled: zone.fastResponseEnabled,
                prewarnKeypads: zone.prewarnKeypads,
                presignalKeypads: zone.presignalKeypads,
                crossZoneEnabled: zone.crossZoneEnabled,
                entryDelayNumber: ZoneEntryDelayNumber.FOUR,
                priorityZone: false,
                firePanelSlaveInput: zone.firePanelSlaveInput,
                realTimeStatusEnabled: zone.realTimeStatusEnabled,
                trafficCountEnabled: null,
                zoneAuditDays: null,
                wireless: zone.wireless,
                serialNumber: zone.serialNumber,
                contactNumber: zone.contactNumber,
                supervisionTime:
                  zone.supervisionTime ?? ZoneSupervisionTime.NONE,
                wirelessLedEnabled: false,
                wirelessDisarmDisableEnabled: false,
                wirelessPirPulseCount: null,
                wirelessPirSensitivity: null,
                wirelessPetImmunity: null,
                chimeSound: null,
                lockdownEnabled: null,
                normallyClosed: null,
                wirelessContactNormallyOpen: null,
                competitorWireless: null,
                sensorType: null,
                receiverRouting: null,
                expanderSerialNumber: zone.expanderSerialNumber,
                isNew: zone.isNew,
                tamperEnabled: null,
                remoteZoneType: null,
              })),
          },
          onCompleted: (response) => {
            const saveErrors: SaveErrors = [];
            if (response.sendZoneProgramming.type && showAlerts) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendZoneProgramming.results) {
              response.sendZoneProgramming.results.forEach((response) => {
                if (
                  response.__typename ===
                  "SendZoneProgrammingZoneSuccessPayload"
                ) {
                  resetLastUpdated(response.zone.id);
                } else if (
                  response.__typename === "SendListItemsErrorPayload"
                ) {
                  saveErrors.push({
                    programmingConcept: title,
                    errors: response.errors,
                    listItemNumber: response.number,
                  });
                }
              });

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (showAlerts && !saveErrors.length) {
                showAlert({
                  type: "success",
                  text: "Successfully Updated Zone Informations",
                });
              }
            }
            resolve(saveErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSaving,
  ];
};

export const useRetrieveMutation = (props: {
  controlSystem: XFZoneInformationProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshZoneInformation, isRefreshing] =
    useMutation<XFZoneInformationProgrammingConceptFormZoneInformationRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshZoneInformation({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshZoneInformation;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Zone Information Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshZoneInformation: {
                    __typename: response.refreshZoneInformation.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Zone Information: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Zone Information",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};

const readZoneInformationsTemplateData = (
  programmingTemplateConcepts: XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        zoneInformations {
          included
          number
          name {
            included
            data
          }
          location {
            included
            data
          }          
          type {
            included
            data
          }
          fireBellOutputNumber {
            included
            data
          }
          armedOpenActionMessage {
            included
            data
          }
          armedOpenOutputNumber {
            included
            data
          }
          armedOpenOutputAction {
            included
            data
          }
          armedShortActionMessage {
            included
            data
          }
          armedShortOutputNumber {
            included
            data
          }
          armedShortOutputAction {
            included
            data
          }
          swingerBypassEnabled {
            included
            data
          }
          retardDelayEnabled {
            included
            data
          }
          fastResponseEnabled {
            included
            data
          }
          prewarnKeypads {
            included
            data
          }
          presignalKeypads {
            included
            data
          }
          crossZoneEnabled {
            included
            data
          }
          firePanelSlaveInput {
            included
            data
          }
          realTimeStatusEnabled {
            included
            data
          }
          wireless {
            included
            data
          }
          contactNumber {
            included
            data
          }
          supervisionTime {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).zoneInformations;

export function applyTemplateData(
  programmingTemplateConcepts: XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const zoneRecordProxies =
    panelRecordProxy.getLinkedRecords("zoneInformations") ?? [];
  const zonesByNumber = indexRecordProxiesByNumber(zoneRecordProxies);

  const zonesTemplateData =
    readZoneInformationsTemplateData(programmingTemplateConcepts) ?? [];
  zonesTemplateData.forEach((zoneTemplateData) => {
    if (zoneTemplateData?.included) {
      let zoneRecordProxy = zonesByNumber.get(zoneTemplateData.number);
      if (!zoneRecordProxy) {
        const newZoneId = applyNewZoneToZoneInformationsList(
          {
            panel: {
              id: panelRecordProxy.getValue("id"),
              softwareVersion: panelRecordProxy.getValue("softwareVersion"),
              zoneNumberRange:
                panelRecordProxy.getValue("zoneNumberRange") ?? [],
              zoneInformations: zoneRecordProxies.map(
                (zoneRecordProxy) =>
                  ({ number: zoneRecordProxy.getValue("number") } as const)
              ),
            } as const,
          } as const,
          store
        );
        if (newZoneId) {
          zoneRecordProxy = store.get(newZoneId) as RecordProxy<Zone>;
          if (zoneRecordProxy) {
            zoneRecordProxy.setValue(zoneTemplateData.number, "number");
          }
        }
      }

      if (!zoneRecordProxy) {
        return;
      }

      if (
        !zoneTemplateData.fireBellOutputNumber?.included &&
        zoneTemplateData.type?.included &&
        [
          ZoneType.FIRE,
          ZoneType.FIRE_VERIFY,
          ZoneType.SUPERVISORY,
          ZoneType.CARBON_MONOXIDE,
        ].includes(zoneTemplateData.type?.data as ZoneType)
      ) {
        const outputNumber =
          zoneRecordProxy.getValue("fireBellOutputNumber") ??
          zoneRecordProxy.getValue("disarmedOpenOutputNumber") ??
          "000";
        zoneRecordProxy.setValue(outputNumber, "fireBellOutputNumber");
      } else if (
        zoneTemplateData.type?.included &&
        ![
          ZoneType.FIRE,
          ZoneType.FIRE_VERIFY,
          ZoneType.SUPERVISORY,
          ZoneType.CARBON_MONOXIDE,
        ].includes(zoneTemplateData.type?.data as ZoneType)
      ) {
        const outputNumber =
          zoneRecordProxy.getValue("disarmedOpenOutputNumber") ??
          zoneRecordProxy.getValue("fireBellOutputNumber") ??
          "000";
        zoneRecordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
      } else if (
        !zoneTemplateData.type?.included &&
        [
          ZoneType.FIRE,
          ZoneType.FIRE_VERIFY,
          ZoneType.SUPERVISORY,
          ZoneType.CARBON_MONOXIDE,
        ].includes(zoneRecordProxy.getValue("type") as ZoneType)
      ) {
        const outputNumber =
          zoneRecordProxy.getValue("fireBellOutputNumber") ?? "000";

        zoneRecordProxy.setValue(outputNumber, "fireBellOutputNumber");
      }

      applyTemplateScalarDataToRecordProxy(
        zoneRecordProxy,
        omit(
          ["area", "followArea", "reportWithAccountNumberForArea"],
          zoneTemplateData
        )
      );

      if (!zonesByNumber.has(zoneTemplateData.number)) {
        zonesByNumber.set(zoneTemplateData.number, zoneRecordProxy);
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(zonesByNumber),
    "zoneInformations"
  );
}

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XFZoneInformationProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XFZoneInformationProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            zoneInformations {
              isNew
            }
          }
        }
      `
    );
  const { zoneInformations } = controlSystem.panel;
  const itemsCount = zoneInformations.length;
  const hasNewItems =
    itemsCount > 0 && zoneInformations.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFZoneInformationProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFZoneInformationProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedZoneInformation {
            id
          }
          panel {
            id
            zoneNumberRange
            totalWirelessZoneMax
            totalZonesMax
            hardwareModel
            softwareVersion
            ...ZoneInformationNumberField_panel
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            zoneInformations {
              id
              isNew
              number
              name
              ...XFZoneInformationsFields_zone
              ...XFZoneInformationProgrammingConceptForm_ZoneListItemLabel_zone
              remoteZoneType
            }
            newZone {
              id
              number
              isNew
              ...XFZoneInformationsFields_zone
              remoteZoneType
            }
            systemOptions {
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsContextHouseCode_systemOptions
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseZoneIsWirelessOnly_panel
            ...PanelContextUseHas1100T_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseZoneNumberRange_panel
            ...PanelContextUseZoneIsWirelessOnly_panel
            ...PanelContextUseVplexNumbers_panel
            ...PanelContextUseSupportsVplex_panel
            ...PanelContextUseHas1100T_panel
            ...PanelContextUseSupports1100T_panel
            ...PanelContextUseSupportsXR550_panel
            ...PanelContextUseWirelessZoneNumberRange_panel
            ...PanelContextUseBuiltIn1100WirelessEnabled_panel
            ...PanelContextUseSupports1100T_panel
            ...PanelContextUseAreaList_panel
            ...PanelContextHelpFiles_panel
            ...ZoneInformationSerialNumberField_ZoneList_panel
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
        }
      `
    );

  const relayEnv = useRelayEnvironment();
  const uncheckListItem = useUncheckListItem()(ZONE_IDS);
  const {
    zoneInformations,
    newZone,
    helpFiles: { programmingGuideUrl },
  } = controlSystem.panel;

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    zoneInformations[0]?.id ?? null
  );

  const [deleteZoneInformation, isDeleting] =
    useMutation<XFZoneInformationProgrammingConceptFormZoneInformationDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const templateContext = useTemplateContext();
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const removeSelectedZone = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.zoneInformations.findIndex(
          (zone) => zone.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.zoneInformations.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return (
          controlSystem.panel.zoneInformations[newSelectedIndex]?.id ?? null
        );
      });
      const zone = zoneInformations.find(
        (zone) => zone.id === selectedListItemId
      );
      uncheckListItem(String(zone?.number));
      if (zone?.isNew || templateContext.isEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "zoneInformations",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "Zone",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            zone?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteZoneInformation({
          variables: {
            id: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "zoneInformations",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedZoneId } = response.deleteZoneInformation;
            if (deletedZoneId) {
              showAlert({
                type: "success",
                text: "Zone Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "zoneInformations",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedZoneId, error } = response.deleteZoneInformation;
            if (deletedZoneId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  removeListItemFromStore(
                    selectedListItemId,
                    "zoneInformations",
                    controlSystem.panel.id,
                    parentStore
                  );
                });
              }
            } else {
              if (
                response.deleteZoneInformation.__typename !==
                "DeleteZoneInformationSuccessPayload"
              ) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Delete Zone: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Delete Zone",
                  });
                }
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Zone on Error",
            });
          },
        });
      }
    }
  };

  const availableNumbers = getAvailableNumbers(controlSystem);
  const canAdd = availableNumbers.size > 0 && !!newZone;

  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
    isValidatingProgramming,
  } = useProgrammingActionsContext();

  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <SystemOptionsContextProvider
        systemOptions={controlSystem.panel.systemOptions}
      >
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Zone%20Information`}
          title={title}
          deleting={isDeleting}
          isArrayConcept
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.zoneInformations
          )}
          amountAvailable={availableNumbers.size}
          addButton={
            <ProgrammingConceptForm.AddButton
              onClick={() => {
                relayEnv.commitUpdate((store) => {
                  const newZoneId = applyNewZoneToZoneInformationsList(
                    controlSystem,
                    store
                  );
                  if (newZoneId) {
                    setSelectedListItemId(newZoneId);
                  }
                });
              }}
            >
              Add Zone
            </ProgrammingConceptForm.AddButton>
          }
        >
          {(conceptId === activeConcept ||
            templateContext.isApplying ||
            isSavingAll) && (
            <ProgrammingConceptForm.ListItemsContainer>
              <ProgrammingConceptForm.ListItemPicker
                selectedId={selectedListItemId}
                onChange={(id) => {
                  setSelectedListItemId(id);
                }}
                newItemId={newZone?.id}
                items={zoneInformations.map((zone) => ({
                  id: zone.id,
                  templateListItemId: zoneListItemTemplateId(
                    String(zone.number)
                  ),
                  isnew: zone.isNew,
                  label: `#${zone.number} ${zone.name}`,
                }))}
              />
              <ProgrammingConceptForm.SelectedItemsContainer
                selectedListItemId={selectedListItemId}
                setSelectedListItemId={setSelectedListItemId}
              >
                {controlSystem.panel.zoneInformations.map(
                  (zoneInformation) =>
                    (zoneInformation.id === selectedListItemId ||
                      templateContext.isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                      ((isSavingAll || //Rendering at the last second before saving to check if there are errors or changes
                        programmingConcepts[conceptId].isSaving) &&
                        isValidatingProgramming)) && (
                      <RemountOnUpdateContainer nodeId={zoneInformation.id}>
                        <ProgrammingConceptForm.SelectedItem
                          conceptId={conceptId}
                          isnew={zoneInformation.isNew}
                          visible={zoneInformation.id === selectedListItemId}
                          key={zoneInformation.id}
                          listItemId={zoneInformation.id}
                          templateListItemId={zoneListItemTemplateId(
                            String(zoneInformation.number)
                          )}
                          title={<ZoneListItemLabel zone={zoneInformation} />}
                          isRemoteProgramZone={
                            zoneInformation.remoteZoneType ===
                            ZoneRemoteZoneType.REMOTE
                          }
                          onDuplicate={
                            canAdd &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const duplicateId =
                                  applyDuplicatedZoneInformationToZoneInformationsList(
                                    selectedListItemId,
                                    controlSystem,
                                    store
                                  );
                                if (duplicateId) {
                                  setSelectedListItemId(duplicateId);
                                }
                              });
                            })
                          }
                          onCopy={() => {
                            relayEnv.commitUpdate((store) => {
                              const controlSystemRecord = store.get(
                                controlSystem.id
                              );
                              const zoneInformationRecord =
                                store.get<Zone>(selectedListItemId);
                              if (
                                controlSystemRecord &&
                                zoneInformationRecord
                              ) {
                                const tempRecord =
                                  store.get("copiedZoneInformation") ??
                                  store.create(
                                    "copiedZoneInformation",
                                    "ZoneInformation"
                                  );
                                tempRecord.copyFieldsFrom(
                                  zoneInformationRecord
                                );
                                controlSystemRecord.setLinkedRecord(
                                  tempRecord,
                                  "copiedZoneInformation"
                                );
                              }
                            });
                          }}
                          onPaste={
                            !!controlSystem.copiedZoneInformation &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const zoneInformationRecord =
                                  store.get<Zone>(selectedListItemId);
                                const copiedZoneInformationRecord =
                                  store.get<Zone>("copiedZoneInformation");
                                if (
                                  zoneInformationRecord &&
                                  copiedZoneInformationRecord
                                ) {
                                  const allPossibleWirelessNumbers = new Set(
                                    getWirelessZoneNumberRange(
                                      controlSystem.panel.hardwareModel,
                                      Number(
                                        controlSystem.panel.softwareVersion
                                      ),
                                      true
                                    )
                                  );
                                  applyCopiedZoneInformationProgrammingToZoneInformation(
                                    copiedZoneInformationRecord,
                                    zoneInformationRecord,
                                    allPossibleWirelessNumbers
                                  );
                                }
                              });
                            })
                          }
                          onRemove={removeSelectedZone}
                        >
                          <XFZoneInformationsFields
                            key={zoneInformation.id}
                            zoneInformation={zoneInformation}
                          />
                        </ProgrammingConceptForm.SelectedItem>
                      </RemountOnUpdateContainer>
                    )
                )}
              </ProgrammingConceptForm.SelectedItemsContainer>
            </ProgrammingConceptForm.ListItemsContainer>
          )}
        </ProgrammingConceptForm>
      </SystemOptionsContextProvider>
    </PanelContextProvider>
  );
}

const applyNewZoneToZoneInformationsList = (
  controlSystem: {
    readonly panel: {
      readonly id: string;
      readonly softwareVersion: string;
    };
  } & Parameters<typeof getNextAvailableNumber>[0],
  store: RecordSourceProxy
) => {
  const { id } = controlSystem.panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newZoneRecord = panelRecord.getLinkedRecord("newZone");
    if (newZoneRecord) {
      const nextNumber = getNextAvailableNumber(controlSystem);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromZoneId(asID(newZoneRecord.getDataID()));
        const nextNewZoneId = idAsString(toZoneId(systemId, nextNumber));
        const nextNewZone = store.create(
          nextNewZoneId,
          "Zone"
        ) as RecordProxy<Zone>;
        nextNewZone.copyFieldsFrom(newZoneRecord);
        nextNewZone.setValue(nextNewZoneId, "id");
        nextNewZone.setValue(nextNumber, "number");
        nextNewZone.setValue(true, "isNew");
        const zoneInformationRecords =
          panelRecord.getLinkedRecords("zoneInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...zoneInformationRecords, nextNewZone],
          "zoneInformations"
        );

        return nextNewZone.getValue("id");
      }
    }
  }
};

const applyDuplicatedZoneInformationToZoneInformationsList = (
  zoneId: string,
  controlSystem: XFZoneInformationProgrammingConceptForm_controlSystem$data,
  store: RecordSourceProxy
) => {
  const { id } = controlSystem.panel;
  const panelRecord = store.get<Panel>(id);
  const zoneInformationRecord = store.get<Zone>(zoneId);
  const allPossibleWirelessNumbers = new Set(
    getWirelessZoneNumberRange(
      controlSystem.panel.hardwareModel,
      Number(controlSystem.panel.softwareVersion),
      true
    )
  );
  if (panelRecord && zoneInformationRecord) {
    const newZoneRecord = panelRecord.getLinkedRecord("newZone");
    newZoneRecord.setValue(false, "wirelessDisarmDisableEnabled");
    newZoneRecord.setValue(true, "isNew");
    if (newZoneRecord) {
      const isWireless =
        zoneInformationRecord.getValue("wireless") ||
        zoneInformationRecord.getValue("competitorWireless");
      const nextWirelessNumber = getNextAvailableWirelessNumber(controlSystem);
      if (isWireless && isNotNullOrUndefined(nextWirelessNumber)) {
        return setZoneRecordForDuplicating(
          newZoneRecord,
          nextWirelessNumber,
          store,
          zoneInformationRecord,
          panelRecord,
          allPossibleWirelessNumbers
        );
      } else {
        const nextNumber = getNextAvailableNumber(controlSystem);
        if (isNotNullOrUndefined(nextNumber)) {
          return setZoneRecordForDuplicating(
            newZoneRecord,
            nextNumber,
            store,
            zoneInformationRecord,
            panelRecord,
            allPossibleWirelessNumbers
          );
        }
      }
    }
  }
};

const setZoneRecordForDuplicating = (
  newZoneRecord: RecordProxy<Zone>,
  nextNumber: number,
  store: RecordSourceProxy,
  zoneInformationRecord: RecordProxy<Zone>,
  panelRecord: RecordProxy<Panel>,
  allPossibleWirelessNumbers: Set<number>
) => {
  const { systemId } = fromZoneId(asID(newZoneRecord.getDataID()));
  const nextNewZoneId = idAsString(toZoneId(systemId, nextNumber));
  const nextNewZone = store.create(
    nextNewZoneId,
    "ZoneInformation"
  ) as RecordProxy<Zone>;
  nextNewZone.copyFieldsFrom(newZoneRecord);
  nextNewZone.setValue(nextNewZoneId, "id");
  nextNewZone.setValue(nextNumber, "number");
  const duplicatedZoneInformationRecord =
    applyDupedZoneInformationProgrammingToZoneInformation(
      zoneInformationRecord,
      nextNewZone,
      allPossibleWirelessNumbers
    );
  const zoneInformationRecords =
    panelRecord.getLinkedRecords("zoneInformations") ?? [];
  panelRecord.setLinkedRecords(
    [...zoneInformationRecords, duplicatedZoneInformationRecord],
    "zoneInformations"
  );

  return duplicatedZoneInformationRecord.getValue("id");
};

const getAvailableNumbers = (controlSystem: {
  readonly panel: {
    readonly zoneNumberRange: readonly number[];
    readonly zoneInformations: ReadonlyArray<{
      readonly number: string;
    }>;
  };
}) => {
  const { zoneNumberRange } = controlSystem.panel;

  const allPossibleNumbers = new Set(zoneNumberRange);

  const takenNumbers = new Set(
    controlSystem.panel.zoneInformations.map(({ number }) => Number(number))
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  controlSystem: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(controlSystem));

const getAvailableWirelessNumbers = (
  controlSystem: XFZoneInformationProgrammingConceptForm_controlSystem$data
) => {
  const { hardwareModel, softwareVersion } = controlSystem.panel;

  const allPossibleNumbers = new Set(
    getWirelessZoneNumberRange(hardwareModel, Number(softwareVersion), true)
  );

  const takenNumbers = new Set(
    controlSystem.panel.zoneInformations.map(({ number }) => Number(number))
  );

  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableWirelessNumber = (
  controlSystem: XFZoneInformationProgrammingConceptForm_controlSystem$data
) => {
  return setFirst(getAvailableWirelessNumbers(controlSystem));
};

const ZoneListItemLabel = (props: {
  zone: XFZoneInformationProgrammingConceptForm_ZoneListItemLabel_zone$key;
}) => {
  const zone = useFragment(
    graphql`
      fragment XFZoneInformationProgrammingConceptForm_ZoneListItemLabel_zone on Zone {
        id
        name
        number
      }
    `,
    props.zone
  );

  return (
    <>
      #{zone.number} {zone.name}
    </>
  );
};
