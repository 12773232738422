import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { systemOptionsCrossZoneTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import { systemOptionsDetectWirelessJammingFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDetectWirelessJammingField";
import { systemOptionsHoursFromGMTFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import { systemOptionsInspectionReminderFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsInspectionReminderField";
import { systemOptionsLatchedSupervisoryZonesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsLatchedSupervisoryZonesField";
import { systemOptionsObserveDstFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsObserveDstField";
import { systemOptionsPowerFailDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import { systemOptionsPrimaryProgrammingLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPrimaryProgrammingLanguageField";
import { systemOptionsPrimaryUserLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPrimaryUserLanguageField";
import { systemOptionsSecondaryProgrammingLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSecondaryProgrammingLanguageField";
import { systemOptionsSecondaryUserLanguageFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSecondaryUserLanguageField";
import { systemOptionsSwingerBypassTripsFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import { systemOptionsTimeChangeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsTimeChangeField";
import { systemOptionsWirelessAudiblesFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import { systemOptionsWirelessEncryption1100FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100Field";
import { systemOptionsWirelessEncryption1100PassphraseFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessEncryption1100PassphraseField";
import { systemOptionsWirelessHouseCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessHouseCodeField";
import { systemOptionsZoneRetardDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsZoneRetardDelayField";
import { XFSystemOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XFFullProgramming/__generated__/XFSystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getSystemOptionsState } from "../../../XFFullProgramming/XFSystemOptionsProgrammingConceptForm";
import { XfProgrammingTemplateSystemOptionsInput } from "../Create/__generated__/XFTemplateSaveMutation.graphql";
import { XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        systemOptions {
          id
          included
          crossZoneTime {
            included
            data
          }
          detectJam {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          houseCode {
            included
            data
          }
          latchedSupervisoryZones {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          primaryProgrammingLanguage {
            included
            data
          }
          primaryUserLanguage {
            included
            data
          }
          secondaryProgrammingLanguage {
            included
            data
          }
          secondaryUserLanguage {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          timeChange {
            included
            data
          }
          observeDst {
            included
            data
          }
          inspectionReminder {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          wirelessEncryption1100 {
            included
            data
          }
          wirelessEncryption1100Passphrase {
            included
            data
          }
          zoneRetardDelay {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XFSystemOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XfProgrammingTemplateSystemOptionsInput => {
  const {
    panel: { systemOptions },
  } = getSystemOptionsState(controlSystem);

  const systemOptionsInputFields = {
    zoneRetardDelay: {
      included: includedFields.has(systemOptionsZoneRetardDelayFieldId()),
      data: systemOptions.zoneRetardDelay,
    },
    latchedSupervisoryZones: {
      included: includedFields.has(
        systemOptionsLatchedSupervisoryZonesFieldId()
      ),
      data: systemOptions.latchedSupervisoryZones,
    },
    crossZoneTime: {
      included: includedFields.has(systemOptionsCrossZoneTimeFieldId()),
      data: systemOptions.crossZoneTime,
    },
    powerFailDelay: {
      included: includedFields.has(systemOptionsPowerFailDelayFieldId()),
      data: systemOptions.powerFailDelay,
    },
    swingerBypassTrips: {
      included: includedFields.has(systemOptionsSwingerBypassTripsFieldId()),
      data: systemOptions.swingerBypassTrips,
    },
    timeChange: {
      included: includedFields.has(systemOptionsTimeChangeFieldId()),
      data: systemOptions.timeChange,
    },
    observeDst: {
      included: includedFields.has(systemOptionsObserveDstFieldId()),
      data: systemOptions.observeDst,
    },
    inspectionReminder: {
      included: includedFields.has(systemOptionsInspectionReminderFieldId()),
      data: systemOptions.inspectionReminder,
    },
    hoursFromGMT: {
      included: includedFields.has(systemOptionsHoursFromGMTFieldId()),
      data: systemOptions.hoursFromGMT,
    },
    houseCode: {
      included: includedFields.has(systemOptionsWirelessHouseCodeFieldId()),
      data: systemOptions.houseCode,
    },
    detectJam: {
      included: includedFields.has(systemOptionsDetectWirelessJammingFieldId()),
      data: systemOptions.detectJam,
    },
    wirelessAudibles: {
      included: includedFields.has(systemOptionsWirelessAudiblesFieldId()),
      data: systemOptions.wirelessAudibles,
    },
    primaryProgrammingLanguage: {
      included: includedFields.has(
        systemOptionsPrimaryProgrammingLanguageFieldId()
      ),
      data: systemOptions.primaryProgrammingLanguage,
    },
    secondaryProgrammingLanguage: {
      included: includedFields.has(
        systemOptionsSecondaryProgrammingLanguageFieldId()
      ),
      data: systemOptions.secondaryProgrammingLanguage,
    },
    primaryUserLanguage: {
      included: includedFields.has(systemOptionsPrimaryUserLanguageFieldId()),
      data: systemOptions.primaryUserLanguage,
    },
    secondaryUserLanguage: {
      included: includedFields.has(systemOptionsSecondaryUserLanguageFieldId()),
      data: systemOptions.secondaryUserLanguage,
    },
    wirelessEncryption1100: {
      included: includedFields.has(
        systemOptionsWirelessEncryption1100FieldId()
      ),
      data: systemOptions.wirelessEncryption1100,
    },
    wirelessEncryption1100Passphrase: {
      included: includedFields.has(
        systemOptionsWirelessEncryption1100PassphraseFieldId()
      ),
      data: systemOptions.wirelessEncryption1100Passphrase,
    },
  };

  const systemOptionsIncluded = Object.values(systemOptionsInputFields).some(
    (field) => field.included
  );

  return {
    included: systemOptionsIncluded,
    ...systemOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { systemOptions } = getState(template);

  if (!systemOptions) return;
  if (systemOptions.crossZoneTime?.included) {
    setIncludedFields(setToggle(systemOptionsCrossZoneTimeFieldId()));
  }
  if (systemOptions.detectJam?.included) {
    setIncludedFields(setToggle(systemOptionsDetectWirelessJammingFieldId()));
  }
  if (systemOptions.hoursFromGMT?.included) {
    setIncludedFields(setToggle(systemOptionsHoursFromGMTFieldId()));
  }
  if (systemOptions.houseCode?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessHouseCodeFieldId()));
  }
  if (systemOptions.latchedSupervisoryZones?.included) {
    setIncludedFields(setToggle(systemOptionsLatchedSupervisoryZonesFieldId()));
  }
  if (systemOptions.powerFailDelay?.included) {
    setIncludedFields(setToggle(systemOptionsPowerFailDelayFieldId()));
  }
  if (systemOptions.primaryProgrammingLanguage?.included) {
    setIncludedFields(
      setToggle(systemOptionsPrimaryProgrammingLanguageFieldId())
    );
  }
  if (systemOptions.primaryUserLanguage?.included) {
    setIncludedFields(setToggle(systemOptionsPrimaryUserLanguageFieldId()));
  }
  if (systemOptions.secondaryProgrammingLanguage?.included) {
    setIncludedFields(
      setToggle(systemOptionsSecondaryProgrammingLanguageFieldId())
    );
  }
  if (systemOptions.secondaryUserLanguage?.included) {
    setIncludedFields(setToggle(systemOptionsSecondaryUserLanguageFieldId()));
  }
  if (systemOptions.swingerBypassTrips?.included) {
    setIncludedFields(setToggle(systemOptionsSwingerBypassTripsFieldId()));
  }
  if (systemOptions.timeChange?.included) {
    setIncludedFields(setToggle(systemOptionsTimeChangeFieldId()));
  }
  if (systemOptions.observeDst?.included) {
    setIncludedFields(setToggle(systemOptionsObserveDstFieldId()));
  }
  if (systemOptions.inspectionReminder?.included) {
    setIncludedFields(setToggle(systemOptionsInspectionReminderFieldId()));
  }
  if (systemOptions.wirelessAudibles?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessAudiblesFieldId()));
  }
  if (systemOptions.wirelessEncryption1100?.included) {
    setIncludedFields(setToggle(systemOptionsWirelessEncryption1100FieldId()));
  }
  if (systemOptions.wirelessEncryption1100Passphrase?.included) {
    setIncludedFields(
      setToggle(systemOptionsWirelessEncryption1100PassphraseFieldId())
    );
  }
  if (systemOptions.zoneRetardDelay?.included) {
    setIncludedFields(setToggle(systemOptionsZoneRetardDelayFieldId()));
  }
};
