import React from "react";
import IcoMoon from "react-icomoon";
import SvgIcon from "../web/Icons/SvgIcon";
import iconSet from "./selection.json";

export type DmpIconName =
  | "345_truck"
  | "346_crosshairs"
  | "4_wire"
  | "8860_keypad"
  | "HR"
  | "action"
  | "add"
  | "alarm_bell_1"
  | "alarm_bell_2"
  | "alarmvision"
  | "alarmvision_software"
  | "all"
  | "all_solid"
  | "apartment"
  | "appliances_off"
  | "appliances_on"
  | "arm_inactivity"
  | "armed_shield_solid"
  | "arming_area"
  | "arming_favorite"
  | "arming_shield"
  | "arming_shield_new_outline"
  | "arrow_back"
  | "arrow_forward"
  | "attention"
  | "authority_stamp"
  | "automation"
  | "automation_software"
  | "award_ribbon"
  | "away"
  | "back_10_seconds"
  | "back_15_seconds"
  | "back_30_seconds"
  | "back_5_seconds"
  | "back_to_camera"
  | "back_to_list"
  | "bank"
  | "bar_graph"
  | "battery_3_horiz"
  | "battery_3_vert"
  | "battery_4_horiz"
  | "battery_4_vert"
  | "battery_empty_horiz"
  | "battery_empty_vert"
  | "battery_full_horiz"
  | "battery_full_vert"
  | "battery_low_horiz"
  | "battery_low_vert"
  | "behavior"
  | "bell_chime"
  | "binoculars"
  | "bluetooth"
  | "bolt"
  | "box_cancel"
  | "box_checked"
  | "box_small_cancel"
  | "box_unchecked"
  | "brain"
  | "brightness"
  | "brightness_dim"
  | "brightness_volume"
  | "bring_to_front"
  | "brochure"
  | "bubble_blank"
  | "bubble_exclamation"
  | "bubble_question"
  | "bullet_camera"
  | "burglary"
  | "bypass"
  | "camera"
  | "camera2"
  | "camera_flip"
  | "camera_motion"
  | "card_reader_1"
  | "card_reader_2"
  | "carousel"
  | "cart"
  | "cd"
  | "cell_signal-full"
  | "cell_signal_4"
  | "cell_signal_5"
  | "cell_signal_6"
  | "cell_signal_7"
  | "cell_tower"
  | "central_station"
  | "central_station_communication"
  | "chatbot"
  | "check_in_protection"
  | "checkmark"
  | "child"
  | "chime"
  | "christmas_bulb"
  | "christmas_tree"
  | "church"
  | "circle_arrow_down"
  | "circle_arrow_left"
  | "circle_arrow_right"
  | "circle_arrow_up"
  | "circle_cancel"
  | "circle_pointer_down"
  | "circle_pointer_left"
  | "circle_pointer_right"
  | "circle_pointer_up"
  | "city"
  | "class_b_circuit"
  | "cleaning"
  | "clip"
  | "clips_with_play"
  | "close_cancel"
  | "cloud"
  | "cloud_download"
  | "cloud_upload"
  | "communication_tower_left"
  | "communication_tower_right"
  | "company_store_keypad"
  | "compliance_services"
  | "computer_pointer"
  | "computer_w_pointer"
  | "confluence_home"
  | "confluence_index"
  | "control_down"
  | "control_left"
  | "control_right"
  | "control_up"
  | "credential_reader"
  | "credit_card_back"
  | "credit_card_front"
  | "critical_thinking"
  | "cyber_security"
  | "cycle"
  | "cycle_clockwise"
  | "date"
  | "date_time"
  | "dealer_information"
  | "delivery"
  | "desk"
  | "device"
  | "diagnostics_1"
  | "diagnostics_2"
  | "dinner"
  | "dispose_trash"
  | "distributor"
  | "dmp_building"
  | "dmp_guide"
  | "dmpmoji_smile"
  | "doc"
  | "doc_file"
  | "doc_pdf"
  | "dog"
  | "dollar_bill"
  | "dome_camera"
  | "door"
  | "door_chime"
  | "door_closed"
  | "door_contact"
  | "door_lockdown"
  | "doorbell"
  | "download"
  | "download2"
  | "edit"
  | "edit_2"
  | "encrypted_wireless"
  | "exclamation"
  | "exit"
  | "exit_fullscreen"
  | "exterior_light"
  | "ez_connect"
  | "ez_connect_with_cell"
  | "false_alarm"
  | "family"
  | "fan"
  | "fast_forward"
  | "fast_start_program"
  | "favorite"
  | "favorite_anim_1"
  | "favorite_anim_2"
  | "favorite_anim_3"
  | "favorite_anim_4"
  | "filter"
  | "fingerprint"
  | "fire"
  | "fitness"
  | "flash_flood"
  | "foggy"
  | "folder"
  | "forward_10_seconds"
  | "forward_15_seconds"
  | "forward_30_seconds"
  | "forward_5_seconds"
  | "game"
  | "garage_1"
  | "garage_2"
  | "garage_3"
  | "garage_4"
  | "garage_closed"
  | "garage_open"
  | "gauge"
  | "globe_lined"
  | "globe_with_pointer"
  | "goals"
  | "government"
  | "graph_line"
  | "graphics_keypad"
  | "hand_sanitizer"
  | "hands_free_open_door"
  | "handshake"
  | "handshake_dealer_admin"
  | "headset_monitoring"
  | "heart_checkmark"
  | "history"
  | "home"
  | "honeywell_panel"
  | "hotspot"
  | "id_badge_profiles"
  | "id_card_profiles"
  | "id_cards_profiles"
  | "install_guide"
  | "installer"
  | "installer_vertical"
  | "instant_arming"
  | "insurance"
  | "interview"
  | "invoice_stuffer"
  | "jewelry_store"
  | "key"
  | "keyfob_1"
  | "keyfob_2"
  | "keypad_screen"
  | "laptop"
  | "leaf"
  | "legal_services"
  | "light"
  | "light_solid"
  | "like"
  | "line_graph"
  | "link"
  | "live"
  | "location"
  | "location_solid"
  | "lock_locked"
  | "lock_unlocked"
  | "logger"
  | "low_cell_signal"
  | "magnify_left"
  | "magnify_right"
  | "mail"
  | "man_with_tie"
  | "manual"
  | "map"
  | "map_dealer_base"
  | "master"
  | "measurement_yard"
  | "megaphone"
  | "menu"
  | "menu_2"
  | "menu_list"
  | "menu_sliders"
  | "microphone"
  | "microphone_mute"
  | "military"
  | "missouri"
  | "mobile_credentials"
  | "modem"
  | "mood"
  | "more"
  | "motion"
  | "move_system"
  | "movie_night"
  | "music"
  | "nac_circuit"
  | "network"
  | "new_install"
  | "new_products__services"
  | "new_tab"
  | "news"
  | "night"
  | "night_partly_cloudy"
  | "no_cell_signal"
  | "no_dishes_cutlery"
  | "no_touching"
  | "notepad"
  | "office_building"
  | "outlet_output"
  | "output_groups"
  | "output_options"
  | "overview"
  | "panel_communication"
  | "paper_towel_dispenser"
  | "partly_cloudy"
  | "pause_circle"
  | "pause_icon"
  | "performance"
  | "perimeter"
  | "pers"
  | "personnel"
  | "phone"
  | "phone-v2"
  | "phone_hang_up"
  | "phone_ring"
  | "phone_v1"
  | "phone_x"
  | "picture_placeholder"
  | "pie_chart"
  | "play"
  | "play_circle"
  | "play_icon"
  | "playbook"
  | "plug"
  | "poe_power_over_ethernet"
  | "pointer"
  | "pointer_solid"
  | "police"
  | "power"
  | "power_appliance"
  | "power_off"
  | "programming-guide"
  | "prox_reader"
  | "public_speaker"
  | "question"
  | "quick_exit"
  | "radial_add"
  | "radial_alert"
  | "radial_ban"
  | "radial_button"
  | "radial_check"
  | "radial_empty"
  | "radial_facebook"
  | "radial_info"
  | "radial_mail"
  | "radial_question"
  | "radial_solid"
  | "radial_subtract"
  | "radial_twitter"
  | "radial_warning"
  | "rainy"
  | "real_alarm"
  | "remote_update"
  | "reorder"
  | "reset"
  | "residential"
  | "residential_lockdown"
  | "resize"
  | "rewind"
  | "rooms"
  | "rotate_left"
  | "rotate_right"
  | "sa_backpack_reward"
  | "sa_briefcase_reward"
  | "sa_card_holder_reward"
  | "sa_coin_reward"
  | "sa_jacket_reward"
  | "sa_notepad_reward"
  | "sa_watch_reward"
  | "sales"
  | "sales_team"
  | "school"
  | "scif_secure_room"
  | "screen_resize"
  | "sd_card"
  | "search_left"
  | "search_right"
  | "secura_keypad"
  | "secura_locked"
  | "secura_unlocked"
  | "securecom"
  | "security_command_keypad"
  | "security_gateway"
  | "security_keypad"
  | "security_panel"
  | "security_sensor"
  | "security_sign"
  | "security_siren"
  | "sensors_1106"
  | "server_1"
  | "server_2"
  | "service_request"
  | "settings"
  | "share_1"
  | "share_2"
  | "shield_clean"
  | "shipping_box"
  | "sign"
  | "skills"
  | "skip_down"
  | "skip_up"
  | "sleep"
  | "small_biz"
  | "sneeze"
  | "snow"
  | "sort_-2_items"
  | "sort_4_items"
  | "sort_6_items"
  | "sort_horizontal"
  | "sort_vertical"
  | "speaker_1"
  | "speaker_2"
  | "speaker_aligned_right"
  | "speaker_full"
  | "speaker_mute"
  | "speaker_no_sound"
  | "speaker_off"
  | "spotlight"
  | "stay_home"
  | "stop"
  | "storm"
  | "subtract"
  | "success"
  | "sunny"
  | "sunrise_sunset"
  | "supervised"
  | "support"
  | "switch"
  | "syringe"
  | "takeover"
  | "target"
  | "temperature"
  | "therm_auto"
  | "therm_cool"
  | "therm_heat"
  | "thinline_keypad"
  | "time_clock"
  | "video_timeline"
  | "timer"
  | "tool_hammer"
  | "top_ten_circle"
  | "top_ten_ribbon"
  | "tornado"
  | "touchscreen_annunciator"
  | "training_v2"
  | "trash"
  | "travel"
  | "troubleshooting"
  | "tshirt"
  | "university"
  | "upload"
  | "usa"
  | "user"
  | "user_codes_finger"
  | "user_photo_placeholder"
  | "users"
  | "value_feature"
  | "vault"
  | "visibility"
  | "visibility_off"
  | "voice_integration"
  | "wash_hands"
  | "webinar_series"
  | "wifi"
  | "wifi_disconnected"
  | "wifi_setting_0_secure"
  | "wifi_setting_1"
  | "wifi_setting_1_secure"
  | "wifi_setting_2"
  | "wifi_setting_2_secure"
  | "wifi_setting_3"
  | "wifi_setting_3_secure"
  | "wifi_setting_4"
  | "wifi_setting_4_secure"
  | "wifi_setting_5_secure"
  | "wildfire_warning"
  | "wireless"
  | "world"
  | "xc_cameras"
  | "xtl_plus"
  | "xtltouch"
  | "xtltouch_wall"
  | "zwave"
  | "";

type DmpIconTypes = {
  icon: DmpIconName;
  size?: string | number;
  color?: string;
  style?: React.CSSProperties;
  constrainToParent?: boolean | null | undefined;
};

const DmpIcon = ({
  icon,
  size,
  color,
  style,
  constrainToParent,
}: DmpIconTypes) => {
  const fontsLoaded = {
    IcoMoon: require("./fonts/dmp_icon_font.ttf"),
  };
  if (!fontsLoaded) {
    return null;
  }
  return (
    <>
      {constrainToParent ||
      constrainToParent === null ||
      constrainToParent === undefined ? (
        <SvgIcon style={style}>
          <IcoMoon iconSet={iconSet} icon={icon} size={size} color={color} />
        </SvgIcon>
      ) : (
        <IcoMoon
          iconSet={iconSet}
          icon={icon}
          size={size}
          color={color}
          style={style}
        />
      )}
    </>
  );
};

export default DmpIcon;
