/**
 * @generated SignedSource<<18ebc92e8adb2d13376345deddc3f0d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationExpanderSerialNumberField_zone$data = {
  readonly competitorWireless: boolean | null;
  readonly expanderSerialNumber: string | null;
  readonly number: string;
  readonly serialNumber: string;
  readonly wireless: boolean;
  readonly " $fragmentType": "ZoneInformationExpanderSerialNumberField_zone";
};
export type ZoneInformationExpanderSerialNumberField_zone$key = {
  readonly " $data"?: ZoneInformationExpanderSerialNumberField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationExpanderSerialNumberField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationExpanderSerialNumberField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expanderSerialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "competitorWireless",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "753ad4b4dd42861ba8206d11f897937b";

export default node;
