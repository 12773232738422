import DaColors from "app/common/DaColors";
import { GenericPageFallback } from "components/GenericPageFallback";
import Icon from "components/Icon";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

/**
 *  This component is used on the login page and the system information page.
 * The login page uses the component to add a v-6000 camera to wifi only.
 * The system information page uses the component to add a v-6000 camera to wifi or hardwired
 * and the control system.
 */
interface AddCameraModalProps {
  handleModalClose: () => void;
  userService: any;
  state: any;
  scope: any;
  videoDeviceService: any;
  controlSystemService: any;
}

const AddCameraModal: React.FC<AddCameraModalProps> = ({
  handleModalClose,
  userService,
  state,
  scope,
  videoDeviceService,
  controlSystemService,
}) => {
  const [macOrSerialNumber, setMacNumber] = useState<string>("");
  const [showMacInput, setShowMacInput] = useState<boolean>(false);
  const [showWifiInputs, setShowWifiInputs] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any | undefined>(undefined);
  const [ssid, setSsid] = useState<string>("");
  const [passphrase, setPassphrase] = useState<string>("");
  const [generatedQRCode, setGeneratedQRCode] = useState<string | null>(null);

  useEffect(() => {
    const modalDialogElement = document.getElementById("modal-dialog");
    if (modalDialogElement) {
      modalDialogElement.style.top = "50%";
      modalDialogElement.style.transform = "translate(0, -50%)";
      //This stops the scrolling of the main page behind the modal and the flickering of the page
      //when clicking into an input box in the modal.
      document.body.style.position = "fixed";
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.position = "";
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleHardwireClick = () => {
    setShowMacInput(true);
    setShowWifiInputs(false);
  };

  const handleMacNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMacNumber(event.target.value.toUpperCase());
  };

  const handleMacNumberSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    let pollingDuration = 0;
    const POLLING_INTERVAL = 3000; // Polling interval in milliseconds (3 seconds)
    const MAX_POLLING_DURATION = 180000; // Maximum polling duration in milliseconds (3 minutes)

    let success = false; // Flag to indicate if the polling was successful

    while (pollingDuration < MAX_POLLING_DURATION && !success) {
      setShowLoading(true);
      try {
        const response = await videoDeviceService.searchAvailable(
          `${macOrSerialNumber}`
        );

        const { manufacturer, device_type, id } = response;

        const savedVideoDevice = await videoDeviceService.save(response);

        if (savedVideoDevice && response) {
          const videoDevices = await controlSystemService.getVideoDevices(
            userService.control_system_id
          );

          const channelId = videoDevices.filter(
            (videoDevice: any) => videoDevice.id === id
          )[0].channels[0].id;

          if (scope.userServiceHasSite) {
            const app = ["Uniview", "Malmoset"].includes(manufacturer)
              ? "app.uniview-camera-edit-sites"
              : `app.edit-${device_type}-sites`;

            state.go(app, {
              customer_id: state?.params?.customer_id,
              site_id: scope.siteIdFromUserService,
              device_id: id,
              channel_id: channelId,
            });
          } else {
            const app = ["Uniview", "Malmoset"].includes(manufacturer)
              ? "app.uniview-camera-edit"
              : `app.edit-${device_type}`;

            state.go(app, {
              customer_id: userService.customer_id,
              control_system_id: userService.control_system_id,
              device_id: id,
              channel_id: channelId,
            });
          }
          setShowLoading(false);
          success = true; // Set the success flag to true if the polling was successful
        }
      } catch (error) {
        setErrorData(error);
        pollingDuration += POLLING_INTERVAL;
        await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL));
      }
    }
    //Polling was unsuccessful - Display the error.
    if (!success) {
      setShowLoading(false);
      setShowError(true);
    }
  };

  const handleWiFiClick = () => {
    setShowWifiInputs(true);
    setShowMacInput(false);
  };

  const handleSsidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSsid(event.target.value);
  };

  const handlePassphraseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassphrase(event.target.value);
  };

  const handleWifiSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowWifiInputs(false);

    if (!ssid && !passphrase) {
      setShowMacInput(true);
      return;
    }

    const qrCodeData = `0\n${ssid}\n${passphrase}`;
    setGeneratedQRCode(qrCodeData);
  };

  const handleBackButtonClick = () => {
    setShowMacInput(false);
    setShowWifiInputs(false);
  };

  const CloseButtonX = ({ onClick }: { onClick: () => void }) => (
    <StyledIconCloser onClick={onClick}>
      <Icon name={"close_cancel"} />
    </StyledIconCloser>
  );

  // Get the current url to determine if the user is on the login page
  const currentUrl = window.location.href;
  //check to see if the url has "login" in it
  const isLoginPage = currentUrl.includes("/login");
  // useEffect to trigger  handleWiFiClick() if isLoginPage
  // once on page load
  useEffect(() => {
    if (isLoginPage) {
      handleWiFiClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalWrapper>
      {showLoading ? (
        <LoadingContainer>
          <GenericPageFallback />
        </LoadingContainer>
      ) : (
        <>
          {!generatedQRCode && !showWifiInputs && !showMacInput && (
            <>
              <Heading>
                Add Camera
                <CloseButtonX onClick={handleModalClose}></CloseButtonX>
              </Heading>
              {!isLoginPage ? (
                <TextBody>Select the connection type for this camera.</TextBody>
              ) : (
                <></>
              )}
            </>
          )}
          {showMacInput ? (
            <form onSubmit={handleMacNumberSubmit}>
              <FormContent>
                <Heading>
                  Add Hardwired Camera
                  <CloseButtonX onClick={handleModalClose}></CloseButtonX>
                </Heading>
                <TextBody>
                  Enter the Serial Number or MAC address of the camera once it
                  has joined the network.
                </TextBody>
                <br />
                <Input
                  type="text"
                  placeholder="Enter Mac Address Or Serial Number"
                  value={macOrSerialNumber}
                  onChange={handleMacNumberChange}
                  required
                  autoComplete="off"
                />
                <ButtonContainer>
                  <DirectionalButton onClick={handleBackButtonClick}>
                    Back
                  </DirectionalButton>
                  <DirectionalButton type="submit">Submit</DirectionalButton>
                </ButtonContainer>
              </FormContent>
            </form>
          ) : showWifiInputs ? (
            <form onSubmit={handleWifiSubmit}>
              <FormContent>
                {!isLoginPage ? (
                  <>
                    <Heading>
                      V-4000 Series & Legacy
                      <CloseButtonX onClick={handleModalClose}></CloseButtonX>
                    </Heading>
                    <TextBody>
                      Add the camera to Wi-Fi by pressing and holding the
                      WPS/RESET button on the camera for three seconds while the
                      router is in WPS mode, then select 'Next'.
                    </TextBody>
                    <br />
                  </>
                ) : null}

                <Heading>
                  Add V-6000 Series Device
                  {isLoginPage ? (
                    <CloseButtonX onClick={handleModalClose}></CloseButtonX>
                  ) : null}
                </Heading>

                <TextBody>
                  Enter the Wi-Fi network's SSID and passphrase below, then
                  select 'Next', to generate a QR Code.
                </TextBody>

                <Input
                  type="text"
                  placeholder="Enter SSID"
                  value={ssid}
                  onChange={handleSsidChange}
                  autoComplete="off"
                  required={isLoginPage ? true : false}
                />
                <Input
                  type="password"
                  placeholder="Enter Passphrase"
                  value={passphrase}
                  onChange={handlePassphraseChange}
                  autoComplete="off"
                  required={isLoginPage ? true : false}
                />
                <ButtonContainer>
                  {isLoginPage ? (
                    <FullWidthDirectionalButton type="submit">
                      Next
                    </FullWidthDirectionalButton>
                  ) : (
                    <>
                      <DirectionalButton onClick={handleBackButtonClick}>
                        Back
                      </DirectionalButton>
                      <DirectionalButton type="submit">Next</DirectionalButton>
                    </>
                  )}
                </ButtonContainer>
              </FormContent>
            </form>
          ) : (
            <>
              {generatedQRCode ? (
                <>
                  <FormContent>
                    <Heading>
                      QR Code
                      <CloseButtonX onClick={handleModalClose}></CloseButtonX>
                    </Heading>
                    <TextBody>
                      Hold your device 6 to 12 inches away from the camera and
                      ensure the QR code is facing the camera.
                      {/* Leave the space in the text below */}
                      {!isLoginPage ? (
                        <>
                          {" "}
                          Select 'Next' after the camera has joined the Wi-Fi
                          network.
                        </>
                      ) : null}
                    </TextBody>
                    <QRCodeContainer>
                      <QRCode value={generatedQRCode} size={200} />
                    </QRCodeContainer>
                    <ButtonContainer>
                      {isLoginPage ? (
                        <FullWidthDirectionalButton onClick={handleModalClose}>
                          Close
                        </FullWidthDirectionalButton>
                      ) : (
                        <FullWidthDirectionalButton
                          onClick={handleHardwireClick}
                        >
                          Next
                        </FullWidthDirectionalButton>
                      )}
                    </ButtonContainer>
                  </FormContent>
                </>
              ) : (
                <ButtonContainer>
                  <DirectionalButton onClick={handleHardwireClick}>
                    HardWired
                  </DirectionalButton>
                  <DirectionalButton onClick={handleWiFiClick}>
                    Wi-Fi
                  </DirectionalButton>
                  <DirectionalButton
                    onClick={() => {
                      scope.$parent.$parent.openNvrModal();
                      handleModalClose();
                    }}
                  >
                    NVR/Converter
                  </DirectionalButton>
                </ButtonContainer>
              )}
              {showError && (
                <ErrorMessage>
                  An error occurred: {errorData?.statusText}
                </ErrorMessage>
              )}
            </>
          )}
        </>
      )}
    </ModalWrapper>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  padding: 0.8rem;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const Heading = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
`;

const TextBody = styled.p`
  text-align: left;
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const DirectionalButton = styled.button`
  padding: 10px 20px;
  width: 50%;
  background-color: ${DaColors.PrimaryBase};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #f5f5f5;
  margin: 0 0 10px 10px;
  cursor: pointer;
`;

const FullWidthDirectionalButton = styled(DirectionalButton)`
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
`;

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FormContent = styled.div`
  padding: 0.8rem;
`;
const ModalWrapper = styled.div`
  padding: 1.6rem;
`;
const StyledIconCloser = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
`;

export default AddCameraModal;
