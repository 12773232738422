import { DaSolidButton } from "components/DaStyledElements";
import GenericSuspenseFallback from "components/GenericSuspenseFallback";
import DistributionSubscriberEulaModal from "components/LoginForm/DistributionSubscriberEulaModal";
import SecurityCommandEulaModal from "components/LoginForm/SecurityCommandEulaModal";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import styled from "styled-components/macro";

import {
  AngularStateProvider,
  useStateDotGo,
} from "components/SystemDiagnostics/AngularStateProvider";
import { UserServiceDAContextProvider } from "contexts/UserServiceDAContext";
import DummyLoginModal from "./DummyLoginModal";

type DistributionSubscriberEulaModalPageTypes = {
  $rootScope: any;
  UserService: any;
  RelayService: any;
  $state: any;
};

export default function EulaAgreementRedirectPageRoot({
  $rootScope,
  UserService,
  RelayService,
  $state,
}: DistributionSubscriberEulaModalPageTypes) {
  const environment = RelayService.getEnvironment();
  const dealerId = UserService.dealer_id;

  const [securityCommandEnabled] = React.useState(
    UserService.enabledSecurityCommand()
  );
  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <React.Suspense fallback={<GenericSuspenseFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <UserServiceDAContextProvider userService={UserService}>
            <AngularStateProvider state={$state}>
              <EulaAgreementRedirectPage
                $state={$state}
                dealerId={dealerId}
                RelayService={RelayService}
                UserService={UserService}
              />
            </AngularStateProvider>
          </UserServiceDAContextProvider>
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}

const EulaAgreementRedirectPage = ({
  $state,
  dealerId,
  RelayService,
  UserService,
}: {
  $state: any;
  dealerId: string;
  RelayService: any;
  UserService: any;
}) => {
  const [enabledSecurityCommand] = React.useState(
    UserService.enabledSecurityCommand()
  );

  const nav = useStateDotGo();

  React.useEffect(() => {
    if (enabledSecurityCommand === undefined) {
      nav("page.login", {}, { reload: true });
    }
  }, [enabledSecurityCommand]);

  return enabledSecurityCommand ? (
    <>
      <SecurityCommandEulaModal
        $state={$state}
        dealerId={dealerId}
        RelayService={RelayService}
      />
      <SecurityCommandPicWrapper>
        <PageLoginWrapper>
          <DummyLoginModal></DummyLoginModal>
        </PageLoginWrapper>
      </SecurityCommandPicWrapper>
      <BottomMatterRow>
        <DaSolidButton onClick={() => null}>Terms & Conditions</DaSolidButton>
        <DaSolidButton onClick={() => null}>Privacy Policy</DaSolidButton>
      </BottomMatterRow>
    </>
  ) : (
    <>
      <DistributionSubscriberEulaModal
        $state={$state}
        dealerId={dealerId}
        RelayService={RelayService}
      />
      <DefaultPicWrapper>
        <PageLoginWrapper>
          <DummyLoginModal></DummyLoginModal>
        </PageLoginWrapper>
      </DefaultPicWrapper>
      <BottomMatterRow>
        <DaSolidButton onClick={() => null}>Terms & Conditions</DaSolidButton>
        <DaSolidButton onClick={() => null}>Privacy Policy</DaSolidButton>
      </BottomMatterRow>
    </>
  );
};

const includedPicWrapper = ` 
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center;
  -webkit-background-size: cover;
  background-size: cover;
  `;

const PageLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const DefaultPicWrapper = styled.div`
  ${includedPicWrapper}
  background-image: (
    url("/assets/img/login-backgrounds/LoginScreen_DaDefault.jpg")
  );
  background-image: -webkit-image-set(
    url("/assets/img/login-backgrounds/LoginScreen_DaDefault.webp")
  );
`;

const SecurityCommandPicWrapper = styled.div`
  ${includedPicWrapper}
  background-image: (
    url("/assets/img/login-backgrounds/LoginScreen_SecurityCommand.jpg")
  );
  background-image: -webkit-image-set(
    url("/assets/img/login-backgrounds/LoginScreen_SecurityCommand.webp")
  );
`;

const BottomMatterRow = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function dangerouslyAddToApp() {
  App.component(
    "eulaAgreementRedirectPageRoot",
    react2angular(
      EulaAgreementRedirectPageRoot,

      [],
      ["$rootScope", "UserService", "RelayService", "$state"]
    )
  );
}
