/**
 * @generated SignedSource<<8e9bb4bf22de2693f5377c3332a4dcfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
export type VideoDeviceSettingConfirmation = "API_ERROR" | "CONFIRMATION_ERROR" | "CONFIRMED" | "PENDING" | "SENT" | "TIMEOUT";
export type VideoDeviceStatus = "ACTIVATING" | "ACTIVE" | "ERROR" | "INACCESSIBLE";
import { FragmentRefs } from "relay-runtime";
export type UniviewCameraEditForm_secureComCamera$data = {
  readonly adminPassword: string | null;
  readonly adminUsername: string | null;
  readonly allowCustomerEdit: boolean;
  readonly analyticalDetectionLines: {
    readonly lines: ReadonlyArray<{
      readonly detectPerson: boolean;
      readonly detectVehicle: boolean;
      readonly direction: number;
      readonly endX: number;
      readonly endY: number;
      readonly id: string;
      readonly sensitivity: number;
      readonly startX: number;
      readonly startY: number;
    }>;
  };
  readonly analyticalDetectionRegions: {
    readonly regions: ReadonlyArray<{
      readonly coordinates: ReadonlyArray<{
        readonly x: number;
        readonly y: number;
      }>;
      readonly detectPerson: boolean;
      readonly detectVehicle: boolean;
      readonly id: string;
      readonly sensitivity: number;
    }>;
  };
  readonly ceilingMount: boolean;
  readonly controlSystem: {
    readonly customer: {
      readonly dealer: {
        readonly securityCommand: boolean | null;
        readonly vernaculars: ReadonlyArray<{
          readonly dealerId: number;
          readonly original: string;
          readonly replacement: string;
          readonly scapiId: number;
        } | null>;
      };
    };
    readonly dealerAccess: {
      readonly accessType: string;
    } | null;
    readonly id: string;
    readonly panel: {
      readonly hardwareModel: PanelHardwareModel;
    };
    readonly servicesManager: {
      readonly videoVerificationEnabled: boolean;
    };
  } | null;
  readonly daylightSaving: boolean;
  readonly firmwareVersion: string | null;
  readonly generation: string;
  readonly id: string;
  readonly includeInVerification: boolean;
  readonly ipAddress: string | null;
  readonly isOnline: boolean;
  readonly keypadVisible: boolean;
  readonly lastCheckIn: string | null;
  readonly macAddress: string;
  readonly manufacturer: string;
  readonly maxAnalyticalDetectionLines: number;
  readonly maxAnalyticalDetectionRegions: number;
  readonly maxMotionDetectionRegions: number;
  readonly motionDetectionRegions: {
    readonly regions: ReadonlyArray<{
      readonly coordinates: ReadonlyArray<{
        readonly x: number;
        readonly y: number;
      }>;
      readonly dayObjectSize: number;
      readonly daySensitivityLevel: number;
      readonly id: string;
      readonly nightObjectSize: number;
      readonly nightSensitivityLevel: number;
    }>;
  };
  readonly name: string;
  readonly recordOnAlarm: boolean;
  readonly serialNumber: string | null;
  readonly settingConfirmation: VideoDeviceSettingConfirmation | null;
  readonly snapshot: string | null;
  readonly status: VideoDeviceStatus;
  readonly timeZone: string;
  readonly videoAnalyticsEnabled: boolean;
  readonly videoAnalyticsSupported: boolean;
  readonly videoQuality: string;
  readonly wirelessSignalStrength: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"OutOfSyncBanner_secureComCamera" | "UniviewAnalyticalDetectionElements_secureComCamera" | "UniviewMotionDetectionRegions_secureComCamera">;
  readonly " $fragmentType": "UniviewCameraEditForm_secureComCamera";
};
export type UniviewCameraEditForm_secureComCamera$key = {
  readonly " $data"?: UniviewCameraEditForm_secureComCamera$data;
  readonly " $fragmentSpreads": FragmentRefs<"UniviewCameraEditForm_secureComCamera">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "x",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "y",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sensitivity",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detectPerson",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detectVehicle",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UniviewCameraEditForm_secureComCamera",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "macAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeZone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastCheckIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wirelessSignalStrength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firmwareVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminUsername",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminPassword",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "daylightSaving",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ceilingMount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recordOnAlarm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includeInVerification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manufacturer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowCustomerEdit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingConfirmation",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutOfSyncBanner_secureComCamera"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keypadVisible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoQuality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoAnalyticsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoAnalyticsSupported",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "snapshot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystem",
      "kind": "LinkedField",
      "name": "controlSystem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DealerAccess",
          "kind": "LinkedField",
          "name": "dealerAccess",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accessType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Panel",
          "kind": "LinkedField",
          "name": "panel",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hardwareModel",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ServicesManager",
          "kind": "LinkedField",
          "name": "servicesManager",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoVerificationEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Customer",
          "kind": "LinkedField",
          "name": "customer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Dealer",
              "kind": "LinkedField",
              "name": "dealer",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Vernacular",
                  "kind": "LinkedField",
                  "name": "vernaculars",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scapiId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dealerId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "original",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "replacement",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "securityCommand",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxMotionDetectionRegions",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UniviewMotionDetectionRegions_secureComCamera"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MotionDetectionRegions",
      "kind": "LinkedField",
      "name": "motionDetectionRegions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MotionDetectionRegion",
          "kind": "LinkedField",
          "name": "regions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "MotionDetectionRegionCoordinates",
              "kind": "LinkedField",
              "name": "coordinates",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "daySensitivityLevel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nightSensitivityLevel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dayObjectSize",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nightObjectSize",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UniviewAnalyticalDetectionElements_secureComCamera"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxAnalyticalDetectionLines",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalyticalDetectionLines",
      "kind": "LinkedField",
      "name": "analyticalDetectionLines",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnalyticalDetectionLine",
          "kind": "LinkedField",
          "name": "lines",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "direction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startX",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startY",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endX",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endY",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxAnalyticalDetectionRegions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalyticalDetectionRegions",
      "kind": "LinkedField",
      "name": "analyticalDetectionRegions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnalyticalDetectionRegion",
          "kind": "LinkedField",
          "name": "regions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AnalyticalDetectionRegionCoordinates",
              "kind": "LinkedField",
              "name": "coordinates",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SecureComCamera",
  "abstractKey": null
};
})();

(node as any).hash = "d4b067be82b3ec441c9231655c05179d";

export default node;
