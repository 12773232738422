import CSSIcon from "components/CSSIcon";
import {
  IndicatorState,
  indicatorStyles,
} from "components/FullProgramming/utils/styles";
import * as React from "react";
import styled, { css } from "styled-components";
import { focusRing } from "utils/styles";

// We need to have an inner span in case `as` is `"button"`.
// Safari does not render buttons properly if they have `display: flex`.
const Inner = styled.span<{
  indicator?: IndicatorState | null | undefined;
}>`
  display: flex;
  align-items: center;

  &::after {
    content: "";
    display: inline-block;
    ${indicatorStyles};
  }
`;

const Icon = styled(CSSIcon)`
  font-size: var(--measure-font-16);
  margin-right: var(--measure-half);
`;

const Text = styled.span`
  font-size: var(--measure-font-14);
  font-weight: 600;
  white-space: nowrap;
  color: black;
`;

type RootProps = { selected?: boolean; count?: number | null | undefined };

const rootStyle = css<RootProps>`
  position: relative;
  display: block;
  width: 100%;
  padding: var(--measure-1x) var(--measure-4x) var(--measure-1x)
    var(--measure-2x);
  background-color: ${({ selected }) =>
    selected ? "var(--color-neutral-100)" : "transparent"};
  color: ${({ selected }) =>
    selected ? "var(--color-text-default)" : "var(--color-neutral-900)"};
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--color-info-900);
    transition: 0.25s transform;
    transform: translateX(${({ selected }) => (selected ? 0 : -100)}%);
    z-index: 1;
  }

  &::after {
    content: "${({ count }) => count || ""}";
    display: block;
    position: absolute;
    top: 50%;
    right: var(--measure-2x);
    transform: translateY(-50%);
    padding: 0 var(--measure-half);
    min-width: 3rem;
    border-radius: 9999px;
    white-space: nowrap;
    line-height: 2;
    font-size: var(--measure-font-10);
    font-weight: 600;
    text-align: center;
    background-color: ${({ selected }) =>
      selected ? "var(--color-info-900)" : "#DBDDE3"};
    color: ${({ selected }) =>
      selected ? "white" : "var(--color-neutral-900)"};
  }

  &:hover {
    text-decoration: none;
    background-color: var(--color-neutral-100);
    & ${Text} {
      color: var(--color-text-default);
    }
  }

  &:focus {
    ${focusRing};
  }
`;
const RootAnchor = styled.a<RootProps>`
  ${rootStyle};
`;
const RootButton = styled.button<RootProps>`
  ${rootStyle};
  appearance: none;
  border: none;
`;

type SidebarSubnavButtonBaseProps = {
  selected?: boolean;
  indicator?: IndicatorState | null | undefined;
  icon?: React.ReactElement<React.ComponentProps<typeof Icon>>;
  children: React.ReactElement<React.ComponentProps<typeof Text>>;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  count?: number | null | undefined;
};

export type SidebarSubnavButtonAnchorProps = SidebarSubnavButtonBaseProps & {
  as: "a";
  href: string;
};

export type SidebarSubnavButtonButtonProps = SidebarSubnavButtonBaseProps &
  React.HTMLProps<HTMLButtonElement> & {
    as: "button";
  };

export type SidebarSubnavButtonProps =
  | SidebarSubnavButtonAnchorProps
  | SidebarSubnavButtonButtonProps;

function SidebarSubnavButton(props: SidebarSubnavButtonProps) {
  const inner = (
    <Inner indicator={props.indicator}>
      {props.icon}
      {props.children}
    </Inner>
  );

  return propsAreForAnchor(props) ? (
    <RootAnchor
      href={props.href}
      onClick={props.onClick}
      selected={props.selected}
      count={props.count}
    >
      {inner}
    </RootAnchor>
  ) : (
    <RootButton
      onClick={props.onClick}
      selected={props.selected}
      type="button"
      count={props.count}
    >
      {inner}
    </RootButton>
  );
}

SidebarSubnavButton.Icon = Icon;
SidebarSubnavButton.Text = Text;

export default SidebarSubnavButton;

const propsAreForAnchor = (
  props: SidebarSubnavButtonProps
): props is SidebarSubnavButtonAnchorProps => props.as === "a";
