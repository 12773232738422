/**
 * SiteForm Entry Point
 *
 * Using data from AngularJS this prepares the data and setup for React
 */

import React, { useCallback, useMemo } from "react";

import { RelayEnvironmentProvider } from "react-relay/hooks";
import Relay from "relay-runtime";

import { react2angular } from "react2angular";

import { App } from "app-module";

import { isNotNullOrUndefined } from "common/utils/universal/function";
import { AngularStateProvider } from "components/SystemDiagnostics/AngularStateProvider";
import { EventEmitter } from "events";
import EntryPointContextProvider from "./EntryPointContext";
import FormEntry from "./FormEntry";
import RedirectTo404 from "./RedirectTo404";
import SuspenseFallback from "./SuspenseFallback";
import {
  AngularCountryCodes,
  GenericControlSystemState,
  Notification,
  UserServiceType,
} from "./types";

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{ fallback: (error: any) => React.ReactNode }>,
  { error: any }
> {
  state = { error: null };
  componentDidCatch(error: any) {
    this.setState({ error });
  }
  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;
    if (error) {
      return fallback(this.state);
    }
    return children;
  }
}

type EditProps = {
  siteId: string;
  controlSystem: never;
  mergeFromReactState: never;
  openSecurecomNvrModal: (controlSystemId: string) => void;
  openSecurecomCameraModal: (controlSystemId: string) => void;
  openHikvisionNvrModal: (ControlSystemId: string) => void;
  openDwSpectrumModal: (ControlSystemId: string) => void;
  openLoginAsCustomerModal: (ControlSystemId: string) => void;
  reactivateVideoDevice: (serialNumber: string) => Promise<void>;
  getAngularRouteLink: (route: string, params: any) => string;
  testVideoDeviceConnection: (deviceId: string) => Promise<string>;
  eventEmitter: EventEmitter;
  setSiteName: () => void;
};

type AddProps = {
  siteId: never;
  controlSystem: {
    name: string;
    nickname: string;
    customer_id: string;
    control_system_id: string;
    id: null | string;
    address_1: null | string;
    address_2: null | string;
    city: null | string;
    state: null | string;
    country: null | string;
    postal_code: null | string;
    panels: { hardware_model: string }[];
  };
  mergeFromReactState: (state: GenericControlSystemState) => void;
  openSecurecomNvrModal: never;
  openSecurecomCameraModal: never;
  openHikvisionNvrModal: never;
  openDwSpectrumModal: never;
  openLoginAsCustomerModal: never;
  eventEmitter: never;
  setSiteName: () => void;
  reactivateVideoDevice: never;
  testVideoDeviceConnection: never;
  getAngularRouteLink: (route: string, params: any) => string;
};

type BaseProps = {
  UserService: UserServiceType;
  COUNTRY_CODES: AngularCountryCodes;
  STATE_CODES: { [key: string]: string };
  PROPS: { LEVELUP_DEALERS: number[] };
  RelayService: { getEnvironment: () => Relay.Environment };
  $rootScope: { alerts: any[]; $apply: () => void };
  $location: { updateWithoutReload: (path: string) => void };
  $state: {
    go: (
      path: string,
      params?: { [key: string]: any },
      options?: {
        location?: boolean | "replace";
        inherit?: boolean;
        relative?: boolean;
        notify?: boolean;
        reload?: boolean | string | { [key: string]: any };
      }
    ) => void;
  };
  customerId: string;
  RecentActivityService: any;
};

type Props = (BaseProps & AddProps) | (BaseProps & EditProps);

export function AngularEntryPoint({
  reactivateVideoDevice,
  openSecurecomNvrModal,
  openSecurecomCameraModal,
  openHikvisionNvrModal,
  openDwSpectrumModal: openDWSpectrumModal,
  openLoginAsCustomerModal,
  mergeFromReactState,
  getAngularRouteLink,
  testVideoDeviceConnection,
  eventEmitter,
  setSiteName,
  UserService,
  RelayService,
  COUNTRY_CODES: countryCodes = [],
  STATE_CODES: usStateCodes = {},
  PROPS,
  $rootScope,
  $state,
  siteId,
  controlSystem,
  customerId,
  RecentActivityService,
}: Props) {
  const environment = useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <AngularStateProvider state={$state}>
        <EntryPointContextProvider
          value={{
            countryCodes,
            levelUpDealers: PROPS.LEVELUP_DEALERS,
            usStateCodes,
            createNotification: useCallback(
              (notification: Notification) => {
                $rootScope.alerts.push(notification);
                $rootScope.$apply();
              },
              [$rootScope]
            ),
            customerId,
            getAngularRouteLink,
            redirectTo404: useCallback(() => {
              $state.go("page.404");
            }, [$state]),
            openSecurecomNvrModal,
            openSecurecomCameraModal,
            openHikvisionNvrModal,
            openDWSpectrumModal,
            openLoginAsCustomerModal,
            eventEmitter,
            redirectToSite: useCallback(
              (siteId) => {
                $state.go(
                  "app.sites.edit",
                  { customer_id: customerId, site_id: siteId },
                  {
                    location: "replace",
                    notify: false,
                  }
                );
              },
              [$state, customerId]
            ),
            syncAngularState: useCallback(
              (state: GenericControlSystemState) => {
                if (
                  isNotNullOrUndefined(mergeFromReactState) &&
                  state.type &&
                  state.type !== "X001"
                ) {
                  mergeFromReactState(state);
                }
              },
              [mergeFromReactState]
            ),
            genericControlSystemStateFromAngular: {
              address1:
                controlSystem?.address_1 ??
                UserService?.customerInfo?.address1 ??
                "",
              address2:
                controlSystem?.address_2 ??
                UserService?.customerInfo?.address2 ??
                "",
              city:
                controlSystem?.city ?? UserService?.customerInfo?.city ?? "",
              state:
                controlSystem?.state ??
                UserService?.customerInfo?.state_province ??
                "",
              postalCode:
                controlSystem?.postal_code ??
                UserService?.customerInfo?.postal_code ??
                "",
              country:
                controlSystem?.country ??
                UserService?.customerInfo?.country ??
                "",
              type: controlSystem?.panels?.[0]?.hardware_model,
              name: controlSystem?.name || "",
              nickname: controlSystem?.nickname || "",
              useBillingAddress: true,
            },
            reactivateVideoDevice: useCallback(reactivateVideoDevice, [
              reactivateVideoDevice,
            ]),
            UserService,
            testVideoDeviceConnection,
            RecentActivityService,
          }}
        >
          <ErrorBoundary fallback={() => <RedirectTo404 />}>
            <React.Suspense fallback={<SuspenseFallback />}>
              <FormEntry initialSiteId={siteId} setSiteName={setSiteName} />
            </React.Suspense>
          </ErrorBoundary>
        </EntryPointContextProvider>
      </AngularStateProvider>
    </RelayEnvironmentProvider>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "siteForm",
    react2angular(
      AngularEntryPoint,
      [
        "siteId",
        "customerId",
        "controlSystem",
        "mergeFromReactState",
        "openSecurecomNvrModal",
        "openSecurecomCameraModal",
        "openHikvisionNvrModal",
        "openDwSpectrumModal",
        "openLoginAsCustomerModal",
        "reactivateVideoDevice",
        "getAngularRouteLink",
        "testVideoDeviceConnection",
        "eventEmitter",
        "setSiteName",
      ],
      [
        "RelayService",
        "UserService",
        "COUNTRY_CODES",
        "PROPS",
        "STATE_CODES",
        "$rootScope",
        "$state",
        "RecentActivityService",
      ]
    )
  );
}
