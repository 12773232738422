/**
 * @generated SignedSource<<5564641a31cdffa101daa86dbc8f8daf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ZoneArmingStyle = "ARM" | "DISARM" | "MAINTAIN" | "NONE" | "STEP" | "TOGGLE";
export type ZoneChimeSound = "ASCEND" | "DESCEND" | "DOORBELL" | "NONE" | "OFF";
export type ZoneEntryDelayNumber = "FOUR" | "ONE" | "THREE" | "TWO";
export type ZoneMessage = "ALARM" | "CANCEL_AMBUSH" | "DOOR_PROPPED_OPEN" | "LOCAL" | "NONE" | "SENSOR_RESET" | "TROUBLE";
export type ZoneOutputAction = "FOLLOW" | "MOMENTARY" | "NONE" | "PULSE" | "STEADY";
export type ZonePetImmunity = "NONE" | "OFF" | "ON";
export type ZonePirPulseCount = "FOUR" | "NONE" | "TWO";
export type ZonePirSensitivity = "HIGH" | "LOW" | "NONE";
export type ZoneReceiverRoute = "BOTH_RECEIVERS" | "NONE" | "NORMAL" | "RECEIVER_ONE_ONLY" | "RECEIVER_TWO_ONLY";
export type ZoneSensorType = "GLASSBREAK" | "HOLD_UP" | "PIR_OR_RECESSED_DOOR" | "REPEATER" | "SMOKE" | "UNIVERSAL_TRANSMITTER" | "UNKNOWN_DEVICE" | "WIRELESS_FOUR_ZONE_EXPANDER" | "WIRELESS_PIR";
export type ZoneSupervisionTime = "NONE" | "_1440_MIN" | "_240_MIN" | "_3_MIN" | "_60_MIN" | "_720_MIN";
export type ZoneTransmitterContact = "CONTACT_3" | "CONTACT_4" | "EXTERNAL" | "INTERNAL";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type XRZoneInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly zoneInformations: ReadonlyArray<{
    readonly area: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAreasForArmingZone: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOpenActionMessage: {
      readonly data: ZoneMessage | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOpenOutputAction: {
      readonly data: ZoneOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOpenOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedShortActionMessage: {
      readonly data: ZoneMessage | null;
      readonly included: boolean | null;
    } | null;
    readonly armedShortOutputAction: {
      readonly data: ZoneOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly armedShortOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armingStyle: {
      readonly data: ZoneArmingStyle | null;
      readonly included: boolean | null;
    } | null;
    readonly chimeSound: {
      readonly data: ZoneChimeSound | null;
      readonly included: boolean | null;
    } | null;
    readonly competitorWireless: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly contactNumber: {
      readonly data: ZoneTransmitterContact | null;
      readonly included: boolean | null;
    } | null;
    readonly crossZoneEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedOpenActionMessage: {
      readonly data: ZoneMessage | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedOpenOutputAction: {
      readonly data: ZoneOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedOpenOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedShortActionMessage: {
      readonly data: ZoneMessage | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedShortOutputAction: {
      readonly data: ZoneOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedShortOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelayNumber: {
      readonly data: ZoneEntryDelayNumber | null;
      readonly included: boolean | null;
    } | null;
    readonly fastResponseEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly fireBellOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly firePanelSlaveInput: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly followArea: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly location: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly lockdownEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly normallyClosed: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly presignalKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly prewarnKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly priorityZone: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly realTimeStatusEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly receiverRouting: {
      readonly data: ZoneReceiverRoute | null;
      readonly included: boolean | null;
    } | null;
    readonly reportWithAccountNumberForArea: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly retardDelayEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly sensorType: {
      readonly data: ZoneSensorType | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisionTime: {
      readonly data: ZoneSupervisionTime | null;
      readonly included: boolean | null;
    } | null;
    readonly swingerBypassEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly trafficCountEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly type: {
      readonly data: ZoneType | null;
      readonly included: boolean | null;
    } | null;
    readonly wireless: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessContactNormallyOpen: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessDisarmDisableEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessLedEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessPetImmunity: {
      readonly data: ZonePetImmunity | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessPirPulseCount: {
      readonly data: ZonePirPulseCount | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessPirSensitivity: {
      readonly data: ZonePirSensitivity | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneAuditDays: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XRZoneInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XRZoneInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRZoneInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRZoneInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRZoneInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "5704a7343dd5cd36047c2bf5219aa89b";

export default node;
