/**
 * @generated SignedSource<<00aaa72bf674dd3b69f82d309549ab3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationCommType = "KEYPAD_BUS" | "NETWORK" | "NONE" | "WIRELESS";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type RecorderCameraEdit_varHubCamera$data = {
  readonly availableInVideoVerification: boolean;
  readonly camectCameraId: string;
  readonly camectHubId: string;
  readonly cameraName: string;
  readonly controlSystem: {
    readonly customer: {
      readonly dealer: {
        readonly securityCommand: boolean | null;
        readonly vernaculars: ReadonlyArray<{
          readonly dealerId: number;
          readonly original: string;
          readonly replacement: string;
          readonly scapiId: number;
        } | null>;
      };
    };
    readonly dealerAccess: {
      readonly accessType: string;
    } | null;
    readonly id: string;
    readonly panel: {
      readonly deviceInformations: ReadonlyArray<{
        readonly axNumber?: string;
        readonly deviceCommunicationMethod?: DeviceInformationCommType;
        readonly deviceType?: DeviceInformationType;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionLineSettings_panel" | "RecorderCameraDetectionRegionSettings_panel">;
    };
    readonly servicesManager: {
      readonly videoVerificationEnabled: boolean;
    };
    readonly varHubDetectionRegionZoneNumberMax: number | null;
    readonly varHubDetectionRegionZoneNumberMin: number | null;
  } | null;
  readonly databaseCameraId: number;
  readonly detectionLines: ReadonlyArray<{
    readonly id: string;
    readonly slotNumber: number | null;
    readonly zone: {
      readonly number: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionLineSettings_varHubCameraDetectionLine" | "RecorderCameraEdit_varHubCameraDetectionLine">;
  }>;
  readonly detectionRegions: ReadonlyArray<{
    readonly id: string;
    readonly slotNumber: number | null;
    readonly zone: {
      readonly number: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion" | "RecorderCameraEdit_varHubCameraDetectionRegion">;
  }>;
  readonly id: string;
  readonly macAddress: string;
  readonly megapixels: number;
  readonly snapshot: string | null;
  readonly varHub: {
    readonly cameras: ReadonlyArray<{
      readonly adminPassword: string | null;
      readonly adminUserName: string | null;
      readonly id: string;
      readonly ipAddress: string;
      readonly isStreaming: boolean;
      readonly rtspUrl: string | null;
    }>;
    readonly id: string;
    readonly isOnline: boolean;
    readonly maxDetectionLines: number;
    readonly maxDetectionRegions: number;
    readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionLineSettings_varHub" | "RecorderCameraDetectionRegionSettings_varHub">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionElements_varHubCamera" | "RecorderCameraDetectionLineSettings_varHubCamera" | "RecorderCameraDetectionRegionSettings_varHubCamera">;
  readonly " $fragmentType": "RecorderCameraEdit_varHubCamera";
};
export type RecorderCameraEdit_varHubCamera$key = {
  readonly " $data"?: RecorderCameraEdit_varHubCamera$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraEdit_varHubCamera">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotNumber",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Zone",
  "kind": "LinkedField",
  "name": "zone",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detectAnimals",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detectPeople",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detectVehicles",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drawRegionOnAlerts",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "movementDirection",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Zone",
  "kind": "LinkedField",
  "name": "zone",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v4/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "area",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "coordinates",
    "storageKey": null
  }
],
v12 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecorderCameraEdit_varHubCamera",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseCameraId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cameraName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableInVideoVerification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "camectCameraId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "camectHubId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "macAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "snapshot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "megapixels",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VarHubCameraDetectionRegion",
      "kind": "LinkedField",
      "name": "detectionRegions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "RecorderCameraEdit_varHubCameraDetectionRegion",
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "loiteringPersonSeconds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "loiteringVehicleSeconds",
              "storageKey": null
            },
            (v9/*: any*/),
            (v1/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "VarHubCameraRegionGeometry",
              "kind": "LinkedField",
              "name": "geometry",
              "plural": false,
              "selections": (v11/*: any*/),
              "storageKey": null
            },
            (v12/*: any*/)
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VarHubCameraDetectionLine",
      "kind": "LinkedField",
      "name": "detectionLines",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RecorderCameraDetectionLineSettings_varHubCameraDetectionLine"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "RecorderCameraEdit_varHubCameraDetectionLine",
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v1/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "VarHubCameraLineGeometry",
              "kind": "LinkedField",
              "name": "geometry",
              "plural": false,
              "selections": (v11/*: any*/),
              "storageKey": null
            },
            (v12/*: any*/)
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VarHub",
      "kind": "LinkedField",
      "name": "varHub",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxDetectionRegions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxDetectionLines",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOnline",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RecorderCameraDetectionRegionSettings_varHub"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RecorderCameraDetectionLineSettings_varHub"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VarHubCamera",
          "kind": "LinkedField",
          "name": "cameras",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ipAddress",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isStreaming",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rtspUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "adminUserName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "adminPassword",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystem",
      "kind": "LinkedField",
      "name": "controlSystem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "varHubDetectionRegionZoneNumberMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "varHubDetectionRegionZoneNumberMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DealerAccess",
          "kind": "LinkedField",
          "name": "dealerAccess",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accessType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Panel",
          "kind": "LinkedField",
          "name": "panel",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RecorderCameraDetectionRegionSettings_panel"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RecorderCameraDetectionLineSettings_panel"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "deviceInformations",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "axNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deviceType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deviceCommunicationMethod",
                      "storageKey": null
                    }
                  ],
                  "type": "XrDeviceInformation",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ServicesManager",
          "kind": "LinkedField",
          "name": "servicesManager",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoVerificationEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Customer",
          "kind": "LinkedField",
          "name": "customer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Dealer",
              "kind": "LinkedField",
              "name": "dealer",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Vernacular",
                  "kind": "LinkedField",
                  "name": "vernaculars",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scapiId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dealerId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "original",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "replacement",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "securityCommand",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecorderCameraDetectionRegionSettings_varHubCamera"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecorderCameraDetectionLineSettings_varHubCamera"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecorderCameraDetectionElements_varHubCamera"
    }
  ],
  "type": "VarConnectedCamera",
  "abstractKey": null
};
})();

(node as any).hash = "6056d1047d1f3ae91a31ecf8b20ff7ae";

export default node;
