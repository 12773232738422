/**
 * @generated SignedSource<<78e716e29dc0703f82e2b0c0c3e44373>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AllowTimeChange = "NO" | "SERVER" | "YES";
export type InspectionReminder = "NONE" | "ONE_YEAR" | "SIX_MONTHS" | "THREE_MONTHS";
export type PanelProgrammingLanguage = "CZECH" | "DUTCH" | "ENGLISH" | "FRENCH" | "GREEK" | "NONE" | "SPANISH";
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
export type WirelessEncryption1100 = "ALL" | "BOTH" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts$data = {
  readonly systemOptions: {
    readonly crossZoneTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly detectJam: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly hoursFromGMT: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly houseCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly inspectionReminder: {
      readonly data: InspectionReminder | null;
      readonly included: boolean | null;
    } | null;
    readonly latchedSupervisoryZones: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly observeDst: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly powerFailDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly primaryProgrammingLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly primaryUserLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly secondaryProgrammingLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly secondaryUserLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly swingerBypassTrips: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly timeChange: {
      readonly data: AllowTimeChange | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessAudibles: {
      readonly data: WirelessAudibles | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessEncryption1100: {
      readonly data: WirelessEncryption1100 | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessEncryption1100Passphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneRetardDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts";
};
export type XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "ff493009bdbb0dcbd571ba0f9e458e87";

export default node;
