import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntrePassphraseField_remoteOptions$key } from "./__generated__/RemoteOptionsEntrePassphraseField_remoteOptions.graphql";

export const remoteOptionsEntrePassphraseFieldId = () =>
  "remote-options-entre-passphrase";

function RemoteOptionsEntrePassphraseField() {
  const [{ entrePassphrase, entreConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsEntrePassphraseField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsEntrePassphraseField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            entrePassphrase
            entreConnection
          }
          ... on XfRemoteOptions {
            entrePassphrase
            entreConnection
          }
          ... on TMSentryRemoteOptions {
            entrePassphrase
            entreConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsEntrePassphraseFieldId();
  const disabled = entreConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Entré Passphrase"
      tooltip="Enter a passphrase to encrypt communications with Entré."
    >
      <TextInput
        id={fieldId}
        value={entrePassphrase ?? ""}
        disabled={disabled}
        maxLength={16}
        pattern={"([A-Z0-9 .*#\\-]{8,16}|)"}
        inlineHelp="Accepts 8 to 16 characters: A-Z 0-9 .*#-"
        validationMessage="Accepts 8 to 16 characters: A-Z 0-9 .*#-"
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "entrePassphrase")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntrePassphraseField;
