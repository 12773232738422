import graphql from "babel-plugin-relay/macro";
import Icon from "components/Icon";
import TextInput from "components/Inputs/TextInput";
import Modal from "components/Modal";
import SettingsOption from "components/RecorderEdit/SettingsOption";
import { useShowAlert } from "contexts/AlertsContext";
import { default as React, useRef } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import {
  asID,
  fromControlSystemId,
  idAsString,
  toVarHubId,
} from "securecom-graphql/client";
import styled from "styled-components";
import { RecorderAddModalAddVarHubMutation } from "./__generated__/RecorderAddModalAddVarHubMutation.graphql";
import { RecorderAddModalQuery } from "./__generated__/RecorderAddModalQuery.graphql";
import { RecorderAddModalUpdateVarHubMutation } from "./__generated__/RecorderAddModalUpdateVarHubMutation.graphql";

interface VarHubData {
  hubId: number;
  macAddress: string;
  hubName: string;
  timeLocation: string;
  secondaryNetworkType: string;
  secondaryNetworkIP: string;
  secondaryNetworkSubnetMask: string;
}

type SecondaryNetworkTypeMapping = {
  [key: string]: string;
};

const secondaryNetworkTypes: SecondaryNetworkTypeMapping = {
  "DHCP (default)": "dhcp",
  "Static IP Address": "staticip",
};

const RecorderAddModal = ({
  UserService,
  systemId,
}: {
  UserService: any;
  systemId: string;
}) => {
  const macRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const staticIpRef = useRef<HTMLInputElement>(null);
  const subnetMaskRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [macError, setMacError] = React.useState("");
  const [recorderNameError, setRecorderNameError] = React.useState("");
  const [newHubId, setNewHubId] = React.useState("");

  const [showNetworkModal, setShowNetworkModal] = React.useState(false);
  const [varStaticIp, setVarStaticIp] = React.useState("");
  const [varSubnetMask, setVarSubnetMask] = React.useState("");
  const [staticIpError, setStaticIpError] = React.useState("");
  const [subnetMaskError, setSubnetMaskError] = React.useState("");

  function convertNetworkTypeToDescription(value: string): string {
    const correspondingKey: string | undefined = Object.keys(
      secondaryNetworkTypes
    ).find((key) => secondaryNetworkTypes[key] === value);
    return correspondingKey ? correspondingKey : "Static IP Address";
  }

  const [varHubData, setVarHubData] = React.useState<VarHubData | null>(null);

  const [secondaryNetworkOption, setSecondaryNetworkOption] = React.useState({
    type: varHubData?.secondaryNetworkType ?? "dhcp",
    description: varHubData?.secondaryNetworkType
      ? convertNetworkTypeToDescription(varHubData?.secondaryNetworkType)
      : "DHCP (default)",
  });

  const handleDropdownChange = (selectedOption: string) => {
    const type = secondaryNetworkTypes[selectedOption];
    setSecondaryNetworkOption({
      type: type,
      description: selectedOption,
    });
  };

  const pattern = "^([0-9a-fA-F]{2}[:.-]?){5}[0-9a-fA-F]{2}$";
  const ipPattern = "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$";

  const showAlert = useShowAlert();

  const handleSubmitStaticIP = (e: React.MouseEvent<HTMLButtonElement>) => {
    setStaticIpError(
      staticIpRef.current?.validity.valueMissing
        ? "Error: Please enter a static IP address."
        : staticIpRef.current?.validity.patternMismatch
        ? "Invalid IP Address Configuration: Please enter a valid IP address."
        : ""
    );
    setSubnetMaskError(
      subnetMaskRef.current?.validity.valueMissing
        ? "Error: Please enter a subnet mask."
        : subnetMaskRef.current?.validity.patternMismatch
        ? "Invalid Subnet Mask: Please enter a valid subnet mask."
        : ""
    );

    updateVarHub({
      variables: {
        hub: {
          hubId: String(varHubData?.hubId ?? ""),
          systemId: fromControlSystemId(asID(systemId)).systemId,
          timeLocation: varHubData?.timeLocation ?? "",
          hubName: varHubData?.hubName ?? "",
          secondaryNetworkType:
            secondaryNetworkOption.type ??
            varHubData?.secondaryNetworkType ??
            "",
          secondaryNetworkIP:
            varStaticIp ?? varHubData?.secondaryNetworkIP ?? "",
          secondaryNetworkSubnetMask:
            varSubnetMask ?? varHubData?.secondaryNetworkSubnetMask ?? "",
        },
      },
      onCompleted: (response) => {
        if (response.updateVarHub.__typename === "UpdateVarHubSuccessPayload") {
          showAlert({
            type: "success",
            text: "Successfully updated XV Gateway Secondary Network.",
            suppressTitleCasing: true,
          });

          setShowNetworkModal(false);
        } else {
          showAlert({
            type: "error",
            text: "Failed to update XV Gateway Secondary Network.",
            suppressTitleCasing: true,
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Failed to update XV Gateway Secondary Network.",
          suppressTitleCasing: true,
        });
      },
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    setRecorderNameError(
      nameRef.current?.validity.valueMissing ? "Please enter a name" : ""
    );
    setMacError(
      macRef.current?.validity.valueMissing
        ? "Please enter a MAC address"
        : macRef.current?.validity.patternMismatch
        ? "Please enter a valid MAC address"
        : ""
    );

    if (
      !nameRef.current?.validity.valueMissing &&
      !macRef.current?.validity.valueMissing &&
      !macRef.current?.validity.patternMismatch &&
      nameRef.current?.value &&
      macRef.current?.value
    ) {
      addVarHub({
        variables: {
          addVarHubInput: {
            systemId: systemId,
            macAddress: macRef.current?.value,
            hubName: nameRef.current?.value,
          },
        },
        onCompleted: (data) => {
          if (data.addVarHub.__typename === "AddVarHubSuccessPayload") {
            showAlert({
              text: "Successfully added XV Gateway",
              type: "success",
              suppressTitleCasing: true,
            });

            // Instantiate a new VarHubData object with the data received from the API response
            const newVarHubData: VarHubData = {
              hubId: data.addVarHub.system.varHubs[0]?.hubId || 0,
              macAddress: data.addVarHub.system.varHubs[0]?.macAddress || "",
              hubName: data.addVarHub.system.varHubs[0]?.hubName || "",
              timeLocation:
                data.addVarHub.system.varHubs[0]?.timeLocation || "",
              secondaryNetworkType:
                data.addVarHub.system.varHubs[0]?.secondaryNetworkType || "",
              secondaryNetworkIP: "",
              secondaryNetworkSubnetMask: "",
            };

            setVarHubData(newVarHubData);

            const hubId = data.addVarHub.system.varHubs[0]?.hubId;
            if (hubId) {
              const varHubId = idAsString(
                toVarHubId(UserService.control_system_id, hubId)
              );
              setNewHubId(varHubId);
            }

            setIsOpen(false);

            const hardwareModel =
              data.addVarHub.system.varHubs[0]?.hardwareModel;
            const hasDualNic =
              hardwareModel === "xv60" || hardwareModel === "xv96";
            const dualNicEnabled =
              newVarHubData.secondaryNetworkType !== "disabled";

            if (dualNicEnabled && hasDualNic) {
              setShowNetworkModal(true);
            }
          } else if (data.addVarHub.__typename === "AddVarHubErrorPayload") {
            showAlert({
              text:
                data.addVarHub.error === "HUB_RECENTLY_RESET"
                  ? "XV Gateway recently reset. Please try again."
                  : data.addVarHub.error === "HUB_ALREADY_ACTIVATED"
                  ? "Error adding XV Gateway."
                  : "Error adding XV Gateway.",
              type: "error",
              suppressTitleCasing: true,
            });
          }
        },
        onError: (err) =>
          showAlert({
            text: "Error Adding XV Gateway",
            type: "error",
            suppressTitleCasing: true,
          }),
      });
    }
    e.preventDefault();
  };

  const handleCancel = () => {
    setIsOpen(false);
    setShowNetworkModal(false);
    setMacError("");
    setRecorderNameError("");
    setStaticIpError("");
    setSubnetMaskError("");
  };

  const data = useLazyLoadQuery<RecorderAddModalQuery>(
    graphql`
      query RecorderAddModalQuery($systemId: ID!) {
        node(id: $systemId) {
          id
          __typename
          ... on ControlSystem {
            id
            name
            varHubs {
              id
              isOnline
              panelConnectionEnabled
            }
          }
        }
      }
    `,
    { systemId: systemId } ?? {}
  );

  const [addVarHub, adding] = useMutation<RecorderAddModalAddVarHubMutation>(
    graphql`
      mutation RecorderAddModalAddVarHubMutation(
        $addVarHubInput: AddVarHubInput!
      ) {
        addVarHub(hub: $addVarHubInput) {
          __typename
          ... on AddVarHubSuccessPayload {
            system {
              id
              varHubs {
                hubId
                macAddress
                hubName
                isOnline
                panelConnectionEnabled
                hardwareModel
                hardwareModelDisplayName
                megapixelThresholdWarning
                megapixelThresholdCritical
                supportedMegapixels
                timeLocation
                secondaryNetworkType
                secondaryNetworkIP
                secondaryNetworkSubnetMask
                cameras {
                  id
                  cameraId
                  addedToDB
                  camectCamId
                  camectHubId
                  cameraName
                  height
                  hubId
                  ipAddress
                  isEnabled
                  isStreaming
                  macAddress
                  make
                  needsCredential
                  playerAuthToken
                  playerUrl
                  rtspUrl
                  snapshot
                  width
                }
              }
            }
          }
          ... on AddVarHubErrorPayload {
            error: type
            message
          }
        }
      }
    `
  );

  const updateVarHubMutation = graphql`
    mutation RecorderAddModalUpdateVarHubMutation($hub: UpdateVarHubInput!) {
      updateVarHub(hub: $hub) {
        __typename
        ... on UpdateVarHubSuccessPayload {
          status
        }
        ... on UpdateVarHubErrorPayload {
          type
          message
        }
      }
    }
  `;

  const [updateVarHub, isUpdating] =
    useMutation<RecorderAddModalUpdateVarHubMutation>(updateVarHubMutation);

  return UserService.controlSystem.services_manager
    .video_analytics_recorder_enabled ? (
    <>
      <button
        className="btn btn-dmp btn-sm"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <InnerAddGatewayButtonContainer>
          <Icon name="add" size="1.6rem" color="primary" />
          <div>XV Gateway</div>
        </InnerAddGatewayButtonContainer>
      </button>
      {isOpen ? (
        <Modal onClickOutside={handleCancel}>
          <div className="status-modal modal-header bb0 ">
            <h3 className="modal-title">Add New Device</h3>
          </div>
          <div className="modal-body">
            <form name="addNewCameraForm" className="form-horizontal">
              <div className="form-group">
                <label
                  className="col-sm-3 control-label"
                  htmlFor="recorderName"
                >
                  Device Name <span className="text-danger">*</span>
                </label>
                <NameFieldContainer>
                  <TextInput
                    type="text"
                    className="form-control"
                    required
                    name="recorderName"
                    id="recorderName"
                    ref={nameRef}
                    onBlur={(event) =>
                      (event.target.value = event.target.value.trim())
                    }
                  />
                  <ErrorMessage>
                    {recorderNameError ? recorderNameError : `\n`}
                  </ErrorMessage>
                </NameFieldContainer>
                <label className="col-sm-3 control-label" htmlFor="mac">
                  MAC Address <span className="text-danger">*</span>
                </label>
                <MacFieldContainer>
                  <TextInput
                    type="text"
                    className="form-control"
                    required
                    pattern={pattern}
                    id="mac"
                    name="mac"
                    inlineHelp="681DEFCD00AB"
                    ref={macRef}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                  />
                  <ErrorMessage>{macError ? macError : `\n`}</ErrorMessage>
                </MacFieldContainer>
              </div>
            </form>
          </div>
          <div className="modal-footer bt0">
            <div className="connection-status pull-left"></div>
            <div className="pull-right">
              <button className="btn btn-default" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="btn btn-dmp"
                disabled={adding}
                onClick={(e) => handleSubmit(e)}
              >
                {adding ? `Adding...` : `Add`}
              </button>
            </div>
          </div>
        </Modal>
      ) : null}

      {showNetworkModal ? (
        <Modal onClickOutside={handleCancel}>
          <div className="status-modal modal-header bb0">
            <StyledH3 className="modal-title">
              Configure Secondary Network Interface
            </StyledH3>
            <BlockText>
              The XV Gateway has been successfully added. Please confirm the
              configuration for the secondary network interface for a private
              camera network.
            </BlockText>
            <BlockText>
              Need more information?{" "}
              <a
                href="https://xv-24withalarmvision.dmp.support/xv24/xv-series-installation-and-setup"
                className="da-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Helpfile
              </a>
            </BlockText>
            <InputBox>
              <SettingsOption
                label="Secondary Network"
                radialInfo={`If your private network has a DHCP Server\u000Ado not assign a Static IP Address, or ensure\u000Aa Static IP Address is reserved.`}
                type="dropdown"
                value={secondaryNetworkOption.description}
                dropdownOptions={Object.keys(secondaryNetworkTypes)}
                setSecondaryNetworkOption={handleDropdownChange}
              />
            </InputBox>
            {secondaryNetworkOption.description === "Static IP Address" && (
              <>
                <Root>
                  <TextContainer>
                    <Label>
                      Enter Static IP Address
                      <span className="text-danger">*</span>
                    </Label>
                    {staticIpError ? (
                      <ErrorMessage>
                        <Icon name="attention" />
                        <ErrorMessageText>{staticIpError}</ErrorMessageText>
                      </ErrorMessage>
                    ) : (
                      `\n`
                    )}
                  </TextContainer>
                  <TextInput
                    type="text"
                    name="StaticIP"
                    pattern={ipPattern}
                    value={varStaticIp}
                    ref={staticIpRef}
                    maxLength={15}
                    style={{
                      maxWidth: "20rem",
                      borderColor: staticIpError ? "red" : "",
                    }}
                    className="form-control"
                    onChange={({ target: { value } }) => setVarStaticIp(value)}
                    required
                  />
                </Root>
                <Root>
                  <TextContainer>
                    <Label>
                      Enter Subnet Mask<span className="text-danger">*</span>
                    </Label>
                    {subnetMaskError ? (
                      <ErrorMessage>
                        <Icon name="attention" />
                        <ErrorMessageText> {subnetMaskError}</ErrorMessageText>
                      </ErrorMessage>
                    ) : (
                      `\n`
                    )}
                  </TextContainer>
                  <TextInput
                    type="text"
                    name="SubnetMask"
                    pattern={ipPattern}
                    value={varSubnetMask}
                    ref={subnetMaskRef}
                    maxLength={15}
                    style={{
                      maxWidth: "20rem",
                      borderColor: subnetMaskError ? "red" : "",
                    }}
                    className="form-control"
                    onChange={({ target: { value } }) =>
                      setVarSubnetMask(value)
                    }
                    required
                  />
                </Root>
              </>
            )}
            <button
              className="btn btn-dmp"
              style={{ float: "right" }}
              disabled={adding}
              onClick={(e) => handleSubmitStaticIP(e)}
            >
              {adding
                ? `Saving...`
                : secondaryNetworkOption.description === "DHCP (default)"
                ? `Continue`
                : `Save`}
            </button>
          </div>
        </Modal>
      ) : null}
    </>
  ) : null;
};

const InnerAddGatewayButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;
const InputBox = styled.div`
  margin-left: 8rem;
  color: black;
`;
const StyledH3 = styled.h3`
  margin-bottom: 12px;
`;
const BlockText = styled.div`
  color: black;
  font-size: 14px;
  margin-bottom: 0.375rem;
`;
const ErrorMessageText = styled.div`
  color: black;
  margin-left: 0.25rem;
`;
const Root = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem;
  margin-left: 10rem;
`;
const TextContainer = styled.div`
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0rem;
`;
const Label = styled.span`
  font-weight: bold;
  width: 15rem;
  color: black;
`;
const NameFieldContainer = styled.div`
  display: flex;
  align-items: left;
  margin: 0 2rem 2rem 0;
`;
const MacFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 2rem 0 0;
`;
const ErrorMessage = styled.span`
  display: flex;
  color: red;
  position: absolute;
  margin-top: 3.7rem;
  justify-content: flex-end;
`;

export default RecorderAddModal;
