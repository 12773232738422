import graphql from "babel-plugin-relay/macro";
import Flex from "common/components/web/Flex";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setAdd, setDifference, setFirst } from "common/utils/universal/set";
import AddButton from "components/CameraEditCommon/AddButton";
import {
  FieldLabelCell,
  FieldLabelContainer,
  FieldLabelText,
  FieldValueCell,
  RegionSettingsDeleteButton,
  RegionSettingsHeaderText,
  RegionsSettingsRoot,
  RegionsSettingsRow,
  RegionsSettingsTable,
} from "components/CameraEditCommon/CameraEditStyledComponents";
import { Tooltip } from "components/DaStyledElements";
import Switch from "components/FullProgramming/common/Switch";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import Icon from "components/Icon";
import TextInput from "components/Inputs/TextInput";
import Select from "components/Select";
import { range } from "ramda";
import React, { FC } from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { fetchQuery, RecordProxy } from "relay-runtime";
import {
  Area,
  DirectionOfTravel as directionOfTravel,
  idAsString,
  toZoneId,
  VarHubCameraDetectionLine,
  Zone,
} from "securecom-graphql/client";
import { between } from "utils";
import { removeLineUpdater } from "./utils";
import { RecorderCameraDetectionLineSettingsZonesQuery } from "./__generated__/RecorderCameraDetectionLineSettingsZonesQuery.graphql";
import { RecorderCameraDetectionLineSettings_panel$key } from "./__generated__/RecorderCameraDetectionLineSettings_panel.graphql";
import { RecorderCameraDetectionLineSettings_varHub$key } from "./__generated__/RecorderCameraDetectionLineSettings_varHub.graphql";
import { RecorderCameraDetectionLineSettings_varHubCamera$key } from "./__generated__/RecorderCameraDetectionLineSettings_varHubCamera.graphql";
import { RecorderCameraDetectionLineSettings_varHubCameraDetectionLine$key } from "./__generated__/RecorderCameraDetectionLineSettings_varHubCameraDetectionLine.graphql";

const CAMERA_FRAGMENT = graphql`
  fragment RecorderCameraDetectionLineSettings_varHubCamera on VarConnectedCamera {
    id
    cameraName
    detectionLines {
      id
      slotNumber
      zone {
        number
      }
    }
  }
`;

const LINE_FRAGMENT = graphql`
  fragment RecorderCameraDetectionLineSettings_varHubCameraDetectionLine on VarHubCameraDetectionLine {
    id
    name
    detectPeople
    detectAnimals
    detectVehicles
    drawRegionOnAlerts
    index
    isNew
    movementDirection
    slotNumber
    zone {
      id
      name
      number
      isNew
      area {
        id
      }
    }
  }
`;

const PANEL_FRAGMENT = graphql`
  fragment RecorderCameraDetectionLineSettings_panel on Panel {
    hardwareModel
    softwareVersion
    areas(first: 32, sort: { keys: ["number"], order: ASC }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const VARHUB_FRAGMENT = graphql`
  fragment RecorderCameraDetectionLineSettings_varHub on VarHub {
    id
    panelConnectionEnabled
  }
`;

interface RecorderCameraDetectionLineSettingsProps {
  index: number;
  camera: RecorderCameraDetectionLineSettings_varHubCamera$key;
  detectionLine: RecorderCameraDetectionLineSettings_varHubCameraDetectionLine$key;
  globalSystemId: string;
  isEditable: boolean;
  systemId: string;
  panel: RecorderCameraDetectionLineSettings_panel$key;
  varHub: RecorderCameraDetectionLineSettings_varHub$key | null;
  setRemovedDetectionLineIds: React.Dispatch<React.SetStateAction<Set<string>>>;
  lineZoneNumbers: number[];
}

const RecorderCameraDetectionLineSettings: FC<
  RecorderCameraDetectionLineSettingsProps
> = ({
  index,
  camera,
  detectionLine,
  globalSystemId,
  isEditable,
  systemId,
  panel,
  varHub,
  setRemovedDetectionLineIds,
  lineZoneNumbers,
}) => {
  const cameraData = useFragment(CAMERA_FRAGMENT, camera);
  const panelData = useFragment(PANEL_FRAGMENT, panel);
  const lineData = useFragment(LINE_FRAGMENT, detectionLine);
  const varHubData = useFragment(VARHUB_FRAGMENT, varHub);
  const relayEnv = useRelayEnvironment();

  //State Initialization
  const [fetchingZoneNumber, setFetchingZoneNumber] = React.useState(false);
  const [zoneAvailable, setZoneAvailable] = React.useState(true);

  const [takenZoneNumbersList, setTakenZoneNumbersList] = React.useState<
    Set<number>
  >(new Set() as Set<number>);
  const [possibleZoneNumbersList, setPossibleZoneNumbersList] = React.useState<
    Set<number>
  >(new Set() as Set<number>);
  const [axZoneNumbersList, setAxZoneNumbersList] = React.useState<Set<number>>(
    new Set() as Set<number>
  );

  const [zoneIsDuplicated, setZoneIsDuplicated] = React.useState(false);
  const [zoneIsOutOfRange, setZoneIsOutOfRange] = React.useState(false);
  const [zoneIsInAxRange, setZoneIsInAxRange] = React.useState(false);

  const panelSupportsVarIntegration =
    (resolvePanelType(panelData.hardwareModel).isXr ||
      resolvePanelType(panelData.hardwareModel).isXt75) &&
    panelVersionGTOE(221, panelData.softwareVersion);

  const lineDetectionTypeNotSelected = !(
    lineData.detectPeople ||
    lineData.detectAnimals ||
    lineData.detectVehicles
  );

  const noDetectionRegionTypeCustomMessage = (input: HTMLInputElement) => {
    if (input.validity.valueMissing) {
      return "At least one detection type must be selected";
    }
    return "";
  };

  const handleAddAlarmZone = async () => {
    setFetchingZoneNumber(true);
    const data =
      await fetchQuery<RecorderCameraDetectionLineSettingsZonesQuery>(
        relayEnv,
        graphql`
          query RecorderCameraDetectionLineSettingsZonesQuery($systemId: ID!) {
            controlSystem: node(id: $systemId) {
              ... on ControlSystem {
                varHubDetectionRegionZoneNumberMin
                varHubDetectionRegionZoneNumberMax
                panel {
                  deviceInformations {
                    ... on XrDeviceInformation {
                      axNumber
                      deviceType
                      deviceCommunicationMethod
                    }
                    ... on Xt75DeviceInformation {
                      deviceType
                      deviceCommunicationMethod
                    }
                  }
                }
                zones {
                  number
                }
              }
            }
          }
        `,
        { systemId: globalSystemId },
        { fetchPolicy: "network-only" }
      ).toPromise();

    if (
      data?.controlSystem?.panel?.deviceInformations &&
      data?.controlSystem?.zones &&
      isNotNullOrUndefined(
        data.controlSystem.varHubDetectionRegionZoneNumberMin
      ) &&
      isNotNullOrUndefined(
        data.controlSystem.varHubDetectionRegionZoneNumberMax
      )
    ) {
      const {
        zones,
        varHubDetectionRegionZoneNumberMin,
        varHubDetectionRegionZoneNumberMax,
        panel: { deviceInformations },
      } = data.controlSystem;
      const axTakenZoneNumbers = new Set(
        deviceInformations.flatMap((device) => {
          const number = Number(device.axNumber);
          switch (true) {
            case device.deviceType === "CAMERA" ||
              device.deviceType === "VPLEX":
              return [];
            case between(number, 17, 32):
              return range(500, 600);
            case between(number, 33, 48):
              return range(600, 700);
            case between(number, 49, 64):
              return range(700, 800);
            case between(number, 65, 80):
              return range(800, 900);
            case between(number, 81, 96):
              return range(900, 1000);
            default:
              return [];
          }
        })
      );
      const possibleZoneNumbers = new Set(
        range(
          varHubDetectionRegionZoneNumberMin,
          varHubDetectionRegionZoneNumberMax + 1
        )
      );

      const takenZoneNumbers = new Set(
        zones
          .map(({ number }) => parseInt(number, 10))
          .concat(lineZoneNumbers)
          .concat(Array.from(axTakenZoneNumbers))
      );
      const availableNumbers = setDifference(
        takenZoneNumbers,
        possibleZoneNumbers
      ) as Set<number>;
      setTakenZoneNumbersList(() => takenZoneNumbers);
      setAxZoneNumbersList(() => axTakenZoneNumbers);
      setPossibleZoneNumbersList(() => possibleZoneNumbers);
      const nextAvailableNumber = setFirst(availableNumbers);

      if (isNotNullOrUndefined(nextAvailableNumber)) {
        relayEnv.commitUpdate((store) => {
          const line = store.get(
            lineData.id
          ) as RecordProxy<VarHubCameraDetectionLine>;
          if (line) {
            const zone = store.create(
              idAsString(toZoneId(systemId, nextAvailableNumber)),
              "Zone"
            ) as RecordProxy<Zone>;
            zone.setValue(``, "name");
            zone.setValue(nextAvailableNumber, "number");
            zone.setValue(true, "isNew");
            zone.setValue(null, "area");
            line.setLinkedRecord(zone, "zone");
          }
        });
      } else {
        setZoneAvailable(false);
      }

      setFetchingZoneNumber(true);
    }
  };

  const [movementDirection, setMovementDirection] =
    React.useState<directionOfTravel>(directionOfTravel.CROSS);

  const onHandleDirectionSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setMovementDirection(event.target.value as directionOfTravel);
  };

  return (
    <RegionsSettingsRoot>
      <RegionsSettingsTable>
        <thead>
          <RegionsSettingsRow>
            <th colSpan={2}>
              <Flex alignItems="center" justifyContent="space-between">
                <RegionSettingsHeaderText
                  index={lineData.slotNumber ?? index}
                  onMouseEnter={() => {
                    // brings the line to the top of the pile
                    relayEnv.commitUpdate((store) => {
                      const cameraRecord = store.get(idAsString(cameraData.id));
                      if (cameraRecord && isEditable) {
                        cameraRecord.setValue(
                          lineData.index,
                          "activeDetectionLineIndex"
                        );
                      }
                    });
                  }}
                >
                  Line #{(lineData.slotNumber ?? index) + 1} {lineData.name}
                </RegionSettingsHeaderText>
                {isEditable ? (
                  <RegionSettingsDeleteButton
                    type="button"
                    onClick={() => {
                      if (!lineData.isNew) {
                        setRemovedDetectionLineIds(setAdd(lineData.id));
                      }
                      relayEnv.commitUpdate((store) => {
                        removeLineUpdater(store, cameraData.id, lineData.id);
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </RegionSettingsDeleteButton>
                ) : null}
              </Flex>
            </th>
          </RegionsSettingsRow>
        </thead>
        <tbody>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText htmlFor={`detection-line-${lineData.id}-name`}>
                  Name
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <TextInput
                id={`detection-line-${lineData.id}-name`}
                value={lineData.name}
                required
                onChange={(event) => {
                  relayEnv.commitUpdate((store) => {
                    const line = store.get(
                      lineData.id
                    ) as RecordProxy<VarHubCameraDetectionLine>;
                    if (line) {
                      line.setValue(event.target.value, "name");
                    }
                  });
                }}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-line-${lineData.id}-direction`}
                >
                  Direction
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Select
                id={`detection-line-${lineData.id}-direction`}
                value={
                  lineData.movementDirection
                    ? lineData.movementDirection
                    : movementDirection
                }
                onChange={(event) => {
                  onHandleDirectionSelect(event);
                  relayEnv.commitUpdate((store) => {
                    const newDirection = event.target
                      .value as directionOfTravel;
                    setMovementDirection(newDirection);
                    //update relay
                    const line = store.get(
                      lineData.id
                    ) as RecordProxy<VarHubCameraDetectionLine>;
                    if (line) {
                      line.setValue(event.target.value, "movementDirection");
                    }
                  });
                }}
              >
                <option key={"CROSS"} value={"CROSS"}>
                  Bi-directional
                </option>
                <option key={"IN"} value={"IN"}>
                  Enter
                </option>
                <option key={"OUT"} value={"OUT"}>
                  Exit
                </option>
              </Select>
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-line-${lineData.id}-detect-people`}
                >
                  Detect People
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              {/* Detect People Toggle */}
              <Switch
                id={`detection-line-${lineData.id}-detect-people`}
                label="Detect People"
                required={lineDetectionTypeNotSelected}
                checked={lineData.detectPeople}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  relayEnv.commitUpdate((store) => {
                    const line = store.get(
                      lineData.id
                    ) as RecordProxy<VarHubCameraDetectionLine>;
                    if (line) {
                      line.setValue(event.target.checked, "detectPeople");
                    }
                  });
                }}
                getValidationMessage={noDetectionRegionTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-line-${lineData.id}-detect-animals`}
                >
                  Detect Animals
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-line-${lineData.id}-detect-animals`}
                label="Detect Animals"
                required={lineDetectionTypeNotSelected}
                checked={lineData.detectAnimals}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  relayEnv.commitUpdate((store) => {
                    const line = store.get(
                      lineData.id
                    ) as RecordProxy<VarHubCameraDetectionLine>;
                    if (line) {
                      line.setValue(event.target.checked, "detectAnimals");
                    }
                  });
                }}
                getValidationMessage={noDetectionRegionTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-line-${lineData.id}-detect-vehicles`}
                >
                  Detect Vehicles
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              {/* Detect Vehicles Toggle */}
              <Switch
                id={`detection-line-${lineData.id}-detect-vehicles`}
                label="Detect Vehicles"
                required={lineDetectionTypeNotSelected}
                checked={lineData.detectVehicles}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  relayEnv.commitUpdate((store) => {
                    const line = store.get(
                      lineData.id
                    ) as RecordProxy<VarHubCameraDetectionLine>;
                    if (line) {
                      line.setValue(event.target.checked, "detectVehicles");
                    }
                  });
                }}
                getValidationMessage={noDetectionRegionTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <Tooltip content="Display the configured line on event previews.">
                  <Tooltip.LabelText
                    htmlFor={`detection-line-${lineData.id}-bounding-boxes`}
                  >
                    Display Regions
                  </Tooltip.LabelText>
                </Tooltip>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-line-${lineData.id}-bounding-boxes`}
                label="Display Regions"
                checked={lineData.drawRegionOnAlerts}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  relayEnv.commitUpdate((store) => {
                    const line = store.get(
                      lineData.id
                    ) as RecordProxy<VarHubCameraDetectionLine>;
                    if (line) {
                      line.setValue(event.target.checked, "drawRegionOnAlerts");
                    }
                  });
                }}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          {lineData.zone ? (
            <>
              <RegionsSettingsRow>
                <FieldLabelCell>
                  <FieldLabelContainer>
                    <FieldLabelText
                      htmlFor={`detection-line-${lineData.id}-zone-name`}
                    >
                      Zone Name
                    </FieldLabelText>
                  </FieldLabelContainer>
                </FieldLabelCell>
                <FieldValueCell>
                  <TextInput
                    id={`detection-line-${lineData.id}-zone-name`}
                    value={lineData.zone.name}
                    required
                    maxLength={32}
                    onChange={(event) => {
                      relayEnv.commitUpdate((store) => {
                        const line = store.get(
                          lineData.id
                        ) as RecordProxy<VarHubCameraDetectionLine>;
                        if (line) {
                          const zone = line.getLinkedRecord("zone");
                          if (zone) {
                            zone.setValue(event.target.value, "name");
                          }
                        }
                      });
                    }}
                  />
                </FieldValueCell>
              </RegionsSettingsRow>
              <RegionsSettingsRow>
                <FieldLabelCell>
                  <FieldLabelContainer>
                    <FieldLabelText
                      htmlFor={`detection-line-${lineData.id}-zone-number`}
                    >
                      Zone Number
                    </FieldLabelText>
                  </FieldLabelContainer>
                </FieldLabelCell>
                <FieldValueCell>
                  <TextInput
                    id={`detection-line-${lineData.id}-zone-number`}
                    value={lineData.zone.number}
                    disabled={!lineData.zone.isNew}
                    required
                    onChange={({ target }) => {
                      relayEnv.commitUpdate((store) => {
                        const line = store.get(
                          lineData.id
                        ) as RecordProxy<VarHubCameraDetectionLine>;
                        if (line) {
                          const zone = line.getLinkedRecord("zone");
                          if (zone) {
                            zone.setValue(target.value, "number");
                          }
                        }
                      });
                    }}
                    onBlur={({ target }) => {
                      setZoneIsDuplicated(
                        () =>
                          takenZoneNumbersList.has(Number(target.value)) ||
                          cameraData.detectionLines
                            .map(
                              (line) =>
                                line.id !== lineData.id &&
                                Number(line.zone?.number) ===
                                  Number(target.value)
                            )
                            .includes(true)
                      );
                      setZoneIsOutOfRange(
                        () =>
                          target.value !== "" &&
                          !possibleZoneNumbersList.has(Number(target.value))
                      );
                      setZoneIsInAxRange(() =>
                        axZoneNumbersList.has(Number(target.value))
                      );
                    }}
                  />
                </FieldValueCell>
              </RegionsSettingsRow>

              {lineData.zone.isNew ? (
                zoneIsDuplicated || zoneIsOutOfRange || zoneIsInAxRange ? (
                  <RegionsSettingsRow>
                    <FieldLabelCell />
                    <FieldValueCell>
                      <span className="text-danger">
                        {zoneIsOutOfRange
                          ? "Zone Number Is Out Of Range"
                          : zoneIsInAxRange
                            ? "Zone Number Is In AX Bus Range"
                            : "Zone Number Is Already In Use"}
                      </span>
                    </FieldValueCell>
                  </RegionsSettingsRow>
                ) : null
              ) : null}
              <RegionsSettingsRow>
                <FieldLabelCell>
                  <FieldLabelContainer>
                    <FieldLabelText
                      htmlFor={`detection-line-${lineData.id}-area`}
                    >
                      Area
                    </FieldLabelText>
                  </FieldLabelContainer>
                </FieldLabelCell>
                <FieldValueCell>
                  <Select
                    id={`detection-line-${lineData.id}-area`}
                    value={lineData.zone.area?.id ?? ""}
                    required
                    onChange={(event) => {
                      relayEnv.commitUpdate((store) => {
                        const line = store.get(
                          lineData.id
                        ) as RecordProxy<VarHubCameraDetectionLine>;
                        if (line) {
                          const zone = line.getLinkedRecord("zone");
                          if (zone) {
                            const area = store.get(
                              event.target.value
                            ) as RecordProxy<Area>;
                            if (event.target.value && area) {
                              zone.setLinkedRecord(area, "area");
                            } else {
                              zone.setValue(null, "area");
                            }
                          }
                        }
                      });
                    }}
                  >
                    <Select.Option value="">Select an area</Select.Option>
                    {panelData.areas.edges.map(({ node }) =>
                      node ? (
                        <Select.Option key={node.id} value={node.id}>
                          {node.name}
                        </Select.Option>
                      ) : null
                    )}
                  </Select>
                </FieldValueCell>
              </RegionsSettingsRow>
            </>
          ) : panelSupportsVarIntegration &&
            varHubData?.panelConnectionEnabled ? (
            zoneAvailable ? (
              <RegionsSettingsRow>
                <FieldLabelCell />
                <FieldValueCell>
                  <AddButton
                    loading={fetchingZoneNumber}
                    onClick={() => handleAddAlarmZone()}
                  >
                    Add Alarm Zone
                  </AddButton>
                </FieldValueCell>
              </RegionsSettingsRow>
            ) : (
              <span>No zones available</span>
            )
          ) : null}
        </tbody>
      </RegionsSettingsTable>
    </RegionsSettingsRoot>
  );
};

export default RecorderCameraDetectionLineSettings;
