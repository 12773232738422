import DaColors from "app/common/DaColors";
import Icon from "components/Icon";
import * as React from "react";
import styled from "styled-components/macro";
import { darkenHSL, disabledHSL } from "utils/styles";

function BigBlueAddButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <Root onClick={() => onClick()} disabled={disabled}>
      <Icon name="add" color="white" hoverColor="white" />
    </Root>
  );
}
const Root = styled.button`
  height: 3.2rem;
  width: 3.2rem;
  background-color: ${DaColors.PrimaryBase};
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  &:hover {
    background-color: ${darkenHSL(DaColors.PrimaryBase, 10)};
    color: #ffffff;
  }
  &:focus {
    background-color: ${darkenHSL(DaColors.PrimaryBase, 10)};
    color: #ffffff;
    box-shadow: none;
    -webkit-transform: translateY(0.01rem);
    -moz-transform: translateY(0.01rem);
    -ms-transform: translateY(0.01rem);
    -o-transform: translateY(0.01rem);
    transform: translateY(0.01rem);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &:disabled {
    background-color: ${disabledHSL(DaColors.PrimaryBase, 10, 10)};
    cursor: not-allowed;
    mouse-pointer-events: none;
  }
`;
export default BigBlueAddButton;
