App.controller("DealerSettingsCtrl", [
  "$rootScope",
  "$scope",
  "$state",
  "$modal",
  "$filter",
  "UserService",
  "DealerService",
  "DealerODataAPI",
  "SearchDataService",
  "CompanyStoreService",
  "COUNTRY_CODES",
  "dealer_id",
  "LogoService",
  "CustomerReferralsService",
  "PROPS",
  "$q",
  "IpWhitelistService",
  "IpResolverService",
  "$timeout",
  "DealerKeypadCustomGraphicApi",
  "SevenInchKeypadService",

  function (
    $rootScope,
    $scope,
    $state,
    $modal,
    $filter,
    UserService,
    DealerService,
    DealerODataAPI,
    SearchDataService,
    CompanyStoreService,
    COUNTRY_CODES,
    dealer_id,
    LogoService,
    CustomerReferralsService,
    PROPS,
    $q,
    IpWhitelistService,
    IpResolverService,
    $timeout,
    DealerKeypadCustomGraphicApi,
    SevenInchKeypadService
  ) {
    function init() {
      $scope.UserService = UserService;
      $scope.dealerData = UserService.dealerInfo;
      // Set both App and API access to true
      $scope.dealerData.app_access = true;
      $scope.dealerData.api_access = true;
      $scope.dealerDataForStore = CompanyStoreService.dealerStoreSettings;
      $scope.isSuperAdmin = UserService.canEditDealerSettings();
      $scope.countryCodes = COUNTRY_CODES;
      //Initialization Above
      //Functions Below
      $scope.stateIsEdit = checkStateForEdit();
      $scope.triggerTerminologiesMutation = false;
      $scope.saveAllChanges = saveAllChanges;
      $scope.cancelNewDealer = cancelNewDealer;
      $scope.editCountry = editCountry;
      $scope.generateId = generateId;
      $scope.updatePrimary = updatePrimary;
      $scope.uploadLogo = uploadLogo;
      $scope.customerReferralPhotoUpload = customerReferralPhotoUpload;
      $scope.openPreviewModal = openPreviewModal;
      $scope.changeVideoVerificationStrategy = changeVideoVerificationStrategy;
      $scope.showReferralImage = showReferralImage;
      $scope.getDealerHas7inchKeypads = getDealerHas7inchKeypads;
      //$scope variables
      $scope.changeCountry;
      $scope.light_logo;
      $scope.dark_logo;
      $scope.referral_photo;
      $scope.logoUrl;
      $scope.logoType;
      $scope.referralsObj = {};
      $scope.imageSrc = "";
      $scope.photoExist = false;
      $scope.dealerHas7inchKeypads;
      $scope.dealerId = dealer_id;

      $scope.addingNewDealer = $state.is("app.dealer.settings-new");
      $scope.editingDealer = $state.is("app.dealer.settings-edit");
      $scope.viewingDealer = $state.is("app.dealer.settings-view");
      $scope.editCustomGraphic = editCustomGraphic;
      $scope.customGraphicEdited = false;
      $scope.allDbCustomGraphics = [{ Name: "None", FileName: "None" }];
      $scope.chosenGraphic;
      $scope.canHaveChildren = false;
      $scope.hasCustomers = false;
      $scope.selectedParent = null;

      getDealerHas7inchKeypads(dealer_id);

      getLogos();
      initCustomAppDetails();
      initVideoVerifierData();
      getCustomerReferralTemplates();
      checkForReferralPhoto();
      getDealerIpWhitelist();
      getDealerAssociatedCustomGraphic();
      getAllDbCustomGraphics();
      getAllParents();
      getCustomers(dealer_id);
    }

    function editCustomGraphic(model) {
      $scope.chosenGraphic = model;
      $scope.customGraphicEdited = true;
    }

    init();

    function editCountry() {
      $scope.changeCountry = true;
    }

    // a dealer is a Parent if it canHaveChildren
    $scope.setParent = function (parentData) {
      $scope.selectedParent = parentData;
      $scope.dealerData.parent_id = $scope.selectedParent.id;
    };

    function getAllParents() {
      let noParents = { id: null, name: "None" };
      // get all available parent dealers from odata
      SearchDataService.parentDealersSearch()
        .then(
          function (parents) {
            $scope.availableParents = angular.copy(parents);
            $scope.availableParents.unshift(noParents);
            if ($scope.dealerData.parent_id !== null) {
              $scope.selectedParent = $scope.availableParents.find(
                (parent) => parent.id === $scope.dealerData.parent_id
              );
            } else {
              $scope.selectedParent = null;
            }
          },
          function (error) {
            $scope.error = error;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function getCustomers(dealer_id) {
      DealerODataAPI.getQuickCustomerCount(
        { dealer_id: dealer_id }, //params
        function (customers) {
          $scope.dealersCustomers = angular.copy(customers);
          // if dealer has customers it cannot be a parent
          $scope.hasCustomers = $scope.dealersCustomers.value > 0;
        },
        function (error) {
          //failure
          $scope.error = error;
        }
      );
    }

    $scope.updateCanHaveChildren = function () {
      $scope.canHaveChildren = !$scope.canHaveChildren;
    };

    // This function sets whether or not the dealer has 7 inch keypads
    // and show the 7 inch keypad settings if they do
    function getDealerHas7inchKeypads(dealer_id) {
      SevenInchKeypadService.getDoesDealerHave7inchKeypads(dealer_id).then(
        (data) => {
          if (!data) {
            $scope.dealerHas7inchKeypads = false;
          } else {
            $scope.dealerHas7inchKeypads = true;
          }
        }
      );

      return $scope.ç;
    }

    $scope.isSecurityCommandChecked = function () {
      $scope.dealerData.security_command_direct_billing = false;
      if ($scope.dealerData.security_command === true) {
        $scope.isSecurityCommandEnabled = true;
        $scope.dealerData.allow_billing = false;
        $scope.dealerData.distribution_subscriber = false;
        //set log in as customer to true in the background
        $scope.dealerData.allow_log_in_as_customer = true;
      } else if ($scope.dealerData.security_command === false) {
        $scope.isSecurityCommandEnabled = false;
      }
    };

    $scope.isDistributionSubscriberChecked = function () {
      if ($scope.dealerData.distribution_subscriber === true) {
        $scope.isDistributionSubscriberEnabled = true;
        $scope.dealerData.security_command = false;
      } else if ($scope.dealerData.distribution_subscriber === false) {
        $scope.isDistributionSubscriberEnabled = false;
      }
    };

    $scope.makeActive = function () {
      $scope.dealerData.master_identifiers[0].primary = true;
      for (var i = 0; i < $scope.dealerData.master_identifiers.length; i++) {
        if ($scope.dealerData.video_verification) {
          // sets the first Active and Primary to true if VV is checked
          $scope.dealerData.master_identifiers[i].active = true;
        } else {
          $scope.dealerData.master_identifiers[i].active = false;
          $scope.dealerData.master_identifiers[i].primary = false;
        }
      }
    };

    $scope.activeCheckboxClicked = function (selectedIdentifier) {
      if (selectedIdentifier.primary) {
        //gets the index of active
        var activeMI = $scope.dealerData.master_identifiers.findIndex(function (
          mi
        ) {
          return mi.active;
        });
        //if we have an active update the primary
        if (activeMI > -1) {
          updatePrimary($scope.dealerData.master_identifiers[activeMI]);
        } else {
          //Must always have one active checkbox for primary
          $scope.dealerData.master_identifiers[0].active = true;
          updatePrimary($scope.dealerData.master_identifiers[0]);
        }
      }
    };

    $scope.clearStoreCheckboxes = function () {
      if (UserService.dealerInfo.cms_account_number === "") {
        UserService.dealerInfo.company_store = false;
        UserService.dealerInfo.sales_app_allowed = false;
      }
    };

    function generateId() {
      var thisNumber = $scope.dealerData.master_identifiers.length + 1;
      var isPrimary = thisNumber === 1;
      var newSecureId = {
        dealer_id: $scope.dealerData.id,
        name: $scope.dealerData.name + " Secure ID " + thisNumber,
        primary: isPrimary,
        active: true,
      };
      $scope.dealerData.master_identifiers.push(newSecureId);
    }

    //For the Video Verification IDs -
    //if primary is changed, then make sure to
    //switch the radio button in the view
    function updatePrimary(identifier) {
      //goes through each identifiers
      for (var i = 0; i < $scope.dealerData.master_identifiers.length; i++) {
        // sets primary equal to whatever identifier is active
        $scope.dealerData.master_identifiers[i].primary = angular.equals(
          $scope.dealerData.master_identifiers[i],
          identifier
        );
      }
    }

    //Logo stuff - Will?
    function uploadLogo(file, properties) {
      if (file === undefined) {
        return;
      } // No file was specified, exit out of the function

      properties === "dark_type"
        ? ($scope.uploadingApp = true)
        : ($scope.uploadingWeb = true);

      LogoService.createLogos(dealer_id, properties, file)
        .then(
          function (data) {
            properties === "dark_type"
              ? ($scope.uploadingApp = false)
              : ($scope.uploadingWeb = false);
            $rootScope.alerts.push({ type: "success", text: "Logo uploaded" });
            getLogos();
            // Add an arbitrary string to the end of the dealer logo to force it to not pull from cache
            UserService.logoUrl =
              UserService.logoUrl +
              "?" +
              Math.round(new Date().getTime() / 1000).toString();
          },
          function (error) {
            properties === "dark_type"
              ? ($scope.uploadingApp = false)
              : ($scope.uploadingWeb = false);
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function getLogos() {
      if (!$scope.dealerData.isNew) {
        // Get the url for the 240x100 light logo for the dealer
        LogoService.getLogo(dealer_id, "light_type", 240, 100)
          .then(function (url) {
            $scope.light_logo = url;
          })
          .catch(function (error) {
            console.error(error);
          });

        // Get the url for the 240x100 dark loglo for the dealer
        LogoService.getLogo(dealer_id, "dark_type", 240, 100)
          .then(function (url) {
            $scope.dark_logo = url;
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    }

    function getDealerAssociatedCustomGraphic() {
      DealerKeypadCustomGraphicApi.getAssociatedCustomGraphic(
        {
          dealer_id: $scope.dealerData.id,
        },
        function (data) {
          LogoService.getCustomGraphicById(data.CustomKeypadGraphicsId)
            .then((data) => {
              $scope.chosenGraphic = data.CustomKeypadGraphics.Name;
            })
            .catch((error) => {
              console.error(error);
            });
        },
        function (error) {
          if (error.status === 404) {
            $scope.chosenGraphic = $scope.allDbCustomGraphics[0].Name;
          } else {
            console.error(error);
          }
        }
      );
    }

    function getAllDbCustomGraphics() {
      LogoService.getAllDbCustomGraphics()
        .then((data) =>
          data?.forEach((graphic) =>
            $scope.allDbCustomGraphics.push(graphic.CustomKeypadGraphics)
          )
        )
        .catch((error) => console.error(error));
    }

    function getDealerIpWhitelist() {
      let deferred = $q.defer();
      IpWhitelistService.getDealerIpWhitelist($scope.dealerData.id)
        .then(
          function (data) {
            $scope.dealerData.ipWhitelist = data;
            deferred.resolve();
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    //$timeout is being used here to wait for the DOM to render before scrolling to the bottom.  Without this you get and angular error for two $apply functions.
    function scrollToBottom() {
      $timeout(
        function () {
          let container = document.getElementById("ip-list");
          container.scrollTop = container.scrollHeight;
        },
        0,
        false
      );
    }

    //Used to add a new IP to the list, you can also set useCurrentIp to true and auto-populate the current locations IP address.
    $scope.addIp = function (useCurrentIp) {
      let deferred = $q.defer();
      IpWhitelistService.newDealerWhitelistIp($scope.dealerData.id)
        .then(
          function (newIp) {
            if (useCurrentIp) {
              IpResolverService.getIP().then(
                function (ip) {
                  newIp.IpAddress = ip;
                  $scope.dealerData.ipWhitelist.push(newIp);
                },
                function (error) {
                  console.error(error);
                }
              );
              // newIp.IpAddress = $rootScope.localIP.ip;
            } else {
              $scope.dealerData.ipWhitelist.push(newIp);
            }
            scrollToBottom();
            deferred.resolve();
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    $scope.deleteIp = function (ip) {
      $scope.dealerData.ipWhitelist.forEach((address) => {
        if (ip.IpAddress == address.IpAddress && !ip._destroy) {
          ip._destroy = true;
        }
      });
    };

    /**
     * Creates and opens the IP Whitelist Edit modal
     */
    $scope.openIpWhitelistEditModal = function () {
      var trackedOutputEditModal = $modal.open({
        templateUrl:
          "app/dealer-settings/templates/ip-whitelist-edit-modal.html",
        size: "md",
        controller: "closeIpWhitelistEditModalControl",
        backdrop: "static",
        scope: $scope,
        resolve: {
          pristineIpWhitelist: function () {
            return angular.copy($scope.dealerData.ipWhitelist);
          },
        },
      });
    };

    function showReferralImage(file) {
      // setting the obj generated by the directive,
      // and creating base64Img for display purposes
      $scope.referralsObj.image = file;
      $scope.referralsObj.HasPhoto = true;
      $scope.photoExist = true;
      CustomerReferralsService.getBase64Img(file)
        .then(
          function (base64Img) {
            $scope.referralsObj.PhotoLinkSrc = base64Img;
          },
          function (error) {
            console.error(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function customerReferralPhotoUpload(file) {
      let deferred = $q.defer();
      if (file === undefined) return;

      CustomerReferralsService.uploadTplPhoto(file).then(
        function (photos) {
          deferred.resolve();
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to upload photo",
            json: error,
          });
          deferred.reject();
        }
      );
      return deferred.promise;
    }

    function checkForReferralPhoto() {
      let image = new Image();

      image.onload = function () {
        // image exists and is loaded
        $scope.photoExist = true;
        $scope.imageSrc = image.src;
      };
      image.onerror = function () {
        // image did not load
        let err = new Image();
        err.src = "/error.png";
        $scope.photoExist = false;
      };
      var url = "?refresh=" + new Date().getTime();
      image.src = `${PROPS.imageApiUrl}/Uploads/ReferralProgramImages/${UserService.dealer_id}/ReferralImage/ReferralImage_768w_432h_max.png${url}`;
    }

    $scope.getReferralPhoto = () =>
      $scope.referralsObj.PhotoLinkSrc
        ? $scope.referralsObj.PhotoLinkSrc
        : $scope.imageSrc;

    $scope.deleteTplPhoto = function () {
      $scope.referralsObj.HasPhoto = false;
      $scope.photoExist = false;
      CustomerReferralsService.deleteTplPhoto().then(
        function (success) {
          $scope.referralsObj.PhotoLinkSrc = "";
          $scope.imageSrc = "";
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to delete Customer Referral Photo",
            json: error,
          });
        }
      );
    };

    function getCustomerReferralTemplates() {
      let dealer_id = UserService.dealer_id;
      CustomerReferralsService.getExistingTpl(dealer_id).then(
        function (existingTpl) {
          $scope.referralsObj = existingTpl;
        },
        function () {}
      );
    }

    function changeReferralTpl() {
      let deferred = $q.defer();
      CustomerReferralsService.changeReferralTpl(
        $scope.referralsObj.Id,
        $scope.referralsObj
      ).then(
        function (updatedReferralTpl) {
          deferred.resolve(updatedReferralTpl);
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to save Customer Referral",
            json: error,
          });
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function createReferralTpl() {
      let deferred = $q.defer();

      CustomerReferralsService.createReferralTpl($scope.referralsObj).then(
        function (newTPL) {
          // getCustomerReferralTemplates()
          deferred.resolve(newTPL);
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to save Customer Referral",
            json: error,
          });
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function saveCustomerReferralTpl() {
      let deferred = $q.defer();
      if ($scope.referralsObj.CreatedAt) {
        if ($scope.referralsObj.image)
          customerReferralPhotoUpload($scope.referralsObj.image).then(
            function () {
              changeReferralTpl().then(
                function () {
                  deferred.resolve();
                },
                function () {
                  deferred.reject();
                }
              );
            },
            function () {
              deferred.reject();
            }
          );
        else if ($scope.referralsObj) {
          changeReferralTpl().then(
            function () {
              deferred.resolve();
            },
            function () {
              deferred.reject();
            }
          );
        }
      } else {
        if ($scope.referralsObj.image)
          customerReferralPhotoUpload($scope.referralsObj.image).then(
            function () {
              createReferralTpl().then(
                function () {
                  deferred.resolve();
                },
                function () {
                  deferred.reject();
                }
              );
            },
            function () {
              deferred.reject();
            }
          );
        else if ($scope.referralsObj) {
          createReferralTpl().then(
            function () {
              deferred.resolve();
            },
            function () {
              deferred.reject();
            }
          );
        }
      }
      return deferred.promise;
    }

    function initCustomAppDetails() {
      // If UserService.dealerInfo has been loaded
      if (angular.isDefined($scope.dealerData)) {
        if ($scope.dealerData.isNew) {
          $scope.custom_app_details = {};
        } else if ($scope.dealerData.hasOwnProperty("customer_app_details")) {
          // If the app is not a custom app, initialize the scoped custom app details
          if (
            !$filter("filter")($scope.dealerData.customer_app_details, {
              app_name_codified: "custom",
            })[0]
          ) {
            $scope.custom_app_details = {};
          } else {
            $scope.custom_app_details = $filter("filter")(
              $scope.dealerData.customer_app_details,
              { app_name_codified: "custom" }
            )[0];
          }
        }
      }
    }

    function initVideoVerifierData() {
      if (
        angular.isDefined($scope.dealerData) &&
        $scope.dealerData.isNew &&
        $scope.dealerData.video_verification_strategy === null
      ) {
        $scope.dealerData.video_verification_strategy = "video_verifier";
      }
    }

    //For previewing logos
    function openPreviewModal(logo_url, logo_type) {
      $scope.logoUrl = logo_url;
      $scope.logoType = logo_type;
      var previewLogoModal = $modal.open({
        templateUrl: "previewLogoModal.html",
        controller: "previewLogoModalCtrl",
        windowClass: "",
        size: "md",
        backdrop: true,
        scope: $scope,
      });
    }

    function saveAllChanges() {
      $scope.triggerTerminologiesMutation = true;

      if (
        $scope.custom_app_details &&
        !angular.equals($scope.custom_app_details, {})
      ) {
        if (!$scope.custom_app_details.hasOwnProperty("app_name_codified")) {
          $scope.custom_app_details["app_name_codified"] = "custom";
        }
        if (angular.isDefined($scope.dealerData.customer_app_details)) {
          $scope.dealerData.customer_app_details = [];
          $scope.dealerData.customer_app_details.push(
            $scope.custom_app_details
          );
        } else if (
          angular.isDefined($scope.dealerData.customer_app_details_attributes)
        ) {
          $scope.dealerData.customer_app_details_attributes = [];
          $scope.dealerData.customer_app_details_attributes.push(
            $scope.custom_app_details
          );
        }
      }
      if ($state.is("app.dealer.settings-edit")) {
        if ($scope.customGraphicEdited) {
          const name = $scope.chosenGraphic;
          if (name === "None") {
            DealerKeypadCustomGraphicApi.deleteCustomGraphicAssociation(
              {
                dealer_id: $scope.dealerData.id,
              },
              function (error) {
                console.error(error);
              }
            );
          } else {
            const matchingGraphic = $scope.allDbCustomGraphics.find(
              (graphic) => graphic.Name === name
            );
            DealerKeypadCustomGraphicApi.createCustomGraphicAssociation(
              {
                dealer_id: $scope.dealerData.id,
              },
              {
                CustomKeypadGraphicsId: matchingGraphic.Id,
                AssociationId: $scope.dealerData.id,
              },
              function (data) {
                //success
                return;
              },
              function (error) {
                console.error(error);
                return;
              }
            );
          }
        }

        $scope.dealerData
          .updateDealer()
          .then(
            function (data) {
              if ($scope.dealerData.customer_referral) {
                $scope.referralsObj.DealerId = UserService.dealer_id;
                saveCustomerReferralTpl().then(
                  function () {
                    UserService.setSelectedDealer(
                      new DealerService({ dealer_id: data.dealer.id })
                    )
                      .then(function () {
                        $state.go("app.dealer.settings-view", {
                          dealer_id: data.dealer.id,
                        });
                      })
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function () {}
                );
              } else {
                // Even when editing, setSelectedDealer to refresh the selected dealer information
                UserService.setSelectedDealer(
                  new DealerService({ dealer_id: data.dealer.id })
                )
                  .then(function () {
                    $state.go("app.dealer.settings-view", {
                      dealer_id: data.dealer.id,
                    });
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
              }
            },
            function (error) {
              let errorMessage = error.data.errors.can_have_children
                ? error.data.errors.can_have_children.toString()
                : error;
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to save changes",
                json: errorMessage,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          })
          .finally(function () {
            $scope.triggerTerminologiesMutation = false;
          });
      } else {
        // X1 panels require stored usercodes to be turned on in order to upload users
        // Some dealers have been created with stored user codes turned off which is causing their x1s to act up
        // So we decided to automatically turn on store user codes for all incoming dealers
        $scope.dealerData.store_user_codes = true;
        $scope.dealerData
          .create()
          .then(
            function (data) {
              if ($scope.dealerData.customer_referral) {
                $scope.referralsObj.DealerId = data.dealer.id;
                saveCustomerReferralTpl().then(
                  function () {
                    UserService.setSelectedDealer(
                      new DealerService({ dealer_id: data.dealer.id })
                    )
                      .then(function () {
                        $state.go("app.dealer.settings-view", {
                          dealer_id: data.dealer.id,
                        });
                      })
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function () {}
                );
              } else {
                UserService.setSelectedDealer(
                  new DealerService({ dealer_id: data.dealer.id })
                )
                  .then(function () {
                    $state.go("app.dealer.settings-view", {
                      dealer_id: data.dealer.id,
                    });
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
              }
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to create new dealer",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          })
          .finally(function () {
            $scope.triggerTerminologiesMutation = false;
          });
      }
    }

    function cancelNewDealer() {
      UserService.setDealerInfo(
        new DealerService({ dealer_id: UserService.user.accessible_id })
      )
        .then(function () {
          $state.go("app.dealer.search", {
            dealer_id: UserService.user.accessible_id,
            criteria: "Dealers",
            parameter: "",
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function checkStateForEdit() {
      return (
        $state.is("app.dealer.settings-edit") ||
        $state.is("app.dealer.settings-new")
      );
    }

    function changeVideoVerificationStrategy(strategy) {
      if (
        strategy === "master_identifier" &&
        $scope.dealerData.master_identifiers.length == 0
      ) {
        generateId();
      }
    }
  },
]);

/**
 * A controller for the new dealer store modal
 *
 */
App.controller("previewLogoModalCtrl", [
  "$scope",
  "$modalInstance",
  function ($scope, $modalInstance) {
    $scope.cancel = function () {
      $scope.newCenter = {};
      $modalInstance.dismiss("cancel");
    };
  },
]);

/**
 * A controller for the IP whitelist modal
 *
 */

App.controller("closeIpWhitelistEditModalControl", [
  "$scope",
  "$rootScope",
  "$modalInstance",
  "pristineIpWhitelist",
  "$q",
  "IpWhitelistService",
  "VALIDATION_PATTERNS",
  function (
    $scope,
    $rootScope,
    $modalInstance,
    pristineIpWhitelist,
    $q,
    IpWhitelistService,
    VALIDATION_PATTERNS
  ) {
    $scope.VALIDATION_PATTERNS = VALIDATION_PATTERNS;

    $scope.cancel = function () {
      $scope.dealerData.ipWhitelist = pristineIpWhitelist;
      $modalInstance.dismiss("cancel");
    };
    $scope.save = function () {
      saveIpWhitelistChanges()
        .then(
          function (data) {
            IpWhitelistService.getDealerIpWhitelist($scope.dealerData.id)
              .then(
                function (savedWhitelist) {
                  $scope.dealerData.ipWhitelist = savedWhitelist;
                  //IP Whitelist Retrieved
                  $modalInstance.dismiss("save");
                },
                function (error) {
                  console.error(
                    `Unable to retrieve the IP Whitelist. Error: ${error}`
                  );
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            console.error(
              `Something went wrong when saving the whitelist.  Error: ${error}`
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {});
    };

    function saveIpWhitelistChanges() {
      let promises = [];
      let deferred = $q.defer();
      $scope.dealerData.ipWhitelist.forEach((address) => {
        if (address.isNew && !address._destroy && address.IpAddress) {
          promises.push(
            IpWhitelistService.createDealerWhitelistIp(address).then(function (
              data
            ) {
              address.isNew = false;
              //NEW IP CREATED
            })
          );
        } else if (!address.isNew && !address._destroy && address.IpAddress) {
          promises.push(
            IpWhitelistService.updateDealerWhitelistIp(address).then(function (
              data
            ) {
              //IP UPDATED
            })
          );
        } else if (!address.isNew && address._destroy) {
          promises.push(
            IpWhitelistService.deleteDealerWhitelistIp(address).then(function (
              data
            ) {
              //IP DELETED
            })
          );
        }
      });

      $q.all(promises)
        .then(
          function (data) {
            $rootScope.alerts.push({
              type: "success",
              text: "Whitelist IPs Saved",
            });
            deferred.resolve();
          },
          function (error) {
            if (error.status === 409) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error Saving Whitelist. Duplicate IP",
                json: error,
              });
            } else {
              $rootScope.alerts.push({
                type: "error",
                text: "Error saving Whitelist IPs.",
                json: error,
              });
            }
            console.error(`Unable to Save Whitelist IP. Error: ${error}`);
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    }
  },
]);
