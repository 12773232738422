/**
 * @generated SignedSource<<471c7bd5a45c82886371feeadfca1eda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly alarmReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entreArmDisarmReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreCheckInMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entreConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreOutgoingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entrePassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreSupervisoryReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreUserCommandReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreZoneReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly remoteDisarm: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly serviceReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly validEntreCheckinMinutes: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts";
};
export type TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts"
};

(node as any).hash = "42a5ea553e8d6f307e7f4d300874b023";

export default node;
