import Icon from "components/Icon";
import React from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import styled from "styled-components";

interface CustomerListItemProps {
  customer: {
    customerId: number;
    name: string;
    sites: Map<
      number,
      {
        name: string;
      }
    >;
    systems: Map<
      number,
      {
        name: string;
      }
    >;
    isOpen: boolean;
  };
  index: number;
  onSystemExpand: (index: number) => void;
  tagId: string;
  tagRelationsMap: Map<string, Set<number>>;
  systemReplacement: string | undefined;
}
function CustomerListItem(props: CustomerListItemProps) {
  const {
    customer,
    index,
    onSystemExpand,
    tagId,
    tagRelationsMap,
    systemReplacement,
  } = props;
  const relayEnv = useRelayEnvironment();
  const systemsSelectedCount = getSystemsSelectedCount(props);
  const totalSystemsCount = customer.sites.size + customer.systems.size;
  const customerChecked = tagRelationsMap
    .get("tagCustomers")
    ?.has(customer.customerId);
  const iconName = customer.isOpen ? "control_down" : "control_right";
  const handleCustomerCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    relayEnv.commitUpdate((store) => {
      const tagRelationsResults = store
        .getRoot()
        .getLinkedRecord("getTagRelationsById", { id: tagId });

      if (tagRelationsResults) {
        const tagCustomers = [
          ...(tagRelationsResults.getValue("tagCustomers") as []),
        ];

        if (event.target.checked) {
          tagRelationsResults.setValue(
            [...tagCustomers, customer.customerId],
            "tagCustomers"
          );

          // Clearing Systems and Sites relations as customer relation supersedes them
          tagRelationsResults.setValue([], "tagPanels");
          tagRelationsResults.setValue([], "tagSites");
        } else {
          tagRelationsResults.setValue(
            tagCustomers.filter((id) => id !== customer.customerId),
            "tagCustomers"
          );
        }
      }
    });
  };
  const handleSystemCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    isSite?: boolean
  ) => {
    relayEnv.commitUpdate((store) => {
      const systemId = parseInt(event.target.id);
      const tagRelationsResults = store
        .getRoot()
        .getLinkedRecord("getTagRelationsById", { id: tagId });

      if (tagRelationsResults) {
        const tagRelationType = isSite ? "tagSites" : "tagPanels";
        const tagRelation = isSite
          ? [...(tagRelationsResults.getValue("tagSites") as [])]
          : [...(tagRelationsResults.getValue("tagPanels") as [])];

        if (event.target.checked) {
          tagRelationsResults.setValue(
            [...tagRelation, systemId],
            tagRelationType
          );
        } else {
          tagRelationsResults.setValue(
            tagRelation.filter((id) => id !== systemId),
            tagRelationType
          );
        }
      }
    });
  };

  return (
    <>
      <CustomerContainer>
        <div>
          <Chevron
            name={iconName}
            onClick={() => {
              if (totalSystemsCount > 0) {
                onSystemExpand(index);
              }
            }}
          />
          <Checkbox className="checkbox-inline c-checkbox needsclick">
            <label className="needsclick">
              <input
                id={customer.customerId.toString()}
                type="checkbox"
                checked={customerChecked}
                onChange={handleCustomerCheck}
              />
              <span className="icon-checkmark"></span>
            </label>
          </Checkbox>
        </div>
        <CheckboxLabel htmlFor={customer.customerId.toString()}>
          {customer.name}
        </CheckboxLabel>
        <SystemsSelected>
          {systemsSelectedCount}/{totalSystemsCount}{" "}
          {systemReplacement ?? "Systems"} Selected
        </SystemsSelected>
      </CustomerContainer>
      {customer.isOpen ? (
        <div>
          {Array.from(customer.sites).map(([siteId, site]) => (
            <SystemContainer key={siteId}>
              <div>
                <IconSpacer name="control_right" />
                <Checkbox className="checkbox-inline c-checkbox needsclick">
                  <label className="needsclick">
                    <input
                      id={siteId.toString()}
                      type="checkbox"
                      checked={
                        customerChecked ||
                        tagRelationsMap.get("tagSites")?.has(siteId)
                      }
                      disabled={customerChecked}
                      onChange={(evt) => handleSystemCheck(evt, true)}
                    />
                    <span className="icon-checkmark"></span>
                  </label>
                </Checkbox>
              </div>
              <CheckboxLabel htmlFor={siteId.toString()}>
                {site.name}
              </CheckboxLabel>
            </SystemContainer>
          ))}
          {Array.from(customer.systems).map(([systemId, system]) => (
            <SystemContainer key={systemId}>
              <div>
                <IconSpacer name="control_right" />
                <Checkbox className="checkbox-inline c-checkbox needsclick">
                  <label className="needsclick">
                    <input
                      id={systemId.toString()}
                      type="checkbox"
                      checked={
                        customerChecked ||
                        tagRelationsMap.get("tagPanels")?.has(systemId)
                      }
                      disabled={customerChecked}
                      onChange={handleSystemCheck}
                    />
                    <span className="icon-checkmark"></span>
                  </label>
                </Checkbox>
              </div>
              <CheckboxLabel htmlFor={systemId.toString()}>
                {system.name}
              </CheckboxLabel>
            </SystemContainer>
          ))}
        </div>
      ) : null}
    </>
  );
}

const getSystemsSelectedCount = ({
  customer,
  tagRelationsMap,
}: CustomerListItemProps) => {
  let systemsSelectedCount = 0;

  if (tagRelationsMap.get("tagCustomers")?.has(customer.customerId)) {
    systemsSelectedCount = customer.sites.size + customer.systems.size;
  } else {
    customer.sites.forEach(
      (_site, siteId) =>
        tagRelationsMap.get("tagSites")?.has(siteId) && systemsSelectedCount++
    );
    customer.systems.forEach(
      (_system, systemId) =>
        tagRelationsMap.get("tagPanels")?.has(systemId) &&
        systemsSelectedCount++
    );
  }

  return systemsSelectedCount;
};
const CustomerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: thin;
  border-color: lightgray;
  margin: 5px 1px;
  white-space: nowrap;
`;
const SystemContainer = styled(CustomerContainer)`
  margin-left: 45px;
`;
const Chevron = styled(Icon)`
  margin: 10px 10px 0px 10px;
  cursor: pointer;
`;
const IconSpacer = styled(Icon)`
  visibility: hidden;
  margin-top: 10px;
  margin-left: -6px;
`;
const Checkbox = styled.div`
  margin-bottom: 4px;
`;
const CheckboxLabel = styled.label`
  flex-basis: 215%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  cursor: pointer;
`;
const SystemsSelected = styled.div`
  display: flex;
  width: 100%;
  font-size: 1rem;
  padding-right: 5px;
  justify-content: end;
`;

export default CustomerListItem;
