import graphql from "babel-plugin-relay/macro";
import {
  fromControlSystemId,
  fromSiteId,
  toSiteId,
} from "securecom-graphql/client";
import {
  systemIsScheduledForFutureInstall,
  systemIsSite,
  systemWillBeAutoProgrammed,
} from "utils/control-systems";

App.controller("CustomerSummaryCtrl", [
  "$rootScope",
  "$scope",
  "$q",
  "$stateParams",
  "$sanitize",
  "MIN_VIEW_SEARCH",
  "UserService",
  "Customer",
  "DTOptionsBuilder",
  "DTColumnDefBuilder",
  "DTColumnBuilder",
  "$state",
  "controlSystemModel",
  "DashboardDataService",
  "$filter",
  "$compile",
  "PersonUsersService",
  "CustomerUser",
  "PersonsService",
  "DataTablesUtilsService",
  "TEMP_DEALER_APP_USER",
  "ControlSystemsService",
  "$modal",
  "PROPS",
  "INSTALL_GUIDE",
  "PROGRAMMING_GUIDE",
  "RelayService",
  "SimManagementService",
  "DealerService",
  "TagsService",
  "AuthorizeTechSupportService",

  function (
    $rootScope,
    $scope,
    $q,
    $stateParams,
    $sanitize,
    MIN_VIEW_SEARCH,
    UserService,
    Customer,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    DTColumnBuilder,
    $state,
    controlSystemModel,
    DashboardDataService,
    $filter,
    $compile,
    PersonUsersService,
    CustomerUser,
    PersonsService,
    DataTablesUtilsService,
    TEMP_DEALER_APP_USER,
    ControlSystemsService,
    $modal,
    PROPS,
    INSTALL_GUIDE,
    PROGRAMMING_GUIDE,
    RelayService,
    SimManagementService,
    DealerService,
    TagsService,
    AuthorizeTechSupportService
  ) {
    const THIRTY_DAYS = 2592000000;
    const MS_PER_MINUTE = 60000;
    const summary = this; // ControllerAs
    summary.customer_id = $stateParams.customer_id;
    summary.dealer_id = $stateParams.dealer_id;
    summary.MIN_VIEW_SEARCH = MIN_VIEW_SEARCH;
    summary.authCheckisBusy = false;
    summary.INSTALL_GUIDE = INSTALL_GUIDE;
    summary.PROGRAMMING_GUIDE = PROGRAMMING_GUIDE;
    summary.UserService = UserService;
    summary.customer = new Customer(summary.customer_id);
    summary.UserService.setCustomerInfo(summary.customer);
    summary.fileURL = "";
    summary.personUsers = [];
    summary.loadingPersonUsers = false;
    summary.AppUsersInformation = {};
    summary.tags = [];
    $scope.validVernaculars = JSON.parse(
      localStorage.getItem(`vern-${summary.dealer_id}`)
    );

    summary.status = {
      isopen: false,
    };
    summary.approvalData = {};

    summary.toggleDropdown = function () {
      summary.status.isopen = !summary.status.isopen;
    };

    $scope.itemsPerPageOption = 10;
    $scope.itemsPerPageOptions = [10, 25, 50, 100];

    const customerReplacement =
      UserService.dealerInfo.vernaculars.customers.replacement;
    const systemReplacement =
      UserService.dealerInfo.vernaculars.systems.replacement;

    $scope.deleteMessage = `Are you sure you want to delete this ${
      customerReplacement || "customer"
    }? <br> <span class='text-danger'>This will delete the ${
      customerReplacement || "customer"
    } and all associated ${systemReplacement || "systems"}.</span>`;

    async function init() {
      getPersonUsers();
      getCustomerScore();

      summary.tags = await TagsService.getCustomerTags(summary.customer_id);
    }
    $scope.getCustomerScoreColorClass = function (rating) {
      var color = "";
      if (rating) {
        switch (rating) {
          case "Below Average":
            color = "color--below-avg";
            break;
          case "Average":
            color = "color--average";
            break;
          case "Good":
            color = "color--good";
            break;
          case "Great":
            color = "color--great";
            break;
          case "Excellent":
            color = "color--excellent";
            break;
          default:
            color = "";
        }
        return color;
      }
    };

    $scope.getCustomerScoreRadialBarClass = function (rating) {
      var color = "";
      if (rating) {
        switch (rating) {
          case "Below Average":
            color = "radial-bar--below-average";
            break;
          case "Average":
            color = "radial-bar--average";
            break;
          case "Good":
            color = "radial-bar--good";
            break;
          case "Great":
            color = "radial-bar--great";
            break;
          case "Excellent":
            color = "radial-bar--excellent";
            break;
          default:
            color = "";
        }
        return color;
      }
    };

    $scope.getCustomerScoreIcon = function (status) {
      var color = "";
      var icon = "";
      if (status) {
        icon = "icon-radial_check";
        color = "color--excellent";
      } else {
        //icon = 'icon-radial_question';
        icon = "";
        color = " ";
      }
      return icon;
    };

    /**
     * Function to delete a user
     * @param lastSignInDate
     */
    $scope.daysSinceSignIn = function (lastSignInDate) {
      if (lastSignInDate == null) {
        return "r";
      }
      let lastDate = new Date(lastSignInDate).getTime(),
        currentDate = new Date().getTime();

      if (currentDate - lastDate < THIRTY_DAYS) return "g";
      else if (currentDate - lastDate >= THIRTY_DAYS) return "y";
    };

    //  Takes in an expiration time in Date format
    //  Returns how much time is left in the format Xd, Yh, Zm if XYZ are present.
    //  It is smart enough to leave fields and leave out commas if they are not needed.
    //  For instance if there is 1 day, 0 hours, 23 minutes you would get 1d, 23m
    //  If there is exactly 1 hour you will get 1h
    //  If the time is expired it returns 0
    $scope.userExpirationMinutes = function (expTime) {
      if (expTime) {
        const now = Date.now();
        const exp = new Date(expTime);
        if (exp - now > 0) {
          const days = Math.floor((exp - now) / (MS_PER_MINUTE * 60 * 24));
          const hours = Math.floor(
            ((exp - now) % (MS_PER_MINUTE * 60 * 24)) / (MS_PER_MINUTE * 60)
          );
          const minutes = Math.floor(
            (((exp - now) % (MS_PER_MINUTE * 60 * 24)) % (MS_PER_MINUTE * 60)) /
              MS_PER_MINUTE
          );

          return `${days ? days + (hours || minutes ? "d, " : "d") : ""}${
            hours ? hours + (minutes ? "h, " : "h") : ""
          }${minutes ? minutes + "m" : ""}`;
        } else {
          return 0;
        }
      }
    };

    /**
     * Load the personUsers for this customer
     * @type {Array}
     */
    function getPersonUsers() {
      var deferred = $q.defer();
      summary.loadingPersonUsers = true;
      PersonUsersService.getByCustomerId(summary.customer_id)
        .then(
          function (personUsers) {
            summary.personUsers = personUsers;
            summary.loadingPersonUsers = false;
            // if (summary.personUsers.length <= MIN_VIEW_SEARCH.NUM) {
            //   summary.AppUsersInformation.withDOM('<>t<"bottom"i>');
            // }
            deferred.resolve();
          },
          function (error) {
            console.error(
              "CustomerSummaryCtrl->getPersonUsers() error: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "error getting app users",
            });
            summary.loadingPersonUsers = false;
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    }
    summary.refreshAppUsers = function () {
      getPersonUsers();
    };

    $rootScope.$on("TempAppUserCreatedRefreshCustomerList", function () {
      summary.refreshAppUsers();
    });

    /**
     * Load the control_systems for this customer
     * @type {Array}
     */
    summary.controlSystems = [];
    summary.gettingControlSystems = false;
    // Get the control_systems
    var getControlSystems = function (dealerId, customerId) {
      var deferred = $q.defer();
      summary.gettingControlSystems = true;
      var controlSystems = [];
      const billableSystems = [];
      DashboardDataService.getSystemsForCustomer(dealerId, customerId)
        .then(
          function (systems) {
            // Loop through and convert the funky array to regular objects
            for (var i = 0; i < systems.length; i++) {
              systems[i].control_system_id = systems[i].id;
              controlSystems.push(new controlSystemModel(systems[i]));
              billableSystems.push({
                systemId: systems[i].control_system_id,
                systemName: systems[i].name,
              });
            }
            summary.controlSystems = controlSystems;
            summary.controlSystemsCount = systems.length;
            summary.gettingControlSystems = false;
            // billableSystems for wallet creds
            summary.billableSystems = billableSystems;
            if (summary.controlSystems.length <= MIN_VIEW_SEARCH.NUM) {
              summary.SystemInformation.withDOM('t<"bottom"<i>>');
            }

            deferred.resolve(summary.controlSystems);
          },
          function (error) {
            summary.gettingControlSystems = false;
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    summary.deleteCustomer = function () {
      summary.customer
        .delete()
        .then(
          function (data) {
            // This customer has been deleted, so we should go back to the customer list
            $state.go("app.dealer.dashboard", {
              dealer_id: UserService.dealer_id,
            });
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          },
          function (notify) {
            if (notify.status === "SimScheduleDeactivateError")
              $rootScope.alerts.push({
                type: "error",
                text: "The scheduled cellular activation for this system was unable to be deleted. Please contact SecureCom Customer Service to delete the scheduled cellular activation.",
              });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    summary.checkForTechSupportAccess = function (systemId) {
      summary.authCheckisBusy = true;

      AuthorizeTechSupportService.getTechSupportAuthorization(systemId).then(
        function (data) {
          // return the data as json
          summary.authCheckisBusy = false;
          $rootScope.alerts.push({
            type: "success",
            text: "Authorization granted",
          });
          // pop success message
          $rootScope.alerts.push({
            type: "success",
            text: "Authorization granted",
          });
          summary.authCheckisBusy = false;
          // return the data as json
          summary.approvalData = angular.toJson(data);
          return summary.approvalData;
        },
        function (error) {
          // pop error message
          $rootScope.alerts.push({
            type: "error",
            text: "Authorization has not been granted. Please try again.",
          });
          // return the error as json
          summary.authCheckisBusy = false;
          return angular.toJson(error);
        }
      );
    };
    summary.isAuthorizedToLoginAsCustomer = function () {
      // look at summary.approvalData.ExpiresAt
      // if it is greater than now, return true
      // else return false
      if (summary.approvalData.ExpiresAt) {
        const currrentTimeDate = new Date();
        const expiringTimeDate = new Date(summary.approvalData.ExpiresAt);
        if (expiringTimeDate > currrentTimeDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    /**
     * Function to delete a user
     * @param userId
     */
    summary.deleteUser = function (userId) {
      var user = new CustomerUser();
      // the user.id is used by the delete function.
      user.id = userId;
      user
        .delete()
        .then(
          function () {
            // Splice the user out of the list
            var index = summary.personUsers.findIndex(function (pu) {
              return pu.user_id === userId;
            });
            if (index > -1) {
              summary.personUsers.splice(index, 1);
            }
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error deleting user",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Function to reset access (password) for a user
     * @param {*} pUser -  the PersonUser
     */
    summary.resetAccess = function (pUser) {
      PersonsService.resetAccess(pUser.person_id, pUser.person_email_address)
        .then(
          function () {
            getPersonUsers()
              .then()
              .catch(function (error) {
                console.error(error);
              })
              .finally(function () {
                $rootScope.alerts.push({
                  type: "success",
                  text:
                    "Password reset for " +
                    $sanitize(pUser.person_email_address),
                });
              });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error resetting password",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    summary.deauthorizeTechSupportAuthorization = function (systemId) {
      // use AuthorizeTechSupportService.authorizeTechSupport to grant tech support authorization
      AuthorizeTechSupportService.revokeTechSupportAuthorization(systemId).then(
        function (data) {
          // pop success message
          $rootScope.alerts.push({
            type: "success",
            text: "Successfully revoked tech support authorization",
          });
          // return the data as json
          return angular.toJson(data);
        },
        function (error) {
          // pop error message
          $rootScope.alerts.push({
            type: "error",
            text: "Error revoking tech support authorization",
          });
          // return the error as json
          return angular.toJson(error);
        }
      );
    };

    /**
     * Controls for datatables : System Info table
     **/

    summary.SystemInformation = DataTablesUtilsService.getDTOptions(
      getControlSystems(summary.dealer_id, summary.customer_id)
    ).withDOM(
      '<"data-table__top" <"data-table__top--left"f> <"data-table__top--right"r>><t><"data-table__bottom"<"data-table__bottom--left" i><"data-table__bottom--center"p><"data-table__bottom--right"l>>'
    );
    summary.SystemInformation.withOption("createdRow", function (row) {
      DataTablesUtilsService.createdRow(row, $scope);
    });
    summary.SystemInformation.withPaginationType("simple_numbers");
    summary.SystemInformation.withDisplayLength(10);
    summary.SystemInformation.dtColumnDefs = [
      DTColumnBuilder.newColumn(null)
        .withTitle("Account")
        .renderWith(function (data, type, row) {
          return `<div style="display: flex; justify-items: flex-start; align-items: center;"><span>${
            row.panels[0].account_prefix + "-" + row.panels[0].account_number
          }</span></div>`;
        }),

      DTColumnBuilder.newColumn(null)
        .withTitle("System Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.navToSystemView(
            row,
            "customer_id",
            "control_system_id",
            "",
            "name",
            "site_id"
          );
        }),

      DTColumnBuilder.newColumn(null)
        .withTitle("System Type")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asHardwareModel(
            row,
            "panels[0].hardware_model",
            "panels[0].hardware_model_name"
          );
        }),

      DTColumnBuilder.newColumn(null)
        .withTitle("Version")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.returnFirmware(
            row,
            "panels[0].software_version",
            "panels[0].software_date",
            "panels[0].software_version_modifier"
          );
        }),

      DTColumnBuilder.newColumn(null)
        .withTitle("Connection")
        .withOption("width", "100")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.commType(row, "panels[0].comm_type");
        }),
    ];

    if (
      UserService.canCreateTempDealerCustomerUser() ||
      UserService.isTechSupport()
    ) {
      summary.SystemInformation.dtColumnDefs.push(
        DTColumnBuilder.newColumn(null)
          .withClass("login-button")
          .withOption("width", "160")
          .notSortable()
          .renderWith(function (data, type, row) {
            return DataTablesUtilsService.createTempUserLink(
              row.panels[0],
              TEMP_DEALER_APP_USER.BUTTON_LABEL
            );
          })
      );
    }

    // summary.SystemInformation.dtColumnDefs.push(
    //   DTColumnBuilder.newColumn(null).notSortable().withOption('width', '10').renderWith(function (data, type, row) {
    //     return DataTablesUtilsService.createMoreMenu(row, row.panels[0], 'dealer_id', 'customer_id', 'cust_name', 'panels[0].control_system_id', TEMP_DEALER_APP_USER.BUTTON_LABEL, '32', 'name')
    //   })
    // );

    summary.SystemInformation.dtColumnDefs.push(
      DTColumnBuilder.newColumn(null)
        .notSortable()
        .withOption("width", "10")
        .renderWith(function (data, type, row) {
          var customerIdVal = row.customer_id;
          var controlSystemIdVal = row.panels[0].control_system_id;
          var controlSystemNameVal = row.name;
          var IdVal = row.id;
          var panelNameVal = $sanitize(row.name);
          const hardwareModelVal = row.panels[0].hardware_model;
          const siteIdVal = row.site_id;
          var dropdownBtn = `
          <div
  class="dropdown-select pull-right"
  dropdown
  dropdown-toggle
  dropdown-append-to-body
  ng-if="UserService.canEditSystems() || UserService.canViewServiceRequests() || UserService.canDeleteSystems() || UserService.canCreateTempDealerCustomerUser()"
>
  <span
    type="button"
    id="system-dropdown-${controlSystemIdVal}"
    class="dropdown-select__link dropdown-toggle"
    ng-disabled="disabled"
    ng-click="summary.status.isopen = true"
  >
    <da-icon icon="icon-more" class="icon-20" icon-color="primary"></da-icon>
  </span>
  <!--DROPDOWN MENU-->
  <ul
    class="dropdown-menu dropdown-select__container"
    style="transform: translateX(-20rem)"
    aria-labelledby="system-dropdown-${controlSystemIdVal}"
    ng-show="summary.status.isopen"
  >
    <li class="dropdown-select__item" ng-if=""UserService.enabledSecurityCommand()">
      <!--DEALER LOGIN AS CUSTOMER  (visible on mobile) -->
      <a
        class="dropdown-select__link dropdown-select__link--flex hidden-md hidden-lg da-temp-app-user"
        ng-if="UserService.canCreateTempDealerCustomerUser()"
        ng-click="createTempUser(${row.panels[0].id})"
      >
        <div class="flex--start--center">
          <da-icon
            icon="icon-armed_shield_solid"
            class="icon-20 mar-r-8"
            icon-color="neutral"
          ></da-icon>
          <span>${TEMP_DEALER_APP_USER.BUTTON_LABEL}</span>
        </div>
      </a>

      <!-- AUTHORIZE SUPPORT -->
      <a
        class="dropdown-select__link dropdown-select__link--flex da-authorize-temp-app-user"
        ng-if="UserService.canAuthorizeLoginAsCustomer() && UserService.enabledAllowTechSupportLogInAsCustomer()"
        ng-click="authorizeTempAppUser(${row.panels[0].id})"
      >
        <div class="flex--start--center">
          <da-icon
            icon="icon-armed_shield_solid"
            class="icon-20 mar-r-8"
            icon-color="neutral"
          ></da-icon>
          <span>${TEMP_DEALER_APP_USER.AUTHORIZE_BUTTON_LABEL}</span>
        </div>
      </a>

      <!-- EDIT -->
      <a
        class="dropdown-select__link dropdown-select__link--flex"
        ui-sref="${
          hardwareModelVal === "X001"
            ? `app.sites.edit({customer_id: ${customerIdVal}, site_id: ${siteIdVal}})`
            : `app.control_system_edit({customer_id:${customerIdVal},control_system_id:${controlSystemIdVal}})`
        }"
        ng-if="UserService.canEditSystems()"
      >
        <div class="flex--start--center">
          <da-icon
            icon="icon-settings"
            class="icon-20 mar-r-8"
            icon-color="neutral"
          ></da-icon>
          <span>Edit</span>
        </div>
      </a>

      <!-- INSTALL GUIDE -->
      <a
        class="dropdown-select__link dropdown-select__link--flex"
        href="${summary.INSTALL_GUIDE.uri}/${
            summary.INSTALL_GUIDE[hardwareModelVal]
          }"
        target="_blank"
        >
         <div class="flex--start--center">
          <da-icon
            icon="icon-install_guide"
            class="icon-20 mar-r-8"
            icon-color="neutral"
          ></da-icon>
          <span>Installation Guide</span>
        </div>
      </a>

      <!-- PROGRAMMING GUIDE -->
      <a
        class="dropdown-select__link dropdown-select__link--flex"
        href="${summary.PROGRAMMING_GUIDE.uri}/${
            summary.PROGRAMMING_GUIDE[hardwareModelVal]
          }"
        target="_blank"
        >
         <div class="flex--start--center">
          <da-icon
            icon="icon-install_guide"
            class="icon-20 mar-r-8"
            icon-color="neutral"
          ></da-icon>
          <span>Programming Guide</span>
        </div>
      </a>

      <!-- Create Service Request -->
      <a
        class="dropdown-select__link dropdown-select__link--flex"
        ng-if="UserService.canViewServiceRequests() && ${
          hardwareModelVal !== "X001"
        } &&  UserService.enabledSecurityCommand()"
        da-service-request
        control-system-id="${controlSystemIdVal}"
        control-system-name="${controlSystemNameVal}
    "
        ><div class="flex--start--center">
          <da-icon
            icon="icon-troubleshooting"
            class="icon-20 mar-r-8"
            icon-color="neutral"
          ></da-icon
          ><span>Create Service Request</span>
        </div></a
      >

      <!-- Move System -->
      <a
        class="dropdown-select__link dropdown-select__link--flex"
        ng-if="UserService.canMoveSystems() && ${hardwareModelVal !== "X001"}"
        ng-click="openMoveSystemModal(${controlSystemIdVal})"
        ><div class="flex--start--center">
          <i class="svg-icon mar-r-8"></i><span>Move System</span>
        </div></a
      >

      <!-- Delete -->
      <a
        class="dropdown-select__link dropdown-select__link--flex"
        da-confirm-button="summary.deleteSystem(${controlSystemIdVal}); "
        location="left"
        message="Deleting  ${panelNameVal}. The cellular communicator will not be deactivated and will continue to appear on your SecureCom Wireless bill. Are you sure you would like to delete this system?
"
        yes="Delete"
        no="Cancel"
        ng-if="UserService.canDeleteSystems()"
        ><div class="flex--start--center text-danger">
          <da-icon
            icon="icon-trash"
            class="icon-20 mar-r-8"
            icon-color="failure"
          ></da-icon
          ><span>Delete</span>
        </div></a
      >
    </li>
  </ul>
</div>


          `;
          return dropdownBtn;
        })
    );

    // TODO: This code, for some unknown reason, is making the refresh work after deleting a control system...
    summary.SystemInformation.withButtons([
      // {extend: 'colvis', className: 'btn-sm', text: ''}
    ]);

    /**
     * Creates and opens the Customer Score modal
     */
    $scope.openCustomerScoreModal = function () {
      var customerScoreModal = $modal.open({
        templateUrl: "app/customer/customer-score-modal.html",
        size: "lg",
        controller: "customerScoreModalCtrl",
        backdrop: true,
        scope: $scope,
      });
    };
    $scope.openPanelCannotBeMovedModal = function () {
      var panelCannotBeMovedModal = $modal.open({
        templateUrl:
          "app/common/templates/move-system-mobile-wallet-creds-alert.html",
        size: "lg",
        controller: "panelCannotBeMovedModalCtrl",
        backdrop: true,
        scope: $scope,
      });
    };
    /**
     * Creates and opens the Customer Score modal
     */
    $scope.openMoveSystemModal = function (systemId) {
      let moveSystemModal = $modal.open({
        templateUrl: "app/customer/move-system-modal.html",
        size: "md",
        controller: "MoveSystemModalCtrl",
        backdrop: "static",
        scope: $scope,
        resolve: {
          systemId: systemId,
          summary: summary,
          openPanelCannotBeMovedModal: function () {
            return $scope.openPanelCannotBeMovedModal;
          },
        },
      });
    };

    /**
     * Creates and opens a modal to display status messages during first connect
     **/
    $scope.openAuthorizeTempAppUserModal = function (systemId) {
      let authorizeTempAppUserModal = {};
      let deferred = $q.defer();
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          authorizeTempAppUserModal = $modal.open({
            templateUrl:
              "app/common/templates/authorize-temp-user-modal-tpl.html",
            controller: "AuthorizeTempUserModalCtrl",
            size: "md",
            backdrop: "static",
            resolve: {
              systemId: function () {
                return systemId;
              },
            },
          });
          break;
        case "techApp":
          console.warn("daTempAppUser directive does not support TechApp");
          deferred.reject();
          break;
        default:
          console.warn(
            " daAuthorizeTempApp directive does not support $rootScope.appProperties.type: " +
              $rootScope.appProperties.type
          );
          deferred.reject();
          break;
      }
      return deferred.promise;
    };

    /**
     * Creates and opens a modal to display the success or failure of allowing
     * access of a temporary user.
     **/
    $scope.openTempUserModal = function (systemId, isUserManagement) {
      let statusModal = {};
      let deferred = $q.defer();
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          statusModal = $modal.open({
            templateUrl: "app/common/templates/temp-user-modal-tpl.html",
            controller: "DaUserManagementLoginCtrl",
            size: "md",
            backdrop: "static",
            resolve: {
              systemId: function () {
                return systemId;
              },
              isUserManagement: function () {
                return isUserManagement;
              },
            },
          });
          statusModal.result.then(
            function (result) {
              if (result.createdTempUser) {
                deferred.resolve();
              } else {
                deferred.reject();
              }
            },
            function () {
              deferred.reject();
            }
          );
          break;
        case "techApp":
          console.warn(
            "daTempAppUserPreAuthorized directive does not support TechApp"
          );
          deferred.reject();
          break;
        default:
          console.warn(
            "daTempAppUserPreAuthorized directive does not support $rootScope.appProperties.type: " +
              $rootScope.appProperties.type
          );
          deferred.reject();
          break;
      }
      return deferred.promise;
    };

    /**
     * Creates and opens a modal to confirm or cancel the deletion of a control system or site
     **/
    $scope.openDeleteSystemModal = function (systemId, system) {
      $scope.deleteSystemModal = $modal.open({
        templateUrl: "app/common/templates/delete-system-modal.html",
        size: "md",
        backdrop: "static",
        controller: "DeleteRequestModalCtrl",
        resolve: {
          systemId: function () {
            return systemId;
          },
          title: function () {
            return String(system.name);
          },
          summary: function () {
            return summary;
          },
        },
      });
    };
    openMoveSystemModal = $scope.openMoveSystemModal;
    /**
     * Creates and opens a modal to allow the user to pick
     * another billable control system
     * to confirm or cancel the deletion of a control system or site
     **/
    $scope.openHandleBillableSystemModal = function (
      systemId,
      system,
      pickNewBillableSystemList,
      customer,
      action
    ) {
      $scope.handleBillableSystemModal = $modal.open({
        templateUrl:
          "app/common/templates/billable-system-handle-conflicts-modal.html",
        size: "md",
        backdrop: "static",
        controller: "BillingSystemActionRequestModalCtrl",
        resolve: {
          systemId: function () {
            return systemId;
          },
          title: function () {
            return String(system.name);
          },

          summary: function () {
            return summary;
          },
          system: function () {
            return system;
          },
          customer: function () {
            return UserService.customer;
          },
          pickNewBillableSystemList: function () {
            return pickNewBillableSystemList;
          },
          action: function () {
            return action;
          },
          openDeleteSystemModal: function () {
            return $scope.openDeleteSystemModal;
          },
          openMoveSystemModal: function () {
            return $scope.openMoveSystemModal;
          },
        },
      });
    };

    /**
     * Creates and opens a modal to open a service request for a panel
     **/
    $scope.openServiceRequestModal = function (controlSystemId) {
      if (controlSystemId) $scope.controlSystemId = controlSystemId;
      $scope.serviceRequestModal = $modal.open({
        templateUrl: "app/common/templates/service-request.html",
        size: "md",
        backdrop: "static",
        scope: $scope,
        controller: "ServiceRequestModalCtrl",
      });
    };

    //Set this to update on the React side when we delete or move a control system or X1
    $rootScope.deletedPanel = "";
    $rootScope.movedSystemInfo = {};

    function getCustomerScore() {
      summary.customer
        .getCustomerScore(summary.customer_id)
        .then(
          function (score) {
            summary.customer.customerScore = score;
          },
          function (error) {}
        )
        .catch(function (error) {});
    }
    /**
     * Function to delete a user
     * @param lastSignInDate
     */
    $scope.daysSinceSignIn = function (lastSignInDate) {
      if (lastSignInDate == null) {
        return "r";
      }
      let lastDate = new Date(lastSignInDate).getTime(),
        currentDate = new Date().getTime();

      if (currentDate - lastDate < THIRTY_DAYS) {
        return "g";
      } else if (currentDate - lastDate >= THIRTY_DAYS) {
        return "y";
      }
    };

    // Not sure why but this is required to get the filter to work
    $scope.searchTextBoxChange = function (text) {
      $scope.searchText = text;
    };

    init();
  },
]);

App.controller("customerScoreModalCtrl", [
  "$scope",
  "$modalInstance",
  function ($scope, $modalInstance) {
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };
  },
]);

App.controller("panelCannotBeMovedModalCtrl", [
  "$scope",
  "$modalInstance",
  function ($scope, $modalInstance) {
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };
  },
]);

/**
 * Controller: DeleteRequestModalCtrl
 *
 * Description:
 * This controller manages the Delete Request Modal. It's responsible for handling the deletion
 * of control systems or sites. The implementation was updated to accommodate a new modal workflow
 * that supports these deletions.
 *
 * Purpose:
 * The primary purpose of this controller is to facilitate the deletion process and ensure
 * the corresponding UI updates on the React side. It achieves this by passing the ID of the
 * deleted system through the AngularJS `$rootScope`, which in turn triggers the React table
 * to remove the corresponding panel or X1 system from its list.
 *
 * Note:
 * This controller plays a crucial role in maintaining synchronization between AngularJS and React
 * components, especially in scenarios involving dynamic UI updates post deletion operations.
 */

App.controller("DeleteRequestModalCtrl", [
  "$scope",
  "systemId",
  "title",
  "Customer",
  "$modalInstance",
  "RelayService",
  "$filter",
  "DashboardDataService",
  "SimManagementService",
  "UserService",
  "$rootScope",
  "$compile",
  "summary",
  "RecentActivityService",

  function (
    $scope,
    systemId,
    title,
    Customer,
    $modalInstance,
    RelayService,
    $filter,
    DashboardDataService,
    SimManagementService,
    UserService,
    $rootScope,
    $compile,
    summary,
    RecentActivityService
  ) {
    $scope.UserService = UserService;
    $scope.dealerInfo = UserService.dealerInfo;
    $scope.title = title;
    $scope.systemId = systemId;
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    const deleteSystemSite = (system) => {
      return RelayService.commitMutation(
        graphql`
          mutation customerSummaryCtrl_RemoveSiteMutation($siteId: ID!) {
            removeSite(siteId: $siteId) {
              __typename
              ... on NotFoundError {
                errorType: type
              }
            }
          }
        `,
        { siteId: toSiteId(system.customer_id, system.site_id) }
      );
    };

    $scope.deleteSystem = function () {
      var system = $filter("filter")(summary.controlSystems, {
        id: systemId,
      })[0];

      const promise = systemIsSite(system)
        ? deleteSystemSite(system).then((result) => {
            $rootScope.deletedPanel = systemId;
            //By adding this system.destroy() here forces a re-render on the React side to remove the Site from the list
            //similar to how we remove a normal control system.
            system.destroy();
            return result;
          })
        : system.destroy();

      if (
        !systemIsSite(system) &&
        systemWillBeAutoProgrammed(system) &&
        systemIsScheduledForFutureInstall(system)
      ) {
        SimManagementService.deactivate(
          {
            iccid: system.panels[0].sim_identifier,
            key: UserService.dealerInfo.securecom_license,
          },
          system,
          UserService.dealerInfo.dealer_code,
          system.panels[0].id,
          UserService.dealerInfo.id
        ).then(
          (success) => {},
          (error) => {
            $rootScope.alerts.push({
              type: "error",
              text: "A scheduled cellular activation for this customer was unable to be deleted. Please contact SecureCom Customer Service to delete the scheduled cellular activation.",
            });
          }
        );
      }

      promise
        .then(
          function () {
            $scope.lastDeletedSystem = { removedSystem: systemId };
            RecentActivityService.removeSystem(systemId, systemIsSite(system));
            $rootScope.deletedPanel = systemId;
            // Splice the system out of the list
            var index = summary.controlSystems.indexOf(system);
            if (index > -1) {
              summary.controlSystems.splice(index, 1);
            }
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
  },
]);

App.controller("BillingSystemActionRequestModalCtrl", [
  "$scope",
  "systemId",
  "title",
  "pickNewBillableSystemList",
  "$modalInstance",
  "RelayService",
  "$filter",
  "DashboardDataService",
  "SimManagementService",
  "UserService",
  "$rootScope",
  "$compile",
  "summary",
  "RecentActivityService",
  "Customer",
  "action",
  "openDeleteSystemModal",
  "openMoveSystemModal",
  "system",
  function (
    $scope,
    systemId,
    title,
    pickNewBillableSystemList,
    $modalInstance,
    RelayService,
    $filter,
    DashboardDataService,
    SimManagementService,
    UserService,
    $rootScope,
    $compile,
    summary,
    RecentActivityService,
    Customer,
    action,
    openDeleteSystemModal,
    opemMoveSystemModal,
    system
  ) {
    $scope.UserService = UserService;
    $scope.openDeleteSystemModal = openDeleteSystemModal;
    $scope.openMoveSystemModal = openMoveSystemModal;

    $scope.systemId = systemId;
    $scope.system = system;
    $scope.title = system.name;
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    // this is my customer object
    switch (action) {
      case "delete":
        $scope.do = "delete";
        $scope.doing = "deleting";
        $scope.done = "deleted";
        break;
      case "move":
        $scope.do = "move";
        $scope.doing = "moving";
        $scope.done = "moved";
        break;
      default:
        $scope.do = "delete";
        $scope.doing = "deleting";
        $scope.done = "deleted";
    }

    $scope.customerId = summary.customer.id;
    $scope.customerToSave = new Customer($scope.customerId);

    // Translate the graphQL id back to a scapi system id and generate a new array
    $scope.pickNewBillableSystemList = pickNewBillableSystemList.map(
      (system) => {
        return {
          id: system.isX1
            ? Number(system.siteBillingControlSystemId)
            : Number(fromControlSystemId(system.id).systemId),
          name: system.name,

          siteId: system.isX1 ? Number(fromSiteId(system.id).siteId) : null,
        };
      }
    );
    $scope.new_billing_control_system_id = null;

    $scope.setNewBillingControlSystem = function (newId) {
      $scope.customerToSave.billing_control_system_id = newId;
    };
    $scope.saveNewBillableSystemAndOpenDeleteModal = function () {
      $scope.customerToSave
        .update()
        .then(
          function (data) {},
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: $filter("translate")("api.CUSTOMER_SAVE_FAILED"),
              json: error,
            });
          },
          function (progress) {
            if (progress.status === "success") {
              $scope.cancel();
              $rootScope.alerts.push({
                type: "success",
                text: "New Billing System Saved",
              });
              if (action === "delete") {
                $scope.openDeleteSystemModal($scope.systemId, $scope.system);
                $modalInstance.dismiss("cancel");
              }
              if (action === "move") {
                $scope.openMoveSystemModal($scope.systemId);
                $modalInstance.dismiss("cancel");
              }
            } else {
              $rootScope.alerts.push({
                type: "error",
                text: $filter("translate")("api.CUSTOMER_SAVE_FAILED"),
                json: error,
              });
            }
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      $scope.cancel();
    };
  },
]);

/**
 * @ngdoc object
 * @name App.controller:MoveSystemModalCtrl
 *
 *
 * @description
 *  Controller for the move system modal.
 */
App.controller("MoveSystemModalCtrl", [
  "$scope",
  "$modalInstance",
  "DashboardDataService",
  "UserService",
  "$filter",
  "ControlSystemService",
  "systemId",
  "MoveSystemToCustomerService",
  "summary",
  "$compile",
  "$rootScope",
  "UserCodeService",
  "openPanelCannotBeMovedModal",
  function (
    $scope,
    $modalInstance,
    DashboardDataService,
    UserService,
    $filter,
    ControlSystemService,
    systemId,
    MoveSystemToCustomerService,
    summary,
    $compile,
    $rootScope,
    UserCodeService,
    openPanelCannotBeMovedModal
  ) {
    $scope.UserService = UserService;
    let customersForDealer = [];

    // Set up container to hold information for initiating the move
    $scope.moveInfo = {
      nextCustomer: "",
    };

    // Container for the customer list to show on the view
    $scope.customerListView = [];

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    $scope.walletCredsPresent = null;
    $scope.checkIfPanelHasWalletCreds = async function () {
      try {
        const data = await UserCodeService.getCredentialsByPanelId(
          systemId,
          "credential_type eq 'digital_card'"
        );

        return ($scope.walletCredsPresent = data.value.length > 0);
      } catch (error) {
        console.error("Error retrieving system creds");
        return false;
      }
    };

    // Once the user starts typing to find a customer, filter the list of customers down to those who match the search
    $scope.refreshCustomerList = function (searchParam) {
      $scope.customerListView = $filter("filter")(
        customersForDealer,
        searchParam
      );
    };
    $scope.getWalletCreds = async function () {
      await $scope.checkIfPanelHasWalletCreds();

      if ($scope.walletCredsPresent) {
        openPanelCannotBeMovedModal();
        $modalInstance.dismiss("cancel");
      }
    };
    $scope.getWalletCreds();
    $scope.moveSystem = async function () {
      let system = $filter("filter")(summary.controlSystems, {
        id: systemId,
      })[0];

      MoveSystemToCustomerService.moveSystem(
        UserService.customer_id,
        $scope.moveInfo.nextCustomer.id,
        systemId
      )
        .then(
          function (success) {
            $rootScope.movedSystemInfo = {
              systemId: systemId,
              customerId: UserService.customer_id,
            };

            $scope.cancel();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "unable to move customer",
              json: error,
            });
          }
        )
        .catch(function (error) {});
    };

    function init() {
      // Grab the customers for the dealer
      DashboardDataService.getCustomersForDealer(UserService.dealer_id)
        .then(
          function (customers) {
            customersForDealer = customers;
          },
          function (error) {}
        )
        .catch(function (error) {});
    }

    init();
  },
]);
