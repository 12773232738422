import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  keyfobListItemTemplateId,
  KEYFOB_IDS,
} from "components/FullProgramming/common/KeyfobFields/KeyfobNumberField";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  ControlSystem,
  fromControlSystemId,
  fromKeyfobId,
  idAsString,
  Keyfob,
  Panel,
  toGlobalId,
  toKeyfobId,
} from "securecom-graphql/client";
import { useControlSystemFragment } from "../../common/ControlSystemContext";
import KeyfobFields from "../../common/KeyfobFields";
import {
  XTKeyfobProgrammingConceptFormInline_controlSystem$data,
  XTKeyfobProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XTKeyfobProgrammingConceptFormInline_controlSystem.graphql";
import {
  XTKeyfobProgrammingConceptFormInline_keyfob$data,
  XTKeyfobProgrammingConceptFormInline_keyfob$key,
} from "./__generated__/XTKeyfobProgrammingConceptFormInline_keyfob.graphql";
import { XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import { XTKeyfobProgrammingConceptFormKeyfobDeleteMutation } from "./__generated__/XTKeyfobProgrammingConceptFormKeyfobDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XTKeyfobProgrammingConceptFormKeyfobRefreshMutation,
} from "./__generated__/XTKeyfobProgrammingConceptFormKeyfobRefreshMutation.graphql";
import {
  XTKeyfobProgrammingConceptFormKeyfobSendMutation,
  XTKeyfobProgrammingConceptFormKeyfobSendMutation$data,
} from "./__generated__/XTKeyfobProgrammingConceptFormKeyfobSendMutation.graphql";
import { XTKeyfobProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XTKeyfobProgrammingConceptFormNavButton_controlSystem.graphql";
import { XTKeyfobProgrammingConceptForm_controlSystem$key } from "./__generated__/XTKeyfobProgrammingConceptForm_controlSystem.graphql";

export const title = "Key Fobs";
export const conceptId = "xt-key-fobs";

export const getState = (
  controlSystem: XTKeyfobProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XTKeyfobProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          keyfobs {
            __typename
            id
            number
            isNew
            ...XTKeyfobProgrammingConceptFormInline_keyfob
          }
        }
      }
    `,
    controlSystem
  );

export const getKeyfobState = (
  keyfob: XTKeyfobProgrammingConceptFormInline_keyfob$key
) =>
  readInlineData(
    graphql`
      fragment XTKeyfobProgrammingConceptFormInline_keyfob on Keyfob @inline {
        __typename
        id
        number
        keyfobSerialNumber
        numberOfButtons
        keyfobSupervisionTime
        buttonOneAction
        buttonTwoAction
        buttonThreeAction
        buttonFourAction
        buttonOneOutputAction
        buttonTwoOutputAction
        buttonThreeOutputAction
        buttonFourOutputAction
        buttonOneOutput
        buttonTwoOutput
        buttonThreeOutput
        buttonFourOutput
        buttonOnePressTime
        buttonTwoPressTime
        buttonThreePressTime
        buttonFourPressTime
        buttonOneAreas
        buttonTwoAreas
        buttonThreeAreas
        buttonFourAreas
        user {
          __typename
          id
          number
          name
        }
        isNew
      }
    `,
    keyfob
  );

const deleteMutation = graphql`
  mutation XTKeyfobProgrammingConceptFormKeyfobDeleteMutation($keyfobId: ID!) {
    deleteKeyfob(keyfobId: $keyfobId) {
      ... on DeleteKeyfobSuccessPayload {
        __typename
        deletedKeyfobId
      }
      ... on FailedToRemoveKeyfobErrorPayload {
        error: type
      }
    }
  }
`;

const retrieveMutation = graphql`
  mutation XTKeyfobProgrammingConceptFormKeyfobRefreshMutation($id: ID!) {
    refreshKeyfobs(id: $id) {
      ... on RefreshKeyfobsSuccessPayload {
        __typename
        controlSystem {
          ...XTKeyfobProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XTKeyfobProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XTKeyfobProgrammingConceptFormKeyfobRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id: systemId } = getState(props.controlSystem);
        retrieve({
          variables: {
            id: systemId,
          },
          onCompleted: (response) => {
            const { controlSystem, error, __typename } =
              response.refreshKeyfobs;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Key Fobs Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id: systemId,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshKeyfobs: {
                    __typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Key Fobs: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Key Fobs",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XTKeyfobProgrammingConceptFormKeyfobSendMutation(
    $systemId: ID!
    $keyfobs: [KeyfobProgrammingInput!]!
  ) {
    sendKeyfobsProgramming(systemId: $systemId, keyfobs: $keyfobs) {
      ... on Error {
        type
      }
      ... on SendKeyfobsProgrammingSuccessPayload {
        results {
          __typename
          ... on SendKeyfobsProgrammingKeyfobSuccessPayload {
            keyfob {
              __typename
              id
              number
              ...XTKeyfobProgrammingConceptFormInline_keyfob
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
  }
`;

const mergeOldAndNewKeyfobs = (
  response: XTKeyfobProgrammingConceptFormKeyfobSendMutation$data,
  originalControlSystemData: XTKeyfobProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendKeyfobsProgramming.results) {
    const successfulKeyfobs = response.sendKeyfobsProgramming.results
      .map((keyfob) => {
        if (
          keyfob.__typename === "SendKeyfobsProgrammingKeyfobSuccessPayload"
        ) {
          return keyfob;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.keyfob)
      .map(getKeyfobState);

    const mergedKeyfobsMap = new Map<
      string,
      XTKeyfobProgrammingConceptFormInline_keyfob$data
    >();

    originalControlSystemData.panel.keyfobs
      .map(getKeyfobState)
      .forEach((item) => mergedKeyfobsMap.set(item.id, item));

    successfulKeyfobs.forEach((item) =>
      mergedKeyfobsMap.set(item.id, {
        ...mergedKeyfobsMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedKeyfobsMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XTKeyfobProgrammingConceptFormKeyfobSendMutation$data,
  originalControlSystemData: XTKeyfobProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedKeyfobs = mergeOldAndNewKeyfobs(
    response,
    originalControlSystemData
  );

  // Update original data store
  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshKeyfobs: {
        __typename: "RefreshKeyfobsSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            keyfobs: mergedKeyfobs,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XTKeyfobProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XTKeyfobProgrammingConceptFormKeyfobSendMutation>(saveMutation);

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const changedKeyfobs = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { keyfobs },
        } = getState(props.controlSystem);
        save({
          variables: {
            systemId,
            keyfobs: keyfobs
              .filter(
                (keyfob) =>
                  keyfob.isNew ||
                  (!!changedKeyfobs &&
                    listItemHasChanged(keyfob.id, changedKeyfobs)) ||
                  isSavingAllListItems
              )
              .map(getKeyfobState)
              .map((keyfob) => ({
                id: keyfob.id,
                number: keyfob.number,
                userNumber: keyfob.user?.number ?? 0,
                buttonOneAction: keyfob.buttonOneAction,
                buttonTwoAction: keyfob.buttonTwoAction,
                buttonThreeAction: keyfob.buttonThreeAction,
                buttonFourAction: keyfob.buttonFourAction,
                buttonOneAreas: keyfob.buttonOneAreas,
                buttonTwoAreas: keyfob.buttonTwoAreas,
                buttonThreeAreas: keyfob.buttonThreeAreas,
                buttonFourAreas: keyfob.buttonFourAreas,
                numberOfButtons: keyfob.numberOfButtons,
                keyfobSerialNumber: keyfob.keyfobSerialNumber,
                buttonOneOutputAction: keyfob.buttonOneOutputAction,
                buttonTwoOutputAction: keyfob.buttonTwoOutputAction,
                buttonThreeOutputAction: keyfob.buttonThreeOutputAction,
                buttonFourOutputAction: keyfob.buttonFourOutputAction,
                buttonOneOutput: keyfob.buttonOneOutput,
                buttonTwoOutput: keyfob.buttonTwoOutput,
                buttonThreeOutput: keyfob.buttonThreeOutput,
                buttonFourOutput: keyfob.buttonFourOutput,
                buttonOnePressTime: keyfob.buttonOnePressTime,
                buttonTwoPressTime: keyfob.buttonTwoPressTime,
                buttonThreePressTime: keyfob.buttonThreePressTime,
                buttonFourPressTime: keyfob.buttonFourPressTime,
                keyfobSupervisionTime: keyfob.keyfobSupervisionTime,
                isNew: keyfob.isNew,
              })),
          },
          onCompleted: (response) => {
            const sendErrors: SaveErrors = [];
            if (response.sendKeyfobsProgramming.type && showAlerts) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendKeyfobsProgramming.results) {
              response.sendKeyfobsProgramming.results.forEach((response) => {
                if (
                  response.__typename ===
                  "SendKeyfobsProgrammingKeyfobSuccessPayload"
                ) {
                  resetLastUpdated(response.keyfob.id);
                } else if (
                  response.__typename === "SendListItemsErrorPayload"
                ) {
                  sendErrors.push({
                    programmingConcept: title,
                    errors: response.errors,
                    listItemNumber: response.number,
                  });
                }
              });

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (!sendErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: `Successfully Updated ${title}`,
                });
              }
            }
            resolve(sendErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSaving,
  ];
};

const readKeyfobsTemplateData = (
  programmingTemplateConcepts: XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        keyfobs {
          id
          included
          number
          userNumber {
            included
            data
          }
          buttonOneAction {
            included
            data
          }
          buttonTwoAction {
            included
            data
          }
          buttonThreeAction {
            included
            data
          }
          buttonFourAction {
            included
            data
          }
          buttonOneAreas {
            included
            data
          }
          buttonTwoAreas {
            included
            data
          }
          buttonThreeAreas {
            included
            data
          }
          buttonFourAreas {
            included
            data
          }
          numberOfButtons {
            included
            data
          }
          buttonOneOutputAction {
            included
            data
          }
          buttonTwoOutputAction {
            included
            data
          }
          buttonThreeOutputAction {
            included
            data
          }
          buttonFourOutputAction {
            included
            data
          }
          buttonOneOutput {
            included
            data
          }
          buttonTwoOutput {
            included
            data
          }
          buttonThreeOutput {
            included
            data
          }
          buttonFourOutput {
            included
            data
          }
          buttonOnePressTime {
            included
            data
          }
          buttonTwoPressTime {
            included
            data
          }
          buttonThreePressTime {
            included
            data
          }
          buttonFourPressTime {
            included
            data
          }
          keyfobSupervisionTime {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).keyfobs;

export function applyTemplateData(
  programmingTemplateConcepts: XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const keyfobRecordProxies =
    panelRecordProxy.getLinkedRecords("keyfobs") ?? [];
  const keyfobsByNumber = indexRecordProxiesByNumber(keyfobRecordProxies);

  const keyfobsTemplateData =
    readKeyfobsTemplateData(programmingTemplateConcepts) ?? [];

  keyfobsTemplateData.forEach((keyfobTemplateData) => {
    if (keyfobTemplateData?.included) {
      let keyfobRecordProxy = keyfobsByNumber.get(keyfobTemplateData.number);
      if (!keyfobRecordProxy) {
        const newKeyfobId = applyNewDeviceToKeyfobsList(
          {
            id: panelRecordProxy.getValue("id"),
            keyfobsRange: panelRecordProxy.getValue("keyfobsRange") ?? null,
            newKeyfob: panelRecordProxy.getLinkedRecord("newKeyfob") && {
              id: panelRecordProxy.getLinkedRecord("newKeyfob").getValue("id"),
            },
            outputInformations:
              panelRecordProxy
                .getLinkedRecords("outputInformations")
                ?.map((recordProxy) => ({
                  number: recordProxy.getValue("number"),
                })) ?? [],
            zoneInformations:
              panelRecordProxy
                .getLinkedRecords("zoneInformations")
                ?.map((recordProxy) => ({
                  number: recordProxy.getValue("number"),
                })) ?? [],
            keyfobs: keyfobRecordProxies.map((recordProxy) => ({
              number: recordProxy.getValue("number"),
            })),
          },
          store
        );
        if (newKeyfobId) {
          keyfobRecordProxy = store.get(newKeyfobId) as RecordProxy<Keyfob>;
          if (keyfobRecordProxy) {
            keyfobRecordProxy.setValue(keyfobTemplateData.number, "number");
          }
        }
      }

      if (!keyfobRecordProxy) {
        return;
      }

      applyTemplateScalarDataToRecordProxy(
        keyfobRecordProxy,
        keyfobTemplateData
      );

      if (!keyfobsByNumber.has(keyfobTemplateData.number)) {
        keyfobsByNumber.set(keyfobTemplateData.number, keyfobRecordProxy);
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(keyfobsByNumber),
    "keyfobs"
  );
}

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XTKeyfobProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XTKeyfobProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            keyfobs {
              isNew
            }
          }
        }
      `
    );
  const { keyfobs } = controlSystem.panel;
  const itemsCount = keyfobs.length;
  const hasNewItems = itemsCount > 0 && keyfobs.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XTKeyfobProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XTKeyfobProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedKeyfob {
            id
          }
          panel {
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            keyfobsRange
            maxKeyfobs
            outputInformations {
              id
              number
            }
            zoneInformations {
              id
              number
            }
            keyfobs {
              id
              number
              keyfobSerialNumber
              isNew
              ...KeyfobFields_keyfob
            }
            newKeyfob {
              id
              number
              keyfobSerialNumber
              numberOfButtons
              keyfobSupervisionTime
              buttonOneAction
              buttonTwoAction
              buttonThreeAction
              buttonFourAction
              buttonOneOutputAction
              buttonTwoOutputAction
              buttonThreeOutputAction
              buttonFourOutputAction
              buttonOneOutput
              buttonTwoOutput
              buttonThreeOutput
              buttonFourOutput
              buttonOnePressTime
              buttonTwoPressTime
              buttonThreePressTime
              buttonFourPressTime
              buttonOneAreas
              buttonTwoAreas
              buttonThreeAreas
              buttonFourAreas
              isNew
              user {
                __typename
                id
                number
                name
              }
              ...KeyfobFields_keyfob
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseKeyfobNumberRange_panel
            ...PanelContextUseUserList_panel
            ...PanelContextUseSystemOptionsSystemType_panel
            ...KeyfobSerialNumberField_SerialNumberList_panel
            ...KeyfobNumberField_panel
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
        }
      `
    );
  const relayEnv = useRelayEnvironment();
  const uncheckListItem = useUncheckListItem()(KEYFOB_IDS);
  const {
    keyfobs,
    newKeyfob,
    helpFiles: { programmingGuideUrl },
  } = controlSystem.panel;

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    keyfobs[0]?.id ?? null
  );

  const [deleteKeyfob, isDeleting] =
    useMutation<XTKeyfobProgrammingConceptFormKeyfobDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();

  const parentRelayEnv = useParentRelayEnvironment();
  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const removeSelectedKeyfob = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.keyfobs.findIndex(
          (keyfobs) => keyfobs.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.keyfobs.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return controlSystem.panel.keyfobs[newSelectedIndex]?.id ?? null;
      });
      const keyfob = controlSystem.panel.keyfobs.find(
        (keyfob) => keyfob.id === selectedListItemId
      );
      uncheckListItem(String(keyfob?.number));
      if (keyfob?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "keyfobs",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "Keyfob",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            keyfob?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteKeyfob({
          variables: {
            keyfobId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "keyfobs",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedKeyfobId } = response.deleteKeyfob;
            if (deletedKeyfobId) {
              showAlert({
                type: "success",
                text: "Keyfob Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "keyfobs",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedKeyfobId, error } = response.deleteKeyfob;
            if (deletedKeyfobId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedKeyfobId) {
                    removeListItemFromStore(
                      selectedListItemId,
                      "keyfobs",
                      controlSystem.panel.id,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Keyfob: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Delete Keyfob",
                });
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Keyfob",
            });
          },
        });
      }
    }
  };

  const availableNumbers = getAvailableNumbers(controlSystem.panel);
  const canAdd = availableNumbers.size > 0 && !!newKeyfob;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#1100%20Series%20Key%20Fobs`}
        title={title}
        deleting={isDeleting}
        isArrayConcept
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.keyfobs
        )}
        amountAvailable={availableNumbers.size}
        addButton={
          <ProgrammingConceptForm.AddButton
            onClick={() => {
              relayEnv.commitUpdate((store) => {
                const newKeyfobId = applyNewDeviceToKeyfobsList(
                  controlSystem.panel,
                  store
                );
                if (newKeyfobId) {
                  setSelectedListItemId(newKeyfobId);
                }
              });
            }}
          >
            Add Keyfob
          </ProgrammingConceptForm.AddButton>
        }
      >
        <RemountOnUpdateContainer nodeId={conceptId}>
          {(conceptId === activeConcept || isApplying || isSavingAll) && (
            <ProgrammingConceptForm.ListItemsContainer>
              <ProgrammingConceptForm.ListItemPicker
                selectedId={selectedListItemId}
                onChange={(id) => {
                  setSelectedListItemId(id);
                }}
                newItemId={newKeyfob?.id}
                items={keyfobs.map((keyfob) => ({
                  id: keyfob.id,
                  templateListItemId: keyfobListItemTemplateId(
                    String(keyfob.number)
                  ),
                  isnew: keyfob.isNew,
                  label: `#${keyfob.number} SN:${keyfob.keyfobSerialNumber}`,
                }))}
              />
              <ProgrammingConceptForm.SelectedItemsContainer
                selectedListItemId={selectedListItemId}
                setSelectedListItemId={setSelectedListItemId}
              >
                {keyfobs.map(
                  (keyfob) =>
                    (keyfob.id === selectedListItemId || //Rendering at the last second before saving to check if there are errors or changes
                      programmingConcepts[conceptId].isSaving ||
                      isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                      isSavingAll) && (
                      <RemountOnUpdateContainer nodeId={keyfob.id}>
                        <ProgrammingConceptForm.SelectedItem
                          conceptId={conceptId}
                          isnew={keyfob.isNew}
                          visible={keyfob.id === selectedListItemId}
                          key={keyfob.id}
                          listItemId={keyfob.id}
                          templateListItemId={keyfobListItemTemplateId(
                            String(keyfob.number)
                          )}
                          title={`# ${keyfob.number} Serial No: ${keyfob.keyfobSerialNumber}`}
                          onDuplicate={
                            canAdd &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const duplicateId =
                                  applyDuplicatedKeyfobToKeyfobsList(
                                    selectedListItemId,
                                    controlSystem.panel,
                                    store
                                  );
                                if (duplicateId) {
                                  setSelectedListItemId(duplicateId);
                                }
                              });
                            })
                          }
                          onCopy={() => {
                            relayEnv.commitUpdate((store) => {
                              const controlSystemRecord = store.get(
                                controlSystem.id
                              );
                              const keyfobRecord =
                                store.get<Keyfob>(selectedListItemId);
                              if (controlSystemRecord && keyfobRecord) {
                                const tempRecord =
                                  store.get("copiedKeyfob") ??
                                  store.create("copiedKeyfob", "Keyfob");
                                tempRecord.copyFieldsFrom(keyfobRecord);
                                controlSystemRecord.setLinkedRecord(
                                  tempRecord,
                                  "copiedKeyfob"
                                );
                              }
                            });
                          }}
                          onPaste={
                            !!controlSystem.copiedKeyfob &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const keyfobRecord =
                                  store.get<Keyfob>(selectedListItemId);
                                const copiedKeyfobRecord =
                                  store.get<Keyfob>("copiedKeyfob");
                                if (keyfobRecord && copiedKeyfobRecord) {
                                  applyKeyfobProgrammingToKeyfob(
                                    copiedKeyfobRecord,
                                    keyfobRecord
                                  );
                                }
                              });
                            })
                          }
                          onRemove={removeSelectedKeyfob}
                        >
                          <KeyfobFields key={keyfob.id} keyfob={keyfob} />
                        </ProgrammingConceptForm.SelectedItem>
                      </RemountOnUpdateContainer>
                    )
                )}
              </ProgrammingConceptForm.SelectedItemsContainer>
            </ProgrammingConceptForm.ListItemsContainer>
          )}
        </RemountOnUpdateContainer>
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}

const applyNewDeviceToKeyfobsList = (
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly newKeyfob: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newKeyfob } = panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newKeyfobTemplate = panelRecord.getLinkedRecord("newKeyfob");
    if (newKeyfob) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromKeyfobId(asID(newKeyfobTemplate.getDataID()));
        const nextNewKeyfobId = idAsString(toKeyfobId(systemId, nextNumber));
        const nextNewKeyfob = store.create(
          nextNewKeyfobId,
          "Keyfob"
        ) as RecordProxy<Keyfob>;
        nextNewKeyfob.copyFieldsFrom(newKeyfobTemplate);
        nextNewKeyfob.setValue(nextNewKeyfobId, "id");
        nextNewKeyfob.setValue(nextNumber, "number");
        nextNewKeyfob.setValue(true, "isNew");

        const keyfobRecords = panelRecord.getLinkedRecords("keyfobs") ?? [];
        panelRecord.setLinkedRecords(
          [...keyfobRecords, nextNewKeyfob],
          "keyfobs"
        );

        return nextNewKeyfob.getValue("id");
      }
    }
  }
};

const applyDuplicatedKeyfobToKeyfobsList = (
  keyfobID: string,
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly newKeyfob: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newKeyfob } = panel;
  const panelRecord = store.get<Panel>(id);
  const keyfobRecord = store.get<Keyfob>(keyfobID);
  if (panelRecord && keyfobRecord) {
    const newKeyfobTemplate = panelRecord.getLinkedRecord("newKeyfob");
    if (newKeyfob) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromKeyfobId(asID(newKeyfobTemplate.getDataID()));
        const nextNewKeyfobId = idAsString(toKeyfobId(systemId, nextNumber));
        const nextNewKeyfob = store.create(
          nextNewKeyfobId,
          "Keyfob"
        ) as RecordProxy<Keyfob>;
        nextNewKeyfob.copyFieldsFrom(newKeyfobTemplate);
        nextNewKeyfob.setValue(nextNewKeyfobId, "id");
        nextNewKeyfob.setValue(nextNumber, "number");
        nextNewKeyfob.setValue(true, "isNew");

        const duplicatedKeyfobRecord = applyKeyfobProgrammingToKeyfob(
          keyfobRecord,
          nextNewKeyfob
        );
        const keyfobRecords = panelRecord.getLinkedRecords("keyfobs") ?? [];
        panelRecord.setLinkedRecords(
          [...keyfobRecords, duplicatedKeyfobRecord],
          "keyfobs"
        );

        return duplicatedKeyfobRecord.getValue("id");
      }
    }
  }
};

const applyKeyfobProgrammingToKeyfob = (
  source: RecordProxy<Keyfob>,
  dest: RecordProxy<Keyfob>
) => {
  const id = dest.getValue("id");
  const number = dest.getValue("number");
  const isNew = dest.getValue("isNew");
  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(number, "number");
  return dest;
};

const getAvailableNumbers = (panel: {
  readonly keyfobsRange: ReadonlyArray<number> | null;
  readonly outputInformations: ReadonlyArray<{ readonly number: number }>;
  readonly keyfobs: ReadonlyArray<{ readonly number: number }>;
  readonly zoneInformations: ReadonlyArray<{ readonly number: string }>;
}) => {
  const { keyfobsRange, outputInformations, zoneInformations } = panel;

  const allPossibleNumbers = new Set(keyfobsRange);

  const takenNumbers = new Set(
    panel.keyfobs
      .map(({ number }) => Number(number))
      .concat(outputInformations.map(({ number }) => Number(number)))
      .concat(zoneInformations.map(({ number }) => Number(number)))
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(panel));
