import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { AllowTimeChange } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsObserveDstField_systemOptions$key } from "./__generated__/SystemOptionsObserveDstField_systemOptions.graphql";

export const systemOptionsObserveDstFieldId = () =>
  "system-options-observe-dst";

function ObserveDstField() {
  const [{ observeDst, timeChange }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsObserveDstField_systemOptions$key>(
      graphql`
        fragment SystemOptionsObserveDstField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            observeDst
          }
          ... on XfSystemOptions {
            observeDst
          }
          ... on XtSystemOptions {
            observeDst
          }
          ... on Xt75SystemOptions {
            observeDst
          }
          ... on Xt75SystemOptions {
            observeDst
          }
          ... on TMSentrySystemOptions {
            observeDst
          }
          timeChange
        }
      `
    );

  const fieldId = systemOptionsObserveDstFieldId();
  const label = "Observe DST";
  const disabled = ![AllowTimeChange.SERVER].includes(
    timeChange as AllowTimeChange
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable to observe Daylight Savings Time when the panel's time is updated via SecureCom."
      disabled={disabled}
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={observeDst ?? false}
        disabled={disabled}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!observeDst, "observeDst");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ObserveDstField;
