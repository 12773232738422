/**
 * @generated SignedSource<<1aaa0ab7e01bc358892296b49765fc75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ZoneArmingStyle = "ARM" | "DISARM" | "MAINTAIN" | "NONE" | "STEP" | "TOGGLE";
export type ZoneChimeSound = "ASCEND" | "DESCEND" | "DOORBELL" | "NONE" | "OFF";
export type ZoneEntryDelayNumber = "FOUR" | "ONE" | "THREE" | "TWO";
export type ZoneMessage = "ALARM" | "CANCEL_AMBUSH" | "DOOR_PROPPED_OPEN" | "LOCAL" | "NONE" | "SENSOR_RESET" | "TROUBLE";
export type ZoneOutputAction = "FOLLOW" | "MOMENTARY" | "NONE" | "PULSE" | "STEADY";
export type ZonePetImmunity = "NONE" | "OFF" | "ON";
export type ZonePirPulseCount = "FOUR" | "NONE" | "TWO";
export type ZonePirSensitivity = "HIGH" | "LOW" | "NONE";
export type ZoneReceiverRoute = "BOTH_RECEIVERS" | "NONE" | "NORMAL" | "RECEIVER_ONE_ONLY" | "RECEIVER_TWO_ONLY";
export type ZoneSensorType = "GLASSBREAK" | "HOLD_UP" | "PIR_OR_RECESSED_DOOR" | "REPEATER" | "SMOKE" | "UNIVERSAL_TRANSMITTER" | "UNKNOWN_DEVICE" | "WIRELESS_FOUR_ZONE_EXPANDER" | "WIRELESS_PIR";
export type ZoneSupervisionTime = "NONE" | "_1440_MIN" | "_240_MIN" | "_3_MIN" | "_60_MIN" | "_720_MIN";
export type ZoneTransmitterContact = "CONTACT_3" | "CONTACT_4" | "EXTERNAL" | "INTERNAL";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type XTZoneInformationsProgrammingConceptFormInline_zone$data = {
  readonly __typename: "Zone";
  readonly area: {
    readonly __typename: string;
    readonly id: string;
    readonly number: string;
  } | null;
  readonly armedAreasForArmingZone: string;
  readonly armedOpenActionMessage: ZoneMessage | null;
  readonly armedOpenOutputAction: ZoneOutputAction | null;
  readonly armedOpenOutputNumber: string;
  readonly armedShortActionMessage: ZoneMessage | null;
  readonly armedShortOutputAction: ZoneOutputAction | null;
  readonly armedShortOutputNumber: string;
  readonly armingStyle: ZoneArmingStyle | null;
  readonly chimeSound: ZoneChimeSound | null;
  readonly competitorWireless: boolean | null;
  readonly contactNumber: ZoneTransmitterContact | null;
  readonly crossZoneEnabled: boolean;
  readonly disarmedOpenActionMessage: ZoneMessage | null;
  readonly disarmedOpenOutputAction: ZoneOutputAction | null;
  readonly disarmedOpenOutputNumber: string | null;
  readonly disarmedShortActionMessage: ZoneMessage | null;
  readonly disarmedShortOutputAction: ZoneOutputAction | null;
  readonly disarmedShortOutputNumber: string;
  readonly entryDelayNumber: ZoneEntryDelayNumber | null;
  readonly expanderSerialNumber: string | null;
  readonly fastResponseEnabled: boolean;
  readonly firePanelSlaveInput: boolean;
  readonly followArea: {
    readonly __typename: string;
    readonly number: string;
  } | null;
  readonly id: string;
  readonly is24HrZone: boolean;
  readonly isNew: boolean;
  readonly location: string | null;
  readonly lockdownEnabled: boolean;
  readonly name: string;
  readonly normallyClosed: boolean | null;
  readonly number: string;
  readonly prewarnKeypads: string;
  readonly priorityZone: boolean;
  readonly realTimeStatusEnabled: boolean;
  readonly receiverRouting: ZoneReceiverRoute | null;
  readonly reportWithAccountNumberForArea: {
    readonly __typename: string;
    readonly number: string;
  } | null;
  readonly retardDelayEnabled: boolean;
  readonly sensorType: ZoneSensorType;
  readonly serialNumber: string;
  readonly supervisionTime: ZoneSupervisionTime | null;
  readonly swingerBypassEnabled: boolean;
  readonly tamperEnabled: boolean | null;
  readonly trafficCountEnabled: boolean;
  readonly type: ZoneType;
  readonly wireless: boolean;
  readonly wirelessContactNormallyOpen: boolean;
  readonly wirelessDisarmDisableEnabled: boolean;
  readonly wirelessLedEnabled: boolean;
  readonly wirelessPetImmunity: ZonePetImmunity;
  readonly wirelessPirPulseCount: ZonePirPulseCount | null;
  readonly wirelessPirSensitivity: ZonePirSensitivity | null;
  readonly zoneAuditDays: string;
  readonly " $fragmentType": "XTZoneInformationsProgrammingConceptFormInline_zone";
};
export type XTZoneInformationsProgrammingConceptFormInline_zone$key = {
  readonly " $data"?: XTZoneInformationsProgrammingConceptFormInline_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTZoneInformationsProgrammingConceptFormInline_zone">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTZoneInformationsProgrammingConceptFormInline_zone"
};

(node as any).hash = "8a8ab6f7769fd38240312f430ca57fb4";

export default node;
