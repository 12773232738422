App.directive("daActiveDualsimCheck", function () {
  return {
    require: "^form",
    link: function (scope, element, attrs, formController) {
      scope.$watchGroup(
        [
          "CSC.sim1.status_type",
          "CSC.sim2.status_type",
          "dualSimPanelOpen",
          "controlSystem.panels[0].comm_type",
        ],
        function () {
          const dualSim1InputNgModel = formController["dualsim_one_identifier"];
          const dualSim2InputNgModel = formController["dualsim_two_identifier"];
          const commType = scope.controlSystem.panels[0].comm_type;

          if (!dualSim1InputNgModel || !dualSim2InputNgModel) {
            return;
          }

          if (commType !== "cell" && commType !== "persistent_w_cell_backup") {
            dualSim1InputNgModel.$setValidity("dualSimPanelValid", true);
            dualSim2InputNgModel.$setValidity("dualSimPanelValid", true);
            return;
          }

          const sim1Status = scope.CSC.sim1?.status_type;
          const sim2Status = scope.CSC.sim2?.status_type;

          if (
            (scope.isActiveOrActivePending(sim1Status) ||
              sim1Status === "Please Wait...") &&
            (scope.isActiveOrActivePending(sim2Status) ||
              sim2Status === "Please Wait...") &&
            !scope.dualSimPanelOpen
          ) {
            dualSim1InputNgModel.$setValidity("dualSimPanelValid", false);
            dualSim2InputNgModel.$setValidity("dualSimPanelValid", false);
          } else {
            dualSim1InputNgModel.$setValidity("dualSimPanelValid", true);
            dualSim2InputNgModel.$setValidity("dualSimPanelValid", true);
          }
        }
      );
    },
  };
});
