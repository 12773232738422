import * as React from "react";
import styled from "styled-components";

export const IconSvg = styled.svg<{
  size?: string;
  color?: string;
  hoverColor?: string;
}>`
  width: 1em;
  height: 1em;
  font-size: ${({ size }) => size || "var(--measure-font-16)"};
  color: ${({ color }) => color || "currentColor"};
  &:hover {
    color: ${({ hoverColor }) => hoverColor || "currentColor"};
  }
`;

export default function Icon(
  props: React.ComponentProps<typeof IconSvg> & {
    name:
      | "add"
      | "armed_shield_solid"
      | "attention"
      | "camera"
      | "checkmark"
      | "clip"
      | "clips_with_play"
      | "close_cancel"
      | "cloud_download"
      | "control_down"
      | "control_left"
      | "control_right"
      | "control_up"
      | "cycle"
      | "date"
      | "install_guide"
      | "mail"
      | "more"
      | "move_system"
      | "radial_alert"
      | "radial_check"
      | "radial_info"
      | "radial_question"
      | "history"
      | "settings"
      | "switch"
      | "trash"
      | "troubleshooting"
      | "var_box"
      | "visibility_off"
      | "visibility";
  }
) {
  const { name, ...otherProps } = props;
  return (
    <IconSvg fill="currentColor" {...otherProps}>
      <use xlinkHref={`assets/img/sprite.svg#icon-${name}`} />
    </IconSvg>
  );
}
