import graphql from "babel-plugin-relay/macro";
import Icon from "components/Icon";
import {
  DeleteConfirmModal,
  GenericConfirmModal,
} from "components/Modal/ConfirmModal";
import React, { useState } from "react";
import { Disposable, useMutation, Variables } from "react-relay";
import { ErrorType } from "securecom-graphql/client";
import { useCreateNotification } from "../EntryPointContext";
import { SiteControlSystemFormAction } from "./ExistingSiteControlSystemForm";
import { ReplaceX1ModalDeleteX1BackupMutation } from "./__generated__/ReplaceX1ModalDeleteX1BackupMutation.graphql";
import { ReplaceX1ModalReplaceX1Mutation } from "./__generated__/ReplaceX1ModalReplaceX1Mutation.graphql";

interface ReplaceX1ModalProps {
  siteId: string;
  systemName: string;
  hasBackup: boolean;
  onCancel: () => void;
  refetch: (vars: Partial<Variables>, options?: any) => Disposable;
  dispatch: React.Dispatch<SiteControlSystemFormAction>;
  vernaculars:
    | readonly ({
        readonly original: string;
        readonly replacement: string;
      } | null)[]
    | undefined;
}
function ReplaceX1Modal(props: ReplaceX1ModalProps) {
  const {
    siteId,
    systemName,
    hasBackup,
    onCancel,
    refetch,
    dispatch,
    vernaculars,
  } = props;
  const [replaceX1] =
    useMutation<ReplaceX1ModalReplaceX1Mutation>(replaceX1Mutation);
  const [deleteX1Backup, isDeletingingX1Backup] =
    useMutation<ReplaceX1ModalDeleteX1BackupMutation>(deleteX1BackupMutation);
  const createNotification = useCreateNotification();
  const [newSerialNumber, setNewSerialNumber] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isReplacingX1, setIsReplacingX1] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [disableDeleteBackup, setDisableDeleteBackup] = useState(false);
  const serialNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    const serialNumberRegex = new RegExp(/[0-9A-F]{8}/);

    if (value.length === 8) {
      if (!serialNumberRegex.test(value)) {
        setErrorMsg("Enter a new 8-digit serial number (0-9, A-F)");
        setHasError(true);
      }
    } else if (value === "") {
      setErrorMsg("This field is required.");
      setHasError(true);
    } else {
      setHasError(false);
    }

    setNewSerialNumber(value);
  };
  const handleReplaceX1 = () => {
    setIsReplacingX1(true);
    replaceX1({
      variables: {
        siteId: siteId,
        newSerialNumber: newSerialNumber,
      },
      onCompleted: ({ replaceX1 }) => {
        const refetchData = async () => {
          dispatch({ type: "UPDATE_REFETCHING", value: true });
          await refetch({}, { fetchPolicy: "store-and-network" });
          dispatch({ type: "UPDATE_REFETCHING", value: false });
          onCancel();
        };

        if (replaceX1.errorType) {
          setIsReplacingX1(false);
          switch (replaceX1.errorType) {
            case ErrorType.PANEL_NOT_CHECKED_IN:
              setErrorMsg("This device appears to be offline.");
              break;
            case ErrorType.SERIAL_NUMBER_NOT_FOUND:
              setErrorMsg("Serial number not found.");
              break;
            case ErrorType.NOT_FOUND:
              setErrorMsg(`Unable to find ${systemReplacement ?? "system"}.`);
              break;
          }
          setHasError(true);
        } else {
          const delay = 2500;
          // Need to set a delay due to the readOnly database that replacingPanelJobGroupId is
          // being queried from needs to be replicated over after the job is created
          setTimeout(() => {
            refetchData();
            dispatch({ type: "UPDATE_REPLACING_PANEL", value: true });
          }, delay);
        }
      },
      onError: () => {
        setIsReplacingX1(false);
        setErrorMsg(`Unable to find ${systemReplacement ?? "system"}.`);
        setHasError(true);
      },
    });
  };
  const handleDeleteX1Backup = () => {
    deleteX1Backup({
      variables: {
        siteId: siteId,
      },
      onCompleted: ({ deleteX1Backup }) => {
        if (deleteX1Backup.errorType) {
          createNotification({
            type: "error",
            text: "Failed to Delete X1 Backup.",
          });
        } else {
          setDisableDeleteBackup(true);
          refetch({}, { fetchPolicy: "store-and-network" });
          createNotification({
            type: "success",
            text: "X1 Backup Deleted Successfully.",
          });
        }
        setConfirmDeleteOpen(false);
      },
      onError: () => {
        createNotification({
          type: "error",
          text: "Failed to Delete X1 Backup.",
        });
        setConfirmDeleteOpen(false);
      },
    });
  };

  const systemReplacement = vernaculars?.find(
    (v) => v?.original === "systems"
  )?.replacement;

  return (
    <GenericConfirmModal
      icon={<Icon name="switch" />}
      header={`Replace Panel for ${systemName}`}
      cancelText="Cancel"
      suppressConfirm={true}
      onCancel={onCancel}
    >
      <h5 className="mb-lg">{`To ${
        hasBackup ? "retry " : ""
      }replace an X1:`}</h5>
      <ol className="h5 mb-lg">
        <li>
          Ensure the replacement X1 is powered on and connected to EasyConnect
          or Cell.
        </li>
        <li>Enter the serial number of the replacement X1.</li>
        <li>{`Press "${hasBackup ? "Retry " : ""}Replace X1".`}</li>
      </ol>
      {hasBackup ? (
        <>
          <h5 className="mb-lg">
            To replace an X1 with current configuration:
          </h5>
          <ol className="h5 mb-lg">
            <li>
              Delete the original X1 configuration by pressing "Delete X1
              Backup".
            </li>
            <li>Wait for deletion confirmation.</li>
            <li>Follow the steps on replacing an X1 as normal.</li>
          </ol>
        </>
      ) : null}
      <hr />
      <div className="form-horizontal">
        <div className="row">
          <div className="col-sm-12">
            <div className={`form-group ${hasError ? "has-error" : ""}`}>
              <label className="control-label col-sm-3">Serial Number</label>
              <div className="col-sm-9">
                <input
                  id="panel_swap_new_serial_number"
                  name="panel_swap_new_serial_number"
                  type="text"
                  className="form-control"
                  required={true}
                  maxLength={8}
                  onChange={serialNumberChange}
                  placeholder="Enter New Serial Number"
                  value={newSerialNumber}
                  pattern="'[0-9A-F]{8}'"
                />
                {hasError ? <span>{errorMsg}</span> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <button
              type="button"
              disabled={
                hasError || newSerialNumber.length !== 8 || isReplacingX1
              }
              className="btn btn-block btn-dmp mb-lg"
              onClick={handleReplaceX1}
            >
              <span className="btn-font-large">{`${
                hasBackup ? "Retry " : ""
              }Replace X1`}</span>
            </button>
          </div>
        </div>
        {hasBackup ? (
          <div className="row">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-block btn-danger mb-lg"
                disabled={disableDeleteBackup || isDeletingingX1Backup}
                onClick={() => setConfirmDeleteOpen(true)}
              >
                <span className="btn-font-large">Delete X1 Backup</span>
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {confirmDeleteOpen ? (
        <DeleteConfirmModal
          header="Delete X1 Backup"
          children="Are you sure you want to delete the X1 backup configuration?"
          onConfirm={handleDeleteX1Backup}
          onCancel={() => setConfirmDeleteOpen(false)}
        />
      ) : null}
    </GenericConfirmModal>
  );
}

export default ReplaceX1Modal;

const replaceX1Mutation = graphql`
  mutation ReplaceX1ModalReplaceX1Mutation(
    $siteId: ID!
    $newSerialNumber: String!
  ) {
    replaceX1(siteId: $siteId, newSerialNumber: $newSerialNumber) {
      ... on ReplaceX1SuccessResponse {
        jobGroupId
        status
      }
      ... on NotFoundError {
        errorType: type
      }
      ... on SerialNumberNotFoundError {
        errorType: type
      }
      ... on PanelNotCheckedInError {
        errorType: type
      }
    }
  }
`;
const deleteX1BackupMutation = graphql`
  mutation ReplaceX1ModalDeleteX1BackupMutation($siteId: ID!) {
    deleteX1Backup(siteId: $siteId) {
      ... on DeleteX1BackupSuccessResponse {
        status
      }
      ... on NotFoundError {
        errorType: type
      }
    }
  }
`;
