/**
 * @generated SignedSource<<edcc7b0990b31c61a0a4effa172fdef9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AllowTimeChange = "NO" | "SERVER" | "YES";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsTimeChangeField_systemOptions$data = {
  readonly observeDst?: boolean | null;
  readonly timeChange: AllowTimeChange;
  readonly " $fragmentType": "SystemOptionsTimeChangeField_systemOptions";
};
export type SystemOptionsTimeChangeField_systemOptions$key = {
  readonly " $data"?: SystemOptionsTimeChangeField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsTimeChangeField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "observeDst",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsTimeChangeField_systemOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeChange",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "TMSentrySystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "e085066c849463bd2a4f807c08d3cfc9";

export default node;
