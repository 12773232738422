import DaColors from "app/common/DaColors";
import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

// This label/input combo will align to the right. The inputs themselves should line up perfectly
// vertical while the labels only align from the right side

// ex.
//     label1 input
//    label11 input
//   label111 input

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.6rem auto;
  width: 100%;
`;
const Label = styled.label<{ isInvalid?: boolean }>`
  color: ${(props) => (props.isInvalid ? DaColors.Failure500 : "inherit")};
  margin-right: 1.6rem;
  padding-top: 0.7rem;
  width: 12rem;
  text-align: right;
`;
const Input = styled.input<{ isInvalid?: boolean }>`
  flex-grow: 1;
  height: 3.5rem;
  font-size: 1.4rem;
  padding: 0.6rem 1.6rem;
  border: 0.1rem solid
    ${(props) => (props.isInvalid ? DaColors.Failure500 : DaColors.Primary200)};
  border-radius: 0.4rem;

  &:focus {
    border-color: ${(props) =>
      props.isInvalid ? DaColors.Failure500 : DaColors.Info500};
  }

  &:disabled {
    background-color: #edf1f2;
  }
`;
const ErrorMessage = styled.div`
  color: ${DaColors.Failure500};
`;

type Props = {
  errorMessage?: string;
  label?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const CleanLabeledInput = ({ errorMessage, ...htmlProps }: Props) => {
  return (
    <Wrapper>
      <Label htmlFor={htmlProps.id} isInvalid={!!errorMessage}>
        {htmlProps.label}
      </Label>
      <Input {...htmlProps} isInvalid={!!errorMessage} />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

export default CleanLabeledInput;
