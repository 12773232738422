/**
 * @generated SignedSource<<4ffff84b86d810ecd2a3f119b623ee10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagFieldValueQuery$variables = {
  customerId: string;
  getCustomerTags: boolean;
  typeId: string;
};
export type TagFieldValueQuery$data = {
  readonly customerRequiredTags?: {
    readonly tags: ReadonlyArray<{
      readonly billingDealerId: number | null;
      readonly id: string;
      readonly isFixed: boolean | null;
      readonly label: string | null;
      readonly value: string | null;
    } | null>;
  };
  readonly getTagsByType: {
    readonly tags: ReadonlyArray<{
      readonly billingDealerId: number | null;
      readonly id: string;
      readonly isFixed: boolean | null;
      readonly label: string | null;
      readonly value: string | null;
    } | null>;
  };
};
export type TagFieldValueQuery = {
  response: TagFieldValueQuery$data;
  variables: TagFieldValueQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "getCustomerTags"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeId"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Tag",
    "kind": "LinkedField",
    "name": "tags",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isFixed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "billingDealerId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "typeId"
      }
    ],
    "concreteType": "TagFieldValue",
    "kind": "LinkedField",
    "name": "getTagsByType",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "condition": "getCustomerTags",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": "customerRequiredTags",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "customerId"
          }
        ],
        "concreteType": "TagFieldValue",
        "kind": "LinkedField",
        "name": "getTagsByType",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TagFieldValueQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TagFieldValueQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "f254210e4861851d5bfe68abbdcbb65a",
    "id": null,
    "metadata": {},
    "name": "TagFieldValueQuery",
    "operationKind": "query",
    "text": "query TagFieldValueQuery(\n  $typeId: ID!\n  $getCustomerTags: Boolean!\n  $customerId: ID!\n) {\n  getTagsByType(id: $typeId) {\n    tags {\n      id\n      isFixed\n      label\n      value\n      billingDealerId\n    }\n  }\n  customerRequiredTags: getTagsByType(id: $customerId) @skip(if: $getCustomerTags) {\n    tags {\n      id\n      isFixed\n      label\n      value\n      billingDealerId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e7b96265473f115d3e77be7e17e2176";

export default node;
