/**
 * @generated SignedSource<<8298caa64395a5f7865dc1f9d124db3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DistributionSubscriberEulaModalAgreeToEulaMutation$variables = {};
export type DistributionSubscriberEulaModalAgreeToEulaMutation$data = {
  readonly agreeToEula: {
    readonly hasAgreed: boolean;
  };
};
export type DistributionSubscriberEulaModalAgreeToEulaMutation = {
  response: DistributionSubscriberEulaModalAgreeToEulaMutation$data;
  variables: DistributionSubscriberEulaModalAgreeToEulaMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EulaStatus",
    "kind": "LinkedField",
    "name": "agreeToEula",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasAgreed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DistributionSubscriberEulaModalAgreeToEulaMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DistributionSubscriberEulaModalAgreeToEulaMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8b022ba365aaa769fb87a9d269e54247",
    "id": null,
    "metadata": {},
    "name": "DistributionSubscriberEulaModalAgreeToEulaMutation",
    "operationKind": "mutation",
    "text": "mutation DistributionSubscriberEulaModalAgreeToEulaMutation {\n  agreeToEula {\n    hasAgreed\n  }\n}\n"
  }
};
})();

(node as any).hash = "855115b925e5bd9945d33f4b61d1c437";

export default node;
