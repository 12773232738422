import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorUserCommandReportsField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorUserCommandReportsField_remoteOptions.graphql";

export const remoteOptionsIntegratorUserCommandReportsFieldId = () =>
  "remote-options-integrator-user-command-reports";

function RemoteOptionsIntegratorUserCommandReportsField() {
  const [
    { integratorUserCommandReports, integratorConnection },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorUserCommandReportsField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorUserCommandReportsField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorUserCommandReports
          integratorConnection
        }
        ... on Xt75RemoteOptions {
          integratorUserCommandReports
          integratorConnection
        }
        ... on XfRemoteOptions {
          integratorUserCommandReports
          integratorConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorUserCommandReportsFieldId();
  const label = "Integrator User Command Reports";
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label={label}
    >
      <Switch
        id={fieldId}
        disabled={disabled}
        label={label}
        checked={!!integratorUserCommandReports}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !integratorUserCommandReports,
              "integratorUserCommandReports"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorUserCommandReportsField;
