/**
 * @generated SignedSource<<f998e2614a5b014ef5410478e232b1ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AllowTimeChange = "NO" | "SERVER" | "YES";
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
import { FragmentRefs } from "relay-runtime";
export type TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly systemOptions: {
    readonly closingCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly crossZoneTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly displayTime: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly enableKeypadPanicKeys: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay1: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly exitDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly hoursFromGMT: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly observeDst: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly powerFailDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly resetSwingerBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly swingerBypassTrips: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly timeChange: {
      readonly data: AllowTimeChange | null;
      readonly included: boolean | null;
    } | null;
    readonly useFalseAlarmQuestion: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly weatherZipCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessAudibles: {
      readonly data: WirelessAudibles | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "44f0d4f4b2b6c833f739e3a63acf80ae";

export default node;
