/**
 * @name App.service:InitialConnectionService
 *
 * @description Utility service for establishing connection with the panel via a scheduled job.
 *   After the job has been run, the panel and VK will be programmed with the provided acct_num and remote_key.
 */
App.service("InitialConnectionService", [
  "$q",
  "InitialConnectionResource",
  "$modal",
  "$rootScope",
  "ReplacePanelService",
  "ControlSystemsService",
  "ClientEventsService",
  "OnlinePanelService",
  "UserService",
  "$interval",
  "PROPS",
  "$state",
  function (
    $q,
    InitialConnectionResource,
    $modal,
    $rootScope,
    ReplacePanelService,
    ControlSystemsService,
    ClientEventsService,
    OnlinePanelService,
    UserService,
    $interval,
    PROPS,
    $state
  ) {
    var _this = this;

    /**
     * The job group id returned
     * @type {number}
     * @private
     */
    var _jobGroupID = 0;

    /**
     * Status of the panel for which initial connection is being established, set by refreshJob()
     * @type {{}}
     * @private
     */
    var _jobData = {};

    /**
     * Initialize locally stored job data
     */
    var initJobData = function () {
      _jobGroupID = 0;
      _jobData = {};
    };

    var getCurrentPanel = function () {
      var panel = {};
      angular.merge(
        panel,
        ControlSystemsService.currentControlSystem.panels[
          ControlSystemsService.currentControlSystem.panel_index
        ]
      );
      return panel;
    };

    _this.getJobData = function () {
      return _jobData;
    };

    /**
     * Retrieves the job status for the initial connection job
     */
    _this.refreshJob = function () {
      var deferred = $q.defer();
      InitialConnectionResource.get(
        { panel_id: getCurrentPanel().id, groupID: _jobGroupID },
        function (data) {
          _jobData = {};
          angular.extend(_jobData, data.icStatus);
          deferred.resolve();
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * Check if the panel has a connection job in progress, waits till it completes to resolve.
     */
    _this.checkForRunningJob = function () {
      var deferred = $q.defer();
      InitialConnectionResource.getIncomplete(
        { panel_id: getCurrentPanel().id },
        function (data) {
          _jobGroupID = angular.copy(data).Id;
          openStatusModal()
            .then(
              function () {
                deferred.resolve();
              },
              function () {
                deferred.reject();
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        },
        function (error) {
          if (error.status && error.status === 404) {
            deferred.resolve();
          } else {
            console.error(
              "Error retrieving running initial connection job: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to check for running initial connection job",
              json: error,
            });
            deferred.reject();
          }
        }
      );
      return deferred.promise;
    };

    /**
     * When SCAPI connects to the panel, it may create an incomplete System Options object (with only an arm_mode).
     * bypassForSCAPICorrection is set when we detect that condition so that we can connect to the panel without
     * performing a full initial connection. We still want to perform the initial connection later since other
     * behavior is based on that event.
     * @type {boolean}
     */
    _this.bypassForSCAPICorrection = false;
    /**
     * Function that can be called to guarantee we've done all we can to prepare the panel for connection. This
     * includes initial connection done and panel is online.
     * Resolves immediately if all clear
     */
    _this.ensureConnectionReady = function (force) {
      var deferred = $q.defer();
      if (
        !force &&
        (ClientEventsService.initialConnection.hasBeenEstablished() ||
          _this.bypassForSCAPICorrection)
      ) {
        if (_this.bypassForSCAPICorrection || OnlinePanelService.isOnline()) {
          deferred.resolve({ reload: false });
        } else if (OnlinePanelService.isOffline()) {
          queueBringOnline()
            .then(
              function () {
                deferred.resolve({ reload: true });
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        } else {
          // This shouldn't happen
          console.error(
            "InitialConnectionService->ensureConnectionReady() - Control System not loaded."
          );
          deferred.reject();
        }
      } else {
        deferred.resolve(queueEstablishJob());
      }
      return deferred.promise;
    };

    /**
     * Data for queueBringOnline()
     * @type {{inProgress: boolean, deferredObject: null}}
     */
    var queueBringOnlineJobData = { inProgress: false, deferredObject: null };
    /**
     * Either create a new or return a promise for an existing bringOnline job
     */
    var queueBringOnline = function () {
      var deferred = $q.defer();
      if (queueBringOnlineJobData.inProgress) {
        return queueBringOnlineJobData.deferredObject.promise;
      } else {
        queueBringOnlineJobData.deferredObject = deferred;
        queueBringOnlineJobData.inProgress = true;
        // Initiate First Time connect workflow
        OnlinePanelService.bringOnline()
          .then(
            function () {
              deferred.resolve();
              queueBringOnlineJobData.inProgress = false;
            },
            function (error) {
              deferred.reject(error);
              queueBringOnlineJobData.inProgress = false;
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      return deferred.promise;
    };

    /**
     * Data for queueEstablishJob()
     * @type {{inProgress: boolean, deferredObject: null}}
     */
    var queueEstablishJobData = { inProgress: false, deferredObject: null };
    /**
     * Either create a new or return a promise for an existing establish job
     */
    var queueEstablishJob = function () {
      var deferred = $q.defer();
      const panelComingFromPreProgrammedState =
        !ControlSystemsService.currentControlSystem.panels[
          ControlSystemsService.currentControlSystem.panel_index
        ].online;
      if (queueEstablishJobData.inProgress) {
        return queueEstablishJobData.deferredObject.promise;
      } else {
        queueEstablishJobData.deferredObject = deferred;
        queueEstablishJobData.inProgress = true;
        // Initiate First Time connect workflow
        establish()
          .then(
            function () {
              deferred.resolve();
              queueEstablishJobData.inProgress = false;
            },
            function (error) {
              deferred.reject(error);
              queueEstablishJobData.inProgress = false;
              if (panelComingFromPreProgrammedState) {
                ControlSystemsService.currentControlSystem.panels[
                  ControlSystemsService.currentControlSystem.panel_index
                ].online = false;
              }
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      return deferred.promise;
    };

    /**
     * Start initial connection to the panel
     */
    var establish = function () {
      var deferred = $q.defer();
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          var confirmModal = $modal.open({
            templateUrl: "confirmConnectModalContent.html",
            controller: "ConfirmConnectModalCtrl",
            size: "md",
            backdrop: "static",
          });
          confirmModal.result
            .then(
              function (reason) {
                performICConfirmed()
                  .then(
                    function () {
                      deferred.resolve();
                    },
                    function (error) {
                      deferred.reject(error);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function (error) {
                performICDismissed();
                deferred.reject("USER_CANCELLED");
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          break;
        case "techApp":
          $modal
            .fromTemplateUrl(
              "templates/initial_connection/perform-initial-connection-confirm-modal.html",
              {
                animation: "animated slideInRight",
              }
            )
            .then(function (modal) {
              let modalIsRemoved = false;
              modal.show();
              $rootScope.$broadcast("initial_connection_triggered");
              modal.scope.OnlinePanelService = OnlinePanelService;
              var canFastProgram = UserService.canFastProgramPanel();
              var canFullProgram = UserService.canProgramFullPanel();
              modal.scope.programmingType =
                canFastProgram && !canFullProgram
                  ? "Fast"
                  : !canFastProgram && canFullProgram
                  ? "Full"
                  : canFastProgram && canFullProgram
                  ? "Fast or Full"
                  : "";
              modal.scope.connect = function () {
                performICConfirmed()
                  .then(
                    function () {
                      deferred.resolve();
                    },
                    function (error) {
                      deferred.reject(error);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
                closeModal();
              };
              modal.scope.cancel = function () {
                performICDismissed();
                deferred.reject("USER_CANCELLED");
                closeModal();
              };
              function closeModal() {
                modalIsRemoved = true;
                modal.remove();
              }
              modal.scope.$on("modal.hidden", function () {
                if (!modalIsRemoved) {
                  queueEstablishJobData.inProgress = false;
                  modal.remove().then(
                    function () {
                      modalIsRemoved = true;
                    },
                    function () {}
                  );
                }
              });
            })
            .catch(function (error) {
              console.error(error);
            });
          break;
        default:
          deferred.reject("Unknown app type");
          break;
      }
      return deferred.promise;
    };

    function performICConfirmed() {
      var deferred = $q.defer();
      if (OnlinePanelService.isOffline()) {
        queueBringOnline()
          .then(
            function () {
              performInitialConnection()
                .then(
                  function () {
                    deferred.resolve();
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else if (OnlinePanelService.isOnline()) {
        performInitialConnection()
          .then(
            function () {
              deferred.resolve();
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        // This shouldn't happen
        deferred.reject("Online status could not be determined");
      }
      return deferred.promise;
    }

    function performICDismissed() {
      if (ReplacePanelService.replacingPanel()) {
        ReplacePanelService.cancelPanelReplacement();
        $rootScope.alerts.push({
          type: "error",
          text: "Panel replacement cancelled",
        });
      }
    }

    var performInitialConnection = function () {
      var deferred = $q.defer();
      initJobData();
      createJob()
        .then(
          function () {
            openStatusModal()
              .then(
                function () {
                  ClientEventsService.initialConnection
                    .getEvents(
                      ControlSystemsService.currentControlSystem.panels[
                        ControlSystemsService.currentControlSystem.panel_index
                      ].id
                    )
                    .then(
                      function () {
                        deferred.resolve();
                      },
                      function (error) {
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                function (error) {
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            console.error(
              "Unable to create initial connection job" + angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    _this.cancelJob = function () {
      var deferred = $q.defer();
      InitialConnectionResource.cancel(
        { panel_id: getCurrentPanel().id, groupID: _jobGroupID },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    _this.jobCompleted = function () {
      return _this.didJobSucceed() || _this.didJobFail();
    };

    /**
     * Function to determine if the given status is one of a successful job
     * @returns {boolean}
     */
    _this.didJobSucceed = function () {
      return _jobData.JobStatus && _jobData.JobStatus === "success";
    };

    /**
     * Function to determine if the given status is that of a failed job
     * @returns {boolean}
     */
    _this.didJobFail = function () {
      var failStatus = ["fail", "unknown", "hijacked"];
      return (
        _jobData.JobStatus && failStatus.indexOf(_jobData.JobStatus) !== -1
      );
    };

    var statusModal = {};
    /**
     * Creates and opens a modal to display status messages during first connect
     **/
    var openStatusModal = function () {
      var deferred = $q.defer();
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          statusModal = $modal.open({
            templateUrl: "app/common/templates/initial-connection-modal.html",
            controller: "InitialConnectionModalCtrl",
            size: "md",
            backdrop: "static",
            resolve: {
              InitialConnectionService: function () {
                return _this;
              },
            },
          });
          statusModal.result
            .then(
              function (reason) {
                if (_this.didJobSucceed()) {
                  deferred.resolve();
                } else {
                  deferred.reject();
                }
              },
              function () {
                if (_this.didJobSucceed()) {
                  deferred.resolve();
                } else {
                  deferred.reject();
                }
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          break;
        case "techApp":
          $modal
            .fromTemplateUrl(
              "templates/initial_connection/initial-connection-progress-modal.html",
              {
                animation: "animated slideInRight",
                controller: "InitialConnectionModalCtrl",
              }
            )
            .then(function (modal) {
              modal.show();
              modal.scope.jobState = {
                hasData: false,
                inProgress: false,
                currentStep: 0,
                canBeCancelled: false,
                succeeded: false,
                failed: false,
                cancelled: false,
              };
              modal.scope.donutValueColor = ["#3D85A1"];
              modal.scope.controlSystemName =
                ControlSystemsService.currentControlSystem.name;
              modal.scope.panel =
                ControlSystemsService.currentControlSystem.panels[
                  ControlSystemsService.currentControlSystem.panel_index
                ];
              modal.scope.cancel = function () {
                _this
                  .cancelJob()
                  .then(
                    function (data) {
                      modal.scope.icCancelResponse = data.Description;
                    },
                    function (error) {}
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              };
              modal.scope.close = function () {
                if (_this.didJobSucceed()) {
                  deferred.resolve();
                } else {
                  var failReason = DoesNestedPropertyExist(
                    modal,
                    "scope.icJobStatus.JobStatus"
                  )
                    ? "progress modal closed with job status: " +
                      modal.scope.icJobStatus.JobStatus
                    : "Modal closed";
                  deferred.reject(failReason);
                }
                modal.remove();
              };
              modal.scope.formatPercent = function (x) {
                return Math.ceil(x);
              };
              var initialConnectionWorker = $interval(function () {
                _this
                  .refreshJob()
                  .then(
                    function () {
                      modal.scope.jobState.hasData =
                        Object.keys(_jobData).length > 0;
                      if (modal.scope.jobState.hasData) {
                        modal.scope.icJobStatus = _jobData;
                        modal.scope.jobState.inProgress =
                          inProgressJobStatuses.indexOf(_jobData.JobStatus) !==
                          -1;
                        modal.scope.jobState.currentStep =
                          _jobData.CurrentUIStep;
                        modal.scope.jobState.canBeCancelled =
                          _jobData.InitialConnectionAttemptList !== null &&
                          _jobData.CanBeCancelled;
                        modal.scope.jobState.succeeded =
                          successfulJobStatuses.indexOf(_jobData.JobStatus) !==
                          -1;
                        modal.scope.jobState.failed =
                          unsuccessfulJobStatuses.indexOf(
                            _jobData.JobStatus
                          ) !== -1;
                        modal.scope.jobState.cancelled =
                          _jobData.JobMessage === "Job cancelled";
                      }
                      if (_this.jobCompleted()) {
                        $interval.cancel(initialConnectionWorker);
                      }
                    },
                    function (error) {
                      if (error.status === 403) {
                        console.warn(
                          "Initial connect job status forbidden - panel may have been moved to default dealer."
                        );
                      } else {
                        console.error(
                          "Unable to get initial connection job from server. Error" +
                            angular.toJson(error)
                        );
                        $interval.cancel(initialConnectionWorker);
                        modal.scope.icJobStatus.JobStatus = "error";
                      }
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              }, PROPS.initialConnectionWatcherInterval);
            })
            .catch(function (error) {
              console.error(error);
            });
          break;
        default:
          deferred.reject("Unknown app type");
          break;
      }
      return deferred.promise;
    };

    var inProgressJobStatuses = [
      "new",
      "created",
      "acquired",
      "started",
      "running",
    ];
    var successfulJobStatuses = ["completed", "success"];
    var unsuccessfulJobStatuses = ["failed", "fail", "error", "unknown"];

    /**
     * Creates a scheduled job to connect to the panel for the first time and
     * update vk cache / set account and remote key to desired values
     */
    var createJob = function () {
      var deferred = $q.defer();
      var panel = getCurrentPanel();
      InitialConnectionResource.create(
        {
          panel_id: panel.id,
          acct_num: panel.account_number,
          remote_key: panel.remote_key,
        },
        function (data) {
          _jobGroupID = angular.copy(data).Id;
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
  },
]);

/**
 * A controller specifically for confirm initial connect
 */
App.controller("ConfirmConnectModalCtrl", [
  "$scope",
  "$modalInstance",
  "PanelProgrammingService",
  "OnlinePanelService",
  "UserService",
  "$state",
  function (
    $scope,
    $modalInstance,
    PanelProgrammingService,
    OnlinePanelService,
    UserService,
    $state
  ) {
    $scope.PanelProgrammingService = PanelProgrammingService;
    $scope.OnlinePanelService = OnlinePanelService;

    const systemReplacement =
      UserService.dealerInfo.vernaculars.systems.replacement;
    const isSecurityCommand = UserService.dealerInfo.security_command;
    const appName = isSecurityCommand ? "Security Command" : "Dealer Admin";

    $scope.firstInitialConnectMessage = `${appName} needs to connect to this ${
      systemReplacement || "system"
    } for the first time.`;

    var canFastProgram = UserService.canFastProgramPanel();
    var canFullProgram = UserService.canProgramFullPanel();
    if (canFastProgram && !canFullProgram) {
      $scope.programmingButton = "Fast";
    } else if (!canFastProgram && canFullProgram) {
      $scope.programmingButton = "Full";
    } else if (canFastProgram && canFullProgram) {
      $scope.programmingButton = "Fast or Full";
    }

    $scope.stateIsPanelProgramming = function () {
      return $state.is("app.panel_programming.programming");
    };

    $scope.connect = function () {
      $modalInstance.close("connect");
    };

    $scope.cancel = function () {
      $modalInstance.dismiss("USER_CANCELLED");
    };
  },
]);

/**
 * A controller specifically for status while attempting to retrieve programming
 */
App.controller("InitialConnectionModalCtrl", [
  "$interval",
  "$scope",
  "$modalInstance",
  "InitialConnectionService",
  "PROPS",
  "ControlSystemsService",
  "PanelProgrammingService",
  "UserService",
  function (
    $interval,
    $scope,
    $modalInstance,
    InitialConnectionService,
    PROPS,
    ControlSystemsService,
    PanelProgrammingService,
    UserService
  ) {
    var _this = this;
    $scope.UserService = UserService;
    $scope["icJobStatus"] = {};
    $scope["controlSystemName"] =
      ControlSystemsService.currentControlSystem.name;
    $scope["panel"] =
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ];

    $scope.reload = function () {
      $modalInstance.close("reload");
    };

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    /**
     * Cancel initial connection job
     */
    $scope.cancelConnect = function () {
      InitialConnectionService.cancelJob()
        .then(
          function (data) {
            $scope["icCancelResponse"] = data.Description;
          },
          function (error) {}
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.closeStatusModal = function (reason) {
      try {
        $scope.pushAppFeatures();
        $modalInstance.close(reason);
      } catch (error) {}
    };

    $scope.pushAppFeatures = function () {
      PanelProgrammingService.sendAppFeatures($scope.panel.id);
    };

    $scope.formatPercent = function (x) {
      return Math.ceil(x);
    };

    /**
     * Starts interval function that checks Initial Connection job group status,
     * updating scoped data for display in the initial connection modal
     */
    var startICJobMonitor = function () {
      _this.initialConnectionWorker = $interval(function () {
        InitialConnectionService.refreshJob()
          .then(
            function () {
              $scope.icJobStatus = InitialConnectionService.getJobData();
              if (InitialConnectionService.jobCompleted()) {
                $interval.cancel(_this.initialConnectionWorker);
              }
            },
            function (error) {
              if (error.status === 403) {
                console.warn(
                  "Initial connect job status forbidden - panel may have been moved to default dealer."
                );
              } else {
                console.error(
                  "Unable to get initial connection job from server. Error" +
                    angular.toJson(error)
                );
                $interval.cancel(_this.initialConnectionWorker);
                modal.scope.icJobStatus.JobStatus = "error";
              }
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }, PROPS.initialConnectionWatcherInterval);
    };

    startICJobMonitor();
  },
]);
