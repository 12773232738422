/**
 * @generated SignedSource<<3c66a4c97d37fd01861fa8162119b23e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsIntegratorBackupTcpPortField_remoteOptions$data = {
  readonly integratorBackupConnection?: RemoteOptionsConnectionType | null;
  readonly integratorBackupIncomingPort?: string | null;
  readonly integratorConnection?: RemoteOptionsConnectionType | null;
  readonly " $fragmentType": "RemoteOptionsIntegratorBackupTcpPortField_remoteOptions";
};
export type RemoteOptionsIntegratorBackupTcpPortField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsIntegratorBackupTcpPortField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsIntegratorBackupTcpPortField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorConnection",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorBackupConnection",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorBackupIncomingPort",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsIntegratorBackupTcpPortField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75RemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "5ac4fbb673603b6d39895e5a477944f0";

export default node;
