/**
 * @generated SignedSource<<1c66a8ef4f3447411410f47ec4ff25a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75CardFormatsProgrammingConceptForm_controlSystem$data = {
  readonly copiedCardFormat: {
    readonly id: string;
  } | null;
  readonly customer: {
    readonly id: string;
  };
  readonly id: string;
  readonly panel: {
    readonly cardFormats: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly name: string;
      readonly number: string;
      readonly " $fragmentSpreads": FragmentRefs<"CardFormatContext_cardFormat" | "CardFormatNameField_cardFormat" | "CardFormatNumberField_cardFormat" | "CardFormatRequireSiteCodeField_cardFormat" | "CardFormatSiteCode1Field_cardFormat" | "CardFormatSiteCode2Field_cardFormat" | "CardFormatSiteCode3Field_cardFormat" | "CardFormatSiteCode4Field_cardFormat" | "CardFormatSiteCode5Field_cardFormat" | "CardFormatSiteCode6Field_cardFormat" | "CardFormatSiteCode7Field_cardFormat" | "CardFormatSiteCode8Field_cardFormat" | "CardFormatSiteCodeLengthField_cardFormat" | "CardFormatSiteCodePositionField_cardFormat" | "CardFormatTypeField_cardFormat" | "CardFormatUserCodeDigitsField_cardFormat" | "CardFormatUserCodeLengthField_cardFormat" | "CardFormatUserCodePositionField_cardFormat" | "CardFormatWiegandCodeLengthField_cardFormat">;
    }>;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly newCardFormat: {
      readonly id: string;
      readonly isNew: boolean;
      readonly name: string;
      readonly number: string;
      readonly " $fragmentSpreads": FragmentRefs<"CardFormatContext_cardFormat" | "CardFormatNameField_cardFormat" | "CardFormatNumberField_cardFormat" | "CardFormatRequireSiteCodeField_cardFormat" | "CardFormatSiteCode1Field_cardFormat" | "CardFormatSiteCode2Field_cardFormat" | "CardFormatSiteCode3Field_cardFormat" | "CardFormatSiteCode4Field_cardFormat" | "CardFormatSiteCode5Field_cardFormat" | "CardFormatSiteCode6Field_cardFormat" | "CardFormatSiteCode7Field_cardFormat" | "CardFormatSiteCode8Field_cardFormat" | "CardFormatSiteCodeLengthField_cardFormat" | "CardFormatSiteCodePositionField_cardFormat" | "CardFormatTypeField_cardFormat" | "CardFormatUserCodeDigitsField_cardFormat" | "CardFormatUserCodeLengthField_cardFormat" | "CardFormatUserCodePositionField_cardFormat" | "CardFormatWiegandCodeLengthField_cardFormat">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"CardFormatTypeField_CardFormatList_panel" | "CardFormatWiegandCodeLengthField_CardFormatList_panel" | "PanelContextUseHardwareModel_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XT75CardFormatsProgrammingConceptForm_controlSystem";
};
export type XT75CardFormatsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XT75CardFormatsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75CardFormatsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isNew",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatContext_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatNumberField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatNameField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatTypeField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatWiegandCodeLengthField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCodePositionField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCodeLengthField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatUserCodePositionField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatUserCodeLengthField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatUserCodeDigitsField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatRequireSiteCodeField_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCode1Field_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCode2Field_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCode3Field_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCode4Field_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCode5Field_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCode6Field_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCode7Field_cardFormat"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CardFormatSiteCode8Field_cardFormat"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75CardFormatsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CardFormatWiegandCodeLengthField_CardFormatList_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CardFormatTypeField_CardFormatList_panel"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CardFormat",
          "kind": "LinkedField",
          "name": "cardFormats",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CardFormat",
          "kind": "LinkedField",
          "name": "newCardFormat",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CardFormat",
          "kind": "LinkedField",
          "name": "copiedCardFormat",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "9a31d26f6878a4a77f1849079d92f75a";

export default node;
