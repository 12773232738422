import graphql from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment, useMutation } from "react-relay/hooks";
import styled from "styled-components";
import { ArmingShieldIcon } from "../Icons";
import Space from "../Space";
import { themeDanger } from "../Theme";
import TransitionView from "../TransitionView";
import Widget from "../Widget";
import AllPerimeterArming from "./AllPerimeterArming";
import AreaArming from "./AreaArming";
import BadZonesAlert from "./BadZonesAlert";
import HomeAwayArming from "./HomeAwayArming";
import HomeSleepAwayArming from "./HomeSleepAwayArming";
import messages from "./messages";
import SingleAreaArming from "./SingleAreaArming";
import { ArmingWidgetAreaInformationsAndArmedStatusMutation } from "./__generated__/ArmingWidgetAreaInformationsAndArmedStatusMutation.graphql";
import { ArmingWidget_controlSystem$key } from "./__generated__/ArmingWidget_controlSystem.graphql";

const ArmingWidget = React.forwardRef(
  (
    props: {
      globalControls?: React.ReactNode[];
      setBadZonesActions?: Function;
      controlSystem: ArmingWidget_controlSystem$key;
      onRefreshError: Function;
      style?: {};
      title?: string;
      icon?: React.ReactNode;
      isLoading: boolean;
      UserService: any;
    },
    ref
  ) => {
    const system = useFragment(
      graphql`
        fragment ArmingWidget_controlSystem on ControlSystem {
          id
          name
          isArmed
          isInAlarm
          isECP
          isDSC
          isSingleAreaSystem
          isHSASystem
          isHSAGSystem
          isHASystem
          isAreaSystem
          isAPSystem
          isTMSentry
          ...SingleAreaArming_controlSystem
          ...AreaArming_controlSystem
          ...AllPerimeterArming_controlSystem
          ...HomeAwayArming_controlSystem
          ...HomeSleepAwayArming_controlSystem
          ...BadZonesAlert_controlSystem
        }
      `,
      props.controlSystem
    );
    const [
      refreshAreaInformationsAndArmedStatus,
      isRefreshingAreaInformationsAndArmedStatus,
    ] = useMutation<ArmingWidgetAreaInformationsAndArmedStatusMutation>(
      graphql`
        mutation ArmingWidgetAreaInformationsAndArmedStatusMutation(
          $systemId: ID!
        ) {
          refreshAreaInformationsAndArmedStatus(systemId: $systemId) {
            ... on RefreshAreaInformationsAndArmedStatusSuccessPayload {
              controlSystem {
                ...ArmingWidget_controlSystem
              }
            }
            ... on Error {
              type
            }
          }
        }
      `
    );

    const refreshAreas = () => {
      refreshAreaInformationsAndArmedStatus({
        variables: {
          systemId: system.id,
        },
        onCompleted: (response) => {
          if (response.refreshAreaInformationsAndArmedStatus?.type) {
            props.onRefreshError();
          }
        },
      });
    };

    const intl = useIntl();
    const [statusMessage, setStatusMessage] = useState(
      (system.isAreaSystem || system.isHSAGSystem) && !system.isSingleAreaSystem
        ? null
        : !system.isSingleAreaSystem &&
          !system.isHSASystem &&
          !system.isHSAGSystem &&
          !system.isHASystem &&
          !system.isAreaSystem &&
          !system.isAPSystem
        ? null
        : system.isArmed
        ? {
            ...messages.statusMessage,
            values: {
              status: intl.formatMessage(messages.armed).toLowerCase(),
            },
          }
        : {
            ...messages.statusMessage,
            values: {
              status: intl.formatMessage(messages.disarmed).toLowerCase(),
            },
          }
    );
    const [isLoading, setIsLoading] = useState(false);
    const [badZonesMethod, setBadZonesMethod] = useState(null);
    return (
      <Wrapper ref={ref}>
        <Widget
          icon={props.icon ? props.icon : <ArmingShieldIcon />}
          title={props.title ? props.title : "Arming"}
          statusMessage={
            system.isInAlarm ? (
              <BurgAlarmStatusMessage>
                <FormattedMessage
                  {...messages.statusMessage}
                  values={{
                    status: intl.formatMessage(messages.inAlarm),
                  }}
                />
              </BurgAlarmStatusMessage>
            ) : statusMessage ? (
              <FormattedMessage key="status-message" {...statusMessage} />
            ) : null
          }
          loading={
            props.isLoading ||
            isRefreshingAreaInformationsAndArmedStatus ||
            isLoading
          }
        >
          <ContentWrapper>
            {system.isSingleAreaSystem ? (
              <SingleAreaArming
                controlSystem={system}
                setStatusMessage={setStatusMessage}
                setIsLoading={setIsLoading}
              />
            ) : system.isAreaSystem ||
              system.isHSAGSystem ||
              system.isTMSentry ? (
              <AreaArming
                armingIsEnabled={props.UserService.armingIsEnabled()}
                disarmingIsEnabled={props.UserService.disarmingIsEnabled()}
                controlSystem={system}
                setStatusMessage={setStatusMessage}
                setBadZonesMethod={setBadZonesMethod}
              />
            ) : system.isAPSystem ? (
              <AllPerimeterArming
                armingIsEnabled={props.UserService.armingIsEnabled()}
                disarmingIsEnabled={props.UserService.disarmingIsEnabled()}
                controlSystem={system}
                setStatusMessage={setStatusMessage}
                setIsLoading={setIsLoading}
                setBadZonesMethod={setBadZonesMethod}
              />
            ) : system.isHASystem ? (
              <HomeAwayArming
                armingIsEnabled={props.UserService.armingIsEnabled()}
                disarmingIsEnabled={props.UserService.disarmingIsEnabled()}
                controlSystem={system}
                setStatusMessage={setStatusMessage}
                setIsLoading={setIsLoading}
                setBadZonesMethod={setBadZonesMethod}
              />
            ) : system.isHSASystem ? (
              <HomeSleepAwayArming
                armingIsEnabled={props.UserService.armingIsEnabled()}
                disarmingIsEnabled={props.UserService.disarmingIsEnabled()}
                controlSystem={system}
                setStatusMessage={setStatusMessage}
                setIsLoading={setIsLoading}
                setBadZonesMethod={setBadZonesMethod}
              />
            ) : (
              <>
                <Space small />
                <FormattedMessage
                  key="status-failed-message"
                  {...messages.failedToRetrieveArmedStatus}
                />
              </>
            )}
          </ContentWrapper>
        </Widget>
        <TransitionView
          type="push"
          active={badZonesMethod !== null}
          data={badZonesMethod}
        >
          {(inlineStyle, data) => (
            <Overlay style={inlineStyle}>
              <BadZonesAlert
                setBadZonesActions={() => {}}
                controlSystem={system}
                setBadZonesMethod={setBadZonesMethod}
                badZones={data.badZones}
                respondToBadZones={data.executeMutation}
                isECP={system.isECP}
                isDSC={system.isDSC}
                setStatusMessage={setStatusMessage}
                setIsLoading={setIsLoading}
                isAreaSystem={system.isAreaSystem || system.isHSAGSystem}
              />
            </Overlay>
          )}
        </TransitionView>
      </Wrapper>
    );
  }
);

export default ArmingWidget;
const Wrapper = styled.div`
  position: relative;
`;
const ContentWrapper = styled(Wrapper)`
  min-height: 20rem;
`;
const BurgAlarmStatusMessage = styled.span`
  color: ${themeDanger};
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;
