import { motionDetectionElementsSvgId } from "components/CameraEditCommon/constants";
import { Coordinates, RegionBounds } from "components/CameraEditCommon/types";
import {
  CoordinateRenderer,
  getClosestPoint,
  getEdgePoints,
  getPointForSlopeGivenX,
  getPointForSlopeGivenY,
  normalizePxX,
  normalizePxY,
  renderCoordinates,
  slope,
} from "components/CameraEditCommon/utils";
import React, { useMemo, useState } from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import { DirectionOfTravel as DirectionOfTravelTypes } from "securecom-graphql/client";
import styled from "styled-components";
import { ArrowState } from "./arrow/ArrowState";
import DirectionOfTravelArrows from "./arrow/DirectionOfTravelArrows";
import { VarHubCameraWithClientSchemaData } from "./types";

// Helper Components
const CornerPoint: React.FC<{
  x: number;
  y: number;
  active?: boolean;
  index: number;
  regionIndex: number;
  mouseDown: boolean;
}> = ({ x, y, active, index, regionIndex, mouseDown }) => (
  <g>
    <circle
      r={5}
      cx={x}
      cy={y}
      fill={active ? "blue" : "white"}
      stroke="blue"
      strokeWidth={2}
    />
    <circle
      id={`motion-detection-region-${regionIndex}-point-${index}`}
      r={10}
      cx={x}
      cy={y}
      fill="transparent"
      style={{ cursor: mouseDown ? "grabbing" : "grab" }}
    />
  </g>
);
const Edge: React.FC<{
  point1: Coordinates;
  point2: Coordinates;
  index: number;
  regionIndex: number;
  cameraId: string;
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
  bounds: RegionBounds;
}> = ({
  point1,
  point2,
  index,
  regionIndex,
  cameraId,
  renderX,
  renderY,
  bounds,
}) => {
  const relayEnv = useRelayEnvironment();
  const [newPointCoordinates, setNewPointCoordinates] = useState<
    [number, number] | null
  >(null);

  const slopeValue = useMemo(() => slope(point1)(point2), [point1, point2]);

  const handleMouseMove = (event: React.MouseEvent<SVGLineElement>) => {
    const { clientX, clientY } = event.nativeEvent;
    const svg = document.getElementById(motionDetectionElementsSvgId);
    if (!svg) return;
    const svgRect = svg.getBoundingClientRect();
    const cursorX = normalizePxX(bounds)(svg)(clientX - svgRect.x);
    const cursorY = normalizePxY(bounds)(svg)(clientY - svgRect.y);
    if (slopeValue === Infinity) {
      setNewPointCoordinates([point1[0], cursorY]);
    } else {
      const p1 = getPointForSlopeGivenX(point1)(slopeValue)(cursorX);
      const p2 = getPointForSlopeGivenY(point1)(slopeValue)(cursorY);
      setNewPointCoordinates(getClosestPoint([cursorX, cursorY])(p1)(p2));
    }
  };

  const handleMouseDown = (event: React.MouseEvent<SVGLineElement>) => {
    if (newPointCoordinates) {
      event.nativeEvent.stopPropagation();
      relayEnv.commitUpdate((store) => {
        const camera = store.get(
          cameraId
        ) as RecordProxy<VarHubCameraWithClientSchemaData>;
        const region = camera.getLinkedRecords("detectionRegions")[regionIndex];
        const geometry = region.getLinkedRecord("geometry");
        const coordinates = [...geometry.getValue("coordinates")[0]];
        coordinates.splice(index + 1, 0, newPointCoordinates);
        geometry.setValue([coordinates], "coordinates");
        region.setValue([index + 1], "activeCoordinateIndexes");
        camera.setValue(true, "mouseDown");
      });
    }
  };

  return (
    <>
      {newPointCoordinates && (
        <circle
          id={`motion-detection-region-${index}-new-point`}
          r={5}
          cx={renderX(newPointCoordinates[0])}
          cy={renderY(newPointCoordinates[1])}
          fill="white"
          stroke="blue"
          strokeWidth={2}
          opacity={0.8}
        />
      )}
      <line
        id={`motion-detection-region-${regionIndex}-edge-${index}`}
        x1={renderX(point1[0])}
        y1={renderY(point1[1])}
        x2={renderX(point2[0])}
        y2={renderY(point2[1])}
        strokeWidth={25}
        stroke="transparent"
        style={{ cursor: "copy" }}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => setNewPointCoordinates(null)}
        onMouseDown={handleMouseDown}
      />
    </>
  );
  };
// Main Component
const DetectionRegion: React.FC<{
  cameraId: string;
  isActive: boolean;
  mouseDown: boolean;
  aspectRatio: number;
  color: string;
  coordinates: Coordinates[];
  index: number;
  activeCoordinateIndexes: number[];
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
  bounds: RegionBounds;
  directionOfTravel: DirectionOfTravelTypes;
}> = ({
  cameraId,
  isActive,
  color,
  coordinates,
  index,
  activeCoordinateIndexes,
  renderX,
  renderY,
  bounds,
  mouseDown,
  directionOfTravel,
}) => {
  const coordinatesToRender = coordinates.slice(0, -1);
  const renderedPoints = coordinatesToRender.map(
    renderCoordinates(renderX)(renderY)
  );
  const centerX =
    (Math.min(...coordinates.map((c) => c[0])) +
      Math.max(...coordinates.map((c) => c[0]))) /
    2;

  const centerY =
    (Math.min(...coordinates.map((c) => c[1])) +
      Math.max(...coordinates.map((c) => c[1]))) /
    2;

  const regionWidth =
    Math.max(...coordinates.map((c) => c[0])) -
    Math.min(...coordinates.map((c) => c[0]));

  const regionHeight =
    Math.max(...coordinates.map((c) => c[1])) -
    Math.min(...coordinates.map((c) => c[1]));

  const arrowStateValue = useMemo(() => {
    switch (directionOfTravel) {
      case "IN":
        return ArrowState.Inward;
      case "OUT":
        return ArrowState.Outward;
      case "CROSS":
        return ArrowState.BiDirectional;
      case "OFF":
        return ArrowState.None;
      default:
        return ArrowState.None;
    }
  }, [directionOfTravel]);

  // Calculate midpoints and angles of each edge
  const edges = getEdgePoints(coordinatesToRender);
  const midpoints = edges.map(([p1, p2]) => [
    (p1[0] + p2[0]) / 2,
    (p1[1] + p2[1]) / 2,
  ]);
  const angles = edges.map(([p1, p2]) => {
    const dx = p2[0] - p1[0];
    const dy = p2[1] - p1[1];
    return Math.atan2(dy, dx) * (180 / Math.PI)
  });

  return (
    <>
      <g>
        <RegionPath
          id={`motion-detection-region-${index}`}
          fill={color}
          opacity={0.8}
          d={`${renderedPoints.reduce(
            (d, [x, y], i) => (i === 0 ? `M ${x} ${y}` : `${d} L ${x} ${y}`),
            ""
          )} Z`}
          isActive={isActive}
        />
        {isActive && (
          <>
            {!mouseDown &&
              edges.map(([p1, p2], edgeIndex) => (
                <Edge
                  key={`edge-${edgeIndex}`}
                  point1={p1}
                  point2={p2}
                  index={edgeIndex}
                  regionIndex={index}
                  renderX={renderX}
                  renderY={renderY}
                  bounds={bounds}
                  cameraId={cameraId}
                />
              ))}
            {renderedPoints.map(([x, y], pointIndex) => (
              <CornerPoint
                x={x}
                y={y}
                key={`corner-${pointIndex}`}
                active={new Set(activeCoordinateIndexes).has(pointIndex)}
                index={pointIndex}
                regionIndex={index}
                mouseDown={mouseDown}
              />
            ))}
          </>
        )}
      </g>
      {midpoints.map((midpoint, index) => (
         <g
          key={index}
          transform={`translate(${renderX(midpoint[0]) - 100}, ${
            renderY(midpoint[1]) - 85
          }) rotate(${angles[index]}, 100, 85)`}
        >
          <DirectionOfTravelArrows
            arrowState={arrowStateValue}
            regionWidth={regionWidth}
            regionHeight={regionHeight}
          />
        </g>
      ))}
    </>
  );
};

export default DetectionRegion;

// Styled Components
const RegionPath = styled.path<{ isActive: boolean }>`
  cursor: ${({ isActive }) => (isActive ? "move" : "default")};
`;