import graphql from "babel-plugin-relay/macro";
import { LegaleseModal } from "components/LegaleseModal";
import React, { useCallback } from "react";
import { useMutation } from "react-relay";
import { DistributionSubscriberEulaModalAgreeToEulaMutation } from "./__generated__/DistributionSubscriberEulaModalAgreeToEulaMutation.graphql";

const agreeToEulaMutation = graphql`
  mutation DistributionSubscriberEulaModalAgreeToEulaMutation {
    agreeToEula {
      hasAgreed
    }
  }
`;

const DistributionSubscriberEulaModal = ({
  $state,
  dealerId,
  RelayService,
}: {
  $state: any;
  dealerId: string;
  RelayService: any;
}) => {
  const [agreeToEula, agreeingToEula] =
    useMutation<DistributionSubscriberEulaModalAgreeToEulaMutation>(
      agreeToEulaMutation
    );

  const handleLogOut = useCallback(() => {
    RelayService.logout();
    reLogin();
  }, []);

  const redirect = () =>
    $state.go("app.dealer.dealer-invoices-download", {
      dealer_id: dealerId,
    });

  const reLogin = () => $state.go("page.login");

  const handleLogIn = useCallback(() => {
    agreeToEula({
      variables: {},
      onCompleted: ({ agreeToEula }) => {
        if (agreeToEula.hasAgreed) {
          redirect();
        } else {
          handleLogOut();
        }
      },
      onError: () => {
        handleLogOut();
      },
    });
  }, []);

  // this return utilizes a local asset and will be changed when the new html link is available
  return (
    <LegaleseModal
      modalTitle=" Scroll to the bottom for acknowledgement"
      titleColor={"DaColors.Neutral200"}
      srcFile="assets/img/legal-docs/LT-2911_GlobalDealer-ServicesAgreement_24362.html"
      handleCloseModal={handleLogOut}
      hasAgreementCheckbox
      handleSubmit={handleLogIn}
      loginText="Sign In"
    />
  );
};

export default DistributionSubscriberEulaModal;
