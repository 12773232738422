import { Coordinates } from "components/CameraEditCommon/types";
import {
  CoordinateRenderer,
  renderCoordinates,
} from "components/CameraEditCommon/utils";
import * as React from "react";
import { useMemo } from "react";
import { DirectionOfTravel as DirectionOfTravelTypes } from "securecom-graphql/client";
import { ArrowState } from "./arrow/ArrowState";
import DirectionOfTravelArrows from "./arrow/DirectionOfTravelArrows";

// Points at the end of the line
function CornerPoint(props: {
  x: number;
  y: number;
  active?: boolean;
  index: number;
  lineIndex: number;
  mouseDown: boolean;
}) {
  return (
    <g>
      <circle
        r={5}
        cx={props.x}
        cy={props.y}
        fill={props.active ? "blue" : "white"}
        stroke="blue"
        strokeWidth={2}
      />
      <circle
        id={`motion-detection-line-${props.lineIndex}-point-${props.index}`}
        r={10}
        cx={props.x}
        cy={props.y}
        fill="transparent"
        style={{ cursor: props.mouseDown ? "grabbing" : "grab" }}
      />
    </g>
  );
}

function DetectionLine(props: {
  isActive: boolean;
  mouseDown: boolean;
  aspectRatio: number;
  color: string;
  coordinates: Coordinates[];
  index: number;
  directionOfTravel: DirectionOfTravelTypes;
  activeCoordinateIndexes: number[];
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
}) {
  const {
    isActive,
    color,
    coordinates,
    index,
    activeCoordinateIndexes,
    renderX,
    renderY,
    mouseDown,
    directionOfTravel,
  } = props;

  const renderedPoints = coordinates.map(renderCoordinates(renderX)(renderY));

  const [point1, point2] = renderedPoints;
  const [x1, y1] = point1;
  const [x2, y2] = point2;

  const deltaX = x1 - x2;
  const deltaY = y1 - y2;

  let angleOfRotation;

  if (deltaX === 0) {
    angleOfRotation = 0;
  } else if (deltaY === 0) {
    angleOfRotation = 90;
  } else {
    angleOfRotation = (Math.atan2(-deltaX, deltaY) * 180) / Math.PI;
  }

  const centerX =
    (Math.min(...coordinates.map((c) => c[0])) +
      Math.max(...coordinates.map((c) => c[0]))) /
    2;

  const centerY =
    (Math.min(...coordinates.map((c) => c[1])) +
      Math.max(...coordinates.map((c) => c[1]))) /
    2;

  const lineWidth =
    Math.max(...coordinates.map((c) => c[0])) -
    Math.min(...coordinates.map((c) => c[0]));

  const lineHeight =
    Math.max(...coordinates.map((c) => c[1])) -
    Math.min(...coordinates.map((c) => c[1]));

  const arrowStateValue = useMemo(() => {
    switch (directionOfTravel) {
      case "IN":
        return ArrowState.InwardLine;
      case "OUT":
        return ArrowState.OutwardLine;
      case "CROSS":
        return ArrowState.BiDirectionalLine;
      default:
        return ArrowState.BiDirectionalLine;
    }
  }, [directionOfTravel]);

  return (
    <>
      <g>
        <>
          <line
            id={`motion-detection-line-${index}`}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            stroke={color}
            strokeWidth={isActive ? 10 : 5}
            style={isActive ? { cursor: "move" } : { cursor: "default" }}
          />
        </>
        {isActive && (
          <>
            {renderedPoints.map(([x, y], pointIndex) => (
              // if active draw the end points
              <CornerPoint
                key={`end-point-${pointIndex}`}
                x={x}
                y={y}
                active={new Set(activeCoordinateIndexes).has(pointIndex)}
                index={pointIndex}
                lineIndex={index}
                mouseDown={mouseDown}
              />
            ))}
          </>
        )}
      </g>
      <g
        transform={`translate(${renderX(centerX) - 100}, ${
          renderY(centerY) - 100
        })`}
      >
        <DirectionOfTravelArrows
          arrowState={arrowStateValue}
          regionWidth={lineWidth}
          regionHeight={lineHeight}
          angleOfRotation={angleOfRotation}
        />
      </g>
    </>
  );
}

export default DetectionLine;
