/**
 * @generated SignedSource<<a4027dbe5afb2562e0c31e308b8761b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileCredentialsQuery$variables = {
  dealerId: string;
};
export type MobileCredentialsQuery$data = {
  readonly dealer: {
    readonly customersConnection: {
      readonly nodes: ReadonlyArray<{
        readonly availableMobileCredentials: number;
        readonly name: string;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AvailableCredentialsTableFragment_dealer" | "HistoryTableFragment" | "IssueCredentialsFragment_dealer">;
  } | null;
};
export type MobileCredentialsQuery = {
  response: MobileCredentialsQuery$data;
  variables: MobileCredentialsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dealerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availableMobileCredentials",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileCredentialsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "IssueCredentialsFragment_dealer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AvailableCredentialsTableFragment_dealer"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DealerCustomersConnection",
            "kind": "LinkedField",
            "name": "customersConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HistoryTableFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MobileCredentialsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mobileCredentialCost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DealerCustomersConnection",
            "kind": "LinkedField",
            "name": "customersConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unikeyEnabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Vernacular",
            "kind": "LinkedField",
            "name": "vernaculars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "original",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "replacement",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DealerCredentialsSummaryConnection",
            "kind": "LinkedField",
            "name": "credentialsSummaryConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CredentialSummary",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "availableCredits",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalPurchased",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalMobileCredentials",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "customer",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "databaseId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DealerMobileCredentialsHistoryConnection",
            "kind": "LinkedField",
            "name": "mobileCredentialsHistoryConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MobileCredentialHistoryEvent",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "by",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cost",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95748e2242b620068d46a5c2f3d90ecc",
    "id": null,
    "metadata": {},
    "name": "MobileCredentialsQuery",
    "operationKind": "query",
    "text": "query MobileCredentialsQuery(\n  $dealerId: ID!\n) {\n  dealer(id: $dealerId) {\n    ...IssueCredentialsFragment_dealer\n    ...AvailableCredentialsTableFragment_dealer\n    customersConnection {\n      nodes {\n        name\n        availableMobileCredentials\n        id\n      }\n    }\n    ...HistoryTableFragment\n    id\n  }\n}\n\nfragment AvailableCredentialsTableFragment_dealer on Dealer {\n  credentialsSummaryConnection {\n    nodes {\n      id\n      availableCredits\n      totalPurchased\n      totalMobileCredentials\n      customer {\n        id\n        name\n        databaseId\n      }\n    }\n  }\n  vernaculars {\n    original\n    replacement\n    id\n  }\n}\n\nfragment HistoryTableFragment on Dealer {\n  mobileCredentialsHistoryConnection {\n    nodes {\n      id\n      date\n      by\n      amount\n      notes\n      customerName\n      cost\n    }\n  }\n  vernaculars {\n    original\n    replacement\n    id\n  }\n}\n\nfragment IssueCredentialsFragment_dealer on Dealer {\n  id\n  mobileCredentialCost\n  customersConnection {\n    nodes {\n      id\n      name\n      availableMobileCredentials\n      unikeyEnabled\n    }\n  }\n  vernaculars {\n    original\n    replacement\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ce981751fe4ada45f2ba6a7a51aa552";

export default node;
